import { useCallback } from "react";
import { customTranslationsSelector } from "../Selectors";
import useAppSelector from "./useAppSelector";

/**
 * A hook that returns a function that can be used to fetch custom translations from provied key.
 *
 * @returns A function that can be used to fetch translations.
 */
const useCustomTranslate = () => {
    const customTranslations = useAppSelector(customTranslationsSelector);

    const ct = useCallback(
        (key: string): string => {
            return customTranslations.find((translation: any) => translation.key === key)?.value || "";
        },
        [customTranslations]
    );

    return ct;
};

export default useCustomTranslate;
