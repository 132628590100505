import React from "react";
import { ChevronDownIcon, Heading, Status, style } from "@r360/library";
import "./ExpandableSection.scss";
import classNames from "classnames";
import AnimateHeight from "react-animate-height";

type TExpandableSection = {
    expanded?: boolean;
    onToggle?: () => void;
    icon?: React.ReactElement;
    title: string;
    children: React.ReactElement;
    status?: "success" | "error";
    disabled?: boolean;
};

export const ExpandableSection = ({
    expanded = true,
    onToggle,
    icon,
    title,
    children,
    status,
    disabled = false,
}: TExpandableSection) => {
    const renderStatus = () => {
        switch (status) {
            case "success":
                return <Status type="success" />;
            case "error":
                return <Status type="error" />;
            default:
                return null;
        }
    };

    const handleToggle = () => {
        onToggle?.();
    };

    return (
        <section
            className={classNames("expandable-section", {
                "expandable-section--w-icon": icon,
                "expandable-section--expanded": expanded,
                "expandable-section--success": status === "success",
                "expandable-section--error": status === "error",
                "expandable-section--disabled": disabled,
            })}
        >
            <button type="button" onClick={handleToggle} className="expandable-section__header">
                {icon && <div className="expandable-section__icon">{icon}</div>}
                <div className="expandable-section__title">
                    <Heading type="h2" styleAs="h4">
                        {title}
                    </Heading>
                </div>
                <div className="expandable-section__status">{renderStatus()}</div>
                <ChevronDownIcon size={30} color={style.midBlueColor} className="expandable-section__chevron" />
            </button>
            <AnimateHeight
                animateOpacity
                // id={panelId}
                duration={200}
                height={expanded ? "auto" : 0}
                // aria-labelledby={buttonId}
            >
                <div className="expandable-section__content">{children}</div>
            </AnimateHeight>
        </section>
    );
};
