import React from "react";
import { useSelector } from "react-redux";
import { sortRentals } from "../../BusinessUtils";
import { bikeTypeFilters } from "../../Constants";
import BikeItem from "./BikeItem/BikeItem";

const BikeRentalView = props => {
    const filters = useSelector(state => state.filter);
    const reservationTypeResult = useSelector(state => state.reservationResult);
    const rentals = reservationTypeResult[props.reservationType];
    let rentalItems = [];
    const activeFilters = filters[props.reservationType];

    if (rentals?.groups) {
        rentalItems = [].concat(
            ...Object.values(rentals.groups).map(group => {
                if (group.isGrouped && group.groupitem?.thumbnail) {
                    return group.items.map(item => ({ ...item, thumbnail: group.groupitem.thumbnail }));
                }
                return group.items;
            })
        );

        if (props.bikeTypeFilter === bikeTypeFilters.BIKES_ONLY) {
            rentalItems = rentalItems.filter(
                item => item.crits && Object.keys(item.crits).find(x => x === bikeTypeFilters.BIKES_ONLY)
            );
        }

        if (props.bikeTypeFilter === bikeTypeFilters.OPTIONALS_ONLY) {
            rentalItems = rentalItems.filter(
                item => item.crits && Object.keys(item.crits).find(x => x === bikeTypeFilters.OPTIONALS_ONLY)
            );
        }

        const requiredCrits = Object.keys(activeFilters).map(critlid => {
            if (typeof critlid === "string") {
                return parseInt(critlid); // convert "2058" --> 2058
            }
            return critlid;
        });

        if (requiredCrits.length) {
            // Allow rental items that have any matching crits
            const checker = (bike, requiredCrits) => requiredCrits.some(v => bike.includes(v));

            rentalItems = rentalItems.filter(item => {
                if (item.crits) {
                    const boolcrits = Object.keys(item.crits).reduce((p, c) => {
                        if (item.crits[c] === true) {
                            p[c] = item.crits[c];
                        }

                        return p;
                    }, {}); // Only true bool type.

                    const bike = [...Object.values(item.crits), ...Object.keys(boolcrits)].map(critlid => {
                        if (typeof critlid === "string") {
                            return parseInt(critlid); // convert "2058" --> 2058
                        }
                        return critlid;
                    });

                    const checked = checker(bike, requiredCrits);
                    return checked;
                } else {
                    // It criterias does not exist on item, do not include it
                    return false;
                }
            });
        }
    }

    const displayRentalItems = sortRentals(rentalItems.filter(item => item.available));

    return (
        <div className="bike-view">
            <div className="bike-view__options row" aria-live="polite">
                {displayRentalItems.map(item => {
                    return (
                        <BikeItem
                            item={item}
                            cartProducts={props.cartProducts}
                            promoCode={filters.promoCode}
                            key={`${item.pooldesc1}_${item.pricecode}`}
                            index={`${item.pooldesc1}_${item.pricecode}`}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default BikeRentalView;
