import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Button, Col, Fade, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import { Alert, Icon, Modal, Theme } from "../UI";
import { TickIcon } from "../UI/R360Icons";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/src/ReactCrop.scss";
import "./PhotoButton.scss";
import AxiosClient from "../../API/AxiosClient";
import { updateUserData } from "../../store/actions/account";
import { updateGuestData } from "../../store/actions/guests";
import { textsSelector } from "../../Selectors";

const imageAspectRatio = 138 / 150;
const minWidth = 150;
const minHeight = imageAspectRatio * minWidth;

const PhotoButton = React.memo(
    forwardRef((props, ref) => {
        const dispatch = useDispatch();
        const userToken = useSelector(state => state.account.token);
        const { guestlid, imagehash } = props;
        const [showSkipassUpload, setShowSkipassUpload] = useState(false);
        const texts = useSelector(textsSelector);
        const [verified, setVerified] = useState(false);
        const [crop, setCrop] = useState();
        const [completedCrop, setCompletedCrop] = useState();
        const fileInputRef = useRef(null);
        const [showCropper, setShowCropper] = useState(false);
        const imageCropRef = useRef(null);
        const [imageUploadSuccessful, setImageUploadSuccessful] = useState(false);
        const [imageUploadFailed, setImageUploadFailed] = useState(false);
        const [imageSizeToBig, setImageSizeToBig] = useState(false);
        const [imageResolutionError, setImageResolutionError] = useState(false);
        const guests = useSelector(state => state.guests.guests);
        const user = useSelector(state => state.account.user);

        const getImageUrlFromHash = imagehash => {
            if (!imagehash) {
                return "";
            }

            const baseImageUrl = "https://r360generalstorage.blob.core.windows.net/userprofileimages/";
            const imageExtension = ".jpg";

            return `${baseImageUrl}${imagehash}${imageExtension}`;
        };

        const [imagePreviewUrl, setImagePreviewUrl] = useState(getImageUrlFromHash(imagehash));
        const [imageCropUrl, setImageCropUrl] = useState();

        const openPhotoButtonModal = () => setShowSkipassUpload(true);

        useImperativeHandle(ref, () => {
            return {
                openPhotoButtonModal,
            };
        });

        useEffect(() => {
            setVerified(!!imagehash);
            setImagePreviewUrl(getImageUrlFromHash(imagehash));
        }, [imagehash]);

        useEffect(() => {
            if (showSkipassUpload) {
                resetCropper();
                setImageUploadSuccessful(false);
                setImageSizeToBig(false);
                setImageUploadFailed(false);
            }
        }, [showSkipassUpload]);

        const handleShowModal = () => {
            setShowSkipassUpload(true);
        };

        const onImageLoad = e => {
            const { naturalWidth: width, naturalHeight: height } = e.currentTarget;

            if (width < minWidth || height < minHeight) {
                resetCropper();
                setImageResolutionError(true);
                return;
            }

            const aspectCropConfig =
                width * imageAspectRatio > height
                    ? { unit: "%", width: Math.min(100, Math.max(80, (minWidth / width) * 100)) }
                    : { unit: "%", height: Math.min(100, Math.max(80, (minHeight / height) * 100)) };

            const crop = centerCrop(makeAspectCrop(aspectCropConfig, imageAspectRatio, width, height), width, height);

            setCrop(crop);
        };

        const onSelectFile = e => {
            if (e.target.files && e.target.files.length > 0) {
                setCrop(undefined); // Makes crop preview update between images.
                const reader = new FileReader();
                reader.addEventListener("load", () => setImageCropUrl(reader.result.toString() || ""));
                reader.readAsDataURL(e.target.files[0]);

                setShowCropper(true);
            }
        };

        const openFileInput = () => {
            fileInputRef.current?.click?.();
        };

        const getCroppedCanvas = (image, crop) => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");

            if (!ctx) {
                throw new Error("No 2d context");
            }

            const { naturalWidth, naturalHeight } = image;

            const scaleX = naturalWidth / image.width;
            const scaleY = naturalHeight / image.height;

            canvas.width = Math.floor(crop.width * scaleX);
            canvas.height = Math.floor(crop.height * scaleY);
            ctx.imageSmoothingQuality = "high";

            const cropX = crop.x * scaleX;
            const cropY = crop.y * scaleY;

            const centerX = naturalWidth / 2;
            const centerY = naturalHeight / 2;

            ctx.save();

            ctx.translate(-cropX, -cropY);
            ctx.translate(centerX, centerY);
            ctx.translate(-centerX, -centerY);
            ctx.drawImage(image, 0, 0, naturalWidth, naturalHeight, 0, 0, naturalWidth, naturalHeight);
            ctx.restore();

            return canvas;
        };

        const handleSaveCrop = () => {
            const croppedCanvas = getCroppedCanvas(imageCropRef.current, completedCrop);
            const previewUrl = croppedCanvas.toDataURL("image/jpeg");

            setImagePreviewUrl(previewUrl);

            croppedCanvas.toBlob(blob => {
                uploadGuestImage(guestlid, blob);
            }, "image/jpeg");

            resetCropper();
        };

        const resetCropper = () => {
            if (fileInputRef?.current) {
                fileInputRef.current.value = null;
            }

            setImageCropUrl(undefined);
            setShowCropper(false);
            setCrop(undefined);
            setCompletedCrop(undefined);
            setImageUploadSuccessful(false);
            setImageSizeToBig(false);
            setImageUploadFailed(false);
            setImageResolutionError(false);
        };

        /**
         * Upload image for a guest.
         *
         * @param {number} guestlid
         * @param {Blob} imageBlob
         */
        const uploadGuestImage = (guestlid, imageBlob) => {
            setImageUploadSuccessful(false);
            setImageSizeToBig(false);
            setImageUploadFailed(false);

            const config = {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                    "Content-Type": "multipart/form-data",
                },
            };

            const url = `users/guestimage`;
            const formData = new FormData();
            formData.append("guestlid", guestlid);
            formData.append("file", imageBlob, "guestphoto.jpg");

            AxiosClient.post(url, formData, config)
                .then(response => {
                    if (!response?.data?.payload?.imageHash) {
                        throw new Error("Failed to upload skipass image");
                    }

                    setImageUploadSuccessful(true);
                    updateImageHashOnUserAndGuest(response.data.payload.imageHash);
                })
                .catch(error => {
                    if (error.response.request.status === 413) {
                        setImageSizeToBig(true);
                    } else {
                        setImageUploadFailed(true);
                    }
                    setImagePreviewUrl(getImageUrlFromHash(imagehash));
                    console.error(error);
                });
        };

        const updateImageHashOnUserAndGuest = imagehash => {
            // Update image hash for the user.
            if (guestlid === user?.id) {
                dispatch(updateUserData("imagehash", imagehash));
            }

            // If guestlid matches guest, update image hash for the guest.
            if (guests[guestlid]) {
                dispatch(updateGuestData(guestlid, "imagehash", imagehash));
            }
        };

        return (
            <>
                <Button
                    className={"photo-button btn form-control m-0" + (!verified ? " bg-danger" : "")}
                    style={{ lineHeight: 1.3 }}
                    onClick={handleShowModal}
                >
                    {verified && (
                        <>
                            {texts?.photouploaded}
                            <TickIcon size={24} color="#32bea6" iconClass="photo-button__icon" />
                        </>
                    )}
                    {!verified && <>{texts?.uploadphoto}</>}
                </Button>
                <Modal
                    size={showCropper ? "lg" : "md"}
                    backdrop={showCropper ? "static" : true}
                    show={showSkipassUpload}
                    setShow={setShowSkipassUpload}
                >
                    <div className="mx-4">
                        <Row className="mb-5">
                            <Col>
                                <h3 className="h3">{texts["skipass.uploadimage.heading"]}</h3>
                            </Col>
                        </Row>
                        {!showCropper && !imagePreviewUrl && (
                            <Row>
                                <Col className="text-center">
                                    <Button onClick={openFileInput}>{texts["skipass.uploadimage.button.new"]}</Button>
                                </Col>
                            </Row>
                        )}
                        {!showCropper && imagePreviewUrl && (
                            <>
                                <Row className="mb-4">
                                    <Col className="text-center">
                                        <img
                                            src={imagePreviewUrl}
                                            style={{
                                                maxWidth: "100%",
                                                maxHeight: "300px",
                                                borderRadius: "6px",
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="text-center">
                                        <Button onClick={openFileInput}>
                                            {texts["skipass.uploadimage.button.change"]}
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                        )}
                        {showCropper && (
                            <>
                                <Row className="mb-4">
                                    <Col>
                                        <Button className="w-100 button--invert" onClick={resetCropper}>
                                            {texts["skipass.uploadimage.cancel"]}
                                        </Button>
                                    </Col>
                                    <Col>
                                        <Button className="w-100" onClick={handleSaveCrop}>
                                            {texts["skipass.uploadimage.save"]}
                                        </Button>
                                    </Col>
                                </Row>
                                <Row className="mb-4">
                                    <Col>{texts["skipass.uploadimage.cropinstructions"]}</Col>
                                </Row>
                                <Row className="text-center">
                                    <Col>
                                        <ReactCrop
                                            aspect={imageAspectRatio}
                                            minWidth={150}
                                            keepSelection={true}
                                            ruleOfThirds={true}
                                            crop={crop}
                                            onChange={(_, percentageCrop) => setCrop(percentageCrop)}
                                            onComplete={setCompletedCrop}
                                        >
                                            <img
                                                ref={imageCropRef}
                                                src={imageCropUrl}
                                                onLoad={onImageLoad}
                                                style={{ maxHeight: "60vh" }}
                                            />
                                        </ReactCrop>
                                    </Col>
                                </Row>
                            </>
                        )}
                        {!showCropper && (
                            <>
                                {imageUploadSuccessful && (
                                    <Row className="mt-4">
                                        <Col>
                                            <Alert type="success">
                                                <p>{texts["skipass.uploadimage.successful"]}</p>
                                            </Alert>
                                        </Col>
                                    </Row>
                                )}
                                {imageUploadFailed && (
                                    <Row className="mt-4">
                                        <Col>
                                            <Alert type="warning">
                                                <p>{texts["skipass.uploadimage.failed"]}</p>
                                            </Alert>
                                        </Col>
                                    </Row>
                                )}
                                {imageSizeToBig && (
                                    <Row className="mt-4">
                                        <Col>
                                            <Alert type="warning">
                                                <p>{texts["skipass.uploadimage.size_error"]}</p>
                                            </Alert>
                                        </Col>
                                    </Row>
                                )}
                                {imageResolutionError && (
                                    <Row className="mt-4">
                                        <Col>
                                            <Alert type="warning">
                                                <p>{texts["skipass.uploadimage.resolution_error"]}</p>
                                            </Alert>
                                        </Col>
                                    </Row>
                                )}
                            </>
                        )}
                    </div>
                    <input
                        className="d-none"
                        ref={fileInputRef}
                        type="file"
                        accept="image/png, image/jpeg"
                        onChange={onSelectFile}
                    />
                </Modal>
            </>
        );
    })
);

PhotoButton.displayName = "PhotoButton";

export default PhotoButton;
