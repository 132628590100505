import {
    Button,
    ButtonSkeleton,
    ChevronLeftIcon,
    ChevronRightIcon,
    Heading,
    Modal,
    Skeleton,
    SkeletonGroup,
    Select,
    TSelectOption,
} from "@r360/library";
import { capitalize } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { RootState } from "../..";
import useAppSelector from "../../hooks/useAppSelector";
import { TGroupedFilteredItem } from "../../pages/AccommodationAreaPage/AccommodationAreaPage";
import {
    ECriteriaPresentationType,
    ECriteriaType,
    TCriteriaItem,
    TGroup,
    TItem,
    TReservationResult,
    TReservationTypeCriterias,
    TReservationTypeLid,
} from "../../store/types";
import { EditSearchButton } from "../EditSearchButton/EditSearchButton";
import { AccommodationChangeSearch } from "../AccommodationChangeSearch/AccommodationChangeSearch";
import { AvailableAccommodationItem } from "./AvailableAccommodationItem/AvailableAccommodationItem";
import { NoSearchResult } from "../NoSearchResult/NoSearchResult";
import { ToggleFilterButton } from "../ToggleFilterButton/ToggleFilterButton";
import useTranslate from "../../hooks/useTranslate";
import { optionsSelector, resvTypeByLid } from "../../Selectors";
import useMemoizeArg from "../../hooks/useMemoizeArg";
import { AccommodationFilter } from "../AccommodationFilter/AccommodationFilter";
import classNames from "classnames";
import ProductImagesGallery from "../ProductImagesGallery/ProductImagesGallery";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { sortBySortorder } from "../../BusinessUtils";
import pick from "lodash/pick";
import useAppDispatch from "../../hooks/useAppDispatch";
import { fetchProductsExtras } from "../../store/actions/reservationResult";

type TAvailableAccommodations = {
    reservationTypeId: TReservationTypeLid;
    groupPool: TGroup;
    items: TGroupedFilteredItem[];
    onPriceCalendarClick: (arg0: TItem) => void;
    useFilterSearch: boolean;
    filterSearch: string;
    setFilterSearch: React.Dispatch<React.SetStateAction<string>>;
    accomodationResult: TReservationResult;
};

type TSelectedSortOption = {
    value: string | number;
    label: string;
    critType?: ECriteriaType;
};

export const itemTitleColumnClasses =
    "available-accommodation-item__column available-accommodation-item__column--title";
export const itemCritColumnClasses = "available-accommodation-item__column available-accommodation-item__column--crit";
export const itemPriceColumnClasses =
    "available-accommodation-item__column available-accommodation-item__column--price";

const presentationTypeFiltersToShow = [
    "interval_filters",
    "accommodation_type_filters",
    "enum_filters",
    "amenities_filters",
];

export const AvailableAccommodations = ({
    groupPool,
    items,
    onPriceCalendarClick,
    reservationTypeId,
    useFilterSearch,
    filterSearch,
    setFilterSearch,
    accomodationResult,
}: TAvailableAccommodations) => {
    const dispatch = useAppDispatch();
    const reservationResult = useAppSelector(state => state.reservationResult[reservationTypeId]);
    const arrdate = reservationResult?.search?.arrdate;
    const depdate = reservationResult?.search?.depdate;
    const guests = reservationResult?.search?.guests;
    const searchWithPriceExists = !!(arrdate && depdate && reservationResult?.search.checkPriceAndAvailability);
    const { isDesktopNewDesign } = useAppSelector((state: RootState) => state.window);
    const [showFilterRow, setShowFilterRow] = useState(false);
    const [showSearch, setShowSearch] = useState<boolean>(isDesktopNewDesign);
    const t = useTranslate();
    const [accommodationUnitCriterias, setAccommodationUnitCriterias] = useState<TCriteriaItem[]>([]);
    const reservationTypeCriterias: TReservationTypeCriterias =
        useAppSelector((state: RootState) => resvTypeByLid(state, reservationTypeId))?.criterias || {};
    const { requestsLoading } = useAppSelector((state: RootState) => state.axiosStatus);
    const accomodationResultLoading = requestsLoading["fetchReservationTypeResults"];
    const [imageIndex, setImageIndex] = useState(0);
    const [showGalleryModal, setShowGalleryModal] = useState(false);
    const [isChangeSearchOpen, setIsChangeSearchOpen] = useState(true);
    const { isIframe, isDesktop, isTablet, isMobile, iframeOffsetTop, screenSize } = useAppSelector(
        (state: RootState) => state.window
    );
    const lang = useAppSelector(state => state.clientData?.lang);
    const showImageDescriptions = useSelector(optionsSelector).layout?.show_image_descriptions || false;
    const [selectedSortOption, setSelectedSortOption] = useState<TSelectedSortOption>({
        value: 0,
        label: "Standard",
    });
    const [sortedItems, setSortedItems] = useState<TGroupedFilteredItem[]>(sortBySortorder(items));

    const getSortOptions = () => {
        let sortOptions: TSelectOption[] = [];
        sortOptions = accommodationUnitCriterias.map(option => {
            return {
                value: option.code,
                label: option.title,
                critType: option.type,
            };
        });
        if (searchWithPriceExists) {
            sortOptions.push({ value: 1, label: t("book.sorting.price") });
        }
        sortOptions.unshift(
            { value: 0, label: t("book.sorting.standard") },
            { value: 3, label: t("book.sorting.accommodation_name") }
        );
        return sortOptions;
    };

    const navigate = useNavigate();

    useEffect(() => {
        setAccommodationUnitCriterias(
            reservationTypeCriterias[ECriteriaPresentationType.ACCOMMODATION_UNIT_CRITERIAS] || []
        );
    }, [useMemoizeArg(reservationTypeCriterias)]);

    useEffect(() => {
        setShowSearch(isDesktopNewDesign);
    }, [isDesktopNewDesign]);

    useEffect(() => {
        if (!searchWithPriceExists) {
            return;
        }

        // Only fetch extras for items that hasn't item.info.extras set. If no extras exists, it will be an empty array.
        const fetchExtrasProductIds = [
            ...new Set(items.flatMap(group => group.items.filter(item => !item.info?.extras).map(item => item.id))),
        ];

        if (fetchExtrasProductIds.length > 0) {
            dispatch(fetchProductsExtras(reservationTypeId, fetchExtrasProductIds));
        }
    }, [dispatch, useMemoizeArg(items)]);

    const hasMatchingCriterias =
        Object.values(pick(reservationTypeCriterias, presentationTypeFiltersToShow)).flat().length > 0 ||
        useFilterSearch;
    const showFilter = Object.values(reservationResult?.groups || {}).length > 0 && hasMatchingCriterias;

    const onClickForwardGallery = () => {
        imageIndex + 1 === items.length ? setImageIndex(0) : setImageIndex(prevState => prevState + 1);
    };

    const onClickBackGallery = () => {
        imageIndex - 1 === -1 ? setImageIndex(items.length - 1) : setImageIndex(prevState => prevState - 1);
    };

    const onGalleryButtonClick = (index: number) => {
        setImageIndex(index);
        setShowGalleryModal(true);
    };

    const onEditSearchClick = () => {
        setIsChangeSearchOpen(prevState => !prevState);
    };

    const sortItems = useCallback(() => {
        let sortedItems: TGroupedFilteredItem[] = [];
        if (selectedSortOption.critType === ECriteriaType.MIN || selectedSortOption.critType === ECriteriaType.MAX) {
            sortedItems = [...items].sort(
                (a, b) => a.items[0].crits[selectedSortOption.value] - b.items[0].crits[selectedSortOption.value]
            );
        } else if (selectedSortOption.critType === ECriteriaType.BOOL) {
            sortedItems = [...items].sort(
                (a, b) => b.items[0].crits[selectedSortOption.value] - a.items[0].crits[selectedSortOption.value]
            );
        } else if (selectedSortOption.critType === ECriteriaType.ENUM) {
            // Find the criteria object for the selectedSortOption.
            const selectedCriteria = accommodationUnitCriterias.find(
                criteria => criteria.code === selectedSortOption.value
            );
            // Sorting the groupedFilteredItems based on the selectedCriteria in ascending alphabetical order.
            sortedItems = [...items].sort((a, b) => {
                const itemA = a.items[0];
                const itemB = b.items[0];
                const enumItemA = selectedCriteria?.enums.find(
                    enumItem => enumItem.code === itemA.crits[selectedSortOption.value]
                );
                const enumItemB = selectedCriteria?.enums.find(
                    enumItem => enumItem.code === itemB.crits[selectedSortOption.value]
                );
                const titleA = (enumItemA && enumItemA.title) || "";
                const titleB = (enumItemB && enumItemB.title) || "";

                return titleA.localeCompare(titleB, "sv", { numeric: true, ignorePunctuation: true });
            });
        } else if (selectedSortOption.value === 0) {
            //Standard option
            sortedItems = sortBySortorder(items);
        } else if (selectedSortOption.value === 1) {
            //Price option
            sortedItems = [...items].sort((a, b) => a.items[0].price - b.items[0].price);
        } else if (selectedSortOption.value === 3) {
            //Accommodation title option
            sortedItems = [...items].sort((a, b) =>
                a.items[0].title.localeCompare(b.items[0].title, "sv", { numeric: true, ignorePunctuation: true })
            );
        }
        setSortedItems(sortedItems);
    }, [accommodationUnitCriterias, items, selectedSortOption.critType, selectedSortOption.value]);

    useEffect(() => {
        sortItems();
    }, [sortItems]);

    const isPastBottom = isDesktop
        ? screenSize.height < iframeOffsetTop + 800
        : screenSize.height < iframeOffsetTop + 400;

    return (
        <>
            <div className="row">
                <div className="col-12 col-lg available-accommodation__search-header">
                    <Heading type="h2">
                        {t("book.general.available_accomodations")} ({items.length}){" "}
                    </Heading>
                </div>
                <div className="col-12 col-lg available-accommodation__search-actions">
                    {showFilter && (
                        <ToggleFilterButton
                            reservationTypeId={reservationTypeId}
                            showFilter={showFilterRow}
                            onClick={setShowFilterRow}
                        />
                    )}
                </div>
            </div>
            {!isDesktopNewDesign && (
                <div className="u-mb-24">
                    <EditSearchButton
                        arrDate={arrdate}
                        depDate={depdate}
                        guestsCount={guests && guests.length}
                        onClick={onEditSearchClick}
                        searchExists={searchWithPriceExists}
                        isOpen={isChangeSearchOpen}
                    />
                </div>
            )}
            {showFilter && (!isDesktopNewDesign || showFilterRow) && (
                <div className="u-mb-24">
                    <AccommodationFilter
                        key={reservationResult?.resultSetId || ""}
                        reservationTypeId={reservationTypeId}
                        showOnMobile={!isDesktopNewDesign && showFilterRow}
                        onMobileClose={() => setShowFilterRow(false)}
                        showFilterSearch={useFilterSearch}
                        filterSearch={filterSearch}
                        onFilterSearchChange={setFilterSearch}
                        unfilteredSearchResult={Object.values(accomodationResult?.groups || {})}
                        filteredSearchResult={items}
                        columns={isDesktopNewDesign}
                    />
                </div>
            )}
            {(showSearch || isChangeSearchOpen) && (
                <AccommodationChangeSearch
                    reservationTypeid={reservationTypeId}
                    groupPoollid={groupPool.grouppoollid}
                />
            )}
            {(reservationResult?.error || !reservationResult?.hits) && !accomodationResultLoading && (
                <div className="u-pt-36 u-d-flex u-flex-column u-align-items-center">
                    <NoSearchResult />
                    <Button
                        buttonClassName="u-mt-24 u-mb-54"
                        leftIcon={<ChevronLeftIcon />}
                        onClick={() => navigate("/")}
                    >
                        {t("book.cart.back_to_selection")}
                    </Button>
                </div>
            )}
            {accomodationResultLoading && !reservationResult?.error && (
                <SkeletonGroup>
                    {[...Array(4)].map((_item, index) => {
                        return (
                            <div
                                key={index}
                                className={classNames("u-pb-12 u-mb-24 u-mb-xl-3", {
                                    "u-bg-white": !(index & 1) || !isDesktopNewDesign,
                                })}
                            >
                                <Skeleton width="100%" height="40px" borderRadius="0" className="u-mb-6" />
                                <div className="row u-justify-content-between">
                                    <div className="col-12 col-xl u-d-flex u-gap-12 u-align-items-xl-center u-flex-column u-flex-xl-row u-mb-24 u-mb-xl-0">
                                        <Skeleton
                                            width={isDesktop ? "200px" : "100%"}
                                            height="100px"
                                            borderRadius="0"
                                            style={{ flexShrink: 0, marginRight: "18px" }}
                                        />
                                        {[...Array(7)].map((_item, index) => {
                                            return (
                                                <Skeleton
                                                    key={index}
                                                    shade={!(index & 1) && !isDesktopNewDesign ? "light" : "medium"}
                                                    width={isDesktop ? "70px" : "100%"}
                                                />
                                            );
                                        })}
                                    </div>
                                    <div className="col-12 col-xl u-d-flex u-justify-content-end u-mb-12 u-mr-12 u-mb-xl-0">
                                        <ButtonSkeleton width={!isDesktopNewDesign ? "100%" : "150px"} />
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </SkeletonGroup>
            )}
            {reservationResult?.hits && !accomodationResultLoading && (
                <div>
                    <div className="available-accommodation-header__sort-select-container">
                        <p>{t("book.general.sort")}</p>
                        <div className="available-accommodation-header__sort-select-width">
                            <Select
                                options={getSortOptions()}
                                defaultValue={selectedSortOption.value}
                                onSelectCallback={(option: TSelectOption) => setSelectedSortOption(option)}
                                type="standard"
                                fullWidth
                            />
                        </div>
                    </div>
                    <div className="available-accommodation__list-container">
                        {isDesktopNewDesign && (
                            <div className="available-accommodation-header" style={{ top: iframeOffsetTop }}>
                                <div className={itemTitleColumnClasses}>
                                    <span className="available-accommodation-header__title">
                                        {t("book.general.accomodation")}
                                    </span>{" "}
                                </div>
                                {accommodationUnitCriterias.map(criteria => (
                                    <div key={criteria.code} className={itemCritColumnClasses}>
                                        {capitalize(criteria.title.toLowerCase())}
                                    </div>
                                ))}
                                <div className={itemPriceColumnClasses}>
                                    {searchWithPriceExists && t("book.general.price")}
                                </div>
                            </div>
                        )}
                        {sortedItems.map((item, index) => {
                            return (
                                <AvailableAccommodationItem
                                    reservationTypeid={reservationTypeId}
                                    productListItem={item}
                                    critsToDisplay={accommodationUnitCriterias}
                                    key={item.items[0].id}
                                    showPrice={searchWithPriceExists}
                                    fetchExtras={searchWithPriceExists}
                                    onPriceCalendarClick={onPriceCalendarClick}
                                    promoCode={item.items[0].promotionCode || ""}
                                    onGalleryClick={() => onGalleryButtonClick(index)}
                                    options={item.items}
                                />
                            );
                        })}
                    </div>
                </div>
            )}

            <Modal
                open={showGalleryModal}
                size="lg"
                onClose={() => setShowGalleryModal(false)}
                {...(isIframe && isDesktop && { fromTop: 120 + iframeOffsetTop + "px" })}
                {...(isIframe &&
                    (isMobile || isTablet) && {
                        fromTop: 70 + iframeOffsetTop + "px",
                    })}
                className={classNames("accommodation-area-page__gallery-modal", {
                    "accommodation-area-page__gallery-modal-past-bottom": isPastBottom,
                })}
            >
                {sortedItems.length > 1 && (
                    <div className="u-d-flex justify-content-between align-items-center u-m-18">
                        <Button
                            type="tertiary"
                            leftIcon={<ChevronLeftIcon />}
                            buttonSize="small"
                            onClick={() => onClickBackGallery()}
                        >
                            {t("book.gallery.previous")}
                        </Button>
                        <Button
                            type="tertiary"
                            rightIcon={<ChevronRightIcon />}
                            buttonSize="small"
                            onClick={() => onClickForwardGallery()}
                        >
                            {t("book.gallery.next")}
                        </Button>
                    </div>
                )}
                <div className="u-d-flex justify-content-center u-mb-12">
                    <Heading type="h5">{sortedItems[imageIndex]?.items[0].title || ""}</Heading>
                </div>
                {sortedItems[imageIndex]?.items[0].images.length < 1 ? (
                    <div className="u-d-flex justify-content-center u-mt-18">
                        <p>
                            <i>{t("book.gallery.noimages")}</i>
                        </p>
                    </div>
                ) : (
                    <ProductImagesGallery
                        images={sortedItems[imageIndex]?.items[0].images.map(image => {
                            return {
                                thumbnail: image.url,
                                original: image.url,
                                text: image?.translatedDesc ? image?.translatedDesc[lang] : "",
                            };
                        })}
                        showImageDescriptions={showImageDescriptions}
                    />
                )}
            </Modal>
        </>
    );
};
