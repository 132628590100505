import React from "react";
import { useSelector } from "react-redux";
import CheckoutSummary from "../../components/CheckoutSummary/CheckoutSummary";
import { textsSelector } from "../../Selectors";

// Checkout Test summary page
const CheckoutSummaryPage = () => {
    const texts = useSelector(textsSelector);

    return (
        <div className="container">
            <h1 className="page-header">{texts?.checkoutsummaryheader}</h1>
            <CheckoutSummary texts={texts} />
        </div>
    );
};

export default CheckoutSummaryPage;
