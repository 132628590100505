import { useCallback, useEffect } from "react";

const useThrottle = (effectFn, delay, dependencies) => {
    // eslint-disable-next-line
    const callback = useCallback(effectFn, dependencies);

    useEffect(() => {
        const timer = setTimeout(() => {
            callback();
        }, delay);

        return () => {
            clearTimeout(timer);
        };
    }, [callback, delay]);
};

export default useThrottle;
