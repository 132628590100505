import React from "react";
import { Modal } from "@r360/library";
import useAppSelector from "../../hooks/useAppSelector";
import { RootState } from "../..";
import { Login } from "../Login/Login";
import { useDispatch } from "react-redux";
import { setSignUpWrapper } from "../../store/actions/window";
import "./LoginRegistrationModal.scss";
import { sendMessage } from "../../Helper";
import Registration from "../Registration";
import classNames from "classnames";

export const LoginRegistrationModal = () => {
    const dispatch = useDispatch();
    const { loginModal, registrationModal } = useAppSelector((state: RootState) => state.window.signUpWrapper);
    const showModal = loginModal || registrationModal;
    const { isIframe } = useAppSelector((state: RootState) => state.window);

    const closeModal = () => {
        dispatch(setSignUpWrapper(false, false, false));
    };

    return (
        <Modal
            open={showModal}
            onClose={closeModal}
            className={classNames("login-registration-modal", {
                "login-registration-modal--login": loginModal,
                "login-registration-modal--registration": registrationModal,
            })}
            onOpen={() => sendMessage({ event: "scrollToTop" })}
            {...(isIframe && { fromTop: "250px" })}
            closeOnOutsideClick={false}
        >
            {loginModal && <Login />}
            {registrationModal && <Registration />}
        </Modal>
    );
};
