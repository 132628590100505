import { Button, Heading } from "@r360/library";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
    getNewOrModifiedCartProducts,
    hasBookstat,
    isAccommodation,
    setAdditionalOnBookedAccommodationProductExtras,
} from "../../../BusinessUtils";
import { formatPrice, getCartItemPrice } from "../../../Helper";
import { checkoutUrlSelector } from "../../../Selectors";
import useAppSelector from "../../../hooks/useAppSelector";
import useTranslate from "../../../hooks/useTranslate";
import { TCartItem, TGeneralAges, TReservationType } from "../../../store/types";
import { useSelector } from "react-redux";
import { agesSelector } from "../../../selectors/Selectors";
import "./CartSummary.scss";

export const CartSummary = () => {
    const navigate = useNavigate();
    const t = useTranslate();
    const currency = useAppSelector(state => state.clientData?.options?.general?.currency);
    const generalAges: TGeneralAges = useSelector(agesSelector);

    const reservationTypes = useAppSelector(state => state.clientData.reservationtypes).filter(
        (reservationType: TReservationType) => !reservationType.hidden
    );
    const checkoutUrl = useAppSelector(checkoutUrlSelector);

    const cartProducts = (useAppSelector(state => state.checkoutCart?.products) || []).map((product: TCartItem) =>
        setAdditionalOnBookedAccommodationProductExtras(product)
    );
    const newOrModifiedCartProducts = getNewOrModifiedCartProducts(cartProducts);

    const newOrModifiedAccommodationCartProducts = newOrModifiedCartProducts.filter(isAccommodation);

    let totalPrice = 0;

    if (newOrModifiedCartProducts.length === 0) {
        return null;
    }

    // Add values to variables
    newOrModifiedCartProducts.forEach((item: TCartItem) => {
        totalPrice += getCartItemPrice(item, generalAges) ?? 0;
    });

    // Get all extras for accommodations in cart.
    const accommodationExtrasToSum = newOrModifiedAccommodationCartProducts
        .flatMap((accommodation: TCartItem) => accommodation.info?.extras || [])
        .filter((extra: TCartItem) => !hasBookstat(extra) || extra.additional);

    // Add costs for accommodation extras
    const accommodationExtrasToSumFiltered = accommodationExtrasToSum.filter((extra: TCartItem) => {
        if (extra.rules.mandatory_web) {
            return false;
        } else {
            return (extra.quantity !== 0 && extra.rules.max_qty !== 0) || extra.checked === true;
        }
    });

    accommodationExtrasToSumFiltered.forEach((item: TCartItem) => {
        totalPrice += item.additional?.price || item.price;
    });

    return (
        <div className="cart-summary-wrapper">
            <Heading type="h5">{t("book.cart.summary.title")}</Heading>
            <div className="cart-summary">
                <div className="cart-summary-list">
                    {reservationTypes.map((reservationType: TReservationType) => {
                        const items = newOrModifiedCartProducts.filter(
                            (item: TCartItem) => item.type === reservationType.lid && !item.connectedProductId,
                            10
                        );
                        const cartItemIds: (number | string)[] = items.map((item: TCartItem) => item.cartItemId);
                        const extras = accommodationExtrasToSumFiltered.filter(
                            (item: TCartItem) => item.type === reservationType.lid
                        );

                        // Skipass extras.
                        const connectedProducts = newOrModifiedCartProducts.filter(
                            (item: TCartItem) =>
                                item.connectedProductId && cartItemIds.includes(item.connectedProductId)
                        );
                        const sum = [...items, ...extras, ...connectedProducts].reduce(
                            (acc: number, item: TCartItem) => {
                                const cartItemPrice = getCartItemPrice(item, generalAges);
                                if (cartItemPrice !== null) return cartItemPrice + acc;
                                return acc;
                            },
                            0
                        );
                        if (items.length === 0) {
                            return null;
                        }
                        return (
                            <div key={reservationType.lid} className="cart-summary-list-row">
                                <div>{reservationType.description}</div>
                                <div>{formatPrice(sum, currency)}</div>
                            </div>
                        );
                    })}
                </div>
                <div className="cart-summary-bottom">
                    <div className="cart-summary-bottom-price">
                        <Heading type="h5">{t("book.cart.summary.total_price")}</Heading>
                        <Heading type="h5">{formatPrice(totalPrice, currency)}</Heading>
                    </div>
                    <Button
                        onClick={() => {
                            navigate(checkoutUrl);
                        }}
                    >
                        {t("book.cart.summary.goto_checkout")}
                    </Button>
                </div>
            </div>
        </div>
    );
};
