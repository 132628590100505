import * as React from "react";
import defautIconStats from "../Icon.types";

const CloseInverted = ({ size = defautIconStats.size, iconClass = defautIconStats.width }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            style={{ height: size, width: size }}
            width="512pt"
            height="512pt"
            className={iconClass}
        >
            <ellipse fill="#E04F5F" cx="256" cy="256" rx="256" ry="255.832" />
            <g transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 77.26 32)">
                <rect fill="#FFFFFF" x="3.98" y="-427.615" width="55.992" height="285.672" />
                <rect fill="#FFFFFF" x="-110.828" y="-312.815" width="285.672" height="55.992" />
            </g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
        </svg>
    );
};

export default CloseInverted;
