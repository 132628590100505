import { Formik, FormikProps } from "formik";
import React, { forwardRef, useImperativeHandle, useRef } from "react";
import * as yup from "yup";
import { isNumberWithoutDecimals } from "../../Helper";
import { Input } from "@r360/library";
import { isFieldValid } from "./PersonalInformation";
import useTranslate from "../../hooks/useTranslate";

type TPersonalRentalInformation = {
    weight: number;
    height: number;
    shoesize: number;
    headsize: number;
    onSubmit?: (arg0: TPersonalRentalForm) => void;
    disabled?: boolean;
    showWeight?: boolean;
    showHeight?: boolean;
    showShoesize?: boolean;
    showHeadsize?: boolean;
};

export type TPersonalRentalForm = {
    weight: number;
    height: number;
    shoesize: number;
    headsize: number;
};

const PersonalRentalInformation = forwardRef(
    (
        {
            weight,
            height,
            shoesize,
            headsize,
            onSubmit,
            disabled,
            showWeight,
            showHeight,
            showShoesize,
            showHeadsize,
        }: TPersonalRentalInformation,
        ref
    ) => {
        const t = useTranslate();
        const formikRef = useRef<FormikProps<TPersonalRentalForm>>(null);

        useImperativeHandle(ref, () => ({
            submitForm: formikRef.current?.submitForm,
            resetForm: formikRef.current?.resetForm,
            resetTouched: () => formikRef.current?.setTouched({}),
        }));

        const validationSchema = yup.object().shape({
            ...(showWeight && { weight: yup.number() }),
            ...(showHeight && { height: yup.number() }),
            ...(showShoesize && { shoesize: yup.number() }),
            ...(showHeadsize && { headsize: yup.number() }),
        });

        const customHandleSubmit = () => {
            formikRef.current && onSubmit?.(formikRef.current.values);
        };

        return (
            <>
                <Formik
                    innerRef={formikRef}
                    validationSchema={validationSchema}
                    onSubmit={customHandleSubmit}
                    initialValues={
                        {
                            ...(showWeight && { weight: weight || "" }),
                            ...(showHeight && { height: height || "" }),
                            ...(showShoesize && { shoesize: shoesize || "" }),
                            ...(showHeadsize && { headsize: headsize || "" }),
                        } as TPersonalRentalForm
                    }
                >
                    {({ handleSubmit, handleChange, handleBlur, values, touched, errors }) => {
                        return (
                            <>
                                <form className="row u-bs-gutters-6 u-mb-12" onSubmit={handleSubmit} noValidate>
                                    {showWeight && (
                                        <div className="col-6 col-xl-3 u-mb-12 u-mb-xl-0">
                                            <Input
                                                label={`${t("book.general.weight")} (${t("book.general.kilogram")})`}
                                                required
                                                name="weight"
                                                value={values.weight}
                                                isValid={
                                                    !values.weight
                                                        ? undefined
                                                        : isFieldValid(touched.weight, errors.weight)
                                                }
                                                onChange={e => {
                                                    if (isNumberWithoutDecimals(e.target.value)) {
                                                        handleChange(e);
                                                    }
                                                }}
                                                onBlur={handleBlur}
                                                disabled={disabled}
                                                labelNoWrap
                                            />
                                        </div>
                                    )}
                                    {showHeight && (
                                        <div className="col-6 col-xl-3 u-mb-12 u-mb-xl-0">
                                            <Input
                                                label={`${t("book.general.height")} (${t("book.general.centimeter")})`}
                                                required
                                                name="height"
                                                value={values.height}
                                                isValid={
                                                    !values.height
                                                        ? undefined
                                                        : isFieldValid(touched.height, errors.weight)
                                                }
                                                onChange={e => {
                                                    if (isNumberWithoutDecimals(e.target.value)) {
                                                        handleChange(e);
                                                    }
                                                }}
                                                onBlur={handleBlur}
                                                disabled={disabled}
                                                labelNoWrap
                                            />
                                        </div>
                                    )}
                                    {showShoesize && (
                                        <div className="col-6 col-xl-3 u-mb-12 u-mb-xl-0">
                                            <Input
                                                label={`${t("book.general.shoesize")} (eu)`}
                                                required
                                                name="shoesize"
                                                value={values.shoesize}
                                                isValid={
                                                    !values.shoesize
                                                        ? undefined
                                                        : isFieldValid(touched.shoesize, errors.shoesize)
                                                }
                                                onChange={e => {
                                                    if (isNumberWithoutDecimals(e.target.value)) {
                                                        handleChange(e);
                                                    }
                                                }}
                                                onBlur={handleBlur}
                                                disabled={disabled}
                                                labelNoWrap
                                            />
                                        </div>
                                    )}
                                    {showHeadsize && (
                                        <div className="col-6 col-xl-3 u-mb-12 u-mb-xl-0">
                                            <Input
                                                label={`${t("book.general.headsize")} (${t(
                                                    "book.general.centimeter"
                                                )})`}
                                                required
                                                name="headsize"
                                                value={values.headsize}
                                                isValid={
                                                    !values.headsize
                                                        ? undefined
                                                        : isFieldValid(touched.headsize, errors.headsize)
                                                }
                                                onChange={e => {
                                                    if (isNumberWithoutDecimals(e.target.value)) {
                                                        handleChange(e);
                                                    }
                                                }}
                                                onBlur={handleBlur}
                                                disabled={disabled}
                                                labelNoWrap
                                            />
                                        </div>
                                    )}
                                </form>
                            </>
                        );
                    }}
                </Formik>
            </>
        );
    }
);

PersonalRentalInformation.displayName = "PersonalRentalInformation";

export default PersonalRentalInformation;
