import React from "react";
import { getNewOrModifiedCartProducts, setAdditionalOnBookedAccommodationProductExtras } from "../../../BusinessUtils";
import useAppSelector from "../../../hooks/useAppSelector";
import { TCartItem, TReservationType } from "../../../store/types";
import { Heading } from "@r360/library";
import CartItem from "./CartItem";
import "./CartItemsList.scss";
import partition from "lodash/partition";
import sortBy from "lodash/sortBy";
import { cartProductsSelector } from "../../../selectors/Selectors";

export const CartItemsList = () => {
    const cartProducts = useAppSelector(cartProductsSelector).map(product =>
        setAdditionalOnBookedAccommodationProductExtras(product)
    );
    const [newOrModifiedCartProducts, newOrModifiedCartConnectedProducts] = partition(
        getNewOrModifiedCartProducts(cartProducts),
        item => !item.connectedProductId
    );
    const reservationTypes = useAppSelector(state => state.clientData.reservationtypes).filter(
        (reservationType: TReservationType) => !reservationType.hidden
    );

    const renderReservationTypeItems = (resvType: TReservationType, key: number) => {
        const items = sortBy(
            newOrModifiedCartProducts.filter((item: TCartItem) => item.type === resvType.lid, 10),
            item => item.cartItemAddedTimestamp
        );
        const accommodationItems = items.filter(item => item.kind === "ACCOMMODATION");
        const totalItems = items.reduce((acc: number, item: TCartItem) => {
            if (accommodationItems.length > 0) {
                return accommodationItems.length;
            } else {
                return acc + item.count || item.quantity || 0;
            }
        }, 0);

        if (items.length === 0) {
            return null;
        }

        return (
            <div className="cart-items-list-category" key={key}>
                <Heading type="h5">
                    {resvType.description} ({totalItems})
                </Heading>
                {items.map((item: TCartItem, index: number) => {
                    return (
                        <CartItem
                            key={index}
                            item={item}
                            connectedProducts={newOrModifiedCartConnectedProducts.filter(
                                connectedProduct => connectedProduct.connectedProductId === item.cartItemId
                            )}
                        />
                    );
                })}
            </div>
        );
    };

    return (
        <div className="cart-items-list">
            {reservationTypes?.length > 0 &&
                reservationTypes.map((reservationType: TReservationType) => {
                    return renderReservationTypeItems(reservationType, reservationType.lid);
                })}
        </div>
    );
};
