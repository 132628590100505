import React from "react";

import { Icon } from "../";
import "./InformationBlock.scss";

/**
 * Information block used to show additional help information, usually displayed with a yellow circle/point icon.
 *
 * Usage:
 * <InformationBlock></InformationBlock>    // Does not render empty/undefined
 * <InformationBlock> </InformationBlock>   // Does not render white space
 * <InformationBlock>\n</InformationBlock>  // Does not render white space
 * <InformationBlock>Hello world</InformationBlock>         // Renders the string
 * <InformationBlock><p> </p></InformationBlock>            // Renders the JSX even if it contains empty string
 * <InformationBlock><p>Hello world</p></InformationBlock>  // Renders the JSX
 */
const InformationBlock = ({ children, className }) => {
    const classes = className ? `information-block ${className}` : "information-block";

    // Do not render if we add an empty string or string that contains only whitespace characters.
    // Always render if we add HTML/JSX. Then children has type object.
    if (typeof children === "undefined" || (typeof children === "string" && children.trim() === "")) {
        // Do not render
        return null;
    }

    return (
        <div className={classes}>
            <div className="information-block__icon-container">
                <Icon name="FaRegDotCircle" size={16} color="#f1c40f" className="information-block__icon" />
            </div>
            <div className="information-block__text">{children}</div>
        </div>
    );
};

export default React.memo(InformationBlock);
