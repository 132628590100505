import { debounce } from "lodash";
import { useCallback } from "react";

/**
 * Debounce a function call to only execute the function if it has not been called for n delay number of milliseconds.
 *
 * The last function call will be executed. All previous calls will be discarded and not executed.
 *
 * # Example: If you want to track page views and want to delay the tracking until the application is finished redirecting the user
 * you can use a debouncer. The debouncer allows us to track only the last redirected url.
 *
 * Our win here is that we won't track the intermediate redirections, only the last redirect is tracked, IF everything happens within the
 * specified delay.
 *
 * See lodash.debounce for documentation about debounce
 *
 * # Usage example:
 * const debouncedPageView = useDebounce(
 *       path => {
 *           console.log("This log is executed only if no other call to debouncedPageView has been done within 2000 ms ", path);
 *       },
 *       2000,
 *       []
 * );
 *
 * useEffect(() => {
 *        console.log("This log is executed every time location.pathname changes", location.pathname);
 *        debouncedPageView(location.pathname);
 * }, [debouncedPageView, location.pathname]);
 *
 * @param {function} effectFn The function you want to execute
 * @param {number} delay In milliseconds
 * @param {array} dependencies
 * @returns
 */
const useDebounce = (effectFn, delay, dependencies = []) => {
    // eslint-disable-next-line
    const debouncedFn = useCallback(debounce(effectFn, delay), dependencies);

    return debouncedFn;
};

export default useDebounce;
