import { signal } from ".";
import { SIGNAL_PAGE_VIEW } from "./types";

/**
 * Create a tracking signal which may be used to track page views.
 *
 * The signal will contain the page and location
 *
 * @returns {object} Signal
 */
export const createPageViewSignal = pathname => {
    const theSignal = signal(SIGNAL_PAGE_VIEW, {
        page: pathname,
        location: window.location.toString(),
    });

    return theSignal;
};
