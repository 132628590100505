import classnames from "classnames";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { showArrDepDates } from "../BusinessUtils";
import ChangeSearch from "../components/ChangeSearch/ChangeSearch";
import ProductGallery from "../components/ProductGallery/ProductGallery";
import ProductImagesGallery from "../components/ProductImagesGallery/ProductImagesGallery";
import { Icon, Modal } from "../components/UI";
import { CalendarIcon, GuestIcon } from "../components/UI/R360Icons";
import ReadMoreText from "../components/UI/ReadMoreText";
import * as Constants from "../Constants";
import { formatPrice } from "../Helper";
import useChangeSearch from "../hooks/useChangeSearch";
import { optionsSelector } from "../selectors/Selectors";
import { addProduct } from "../store/actions/checkoutCart";
import useAppSelector from "../hooks/useAppSelector";
import { textsSelector } from "../Selectors";

let addedToCartTimeout;

// PackagePage page component
const PackagePage = () => {
    const navigate = useNavigate();
    const routeParams = useParams();
    const dispatch = useDispatch();
    const location = useLocation();
    const reservationTypeid = parseInt(routeParams.reservationTypeid, 10);
    const poollid = parseInt(routeParams.poollid, 10);
    const isMobile = useSelector(state => state.window.isMobile);
    const clientData = useSelector(state => state.clientData);
    const texts = useSelector(textsSelector);
    const reservationTypes = useSelector(state => state.clientData.reservationtypes);
    const reservationResult = useSelector(state => state.reservationResult);
    const calendarData = useSelector(state => state.clientData?.calendarData);
    const ages = JSON.parse(clientData?.options.general.ages);
    const [product, setProduct] = useState();
    const [images, setImages] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const reservationTypeResult = reservationResult[reservationTypeid];
    const showImageDescriptions = useSelector(optionsSelector).layout?.show_image_descriptions || false;
    const [showAddedToCartForProductId, setShowAddedToCartForProductId] = useState();
    const lang = useAppSelector(state => state.clientData?.lang);

    useChangeSearch({
        reservationResultSearch: reservationTypeResult?.search,
        reservationTypeId: reservationTypeid,
        skipGuestsOnSearchWithPrice: false,
        allowSearchWithoutPrice: true,
    });

    useEffect(() => {
        return () => clearTimeout(addedToCartTimeout);
    }, []);

    useEffect(() => {
        // Validate age categories for selected reservation type
        if (reservationTypeid && reservationTypes && ages) {
            const reservationType = reservationTypes.find(type => {
                return Number(type.lid) === reservationTypeid;
            });

            // Some reservation types do not require age categories
            const requireAgeCategories = [Constants.productTypeNames.PACKAGE].includes(reservationType?.type);

            if (!requireAgeCategories) {
                return;
            }

            if (!(reservationTypeid in ages)) {
                throw new Error(`Faulty setup: Missing age categories for reservation type ${reservationTypeid}`);
            }
        }
    }, [reservationTypeid, reservationTypes, ages]);

    // Handle click on more info button
    const handleProductGalleryClick = () => {
        setShowModal(!showModal);
    };

    useEffect(() => {
        // Check if any search results exists.
        if (reservationTypeResult) {
            const packageProduct = Object.values(reservationTypeResult.groups).find(group => group.poollid === poollid);

            if (packageProduct) {
                setProduct(packageProduct);

                if (packageProduct.images.length !== 0) {
                    const packageImages = packageProduct.images.map(image => ({
                        thumbnail: image.url,
                        original: image.url,
                        text: image?.translatedDesc ? image?.translatedDesc[lang] : "",
                    }));

                    setImages(packageImages);
                }
            } else {
                navigate("/search/" + reservationTypeid, { replace: true, state: { redirect: true } });
                setProduct();
            }
        }

        // TODO: R360ONL-741 Fix dependencies for useEffect related to searches.
        // eslint-disable-next-line
    }, [reservationTypeResult]);

    const goBackURL = () => {
        let path = "/search",
            search = "";

        if (reservationTypeid) {
            path = `${path}/${reservationTypeid}`;
        }

        if (location?.search) {
            search = location.search;
        }

        return `${path}${search}`;
    };

    const handleShowAllOffering = () => {
        navigate(`/package/${reservationTypeid}/${poollid}?checkPrice=0`);
    };

    const showPrice = (price, currency) => {
        return `${formatPrice(price, currency)}`;
    };

    const addProductToCart = item => {
        const generalAges = JSON.parse(clientData.options?.general?.ages);

        const newItem = {
            info: {
                guests: {
                    totalGuests: reservationTypeResult?.search.guests.length || 0,
                },
            },
            ...product,
            ...item,
        };

        generalAges[newItem.type].forEach(age => {
            newItem.info.guests[age.key] =
                reservationTypeResult?.search?.guests.filter(guestAge => guestAge >= age.min && guestAge <= age.max)
                    .length || 0;
        });

        setShowAddedToCartForProductId(item.id);
        dispatch(addProduct(newItem));

        clearTimeout(addedToCartTimeout);
        addedToCartTimeout = setTimeout(() => setShowAddedToCartForProductId(), 2000);
    };

    const primaryColor = "#0D3E60";

    const renderProducts = () => {
        if (!product) return null;

        return product.items.map(item => {
            const guestCount = (reservationTypeResult?.search.guests || []).length;
            const checkPriceAndAvailability = reservationTypeResult?.search.checkPriceAndAvailability;

            return (
                <div
                    style={{
                        border: "1px solid #DADADA",
                        borderRadius: "8px",
                        marginBottom: "0.75rem",
                        padding: "1rem",
                        backgroundColor: "#F8F8F8",
                    }}
                    key={item.id}
                >
                    <Row className="align-items-center">
                        <Col md={4} className="d-flex align-items-center">
                            <CalendarIcon size={14} color={primaryColor} />
                            <span className="ms-2">{showArrDepDates(item)}</span>
                        </Col>
                        {checkPriceAndAvailability && (
                            <>
                                <Col md={3} className={classnames({ "text-end": !isMobile })}>
                                    <GuestIcon size={14} color={primaryColor} iconClass="me-2" />
                                    {guestCount} {guestCount === 1 ? texts?.["generalguest"] : texts?.["generalguests"]}
                                </Col>
                                <Col
                                    xs={6}
                                    md={2}
                                    className={classnames({ "text-end": !isMobile, "mt-2": isMobile })}
                                    style={{ fontWeight: 500, color: primaryColor }}
                                >
                                    {showPrice(item.price, product.curr)}
                                </Col>
                                <Col xs={6} md={3} className="text-end">
                                    {showAddedToCartForProductId === item.id ? (
                                        <Button className="button" disabled size="sm" style={{ whiteSpace: "nowrap" }}>
                                            {texts["product.added_to_cart"]}
                                        </Button>
                                    ) : (
                                        <Button
                                            className="button"
                                            onClick={() => addProductToCart(item)}
                                            size="sm"
                                            style={{ whiteSpace: "nowrap" }}
                                        >
                                            {texts?.reservationbutton}
                                        </Button>
                                    )}
                                </Col>
                            </>
                        )}
                    </Row>
                </div>
            );
        });
    };

    // Render
    if (product) {
        const format = "ddd D MMM";
        const arrdate = moment(reservationTypeResult?.search?.arrdate);
        const depdate = moment(reservationTypeResult?.search?.depdate);

        return (
            <>
                <section>
                    <div className="container">
                        <Link
                            text={texts?.topackageoverview}
                            className="btn button button--text-only back-link"
                            to={goBackURL()}
                        >
                            <Icon name="FaArrowLeft" color="#0d3e60" size={16} />
                            <span className="back-link__text">{texts?.topackageoverview}</span>
                        </Link>

                        <ProductGallery images={images} />

                        <div style={{ textAlign: "right", height: "2rem", paddingTop: "0.5rem" }}>
                            {images.length > 2 && (
                                <a className="link" onClick={handleProductGalleryClick}>
                                    {texts?.showallpictures}
                                </a>
                            )}
                        </div>
                    </div>
                    <Modal show={showModal} setShow={setShowModal} size="lg">
                        <ProductImagesGallery images={images} showImageDescriptions={showImageDescriptions} />
                    </Modal>
                </section>
                <section className="container">
                    <div className="row">
                        <div className="col-md-12 col-lg-12">
                            <h1 className="page-header pb-0">{product.title}</h1>
                        </div>
                        {product.weblong !== "" && (
                            <div className="col-md-12 col-lg-12" style={{ paddingTop: "1rem" }}>
                                <ReadMoreText
                                    lines={3}
                                    moreText={texts?.showmore}
                                    lessText={texts?.showless}
                                    anchorClass="link"
                                    html={true}
                                    whiteSpacePreLine={true}
                                >
                                    {product.weblong}
                                </ReadMoreText>
                            </div>
                        )}
                    </div>
                </section>
                {!isMobile && (
                    <section className="container mt-5 mb-5">
                        <div className="row mb-3">
                            <div className="col" style={{ display: "flex", alignItems: "center" }}>
                                <h4 className="mb-0 me-5">{texts["package.available_packages"]}</h4>
                                {reservationTypeResult?.search?.checkPriceAndAvailability && (
                                    <a className="link ms-5" onClick={handleShowAllOffering}>
                                        {texts["search.show_all_offering"]}
                                    </a>
                                )}
                            </div>
                        </div>
                        {calendarData && reservationTypes.length > 0 && (
                            <>
                                <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
                                    <div className="me-2" style={{ flexShrink: 0 }}>
                                        <ChangeSearch
                                            texts={texts}
                                            resvType={reservationTypeid}
                                            showRange
                                            isMobile={isMobile}
                                            reservationResultSearch={reservationTypeResult?.search}
                                            reservationTypeid={reservationTypeid}
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                    </section>
                )}
                {isMobile && (
                    <section className="container">
                        <div style={{ textAlign: "left", paddingTop: "2rem" }}>
                            <h4>{texts["package.available_packages"]}</h4>
                        </div>
                        <div
                            className="mb-3"
                            style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
                        >
                            {reservationTypeResult?.search?.checkPriceAndAvailability && (
                                <>
                                    <h6 className="m-0">{`${arrdate.format(format)} - ${depdate.format(format)}`}</h6>
                                    <a className="link ms-5" onClick={handleShowAllOffering}>
                                        {texts["search.show_all_offering"]}
                                    </a>
                                </>
                            )}
                        </div>
                        {calendarData && reservationTypes.length > 0 && (
                            <>
                                <div className="mb-4">
                                    <ChangeSearch
                                        texts={texts}
                                        resvType={reservationTypeid}
                                        showRange
                                        isMobile={isMobile}
                                        reservationResultSearch={reservationTypeResult?.search}
                                        reservationTypeid={reservationTypeid}
                                    />
                                </div>
                            </>
                        )}
                    </section>
                )}
                <section className="container">
                    <div className="row">
                        <div className="col-md-12">{renderProducts()}</div>
                    </div>
                </section>
            </>
        );
    }

    return null;
};

export default PackagePage;
