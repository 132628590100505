import { roundBy, formatNumber } from "../Helper";

/**
 * Filter group items
 * @returns {Object} — group object with filtered items
 */
export const filterGroupItems = (group, activeFilters, filterCriterias, availableFilters, showAgeFilter = true) => {
    if (showAgeFilter) {
        filterCriterias = { 5008: { ...availableFilters[5008], min: 0, max: 0 }, ...filterCriterias };
    }
    const filteredGroup = { ...group };

    // Filter out group items that match active filter values
    filteredGroup.items = Object.values(group.items).filter(item => {
        return isFilterMatch(activeFilters, item.crits, filterCriterias);
    });
    // Get common filter criterias for filtered group items
    let commonCrits = filteredGroup.items.map(item => item.crits);
    commonCrits = commonCrits.length ? commonCrits : [commonCrits];
    filteredGroup.commonCrits = minMaxFilter(commonCrits, activeFilters, availableFilters);

    return filteredGroup;
};

/**
 * Compare active filters and item criterias
 * @returns {Boolean} — true if all filters match, else false
 */
export const isFilterMatch = (activeFilters, crits, filterCriterias) => {
    if (!activeFilters || typeof activeFilters === "undefined" || Object.keys(activeFilters).length === 0) {
        return true;
    }
    // debugger;
    for (const critcode in activeFilters) {
        if (critcode in activeFilters && critcode in crits) {
            if (criteriaMatch(crits[critcode], activeFilters[critcode], critcode, filterCriterias) === false)
                return false;
        } else {
            return false;
        }
    }
    return true;
};

/**
 * Match criteria value to active filter value
 * @returns {Boolean} — true if values match, else false
 */
export const criteriaMatch = (value, filterValue, critcode, filterCriterias) => {
    if (!(critcode in filterCriterias)) {
        return false;
    }
    const criteria = filterCriterias[critcode];

    if (criteria.type === "ENUM") {
        // An active enum was found in list of enums
        return filterValue.includes(value);
    } else if (criteria.displayAs === "range") {
        return value >= filterValue.min && value <= filterValue.max;
    } else if (criteria.displayAs === "bool") {
        return value === filterValue;
    } else if (typeof value === "number") {
        // Compare numeric value
        return value >= filterValue;
    }

    return false;
};

/**
 * Set value and placeholder for filter name
 * @returns {String} — combined value and placeholder text
 */
export const filterLabel = (filter, availableFilters) => {
    let criteria;

    if (availableFilters && filter.code in availableFilters) {
        criteria = availableFilters[filter.code];
    }

    if (criteria) {
        if (criteria.type === "ENUM") {
            // Strange behavior: multiple enums are set as min and max from BE??
            if (
                filter.max !== filter.min &&
                criteria.enums[filter.min] !== undefined &&
                criteria.enums[filter.max] !== undefined
            ) {
                const enumItem1 = criteria.enums[filter.min];
                const enumItem2 = criteria.enums[filter.max];
                return `${enumItem1.value}-${enumItem2.value} #S#`; // show without prepended title
            } else if (criteria.enums[filter.value] !== undefined) {
                const enumItem = criteria.enums[filter.value];
                return `${enumItem.value} #S#`; // show without prepended title
            }
        } else if (criteria.type === "MAX") {
            // distance
            if (criteria.typeunit === "m" && filter.value >= 1000) {
                return `#T# ${roundBy(filter.value / 1000)}km #S#`;
            } else {
                return `#T# ${filter.value}${criteria.typeunit} #S#`;
            }
        }
    }

    // Fallback
    switch (filter.type) {
        case "boolean":
            return `#T# #S#`;
        case "number":
            if (filter.max !== filter.min) {
                return `#T# ${formatNumber(filter.min)}-${formatNumber(filter.max)} #S#`;
            } else {
                return `#T# ${formatNumber(filter.value)} #S#`;
            }
        default:
            return `#T# ${filter.value} #S#`;
    }
};

/**
 * Set common filter criterias, min and max values
 * @returns {Object} — set of group common filter criterias
 */
export const minMaxFilter = (allfilters, activeFilters, availableFilters) => {
    let f = {};

    for (const i in allfilters) {
        const filters = allfilters[i];
        for (const y in filters) {
            const filter = filters[y];
            if (typeof f[y] === "undefined") f[y] = filter;
            if (f[y] === null || !f[y].type) {
                const _ty = f[y];
                f[y] = {};
                f[y].value = _ty;
                f[y].code = parseInt(y);
                f[y].type = typeof filter;
                if (f[y].type === "number") {
                    f[y].min = filter;
                    f[y].max = filter;
                }
            }
            if (f[y].min > filter) f[y].min = filter;
            if (f[y].max < filter) f[y].max = filter;
            if (f[y] === false && filter === true) f[y] = true;
        }
    }

    // Add filter label and active state
    for (const y in f) {
        f[y].label = filterLabel(f[y], availableFilters);
        if (activeFilters[y] !== undefined) {
            f[y].active = true;
        } else {
            f[y].active = false;
        }
    }
    return f;
};
