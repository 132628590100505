import React from "react";
import "./ImagePresentation.scss";
import { Button, GridIcon, OptimizedImage, getOptimizedImagePathFromUrl } from "@r360/library";
import useTranslate from "../../hooks/useTranslate";

const ImagePresentation = props => {
    const t = useTranslate();

    const {
        aspectRatioX,
        aspectRatioY,
        spacingXInPercent,
        spacingYInPercent,
        images,
        showModalButton,
        onShowModalButtonClick,
    } = props;
    const thumbnailsCount = images.length - 1;

    const largeImage = images[0];
    const thumbnails = images.slice(1, thumbnailsCount + 1);

    const getLargeImageWidth = () => {
        const ratioConstant = aspectRatioY / aspectRatioX;
        const spacingX = parseInt(spacingXInPercent, 10);
        const spacingY = parseInt(spacingYInPercent, 10);
        const imageCount = images.length;

        if (imageCount === 1) {
            return 100;
        }

        const thumbnailWidth =
            (100 - spacingX - 1 * (((imageCount - 2) * spacingY) / ratioConstant)) / (imageCount - 1 + 1);
        const largeImageWidth = 100 - spacingX - thumbnailWidth;

        return largeImageWidth;
    };

    const getLargeCss = () => {
        return {
            flex: `0 0 ${getLargeImageWidth()}%`,
        };
    };

    const getLargeItemCss = () => {
        return {
            paddingTop: `${(aspectRatioY / aspectRatioX) * 100}%`,
        };
    };

    const getThumbnailsCss = () => {
        return {
            flex: `0 0 calc(100% - ${getLargeImageWidth()}% - ${spacingXInPercent})`,
        };
    };

    const getThumbnailCss = () => {
        return {
            paddingTop: `${(aspectRatioY / aspectRatioX) * 100}%`,
        };
    };

    const renderShowModalButton = () => {
        return (
            <Button
                buttonClassName="image-presentation__show-modal-btn"
                type="secondary"
                buttonSize="small"
                onClick={onShowModalButtonClick}
                leftIcon={<GridIcon />}
            >
                {t("book.general.show_all_images")}
            </Button>
        );
    };

    const renderLargeImage = () => (
        <div className="image-presentation__large" style={getLargeCss()}>
            <div className="image-presentation__large-item">
                <OptimizedImage
                    aspectX={aspectRatioX}
                    aspectY={aspectRatioY}
                    path={getOptimizedImagePathFromUrl(largeImage)}
                />
                {showModalButton && images.length === 1 && renderShowModalButton()}
            </div>
        </div>
    );

    const renderThumbnails = () => (
        <div className="image-presentation__thumbnails" style={getThumbnailsCss()}>
            {thumbnails.map((image, index) => (
                <div key={index} className="image-presentation__thumbnails-item">
                    <OptimizedImage
                        aspectX={aspectRatioX}
                        aspectY={aspectRatioY}
                        path={getOptimizedImagePathFromUrl(image)}
                    />
                    {showModalButton && images.length > 1 && index === thumbnails.length - 1 && renderShowModalButton()}
                </div>
            ))}
        </div>
    );

    return (
        <div className="image-presentation">
            {renderLargeImage()}
            {renderThumbnails()}
        </div>
    );
};

export default ImagePresentation;
