import * as React from "react";
import Theme from "../../Theme";

const ArrowLeft = ({ color = Theme.palette.primary }) => {
    return (
        <svg width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.2106 8.35221L8.62822 0.934962C8.98584 0.577343 9.56597 0.577343 9.92359 0.934962L10.7888 1.80019C11.1461 2.15743 11.1464 2.73603 10.7903 3.09403L4.91159 8.99989L10.79 14.9061C11.1464 15.2641 11.1457 15.8427 10.7884 16.2L9.92321 17.0652C9.56559 17.4228 8.98546 17.4228 8.62784 17.0652L1.2106 9.64757C0.852978 9.28996 0.852978 8.70983 1.2106 8.35221Z"
                fill={color}
            />
        </svg>
    );
};

export default ArrowLeft;
