import { RootState } from "../../index";
import classNames from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import queryString from "query-string";
import moment from "moment-timezone";
import AxiosClient from "../../API/AxiosClient";
import { agesSelector } from "../../selectors/Selectors";
import { isEqual } from "lodash";
import { capitalizeFirstLetter, formatPrice } from "../../Helper";
import { TGroupedFilteredItem } from "../../pages/AccommodationAreaPage/AccommodationAreaPage";
import { TGeneralAge, TReservationResult } from "../../store/types";
import {
    ChevronLeftIcon,
    ChevronRightIcon,
    Heading,
    Skeleton,
    SkeletonGroup,
    Button,
    Tooltip,
    Notification,
    HistoryIcon,
    style,
    ChevronDownIcon,
    ChevronUpIcon,
} from "@r360/library";
import "./PriceCalendar.scss";
import useTranslate from "../../hooks/useTranslate";
import useAppSelector from "../../hooks/useAppSelector";

type TCalandarDataPrice = {
    fromDate: string;
    index: number;
    price: number | null;
    priceCode: string;
    toDate: string;
    selected?: boolean;
};

type TCalendarDataWeeks = {
    prices: TCalandarDataPrice[];
    week: number;
    weekEndDate: moment.Moment;
    weekStartDate: moment.Moment;
    year: string;
    mondayStartDate: string;
};

type TSpecialPeriod = {
    name: string;
    periods: TPeriod[];
};

type TPeriod = {
    price: number;
    priceCode: string;
    fromDate: string;
    toDate: string;
    selected?: boolean;
};

type TCalendarData = {
    nextWeeksMondayStartDate: string[];
    previousWeeksMondayStartDate: string[];
    specialPeriods: TSpecialPeriod[];
    weeks: TCalendarDataWeeks[];
};

type TPriceCalendar = {
    groupedItem: TGroupedFilteredItem;
    filteredItems: TGroupedFilteredItem[];
    resvType: number;
    reservationResultSearch: any;
};

const PriceCalendar = ({ groupedItem, filteredItems, resvType, reservationResultSearch }: TPriceCalendar) => {
    const navigate = useNavigate();
    const routeParams = useParams();
    const currency = useSelector((state: RootState) => state.clientData?.options?.general?.currency);
    const firstItem = groupedItem.items[0];
    const { grouppoollid, poollid, unitlid } = groupedItem;
    const isDesktop = useSelector((state: RootState) => state.window.isDesktop);
    const indexOfCurrentItem = filteredItems.findIndex(item => unitlid === item.unitlid);
    const generalAges = useSelector(agesSelector, isEqual);
    const ages: TGeneralAge[] = generalAges[resvType];
    const previousUnitLid = indexOfCurrentItem > 0 ? filteredItems[indexOfCurrentItem - 1].unitlid : null;
    const [calendarData, setCalendarData] = useState<TCalendarData | undefined>();
    const [ageParams, setAgeParams] = useState<any>(null);
    const [isSpecialPeriodsOpen, setIsSpecialPeriodsOpen] = useState(true);
    const nextUnitLid =
        filteredItems.length - 1 > indexOfCurrentItem ? filteredItems[indexOfCurrentItem + 1].unitlid : null;
    const loading = useSelector((state: RootState) => state.axiosStatus.loading);
    const t = useTranslate();
    const infoText = t("book.price_calendar.info");
    const [isInitialRender, setIsInitialRender] = useState(true);
    //Used for dates search result:
    const reservationResult = useAppSelector((state: RootState) => state.reservationResult);
    const reservationTypeId = parseInt(routeParams.reservationTypeid ?? "", 10);
    const accomodationResult = reservationResult[reservationTypeId] as TReservationResult;
    const [firstDate, setFirstDate] = useState("");

    const today = moment().format("YYYY-MM-DD");
    const hasSpecialPeriods = calendarData && calendarData.specialPeriods?.length > 0;

    useEffect(() => {
        const ages: TGeneralAge[] = generalAges[resvType];
        const defaultAgeValue = ages.find(age => age.description.includes("c5"))?.value;
        const defaultAges = [defaultAgeValue, defaultAgeValue];
        setAgeParams(reservationResultSearch?.guests?.length > 0 ? reservationResultSearch?.guests : defaultAges);
    }, [generalAges, reservationResultSearch?.guests, resvType]);

    const showPrice = (price: number) => {
        return `${formatPrice(price, currency)}`;
    };

    const getFirstDate = useCallback(() => {
        if (accomodationResult?.search.arrdate !== today && isInitialRender) {
            const twoWeeksBack = moment(accomodationResult?.search.arrdate).subtract(2, "weeks").format("YYYY-MM-DD");
            twoWeeksBack < today ? setFirstDate(today) : setFirstDate(twoWeeksBack);
        } else if (!firstDate) {
            setFirstDate(today);
        }
        return firstDate;
    }, [accomodationResult?.search.arrdate, firstDate, isInitialRender, today]);

    const fetchPrices = useCallback(
        (date = getFirstDate()) => {
            const url = `/calendars/prices/${resvType}/${poollid}/${unitlid}/${ageParams?.join(",")}/${date}`;

            ageParams !== null &&
                AxiosClient.get(url).then(response => {
                    const data = response?.data?.payload || {};
                    const newWeekData = {
                        ...data,
                        specialPeriods: data.specialPeriods.map(({ periods, ...rest }: any) => {
                            const periodsWithSelected = periods.map((period: TPeriod) => {
                                if (
                                    period.fromDate === accomodationResult?.search.arrdate &&
                                    period.toDate === accomodationResult?.search.depdate
                                ) {
                                    return { ...period, selected: true };
                                }
                                return period;
                            });
                            return { periods: periodsWithSelected, ...rest };
                        }),
                        weeks: data.weeks.map(({ mondayStartDate, prices, ...rest }: any) => {
                            const mondayStartDateMoment = moment(mondayStartDate);
                            const week = mondayStartDateMoment.isoWeek();
                            const year = mondayStartDateMoment.format("YYYY");
                            const pricesWithSelected = prices.map((price: any) => {
                                //arrdat, depdate och price code
                                if (
                                    price.fromDate === firstItem.arrdate &&
                                    price.toDate === firstItem.depdate &&
                                    price.priceCode === firstItem.pricecode
                                ) {
                                    return { ...price, selected: true };
                                }
                                return price;
                            });

                            return {
                                ...rest,
                                prices: pricesWithSelected,
                                week,
                                year,
                                weekStartDate: mondayStartDateMoment,
                                weekEndDate: moment(mondayStartDate).add(6, "days"),
                            };
                        }) as TCalendarDataWeeks,
                    };
                    setCalendarData(newWeekData);
                });
        },
        [
            getFirstDate,
            resvType,
            poollid,
            unitlid,
            ageParams,
            accomodationResult?.search.arrdate,
            accomodationResult?.search.depdate,
            firstItem.arrdate,
            firstItem.depdate,
            firstItem.pricecode,
        ]
    );

    useEffect(() => {
        fetchPrices();
    }, [fetchPrices]);

    const onPreviousAccommodationClick = () => {
        setCalendarData(undefined);
        previousUnitLid &&
            navigate(`/product/${resvType}/${grouppoollid}/${poollid}/${previousUnitLid}/price_calendar`, {
                replace: true,
                state: { scrollToTop: false },
            });
    };
    const onNextAccommodationClick = () => {
        setCalendarData(undefined);
        nextUnitLid &&
            navigate(`/product/${resvType}/${grouppoollid}/${poollid}/${nextUnitLid}/price_calendar`, {
                replace: true,
                state: { scrollToTop: false },
            });
    };

    const onPriceClick = ({ priceCode, fromDate, toDate }: any) => {
        const queryStringObject: any = {}; // TODO type
        queryStringObject.startDate = fromDate;
        queryStringObject.endDate = toDate;
        queryStringObject.pricecode = priceCode;
        ageParams.forEach((value: any) => {
            const age = ages.find(age => age.value === value);

            if (!age) {
                return;
            }

            queryStringObject[age.key] = age.key in queryStringObject ? queryStringObject[age.key] + 1 : 1;
        });
        navigate(
            `/product/${resvType}/${grouppoollid}/${poollid}/${unitlid}?${queryString.stringify(queryStringObject)}`
        );
    };

    const onNextClick = () => {
        const hasNextWeeks = calendarData?.nextWeeksMondayStartDate.length;
        const firstAvailableDate = calendarData?.weeks[2]?.weekStartDate;
        if (hasNextWeeks) {
            setFirstDate(moment(firstAvailableDate).format("YYYY-MM-DD"));
            setIsInitialRender(false);
        }
        setCalendarData(undefined);
    };

    const hasBigGap = (index: number): boolean => {
        const weekNumbers = calendarData?.weeks?.map(week => week.week);
        const prevWeek = weekNumbers && weekNumbers[index - 1];
        if (weekNumbers && prevWeek) {
            return prevWeek < weekNumbers[index] - 1 ? true : false;
        }
        return false;
    };

    const onPreviousClick = () => {
        const hasPreviousWeeks = calendarData?.previousWeeksMondayStartDate.length;
        const firstAvailableDate =
            calendarData?.previousWeeksMondayStartDate.length === 1
                ? calendarData?.previousWeeksMondayStartDate.at(-1)
                : calendarData?.previousWeeksMondayStartDate.at(-2);
        if (hasPreviousWeeks) {
            setFirstDate(moment(firstAvailableDate).format("YYYY-MM-DD"));
            setIsInitialRender(false);
        }
        setCalendarData(undefined);
    };

    const isEmptyResults = () => {
        return calendarData?.weeks.length === 0;
    };

    const linkPreviousClassName = classNames("price-calendar-navigation-link", {
        disabled: !previousUnitLid,
    });
    const linkNextClassName = classNames("price-calendar-navigation-link", "price-calendar-navigation-link__next", {
        disabled: !nextUnitLid,
    });
    const priceCalendarTableClassName = classNames("price-calendar-desktop", {
        "price-calendar-with-special-periods": isDesktop && hasSpecialPeriods,
    });
    const tableHeadClassName = classNames("table-head", {
        "table-head-with-sepcial-periods": isDesktop && hasSpecialPeriods,
    });
    const showEarlierButtonClassName = classNames("price-calendar-show-earlier-button-container", {
        "price-calendar-show-earlier-button-container-with-special-periods": isDesktop && hasSpecialPeriods,
    });
    const specialPeriodsColumn = classNames("price-calendar-special-periods-column", {
        "price-calendar-special-periods-column-small-screens": !isDesktop && hasSpecialPeriods,
    });

    const getWeeksFromDates = (from: string, to?: string): string => {
        const earliestWeekNumber = moment(from, "YYYY-MM-DD").week();
        const latestWeekNumber = moment(to, "YYYY-MM-DD").week();
        return `${earliestWeekNumber}–${latestWeekNumber}`;
    };

    const renderSpecialPeriodButton = (prices: TCalandarDataPrice[]) => {
        const fromDate = prices.map(p => p.fromDate)[0];
        const toDate = prices.map(p => p.toDate).at(-1);

        const matchingSpecialPeriod =
            toDate &&
            fromDate &&
            calendarData?.specialPeriods
                ?.map(period => period.periods.filter(p => p.fromDate >= fromDate && p.toDate <= toDate))
                .flat();

        const periodName =
            matchingSpecialPeriod &&
            calendarData?.specialPeriods.find(p => p.periods.find(period => period === matchingSpecialPeriod[0]))?.name;

        if (matchingSpecialPeriod && periodName) {
            return (
                <div
                    className="price-calendar-special-period-button"
                    style={{ margin: isDesktop ? "3px 0 12px 0" : "12px 0 6px 0" }}
                    onClick={() =>
                        onPriceClick({
                            priceCode: matchingSpecialPeriod[0]?.priceCode,
                            fromDate: matchingSpecialPeriod[0]?.fromDate,
                            toDate: matchingSpecialPeriod[0]?.toDate,
                        })
                    }
                >
                    {matchingSpecialPeriod && <span>{periodName}: </span>}
                    <span>
                        {`${moment(matchingSpecialPeriod[0]?.fromDate).format("ddd")}
                            ${moment(matchingSpecialPeriod[0]?.fromDate).format("D/M")}
                        - `}
                    </span>
                    <span>
                        {`${moment(matchingSpecialPeriod[0]?.toDate).format("ddd")}
                            ${moment(matchingSpecialPeriod[0]?.toDate).format("D/M")}, `}
                    </span>
                    <br />
                    <span className="u-fw-medium">{showPrice(matchingSpecialPeriod[0]?.price)}</span>
                </div>
            );
        } else {
            return null;
        }
    };

    const renderWeekDates = (year: string, prices: TCalandarDataPrice[]) => {
        const earliestDate = prices.map(p => p.fromDate).sort()[0];
        const latestDate = prices
            .map(p => p.toDate)
            .sort()
            .at(-1);
        return (
            <>
                <div className="price-calendar-week-year-text">
                    {t("price_calendar.week")} {getWeeksFromDates(earliestDate, latestDate)}, {year}
                </div>
                <Tooltip
                    toolTipContent={
                        <div className="u-d-flex u-flex-column">
                            {prices.map((price, index: number) => {
                                return (
                                    <div key={index}>
                                        <span className="u-fw-medium">
                                            {`${capitalizeFirstLetter(moment(price.fromDate).format("ddd"))}-${moment(
                                                price.toDate
                                            ).format("ddd")}: `}
                                        </span>
                                        <span>
                                            {`${moment(price.fromDate).format("D/M")} - ${moment(price.toDate).format(
                                                "D/M"
                                            )}`}
                                        </span>
                                    </div>
                                );
                            })}
                        </div>
                    }
                    triggerOnClick
                >
                    <Button type="tertiary">{t("book.general.dates")}</Button>
                </Tooltip>
            </>
        );
    };

    return (
        <div className="price-calendar price-calendar--new-design">
            <div className="price-calendar-title-top">{t("book.price_calendar.title")}</div>
            <div className="price-calendar-navigation">
                <a className={linkPreviousClassName} onClick={onPreviousAccommodationClick}>
                    <ChevronLeftIcon />
                    {isDesktop && t("price_calendar.previous")}
                </a>
                <div className="price-calendar-title">
                    <Heading type="h2" styleAs="h4">
                        {firstItem.title}
                    </Heading>
                </div>
                <a className={linkNextClassName} onClick={onNextAccommodationClick}>
                    {isDesktop && t("price_calendar.next")}
                    <ChevronRightIcon />
                </a>
            </div>

            {isEmptyResults() && !loading ? (
                <i className="price-calendar-empty-result">{t("price_calendar.empty_result")}</i>
            ) : calendarData && Object.keys(calendarData).length > 0 ? (
                <>
                    <div className="u-d-flex justify-content-end align-items-center">
                        <div className="price-calendar-search-match-box"></div>
                        <span className="price-calendar-search-match-box-text">
                            {t("book.price_calendar.search_match")}
                        </span>
                    </div>
                    {!!infoText && (
                        <div className="price-calendar-notification-warning">
                            <Notification type="warning">{t("book.price_calendar.info")}</Notification>
                        </div>
                    )}
                    <div className="price-calendar-periods-container">
                        {hasSpecialPeriods && (
                            <aside className={specialPeriodsColumn}>
                                <div
                                    className="u-d-flex justify-content-between"
                                    onClick={() => !isDesktop && setIsSpecialPeriodsOpen(prevState => !prevState)}
                                >
                                    <Heading type={isDesktop ? "h5" : "h6"}>
                                        {t("book.price_calendar.special_periods")}
                                    </Heading>
                                    {calendarData?.specialPeriods?.length > 1 && !isDesktop && (
                                        <Button type="tertiary">
                                            {isSpecialPeriodsOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                                        </Button>
                                    )}
                                </div>
                                {isSpecialPeriodsOpen &&
                                    calendarData.specialPeriods.map((period: TSpecialPeriod, index: number) => (
                                        <div key={index} className="u-mt-12">
                                            <p className="u-fw-medium">{period.name}</p>
                                            {period.periods.map((p: TPeriod, index: number) => (
                                                <div key={index} style={{ marginTop: "-12px" }}>
                                                    <span className="price-calendar-special-periods-dates">
                                                        {`${capitalizeFirstLetter(
                                                            moment(p.fromDate).format("ddd")
                                                        )}-${moment(p.toDate).format("ddd")}: `}
                                                    </span>
                                                    <span className="price-calendar-special-periods-dates">
                                                        {`${moment(p.fromDate).format("D/M")} - ${moment(
                                                            p.toDate
                                                        ).format("D/M")}`}
                                                    </span>

                                                    <Button
                                                        buttonSize="small"
                                                        type="secondary"
                                                        buttonClassName={`price-calendar-price-button price-calendar-price-button__mobile ${
                                                            p.selected && firstDate
                                                                ? "price-calendar-price-button__selected"
                                                                : ""
                                                        }`}
                                                        onClick={() =>
                                                            onPriceClick({
                                                                priceCode: p.priceCode,
                                                                fromDate: p.fromDate,
                                                                toDate: p.toDate,
                                                            })
                                                        }
                                                    >
                                                        {showPrice(p.price)}
                                                    </Button>
                                                    {calendarData?.specialPeriods?.length > 1 && <hr />}
                                                </div>
                                            ))}
                                        </div>
                                    ))}
                            </aside>
                        )}
                        <div className={showEarlierButtonClassName}>
                            <Button
                                type="transparent"
                                fullWidth
                                buttonClassName="price-calendar-show-more-button"
                                leftIcon={<HistoryIcon color={style.brandBlueColor} />}
                                disabled={!calendarData?.previousWeeksMondayStartDate.length || firstDate <= today}
                                onClick={onPreviousClick}
                            >
                                {t("book.price_calendar.show_earlier_weeks")}
                            </Button>
                        </div>
                    </div>
                    {!isDesktop ? (
                        <div className="price-calendar">
                            {calendarData &&
                                Object.keys(calendarData)?.length > 0 &&
                                calendarData?.weeks.map(({ year, prices }, dataIndex: number) => {
                                    return (
                                        <div key={dataIndex}>
                                            {hasBigGap(dataIndex) && <hr style={{ height: "3px", marginTop: "3px" }} />}
                                            <div className="price-calendar-card">
                                                <div className="u-d-flex u-gap-18 u-mb-24 u-align-items-center">
                                                    {renderWeekDates(year, prices)}
                                                </div>
                                                <div className="price-calendar-card-body">
                                                    {prices.map(
                                                        (price, index) =>
                                                            price.price !== null && (
                                                                <div key={index} style={{ textAlign: "center" }}>
                                                                    {capitalizeFirstLetter(
                                                                        moment(price.fromDate)
                                                                            .format("dddd")
                                                                            .slice(0, 3)
                                                                    )}{" "}
                                                                    -{" "}
                                                                    {capitalizeFirstLetter(
                                                                        moment(price.toDate).format("dddd").slice(0, 3)
                                                                    )}
                                                                    <Button
                                                                        onClick={() => onPriceClick(price)}
                                                                        buttonClassName={`price-calendar-price-button price-calendar-price-button__mobile ${
                                                                            price.selected
                                                                                ? "price-calendar-price-button__selected"
                                                                                : ""
                                                                        }`}
                                                                    >
                                                                        {showPrice(price.price)}
                                                                    </Button>
                                                                </div>
                                                            )
                                                    )}
                                                </div>
                                                <div>{renderSpecialPeriodButton(prices)}</div>
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                    ) : (
                        <>
                            <table className={priceCalendarTableClassName}>
                                <thead className={tableHeadClassName}>
                                    <tr>
                                        <th>{t("price_calendar.date")}</th>
                                        {calendarData &&
                                            Object.keys(calendarData)?.length > 0 &&
                                            calendarData.weeks.flat()[0]?.prices.map(({ fromDate, toDate }, index) => (
                                                <th key={index}>
                                                    {capitalizeFirstLetter(moment(fromDate).format("dddd")).slice(0, 3)}{" "}
                                                    - {capitalizeFirstLetter(moment(toDate).format("dddd")).slice(0, 3)}
                                                </th>
                                            ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {calendarData &&
                                        Object.keys(calendarData)?.length > 0 &&
                                        calendarData.weeks.map(({ year, prices }, index: number) => (
                                            <tr
                                                key={index}
                                                style={{
                                                    borderTop: hasBigGap(index)
                                                        ? `4px solid ${style.darkGreyColor}`
                                                        : "",
                                                }}
                                            >
                                                <td>{renderWeekDates(year, prices)}</td>
                                                {prices.map((price, index) => (
                                                    <td key={index}>
                                                        <div className="price-calendar-price">
                                                            {price.price !== null && (
                                                                <Button
                                                                    onClick={() => onPriceClick(price)}
                                                                    type="secondary"
                                                                    fullWidth
                                                                    buttonSize="small"
                                                                    buttonClassName={`price-calendar-price-button ${
                                                                        price.selected
                                                                            ? "price-calendar-price-button__selected"
                                                                            : ""
                                                                    }`}
                                                                >
                                                                    {showPrice(price.price)}
                                                                </Button>
                                                            )}
                                                        </div>
                                                    </td>
                                                ))}
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </>
                    )}
                    <div className={showEarlierButtonClassName}>
                        <Button
                            type="transparent"
                            fullWidth
                            buttonClassName="price-calendar-show-more-button"
                            rightIcon={
                                <HistoryIcon className="price-calendar-forward-icon" color={style.brandBlueColor} />
                            }
                            onClick={onNextClick}
                            disabled={!calendarData?.nextWeeksMondayStartDate.length}
                        >
                            {t("book.price_calendar.show_later_weeks")}
                        </Button>
                    </div>
                </>
            ) : (
                <SkeletonGroup>
                    <div className="u-mb-24">
                        <Skeleton height="50px" />
                    </div>

                    {[...Array(7)].map((_item, i) => {
                        return (
                            <div key={i} className="u-d-flex u-gap-12 u-mb-30">
                                <Skeleton width="50%" />
                                <Skeleton height="40px" />
                                <Skeleton height="40px" />
                                <Skeleton height="40px" />
                                <Skeleton height="40px" />
                            </div>
                        );
                    })}
                </SkeletonGroup>
            )}
        </div>
    );
};

export default PriceCalendar;
