import React from "react";
import "./LoadingOverlay.scss";
import ClipLoader from "react-spinners/ClipLoader";
import { Fade } from "react-bootstrap";
import { Theme } from "../../../components/UI";
import { useAxiosLoader } from "../../../API/AxiosClient";
import { useSelector } from "react-redux";
import { optionsSelector } from "../../../selectors/Selectors";
import { useLocation } from "react-router-dom";

const LoadingOverlay = () => {
    const [loading] = useAxiosLoader();
    const location = useLocation();
    const layoutOptions = useSelector(optionsSelector).layout;

    const newDesignForReservationTypes =
        (location.pathname.includes("/search") ||
            location.pathname.includes("/cart") ||
            location.pathname.includes("/product")) &&
        layoutOptions?.new_design_menu &&
        layoutOptions?.new_design_accommodation &&
        layoutOptions?.new_design_activity &&
        layoutOptions?.new_design_letting &&
        layoutOptions?.new_design_package &&
        layoutOptions?.new_design_rental &&
        layoutOptions?.new_design_skipass;

    const newDesignLoginSignup =
        (layoutOptions?.new_design_login_signup && location.pathname.includes("/login")) ||
        location.pathname.includes("/registration");

    const newDesignForCheckout =
        layoutOptions?.new_design_checkout &&
        (location.pathname.includes("/checkout") ||
            location.pathname.includes("/fastcheckout") ||
            location.pathname.includes("/pay"));

    const newDesignForMyPages = layoutOptions?.new_design_my_pages && location.pathname.includes("/my-pages");

    if (newDesignForReservationTypes || newDesignLoginSignup || newDesignForCheckout || newDesignForMyPages) {
        return null;
    }

    return (
        <Fade in={loading} unmountOnExit={true}>
            <div
                style={{
                    position: "fixed",
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    backgroundColor: "rgba(255,255,255,0.6)",
                    zIndex: 99999,
                }}
            >
                <div
                    style={{
                        position: "absolute",
                        left: 0,
                        right: 0,
                        top: "45%",
                        marginLeft: "auto",
                        marginRight: "auto",
                        width: "100px",
                        textAlign: "center",
                        alignItems: "center",
                    }}
                >
                    <Fade in={loading}>
                        <ClipLoader size={60} color={Theme.palette.primary} loading={true} />
                    </Fade>
                </div>
            </div>
        </Fade>
    );
};

export default LoadingOverlay;
