import React, { useEffect, useRef, useState } from "react";
import { Accordion, Button, Container, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isAccommodation, isCancelled, isNew } from "../../BusinessUtils";
import Accommodation from "../../components/FillOutComponents/Accommodation/Accommodation";
import Activities from "../../components/FillOutComponents/Activities/Activities";
import BikeRental from "../../components/FillOutComponents/BikeRental/BikeRental";
import CustomToggle from "../../components/FillOutComponents/CustomToggle/CustomToggle";
import Guests from "../../components/FillOutComponents/Guests/Guests";
import Package from "../../components/FillOutComponents/Package/Package";
import SkiPass from "../../components/FillOutComponents/SkiPass/Skipass";
import SkiRental from "../../components/FillOutComponents/SkiRental/SkiRental";
import InformationConsent from "../../components/InformationConsent/OldInformationConsent";
import { Alert, alertDialog, Button as UIButton, Icon } from "../../components/UI";
import * as Constants from "../../Constants";
import { isEmpty } from "../../Helper";
import { guestsOnTravelSelector, optionsSelector, reservationTypesByTypeSelector } from "../../selectors/Selectors";
import {
    checkAuthenticationStatus,
    publishUpdatedUserData,
    updatedUserData,
    updateUserData,
} from "../../store/actions/account";
import {
    addedAllProducts,
    createNewCart,
    deleteProductFromCart,
    removeProduct,
    syncProductsInCart,
    updateProduct,
    updateProductWithCount,
} from "../../store/actions/checkoutCart";
import { fetchGuestsOnCart, fetchGuestsOnUser, syncGuestsOnCart, updatedAllGuests } from "../../store/actions/guests";
import * as types from "../../store/actions/types";
import {
    BikeIcon,
    ContactIcon,
    GuestIcon,
    HouseIcon,
    SkiIcon,
    SkiingIcon,
    TickIcon,
    WritingIcon,
} from "../../components/UI/R360Icons";
import { textsSelector } from "../../Selectors";

const CheckoutPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isMobile = useSelector(state => state.window.isMobile);
    const user = useSelector(state => state.account.user);
    const isAgent = !!user?.isAgent;
    const texts = useSelector(textsSelector);
    const clientData = useSelector(state => state.clientData);
    const agreements = useSelector(state => state.clientData.agreements);
    const userToken = useSelector(state => state.account.token);
    const allGuests = useSelector(state => state.guests.guests);
    const guestsOnTravel = useSelector(guestsOnTravelSelector);
    const checkoutError = useSelector(state => state.checkoutCart.error);
    const guestsError = useSelector(state => state.guests.error);
    const accountError = useSelector(state => state.account.error);
    const checkoutCartId = useSelector(state => state.checkoutCart.cartId);
    const primaryCartGuestId = useSelector(state => state.checkoutCart.primaryGuestId);
    const loginSuccessful = useSelector(state => state.account.loginSuccessful);
    const createdFromReservation = useSelector(state => state.checkoutCart.createdFromReservation);
    const addedAllProductsSucceed = useSelector(state => state.checkoutCart.addedAllProductsSucceed);
    const allGuestsUpdatedSuccessfully = useSelector(state => state.guests.allGuestsUpdatedSuccessfully);
    const successfullyUpdatedUserData = useSelector(state => state.account.successfullyUpdatedUserData);
    const [requestData, setRequestData] = useState([]);
    const cartSummary = useSelector(state => state.checkoutCart.summary);
    const [checkoutFinished, setCheckoutFinished] = useState(false);
    const [didFetchGuestsOnUser, setDidFetchGuestsOnUser] = useState(false);
    const [totalGuestsCount, setTotalGuestsCount] = useState(0);
    const [activeKey, setActiveKey] = useState(1); // 0 can't be used as it returns null in the Accordion context.
    const [validated, setValidated] = useState({ 0: false });
    const [alertMessage, setAlertMessage] = useState({ 0: false });
    const [showCompleteIcon, setShowCompleteIcon] = useState({ 1: false });
    const reservationTypes = useSelector(state => state.clientData.reservationtypes);
    const allProducts = useSelector(state => state.checkoutCart.products);
    const products = allProducts.filter(product => !isCancelled(product));
    const skipGuestsOnAccommodation = useSelector(optionsSelector).reservation?.skipguestsonaccommodation || false;
    const [stepErrors, setStepErrors] = useState({});
    const newProducts = products.filter(isNew);
    const loadingRequest = useSelector(state => state.axiosStatus?.loading);

    const accommodationTypes = useSelector(state =>
        reservationTypesByTypeSelector(state, Constants.productTypeNames.ACCOMMODATION)
    );
    const accommodationLids = accommodationTypes.map(x => parseInt(x.lid));

    const accommodations = newProducts.filter(product => accommodationLids.includes(parseInt(product.type)));

    const notAccommodations = products.filter(product => !accommodationLids.includes(parseInt(product.type)));

    const onlyPrimaryGuest = skipGuestsOnAccommodation && !notAccommodations.length;

    const uniqueProductTypes = [
        ...new Set(
            [...accommodations, ...notAccommodations]
                // Keep products that are not booked, show newly selected products
                .filter(product => !product.detlid)
                .map(product => parseInt(product.type))
        ),
    ];

    const firstFormIndex = 1;
    const lastFormIndex = uniqueProductTypes.length + firstFormIndex + 1;

    const ICON_SIZE = 30;

    useEffect(() => {
        dispatch(checkAuthenticationStatus(userToken));
    }, [dispatch, userToken, loginSuccessful]);

    // Redirect customer to search page if no products exists in the cart
    useEffect(() => {
        if (allProducts && allProducts.length === 0) {
            console.log("Checkout: Cart is empty, redirect back to search page"); // KEEP: Useful for debugging in log rocket, because some times the cart is empty
            alertDialog({
                title: texts["cartempty"],
                okLabel: texts["general.confirmation.continue"],
            }).then(() => {
                navigate("/search", { replace: true });
            });
        }
    }, [allProducts, texts, navigate]);

    // Skip checkout page if only extras have been changed and no agreements are awaiting answers.
    // If any other product has been changed we need to go through the checkout as usual.
    // - Check if only any extras has been added
    // - Check if only any extras has got a new quantity
    useEffect(() => {
        if (user && userToken && products && products.length > 0) {
            const hasNewProducts = products.some(isNew);

            const accommodationsWithNewOrModifiedExtras = products.filter(
                product =>
                    isAccommodation(product) &&
                    product.info?.extras?.some(extra => extra.quantity !== extra.bookedquantity)
            );

            // Do not proceed if we have any new products or if any extras have not been added or modified
            if (!hasNewProducts && accommodationsWithNewOrModifiedExtras.length) {
                // Check if all agreements have been answered, if not we show the questions again and require
                // the user to answer before proceeding to checkout summary.
                const allAgreementsAnswered = agreements.every(
                    agreement => agreement.gdprtypelid in (user.agreements || {})
                );

                // If an answer is required, the new or modified extras will be created using the normal process
                if (allAgreementsAnswered) {
                    // Add the extras and mark the checkout as finished
                    const guestsArray = skipGuestsOnAccommodation ? [] : Object.values(product.guests || {});
                    const productsData = accommodationsWithNewOrModifiedExtras.map(product => ({
                        product: product,
                        mandatory: {
                            product_id: product.id,
                            guests: guestsArray,
                        },
                    }));

                    dispatch(syncProductsInCart(checkoutCartId, productsData));
                    setCheckoutFinished(true);
                } else {
                    // Show form with agreements to user
                    setActiveKey(lastFormIndex);
                }
            }
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        dispatch({ type: types.CLEAR_ERRORS });
        dispatch(addedAllProducts(false));
        dispatch(updatedAllGuests(false));
        dispatch(updatedUserData(false));

        let guestCount = 0;

        accommodations.forEach(accommodation => {
            guestCount += accommodation.info.guests?.totalGuests;
        });

        if (accommodations.length === 0) {
            guestCount += 1;
        }

        setTotalGuestsCount(guestCount);

        if (!checkoutCartId) {
            dispatch(createNewCart(userToken, clientData?.cookie));
        }
        // If a primary guest is not connected to the cart we create a new cart replacing the previous cart.
        // This may happen when using the fast checkout and switching to regular checkout.
        else if (!primaryCartGuestId) {
            dispatch(createNewCart(userToken, clientData?.cookie));
        }

        // eslint-disable-next-line
    }, []); // run only one time

    useEffect(() => {
        if (allGuestsUpdatedSuccessfully === true && checkoutFinished === true) {
            // If agent, no user data should be updated but the flow requires it so fake that it was successful.
            if (isAgent) {
                dispatch(updatedUserData(true));
            } else {
                dispatch(publishUpdatedUserData(user, userToken));
            }
        }

        // eslint-disable-next-line
    }, [allGuestsUpdatedSuccessfully, isAgent]);

    useEffect(() => {
        if (successfullyUpdatedUserData === true && checkoutFinished === true) {
            dispatch(syncProductsInCart(checkoutCartId, requestData));
        }

        // eslint-disable-next-line
    }, [successfullyUpdatedUserData]);

    useEffect(() => {
        if (addedAllProductsSucceed === true && checkoutFinished === true) {
            navigate("/checkout/summary");
        }
    }, [addedAllProductsSucceed, checkoutFinished, navigate]);

    useEffect(() => {
        // If not guests exists for the cart, fetch from API.
        if (checkoutCartId && !createdFromReservation && isEmpty(allGuests) && userToken && 1 == 2) {
            // Fetch the guests from the cart, only the primary will exist at the beginning.
            dispatch(fetchGuestsOnCart(checkoutCartId, userToken));
        }
    }, [checkoutCartId, allGuests, createdFromReservation, dispatch, userToken]);

    /**
     * Fetch the guests connected to the logged in user.
     * Only for a new booking.
     */
    useEffect(() => {
        if (
            !isEmpty(allGuests || {}) ||
            !checkoutCartId ||
            didFetchGuestsOnUser ||
            createdFromReservation ||
            !userToken ||
            onlyPrimaryGuest
        ) {
            return;
        }

        dispatch(fetchGuestsOnUser(userToken, true));
        setDidFetchGuestsOnUser(true);
    }, [
        allGuests,
        checkoutCartId,
        createdFromReservation,
        didFetchGuestsOnUser,
        dispatch,
        userToken,
        onlyPrimaryGuest,
    ]);

    /**
     * Fetch the guests connected to the logged in user.
     * Only for an existing booking.
     */
    useEffect(() => {
        if (!allGuests || !checkoutCartId || !createdFromReservation || didFetchGuestsOnUser || !userToken) {
            return;
        }

        const guestArray = Object.values(allGuests);

        if (guestArray.every(guest => guest.disabled)) {
            dispatch(fetchGuestsOnUser(userToken, true));
            setDidFetchGuestsOnUser(true);
        }
    }, [allGuests, checkoutCartId, createdFromReservation, didFetchGuestsOnUser, dispatch, userToken]);

    const alertView = () => {
        const errors = [].concat(
            checkoutError?.errors ? checkoutError?.errors : [],
            guestsError?.errors ? guestsError?.errors : [],
            accountError?.errors ? accountError?.errors : []
        );
        return (
            <Alert type="warning">
                {errors.map((error, index) => {
                    return (
                        <div key={index}>
                            <p>{error?.message}</p>
                            {error.data && (
                                <ul>
                                    {Object.entries(error.data).map(errorItem => (
                                        <li key={errorItem[0]}>
                                            <b>{errorItem[0]}:</b> {errorItem[1]}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    );
                })}
            </Alert>
        );
    };

    const removeProductFromCartAndSummary = productToRemove => {
        dispatch(removeProduct(productToRemove));
        if (cartSummary) {
            Object.keys(cartSummary.products).forEach(key => {
                const product = cartSummary.products[key];
                if (product.original_id === productToRemove.id) {
                    dispatch(deleteProductFromCart(userToken, cartSummary.id, key));
                }
            });
        }
    };

    const removeProductFromCart = (productToRemove, itemToRemove) => {
        const currentCount = productToRemove.count - 1;
        if (currentCount === 0) {
            removeProductFromCartAndSummary(productToRemove);
        } else {
            dispatch(
                updateProduct(productToRemove, {
                    key: "items",
                    value: productToRemove.items.filter(item => item !== itemToRemove),
                })
            );
            dispatch(updateProductWithCount(productToRemove, currentCount));
        }
    };

    const product = (type, index) => {
        const typesToShow = reservationTypes.find(rType => {
            return parseInt(rType.lid, 10) === parseInt(type, 10);
        });

        switch (typesToShow?.type) {
            case Constants.productTypeNames.ACCOMMODATION:
                return (
                    <Accommodation
                        removeProductFromCart={removeProductFromCartAndSummary}
                        key={`${type}${index}`}
                        type={typesToShow}
                        products={newProducts}
                        isMobile={isMobile}
                        texts={texts}
                    />
                );

            case Constants.productTypeNames.SKIPASS:
                return (
                    <SkiPass
                        removeProductFromCart={removeProductFromCart}
                        key={`${type}${index}`}
                        type={typesToShow}
                        allProducts={products}
                        products={newProducts}
                        isMobile={isMobile}
                        texts={texts}
                    />
                );

            case Constants.productTypeNames.LETTING:
                return (
                    <SkiRental
                        removeProductFromCart={removeProductFromCart}
                        key={`${type}${index}`}
                        type={typesToShow}
                        products={newProducts}
                        isMobile={isMobile}
                        texts={texts}
                    />
                );

            case Constants.productTypeNames.RENTAL:
                return (
                    <BikeRental
                        removeProductFromCart={removeProductFromCart}
                        key={`${type}${index}`}
                        type={typesToShow}
                        products={newProducts}
                        isMobile={isMobile}
                        texts={texts}
                    />
                );

            case Constants.productTypeNames.ACTIVITY:
                return (
                    <Activities
                        removeProductFromCart={removeProductFromCart}
                        key={`${type}${index}`}
                        type={typesToShow}
                        products={newProducts}
                        isMobile={isMobile}
                        texts={texts}
                    />
                );

            case Constants.productTypeNames.PACKAGE:
                return (
                    <Package
                        removeProductFromCart={removeProductFromCartAndSummary}
                        key={`${type}${index}`}
                        type={typesToShow}
                        products={newProducts}
                        isMobile={isMobile}
                        texts={texts}
                    />
                );

            case Constants.productTypeNames.SKIINGSCHOOL:
                return;
            default:
                return;
        }
    };

    const toggleHeader = (type, index) => {
        const typesToShow = reservationTypes.find(rType => parseInt(rType.lid, 10) === parseInt(type, 10));
        let toggleTitle = typesToShow?.description;
        let icon = "";

        if (!typesToShow) {
            return;
        }

        switch (typesToShow.type) {
            case Constants.productTypeNames.ACCOMMODATION:
                icon = showCompleteIcon[index] ? (
                    <TickIcon color="#32bea6" size={ICON_SIZE} />
                ) : (
                    <HouseIcon color="black" size={ICON_SIZE} />
                );
                break;

            case Constants.productTypeNames.SKIPASS:
                icon = showCompleteIcon[index] ? (
                    <TickIcon color="#32bea6" size={ICON_SIZE} />
                ) : (
                    <ContactIcon color="black" size={ICON_SIZE} />
                );
                break;

            case Constants.productTypeNames.LETTING:
                icon = showCompleteIcon[index] ? (
                    <TickIcon color="#32bea6" size={ICON_SIZE} />
                ) : (
                    <SkiingIcon color="black" size={ICON_SIZE} />
                );
                break;

            case Constants.productTypeNames.ACTIVITY:
                icon = showCompleteIcon[index] ? (
                    <TickIcon color="#32bea6" size={ICON_SIZE} />
                ) : (
                    <SkiIcon color="black" size={ICON_SIZE} />
                );
                break;

            case Constants.productTypeNames.RENTAL:
                icon = showCompleteIcon[index] ? (
                    <TickIcon color="#32bea6" size={ICON_SIZE} />
                ) : (
                    <BikeIcon color="black" size={ICON_SIZE} />
                );
                break;

            case Constants.productTypeNames.PACKAGE:
                icon = showCompleteIcon[index] ? (
                    <TickIcon color="#32bea6" size={ICON_SIZE} />
                ) : (
                    <Icon name="FaGift" color="black" size={ICON_SIZE} />
                );
                break;

            default:
                return;
        }

        return (
            <div key={index + type} style={activeKey >= index ? null : { opacity: "0.2", pointerEvents: "none" }}>
                <CustomToggle
                    isMobile={isMobile}
                    title={toggleTitle}
                    icon={icon}
                    eventKey={index}
                    activeKey={activeKey}
                    className="mb-0"
                ></CustomToggle>
            </div>
        );
    };

    /**
     * Handler when submitting the guests view.
     */
    const handleSubmitGuests = () => {
        const nextAccordionIndex = firstFormIndex + 1;

        setActiveKey(nextAccordionIndex);
        setShowCompleteIcon({ ...showCompleteIcon, [firstFormIndex]: true });

        // Reset any step errors.
        // Most of the errors are related to invalid or missing information on guests.
        setStepErrors({});
    };

    const handleSubmitOfAccommodation = (event, index, resvtypelid) => {
        // Prevent normal form submission early
        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;

        if (skipGuestsOnAccommodation) {
            handleSubmit(event, index);
        }

        // -- Validate that the guests that have been assigned to an accommodation have at least one phone number
        const accommodationsToValidate = accommodations
            .filter(accommodation => parseInt(accommodation.type) === resvtypelid)
            .filter(accommodation => Object.values(accommodation.guests).length > 0);

        const validAccommodations = accommodationsToValidate.filter(accommodation =>
            Object.values(accommodation.guests).some(guestlid => {
                const guest = guestsOnTravel[guestlid];
                return guest?.phoneprefix && guest?.phone;
            })
        );

        if (validAccommodations.length === accommodationsToValidate.length) {
            handleSubmit(event, index);

            if (form.checkValidity() === true) {
                delete stepErrors[index];
            }
        } else {
            // One of the accommodations is invalid
            setShowCompleteIcon({ ...showCompleteIcon, [index]: false });
            setValidated({ [index]: true });

            setStepErrors({
                [index]: texts.validationphonenumberrequiredononeguestinaccommodation,
            });
        }
    };

    const handleSubmit = (event, index) => {
        // Prevent normal form submission early
        event.preventDefault();
        event.stopPropagation();

        const nextAccordionIndex = index + 1;
        const form = event.currentTarget;

        const isFormValid = form.checkValidity();

        if (isFormValid === true && !loadingRequest) {
            setActiveKey(nextAccordionIndex);
            setShowCompleteIcon({ ...showCompleteIcon, [index]: true });
        } else {
            setShowCompleteIcon({ ...showCompleteIcon, [index]: false });
        }

        setValidated({ [index]: true });

        setAlertMessage({ [lastFormIndex]: isFormValid });

        if (agreements.length > 0) {
            if (lastFormIndex === index && isFormValid === true) {
                handleLastButtonClick();
            }
        } else {
            if (lastFormIndex - 1 === index && isFormValid === true) {
                handleLastButtonClick();
            }
        }
    };

    const handleAgreementChange = (agreement, value) => {
        const agreements = user.agreements || {};
        agreements[agreement.gdprtypelid] = value;
        dispatch(updateUserData("agreements", agreements));
    };

    const handleLastButtonClick = () => {
        let productData = null;
        const productsRequestData = [];

        products.forEach(product => {
            const currentProductType = reservationTypes.find(
                reservationType => reservationType.lid === parseInt(product.type)
            )?.type;

            switch (currentProductType) {
                case Constants.productTypeNames.ACCOMMODATION: {
                    // Guests are not mandatory if an option is enabled to book accommodations without guests.
                    // It may also happen that the option has been enabled before (e.g in demo) and disabled,
                    // which will give us an accommodation without any guests.
                    const guestsArray = skipGuestsOnAccommodation ? [] : Object.values(product.guests || {});

                    productData = {
                        product: product,
                        mandatory: {
                            product_id: product.id,
                            guests: guestsArray,
                            cartItemId: product.cartItemId || null,
                        },
                    };
                    productsRequestData.push(productData);
                    break;
                }
                case Constants.productTypeNames.SKIPASS: {
                    if (product.items && product.id) {
                        product.items.forEach(item => {
                            productData = {
                                product: product,
                                cartId: item.id,
                                mandatory: {
                                    product_id: product.id,
                                    withExistingKeycard: isNaN(parseInt(item.keycard)) ? 1 : parseInt(item.keycard),
                                    guests: [item.guestId],
                                    connectedProductId: product.connectedProductId || null,
                                },
                            };
                            if (!!item.keycard && item?.valid === true) {
                                productData.mandatory.keycardNo = `${item.keycardNo}`;
                            }
                            productsRequestData.push(productData);
                        });
                    }

                    break;
                }
                case Constants.productTypeNames.ACTIVITY: {
                    // Add guest connections that have been selected on the product, e.g guestlid and phone number for ski school

                    if (product.items && product.id) {
                        if (product.dynamicprices === true) {
                            const guestsArray = product.items.map(item => item.guestId);
                            const guestNotes = product.items
                                .filter(item => item.note)
                                .reduce((acc, current) => {
                                    acc[current.guestId] = current.note;
                                    return acc;
                                }, {});

                            // Phone number and prefix shall be handled like notes as extra details on an activity.
                            // We don't want to use the guest phone details
                            const phonePrefixes = product.items
                                .filter(item => item.phoneprefix)
                                .reduce((acc, current) => {
                                    acc[current.guestId] = current.phoneprefix;
                                    return acc;
                                }, {});
                            const phones = product.items
                                .filter(item => item.phone)
                                .reduce((acc, current) => {
                                    acc[current.guestId] = current.phone;
                                    return acc;
                                }, {});

                            productData = {
                                product: product,
                                mandatory: {
                                    product_id: product.id,
                                    guests: guestsArray,
                                    guestNotes,
                                    phonePrefixes,
                                    phones,
                                },
                            };
                            productsRequestData.push(productData);
                        } else {
                            product.items.forEach(item => {
                                productData = {
                                    product: product,
                                    cartId: item.id,
                                    mandatory: {
                                        product_id: product.id,
                                        guests: [item.guestId],
                                        guestNotes: item.note ? { [item.guestId]: item.note } : {},
                                        phonePrefixes: item.phoneprefix ? { [item.guestId]: item.phoneprefix } : {},
                                        phones: item.phone ? { [item.guestId]: item.phone } : {},
                                    },
                                };
                                productsRequestData.push(productData);
                            });
                        }
                    }

                    break;
                }
                case Constants.productTypeNames.LETTING: {
                    if (product.items && product.id) {
                        console.log(product);
                        product.items.forEach(item => {
                            productData = {
                                product: product,
                                cartId: item.id,

                                mandatory: {
                                    product_id: product.id,
                                    withInsurance: item.withInsurance === true ? 1 : 0,
                                    deliveryPoint: item.deliveryPoint,
                                    guests: [item.guestId],
                                    note: item.note || "",
                                },
                            };
                            productsRequestData.push(productData);
                        });
                    }

                    break;
                }
                case Constants.productTypeNames.RENTAL: {
                    if (product.items && product.id) {
                        product.items.forEach(item => {
                            productData = {
                                product: product,
                                cartId: item.id,

                                mandatory: {
                                    product_id: product.id,
                                    withInsurance: item.withInsurance === true ? 1 : 0,
                                    guests: [item.guestId],
                                    deliveryPoint: item.deliveryPoint,
                                },
                            };
                            productsRequestData.push(productData);
                        });
                    }

                    break;
                }
                case Constants.productTypeNames.PACKAGE: {
                    const guestsArray = Object.values(product.guests || {});

                    productData = {
                        product: product,
                        mandatory: {
                            product_id: product.id,
                            guests: guestsArray,
                        },
                    };
                    productsRequestData.push(productData);
                    break;
                }

                default:
                    break;
            }
        });

        dispatch(syncGuestsOnCart(userToken, checkoutCartId, onlyPrimaryGuest ? [] : guestsOnTravel));

        setRequestData(productsRequestData);

        setCheckoutFinished(true);
    };

    const guestsRef = useRef(null);

    // Make sure texts are loaded before continue rendering.
    if (!texts) {
        return <></>;
    }

    return (
        <Container>
            <UIButton className="button--text-only back-link" to="/">
                <Icon name="FaArrowLeft" color="#0d3e60" size={16} />
                <span className="back-link__text">{texts?.generalstart}</span>
            </UIButton>
            <h1 className="page-header">{texts?.checkoutheader}</h1>
            {(checkoutError || guestsError || accountError) && alertView()}
            <Accordion
                defaultActiveKey={activeKey}
                activeKey={activeKey}
                onSelect={selectedKey => {
                    if (selectedKey !== null) {
                        setActiveKey(selectedKey);
                    }
                }}
            >
                <div className="mb-5">
                    <CustomToggle
                        isMobile={isMobile}
                        title={texts?.generalguests}
                        icon={
                            showCompleteIcon[firstFormIndex] ? (
                                <TickIcon color="#32bea6" size={ICON_SIZE} iconClass={"helpIconski"} />
                            ) : (
                                <GuestIcon color="black" size={ICON_SIZE} iconClass="helpIconski" />
                            )
                        }
                        eventKey={firstFormIndex}
                        activeKey={activeKey}
                        className="mb-0"
                    />
                    <Accordion.Collapse eventKey={firstFormIndex}>
                        <>
                            <Guests
                                ref={guestsRef}
                                texts={texts}
                                totalGuestsCount={totalGuestsCount}
                                checkoutCartId={checkoutCartId}
                                products={products}
                                onSubmitAll={handleSubmitGuests}
                                onlyPrimaryGuest={onlyPrimaryGuest}
                            />
                            <Button onClick={guestsRef?.current?.submitAll} className="checkout__continueButton button">
                                {texts?.generalnext}
                                <Icon className="ms-3" name="FaArrowRight" size={15} />
                            </Button>
                        </>
                    </Accordion.Collapse>
                </div>
                {uniqueProductTypes.length >= 1 &&
                    uniqueProductTypes.map((type, index) => {
                        index = index + firstFormIndex + 1;

                        return (
                            <div key={`${type}_${index}`} className="mb-5">
                                {toggleHeader(type, index)}
                                <Form
                                    noValidate
                                    validated={validated[index]}
                                    onSubmit={e => {
                                        if (accommodationLids.includes(type)) {
                                            handleSubmitOfAccommodation(e, index, type);
                                        } else {
                                            handleSubmit(e, index);
                                        }
                                    }}
                                >
                                    <Accordion.Collapse eventKey={index}>
                                        <div>
                                            {product(type, index)}

                                            {index in stepErrors && (
                                                <Alert type="warning">
                                                    <p>{stepErrors[index]}</p>
                                                </Alert>
                                            )}

                                            <Button type="submit" className="checkout__continueButton button">
                                                {uniqueProductTypes.length === index && agreements.length === 0
                                                    ? texts?.generalcontinue
                                                    : texts?.generalnext}
                                                <Icon className="ms-3" name="FaArrowRight" size={15} />
                                            </Button>
                                        </div>
                                    </Accordion.Collapse>
                                </Form>
                            </div>
                        );
                    })}

                {agreements?.length > 0 && (
                    <Form
                        noValidate
                        validated={validated[lastFormIndex]}
                        onSubmit={e => {
                            handleSubmit(e, lastFormIndex);
                        }}
                    >
                        <div style={lastFormIndex > activeKey ? { opacity: "0.2", pointerEvents: "none" } : null}>
                            <CustomToggle
                                isMobile={isMobile}
                                title={texts?.informationconsentheader || "Information consent"}
                                icon={<WritingIcon color="black" size={ICON_SIZE} iconClass="helpIconski" />}
                                eventKey={lastFormIndex}
                                activeKey={activeKey}
                            />
                        </div>
                        <Accordion.Collapse
                            key={lastFormIndex}
                            in={activeKey === lastFormIndex}
                            eventKey={lastFormIndex}
                        >
                            <div>
                                {agreements.map((item, index) => {
                                    return (
                                        <InformationConsent
                                            key={index}
                                            agreement={item}
                                            agreementValue={user.agreements?.[item.gdprtypelid] ?? null}
                                            onChange={handleAgreementChange}
                                        />
                                    );
                                })}
                                {alertMessage[lastFormIndex] === false && (
                                    <Alert type="warning">
                                        <p className="mt-2">{texts?.validationpleasecompleteform}</p>
                                    </Alert>
                                )}
                                <Button className="checkout__continueButton button" type="submit">
                                    {texts?.gotocheckoutsummary}
                                </Button>
                            </div>
                        </Accordion.Collapse>
                    </Form>
                )}
            </Accordion>
        </Container>
    );
};

export default CheckoutPage;
