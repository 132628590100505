import { useCallback, useEffect, useState } from "react";
import debounce from "lodash/debounce";

const useWindowSize = () => {
    const isWindow = typeof window === "object";

    const getSize = useCallback(() => {
        return {
            width: isWindow ? window.innerWidth : undefined,
            height: isWindow ? window.innerHeight : undefined,
        };
    }, [isWindow]);

    const [windowSize, setWindowSize] = useState(getSize);

    useEffect(() => {
        if (!isWindow) {
            return false;
        }

        const handleResize = () => {
            setWindowSize(getSize());
        };

        // We don't need to handle the resize during resize, only after
        const debouncedResize = debounce(handleResize, 250);
        window.addEventListener("resize", debouncedResize);
        return () => window.removeEventListener("resize", debouncedResize);
    }, [getSize, isWindow]);

    return windowSize;
};

export default useWindowSize;
