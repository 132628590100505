import { BasicTable, CloseIcon, Heading } from "@r360/library";
import React, { useCallback } from "react";
import useTranslate from "../../hooks/useTranslate";
import { TOwnerRule } from "../../pages/AccommodationPage/AccommodationPage";
import "./AccommodationOwnerRules.scss";
import useAppSelector from "../../hooks/useAppSelector";

type TAccommodationOwnerRules = {
    ownerRules: TOwnerRule[];
};

export const AccommodationOwnerRules = ({ ownerRules }: TAccommodationOwnerRules) => {
    const t = useTranslate();
    const { isMobile } = useAppSelector(state => state.window);

    const getNumbersOfDaysText = useCallback(
        (numberOfDays: number): string =>
            `${numberOfDays} ` + t(`book.general.${numberOfDays === 1 ? "day" : "days"}`).toLocaleLowerCase(),
        []
    );

    const columns = [
        {
            title: t("book.accommodation.owner_rules.period"),
            dataIndex: "dates",
            key: "dates",
            width: "34%",
            render: (_: any, record: TOwnerRule) => (
                <>
                    {record.fromDate} - {record.toDate}
                </>
            ),
        },
        {
            title: t("book.accommodation.owner_rules.used_days"),
            dataIndex: "usedDays",
            key: "usedDays",
            width: "22%",
            render: (_: any, record: TOwnerRule) => <>{getNumbersOfDaysText(record.usedDays)}</>,
        },
        {
            title: t("book.accommodation.owner_rules.remaining_days"),
            dataIndex: "remainingDays",
            key: "remainingDays",
            width: "22%",
            render: (_: any, record: TOwnerRule) => {
                const remainingDays = record.maxDays - record.usedDays;

                return remainingDays > 0 ? (
                    getNumbersOfDaysText(remainingDays)
                ) : (
                    <span className="accommodation-owner-rules__closed-for-booking">
                        <CloseIcon color="#E04F5F"></CloseIcon> {t("book.accommodation.owner_rules.closed_for_booking")}
                    </span>
                );
            },
        },
        {
            title: t("book.accommodation.owner_rules.total_days"),
            dataIndex: "maxDays",
            key: "maxDays",
            width: "22%",
            render: (_: any, record: TOwnerRule) => <>{getNumbersOfDaysText(record.maxDays)}</>,
        },
    ];

    const mobileRender = (ownerRules: TOwnerRule[]) => {
        return (
            <>
                {ownerRules.map((rule, index) => (
                    <div key={index} className="accommodation-owner-rules__mobile">
                        <div className="accommodation-owner-rules__mobile-header">
                            <div className="accommodation-owner-rules__mobile-header-title">
                                {t("book.accommodation.owner_rules.period")}
                            </div>
                            {rule.fromDate} - {rule.toDate}
                        </div>
                        <div className="accommodation-owner-rules__mobile-content">
                            <div className="accommodation-owner-rules__mobile-content-item">
                                <span>{t("book.accommodation.owner_rules.used_days")}</span>
                                <span>{rule.usedDays}</span>
                            </div>
                            <div className="accommodation-owner-rules__mobile-content-item">
                                <span>{t("book.accommodation.owner_rules.remaining_days")}</span>
                                <span>
                                    {rule.maxDays - rule.usedDays > 0 ? (
                                        rule.maxDays - rule.usedDays
                                    ) : (
                                        <span className="accommodation-owner-rules__closed-for-booking">
                                            <CloseIcon color="#E04F5F"></CloseIcon>{" "}
                                            {t("book.accommodation.owner_rules.closed_for_booking")}
                                        </span>
                                    )}
                                </span>
                            </div>
                            <div className="accommodation-owner-rules__mobile-content-item">
                                <span>{t("book.accommodation.owner_rules.total_days")}</span>
                                <span>{rule.maxDays}</span>
                            </div>
                        </div>
                    </div>
                ))}
            </>
        );
    };

    return (
        <>
            <Heading type="h2">{t("book.accommodation.owner_rules.title")}</Heading>
            <div className="accommodation-owner-rules">
                {!isMobile ? (
                    <BasicTable columns={columns} data={ownerRules} rowKey={"fromDate"} />
                ) : (
                    mobileRender(ownerRules)
                )}
            </div>
        </>
    );
};
