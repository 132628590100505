import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { isSkipassForFastCheckout } from "../../BusinessUtils";
import SkiPass from "../../components/FillOutComponents/SkiPass/Skipass";
import { alertDialog, Alert, Button as UIButton, Icon, InformationBlock } from "../../components/UI";
import * as Constants from "../../Constants";
import { optionsSelector, textsSelector } from "../../Selectors";
import {
    addedAllProducts,
    createNewCart,
    removeProduct,
    syncProductsInCart,
    updateProduct,
    updateProductWithCount,
} from "../../store/actions/checkoutCart";

import { SkiingIcon } from "../../components/UI/R360Icons";
import { makeReservation } from "../../store/actions/reservation";
import * as types from "../../store/actions/types";
import { formatPrice } from "../../Helper";

const FastCheckoutPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const texts = useSelector(textsSelector);
    const clientData = useSelector(state => state.clientData);
    const loggedInUser = useSelector(state => state.account.user);
    const cartProducts = useSelector(state => state.checkoutCart.products) || [];
    const skipassProductsForFastCheckout = cartProducts.filter(isSkipassForFastCheckout);
    const hasOnlySkipassProductsForFastCheckout =
        cartProducts.length > 0 && cartProducts.length === skipassProductsForFastCheckout.length;
    const addedAllProductsSucceed = useSelector(state => state.checkoutCart.addedAllProductsSucceed);
    const reservation = useSelector(state => state.reservation.reservation);
    const [validated, setValidated] = useState(false);
    const [showAlertMessage, setShowAlertMessage] = useState(false);
    const reservationTypes = useSelector(state => state.clientData.reservationtypes);
    const checkoutCartId = useSelector(state => state.checkoutCart.cartId);
    const fastcheckoutEnabled = useSelector(optionsSelector).reservation?.fastcheckout;
    const currency = useSelector(state => state.clientData?.options?.general?.currency);
    const loadingRequest = useSelector(state => state.axiosStatus?.loading);

    const uniqueSkipassReservationTypes = [
        ...new Set(
            skipassProductsForFastCheckout
                .map(item => reservationTypes.find(resvType => resvType.lid === item.type))
                .filter(item => !!item)
        ),
    ];

    useEffect(() => {
        // Redirect customer to search page if no products exists in the cart.
        if (cartProducts && cartProducts.length === 0) {
            console.log("FastCheckout: Cart is empty, redirect back to search page"); // KEEP: Useful for debugging in log rocket, because some times the cart is empty
            alertDialog({
                title: texts["cartempty"],
                okLabel: texts["general.confirmation.continue"],
            }).then(() => {
                navigate("/search", { replace: true });
            });
        }
    }, [cartProducts, texts, navigate]);

    useEffect(() => {
        // Navigate to checkout if user is logged in or fast checkout isn't enabled.
        if (loggedInUser || !fastcheckoutEnabled) {
            navigate("/checkout", { replace: true });
            return;
        }

        // Navigate to regular checkout if cart doesn't only contain skipass products allowed in the fast checkout.
        if (cartProducts.length > 0 && !hasOnlySkipassProductsForFastCheckout) {
            navigate("/checkout", { replace: true });
        }
    }, [navigate, cartProducts, hasOnlySkipassProductsForFastCheckout, loggedInUser, fastcheckoutEnabled]);

    useEffect(() => {
        dispatch({ type: types.CLEAR_ERRORS });
        dispatch(addedAllProducts(false));

        if (!checkoutCartId && hasOnlySkipassProductsForFastCheckout) {
            // Create cart without a customer token to make it an unauthenticated cart
            dispatch(createNewCart(null, clientData?.cookie));
        }

        // eslint-disable-next-line
    }, []); // run only one time

    useEffect(() => {
        if (addedAllProductsSucceed === true && checkoutCartId) {
            dispatch(makeReservation(null, checkoutCartId));
        }
    }, [dispatch, addedAllProductsSucceed, checkoutCartId]);

    useEffect(() => {
        if (reservation) {
            navigate(`/pay/${reservation.resvid}/0/${reservation.payerlid}?verificationHash=${reservation.hash}`);
        }
    }, [reservation, navigate]);

    const handleSubmit = event => {
        const form = event.currentTarget;

        setValidated(true);

        setShowAlertMessage(!form.checkValidity());

        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity() === true && !loadingRequest) {
            const productsRequestData = [];

            skipassProductsForFastCheckout.forEach(product => {
                const currentProductType = reservationTypes.find(
                    reservationType => reservationType.lid === parseInt(product.type)
                )?.type;

                if (currentProductType === Constants.productTypeNames.SKIPASS && product.items && product.id) {
                    product.items.forEach(item => {
                        let productData = {
                            product: product,
                            cartId: item.id,
                            mandatory: {
                                product_id: product.id,
                                withExistingKeycard: isNaN(parseInt(item.keycard)) ? 1 : parseInt(item.keycard),
                                firstname: "Guest",
                                lastname: "Guest",
                            },
                        };

                        if (!!item.keycard && item?.valid === true) {
                            productData.mandatory.keycardNo = `${item.keycardNo}`;
                        }

                        productsRequestData.push(productData);
                    });
                }
            });

            dispatch({ type: types.CLEAR_ERRORS });
            dispatch(addedAllProducts(false));
            dispatch(syncProductsInCart(checkoutCartId, productsRequestData));
        }
    };

    const removeProductFromCart = (productToRemove, itemToRemove) => {
        const currentCount = productToRemove.count - 1;
        if (currentCount === 0) {
            dispatch(removeProduct(productToRemove));
        } else {
            dispatch(
                updateProduct(productToRemove, {
                    key: "items",
                    value: productToRemove.items.filter(item => item !== itemToRemove),
                })
            );
            dispatch(updateProductWithCount(productToRemove, currentCount));
        }
    };

    const getTotalPrice = () => {
        const totalPrice = skipassProductsForFastCheckout.reduce((accPrice, item) => {
            accPrice += parseInt(item.price * (item.count || 1));

            // Include eventual new keycards if connected to a keycard provider.
            // Value "0" means that an existing card won't be used and therefore needs to by a new.
            if (item.connected && item.keycardPrice > 0) {
                const newKeycardsCount = (item.items || []).filter(item => item.keycard === "0").length;
                accPrice += newKeycardsCount * item.keycardPrice;
            }

            return accPrice;
        }, 0);

        return formatPrice(totalPrice, currency);
    };

    // Make sure texts are loaded before continue rendering.
    if (!texts) {
        return <></>;
    }

    return (
        <Container>
            <UIButton className="button--text-only back-link" to="/">
                <Icon name="FaArrowLeft" color="#0d3e60" size={16} />
                <span className="back-link__text">{texts?.generalstart}</span>
            </UIButton>
            <h1 className="page-header">{texts["fastcheckout.title"]}</h1>
            <InformationBlock className="mb-4">
                {texts["fastcheckout.description"]} <Link to="/checkout">{texts?.login}</Link>
            </InformationBlock>

            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                {uniqueSkipassReservationTypes.map(resvType => (
                    <React.Fragment key={resvType.lid}>
                        <div className="mt-5 d-flex align-items-center">
                            <SkiingIcon color="black" size={30} />
                            <h2 className="ms-3 mb-0">{resvType.description}</h2>
                        </div>

                        <SkiPass
                            removeProductFromCart={removeProductFromCart}
                            type={resvType}
                            products={skipassProductsForFastCheckout}
                            texts={texts}
                            skipGuestsOnSkipass={true}
                        />
                    </React.Fragment>
                ))}

                {showAlertMessage && (
                    <Row className="mt-4">
                        <Col md="8">
                            <Alert type="warning">
                                <p className="mt-2">{texts?.validationpleasecompleteform}</p>
                            </Alert>
                        </Col>
                    </Row>
                )}

                <h5 className="h5 mt-5 mb-2">
                    {texts?.totalprice}
                    <span className="ms-5">{getTotalPrice()}</span>
                </h5>

                <Button type="submit" className="button mt-4">
                    {texts?.continuetopayment}
                    <Icon className="ms-3" name="FaArrowRight" size={15} />
                </Button>
            </Form>
        </Container>
    );
};

export default FastCheckoutPage;
