import { setSignUpWrapper } from "./actions/window";
import * as types from "./actions/types";
import { createBrowserHistory } from "history";
import { signOutUser } from "./actions/account";

/*
  Listen for all (intercept) requests and responses made with Axios
*/
const interceptor = (axios, store) => {
    axios.interceptors.request.use(
        conf => {
            store.dispatch({
                type: "SET_ERROR", // remove network errors
                error: null,
            });
            return conf;
        },
        error => {
            return Promise.reject(error);
        }
    );
    axios.interceptors.response.use(
        next => {
            return Promise.resolve(next);
        },
        error => {
            const status = error.response?.status;

            if (status === 500) {
                // internal server error
                store.dispatch({
                    type: "SET_ERROR",
                    error: error.response,
                });
            } else if (status === 401) {
                // unauthorized
                // Show login dialog to allow user to login/relogin if we receive an unauthorized status from server
                const state = store.getState();

                // Remember the current URL that was before being automatically logged out
                const history = createBrowserHistory();
                const pathBeforeSignOut = history.location.pathname.replace(/^\/client/, ""); // remove basepath "/client"

                // Only re-login if we are already authenticated.
                // This protects for multiple chained requests that return status code 401.
                // We only want to logout one time :)
                if (state.account.loginSuccessful) {
                    // Clear user's state
                    store.dispatch(signOutUser("unauthorized action detected", true));
                }

                // Show login modal if not already on login page.
                if (pathBeforeSignOut !== "/user/login") {
                    store.dispatch(setSignUpWrapper(true, true, false, pathBeforeSignOut));
                }
            }

            return Promise.reject(error);
        }
    );
};
export default { interceptor };
