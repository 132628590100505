import React from "react";
import { Alert } from "react-bootstrap";
import { Icon } from "../";
import "./AlertSorry.scss";

export const AlertSorry = ({ heading, content, variant = "light" }) => (
    <Alert variant={variant} className="alert-sorry">
        <div className="alert-sorry-icon">
            <Icon name="FaRegMehRollingEyes" size={40} color="#F1C40F" />
        </div>
        <div className="alert-sorry-text">
            <h3 className="alert-sorry-text__heading">{heading}</h3>
            <p>{content}</p>
        </div>
    </Alert>
);

export default AlertSorry;
