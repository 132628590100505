import isEqual from "lodash/isEqual";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { showArrDepDates } from "../../BusinessUtils";
import { capitalizeFirstLetter, getGuestNameAndAge } from "../../Helper";
import { agesSelector, guestsOnTravelSelector } from "../../selectors/Selectors";
import { updateProduct } from "../../store/actions/checkoutCart";
import { TSectionStates } from "../../pages/CheckoutConnectPage/CheckoutConnectPage";
import { TCartItem, TGeneralAge, TGuest, TReservationType } from "../../store/types";
import { RootState } from "../..";
import classNames from "classnames";
import { Select, TSelectOption, Button, Tooltip, ConfirmationModal, Notification } from "@r360/library";
import { CheckoutConnectCard } from "./CheckoutConnectCard";
import {
    CheckoutConnectCardHeading,
    CheckoutConnectCardRemoveBtn,
    CheckoutConnectNoGuestsToConnect,
} from "./CheckoutConnectCard/CheckoutConnectCard";
import useTranslate from "../../hooks/useTranslate";

type TCheckoutConnectPackage = {
    sectionStates: TSectionStates;
    removeProductFromCart: (arg0: TCartItem) => void;
    products: TCartItem[];
    type: TReservationType;
};

export const CheckoutConnectPackage = ({
    sectionStates,
    removeProductFromCart,
    products,
    type,
}: TCheckoutConnectPackage) => {
    const t = useTranslate();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const packages = products.filter(product => product.type === type.lid);
    const guestsOnTravel = useSelector(guestsOnTravelSelector);
    const generalAges = useSelector(agesSelector);
    const userToken = useSelector((state: RootState) => state.account.token);
    const checkoutCartId = useSelector((state: RootState) => state.checkoutCart.cartId);
    const sectionState = sectionStates[type.lid];
    const { isMobile, isIframe, isDesktop, iframeOffsetTop, isTablet } = useSelector(
        (state: RootState) => state.window
    );

    const [packageToChange, setPackageToChange] = useState<TCartItem | null>(null);

    /**
     * Check that all guests connected to packages should be on the travel.
     * If not, remove them from the package.
     */
    useEffect(() => {
        const guestIds = Object.keys(guestsOnTravel).map(guestId => parseInt(guestId));

        for (const packageItem of packages) {
            if (!packageItem.guests) {
                continue;
            }

            const packageGuests = { ...packageItem.guests };

            for (const [index, packageGuestId] of Object.entries(packageGuests)) {
                if (!guestIds.includes(packageGuestId)) {
                    delete packageGuests[index];
                }
            }

            if (!isEqual(packageItem.guests, packageGuests)) {
                dispatch(
                    updateProduct(packageItem, {
                        key: "guests",
                        value: packageGuests,
                    })
                );
            }
        }
    }, [dispatch, packages, guestsOnTravel]);

    // Check whether accomodations have period overlaps
    let packagesForSamePeriod = false;

    if (packages.length > 1) {
        packages.every(accomodation => {
            const arrDate = moment(accomodation.arrdate);
            const depDate = moment(accomodation.depdate);

            const otherPackages = packages.filter(filterPackage => filterPackage.id !== accomodation.id);

            const otherAccomodationsInSamePeriod = otherPackages.some(otherAccommodation => {
                const assignedArrDate = moment(otherAccommodation.arrdate);
                const assignedDepDate = moment(otherAccommodation.depdate);

                if (
                    arrDate.isBetween(assignedArrDate, assignedDepDate, undefined, "[)") ||
                    depDate.isBetween(assignedArrDate, assignedDepDate, undefined, "(]")
                ) {
                    return true;
                }

                return false;
            });

            if (otherAccomodationsInSamePeriod) {
                packagesForSamePeriod = true;
                // Break the every loop by returning false
                return false;
            } else {
                packagesForSamePeriod = false;
            }
        });
    }

    /**
     * Get ages for a reservation type.
     *
     * @param {string} reservationTypeId The reservation type ID.
     *
     * @returns {Object[]} A list of ages objects.
     */
    const getAgesForReservationType = (reservationTypeId: number) => {
        if (!generalAges[reservationTypeId]) {
            throw new Error(`Reservation type ${reservationTypeId} does not have any ages configured in general ages`);
        }

        return generalAges[reservationTypeId];
    };

    /**
     * Get a list of ages for an package sorted by descending min age.
     *
     * E.g. if guests for package is { youth: 2, adult: 1, child: 1 }, this is returned:
     * [{ key: "adult", ...} , { key: "youth", ...}, { key: "youth", ...}, { key: "child", ...}]
     *
     * @param {Object} packageItem The package product from cart.
     *
     * @returns {Object[]} A list of ages for an package sorted by descending min age.
     */
    const getSortedAgesForPackage = (packageItem: TCartItem) => {
        const ages = getAgesForReservationType(packageItem.type) as TGeneralAge[];
        const guestCounts: { [key: string]: number } = packageItem.info.guests;
        const totalGuestsCount = packageItem.info.guests.totalGuests;

        // Sort age categories by descending min age.
        const sortedAges = ages.sort((a, b) => b.min - a.min);

        const selectsByAge: TGeneralAge[] = [];

        sortedAges.forEach(age => {
            if (guestCounts[age.key] > 0) {
                for (let i = 0; i < guestCounts[age.key]; i++) {
                    selectsByAge.push(age);
                }
            }
        });

        if (selectsByAge.length !== totalGuestsCount) {
            throw new Error(
                `Guest count doesn't match when comparing total guests and guest count per age category for package with intervallid ${packageItem.intervallid}`
            );
        }

        return selectsByAge;
    };

    /**
     * Check if a guest can be added to an package slot based on age and other assigned
     * packages.
     *
     * @param {Object} guest
     * @param {Object} packageItem
     * @param {number} dropdownIndex
     * @param {Object} age
     *
     * @returns {boolean} True if the guest can be added to the slot, otherwise false.
     */
    const getCanGuestBeAddedToPackageSlot = (
        guest: TGuest,
        packageItem: TCartItem,
        dropdownIndex: number,
        age: TGeneralAge
    ) => {
        const arrDate = moment(packageItem.arrdate);
        const depDate = moment(packageItem.depdate);

        // Check that the guest is the correct age on arrival date.
        if (!guest.birthday) {
            return false;
        }

        const guestAgeAtArrivalDate = arrDate.diff(moment(guest.birthday), "years");

        if (guestAgeAtArrivalDate < age.min || guestAgeAtArrivalDate > age.max) {
            return false;
        }

        // Check if the guest is already assigned to another spot on the current package.
        const guestIsAlreadyAssignedToOtherSpotAtCurrentPackage = Object.entries(packageItem.guests || {}).some(
            ([index, packageGuestId]) => {
                if (packageGuestId === guest.id && parseInt(index) !== dropdownIndex) {
                    return true;
                }

                return false;
            }
        );

        if (guestIsAlreadyAssignedToOtherSpotAtCurrentPackage) {
            return false;
        }

        // Check if the guest is assigned to any other packages during the same period.
        const otherPackages = packages.filter(filterPackage => filterPackage.id !== packageItem.id);

        const guestIsAlreadyAssignedToOtherPackageForPeriod = otherPackages.some(otherPackage => {
            if (Object.values(otherPackage.guests || {}).includes(guest.id)) {
                const assignedArrDate = moment(otherPackage.arrdate);
                const assignedDepDate = moment(otherPackage.depdate);

                if (
                    arrDate.isBetween(assignedArrDate, assignedDepDate, undefined, "[)") ||
                    depDate.isBetween(assignedArrDate, assignedDepDate, undefined, "(]")
                ) {
                    return true;
                }
            }

            return false;
        });

        if (guestIsAlreadyAssignedToOtherPackageForPeriod) {
            return false;
        }

        return true;
    };

    /**
     * Assign a guest to a package slot.
     *
     * @param {Object} packageItem
     * @param {number} dropdownIndex
     * @param {number} guestId
     */
    const assignGuestToPackage = (packageItem: TCartItem, dropdownIndex: number, guestId: string) => {
        const packageGuests = { ...packageItem.guests };

        // If not guest is provided, remove guest is assigned to the provided index.
        if (guestId === "") {
            delete packageGuests[dropdownIndex];
        } else {
            const guestIdNumber = parseInt(guestId);

            if (Object.values(packageGuests).includes(guestIdNumber)) {
                return;
            }

            packageGuests[dropdownIndex] = guestIdNumber;
        }

        dispatch(
            updateProduct(packageItem, {
                key: "guests",
                value: packageGuests,
            })
        );
    };

    /**
     * Render the select fields for a package.
     *
     * @param {Object} packageItem
     */
    const renderGuestAgesForPackage = (packageItem: TCartItem) => {
        const sortedAges = getSortedAgesForPackage(packageItem);

        const groupedAges = sortedAges.reduce((acc: TGeneralAge[], currentAge) => {
            const currentAgeIndexOfAcc = acc.findIndex(age => age.key === currentAge.key);

            if (currentAgeIndexOfAcc === -1) {
                currentAge.guestCount = 1;
                acc.push(currentAge);
            } else {
                acc[currentAgeIndexOfAcc].guestCount!++;
            }

            return acc;
        }, []);

        return (
            <div className="u-d-flex u-gap-3 u-flex-column">
                {groupedAges.map((age: any, index: number) => (
                    <span key={index}>{`${age.guestCount} st ${t(age.title)}`}</span>
                ))}
            </div>
        );
    };

    const renderGuestCountForPackage = (packageItem: TCartItem) => {
        return (
            <Tooltip toolTipContent={renderGuestAgesForPackage(packageItem)} triggerOnClick>
                <Button type="tertiary">
                    {packageItem.info.guests.totalGuests === 1
                        ? `${packageItem.info.guests.totalGuests} ${t("generalguest")}`
                        : `${packageItem.info.guests.totalGuests} ${t("generalguests")}`}
                </Button>
            </Tooltip>
        );
    };

    const renderGuestSelectsForPackage = (packageItem: TCartItem) => {
        const sortedAges = getSortedAgesForPackage(packageItem);
        const packageGuests = packageItem.guests || {};

        return (
            <div className="row u-bs-gutters-6">
                {sortedAges.map((age, dropdownIndex) => {
                    const guestSelectOptions = Object.entries(guestsOnTravel)
                        .filter(([, guest]) => getCanGuestBeAddedToPackageSlot(guest, packageItem, dropdownIndex, age))
                        .map(([id, guest]) => ({
                            value: id,
                            label: getGuestNameAndAge(guest, "år", packageItem),
                        }));

                    return (
                        <div
                            className={classNames("col-12 u-mb-18", {
                                "col-lg-6": sortedAges.length <= 2,
                                "col-lg-4": sortedAges.length > 2,
                            })}
                            key={`${age.key}-${dropdownIndex}`}
                        >
                            <Select
                                required
                                name="guestId"
                                label={
                                    <span>
                                        {`Gäst ${dropdownIndex + 1} `}
                                        <span
                                            style={{ fontWeight: 400 }}
                                            className="u-fw-default"
                                        >{`(${age.min} - ${age.max}) år`}</span>
                                    </span>
                                }
                                defaultValue={(packageGuests[dropdownIndex] || "").toString()}
                                options={guestSelectOptions}
                                fullWidth
                                type="standard"
                                onSelectCallback={(selectedOption: TSelectOption) =>
                                    assignGuestToPackage(packageItem, dropdownIndex, selectedOption.value as string)
                                }
                                placeholder="Välj en gäst"
                                useFirstAsDefault={false}
                                disabled={!guestSelectOptions.length}
                                isValid={
                                    sectionState.status === undefined
                                        ? undefined
                                        : !(sectionState.status === "error" && !packageGuests[dropdownIndex])
                                }
                                showAsterisk
                                boldLabel
                            />
                            {!guestSelectOptions.length && (
                                <CheckoutConnectNoGuestsToConnect minAge={age.min} maxAge={age.max} />
                            )}
                        </div>
                    );
                })}
            </div>
        );
    };

    const renderPackages = () => {
        const sortedPackages = packages.sort((a, b) => moment(a.arrdate).unix() - moment(b.arrdate).unix());

        return (
            <>
                {sortedPackages.map(packageItem => {
                    return (
                        <div key={packageItem.title}>
                            <CheckoutConnectCard>
                                <div className="checkout-connect-accomodation">
                                    <div className="checkout-connect-accomodation__content">
                                        <div>
                                            <div className="u-mb-12">
                                                <CheckoutConnectCardHeading primaryHeading={packageItem.title} />
                                            </div>
                                            <div className="u-mb-24">
                                                {renderGuestCountForPackage(packageItem)}
                                                {", "}
                                                <span>
                                                    {capitalizeFirstLetter(
                                                        showArrDepDates(packageItem, "dddd Do MMMM")
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="u-mb-18">{renderGuestSelectsForPackage(packageItem)}</div>
                                        <div className="u-d-flex u-gap-24">
                                            <CheckoutConnectCardRemoveBtn
                                                onClick={() => removeProductFromCart(packageItem)}
                                            />
                                            <Button type="tertiary" onClick={() => setPackageToChange(packageItem)}>
                                                {t("book.general.edit")}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </CheckoutConnectCard>
                        </div>
                    );
                })}
            </>
        );
    };

    // Make sure the required data from the state has been fetched.
    if (!guestsOnTravel || !generalAges || !userToken || !checkoutCartId) {
        return <></>;
    }

    return (
        <>
            {packagesForSamePeriod && (
                <div className="u-mb-24">
                    <Notification type="warning">
                        <div className="u-fw-medium">Du har flera paket under samma period</div>
                        Du kommer inte kunna koppla en gäst till fler en ett paket om dessa infaller under samma period.
                    </Notification>
                </div>
            )}
            {renderPackages()}
            <ConfirmationModal
                open={packageToChange}
                heading="Ändra paket"
                description="För att ändra antalet gäster behöver paketet tas bort och läggas till på nytt. Genom att fortsätta tas ditt paket bort från varukorgen och du skickas vidare till sidan för paket."
                confirmBtnText={"Fortsätt"}
                cancelBtnText={"Avbryt"}
                removing={true}
                onClose={() => setPackageToChange(null)}
                onConfirmClick={() => {
                    if (!packageToChange) {
                        return;
                    }

                    const { type } = packageToChange;
                    removeProductFromCart(packageToChange);
                    navigate(`/search/${type}`);
                }}
                onCancelClick={() => setPackageToChange(null)}
                {...(isIframe && isDesktop && { fromTop: 120 + iframeOffsetTop + "px" })}
                {...(isIframe &&
                    (isMobile || isTablet) && {
                        fromTop: 80 + iframeOffsetTop + "px",
                    })}
            />
        </>
    );
};
