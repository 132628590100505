import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAppSelector from "../../hooks/useAppSelector";
import { Button, Checkbox, Heading, Input, UserPlusIcon, style, Notification } from "@r360/library";
import { registerNewUser, resetApiMessages } from "../../store/actions/account";
import { TAccount, TClientCountry } from "../../store/types";
import useTranslate from "../../hooks/useTranslate";
import { PhoneCountryCodeSelect } from "../PhoneCountryCodeSelect/PhoneCountryCodeSelect";
import { YearSelect, MonthSelect, DaySelect } from "../BirthDateSelect";
import { setSignUpWrapper } from "../../store/actions/window";
import useAppDispatch from "../../hooks/useAppDispatch";
import moment from "moment";
import classNames from "classnames";
import { CountrySelect } from "../CountrySelect";
import { RichText } from "../UI";
import "./Registration.scss";
import { Formik, FormikProps, getIn } from "formik";
import * as yup from "yup";
import { TFormikErrors, TFormikTouched } from "../PersonalInformation/PersonalInformation";
import useFormPhoneNumberValidation from "../../hooks/useFormPhoneNumberValidation";
import { isNumberWithoutDecimals } from "../../Helper";

type TSignUp = {
    redirectPath?: string;
    registrationPage?: boolean;
    onDarkBackground?: boolean;
};

export interface TCreateAccountForm extends Record<string, string | number | boolean | null> {
    firstname: string;
    lastname: string;
    year: number | null;
    month: number | null;
    day: number | null;
    email: string;
    phoneprefix: number | null;
    phone: string;
    address: string;
    postalcode: string;
    city: string;
    country: string;
    password: string;
    repeatedPassword: string;
    acceptedTerms: boolean;
}

export const Registration = ({ redirectPath, registrationPage = false, onDarkBackground = false }: TSignUp) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const t = useTranslate();

    const clientData = useAppSelector(state => state.clientData);
    const termsUrl: string = clientData?.options?.reservation?.terms_url;
    const countries: TClientCountry[] = clientData.country;
    const { failedToCreateNewUser, loginSuccessful } = useAppSelector(state => state.account) as TAccount;
    const pathname: string = useAppSelector(state => state.window.signUpWrapper.pathname);
    const { requestsLoading } = useAppSelector(state => state.axiosStatus);
    const creatingAccountInProgress: boolean | undefined = requestsLoading["registerNewUser"];

    const [showErrorNotification, setShowErrorNotification] = useState(false);
    const formikRef = useRef<FormikProps<TCreateAccountForm>>(null);

    const redirectTo = redirectPath ?? (pathname ? pathname : "/");
    const errorCreatingAccount = failedToCreateNewUser?.error;
    const errors = failedToCreateNewUser?.data?.map(item => {
        if (item.data && Object.keys(item.data).length > 0) {
            return item.data;
        }
        return {};
    });
    const { phoneValid, validatePhone, validatePhonePrefix } = useFormPhoneNumberValidation(
        true,
        false,
        false,
        () => {
            null;
        },
        () => {
            null;
        }
    ) as {
        phoneValid: boolean;
        phonePrefixValid: boolean;
        validatePhone: (arg0: string | number, arg1: string | number) => boolean;
        validatePhonePrefix: (arg0: string | number, arg1: string | number) => boolean;
    };

    useEffect(() => {
        if (loginSuccessful) {
            if (redirectTo) {
                if (!registrationPage) {
                    dispatch(setSignUpWrapper(false, false, false));
                }
                navigate(redirectTo);
            } else {
                window.location.reload();
            }
        }
    }, [dispatch, navigate, redirectTo, registrationPage, loginSuccessful]);

    useEffect(() => {
        if (errorCreatingAccount) {
            setShowErrorNotification(true);

            errors?.forEach(error => {
                Object.entries(error).forEach(([key, value]) => {
                    formikRef.current?.setErrors({ [key]: value });
                });
            });
        }
    }, [errorCreatingAccount, errors]);

    const validationSchema = yup.object().shape({
        firstname: yup.string().required(),
        lastname: yup.string().required(),
        year: yup.number().required(),
        month: yup.number().required(),
        day: yup.number().required(),
        email: yup.string().email().required(),
        address: yup.string().required(),
        postalcode: yup.number().required(),
        city: yup.string().required(),
        country: yup.string().required(),
        password: yup.string().required().min(8),
        repeatedPassword: yup
            .string()
            .required()
            .oneOf([yup.ref("password"), ""]),
        ...(termsUrl && {
            acceptedTerms: yup.boolean().required().oneOf([true]),
        }),
    });

    const removeErrorMessages = useCallback(() => {
        dispatch(resetApiMessages());
        setShowErrorNotification(false);
    }, [dispatch]);

    const createAccount = (values: TCreateAccountForm) => {
        const {
            firstname,
            lastname,
            year,
            month,
            day,
            email,
            address,
            postalcode,
            city,
            country,
            phoneprefix,
            phone,
            password,
            repeatedPassword,
        } = values;

        const dateFormat = "YYYY-MM-DD";
        const birthday = moment()
            .set({
                year: year ?? undefined,
                month: month ? month - 1 : undefined,
                date: day ?? undefined,
            })
            .format(dateFormat);

        const newUser = {
            name1: firstname,
            name2: lastname,
            year,
            month,
            day,
            email,
            addr1: address,
            addr3: postalcode,
            addr4: city,
            addr5: country,
            phone3prefix: phoneprefix,
            phone3: phone,
            password,
            repeatpassword: repeatedPassword,
            birthday,
        };

        removeErrorMessages();
        dispatch(registerNewUser(newUser));
    };

    const isFieldValid = (touched: TFormikTouched, errors: TFormikErrors) => {
        if (touched) {
            if (!errors) {
                return true;
            } else {
                return false;
            }
        } else {
            return undefined;
        }
    };

    const isFieldRequired = (name: string) => {
        const schemaDescription = validationSchema.describe();
        const accessor = name.split(".").join(".fields.");
        const field = getIn(schemaDescription.fields, accessor);
        if (!field) {
            return false;
        }
        const isRequired = field.tests.some((test: any) => test.name === "required") || !field.optional;
        return isRequired;
    };

    const renderErrorNotification = () => {
        if (!errors || errors?.length === 0) {
            return null;
        }

        return (
            <div className="u-mb-18">
                <Notification type="error">
                    {errors.map(error => {
                        return Object.values(error).map((item, index) => {
                            let errorText = "";

                            if (item === "User already exists on email") {
                                errorText = t("book.account.email_already_in_use");
                            }

                            return <div key={index}>{errorText}</div>;
                        });
                    })}
                </Notification>
            </div>
        );
    };

    return (
        <div
            className={classNames("registration", {
                "registration--in-page": registrationPage,
                "u-disabled": creatingAccountInProgress,
            })}
        >
            <Heading type="h2" className="u-mb-12 u-text-center" onDarkBackground={onDarkBackground}>
                {t("book.account.create")}
            </Heading>
            {showErrorNotification && renderErrorNotification()}
            <Formik
                innerRef={formikRef}
                validationSchema={validationSchema}
                onSubmit={createAccount}
                initialValues={
                    {
                        firstname: "",
                        lastname: "",
                        year: null,
                        month: null,
                        day: null,
                        email: "",
                        phoneprefix: null,
                        phone: "",
                        address: "",
                        postalcode: "",
                        city: "",
                        country: "",
                        password: "",
                        repeatedPassword: "",
                        ...(termsUrl && {
                            acceptedTerms: false,
                        }),
                    } as TCreateAccountForm
                }
                enableReinitialize={false}
            >
                {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    errors,
                    setFieldValue,
                    setFieldTouched,
                    setFieldError,
                }) => {
                    return (
                        <form className="row u-bs-gutters-6" noValidate onSubmit={handleSubmit}>
                            <div className="col-12 col-md-6">
                                <Input
                                    label={t("book.account.firstname")}
                                    required
                                    name="firstname"
                                    id="formGroupFirstName"
                                    value={values.firstname}
                                    isValid={isFieldValid(touched.firstname, errors.firstname)}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    onDarkBackground={onDarkBackground}
                                    showAsterisk={isFieldRequired("firstname")}
                                />
                            </div>
                            <div className="col-12 col-md-6">
                                <Input
                                    label={t("book.account.lastname")}
                                    required
                                    name="lastname"
                                    id="formGroupLastName"
                                    value={values.lastname}
                                    isValid={isFieldValid(touched.lastname, errors.lastname)}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    onDarkBackground={onDarkBackground}
                                    showAsterisk={isFieldRequired("lastname")}
                                />
                            </div>
                            <div className="col-4 col-md-2">
                                <YearSelect
                                    name="year"
                                    value={values.year ?? parseInt("")}
                                    onChange={(selectedOption, name) => {
                                        setFieldTouched(name, true);
                                        setFieldValue(name, selectedOption.value);
                                    }}
                                    onDarkBackground={onDarkBackground}
                                    isValid={isFieldValid(touched.year, errors.year)}
                                    showAsterisk={isFieldRequired("year")}
                                />
                            </div>
                            <div className="col-4 col-md-2">
                                <MonthSelect
                                    name="month"
                                    value={values.month ?? parseInt("")}
                                    year={values.year}
                                    onChange={(selectedOption, name) => {
                                        setFieldTouched(name, true);
                                        setFieldValue(name, selectedOption.value);
                                    }}
                                    onDarkBackground={onDarkBackground}
                                    isValid={isFieldValid(touched.month, errors.month)}
                                    showAsterisk={isFieldRequired("month")}
                                    shortPlaceholder
                                />
                            </div>
                            <div className="col-4 col-md-2">
                                <DaySelect
                                    name="day"
                                    value={values.day ?? parseInt("")}
                                    year={values.year}
                                    month={values.month}
                                    onChange={(selectedOption, name) => {
                                        setFieldTouched(name, true);
                                        setFieldValue(name, selectedOption.value);
                                    }}
                                    onDarkBackground={onDarkBackground}
                                    isValid={isFieldValid(touched.day, errors.day)}
                                    showAsterisk={isFieldRequired("day")}
                                />
                            </div>
                            <div className="col-12 col-md-6">
                                <Input
                                    type="email"
                                    label={t("book.general.email")}
                                    required
                                    name="email"
                                    id="formGroupEmail"
                                    value={values.email}
                                    isValid={isFieldValid(touched.email, errors.email)}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    onDarkBackground={onDarkBackground}
                                    showAsterisk={isFieldRequired("email")}
                                    errorMessage={t("book.account.email.validation")}
                                    showValidationMessageOnBlurOnly
                                />
                            </div>
                            <div className="col-12 col-md-5">
                                <Input
                                    label={t("book.account.address")}
                                    required
                                    name="address"
                                    id="formGroupAddress"
                                    value={values.address}
                                    isValid={isFieldValid(touched.address, errors.address)}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    onDarkBackground={onDarkBackground}
                                    showAsterisk={isFieldRequired("address")}
                                />
                            </div>
                            <div className="col-6 col-md-3">
                                <Input
                                    label={t("book.account.postalcode")}
                                    required
                                    name="postalcode"
                                    id="formGroupPostalcode"
                                    value={values.postalcode}
                                    isValid={isFieldValid(touched.postalcode, errors.postalcode)}
                                    onChange={e => isNumberWithoutDecimals(e.target.value) && handleChange(e)}
                                    onBlur={handleBlur}
                                    onDarkBackground={onDarkBackground}
                                    showAsterisk={isFieldRequired("postalcode")}
                                />
                            </div>
                            <div className="col-6 col-md-4">
                                <Input
                                    label={t("book.account.city")}
                                    required
                                    name="city"
                                    id="formGroupCity"
                                    value={values.city ?? ""}
                                    isValid={isFieldValid(touched.city, errors.city)}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    onDarkBackground={onDarkBackground}
                                    showAsterisk={isFieldRequired("city")}
                                />
                            </div>
                            <div className="col-6 col-md-4">
                                <CountrySelect
                                    countries={countries}
                                    name="country"
                                    value={values.country}
                                    onChange={(selectedOption, name) => {
                                        setFieldTouched(name, true);
                                        setFieldValue(name, selectedOption.value);
                                    }}
                                    isValid={touched.country && !errors.country}
                                    showAsterisk={isFieldRequired("country")}
                                />
                            </div>
                            <div className="col-6 col-md-4">
                                <PhoneCountryCodeSelect
                                    name="phoneprefix"
                                    value={values.phoneprefix ?? parseInt("")}
                                    isValid={
                                        touched.phone && phoneValid && values.phoneprefix
                                            ? true
                                            : touched.phone && !phoneValid
                                            ? false
                                            : undefined
                                    }
                                    onChange={(selectedOption, name) => {
                                        setFieldTouched(name, true);
                                        setFieldValue(name, selectedOption.value);

                                        if (!validatePhonePrefix(selectedOption.value, values.phone ?? "")) {
                                            setFieldError(name, "Invalid phone prefix");
                                        }
                                    }}
                                    showAsterisk
                                />
                            </div>
                            <div className="col-12 col-md-4">
                                <Input
                                    label={t("book.account.phone")}
                                    required
                                    name="phone"
                                    type="tel"
                                    value={values.phone}
                                    isValid={
                                        touched.phone && phoneValid
                                            ? true
                                            : touched.phone && touched && !phoneValid
                                            ? false
                                            : undefined
                                    }
                                    errorMessage={t("book.account.phone.validation")}
                                    onChange={e => {
                                        if (isNumberWithoutDecimals(e.target.value)) {
                                            handleChange(e);

                                            if (!validatePhone(values.phoneprefix ?? "", e.target.value)) {
                                                setFieldError("phone", "Invalid phone");
                                            }
                                        }
                                    }}
                                    onBlur={handleBlur}
                                    showAsterisk
                                />
                            </div>
                            <div className="col-12 col-md-6">
                                <Input
                                    type="password"
                                    label={t("book.general.password")}
                                    required
                                    name="password"
                                    id="formGroupPassword"
                                    value={values.password}
                                    isValid={isFieldValid(touched.password, errors.password)}
                                    errorMessage={t("book.account.password.validation.length")}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    onDarkBackground={onDarkBackground}
                                    showValidationMessageOnBlurOnly
                                    showAsterisk
                                />
                            </div>
                            <div className="col-12 col-md-6">
                                <Input
                                    type="password"
                                    label={t("book.account.confirm_password")}
                                    required
                                    autoComplete="off"
                                    name="repeatedPassword"
                                    id="formGroupRepeatedPassword"
                                    value={values.repeatedPassword}
                                    isValid={isFieldValid(touched.repeatedPassword, errors.repeatedPassword)}
                                    errorMessage={t("book.account.password.validation")}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    showValidationMessageOnBlurOnly
                                    onDarkBackground={onDarkBackground}
                                    showAsterisk
                                />
                            </div>
                            {termsUrl && (
                                <div className="col-12 u-pt-6 u-pb-6">
                                    <Checkbox
                                        label={<RichText content={t("book.account.terms", [termsUrl])} />}
                                        name="acceptedTerms"
                                        checked={values.acceptedTerms}
                                        onChange={e => setFieldValue("acceptedTerms", e.target.checked)}
                                        onBlur={handleBlur}
                                        onDarkBackground={onDarkBackground}
                                        isValid={isFieldValid(touched.acceptedTerms, errors.acceptedTerms)}
                                    />
                                </div>
                            )}
                            <div className="col-6 u-d-flex u-align-items-center">
                                <p className="u-mb-0">
                                    <span className={classNames({ "u-color-white": onDarkBackground })}>
                                        {t("book.account.already_have_account")}
                                    </span>
                                    <Button
                                        type="tertiary"
                                        onClick={() => {
                                            removeErrorMessages();
                                            if (registrationPage) {
                                                navigate(`/user/login?path=${redirectTo}`);
                                            } else {
                                                dispatch(setSignUpWrapper(true, true, false, redirectTo));
                                            }
                                        }}
                                        buttonClassName="u-ml-6"
                                        onDarkBackground={onDarkBackground}
                                    >
                                        {t("book.menu.login")}
                                    </Button>
                                </p>
                            </div>
                            <div className="col-6 u-d-flex u-justify-content-end">
                                <Button
                                    type="login"
                                    submit
                                    rightIcon={<UserPlusIcon color={style.darkBlueColor} />}
                                    loading={creatingAccountInProgress}
                                    onDarkBackground={onDarkBackground}
                                >
                                    {t("book.account.create")}
                                </Button>
                            </div>
                        </form>
                    );
                }}
            </Formik>
        </div>
    );
};
