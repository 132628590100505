import React from "react";
import { Select, TSelectOption } from "@r360/library";
import { TClientCountry } from "../../store/types";
import useTranslate from "../../hooks/useTranslate";

type TCountrySelect = {
    countries: TClientCountry[];
    name: string;
    value: number | string;
    onChange: (selectedOption: TSelectOption, name: string) => void;
    onDarkBackground?: boolean;
    disabled?: boolean;
    isValid?: boolean;
    showAsterisk?: boolean;
};

export const CountrySelect = ({
    countries,
    name,
    value,
    onChange,
    onDarkBackground,
    disabled,
    isValid,
    showAsterisk,
}: TCountrySelect) => {
    const t = useTranslate();

    const options = countries.map(country => {
        return {
            value: country.code,
            label: country.name,
        };
    });

    return (
        <div className="country-select">
            <Select
                label={t("book.account.country")}
                name={name}
                fullWidth
                defaultValue={value}
                options={options}
                onSelectCallback={(selectedOption: TSelectOption) => onChange(selectedOption, name)}
                onDarkBackground={onDarkBackground}
                placeholder={t("book.account.country")}
                useFirstAsDefault={false}
                type="standard"
                disabled={disabled}
                isValid={isValid}
                showAsterisk={showAsterisk}
            />
        </div>
    );
};
