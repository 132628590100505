import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import ProductView from "../../ProductView/ProductView";
import {
    addProductWithCount,
    updateProductWithCount,
    removeProduct,
    setLatestChangedProductType,
} from "../../../store/actions/checkoutCart";
import { getAgeCategories } from "../../../Helper";
import { Incrementer, Button, Modal, Tag, ResponsiveImage } from "../../UI";
import { Skipass as SkipassImage } from "../../../assets/reservationTypes";
import "./SkipassItem.scss";
import ProductPrice from "../../ProductPrice";
import { textsSelector } from "../../../Selectors";

// Skipass item component
const SkipassItem = props => {
    const {
        title,
        thumbnail,
        price,
        available,
        index,
        poollid,
        arrdate,
        category,
        days,
        type,
        minage,
        maxage,
        images,
    } = props.item;

    const [count, setCount] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const dispatch = useDispatch();
    const currency = useSelector(state => state.clientData?.options?.general?.currency);
    const texts = useSelector(textsSelector);
    const productInCart = props.cartProducts?.find(product => {
        const productId =
            product.poollid + product.arrdate + product.price + product.category + (product.bookstat || "");
        const propsId = poollid + arrdate + price + category;
        return productId === propsId;
    });

    // Set count value if product is present in cart
    useEffect(() => {
        setCount(productInCart?.count);
    }, [productInCart]);

    // Expand accordion in OrderOverview
    const expandOrderOverviewAccordion = () => {
        dispatch(setLatestChangedProductType(parseInt(type)));
    };

    // Handle click on more info button
    const handleClick = () => {
        setShowModal(!showModal);
    };

    // Increment count
    const incrementCount = () => {
        setCount(count + 1);
        dispatch(addProductWithCount(props.item));
        expandOrderOverviewAccordion();
    };

    // Set item count
    const setItemCount = response => {
        if (productInCart) {
            if (response.value === 0) {
                dispatch(removeProduct(productInCart));
            } else {
                dispatch(updateProductWithCount(productInCart, response.value));
            }
        }
        setCount(response.value);
        expandOrderOverviewAccordion();
    };

    // Get age category tags
    const getAgeCategoryTags = () => {
        if (minage !== null && maxage !== null && props.generalAges) {
            const categories = getAgeCategories(minage, maxage, props.generalAges[parseInt(type)]);

            return categories.map((item, index) => {
                return (
                    <Tag key={index} type="small" className="letting-item__period-tag" index={index}>
                        {texts?.[item.title]}
                    </Tag>
                );
            });
        }
    };

    // Render activity input
    const SkipassInput = () => {
        if (count > 0) {
            return (
                <div className="old-skipass-item__incrementer">
                    <Incrementer label={title} value={count} maxValue={available} onChange={setItemCount} size={8} />
                </div>
            );
        }
        return (
            <Button text={texts?.generaladd} onClick={incrementCount} className="old-skipass-item__booking-button" />
        );
    };

    const thumbnailUrl = thumbnail?.url || images?.[0]?.url || undefined;

    return (
        <div className="old-skipass-item__wrapper col-sm-12 col-md-6 col-lg-4">
            <div className="old-skipass-item">
                <div className="old-skipass-item__image-container">
                    <ResponsiveImage
                        height={204}
                        src={thumbnailUrl}
                        placeholder={thumbnailUrl ? undefined : SkipassImage}
                        className="old-skipass-item__image"
                    />
                    {props.promoCode && (
                        <div className="old-skipass-item__promo-tag-container">
                            <Tag type="small" className="old-skipass-item__promo-tag">
                                {texts?.promocodeincluded}
                            </Tag>
                        </div>
                    )}
                </div>
                <div className="old-skipass-item__body">
                    <div className="tag__container">
                        {parseInt(days) > 0 && (
                            <Tag type="small" className="old-skipass-item__period-tag">
                                {days} {days > 1 ? `${texts?.generaldays}` : `${texts?.generalday}`}
                            </Tag>
                        )}
                        {parseInt(days) === 0 && (
                            <Tag type="small" className="old-skipass-item__period-tag">
                                {texts?.seasonpass}
                            </Tag>
                        )}
                        {getAgeCategoryTags()}
                    </div>
                    <h3 className="h5 old-skipass-item__header">{title}</h3>
                    <div className="old-skipass-item__modal-button-container">
                        <Button
                            id={`skipass-more-information-${index}`}
                            text={texts?.moreinformation}
                            className="old-skipass-item__modal-button"
                            onClick={handleClick}
                            hasPopup={true}
                            expanded={showModal}
                            disabled={props.item?.weblong ? false : true}
                        />
                        <SkipassInput />
                    </div>
                    <div className="old-skipass-item__price">
                        <ProductPrice
                            price={props.item.price}
                            ordinaryprice={props.item.ordinaryprice}
                            currency={currency}
                        />
                    </div>
                </div>
            </div>
            <Modal
                show={showModal}
                setShow={setShowModal}
                labelledBy={`skipass-more-information-${props.index}`}
                className="old-skipass-item__modal"
                id={`skipass-more-information-modal-${props.index}`}
            >
                <ProductView
                    weblong={props.item?.weblong ? props.item?.weblong : "-"}
                    title={title}
                    image={thumbnail?.url || SkipassImage}
                    texts={texts}
                />
            </Modal>
        </div>
    );
};

export default SkipassItem;
