import React, { useEffect, useRef, useState } from "react";
import { useSelector, useStore } from "react-redux";
import { useLocation, useMatch, useNavigate } from "react-router-dom";
import {
    activeReservationTypeSelector,
    agesSelector,
    cartCreatedFromReservationSelector,
    languagesSelector,
    optionsSelector,
} from "../../selectors/Selectors";
import { emptyCartProducts } from "../../store/actions/checkoutCart";
import { resetFilterState } from "../../store/actions/filter";
import { clearAllReservationTypeResults } from "../../store/actions/reservationResult";
import { resetLastSearchedTimestamps } from "../../store/actions/search";
import { syncStateToBrowserStorage } from "../../configureStore";
import { setSignUpWrapper } from "../../store/actions/window";
import Menu from "../Menu";
import "./MainHeader.scss";
import { Select, Button, ConfirmationModal } from "@r360/library";
import useQueryString from "../../hooks/QueryString/useQueryString";
import { style, UserIcon, CartIcon, Heading } from "@r360/library";
import MenuSearch from "../MenuSearch";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import { TCartItem, TGeneralAges, TLanguage, TReservationType } from "../../store/types";
import { getNewOrModifiedCartProducts, hasBookstat, isAccommodation } from "../../BusinessUtils";
import useTranslate from "../../hooks/useTranslate";
import PageContainer from "../PageContainer";
import { RootState } from "../..";
import useReservationTypeLink from "../../hooks/useReservationTypeLink";
import { Col, Row } from "react-bootstrap";
import ArrowRight from "../UI/R360Icons/ArrowRight";
import * as Constants from "../../Constants";
import { formatPrice, getCartItemPrice, getFancyAccomodationPrice, getFancyCartItemPrice } from "../../Helper";
import { checkoutUrlSelector } from "../../Selectors";
import classNames from "classnames";
import confirmationDialog from "../Utilities/ConfirmationDialog/ConfirmationDialog";

export const MainHeader: React.FC = () => {
    const location = useLocation();
    const store = useStore();
    const dispatch = useAppDispatch();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const routeMatch = useMatch("/search/:id");
    const currentRouteId = routeMatch?.params?.id;
    const languages = useAppSelector(languagesSelector);
    const t = useTranslate();
    const lang = useAppSelector(state => state.clientData?.lang);
    const newDesignMyPages = useSelector(optionsSelector).layout?.new_design_my_pages;
    const checkoutCart = useAppSelector(state => state.checkoutCart);
    const [hiddenActionLinks, setHiddenActionLinks] = useState(false);
    const productsNotAddedPath = useAppSelector(state => state.checkoutCart.productsNotAddedPath);
    const [queryLang, setQueryLang] = useQueryString("lang");
    const loginSuccessful = useAppSelector(state => state.account.loginSuccessful);
    const cartProducts = getNewOrModifiedCartProducts(useAppSelector(state => state.checkoutCart?.products));
    const activeReservationType = useAppSelector(state => activeReservationTypeSelector(state, currentRouteId));
    const [reservationTypes, setReservationTypes] = useState<TReservationType[]>([]);
    const reservationResult = useAppSelector(state => state.reservationResult);
    const reservationTypesFromStore = useAppSelector(state => state.clientData.reservationtypes);
    const clientData = useAppSelector((state: RootState) => state.clientData);
    const reservationTypeLink = useReservationTypeLink();
    const { useAutoSearch } = (
        activeReservationType?.lid
            ? clientData.reservationtypes.find((x: TReservationType) => x.lid === parseInt(activeReservationType?.lid))
            : {}
    ) as TReservationType;
    const isNewCheckout =
        (location.pathname.includes("/checkout") || location.pathname.includes("/pay")) &&
        clientData.options.layout.new_design_checkout;
    const showMenuSearch = !(activeReservationType?.type === "SKIPASS" && useAutoSearch);
    const [changeLanguageConfirmation, setChangeLanguageConfirmation] = useState<{ show: boolean; language: string }>({
        show: false,
        language: "",
    });
    const { isIframe, isDesktop, isMobile, isTablet, iframeOffsetTop } = useAppSelector(
        (state: RootState) => state.window
    );
    const productsInCart = checkoutCart?.products?.length > 0;
    const cartCreatedFromReservation = useAppSelector(cartCreatedFromReservationSelector);
    const [isCartDropdownOpen, setIsCartDropdownOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        setReservationTypes(() =>
            reservationTypesFromStore
                .filter((item: TReservationType) => !item.hidden)
                .map((reservationType: TReservationType) => ({
                    ...reservationType,
                    link: reservationTypeLink(reservationType),
                    active: reservationType.lid === activeReservationType?.lid,
                }))
                .map((reservationType: TReservationType) => ({
                    ...reservationType,
                    active: reservationType.lid === activeReservationType?.lid,
                }))
        );
    }, [queryLang, activeReservationType, reservationResult, reservationTypesFromStore]);

    useEffect(() => {
        const hideActionLinks = (): boolean => {
            const hidePages = ["/user/login", "/user/registration"];
            if (location && hidePages.includes(pathname)) {
                return true;
            }
            return false;
        };
        setHiddenActionLinks(hideActionLinks());
    }, [location, pathname]);

    // Clear search results when language is changed or when page reloads
    useEffect(() => {
        // Language was changed via URL
        if (queryLang) {
            console.log("Language was changed to '" + queryLang + "', or page reloaded. Clear search results"); // KEEP
            dispatch(resetLastSearchedTimestamps());
        }
    }, [dispatch, queryLang]);

    // Handle click outside of dropdown
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsCartDropdownOpen(false);
            }
        };

        if (isCartDropdownOpen) {
            document.addEventListener("click", handleClickOutside, true);
        } else {
            document.removeEventListener("click", handleClickOutside, true);
        }

        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    }, [isCartDropdownOpen]);

    const changeLanguage = async (languageCode: string) => {
        if (productsInCart) {
            dispatch(emptyCartProducts());
        }

        dispatch(clearAllReservationTypeResults());
        dispatch(resetLastSearchedTimestamps());
        dispatch(resetFilterState());

        // Trigger sync of Redux state to browser storage explicitly to avoid race condition with throttle-time.
        syncStateToBrowserStorage(store)();

        setQueryLang(languageCode);
        window.location.reload();
    };

    const getCartItemsCount = () => {
        if (cartProducts) {
            return cartProducts.reduce((acc: number, item: TCartItem) => {
                if (item.count) {
                    return acc + item.count;
                }
                return acc + 1;
            }, 0);
        }
        return 0;
    };

    const cartItemsCount = getCartItemsCount();

    const getHeaderTitle = () => {
        if (location.pathname.includes("/checkout/summary")) {
            return t("book.checkout.summary");
        }
        if (location.pathname.includes("/pay")) {
            return t("book.checkout.pay_order");
        }
        if (location.pathname.includes("/checkout")) {
            return t("book.checkout.connect_guests");
        }
    };

    const handleLoginClick = async () => {
        if (productsNotAddedPath) {
            const response = await confirmationDialog({
                heading: t("book.leave_warning_header"),
                description: t("book.leave_warning_text"),
                confirmBtnText: t("book.leave_warning_confirm"),
                cancelBtnText: t("book.leave_warning_cancel"),
            });

            if (!response) {
                return;
            }
        }

        dispatch(setSignUpWrapper(true, true, false, newDesignMyPages ? "/my-pages" : "/account"));
    };

    return (
        <>
            <ConfirmationModal
                open={!!changeLanguageConfirmation.language}
                heading={t("book.confirmations.change_language.title")}
                description={t("book.confirmations.change_language.description")}
                confirmBtnText={t("book.confirmations.change_language.ok")}
                cancelBtnText={t("book.general.cancel")}
                removing={true}
                onClose={() => setChangeLanguageConfirmation({ show: false, language: "" })}
                onConfirmClick={() => changeLanguage(changeLanguageConfirmation.language)}
                onCancelClick={() => setChangeLanguageConfirmation({ show: false, language: "" })}
                {...(isIframe && isDesktop && { fromTop: 120 + iframeOffsetTop + "px" })}
                {...(isIframe &&
                    (isMobile || isTablet) && {
                        fromTop: 80 + iframeOffsetTop + "px",
                    })}
            />
            <div className="main-header-wrapper">
                <PageContainer minHeight={false}>
                    <div className="main-header">
                        <div className="main-header-menu">
                            {!isNewCheckout ? (
                                <Menu reservationTypes={reservationTypes} />
                            ) : (
                                <Heading onDarkBackground type="h1" className="u-pt-24 u-pt-lg-0">
                                    {` ${t("book.checkout")}  `}
                                    <span className="main-header-sub-heading">{getHeaderTitle()}</span>
                                </Heading>
                            )}
                            <div className="main-header-right-section">
                                <div className="main-header-language-select">
                                    {languages.length >= 2 && (
                                        <Select
                                            type="menu-secondary"
                                            defaultValue={lang}
                                            onSelectCallback={({ value }: { value: string }) =>
                                                productsInCart
                                                    ? setChangeLanguageConfirmation({ show: true, language: value })
                                                    : changeLanguage(value)
                                            }
                                            options={languages.map((language: TLanguage) => ({
                                                value: language.code,
                                                label: language.language,
                                            }))}
                                            hideSelectedFromOptions
                                        />
                                    )}
                                </div>
                                <div className="main-header-button-group">
                                    <div className="main-header__cart-button" ref={dropdownRef}>
                                        <Button
                                            buttonClassName="main-header-button-group-button"
                                            badgeCount={cartItemsCount > 0 ? cartItemsCount : null}
                                            type="secondary"
                                            rightIcon={
                                                <CartIcon
                                                    color={
                                                        cartItemsCount <= 0 ? style.darkGreyColor : style.brandBlueColor
                                                    }
                                                />
                                            }
                                            onClick={() => setIsCartDropdownOpen(prevState => !prevState)}
                                            disabled={cartItemsCount <= 0 && !cartCreatedFromReservation}
                                        >
                                            {t("book.menu.cart")}
                                        </Button>
                                        {isCartDropdownOpen && (
                                            <CartDropdown
                                                setIsCartDropdownOpen={setIsCartDropdownOpen}
                                                cartProducts={cartProducts}
                                            />
                                        )}
                                    </div>
                                    {!hiddenActionLinks &&
                                        (loginSuccessful ? (
                                            <Button
                                                buttonClassName="main-header-button-group-button"
                                                type="my-account"
                                                onClick={() => navigate(newDesignMyPages ? "/my-pages" : "/account")}
                                            >
                                                {newDesignMyPages ? t("book.my_pages") : t("book.menu.my_account")}
                                            </Button>
                                        ) : (
                                            <Button
                                                buttonClassName="main-header-button-group-button"
                                                type="login"
                                                onClick={() => handleLoginClick()}
                                                rightIcon={<UserIcon color={style.darkBlueColor} />}
                                            >
                                                {t("book.menu.login")}
                                            </Button>
                                        ))}
                                </div>
                            </div>
                        </div>
                        {activeReservationType && (
                            <>
                                <Heading type="h1" className="u-color-custom-primary-contrast">
                                    {activeReservationType.description}
                                </Heading>
                                <MenuSearch reservationTypes={reservationTypes} showMenuSearch={showMenuSearch} />
                            </>
                        )}
                    </div>
                </PageContainer>
            </div>
        </>
    );
};

type TCartDropdown = {
    setIsCartDropdownOpen: (arg0: boolean) => void;
    cartProducts: TCartItem[];
};

const CartDropdown = ({ setIsCartDropdownOpen, cartProducts }: TCartDropdown) => {
    const navigate = useNavigate();
    const currency = useAppSelector(state => state.clientData?.options?.general?.currency);
    const generalAges: TGeneralAges = useSelector(agesSelector);
    const t = useTranslate();
    const checkoutUrl = useAppSelector(checkoutUrlSelector);
    const location = useLocation();

    let totalPrice = 0;

    const newOrModifiedCartProducts = getNewOrModifiedCartProducts(cartProducts);
    const newOrModifiedAccommodationCartProducts = newOrModifiedCartProducts.filter(isAccommodation);

    // Get all extras for accommodations in cart.
    const accommodationExtrasToSum = newOrModifiedAccommodationCartProducts
        .flatMap((accommodation: TCartItem) => accommodation.info?.extras || [])
        .filter((extra: TCartItem) => !hasBookstat(extra) || extra.additional);

    // Add values to variables
    newOrModifiedCartProducts.forEach((item: TCartItem) => {
        totalPrice += getCartItemPrice(item, generalAges) ?? 0;
    });

    // Add costs for accommodation extras
    const accommodationExtrasToSumFiltered = accommodationExtrasToSum.filter((extra: TCartItem) => {
        if (extra.rules.mandatory_web) {
            return false;
        } else {
            return (extra.quantity !== 0 && extra.rules.max_qty !== 0) || extra.checked === true;
        }
    });

    accommodationExtrasToSumFiltered.forEach((item: TCartItem) => {
        totalPrice += item.additional?.price || item.price;
    });

    const onToCartClick = () => {
        if (location.pathname === "/cart") {
            navigate(checkoutUrl);
        } else {
            navigate("/cart");
        }
        setIsCartDropdownOpen(false);
    };

    const getExtrasPrice = (item: TCartItem) => {
        const extras = item.info.extras.filter(extra => extra.quantity > 0);
        const extrasPrice = extras?.reduce((prev, curr) => {
            if (curr.rules.mandatory_web) {
                return prev * curr.quantity;
            }

            return prev + (curr.additional?.price ?? curr.price);
        }, 0);
        return extrasPrice;
    };

    const connectedProductPrice = (item: TCartItem) => {
        const connectedProducts = cartProducts.filter(cartItem => cartItem.connectedProductId === item.cartItemId);

        return connectedProducts.reduce((sum, product) => {
            if (product) {
                return sum + product.aprice * product.count;
            }
            return sum;
        }, 0);
    };

    return (
        <div
            className={classNames("main-header__cart-dropdown", {
                "main-header__cart-dropdown--loginPage": location.pathname.includes("login"),
            })}
        >
            <div className="main-header__cart-dropdown-header">
                <Heading type={"h6"}>{t("book.cart.title")}</Heading>
            </div>
            <div
                className={classNames("main-header__cart-dropdown-content", {
                    "main-header__cart-dropdown-last": cartProducts.length > 4,
                })}
            >
                {cartProducts
                    .filter(cartItem => !cartItem.connectedProductId)
                    .map((cartItem: TCartItem, index: number) => (
                        <Row key={index} className="main-header__cart-dropdown-row">
                            <Col md={12} className="u-d-flex u-justify-content-between">
                                <div>
                                    <span className="u-fw-medium u-mr-12">
                                        {cartItem.groupTitle || cartItem.pooldesc4}
                                    </span>
                                </div>
                                <div style={{ minWidth: "100px" }}>
                                    <span style={{ float: "right" }}>
                                        {cartItem.kind === Constants.productTypeNames.ACCOMMODATION
                                            ? getFancyAccomodationPrice(
                                                  cartItem,
                                                  getExtrasPrice(cartItem) + connectedProductPrice(cartItem),
                                                  currency
                                              )
                                            : getFancyCartItemPrice(cartItem, currency, generalAges)}
                                    </span>
                                </div>
                            </Col>
                            <Col>
                                <span className="u-fs-12">{`${
                                    cartItem.kind === Constants.productTypeNames.ACCOMMODATION ||
                                    cartItem.kind === Constants.productTypeNames.PACKAGE
                                        ? cartItem.info.guests.totalGuests
                                        : cartItem.count
                                        ? cartItem.count
                                        : cartItem.quantity
                                } ${
                                    cartItem.count > 1 ||
                                    cartItem.quantity > 1 ||
                                    (cartItem.info?.guests?.totalGuests && cartItem.info.guests.totalGuests > 1)
                                        ? t("book.general.guests")
                                        : t("book.general.guest")
                                }`}</span>
                            </Col>
                        </Row>
                    ))}
            </div>
            {cartProducts.length > 4 && <div className="main-header__dropdown-footer-gradient"></div>}
            <div className="main-header__cart-dropdown-footer">
                <p className="u-fw-medium">{t("book.general.total")}</p>
                <p className="u-fw-medium">{formatPrice(totalPrice, currency)}</p>
            </div>
            <Button onClick={onToCartClick} fullWidth type="secondary" rightIcon={<ArrowRight />}>
                {location.pathname === "/cart" ? t("book.cart.summary.goto_checkout") : t("book.general.go_to_cart")}
            </Button>
        </div>
    );
};
