import React from "react";
import { Select, TSelectOption } from "@r360/library";
import phoneCountryCodes from "./PhoneCountryCodes.json";
import useTranslate from "../../hooks/useTranslate";

type TPhoneCountryCodesSelect = {
    name: string;
    value: number;
    onChange: (selectedOption: TSelectOption, name: string) => void;
    onDarkBackground?: boolean;
    isValid?: boolean;
    disabled?: boolean;
    showAsterisk?: boolean;
};

export const PhoneCountryCodeSelect = ({
    name,
    value,
    onChange,
    onDarkBackground,
    isValid,
    disabled = false,
    showAsterisk = false,
}: TPhoneCountryCodesSelect) => {
    const t = useTranslate();

    const options = Object.values(phoneCountryCodes)
        .flat()
        .map(country => {
            return {
                value: country.phoneCode,
                label: `${country.countryName} (+${country.phoneCode})`,
            };
        });

    return (
        <div className="phone-country-code-select">
            <Select
                label={t("book.account.country_code")}
                name={name}
                fullWidth
                defaultValue={value}
                options={options}
                onSelectCallback={(selectedOption: TSelectOption) => onChange(selectedOption, name)}
                onDarkBackground={onDarkBackground}
                placeholder={t("book.account.country_code")}
                useFirstAsDefault={false}
                type="standard"
                isValid={isValid}
                disabled={disabled}
                showAsterisk={showAsterisk}
                showPlaceholderInOptions={!showAsterisk}
            />
        </div>
    );
};
