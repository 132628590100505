import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { retrieveUserPassword } from "../../store/actions/account";
import { Alert } from "../UI";
import "./ForgotPassword.scss";
import useTranslate from "../../hooks/useTranslate";

const ForgotPassword = ({ loginLinkCallback }) => {
    const t = useTranslate();
    const retrievedPassword = useSelector(state => state.account.retrievedPassword);
    const [retrieveMessage, setRetrieveMessage] = useState(null);
    const [username, setUserName] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        setRetrieveMessage(retrievedPassword);
    }, [retrievedPassword]);

    const passwordForgotten = () => {
        return (
            <div className="forgotPassword">
                {!retrieveMessage?.result && (
                    <>
                        <div className="text-center">
                            <h1 className="h1 page-header">{t("passwordforgotten")}</h1>
                        </div>
                        {retrieveMessage !== undefined && retrieveMessage !== null && !retrieveMessage?.result && (
                            <Alert type="warning">
                                <p>
                                    {t("foundnouser")} {username}.
                                </p>
                            </Alert>
                        )}

                        <form
                            onSubmit={e => {
                                e.preventDefault();
                                username && dispatch(retrieveUserPassword(username));
                            }}
                        >
                            <div>
                                <label htmlFor="username" className="visuallyhidden">
                                    {t("generalemail")}
                                </label>
                                <input
                                    type="text"
                                    placeholder={t("generalemail")}
                                    value={username}
                                    id="username"
                                    name="username"
                                    className="form-control forgotPassword__username"
                                    onChange={e => {
                                        setUserName(e.target.value);
                                    }}
                                />
                            </div>
                            <div className="d-grid forgotPassword__submit-container">
                                <input
                                    type="submit"
                                    className="btn btn-primary forgotPassword__submit"
                                    value={t("send")}
                                />
                            </div>
                        </form>
                    </>
                )}
                {retrieveMessage?.result && (
                    <Alert type="success">
                        <p>{t("book.retrievepasswordsuccess").replace("#username#", username)}</p>
                    </Alert>
                )}
                <div className="text-center">
                    <a
                        className="forgotPassword__login-link"
                        onClick={e => {
                            e.preventDefault();
                            setRetrieveMessage("");
                            loginLinkCallback ? loginLinkCallback() : navigate("/user/login");
                        }}
                    >
                        {t("book.gotologin")}
                    </a>
                </div>
            </div>
        );
    };

    return passwordForgotten();
};
export default ForgotPassword;
