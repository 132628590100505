import produce from "immer";
import * as types from "../actions/types";

const INITIAL_STATE = {
    lastSearched: {},
    defaultLifetime: 20, // in minutes
    customLifetimes: {
        // lifetime per reservation type in minutes
        ACCOMMODATION: null,
        ACTIVITY: null,
        LETTING: null,
        RENTAL: null,
        SKIPASS: null,
        PACKAGE: null,
        OTHER: null,
    },
    lastSearchedDateSpan: {
        type: "",
        resvtypeId: null,
        timestamp: "",
        arrdate: "",
        depdate: "",
    },
    selectedSortAccommodation: {
        type: "",
        sort: "standard",
    },
};

const reducer = produce((draft, action) => {
    switch (action.type) {
        case types.SET_LAST_SEARCHED_TIMESTAMP:
            draft.lastSearched[action.resvtype] = action.timestamp;
            break;
        case types.RESET_LAST_SEARCHED_TIMESTAMP_BY_RESVTYPE:
            draft.lastSearched[action.resvtype] = null;
            break;
        case types.SET_LAST_SEARCHED_DATE_SPAN:
            draft.lastSearchedDateSpan = action;
            break;
        case types.RESET_LAST_SEARCHED_DATE_SPAN:
            return INITIAL_STATE;
        case types.SET_SELECTED_SORT_ACCOMMODATION:
            draft.selectedSortAccommodation = action;
            break;
        case types.RESET_SELECTED_SORT_ACCOMMODATION:
            return INITIAL_STATE;
        case types.RESET_LAST_SEARCHED_TIMESTAMPS:
            return INITIAL_STATE;

        default:
            break;
    }
}, INITIAL_STATE);
export default reducer;
