import React, { useEffect, useState } from "react";
import { Card, Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { guestsOnTravelSelector } from "../../../selectors/Selectors";
import { updateProduct } from "../../../store/actions/checkoutCart";
import { InformationBlock, Tag } from "../../UI";
import { v4 as uuidv4 } from "uuid";
import "./BikeRental.scss";

const BikeRental = props => {
    const { products, texts, type } = props;
    const dispatch = useDispatch();
    const guestsOnTravel = useSelector(guestsOnTravelSelector);
    const lettinghelpimage = useSelector(state => state.clientData.options.general?.lettinghelpimage);
    const deliveryPoints = useSelector(state => state.clientData.deliveryPoints);
    const [showModal, setShowModal] = useState(false);
    const rentals = products.filter(product => Number(product.type) === type.lid);

    useEffect(() => {
        rentals.forEach(rental => {
            // eslint-disable-next-line no-empty
            if (rental.items && rental.items.length === rental.count) {
            } else {
                if (rental.items) {
                    let rest = rental.count - rental.items.length;
                    let items = rental.items;
                    if (rest > 0) {
                        for (let index = 0; index < rest; index++) {
                            const uuid = uuidv4();
                            items.push({ id: uuid, guestId: null });
                        }
                    } else {
                        rest = rest * -1;
                        for (let index = 0; index < rest; index++) {
                            items.pop();
                        }
                    }
                    dispatch(updateProduct(rental, { key: "items", value: items }));
                } else {
                    let items = [];
                    for (let count = 0; count < rental.count; count++) {
                        const uuid = uuidv4();
                        items.push({ id: uuid, guestId: null });
                    }
                    dispatch(updateProduct(rental, { key: "items", value: items }));
                }
            }
        });
    }, [dispatch, rentals]);

    const handleProductChange = (event, product, productItem) => {
        const { name } = event.target;
        const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
        dispatch(
            updateProduct(product, {
                key: "items",
                value: product.items.map(item => (item.id === productItem.id ? { ...item, [name]: value } : item)),
            })
        );
    };

    return (
        <div className="bikeRental pt-4">
            <InformationBlock className="mb-4">{texts?.missingguestinformation}</InformationBlock>

            <div>
                {rentals.map(rental => {
                    return (
                        !rental.detlid &&
                        rental.items?.map((item, index) => {
                            return (
                                <div key={item.id + index} className="bikeRental__item">
                                    <Row>
                                        <Form.Group as={Col} md="auto">
                                            <h5>{rental.title}</h5>
                                        </Form.Group>
                                    </Row>
                                    <Row className="bikeRental__insurance">
                                        <Form.Group as={Col} md="3" lg="3" controlId="validationCustom01">
                                            <Form.Control
                                                required
                                                as="select"
                                                name="guestId"
                                                value={item.guestId || ""}
                                                onChange={event => {
                                                    handleProductChange(event, rental, item);
                                                }}
                                            >
                                                <option value="" disabled>
                                                    {texts?.generalguest}*
                                                </option>

                                                {Object.entries(guestsOnTravel).map(guest => {
                                                    return (
                                                        <option key={guest[1].id} value={guest[1].id}>
                                                            {guest[1].firstname + " " + guest[1].lastname}
                                                        </option>
                                                    );
                                                })}
                                            </Form.Control>
                                        </Form.Group>

                                        {rental.priceInsurance &&
                                            rental.price(
                                                <>
                                                    <Form.Group
                                                        as={Col}
                                                        md="float-start"
                                                        controlId={"formBasicCheckbox" + item.id}
                                                    >
                                                        <div className="checkbox">
                                                            <label
                                                                htmlFor={"formBasicCheckbox" + item.id}
                                                                className="checkbox__label"
                                                            >
                                                                <span className="checkbox__label-text">
                                                                    {texts?.addinsurance}
                                                                </span>
                                                                <input
                                                                    type="checkbox"
                                                                    id={"formBasicCheckbox" + item.id}
                                                                    name="withInsurance"
                                                                    className="checkbox__input"
                                                                    checked={item.withInsurance}
                                                                    onChange={event => {
                                                                        handleProductChange(event, rental, item);
                                                                    }}
                                                                />
                                                                <span
                                                                    className="checkbox__checkmark"
                                                                    aria-hidden="true"
                                                                />
                                                            </label>
                                                        </div>
                                                    </Form.Group>
                                                    <Form.Group as={Col}>
                                                        <Tag type="medium" className="bikeRental__insurance-price-tag">
                                                            {rental.priceInsurance - rental.price + " " + rental.curr}
                                                        </Tag>
                                                    </Form.Group>
                                                </>
                                            )}
                                        <Form.Group as={Col} md="3" controlId="validationCustom05">
                                            <Form.Control
                                                required
                                                disabled={!item.guestId}
                                                as={deliveryPoints?.length > 0 ? "select" : null}
                                                name="deliveryPoint"
                                                value={item.deliveryPoint || ""}
                                                onChange={event => {
                                                    handleProductChange(event, rental, item);
                                                }}
                                            >
                                                <option value="" disabled>
                                                    {texts?.delivery}*
                                                </option>
                                                {deliveryPoints.map((deliveryPoint, index) => {
                                                    return (
                                                        <option
                                                            key={deliveryPoint.shopid + index}
                                                            value={deliveryPoint.namenative}
                                                        >
                                                            {deliveryPoint.namedisplay}
                                                        </option>
                                                    );
                                                })}
                                            </Form.Control>
                                        </Form.Group>
                                    </Row>
                                </div>
                            );
                        })
                    );
                })}
            </div>

            <Modal show={showModal} setShow={setShowModal}>
                <div className="d-flex justify-content-center">
                    <Card className="border-0" style={{ width: "18rem" }}>
                        <Card.Img variant="top" src={lettinghelpimage} />
                        <Card.Body>
                            <Card.Title>
                                {texts?.lettinghelpinformationheader || texts?.skirentallettinghelpinformation}
                            </Card.Title>
                            <Card.Text>{texts.lettinghelpinformation || texts?.noinformation}</Card.Text>
                        </Card.Body>
                    </Card>
                </div>
            </Modal>
        </div>
    );
};

export default BikeRental;
