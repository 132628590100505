import React from "react";
import "./Tag.scss";

const tag = props => {
    const className = props.className ? ` ${props.className}` : "";
    let classType;

    switch (props.type) {
        case "small":
            classType = "tag__small";
            break;
        case "medium":
            classType = "tag__medium";
            break;
        default:
            classType = "tag";
            break;
    }

    return (
        <div className={`${classType}${className}`}>
            <div>{props.children}</div>
        </div>
    );
};

export default tag;
