import * as React from "react";
import defautIconStats from "../Icon.types";
import Theme from "../../Theme";

const Select = ({ color = Theme.palette.primary, size = defautIconStats.size, iconClass = defautIconStats.width }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 204.8 204.8"
            style={{ height: size, width: size }}
            width="512pt"
            height="512pt"
            className={iconClass}
        >
            <g>
                <g>
                    <path
                        fill={color}
                        d="M180.907,20.48H23.893c-1.886,0-3.413,1.527-3.413,3.413v157.013c0,1.886,1.527,3.413,3.413,3.413h157.013
            c1.886,0,3.413-1.527,3.413-3.413V23.893C184.32,22.008,182.793,20.48,180.907,20.48z M177.494,177.493h-0.001H27.307V27.307
            h150.187V177.493z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        fill={color}
                        d="M23.893,0c-1.886,0-3.413,1.527-3.413,3.413v10.24c0,1.886,1.527,3.413,3.413,3.413s3.413-1.527,3.413-3.413V3.414
            C27.307,1.527,25.779,0,23.893,0z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        fill={color}
                        d="M13.653,20.48H3.413C1.527,20.48,0,22.008,0,23.893s1.527,3.413,3.413,3.413h10.24c1.886,0,3.413-1.527,3.413-3.413
            S15.539,20.48,13.653,20.48z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        fill={color}
                        d="M13.653,177.493H3.413c-1.886,0-3.413,1.527-3.413,3.413c0,1.886,1.527,3.413,3.413,3.413h10.24
            c1.886,0,3.413-1.527,3.413-3.413C17.066,179.02,15.539,177.493,13.653,177.493z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        fill={color}
                        d="M23.893,187.733c-1.886,0-3.413,1.528-3.413,3.413v10.24c0,1.886,1.527,3.413,3.413,3.413s3.413-1.527,3.413-3.413v-10.24
            C27.307,189.261,25.779,187.733,23.893,187.733z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        fill={color}
                        d="M180.907,187.733c-1.886,0-3.413,1.528-3.413,3.413v10.24c0,1.886,1.527,3.413,3.413,3.413
            c1.886,0,3.413-1.527,3.413-3.413v-10.24C184.32,189.261,182.793,187.733,180.907,187.733z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        fill={color}
                        d="M201.387,177.493h-10.24c-1.886,0-3.413,1.527-3.413,3.413c0,1.886,1.528,3.413,3.413,3.413h10.24
            c1.886,0,3.413-1.527,3.413-3.413C204.8,179.02,203.273,177.493,201.387,177.493z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        fill={color}
                        d="M201.387,20.48h-10.24c-1.886,0-3.413,1.527-3.413,3.413s1.528,3.413,3.413,3.413h10.24c1.886,0,3.413-1.527,3.413-3.413
            S203.273,20.48,201.387,20.48z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        fill={color}
                        d="M180.907,0c-1.886,0-3.413,1.527-3.413,3.413v10.24c0,1.886,1.527,3.413,3.413,3.413c1.886,0,3.413-1.527,3.413-3.413
            V3.414C184.32,1.527,182.793,0,180.907,0z"
                    />
                </g>
            </g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
        </svg>
    );
};

export default Select;
