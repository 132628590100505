import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import AxiosClient from "../../../API/AxiosClient";
import { Alert } from "../../UI";
import "./OldResetPassword.scss";
import { textsSelector } from "../../../Selectors";

const ResetPassword = ({ token }) => {
    const texts = useSelector(textsSelector);
    const [newPassword, setNewPassword] = useState("");
    const [repeatNewPassword, setRepeatNewPassword] = useState("");
    const [passwordHasChanged, setPasswordHasChanged] = useState(false);
    const [tokenVerified, setTokenVerified] = useState(null);
    const [formHasBeenSubmitted, setFormHasBeenSubmitted] = useState(false);

    useEffect(() => {
        AxiosClient.get(`users/verify_password_reset_token?token=${token}`)
            .then(() => {
                setTokenVerified(true);
            })
            .catch(() => {
                setTokenVerified(false);
            });
    }, [token]);

    const handleSubmit = e => {
        e.preventDefault();

        if (passwordsAreEqual() && passwordsHaveValidLength() && tokenVerified) {
            const sendPasswordWithToken = async () => {
                try {
                    const response = await AxiosClient.put("users/reset_password", {
                        password: newPassword,
                        repeatpassword: repeatNewPassword,
                        token: token,
                    });

                    if (response) {
                        setPasswordHasChanged(true);
                    }
                } catch (error) {
                    console.error(error);
                    setTokenVerified(false);
                }
            };

            sendPasswordWithToken();
        }

        setFormHasBeenSubmitted(true);
    };

    const passwordsAreEqual = () => {
        return newPassword === repeatNewPassword;
    };

    const passwordsHaveValidLength = () => {
        return newPassword.length >= 8 && repeatNewPassword.length >= 8;
    };

    const changePasswordForm = () => {
        return (
            <div className="ResetPassword">
                <h1>{texts?.generalchangepassword}</h1>
                {formHasBeenSubmitted && (!passwordsAreEqual() || !passwordsHaveValidLength()) && (
                    <Alert type="warning">
                        <ul>
                            {!passwordsAreEqual() && <li>{texts?.passworddonotmatch}</li>}
                            {!passwordsHaveValidLength() && <li>{texts?.validationpasswordtooshort}</li>}
                        </ul>
                    </Alert>
                )}

                {!passwordHasChanged && tokenVerified !== false && (
                    <form className="ResetPassword__form" onSubmit={handleSubmit}>
                        <div>
                            <input
                                type="password"
                                placeholder={texts?.newpassword}
                                value={newPassword}
                                name="newPassword"
                                className="form-control ResetPassword__newpassword"
                                onChange={e => {
                                    setNewPassword(e.target.value);
                                }}
                            />
                        </div>
                        <div>
                            <input
                                type="password"
                                placeholder={texts?.repeatnewpassword}
                                value={repeatNewPassword}
                                name="repeatNewPassword"
                                className="form-control ResetPassword__repeatnewpassword"
                                onChange={e => {
                                    setRepeatNewPassword(e.target.value);
                                }}
                            />
                        </div>
                        <div className="d-grid">
                            <input
                                type="submit"
                                className="btn btn-primary ResetPassword__submit"
                                value={texts?.save}
                            />
                        </div>
                    </form>
                )}
                {passwordHasChanged && (
                    <Alert type="success">
                        <p>
                            {texts?.generalpasswordwaschanged}{" "}
                            <div>
                                <Link to="/user/login" className="ResetPassword__loginlink">
                                    {texts?.login}
                                </Link>
                            </div>
                        </p>
                    </Alert>
                )}
                {tokenVerified === false && (
                    <Alert type="warning">
                        <p>
                            {texts?.tokenhasexpired}
                            <Link to="/user/forgotpassword" className="button--link ResetPassword__resetPasswordAgain">
                                {texts?.resetpasswordagain}
                            </Link>
                        </p>
                    </Alert>
                )}
            </div>
        );
    };
    return changePasswordForm();
};

export default ResetPassword;
