import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Form } from "react-bootstrap";
import { confirmable, createConfirmation } from "react-confirm";
import classnames from "classnames";
import "./PromptDialog.scss";

/**
 * Open prompt dialog
 *
 * Usage:
 * const openBookingNotePrompt = () => {
 *   const [status, value] = await promptDialog({
 *     title: 'Booking note',
 *     description: 'Enter an additional note to your booking',
 *     okLabel: 'Save note',
 *     cancelLabel: 'Cancel',
 *     initialValue: 'The initial value',
 *     placeholder: 'Enter note here...',
 *  })
 * }
 *
 * If status is true, the OK-button was pressed.
 * If status is false, the Cancel-button or the Esc-key was pressed.
 * The value contains the entered text. If status is false, an empty string is returned.
 *
 * @param {object} props
 * @returns Promise
 */
const Prompt = props => {
    const {
        okLabel = "OK",
        cancelLabel = "Cancel",
        title,
        description,
        initialValue = "",
        placeholder = "",
        show,
        proceed,
        enableEscape = true,
        maxLength,
    } = props;

    const [value, setValue] = useState(initialValue);

    return (
        <div>
            <Modal
                show={show}
                onHide={() => proceed(false)}
                backdrop={enableEscape ? true : "static"}
                keyboard={enableEscape}
            >
                {title && (
                    <Modal.Header>
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>
                )}
                <Modal.Body className="prompt-dialog__body">
                    {description && <div className="mb-4">{description}</div>}

                    <Form.Control
                        as="textarea"
                        value={value}
                        onChange={e => setValue(e.target.value)}
                        rows="6"
                        placeholder={placeholder}
                        maxLength={maxLength || null}
                    />
                </Modal.Body>
                <Modal.Footer>
                    {maxLength && (
                        <div
                            className={classnames("fw-light me-auto", {
                                "text-muted": value.length < maxLength,
                                "text-danger": value.length === maxLength,
                            })}
                        >
                            {value.length} / {maxLength}
                        </div>
                    )}
                    <Button
                        onClick={() => proceed([false, ""])}
                        variant="link"
                        size="md"
                        className="fw-light text-primary p-0"
                    >
                        {cancelLabel}
                    </Button>
                    <Button
                        className="prompt-dialog__buttons-ok"
                        variant="primary"
                        onClick={() => proceed([true, value])}
                    >
                        {okLabel}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

Prompt.propTypes = {
    okLabel: PropTypes.string,
    cancelLabel: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    initialValue: PropTypes.string,
    placeholder: PropTypes.string,
    enableEscape: PropTypes.bool,

    // Below props are provided by confirmable, don't change.
    show: PropTypes.bool,
    proceed: PropTypes.func,
};

export default function promptDialog(options = {}) {
    return createConfirmation(confirmable(Prompt))(options);
}
