import React, { useEffect, useState } from "react";
import { Alert as BootstrapAlert, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import ChangeSearch from "../components/ChangeSearch/ChangeSearch";
import ReservationTypesLoader from "../components/ContentLoaderOld/ReservationTypesLoader";
import PackageResultList from "../components/PackageResultList/PackageResultList";
import ReservationTypeNoResults from "../components/ReservationTypeNoResults";
import { Alert, Button, Icon } from "../components/UI";
import * as Constants from "../Constants";
import useChangeSearch from "../hooks/useChangeSearch";
import { optionsSelector, textsSelector } from "../Selectors";
import { resetLastSearchedTimestamps } from "../store/actions/search";

const PackageResultPage = props => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const isMobile = useSelector(state => state.window.isMobile);
    const lid = props.reservationType;
    const isLoading = useSelector(state => state.axiosStatus.loading);
    const texts = useSelector(textsSelector);
    const reservationResult = useSelector(state => state.reservationResult[lid]);
    const [redirect, setRedirect] = useState(false);
    const [packagesCount, setPackagesCount] = useState(0);
    const newDesignMenu = useSelector(optionsSelector).layout?.new_design_menu;

    useChangeSearch({
        reservationResultSearch: reservationResult?.search,
        reservationTypeId: lid,
        skipGuestsOnSearchWithPrice: false,
        allowSearchWithoutPrice: true,
    });

    useEffect(() => {
        if (location?.state?.redirect) {
            setRedirect(true);
        }

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setPackagesCount(Object.values(reservationResult?.groups || {}).length);
    }, [reservationResult?.groups]);

    const resetLastSearchedTimestampsAndReloadPage = () => {
        dispatch(resetLastSearchedTimestamps());
        window.location.reload();
    };

    const handleShowAllOffering = () => {
        navigate(`/search/${lid}?checkPrice=0`);
    };

    function renderResults() {
        if (packagesCount > 0 && !isLoading) {
            return (
                <>
                    <h3 className="page-header mt-5" aria-live="polite">
                        {reservationResult?.search?.checkPriceAndAvailability
                            ? `${texts["package.packages_and_campaigns"]} (${packagesCount})`
                            : `${texts["package.all_packages_and_campaigns"]} (${packagesCount})`}
                    </h3>
                    <PackageResultList
                        reservationType={props.reservationType}
                        reservationTypeResult={reservationResult?.groups}
                    />
                </>
            );
        } else if (
            // no hits
            (reservationResult?.hitsMatchingFilter === "0" || !packagesCount) &&
            !isLoading
        ) {
            return <ReservationTypeNoResults resvtype={Constants.productTypeNames.PACKAGE} />;
        } else if (reservationResult?.error && !isLoading) {
            return (
                <BootstrapAlert variant="light" className="letting-view__alert--fetch-error mt-3">
                    <div className="letting-view__alert-icon">
                        <Icon name="FaExclamationTriangle" size={40} color="#e04f5f" />
                    </div>
                    <div className="letting-view__alert-text">
                        <h3 className="h6">{texts?.noresultnetworkheader}</h3>
                        <p>{texts?.noresultnetworkbody}</p>
                        <Button
                            onClick={resetLastSearchedTimestampsAndReloadPage}
                            className="button--text-only letting-view__alert-button"
                        >
                            <Icon name="FaUndoAlt" size={18} color="#333333" />
                            <span className="sr-only">
                                {texts?.noresultgetdata} {props.header}
                            </span>
                        </Button>
                    </div>
                </BootstrapAlert>
            );
        } else {
            // showing shadow loaders
            return (
                <div className="row mt-5">
                    {[1, 2, 3].map((item, index) => {
                        return (
                            <div className="col-sm-12 col-md-6 col-lg-4" key={"loader" + index}>
                                <ReservationTypesLoader />
                            </div>
                        );
                    })}
                </div>
            );
        }
    }

    return (
        <Container>
            <div className="row">
                <div className="col">
                    <header style={{ marginBottom: "2rem" }}>
                        {texts?.packageinformation && texts?.packageinformation.trim() !== "" && (
                            <BootstrapAlert variant={"light"} className="activities-view__alert--info">
                                <div className="results-view__resvtype-message">
                                    <div className="activities-view__alert-icon">
                                        <Icon name="FaRegDotCircle" size={16} color="#f1c40f" />
                                    </div>
                                    <div className="activities-view__alert-text">
                                        <p>{texts?.packageinformation}</p>
                                    </div>
                                </div>
                            </BootstrapAlert>
                        )}
                    </header>

                    {redirect && (
                        <Container>
                            <Row className="mb-3">
                                <Alert type="warning" dismissable>
                                    <p>{texts?.nohitsredirectmessage || texts?.nohitsredirectedaccomodationmessage}</p>
                                </Alert>
                            </Row>
                        </Container>
                    )}

                    {!newDesignMenu &&
                        (!isMobile ? (
                            <>
                                <div
                                    style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <div className="me-2" style={{ flexShrink: 0 }}>
                                        <ChangeSearch
                                            texts={texts}
                                            showRange
                                            isMobile={false}
                                            reservationResultSearch={reservationResult?.search}
                                            reservationTypeid={lid}
                                        />
                                    </div>

                                    {reservationResult?.search?.checkPriceAndAvailability && (
                                        <a
                                            onClick={handleShowAllOffering}
                                            className="link ms-2"
                                            style={{ flexShrink: 0 }}
                                        >
                                            {texts["search.show_all_offering"]}
                                        </a>
                                    )}
                                </div>
                            </>
                        ) : (
                            <>
                                {reservationResult?.search?.checkPriceAndAvailability && (
                                    <a onClick={handleShowAllOffering} className="link">
                                        {texts["search.show_all_offering"]}
                                    </a>
                                )}

                                <ChangeSearch
                                    texts={texts}
                                    showRange
                                    isMobile={false}
                                    reservationResultSearch={reservationResult?.search}
                                    reservationTypeid={lid}
                                />
                            </>
                        ))}

                    {/* Products */}

                    {renderResults()}
                </div>
            </div>
        </Container>
    );
};

export default PackageResultPage;
