import { Heading } from "@r360/library";
import React, { useState } from "react";
import { RootState } from "../..";
import useAppSelector from "../../hooks/useAppSelector";
import useTranslate from "../../hooks/useTranslate";
import { TFilterCriteria } from "../../store/types";
import FilterRow from "../FilterRow";
import { ToggleFilterButton } from "../ToggleFilterButton/ToggleFilterButton";

type TReservationTypeHeader = {
    reservationTypeId: number;
    hits: number;
    reservationTypeCrits?: TFilterCriteria;
    filtersAvailable?: boolean;
};

export const ReservationTypeHeader = ({ reservationTypeId, hits, filtersAvailable = true }: TReservationTypeHeader) => {
    const { isDesktopNewDesign } = useAppSelector((state: RootState) => state.window);
    const [showFilterRow, setShowFilterRow] = useState(isDesktopNewDesign);
    const t = useTranslate();

    return (
        <>
            <div className="u-d-flex u-flex-column u-flex-md-row u-justify-content-between u-mb-24 u-gap-12">
                <Heading type="h1" styleAs="h2">
                    {t("book.results.skipass.showing_results")} ({hits})
                </Heading>
                {filtersAvailable && (
                    <ToggleFilterButton
                        reservationTypeId={reservationTypeId}
                        showFilter={showFilterRow}
                        onClick={setShowFilterRow}
                    />
                )}
            </div>
            {filtersAvailable && <FilterRow reservationTypeId={reservationTypeId} showFilter={showFilterRow} />}
        </>
    );
};
