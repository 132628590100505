import moment from "moment-timezone";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AxiosClient from "../../API/AxiosClient";
import { AlertSorry, Button, Modal } from "../../components/UI";
import { formatPrice } from "../../Helper";
import { optionsSelector } from "../../selectors/Selectors";
import { addProduct } from "../../store/actions/checkoutCart";
import { clearNotifications } from "../../store/actions/notifications";
import ChangeSearch from "../ChangeSearch/ChangeSearch";
import ProductAmenities from "../ProductAmenities/ProductAmenities";
import ProductGallery from "../ProductGallery/ProductGallery";
import ProductImagesGallery from "../ProductImagesGallery/ProductImagesGallery";
import ReadMoreText from "../UI/ReadMoreText";
import "./AccommodationView.scss";
import { getInspectionDataForProduct, isAccommodation } from "../../BusinessUtils";
import useAppSelector from "../../hooks/useAppSelector";

const showPrice = (price, currency) => {
    return `${formatPrice(price, currency)}`;
};

const AccommodationView = ({
    texts,
    groupedItem,
    presentationCriteriasDefs,
    groupItem,
    resvType,
    reservationResultSearch,
    showNoPoolUnitFoundMessage,
    availablePeriods,
}) => {
    const [showImagesModal, setShowImagesModal] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const clientData = useSelector(state => state.clientData);
    const guestData = useSelector(state => state.clientData?.options?.general);
    const cartProducts = useSelector(state => state.checkoutCart?.products);
    const accommodationCartProducts = cartProducts.filter(isAccommodation);
    const { isMobile, isTablet } = useSelector(state => state.window);
    const showImageDescriptions = useSelector(optionsSelector).layout?.show_image_descriptions || false;
    const calendarEndDate = useSelector(state => state.clientData?.calendarData?.calendar?.[resvType]?.enddate);
    const [poolUnitBlockedPeriods, setPoolUnitBlockedPeriods] = useState();
    const firstItem = groupedItem.items[0];
    const hasPackages = groupedItem.items.length >= 2;
    const useAccommodationPoolUnitPeriods = !!useSelector(optionsSelector).calendar?.useaccommodationpoolunitperiods;
    const lang = useAppSelector(state => state.clientData?.lang);

    const isItemBookable = useCallback(
        item => {
            // If pool item, always allow booking.
            if (!item.unitlid) {
                return true;
            }

            const matchedUnitsInCart = accommodationCartProducts.filter(
                cartProduct => cartProduct.poollid === item.poollid && cartProduct.unitlid === item.unitlid
            );

            const itemArrDate = moment(item.arrdate);
            const itemDepDate = moment(item.depdate);
            const hasDateConflict = matchedUnitsInCart.some(cartProduct => {
                const cartProductArrDate = moment(cartProduct.arrdate);
                const cartProductDepDate = moment(cartProduct.depdate);

                return (
                    itemArrDate.isBetween(cartProductArrDate, cartProductDepDate, undefined, "[)") ||
                    itemDepDate.isBetween(cartProductArrDate, cartProductDepDate, undefined, "(]")
                );
            });

            return !hasDateConflict;
        },
        [accommodationCartProducts]
    );

    const fetchAccommodationAvailability = (poollid, unitlid) => {
        const dateFormat = "YYYY-MM-DD";
        const todayDate = moment().format(dateFormat);
        const inTwoYearsDateMoment = moment().add(2, "years");
        const calendarEndDateMoment = moment(calendarEndDate);
        const endDate =
            calendarEndDate && calendarEndDateMoment.isValid() && calendarEndDateMoment.isBefore(inTwoYearsDateMoment)
                ? calendarEndDateMoment.format(dateFormat)
                : inTwoYearsDateMoment.format(dateFormat);

        const url = `/calendars/accommodation_availability/${poollid}/${unitlid}/${todayDate}/${endDate}`;

        AxiosClient.get(url).then(response => {
            // Convert response to blocked periods.
            // From { "2021-01-01": false, "2021-01-02": false, "2021-01-03": true }
            // To [{ "fromdate": "2021-01-01", "todate" => "2021-01-02", "auto": false }]
            const availability = response?.data?.payload || {};
            const availabilityEntries = Object.entries(availability);
            const blockedPeriods = [];

            let nextPeriodFromDate;
            let nextPeriodToDate;
            availabilityEntries.forEach(([date, available], index) => {
                if (!available) {
                    if (!nextPeriodFromDate) {
                        nextPeriodFromDate = date;
                    }

                    nextPeriodToDate = date;
                }

                // If there exists dates for next period and (the current day is available or we are at end of loop),
                // set a blocket beriod.
                if (nextPeriodFromDate && nextPeriodToDate && (available || availabilityEntries.length === index + 1)) {
                    blockedPeriods.push({
                        description: "Generated from accommodation availability",
                        fromdate: nextPeriodFromDate,
                        todate: nextPeriodToDate,
                        auto: false,
                    });

                    // Reset next period dates for future entries in blocket periods.
                    nextPeriodFromDate = null;
                    nextPeriodToDate = null;
                }
            });

            setPoolUnitBlockedPeriods([
                ...(clientData.calendarData?.blocked_periods?.[resvType] || []),
                ...blockedPeriods,
            ]);
        });
    };

    useEffect(() => {
        fetchAccommodationAvailability(groupedItem.poollid, groupedItem.unitlid);
        dispatch(clearNotifications());
        // eslint-disable-next-line
    }, []);

    // Handle click on show all pcitures
    const handleShowImagesClick = () => {
        setShowImagesModal(!showImagesModal);
    };

    // Prepare images for product gallery
    const images = firstItem.images.map(image => ({
        thumbnail: image.url,
        original: image.url,
        text: image?.translatedDesc ? image?.translatedDesc[lang] : "",
    }));
    const crits = firstItem?.crits;

    const format = "ddd D MMM";

    const arrdate = moment(firstItem?.arrdate);
    const depdate = moment(firstItem?.depdate);

    const obj = JSON.parse(guestData?.ages);
    const ages = obj[resvType];

    const bookAndContinueToReservationPage = item => {
        const generalAges = JSON.parse(clientData.options?.general?.ages);

        let newItem = {
            ...item,
            groupTitle: groupItem.title,
            info: {
                ...item.info,
                guests: {
                    totalGuests: reservationResultSearch?.guests?.length || 0,
                },
                extras: item.info?.extras,
            },
        };

        generalAges[item.type].forEach(age => {
            newItem.info.guests[age.key] =
                reservationResultSearch?.guests.filter(guestAge => guestAge >= age.min && guestAge <= age.max).length ||
                0;
        });

        dispatch(addProduct(newItem));
        navigate("/reservation/extras");
    };

    const verboseGuests = (ages || [])
        .map(age => {
            const guestCount = (reservationResultSearch?.guests || []).filter(
                guestAge => guestAge >= age.min && guestAge <= age.max
            ).length;

            return [age, guestCount];
        })
        .filter(([, guestCount]) => guestCount > 0)
        .map(([age, guestCount]) => `${guestCount} ${texts?.[age.title]}`);

    const renderChangeSearch = () => {
        if (!poolUnitBlockedPeriods) {
            return;
        }

        if (useAccommodationPoolUnitPeriods && !availablePeriods) {
            return;
        }

        return (
            <ChangeSearch
                texts={texts}
                showController
                numberOfMonths={isMobile || isTablet ? 1 : 2}
                reservationResultSearch={reservationResultSearch}
                reservationTypeid={resvType}
                availablePeriods={useAccommodationPoolUnitPeriods ? { [resvType]: availablePeriods } : undefined}
                blockedPeriods={{ [resvType]: poolUnitBlockedPeriods }}
            />
        );
    };

    const renderBookableItem = item => {
        const { pricetext, price, ordinaryprice, curr: currency } = item;

        const title = pricetext ? pricetext : hasPackages ? texts["product_availability.accommodation_only"] : "";

        const itemIsBookable = isItemBookable(item);

        return (
            <div
                key={`${item.poollid}_${item.unitlid}_${item.pricecode}`}
                data-key={`${item.poollid}_${item.unitlid}_${item.pricecode}`}
                className="row"
                {...getInspectionDataForProduct(item)}
            >
                <div className="col">
                    <div className="accommodation-view__bookable-item">
                        <div>
                            <div className="accommodation-view__bookable-item-price">{showPrice(price, currency)}</div>
                            {ordinaryprice > 0 && (
                                <div className="accommodation-view__bookable-item-ordinaryprice">
                                    {showPrice(ordinaryprice, currency)}
                                </div>
                            )}
                            {title && <div>{title}</div>}
                            <span className="accommodation-view__bookable-item-description">
                                {`${texts?.pricerefersto} ${arrdate.format(format)} - ${depdate.format(format)}`}
                                <br />
                                {verboseGuests.join(", ")}
                            </span>
                        </div>
                        <div>
                            <Button
                                onClick={() => bookAndContinueToReservationPage(item)}
                                className="accommodation-view__bookable-item-book-button"
                                disabled={!itemIsBookable}
                            >
                                {itemIsBookable ? texts?.reservationbutton : texts["product.added_to_cart"]}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="accommodation-view">
            <h1 className="accommodation-view-title">{firstItem.title}</h1>
            <ProductGallery images={images} numberOfThumbnails={2} />
            <div style={{ textAlign: "right", height: "2rem", paddingTop: "0.5rem" }}>
                {images.length > 1 && (
                    <a className="link" onClick={handleShowImagesClick}>
                        {texts?.showallpictures}
                    </a>
                )}
            </div>

            {firstItem?.weblong?.trim() !== "" && (
                <div className="row mb-4">
                    <div className="col-md-12 col-lg-12">
                        <h1 className="accommodation-view-header">
                            {texts?.accommodationinformationheading || texts?.description}
                        </h1>
                    </div>
                    <div className="col-md-12 col-lg-12">
                        <ReadMoreText
                            lines={3}
                            moreText={texts?.showmore}
                            lessText={texts?.showless}
                            anchorClass="link"
                            html={true}
                            whiteSpacePreLine={true}
                        >
                            {firstItem?.weblong}
                        </ReadMoreText>
                    </div>
                </div>
            )}

            {Object.entries(crits).filter(([critId]) => presentationCriteriasDefs[critId]).length > 0 && (
                <div className="row mb-4">
                    <div className="col">
                        <h1 className="accommodation-view-header" style={{ paddingBottom: 0 }}>
                            {texts?.amenities}
                        </h1>
                        <ProductAmenities
                            presentationCriteriasDefs={presentationCriteriasDefs}
                            crits={crits}
                            texts={texts}
                        />
                    </div>
                </div>
            )}

            <h1 className="accommodation-view-header" style={{ paddingBottom: 0 }}>
                {texts["product.search_available_date_and_price"]}
            </h1>
            {renderChangeSearch()}

            <div className="row mt-4">
                <div className="col-md-12">
                    {showNoPoolUnitFoundMessage ? (
                        <AlertSorry
                            heading={texts?.accommodationnoresultheader}
                            content={texts?.accommodationnoresultbody}
                        />
                    ) : (
                        groupedItem.items.filter(item => item.pricecode).map(item => renderBookableItem(item))
                    )}
                </div>
            </div>

            <Modal show={showImagesModal} setShow={setShowImagesModal} size="lg">
                <ProductImagesGallery images={images} showImageDescriptions={showImageDescriptions} />
            </Modal>
        </div>
    );
};

export default AccommodationView;
