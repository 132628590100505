import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import AxiosClient from "../../API/AxiosClient";
import { optionsSelector } from "../../Selectors";
import useAppSelector from "../../hooks/useAppSelector";
import { TReservationType } from "../../store/types";
import MenuSearch from "../MenuSearch";
import { TAvailablePeriod, TBlockedPeriod } from "../UI/DatePicker/DatePicker";
import "./AccommodationChangeSearch.scss";

type TAccommodationChangeSearch = {
    reservationTypeid: number;
    groupPoollid: number;
    poollid?: number;
    unitlid?: number;
    searchButtonText?: string;
    useAnyAsAvailablePeriods?: boolean;
    getOwnerMaxRangeLength?: (startDate: string) => number | undefined;
    isSmall?: boolean;
};

const dateFormat = "YYYY-MM-DD";

const anyAsAvailablePeriods = [
    {
        startdate: moment().format(dateFormat),
        enddate: moment().add(2, "years").format(dateFormat),
        rules: [[-1, -1]],
        description: "Generated for useAnyAsAvailablePeriods",
    },
];

export const AccommodationChangeSearch = ({
    reservationTypeid,
    groupPoollid,
    poollid,
    unitlid,
    searchButtonText,
    useAnyAsAvailablePeriods = false,
    getOwnerMaxRangeLength,
    isSmall,
}: TAccommodationChangeSearch) => {
    const reservationTypeForSearch = useAppSelector(state => state.clientData.reservationtypes)
        .filter((reservationType: TReservationType) => reservationType.lid === reservationTypeid)
        .map((reservationType: TReservationType) => ({
            ...reservationType,
            link: `/search/${reservationType.lid}`,
            active: true,
        }));

    const clientData = useAppSelector(state => state.clientData);
    const calendarEndDate = useAppSelector(
        state => state.clientData?.calendarData?.calendar?.[reservationTypeid]?.enddate
    );

    const useAccommodationPoolUnitPeriods = !!useAppSelector(optionsSelector).calendar?.useaccommodationpoolunitperiods;
    const [availablePeriodsGroupPool, setAvailablePeriodsGroupPool] = useState<TAvailablePeriod[] | undefined>();
    const [availablePeriodsPoolUnit, setAvailablePeriodsPoolUnit] = useState<TAvailablePeriod[] | undefined>();
    const [blockedPeriodsPoolUnit, setBlockedPeriodsPoolUnit] = useState<TBlockedPeriod[] | undefined>();
    const [maxRangeLength, setMaxRangeLength] = useState<number | undefined>();
    const allowGroupPoolPeriods = reservationTypeid && groupPoollid && useAccommodationPoolUnitPeriods;
    const isPoolUnit = poollid && unitlid;
    const account = useAppSelector(state => state.account);

    const fetchAvailablePeriodsGroupPool = (reservationTypeid: number, grouppoollid: number) => {
        const url = `/calendars/available_periods_grouppool?resvtype=${reservationTypeid}&grouppoollid=${grouppoollid}`;

        AxiosClient.get(url).then(response => {
            if (response?.data?.payload && response?.data?.payload.length > 0) {
                setAvailablePeriodsGroupPool(response?.data?.payload);
            }
        });
    };

    const fetchAccommodationBlockedPeriods = (poollid: number, unitlid: number) => {
        const todayDate = moment().format(dateFormat);
        const inTwoYearsDateMoment = moment().add(2, "years");
        const calendarEndDateMoment = moment(calendarEndDate);
        const endDate =
            calendarEndDate && calendarEndDateMoment.isValid() && calendarEndDateMoment.isBefore(inTwoYearsDateMoment)
                ? calendarEndDateMoment.format(dateFormat)
                : inTwoYearsDateMoment.format(dateFormat);

        const url = `/calendars/accommodation_availability/${poollid}/${unitlid}/${todayDate}/${endDate}`;

        // Provide token if logged in as owner to identify the owners own accommodations.
        const config = account?.user?.isOwner
            ? {
                  headers: {
                      Authorization: `Bearer ${account.token}`,
                  },
              }
            : {};

        AxiosClient.get(url, config).then(response => {
            // Convert response to blocked periods.
            // From { "2021-01-01": false, "2021-01-02": false, "2021-01-03": true }
            // To [{ "fromdate": "2021-01-01", "todate" => "2021-01-02", "auto": false }]
            const availability = response?.data?.payload || {};
            const availabilityEntries = Object.entries(availability);
            const blockedPeriods: any = []; // TODO type

            let nextPeriodFromDate: string | null;
            let nextPeriodToDate: string | null;
            availabilityEntries.forEach(([date, available], index) => {
                if (!available) {
                    if (!nextPeriodFromDate) {
                        nextPeriodFromDate = date;
                    }

                    nextPeriodToDate = date;
                }

                // If there exists dates for next period and (the current day is available or we are at end of loop),
                // set a blocket beriod.
                if (nextPeriodFromDate && nextPeriodToDate && (available || availabilityEntries.length === index + 1)) {
                    blockedPeriods.push({
                        description: "Generated from accommodation availability",
                        fromdate: nextPeriodFromDate,
                        todate: nextPeriodToDate,
                        auto: false,
                    });

                    // Reset next period dates for future entries in blocket periods.
                    nextPeriodFromDate = null;
                    nextPeriodToDate = null;
                }
            });

            setBlockedPeriodsPoolUnit([
                ...(clientData.calendarData?.blocked_periods?.[reservationTypeid] || []),
                ...blockedPeriods,
            ]);
        });
    };

    // 1. Fetch available periods for group pool.
    useEffect(() => {
        if (allowGroupPoolPeriods && isPoolUnit && !useAnyAsAvailablePeriods) {
            fetchAvailablePeriodsGroupPool(reservationTypeid, groupPoollid);
        }
    }, [allowGroupPoolPeriods, isPoolUnit, reservationTypeid, groupPoollid, useAnyAsAvailablePeriods]);

    // 2. Fetch available periods for pool/unit after periods for group pool has been fetched.
    useEffect(() => {
        if (availablePeriodsGroupPool && useAccommodationPoolUnitPeriods && isPoolUnit) {
            setAvailablePeriodsPoolUnit(
                (availablePeriodsGroupPool || []).filter(
                    period => period.poollid === poollid && (period.unitlid === 0 || period.unitlid === unitlid)
                )
            );
        }
    }, [availablePeriodsGroupPool, useAccommodationPoolUnitPeriods, allowGroupPoolPeriods]);

    useEffect(() => {
        if (isPoolUnit) {
            fetchAccommodationBlockedPeriods(poollid, unitlid);
        }
        // dispatch(clearNotifications());
        // eslint-disable-next-line
    }, [poollid, unitlid]);

    const handleSelectStartDate = useCallback(
        (startDate: string) => {
            const maxRangeLength = getOwnerMaxRangeLength?.(startDate);

            if (maxRangeLength === undefined) {
                return;
            }

            setMaxRangeLength(maxRangeLength);
        },
        [getOwnerMaxRangeLength, setMaxRangeLength]
    );

    const handleSelectDates = useCallback(() => {
        setMaxRangeLength(undefined);
    }, [setMaxRangeLength]);

    const availablePeriods = useAnyAsAvailablePeriods
        ? anyAsAvailablePeriods
        : availablePeriodsPoolUnit || availablePeriodsGroupPool;

    return (
        <div className="accommodation-change-search">
            <MenuSearch
                reservationTypes={reservationTypeForSearch}
                onDarkBackground={false}
                availablePeriods={
                    availablePeriods && {
                        [reservationTypeid.toString()]: availablePeriods,
                    }
                }
                blockedPeriods={
                    blockedPeriodsPoolUnit && {
                        [reservationTypeid.toString()]: blockedPeriodsPoolUnit,
                    }
                }
                searchButtonText={searchButtonText}
                outsideHeader
                onSelectStartDate={handleSelectStartDate}
                onSelectDates={handleSelectDates}
                maxRangeLength={maxRangeLength}
                groupPoollid={groupPoollid}
                poollid={poollid}
                unitlid={unitlid}
                isSmall={isSmall}
            />
        </div>
    );
};
