import React, { useCallback } from "react";
import { Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { ReactSVG } from "react-svg";
import { default as crits_R360 } from "../../assets/crits";
import { iconByCritId } from "../UI/R360Icons";
import "./ProductAvailability.scss";
import ProductAvailabilityItem from "./ProductAvailabilityItem/ProductAvailabilityItem";
import { textsSelector } from "../../Selectors";

const ProductAvailability = ({ product, productList, onSelect, onPriceCalendarClick }) => {
    const isMobile = useSelector(state => state.window.isMobile);
    const texts = useSelector(textsSelector);
    const availableFilters = useSelector(state => state.availableFilters.default);

    const renderProductList = resultTableCritsSortorder => {
        return productList.map(groupedProduct => (
            <React.Fragment key={`${groupedProduct.poollid}_${groupedProduct.unitlid}`}>
                <ProductAvailabilityItem
                    data={groupedProduct}
                    didSelectProduct={onSelect}
                    onPriceCalendarClick={onPriceCalendarClick}
                    groupTitle={product.groupitem.title}
                    isMobile={isMobile}
                    texts={texts}
                    tableCrits={product.resulttablecrits}
                    renderCritHeader={renderCritHeader}
                    resultTableCritsSortorder={resultTableCritsSortorder}
                />
            </React.Fragment>
        ));
    };

    // Render crit header
    const renderCritHeader = useCallback(
        (id, mobileProps) => {
            const critProps = mobileProps || { color: "#333333", size: 28 };

            // Get official icon...
            if (iconByCritId(parseInt(id))) {
                return (
                    <>
                        {iconByCritId(parseInt(id), critProps)}
                        <span className="sr-only">{product.resulttablecrits?.[id]?.title}</span>
                    </>
                );
                // ...or secondary icon...
            } else if (crits_R360[`./${parseInt(id)}.svg`]) {
                return (
                    <>
                        <ReactSVG
                            src={crits_R360[`./${parseInt(id)}.svg`]}
                            afterInjection={error => {
                                if (error) {
                                    console.error(error);
                                    return;
                                }
                                // console.log(svg)
                            }}
                            beforeInjection={svg => {
                                if (mobileProps) {
                                    svg.setAttribute("height", `${mobileProps.size}px`);
                                    svg.setAttribute("width", `${mobileProps.size}px`);
                                    svg.setAttribute("fill", mobileProps.color);
                                } else {
                                    svg.setAttribute("height", "27px");
                                    svg.setAttribute("height", "27px");
                                }
                            }}
                            // evalScripts="always"
                            // fallback={() => <span>Error!</span>}
                            // loading={() => <span>Loading</span>}
                            renumerateIRIElements={false}
                            useRequestCache={true}
                            wrapper="span"
                            // className="wrapper-class-name"
                            // onClick={() => {
                            //   console.log('wrapper onClick')
                            // }}
                        />
                        {/* <img src={crits_R360[`./${parseInt(id)}.svg`]} alt="" style={{ height: imageHeight, marginTop: "-0.2rem" }} aria-hidden="true" /> */}
                        <span className="sr-only">{product.resulttablecrits?.[id]?.title}</span>
                    </>
                );
            }
            // ...else title if no icon exists.
            return availableFilters?.[id]?.title;
        },
        [product, availableFilters]
    );

    // Sort table criterias
    const resultTableCritsSortorder = product.resulttablecrits
        ? Object.values(product.resulttablecrits)
              .sort((a, b) => a.sortorder - b.sortorder)
              .map(crit => crit.code)
        : [];

    return (
        <div className="product-availability">
            {/* <h2 className="h1 product-availability__header">{texts?.availableaccommodations}</h2> */}
            {(productList || []).length ? (
                <Table size="sm" className="product-availability__table" responsive borderless>
                    {!isMobile && (
                        <thead>
                            <tr>
                                <th scope="col">
                                    <span className="sr-only">{texts?.accommodation}</span>
                                </th>
                                {resultTableCritsSortorder.map(critCode => (
                                    <th
                                        scope="col"
                                        title={product.resulttablecrits[critCode].title}
                                        data-critcode={critCode}
                                        key={critCode}
                                    >
                                        {renderCritHeader(critCode)}
                                    </th>
                                ))}
                                <th scope="col" title={texts?.generalprice}>
                                    <span className="sr-only">{texts?.generalprice}</span>
                                </th>
                                <th scope="col" title={texts?.accommodationaddedtocart}>
                                    <span className="sr-only">{texts?.accommodationaddedtocart}?</span>
                                </th>
                            </tr>
                        </thead>
                    )}
                    <tbody>{renderProductList(resultTableCritsSortorder)}</tbody>
                </Table>
            ) : (
                <div className="product-availability__error-message">{texts?.noaccommodations}</div>
            )}
        </div>
    );
};

export default ProductAvailability;
