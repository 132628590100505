import * as React from "react";
import defautIconStats from "../Icon.types";
import Theme from "../../Theme";

const Skiing = ({ color = Theme.palette.primary, size = defautIconStats.size, iconClass = defautIconStats.width }) => {
    return (
        <svg
            id="Capa_1"
            enableBackground="new 0 0 512 512"
            viewBox="0 0 512 512"
            style={{ height: size, width: size }}
            xmlns="http://www.w3.org/2000/svg"
            className={iconClass}
        >
            <g>
                <g>
                    <path
                        fill={color}
                        d="m274.367 333.341c1.465 1.464 3.385 2.197 5.304 2.197s3.839-.732 5.304-2.197l48.568-48.568c2.929-2.929 2.929-7.678 0-10.606l-19.999-19.999c-2.93-2.929-7.678-2.929-10.607 0l-48.568 48.568c-2.929 2.929-2.929 7.678 0 10.606zm33.873-63.264 9.393 9.393-37.962 37.962-9.393-9.393z"
                    />
                    <path
                        fill={color}
                        d="m388.122 267.334c2.929-2.929 2.929-7.678 0-10.606-2.93-2.929-7.678-2.929-10.607 0l-14.31 14.31c-1.324-4.163-3.629-7.979-6.806-11.156l-28.57-28.57c-3.177-3.177-6.993-5.481-11.155-6.806l79.889-79.889 32.573 60.492-26.826 26.826c-2.929 2.929-2.929 7.678 0 10.606 2.93 2.929 7.678 2.929 10.607 0l85.053-85.054c9.049-9.048 14.031-21.078 14.031-33.873s-4.982-24.825-14.03-33.873-21.078-14.031-33.874-14.031-24.825 4.983-33.873 14.031l-340.382 340.382c-9.049 9.047-14.031 21.077-14.031 33.873s4.982 24.826 14.03 33.873c9.048 9.048 21.077 14.031 33.873 14.031s24.825-4.983 33.873-14.031zm-218.3 197.087-32.573-60.491 10.354-10.354 32.572 60.492zm-11.146-81.919 66.031-66.03c1.324 4.162 3.629 7.978 6.806 11.156l28.569 28.569c3.237 3.236 7.083 5.495 11.171 6.792l-80.005 80.005zm149.564-144.304c3.393 0 6.583 1.321 8.981 3.72l28.569 28.57c2.399 2.399 3.721 5.589 3.721 8.982 0 3.39-1.319 6.577-3.714 8.975l-57.147 57.147c-4.951 4.95-13.01 4.95-17.961-.002l-28.569-28.569c-2.399-2.399-3.721-5.589-3.721-8.982 0-3.385 1.317-6.568 3.706-8.966l57.16-57.16c2.398-2.396 5.585-3.715 8.975-3.715zm99.396-104.656 10.353-10.353 32.573 60.492-10.354 10.354zm33.195-33.195c6.214-6.215 14.477-9.637 23.266-9.637s17.052 3.422 23.266 9.637c6.216 6.215 9.638 14.478 9.638 23.267s-3.422 17.051-9.638 23.266l-25.727 25.727-32.573-60.492zm-293.851 386.915c-6.214 6.215-14.477 9.637-23.266 9.637s-17.052-3.422-23.267-9.637-9.637-14.478-9.637-23.267 3.422-17.051 9.638-23.266l25.727-25.727 32.573 60.491z"
                    />
                    <path
                        fill={color}
                        d="m237.835 178.458c-1.407-1.407-3.314-2.197-5.304-2.197s-3.896.79-5.304 2.197l-48.568 48.568c-2.929 2.929-2.929 7.678 0 10.606l19.998 19.999c1.407 1.407 3.314 2.197 5.304 2.197s3.896-.79 5.304-2.197l48.568-48.568c2.929-2.929 2.929-7.678 0-10.606zm-33.874 63.263-9.392-9.392 37.962-37.962 9.392 9.392z"
                    />
                    <path
                        fill={color}
                        d="m422.261 81.777c18.677-18.678 18.677-49.069-.001-67.746-18.678-18.678-49.068-18.678-67.746 0l-240.581 240.581c-2.929 2.929-2.929 7.678 0 10.606 2.93 2.929 7.678 2.929 10.607 0l24.471-24.471c1.297 4.088 3.555 7.934 6.791 11.17l28.57 28.57c3.178 3.178 6.994 5.482 11.157 6.807l-66.029 66.031-60.493-32.573 30.703-30.703c2.929-2.929 2.929-7.678 0-10.606-2.93-2.929-7.678-2.929-10.607 0l-74.971 74.971c-18.677 18.678-18.677 49.068 0 67.746 9.34 9.34 21.604 14.009 33.873 14.008 12.266-.001 24.535-4.67 33.874-14.008zm-141.571 102.395-28.57-28.57c-3.178-3.177-6.994-5.482-11.156-6.807l65.93-65.93 60.493 32.573-79.904 79.904c-1.298-4.087-3.557-7.934-6.793-11.17zm47.63-122.734 60.493 32.573-10.354 10.354-60.493-32.573zm36.801-36.801c12.828-12.829 33.704-12.829 46.532 0 12.829 12.829 12.829 33.704 0 46.533l-11.768 11.768-60.492-32.573zm-152.177 245.243c-2.399 2.399-5.59 3.721-8.982 3.721s-6.582-1.321-8.98-3.72l-28.57-28.57c-4.951-4.951-4.952-13.006-.004-17.959l57.156-57.156c2.398-2.391 5.582-3.708 8.969-3.708 3.393 0 6.582 1.321 8.98 3.72l28.57 28.57c4.952 4.952 4.952 13.01.001 17.963l-57.134 57.134c-.002.002-.004.003-.006.005zm-94.518 94.518-10.354 10.354-60.493-32.573 10.354-10.354zm-93.687 47.155c-12.829-12.829-12.829-33.704 0-46.533l11.767-11.767 60.493 32.573-25.727 25.727c-12.83 12.829-33.705 12.829-46.533 0z"
                    />
                    <path
                        fill={color}
                        d="m509.804 240.377-21.427-21.427c-1.407-1.407-3.314-2.197-5.304-2.197s-3.896.79-5.304 2.197l-60.889 60.889c-2.929 2.929-2.929 7.678 0 10.606l5.41 5.41-169.006 169.007-5.41-5.41c-2.93-2.929-7.678-2.929-10.607 0s-2.929 7.678 0 10.606l5.411 5.411-23.728 23.728c-2.929 2.929-2.929 7.678 0 10.606 1.465 1.464 3.385 2.197 5.304 2.197s3.839-.732 5.304-2.197l23.728-23.728 5.41 5.41c1.465 1.464 3.385 2.197 5.304 2.197s3.839-.732 5.304-2.197c2.929-2.929 2.929-7.678 0-10.606l-5.41-5.41 169.007-169.007 5.41 5.41c1.407 1.407 3.314 2.197 5.304 2.197s3.896-.79 5.304-2.197l60.889-60.889c2.925-2.929 2.925-7.678-.004-10.606zm-66.192 55.585-10.82-10.821 50.282-50.282 10.82 10.821z"
                    />
                    <path
                        fill={color}
                        d="m7.5 295.247c1.919 0 3.839-.732 5.304-2.197l23.728-23.727 5.41 5.41c1.465 1.464 3.385 2.197 5.304 2.197s3.839-.732 5.304-2.197c2.929-2.929 2.929-7.678 0-10.606l-5.411-5.411 169.007-169.007 5.41 5.41c1.465 1.464 3.385 2.197 5.304 2.197s3.839-.732 5.304-2.197l60.888-60.888c2.929-2.929 2.929-7.678 0-10.606l-21.428-21.428c-1.407-1.407-3.314-2.197-5.303-2.197s-3.896.79-5.304 2.197l-60.889 60.888c-1.406 1.406-2.196 3.314-2.196 5.303s.79 3.897 2.196 5.303l5.411 5.411-169.008 169.007-5.41-5.41c-2.93-2.929-7.678-2.929-10.607 0s-2.929 7.678 0 10.606l5.41 5.41-23.727 23.729c-2.929 2.929-2.929 7.678 0 10.606 1.464 1.465 3.383 2.197 5.303 2.197zm258.821-277.14 10.82 10.821-50.281 50.281-10.821-10.821z"
                    />
                </g>
            </g>
        </svg>
    );
};

export default Skiing;
