import React, { useState, useEffect } from "react";
import { ReactSVG } from "react-svg";
import "./ProductAmenities.scss";
// import { default as criterias } from "../../assets/criterias"; <-- old icons
import { default as crits_R360 } from "../../assets/crits";

// Product amenities component
const ProductAmenities = props => {
    const { presentationCriteriasDefs, crits, texts } = props;
    const [showPresentationDefsCrits, setShowPresentationDefsCrits] = useState(false);
    const [showToggle, setShowToggle] = useState(false);

    // Handle click on show all presentation defs crits
    const handleShowPresentationDefsCrits = () => {
        setShowPresentationDefsCrits(!showPresentationDefsCrits);
    };

    useEffect(() => {
        setShowToggle(Object.entries(crits).filter(([critId]) => presentationCriteriasDefs[critId]).length > 12);
    }, [crits, presentationCriteriasDefs]);

    // Sort and filter criterias
    const sortedCriteriaCodesToDisplay = Object.values(presentationCriteriasDefs)
        .filter(criteriaDef => criteriaDef.code in crits) // Keep only criterias that exists on the product
        .sort((a, b) => a.sortorder - b.sortorder)
        .map(criteriaDef => criteriaDef.code);

    // Render amenity
    const renderAmenity = (crit, image) => {
        const critcode = crit[0];
        const value = crit[1];
        const definition = presentationCriteriasDefs[critcode];
        const enumValue = definition.type === "ENUM" ? definition.enums[value].value : "";
        const disabledPrefix = definition.type === "BOOL" && !value ? "--disabled" : "";
        // const columnSize = renderedInModal ? 4 : 3;
        const ariaLabel =
            definition.type === "BOOL" && !value ? `${definition.title} ${texts?.notincluded}` : definition.title;

        return (
            <div
                className="product-amenities__details-cell"
                id={`product-amenities__details-${critcode}`}
                key={critcode}
            >
                {image && (
                    <ReactSVG
                        beforeInjection={svg => {
                            svg.setAttribute("height", "1rem");
                        }}
                        src={image}
                        useRequestCache={true}
                        wrapper="span"
                    />
                )}

                {definition.type === "BOOL" && (
                    <span className={`product-amenities__details-text${disabledPrefix}`} aria-label={ariaLabel}>
                        {definition.title}
                    </span>
                )}

                {definition.type === "ENUM" && (
                    <span className={`product-amenities__details-text${disabledPrefix}`} aria-label={ariaLabel}>
                        {`${definition.title}: ${enumValue}`}
                    </span>
                )}

                {["MIN", "MAX"].includes(definition.type) && (
                    <span className={`product-amenities__details-text${disabledPrefix}`} aria-label={ariaLabel}>
                        {`${definition.title}: ${value}${definition.typeunit}`}
                    </span>
                )}
            </div>
        );
    };

    return (
        <>
            <div className="product-amenities__details">
                {sortedCriteriaCodesToDisplay
                    .splice(0, showPresentationDefsCrits ? Object.values(sortedCriteriaCodesToDisplay).length : 12)
                    .map(critCode => {
                        const value = crits[critCode];
                        const imgUrl = crits_R360[`./${critCode}.svg`];
                        return renderAmenity([critCode, value], imgUrl);
                    })}
            </div>

            {showToggle && (
                <div>
                    <a onClick={handleShowPresentationDefsCrits} className="link">
                        {showPresentationDefsCrits ? texts?.showfeweramenities : texts?.showallamenities}
                    </a>
                </div>
            )}
        </>
    );
};

export default ProductAmenities;
