import React, { useCallback, useEffect, useState } from "react";
import { Button, Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { isBooked, showArrDepDates } from "../../../BusinessUtils";
import {
    displayGuestText,
    getGuestNameAndAge,
    isGuestRightAgeForProduct,
    isNumberWithoutDecimals,
} from "../../../Helper";
import { guestsOnTravelSelector, optionsSelector } from "../../../selectors/Selectors";
import { updateProduct } from "../../../store/actions/checkoutCart";
import { updateGuestData } from "../../../store/actions/guests";
import { Icon, InformationBlock, Modal, Tag } from "../../UI";
import { v4 as uuidv4 } from "uuid";
import "./SkiRental.scss";
import promptDialog from "../../UI/PromptDialog";
import { updateUserData } from "../../../store/actions/account";

const SkiRental = props => {
    const dispatch = useDispatch();
    const { products, type, texts } = props;
    const deliveryPoints = useSelector(state => state.clientData.deliveryPoints);
    const lettings = products.filter(
        product => !isBooked(product) && parseInt(product.type, 10) === parseInt(type.lid, 10)
    );
    const lettinghelpimage = useSelector(state => state.clientData.options.general?.lettinghelpimage);
    const guestsOnTravel = useSelector(guestsOnTravelSelector);
    const allowLettingNotes = useSelector(optionsSelector).reservation?.allow_letting_notes || false;
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        lettings.forEach(letting => {
            if (letting.items && letting.items.length === letting.count) {
                // Empty statement.
            } else {
                if (letting.items) {
                    let rest = letting.count - letting.items.length;
                    let items = letting.items;
                    if (rest > 0) {
                        //console.log("CREATE " + rest + " skipaaess");

                        for (let index = 0; index < rest; index++) {
                            const uuid = uuidv4();
                            items.push({ id: uuid, guestId: null });
                        }
                    } else {
                        rest = rest * -1;
                        //console.log("Remove " + rest + " skipaaess");
                        for (let index = 0; index < rest; index++) {
                            items.pop();
                        }
                    }
                    dispatch(updateProduct(letting, { key: "items", value: items }));
                } else {
                    let items = [];
                    for (let count = 0; count < letting.count; count++) {
                        const uuid = uuidv4();
                        items.push({ id: uuid, guestId: null });
                    }
                    dispatch(updateProduct(letting, { key: "items", value: items }));
                }
            }
        });
    }, [dispatch, lettings]);

    const handleProductChange = (event, product, productItem) => {
        const { name } = event.target;
        const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;

        updateProductItem(product, productItem, name, value);
    };

    const updateProductItem = (product, productItem, key, value) => {
        dispatch(
            updateProduct(product, {
                key: "items",
                value: product.items.map(item => (item.id === productItem.id ? { ...item, [key]: value } : item)),
            })
        );
    };

    const updateGuestInformation = (event, guestId) => {
        const name = event.target ? event.target.name : event.name;
        const value = event.target ? event.target.value : event.value;
        dispatch(updateGuestData(guestId, name, value));

        // If the guest being updated is the primary guest, the user also needs to be updated.
        if (guestsOnTravel[guestId]?.primary) {
            dispatch(updateUserData(name, value));
        }
    };

    // NOTE! This can be used again when r360 supports different delivery points for each item.
    /*const deliveryView = (rental, item) => {
    if (deliveryPoints.length > 1) {
      return (
        <Form.Group as={Col} md="3">
          <Form.Control
            required
            disabled={!item.guestId}
            as={deliveryPoints?.length > 0 ? "select" : null}
            name="deliveryPoint"
            value={item.deliveryPoint || ""}
            onChange={(event) => {
              handleProductChange(event, rental, item);
            }}
          >
            <option value="" disabled>
              {texts?.delivery}*
            </option>
            {deliveryPoints.map((deliveryPoint, index) => {
              return (
                <option
                  key={deliveryPoint.shopid + index}
                  value={deliveryPoint.namenative}
                >
                  {deliveryPoint.namedisplay}
                </option>
              );
            })}
          </Form.Control>
        </Form.Group>
      );
    } else {
      return (
        <Form.Group as={Col} md="3">
          <Form.Control
            required
            disabled={true}
            name="deliveryPoint"
            value={deliveryPoints[0]?.namenative}
          ></Form.Control>
        </Form.Group>
      );
    }
  };*/

    /**
     * NOTE! This can be removed when r360 supports different delivery points for each item.
     */
    const setDeliveryPointForAllItems = useCallback(
        deliveryPoint => {
            if (!deliveryPoint) {
                return;
            }

            // - Only add delivery point to non-booked products
            // - Allow customer to change delivery point on new bookings when having two or more delivery points
            lettings
                .filter(letting => !letting.detlid && letting.items?.length > 0)
                .forEach(letting => {
                    let items = letting.items.filter(
                        item => !("deliveryPoint" in item) || item.deliveryPoint !== deliveryPoint
                    );

                    if (items.length > 0) {
                        items = items.map(item => {
                            item.deliveryPoint = deliveryPoint;
                            return item;
                        });

                        dispatch(
                            updateProduct(letting, {
                                key: "items",
                                value: items,
                            })
                        );
                    }
                });
        },
        [dispatch, lettings]
    );

    /**
     * NOTE! This can be removed when r360 supports different delivery points for each item.
     *
     * Sync that all items have the same delivery point.
     */
    useEffect(() => {
        if (lettings && lettings.length > 0 && deliveryPoints.length > 0) {
            if (lettings?.filter(product => product.detlid).length > 0) {
                // Use delivery point from first booked product
                const firstLettingItem = lettings[0];
                setDeliveryPointForAllItems(firstLettingItem.deliveryPoint);
            } else if (deliveryPoints.length === 1) {
                // There is only one delivery point, use it on all letting products
                setDeliveryPointForAllItems(deliveryPoints[0].namenative);
            } else if (lettings?.[0]?.items?.[0] && deliveryPoints.length > 1) {
                // Use delivery point from first non booked product (if set)
                const firstLettingItem = lettings[0].items[0];

                if (firstLettingItem.deliveryPoint) {
                    const uniqueDeliveryPoints = new Set(
                        lettings.reduce(
                            (acc, { items }) => {
                                if (items?.length) {
                                    acc = [...acc, ...items.map(item => item.deliveryPoint)];
                                }

                                return acc;
                            },
                            [firstLettingItem.deliveryPoint]
                        )
                    );

                    if (uniqueDeliveryPoints.size > 1) {
                        setDeliveryPointForAllItems(firstLettingItem.deliveryPoint);
                    }
                }
            }
        }
    }, [lettings, deliveryPoints, setDeliveryPointForAllItems]);

    /**
     * NOTE! This can be removed when r360 supports different delivery points for each item.
     */
    const combinedDeliveryViewSelect = () => {
        // Use delivery point from first booked letting product
        if (lettings?.filter(product => product.detlid).length > 0) {
            const firstLettingItem = lettings[0];

            return (
                <Form.Group as={Col} md="3">
                    <Form.Control readOnly value={firstLettingItem.deliveryPoint}></Form.Control>
                    <Form.Control
                        type="hidden"
                        name="deliveryPoint"
                        value={firstLettingItem.deliveryPoint}
                    ></Form.Control>
                </Form.Group>
            );
        } else if (lettings?.[0]?.items?.length && deliveryPoints.length > 1) {
            // Pick first item on the first letting to store the delivery point in and then sync it with the rest of the items.
            const firstLettingItem = lettings[0].items[0];

            if (!firstLettingItem) {
                return;
            }

            return (
                <Form.Group as={Col} md="3">
                    <Form.Control
                        required
                        as={"select"}
                        name="deliveryPoint"
                        defaultValue={firstLettingItem.deliveryPoint || ""}
                        onChange={event => {
                            setDeliveryPointForAllItems(event.target.value);
                        }}
                    >
                        <option value="" disabled>
                            {texts?.delivery}*
                        </option>
                        {deliveryPoints.map((deliveryPoint, index) => {
                            return (
                                <option key={deliveryPoint.shopid + index} value={deliveryPoint.namenative}>
                                    {deliveryPoint.namedisplay}
                                </option>
                            );
                        })}
                    </Form.Control>
                </Form.Group>
            );
        } else {
            return (
                <Form.Group as={Col} md="3">
                    <Form.Control readOnly value={deliveryPoints[0]?.namenative}></Form.Control>
                    <Form.Control
                        type="hidden"
                        name="deliveryPoint"
                        value={deliveryPoints[0]?.namenative}
                    ></Form.Control>
                </Form.Group>
            );
        }
    };

    const handleClick = () => {
        setShowModal(!showModal);
    };

    const productHasInsurance = product => product.price !== product.priceInsurance;

    const openNotesPromptDialog = async (product, productItem) => {
        const [status, value] = await promptDialog({
            placeholder: texts["checkout.skirental.note_prompt.placeholder"],
            okLabel: texts["general.prompt.save"],
            cancelLabel: texts["general.confirmation.cancel"],
            initialValue: productItem.note || "",
            maxLength: 250,
        });

        if (status) {
            updateProductItem(product, productItem, "note", value);
        }
    };

    return (
        <div className="skiRental">
            <InformationBlock className="mt-4 mb-4">{texts?.missingguestinformation}</InformationBlock>

            {texts?.lettinghelpinformation && (
                <div className="d-flex skiRental__infoButton">
                    <Button variant="primary" className="skiRental__infoButton-icon" onClick={handleClick}>
                        ?
                    </Button>
                    <Button
                        variant="button"
                        className="p-0 ps-1 fw-light border-0 skiRental__infoButton-text"
                        onClick={handleClick}
                    >
                        {texts.lettinghelpinformationshort || "Help"}
                    </Button>
                </div>
            )}

            <div>
                {lettings.map(rental => {
                    return (
                        !rental.detlid &&
                        rental.items?.map((item, index) => {
                            return (
                                <div key={item.id + index} className="skiRental__item">
                                    <Row>
                                        <Form.Group as={Col} md="auto">
                                            <h5>{rental.title}</h5>
                                        </Form.Group>
                                        <Form.Group as={Col} md="auto">
                                            <Row>
                                                <Tag className="col-auto me-1" type="medium">
                                                    {showArrDepDates(rental)}
                                                </Tag>
                                                <Tag className="col-auto" type="medium">
                                                    {displayGuestText(
                                                        rental.minage,
                                                        rental.maxage,
                                                        texts?.generalyearshort
                                                    )}
                                                </Tag>
                                            </Row>
                                        </Form.Group>
                                    </Row>

                                    {productHasInsurance(rental) && (
                                        <Row className="skiRental__insurance">
                                            <Form.Group
                                                as={Col}
                                                md="float-start"
                                                controlId={"formBasicCheckbox" + item.id}
                                            >
                                                <div className="checkbox">
                                                    <label
                                                        htmlFor={"formBasicCheckbox" + item.id}
                                                        className="checkbox__label"
                                                    >
                                                        <span className="checkbox__label-text">
                                                            {texts?.addinsurance}
                                                        </span>
                                                        <input
                                                            type="checkbox"
                                                            id={"formBasicCheckbox" + item.id}
                                                            name="withInsurance"
                                                            className="checkbox__input"
                                                            checked={item.withInsurance}
                                                            onChange={event => {
                                                                handleProductChange(event, rental, item);
                                                            }}
                                                        />
                                                        <span className="checkbox__checkmark" aria-hidden="true" />
                                                    </label>
                                                </div>
                                            </Form.Group>
                                            <Form.Group className="float-start" as={Col}>
                                                <Row>
                                                    <Tag
                                                        type="medium"
                                                        className="col-auto skiRental__insurance-price-tag"
                                                    >
                                                        {rental.priceInsurance &&
                                                            rental.price &&
                                                            rental.priceInsurance - rental.price + " " + rental.curr}
                                                    </Tag>
                                                </Row>
                                            </Form.Group>
                                        </Row>
                                    )}

                                    <Row>
                                        <Form.Group as={Col} md="3" lg="3">
                                            <Form.Control
                                                required
                                                as="select"
                                                name="guestId"
                                                value={item.guestId || ""}
                                                onChange={event => {
                                                    handleProductChange(event, rental, item);
                                                }}
                                            >
                                                <option value="" disabled>
                                                    {texts?.generalguest}*
                                                </option>

                                                {Object.entries(guestsOnTravel).map(([, guest]) => {
                                                    return (
                                                        <option
                                                            key={guest.id}
                                                            value={guest.id}
                                                            disabled={!isGuestRightAgeForProduct(guest, rental)}
                                                        >
                                                            {getGuestNameAndAge(guest, texts?.generalyearshort)}
                                                        </option>
                                                    );
                                                })}
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" lg="2">
                                            <InputGroup>
                                                <Form.Control
                                                    required
                                                    disabled={!item.guestId}
                                                    name="weight"
                                                    value={guestsOnTravel[item.guestId]?.weight || ""}
                                                    onChange={event => {
                                                        if (isNumberWithoutDecimals(event.target.value)) {
                                                            updateGuestInformation(event, item.guestId);
                                                        }
                                                    }}
                                                    type="text"
                                                    placeholder={texts?.weight}
                                                />
                                                <InputGroup.Text>kg</InputGroup.Text>
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" lg="2">
                                            <InputGroup>
                                                <Form.Control
                                                    required
                                                    disabled={!item.guestId}
                                                    name="height"
                                                    value={guestsOnTravel[item.guestId]?.height || ""}
                                                    onChange={event => {
                                                        if (isNumberWithoutDecimals(event.target.value)) {
                                                            updateGuestInformation(event, item.guestId);
                                                        }
                                                    }}
                                                    type="text"
                                                    placeholder={texts?.height}
                                                />
                                                <InputGroup.Text>cm</InputGroup.Text>
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" lg="2">
                                            <InputGroup>
                                                <Form.Control
                                                    required
                                                    disabled={!item.guestId}
                                                    name="shoesize"
                                                    value={guestsOnTravel[item.guestId]?.shoesize || ""}
                                                    onChange={event => {
                                                        if (isNumberWithoutDecimals(event.target.value)) {
                                                            updateGuestInformation(event, item.guestId);
                                                        }
                                                    }}
                                                    type="text"
                                                    placeholder={texts?.shoesize}
                                                />
                                                <InputGroup.Text>
                                                    {texts["checkout.skirental.shoesize_unit"]}
                                                </InputGroup.Text>
                                            </InputGroup>
                                        </Form.Group>
                                    </Row>

                                    {allowLettingNotes && (
                                        <Row>
                                            <Form.Group as={Col} md={12} lg={9}>
                                                <Button
                                                    onClick={() => openNotesPromptDialog(rental, item)}
                                                    variant="link"
                                                    size="md"
                                                    className="fw-light text-primary p-0 d-flex align-items-center"
                                                >
                                                    <Icon name="FaRegEdit" size={16} className="me-2 mb-1" />
                                                    {texts[item.note ? "checkout.change_note" : "checkout.add_note"]}
                                                </Button>
                                                {item.note && (
                                                    <div className="skiRental__noteBox fw-light">{item.note}</div>
                                                )}
                                            </Form.Group>
                                        </Row>
                                    )}

                                    <Row>
                                        <Form.Group as={Col} md={12} lg={9}>
                                            <Button
                                                className="skiRental__button p-0 text-danger"
                                                variant="link"
                                                onClick={() => {
                                                    props.removeProductFromCart(rental, item);
                                                }}
                                            >
                                                {texts?.generalremove}
                                            </Button>
                                        </Form.Group>
                                    </Row>
                                </div>
                            );
                        })
                    );
                })}
            </div>
            {deliveryPoints.length && (
                <>
                    <Row className="skiRental__deliveryPoint">
                        <Form.Group as={Col} md="auto">
                            <h5>{texts["checkout.skirental.titles.delivery_point"]}</h5>
                        </Form.Group>
                    </Row>
                    <Row>{combinedDeliveryViewSelect()}</Row>
                </>
            )}
            <Modal show={showModal} setShow={setShowModal}>
                <div className="d-flex justify-content-center">
                    <Card className="border-0" style={{ width: "18rem" }}>
                        <Card.Img variant="top" src={lettinghelpimage} />
                        <Card.Body>
                            <Card.Title>{texts?.lettinghelpinformationheader || "Letting help information"}</Card.Title>
                            <Card.Text>{texts.lettinghelpinformation || "No information"}</Card.Text>
                        </Card.Body>
                    </Card>
                </div>
            </Modal>
        </div>
    );
};

export default SkiRental;
