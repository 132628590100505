import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Heading, Button, TrashIcon, ConfirmationModal, Breadcrumbs } from "@r360/library";
import PageContainer from "../../components/PageContainer";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import useTranslate from "../../hooks/useTranslate";
import { cartProductsSelector } from "../../selectors/Selectors";
import { emptyCartProducts } from "../../store/actions/checkoutCart";
import "./CartPage.scss";
import CartPageContent from "../../components/Cart/CartPageContent";
import { RootState } from "../..";

const CartPage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const cartProducts = useAppSelector(cartProductsSelector);
    const t = useTranslate(true);
    const [modal, setModal] = useState(false);
    const { isIframe, isMobile, isDesktop, isTablet, iframeOffsetTop } = useAppSelector(
        (state: RootState) => state.window
    );
    const createdFromReservation = useAppSelector(state => state.checkoutCart.createdFromReservation);

    const handleEmptyCart = async () => {
        dispatch(emptyCartProducts());
        navigate("/search");
    };

    const breadcrumbs = [
        {
            title: t("book.products"),
            path: "/search",
        },
        {
            title: t("book.cart.title"),
            path: "",
        },
    ];

    return (
        <PageContainer>
            <>
                <div className="cart-page-actions">
                    <Breadcrumbs breadcrumbs={breadcrumbs} />
                    {cartProducts.length > 0 && (
                        <>
                            {!createdFromReservation && (
                                <Button
                                    rightIcon={<TrashIcon />}
                                    type="tertiary"
                                    onClick={() => setModal(prev => !prev)}
                                >
                                    {t("book.cart.empty_cart")}
                                </Button>
                            )}

                            <ConfirmationModal
                                open={modal}
                                heading={t("book.confirmations.empty_cart.title")}
                                confirmBtnText={t("book.confirmations.empty_cart.ok")}
                                cancelBtnText={t("book.general.cancel")}
                                removing={true}
                                onClose={() => setModal(false)}
                                onConfirmClick={handleEmptyCart}
                                onCancelClick={() => setModal(false)}
                                {...(isIframe && isDesktop && { fromTop: 120 + iframeOffsetTop + "px" })}
                                {...(isIframe &&
                                    (isMobile || isTablet) && {
                                        fromTop: 80 + iframeOffsetTop + "px",
                                    })}
                            />
                        </>
                    )}
                </div>
                <Heading type="h1" className="cart-page-header">
                    {t("book.cart.title")}
                </Heading>
                <CartPageContent />
            </>
        </PageContainer>
    );
};

export default CartPage;
