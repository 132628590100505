import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { formatPrice } from "../../../Helper";
import ProductGallery from "../../ProductGallery/ProductGallery";
import ReadMoreText from "../../UI/ReadMoreText";
import "./PackageResultItem.scss";

// Package result item component
const PackageResultItem = ({ product, type, price = null }) => {
    console.log(product);
    const location = useLocation();
    const navigate = useNavigate();
    const currency = useSelector(state => state.clientData?.options?.general?.currency);

    const navigateToPool = () => {
        navigate(`/package/${type}/${product.poollid}${location.search}`);
    };

    return (
        <div className="col-sm-12 col-md-6 col-lg-4 mb-5 package-list-item package-list-item__child">
            <div className="card border-0 h-100" style={{ background: "transparent" }}>
                <div className="package-list-item__image-container">
                    <ProductGallery
                        images={(product?.images || []).map(image => ({
                            original: image.url,
                            thumbnail: image.url,
                        }))}
                        forceImageGallery={true}
                        onImageGalleryClick={navigateToPool}
                    />
                </div>
                <div className="card-body p-0" onClick={navigateToPool}>
                    <div className="card-title package-list-item__title">{product.title}</div>
                </div>
                <div className="card-body p-0" onClick={navigateToPool}>
                    <ReadMoreText lines={5} html={true} whiteSpacePreLine={true} hideAnchor>
                        {product?.weblong}
                    </ReadMoreText>
                </div>
                {price !== null && <div className="package-list-item__price">fr. {formatPrice(price, currency)}</div>}
            </div>
        </div>
    );
};

export default PackageResultItem;
