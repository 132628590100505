import React from "react";
import { useSelector } from "react-redux";
import { optionsSelector } from "../../selectors/Selectors";
import AdditionalProduct from "../AdditionalProduct/AdditionalProduct";
import "./AdditionalProductsLinks.scss";

const AdditionalProductsLinks = ({ texts, onClick, disabledReservationTypes = [], disabledReservationTypeMessage }) => {
    const reservationTypes = useSelector(state => state.clientData.reservationtypes);
    const reservationTypeCardsBackgroundColor =
        useSelector(optionsSelector).layout?.reservation_type_cards_background_color || "";

    return (
        <div className={`additional-products-links${texts?.additionalproductlinksheader ? "" : " pt-1"}`}>
            {texts?.additionalproductlinksheader && (
                <h2 className="additional-products-links__title">{texts?.additionalproductlinksheader}</h2>
            )}

            <div className="additional-products-links__list">
                {reservationTypes
                    .filter(item => item.hidden === false)
                    .map((item, index) => (
                        <div key={item.lid} className="additional-products-links__list-item">
                            <AdditionalProduct
                                texts={texts}
                                product={item}
                                index={index}
                                onClick={() => {
                                    onClick(item);
                                }}
                                height={150}
                                backgroundColorOverride={reservationTypeCardsBackgroundColor?.trim()}
                                disabled={disabledReservationTypes.includes(item.lid)}
                                disabledMessage={disabledReservationTypeMessage}
                            />
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default AdditionalProductsLinks;
