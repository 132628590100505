import React from "react";
import { useParams } from "react-router-dom";
import { ResetPassword } from "../../components/ResetPassword";
import PageContainer from "../../components/PageContainer";
import "./ResetPasswordPage.scss";

const ResetPasswordPage = () => {
    const { token } = useParams();

    return (
        <div className="reset-password-page">
            <PageContainer>
                <ResetPassword token={token} />
            </PageContainer>
        </div>
    );
};

export default ResetPasswordPage;
