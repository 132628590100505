import {
    Accordion,
    Button,
    Heading,
    Input,
    style,
    UserIcon,
    Notification,
    SkeletonGroup,
    Skeleton,
    ButtonSkeleton,
} from "@r360/library";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import useTranslate from "../../hooks/useTranslate";
import { authenticateCustomer, resetApiMessages, retrieveUserPassword } from "../../store/actions/account";
import { setSignUpWrapper } from "../../store/actions/window";
import { validateEmail } from "../../ValidationUtils";
import { Footer } from "../Footer/Footer";
import classNames from "classnames";
import "./Login.scss";
import { optionsSelector } from "../../Selectors";
import { TSignUpWrapper } from "../../store/types";

type TLogin = {
    redirectPath?: any;
    loginPage?: boolean;
};

export const Login = ({ redirectPath, loginPage = false }: TLogin) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const t = useTranslate();
    const { loginSuccessful, error, retrievedPassword } = useAppSelector(state => state.account);
    const myPageOnly = !!useAppSelector(optionsSelector).general?.mypage_only;
    const pathname = useAppSelector(state => state.window.signUpWrapper.pathname);
    const [username, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [resetPasswordEmail, setResetPasswordEmail] = useState("");
    const [showLoginErrorMessage, setShowLoginErrorMessage] = useState(false);
    const [showEmptyLoginFieldsMessage, setShowEmptyLoginFieldsMessage] = useState(false);
    const [retrievedPasswordSuccess, setRetrievedPasswordSuccess] = useState<boolean | null>(null);
    const newDesignMyPages = useAppSelector(optionsSelector).layout?.new_design_my_pages;
    const redirectTo = redirectPath ?? (loginPage ? (newDesignMyPages ? "/my-pages" : "/account") : pathname);
    const usernameIsValid = !username ? undefined : validateEmail(username);
    const resetPasswordEmailIsValid = !resetPasswordEmail ? undefined : validateEmail(resetPasswordEmail);
    const { requestsLoading } = useAppSelector(state => state.axiosStatus);
    const signUpWrapper: TSignUpWrapper = useAppSelector(state => state.window.signUpWrapper);
    const authenticationInProgress = requestsLoading["authenticateCustomer"];
    const retrievingPasswordInProgress = requestsLoading["retrieveUserPassword"];

    useEffect(() => {
        dispatch(resetApiMessages());
    }, [dispatch]);

    useEffect(() => {
        if (retrievedPassword?.result && retrievedPassword?.successful) {
            setRetrievedPasswordSuccess(true);
        } else if (retrievedPassword === false) {
            setRetrievedPasswordSuccess(false);
        } else {
            setRetrievedPasswordSuccess(null);
        }
    }, [retrievedPassword]);

    useEffect(() => {
        if (loginSuccessful) {
            navigate(redirectTo);
            dispatch(setSignUpWrapper(false, false, false));
        }
    }, [loginSuccessful, dispatch, navigate, pathname, redirectPath, redirectTo]);

    useEffect(() => {
        setShowLoginErrorMessage(error);
    }, [error]);

    useEffect(() => {
        // Ensure that the loginmodal is hidden when on the login page
        if (loginPage && signUpWrapper.showWrapper) {
            dispatch(setSignUpWrapper(false, false, false));
        }
        // eslint-disable-next-line
    }, [loginPage, signUpWrapper.showWrapper]);

    const attemptLogin = () => {
        setShowEmptyLoginFieldsMessage(false);
        setShowLoginErrorMessage(false);

        if (!username || !password) {
            setTimeout(() => {
                setShowEmptyLoginFieldsMessage(true);
            }, 0);
        } else if (usernameIsValid === false) {
            return;
        } else if (username && password) {
            dispatch(authenticateCustomer(username, password));
        }

        if (usernameIsValid === false) {
            return;
        }
    };

    const removeErrorMessages = () => {
        setShowEmptyLoginFieldsMessage(false);
        dispatch(resetApiMessages());
    };

    const onResetPasswordSubmit = () => {
        if (!resetPasswordEmailIsValid) {
            return;
        }

        resetPasswordEmail && dispatch(retrieveUserPassword(resetPasswordEmail));
    };

    return (
        <div className={classNames("login", { "login--in-page": loginPage })}>
            {authenticationInProgress ? (
                <SkeletonGroup>
                    <div className="u-mb-24 u-d-flex u-justify-content-center">
                        <Skeleton shade="dark" width="50%" height="40px" />
                    </div>
                    <div className="u-mb-24">
                        <ButtonSkeleton shade="dark" width="100%" />
                    </div>
                    <div className="u-mb-24">
                        <ButtonSkeleton shade="dark" width="100%" />
                    </div>
                    <div className="u-mb-30">
                        <ButtonSkeleton shade="dark" width="100%" />
                    </div>
                    <div className="u-mb-12">
                        <Skeleton shade="dark" width="50%" />
                    </div>
                    <div className="u-mb-30">
                        <Skeleton shade="dark" width="25%" />
                    </div>
                </SkeletonGroup>
            ) : (
                <>
                    <div className="u-mb-30">
                        <Heading type="h2" className="u-mb-24 u-text-center" onDarkBackground>
                            {t("book.menu.login")}
                        </Heading>
                        {showLoginErrorMessage && error && username && password && (
                            <div className="u-mb-18">
                                <Notification type="error">
                                    <p className="u-mb-0">{t("book.account.login_error")}</p>
                                </Notification>
                            </div>
                        )}
                        {showEmptyLoginFieldsMessage && (
                            <div className="u-mb-18">
                                <Notification type="error">
                                    <p className="u-mb-0">
                                        <div>{!username && "E-mail saknas"}</div>
                                        <div>{!password && "Lösenord saknas"}</div>
                                    </p>
                                </Notification>
                            </div>
                        )}
                        <form
                            onSubmit={e => {
                                e.preventDefault();
                                attemptLogin();
                            }}
                            noValidate
                        >
                            <div className="u-d-flex u-flex-column u-gap-12 u-mb-24">
                                <Input
                                    type="email"
                                    label={t("book.general.email")}
                                    value={username}
                                    isValid={usernameIsValid}
                                    errorMessage={t("book.account.email.validation")}
                                    id="username"
                                    name="username"
                                    onChange={e => {
                                        setUserName(e.target.value);
                                    }}
                                    onDarkBackground
                                    showValidationMessageOnBlurOnly
                                />
                                <Input
                                    label={t("book.general.password")}
                                    type="password"
                                    id="password"
                                    name="password"
                                    value={password}
                                    onChange={e => {
                                        setPassword(e.target.value);
                                    }}
                                    onDarkBackground
                                />
                            </div>
                            <Button
                                submit
                                type="login"
                                rightIcon={<UserIcon color={style.darkBlueColor} />}
                                fullWidth
                                disabled={!username || !usernameIsValid || !password}
                            >
                                {t("book.menu.login")}
                            </Button>
                        </form>
                    </div>
                    {!myPageOnly && (
                        <p>
                            <span className="u-color-white">{t("book.account.no_account")}</span>
                            <Button
                                type="tertiary"
                                allowCustomColors={false}
                                onClick={() => {
                                    removeErrorMessages();
                                    if (loginPage) {
                                        navigate(`/user/registration?path=${redirectTo}`);
                                    } else {
                                        dispatch(setSignUpWrapper(true, false, true, redirectTo));
                                    }
                                }}
                                buttonClassName="u-ml-6"
                                onDarkBackground
                            >
                                {t("book.account.create")}
                            </Button>
                        </p>
                    )}
                    <Accordion title={t("book.account.forgot_password")} stripped btnTextColor={style.whiteColor}>
                        <div className="u-pt-24 u-pb-24">
                            {retrievedPasswordSuccess === false && (
                                <div className="u-mb-18">
                                    <Notification type="error">
                                        <p className="u-mb-0">{t("book.account.no_email_found")}</p>
                                    </Notification>
                                </div>
                            )}
                            {retrievedPasswordSuccess && (
                                <div className="u-mb-18">
                                    <Notification type="success">
                                        <p className="u-mb-0">
                                            {t("book.account.retrieved_password_sent", [resetPasswordEmail])}
                                        </p>
                                    </Notification>
                                </div>
                            )}

                            {retrievingPasswordInProgress ? (
                                <div className="u-pt-12">
                                    <ButtonSkeleton width="100%" shade="dark" />
                                </div>
                            ) : (
                                <Input
                                    type="email"
                                    label={t("book.general.email")}
                                    withSubmitButton
                                    submitButtonLabel={t("book.search.campaign.submit")}
                                    onSubmitButtonPress={onResetPasswordSubmit}
                                    onChange={e => {
                                        setResetPasswordEmail(e.target.value);
                                    }}
                                    onDarkBackground
                                    isValid={resetPasswordEmailIsValid}
                                    errorMessage={t("book.account.email.validation")}
                                    showValidationMessageOnBlurOnly
                                />
                            )}
                        </div>
                    </Accordion>
                    {!loginPage && (
                        <div className="u-pt-42">
                            <Footer size="small" color="white" />
                        </div>
                    )}
                </>
            )}
        </div>
    );
};
