import React from "react";
import moment from "moment-timezone";
import { getDateFormat } from "../../Helper";
import { Icon, RichText } from "../UI";
import "./ProductView.scss";
import { useSelector } from "react-redux";

// Product view component
const ProductView = props => {
    const texts = props.texts;
    const countryOfBusiness = useSelector(state => state.clientData.countryOfBusiness);

    return (
        <div className="product-view">
            <h1 className="product-view__title">{props.title}</h1>
            <img className="product-view__image" src={props.image} alt={`presentationsbild för ${props.title}`} />
            <div className="product-view__body">
                {props.description && (
                    <div className="product-view__description">
                        <RichText content={props.description} />
                    </div>
                )}

                {props.weblong && (
                    <div className="product-view__weblong">
                        <p>
                            <RichText content={props.weblong} />
                        </p>
                    </div>
                )}
                {props.occations?.length > 0 && (
                    <div className="product-view__occations">
                        {props.occations.length > 1 && (
                            <div className="product-view__occations-header">
                                {props.occations.length} {texts.activityoccations}
                            </div>
                        )}
                        <ul className="product-view__occations-list">
                            {props.occations.map((occation, index) => {
                                return (
                                    <li className="product-view__occations-list-item" key={index}>
                                        <span>
                                            <Icon
                                                name="FaRegClock"
                                                size={14}
                                                color="#333333"
                                                className="product-view__occations-list-icon"
                                            />
                                            {moment(occation.date).format(getDateFormat(countryOfBusiness))}
                                        </span>
                                        <span>kl. {`${occation.fromtime}-${occation.totime}`}</span>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProductView;
