import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BookingsListView from "../../components/BookingsListView/BookingsListView";
import { PlusIcon } from "../../components/UI/R360Icons";
import { optionsSelector } from "../../selectors/Selectors";
import { fetchReservations, checkAuthenticationStatus } from "../../store/actions/account";
import { textsSelector } from "../../Selectors";

// My bookings account page
const MyBookings = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userToken = useSelector(state => state.account.token);
    const texts = useSelector(textsSelector);
    const reservations = useSelector(state => state.account.reservations);
    const reservationTypes = useSelector(state => state.clientData.reservationtypes);
    const myPageOnly = !!useSelector(optionsSelector).general?.mypage_only;

    // Check if authenticated
    useEffect(() => {
        dispatch(checkAuthenticationStatus(userToken));
    }, [dispatch, userToken]);

    useEffect(() => {
        dispatch(fetchReservations(userToken));
    }, [dispatch, userToken]);

    return (
        <div className="container">
            <h1 className="page-header mb-3">{texts?.bookings}</h1>

            {!myPageOnly && (
                <button
                    type="button"
                    className="btn button button--invert mb-4"
                    onClick={() => {
                        navigate("/");
                    }}
                >
                    <PlusIcon size="14" /> {texts?.generalnewreservation}
                </button>
            )}

            <BookingsListView reservations={reservations} reservationTypes={reservationTypes} texts={texts} />
        </div>
    );
};

export default MyBookings;
