import {
    Breadcrumbs,
    Button,
    ConfirmationModal,
    Heading,
    Modal,
    Notification,
    Radio,
    Skeleton,
    SkeletonGroup,
    TrashIcon,
} from "@r360/library";
import React, { ChangeEvent, FormEvent, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RootState } from "../..";
import { isAccommodation, isCancelled, isNew } from "../../BusinessUtils";
import * as Constants from "../../Constants";
import { guestsOnTravelSelector, optionsSelector, reservationTypesByTypeSelector } from "../../Selectors";
import PageContainer from "../../components/PageContainer";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import {
    checkAuthenticationStatus,
    publishUpdatedUserData,
    updateUserData,
    updatedUserData,
} from "../../store/actions/account";
import {
    addedAllProducts,
    createNewCart,
    deleteProductFromCart,
    emptyCartProducts,
    removeProduct,
    setBookingForOthers,
    syncProductsInCart,
    updateProduct,
    updateProductWithCount,
} from "../../store/actions/checkoutCart";
import { fetchGuestsOnCart, fetchGuestsOnUser, syncGuestsOnCart, updatedAllGuests } from "../../store/actions/guests";
import * as types from "../../store/actions/types";
import {
    TAgreement,
    TCartItem,
    TCartItemItem,
    TCheckoutCart,
    TGuest,
    TGuestsOnTravelReducer,
    TReservationType,
    TUser,
} from "../../store/types";

import classNames from "classnames";
import { isEmpty, lowerFirst } from "lodash";
import { getProductTitle, scrollToContainerIfNeeded, sendMessage } from "../../Helper";
import {
    CheckoutConnectAccomodation,
    CheckoutConnectActivities,
    CheckoutConnectAgreement,
    CheckoutConnectGuests,
    CheckoutConnectLetting,
    CheckoutConnectPackage,
    CheckoutConnectProceedButton,
    CheckoutConnectRental,
    CheckoutConnectSkipass,
} from "../../components/CheckoutConnect";
import { TCheckoutConnectGuestsHandle } from "../../components/CheckoutConnect/CheckoutConnectGuests/CheckoutConnectGuests";
import { ExpandableSection } from "../../components/ExpandableSection";
import useTranslate from "../../hooks/useTranslate";
import "./CheckoutConnectPage.scss";
import { RichText } from "../../components/UI";

export type TSectionStatus = "success" | "error";

export type TSectionState = {
    expanded?: boolean;
    status?: TSectionStatus;
    disabled?: boolean;
    isProduct?: boolean;
    statusMessage?: string;
    hidden?: boolean;
};

export type TSectionStates = {
    [key: string]: TSectionState;
};

export enum StaticSection {
    USER = "user",
    GUESTS = "guests",
}

type TRequestData = {
    product: TCartItem;
    cartId?: string;
    mandatory: {
        product_id: number;
        withInsurance?: number;
        withExistingKeycard?: string;
        keycardNo?: string;
        deliveryPoint?: string;
        guests?: number[];
        note?: string;
        guestNotes?: { [key: string]: string };
        phonePrefixes?: { [key: string]: string };
        phones?: { [key: string]: string };
        connectedProductId?: number | string;
        cartItemId?: number | string;
    };
};

export const isSectionStateInputValid = (sectionState: TSectionState, value: string) => {
    return sectionState.status === undefined ? undefined : !(sectionState.status === "error" && !value);
};

const CheckoutPage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const t = useTranslate();

    const account = useAppSelector((state: RootState) => state.account);
    const user = account.user as TUser;
    const userToken = account.token;
    const loginSuccessful = account.loginSuccessful;
    const successfullyUpdatedUserData = account.successfullyUpdatedUserData;
    const accountError = account.error;
    const isAgent = !!user?.isAgent;
    const isOwner = !!user?.isOwner;
    const clientData = useAppSelector(state => state.clientData);
    const texts = clientData.texts;
    const reservationTypes = clientData.reservationtypes as TReservationType[];
    const agreements = clientData.agreements as TAgreement[];
    const skipGuestsOnAccommodation = useAppSelector(optionsSelector).reservation?.skipguestsonaccommodation ?? false;
    const allGuests = useAppSelector(state => state.guests.guests) as TGuestsOnTravelReducer;
    const guestsOnTravel = useAppSelector(guestsOnTravelSelector) as { [key: string]: TGuest };
    const allGuestsUpdatedSuccessfully = useAppSelector(state => state.guests.allGuestsUpdatedSuccessfully);
    const guestsError = useAppSelector(state => state.guests.error);
    const checkoutCart = useAppSelector(state => state.checkoutCart) as TCheckoutCart;
    const bookingForOthers = checkoutCart.bookingForOthers;
    const checkoutError = checkoutCart.error;
    const checkoutCartId = checkoutCart.cartId;
    const cartSummary = checkoutCart.summary;
    const primaryCartGuestId = checkoutCart.primaryGuestId;
    const createdFromReservation = checkoutCart.createdFromReservation;
    const addedAllProductsSucceed = checkoutCart.addedAllProductsSucceed;
    const allProducts = checkoutCart.products as TCartItem[];
    const products = allProducts.filter(product => !isCancelled(product));
    const { isMobile, isTablet, isDesktop, isIframe, iframeOffsetTop } = useAppSelector(
        (state: RootState) => state.window
    );
    const accommodationTypes = useAppSelector(
        (state: RootState) =>
            reservationTypesByTypeSelector(state, Constants.productTypeNames.ACCOMMODATION) as TReservationType[]
    );
    const accommodationLids = accommodationTypes.map(x => x.lid);
    const accommodations = products.filter(product => accommodationLids.includes(product.type));
    const notAccommodations = products.filter(product => !accommodationLids.includes(product.type));
    const onlyPrimaryGuest = skipGuestsOnAccommodation && !notAccommodations.length;

    // Check if an existing booking was made with bookingForOthers as true
    const existingBookingForOthers =
        createdFromReservation && !Object.values(guestsOnTravel).some(guest => guest.id === primaryCartGuestId);

    const uniqueProductTypes = [
        ...new Set(
            [...accommodations, ...notAccommodations]
                .filter(
                    product =>
                        !product.detlid && // ... Remove booked products
                        (accommodations.some(
                            accomodation =>
                                accomodation.detlid && product.connectedProductId === accomodation.cartItemId // ... Keep products that are connected to booked accomodations
                        ) ||
                            !product.connectedProductId) // ... Otherwise remove producs connected to other products
                )
                .map(product => product.type)
        ),
    ];

    const initSectionStates = () => {
        const sectionsArray = [
            StaticSection.USER,
            StaticSection.GUESTS,
            ...uniqueProductTypes.map(product => product.toString()),
        ];
        const sections = sectionsArray.reduce((acc: TSectionStates, section) => {
            const isUser = section === StaticSection.USER;
            const isGuests = section === StaticSection.GUESTS;
            const isAccomodation = accommodations.some(accomodation => accomodation.type.toString() === section);

            let expanded = false,
                disabled = false;

            if (bookingForOthers !== null || onlyPrimaryGuest || createdFromReservation) {
                // Accomodation should be expanded and not disabled if onlyPrimaryGuest and bookingForOthers is not null
                if (isAccomodation && onlyPrimaryGuest) {
                    if (bookingForOthers !== null) {
                        expanded = true;
                    } else {
                        disabled = true;
                    }
                    // Never disable user or guest sections unless bookingForOthers is null the order is not created from reservation
                } else if (isUser || isGuests) {
                    disabled = bookingForOthers === null && !createdFromReservation;
                    // Guests should be expanded
                    if (isGuests) {
                        expanded = true;
                    }
                } else {
                    disabled = true;
                }
            } else {
                disabled = true;
            }

            return {
                ...acc,
                [section]: {
                    expanded,
                    status: undefined,
                    disabled,
                    isProduct: !isUser && !isGuests,
                    statusMessage: "",
                    hidden: isGuests && onlyPrimaryGuest && !bookingForOthers,
                },
            };
        }, {});

        return sections;
    };

    const [checkoutFinished, setCheckoutFinished] = useState(false);
    const [sectionStates, setSectionStates] = useState<TSectionStates>(initSectionStates());
    const [totalGuestsCount, setTotalGuestsCount] = useState(0);
    const [didFetchGuestsOnUser, setDidFetchGuestsOnUser] = useState(false);
    const [removedFromAssignedProductsWarning, setRemovedFromAssignedProductsWarning] = useState(false);
    const guestsRef = useRef<TCheckoutConnectGuestsHandle | null>(null);
    const [requestData, setRequestData] = useState<TRequestData[]>([]);
    const lastFormIndex = uniqueProductTypes.length;
    const [productToRemove, setProductToRemove] = useState<{
        product: TCartItem;
        item: TCartItemItem | null;
        removeProductFromCartAndSummary: boolean;
        removeGuest?: boolean;
        isAccommodation?: boolean;
    } | null>(null);
    const [showAgreementsModal, setShowAgreementsModal] = useState(false);
    const [agreementsValid, setAgreementsValid] = useState<boolean | undefined>(undefined);
    const [showSkeleton, setShowSkeleton] = useState(true);
    const [emptyCartModal, setEmptyCartModal] = useState(false);
    const [clearCartModal, setClearCartModal] = useState(false);
    const [checkingSkipConnect, setCheckingSkipConnect] = useState(true);

    const breadcrumbs = [
        {
            title: t("book.products"),
            path: "/search",
        },
        {
            title: t("book.menu.cart"),
            path: "/cart",
        },
        {
            title: `${t("book.checkout")} - ${t("book.checkout.connect_guests")}`,
        },
    ];

    // Related to R360ONL-1721 trying to find the cause of a bug
    const mrBugHunter = (customData = {}) => {
        console.log("DEBUG CheckoutPage local states", {
            sectionStates,
            bookingForOthers,
            checkoutFinished,
            totalGuestsCount,
            skipGuestsOnAccommodation,
            onlyPrimaryGuest,
            addedAllProductsSucceed,
            allGuestsUpdatedSuccessfully,
            checkoutError,
            guestsError,
            accountError,
            uniqueProductTypes,
            guestsOnTravel,
            allProducts,
            customData,
        });
    };

    // Set bookingForOthers for agents and owners
    useEffect(() => {
        if (isAgent || isOwner || existingBookingForOthers) {
            dispatch(setBookingForOthers(true));
        }
        // eslint-disable-next-line
    }, [isAgent, isOwner, existingBookingForOthers]);

    // When the user makes a choice on bookingForOthers, we need to update the sectionStates
    useEffect(() => {
        if (bookingForOthers !== null) {
            [StaticSection.USER, StaticSection.GUESTS].forEach(section => {
                changeSectionState(section, { expanded: section === StaticSection.GUESTS, disabled: false });
            });

            // When onlyPrimaryGuest, expand and enable accomodation if booking for myself and show the guest section
            if (onlyPrimaryGuest) {
                changeSectionState(uniqueProductTypes?.[0].toString(), {
                    expanded: !bookingForOthers,
                    disabled: bookingForOthers,
                });
                changeSectionState(StaticSection.GUESTS, { hidden: !bookingForOthers });
            }
        }
    }, [bookingForOthers, onlyPrimaryGuest]);

    // Remove skeletons when all when we have fetched guests and products
    // Note that when isAgent it's ok that guests is empty
    useEffect(() => {
        if ((isAgent || !isEmpty(allGuests)) && !isEmpty(allProducts) && showSkeleton && !checkingSkipConnect) {
            setShowSkeleton(false);
        }
    }, [allGuests, allProducts, showSkeleton, checkingSkipConnect, isAgent]);

    useEffect(() => {
        if (!!createdFromReservation && bookingForOthers !== null) {
            dispatch(setBookingForOthers(null));
        }
    }, [createdFromReservation, bookingForOthers, dispatch]);

    useEffect(() => {
        dispatch(checkAuthenticationStatus(userToken));
    }, [dispatch, userToken, loginSuccessful]);

    // Redirect customer to search page if no products exists in the cart
    useEffect(() => {
        if (allProducts && allProducts.length === 0) {
            console.log("Checkout: Cart is empty, redirect back to search page"); // KEEP: Useful for debugging in log rocket, because some times the cart is empty
            setEmptyCartModal(true);
        }
    }, [allProducts, texts, navigate]);

    // Skip checkout page if only extras have been changed and no agreements are awaiting answers.
    // If any other product has been changed we need to go through the checkout as usual.
    // - Check if only any extras has been added
    // - Check if only any extras has got a new quantity
    useEffect(() => {
        if (user && userToken && products && products.length > 0) {
            const hasNewProducts = products.some(isNew);

            const accommodationsWithNewOrModifiedExtras = products.filter(
                product =>
                    isAccommodation(product) &&
                    product.info?.extras?.some(extra => extra.quantity !== extra.bookedquantity)
            );

            // Do not proceed if we have any new products or if any extras have not been added or modified
            if (!hasNewProducts && accommodationsWithNewOrModifiedExtras.length) {
                // Check if all agreements have been answered, if not we show the questions again and require
                // the user to answer before proceeding to checkout summary.
                const allAgreementsAnswered = agreements.every(
                    agreement => agreement.gdprtypelid in (user.agreements || {})
                );

                // If an answer is required, the new or modified extras will be created using the normal process
                if (allAgreementsAnswered) {
                    // Add the extras and mark the checkout as finished

                    const productsData = accommodationsWithNewOrModifiedExtras.map(product => ({
                        product: product,
                        mandatory: {
                            product_id: product.id,
                            guests: skipGuestsOnAccommodation ? [] : Object.values(product.guests || {}),
                        },
                    }));

                    dispatch(syncProductsInCart(checkoutCartId, productsData));
                    setCheckoutFinished(true);
                } else {
                    setShowAgreementsModal(true);
                    setCheckingSkipConnect(false);
                }
            } else {
                setCheckingSkipConnect(false);
            }
        }
    }, []); // eslint-disable-line

    useEffect(() => {
        dispatch({ type: types.CLEAR_ERRORS });
        dispatch(addedAllProducts(false));
        dispatch(updatedAllGuests(false));
        dispatch(updatedUserData(false));

        let guestCount = 0;

        accommodations.forEach(accommodation => {
            guestCount += accommodation.info.guests?.totalGuests;
        });

        if (accommodations.length === 0) {
            guestCount += 1;
        }

        setTotalGuestsCount(guestCount);

        if (!checkoutCartId) {
            dispatch(createNewCart(userToken, clientData?.cookie));
        }
        // If a primary guest is not connected to the cart we create a new cart replacing the previous cart.
        // This may happen when using the fast checkout and switching to regular checkout.
        else if (!primaryCartGuestId) {
            dispatch(createNewCart(userToken, clientData?.cookie));
        }

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (allGuestsUpdatedSuccessfully === true && checkoutFinished === true) {
            // If agent, no user data should be updated but the flow requires it so fake that it was successful.
            if (isAgent) {
                dispatch(updatedUserData(true));
            } else {
                dispatch(publishUpdatedUserData(user, userToken));
            }
        }

        // eslint-disable-next-line
    }, [allGuestsUpdatedSuccessfully, isAgent]);

    useEffect(() => {
        if (successfullyUpdatedUserData === true && checkoutFinished === true) {
            dispatch(syncProductsInCart(checkoutCartId, requestData));
        }

        // eslint-disable-next-line
    }, [successfullyUpdatedUserData]);

    useEffect(() => {
        if (addedAllProductsSucceed === true && checkoutFinished === true) {
            navigate("/checkout/summary");
        }
    }, [addedAllProductsSucceed, checkoutFinished, navigate]);

    useEffect(() => {
        // If not guests exists for the cart, fetch from API.
        // The depandacy array is empty to prevent this function from running again and again
        if (checkoutCartId && !createdFromReservation && isEmpty(allGuests) && userToken) {
            // Fetch the guests from the cart, only the primary will exist at the beginning.
            dispatch(fetchGuestsOnCart(checkoutCartId, userToken));
        }
        // eslint-disable-next-line
    }, []);

    /**
     * Fetch the guests connected to the logged in user.
     * Only for a new booking.
     */
    useEffect(() => {
        if (
            !isEmpty(allGuests || {}) ||
            !checkoutCartId ||
            didFetchGuestsOnUser ||
            createdFromReservation ||
            !userToken
            // ||
            // onlyPrimaryGuest
        ) {
            return;
        }
        dispatch(fetchGuestsOnUser(userToken, true));
        setDidFetchGuestsOnUser(true);
    }, [
        allGuests,
        checkoutCartId,
        createdFromReservation,
        didFetchGuestsOnUser,
        dispatch,
        userToken,
        onlyPrimaryGuest,
    ]);

    /**
     * Fetch the guests connected to the logged in user.
     * Only for an existing booking.
     */
    useEffect(() => {
        if (!allGuests || !checkoutCartId || !createdFromReservation || didFetchGuestsOnUser || !userToken) {
            return;
        }

        const guestArray = Object.values(allGuests);

        if (guestArray.every(guest => guest.disabled)) {
            dispatch(fetchGuestsOnUser(userToken, true));
            setDidFetchGuestsOnUser(true);
        }
    }, [allGuests, checkoutCartId, createdFromReservation, didFetchGuestsOnUser, dispatch, userToken]);

    const removeProductFromCartAndSummary = (productToRemove: TCartItem) => {
        dispatch(removeProduct(productToRemove));
        if (cartSummary) {
            Object.keys(cartSummary.products).forEach(key => {
                const product = cartSummary.products[key];
                if (product.original_id === productToRemove.id) {
                    dispatch(deleteProductFromCart(userToken, cartSummary.id, key));
                }
            });
        }

        // If no more products exists for the reservationtype, then proceed to the next section.
        if (!products.some(product => product.type === productToRemove.type && product.id !== productToRemove.id)) {
            proceedToNextSection(productToRemove.type.toString());
        }
    };

    const removeProductFromCart = (productToRemove: TCartItem, itemToRemove: TCartItemItem) => {
        const currentCount = productToRemove.count - 1;
        if (currentCount === 0) {
            removeProductFromCartAndSummary(productToRemove);
        } else {
            dispatch(
                updateProduct(productToRemove, {
                    key: "items",
                    value: productToRemove.items?.filter(item => item !== itemToRemove),
                })
            );
            dispatch(updateProductWithCount(productToRemove, currentCount));
        }
    };

    const handleSetBookingForOthers = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === "true") {
            // Only show warning if the primary guest has products assigned to them
            if (guestsRef.current?.getPrimaryGuestAssignedTitles?.length) {
                setRemovedFromAssignedProductsWarning(true);
            } else {
                dispatch(setBookingForOthers(true));
            }
        } else {
            dispatch(setBookingForOthers(false));
        }
    };

    const handleClearCart = async () => {
        dispatch(emptyCartProducts());
        navigate("/search");
    };

    /**
     * Handler when submitting the guests view.
     */
    const handleSubmitGuests = () => {
        proceedToNextSection(StaticSection.GUESTS);
    };

    const handleSubmitOfAccommodation = (event: any, resvtypelid: number, isLastSection = false) => {
        // Prevent normal form submission early
        event.preventDefault();
        event.stopPropagation();

        console.log("DEBUG CheckoutPage.handleSubmitOfAccommodation", "ResvTypeLid", resvtypelid);

        const form = event.currentTarget;

        const invalidFormListener = form.addEventListener(
            "invalid",
            (e: any) => {
                console.log("DEBUG CheckoutPage.handleSubmitOfAccommodation invalid input was detected", e);
            },
            true
        );

        if (skipGuestsOnAccommodation) {
            handleSubmit(event, resvtypelid, isLastSection);
        }

        console.log(accommodations);

        // -- Validate that the guests that have been assigned to an accommodation have at least one phone number
        const accommodationsToValidate = accommodations
            .filter(accommodation => accommodation.type === resvtypelid)
            .filter(accommodation => accommodation.guests && Object.values(accommodation.guests).length > 0);

        const validAccommodations = accommodationsToValidate.filter(accommodation =>
            Object.values(accommodation.guests).some(guestlid => {
                const guest = guestsOnTravel[guestlid];
                return guest?.phoneprefix && guest?.phone;
            })
        );

        console.log("DEBUG CheckoutPage.handleSubmitOfAccommodation", {
            resvtypelid,
            accommodationsToValidate,
            validAccommodations,
        });

        if (validAccommodations.length === accommodationsToValidate.length) {
            handleSubmit(event, resvtypelid, isLastSection);

            if (form.checkValidity() === true) {
                console.log(
                    "DEBUG CheckoutPage.handleSubmitOfAccommodation has valid accommodations and phone numbers",
                    "ResvTypeLid",
                    resvtypelid
                );
            }
        } else {
            // One of the accommodations is invalid
            changeSectionState(resvtypelid.toString(), {
                status: "error",
                statusMessage: "Minst en gäst per boende behöver ha ett mobilnummer.",
            });
            console.log(
                "DEBUG CheckoutPage.handleSubmitOfAccommodation has an invalid accommodation",
                "Index",
                resvtypelid
            );
        }

        form.removeEventListener("invalid", invalidFormListener);
    };

    const handleSubmit = (
        event: FormEvent<HTMLFormElement>,
        section: number | string,
        isLastProduct = false,
        isAgreements = false
    ) => {
        // Prevent normal form submission early
        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;
        const invalidFormListener: any = form.addEventListener(
            "invalid",
            e => {
                console.log("DEBUG CheckoutPage.handleSubmit invalid input was detected", e);
            },
            true
        );

        const isFormValid = form.checkValidity();

        if (isFormValid === true) {
            console.log("DEBUG CheckoutPage.handleSubmit", "Section", section, "Form is valid", form);
            if (!isLastProduct && !isAgreements) {
                proceedToNextSection(section.toString());
            }
        } else {
            if (!isAgreements) {
                changeSectionState(section.toString(), { status: "error" });
            } else {
                setAgreementsValid(false);
            }

            console.warn(
                "DEBUG CheckoutPage.handleSubmit",
                "Section",
                section,
                "Form is not valid",
                "Event is",
                event,
                "Form",
                form
            );
        }

        if (agreements.length > 0) {
            console.log("DEBUG CheckoutPage.handleSubmit", {
                message: "Has agreements",
                lastFormIndex,
                section,
                isFormValid,
            });

            if (isLastProduct && isFormValid === true) {
                setShowAgreementsModal(true);
            } else if (isAgreements && isFormValid == true) {
                setAgreementsValid(true);
                handleLastButtonClick();
            }
        } else {
            console.log("DEBUG CheckoutPage.handleSubmit", {
                message: "No agreements",
                lastFormIndex,
                section,
                isFormValid,
            });

            if (isLastProduct && isFormValid === true) {
                handleLastButtonClick();
            }
        }

        mrBugHunter();
        form.removeEventListener("invalid", invalidFormListener);
    };

    const changeSectionState = (section: string, state: TSectionState) => {
        setSectionStates(prevState => ({
            ...prevState,
            [section]: {
                ...prevState[section],
                expanded: state.expanded ?? prevState[section]?.expanded,
                status: state.status ?? prevState[section]?.status,
                disabled: state.disabled ?? prevState[section]?.disabled,
                statusMessage: state.statusMessage ?? prevState[section]?.statusMessage,
                hidden: state.hidden ?? prevState[section]?.hidden,
            },
        }));
    };

    const proceedToNextSection = (currentSection: string, goToSection?: string) => {
        const firstProduct = uniqueProductTypes[0];
        let nextSection: string | null = null;

        if (!goToSection) {
            changeSectionState(currentSection, {
                expanded: false,
                status: "success",
            });
        }

        if (currentSection === StaticSection.GUESTS) {
            nextSection = firstProduct.toString();
        } else {
            const currentProductIndex = uniqueProductTypes.indexOf(parseInt(currentSection));
            nextSection = goToSection ?? uniqueProductTypes[currentProductIndex + 1].toString();
        }

        setTimeout(() => {
            if (nextSection) {
                changeSectionState(nextSection, {
                    expanded: true,
                    disabled: false,
                });

                setTimeout(() => {
                    const scrollToId =
                        goToSection === StaticSection.GUESTS ? "guest-form-section" : `product-section-${nextSection}`;

                    scrollToContainerIfNeeded(scrollToId, true);
                }, 200);
            }
        }, 500);
    };

    const handleAgreementChange = (agreement: TAgreement, value: boolean) => {
        const agreements = user.agreements || {};
        agreements[agreement.gdprtypelid] = value;
        dispatch(updateUserData("agreements", agreements));
    };

    const handleLastButtonClick = () => {
        let productData: TRequestData | null = null;
        const productsRequestData: TRequestData[] = [];

        if (showAgreementsModal) {
            setShowAgreementsModal(false);
        }

        console.log("DEBUG CheckoutPage.handleLastButtonClick", "BEGIN");

        products.forEach(product => {
            const currentProductType = reservationTypes.find(
                reservationType => reservationType.lid === product.type
            )?.type;

            switch (currentProductType) {
                case Constants.productTypeNames.ACCOMMODATION: {
                    // Guests are not mandatory if an option is enabled to book accommodations without guests.
                    // It may also happen that the option has been enabled before (e.g in demo) and disabled,
                    // which will give us an accommodation without any guests.

                    const guestsArray = skipGuestsOnAccommodation ? [] : Object.values(product.guests || {});

                    productData = {
                        product: product,
                        mandatory: {
                            product_id: product.id,
                            guests: guestsArray,
                            cartItemId: product.cartItemId || undefined,
                        },
                    };
                    productsRequestData.push(productData);
                    break;
                }
                case Constants.productTypeNames.SKIPASS: {
                    if (product.items && product.id) {
                        product.items.forEach(item => {
                            productData = {
                                product: product,
                                cartId: item.id,
                                mandatory: {
                                    product_id: product.id,
                                    withExistingKeycard: item.keycard ?? "1",
                                    guests: [parseInt(item.guestId ?? "")],
                                    keycardNo: "",
                                    connectedProductId: product.connectedProductId || undefined,
                                },
                            };
                            if (!!item.keycard && item?.valid === true) {
                                productData.mandatory.keycardNo = `${item.keycardNo}`;
                            }
                            productsRequestData.push(productData);
                        });
                    }

                    break;
                }
                case Constants.productTypeNames.ACTIVITY: {
                    // Add guest connections that have been selected on the product, e.g guestlid and phone number for ski school

                    if (product.items && product.id) {
                        if (product.dynamicprices === true) {
                            const guestsArray = product.items.map(item => parseInt(item.guestId ?? ""));
                            const guestNotes = product.items
                                .filter(item => item.note)
                                .reduce((acc: { [key: string]: string }, current) => {
                                    acc[current.guestId ?? ""] = current.note ?? "";
                                    return acc;
                                }, {});

                            // Phone number and prefix shall be handled like notes as extra details on an activity.
                            // We don't want to use the guest phone details
                            const phonePrefixes = product.items
                                .filter(item => item.phoneprefix)
                                .reduce((acc: { [key: string]: string }, current) => {
                                    acc[current.guestId ?? ""] = current.phoneprefix ?? "";
                                    return acc;
                                }, {});
                            const phones = product.items
                                .filter(item => item.phone)
                                .reduce((acc: { [key: string]: string }, current) => {
                                    acc[current.guestId ?? ""] = current.phone ?? "";
                                    return acc;
                                }, {});

                            productData = {
                                product: product,
                                mandatory: {
                                    product_id: product.id,
                                    guests: guestsArray,
                                    guestNotes,
                                    phonePrefixes,
                                    phones,
                                },
                            };
                            productsRequestData.push(productData);
                        } else {
                            product.items.forEach(item => {
                                productData = {
                                    product: product,
                                    cartId: item.id,
                                    mandatory: {
                                        product_id: product.id,
                                        guests: [parseInt(item.guestId ?? "")],
                                        guestNotes: item.note ? { [item.guestId ?? ""]: item.note } : {},
                                        phonePrefixes: item.phoneprefix
                                            ? { [item.guestId ?? ""]: item.phoneprefix }
                                            : {},
                                        phones: item.phone ? { [item.guestId ?? ""]: item.phone } : {},
                                    },
                                };
                                productsRequestData.push(productData);
                            });
                        }
                    }

                    break;
                }
                case Constants.productTypeNames.LETTING: {
                    if (product.items && product.id) {
                        product.items.forEach(item => {
                            productData = {
                                product: product,
                                cartId: item.id,

                                mandatory: {
                                    product_id: product.id,
                                    withInsurance: item.withInsurance === true ? 1 : 0,
                                    deliveryPoint: item.deliveryPoint,
                                    guests: [parseInt(item.guestId ?? "")],
                                    note: item.note || "",
                                },
                            };
                            productsRequestData.push(productData);
                        });
                    }

                    break;
                }
                case Constants.productTypeNames.RENTAL: {
                    if (product.items && product.id) {
                        product.items.forEach(item => {
                            productData = {
                                product: product,
                                cartId: item.id,

                                mandatory: {
                                    product_id: product.id,
                                    withInsurance: item.withInsurance === true ? 1 : 0,
                                    guests: [parseInt(item.guestId ?? "")],
                                    deliveryPoint: item.deliveryPoint,
                                },
                            };
                            productsRequestData.push(productData);
                        });
                    }

                    break;
                }
                case Constants.productTypeNames.PACKAGE: {
                    const guestsArray = Object.values(product.guests || {});

                    productData = {
                        product: product,
                        mandatory: {
                            product_id: product.id,
                            guests: guestsArray,
                        },
                    };
                    productsRequestData.push(productData);
                    break;
                }

                default:
                    break;
            }
        });

        mrBugHunter();

        dispatch(syncGuestsOnCart(userToken, checkoutCartId, onlyPrimaryGuest ? [] : guestsOnTravel));

        console.log("Products ready for cart", productsRequestData); // KEEP: This log is good for debugging in logrocket
        setRequestData(productsRequestData);

        const completedSectionStates = sectionStates;

        Object.entries(sectionStates).forEach(([type, values]) => {
            completedSectionStates[type] = { ...values, expanded: false, disabled: true, status: "success" };
        });

        setSectionStates(completedSectionStates);

        setCheckoutFinished(true);

        console.log("DEBUG CheckoutPage.handleLastButtonClick", "END");
    };

    const product = (type: number, index: number) => {
        const typesToShow = reservationTypes.find(rType => {
            return rType.lid === type;
        });

        const newProducts = products.filter(isNew);

        switch (typesToShow?.type) {
            case Constants.productTypeNames.ACCOMMODATION:
                return (
                    <>
                        <CheckoutConnectAccomodation
                            sectionStates={sectionStates}
                            changeSectionState={(section, state) => changeSectionState(section, state)}
                            removeProductFromCart={(product, confirmationModal = true) => {
                                if (confirmationModal) {
                                    setProductToRemove({
                                        product: product,
                                        item: null,
                                        removeProductFromCartAndSummary: true,
                                        isAccommodation: true,
                                    });
                                } else {
                                    removeProductFromCartAndSummary(product);
                                }
                            }}
                            removeConnectedProductFromCart={(product, item) =>
                                setProductToRemove({
                                    product: product,
                                    item: item,
                                    removeProductFromCartAndSummary: false,
                                })
                            }
                            key={`${type}${index}`}
                            type={typesToShow}
                            products={newProducts}
                        />
                    </>
                );

            case Constants.productTypeNames.SKIPASS:
                return (
                    <CheckoutConnectSkipass
                        sectionStates={sectionStates}
                        changeSectionState={changeSectionState}
                        removeProductFromCart={(product, item) =>
                            setProductToRemove({
                                product: product,
                                item: item,
                                removeProductFromCartAndSummary: false,
                            })
                        }
                        key={`${type}${index}`}
                        type={typesToShow}
                        products={newProducts}
                    />
                );

            case Constants.productTypeNames.LETTING:
                return (
                    <CheckoutConnectLetting
                        sectionStates={sectionStates}
                        removeProductFromCart={(product, item) =>
                            setProductToRemove({
                                product: product,
                                item: item,
                                removeProductFromCartAndSummary: false,
                            })
                        }
                        key={`${type}${index}`}
                        type={typesToShow}
                        products={newProducts}
                    />
                );

            case Constants.productTypeNames.RENTAL:
                return (
                    <CheckoutConnectRental
                        sectionStates={sectionStates}
                        removeProductFromCart={(product, item) =>
                            setProductToRemove({
                                product: product,
                                item: item,
                                removeProductFromCartAndSummary: false,
                            })
                        }
                        key={`${type}${index}`}
                        type={typesToShow}
                        products={newProducts}
                    />
                );

            case Constants.productTypeNames.ACTIVITY:
                return (
                    <CheckoutConnectActivities
                        sectionStates={sectionStates}
                        removeProductFromCart={(product, item) =>
                            setProductToRemove({
                                product: product,
                                item: item,
                                removeProductFromCartAndSummary: false,
                                removeGuest: true,
                            })
                        }
                        removeProductFromCartAndSummary={product =>
                            setProductToRemove({
                                product: product,
                                item: null,
                                removeProductFromCartAndSummary: true,
                            })
                        }
                        key={`${type}${index}`}
                        type={typesToShow}
                        products={newProducts}
                    />
                );

            case Constants.productTypeNames.PACKAGE:
                return (
                    <CheckoutConnectPackage
                        sectionStates={sectionStates}
                        removeProductFromCart={product =>
                            setProductToRemove({
                                product: product,
                                item: null,
                                removeProductFromCartAndSummary: true,
                            })
                        }
                        key={`${type}${index}`}
                        type={typesToShow}
                        products={newProducts}
                    />
                );

            default:
                return;
        }
    };

    return (
        <>
            <PageContainer>
                <div className="u-d-flex u-justify-content-between ">
                    <Breadcrumbs breadcrumbs={breadcrumbs} firstItemsDropdown={isMobile} />
                    <Button rightIcon={<TrashIcon />} type="tertiary" onClick={() => setClearCartModal(true)}>
                        {t("book.checkout.cancel_order")}
                    </Button>
                </div>
                <div className="u-pt-36">
                    <section
                        className={classNames("u-text-center u-mb-60", {
                            "u-disabled": checkoutFinished,
                        })}
                    >
                        <Heading type="h2" styleAs="h3" className="u-mb-36">
                            {t("book.checkout.are_you_booking_for_others")}
                        </Heading>
                        <div className="checkout-booking-for">
                            <Radio
                                name="booking-for-others"
                                buttonStyle
                                value="false"
                                label={t("book.checkout.booking_for_myself")}
                                checked={bookingForOthers === false}
                                onChange={handleSetBookingForOthers}
                                disabled={isAgent || checkoutFinished || !!createdFromReservation}
                            />
                            <Radio
                                name="booking-for-others"
                                buttonStyle
                                value="true"
                                label={t("book.checkout.booking_for_others")}
                                checked={bookingForOthers === true}
                                onChange={handleSetBookingForOthers}
                                disabled={isAgent || checkoutFinished || !!createdFromReservation}
                            />
                        </div>
                    </section>
                    {showSkeleton ? (
                        <>
                            <div className="u-d-flex u-flex-column u-gap-24">
                                {[...Array((Object.values(allProducts).length ?? 3) + 2)].map((_product, i) => {
                                    return (
                                        <SkeletonGroup key={i}>
                                            <Skeleton height="62px" borderRadius="12px" />
                                        </SkeletonGroup>
                                    );
                                })}
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="checkout-sections">
                                <div className="u-mb-24">
                                    <Notification type="info">
                                        <p className="u-mb-0">
                                            <RichText content={t("book.checkout.go_back_to_change")} />
                                        </p>
                                    </Notification>
                                </div>
                                <div className="u-mb-24">
                                    <CheckoutConnectGuests
                                        ref={guestsRef}
                                        sectionStates={sectionStates}
                                        changeSectionState={(section, state) => changeSectionState(section, state)}
                                        onlyPrimaryGuest={onlyPrimaryGuest}
                                        allGuests={allGuests}
                                        products={products}
                                        checkoutCartId={checkoutCartId}
                                        onSubmitAll={handleSubmitGuests}
                                        bookingForOthers={bookingForOthers}
                                        isAgent={isAgent}
                                        createdFromReservation={createdFromReservation}
                                    />
                                </div>
                                {uniqueProductTypes.length >= 1 &&
                                    uniqueProductTypes.map((type, index) => {
                                        const title = reservationTypes.find(rType => rType.lid === type)?.description;
                                        const sectionState = sectionStates?.[type];
                                        const { expanded, disabled } = sectionState;

                                        // Find the title of the next product-type
                                        let proceedBtnTitle = reservationTypes.find(
                                            rType =>
                                                rType.lid === uniqueProductTypes[uniqueProductTypes.indexOf(type) + 1]
                                        )?.description;

                                        const isLastProduct = lastFormIndex - 1 === index;

                                        if (isLastProduct) {
                                            proceedBtnTitle = lowerFirst(t("book.checkout.summary"));
                                        }

                                        const isAccommodation = accommodationLids.includes(type);

                                        return (
                                            <div
                                                id={`product-section-${type}`}
                                                key={`${type}_${index}`}
                                                className="u-mb-24"
                                            >
                                                <form
                                                    noValidate
                                                    onSubmit={e => {
                                                        e.preventDefault();
                                                        if (isAccommodation) {
                                                            handleSubmitOfAccommodation(e, type, isLastProduct);
                                                        } else {
                                                            handleSubmit(e, type, isLastProduct);
                                                        }
                                                    }}
                                                    onChange={e => {
                                                        const target = e.target as HTMLInputElement;

                                                        // Don't do live validation for skipass WTP-number
                                                        if (target.name === "keycardNo") {
                                                            return;
                                                        }

                                                        // Selecting this radio created a custom validation error
                                                        // Don't change status when selecting it
                                                        const isRadioForSkipassKeycard1 =
                                                            target.name.includes("skipass-radio") &&
                                                            target.value === "1";

                                                        if (
                                                            sectionStates[type].status !== undefined &&
                                                            !isRadioForSkipassKeycard1
                                                        ) {
                                                            changeSectionState(type.toString(), {
                                                                status: e.currentTarget.checkValidity()
                                                                    ? "success"
                                                                    : "error",
                                                            });
                                                        }
                                                    }}
                                                >
                                                    <ExpandableSection
                                                        title={title ?? ""}
                                                        expanded={expanded}
                                                        status={sectionStates[type].status}
                                                        onToggle={() => {
                                                            !disabled &&
                                                                changeSectionState(type.toString(), {
                                                                    expanded: !expanded,
                                                                });
                                                        }}
                                                        disabled={disabled}
                                                    >
                                                        <>
                                                            {sectionStates[type].status === "error" &&
                                                                sectionStates[type].statusMessage && (
                                                                    <div className="u-mb-18">
                                                                        <Notification type="error">
                                                                            <p className="u-mb-0">
                                                                                {sectionStates[type].statusMessage}
                                                                            </p>
                                                                        </Notification>
                                                                    </div>
                                                                )}
                                                            {!onlyPrimaryGuest &&
                                                                !(isAccommodation && skipGuestsOnAccommodation) && (
                                                                    <div className="u-mb-18">
                                                                        <Notification type="info">
                                                                            <p className="u-mb-0">
                                                                                {`${t(
                                                                                    "book.checkout.missing_guests"
                                                                                )} `}
                                                                                <Button
                                                                                    type="tertiary"
                                                                                    onClick={() =>
                                                                                        proceedToNextSection(
                                                                                            type.toString(),
                                                                                            StaticSection.GUESTS
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    {t(
                                                                                        "book.checkout.missing_guests.link"
                                                                                    )}
                                                                                </Button>
                                                                                {`. ${t(
                                                                                    "book.checkout.missing_guests.check_age"
                                                                                )}`}
                                                                            </p>
                                                                        </Notification>
                                                                    </div>
                                                                )}

                                                            {product(type, index)}
                                                            <CheckoutConnectProceedButton
                                                                text={`${t(
                                                                    "book.checkout.continue_to"
                                                                )} ${proceedBtnTitle} `}
                                                                lastSection={isLastProduct}
                                                            />
                                                        </>
                                                    </ExpandableSection>
                                                </form>
                                            </div>
                                        );
                                    })}
                            </div>
                        </>
                    )}

                    {checkoutFinished && (
                        <div className="u-text-center u-pt-24">
                            <p className="u-fw-medium">{t("book.checkout.connecting_products")}</p>
                        </div>
                    )}
                </div>
            </PageContainer>
            <ConfirmationModal
                open={productToRemove}
                heading={
                    productToRemove?.removeGuest && productToRemove?.item
                        ? t("book.checkout.remove_guest_from_product", [getProductTitle(productToRemove.product)])
                        : t("book.confirmations.remove_product_from_cart.title", [
                              productToRemove?.isAccommodation
                                  ? productToRemove.product.title
                                  : getProductTitle(productToRemove?.product),
                          ])
                }
                description={
                    productToRemove?.isAccommodation
                        ? t("book.confirmations.remove_product_from_cart.description_extras")
                        : ""
                }
                confirmBtnText={t("book.confirmations.remove_product_from_cart.ok")}
                cancelBtnText={t("book.confirmations.remove_product_from_cart.cancel")}
                removing={true}
                onClose={() => setProductToRemove(null)}
                onConfirmClick={() => {
                    if (productToRemove?.removeProductFromCartAndSummary) {
                        removeProductFromCartAndSummary(productToRemove.product);
                    } else if (productToRemove) {
                        removeProductFromCart(productToRemove.product, productToRemove.item ?? {});
                    }

                    setProductToRemove(null);
                }}
                onCancelClick={() => setProductToRemove(null)}
                {...(isIframe && isDesktop && { fromTop: 120 + iframeOffsetTop + "px" })}
                {...(isIframe &&
                    (isMobile || isTablet) && {
                        fromTop: 80 + iframeOffsetTop + "px",
                    })}
            />
            <ConfirmationModal
                open={removedFromAssignedProductsWarning}
                onClose={() => setRemovedFromAssignedProductsWarning(false)}
                heading={t("book.checkout.primary_guest_has_connected_products")}
                description={
                    <>
                        {t("book.checkout.primary_guest_has_connected_products.description")}
                        <ul className="u-pl-18 u-pt-12 u-mb-24">
                            {guestsRef.current?.getPrimaryGuestAssignedTitles?.map((title: string) => (
                                <li key={title}>{title}</li>
                            )) ?? ""}
                        </ul>
                    </>
                }
                confirmBtnText={t("book.general.ok")}
                onConfirmClick={() => {
                    dispatch(setBookingForOthers(true));
                    setRemovedFromAssignedProductsWarning(false);
                }}
                cancelBtnText={t("book.general.cancel")}
                onCancelClick={() => setRemovedFromAssignedProductsWarning(false)}
                removing
                {...(isIframe && isDesktop && { fromTop: 120 + iframeOffsetTop + "px" })}
                {...(isIframe &&
                    (isMobile || isTablet) && {
                        fromTop: 80 + iframeOffsetTop + "px",
                    })}
            />
            <ConfirmationModal
                open={emptyCartModal}
                onClose={() => setEmptyCartModal(false)}
                heading={t("book.cart_expired_alert.title")}
                confirmBtnText={t("book.general.continue")}
                onConfirmClick={() => {
                    navigate("/search", { replace: true });
                }}
                {...(isIframe && isDesktop && { fromTop: 120 + iframeOffsetTop + "px" })}
                {...(isIframe &&
                    (isMobile || isTablet) && {
                        fromTop: 80 + iframeOffsetTop + "px",
                    })}
            />
            <ConfirmationModal
                open={clearCartModal}
                heading={t("book.checkout.cancel_order.description")}
                confirmBtnText={t("book.checkout.cancel_order")}
                cancelBtnText={t("book.confirmations.remove_product_from_cart.cancel")}
                removing={true}
                onClose={() => setClearCartModal(false)}
                onConfirmClick={handleClearCart}
                onCancelClick={() => setClearCartModal(false)}
                {...(isIframe && isDesktop && { fromTop: 120 + iframeOffsetTop + "px" })}
                {...(isIframe &&
                    (isMobile || isTablet) && {
                        fromTop: 80 + iframeOffsetTop + "px",
                    })}
            />
            {agreements.length > 0 && (
                <Modal
                    open={showAgreementsModal}
                    onClose={() => setShowAgreementsModal(false)}
                    onOpen={() => isIframe && sendMessage({ event: "scrollToTop" })}
                    {...(isIframe && { fromTop: isDesktop ? "120px" : "80px" })}
                >
                    <form noValidate onSubmit={e => handleSubmit(e, "agreements", false, true)}>
                        <div className="u-mb-24">
                            {agreements.map((agreement: TAgreement, index: number) => {
                                return (
                                    <CheckoutConnectAgreement
                                        key={index}
                                        agreement={agreement}
                                        agreementValue={user?.agreements?.[agreement.gdprtypelid] ?? null}
                                        onChange={handleAgreementChange}
                                        agreementsValid={agreementsValid}
                                    />
                                );
                            })}
                        </div>
                        <div className="u-d-flex u-gap-12 u-justify-content-end">
                            <Button type="secondary" onClick={() => setShowAgreementsModal(false)}>
                                {t("book.general.cancel")}
                            </Button>
                            <Button submit>{t("book.general.continue")}</Button>
                        </div>
                    </form>
                </Modal>
            )}
        </>
    );
};

export default CheckoutPage;
