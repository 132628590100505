import React from "react";
import { TCriteriaItem, TCrits } from "../../store/types";
import capitalize from "lodash/capitalize";
import "./AccommodationHighlights.scss";

type TAccommodationHighlights = {
    criterias: TCriteriaItem[];
    criteriaValues: TCrits;
};

export const AccommodationHighlights = ({ criterias, criteriaValues }: TAccommodationHighlights) => {
    // Only criterias that exists on the product.
    const criteriasToDisplay = criterias.filter(criteria => criteria.code in criteriaValues);

    if (criterias.length === 0) return null;

    return (
        <div className="accommodation-highlights">
            {criteriasToDisplay.map(criteria => {
                const value = criteriaValues[criteria.code];
                return (
                    <div className="accommodation-highlight" key={criteria.code}>
                        <span className="accommodation-highlight__value">{`${value} ${criteria.typeunit}`.trim()}</span>
                        <span className="accommodation-highlight__title">
                            {capitalize(criteria.title.toLocaleLowerCase())}
                        </span>
                    </div>
                );
            })}
        </div>
    );
};
