import { Formik } from "formik";
import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import * as yup from "yup";
import { isNumberWithoutDecimals } from "../../../Helper";
import "./PersonalInformation.scss";

const PersonalRentalInformation = forwardRef((props, ref) => {
    const {
        weight,
        height,
        shoesize,
        headsize,
        texts,
        onSubmit,
        disabled,
        showWeight,
        showHeight,
        showShoesize,
        showHeadsize,
    } = props;
    const formikRef = useRef(null);

    useImperativeHandle(ref, () => ({
        submitForm: formikRef.current.submitForm,
        resetForm: formikRef.current.resetForm,
        resetTouched: () => formikRef.current.setTouched({}),
    }));

    const validationSchema = yup.object().shape({
        ...(showWeight && { weight: yup.number() }),
        ...(showHeight && { height: yup.number() }),
        ...(showShoesize && { shoesize: yup.number() }),
        ...(showHeadsize && { headsize: yup.number() }),
    });

    const customHandleSubmit = () => {
        onSubmit && onSubmit(formikRef.current.values);
    };

    if (!texts) {
        return <></>;
    }

    return (
        <div className={"personal-rental-information"}>
            <Formik
                innerRef={formikRef}
                validationSchema={validationSchema}
                onSubmit={customHandleSubmit}
                initialValues={{
                    ...(showWeight && { weight: weight || "" }),
                    ...(showHeight && { height: height || "" }),
                    ...(showShoesize && { shoesize: shoesize || "" }),
                    ...(showHeadsize && { headsize: headsize || "" }),
                }}
            >
                {({ handleSubmit, handleChange, handleBlur, values, touched, errors }) => {
                    return (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Row>
                                {showWeight && (
                                    <Form.Group as={Col} md="3">
                                        <InputGroup>
                                            <Form.Control
                                                type="text"
                                                name="weight"
                                                placeholder={texts.weight}
                                                value={values.weight}
                                                onChange={event => {
                                                    if (isNumberWithoutDecimals(event.target.value)) {
                                                        handleChange(event);
                                                    }
                                                }}
                                                onBlur={handleBlur}
                                                isValid={touched.weight && !errors.weight}
                                                isInvalid={touched.weight && !!errors.weight}
                                                disabled={disabled}
                                            />
                                            <InputGroup.Text>kg</InputGroup.Text>
                                        </InputGroup>
                                    </Form.Group>
                                )}

                                {showHeight && (
                                    <Form.Group as={Col} md="3">
                                        <InputGroup>
                                            <Form.Control
                                                type="text"
                                                name="height"
                                                placeholder={texts.height}
                                                value={values.height}
                                                onChange={event => {
                                                    if (isNumberWithoutDecimals(event.target.value)) {
                                                        handleChange(event);
                                                    }
                                                }}
                                                onBlur={handleBlur}
                                                isValid={touched.height && !errors.height}
                                                isInvalid={touched.height && !!errors.height}
                                                disabled={disabled}
                                            />
                                            <InputGroup.Text>cm</InputGroup.Text>
                                        </InputGroup>
                                    </Form.Group>
                                )}

                                {showShoesize && (
                                    <Form.Group as={Col} md="3">
                                        <InputGroup>
                                            <Form.Control
                                                type="text"
                                                name="shoesize"
                                                placeholder={texts.shoesize}
                                                value={values.shoesize}
                                                onChange={event => {
                                                    if (isNumberWithoutDecimals(event.target.value)) {
                                                        handleChange(event);
                                                    }
                                                }}
                                                onBlur={handleBlur}
                                                isValid={touched.shoesize && !errors.shoesize}
                                                isInvalid={touched.shoesize && !!errors.shoesize}
                                                disabled={disabled}
                                            />
                                            <InputGroup.Text>
                                                {texts["checkout.skirental.shoesize_unit"]}
                                            </InputGroup.Text>
                                        </InputGroup>
                                    </Form.Group>
                                )}

                                {showHeadsize && (
                                    <Form.Group as={Col} md="3">
                                        <InputGroup>
                                            <Form.Control
                                                type="text"
                                                name="headsize"
                                                placeholder={texts.headsize}
                                                value={values.headsize}
                                                onChange={event => {
                                                    if (isNumberWithoutDecimals(event.target.value)) {
                                                        handleChange(event);
                                                    }
                                                }}
                                                onBlur={handleBlur}
                                                isValid={touched.headsize && !errors.headsize}
                                                isInvalid={touched.headsize && !!errors.headsize}
                                                disabled={disabled}
                                            />
                                            <InputGroup.Text>cm</InputGroup.Text>
                                        </InputGroup>
                                    </Form.Group>
                                )}
                            </Row>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
});

PersonalRentalInformation.displayName = "PersonalRentalInformation";

export default PersonalRentalInformation;
