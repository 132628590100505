import { Skeleton, SkeletonGroup } from "@r360/library";
import React from "react";

export const AccommodationResultCardSkeleton = () => {
    return (
        <SkeletonGroup>
            <div className="u-bg-white u-br-6 u-overflow-hidden">
                <Skeleton height="200px" borderRadius="0" />
                <div className="u-p-18">
                    <Skeleton width="80%" className="u-mb-12" />
                    <div className="u-d-flex u-gap-6 u-pr-36 u-mb-24">
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                    </div>
                    <div className="row">
                        <div className="col-6 u-d-flex u-flex-column u-gap-6 u-align-items-center u-mb-12">
                            <Skeleton width="70%" />
                            <Skeleton width="90%" />
                        </div>
                        <div className="col-6 u-d-flex u-flex-column u-gap-6 u-align-items-center u-mb-12">
                            <Skeleton width="70%" />
                            <Skeleton width="90%" />
                        </div>
                        <div className="col-6 u-d-flex u-flex-column u-gap-6 u-align-items-center u-mb-12">
                            <Skeleton width="70%" />
                            <Skeleton width="90%" />
                        </div>
                        <div className="col-6 u-d-flex u-flex-column u-gap-6 u-align-items-center u-mb-12">
                            <Skeleton width="70%" />
                            <Skeleton width="90%" />
                        </div>
                    </div>
                </div>
                <Skeleton height="55px" borderRadius="0" />
            </div>
        </SkeletonGroup>
    );
};
