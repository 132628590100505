import React, { useState } from "react";
import { TItem } from "../../store/types";
import useItemInCart from "../../hooks/useItemInCart";
import { TAddToCartData, TGuests, TItemColumn } from "./MultipleVariantsCard";
import { Button, CalendarIcon, ChevronDownIcon, ChevronUpIcon, ClockIcon, style } from "@r360/library";
import { MultipleVariantsDropdownButton } from "./MultipleVariantsDropdownButton";
import useTranslate from "../../hooks/useTranslate";
import "./MultipleVariantsCard.scss";
import moment from "moment";
import useAppSelector from "../../hooks/useAppSelector";

type TMultipleItemRow = {
    groupedItem?: TItem[];
    item: TItem;
    getItemColumns: (arg0: TItem) => TItemColumn[];
    searchWithoutPrice?: boolean;
    unlimitedBookings?: boolean;
    addToCartSuccess: number | null;
    handleAddToCart: (arg0: TItem, arg1: number, arg2?: TGuests) => void;
    addToCartData: Record<number, TAddToCartData>;
    changeGroupQuantity: (arg0: TItem, arg1: number, arg2?: TGuests) => void;
};

const MultipleItemRow = ({
    item,
    groupedItem,
    getItemColumns,
    searchWithoutPrice,
    unlimitedBookings,
    addToCartSuccess,
    handleAddToCart,
    addToCartData,
    changeGroupQuantity,
}: TMultipleItemRow) => {
    const getItemInCart = useItemInCart();
    const t = useTranslate();
    const [isChooseTimeOpen, setIsChooseTimeOpen] = useState(false);
    const { isMobile } = useAppSelector(state => state.window);

    const onePlaceLeft = (item: TItem) => {
        return parseInt(item.booked ?? "0") + (item.available ?? 0) === 1;
    };

    let lowestFromTime = "";
    let highestToTime = "";

    const getFirstLastTime = () => {
        groupedItem?.forEach(i => {
            i.occations.forEach(occation => {
                if (!lowestFromTime || occation.fromtime < lowestFromTime) {
                    lowestFromTime = occation.fromtime;
                }
                if (!highestToTime || occation.totime > highestToTime) {
                    highestToTime = occation.totime;
                }
            });
        });
        if (!lowestFromTime || !highestToTime) {
            return null;
        } else {
            return (
                <div className="u-d-flex gap-1">
                    <ClockIcon color={style.brandBlueColor} className="u-mt-3" />
                    <span>{`${lowestFromTime} - ${highestToTime}`}</span>
                </div>
            );
        }
    };

    if (groupedItem && groupedItem.length > 1) {
        return (
            <div className="multiple-variants-card-item-expandable">
                <div className="multiple-variants-card-item-expandable-row">
                    <div className="multiple-variants-card-item-expandable-row-date-time">
                        <div className="u-d-flex gap-1 u-mr-36">
                            <CalendarIcon color={style.brandBlueColor} className="u-mt-3" />
                            {moment(groupedItem[0].arrdate).format("D MMMM")}
                        </div>
                        {getFirstLastTime()}
                    </div>
                    <Button
                        rightIcon={isChooseTimeOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                        buttonSize="small"
                        onClick={() => setIsChooseTimeOpen(prevState => !prevState)}
                        type="transparent"
                        fullWidth={isMobile}
                    >
                        {`${t("book.activity.select_time")} (${groupedItem.length})`}
                    </Button>
                </div>
                <div>
                    {isChooseTimeOpen &&
                        groupedItem.map((item, index) => (
                            <div key={index} className="multiple-variants-card-item-white">
                                <div className="row">
                                    {getItemColumns(item).map((column, i) => (
                                        <ItemRowColumns column={column} key={i} />
                                    ))}
                                </div>
                                <div>
                                    {searchWithoutPrice ? null : onePlaceLeft(item) || unlimitedBookings ? (
                                        <div style={{ minWidth: 185 }} className="u-d-flex u-justify-content-end">
                                            {addToCartSuccess === item.id ? (
                                                <Button type="success" buttonClassName="a-scale-11">
                                                    {t("book.results.added_to_cart")}
                                                </Button>
                                            ) : (
                                                <Button
                                                    onClick={() => handleAddToCart(item, 1)}
                                                    disabled={
                                                        unlimitedBookings ? false : getItemInCart(item).isItemInCart
                                                    }
                                                >
                                                    {unlimitedBookings
                                                        ? t("book.results.package.add_to_cart")
                                                        : t("book.results.add_to_cart")}
                                                </Button>
                                            )}
                                        </div>
                                    ) : (
                                        <MultipleVariantsDropdownButton
                                            item={item}
                                            addToCartData={addToCartData}
                                            onChange={changeGroupQuantity}
                                            handleAddToCart={handleAddToCart}
                                            addToCartSuccess={addToCartSuccess}
                                            itemInCartQuantity={getItemInCart(item).itemInCartQuantity}
                                        />
                                    )}
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        );
    } else {
        return (
            <div className="multiple-variants-card-item">
                <div className="row">
                    {getItemColumns(item).map((column, i) => (
                        <ItemRowColumns column={column} key={i} />
                    ))}
                </div>
                {searchWithoutPrice ? null : onePlaceLeft(item) || unlimitedBookings ? (
                    <div style={{ minWidth: 185 }} className="u-d-flex u-justify-content-end">
                        {addToCartSuccess === item.id ? (
                            <Button type="success" buttonClassName="a-scale-11">
                                {t("book.results.added_to_cart")}
                            </Button>
                        ) : (
                            <Button
                                onClick={() => item && handleAddToCart(item, 1)}
                                disabled={unlimitedBookings ? false : getItemInCart(item).isItemInCart}
                            >
                                {unlimitedBookings
                                    ? t("book.results.package.add_to_cart")
                                    : t("book.results.add_to_cart")}
                            </Button>
                        )}
                    </div>
                ) : (
                    <MultipleVariantsDropdownButton
                        item={item}
                        addToCartData={addToCartData}
                        onChange={changeGroupQuantity}
                        handleAddToCart={handleAddToCart}
                        addToCartSuccess={addToCartSuccess}
                        itemInCartQuantity={getItemInCart(item).itemInCartQuantity}
                    />
                )}
            </div>
        );
    }
};

export default MultipleItemRow;

type TItemRowColumns = {
    column: TItemColumn;
};

const ItemRowColumns = ({ column }: TItemRowColumns) => {
    if (!column.title) {
        return null;
    } else {
        return (
            <div className={`col-12 col-xl u-d-flex u-align-items-center`}>
                <div className="u-d-flex u-align-items-start u-gap-6">
                    <div>{column.icon}</div>
                    <div>{column.title}</div>
                </div>
            </div>
        );
    }
};
