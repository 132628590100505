import React from "react";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { Login } from "../../components/Login/Login";
import PageContainer from "../../components/PageContainer";
import "./LoginPage.scss";

/**
 * Login page
 *
 * Supports the "?path=<path>" parameter to redirect user to path after successful login
 */

export const LoginPage = () => {
    const location = useLocation();
    const qs = queryString.parse(location.search);

    return (
        <div className="login-page">
            <PageContainer>
                <Login loginPage={true} redirectPath={qs.path} />
            </PageContainer>
        </div>
    );
};

export default LoginPage;
