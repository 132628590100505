import React, { useEffect, useState } from "react";
import { Col, OverlayTrigger, Popover, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Package as PackageImage } from "../../assets/reservationTypes";
import { isBooked, isPackage, showArrDepDates } from "../../BusinessUtils";
import * as Constants from "../../Constants";
import { formatPrice } from "../../Helper";
import * as types from "../../store/actions/types";
import CancelProductButton from "../CancelProductButton/CancelProductButton";
import { CardImage, Icon, RichText } from "../UI";
import { CalendarIcon, GuestIcon } from "../UI/R360Icons";
import "./PackageList.scss";

const PackageList = ({
    uniqueProductTypes = [],
    texts,
    products,
    currency,
    booking,
    checkoutSummary,
    setRenderedProduct,
}) => {
    const dispatch = useDispatch();
    const cartCreatedFromReservation = useSelector(state => state.checkoutCart.createdFromReservation);
    const packageProducts = Object.values(products).filter(isPackage);

    const [showDetails, setShowDetails] = useState(false);

    const handleMoreInformationClick = () => {
        setShowDetails(!showDetails);
    };

    useEffect(() => {
        dispatch({ type: types.CLEAR_ERRORS });
    }, [dispatch]);

    // Get output based on product type
    const renderProduct = product => {
        // Create a new array with total numbers of guests and get its length.
        const totalGuests = Object.values(product.guests || {}).length;

        // Total guests dropdown menu code
        const renderTotalGuestsDropDown = () => {
            return (
                <OverlayTrigger
                    trigger="click"
                    placement="bottom"
                    rootClose={true}
                    overlay={
                        <Popover>
                            <Popover.Body>
                                <ul className="packageList-item__totalGuestsDropDownButton-list">
                                    {Object.values(product.guests).map(guest => {
                                        return (
                                            <li key={guest.id}>
                                                {guest.firstname} {guest.lastname}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </Popover.Body>
                        </Popover>
                    }
                >
                    <a className="link" onClick={renderTotalGuestsDropDownHandleClick}>
                        {totalGuests}
                        {totalGuests === 1 ? ` ${texts?.generalguest}` : ` ${texts?.generalguests}`}
                        <Icon name="FaCaretDown" size={12} color="black" className="packageList-item__dropDownIcon" />
                    </a>
                </OverlayTrigger>
            );
        };

        const renderTotalGuestsDropDownHandleClick = event => {
            event.stopPropagation();
            event.preventDefault();
        };

        setRenderedProduct?.(product.id);

        const renderProductDetails = () => {
            return (
                <>
                    {(checkoutSummary || isBooked(product)) && (
                        <>
                            {cartCreatedFromReservation && !isBooked(product) && (
                                <Row>
                                    <Col>
                                        <h6 className="h6">{texts["checkoutsummary.new_in_change_reservation"]}</h6>
                                    </Col>
                                </Row>
                            )}
                            <Row className="packages" key={product.id}>
                                <Col md={4} sm={4}>
                                    <CardImage
                                        image={product?.mainimage?.url}
                                        placeholder={PackageImage}
                                        liked={false}
                                        showLike={false}
                                        height="204px"
                                    />
                                </Col>
                                <Col md={8} sm={8}>
                                    <Row style={{ paddingTop: "1rem" }}>
                                        <Col md={8} xs={6}>
                                            <Row>
                                                <h6 className="packageList-item__package-item-header">
                                                    {product.title}
                                                </h6>
                                            </Row>
                                        </Col>
                                        <Col md={4} xs={6}>
                                            <Row>
                                                <Col md={booking ? 10 : 12}>
                                                    <h6 className="packageList-item__price">
                                                        {formatPrice(product.price, currency)}
                                                    </h6>
                                                </Col>
                                                {booking && (
                                                    <Col md={2} className="text-end">
                                                        <CancelProductButton
                                                            resvid={booking.resvid}
                                                            detlid={product.detlid}
                                                            productTitle={product.title}
                                                            confirmationTitle={
                                                                texts["bookingview.cancel_package_confirmation.title"]
                                                            }
                                                            confirmationDescription={
                                                                texts[
                                                                    "bookingview.cancel_package_confirmation.description"
                                                                ]
                                                            }
                                                            allowCancellation={booking.allowcancellation}
                                                        />
                                                    </Col>
                                                )}
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col md={4} className="packageList-item__icons-dates">
                                            <CalendarIcon
                                                size={19}
                                                color="#333333"
                                                iconClass="packageList-item__icons-dates-icon"
                                            />
                                            <span>
                                                {/* Show arrival/departure dates with correct formatting by reservation type */}
                                                {showArrDepDates(product)}
                                            </span>
                                        </Col>
                                        <Col md={4} className="packageList-item__icons-guests">
                                            <GuestIcon
                                                size={19}
                                                color="#333333"
                                                iconClass="packageList-item__icons-guests-guestIcon"
                                            />
                                            {<span>{renderTotalGuestsDropDown()}</span>}
                                        </Col>
                                    </Row>
                                    {showDetails && product?.weblong && (
                                        <Row>
                                            <Col>
                                                <RichText content={product.weblong} />
                                            </Col>
                                        </Row>
                                    )}
                                </Col>
                            </Row>
                        </>
                    )}
                </>
            );
        };

        return renderProductDetails();
    };

    return (
        <div className="box packageList">
            {uniqueProductTypes
                .filter(productType => productType.type === Constants.productTypeNames.PACKAGE)
                .filter(productType => {
                    return packageProducts
                        .filter(product => product.type === productType.lid)
                        .filter(product => checkoutSummary || isBooked(product)).length;
                })
                .map((productType, index) => {
                    return (
                        <div className="packageList__section-wrapper" key={productType.lid}>
                            <Row>
                                <Col md={8} sm={12}>
                                    <span className="box-head packageList__section-head">
                                        <h2 className="box-heading packageList__section-header">
                                            {productType.description}
                                        </h2>
                                    </span>
                                </Col>
                                {index === 0 && (
                                    <Col md={4} sm={12} className="text-end">
                                        <a className="link" onClick={handleMoreInformationClick}>
                                            {
                                                texts[
                                                    showDetails
                                                        ? "guests.lessinformation"
                                                        : "guests.moreinformationbutton"
                                                ]
                                            }
                                        </a>
                                    </Col>
                                )}
                            </Row>
                            {packageProducts
                                .filter(product => product.type === productType.lid)
                                .filter(product => checkoutSummary || isBooked(product))
                                .map((product, index) => {
                                    return <React.Fragment key={index}>{renderProduct(product)}</React.Fragment>;
                                })}
                        </div>
                    );
                })}
        </div>
    );
};

export default PackageList;
