import {
    Breadcrumbs,
    Button,
    ButtonSkeleton,
    ChevronLeftIcon,
    EMapType,
    ExpandableText,
    Heading,
    Map,
    Modal,
    Skeleton,
    SkeletonGroup,
} from "@r360/library";
import sortBy from "lodash/sortBy";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { RootState } from "../..";
import AxiosClient from "../../API/AxiosClient";
import { isAccommodation } from "../../BusinessUtils";
import { optionsSelector } from "../../Selectors";
import { AccommodationAmenities } from "../../components/AccommodationAmenities/AccommodationAmenities";
import { AccommodationChangeSearch } from "../../components/AccommodationChangeSearch/AccommodationChangeSearch";
import { AccommodationHighlights } from "../../components/AccommodationHighlights/AccommodationHighlights";
import { AccommodationOption } from "../../components/AccommodationOption/AccommodationOption";
import { AccommodationOwnerRules } from "../../components/AccommodationOwnerRules/AccommodationOwnerRules";
import { EditSearchButton } from "../../components/EditSearchButton/EditSearchButton";
import ImagePresentation from "../../components/ImagePresentation/ImagePresentation";
import { NoSearchResult } from "../../components/NoSearchResult/NoSearchResult";
import PageContainer from "../../components/PageContainer";
import ProductImagesGallery from "../../components/ProductImagesGallery/ProductImagesGallery";
import { RichText } from "../../components/UI";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import useChangeSearch from "../../hooks/useChangeSearch";
import useMemoizeArg from "../../hooks/useMemoizeArg";
import useTranslate from "../../hooks/useTranslate";
import { resvTypeByLid, userSelector } from "../../selectors/Selectors";
import { fetchProductsExtras } from "../../store/actions/reservationResult";
import {
    ECriteriaPresentationType,
    TCartItem,
    TCriteriaItem,
    TGroup,
    TItem,
    TReservationResult,
    TReservationTypeCriterias,
    TSearch,
} from "../../store/types";
import "./AccommodationPage.scss";

export type TOwnerRule = {
    fromDate: string;
    toDate: string;
    daysAhead: number;
    maxDays: number;
    usedDays: number;
};

enum ESearchResultView {
    NONE,
    SKELETON,
    ACCOMMODATION_OPTIONS,
    NO_SEARCH_RESULTS,
}

const dateFormat = "YYYY-MM-DD";

const AccommodationPage = () => {
    const routeParams = useParams();
    const location = useLocation();
    const reservationTypeId = parseInt(routeParams.reservationTypeid ?? "", 10);
    const grouppoollid = parseInt(routeParams.grouppoollid ?? "", 10);
    const poollid = parseInt(routeParams.poollid || "", 10);
    const unitlid = parseInt(routeParams.unitlid || "", 10);
    const reservationResult = useAppSelector((state: RootState) => state.reservationResult);
    const reservationType = useAppSelector((state: RootState) => resvTypeByLid(state, reservationTypeId));
    const reservationTypeDescription = reservationType?.description;
    const accomodationResult = reservationResult[reservationTypeId] as TReservationResult;
    const skipassExtrasResult: TReservationResult | undefined =
        reservationType?.useSkipassExtra && reservationType.skipassExtraReservationTypeId
            ? reservationResult[reservationType.skipassExtraReservationTypeId]
            : undefined;
    const { arrdate, depdate, guests, checkPriceAndAvailability } = (accomodationResult?.search as TSearch) || {};
    const { isDesktopNewDesign, isMobile, isIframe, isDesktop, isTablet, iframeOffsetTop } = useAppSelector(
        (state: RootState) => state.window
    );
    const t = useTranslate();
    const [group, setGroup] = useState<TGroup | null>(null);
    const [items, setItems] = useState<TItem[] | null>(null);
    const [hasBookableItems, setHasBookableItems] = useState<boolean>(false);
    const hasPackages = !!items && items.length >= 2;
    const [showGalleryModal, setShowGalleryModal] = useState(false);
    const [showShowTooFarApartWarning, setShowTooFarApartWarning] = useState(false);
    const [accommodationQuantityDistanceCriterias, setAccommodationQuantityDistanceCriterias] = useState<
        TCriteriaItem[]
    >([]);
    const [accommodationAmenities, setAccommodationAmenities] = useState<TCriteriaItem[]>([]);
    const reservationTypeCriterias: TReservationTypeCriterias = reservationType?.criterias || {};
    const cartProducts: TCartItem[] = useAppSelector(state => state.checkoutCart?.products);
    const accommodationCartProducts = cartProducts.filter(isAccommodation);
    const searchWithPriceExists = !!(arrdate && depdate && checkPriceAndAvailability);
    const firstItem = items?.[0];
    const lang = useAppSelector(state => state.clientData?.lang);
    const images = firstItem?.images.map(image => ({
        thumbnail: image.url,
        original: image.url,
        text: image?.translatedDesc ? image?.translatedDesc[lang] : "",
    }));
    const hasItemsWithoutExtras = items?.some(item => !item.info?.extras);
    const user = useAppSelector(userSelector);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { requestsLoading } = useAppSelector((state: RootState) => state.axiosStatus);
    const accomodationResultLoading = requestsLoading["fetchReservationTypeResults"];
    const account = useAppSelector(state => state.account);
    const [ownerRules, setOwnerRules] = useState<TOwnerRule[] | undefined>();
    const showAccommodationOwnerRules = !!useSelector(optionsSelector).owner?.show_accommodation_owner_rules;
    const [isChangeSearchOpen, setIsChangeSearchOpen] = useState(searchWithPriceExists ? false : true);
    const showImageDescriptions = useSelector(optionsSelector).layout?.show_image_descriptions || false;
    const isMapEnabled = !!useAppSelector(optionsSelector)?.module?.maps;

    useEffect(() => {
        AxiosClient.get("/client/mapview");
    }, []);

    // Fetch owner rules for accommodation if logged in as an owner and accommodation belongs to owner.
    useEffect(() => {
        if (showAccommodationOwnerRules && account?.user?.isOwner && firstItem?.ownerlid === account.user.addrlid) {
            const todayDate = moment().format(dateFormat);
            const endDate = moment().add(2, "years").format(dateFormat);

            const url = `/calendars/owner_rules/${poollid}/${unitlid}/${todayDate}/${endDate}`;

            const config = {
                headers: {
                    Authorization: `Bearer ${account.token}`,
                },
            };

            AxiosClient.get(url, config).then(response => {
                if (response?.data?.payload) {
                    setOwnerRules(response?.data?.payload);
                }
            });
        }
    }, [useMemoizeArg(firstItem), useMemoizeArg(account), showAccommodationOwnerRules]);

    useEffect(() => {
        setAccommodationQuantityDistanceCriterias(
            reservationTypeCriterias[ECriteriaPresentationType.ACCOMMODATION_QUANTITY_DISTANCE_CRITERIAS] || []
        );
        setAccommodationAmenities(reservationTypeCriterias[ECriteriaPresentationType.ACCOMMODATION_AMENITIES] || []);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [useMemoizeArg(reservationTypeCriterias)]);

    useChangeSearch({
        reservationResultSearch: accomodationResult?.search,
        reservationTypeId: reservationTypeId,
        skipGuestsOnSearchWithPrice: false,
        allowSearchWithoutPrice: true,
        grouppoollid: grouppoollid,
    });

    // Search for skipass extra.
    useChangeSearch({
        reservationResultSearch: skipassExtrasResult?.search,
        reservationTypeId: reservationType?.skipassExtraReservationTypeId,
        skipGuestsOnSearchWithPrice: true,
        allowSearchWithoutPrice: false,
        disabled: !(reservationType?.useSkipassExtra && reservationType?.skipassExtraReservationTypeId > 0),
    });

    useEffect(() => {
        let updatedHasBookableItems = false;

        if (accomodationResult?.groups) {
            const group = Object.values(accomodationResult?.groups).find(group => group.grouppoollid === grouppoollid);
            const itemsToSet = group?.items?.filter(item => item.poollid === poollid && item.unitlid === unitlid);
            // Sort items by price, but always position an empty pricetext first.
            const sortedItems = sortBy(itemsToSet, item => (!item.pricetext ? -1 : item.price));

            if (group && itemsToSet?.length) {
                updatedHasBookableItems = accomodationResult?.search?.checkPriceAndAvailability
                    ? itemsToSet?.some(item => item.pricecode || item.ownerlid)
                    : false;

                setGroup(group);
                setItems(sortedItems);
            } else if (!items) {
                // If no previous search result exists, navigate back to previous page.
                const path = `/product/${reservationTypeId}/${grouppoollid}`;
                navigate(`${path}${location.search}`, { replace: true });
            }
        }

        setHasBookableItems(updatedHasBookableItems);
    }, [accomodationResult, grouppoollid, poollid, unitlid, useMemoizeArg(items)]);

    useEffect(() => {
        if (!searchWithPriceExists) {
            return;
        }

        // Only fetch extras for items that hasn't item.info.extras set. If no extras exists, it will be an empty array.
        const fetchExtrasProductIds = [
            ...new Set((items || []).filter(item => !item.info?.extras).map(item => item.id)),
        ];

        if (fetchExtrasProductIds.length > 0) {
            dispatch(fetchProductsExtras(reservationTypeId, fetchExtrasProductIds));
        }
    }, [dispatch, reservationTypeId, useMemoizeArg(items)]);

    const renderBreadcrumbs = () => {
        const breadcrumbs = [
            {
                title: reservationTypeDescription,
                path: `/search/${reservationTypeId}${location.search}`,
            },
            {
                title: group?.groupitem.title ?? "",
                path: `/product/${reservationTypeId}/${grouppoollid}${location.search}`,
            },
            {
                title: firstItem?.title ?? "",
                path: "",
            },
        ];

        return <Breadcrumbs breadcrumbs={breadcrumbs} firstItemsDropdown={isMobile} />;
    };

    const accommodationsAreTooFarApart = () => {
        let tooFarApart = false;

        if (accommodationCartProducts.length > 0) {
            accommodationCartProducts.forEach(cartItem => {
                if (moment(cartItem.arrdate).isAfter(depdate, "day") || moment(arrdate).isAfter(cartItem.depdate)) {
                    tooFarApart = true;
                }
            });
        }

        return tooFarApart;
    };

    const getOwnerMaxRangeLength = useCallback(
        (startDate: string): number | undefined => {
            if (!ownerRules) {
                return;
            }

            let maxRangeLength = 0;
            const usedDaysCounters: { [key: number]: number } = {};

            // Go through every date between startDate and 50 days ahead and check each date against rules.
            // Don't validate against blocks, that will be handled by the DatePicker.
            for (let index = 1; index <= 50; index++) {
                const depDay = moment(startDate).add(index, "day");

                // Find first matching owner rule.
                const ruleIndex = ownerRules.findIndex(rule => {
                    const isSameOrBetween = depDay.isBetween(moment(rule.fromDate), moment(rule.toDate), "day", "[]");
                    return isSameOrBetween;
                });

                // If no rule found for depDay, allow it and move to next depDay.
                if (ruleIndex === -1) {
                    maxRangeLength++;
                    continue;
                }

                const rule = ownerRules[ruleIndex];

                if (rule.usedDays + (usedDaysCounters[ruleIndex] || 0) >= rule.maxDays) {
                    break;
                }

                usedDaysCounters[ruleIndex] = (usedDaysCounters[ruleIndex] || 0) + 1;
                maxRangeLength++;
            }

            return maxRangeLength;
        },
        [ownerRules]
    );

    const handleBooking = (item: TItem) => {
        if (accommodationsAreTooFarApart()) {
            setShowTooFarApartWarning(true);
        } else {
            navigate(
                `/product-extras/${reservationTypeId}/${item.grouppoollid}/${item.poollid}/${item.unitlid}/${item.id}`
            );
        }
    };

    const getSearchResultView = useCallback((): ESearchResultView => {
        if (accomodationResultLoading && !accomodationResult?.error) {
            return ESearchResultView.SKELETON;
        }

        if (hasBookableItems) {
            return ESearchResultView.ACCOMMODATION_OPTIONS;
        }

        if (
            accomodationResult?.search?.checkPriceAndAvailability ||
            accomodationResult?.error ||
            !accomodationResult?.groups
        ) {
            return ESearchResultView.NO_SEARCH_RESULTS;
        }

        return ESearchResultView.NONE;
    }, [
        accomodationResultLoading,
        accomodationResult?.error,
        accomodationResult?.search?.checkPriceAndAvailability,
        accomodationResult?.groups,
        hasBookableItems,
    ]);

    const searchResultView = getSearchResultView();

    const onEditSearchClick = () => {
        setIsChangeSearchOpen(prevState => !prevState);
    };

    if (!firstItem || !group) {
        return null;
    }

    return (
        <PageContainer>
            <div className="accommodation-page">
                <div className="u-mb-54">{renderBreadcrumbs()}</div>
                <>
                    <Modal open={showShowTooFarApartWarning} onClose={() => setShowTooFarApartWarning(false)}>
                        <Heading type="h3" className="u-mb-12">
                            {t("book.accomodations_not_overlapping.title")}
                        </Heading>
                        <p>{t("book.accomodations_not_overlapping.description")}</p>
                    </Modal>
                    {!isDesktop && (
                        <div>
                            <Heading type="h1" className="u-mb-12">
                                {firstItem.title}
                            </Heading>
                            {accomodationResultLoading ? (
                                <Skeleton height="200px" width="100%" className="u-mb-30" />
                            ) : (
                                <div className="u-mb-30">
                                    {!!images?.length && (
                                        <ImagePresentation
                                            aspectRatioX="16"
                                            aspectRatioY="9"
                                            spacingXInPercent="2%"
                                            spacingYInPercent="1%"
                                            images={images
                                                .slice(0, isDesktopNewDesign ? 3 : 1)
                                                .map(image => image.original)}
                                            showModalButton={images.length && images.length > 1}
                                            onShowModalButtonClick={() => setShowGalleryModal(true)}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                    <Row>
                        <Col lg={4} className="u-pr-24">
                            <Heading type="h3" className="u-mb-24">
                                {t("book.accomodation.availability_for", [firstItem.title])}
                            </Heading>

                            <div className={isChangeSearchOpen ? "u-mb-24" : ""}>
                                <EditSearchButton
                                    arrDate={arrdate}
                                    depDate={depdate}
                                    guestsCount={guests?.length}
                                    onClick={onEditSearchClick}
                                    searchExists={searchWithPriceExists}
                                    isOpen={isChangeSearchOpen}
                                />
                            </div>

                            {isChangeSearchOpen && (
                                <AccommodationChangeSearch
                                    reservationTypeid={reservationTypeId}
                                    groupPoollid={grouppoollid}
                                    poollid={poollid}
                                    unitlid={unitlid}
                                    searchButtonText={
                                        firstItem?.ownerlid === user?.addrlid
                                            ? t("book.search.owner_accommodation")
                                            : t("book.general.show_pris")
                                    }
                                    useAnyAsAvailablePeriods={user?.isOwner && firstItem?.ownerlid === user?.addrlid}
                                    getOwnerMaxRangeLength={getOwnerMaxRangeLength}
                                    isSmall={true}
                                />
                            )}

                            <div className="u-pt-12 u-mb-24 u-mt-24">
                                {searchResultView === ESearchResultView.ACCOMMODATION_OPTIONS && (
                                    <>
                                        {(items.every(item => item.ownerlid) ? [firstItem] : items)
                                            .filter(item => item.ownerlid || item.pricecode)
                                            .map((item, i) => {
                                                return (
                                                    <AccommodationOption
                                                        key={`${item.poollid}_${item.unitlid}_${item.pricecode}`}
                                                        item={item}
                                                        hasPackages={hasPackages}
                                                        guestCount={guests?.length}
                                                        arrDate={arrdate}
                                                        depDate={depdate}
                                                        onBooking={() => handleBooking(item)}
                                                        accommodationCartProducts={accommodationCartProducts}
                                                        getOwnerMaxRangeLength={getOwnerMaxRangeLength}
                                                        isSmall={true}
                                                    />
                                                );
                                            })}
                                    </>
                                )}
                                {searchResultView === ESearchResultView.SKELETON && (
                                    <SkeletonGroup>
                                        {[...Array(2)].map((_item, index) => {
                                            return (
                                                <div key={index} className="u-bg-white u-p-24 u-br-8 u-mb-18">
                                                    <div className="row">
                                                        <div className="col-12 u-d-flex u-justify-content-between">
                                                            <Skeleton className="u-mb-18" height="35px" width="60%" />
                                                            <Skeleton width="30%" height="30px" />
                                                        </div>
                                                        <div className="col-12">
                                                            <Skeleton width="100%" className="u-mb-18" />
                                                            <Skeleton width="100%" className="u-mb-18" />
                                                        </div>

                                                        <div className="col-12">
                                                            <ButtonSkeleton width="100%" />
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </SkeletonGroup>
                                )}
                                {searchResultView === ESearchResultView.NO_SEARCH_RESULTS && (
                                    <div className="u-pt-36 u-d-flex u-flex-column u-align-items-center">
                                        <NoSearchResult />
                                        <Button
                                            buttonClassName="u-mt-24 u-mb-54"
                                            leftIcon={<ChevronLeftIcon />}
                                            onClick={() => navigate("/")}
                                        >
                                            {t("book.cart.back_to_selection")}
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </Col>
                        <Col
                            lg={8}
                            style={{ borderLeft: isDesktop ? "1px solid #ccc" : "" }}
                            className={isDesktop ? "u-pl-24" : ""}
                        >
                            {isDesktop && (
                                <div>
                                    <Heading type="h1" className="u-mb-12">
                                        {firstItem.title}
                                    </Heading>
                                    <div className="u-mb-30">
                                        {!!images?.length && (
                                            <ImagePresentation
                                                aspectRatioX="16"
                                                aspectRatioY="9"
                                                spacingXInPercent="2%"
                                                spacingYInPercent="1%"
                                                images={images
                                                    .slice(0, isDesktopNewDesign ? 3 : 1)
                                                    .map(image => image.original)}
                                                showModalButton={images.length && images.length > 1}
                                                onShowModalButtonClick={() => setShowGalleryModal(true)}
                                            />
                                        )}
                                    </div>
                                </div>
                            )}
                            {firstItem.weblong && (
                                <div className="row u-mb-24">
                                    <div className="col-12">
                                        <Heading type="h2" className="u-mb-12">
                                            {t("accommodationinformationheading") || t("description")}
                                        </Heading>
                                        <ExpandableText
                                            text={firstItem.weblong ?? ""}
                                            collapsedButtonText={t("book.general.read_more")}
                                            expandedButtonText={t("book.general.read_less")}
                                            renderText={text => <RichText content={text}></RichText>}
                                        />
                                    </div>
                                </div>
                            )}
                            <div className="u-mb-42">
                                <AccommodationHighlights
                                    criterias={accommodationQuantityDistanceCriterias}
                                    criteriaValues={firstItem.crits}
                                />
                            </div>
                            {accommodationAmenities && (
                                <div className="u-mb-42">
                                    <AccommodationAmenities
                                        criterias={accommodationAmenities}
                                        criteriaValues={firstItem.crits}
                                    />
                                </div>
                            )}
                            {!!ownerRules?.length && (
                                <div className="u-pt-30 u-mb-24">
                                    <AccommodationOwnerRules ownerRules={ownerRules} />
                                </div>
                            )}
                            {isMapEnabled && !!firstItem.latitude && !!firstItem.longitude && (
                                <div className="u-mb-42 accommodation-page__map">
                                    <Map
                                        markers={[{ lat: firstItem.latitude, lng: firstItem.longitude }]}
                                        mapType={EMapType.DEFAULT}
                                        useSinglePin
                                    />
                                </div>
                            )}
                        </Col>
                    </Row>
                    <Modal
                        open={showGalleryModal}
                        size="lg"
                        onClose={() => setShowGalleryModal(false)}
                        {...(isIframe && isDesktop && { fromTop: 120 + iframeOffsetTop + "px" })}
                        {...(isIframe &&
                            (isMobile || isTablet) && {
                                fromTop: 80 + iframeOffsetTop + "px",
                            })}
                        className="accommodation-page__gallery-modal"
                    >
                        <ProductImagesGallery images={images} showImageDescriptions={showImageDescriptions} />
                    </Modal>
                </>
            </div>
        </PageContainer>
    );
};

export default AccommodationPage;
