import { Button, Checkbox, Dropdown, TDropDownHandle, TrashIcon } from "@r360/library";
import classNames from "classnames";
import { max, sum } from "lodash";
import moment from "moment-timezone";
import queryString from "query-string";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { RootState } from "../..";
import { productTypeNames } from "../../Constants";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import useTranslate from "../../hooks/useTranslate";
import { SkipassProductSelector } from "../../selectors/productsSelectors";
import { setPromoCode } from "../../store/actions/filter";
import { clearAllReservationTypeResults } from "../../store/actions/reservationResult";
import { resetLastSearchedDateSpan } from "../../store/actions/search";
import { TReservationResult, TReservationType } from "../../store/types";
import { GuestsPopover } from "../Guests/Guests";
import PromotionCodeInput from "../PromotionCodeInput";
import DatePicker from "../UI/DatePicker";
import { TAvailablePeriod, TBlockedPeriod } from "../UI/DatePicker/DatePicker";
import "./MenuSearch.scss";
import confirmationDialog from "../Utilities/ConfirmationDialog/ConfirmationDialog";

type TMenuSearch = {
    reservationTypes: TReservationType[];
    onDarkBackground?: boolean;
    availablePeriods?: { [key: string]: TAvailablePeriod[] | undefined };
    blockedPeriods?: { [key: string]: TBlockedPeriod[] | undefined };
    searchButtonText?: string;
    showMenuSearch?: boolean;
    outsideHeader?: boolean;
    onSelectStartDate?: (startDate: string) => void;
    onSelectDates?: (startDate: string, endDate: string) => void;
    maxRangeLength?: number;
    groupPoollid?: number;
    poollid?: number;
    unitlid?: number;
    isSmall?: boolean;
};

enum TCalendarType {
    SINGLE_CALENDAR = "single",
    DOUBLE_CALENDAR = "double",
}

type TSearchFields = {
    calendarType?: TCalendarType;
    calendarTogglable?: boolean;
    numberOfDays?: boolean;
    guests?: boolean;
    withSearchButton?: boolean;
    discountCode?: boolean;
    productSelector?: (state: RootState, reservationTypeid: number) => [];
};

export type TAge = {
    description: string;
    key: string;
    max: number;
    min: number;
    minCount?: number;
    title: string;
    value: number;
    requiresAge?: boolean;
    default?: number;
};

export type TAgesAreValid = {
    [key: string]: boolean;
};

export const MenuSearch: React.FC<TMenuSearch> = ({
    reservationTypes,
    onDarkBackground = true,
    availablePeriods,
    blockedPeriods,
    searchButtonText,
    showMenuSearch = true,
    outsideHeader = false,
    onSelectStartDate,
    onSelectDates,
    maxRangeLength,
    groupPoollid,
    poollid,
    unitlid,
    isSmall,
}) => {
    const t = useTranslate();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const activeReservationType = reservationTypes.find(({ active }) => active);
    const reservationTypeResult = useAppSelector(
        state => activeReservationType && state.reservationResult[activeReservationType.lid]
    ) as TReservationResult | undefined;
    const clientData = useAppSelector((state: RootState) => state.clientData);
    const productsNotAddedPath = useAppSelector(state => state.checkoutCart.productsNotAddedPath);
    const guestData = clientData?.options?.general;
    const [selectedStartDate, setSelectedStartDate] = useState("");
    const [selectedEndDate, setSelectedEndDate] = useState("");
    const { useCampaign } = (
        activeReservationType?.lid
            ? clientData.reservationtypes.find((x: TReservationType) => x.lid === activeReservationType.lid)
            : {}
    ) as TReservationType;
    const [guestsField, setGuestsField] = useState<{ [key: string]: number }>({});
    const [allowSearch, setAllowSearch] = useState(false);
    const [calendarType, setCalendarType] = useState<TCalendarType>(TCalendarType.DOUBLE_CALENDAR);
    const guestsDropdownRef = useRef<TDropDownHandle>(null);
    const { isMobile } = useSelector((state: RootState) => state.window);
    const [requiredAges, setRequiredAges] = useState<{ [key: string]: (number | string)[] }>({});
    const requiredAgesPrefix = "ages-for-";
    const [enableEndDate, setEnableEndDate] = useState(true);
    const { requestsLoading } = useAppSelector((state: RootState) => state.axiosStatus);
    const searchResultLoading = requestsLoading["fetchReservationTypeResults"];
    const location = useLocation();
    const [maxGuests, setMaxGuests] = useState<number | undefined>();

    const setSelectedDate = ({ startDate, endDate }: { startDate?: string | null; endDate?: string | null }) => {
        setSelectedStartDate(startDate || "");
        setSelectedEndDate(endDate || "");
    };

    const menuSearchFields: TSearchFields = useMemo(() => {
        switch (activeReservationType?.type) {
            case productTypeNames.SKIPASS: {
                return {
                    calendarType: TCalendarType.SINGLE_CALENDAR,
                    productSelector: SkipassProductSelector,
                    withSearchButton: true,
                    discountCode: useCampaign ?? true,
                };
            }
            case productTypeNames.ACTIVITY: {
                return {
                    calendarType: TCalendarType.DOUBLE_CALENDAR,
                    calendarTogglable: true,
                    discountCode: useCampaign ?? true,
                    withSearchButton: true,
                };
            }
            case productTypeNames.LETTING: {
                return {
                    calendarType: TCalendarType.DOUBLE_CALENDAR,
                    discountCode: useCampaign ?? true,
                    withSearchButton: true,
                    calendarTogglable: true,
                };
            }
            case productTypeNames.RENTAL: {
                return {
                    calendarType: TCalendarType.DOUBLE_CALENDAR,
                    calendarTogglable: true,
                    withSearchButton: true,
                };
            }
            case productTypeNames.ACCOMMODATION: {
                return {
                    calendarType: TCalendarType.DOUBLE_CALENDAR,
                    guests: true,
                    withSearchButton: true,
                    discountCode: useCampaign ?? true,
                };
            }
            case productTypeNames.PACKAGE: {
                return {
                    calendarType: TCalendarType.DOUBLE_CALENDAR,
                    guests: true,
                    withSearchButton: true,
                };
            }

            default: {
                return {};
            }
        }
    }, [activeReservationType?.type, useCampaign]);

    const getSearchButtonText = (): string => {
        if (searchButtonText) {
            return searchButtonText;
        }

        switch (activeReservationType?.type) {
            case productTypeNames.SKIPASS: {
                return t("book.search.skipass");
            }
            case productTypeNames.ACTIVITY: {
                return t("book.search.activity");
            }
            case productTypeNames.LETTING: {
                return t("book.search.letting");
            }
            case productTypeNames.RENTAL: {
                return t("book.search.rental");
            }
            case productTypeNames.ACCOMMODATION: {
                return t("book.search.accommodation");
            }

            default: {
                return t("book.general.search");
            }
        }
    };

    useEffect(() => {
        menuSearchFields?.calendarType &&
            setInitialDatesData(menuSearchFields?.calendarType, menuSearchFields.calendarTogglable || false);
    }, [
        activeReservationType?.lid,
        menuSearchFields,
        reservationTypeResult?.search?.arrdate,
        reservationTypeResult?.search?.depdate,
        location.search,
    ]);

    useEffect(() => {
        menuSearchFields?.guests && setInitialGuestsData(true);
    }, [activeReservationType?.lid, menuSearchFields, reservationTypeResult?.search?.guests]);

    useEffect(() => {
        setAllowSearch(checkIsAllowed());
    }, [activeReservationType?.lid, selectedStartDate, selectedEndDate, guestsField, requiredAges, menuSearchFields]);

    /**
     * Get the max guest count for the active reservation type only if it's of type accommodation.
     */
    useEffect(() => {
        // If active reservation type is of type accommodation, get the max guest count.
        if (activeReservationType?.type === productTypeNames.ACCOMMODATION) {
            // Flat map all units and get the max guest count.
            const allItems = Object.values(reservationTypeResult?.groups || {})
                .flatMap(group => group.items)
                .filter(
                    item =>
                        (!groupPoollid || item.grouppoollid === groupPoollid) &&
                        (!poollid || item.poollid === poollid) &&
                        (!unitlid || item.unitlid === unitlid)
                );

            const maxCapac = Math.max(...allItems.map(item => item.capac || 0), 0);

            setMaxGuests(maxCapac || undefined);
        } else {
            setMaxGuests(undefined);
        }
    }, [activeReservationType, reservationTypeResult, groupPoollid, poollid, unitlid]);

    const guestCount = sum(Object.values(guestsField));

    const checkIsAllowed = () => {
        if (calendarType === TCalendarType.SINGLE_CALENDAR) {
            if (!moment(selectedStartDate).isValid()) return false;
        }
        if (calendarType === TCalendarType.DOUBLE_CALENDAR) {
            if (!moment(selectedStartDate).isValid() || !moment(selectedEndDate).isValid()) return false;
        }
        if (menuSearchFields?.guests) {
            if (guestCount <= 0) {
                return false;
            }

            // If a guest that requires age have an undefined age
            if (Object.values(requiredAges).flat().includes("undefined")) {
                return false;
            }
        }

        return true;
    };

    const setInitialDatesData = (calendarType: TCalendarType, calendarTogglable: boolean) => {
        //Only set dates if it's not the initial automatic search

        const queryStringValues = queryString.parse(location.search);
        const arrdate = queryStringValues.startDate || reservationTypeResult?.search?.arrdate;
        const depdate = queryStringValues.endDate || reservationTypeResult?.search?.depdate;

        if (
            reservationTypeResult?.search?.checkPriceAndAvailability &&
            arrdate &&
            depdate &&
            // Never expects an array, but the type of queryStringValues can support it.
            !Array.isArray(arrdate) &&
            !Array.isArray(depdate)
        ) {
            setEnableEndDate(true);
            setSelectedDate({
                startDate: arrdate,
                endDate: depdate,
            });
        } else if (
            !reservationTypeResult?.search?.checkPriceAndAvailability &&
            queryStringValues.startDate &&
            queryStringValues.endDate
        ) {
            setEnableEndDate(true);
            setSelectedDate({
                startDate: queryStringValues.startDate as string,
                endDate: queryStringValues.endDate as string,
            });
        } else {
            setSelectedDate({});
            setEnableEndDate(false);
        }

        if (reservationTypeResult?.groups && calendarTogglable) {
            if (arrdate === depdate) {
                setCalendarType(TCalendarType.SINGLE_CALENDAR);
            } else {
                setCalendarType(TCalendarType.DOUBLE_CALENDAR);
            }
        } else {
            setCalendarType(calendarType);
        }
    };

    const setInitialGuestsData = (useParams = false) => {
        if (guestData?.ages && activeReservationType) {
            const obj = JSON.parse(guestData?.ages);
            const ages = obj[activeReservationType.lid];
            const guests: { [key: string]: number } = {};
            const requiredAgesFromStore: { [key: string]: (number | string)[] } = {};

            ages?.forEach((age: TAge) => {
                const guestCountFromStore = (reservationTypeResult?.search?.guests || [])?.filter(
                    (guestAge: number) => guestAge >= age.min && guestAge <= age.max
                ).length;

                const guestCount = useParams && guestCountFromStore ? guestCountFromStore : 0;

                if (age.default && age.default > (age.minCount || 0)) {
                    guests[age.key] = guestCount ? guestCount : age.default;
                } else if (age.minCount || (age.default && age.minCount && age.default < age?.minCount)) {
                    guests[age.key] = age.minCount > guestCount ? age.minCount : guestCount;
                } else {
                    guests[age.key] = guestCount ? guestCount : 0;
                }

                if (age.requiresAge) {
                    requiredAgesFromStore[age.key] =
                        reservationTypeResult?.search?.guests?.filter(
                            (guestAge: number) => guestAge >= age.min && guestAge <= age.max
                        ) || [];
                }
            });

            setGuestsField(guests);
            setRequiredAges(requiredAgesFromStore);
        }
    };

    const handleDateChange = (startDate: string, endDate: string | null = null) => {
        if (!startDate && !endDate) {
            setEnableEndDate(false);
        }

        if (moment(startDate).isValid() && !enableEndDate) {
            setEnableEndDate(true);
        }

        if (calendarType === TCalendarType.DOUBLE_CALENDAR) {
            if (moment(startDate).isValid() && !moment(endDate).isValid()) {
                setSelectedDate({
                    startDate,
                    endDate: null,
                });
                onSelectStartDate?.(startDate);
            } else if (moment(startDate).isValid() && moment(endDate).isValid()) {
                onSelectDates?.(startDate, endDate as string);
                setEnableEndDate(true);
                setSelectedDate({
                    startDate,
                    endDate,
                });
            }
        } else if (calendarType === TCalendarType.SINGLE_CALENDAR) {
            if (moment(startDate).isValid()) {
                setSelectedDate({
                    startDate,
                    endDate: startDate,
                });
            }
        }
    };

    const handleGuestChange = (count: number, data: TAge) => {
        if (count < 1) {
            setGuestsField({ ...guestsField, [data.key]: 0 });
        } else {
            setGuestsField({ ...guestsField, [data.key]: count });
        }
    };

    const createSearchUrl = () => {
        let queryStringObject: { [key: string]: string | number | null } = {
            startDate: null,
            endDate: null,
        };
        queryStringObject.startDate = selectedStartDate;
        queryStringObject.endDate = selectedEndDate;
        if (menuSearchFields?.guests) {
            for (const [key, value] of Object.entries(requiredAges)) {
                if (value.length > 0) {
                    queryStringObject[`${requiredAgesPrefix}${key}`] = value?.join().replaceAll(",", "-");
                }
            }

            queryStringObject = { ...queryStringObject, ...guestsField };
        }

        return queryString.stringify(queryStringObject);
    };

    const triggerSearch = () => {
        const allowed = checkIsAllowed();
        const autoSearch = !menuSearchFields?.withSearchButton;
        if (allowed && autoSearch) {
            handleSearch();
        }
    };

    const handleSearch = () => {
        const pathname = window.location.pathname.replace(/^.*\/client/i, "");
        const url = createSearchUrl();
        navigate(`${pathname}?${url}`, { replace: true, state: { scrollToTop: false } });
    };

    const datePickerClassName = classNames(
        calendarType === TCalendarType.SINGLE_CALENDAR
            ? "menu-search-fields__dates-single"
            : "menu-search-fields__dates"
    );

    const closeGuests = () => {
        if (guestsDropdownRef.current) {
            // start() has type inferrence here as well
            guestsDropdownRef.current.setRefIsOpen();
        }
    };

    const onSingleDateToggle = () => {
        setSelectedDate({
            startDate: calendarType === TCalendarType.DOUBLE_CALENDAR ? selectedStartDate : "",
            endDate: calendarType === TCalendarType.DOUBLE_CALENDAR ? selectedStartDate : "",
        });
        setCalendarType(
            calendarType === TCalendarType.DOUBLE_CALENDAR
                ? TCalendarType.SINGLE_CALENDAR
                : TCalendarType.DOUBLE_CALENDAR
        );
    };

    const handleShowAllOffering = async () => {
        // If there are products not added to cart, show a warning dialog
        if (productsNotAddedPath) {
            const response = await confirmationDialog({
                heading: t("book.leave_warning_header"),
                description: t("book.leave_warning_text"),
                confirmBtnText: t("book.leave_warning_confirm"),
                cancelBtnText: t("book.leave_warning_cancel"),
            });

            if (!response) {
                return;
            }
        }

        setSelectedDate({});
        setGuestsField({});
        setRequiredAges({});
        dispatch(setPromoCode(""));
        setEnableEndDate(false);
        dispatch(resetLastSearchedDateSpan());
        dispatch(clearAllReservationTypeResults());

        setTimeout(() => {
            if (activeReservationType?.lid) {
                navigate(`${location.pathname.replace(process.env.PUBLIC_URL ?? "", "")}?checkPrice=0`);
            }
        }, 500);
    };

    return showMenuSearch ? (
        <div className={isSmall ? "menu-search-small" : "menu-search"}>
            <div className={isSmall ? "menu-search-small-fields" : "menu-search-fields"}>
                {menuSearchFields?.calendarType ? (
                    <div className={isSmall ? "menu-search-small-fields__dates" : datePickerClassName}>
                        <div
                            className={classNames("date-picker-new", {
                                "date-picker-new--disable-end-date": !enableEndDate,
                            })}
                        >
                            <DatePicker
                                key={`${activeReservationType?.lid}${groupPoollid ? groupPoollid : ""}${
                                    unitlid ? unitlid : ""
                                }`}
                                resvTypeLid={`${activeReservationType?.lid}`}
                                resvTypeType={activeReservationType?.type}
                                showRange={calendarType === TCalendarType.DOUBLE_CALENDAR}
                                singleDate={calendarType === TCalendarType.SINGLE_CALENDAR}
                                onCloseCallback={triggerSearch}
                                changedDate={handleDateChange}
                                startDate={selectedStartDate ? moment(selectedStartDate) : null}
                                endDate={selectedEndDate ? moment(selectedEndDate) : null}
                                startDatePlaceholderText={t("book.search.start_date")}
                                endDatePlaceholderText={t("book.search.end_date")}
                                keepOpenOnDateSelect={false}
                                availablePeriods={availablePeriods}
                                blockedPeriods={blockedPeriods}
                                maxRangeLength={maxRangeLength}
                            />
                        </div>
                        {!isMobile && menuSearchFields?.calendarTogglable ? (
                            <Checkbox
                                label={t("book.search.single_date")}
                                checked={calendarType === TCalendarType.SINGLE_CALENDAR}
                                customColorsReversed
                                onClick={onSingleDateToggle}
                            />
                        ) : null}
                    </div>
                ) : null}
                {menuSearchFields?.guests ? (
                    <div className={isSmall ? "menu-search-small-fields__guests" : "menu-search-fields__guests"}>
                        <Dropdown
                            allowCustomColors={onDarkBackground}
                            fullWidth
                            label={t("book.search.select_guests")}
                            rightAlign
                            onCloseCallback={triggerSearch}
                            ref={guestsDropdownRef}
                            value={
                                guestCount === 0
                                    ? t("book.general.guest")
                                    : guestCount <= 1
                                    ? `${guestCount} ${t("book.general.guest")}`
                                    : `${guestCount} ${t("book.general.guests")}`
                            }
                            isValid={!Object.values(requiredAges).flat().includes("undefined")}
                        >
                            <GuestsPopover
                                resvType={`${activeReservationType?.lid}`}
                                filters={guestsField}
                                onChange={handleGuestChange}
                                onReset={setInitialGuestsData}
                                onDone={closeGuests}
                                requiredAges={requiredAges}
                                setRequiredAges={setRequiredAges}
                                maxCount={maxGuests}
                            />
                        </Dropdown>
                    </div>
                ) : null}
                {menuSearchFields?.withSearchButton ? (
                    <>
                        <Button
                            type="secondary"
                            buttonSize="medium"
                            buttonClassName={
                                isSmall
                                    ? "menu-search-small-fields__search-button"
                                    : "menu-search-fields__search-button"
                            }
                            disabled={!allowSearch || searchResultLoading}
                            fullWidth={isMobile || isSmall}
                            onClick={() => handleSearch()}
                        >
                            {getSearchButtonText()}
                        </Button>
                        {isMobile && menuSearchFields?.calendarTogglable ? (
                            <div className="menu-search-fields__mobile-calendar-toggle">
                                <Checkbox
                                    label={t("book.search.single_date")}
                                    checked={calendarType === TCalendarType.SINGLE_CALENDAR}
                                    onClick={onSingleDateToggle}
                                />
                            </div>
                        ) : null}
                    </>
                ) : null}
                {menuSearchFields?.discountCode ? (
                    <div className="menu-search-fields__discount-code-wrapper">
                        <PromotionCodeInput
                            className="menu-search-fields__discount-code"
                            onDarkBackground={onDarkBackground}
                            breakSubmittedCode={isSmall ? false : outsideHeader}
                            classNameButton={isSmall ? "menu-search-small-fields__discount-button" : ""}
                        />
                    </div>
                ) : null}
            </div>
            <Button
                type="tertiary"
                customColorsReversed={onDarkBackground}
                onClick={handleShowAllOffering}
                leftIcon={<TrashIcon />}
            >
                {t("book.search.clear_search")}
            </Button>
        </div>
    ) : menuSearchFields?.discountCode ? (
        <PromotionCodeInput
            classNameButton="menu-search-fields__discount-code-button"
            onDarkBackground={onDarkBackground}
            withoutMenuSearch
        />
    ) : null;
};
