import React from "react";
import { AnglesDownIcon, ArrowRightIcon, Button } from "@r360/library";
import "./CheckoutConnectProceedButton.scss";
import { RootState } from "../../..";
import { useSelector } from "react-redux";

type TCheckoutProceedButton = {
    text: string;
    onClick?: () => void;
    disabled?: boolean;
    lastSection?: boolean;
};

export const CheckoutConnectProceedButton = ({
    text,
    onClick,
    disabled = false,
    lastSection = false,
}: TCheckoutProceedButton) => {
    const { isMobile } = useSelector((state: RootState) => state.window);

    return (
        <div className="u-d-flex justify-content-center u-pt-12">
            <Button
                onClick={onClick}
                rightIcon={lastSection ? <ArrowRightIcon /> : <AnglesDownIcon />}
                buttonClassName="checkout-proceed-button"
                fullWidth={isMobile}
                submit
                disabled={disabled}
            >
                {text}
            </Button>
        </div>
    );
};
