import classnames from "classnames";
import React, { forwardRef, useContext, useImperativeHandle } from "react";
import { useAccordionButton } from "react-bootstrap";
import AccordionContext from "react-bootstrap/AccordionContext";
import { ArrowDownIcon } from "../../UI/R360Icons";
import "./CustomToggle.scss";

const CustomToggle = forwardRef(({ eventKey, title, icon, isMobile, callback, className }, ref) => {
    const currentEventKey = useContext(AccordionContext);
    const isCurrentEventKey = currentEventKey === eventKey;
    const customToggleClass = classnames("custom-toggle", {
        [className]: !!className,
    });
    const toggleClass = classnames("custom-toggle__toggle-icon", {
        "custom-toggle__toggle-icon--toggled": isCurrentEventKey,
    });

    useImperativeHandle(ref, () => ({
        toggle: toggleContent,
    }));

    // Toggle function
    const toggleContent = useAccordionButton(eventKey, () => {
        callback && callback(eventKey);
    });

    return (
        <div className={customToggleClass}>
            <div className="custom-toggle__container" onClick={toggleContent}>
                <div className="custom-toggle__icon">{icon}</div>
                <div className="custom-toggle__text">
                    <h2 className="custom-toggle__title">{title}</h2>
                    <ArrowDownIcon size={isMobile ? 16 : 20} color="#333333" iconClass={toggleClass} />
                </div>
            </div>
        </div>
    );
});

CustomToggle.displayName = "CustomToggle";

export default CustomToggle;
