import {
    CalendarIcon,
    CircleExclamationIcon,
    ClockIcon,
    ErrorMessage,
    LocationIcon,
    style,
    TagIcon,
    Tooltip,
    UserCheckIcon,
    UserIcon,
    UsersGroupIcon,
    UserTwoIcon,
} from "@r360/library";
import { max, min } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { RootState } from "../..";
import { ActivityDesktop } from "../../assets/reservationTypes/Activity";
import { hasDynamicPrices, hasSamePriceInAllGuestCategories, sortActivityItems, sortGroups } from "../../BusinessUtils";
import { MultipleVariantsCard } from "../../components/MultipleVariantsCard/MultipleVariantsCard";
import { MultipleVariantsCardSkeleton } from "../../components/MultipleVariantsCard/MultipleVariantsCardSkeleton";
import { NoSearchResult } from "../../components/NoSearchResult/NoSearchResult";
import { ReservationTypeHeader } from "../../components/ReservationTypeHeader/ReservationTypeHeader";
import { ReservationTypeImage } from "../../components/ReservationTypeImage/ReservationTypeImage";
import { RichText } from "../../components/UI";
import { capitalizeFirstLetter, formatPrice } from "../../Helper";
import { newFilterGroupItems } from "../../hooks";
import useAppSelector from "../../hooks/useAppSelector";
import useChangeFilter from "../../hooks/useChangeFilter";
import useChangeSearch from "../../hooks/useChangeSearch";
import { useProductsNotAddedPath } from "../../hooks/useProductsNotAddedPath";
import useTranslate from "../../hooks/useTranslate";
import { resvTypeByLid } from "../../Selectors";
import {
    TCriteriaItem,
    TGroup,
    TItem,
    TReservationResult,
    TReservationType,
    TReservationTypeLid,
} from "../../store/types";

type TActivitiesPage = {
    reservationTypeid: TReservationTypeLid;
};

export const ActivitiesPage = ({ reservationTypeid }: TActivitiesPage) => {
    const filter = useAppSelector((state: RootState) => state.filter);
    const activeFilters = filter[reservationTypeid];
    const { isDesktopNewDesign } = useAppSelector((state: RootState) => state.window);
    const { requestsLoading } = useAppSelector((state: RootState) => state.axiosStatus);
    const isLoading = requestsLoading["fetchReservationTypeResults"];
    const currency = useAppSelector((state: RootState) => state.clientData?.options?.general?.currency);
    const reservationTypeResult = useAppSelector((state: RootState) => state.reservationResult);
    const activitiesResult: TReservationResult = reservationTypeResult[reservationTypeid];
    const [filteredData, setFilteredData] = useState<TGroup[]>([]);
    const [addToCartDataQuantity, setAddToCartDataQuantity] = useState<{ [key: number]: number }>({});
    const reservationType: TReservationType = useAppSelector((state: RootState) =>
        resvTypeByLid(state, reservationTypeid)
    );
    const reservationTypeCriterias = reservationType?.criterias || {};
    const addToCartTotalQuantity = Object.values(addToCartDataQuantity).reduce((acc, item) => acc + item, 0);
    const showProductsNotAddedWarning = !!addToCartTotalQuantity;
    const t = useTranslate();

    useChangeSearch({
        reservationResultSearch: activitiesResult?.search,
        reservationTypeId: reservationTypeid,
        skipGuestsOnSearchWithPrice: true,
        allowSearchWithoutPrice: false,
    });
    useProductsNotAddedPath(showProductsNotAddedWarning);
    useChangeFilter(reservationTypeid);

    // Clear addToCartData when a new search has been made
    useEffect(() => {
        setAddToCartDataQuantity({});
    }, [activitiesResult?.resultSetId]);

    useEffect(() => {
        const criterias = Object.values(reservationTypeCriterias)
            .flat()
            .reduce((acc, curr) => {
                acc[curr.code] = curr;
                return acc;
            }, {} as { [key: number]: TCriteriaItem });

        // Filter by active criterias.
        let filteredData = Object.values(activitiesResult?.groups || {})
            .map((group: TGroup) => {
                return newFilterGroupItems(group, activeFilters || {}, criterias);
            })
            .filter(group => !!group.items.length);

        // Sort activity groups and items.
        filteredData = sortGroups(filteredData).map(group => {
            group.items = sortActivityItems(group.items);
            return group;
        });

        setFilteredData(filteredData);
    }, [activitiesResult?.groups, activeFilters]);

    const getGroupAgeSpan = useCallback(
        (group: TGroup) => {
            const { minAge, maxAge } = group.items.reduce(
                (acc, curr) => {
                    return {
                        minAge: !acc.minAge ? curr.minage : min([curr.minage, acc.minAge]) ?? 0,
                        maxAge: max([curr.maxage, acc.maxAge]) ?? 0,
                    };
                },
                { minAge: 0, maxAge: 0 }
            );

            if (minAge === 0 && maxAge === 99) {
                return t("book.general.all_ages");
            } else {
                return `${minAge} - ${maxAge} ${t("book.general.years")}`;
            }
        },
        [activitiesResult?.resultSetId]
    );

    const getActivityColumns = (item: TItem) => {
        const occations = item.occations;
        const multipleOccations = occations.length > 1;
        const firstOccation = occations[0];
        const lastOccation = multipleOccations ? occations[occations.length - 1] : undefined;
        const days = moment(lastOccation?.date).diff(moment(firstOccation.date), "days") + 1;
        const daysWithoutOccation = days - occations.length;
        const bookedPlaces = parseInt(item.booked ?? "0");
        const unlimitedPlaces = item.freesale === "F" ? false : !!item.freesale;
        const privateActivity = item.limittoonereservation === "F" ? false : !!item.limittoonereservation;
        const privateActivitySingle = bookedPlaces + (item?.available ?? 0) === 1;

        const lowestPrice = (item: TItem) => {
            const priceEntries = Object.entries(item).filter(
                ([key, value]) => key.startsWith("pricec") && typeof value === "number"
            );

            if (priceEntries.length === 0) {
                return null;
            } else if (priceEntries) {
                return priceEntries.reduce(
                    (min, [key, value]) => (value && min ? (value < min ? value : min) : null),
                    priceEntries[0][1]
                );
            }
        };

        return [
            {
                title: (
                    <div className="u-d-flex u-gap-6 u-align-items-center" style={{ minWidth: 185 }}>
                        {multipleOccations ? (
                            <div>
                                <div>{`${moment(firstOccation.date).format("D MMMM")} - ${moment(
                                    lastOccation?.date
                                ).format("D MMMM")}`}</div>
                                <div>{t("book.general.number_days", [occations.length])}</div>
                            </div>
                        ) : (
                            moment(firstOccation?.date).format("D MMMM")
                        )}
                        {daysWithoutOccation > 0 && (
                            <Tooltip
                                toolTipContent={
                                    <>
                                        {occations.map(occ => {
                                            return (
                                                <div key={occ.date}>
                                                    {capitalizeFirstLetter(moment(occ.date).format("dddd D MMMM"))}
                                                </div>
                                            );
                                        })}
                                    </>
                                }
                                triggerOnClick
                            >
                                <div style={{ cursor: "pointer" }}>
                                    <CircleExclamationIcon color={style.yellowColor} />
                                </div>
                            </Tooltip>
                        )}
                    </div>
                ),
                icon: <CalendarIcon color={style.brandBlueColor} />,
            },
            {
                title: `${item.fromtime} - ${item.totime}`,
                icon: <ClockIcon color={style.brandBlueColor} />,
            },
            {
                title: item.location ? capitalizeFirstLetter(item.location.toLowerCase()) : undefined,
                icon: <LocationIcon color={style.brandBlueColor} />,
            },
            {
                title: (
                    <>
                        {privateActivitySingle ? (
                            <>
                                <RichText content={t("book.activity.activity_for_one")} />
                            </>
                        ) : privateActivity ? (
                            <>
                                <RichText
                                    content={t("book.activity.company_max_persons", [
                                        bookedPlaces + (item.available ?? 0),
                                    ])}
                                />
                            </>
                        ) : (
                            <>
                                <div>
                                    {unlimitedPlaces
                                        ? t("book.activity.unlimited_places")
                                        : t("book.activity.group_activity")}
                                </div>
                                {!unlimitedPlaces && (
                                    <div>
                                        {t("book.activity.places_of_places_left", [
                                            item.available ?? 0,
                                            bookedPlaces + (item.available ?? 0),
                                        ])}
                                    </div>
                                )}
                            </>
                        )}
                    </>
                ),
                icon: privateActivitySingle ? (
                    <UserIcon color={style.brandBlueColor} />
                ) : privateActivity ? (
                    <UserTwoIcon color={style.brandBlueColor} />
                ) : (
                    <UsersGroupIcon color={style.brandBlueColor} />
                ),
            },
            {
                title: `${formatPrice(lowestPrice(item), currency)}`,
                icon: <TagIcon color={style.brandBlueColor} />,
            },
        ];
    };

    const renderActivitiesList = () => {
        if (isLoading) {
            return [...Array(8)].map((_, i) => <MultipleVariantsCardSkeleton key={i} isDesktop={isDesktopNewDesign} />);
        } else if (filteredData.length && !isLoading) {
            return filteredData.map((group, i) => {
                const title = group.groupitem?.title ?? group.items[0]?.title;
                const images = [
                    ...(group.items[0]?.mainimage ? [group.items[0]?.mainimage] : []),
                    ...(group.items[0]?.images || []),
                ];

                let priceLabelText = "";

                if (hasDynamicPrices(group.items[0])) {
                    priceLabelText = t("book.activity.groupprice_from");
                } else if (hasSamePriceInAllGuestCategories(group.items[0])) {
                    priceLabelText = t("book.general.price_per_person");
                } else {
                    priceLabelText = t("book.general.price_from");
                }

                return (
                    <MultipleVariantsCard
                        key={group.id}
                        group={group}
                        items={group.items}
                        title={title}
                        description={group.groupitem?.weblong ?? group.items[0]?.weblong}
                        availability={group.items[0]?.available}
                        images={images}
                        showVariants
                        currency={currency}
                        getItemColumns={getActivityColumns}
                        priceLabelText={priceLabelText}
                        showItemsButtonText={t("book.activity.show_occurrences", [group.items.length])}
                        fallbackImage={ActivityDesktop}
                        renderExtraInformation={() => {
                            return (
                                <div className="u-d-flex u-gap-6 u-align-items-center">
                                    <UserCheckIcon />
                                    <span className="u-fw-medium">{getGroupAgeSpan(group)}</span>
                                </div>
                            );
                        }}
                        onAddToCartQuantityChange={data =>
                            setAddToCartDataQuantity(prevState => {
                                if (prevState[group.items[0]?.id] === data) {
                                    return prevState;
                                }

                                return { ...prevState, [group.items[0]?.id]: data };
                            })
                        }
                        index={i}
                        arrayLength={filteredData.length}
                        animate={!Object.values(activeFilters ?? {}).length}
                        decorateItemBeforeCart={item => ({ ...item, groupTitle: title })}
                        reservationType={reservationType.type}
                    />
                );
            });
        } else if ((activitiesResult?.hitsMatchingFilter === "0" || !filteredData.length) && !isLoading) {
            return <NoSearchResult />;
        } else if (activitiesResult?.error && !isLoading) {
            return (
                <div className="u-pt-24">
                    <ErrorMessage
                        headingText={t("book.general.error")}
                        primaryButtonText={t("book.general.reload_page")}
                        primaryButtonOnClick={() => window.location.reload()}
                    />
                </div>
            );
        }
    };

    const showFilter =
        activitiesResult?.groups &&
        reservationTypeCriterias &&
        Object.values(activitiesResult.groups).length > 0 &&
        Object.values(reservationTypeCriterias).flat().length > 0;

    return activitiesResult?.search || isLoading ? (
        <>
            {/* <ProductsNotAddedToCartModal readyToShow={showProductsNotAddedWarning} /> */}
            <ReservationTypeHeader
                reservationTypeId={reservationTypeid}
                hits={filteredData?.length}
                filtersAvailable={showFilter}
            />
            {renderActivitiesList()}
        </>
    ) : (
        <ReservationTypeImage reservationTypeId={reservationTypeid} />
    );
};
