import React from "react";
import { IconContext } from "react-icons";
import * as Icons from "react-icons/fa";
import PropTypes from "prop-types";
import "./Icon.scss";

const Icon = props => {
    const IconType = Icons[props.name];

    return (
        <IconContext.Provider value={{ color: `${props.color}` }}>
            <IconType {...props} size={props.size} />
        </IconContext.Provider>
    );
};

export default Icon;

Icon.propTypes = {
    name: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
    color: PropTypes.string,
    className: PropTypes.string,
};
