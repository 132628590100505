import React from "react";
import { Heading, IncrementerButton } from "@r360/library";
import "./Incrementer.scss";
import classNames from "classnames";

type TIncrementer = {
    label?: string;
    name: string;
    describedBy?: string;
    value: number;
    minValue?: number;
    maxValue?: number;
    onChange: (value: { key: string; value: number }) => void;
    size?: "small" | "medium";
    disabled?: boolean;

    // All other props
    [x: string]: unknown;
};

// Number incrementer component
export const Incrementer: React.FC<TIncrementer> = ({
    label = "",
    name,
    describedBy = "",
    value,
    minValue = 0,
    maxValue,
    onChange,
    size = "medium",
    disabled = false,
    ...restProps
}) => {
    // Handle decrement value
    const handleDecrement = () => {
        if (onChange) {
            if (value > minValue) {
                onChange({ key: name, value: value - 1 });
            }
        }
    };

    // Handle increment value
    const handleIncrement = () => {
        if (onChange) {
            // Note: maxValue may be 0, which is falsey so we need to check for undefined
            if (typeof maxValue !== "undefined") {
                if (maxValue > value) {
                    onChange({ key: name, value: value + 1 });
                }
            } else {
                onChange({ key: name, value: value + 1 });
            }
        }
    };

    const minValueReached = !!(value <= minValue);

    // Note: maxValue may be 0, which is falsey so we need to check for undefined
    const maxValueReached = !!(typeof maxValue !== "undefined" && value >= maxValue);

    // Set button classes based on count, min and max values
    const decrementClasses = minValueReached
        ? "incrementer__toggle-button incrementer__toggle-button--disabled"
        : "incrementer__toggle-button";
    const incrementClasses = maxValueReached
        ? "incrementer__toggle-button incrementer__toggle-button--disabled"
        : "incrementer__toggle-button";

    return (
        <div className={classNames("incrementer", { "u-disabled": disabled })} role="group" id={name} {...restProps}>
            <button
                type="button"
                onClick={handleDecrement}
                className={decrementClasses}
                aria-label={`Decrease value for ${label}`}
                aria-describedby={describedBy}
                disabled={disabled}
            >
                <span className="incrementer__toggle-icon" aria-hidden="true">
                    <IncrementerButton type="decrement" incrementerSize={size} disabled={minValueReached} />
                </span>
            </button>
            {size === "small" ? (
                <div className="incrementer__amount incrementer__amount-small">{value}</div>
            ) : (
                <Heading type="h2" className="incrementer__amount">
                    {value}
                </Heading>
            )}
            <button
                type="button"
                onClick={handleIncrement}
                className={incrementClasses}
                aria-label={`Increase value for ${label}`}
                aria-describedby={describedBy}
                disabled={disabled}
            >
                <span className="incrementer__toggle-icon" aria-hidden="true">
                    <IncrementerButton incrementerSize={size} disabled={maxValueReached} />
                </span>
            </button>
        </div>
    );
};
