import * as types from "../actions/types";

const initialState = {
    user: null,
    reservations: [],
    error: null,
    token: null,
    loginSuccessful: false,
    failedToCreateNewUser: null,
    bookingDetailedView: false,
    newUser: {
        name1: null,
        name2: null,
        addr1: null,
        addr3: null,
        addr4: null,
        addr5: null,
        phone3prefix: null,
        phone3: null,
        email: null,
        password: null,
        repeatpassword: null,
        year: null,
        month: null,
        day: null,
    },
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SIGN_OUT_USER:
            // Keep everything except the account's state to allow the customer to keep its cart if getting logged out automatically
            return initialState;

        case types.SET_ACCOUNT_RESERVATIONS:
            if (state.user) {
                return {
                    ...state,
                    reservations: action.reservations,
                    error: action.error,
                };
            }
            return state;

        case types.SET_ACCOUNT_RESERVATION: {
            let reservations = [];
            const stateReservations = state.reservations || [];

            if (stateReservations.length) {
                // Search for reservation
                const existingReservationIndex = stateReservations.findIndex(
                    reservation => reservation.headlid === action.product.reservation.headlid
                );

                if (existingReservationIndex >= 0) {
                    // Replace existing reservation
                    reservations = Object.assign([], stateReservations, {
                        [existingReservationIndex]: action.product,
                    });
                } else {
                    // Add reservation to list
                    reservations = stateReservations.slice().concat(Object.assign({}, action.product));
                }
            } else {
                // Add reservation to list
                reservations = stateReservations.slice().concat(Object.assign({}, action.product));
            }

            return {
                ...state,
                reservations: reservations,
                error: action.error,
            };
        }

        case types.FETCH_ACCOUNT_RESERVATIONS_FAILED:
            return {
                ...state,
                error: action.error,
            };
        case types.SET_CUSTOMER_TOKEN:
            return {
                ...state,
                token: action.token,
                error: action.error,
            };
        case types.AUTHENTICATE_CUSTOMER_FAILED:
            return {
                ...state,
                error: action.error,
            };

        case types.SET_AUTHENTICATED_USER_DATA: {
            return {
                ...state,
                user: action.user,
                error: action.error,
                loginSuccessful: action.loginSuccessful,
                failedToCreateNewUser: false,
            };
        }
        case types.FETCH_AUTHENTICATED_USER_DATA_FAILED:
            return {
                ...state,
                error: action.error,
                loginSuccessful: action.loginSuccessful,
            };

        case types.SET_CUSTOMER_AUTHENTICATION_STATUS:
            if (state.user) {
                return {
                    ...state,
                    error: action.error,
                    loginSuccessful: action.loginSuccessful,
                };
            }
            return state;

        case types.SET_UNREGISTERED_USER:
            return {
                ...state,
                newUser: {
                    ...state.newUser,
                    [action.key]: action.value,
                },
            };

        case types.FAILED_TO_CREATE_NEW_USER:
            return {
                ...state,
                failedToCreateNewUser: {
                    error: action.error,
                    data: action.data,
                },
            };

        case types.UPDATE_USER_DATA:
            return {
                ...state,
                user: {
                    ...state.user,
                    [action.key]: action.value,
                },
            };

        case types.PUBLISH_UPDATED_USER_DATA_FAILED: {
            return {
                ...state,
                error: action.error,
            };
        }

        case types.SUCCESSFULLY_UPDATED_USER_DATA: {
            return {
                ...state,
                successfullyUpdatedUserData: action.value,
            };
        }

        case types.SET_RETRIEVE_PASSWORD_SUCCESS: {
            return {
                ...state,
                retrievedPassword: action.payload,
            };
        }

        case types.SET_RETRIEVE_PASSWORD_FAILED: {
            return {
                ...state,
                retrievedPassword: false,
            };
        }
        case types.RESET_API_MESSAGES: {
            return {
                ...state,
                retrievedPassword: null,
                failedToCreateNewUser: null,
                error: null,
            };
        }

        case types.CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };

        case types.SET_ACCOUNT_GUESTS:
            return {
                ...state,
                guests: action.guests,
            };

        case types.FETCH_ACCOUNT_GUESTS_FAILED:
            return {
                ...state,
                error: action.error,
            };
        case types.SET_BOOKING_DETAILED_VIEW:
            return {
                ...state,
                bookingDetailedView: action.detailedView,
            };

        default:
            return {
                ...state,
            };
    }
};

export default reducer;
