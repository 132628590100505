import React from "react";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import Login from "../../components/Login/OldLogin";

/**
 * Login page
 *
 * Supports the "?path=<path>" parameter to redirect user to path after successful login
 */
const LoginPage = () => {
    const location = useLocation();
    const qs = queryString.parse(location.search);

    return (
        <div className="container">
            <Login loginPage={true} redirectPath={qs.path} />
        </div>
    );
};

export default LoginPage;
