import _sum from "lodash/sum";
import React, { useEffect, useState } from "react";
import { Alert as BootstrapAlert, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router";
import AccommodationResultList from "../../components/AccommodationResultList/OldAccommodationResultList";
import ChangeSearch from "../../components/ChangeSearch/ChangeSearch";
import ReservationTypesLoader from "../../components/ContentLoaderOld/ReservationTypesLoader";
import PromotionCodeInput from "../../components/PromotionCodeInputOld/PromotionCodeInput";
import ReservationTypeNoResults from "../../components/ReservationTypeNoResults";
import { Alert, Button, Filters, Icon } from "../../components/UI";
import MoreFilterButton from "../../components/UI/Filters/MoreFilterButton/MoreFilterButton";
import { productTypeNames } from "../../Constants";
import { filterGroupItems } from "../../hooks";
import useChangeFilter from "../../hooks/useChangeFilter";
import useChangeSearch from "../../hooks/useChangeSearch";
import { optionsSelector, textsSelector } from "../../Selectors";
import { clearFilters, setPromoCode } from "../../store/actions/filter";
import { resetLastSearchedTimestamps } from "../../store/actions/search";

const AccommodationResultPage = props => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const filters = useSelector(state => state.filter);
    const isMobile = useSelector(state => state.window.isMobile);
    const promotion = useSelector(state => state.clientData?.options?.module?.promotion);
    const lid = props.reservationType;
    const availableFilters = useSelector(state => state.availableFilters.default);
    const activeFilters = filters[lid];
    const isLoading = useSelector(state => state.axiosStatus.loading);
    const texts = useSelector(textsSelector);
    const reservationResult = useSelector(state => state.reservationResult[lid]);
    //const [promoCodeFromQuery, onSetPromoCodeInQuery] = useQueryString("promoCode");
    const [redirect, setRedirect] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [filterSearch, setFilterSearch] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    const [accommodationsCount, setAccommodationsCount] = useState(0);
    const newDesignMenu = useSelector(optionsSelector).layout?.new_design_menu;

    useChangeSearch({
        reservationResultSearch: reservationResult?.search,
        reservationTypeId: lid,
        skipGuestsOnSearchWithPrice: false,
        allowSearchWithoutPrice: true,
    });

    useChangeFilter(lid);

    useEffect(() => {
        if (location?.state?.redirect) {
            setRedirect(true);
        }

        // eslint-disable-next-line
    }, []);

    const getUniqueAccommodationsCount = items => {
        const count = new Set([...items.map(item => `${item.poollid}_${item.unitlid}`)]).size;

        return count || items.length;
    };

    const resetLastSearchedTimestampsAndReloadPage = () => {
        dispatch(resetLastSearchedTimestamps());
        window.location.reload();
    };

    useEffect(() => {
        const filterSearchNeedleMatcher = new RegExp(filterSearch, "i");

        const filterCriterias =
            filters.filterCriterias && lid in filters.filterCriterias ? filters.filterCriterias[lid] : {};

        const filteredData = Object.values(reservationResult?.groups || {})
            .map(group => {
                return filterGroupItems(group, activeFilters || {}, filterCriterias, availableFilters, false);
            })
            .map(group => {
                if (filterSearch === "") {
                    return group;
                }

                let filteredGroup = { ...group };
                filteredGroup.items = filteredGroup.items.filter(
                    item =>
                        item.desc1?.toString?.().search(filterSearchNeedleMatcher) !== -1 ||
                        item.title?.toString?.().search(filterSearchNeedleMatcher) !== -1 ||
                        item.unitdesc1?.toString?.().search(filterSearchNeedleMatcher) !== -1
                );

                return filteredGroup;
            })
            .filter(group => !!group.items.length);

        setAccommodationsCount(_sum(filteredData.map(group => getUniqueAccommodationsCount(group.items))));

        setFilteredData(filteredData);
    }, [reservationResult?.groups, filterSearch, activeFilters, availableFilters, filters.filterCriterias, lid]);

    const handleShowAllOffering = () => {
        setFilterSearch("");
        dispatch(setPromoCode(""));
        dispatch(clearFilters(lid));
        navigate(`/search/${lid}?checkPrice=0`);
    };

    function renderResults() {
        if (filteredData.length && !isLoading) {
            return (
                <>
                    <h3 className="page-header mt-5" aria-live="polite">
                        {reservationResult?.search?.checkPriceAndAvailability
                            ? `${accommodationsCount} ${texts?.accommodationplaces}`
                            : texts?.ouraccommodationareas}
                    </h3>
                    <AccommodationResultList
                        reservationType={props.reservationType}
                        reservationTypeResult={filteredData}
                        promoCode={reservationResult?.search?.promoCode}
                    />
                </>
            );
        } else if (
            // no hits
            (reservationResult?.hitsMatchingFilter === "0" || !filteredData.length) &&
            !isLoading
        ) {
            return <ReservationTypeNoResults resvtype={productTypeNames.ACCOMMODATION} />;
        } else if (reservationResult?.error && !isLoading) {
            return (
                <BootstrapAlert variant="light" className="letting-view__alert--fetch-error mt-3">
                    <div className="letting-view__alert-icon">
                        <Icon name="FaExclamationTriangle" size={40} color="#e04f5f" />
                    </div>
                    <div className="letting-view__alert-text">
                        <h3 className="h6">{texts?.noresultnetworkheader}</h3>
                        <p>{texts?.noresultnetworkbody}</p>
                        <Button
                            onClick={resetLastSearchedTimestampsAndReloadPage}
                            className="button--text-only letting-view__alert-button"
                        >
                            <Icon name="FaUndoAlt" size={18} color="#333333" />
                            <span className="sr-only">
                                {texts?.noresultgetdata} {props.header}
                            </span>
                        </Button>
                    </div>
                </BootstrapAlert>
            );
        } else {
            // showing shadow loaders
            return (
                <div className="row mt-5">
                    {[1, 2, 3].map((item, index) => {
                        return (
                            <div className="col-sm-12 col-md-6 col-lg-4" key={"loader" + index}>
                                <ReservationTypesLoader />
                            </div>
                        );
                    })}
                </div>
            );
        }
    }

    return (
        <Container>
            <div className="row">
                <div className="col">
                    <header style={{ marginBottom: "2rem" }}>
                        {texts?.accommodationinformation && texts?.accommodationinformation.trim() !== "" && (
                            <BootstrapAlert variant={"light"} className="activities-view__alert--info">
                                <div className="results-view__resvtype-message">
                                    <div className="activities-view__alert-icon">
                                        <Icon name="FaRegDotCircle" size={16} color="#f1c40f" />
                                    </div>
                                    <div className="activities-view__alert-text">
                                        <p>{texts?.accommodationinformation}</p>
                                    </div>
                                </div>
                            </BootstrapAlert>
                        )}
                    </header>

                    <Container>
                        {redirect && (
                            <Row className="mb-3">
                                <Alert type="warning" dismissable>
                                    <p>{texts?.nohitsredirectmessage || texts?.nohitsredirectedaccomodationmessage}</p>
                                </Alert>
                            </Row>
                        )}
                    </Container>

                    {!newDesignMenu &&
                        (!isMobile ? (
                            <>
                                <div
                                    style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <div className="me-2" style={{ flexShrink: 0 }}>
                                        <ChangeSearch
                                            texts={texts}
                                            showRange
                                            isMobile={false}
                                            reservationResultSearch={reservationResult?.search}
                                            reservationTypeid={lid}
                                        />
                                    </div>

                                    {filters?.filterCriterias?.[lid] && (
                                        <div className="my-2" style={{ flexShrink: 0 }}>
                                            <MoreFilterButton
                                                text={showFilter ? `${texts?.filterclose}` : `${texts?.filtermore}`}
                                                onClick={() => setShowFilter(!showFilter)}
                                            />
                                        </div>
                                    )}
                                    {reservationResult?.search?.checkPriceAndAvailability && (
                                        <a
                                            onClick={handleShowAllOffering}
                                            className="link ms-2"
                                            style={{ flexShrink: 0 }}
                                        >
                                            {texts["search.show_all_offering"]}
                                        </a>
                                    )}
                                </div>

                                <Filters
                                    reservationType={lid}
                                    showFilter={showFilter}
                                    showFilterSearch={true}
                                    filterSearch={filterSearch}
                                    onFilterSearchChange={setFilterSearch}
                                />

                                {promotion && reservationResult?.search?.checkPriceAndAvailability && (
                                    <PromotionCodeInput
                                        promoCode={filters.promoCode}
                                        texts={texts}
                                        /*onSave={code => {
                                        onSetPromoCodeInQuery(code);
                                        dispatch(resetPromoCode());
                                    }}
                                    onRemove={() => {
                                        onSetPromoCodeInQuery("");
                                        dispatch(resetPromoCode());
                                    }}*/
                                    />
                                )}
                            </>
                        ) : (
                            <>
                                {reservationResult?.search?.checkPriceAndAvailability && (
                                    <a onClick={handleShowAllOffering} className="link">
                                        {texts["search.show_all_offering"]}
                                    </a>
                                )}

                                <ChangeSearch
                                    texts={texts}
                                    showRange
                                    isMobile={false}
                                    reservationResultSearch={reservationResult?.search}
                                    reservationTypeid={lid}
                                />

                                {promotion && reservationResult?.search?.checkPriceAndAvailability && (
                                    <>
                                        <PromotionCodeInput promoCode={filters.promoCode} texts={texts} />
                                    </>
                                )}

                                <div style={{ height: "0.5rem" }}></div>

                                {filters?.filterCriterias?.[lid] && (
                                    <>
                                        <MoreFilterButton
                                            text={showFilter ? `${texts?.filterclose}` : `${texts?.filtermore}`}
                                            onClick={() => setShowFilter(!showFilter)}
                                        />
                                        <div style={{ height: "0.5rem" }}></div>
                                    </>
                                )}

                                <Filters
                                    reservationType={lid}
                                    showFilter={showFilter}
                                    showFilterSearch={true}
                                    filterSearch={filterSearch}
                                    onFilterSearchChange={setFilterSearch}
                                />
                            </>
                        ))}

                    {/* Products */}

                    {renderResults()}
                </div>
            </div>
        </Container>
    );
};

export default AccommodationResultPage;
