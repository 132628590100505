import React, { useEffect, useState } from "react";
import useTranslate from "../../../hooks/useTranslate";
import { Input } from "@r360/library";
import { TAge } from "../../MenuSearch/MenuSearch";
import Incrementer from "../../UI/Incrementer";
import "./Guest.scss";
import { formatPrice } from "../../../Helper";

type TGuest = {
    guestCount: number;
    data: TAge;
    onChange: (value: number, data: TAge) => void;
    requiredAges?: { [key: string]: (number | string)[] };
    setRequiredAges: React.Dispatch<
        React.SetStateAction<{
            [key: string]: (number | string)[];
        }>
    >;
    price?: number;
    currency?: string;
    max?: number;
};

const unsetAgeText = "undefined";

export const Guest: React.FC<TGuest> = ({
    guestCount,
    price,
    data,
    onChange,
    requiredAges,
    setRequiredAges,
    currency,
    max = 99,
}) => {
    const t = useTranslate();
    const title = t(data.title);
    const description = t(data.description);
    const requiresAge = data.requiresAge;
    const groupAges = requiredAges?.[data.key];

    useEffect(() => {
        if (data.requiresAge) {
            // Remove the last age (or ages) if count is decremented, or if incremented, add a new age as undefined
            if (groupAges && guestCount < groupAges?.length) {
                setRequiredAges(prevState => {
                    prevState[data.key].splice(-(groupAges.length - guestCount));
                    return { ...prevState };
                });
            } else if (!groupAges && guestCount) {
                setRequiredAges(prevState => ({
                    ...prevState,
                    [data.key]: prevState[data.key] ? [...prevState[data.key], unsetAgeText] : [unsetAgeText],
                }));
            } else if (groupAges && guestCount > groupAges?.length) {
                setRequiredAges(prevState => ({
                    ...prevState,
                    [data.key]: prevState[data.key] ? [...prevState[data.key], unsetAgeText] : [unsetAgeText],
                }));
            }
        }
    }, [data.requiresAge, data.key, guestCount, groupAges, setRequiredAges]);

    const handleIncrementerChange = ({ value }: { value: number }) => {
        onChange?.(value, data);
    };

    const handleAgeChange = (value: string, i: number) => {
        setRequiredAges(prevState => {
            prevState[data.key]?.splice(i, 1, !value ? unsetAgeText : value);
            return { ...prevState };
        });
    };

    return (
        <>
            <div className="guest">
                <div className="guest-text">
                    <div className="guest-text__title" id={`guest-stepper-title-label-${title}`}>
                        {title}
                    </div>
                    {description && (
                        <div className="guest-text__description" id={`guest-stepper-description-label-${title}`}>
                            {description}
                        </div>
                    )}
                    {typeof price !== "undefined" && price >= 0 && (
                        <div className="guest-text__description" id={`guest-stepper-description-label-${price}`}>
                            {formatPrice(price, currency)}
                        </div>
                    )}
                </div>
                <Incrementer
                    name={title}
                    label={title}
                    value={guestCount}
                    minValue={data.minCount}
                    maxValue={max}
                    onChange={handleIncrementerChange}
                />
            </div>
            {requiresAge && guestCount > 0 && (
                <div className="guest__age-inputs">
                    <p className="guest__age-inputs-text">{`${t("book.search.guest_picker.set_required_ages")} (${
                        data.min
                    } - ${data.max})`}</p>
                    {[...Array(guestCount)].map((_e, i) => {
                        return (
                            <GuestAgeInput
                                key={i}
                                i={i}
                                age={groupAges?.[i] || ""}
                                min={data.min}
                                max={data.max}
                                onAgeChange={handleAgeChange}
                            />
                        );
                    })}
                </div>
            )}
        </>
    );
};

type TGuestAgeInput = {
    i: number;
    age: number | string;
    min: number;
    max: number;
    onAgeChange: (value: string, i: number) => void;
};

const GuestAgeInput = ({ i, age, min, max, onAgeChange }: TGuestAgeInput) => {
    const [interacted, setInteracted] = useState(false);
    const [valid, setValid] = useState<boolean | undefined>(undefined);
    const t = useTranslate();

    useEffect(() => {
        checkValidAge();
    }, [age]);

    const checkValidAge = () => {
        const currentValue = parseInt(age.toString());
        if (currentValue <= max && currentValue >= min) {
            setValid(true);
        } else if (interacted) {
            setValid(false);
            onAgeChange(unsetAgeText, i);
        }
    };

    return (
        <Input
            type="number"
            key={i}
            placeholder={`${t("book.general.child")} ${i + 1}`}
            defaultValue={age}
            min={min}
            max={max}
            onChange={e => {
                !interacted && setInteracted(true);
                onAgeChange(e.target.value, i);
            }}
            isValid={valid}
        />
    );
};

export default Guest;
