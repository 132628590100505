import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { FocusedInputShape } from "react-dates";
import { default as DatePickerComponent } from "../../Library/components/DatePicker";
import useAppSelector from "../../../hooks/useAppSelector";
import { productTypeNames } from "../../../Constants";
import useTranslate from "../../../hooks/useTranslate";
import useMemoizeArg from "../../../hooks/useMemoizeArg";

enum DatePickerSize {
    "small",
    "medium",
}

type TDatePicker = {
    size?: keyof typeof DatePickerSize | null;
    placeholder?: string;
    ariaLabel?: string;
    startDate?: moment.Moment | null;
    endDate?: moment.Moment | null;
    date?: moment.Moment | null;
    resvTypeLid: string;
    resvTypeType: string | undefined;
    singleDate?: boolean;
    changedDate?: (startDate: string, endDate: string) => void;
    focusChanged?: () => void;
    noBorder?: boolean;
    availablePeriods?: { [key: string]: TAvailablePeriod[] | undefined };
    blockedPeriods?: { [key: string]: TBlockedPeriod[] | undefined };
    keepOpenOnDateSelect?: boolean;
    startDateAriaLabel?: string;
    endDateAriaLabel?: string;
    numberOfMonths?: number;
    showRange?: boolean;
    showController?: boolean;
    onCloseCallback?: () => void;
    startDatePlaceholderText?: string;
    endDatePlaceholderText?: string;
    alwaysShow?: boolean;
    maxRangeLength?: number;
};

type TAvailableEndDatesToStartDate = { [key: string]: string[] };
type TBlockedPeriodsDays = { [key: string]: boolean };

type TDatePickerState = {
    show: boolean;
    focusedInput: FocusedInputShape | null;
    startDate: moment.Moment | null;
    endDate: moment.Moment | null;
    focused: boolean;
    bookableStartDays: string[];
    availableEndDatesToStartDate: TAvailableEndDatesToStartDate;
    initialStartdate: moment.Moment | null;
    blockedPeriodsDays: TBlockedPeriodsDays;
};

export type TAvailablePeriod = {
    startdate: string;
    enddate: string;
    rules: number[][];
    description?: string;
    periodlid?: number;
    poollid?: number;
    unitlid?: number;
};

export type TBlockedPeriod = {
    fromdate: string;
    todate: string;
    description?: string;
    rule: number;
    channel: string;
    auto: boolean;
};

export const DatePicker: React.FC<TDatePicker> = ({
    placeholder,
    ariaLabel,
    startDate = null,
    endDate = null,
    resvTypeLid,
    resvTypeType,
    availablePeriods,
    singleDate,
    changedDate,
    blockedPeriods,
    startDateAriaLabel,
    endDateAriaLabel,
    numberOfMonths,
    showRange,
    onCloseCallback,
    keepOpenOnDateSelect = true,
    startDatePlaceholderText,
    endDatePlaceholderText,
    maxRangeLength,
}: TDatePicker) => {
    const calendarData = useAppSelector(state => state.clientData.calendarData);
    const isMobile = useAppSelector(state => state.window.isMobile);
    const t = useTranslate();
    const [state, setState] = useState<TDatePickerState>({
        show: false,
        focusedInput: null,
        startDate: null,
        endDate: null,
        focused: false,
        bookableStartDays: [],
        availableEndDatesToStartDate: {},
        initialStartdate: null,
        blockedPeriodsDays: {},
    });
    useEffect(() => {
        if (calendarData?.calendar[resvTypeLid]?.startdate) {
            const initialStartdate = moment(calendarData.calendar[resvTypeLid].startdate);
            setState(state => ({
                ...state,
                initialStartdate,
            }));
        }
    }, [resvTypeLid]);

    useEffect(() => {
        setBlockedPeriodsDays();
    }, [useMemoizeArg(blockedPeriods)]);

    useEffect(() => {
        matchBookableDays();
    }, [useMemoizeArg(availablePeriods), useMemoizeArg(state.blockedPeriodsDays), resvTypeLid]);

    useEffect(() => {
        setState(state => ({
            ...state,
            startDate,
        }));
    }, [startDate, resvTypeLid]);

    useEffect(() => {
        setState(state => ({
            ...state,
            endDate,
        }));
    }, [endDate, resvTypeLid]);

    // useEffect(() => {
    //     setState(state => ({
    //         ...state,
    //         date,
    //     }));
    // }, [date, resvTypeLid]);

    // useEffect(() => {
    //     setState(state => ({
    //         ...state,
    //         date,
    //     }));
    // }, [resvTypeLid]);

    useEffect(() => {
        matchSelectedDay();
    }, [state.bookableStartDays]);

    const clearDates = () => {
        setState(state => ({
            ...state,
            startDate: null,
            endDate: null,
            focusedInput: "startDate",
        }));
    };

    const fastDateIsSameOrBetween = (checkDate: string, startDate: string, endDate: string) => {
        const dateTime = new Date(checkDate).getTime();
        const startDateTime = new Date(startDate).getTime();
        const endDateTime = new Date(endDate).getTime();
        const isSameOrBetween = dateTime >= startDateTime && dateTime <= endDateTime;

        return isSameOrBetween;
    };

    const checkIfAnyDayInRangeIsInBlockedPeriods = (rangeStart: moment.Moment, rangeEnd: moment.Moment) => {
        const now = rangeStart.clone();
        let anyDayInRangeIsInBlockedPeriods = false;

        // Early return if no blocked periods.
        if (Object.keys(state.blockedPeriodsDays).length === 0) {
            return false;
        }

        while (now.isSameOrBefore(rangeEnd)) {
            if (state.blockedPeriodsDays[now.format("YYYY-MM-DD")]) {
                anyDayInRangeIsInBlockedPeriods = true;
                break;
            }

            now.add(1, "days");
        }

        return anyDayInRangeIsInBlockedPeriods;
    };
    const isDayBlocked = (day: moment.Moment) => {
        const available_periods = availablePeriods || calendarData?.available_periods;

        // Check if current day is before the start date of the calendar.
        if (calendarData?.calendar[resvTypeLid]?.startdate) {
            const startDate = moment(calendarData?.calendar[resvTypeLid]?.startdate);
            const isBefore = day.diff(startDate) < 0;

            if (isBefore) {
                return true;
            }
        }

        // If the day exists in the days extracted from blocked periods, block the day.
        if (state.blockedPeriodsDays[day.format("YYYY-MM-DD")]) {
            return true;
        }

        // Check if no available periods exists for the reservation type.
        // If so, limit by the startdate and enddate of the calendar.
        if (resvTypeLid && !available_periods?.[resvTypeLid]) {
            const startDate = moment(calendarData?.calendar[resvTypeLid]?.startdate);
            const endDate = moment(calendarData?.calendar[resvTypeLid].enddate);
            const isSameOrBetween = day.isBetween(startDate, endDate, "day", "[]");
            return !isSameOrBetween;
        }

        // Check if the current day is one of the start dates and that it has at least one matching end date.
        if (state.availableEndDatesToStartDate[day.format("YYYY-MM-DD")]?.length >= 1) {
            // The current day is a valid start date.

            // If no dates has been selected, the day is not blocked.
            if (!state?.startDate && !state.endDate) {
                return false;
            }

            // If both start and end dates has been selected, the day is not blocked.
            if (state?.startDate && state.endDate) {
                return false;
            }
        }

        // Check if difference between startDate and day is greater than maxRangeLength.
        // If so, block the day.
        if (state?.startDate && maxRangeLength !== undefined && state.startDate.isSameOrBefore(day)) {
            const rangeLength = day.diff(state.startDate, "days");
            if (rangeLength > maxRangeLength) {
                return true;
            }
        }

        // Checks what days should be blocked when only start date is selected.
        // Only valid departure dates will be visible during selection.
        if (
            state?.startDate &&
            !state.endDate &&
            state.availableEndDatesToStartDate[state?.startDate.format("YYYY-MM-DD")]?.includes(
                day.format("YYYY-MM-DD")
            )
        ) {
            return false;
        }

        //
        // -- Date is blocked if it arrives here (good place for debugging)
        //
        return true;
    };

    const onDatesChange = (startDate: moment.Moment | null, endDate: moment.Moment | null) => {
        if (state.startDate && !state.startDate.isSame(startDate)) {
            endDate = null;
        }
        setState(state => ({ ...state, focusedInput: "startDate" }));

        setState(state => {
            // Always set focus on startDate for controller if 'singleDate' property is supplied...
            if (singleDate && startDate) {
                return {
                    ...state,
                    focusedInput: "startDate",
                };
                // ... or unfocus input fields if on mobile device, else set focus as usually if not.
            } else {
                return {
                    ...state,
                    focusedInput: startDate && endDate ? (isMobile ? null : "startDate") : "endDate",
                };
            }
        });

        setState(state => ({ ...state, startDate: startDate, endDate: endDate }));

        changedDate?.(startDate ? startDate.format("YYYY-MM-DD") : "", endDate ? endDate.format("YYYY-MM-DD") : "");
    };

    const matchingPeriods = (date: string, availablePeriods: TAvailablePeriod[]) => {
        if (availablePeriods) {
            const periods = availablePeriods.filter(availableDate =>
                fastDateIsSameOrBetween(date, availableDate.startdate, availableDate.enddate)
            );
            return periods;
        }

        return [];
    };

    const matchSelectedDay = () => {
        const uniqueAvailablePeriodsForReservationType = getUniqueAvailablePeriodsForReservationType(resvTypeLid);
        const availableEndDatesToStartDate: TAvailableEndDatesToStartDate = {};

        (state.bookableStartDays || []).forEach(element => {
            const day = moment(element, "YYYY-MM-DD");
            const dayDate = day.format("YYYY-MM-DD");

            const periods = matchingPeriods(dayDate, uniqueAvailablePeriodsForReservationType);

            periods.forEach(period => {
                const periodEndDay = moment(period.enddate);

                period.rules.forEach(([startday, length]) => {
                    if (length !== -1 && (startday === day.isoWeekday() || startday === -1)) {
                        const depDay = moment(day.format("YYYY-MM-DD")).add(length, "day");
                        const depDayDate = depDay.format("YYYY-MM-DD");

                        // Check that the arrival date is before the departure date.
                        // Check if the departure date is before or same as the period todate.
                        // Check if any day between arrival and departure date is blocked.
                        if (
                            day.isBefore(depDay) &&
                            depDay.isSameOrBefore(periodEndDay) &&
                            !checkIfAnyDayInRangeIsInBlockedPeriods(day, depDay)
                        ) {
                            if (!availableEndDatesToStartDate[dayDate]) {
                                availableEndDatesToStartDate[dayDate] = [];
                            }
                            availableEndDatesToStartDate[dayDate].push(depDayDate);
                        }
                    } else if (length === -1) {
                        //console.log("Matching period", dayDate, period);
                        for (let index = 1; index < 50; index++) {
                            const depDay = moment(day.format("YYYY-MM-DD")).add(index, "day");
                            const depDayDate = depDay.format("YYYY-MM-DD");

                            // Check if the departure date is before or same as the period todate.
                            // Check if any day between arrival and departure date is blocked.
                            if (
                                depDay.isSameOrBefore(periodEndDay) &&
                                !checkIfAnyDayInRangeIsInBlockedPeriods(day, depDay)
                            ) {
                                if (!availableEndDatesToStartDate[dayDate]) {
                                    availableEndDatesToStartDate[dayDate] = [];
                                }

                                availableEndDatesToStartDate[dayDate].push(depDayDate);
                            } else {
                                // No need to check more dates if day is blocked or if the period todate has been passed.
                                break;
                            }
                        }
                    }
                });
            });
        });

        setState(state => ({ ...state, show: true, availableEndDatesToStartDate }));
    };

    const matchBookableDays = () => {
        // Find out the last available date used for generating the arrays of bookable below
        const lastAvailableDate = calendarData
            ? calendarData.calendar[-1].enddate // use last available date for all reservation types (combined)
            : moment().add(3, "year").format("YYYY-MM-DD"); // default to +3 years if no calendar data exists

        let start = moment().toDate();
        const target = moment(lastAvailableDate).toDate();

        // These arrays contain all valid and bookable days that are shown as selectable in the calendar
        const bookableStartDays: string[] = [];

        const uniqueAvailablePeriodsForReservationType = getUniqueAvailablePeriodsForReservationType(resvTypeLid);

        while (target > start) {
            const day = moment(start);
            const dayDate = day.format("YYYY-MM-DD");

            if (!state.blockedPeriodsDays[dayDate]) {
                const periods = matchingPeriods(dayDate, uniqueAvailablePeriodsForReservationType);
                let dayMatchesPeriods = false;

                periods.forEach(period => {
                    period.rules.forEach(([startday]) => {
                        if (day.isoWeekday() === startday || startday === -1) {
                            dayMatchesPeriods = true;
                            return;
                        }
                    });
                });

                if (dayMatchesPeriods) {
                    bookableStartDays.push(dayDate);
                }
            }

            const date = new Date(start.valueOf());
            date.setDate(date.getDate() + 1);
            start = date;
        }

        setState(state => ({ ...state, bookableStartDays }));
    };

    const setBlockedPeriodsDays = () => {
        const tempBlockedPeriods: TBlockedPeriod[] =
            blockedPeriods?.[resvTypeLid] || calendarData?.blocked_periods[resvTypeLid] || [];

        const blockedPeriodsDays: TBlockedPeriodsDays = {};

        tempBlockedPeriods
            // Skip auto blocked periods for now
            .filter(period => !period.auto)
            .forEach(period => {
                const now = moment(period.fromdate);
                const toDate = moment(period.todate);

                while (now.isSameOrBefore(toDate)) {
                    const nowDate = now.format("YYYY-MM-DD");
                    blockedPeriodsDays[nowDate] = true;
                    now.add(1, "days");
                }
            });

        setState(state => ({ ...state, blockedPeriodsDays }));
    };

    // Combine available periods that have identical start/end date and rules.
    const getUniqueAvailablePeriodsForReservationType = (resvTypeLid: string): TAvailablePeriod[] => {
        const tempAvailablePeriods: TAvailablePeriod[] =
            (availablePeriods || calendarData?.available_periods)?.[resvTypeLid] || [];

        const uniqueAvailablePeriods: { [key: string]: TAvailablePeriod } = {};

        tempAvailablePeriods.forEach(period => {
            const key = `${period.startdate}_${period.enddate}_${JSON.stringify(period.rules)}`;

            if (!uniqueAvailablePeriods[key]) {
                uniqueAvailablePeriods[key] = {
                    startdate: period.startdate,
                    enddate: period.enddate,
                    rules: period.rules,
                };
            }
        });

        return Object.values(uniqueAvailablePeriods);
    };

    const onSingleDateChange = (date: moment.Moment | null) => {
        setState(state => ({ ...state, startDate: date }));
        changedDate?.(date ? date.format("YYYY-MM-DD") : "", "");
    };

    const startDateTitle = () => {
        switch (resvTypeType) {
            case productTypeNames.ACCOMMODATION: {
                return t("book.accommodation.calendararrivaldate");
            }
            case productTypeNames.SKIPASS: {
                return t("book.skipass.calendararrivaldate");
            }
            case productTypeNames.ACTIVITY: {
                return t("book.activity.calendararrivaldate");
            }
            case productTypeNames.LETTING: {
                return t("book.letting.calendararrivaldate");
            }
            case productTypeNames.RENTAL: {
                return t("book.rental.calendararrivaldate");
            }
            case productTypeNames.PACKAGE: {
                return t("book.package.calendararrivaldate");
            }
            default: {
                return "Från datum";
            }
        }
    };
    const endDateTitle = () => {
        switch (resvTypeType) {
            case productTypeNames.ACCOMMODATION: {
                return t("book.accommodation.calendardepartdate");
            }
            case productTypeNames.SKIPASS: {
                return t("book.skipass.calendardepartdate");
            }
            case productTypeNames.ACTIVITY: {
                return t("book.activity.calendardepartdate");
            }
            case productTypeNames.LETTING: {
                return t("book.letting.calendardepartdate");
            }
            case productTypeNames.RENTAL: {
                return t("book.rental.calendardepartdate");
            }
            case productTypeNames.PACKAGE: {
                return t("book.package.calendardepartdate");
            }
            default: {
                return "Till datum";
            }
        }
    };

    // Overrides all default phrases translation
    const defaultPhrasesOverrides = () => {
        const chooseAvailableStartDate = ({ date }: { date: string }) =>
            `Välj ${date} som ditt incheckningsdatum. Det är tillgängligt.`;
        const chooseAvailableEndDate = ({ date }: { date: string }) =>
            `Välj ${date} som ditt utcheckningsdatum. Det är tillgängligt.`;
        const chooseAvailableDate = ({ date }: { date: string }) => date;
        const dateIsUnavailable = ({ date }: { date: string }) => `Ej tillgänglig. ${date}`;
        const dateIsSelected = ({ date }: { date: string }) => `Vald. ${date}`;
        const dateIsSelectedAsStartDate = ({ date }: { date: string }) => `Valt startdatum. ${date}`;
        const dateIsSelectedAsEndDate = ({ date }: { date: string }) => `Valt slutdatum. ${date}`;
        const DateRangePickerOverride = {
            calendarLabel: t("calendar.calendarlabel"),
            roleDescription: t("calendar.roledescription"),
            closeDatePicker: t("calendar.closedatepicker"),
            focusStartDate: t("calendar.focusstartdate"),
            clearDate: t("calendar.cleardate"),
            clearDates: t("calendar.cleardates"),
            jumpToPrevMonth: t("calendar.jumptoprevmonth"),
            jumpToNextMonth: t("calendar.jumptonextmonth"),
            keyboardShortcuts: t("calendar.keyboardshortcuts"),
            showKeyboardShortcutsPanel: t("calendar.showkeyboardshortcutspanel"),
            hideKeyboardShortcutsPanel: t("calendar.hidekeyboardshortcutspanel"),
            openThisPanel: t("calendar.openthispanel"),
            enterKey: t("calendar.enterKey"),
            leftArrowRightArrow: t("calendar.leftarrowrightarrow"),
            upArrowDownArrow: t("calendar.uparrowdownarrow"),
            pageUpPageDown: t("calendar.pageuppagedown"),
            homeEnd: t("calendar.homeend"),
            escape: t("calendar.escape"),
            questionMark: t("calendar.questionmark"),
            selectFocusedDate: t("calendar.selectfocuseddate"),
            moveFocusByOneDay: t("calendar.movefocusbyoneday"),
            moveFocusByOneWeek: t("calendar.movefocusbyoneweek"),
            moveFocusByOneMonth: t("calendar.movefocusbyonemonth"),
            moveFocustoStartAndEndOfWeek: t("calendar.movefocustostartandendofweek"),
            returnFocusToInput: t("calendar.returnfocustoinput"),
            keyboardForwardNavigationInstructions: t("calendar.keyboardforwardnavigationinstructions"),
            keyboardBackwardNavigationInstructions: t("calendar.keyboardbackwardnavigationinstructions"),
            chooseAvailableStartDate: chooseAvailableStartDate,
            chooseAvailableEndDate: chooseAvailableEndDate,
            chooseAvailableDate: chooseAvailableDate,
            dateIsUnavailable: dateIsUnavailable,
            dateIsSelected: dateIsSelected,
            dateIsSelectedAsStartDate: dateIsSelectedAsStartDate,
            dateIsSelectedAsEndDate: dateIsSelectedAsEndDate,
        };
        return DateRangePickerOverride;
    };

    return (
        <DatePickerComponent
            showRange={showRange}
            onCloseCallback={onCloseCallback}
            phrases={defaultPhrasesOverrides()}
            onSingleDateChange={onSingleDateChange}
            onDoubleDatesChange={onDatesChange}
            isDayBlocked={isDayBlocked}
            onClearDates={clearDates}
            initialStartdate={state.initialStartdate}
            startDate={state.startDate}
            endDate={state.endDate}
            placeholder={placeholder || startDatePlaceholderText}
            ariaLabel={ariaLabel || t("calendarselectdate")}
            startDatePlaceholderText={startDatePlaceholderText}
            startDateAriaLabel={startDateAriaLabel || t("calendardateofcheckin")}
            endDatePlaceholderText={endDatePlaceholderText}
            endDateAriaLabel={endDateAriaLabel || t("calendardateofcheckout")}
            focusedInput={state.focusedInput}
            focused={state.focused}
            onSingleFocusChange={({ focused }) => setState(state => ({ ...state, focused }))}
            onDoubleFocusChange={focusedInput => {
                setState(state => ({ ...state, focusedInput }));
            }}
            numberOfMonths={isMobile ? 1 : numberOfMonths || 2}
            keepOpenOnDateSelect={keepOpenOnDateSelect}
            startDateTitle={startDateTitle()}
            endDateTitle={endDateTitle()}
        />
    );
};
