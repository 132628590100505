import React, { useState } from "react";

import Heart from "../Heart/Heart";
import placeholderGray from "../../../assets/placeholderGray.png";
import "./CardImage.scss";

// Card image component
const CardImage = props => {
    const { liked, likeText, image, placeholder, height, showLike = false, className } = props;
    const [showImage, setShowImage] = useState(false);
    const classes = className ? `card-image ${className}` : "card-image";

    // Get heart component state.
    const didClickHeart = () => {
        //TODO: Add this var to Redux
    };

    // On image load
    const onImageLoad = () => {
        if (image) {
            setShowImage(true);
        }
    };

    return (
        <>
            {showLike && (
                <div className="card-image__heart">
                    <Heart liked={liked} likeText={likeText} clicked={didClickHeart} />
                </div>
            )}

            <div className={classes} style={{ height: height }}>
                {/* Use an image tag to trigger the onload event and use a div to scale image to cover area better */}
                <img src={image} className="d-none" onLoad={onImageLoad} alt="" />
                <div
                    className={`card-image__image${!showImage ? " card-image__image--hidden" : ""}`}
                    style={{
                        position: "absolute",
                        margin: 0,
                        background: `url(${image}) center / cover no-repeat`,
                        display: "block",
                        width: "100%",
                        height: "100%",
                    }}
                ></div>

                <img
                    src={placeholder || placeholderGray}
                    alt=""
                    style={{ height: height }}
                    className={`card-image__placeholder${showImage && image ? " card-image__placeholder--hidden" : ""}`}
                    title="Placeholder"
                />
            </div>
        </>
    );
};

export default CardImage;
