import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Letting as LettingImage } from "../../../assets/reservationTypes";
import { formatPrice, getAgeCategories } from "../../../Helper";
import {
    addProductWithCount,
    removeProduct,
    setLatestChangedProductType,
    updateProductWithCount,
} from "../../../store/actions/checkoutCart";
import ProductView from "../../ProductView/ProductView";
import { Button, Incrementer, Modal, ResponsiveImage, Tag } from "../../UI";
import "./LettingItem.scss";
import { textsSelector } from "../../../Selectors";

// Letting item component
const LettingItem = props => {
    const {
        title,
        thumbnail,
        price,
        available,
        index,
        poollid,
        arrdate,
        category,
        occations,
        days,
        type,
        minage,
        maxage,
    } = props.item;
    const [showModal, setShowModal] = useState(false);
    const [count, setCount] = useState(0);
    const dispatch = useDispatch();
    const currency = useSelector(state => state.clientData?.options?.general?.currency);
    const texts = useSelector(textsSelector);
    const productInCart = props.cartProducts?.find(product => {
        const productId =
            product.poollid +
            product.arrdate +
            product.price +
            (product.category || btoa(JSON.stringify(product.occations)));
        const propsId = poollid + arrdate + price + (category || btoa(JSON.stringify(occations)));
        return productId === propsId;
    });

    // Set count value if product is present in cart
    useEffect(() => {
        setCount(productInCart?.count);
    }, [productInCart]);

    // Expand accordion in OrderOverview
    const expandOrderOverviewAccordion = () => {
        dispatch(setLatestChangedProductType(parseInt(type)));
    };

    // Handle click on more info button
    const handleClick = () => {
        setShowModal(!showModal);
    };

    // Increment count
    const incrementCount = () => {
        setCount(count + 1);
        dispatch(addProductWithCount(props.item));
        expandOrderOverviewAccordion();
    };

    // Set item count
    const setItemCount = response => {
        if (productInCart) {
            if (response.value === 0) {
                dispatch(removeProduct(productInCart));
            } else {
                dispatch(updateProductWithCount(productInCart, response.value));
            }
        }
        setCount(response.value);
        expandOrderOverviewAccordion();
    };

    // Get age category tags
    const getAgeCategoryTags = () => {
        if (minage && maxage && props.generalAges) {
            const categories = getAgeCategories(minage, maxage, props.generalAges[parseInt(type)]);

            return categories.map((item, index) => {
                return (
                    <Tag key={index} type="small" className="letting-item__period-tag" index={index}>
                        {texts[item.title]}
                    </Tag>
                );
            });
        }
    };

    // Render activity input
    const LettingInput = () => {
        if (count > 0) {
            return (
                <div className="letting-item__incrementer">
                    <Incrementer label={title} value={count} maxValue={available} onChange={setItemCount} size={8} />
                </div>
            );
        }
        return <Button text={texts?.generaladd} onClick={incrementCount} className="letting-item__booking-button" />;
    };

    return (
        <div className="letting-item__wrapper col-sm-12 col-md-6 col-lg-4">
            <div className="letting-item">
                <div className="letting-item__image-container">
                    <ResponsiveImage
                        height={204}
                        src={thumbnail?.url}
                        placeholder={thumbnail?.url ? undefined : LettingImage}
                        className="letting-item__image"
                    />
                    {props.promoCode && (
                        <div className="letting-item__promo-tag-container">
                            <Tag type="small" className="letting-item__promo-tag">
                                {texts?.promocodeincluded}
                            </Tag>
                        </div>
                    )}
                </div>
                <div className="letting-item__body">
                    <div className="tag__container">
                        {parseInt(days) > 0 && (
                            <Tag type="small" className="letting-item__period-tag">
                                {days} {days > 1 ? `${texts?.generaldays}` : `${texts?.generalday}`}
                            </Tag>
                        )}
                        {parseInt(days) === 0 && (
                            <Tag type="small" className="letting-item__period-tag">
                                {texts?.seasonpass}
                            </Tag>
                        )}
                        {getAgeCategoryTags()}
                    </div>
                    <h2 className="h5 letting-item__header">{title}</h2>
                    <div className="letting-item__modal-button-container">
                        <Button
                            id={`letting-more-information-${index}`}
                            text={texts?.moreinformation}
                            className="letting-item__modal-button"
                            onClick={handleClick}
                            hasPopup={true}
                            expanded={showModal}
                            disabled={props.item?.weblong ? false : true}
                        />
                        <LettingInput />
                    </div>
                    <div className="letting-item__price">{formatPrice(price, currency)}</div>
                </div>
            </div>
            <Modal
                show={showModal}
                setShow={setShowModal}
                labelledBy={`letting-more-information-${props.index}`}
                className="letting-item__modal"
                id={`letting-more-information-modal-${props.index}`}
            >
                <ProductView
                    weblong={props.item?.weblong ? props.item?.weblong : "-"}
                    title={title}
                    image={thumbnail?.url || LettingImage}
                    texts={texts}
                />
            </Modal>
        </div>
    );
};

export default LettingItem;
