import React from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { isCancelled } from "../../BusinessUtils";
import ProductListItem from "../ProductListItem/ProductListItem";
import "./OtherProductList.scss";
import { textsSelector } from "../../Selectors";

const OtherProductList = ({ products, renderedProducts, currency, booking }) => {
    const texts = useSelector(textsSelector);
    const unrenderedProducts = Object.values(products).filter(product => !renderedProducts.includes(product.id));

    if (unrenderedProducts.length === 0) {
        return null;
    }

    return (
        <div className="box otherproductlist">
            {unrenderedProducts.map(product => {
                return (
                    <ProductListItem
                        key={product.id}
                        products={products}
                        product={product}
                        texts={texts}
                        currency={currency}
                        booking={booking}
                        allowCancellation={false}
                    />
                );
            })}
        </div>
    );
};

export default OtherProductList;
