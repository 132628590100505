import React, { ChangeEvent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { guestsOnTravelSelector } from "../../selectors/Selectors";
import { updateProduct } from "../../store/actions/checkoutCart";
import { v4 as uuidv4 } from "uuid";
import { TCartItem, TCartItemItem, TReservationType } from "../../store/types";
import { RootState } from "../..";
import useTranslate from "../../hooks/useTranslate";
import { CheckoutConnectCard } from "./CheckoutConnectCard";
import { CheckoutConnectCardHeading, CheckoutConnectCardRemoveBtn } from "./CheckoutConnectCard/CheckoutConnectCard";
import { capitalizeFirstLetter, getGuestNameAndAge } from "../../Helper";
import { showArrDepDates } from "../../BusinessUtils";
import { Select, style } from "@r360/library";
import { TSectionStates, isSectionStateInputValid } from "../../pages/CheckoutConnectPage/CheckoutConnectPage";
import { CheckoutConnectDeliveryPoint } from ".";

type TCheckoutConnectRental = {
    sectionStates: TSectionStates;
    type: TReservationType;
    products: TCartItem[];
    removeProductFromCart: (arg0: TCartItem, arg1: TCartItemItem) => void;
};

export const CheckoutConnectRental = ({
    sectionStates,
    type,
    products,
    removeProductFromCart,
}: TCheckoutConnectRental) => {
    const dispatch = useDispatch();
    const t = useTranslate();

    const guestsOnTravel = useSelector(guestsOnTravelSelector);
    // const lettinghelpimage = useSelector((state: RootState) => state.clientData.options.general?.lettinghelpimage);
    const { isDesktopNewDesign } = useSelector((state: RootState) => state.window);

    const rentals = products.filter(product => Number(product.type) === type.lid);
    const sectionState = sectionStates[type.lid];

    useEffect(() => {
        rentals.forEach(rental => {
            // eslint-disable-next-line no-empty
            if (rental.items && rental.items.length === rental.count) {
            } else {
                if (rental.items) {
                    let rest = rental.count - rental.items.length;
                    const items = rental.items;
                    if (rest > 0) {
                        for (let index = 0; index < rest; index++) {
                            const uuid = uuidv4();
                            items.push({ id: uuid, guestId: null });
                        }
                    } else {
                        rest = rest * -1;
                        for (let index = 0; index < rest; index++) {
                            items.pop();
                        }
                    }
                    dispatch(updateProduct(rental, { key: "items", value: items }));
                } else {
                    const items = [];
                    for (let count = 0; count < rental.count; count++) {
                        const uuid = uuidv4();
                        items.push({ id: uuid, guestId: null });
                    }
                    dispatch(updateProduct(rental, { key: "items", value: items }));
                }
            }
        });
    }, [dispatch, rentals]);

    const handleProductChange = (event: ChangeEvent<any>, product: TCartItem, productItem: TCartItemItem) => {
        const { name } = event.target;
        const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
        dispatch(
            updateProduct(product, {
                key: "items",
                value: product.items?.map(item => (item.id === productItem.id ? { ...item, [name]: value } : item)),
            })
        );
    };

    const renderRentals = () => {
        return rentals.map(rental => {
            return (
                !rental.detlid &&
                rental.items?.map((item, i) => {
                    const guestSelectOptions = Object.entries(guestsOnTravel).map(([id, guest]) => ({
                        value: id,
                        label: getGuestNameAndAge(guest, "år", rental),
                    }));

                    const titleAndGroupTitleAreTheSame = rental.title === rental.groupTitle;

                    return (
                        <CheckoutConnectCard key={`${item.id}-${i}`}>
                            <div className="row">
                                <div className="col-12 col-lg-7 u-d-flex u-flex-column u-justify-content-between u-mb-0">
                                    <div>
                                        <div className="u-mb-12">
                                            <CheckoutConnectCardHeading
                                                primaryHeading={rental.groupTitle}
                                                secondaryHeading={titleAndGroupTitleAreTheSame ? "" : rental.title}
                                                secondaryHeadingColor={style.alertColor}
                                            />
                                        </div>
                                        <div className="u-mb-6">
                                            <span>
                                                {capitalizeFirstLetter(showArrDepDates(rental, "dddd Do MMMM"))}
                                            </span>
                                        </div>
                                    </div>
                                    {isDesktopNewDesign && (
                                        <div className="u-pt-18">
                                            <CheckoutConnectCardRemoveBtn
                                                onClick={() => removeProductFromCart(rental, item)}
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className="col-12 col-lg-5 u-mb-0">
                                    <div className="u-mb-12">
                                        <Select
                                            required
                                            label={t("book.checkout.for_who")}
                                            name="guestId"
                                            defaultValue={(item.guestId || "").toString()}
                                            options={guestSelectOptions}
                                            fullWidth
                                            type="standard"
                                            onSelectCallbackWithEvent={event => {
                                                handleProductChange(event, rental, item);
                                            }}
                                            placeholder={t("book.checkout.pick_guest")}
                                            useFirstAsDefault={false}
                                            disabled={!guestSelectOptions.length}
                                            isValid={isSectionStateInputValid(sectionState, item.guestId ?? "")}
                                            showAsterisk
                                            boldLabel
                                        />
                                    </div>
                                    <div className="u-pt-12">
                                        {!isDesktopNewDesign && (
                                            <CheckoutConnectCardRemoveBtn
                                                onClick={() => removeProductFromCart(rental, item)}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </CheckoutConnectCard>
                    );
                })
            );
        });
    };

    return (
        <>
            <div className="u-mb-24">{renderRentals()}</div>
            <div className="u-d-flex u-justify-content-center u-mb-36">
                <CheckoutConnectDeliveryPoint sectionState={sectionState} products={rentals} />
            </div>
        </>
    );
};
