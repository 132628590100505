import { Field, Formik } from "formik";
import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { verifyWTPNumber } from "../../../Helper";
import PhotoButton from "../../PhotoButton/PhotoButton";
import "./PersonalInformation.scss";

const PersonalKeycardInformation = forwardRef((props, ref) => {
    const { keycardNo, imagehash, guestlid, texts, onSubmit, showKeycardNo, showKeycardImage } = props;
    const formikRef = useRef(null);

    useImperativeHandle(ref, () => ({
        submitForm: formikRef.current.submitForm,
        resetForm: formikRef.current.resetForm,
        resetTouched: () => formikRef.current.setTouched({}),
    }));

    const customHandleSubmit = () => {
        onSubmit && onSubmit(formikRef.current.values);
    };

    const validateKeycardNo = value => {
        if (value === "") {
            return;
        }

        return verifyWTPNumber(value)
            .then(response => {
                const valid = response.data.payload.valid;

                if (!valid) {
                    return "Invalid keycard number";
                }
            })
            .catch(error => {
                console.log(error);
                return "Invalid keycard number";
            });
    };

    if (!texts) {
        return <></>;
    }

    return (
        <div className={"personal-keycard-information"}>
            <Formik
                innerRef={formikRef}
                onSubmit={customHandleSubmit}
                initialValues={{
                    ...(showKeycardNo && { keycardNo: keycardNo || "" }),
                }}
                validateOnChange={false}
                validateOnBlur={false}
            >
                {({ handleSubmit, handleChange, handleBlur, values, touched, errors }) => {
                    return (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Row>
                                {showKeycardNo && (
                                    <Form.Group as={Col} md="6">
                                        <Field
                                            as={Form.Control}
                                            type="text"
                                            name="keycardNo"
                                            placeholder={texts.wtpnumber}
                                            value={values.keycardNo}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            validate={validateKeycardNo}
                                            isValid={touched.keycardNo && !errors.keycardNo}
                                            isInvalid={touched.keycardNo && !!errors.keycardNo}
                                        ></Field>
                                        <Form.Control.Feedback type="invalid">
                                            {texts?.couldnotvalidate}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                )}
                                {showKeycardImage && (
                                    <Form.Group
                                        as={Col}
                                        md="6"
                                        style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                                    >
                                        {guestlid ? (
                                            <PhotoButton guestlid={guestlid} imagehash={imagehash} />
                                        ) : (
                                            <p className="fw-light m-0">
                                                {texts["mypages.imageuploadafteraddedguest"]}
                                            </p>
                                        )}
                                    </Form.Group>
                                )}
                            </Row>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
});

PersonalKeycardInformation.displayName = "PersonalKeycardInformation";

export default PersonalKeycardInformation;
