import classNames from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Row, OverlayTrigger, Popover } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import queryString from "query-string";
import moment from "moment-timezone";
import ArrowLeft from "../UI/R360Icons/ArrowLeft";
import ArrowRight from "../UI/R360Icons/ArrowRight";
import AxiosClient from "../../API/AxiosClient";
import "./PriceCalendar.scss";
import { agesSelector } from "../../selectors/Selectors";
import { isEqual } from "lodash";
import { formatPrice } from "../../Helper";
import { textsSelector } from "../../Selectors";

const PriceCalendar = ({ groupedItem, filteredItems, resvType, reservationResultSearch }) => {
    const navigate = useNavigate();
    const currency = useSelector(state => state.clientData?.options?.general?.currency);
    const firstItem = groupedItem.items[0];
    const { grouppoollid, poollid, unitlid } = groupedItem;
    const isDesktop = useSelector(state => state.window.isDesktop);
    const indexOfCurrentItem = filteredItems.findIndex(item => unitlid === item.unitlid);
    const generalAges = useSelector(agesSelector, isEqual);
    const ages = generalAges[resvType];
    const previousUnitLid = indexOfCurrentItem > 0 ? filteredItems[indexOfCurrentItem - 1].unitlid : null;
    const [calendarData, setCalendarData] = useState([]);
    const [ageParams, setAgeParams] = useState(null);
    const nextUnitLid =
        filteredItems.length - 1 > indexOfCurrentItem ? filteredItems[indexOfCurrentItem + 1].unitlid : null;
    const texts = useSelector(textsSelector);

    useEffect(() => {
        const ages = generalAges[resvType];
        const defaultAgeValue = ages.find(age => age.description.includes("c5")).value;
        const defaultAges = [defaultAgeValue, defaultAgeValue];
        setAgeParams(reservationResultSearch?.guests.length > 0 ? reservationResultSearch?.guests : defaultAges);
    }, [generalAges, reservationResultSearch?.guests, resvType]);

    const showPrice = price => {
        return `${formatPrice(price, currency)}`;
    };

    const fetchPrices = useCallback(
        (page = 1) => {
            const url = `/calendars/prices/${resvType}/${poollid}/${unitlid}/${ageParams?.join(
                ","
            )}?${queryString.stringify({ page })}`;

            ageParams !== null &&
                AxiosClient.get(url).then(response => {
                    const data = response?.data?.payload || {};
                    const newWeekData = {
                        ...data,
                        weeks: data.weeks.map(({ mondayStartDate, prices, ...rest }) => {
                            const mondayStartDateMoment = moment(mondayStartDate);
                            const week = mondayStartDateMoment.isoWeek();
                            const year = mondayStartDateMoment.format("YYYY");
                            const pricesWithSelected = prices.map(price => {
                                //arrdat, depdate och price code
                                if (
                                    price.fromDate === firstItem.arrdate &&
                                    price.toDate === firstItem.depdate &&
                                    price.priceCode === firstItem.pricecode
                                ) {
                                    return { ...price, selected: true };
                                }
                                return price;
                            });

                            return {
                                ...rest,
                                prices: pricesWithSelected,
                                week,
                                year,
                                weekStartDate: mondayStartDateMoment,
                                weekEndDate: moment(mondayStartDate).add(6, "days"),
                            };
                        }),
                    };
                    setCalendarData(prevData => {
                        let newInsert = true;
                        const newData = prevData.map(val => {
                            if (val.page === newWeekData.page) {
                                newInsert = false;
                                return newWeekData;
                            }
                            return val;
                        });
                        if (newInsert) {
                            return newData.concat(newWeekData);
                        }
                        return newData;
                    });
                });
        },
        [ageParams, poollid, resvType, unitlid, firstItem.arrdate, firstItem.depdate, firstItem.pricecode]
    );

    useEffect(() => {
        fetchPrices();
    }, [fetchPrices]);

    const onPreviousAccommodationClick = () => {
        setCalendarData([]);
        previousUnitLid &&
            navigate(`/product/${resvType}/${grouppoollid}/${poollid}/${previousUnitLid}/price_calendar`, true);
    };
    const onNextAccommodationClick = () => {
        setCalendarData([]);
        nextUnitLid && navigate(`/product/${resvType}/${grouppoollid}/${poollid}/${nextUnitLid}/price_calendar`, true);
    };

    const onPriceClick = ({ priceCode, fromDate, toDate }) => {
        let queryStringObject = {};
        queryStringObject.startDate = fromDate;
        queryStringObject.endDate = toDate;
        queryStringObject.pricecode = priceCode;
        ageParams.forEach(value => {
            const age = ages.find(age => age.value === value);
            queryStringObject[age.key] = age.key in queryStringObject ? queryStringObject[age.key] + 1 : 1;
        });
        navigate(
            `/product/${resvType}/${grouppoollid}/${poollid}/${unitlid}?${queryString.stringify(queryStringObject)}`
        );
    };

    const onFetchMoreClick = () => {
        const { hasNextPage, page } = calendarData[calendarData.length - 1];
        hasNextPage && fetchPrices(page + 1);
    };

    const isEmptyResults = () => {
        return calendarData.map(({ weeks }) => weeks).flat().length === 0;
    };

    const linkPreviousClassName = classNames("price-calendar-navigation-link link", {
        disabled: !previousUnitLid,
    });
    const linkNextClassName = classNames(
        "price-calendar-navigation-link",
        "price-calendar-navigation-link__next link",
        {
            disabled: !nextUnitLid,
        }
    );

    const selectedAges = ageParams
        ?.map(value => ages?.find(age => value === age.value))
        .reduce((acc, val) => {
            acc[val.title] = acc[val.title] ? acc[val.title] + 1 : 1;
            return acc;
        }, {});

    return (
        <div className="price-calendar">
            <div className="price-calendar-title-top">{texts["price_calendar.title"]}</div>
            <div className="price-calendar-navigation">
                <a className={linkPreviousClassName} onClick={onPreviousAccommodationClick}>
                    {isDesktop ? texts["price_calendar.previous"] : <ArrowLeft />}
                </a>
                <div className="price-calendar-title">
                    <h4>{firstItem.title}</h4>
                </div>
                <a className={linkNextClassName} onClick={onNextAccommodationClick}>
                    {isDesktop ? texts["price_calendar.next"] : <ArrowRight />}
                </a>
            </div>
            {isEmptyResults() ? (
                <i className="price-calendar-empty-result">{texts["price_calendar.empty_result"]}</i>
            ) : calendarData.length > 0 ? (
                <>
                    <i>
                        {texts["price_calendar.price_based_on"]}{" "}
                        {selectedAges && (
                            <span>
                                {Object.entries(selectedAges)
                                    .map(([key, value]) => {
                                        return `${value} ${texts?.[key]}`;
                                    })
                                    .join(", ")}
                            </span>
                        )}
                    </i>
                    {!isDesktop ? (
                        <div className="price-calendar">
                            {calendarData.length > 0 &&
                                calendarData
                                    .map(({ weeks }) => weeks)
                                    .flat()
                                    .map(({ week, year, prices }, dataIndex) => {
                                        return (
                                            <div className="price-calendar-card" key={dataIndex}>
                                                <div className="price-calendar-card-header">
                                                    <div className="price-calendar-card-header-week-year-text">
                                                        {texts["price_calendar.week"]} {week}, {year}
                                                    </div>

                                                    <OverlayTrigger
                                                        placement="right"
                                                        overlay={
                                                            <Popover>
                                                                <Popover.Body>
                                                                    {prices.map((price, index) => {
                                                                        const fromDate = moment(
                                                                            price.fromDate,
                                                                            "YYYY-MM-DD"
                                                                        );
                                                                        const toDate = moment(
                                                                            price.toDate,
                                                                            "YYYY-MM-DD"
                                                                        );
                                                                        return (
                                                                            <div key={index}>
                                                                                {fromDate.format("ddd")}-
                                                                                {toDate.format("ddd")}:{" "}
                                                                                {fromDate.format("D/M")} -{" "}
                                                                                {toDate.format("D/M")}
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </Popover.Body>
                                                            </Popover>
                                                        }
                                                    >
                                                        {({ ref, ...triggerHandler }) => (
                                                            <span ref={ref}>
                                                                <Button
                                                                    variant="link"
                                                                    {...triggerHandler}
                                                                    style={{ padding: 0 }}
                                                                >
                                                                    {texts["price_calendar.date"]}
                                                                </Button>
                                                            </span>
                                                        )}
                                                    </OverlayTrigger>
                                                </div>
                                                <div>
                                                    <div className="price-calendar-card-body">
                                                        {prices.map((price, index) => (
                                                            <div key={index}>
                                                                {moment(price.fromDate).format("dddd")} -{" "}
                                                                {moment(price.toDate).format("dddd")}
                                                                {price.price === null ? (
                                                                    <div>-</div>
                                                                ) : (
                                                                    <Button
                                                                        onClick={() => onPriceClick(price)}
                                                                        className={`price-calendar-price-button price-calendar-price-button__mobile ${
                                                                            price.selected
                                                                                ? "price-calendar-price-button__selected"
                                                                                : ""
                                                                        }`}
                                                                    >
                                                                        {showPrice(price.price)}
                                                                    </Button>
                                                                )}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                        </div>
                    ) : (
                        <table className="price-calendar-desktop">
                            <thead>
                                <tr className="price-calendar-desktop-header">
                                    <th>{texts["price_calendar.date"]}</th>
                                    {calendarData.length > 0 &&
                                        calendarData
                                            .map(({ weeks }) => weeks)
                                            .flat()[0]
                                            ?.prices.map(({ fromDate, toDate }, index) => (
                                                <th key={index}>
                                                    {moment(fromDate).format("ddd")} - {moment(toDate).format("ddd")}
                                                </th>
                                            ))}
                                </tr>
                            </thead>
                            <tbody>
                                {calendarData.length > 0 &&
                                    calendarData
                                        .map(({ weeks }) => weeks)
                                        .flat()
                                        .map(({ week, year, prices }, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <div className="price-calendar-week-year-text">
                                                        {texts["price_calendar.week"]} {week}, {year}
                                                    </div>

                                                    <OverlayTrigger
                                                        placement="right"
                                                        overlay={
                                                            <Popover>
                                                                <Popover.Body>
                                                                    {prices.map((price, index) => {
                                                                        const fromDate = moment(
                                                                            price.fromDate,
                                                                            "YYYY-MM-DD"
                                                                        );
                                                                        const toDate = moment(
                                                                            price.toDate,
                                                                            "YYYY-MM-DD"
                                                                        );
                                                                        return (
                                                                            <div key={index}>
                                                                                {fromDate.format("ddd")}-
                                                                                {toDate.format("ddd")}:{" "}
                                                                                {fromDate.format("D/M")} -{" "}
                                                                                {toDate.format("D/M")}
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </Popover.Body>
                                                            </Popover>
                                                        }
                                                    >
                                                        {({ ref, ...triggerHandler }) => (
                                                            <span ref={ref}>
                                                                <Button variant="link" {...triggerHandler}>
                                                                    {texts["price_calendar.date"]}
                                                                </Button>
                                                            </span>
                                                        )}
                                                    </OverlayTrigger>
                                                </td>
                                                {prices.map((price, index) => (
                                                    <td key={index}>
                                                        <div className="price-calendar-price">
                                                            {price.price === null ? (
                                                                "-"
                                                            ) : (
                                                                <Button
                                                                    onClick={() => onPriceClick(price)}
                                                                    className={`price-calendar-price-button ${
                                                                        price.selected
                                                                            ? "price-calendar-price-button__selected"
                                                                            : ""
                                                                    }`}
                                                                >
                                                                    {showPrice(price.price)}
                                                                </Button>
                                                            )}
                                                        </div>
                                                    </td>
                                                ))}
                                            </tr>
                                        ))}
                            </tbody>
                        </table>
                    )}
                    {calendarData.length > 0 && calendarData[calendarData.length - 1].hasNextPage && (
                        <Row className="justify-content-end">
                            <Col md="auto">
                                <a className="price-calendar-navigation-link link" onClick={onFetchMoreClick}>
                                    {texts["price_calendar.fetch_more"]}
                                </a>
                            </Col>
                        </Row>
                    )}
                </>
            ) : null}
        </div>
    );
};

export default PriceCalendar;
