import moment from "moment-timezone";
import * as types from "../actions/types";

export const updateLastSearchedTimestamp = resvtype => {
    const timestamp = moment().toISOString();
    resvtype = parseInt(resvtype);

    return {
        type: types.SET_LAST_SEARCHED_TIMESTAMP,
        resvtype: resvtype,
        timestamp: timestamp,
    };
};

export const resetLastSearchedTimestamps = () => {
    return {
        type: types.RESET_LAST_SEARCHED_TIMESTAMPS,
    };
};

export const resetLastSearchedTimestampByResvtype = resvtype => {
    resvtype = parseInt(resvtype);

    return {
        type: types.RESET_LAST_SEARCHED_TIMESTAMP_BY_RESVTYPE,
        resvtype: resvtype,
    };
};

export const setLastSearchedDateSpan = (resvtypeId, arrdate, depdate) => {
    const timestamp = moment().format("YYYY-MM-DD, hh:mm:ss");
    return {
        type: types.SET_LAST_SEARCHED_DATE_SPAN,
        resvtypeId: resvtypeId,
        timestamp: timestamp,
        arrdate: arrdate,
        depdate: depdate,
    };
};
export const resetLastSearchedDateSpan = () => {
    return {
        type: types.RESET_LAST_SEARCHED_DATE_SPAN,
    };
};

export const setSelectedSortAccommodation = selectedSort => {
    return {
        type: types.SET_SELECTED_SORT_ACCOMMODATION,
        sort: selectedSort,
    };
};
export const resetSelectedSortAccommodation = () => {
    return {
        type: types.RESET_SELECTED_SORT_ACCOMMODATION,
    };
};
