import cloneDeep from "lodash/cloneDeep";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    hasBookstat,
    isAccommodation,
    isBooked,
    isCancelled,
    isParent,
    setAdditionalOnBookedAccommodationProduct,
    showArrDepDates,
} from "../../BusinessUtils";
import * as Constants from "../../Constants";
import { getLinktoProductExtras, getPackageProductTitle } from "../../Helper";
import { agesSelector, optionsSelector } from "../../selectors/Selectors";
import * as types from "../../store/actions/types";
import { RootState } from "../..";
import { TCartItem, TCheckoutCartSummary, TGeneralAge, TReservation, TReservationType } from "../../store/types";
import { BookingSummaryRow, BookingSummaryTable } from "./BookingSummaryTable";
import { TBookingSummaryRowAddition, TBookingSummaryRowGuests } from "./BookingSummaryTable/BookingSummaryTable";
import RichText from "../UI/RichText";
import { useBookingSummary } from "../../hooks/useBookingSummary";
import useTranslate from "../../hooks/useTranslate";
import { createCartFromReservation, emptyCartProducts } from "../../store/actions/checkoutCart";
import useAppDispatch from "../../hooks/useAppDispatch";
import { Button } from "@r360/library";
import moment from "moment";
import BookingSummaryCancellationButton from "./BookingSummaryCancelProductButton";

type TBookingSummaryAccomodation = {
    uniqueProductTypes: (TReservationType | undefined)[];
    checkoutSummary?: TCheckoutCartSummary;
    filterByGuestId: string;
    createdFromReservation?: string;
    booking?: TReservation;
    detailedView: boolean;
    isAgent?: boolean;
    handleSetRenderedProducts: (id: number) => void;
};

export const BookingSummaryAccomodation = ({
    uniqueProductTypes,
    checkoutSummary,
    filterByGuestId,
    createdFromReservation,
    booking,
    detailedView,
    isAgent = false,
    handleSetRenderedProducts,
}: TBookingSummaryAccomodation) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const t = useTranslate();
    const userToken = useSelector((state: RootState) => state.account.token);
    const clientData = useSelector((state: RootState) => state.clientData);
    const reservationTypes: TReservationType[] = clientData.reservationtypes;
    const currency = clientData.options.general.currency;
    const generalAges = useSelector(agesSelector);
    const skipGuestsOnAccommodation = useSelector(optionsSelector).reservation?.skipguestsonaccommodation || false;
    const mandatoryExtras = useSelector(optionsSelector).module?.["mandatory-extras"] || false;
    const [showConnectedSkipasses, setShowConnectedSkipasses] = useState<{ [key: string]: boolean }>();
    const [productExtrasLink, setProductExtrasLink] = useState("");
    const products = booking ? booking.reservation.products : checkoutSummary?.products ?? {};
    const { getProductTitle, getProductGuests, getProductAdditionalInfo, getProductPrice, getProductAdditions } =
        useBookingSummary(products);

    let totalPrice = 0;

    const hasUseSkipassExtra = (resTypeId: number): boolean => {
        const resType = reservationTypes.find(resType => resType.lid === resTypeId);
        if (resType?.useSkipassExtra) {
            return true;
        } else {
            return false;
        }
    };

    const getSkipassExtraName = (resTypeId: number): string => {
        const resType = reservationTypes.find(resType => resType.lid === resTypeId);
        const skiPass = reservationTypes.find(type => type.lid === resType?.skipassExtraReservationTypeId);
        return skiPass ? skiPass.description : "";
    };

    const accommodationParentProducts = Object.values(cloneDeep(products) || {})
        .filter(product => isAccommodation(product) && isParent(product))
        .map(product => {
            product.children = product.children
                .map(childId => products[childId])
                .filter(child => !!child)
                .map(setAdditionalOnBookedAccommodationProduct);
            return product;
        })
        .sort((a, b) => (hasBookstat(b) ? 1 : 0) - (hasBookstat(a) ? 1 : 0));

    useEffect(() => {
        dispatch({ type: types.CLEAR_ERRORS });
    }, [dispatch]);

    useEffect(() => {
        if (createdFromReservation && productExtrasLink) {
            navigate(productExtrasLink);
        }
        //eslint-disable-next-line
    }, [createdFromReservation, productExtrasLink]);

    // Close all connected skipasses when filtering guests
    useEffect(() => {
        const object: { [key: string]: boolean } = {};

        if (showConnectedSkipasses) {
            Object.entries(showConnectedSkipasses).forEach(([productId, value]) => {
                if (value) {
                    object[productId] = false;
                }
            });
        }

        object && setShowConnectedSkipasses(object);
        //eslint-disable-next-line
    }, [filterByGuestId]);

    // Change a reservation.
    const changeReservation = () => {
        const resvid = booking?.reservation?.resvid;

        if (!resvid) {
            return;
        }
        dispatch(emptyCartProducts());
        dispatch(createCartFromReservation(resvid, userToken, reservationTypes, clientData?.cookie));
    };

    const handleAddExtras = (product: TCartItem) => {
        setProductExtrasLink(getLinktoProductExtras(product));
        changeReservation();
    };

    const bookedAccommodationHasNewOrModifiedExtras = (product: TCartItem) => {
        return isBooked(product) && product.children.some(child => !isBooked(child) || child.additional);
    };

    /**
     * Get ages for a reservation type.
     *
     * @param {string} reservationTypeId The reservation type ID.
     *
     * @returns {Object[]} A list of ages objects.
     */
    const getAgesForReservationType = (reservationTypeId: number) => {
        if (!generalAges[reservationTypeId]) {
            throw new Error(`Reservation type ${reservationTypeId} does not have any ages configured in general ages`);
        }

        return generalAges[reservationTypeId];
    };

    const getDepDateHasPassed = (product: TCartItem) =>
        product?.depdate ? moment().isAfter(product.depdate, "day") : true;

    // const getDepDateHasPassed = (product: TCartItem) =>
    //     product?.depdate ? moment().isAfter(product.depdate, "day") : true;

    const verboseGuestsByAges = (accommodation: TCartItem) =>
        ((getAgesForReservationType(accommodation.type) as TGeneralAge[]) || [])
            .map((age: any) => {
                const guestCount = (Object.values(accommodation.guestages || {}) || []).filter(
                    guestAge => guestAge >= age.min && guestAge <= age.max
                ).length;

                return [age, guestCount];
            })
            .filter(([, guestCount]) => guestCount > 0)
            .map(([age, guestCount]) => `${guestCount} ${t(age.title)}`);

    // Get output based on product type
    const renderProduct = (
        product: TCartItem,
        connectedSkipasses: TCartItem[],
        hasNewOrChangedConnectedSkipasses = false
    ) => {
        let additions: TCartItem[] = [],
            additionPrices = [],
            additionsTotalPrice = 0;

        // Calculate total price for all child products that belong to this product
        if (product.children) {
            additions = product.children;
            if (additions && additions.length) {
                additionPrices = additions.map(item => {
                    // Mandatory-Extras: true: Price is included in accommodation, do not sum
                    // Mandatory-Extras: false: Price is not included in acommodation, add to sum
                    if (mandatoryExtras && item.mandatory_web) {
                        return 0;
                    } else {
                        return item.price;
                    }
                });

                additionsTotalPrice =
                    additionPrices.length > 0 ? additionPrices.reduce((accumulator, value) => accumulator + value) : 0;
            }
        } else {
            additions = [];
        }

        const renderProductRow = () => {
            const filteredAdditions = [
                ...additions.filter(addition => !addition.bookstat || addition.bookstat === "B"),
                ...additions.filter(addition => addition.bookstat === "C"),
            ];

            const rowAdditions: TBookingSummaryRowAddition[] = [
                ...(filteredAdditions.length > 0
                    ? [
                          {
                              title: product.title,
                              quantity: 1,
                              price: product.price,
                              showPrice: true,
                              currency,
                              canceled: false,
                              isProduct: true,
                          },
                      ]
                    : []),
                ...filteredAdditions.flatMap(addition => {
                    const title = addition.title;

                    const additionIsModified = !!addition.additional;

                    let showPrice = true;

                    // Mandatory-Extras: true: Price is included in accommodation, show price if price is configured to be shown
                    // Mandatory-Extras: false: Price is not included in acommodation, always show price
                    if (mandatoryExtras && addition.mandatory_web && !addition.showpriceweb) {
                        showPrice = false;
                    }

                    handleSetRenderedProducts(addition.id);

                    // If the addition has been modified, return both the previous and the new
                    return [
                        {
                            title,
                            quantity:
                                additionIsModified && addition.bookedquantity
                                    ? addition.bookedquantity
                                    : addition.quantity,
                            price: additionIsModified
                                ? addition.price - (addition.additional.price ?? 0)
                                : addition.aprice * addition.quantity,
                            showPrice,
                            currency,
                            canceled: addition.bookstat === "C" || additionIsModified,
                            // Show addition as new if the product is booked but the addition is not
                            newOrChanged: isBooked(product) && !isBooked(addition) && !isCancelled(addition),
                        },
                        ...(additionIsModified
                            ? [
                                  {
                                      title,
                                      quantity: addition.quantity,
                                      price: addition.price,
                                      showPrice,
                                      currency,
                                      canceled: addition.bookstat === "C",
                                      newOrChanged: true,
                                  },
                              ]
                            : []),
                    ];
                }),
            ];

            const rowGuests =
                skipGuestsOnAccommodation || Object.values(product.guests || {}).length === 0
                    ? verboseGuestsByAges(product).map((row: string) => row)
                    : Object.values(product.guests).map(guest => `${guest.firstname} ${guest.lastname}`);

            const rowGuestsForDisplay: TBookingSummaryRowGuests[] = rowGuests.map(row => {
                return {
                    name: row,
                };
            });

            let filteredGuestHasConnectedSkipasses = false;

            if (filterByGuestId) {
                filteredGuestHasConnectedSkipasses = connectedSkipasses.some(
                    skipass => filterByGuestId in skipass.guests
                );
            }

            if (filterByGuestId && !(filterByGuestId in product.guests) && !filteredGuestHasConnectedSkipasses) {
                return null;
            }

            if (filteredGuestHasConnectedSkipasses) {
                if (showConnectedSkipasses && !showConnectedSkipasses[product.id]) {
                    setShowConnectedSkipasses(prevState => ({
                        ...prevState,
                        [product.id]: true,
                    }));
                }
            }

            // Show all connected skipasses if any are new
            if (hasNewOrChangedConnectedSkipasses && !showConnectedSkipasses?.[product.id]) {
                setShowConnectedSkipasses(prevState => ({
                    ...prevState,
                    [product.id]: true,
                }));
            }

            const connectedSkipassesPrice = connectedSkipasses.reduce((acc, curr) => acc + getProductPrice(curr), 0);

            if (!filterByGuestId && !hasNewOrChangedConnectedSkipasses && connectedSkipasses.length > 0) {
                rowAdditions.push({
                    title: connectedSkipasses[0].kinddesc,
                    quantity: connectedSkipasses.length,
                    price: connectedSkipassesPrice,
                    showPrice: true,
                    currency: currency,
                    canceled: false,
                    actionText: showConnectedSkipasses?.[product.id]
                        ? t("book.checkout.summary.hide_skipass")
                        : t("book.checkout.summary.show_skipass"),
                    actionOnClick: () =>
                        setShowConnectedSkipasses(prevState => ({
                            ...prevState,
                            [product.id]: !prevState?.[product.id],
                        })),
                });
            }

            const price = Math.round(product.price + additionsTotalPrice + connectedSkipassesPrice);
            const ordinaryPrice =
                product.ordinaryprice > 0
                    ? Math.round(product.ordinaryprice + additionsTotalPrice + connectedSkipassesPrice)
                    : undefined;

            // Add price to total price
            totalPrice = totalPrice + price;

            handleSetRenderedProducts(product.id);

            return (
                <div>
                    <BookingSummaryRow
                        title={getPackageProductTitle(product) || product.pooldesc3}
                        additions={rowAdditions}
                        guests={rowGuestsForDisplay}
                        dates={[showArrDepDates(product)]}
                        price={{
                            price,
                            ordinaryPrice,
                            campaign: !!product.promotionCode,
                            currency,
                        }}
                        cancellationBtn={
                            <BookingSummaryCancellationButton
                                resvid={booking?.reservation.resvid ?? ""}
                                detlid={product.detlid ?? parseInt("")}
                                productTitle={product.title}
                                allowCancellation={(booking?.allowcancellation && !isAgent) ?? false}
                                connectedProducts={connectedSkipasses}
                            />
                        }
                        addExtrasBtn={
                            <>
                                {booking && !getDepDateHasPassed(product) && (
                                    <div className="u-pt-12">
                                        <Button
                                            type="secondary"
                                            buttonSize={"small"}
                                            onClick={() => handleAddExtras(product)}
                                            buttonClassName="u-mr-12 u-mb-6"
                                        >
                                            {t("book.booking.add_extras")}
                                        </Button>
                                        {hasUseSkipassExtra(product.type) && (
                                            <Button
                                                type="secondary"
                                                buttonSize="small"
                                                onClick={() => handleAddExtras(product)}
                                                disabled={
                                                    product.capac ? connectedSkipasses.length >= product.capac : false
                                                }
                                            >
                                                {`${t("book.checkout.add")} ${getSkipassExtraName(product.type)}`}
                                            </Button>
                                        )}
                                    </div>
                                )}
                            </>
                        }
                        details={{
                            description: product.weblong ? <RichText content={product.weblong} /> : "",
                        }}
                        showDetails={detailedView}
                        isNewOrChangedProduct={!!(createdFromReservation && !isBooked(product))}
                        hasConnectedProducts={!!connectedSkipasses.length}
                    />

                    {connectedSkipasses.length > 0 &&
                        connectedSkipasses
                            .filter(skipass => (filterByGuestId ? filterByGuestId in skipass.guests : true))
                            .map(skipass => {
                                handleSetRenderedProducts(skipass.id);

                                return (
                                    <BookingSummaryRow
                                        key={skipass.id}
                                        hidden={!showConnectedSkipasses?.[product.id]}
                                        title={getProductTitle(skipass)}
                                        additions={getProductAdditions(skipass)}
                                        onRenderAddition={addition =>
                                            addition.id && handleSetRenderedProducts(addition.id)
                                        }
                                        guests={getProductGuests(skipass)}
                                        dates={[showArrDepDates(skipass)]}
                                        price={{
                                            price: getProductPrice(skipass),
                                            currency,
                                        }}
                                        details={{
                                            description: skipass.weblong ? <RichText content={skipass.weblong} /> : "",
                                            additionalInfo: getProductAdditionalInfo(skipass),
                                        }}
                                        showDetails={detailedView}
                                        isNewOrChangedProduct={!!(createdFromReservation && !isBooked(skipass))}
                                        isConnectedProduct
                                    />
                                );
                            })}
                </div>
            );
        };

        return renderProductRow();
    };

    return (
        <>
            {uniqueProductTypes
                .filter(productType => productType?.type === Constants.productTypeNames.ACCOMMODATION)
                .filter(productType => {
                    return accommodationParentProducts
                        .filter(product => product.type === productType?.lid)
                        .filter(product => checkoutSummary || isBooked(product)).length;
                })
                .map(productType => {
                    const accomodationsToRender = accommodationParentProducts
                        .filter(product => product.type === productType?.lid)
                        .filter(product => checkoutSummary || isBooked(product));

                    if (!accomodationsToRender.length) {
                        return null;
                    }

                    let newOrChangedProducts = 0;

                    if (createdFromReservation) {
                        newOrChangedProducts = accomodationsToRender
                            .filter(product => !isBooked(product) || bookedAccommodationHasNewOrModifiedExtras(product))
                            .reduce((acc: number, curr: TCartItem) => {
                                const newOrModifiedExtras = curr.children.filter(
                                    child => !isBooked(child) || child.additional
                                );

                                // If only the extras has been modified, add the extras to the count
                                if (isBooked(curr)) {
                                    return acc + (newOrModifiedExtras.length ?? 0);
                                }

                                // If the entire product is new, only add the product to the count
                                return acc + 1;
                            }, 0);
                    }

                    // Create an empty object for any skipasses connected to an accommodation
                    const connectedSkipassesForAccommodations: { [key: string]: TCartItem[] } = {};

                    // ... then populate the object by looping through all accommodations
                    accomodationsToRender.forEach(accomodation => {
                        const connectedSkipasses = Object.values(products).filter(product => {
                            if (!product?.connectedProductId) {
                                return false;
                            }

                            // In checkout the cartItemId is used to connect while id is used on a reservation
                            const accommodationId = accomodation.cartItemId ?? accomodation.id;
                            return product.connectedProductId === accommodationId;
                        });

                        // Add all connected skipasses to the accommodation
                        connectedSkipassesForAccommodations[accomodation.id] = connectedSkipasses;
                    });

                    // If no accommodation or connected skipass have the filtered guest as guest, don't render the table at all
                    if (
                        filterByGuestId &&
                        !accomodationsToRender.some(accommodation => filterByGuestId in accommodation.guests) &&
                        !Object.values(connectedSkipassesForAccommodations)
                            .flat()
                            .some(skipass => filterByGuestId in skipass.guests)
                    ) {
                        return null;
                    }

                    const newConnectedSkipasses = Object.values(connectedSkipassesForAccommodations)
                        .filter(skipasses => skipasses.some(skipass => !isBooked(skipass) && createdFromReservation))
                        .flat();

                    // If new connected skipasses exists, add them to the number of new products
                    if (Object.values(connectedSkipassesForAccommodations).length > 0) {
                        newOrChangedProducts = newOrChangedProducts + newConnectedSkipasses.length;
                    }

                    return (
                        <BookingSummaryTable
                            key={productType?.lid}
                            title={productType?.description ?? ""}
                            productCount={accomodationsToRender.length}
                            numberOfNewProducts={newOrChangedProducts}
                        >
                            <>
                                {accomodationsToRender.map((product, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            {renderProduct(
                                                product,
                                                connectedSkipassesForAccommodations[product.id],
                                                newConnectedSkipasses.length > 0
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                                {!filterByGuestId && (
                                    <BookingSummaryRow
                                        price={{
                                            price: totalPrice,
                                            currency,
                                            isTotalPrice: true,
                                        }}
                                    />
                                )}
                            </>
                        </BookingSummaryTable>
                    );
                })}
        </>
    );
};
