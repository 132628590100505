import { Button, Heading, Skeleton } from "@r360/library";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { RootState } from "../..";
import { formatPrice } from "../../Helper";
import { resvTypeByLid } from "../../Selectors";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import useItemInCart from "../../hooks/useItemInCart";
import useTranslate from "../../hooks/useTranslate";
import { cartProductsSelector, matchingSkipassExtraGroupForAccommodationSelector } from "../../selectors/Selectors";
import {
    addProduct,
    addProductCount,
    removeProduct,
    setCartProductExtras,
    updateProductWithCount,
} from "../../store/actions/checkoutCart";
import { fetchReservationTypeResults } from "../../store/actions/reservationResult";
import { TCartItem, TGeneralAges, TItem, TReservationResult, TReservationType } from "../../store/types";
import "./AccommodationExtras.scss";
import { AccommodationExtrasItem } from "./AccommodationExtrasItem";
import AccommodationSkipassExtraCard, { TAddToCartData } from "./AccommodationSkipassExtraCard";
import ScrollToTop from "../ScrollToTop";
import useReservationTypeLink from "../../hooks/useReservationTypeLink";
import { GoToReservationType } from "../GoToReservationType/GoToReservationType";
import { isBooked, isCancelled, isSystemPool } from "../../BusinessUtils";
import { cartCreatedFromReservationSelector } from "../../selectors/Selectors";

type TAccommodationExtras = {
    item: TItem | TCartItem;
    groupTitle: string;
};

type TSelectedExtra = {
    quantity: number;
    radioSelected?: boolean;
    selectable?: boolean;
};

type TSelectedExtras = {
    [key: string]: TSelectedExtra;
};

export const extrasItemQuantityColumnClass = "col-12 col-lg-2";
export const extrasItemPriceColumnClass = "col-9 col-lg-1";
export const extrasItemTotalColumnClass = "col-3 col-lg-2 accommodation-extras__total-column";

export const AccommodationExtras = ({ item, groupTitle }: TAccommodationExtras) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const reservationTypeId = item.type;
    const { isDesktop } = useAppSelector((state: RootState) => state.window);
    const clientData = useAppSelector((state: RootState) => state.clientData);
    const currency = clientData?.options?.general?.currency;
    const [selectedExtras, setSelectedExtras] = useState<TSelectedExtras>();
    const [showUnselectedExtrasWarning, setShowUnselectedExtrasWarning] = useState(false);
    const itemHasExtras = !!(item.info?.extras && item.info?.extras?.length > 0);
    const t = useTranslate();
    const getItemInCart = useItemInCart();
    const { isItemInCart } = getItemInCart(item);
    const [skipassExtrasAddToCartData, setSkipassExtrasAddToCartData] = useState<TAddToCartData[]>();
    const connectedProducts = useAppSelector(cartProductsSelector).filter(
        connectedProduct =>
            !isCancelled(connectedProduct) &&
            connectedProduct.connectedProductId &&
            connectedProduct.connectedProductId === (item as TCartItem).cartItemId
    );
    const requestsLoading = useAppSelector((state: RootState) => state.axiosStatus).requestsLoading || {};
    const fetchProductsExtras = !!requestsLoading["fetchProductsExtras"];
    const reservationTypeResultsLoading = !!requestsLoading["fetchReservationTypeResults"];
    const skipassExtraGroup = useAppSelector(state => matchingSkipassExtraGroupForAccommodationSelector(state, item));
    const reservationResult = useAppSelector((state: RootState) => state.reservationResult);
    const reservationType = useAppSelector((state: RootState) => resvTypeByLid(state, reservationTypeId));
    const reservationTypeResult = reservationResult[reservationTypeId] as TReservationResult;
    const [searchForSkipassExtrasTriggered, setSearchForSkipassExtrasTriggered] = useState(false);
    // const reservationTypes = clientData.reservationtypes?.filter((resType: TReservationType) => !resType.hidden);
    const reservationTypeLink = useReservationTypeLink();
    const cartCreatedFromReservation = useAppSelector(cartCreatedFromReservationSelector);

    useEffect(() => {
        // Trigger skipass extras search if applicable.
        if (reservationType?.useSkipassExtra && reservationType?.skipassExtraReservationTypeId && !skipassExtraGroup) {
            const requestData = {
                resvtype: reservationType?.skipassExtraReservationTypeId,
                startdate: item.arrdate,
                enddate: item.depdate,
                ages: [],
            };

            dispatch(fetchReservationTypeResults(requestData, true));
            setSearchForSkipassExtrasTriggered(true);
        }
    }, []);

    useEffect(() => {
        if (fetchProductsExtras || reservationTypeResultsLoading) {
            return;
        }

        if (itemHasExtras || skipassExtraGroup) {
            initSelectedExtras();
        } else {
            addItemToCart(true);
        }
    }, [fetchProductsExtras, reservationTypeResultsLoading, itemHasExtras]);

    const initSelectedExtras = () => {
        const extras = item.info?.extras;

        const initSelectedExtras = (extras as TItem[])?.reduce((obj: TSelectedExtras, item: TItem) => {
            return {
                ...obj,
                [item.id]: item.demand_web
                    ? {
                          quantity: item.quantity,
                          radioSelected: isItemInCart || (cartCreatedFromReservation && isSystemPool(item)) || false,
                      }
                    : { quantity: item.quantity },
            };
        }, {});

        setSelectedExtras(initSelectedExtras);
    };

    const checkUnselectedExtras = () => {
        if (selectedExtras) {
            return Object.values(selectedExtras).some(item => item.radioSelected === false);
        } else {
            return false;
        }
    };

    const handleQuantityChange = (quantity: number, id: number) => {
        setSelectedExtras(prevState => {
            if (prevState) {
                const hasRadio = "radioSelected" in prevState[id];

                return {
                    ...prevState,
                    [id]: {
                        ...prevState?.[id],
                        quantity,
                        // We set radioSelected to true so we know that a choice has been made on a radio that requires choice
                        ...(hasRadio && { radioSelected: true }),
                    },
                };
            }
        });
    };

    const renderTotalPrice = () => {
        const booked = isBooked(item);

        let totalPrice = Object.entries(selectedExtras || {}).reduce(
            (total, [id, obj]) => {
                const extra = item.info?.extras?.find(extra => extra.id === parseInt(id));

                if (extra?.mandatory_web) {
                    return total;
                }

                if (extra) {
                    const price = booked
                        ? (obj.quantity - (extra.bookedquantity || 0)) * extra.aprice
                        : obj.quantity * extra.aprice;
                    return total + price;
                } else {
                    return total;
                }
            },
            booked ? 0 : item.price
        );

        if (skipassExtrasAddToCartData) {
            totalPrice = skipassExtrasAddToCartData.reduce((acc, curr) => {
                acc += curr.item.price * curr.quantity;
                return acc;
            }, totalPrice);
        }

        return totalPrice;
    };

    const addItemToCart = (itemWithoutExtras: boolean, navigateToCart = false) => {
        const hasUnselectedExtras = checkUnselectedExtras();

        if (hasUnselectedExtras) {
            setShowUnselectedExtrasWarning(true);
        } else {
            setShowUnselectedExtrasWarning(false);
            const updatedExtras = item.info?.extras?.map(extra => {
                const selectedExtra = selectedExtras?.[extra.id];

                return {
                    ...extra,
                    ...((extra.rules.type_input === "radiobutton" || extra.rules.type_input === "checkbox") && {
                        checked: !!selectedExtra?.quantity,
                    }),
                    quantity: selectedExtra?.quantity ?? 0,
                    price: extra.aprice * (selectedExtra?.quantity ?? 0),
                    // demandRadioSelected: selectedExtra?.radioSelected,
                };
            });

            if (isItemInCart) {
                dispatch(setCartProductExtras(item.id, updatedExtras));

                skipassExtrasAddToCartData?.forEach(entry => {
                    if (entry.quantity >= 1) {
                        if (entry.item.connectedProductId) {
                            // Skipass extra already added and connected to accommodation item.
                            dispatch(updateProductWithCount(entry.item, entry.quantity));
                        } else {
                            // Skipass extra added after that the accommodation was added to cart.
                            dispatch(
                                addProductCount(
                                    {
                                        ...entry.item,
                                        connectedProductId: (item as TCartItem).cartItemId,
                                    },
                                    entry.quantity,
                                    true
                                )
                            );
                        }
                    } else {
                        // Reduced quantity of skipass extra to 0. Remove from cart.
                        dispatch(removeProduct(entry.item));
                    }
                });
            } else {
                const generalAges: TGeneralAges = JSON.parse(clientData.options?.general?.ages);

                const newItem: TItem = {
                    ...item,
                    groupTitle: groupTitle,
                    info: {
                        ...item.info,
                        guests: {
                            totalGuests: reservationTypeResult?.search?.guests?.length || 0,
                        },
                        extras: updatedExtras ?? [],
                    },
                };

                generalAges[item.type].forEach(age => {
                    if (newItem.info?.guests) {
                        newItem.info.guests[age.key] =
                            reservationTypeResult?.search?.guests.filter(
                                guestAge => guestAge >= age.min && guestAge <= age.max
                            ).length || 0;
                    }
                });

                const customCartItemId = uuidv4();
                dispatch(addProduct(newItem, customCartItemId));

                skipassExtrasAddToCartData?.forEach(entry => {
                    if (entry.quantity >= 1) {
                        dispatch(
                            addProductCount(
                                {
                                    ...entry.item,
                                    connectedProductId: customCartItemId,
                                    cartItemId: `${entry.item.poollid}-${customCartItemId}`,
                                },
                                entry.quantity,
                                false
                            )
                        );
                    }
                });
            }

            if (itemWithoutExtras) {
                return;
            } else if (navigateToCart) {
                goToCart();
            } else {
                goBackToSearch();
            }
        }
    };

    const sortedExtras = item.info?.extras
        ? [...item.info.extras]?.sort((a, b) => {
              // A mandatory editbox or dropdown should come after a non-mandatory one
              if (
                  (a.rules.type_input === "editbox" || a.rules.type_input === "dropdown") &&
                  (b.rules.type_input === "editbox" || b.rules.type_input === "dropdown")
              ) {
                  return b.mandatory_web ? -1 : 1;
              }

              // A radiobutton or a checkbox should come after a editbox or dropdown
              if (
                  (a.rules.type_input === "editbox" || a.rules.type_input === "dropdown") &&
                  (b.rules.type_input === "radiobutton" || b.rules.type_input === "checkbox")
              ) {
                  return -1;
              }

              // A checkbox should come after a radiobutton
              if (a.rules.type_input === "radiobutton" && b.rules.type_input === "checkbox") {
                  return -1;
              }

              return 1;
          })
        : [];

    const goBackToSearch = () => {
        navigate(`/search/${reservationTypeId}`);
    };

    const goToCart = () => {
        navigate("/cart");
    };

    const addItemsAndGoToResType = (resType: TReservationType) => {
        const hasUnselectedExtras = checkUnselectedExtras();
        if (hasUnselectedExtras) {
            setShowUnselectedExtrasWarning(true);
            return;
        } else {
            addItemToCart(false, false);
            navigate(reservationTypeLink(resType));
        }
    };

    // const getFallbackImage = (type: string): string => {
    //     switch (type) {
    //         case "ACTIVITY":
    //             return ActivityDesktop;
    //         case "ACCOMMODATION":
    //             return AccommodationDesktop;
    //         case "SKIPASS":
    //             return SkipassDesktop;
    //         case "RENTAL":
    //             return RentalDesktop;
    //         case "LETTING":
    //             return LettingDesktop;
    //         case "PACKAGE":
    //             return PackageDesktop;
    //         default:
    //             return SkipassDesktop;
    //     }
    // };

    if (!itemHasExtras && !fetchProductsExtras && !reservationTypeResultsLoading && !skipassExtraGroup) {
        return (
            <div className="u-d-flex u-flex-column u-align-items-center">
                <Heading type="h1" className="u-mb-24">
                    {t("book.accomodation.added_to_cart")}
                </Heading>
                <div className="u-d-flex u-gap-12">
                    <Button type="secondary" onClick={goBackToSearch}>
                        {t("book.accomodation.keep_shopping")}
                    </Button>
                    <Button onClick={goToCart}>{t("book.general.show_cart")}</Button>
                </div>
            </div>
        );
    }

    return (
        <div className="accommodation-extras u-pt-24">
            <ScrollToTop />
            <Heading type="h1" className="u-mb-48">
                {t("book.accomodation.extras.heading")}
                {isItemInCart && ` (${item.title} )`}
            </Heading>
            {/* Add show only if Accommodation has a connected Skipass restype */}
            {skipassExtraGroup && (
                <AccommodationSkipassExtraCard
                    accommodation={item}
                    skipassExtraGroup={skipassExtraGroup}
                    guests={item.guestages ? Object.values(item.guestages) : reservationTypeResult.search.guests}
                    onChange={setSkipassExtrasAddToCartData}
                    accommodationConnectedProductsInCart={connectedProducts}
                />
            )}
            <div className="accommodation-extras__column-titles row">
                <div className={classNames("col-9 col-lg-7 accommodation-extras__column-title")}>
                    {t("book.general.product")}
                </div>
                {isDesktop && (
                    <>
                        <div
                            className={classNames("accommodation-extras__column-title", extrasItemQuantityColumnClass)}
                        >
                            {t("book.general.quantity")}
                        </div>
                        <div className={classNames("accommodation-extras__column-title", extrasItemPriceColumnClass)}>
                            {t("book.general.price")}
                        </div>
                    </>
                )}
                <div className={classNames("accommodation-extras__column-title", extrasItemTotalColumnClass)}>
                    {t("book.general.total")}
                </div>
            </div>
            <div className="u-mb-24">
                {selectedExtras &&
                    sortedExtras?.map(extra => (
                        <AccommodationExtrasItem
                            key={extra.id}
                            item={extra}
                            quantity={selectedExtras[extra.id]?.quantity}
                            setQuantity={quantity => handleQuantityChange(quantity, extra.id)}
                            currency={currency}
                            showUnselectedWarning={
                                showUnselectedExtrasWarning && selectedExtras[extra.id].radioSelected === false
                            }
                            itemInCart={isItemInCart}
                            accommodationItem={item}
                            loading={reservationTypeResultsLoading}
                        />
                    ))}
            </div>
            <section className="accommodation-extras__summary">
                <Heading type="h5">
                    {isBooked(item) ? t("book.general.total") : t("book.accomodation.total_incl_acc")}
                </Heading>

                {searchForSkipassExtrasTriggered && reservationTypeResultsLoading ? (
                    <Skeleton width="120px" height="30px" shade="dark" />
                ) : (
                    <div className="accommodation-extras__total-price">{`${formatPrice(
                        renderTotalPrice(),
                        currency
                    )}`}</div>
                )}
            </section>
            <div className="u-d-flex u-flex-column u-gap-18 u-align-items-center">
                <Button
                    buttonClassName="accommodation-extras__add-to-cart-btn"
                    onClick={() => addItemToCart(false, true)}
                >
                    {isItemInCart ? t("book.cart.extras.update_and_show_cart") : t("book.general.add_and_show_cart")}
                </Button>
                {!isItemInCart ? (
                    <>
                        <p className="accommodation-extras__continue-text">
                            {t("book.accommodation.extras_add_to_cart_and_continue")}:
                        </p>
                        <GoToReservationType customOnClick={addItemsAndGoToResType} />
                    </>
                ) : (
                    <Button
                        buttonClassName="accommodation-extras__add-to-cart-btn"
                        type={"secondary"}
                        onClick={() => navigate("/cart")}
                    >
                        {t("book.cart.extras.regret_and_show_cart")}
                    </Button>
                )}
            </div>
        </div>
    );
};
