export const system = {
    LANGUAGE: "en",
};

export const productTypeNames = {
    ACCOMMODATION: "ACCOMMODATION",
    SKIPASS: "SKIPASS",
    LETTING: "LETTING",
    RENTAL: "RENTAL",
    PACKAGE: "PACKAGE",
    ACTIVITY: "ACTIVITY",
    OTHER: "OTHER",
};

export const bikeTypeFilters = {
    BIKES_ONLY: "1042",
    OPTIONALS_ONLY: "1150",
};

export const throttleTime = 1500;

export const partPaymentType = {
    PREPAYAMOUNT: "prepay",
    PREFINALAMOUNT: "prefinalpay",
    FINALPAYAMOUNT: "finalpay",
};

export const customColors = {
    CUSTOMPRIMARYCOLOR: "--custom-primary-color",
    CUSTOMPRIMARYHOVERCOLOR: "--custom-primary-hover-color",
    CUSTOMPRIMARYCONTRASTCOLOR: "--custom-primary-contrast-color",
    CUSTOMLOGINCOLOR: "--custom-login-color",
    CUSTOMLOGINCONTRASTCOLOR: "--custom-login-contrast-color",
};
