import React from "react";
import ContentLoader from "react-content-loader";

const ReservationTypesLoader = props => (
    <ContentLoader
        speed={2}
        width={400}
        height={475}
        viewBox="0 0 400 475"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
    >
        <rect x="0" y="0" rx="10" ry="10" width="275" height="275" />
        <rect x="0" y="294" rx="10" ry="10" width="110" height="20" />
        <rect x="0" y="325" rx="0" ry="0" width="98" height="25" />
        <rect x="0" y="370" rx="10" ry="10" width="100" height="30" />
        <rect x="105" y="370" rx="10" ry="10" width="68.75" height="30" />
        <rect x="180" y="370" rx="10" ry="10" width="100" height="30" />
        <rect x="0" y="420" rx="0" ry="0" width="275" height="40" />
    </ContentLoader>
);

export default ReservationTypesLoader;
