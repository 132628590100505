import React, { useContext } from "react";
import { Accordion, Button, Card, useAccordionButton } from "react-bootstrap";
import AccordionContext from "react-bootstrap/AccordionContext";
import { isSystemPool, hasBookstat, isBooked, showArrDepDates } from "../../BusinessUtils";
import { getPackageProductTitle } from "../../Helper";
import { ArrowDownIcon, HouseIcon } from "../UI/R360Icons";
import "./ProductExtras.scss";
import ProductExtrasItem from "./ProductExtrasItem/ProductExtrasItem";

// Product extras component
const ProductExtras = ({ products, texts, isMobile, createdFromReservation }) => {
    const iconSize = isMobile ? 20 : 24;

    // Accordion toggle button
    const AccordionButton = ({ children, eventKey, callback }) => {
        const currentEventKey = useContext(AccordionContext);
        const isCurrentEventKey = currentEventKey.activeEventKey === eventKey;
        const toggleClass = isCurrentEventKey ? " product-extras__accordion-toggle-icon--toggled" : "";
        const toggleContent = useAccordionButton(eventKey, () => {
            const visible = isCurrentEventKey ? false : true;
            callback && callback(eventKey, visible);
            // Todo: Fixa attribut för aria-expanded på knapparna som togglar true/false...
            //console.log("toggle aria-expanded");
            document.querySelector(".product-extras__accordion").scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest",
            });
        });

        return (
            <Button
                className="product-extras__accordion-toggle"
                aria-expanded={isCurrentEventKey ? true : false}
                onClick={toggleContent}
            >
                <div className="d-flex justify-content-between">
                    <div>
                        <HouseIcon size={iconSize} color="#333333" iconClass="product-extras__accordion-icon" />
                        {children}
                    </div>
                    <div>
                        <ArrowDownIcon
                            size={12}
                            color="#333333"
                            iconClass={`product-extras__accordion-toggle-icon${toggleClass}`}
                        />
                    </div>
                </div>
            </Button>
        );
    };

    const getAvailableExtras = (product, extras) => {
        // data.rules.type_input === "checkbox"
        const disabledExtras = getDisabledExtras(product, extras);

        return extras.filter(extra => !disabledExtras.includes(extra));
    };

    const getDisabledExtras = (product, extras) => {
        let disabledExtras = [];

        // Always disable system pools when cart is created from a reservation.
        if (createdFromReservation) {
            disabledExtras = extras.filter(isSystemPool);
        }

        // If the product is booked, always disable
        if (isBooked(product)) {
            disabledExtras = [
                ...new Set([
                    ...disabledExtras,
                    ...extras.filter(
                        extra =>
                            (extra.rules || []).length === 0 ||
                            (hasBookstat(extra) &&
                                extra.bookedquantity === 1 &&
                                extra.rules.type_input === "checkbox") ||
                            (extra.rules.min_qty === 0 && extra.rules.max_qty === 0 && extra.detlid) ||
                            extra.rules.mandatory_web
                    ),
                ]),
            ];
        }

        return disabledExtras;
    };

    console.log(products[0].info.extras.map(console.log));

    return (
        <div className="product-extras">
            <Accordion className="product-extras__accordion" defaultActiveKey={products.length > 1 ? undefined : "0"}>
                {products.map((product, index) => {
                    // List available extras
                    // List disabled extras and exclude cancelled extras
                    const availableExtras = getAvailableExtras(product, product.info?.extras || []);
                    const disabledExtras = getDisabledExtras(product, product.info?.extras || []).filter(extra =>
                        isBooked(extra)
                    );

                    return (
                        <Card key={"card" + index}>
                            <AccordionButton eventKey={`${index}`}>
                                <div className="product-extras__accordion-toggle-text">
                                    {getPackageProductTitle(product)}
                                    <div className="product-extras__accordion-toggle-details mt-2">
                                        {showArrDepDates(product)}
                                        <br />
                                        {`${product.info.guests.totalGuests} ${
                                            product.info.guests.totalGuests === 1
                                                ? texts?.["generalguest"]
                                                : texts?.["generalguests"]
                                        }`}
                                    </div>
                                </div>
                            </AccordionButton>
                            <Accordion.Collapse eventKey={`${index}`}>
                                <Card.Body className="product-extras__accordion-content">
                                    {availableExtras.map(item => {
                                        return (
                                            <ProductExtrasItem
                                                data={item}
                                                accommodation={product}
                                                key={`extras-item-available-${item.id}-${item.extraslid}`}
                                                productId={product.id}
                                                texts={texts}
                                            />
                                        );
                                    })}
                                    {!!disabledExtras.length && (
                                        <>
                                            <p className=" info-text mt-5 mb-4">
                                                {texts["product_extras.change_reservation_disabled_extras"]}
                                            </p>
                                            <div className="soft-disabled">
                                                {disabledExtras.map(item => {
                                                    return (
                                                        <ProductExtrasItem
                                                            data={item}
                                                            accommodation={product}
                                                            key={`extras-item-disabled-${item.id}-${item.extraslid}`}
                                                            productId={product.id}
                                                            texts={texts}
                                                        />
                                                    );
                                                })}
                                            </div>
                                        </>
                                    )}
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    );
                })}
            </Accordion>
        </div>
    );
};

export default ProductExtras;
