import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { checkAuthenticationStatus, fetchReservation, setBookingDetailedView } from "../store/actions/account";
import { bootstrapClient } from "../store/actions/clientData";
import { clearAllReservationTypeResults } from "../store/actions/reservationResult";
import { resetLastSearchedTimestamps } from "../store/actions/search";
import { cancelReservation as cancelReservationAction } from "../store/actions/account";
import PageContainer from "../components/PageContainer";
import useAppDispatch from "../hooks/useAppDispatch";
import { TAccount, TCartItem, TGuest, TReservation, TReservationType, TUser } from "../store/types";
import { isCancelled, isParent } from "../BusinessUtils";
import { BookingSummaryAccomodation, BookingSummaryContactInfo } from "../components/BookingSummary";
import { formatPrice, hasAnyAccommodation } from "../Helper";
import { useBookingSummary } from "../hooks/useBookingSummary";
import * as Constants from "../Constants";

import {
    Button,
    Select,
    TSelectOption,
    Notification,
    Breadcrumbs,
    Heading,
    Checkbox,
    ChevronLeftIcon,
    ChevronRightIcon,
    ConfirmationModal,
    DownloadIcon,
    Tooltip,
} from "@r360/library";
import useTranslate from "../hooks/useTranslate";
import { GoToReservationType } from "../components/GoToReservationType/GoToReservationType";
import { BookingSummaryRow, BookingSummaryTable } from "../components/BookingSummary/BookingSummaryTable";
import { optionsSelector } from "../selectors/Selectors";
import { RichText } from "../components/UI";
import AxiosClient from "../API/AxiosClient";
import { AxiosRequestConfig } from "axios";
import { createCartFromReservation, emptyCartProducts } from "../store/actions/checkoutCart";
import moment from "moment";
import BookingSummaryCancellationButton from "../components/BookingSummary/BookingSummaryCancelProductButton";
import classNames from "classnames";
import { BookingSummarySkeleton } from "../components/BookingSummary/BookingSummarySkeleton";
import useAppSelector from "../hooks/useAppSelector";

const BookingPage = () => {
    const { resvid } = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const t = useTranslate();

    const account: TAccount = useAppSelector(state => state.account);
    const loggedInUser = account.user;
    const userToken = account.token;
    const isAgent = loggedInUser.isAgent;
    const reservations = account.reservations;
    const authenticated = account.loginSuccessful;
    const clientData = useAppSelector(state => state.clientData);
    const reservationTypes: TReservationType[] = clientData.reservationtypes;
    const showReservationAllowed = clientData.options.mypage.showreservation;
    const currency = clientData?.options?.general?.currency;
    const lang = useAppSelector(state => state.clientData?.lang);
    const detailedView = useAppSelector(state => state.account.bookingDetailedView);
    const { isMobile } = useAppSelector(state => state.window);
    const getinvoice = useAppSelector(optionsSelector).mypage.getinvoice;
    const myPageOnly = !!useAppSelector(optionsSelector).general?.mypage_only;
    const { requestsLoading } = useAppSelector(state => state.axiosStatus);
    const loadingReservation = requestsLoading["fetchReservation"];
    const cancellingReservation = requestsLoading["cancelReservation"];
    const createdFromReservation = useAppSelector(state => state.checkoutCart.createdFromReservation);
    const createCartInProgress = useAppSelector(
        state => state.axiosStatus.requestsLoading["createCartFromReservation"]
    );

    const [uniqueProductTypes, setUniqueProductTypes] = useState<(TReservationType | undefined)[]>([]);
    const [showCancelBookingModal, setShowCancelBookingModal] = useState(false);
    const [showPdfErrorModal, setShowPdfErrorModal] = useState(false);
    const [selectedReservationTypeLid, setSelectedReservationTypeLid] = useState<number | undefined>();
    const [didClickAddMoreProducts, setDidClickAddMoreProducts] = useState(false);
    const [renderedProducts, setRenderedProducts] = useState<number[]>([]);

    let booking: TReservation | undefined = undefined;

    // Find booking in state but we will always fetch a fresh booking from backend if we are authenticated and the page reloads.
    if (showReservationAllowed && resvid) {
        booking = reservations?.find(reservation => reservation.reservation?.resvid === resvid);
    } else {
        console.log("Reservation is not allowed to be viewed because mypage/showreservation is turned off");
    }

    const products = booking ? Object.values(booking.reservation.products) : ([] as TCartItem[]);
    const guests: TGuest[] = booking ? Object.values(booking.guests) : [];
    const reservationOkButNotPayed =
        booking?.reservationrules?.reservationok && booking?.reservationrules.totamounttopay > 0;
    const depDateHasPassed = booking?.depdate ? moment().isAfter(booking.depdate, "day") : true;
    const allowAddingMoreProducts = !depDateHasPassed && !myPageOnly && !booking?.cancelled;
    const showSkeleton = loadingReservation || createCartInProgress;
    const hasInvoice = booking?.reservation.hasinvoice;

    const productTypesToHide: string[] = [];
    const unRenderedProducts = products.filter(product => !renderedProducts.includes(product.id));

    products.forEach(product => {
        product.connectedProductId && productTypesToHide.push(product.type.toString());
    });

    const {
        filterByGuest,
        handleFilterByGuest,
        resetFilterByGuest,
        getProductTitle,
        getProductAdditions,
        getProductGuests,
        getProductAdditionalInfo,
        getProductTypeLocation,
        getProductDates,
        getProductPrice,
        isFilteredGuestConnected,
        isActivityWithDifferentGuestages,
        getProductCategoryPrice,
        getGuestAgeCategory,
    } = useBookingSummary(booking?.reservation.products ?? {});

    useEffect(() => {
        if (!reservationTypes.length) {
            dispatch(bootstrapClient());
        }
    }, [dispatch, reservationTypes.length]);

    // Check if authenticated
    useEffect(() => {
        dispatch(checkAuthenticationStatus(userToken));
    }, [dispatch, userToken]);

    // Always fetch current reservation from backend
    useEffect(() => {
        if (showReservationAllowed && authenticated) {
            const reservationPromise = dispatch(fetchReservation(userToken, resvid));

            reservationPromise.then(() => {
                // Clear search results
                dispatch(clearAllReservationTypeResults());
                dispatch(resetLastSearchedTimestamps());
            });
        }
    }, [dispatch, authenticated, userToken, resvid, showReservationAllowed]);

    useEffect(() => {
        if (didClickAddMoreProducts && createdFromReservation && selectedReservationTypeLid) {
            navigate(`/search/${selectedReservationTypeLid}`);
        }

        // TODO: R360ONL-741 Fix dependencies for useEffect related to searches.
        // eslint-disable-next-line
    }, [createdFromReservation, didClickAddMoreProducts, selectedReservationTypeLid]);

    useEffect(() => {
        booking?.cancelled && setShowCancelBookingModal(false);
    }, [booking?.cancelled]);

    useEffect(() => {
        if (booking?.reservation?.products) {
            const uniqueProductTypes = [
                ...new Set(
                    Object.values(booking?.reservation?.products).map(product =>
                        reservationTypes.find(resvtype => resvtype.lid === product.type)
                    )
                ),
            ].filter(uniqueProductType => uniqueProductType); // Make sure the value is truthy, otherwise no match was found above

            // Sort reservation types by their sort order, which is defined in r360
            uniqueProductTypes.sort((a, b) => {
                if (!a || !b) return 0;

                return a.sortorder - b.sortorder;
            });

            setUniqueProductTypes(uniqueProductTypes);
        }
        // TODO: R360ONL-741 Fix dependencies for useEffect related to searches.
        // eslint-disable-next-line
    }, [booking?.reservation?.products]);

    // Early exit if booking not found or no products exists in the booking
    if (!loadingReservation && (!booking || Object.values(booking?.reservation?.products).length === 0)) {
        return null;
    }

    const changeReservation = () => {
        const resvid = booking?.reservation?.resvid;

        if (!resvid || booking?.cancelled) {
            return;
        }

        dispatch(emptyCartProducts());
        dispatch(createCartFromReservation(resvid, userToken, reservationTypes, clientData?.cookie));
    };

    const cancelReservation = () => {
        const resvid = booking?.reservation?.resvid;

        if (!resvid) {
            return;
        }

        dispatch(emptyCartProducts());
        dispatch(cancelReservationAction(userToken, resvid));
        dispatch(createCartFromReservation(null, userToken, reservationTypes, clientData?.cookie));
    };

    const productTypesWithParentProducts = uniqueProductTypes
        .filter(productType => productType?.type !== Constants.productTypeNames.ACCOMMODATION)
        .filter(productType => productType?.lid && !productTypesToHide.includes(productType.lid.toString()))
        .map(productType => {
            return {
                lid: productType?.lid,
                title: productType?.description ?? "",
                products: products
                    .filter(product => product.type === productType?.lid)
                    .filter(product => isParent(product))
                    .sort((a, b) => (a.title === b.title ? 1 : -1)),
            };
        });

    const breadcrumbs = [
        ...(!myPageOnly
            ? [
                  {
                      title: t("book.products"),
                      path: "/",
                  },
              ]
            : []),
        {
            title: t("book.my_pages"),
            path: "/my-pages?bookings=true",
        },
        {
            title: `${t("book.my_pages.booking")} ${resvid}`,
            path: "",
        },
    ];

    const createAndDownloadBlobFile = (arrayBufferBody: any, filename: string) => {
        const blob = new Blob([arrayBufferBody]);
        const link = document.createElement("a");

        if (link.download !== undefined) {
            link.setAttribute("href", URL.createObjectURL(blob));
            link.setAttribute("download", filename);
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const fetchInvoice = (resvid: string) => {
        const config: AxiosRequestConfig = {
            headers: {
                Authorization: `Bearer ${userToken}`,
            },
            responseType: "arraybuffer",
        };

        const url = `/reservations/invoice/${resvid}`;

        AxiosClient.get(url, config)
            .then(response => {
                if (response.data instanceof ArrayBuffer) {
                    createAndDownloadBlobFile(response.data, `${resvid}.pdf`);
                }
            })
            .catch(e => {
                console.error(e);
                setShowPdfErrorModal(true);
            });
    };

    const handleAddMoreProducts = (resType: TReservationType) => {
        if (!booking?.cancelled) {
            setSelectedReservationTypeLid(resType.lid);
            setDidClickAddMoreProducts(true);
            changeReservation();
        }
    };

    const filterByGuestOptions: TSelectOption[] = [
        {
            label: t("book.general.show_all"),
            value: "showAll",
        },
        guests.map(guest => {
            return {
                label: `${guest?.firstname} ${guest?.lastname}`,
                value: guest.id,
            };
        }),
    ].flat();

    const renderFilterByGuestNotification = () => {
        return (
            <div className="u-mb-24">
                <Notification type="warning">
                    {t("book.checkout.filtering_on_guest")} {filterByGuest?.label}{" "}
                    <Button type="tertiary" onClick={resetFilterByGuest}>
                        {t("book.general.remove_filter")}
                    </Button>
                </Notification>
            </div>
        );
    };

    const renderDownloadAsPdfButton = () => {
        const resvid = booking?.reservation.resvid;

        if (!lang || !resvid || !getinvoice || !hasInvoice) {
            return null;
        }

        return (
            <Tooltip toolTipContent={"Kunde inte ladda ner pdf"}>
                <Button type="tertiary" rightIcon={<DownloadIcon />} onClick={() => fetchInvoice(resvid)}>
                    {t("book.booking.download_as_pdf")}
                </Button>
            </Tooltip>
        );
    };

    // Render price
    const renderTotalPrice = () => {
        const totalAmount = booking?.reservationrules.totalamount;
        const totalAmoundPaid = booking?.reservationrules.paidamount;
        const totalAmountToPay = booking?.reservationrules.totamounttopay;

        const renderPriceRow = (title: string, amount: number) => {
            return (
                <div>
                    <span className="u-fw-medium">{title}:</span>
                    <span> {formatPrice(amount, currency)}</span>
                </div>
            );
        };

        return (
            <div className="u-d-flex u-flex-column u-align-items-end u-mb-42" style={{ fontSize: 30 }}>
                {totalAmount !== undefined && renderPriceRow(t("book.general.total"), totalAmount)}
                {!hasInvoice &&
                    totalAmoundPaid !== undefined &&
                    renderPriceRow(t("book.checkout.amount_paid"), totalAmoundPaid)}
                {!hasInvoice &&
                    totalAmountToPay !== undefined &&
                    renderPriceRow(t("book.checkout.amount_to_pay"), totalAmountToPay)}
            </div>
        );
    };

    const handleSetRenderedProducts = (id: number) => {
        if (!renderedProducts.includes(id)) {
            setRenderedProducts([...renderedProducts, id]);
        }
    };

    return (
        <PageContainer>
            <>
                {showSkeleton && <BookingSummarySkeleton />}
                <div className={classNames({ "u-d-none": showSkeleton })}>
                    <Breadcrumbs breadcrumbs={breadcrumbs} />
                    <div className="u-mb-36 u-pt-lg-24">
                        <div className="u-mb-24">
                            <Notification type={"info"}>{t("book.booking.change_booking_info")}</Notification>
                        </div>
                        <div className="u-mb-36">
                            <BookingSummaryContactInfo
                                primaryGuest={loggedInUser}
                                responsibleGuest={loggedInUser as TUser}
                                guests={guests}
                                booking={booking}
                            />
                        </div>
                        {allowAddingMoreProducts && (
                            <>
                                <Heading type="h2" styleAs="h3" className="u-mb-18">
                                    {t("book.booking.add_more_products")}
                                </Heading>
                                <GoToReservationType customOnClick={handleAddMoreProducts} center={false} />
                            </>
                        )}
                    </div>
                    {filterByGuest && renderFilterByGuestNotification()}
                    <div className="u-mb-24 u-d-flex u-flex-column u-flex-lg-row u-align-items-lg-end u-gap-24">
                        {guests.length > 1 && (
                            <div style={{ minWidth: 250 }}>
                                <Select
                                    key={filterByGuest?.value}
                                    label={t("book.checkout.filter_on_guest")}
                                    type="standard"
                                    options={filterByGuestOptions}
                                    defaultValue={filterByGuest?.value}
                                    onSelectCallback={handleFilterByGuest}
                                    fullWidth
                                />
                            </div>
                        )}
                        <Checkbox
                            label={t("book.checkout.show_detailed_view")}
                            checked={detailedView}
                            onChange={() => dispatch(setBookingDetailedView(!detailedView))}
                        />
                    </div>

                    <div className="u-d-flex u-flex-column u-gap-30 u-mb-36">
                        {booking && hasAnyAccommodation(booking.reservation.products) && (
                            <BookingSummaryAccomodation
                                uniqueProductTypes={uniqueProductTypes}
                                filterByGuestId={filterByGuest?.value.toString() ?? ""}
                                booking={booking}
                                detailedView={detailedView}
                                createdFromReservation={createdFromReservation}
                                isAgent={isAgent}
                                handleSetRenderedProducts={handleSetRenderedProducts}
                            />
                        )}
                        {productTypesWithParentProducts
                            .filter(product => product.products.length > 0)
                            .map(productType => {
                                // Init the table totalprice as 0
                                let totalPrice = 0;
                                let showTable = true;

                                if (filterByGuest) {
                                    showTable = productType.products.some(isFilteredGuestConnected);
                                }

                                return (
                                    <BookingSummaryTable
                                        key={productType.title}
                                        title={productType.title ?? ""}
                                        productCount={productType.products.length}
                                        hidden={!showTable}
                                    >
                                        <>
                                            {productType.products.map(product => {
                                                const guests = getProductGuests(product);
                                                let price = 0,
                                                    title = "";

                                                const ordinaryPrice =
                                                    product.ordinaryprice > 0 ? getProductPrice(product, true) : 0;

                                                // If the product has different price categories, get the right price
                                                if (isActivityWithDifferentGuestages(product)) {
                                                    price = getProductCategoryPrice(product);
                                                    title = getProductTitle(
                                                        product,
                                                        getGuestAgeCategory(product)?.min,
                                                        getGuestAgeCategory(product)?.max
                                                    );
                                                } else {
                                                    price = getProductPrice(product);
                                                    title = getProductTitle(product);
                                                }

                                                totalPrice += price;

                                                handleSetRenderedProducts(product.id);

                                                if (product.groupedWith) {
                                                    handleSetRenderedProducts(product.groupedWith);
                                                }

                                                return (
                                                    <BookingSummaryRow
                                                        key={product.id}
                                                        title={title}
                                                        additions={getProductAdditions(product)}
                                                        phone={
                                                            product.phones
                                                                ? Object.values(product.phones)[0]
                                                                : undefined
                                                        }
                                                        guests={guests}
                                                        price={{
                                                            price,
                                                            ordinaryPrice:
                                                                ordinaryPrice > 0 ? ordinaryPrice : undefined,
                                                            campaign: !!product.promotionCode,
                                                            currency,
                                                        }}
                                                        isCancelled={isCancelled(product)}
                                                        cancellationBtn={
                                                            <BookingSummaryCancellationButton
                                                                resvid={booking?.reservation.resvid ?? ""}
                                                                detlid={product.detlid ?? parseInt("")}
                                                                productTitle={product.title}
                                                                allowCancellation={
                                                                    (!isAgent && booking?.allowcancellation) ?? false
                                                                }
                                                                loadingReservation={loadingReservation}
                                                            />
                                                        }
                                                        location={product.location}
                                                        dates={getProductDates(product)}
                                                        details={{
                                                            description: product.weblong ? (
                                                                <RichText content={product.weblong} />
                                                            ) : (
                                                                ""
                                                            ),
                                                            additionalInfo: getProductAdditionalInfo(product),
                                                        }}
                                                        showDetails={detailedView}
                                                        hidden={
                                                            filterByGuest ? !isFilteredGuestConnected(product) : false
                                                        }
                                                    />
                                                );
                                            })}
                                            {!filterByGuest && (
                                                <BookingSummaryRow
                                                    location={getProductTypeLocation(productType.products)}
                                                    price={{
                                                        price: totalPrice,
                                                        currency,
                                                        isTotalPrice: true,
                                                    }}
                                                />
                                            )}
                                        </>
                                    </BookingSummaryTable>
                                );
                            })}
                        {unRenderedProducts.length > 0 && (
                            <BookingSummaryTable
                                title={t("book.checkout.other_products")}
                                productCount={unRenderedProducts.length}
                            >
                                <>
                                    {unRenderedProducts.map(product => (
                                        <BookingSummaryRow
                                            key={product.id}
                                            title={product.title}
                                            quantity={product.quantity}
                                            additions={getProductAdditions(product)}
                                            onRenderAddition={addition =>
                                                addition.id && handleSetRenderedProducts(addition.id)
                                            }
                                            phone={product.phones ? Object.values(product.phones)[0] : undefined}
                                            guests={getProductGuests(product)}
                                            price={{
                                                price: getProductPrice(product),
                                                currency,
                                            }}
                                            isCancelled={isCancelled(product)}
                                            cancellationBtn={
                                                <BookingSummaryCancellationButton
                                                    resvid={booking?.reservation.resvid ?? ""}
                                                    detlid={product.detlid ?? parseInt("")}
                                                    productTitle={product.title}
                                                    allowCancellation={
                                                        (!isAgent && booking?.allowcancellation) ?? false
                                                    }
                                                    loadingReservation={loadingReservation}
                                                />
                                            }
                                            location={product.location}
                                            dates={getProductDates(product)}
                                            details={{
                                                description: product.weblong ? (
                                                    <RichText content={product.weblong} />
                                                ) : (
                                                    ""
                                                ),
                                                additionalInfo: getProductAdditionalInfo(product),
                                            }}
                                            showDetails={detailedView}
                                        />
                                    ))}
                                </>
                            </BookingSummaryTable>
                        )}
                    </div>
                    {!filterByGuest && renderTotalPrice()}
                    <div className="u-d-flex u-flex-column u-flex-md-row u-gap-18 u-flex-wrap u-justify-content-between">
                        <div>
                            <Button
                                type="secondary"
                                leftIcon={<ChevronLeftIcon />}
                                link
                                linkTo="/my-pages"
                                fullWidth={isMobile}
                            >
                                {t("book.my_pages")}
                            </Button>
                        </div>
                        <div className="u-d-flex u-flex-wrap u-gap-18">
                            {renderDownloadAsPdfButton()}
                            {booking?.allowcancellation && !isAgent && (
                                <Button
                                    type="danger"
                                    onClick={() => setShowCancelBookingModal(true)}
                                    fullWidth={isMobile}
                                >
                                    {t("book.my_pages.cancel")}
                                </Button>
                            )}
                            {reservationOkButNotPayed && (
                                <Button
                                    rightIcon={<ChevronRightIcon />}
                                    link
                                    linkTo={`/pay/${booking?.reservation?.resvid}/0/${booking?.reservation?.payerlid}`}
                                    fullWidth={isMobile}
                                >
                                    {t("book.checkout.proceed_to_payment")}
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
                <ConfirmationModal
                    open={showCancelBookingModal}
                    onClose={() => setShowCancelBookingModal(false)}
                    heading={t("book.booking.cancel_booking")}
                    confirmBtnText={t("book.general.continue")}
                    onConfirmClick={() => cancelReservation()}
                    confirmBtnLoading={cancellingReservation}
                    cancelBtnText={t("book.general.cancel")}
                    onCancelClick={() => setShowCancelBookingModal(false)}
                    removing
                />
                <ConfirmationModal
                    open={showPdfErrorModal}
                    onClose={() => setShowPdfErrorModal(false)}
                    heading="Problem att hämta pdf"
                    description="Ett problem uppstod när vi försökte hämta fakturan som PDF för din bokning. Försök igen senare och kontakta oss om problemet kvarstår."
                    confirmBtnText="Ok"
                    onConfirmClick={() => setShowPdfErrorModal(false)}
                    removing={false}
                />
            </>
        </PageContainer>
    );
};

export default BookingPage;
