import React, { useState } from "react";
import { Form, ListGroup } from "react-bootstrap";
import { useSelector } from "react-redux";
import { partPaymentType } from "../../Constants";
import { formatPrice } from "../../Helper";
import { Tag } from "../UI";
import "./PartPayment.scss";
import { textsSelector } from "../../Selectors";

const PartPayment = ({ value, maxAmountToPay, minAmountToPay, currency, onChange }) => {
    const texts = useSelector(textsSelector);

    const handleChange = value => {
        onChange?.(value);
    };

    if (isNaN(maxAmountToPay) || isNaN(minAmountToPay) || !currency) {
        return null;
    }

    return (
        <div className="part-payment">
            <h5 className="fw-light">{texts["payment.part_payment.title"]}</h5>

            <Form>
                <ListGroup>
                    <ListGroup.Item className="d-flex justify-content-between align-items-center">
                        <Form.Check
                            type="radio"
                            name="partpayment"
                            id="partPaymentFinalPayAmount"
                            value={partPaymentType.FINALPAYAMOUNT}
                            label={texts["payment.part_payment.pay_all"]}
                            checked={value === partPaymentType.FINALPAYAMOUNT}
                            onChange={({ target: { value } }) => {
                                handleChange(value);
                            }}
                        />
                        <Tag>{formatPrice(maxAmountToPay, currency)}</Tag>
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <div className="d-flex justify-content-between align-items-center">
                            <Form.Check
                                type="radio"
                                name="partpayment"
                                id="partPaymentPrepayAmount"
                                value={partPaymentType.PREPAYAMOUNT}
                                label={texts["payment.part_payment.pay_registration_fee"]}
                                checked={value === partPaymentType.PREPAYAMOUNT}
                                onChange={({ target: { value } }) => {
                                    handleChange(value);
                                }}
                            />
                            <Tag>{formatPrice(minAmountToPay, currency)}</Tag>
                        </div>
                        <div className="mt-3 fw-light" style={{ fontSize: "0.85rem" }}>
                            {texts["payment.part_payment.remaining_amount"].replace(
                                "%amount%",
                                formatPrice(maxAmountToPay - minAmountToPay, currency)
                            )}
                        </div>
                    </ListGroup.Item>
                </ListGroup>
            </Form>
        </div>
    );
};

export default PartPayment;
