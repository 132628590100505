import { useCallback } from "react";
import useTranslate from "../../../hooks/useTranslate";
import { EItemBookableStatus } from "../../AccommodationOption/AccommodationOption";
import moment from "moment";
import { TCartItem, TItem } from "../../../store/types";
import React from "react";
import { Button, CalendarCheckIcon, HouseCrackedIcon, UserTwoIcon, style } from "@r360/library";
import { formatPrice } from "../../../Helper";
import "./AvailableAccommodationItem.scss";
import useAppSelector from "../../../hooks/useAppSelector";
import { RootState } from "../../..";
import { getInspectionDataForProduct } from "../../../BusinessUtils";

type TOptionRow = {
    option: TItem;
    hasPackages: boolean;
    guestCount: number;
    arrDate: string;
    depDate: string;
    onBooking: () => void;
    accommodationCartProducts: TCartItem[];
};

const OptionRow = ({
    option,
    hasPackages,
    guestCount,
    arrDate,
    depDate,
    onBooking,
    accommodationCartProducts,
}: TOptionRow) => {
    const t = useTranslate();
    const loading = useAppSelector((state: RootState) => state.axiosStatus.loading);

    const getItemBookableStatus = useCallback((): EItemBookableStatus => {
        const itemArrDate = moment(option.arrdate);
        const itemDepDate = moment(option.depdate);

        // If pool item, always allow booking.
        if (!option.unitlid) {
            return EItemBookableStatus.BOOKABLE;
        }

        const matchedUnitsInCart = accommodationCartProducts.filter(
            (cartProduct: TCartItem) => cartProduct.poollid === option.poollid && cartProduct.unitlid === option.unitlid
        );

        const hasDateConflict = matchedUnitsInCart.some((cartProduct: TCartItem) => {
            const cartProductArrDate = moment(cartProduct.arrdate);
            const cartProductDepDate = moment(cartProduct.depdate);

            return (
                itemArrDate.isBetween(cartProductArrDate, cartProductDepDate, undefined, "[)") ||
                itemDepDate.isBetween(cartProductArrDate, cartProductDepDate, undefined, "(]")
            );
        });

        return hasDateConflict ? EItemBookableStatus.ALREADY_IN_CART : EItemBookableStatus.BOOKABLE;
    }, [accommodationCartProducts, option.arrdate, option.depdate, option.poollid, option.unitlid]);

    const itemBookableStatus = getItemBookableStatus();

    const secondaryTitle = option.pricetext
        ? option.pricetext
        : hasPackages
        ? t("product_availability.accommodation_only")
        : "";

    return (
        <div className="option-item-wrapper" {...getInspectionDataForProduct(option)}>
            <div className="col-12 col-lg-4 option-item-wrapper__option-column">
                <HouseCrackedIcon className="option-item-wrapper__option-icon" color={style.brandBlueColor} />
                <span>{secondaryTitle}</span>
            </div>
            <div className="col-12 col-lg-3 option-item-wrapper__option-column">
                <CalendarCheckIcon className="option-item-wrapper__option-icon" color={style.brandBlueColor} />
                <span>{arrDate} – </span>
                <span>{depDate}</span>
            </div>
            <div className="col-12 col-lg-1 option-item-wrapper__option-column">
                <UserTwoIcon className="option-item-wrapper__option-icon" color={style.brandBlueColor} />
                <span>{guestCount}</span>
            </div>
            <div className="col-12 col-lg-2 option-item-wrapper__option-column-price">
                <p>{`${formatPrice(option.price, option.curr)}`} </p>
            </div>
            <div className="col-12 col-lg-2 option-item-wrapper__option-column-button">
                <Button
                    buttonSize="small"
                    buttonClassName="option-item-wrapper__option-book-button"
                    onClick={() => !loading && onBooking()}
                    loading={loading}
                >
                    {itemBookableStatus === EItemBookableStatus.BOOKABLE && t("book.general.book")}
                    {itemBookableStatus === EItemBookableStatus.ALREADY_IN_CART && t("product.added_to_cart")}
                </Button>
            </div>
        </div>
    );
};

export default OptionRow;
