import { Tag } from "../UI";
import React from "react";
import "./ItemCapacity.scss";

const ItemCapacity = props => {
    const { available, texts } = props;
    return (
        <Tag type="small">
            <div className="capacity-info">
                {available} {texts?.capacityleft}
            </div>
        </Tag>
    );
};

export default ItemCapacity;
