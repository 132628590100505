import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import OrderOverview from "../../components/OrderOverview/OrderOverview";
import { Modal } from "../../components/UI";
import { _ } from "../../Helper";

const TestCom = () => {
    const reservationTypes = useSelector(state => state.clientData.reservationtypes);
    const cartProducts = window.state ? window.state.state.products : [];
    // console.log("HERE", cartProducts);

    useEffect(() => {
        // console.log("HEJ", window.state?.state.products);
    }, [cartProducts]);

    return null;
};

const OrderOverviewPage = () => {
    return <Modal show={true} component={TestCom}></Modal>;
};

export default OrderOverviewPage;
