import React, { useState } from "react";
import Icon from "../Icon/Icon";
import PropTypes from "prop-types";
import "./Heart.scss";

const Heart = props => {
    const [favorite, setfavorite] = useState(props.liked);

    let heartType = "FaHeart";
    if (favorite) {
        heartType = "FaRegHeart";
    }

    return (
        <button
            className="btn"
            aria-label={`Favorit-markera ${props.likeText}`}
            aria-pressed={!favorite}
            onClick={event => {
                setfavorite(!favorite);
                props.clicked(favorite);
                event.stopPropagation();
            }}
        >
            <Icon size={23} color="#d75a4a" name={heartType} />
        </button>
    );
};

Heart.propTypes = {
    liked: PropTypes.bool.isRequired,
    clicked: PropTypes.func.isRequired,
};

export default Heart;
