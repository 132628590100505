import React from "react";
import CartItemsList from "../CartItemsList";
import CartSummary from "../CartSummary";
import "./CartPageContent.scss";
import useAppSelector from "../../../hooks/useAppSelector";
import { Divider, Heading, LightBulbIcon, style } from "@r360/library";
import { cartProductsSelector } from "../../../selectors/Selectors";
import {
    accommodationHasNewConnectedProducts,
    accommodationHasNewOrModifiedExtras,
    isAccommodation,
    isBooked,
    isSkipass,
    setAdditionalOnBookedAccommodationProductExtras,
} from "../../../BusinessUtils";
import { CartItemChangeAccommodation } from "../CartItemsList/CartItemChangeAccommodation/CartItemChangeAccommodation";
import useTranslate from "../../../hooks/useTranslate";
import { GoToReservationType } from "../../GoToReservationType/GoToReservationType";

export const CartPageContent = () => {
    const t = useTranslate();

    const cartProducts = useAppSelector(cartProductsSelector).map(product =>
        setAdditionalOnBookedAccommodationProductExtras(product)
    );
    const bookedAccommodationsWithoutChanges = cartProducts.filter(
        product =>
            isAccommodation(product) &&
            isBooked(product) &&
            !accommodationHasNewOrModifiedExtras(product) &&
            !accommodationHasNewConnectedProducts(cartProducts, product)
    );

    const totalGuestsInAccommodation: number = cartProducts
        .filter(product => isAccommodation(product))
        .map(p => p.info.guests.totalGuests)
        .reduce((a, b) => a + b, 0);

    const totalNumberOfSkipasses = cartProducts
        .filter(product => isSkipass(product))
        .map(p => p.count)
        .reduce((a, b) => a + b, 0);

    const renderBookedAccommodationsWithoutChanges = () => (
        <div>
            <Heading style={{ paddingBottom: "4px" }} type="h5">
                {t("book.cart.changeadditions")}
            </Heading>
            {bookedAccommodationsWithoutChanges.map((product, index) => (
                <CartItemChangeAccommodation key={index} item={product} />
            ))}
        </div>
    );

    return (
        <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
            {cartProducts.length > 0 ? (
                <>
                    {bookedAccommodationsWithoutChanges.length > 0 && renderBookedAccommodationsWithoutChanges()}
                    <div className="cart-page-content">
                        <CartItemsList />
                        <div className="u-d-flex u-flex-column" style={{ flex: "1 1" }}>
                            <CartSummary />
                            <Divider className="u-mt-18 u-mb-18" />
                            <p className="u-fw-medium">{t("book.cart.missed_something")}</p>
                            {totalGuestsInAccommodation > 0 &&
                                totalNumberOfSkipasses > 0 &&
                                totalGuestsInAccommodation > totalNumberOfSkipasses && (
                                    <div className="u-d-flex">
                                        <LightBulbIcon color={style.yellowColor} size={20} className="u-mr-6" />
                                        <p className="u-fs-12">
                                            <i>
                                                {t("book.cart.missed_something_description", [
                                                    totalGuestsInAccommodation,
                                                    totalNumberOfSkipasses,
                                                ])}
                                            </i>
                                        </p>
                                    </div>
                                )}
                            <GoToReservationType center inCart />
                        </div>
                    </div>
                </>
            ) : (
                <div>
                    <Heading type="h5" style={{ textAlign: "center" }}>
                        {t("book.cart.empty")}
                    </Heading>
                </div>
            )}
        </div>
    );
};
