import React from "react";
import { useSelector } from "react-redux";
import BikeRentalView from "../components/BikeRentalView/OldBikeRentalView";
import BikeFilterPage from "./BikeFilterPage";
import { textsSelector } from "../Selectors";

const BikeRentalPage = props => {
    const cartProducts = useSelector(state => state.checkoutCart.products);
    const texts = useSelector(textsSelector);
    const reservationTypes = useSelector(state => state.clientData.reservationtypes);
    const theReservationType = reservationTypes.find(type => {
        return parseInt(type.lid, 10) === parseInt(props.reservationType, 10);
    });

    return (
        <>
            {/* <Button className="button--text-only back-link" to={"/"}>
                    <Icon name="FaArrowLeft" color="#0d3e60" size={16} />
                    <span className="back-link__text">{texts?.toresultpage}</span>
                </Button> */}

            <div className="row">
                <div className="col-md-12 col-lg-9">
                    <header className="reservationtype__page-header">
                        {/* <h1 className="page-header">{theReservationType.description}</h1> */}
                        <BikeFilterPage reservationType={props.reservationType} />
                    </header>

                    <div className="row px-3">
                        <div className="col-md-8"></div>
                        {/* <Form.Control
                value={bikeTypeFilter}
                as="select"
                className="col-md-4"
                name="guestId"
                onChange={(e) => handleTypeChange(e.target.value)}
              >
                <option value={"ALL"}>Cyklar & tillbehör</option>
                <option value={bikeTypeFilters.BIKES_ONLY}>Endast cyklar</option>
                <option value={bikeTypeFilters.OPTIONALS_ONLY}>Endast tillbehör</option>
              </Form.Control> */}
                    </div>
                    <div></div>
                    <BikeRentalView
                        texts={texts}
                        bikeTypeFilter="ALL"
                        cartProducts={cartProducts}
                        location={props.location}
                        reservationType={theReservationType.lid}
                    />
                </div>
                {/* <div className="col-md-12 col-lg-3">
                        <OrderOverview
                            showButtons
                            products={cartProducts}
                            reservationTypes={reservationTypes}
                            activeResvLid={props.reservationType}
                        />
                    </div> */}
            </div>
        </>
    );
};

export default BikeRentalPage;
