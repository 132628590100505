import React, { useState } from "react";
import { productTypeNames } from "../../Constants";
import { TReservationType } from "../../store/types";
import "./SearchReservationTypes.scss";
import { RichText } from "../UI";
import useTranslate from "../../hooks/useTranslate";
import { Notification } from "@r360/library";
import {
    SkipassPage,
    ActivitiesPage,
    LettingPage,
    BikeRentalPage,
    AccommodationResultPage,
    PackageResultPage,
} from "../../pages";
import PageContainer from "../PageContainer";
import useAppSelector from "../../hooks/useAppSelector";
import { userSelector } from "../../selectors/Selectors";
import useCustomTranslate from "../../hooks/useCustomTranslate";

const ActiveReservationType = ({ activeReservationType }: { activeReservationType: TReservationType }) => {
    switch (activeReservationType.type) {
        case productTypeNames.SKIPASS: {
            return <SkipassPage reservationTypeid={activeReservationType.lid} />;
        }
        case productTypeNames.ACTIVITY: {
            return <ActivitiesPage reservationTypeid={activeReservationType.lid} />;
        }
        case productTypeNames.LETTING: {
            return <LettingPage reservationTypeid={activeReservationType.lid} />;
        }
        case productTypeNames.RENTAL: {
            return <BikeRentalPage reservationTypeid={activeReservationType.lid} />;
        }
        case productTypeNames.ACCOMMODATION: {
            return <AccommodationResultPage reservationTypeid={activeReservationType.lid} />;
        }
        case productTypeNames.PACKAGE: {
            return <PackageResultPage reservationTypeid={activeReservationType.lid} />;
        }
        default: {
            return <div>Innehåll för ${activeReservationType.description}</div>;
        }
    }
};
export const SearchReservationTypes = ({ activeReservationType }: { activeReservationType: TReservationType }) => {
    const t = useTranslate();
    const ct = useCustomTranslate();
    const isOwner = !!useAppSelector(userSelector)?.isOwner;
    const [closedNotifications, setClosedNotifications] = useState<number[]>([]);
    const reservationHeaderText = isOwner
        ? t("book.header.owner_information")
        : activeReservationType.useGeneralInfo
        ? ct(`reservation_type:${activeReservationType.lid}:general_info`)
        : t("book.header.information");

    const handleCloseNotification = () => {
        // Use timeout to await close-animation of Notification.
        setTimeout(() => setClosedNotifications?.([...closedNotifications, activeReservationType.lid]), 1000);
    };

    return (
        <div className="search-reservation-types">
            <PageContainer>
                {reservationHeaderText && (
                    <div className="search-reservation-types__notification">
                        {!closedNotifications.includes(activeReservationType.lid) && (
                            <Notification
                                type="info"
                                key={activeReservationType.lid} // key is required to force re-render/height-calculation of Notification when resvtype changes.
                                onClose={handleCloseNotification}
                            >
                                <RichText content={reservationHeaderText} />
                            </Notification>
                        )}
                    </div>
                )}
                {activeReservationType ? <ActiveReservationType activeReservationType={activeReservationType} /> : null}
            </PageContainer>
        </div>
    );
};
