import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import InputRange from "react-input-range";
import debounce from "lodash/debounce";
import { RangePropType } from "../../../PropTypes";
import { roundBy } from "../../../Helper";

import "./InputRangeSlider.scss";
import classNames from "classnames";

// Input range component
const InputRangeSlider = props => {
    let { min, max, labelFormatter } = props;
    const {
        multiple = false,
        step = 1,
        name = "",
        label = "",
        value,
        onChange, // when value is changed, when dragging is done
        onInput, // when value is updated directly
        hideLabel,
        disabled = false,
    } = props;

    // Initialize state with normalized values
    const initialState = multiple
        ? {
              min: value.min,
              max: value.max,
          }
        : value;
    const [internalValue, setInternalValue] = useState(initialState);

    // Update state from props when props are updated.
    useEffect(() => {
        setInternalValue(value);
    }, [value]);

    if (!labelFormatter) {
        // Use a default formatter used to return a formatted value as a string
        labelFormatter = value => `${value}`;
    }

    // Create this function once to properly use a debounced function inside a functional component
    // Source: https://rajeshnaroth.medium.com/using-throttle-and-debounce-in-a-react-function-component-5489fc3461b3
    // eslint-disable-next-line
    const debouncedOnChange = useCallback(
        debounce(input => {
            onChange?.(input);
        }, 500),
        []
    );

    const handleChange = input => {
        // Bugfix: react-input-range v1.3.0 may have min/max values out of range.
        // Reproduce without fix: Click on drag handle at the end to adjust max value, current value becomes larger than max.
        // https://github.com/davidchin/react-input-range/issues/116
        let newInput;

        if (multiple) {
            newInput = {
                min: Math.max(roundBy(input.min), min),
                max: Math.min(roundBy(input.max), max),
            };
        } else {
            newInput = roundBy(input);
        }

        // setInternalValue(newInput);
        setInternalValue(newInput);

        // Updates directly
        onInput?.({ key: name, value: newInput });

        // Changes when use has stopped dragging
        if (!multiple || newInput !== input) {
            debouncedOnChange({ key: name, value: newInput });
        }
    };
    const wrapperClass = classNames("InputRangeSlider", { hideLabel });

    return (
        <div className={wrapperClass}>
            {label && <label htmlFor={name}>{label}</label>}

            <InputRange
                draggableTrack={true}
                id={name}
                name={name}
                maxValue={max}
                minValue={min}
                step={step}
                value={internalValue}
                formatLabel={labelFormatter}
                onChange={handleChange}
                disabled={disabled}
            />
        </div>
    );
};

InputRangeSlider.propTypes = {
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    value: RangePropType.isRequired,
    step: PropTypes.number,
    multiple: PropTypes.bool,
    label: PropTypes.string,
    labelFormatter: PropTypes.func,
    onChange: PropTypes.func,
    onInput: PropTypes.func,
    hideLabel: PropTypes.bool,
    disabled: PropTypes.bool,
};
export default InputRangeSlider;
