import { Button, Dropdown, TDropDownHandle } from "@r360/library";
import sum from "lodash/sum";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import AxiosClient from "../../../../API/AxiosClient";
import useAppSelector from "../../../../hooks/useAppSelector";
import useMemoizeArg from "../../../../hooks/useMemoizeArg";
import useTranslate from "../../../../hooks/useTranslate";
import { addProduct, addProductCount, removeProduct } from "../../../../store/actions/checkoutCart";
import { TCartItem, TGroup } from "../../../../store/types";
import { TAge } from "../../../MenuSearch/MenuSearch";
import ConfirmationDialog from "../../../Utilities/ConfirmationDialog";
import "./CartItem.scss";
import { getFancyCartItemPrice } from "../../../../Helper";
import isEqual from "lodash/isEqual";
import { cartProductsSelector } from "../../../../selectors/Selectors";
import sumBy from "lodash/sumBy";
import { GuestsPopover } from "../../../Guests/Guests";

type TAccommodationGuestsComponent = {
    cartItem: TCartItem;
    onCancel: () => void;
    handleChangeExtrasItem: (arg: TCartItem) => void;
};

export const AccommodationGuests = ({ cartItem, onCancel, handleChangeExtrasItem }: TAccommodationGuestsComponent) => {
    const t = useTranslate();
    const dispatch = useDispatch();
    const guestsDropdownRef = useRef<TDropDownHandle>(null);
    const [requiredAges, setRequiredAges] = useState<{ [key: string]: string[] }>({});
    const [guestsField, setGuestsField] = useState<{ [key: string]: number }>({});
    const [totalGuestsCount, setTotalGuestsCount] = useState<number | undefined>();
    const generalAges = JSON.parse(useAppSelector(state => state.clientData).options?.general?.ages);
    const loading = useAppSelector(state => state.axiosStatus.loading);
    const cartProducts = useAppSelector(cartProductsSelector);

    useEffect(() => {
        setInitialGuestsData();
    }, []);

    useEffect(() => {
        setTotalGuestsCount(sum(Object.values(guestsField)));
    }, [useMemoizeArg(guestsField)]);

    const setInitialGuestsData = () => {
        if (!generalAges?.[cartItem.type]) {
            return;
        }

        const ages = generalAges[cartItem.type];
        const guests: { [key: string]: number } = {};
        const requiredAges: { [key: string]: string[] } = {};
        const guestAges = Object.values(cartItem.guestages);

        ages?.forEach((age: TAge) => {
            const guestCount = guestAges.filter(
                (guestAge: number) => guestAge >= age.min && guestAge <= age.max
            ).length;

            guests[age.key] = age.minCount !== undefined && age.minCount > guestCount ? age.minCount : guestCount;

            if (age.requiresAge) {
                requiredAges[age.key] =
                    guestAges
                        .filter((guestAge: number) => guestAge >= age.min && guestAge <= age.max)
                        .map(guestAge => guestAge.toString()) || [];
            }
        });

        setGuestsField(guests);
        setRequiredAges(requiredAges);
    };

    const handleGuestChange = (count: number, data: TAge) => {
        !loading && setGuestsField({ ...guestsField, [data.key]: Math.max(count, 0) });
    };

    const closeGuests = () => {
        if (guestsDropdownRef.current) {
            guestsDropdownRef.current.setRefIsOpen();
        }
    };

    const getGuestAgesArray = () => {
        if (!generalAges?.[cartItem.type]) {
            return;
        }

        const ages = generalAges[cartItem.type];

        const guestAgesArray = (ages || [])
            .filter((age: TAge) => guestsField[age.key] > 0)
            .map((age: TAge) =>
                [...Array(guestsField[age.key])].map((_e, i) => {
                    return age.requiresAge && requiredAges[age.key] ? parseInt(requiredAges[age.key][i]) : age.value;
                })
            )
            .flat();

        return guestAgesArray;
    };

    const handleUpdateGuests = async () => {
        const guestAgesArray = getGuestAgesArray();

        if (!guestAgesArray) {
            return;
        }

        const searchParams = {
            resvtype: cartItem.type,
            grouppoollid: cartItem.grouppoollid,
            startdate: cartItem.arrdate,
            enddate: cartItem.depdate,
            ages: guestAgesArray,
            promoCode: cartItem.promotionCode || "",
        };

        const searchResult = await AxiosClient.get(`/search`, { params: searchParams });

        // Find accommodation matching cartItem in the search result.
        // If found, update the cart item with new data from the search result.´
        const matchingAccommodation = Object.values(
            (searchResult.data.payload?.groups || {}) as { [key: string]: TGroup }
        )
            .flatMap(group => group.items)
            .find(
                item =>
                    item.grouppoollid === cartItem.grouppoollid &&
                    item.poollid === cartItem.poollid &&
                    item.unitlid === cartItem.unitlid &&
                    item.pricetext === cartItem.pricetext
            );

        if (matchingAccommodation) {
            // Replace existing cart item with the new.
            // Transfer the following parameters to the new item:
            // - extras, keep the extras.
            // - groupTile
            // - cartItemId, there can be connected products pointing to this ID. E.g. skipass offers.
            // - cartItemAddedTimestamp, keep the current sort order of the cart.
            const newCartItem = {
                ...matchingAccommodation,
                groupTitle: cartItem.groupTitle,
                info: {
                    guests: {
                        totalGuests: totalGuestsCount,
                    },
                    extras:
                        cartItem.info.extras.map(extra => {
                            extra.parent = matchingAccommodation.id;
                            return extra;
                        }) ?? [],
                },
                cartItemId: cartItem.cartItemId,
                cartItemAddedTimestamp: cartItem.cartItemAddedTimestamp,
            } as TCartItem;

            Object.entries(guestsField).forEach(([key, age]) => {
                if (age > 0) {
                    newCartItem.info.guests[key] = age;
                }
            });

            // If there exists connected products for the current cart item, copy them to be added again
            // since the removal of the current cart item will auto-remove connected products.
            const connectedProducts = cartProducts.filter(
                product => product.connectedProductId === cartItem.cartItemId
            );

            dispatch(removeProduct(cartItem));
            dispatch(addProduct(newCartItem));

            //! Since connected skipasses limit is now based on the capac of the accomodation we no longer have to deal with connected products exceeding number of guests
            // let requireManualAddOfConnectedProducts = false;

            // Add eventual connected products again.
            if (connectedProducts.length > 0) {
                // const connectedProductsCountSum = sumBy(connectedProducts, i => i.count);

                connectedProducts.forEach(connectedProduct => {
                    dispatch(addProductCount({ ...connectedProduct }, connectedProduct.count));
                });

                // The total count of the connected products can't exceed the number of guests on the accommodation.
                // If so, don't add connected products again and inform customer that they have to add them again.
                // if (connectedProductsCountSum <= newCartItem.info.guests.totalGuests) {
                //     connectedProducts.forEach(connectedProduct => {
                //         dispatch(addProductCount({ ...connectedProduct }, connectedProduct.count));
                //     });
                // } else {
                //     // Connected products will not be added and requires manual action form the customer.
                //     requireManualAddOfConnectedProducts = true;
                // }
            }

            onCancel();

            let description = "";

            if (cartItem.price !== newCartItem.price) {
                description +=
                    t("book.confirmations.accommodation_guests_update_success.description_price", [
                        getFancyCartItemPrice(cartItem, cartItem.curr, generalAges),
                        getFancyCartItemPrice(newCartItem, newCartItem.curr, generalAges),
                    ]) + " ";
            }

            description += t("book.confirmations.accommodation_guests_update_success.description_extras");

            // if (requireManualAddOfConnectedProducts) {
            //     description +=
            //         "\n\n" +
            //         t("book.confirmations.accommodation_guests_update_success.description_connected_products_removed");
            // }

            const response = await ConfirmationDialog({
                heading: t("book.confirmations.accommodation_guests_update_success.title"),
                description: description,
                confirmBtnText: t("book.confirmations.accommodation_guests_update_success.ok"),
                cancelBtnText: "",
                // cancelBtnText: requireManualAddOfConnectedProducts ? t("book.cart.accommodation.change_extras") : "",
            });

            if (!response) {
                // if (requireManualAddOfConnectedProducts) {
                //     handleChangeExtrasItem(newCartItem);
                // }
            }
        } else {
            setInitialGuestsData();

            const response = await ConfirmationDialog({
                heading: t("book.confirmations.accommodation_guests_update_capacity_error.title"),
                description: t("book.confirmations.accommodation_guests_update_capacity_error.description"),
                confirmBtnText: t("book.confirmations.accommodation_guests_update_capacity_error.ok"),
                cancelBtnText: t("book.confirmations.accommodation_guests_update_capacity_error.cancel"),
                removing: true,
            });

            if (response) {
                dispatch(removeProduct(cartItem));
            }
        }
    };

    const enableUpdateGuestsButton = () => {
        const guestAgesArray = getGuestAgesArray();
        const requiredAgesIncomplete = Object.values(requiredAges).some(age => age.includes("undefined"));
        const agesHasChanged = guestAgesArray && !isEqual(guestAgesArray, Object.values(cartItem.guestages));

        return !requiredAgesIncomplete && agesHasChanged;
    };

    return (
        <div className="accommodation-guests">
            {totalGuestsCount !== undefined && (
                <>
                    <Dropdown
                        fullWidth
                        ref={guestsDropdownRef}
                        value={
                            totalGuestsCount <= 1
                                ? `${totalGuestsCount} ${t("book.general.guest")}`
                                : `${totalGuestsCount} ${t("book.general.guests")}`
                        }
                        onDarkBackground={false}
                        isValid={!Object.values(requiredAges).flat().includes("undefined")}
                    >
                        <GuestsPopover
                            resvType={cartItem.type}
                            filters={guestsField}
                            onChange={handleGuestChange}
                            onReset={setInitialGuestsData}
                            onDone={closeGuests}
                            requiredAges={requiredAges}
                            setRequiredAges={setRequiredAges}
                            maxCount={cartItem.capac}
                        />
                    </Dropdown>
                    <Button onClick={handleUpdateGuests} disabled={!enableUpdateGuestsButton()} loading={loading}>
                        {t("book.general.update")}
                    </Button>
                    <Button type="secondary" onClick={onCancel} disabled={loading}>
                        {t("book.general.cancel")}
                    </Button>
                </>
            )}
        </div>
    );
};
