import { productTypeNames } from "../Constants";
import { RootState } from "../index";
import { TCartItem, TGroup, TGuest, TGuestsOnTravelReducer, TItem, TLanguage, TReservationType } from "../store/types";
import * as Constants from "../Constants";
import moment from "moment-timezone";

/**
 * Get general ages and allow to fetch ages by a given reservation type lid
 *
 * @param {object} state
 * @param {number} resvtypelid (optional)
 * @returns {object|array}
 */
export const agesSelector = (state: RootState, resvtypelid?: number) => {
    const ages = JSON.parse(state.clientData.options?.general?.ages);

    if (!ages) {
        return;
    }

    if (resvtypelid) {
        if (resvtypelid in ages) {
            return ages[resvtypelid]; // array
        } else {
            throw new Error(`Reservation type ${resvtypelid} does not have any ages configured in general ages`);
        }
    }

    return ages; // hash map
};

export const resvTypeByLid = (state: RootState, typelid: number): TReservationType | undefined => {
    return state.clientData?.reservationtypes?.find((resvtype: TReservationType) => resvtype.lid === typelid);
};

/**
 * Usage: (state, 'ACCOMMODATION') => [500, 1000, ...]
 *
 * Example:
 * const accommodationLids = useSelector(
 *   (state) => resvTypeLidsByTypeSelector(state, Constants.productTypeNames.ACCOMMODATION)
 * );
 *
 * @param {object} state
 * @param {string} type
 * @return {array}
 */
export const resvTypeLidsByTypeSelector = (state: RootState, type: string) => {
    const lids = state.clientData?.reservationtypes
        ?.filter((resvtype: TReservationType) => {
            return resvtype.type === type;
        })
        .map((resvtype: TReservationType) => resvtype.lid);

    if (!lids) {
        return [];
    }

    return lids;
};

/**
 * Get reservation types by specified type.
 * We may have several reservation types of type ACCOMMODATION like "Boende" and "Stugby" and "Camping" and "Hotel"
 *
 * @param {object} state
 * @param {string} type
 * @returns {array}
 */
export const reservationTypesByTypeSelector = (state: RootState, type: string) => {
    const resvtypes = state.clientData?.reservationtypes?.filter((resvtype: TReservationType) => {
        return resvtype.type === type;
    });

    if (!resvtypes) {
        return [];
    }

    return resvtypes;
};

export const guestsOnTravelSelector = (state: RootState) => {
    return Object.entries<TGuest>(state.guests.guests).reduce<TGuestsOnTravelReducer>(
        (acc, [guestId, guest]: [string, TGuest]) => {
            // Don't include primary guest if booking for others
            // if (state.checkoutCart.bookingForOthers && guest.primary) {
            //     return acc;
            // }

            if (guest.ontravel) {
                acc[guestId] = guest;
            }

            return acc;
        },
        {}
    );
};

export const optionsSelector = (state: RootState) => state.clientData?.options || {};

export const cartCreatedFromReservationSelector = (state: RootState) => !!state.checkoutCart?.createdFromReservation;

// export const notificationsCheckSelector = (state: RootState, type, data) => {
//     // Check if all the values in the provided data matches with the data in the notification.
//     const compareData = (needleData, haystackData) =>
//         Object.entries(needleData)
//             .map(([key, value]) => haystackData[key] === value)
//             .every(check => check === true);

//     return !!state.notifications.find(
//         notification => notification.type === type && compareData(data, notification.data)
//     );
// };

/**
 * Get available languages from clientData state.
 *
 * @param {object} state
 * @returns A list of available languages.
 */
export const languagesSelector = (state: RootState) =>
    state.clientData.languages?.filter((lang: TLanguage) => lang.visible) || [];

export const activeReservationTypeSelector = (state: RootState, routeParamsId: string | undefined) => {
    const reservationTypes = state.clientData.reservationtypes.filter((type: TReservationType) => !type.hidden);
    return routeParamsId && reservationTypes.find((type: TReservationType) => type.lid === parseInt(routeParamsId, 10));
};

export const cartProductsSelector = (state: RootState): TCartItem[] => state.checkoutCart.products || [];

export const NewDesignReservationTypeSelector = (state: RootState, resvtype: string): boolean => {
    switch (resvtype) {
        case productTypeNames.ACCOMMODATION: {
            return state.clientData?.options.layout?.new_design_accommodation;
        }
        case productTypeNames.ACTIVITY: {
            return state.clientData?.options.layout?.new_design_activity;
        }
        case productTypeNames.LETTING: {
            return state.clientData?.options.layout?.new_design_letting;
        }
        case productTypeNames.RENTAL: {
            return state.clientData?.options.layout?.new_design_rental;
        }
        case productTypeNames.SKIPASS: {
            return state.clientData?.options.layout?.new_design_skipass;
        }
        case productTypeNames.PACKAGE: {
            return state.clientData?.options.layout?.new_design_package;
        }
        default:
            return false;
    }
};

export const userSelector = (state: RootState) => {
    return state.account?.user;
};

export const matchingSkipassExtraGroupForAccommodationSelector = (
    state: RootState,
    accommodation: TItem
): TGroup | undefined => {
    const accommodationReservationType = resvTypeByLid(state, accommodation.type);

    if (
        !accommodationReservationType ||
        accommodationReservationType.type !== Constants.productTypeNames.ACCOMMODATION ||
        !accommodationReservationType?.useSkipassExtra ||
        !accommodationReservationType.skipassExtraReservationTypeId
    ) {
        return undefined;
    }

    const skipassExtraResult = state.reservationResult?.[accommodationReservationType.skipassExtraReservationTypeId];

    if (!skipassExtraResult) {
        return undefined;
    }

    const skipassExtrasSearchArrdate = moment(skipassExtraResult.search.arrdate);
    const skipassExtrasSearchDepdate = moment(skipassExtraResult.search.depdate);
    const accommodationArrdate = moment(accommodation.arrdate);
    const accommodationDepdate = moment(accommodation.depdate);

    // The search dates needs to match the dates of the accommodation.
    if (
        !skipassExtrasSearchArrdate.isSame(accommodationArrdate, "day") ||
        !skipassExtrasSearchDepdate.isSame(accommodationDepdate, "day")
    ) {
        return undefined;
    }

    // Structure of skipass results require the following format:
    // - One group for each length (day). All items inside the group has the same length.
    // - Each group contains item(s) where each item is a different age category.

    // Given the above rules, find the first group matching arrival and departure.
    const skipassExtraGroup = (Object.values(skipassExtraResult.groups) as TGroup[]).find(
        (group: TGroup) =>
            //group.items[0]?.arrdate === accommodation.arrdate && group.items[0]?.depdate === accommodation.depdate
            group.items.length > 0 &&
            group.items.every(
                item =>
                    item.category !== "keycard" &&
                    moment(item.arrdate).isSame(accommodationArrdate, "day") &&
                    moment(item.depdate).isSame(accommodationDepdate, "day")
            )
    );

    return skipassExtraGroup;
};
