import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { cancelProductFromReservation } from "../../store/actions/account";
import { emptyCartProducts } from "../../store/actions/checkoutCart";
import { confirmationDialog, Icon } from "../UI";
import { textsSelector } from "../../Selectors";

const CancelProductButton = ({
    resvid,
    detlid,
    productTitle = "",
    confirmationTitle = "",
    confirmationDescription = "",
    allowCancellation = false,
    connectedProducts = [],
}) => {
    const dispatch = useDispatch();
    const texts = useSelector(textsSelector);
    const userToken = useSelector(state => state.account.token);

    const cancelProduct = () => {
        if (!resvid || !detlid) {
            return;
        }

        dispatch(emptyCartProducts());
        dispatch(cancelProductFromReservation(userToken, resvid, detlid));
    };

    const openCancelSelectedProductConfirmation = async () => {
        const connectedProductsDescription =
            connectedProducts.length > 0
                ? connectedProducts.map(item => "\n- " + `${item.kinddesc} (${item.title})`).join("")
                : "";

        const response = await confirmationDialog({
            title: confirmationTitle,
            description: confirmationDescription + "\n- " + productTitle + connectedProductsDescription,
            okLabel: texts["generalyes"],
            cancelLabel: texts["general.confirmation.cancel"],
        });

        if (response) {
            cancelProduct();
        }
    };

    return (
        <OverlayTrigger
            placement="left"
            overlay={
                <Tooltip>
                    {allowCancellation ? texts["generalcancel"] : texts["bookingview.cancellation_not_allowed"]}
                </Tooltip>
            }
        >
            <span
                style={{ cursor: allowCancellation ? "pointer" : "default" }}
                onClick={() => {
                    allowCancellation && openCancelSelectedProductConfirmation();
                }}
            >
                <Icon name="FaRegTrashAlt" color={allowCancellation ? "#333" : "#BBB"} size={16} />
            </span>
        </OverlayTrigger>
    );
};

export default CancelProductButton;
