import React from "react";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import SignUp from "../../components/Registration";
import "./RegistrationPage.scss";
import PageContainer from "../../components/PageContainer";

/**
 * Registration page
 *
 * Supports the "?path=<path>" parameter to redirect user back to flow after registration
 */

const RegistrationPage = () => {
    const location = useLocation();
    const qs = queryString.parse(location.search);

    return (
        <div className="registration-page">
            <PageContainer>
                <SignUp registrationPage={true} redirectPath={qs.path} />
            </PageContainer>
        </div>
    );
};

export default RegistrationPage;
