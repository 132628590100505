import React from "react";
import RichText from "../UI/RichText";
import { Heading, Radio, style } from "@r360/library";
import { TAgreement } from "../../store/types";

type TInformationConsent = {
    agreement: TAgreement;
    agreementValue: any;
    onChange: (arg0: TAgreement, arg1: boolean) => void;
    agreementsValid?: boolean;
};

// Information consent component
export const CheckoutConnectAgreement = ({
    agreement,
    agreementValue,
    onChange,
    agreementsValid,
}: TInformationConsent) => {
    // On checkbox change
    const onRadioChange = (data: { key: string; value: boolean }) => {
        onChange(agreement, data.value);
    };

    return (
        <div>
            <Heading type="h2" styleAs="h3">
                {agreement.desc1}
            </Heading>
            <div>
                <p className="u-mb-12">
                    <RichText content={agreement.desc2} />
                </p>
                <div
                    className="u-d-flex u-flex-column u-gap-6"
                    style={{
                        outline:
                            (agreementsValid === false && agreementValue) === null
                                ? `1px solid ${style.alertColor}`
                                : "none",
                        outlineOffset: "6px",
                    }}
                >
                    <Radio
                        required
                        label={agreement.yestext}
                        name={`${agreement.gdprtypelid}`}
                        value={"true"}
                        onChange={() => onRadioChange({ key: `${agreement.gdprtypelid}`, value: true })}
                        checked={agreementValue === true}
                    />
                    <Radio
                        required
                        label={agreement.notext}
                        name={`${agreement.gdprtypelid}`}
                        value={"false"}
                        onChange={() => onRadioChange({ key: `${agreement.gdprtypelid}`, value: false })}
                        checked={agreementValue === false}
                    />
                </div>
            </div>
            <hr />
        </div>
    );
};
