import * as React from "react";
import defautIconStats from "../Icon.types";
import Theme from "../../Theme";

const Booking = ({
    color = Theme.palette.primary,
    size = defautIconStats.size,
    iconClass = defautIconStats.iconClass,
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 480 480"
            // style="enable-background:new 0 0 480 480;"
            style={{ height: size, width: size }}
            width="480pt"
            height="480pt"
            className={iconClass}
        >
            <g>
                <g>
                    <path
                        fill={color}
                        d="M440,64h-8V24c0-13.232-10.768-24-24-24h-16c-13.232,0-24,10.768-24,24v40H112V24c0-13.232-10.768-24-24-24H72
            C58.768,0,48,10.768,48,24v40h-8C17.944,64,0,81.944,0,104v336c0,22.056,17.944,40,40,40h400c22.056,0,40-17.944,40-40V104
            C480,81.944,462.056,64,440,64z M384,72V24c0-4.416,3.584-8,8-8h16c4.416,0,8,3.584,8,8v48v32c0,4.416-3.584,8-8,8h-16
            c-4.416,0-8-3.584-8-8V72z M64,72V24c0-4.416,3.584-8,8-8h16c4.416,0,8,3.584,8,8v48v32c0,4.416-3.584,8-8,8H72
            c-4.416,0-8-3.584-8-8V72z M464,440c0,13.232-10.768,24-24,24H40c-13.232,0-24-10.768-24-24V208h448V440z M464,192H16v-88
            c0-13.232,10.768-24,24-24h8v24c0,13.232,10.768,24,24,24h16c13.232,0,24-10.768,24-24V80h256v24c0,13.232,10.768,24,24,24h16
            c13.232,0,24-10.768,24-24V80h8c13.232,0,24,10.768,24,24V192z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        fill={color}
                        d="M328,104H152c-4.424,0-8,3.576-8,8v48c0,4.424,3.576,8,8,8h176c4.424,0,8-3.576,8-8v-48C336,107.576,332.424,104,328,104z
            M320,152H160v-32h160V152z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        fill={color}
                        d="M104,232H56c-4.424,0-8,3.576-8,8v40c0,4.424,3.576,8,8,8h48c4.424,0,8-3.576,8-8v-40C112,235.576,108.424,232,104,232z
            M96,272H64v-24h32V272z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        fill={color}
                        d="M208,232h-48c-4.424,0-8,3.576-8,8v40c0,4.424,3.576,8,8,8h48c4.424,0,8-3.576,8-8v-40C216,235.576,212.424,232,208,232z
            M200,272h-32v-24h32V272z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        fill={color}
                        d="M320,232h-48c-4.424,0-8,3.576-8,8v40c0,4.424,3.576,8,8,8h48c4.424,0,8-3.576,8-8v-40C328,235.576,324.424,232,320,232z
            M312,272h-32v-24h32V272z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        fill={color}
                        d="M424,232h-48c-4.424,0-8,3.576-8,8v40c0,4.424,3.576,8,8,8h48c4.424,0,8-3.576,8-8v-40C432,235.576,428.424,232,424,232z
            M416,272h-32v-24h32V272z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        fill={color}
                        d="M104,312H56c-4.424,0-8,3.576-8,8v40c0,4.424,3.576,8,8,8h48c4.424,0,8-3.576,8-8v-40C112,315.576,108.424,312,104,312z
            M96,352H64v-24h32V352z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        fill={color}
                        d="M208,312h-48c-4.424,0-8,3.576-8,8v40c0,4.424,3.576,8,8,8h48c4.424,0,8-3.576,8-8v-40C216,315.576,212.424,312,208,312z
            M200,352h-32v-24h32V352z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        fill={color}
                        d="M320,312h-48c-4.424,0-8,3.576-8,8v40c0,4.424,3.576,8,8,8h48c4.424,0,8-3.576,8-8v-40C328,315.576,324.424,312,320,312z
            M312,352h-32v-24h32V352z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        fill={color}
                        d="M424,312h-48c-4.424,0-8,3.576-8,8v40c0,4.424,3.576,8,8,8h48c4.424,0,8-3.576,8-8v-40C432,315.576,428.424,312,424,312z
            M416,352h-32v-24h32V352z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        fill={color}
                        d="M104,392H56c-4.424,0-8,3.576-8,8v40c0,4.424,3.576,8,8,8h48c4.424,0,8-3.576,8-8v-40C112,395.576,108.424,392,104,392z
            M96,432H64v-24h32V432z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        fill={color}
                        d="M208,392h-48c-4.424,0-8,3.576-8,8v40c0,4.424,3.576,8,8,8h48c4.424,0,8-3.576,8-8v-40C216,395.576,212.424,392,208,392z
            M200,432h-32v-24h32V432z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        fill={color}
                        d="M321.856,386.88l-34.392,41.272l-17.808-17.808l-11.312,11.312l24,24C283.84,447.168,285.88,448,288,448
            c0.12,0,0.24,0,0.36-0.008c2.248-0.104,4.344-1.144,5.792-2.864l40-48L321.856,386.88z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        fill={color}
                        d="M417.856,386.88l-34.392,41.272l-17.808-17.808l-11.312,11.312l24,24C379.84,447.168,381.88,448,384,448
            c0.12,0,0.24,0,0.36-0.008c2.248-0.104,4.344-1.144,5.792-2.864l40-48L417.856,386.88z"
                    />
                </g>
            </g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
        </svg>
    );
};

export default Booking;
