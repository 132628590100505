import * as types from "../actions/types";

export const setIsIframe = isIframe => {
    return {
        type: types.SET_IS_IFRAME,
        isIframe: isIframe,
    };
};

export const setIsMapOpen = isMapOpen => {
    return {
        type: types.SET_MAP_OPEN,
        isMapOpen,
    };
};

export const screenResize = screenSize => {
    return dispatch => {
        dispatch(setDevice(screenSize.width));
        dispatch(setSize(screenSize));
    };
};

export const setSize = screenSize => {
    return {
        type: types.SCREEN_RESIZE,
        screenSize: screenSize,
    };
};

export const setIframeOffsetTop = iframeOffsetTop => {
    return {
        type: types.SET_IFRAME_OFFSET_TOP,
        iframeOffsetTop,
    };
};

/*
  Use to show SignUp Wrapper and also which modal to present
*/
/**
 *
 * @param {Bool} showWrapper — show a wrapper on top of page?
 * @param {Bool} loginModal — show login wrapper? (should be false if registration wrapper is true)
 * @param {Bool} registrationModal - show registration wrapper? (should be false if login wrapper is true)
 * @param {String} pathname — transport the user here after login/registration
 */
export const setSignUpWrapper = (showWrapper, loginModal, registrationModal, pathname = "") => {
    return {
        type: types.SET_SIGN_UP_MODAL,
        showWrapper: showWrapper,
        showLoginModal: loginModal,
        showRegistrationModal: registrationModal,
        pathname: pathname,
    };
};

export const setBookingError = error => {
    return {
        type: types.SET_BOOKING_ERROR,
        error: error,
    };
};

export const setDevice = width => {
    let device = undefined;

    switch (true) {
        case width <= 768:
            device = "mobile";
            break;

        case width > 768 && width <= 991:
            device = "tablet";
            break;

        case width >= 992 && width <= 1199:
            device = "desktop";
            break;

        case width > 1200:
            device = "largeDesktop";
            break;

        default:
            break;
    }

    return {
        type: types.SET_DEVICE,
        device: device,
        isMobile: device === "mobile",
        isTablet: device === "tablet",
        isDesktop: device === "desktop" || device === "largeDesktop",
        isTabletNewDesign: device === "tablet" || device === "desktop",
        isDesktopNewDesign: device === "largeDesktop",
    };
};

/*
  Use to set timestamp to clear storage
*/
export const createTimeStamp = date => {
    return {
        type: types.CREATE_TIMESTAMP,
        date: date, // use a iso date string and not a moment object
    };
};

/*
  Use to set Error
*/
export const setError = error => {
    return {
        type: "SET_ERROR",
        error: error,
    };
};
