import * as types from "../actions/types";
import AxiosClient from "../../API/AxiosClient";
import { resetLastSearchedTimestamps } from "./search";
import { setRequestsLoading } from "../../store/actions/axiosStatus";

/*
  Use to create new reservation
*/
export const makeReservation = (token, cartId, note = "") => {
    const config = token
        ? {
              headers: {
                  Authorization: `Bearer ${token}`,
              },
          }
        : {};
    const url = "/reservations";
    const body = {
        cart_id: cartId,
        payment_method: "klarna",
        disable_debug_logger: true,
        keep_cart_after_reservation: true,
        note: note,
    };

    return dispatch => {
        dispatch(setRequestsLoading({ request: "makeReservation", loading: true }));

        AxiosClient.post(url, body, config)
            .then(response => {
                dispatch(resetLastSearchedTimestamps());
                dispatch(setReservation(response.data.payload));
                dispatch(setRequestsLoading({ request: "makeReservation", loading: false }));
            })
            .catch(error => {
                dispatch(makeReservationFailed(error.response.data));
                dispatch(setRequestsLoading({ request: "makeReservation", loading: false }));
            });
    };
};

const setReservation = (reservation, data) => {
    return {
        type: types.SET_RESERVATION,
        reservation: reservation,
        data: data,
    };
};

export const resetReservation = () => {
    return {
        type: types.RESET_RESERVATION,
    };
};

const makeReservationFailed = error => {
    return {
        type: types.MAKE_RESERVATION_FAILED,
        error: error,
    };
};
