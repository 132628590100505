import { ParagraphSkeleton, Skeleton, SkeletonGroup } from "@r360/library";
import classNames from "classnames";
import React from "react";

export const MultipleVariantsCardSkeleton = ({ isDesktop }: { isDesktop: boolean }) => {
    return (
        <SkeletonGroup>
            <div className="u-br-8 u-bg-white u-mb-12 u-overflow-hidden">
                <div className="row">
                    <div className="col-12 col-lg-3">
                        <Skeleton height="150px" borderRadius="0" />
                    </div>
                    <div className="u-p-24 col-12 col-lg-6">
                        <ParagraphSkeleton />
                    </div>
                    <div className="col-12 col-lg-3">
                        <div
                            className={classNames("u-p-24 u-d-flex u-flex-column u-justify-content-end u-gap-6", {
                                "u-align-items-end": !isDesktop,
                            })}
                        >
                            <Skeleton width="20%" />
                            <Skeleton width="40%" />
                            <Skeleton width="80%" height="40px" />
                        </div>
                    </div>
                </div>
            </div>
        </SkeletonGroup>
    );
};
