import React, { useCallback, useEffect, useRef, useState } from "react";
import { resetPromoCode, setPromoCode } from "../../store/actions/filter";
import "./PromotionCodeInput.scss";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import classNames from "classnames";
import useTranslate from "../../hooks/useTranslate";
import { RichText } from "../UI";
import { activeReservationTypeSelector } from "../../selectors/Selectors";
import { useMatch } from "react-router-dom";
import { Button, Input, CheckIcon, CloseIcon, style, TrashIcon } from "@r360/library";
import confirmationDialog from "../Utilities/ConfirmationDialog/ConfirmationDialog";

type TPromotionCodeInput = {
    className?: string;
    onDarkBackground?: boolean;
    classNameButton?: string;
    withoutMenuSearch?: boolean;
    breakSubmittedCode?: boolean;
};

type TCodeData = {
    code: string;
    error: boolean;
} | null;

// TODO move to hooks
const useOutsideClickTrigger = (ref: React.RefObject<HTMLDivElement>, callback: () => void) => {
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                callback();
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
};

export const PromotionCodeInput: React.FC<TPromotionCodeInput> = ({
    className,
    onDarkBackground = true,
    classNameButton,
    withoutMenuSearch = false,
    breakSubmittedCode = false,
}) => {
    const dispatch = useAppDispatch();
    const promoCodeError = useAppSelector(state => state.filter.promoCodeError);
    const t = useTranslate();
    const productsNotAddedPath = useAppSelector(state => state.checkoutCart.productsNotAddedPath);
    const searchRouteMatch = useMatch("/search/:resvTypeId");
    const productRouteMatch = useMatch("/product/:resvTypeId/:groupPoolId/*");
    const currentRouteId = searchRouteMatch?.params.resvTypeId ?? productRouteMatch?.params.resvTypeId;
    const activeReservationType = useAppSelector(state => activeReservationTypeSelector(state, currentRouteId));
    const filter = useAppSelector(state => state.filter);
    const resvTypePromoCodes = filter.resvTypePromoCodes;
    const codeData: TCodeData = resvTypePromoCodes?.[activeReservationType.lid] ?? null;
    const { code, error } = codeData ?? {};
    const codeExists = !!code?.length;
    const [input, setInput] = useState(code);
    const [isInteracting, setIsInteracting] = useState(false);
    const [showInput, setShowInput] = useState(codeExists && !error && !isInteracting);

    useEffect(() => {
        if (!codeExists && !isInteracting) {
            setShowInput(false);
        } else if (codeExists) {
            setShowInput(true);
        }
    }, [codeExists, isInteracting]);

    const handleInputShow = () => {
        !isInteracting && setIsInteracting(true);
        setShowInput(true);
    };

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInput(e.target.value);
    };

    const productsNotAddedWarningModal = () =>
        confirmationDialog({
            heading: t("book.leave_warning_header"),
            description: t("book.leave_warning_text"),
            confirmBtnText: t("book.leave_warning_confirm"),
            cancelBtnText: t("book.leave_warning_cancel"),
        });

    const savePromo = async () => {
        if (productsNotAddedPath) {
            const response = await productsNotAddedWarningModal();

            if (!response) {
                return;
            }
        }

        dispatch(setPromoCode(input, activeReservationType.lid));
        !isInteracting && setIsInteracting(true);

        setTimeout(() => {
            setInput("");
        }, 1000);
    };

    const resetCode = useCallback(async () => {
        if (productsNotAddedPath) {
            const response = await productsNotAddedWarningModal();

            if (!response) {
                return;
            }
        }

        dispatch(resetPromoCode(activeReservationType.lid));
        setInput("");
        !isInteracting && setIsInteracting(true);
    }, [activeReservationType.lid, dispatch, isInteracting, productsNotAddedPath]);

    useEffect(() => {
        if (error) {
            const timer = setTimeout(() => {
                resetCode();
            }, 5000);
            return () => {
                clearTimeout(timer);
            };
        }
    }, [error, resetCode]);

    const wrapperRef = useRef(null);

    useOutsideClickTrigger(wrapperRef, () => {
        setIsInteracting(false);
    });

    const promotionCodeClassName = classNames("promotion-code", className);
    const promotionCodeSubmittedClassName = classNames("promotion-code--submitted", {
        onDarkBackground,
        withoutMenuSearch,
    });
    const promotionCodeButtonClassName = classNames("promotion-code__button", classNameButton, {
        withoutMenuSearch,
    });

    return showInput ? (
        <div ref={wrapperRef} className={promotionCodeClassName}>
            {!error && code ? (
                <div className={promotionCodeSubmittedClassName}>
                    <CheckIcon color={style.successColor} />
                    <p
                        className={classNames("u-mb-0", {
                            "u-color-black": !onDarkBackground,
                            "u-color-custom-primary-contrast": onDarkBackground,
                        })}
                    >
                        <RichText
                            content={t("book.search.campaign.code_activated", [
                                `${code}${breakSubmittedCode ? "</br>" : ""}`,
                            ])}
                        />
                    </p>
                    <button className="promotion-code__remove-btn" onClick={resetCode}>
                        <TrashIcon color={onDarkBackground ? style.customPrimaryContrastColor : style.darkBlueColor} />
                    </button>
                </div>
            ) : error ? (
                <div className={promotionCodeSubmittedClassName}>
                    <CloseIcon color={style.alertColor} size={30} />

                    {promoCodeError?.config?.data ? (
                        <p
                            className={classNames("u-mb-0", {
                                "u-color-black": !onDarkBackground,
                                "u-color-custom-primary-contrast": onDarkBackground,
                            })}
                        >
                            {t("book.search.campaign.not_found")}
                        </p>
                    ) : null}
                    <button className="promotion-code__remove-btn" onClick={resetCode}>
                        <TrashIcon color={onDarkBackground ? style.customPrimaryContrastColor : style.darkBlueColor} />
                    </button>
                </div>
            ) : (
                <div>
                    <Input
                        label={t("book.search.campaign.title")}
                        value={input}
                        onChange={onInputChange}
                        inputSize="medium"
                        onSubmitButtonPress={savePromo}
                        withSubmitButton={!!input}
                        submitButtonLabel={t("book.search.campaign.submit")}
                        autoFocus
                        allowCustomColors={onDarkBackground}
                    />
                </div>
            )}
        </div>
    ) : (
        <Button
            customColorsReversed={onDarkBackground}
            type="tertiary"
            buttonClassName={promotionCodeButtonClassName}
            onClick={handleInputShow}
        >
            {t("book.search.campaign.do_you_have_code")}
        </Button>
    );
};
