import React from "react";
import ProductGallery from "../ProductGallery/ProductGallery";
import "./AccommodationResultCardTiny.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { ImagePlaceholder, Tag } from "@r360/library";
import useTranslate from "../../hooks/useTranslate";
import { TImage, TReservationTypeLid } from "../../store/types";
import { formatPrice } from "../../Helper";
import classNames from "classnames";

export type AccommodationResultCardTinyCriteria = {
    title: string;
    value: string;
};

export type Props = {
    groupPoolName?: string;
    title: string;
    capacity: string | number;
    price: number;
    ordinaryprice: number;
    showPrice: boolean;
    description: string;
    images: TImage[];
    grouppoollid: number;
    poollid: number;
    unitlid: number;
    reservationTypeId: TReservationTypeLid;
    currency: string;
    searchWithPrice?: boolean;
    multipleAccommodationsCard?: boolean;
    isAccommodationAreaPage?: boolean;
};

export const AccommodationResultCardTiny = ({
    groupPoolName,
    title,
    capacity,
    price,
    ordinaryprice,
    showPrice,
    description,
    images,
    grouppoollid,
    poollid,
    unitlid,
    reservationTypeId,
    currency,
    searchWithPrice = false,
    multipleAccommodationsCard = false,
    isAccommodationAreaPage = false,
}: Props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const t = useTranslate();

    const navigateDeeper = () => {
        if (isAccommodationAreaPage) {
            if (!multipleAccommodationsCard) {
                navigate(`/product/${reservationTypeId}/${grouppoollid}/${poollid}/${unitlid}${location.search}`);
            }
        } else if (multipleAccommodationsCard) {
            navigate(`/product/${reservationTypeId}/${grouppoollid}${location.search}`);
        } else {
            navigate(`/product/${reservationTypeId}/${grouppoollid}/${poollid}/${unitlid}${location.search}`);
        }
    };

    const hasOrdinaryPrice = ordinaryprice > 0;

    const formatDescription = (description: string) => {
        const htmlRegEx = /(<([^>]+)>)/gi;
        if (description.length > 90) {
            return `${description.substring(0, 90)}...`.replace(" .", ".").replace(htmlRegEx, "");
        } else {
            return description.replace(htmlRegEx, "");
        }
    };

    const showFromText = !searchWithPrice || multipleAccommodationsCard;
    return (
        <article className="accommodation-card-tiny">
            <div className="accommodation-card-tiny__gallery">
                {images.length > 0 ? (
                    <ProductGallery
                        images={(images || []).map(image => ({
                            original: image.url,
                            thumbnail: image.url,
                        }))}
                        forceImageGallery={true}
                        onImageGalleryClick={() => null}
                        inAccommodationCard
                    />
                ) : (
                    <ImagePlaceholder aspectRatio="16 / 9" />
                )}
            </div>
            <div className="accommodation-card-tiny__click-area" onClick={navigateDeeper}>
                <div className="accommodation-card-tiny__content">
                    <div className="accommodation-card-tiny__heading">
                        <div className="accommodation-card-tiny__sub-heading">{groupPoolName}</div>
                        <h6 className="accommodation-card-tiny__header">{title}</h6>
                        <div className="accommodation-card-tiny__sub-heading">{t("book.map.people", [capacity])}</div>
                    </div>
                    <p className="accommodation-card-tiny__description">{formatDescription(description)}</p>
                </div>
                <h5 className="accommodation-card-tiny__price">
                    {showPrice ? (
                        <>
                            <span className={classNames(hasOrdinaryPrice && "accommodation-card-tiny__price-sheap")}>
                                {showFromText ? t("book.general.from") : null} {formatPrice(price, currency)}
                            </span>
                            {hasOrdinaryPrice && (
                                <span className="accommodation-card-tiny__price-ordinary-price">
                                    {formatPrice(ordinaryprice, currency)}
                                </span>
                            )}
                        </>
                    ) : (
                        <span className="accommodation-card-tiny__price-no-price">
                            {t("book.general.price_placeholder")}
                        </span>
                    )}
                </h5>
            </div>
        </article>
    );
};
