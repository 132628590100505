import React from "react";
import ForgotPassword from "../components/ForgotPassword/ForgotPassword";

const ForgotPasswordPage = () => {
    return (
        <div className="container">
            <ForgotPassword />
        </div>
    );
};

export default ForgotPasswordPage;
