import { cloneDeep } from "lodash";
import * as types from "../actions/types";
import produce from "immer";

const initialState = {
    products: [],
    error: null,
    createdFromReservation: null,
    note: "",
    bookingForOthers: null,
    summaryDetailedView: false,
    productsNotAddedPath: "",
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.ADD_PRODUCT: {
            const clonedProduct = cloneDeep(action.product);

            return {
                ...state,
                products: [...state.products, clonedProduct],
                error: false,
            };
        }
        case types.ADD_PRODUCT_GUESTS: {
            const existingCartItem = state.products.find(
                product =>
                    product.poollid == action.product.poollid &&
                    product.unitlid == action.product.unitlid &&
                    product.activitylid == action.product.activitylid &&
                    product.arrdate == action.product.arrdate &&
                    product.depdate == action.product.depdate &&
                    product.price1 == action.product.price1 &&
                    product.price2 == action.product.price2 &&
                    product.price3 == action.product.price3 &&
                    product.price4 == action.product.price4
            );

            // Add guests to existing product if it already exist in cart
            if (existingCartItem) {
                const updatedProduct = cloneDeep(existingCartItem);

                Object.entries(action.product.info.guests).forEach(([key, value]) => {
                    if (existingCartItem.info?.guests) {
                        updatedProduct.info.guests[key] = value + (existingCartItem.info.guests[key] ?? 0);
                    }
                });

                const count = Object.entries(updatedProduct.info.guests).reduce((acc, [key, value]) => {
                    if (key !== "totalGuests") {
                        acc += value;
                    }
                    return acc;
                }, 0);

                updatedProduct.info.guests.totalGuests = count;
                updatedProduct.count = count;

                return {
                    ...state,
                    // Remove the existing product and add the updated product
                    products: [...state.products.filter(product => product.id !== existingCartItem.id), updatedProduct],
                    error: false,
                };
            }

            return {
                ...state,
                products: [...state.products, action.product],
                error: false,
            };
        }
        case types.ADD_PRODUCT_COUNT: {
            //Only update the product if it allready exist in cart
            if (action.updateCount && state.products.find(product => product.id === action.product.id)) {
                return {
                    ...state,
                    products: state.products.map(product =>
                        product.id === action.product.id ? { ...product, count: action.count + product.count } : product
                    ),
                    error: false,
                };
            }
            const newProduct = {
                ...action.product,
                count: action.count,
            };
            return {
                ...state,
                products: [...state.products, newProduct],
                error: false,
            };
        }

        case types.ADD_PRODUCT_WITH_COUNT: {
            const product = cloneDeep(action.product);
            product.count = 1;

            return {
                ...state,
                products: [...state.products, product],
                error: false,
            };
        }

        case types.SET_PRODUCT_GUEST_CATEGORY_COUNT: {
            state = produce(state, draft => {
                // Find the product and set the count for the selected guest category
                const productIndex = draft.products.findIndex(
                    product => product.cartItemId === action.product.cartItemId
                );

                if (productIndex >= 0) {
                    const product = draft.products[productIndex];

                    if (product.info?.guests) {
                        product.info.guests[action.payload.guestAgeCategory] = action.payload.guestCount;

                        const totalCount = Object.entries(product.info.guests)
                            .filter(([key]) => key !== "totalGuests")
                            .reduce((prev, [, count]) => (prev += count), 0);
                        product.info.guests.totalGuests = totalCount;
                    } else {
                        product.info = {
                            guests: {
                                [action.payload.guestAgeCategory]: action.payload.guestCount,
                                totalGuests: action.payload.guestCount,
                            },
                        };
                    }

                    product.count = product.info.guests.totalGuests;
                }
            });

            return state;
        }

        case types.REMOVE_PRODUCT_GUEST_CATEGORY: {
            state = produce(state, draft => {
                // Find the product and set the count for the selected guest category
                const productIndex = draft.products.findIndex(
                    product => product.cartItemId === action.product.cartItemId
                );

                if (productIndex >= 0) {
                    const product = draft.products[productIndex];

                    if (product.info?.guests) {
                        const prevGuestCount = product.info.guests[action.payload.guestAgeCategory];
                        delete product.info.guests[action.payload.guestAgeCategory];
                        product.info.guests.totalGuests -= prevGuestCount;

                        // Remove product if no guests are left
                        if (product.info.guests.totalGuests === 0) {
                            draft.products.splice(productIndex, 1);
                        }
                    }
                }
            });

            return state;
        }
        case types.REMOVE_PRODUCT:
            return {
                ...state,
                products: state.products.filter(
                    product =>
                        product.cartItemId !== action.product.cartItemId &&
                        product.connectedProductId !== action.product.cartItemId // Remove eventual connected products. E.g. skipass offers.
                ),
                error: false,
            };

        case types.UPDATE_PRODUCT:
            return {
                ...state,
                products: state.products.map(product => {
                    if (product.cartItemId === action.product.cartItemId) {
                        // Product need to be cloned deeply because otherwise this affects extras stored in search results
                        const updatedProduct = cloneDeep(product);
                        updatedProduct[action.payload.key] = action.payload.value;
                        return updatedProduct;
                    }

                    return product;
                }),
            };

        case types.REMOVE_PRODUCT_COUNT:
            return {
                ...state,
                products: state.products.filter(
                    product =>
                        product.cartItemId !== action.product.cartItemId ||
                        (product.cartItemId === action.product.cartItemId && product.countid !== action.count)
                ),
                error: false,
            };

        case types.UPDATE_PRODUCT_WITH_COUNT:
            return {
                ...state,
                products: state.products.map(product => {
                    if (product.cartItemId === action.product.cartItemId) {
                        const updatedProduct = cloneDeep(product);
                        updatedProduct.count = action.count;
                        return updatedProduct;
                    }

                    return product;
                }),
            };

        case types.SET_CHECKOUT_CART_ID:
            return {
                ...state,
                correlation_id: action.payload.correlation_id,
                cartId: action.payload.id,
                primaryGuestId: action.payload.primary_guest_id,
            };

        case types.SUCCESSFULLY_ADDED_ALL_PRODUCTS:
            return {
                ...state,
                addedAllProductsSucceed: action.addedAllProductsSucceed,
            };

        case types.SET_CHECKOUT_CART_SUMMARY:
            return {
                ...state,
                summary: action.summary,
            };

        case types.FETCH_CHECKOUT_CART_SUMMARY_FAILED:
            return {
                ...state,
                summary: null,
                error: action.error,
            };

        case types.UPDATE_PRODUCT_ITEM:
            return {
                ...state,
                products: state.products.map(product =>
                    product.cartItemId === action.productId
                        ? {
                              ...product,

                              // Append row_id to guest connection on the product.
                              // Items comes from CheckoutPage and contains connected guestlid, phone etc
                              items: product.items.map(item =>
                                  item.id === action.cartId ? { ...item, row_id: action.row_id } : item
                              ),
                          }
                        : product
                ),
            };

        case types.ADD_EXTRAS_TO_CART_FAILED:
            return { ...state, error: action.error };

        case types.CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };

        case types.SET_CART_FAILED:
            return {
                ...state,
                error: action.error,
            };

        case types.ADDED_ALL_PRODUCTS_FAILED:
            return {
                ...state,
                error: action.error,
            };

        case types.SYNC_PRODUCTS_IN_CART_FAILED:
            return {
                ...state,
                error: action.error,
            };

        case types.DELETE_PRODUCT_FROM_CART_FAILED:
            return {
                ...state,
                error: action.error,
            };

        case types.SET_LATEST_CHANGED_PRODUCT_TYPE: {
            return {
                ...state,
                activeLid: action.activeLid,
            };
        }

        // Empty cart and also remove existing cart id and summary, which exists if the customer has been on the Checkout Summary
        case types.EMPTY_CART_PRODUCTS: {
            state = produce(state, draft => {
                // createdFromReservation
                draft.products = [];

                if (!draft.createdFromReservation) {
                    delete draft.addedAllProductsSucceed;
                    delete draft.correlation_id;
                    delete draft.cartId;
                    delete draft.primaryGuestId;
                    delete draft.summary;
                }
            });
            return state;
        }

        case types.RESET_CART: {
            return { ...initialState };
        }

        case types.SET_CART_FROM_RESERVATION: {
            return {
                ...state,
                products: action.products,
                createdFromReservation: action.createdFromReservation,
            };
        }

        case types.CREATE_CART_FROM_RESERVATION_FAILED: {
            return {
                ...state,
                products: [],
                error: action.error,
                createdFromReservation: action.createdFromReservation,
            };
        }

        case types.SET_CART_PRODUCT_EXTRAS: {
            const extras = action.payload;
            const productId = action.productId;

            // Update extras for matching product
            const productIndex = state.products.findIndex(product => product.id === productId);

            if (productIndex >= 0) {
                // TODO: Extras shall move directly to product to make it easier to work with them
                if (!state.products[productIndex].info) {
                    state.products[productIndex].info = Object.assign({
                        extras: extras,
                    });
                } else {
                    state.products[productIndex].info = Object.assign({}, state.products[productIndex].info, {
                        extras: extras,
                    });
                }
            }
            return state;
        }

        case types.SET_CHECKOUT_CART_NOTE: {
            return {
                ...state,
                note: action.note,
            };
        }

        case types.SET_BOOKING_FOR_OTHERS: {
            return {
                ...state,
                bookingForOthers: action.bookingForOthers,
            };
        }

        case types.SET_CHECKOUT_SUMMARY_DETAILED_VIEW: {
            return {
                ...state,
                summaryDetailedView: action.detailedView,
            };
        }

        case types.SET_PRODUCTS_NOT_ADDED_PATH: {
            return {
                ...state,
                productsNotAddedPath: action.path,
            };
        }

        default:
            // Make sure we always store empty list of products as an array
            if (state.products === null) {
                state.products = [];
            }

            return {
                ...state,
            };
    }
};

export default reducer;
