import { ConfirmationModal } from "@r360/library";
import React from "react";
import { confirmable, createConfirmation } from "react-confirm";

interface TConfirmationDialog {
    confirmBtnText: string;
    cancelBtnText: string;
    heading: string;
    description?: string;
    removing?: boolean;

    // Below props are provided by confirmable, don't change.
    show: boolean;
    proceed: (status: boolean) => void;
}

/**
 * Open confirmation dialog
 *
 * Usage:
 * const openChangeAccommodationConfirmation = async accommodation => {
 *     const response: boolean = await confirmationDialog({
 *         heading: "Change accommodation",
 *         description: "Do you really want to change accommodation?",
 *         confirmBtnText: "Continue",
 *         cancelBtnText: "Cancel",
 *     })
 * }
 */

const ConfirmationDialog = (props: TConfirmationDialog) => {
    const {
        confirmBtnText = "OK",
        cancelBtnText = "Cancel",
        heading,
        description,
        removing = false,
        show,
        proceed,
    } = props;

    return (
        <ConfirmationModal
            open={show}
            onClose={() => proceed(false)}
            heading={heading}
            description={<div style={{ whiteSpace: "pre-wrap" }}>{description}</div>}
            confirmBtnText={confirmBtnText}
            cancelBtnText={cancelBtnText}
            removing={removing}
            onConfirmClick={() => proceed(true)}
            onCancelClick={() => proceed(false)}
        />
    );
};

export default function confirmationDialog(options = {}) {
    return createConfirmation(confirmable(ConfirmationDialog))(options);
}
