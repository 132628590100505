import React from "react";
import PropTypes from "prop-types";
import InputRangeSlider from "../../InputRangeSlider";
import { RangePropType } from "../../../../PropTypes";

const QuantityRangeFilter = props => {
    const { label, name, unit, value, min, max, onChange, onInput, multiple, hideLabel } = props;

    let step = 1,
        labelFormatter = value => `${value} ${unit}`;

    return (
        <InputRangeSlider
            multiple={multiple ?? false}
            label={label}
            name={name}
            min={min}
            max={max}
            step={step}
            value={value}
            labelFormatter={labelFormatter}
            onChange={onChange}
            onInput={onInput}
            hideLabel={hideLabel}
        />
    );
};

QuantityRangeFilter.propTypes = {
    multiple: PropTypes.bool,
    name: PropTypes.string.isRequired,
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    value: RangePropType.isRequired,
    unit: PropTypes.string,
    onChange: PropTypes.func,
    onInput: PropTypes.func,
    hideLabel: PropTypes.bool,
};

export default QuantityRangeFilter;
