import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../..";
import { TCartItem, TDeliveryPoint } from "../../store/types";
import { LocationIcon, Select, TSelectOption, style } from "@r360/library";
import { updateProduct } from "../../store/actions/checkoutCart";
import { isSectionStateInputValid } from "../../pages/CheckoutConnectPage/CheckoutConnectPage";
import { TSectionState } from "../../pages/CheckoutConnectPage/CheckoutConnectPage";
import useTranslate from "../../hooks/useTranslate";

type TCheckoutConnectDeliveryPoint = {
    sectionState: TSectionState;
    products: TCartItem[];
};

export const CheckoutConnectDeliveryPoint = ({ sectionState, products }: TCheckoutConnectDeliveryPoint) => {
    const t = useTranslate();
    const dispatch = useDispatch();
    const deliveryPoints = useSelector((state: RootState) => state.clientData.deliveryPoints) as TDeliveryPoint[];

    // Pick first item on the first letting to store the delivery point in and then sync it with the rest of the items.
    const firstProductItem = products?.[0].items?.[0];

    /**
     * Sync that all items have the same delivery point.
     */
    useEffect(() => {
        if (products && products.length > 0 && deliveryPoints.length > 0) {
            if (products?.filter(product => product.detlid).length > 0) {
                // Use delivery point from first booked product
                const firstLettingItem = products[0];
                setDeliveryPointForAllItems(firstLettingItem.deliveryPoint ?? "");
            } else if (deliveryPoints.length === 1) {
                // There is only one delivery point, use it on all letting products
                setDeliveryPointForAllItems(deliveryPoints[0].namenative);
            } else if (products?.[0]?.items?.[0] && deliveryPoints.length > 1) {
                // Use delivery point from first non booked product (if set)
                const firstLettingItem = products[0].items[0];

                if (firstLettingItem.deliveryPoint) {
                    const uniqueDeliveryPoints = new Set(
                        products.reduce(
                            (acc, { items }) => {
                                if (items?.length) {
                                    acc = [...acc, ...items.map(item => item.deliveryPoint ?? "")];
                                }

                                return acc;
                            },
                            [firstLettingItem.deliveryPoint]
                        )
                    );

                    if (uniqueDeliveryPoints.size > 1) {
                        setDeliveryPointForAllItems(firstLettingItem.deliveryPoint);
                    }
                }
            }
        }
    }, []);

    const setDeliveryPointForAllItems = useCallback(
        (deliveryPoint: string) => {
            if (!deliveryPoint) {
                return;
            }

            // - Only add delivery point to non-booked products
            // - Allow customer to change delivery point on new bookings when having two or more delivery points
            products
                .filter(product => !product.detlid && product.items?.length)
                .forEach(product => {
                    let items = product.items?.filter(
                        item => !("deliveryPoint" in item) || item.deliveryPoint !== deliveryPoint
                    );

                    if (items?.length) {
                        items = items.map(item => {
                            item.deliveryPoint = deliveryPoint;
                            return item;
                        });

                        dispatch(
                            updateProduct(product, {
                                key: "items",
                                value: items,
                            })
                        );
                    }
                });
        },
        [dispatch, products]
    );

    const options: TSelectOption[] = deliveryPoints.map(point => {
        return {
            value: point.namedisplay,
            label: point.namedisplay,
        };
    });

    if (!firstProductItem) {
        return null;
    }

    return (
        <div
            className="u-d-flex u-flex-column u-align-items-center u-justify-content-center u-gap-18"
            style={{ width: "100%", maxWidth: 350 }}
        >
            <LocationIcon size={42} color={style.brandBlueColor} />
            <Select
                required
                label={t("book.checkout.pick_delivery_point")}
                options={options}
                type="standard"
                fullWidth
                name="deliveryPoint"
                defaultValue={firstProductItem.deliveryPoint || ""}
                onSelectCallback={(selectedOption: TSelectOption) => {
                    setDeliveryPointForAllItems(selectedOption.value as string);
                }}
                useFirstAsDefault={false}
                placeholder={t("book.checkout.pick_delivery_point.short")}
                isValid={isSectionStateInputValid(sectionState, firstProductItem.deliveryPoint ?? "")}
                showAsterisk
                boldLabel
            />
        </div>
    );
};
