import { Button } from "../UI";
import React from "react";
import { useSelector } from "react-redux";
import { CheckmarkIcon } from "../UI/R360Icons";
import "./ItemFilterButton.scss";

const matchIcon = <CheckmarkIcon size={16} color="#FFFFFF" iconClass="matched-icon" />;

const ItemFilterButton = props => {
    const { crits, type } = props;
    const filters = useSelector(state => {
        if (state.filter.filterCriterias && type in state.filter.filterCriterias) {
            return state.filter.filterCriterias[type];
        } else {
            return {};
        }
    });
    const typeFilters = useSelector(state => state.filter[type]);
    const allFilters = useSelector(state => state.availableFilters.default);

    return (
        crits &&
        filters &&
        typeFilters &&
        Object.entries(crits).map((crit, i) => {
            const match = Object.entries(filters).find(x => x[0] === crit[0]);
            if (match) {
                let boolname = "";
                let key = crit[1];
                if (key === true) {
                    key = crit[0];
                    boolname = allFilters[crit[0]]?.title;
                }

                let matched = typeFilters[key];
                const unmatched = match[1].enums[key] || "Default";
                if (matched === true) {
                    matched = allFilters[crit[0]]?.title;
                }

                const text = matched || unmatched.value || boolname;
                if (!text) return null;

                return (
                    <Button
                        key={i}
                        icon={matched ? matchIcon : null}
                        disabled
                        text={text}
                        leftIcon={matched}
                        className={matched ? "matched-span height-button" : "height-button"}
                    />
                );
            }

            return null;
        })
    );
};

export default ItemFilterButton;
