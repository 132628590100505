import React, { ChangeEvent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isBooked, showArrDepDates } from "../../BusinessUtils";
import {
    capitalizeFirstLetter,
    getGuestNameAndAge,
    isGuestRightAgeForProduct,
    isNumberWithoutDecimals,
} from "../../Helper";
import { guestsOnTravelSelector, optionsSelector } from "../../selectors/Selectors";
import { updateProduct } from "../../store/actions/checkoutCart";
import { updateGuestData } from "../../store/actions/guests";
import { v4 as uuidv4 } from "uuid";
import { updateUserData } from "../../store/actions/account";
import { TCartItem, TCartItemItem, TReservationType } from "../../store/types";
import { RootState } from "../..";
import useTranslate from "../../hooks/useTranslate";
import { CheckoutConnectCard } from "./CheckoutConnectCard";
import {
    CheckoutConnectCardHeading,
    CheckoutConnectNoGuestsToConnect,
} from "./CheckoutConnectCard/CheckoutConnectCard";
import { Checkbox, style, Select, Input, Notification } from "@r360/library";
import { TSectionStates, isSectionStateInputValid } from "../../pages/CheckoutConnectPage/CheckoutConnectPage";
import { CheckoutConnectDeliveryPoint } from ".";
import { CheckoutConnectComment } from ".";

type TCheckoutConnectLetting = {
    sectionStates: TSectionStates;
    removeProductFromCart: (arg0: TCartItem, arg1: TCartItemItem) => void;
    products: TCartItem[];
    type: TReservationType;
};

export const CheckoutConnectLetting = ({
    sectionStates,
    removeProductFromCart,
    products,
    type,
}: TCheckoutConnectLetting) => {
    const dispatch = useDispatch();
    const t = useTranslate();
    const guestsOnTravel = useSelector(guestsOnTravelSelector);
    const allowLettingNotes = useSelector(optionsSelector).reservation?.allow_letting_notes || false;
    const { isDesktopNewDesign } = useSelector((state: RootState) => state.window);

    const lettings = products.filter(product => !isBooked(product) && product.type === type.lid);
    const sectionState = sectionStates[type.lid];
    const lettingHelpInformationAvailable = !t("book.checkout.letting_help_information").includes("book.");

    useEffect(() => {
        lettings.forEach(letting => {
            if (letting.items && letting.items.length === letting.count) {
                // Empty statement.
            } else {
                if (letting.items) {
                    let rest = letting.count - letting.items.length;
                    const items = letting.items;
                    if (rest > 0) {
                        //console.log("CREATE " + rest + " skipaaess");

                        for (let index = 0; index < rest; index++) {
                            const uuid = uuidv4();
                            items.push({ id: uuid, guestId: null });
                        }
                    } else {
                        rest = rest * -1;
                        //console.log("Remove " + rest + " skipaaess");
                        for (let index = 0; index < rest; index++) {
                            items.pop();
                        }
                    }
                    dispatch(updateProduct(letting, { key: "items", value: items }));
                } else {
                    const items = [];
                    for (let count = 0; count < letting.count; count++) {
                        const uuid = uuidv4();
                        items.push({ id: uuid, guestId: null });
                    }
                    dispatch(updateProduct(letting, { key: "items", value: items }));
                }
            }
        });
    }, [dispatch, lettings]);

    const handleProductChange = (event: ChangeEvent<any>, product: TCartItem, productItem: TCartItemItem) => {
        const { name } = event.target;

        const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;

        updateProductItem(product, productItem, name, value);
    };

    const updateProductItem = (product: TCartItem, productItem: TCartItemItem, key: string, value: any) => {
        dispatch(
            updateProduct(product, {
                key: "items",
                value: product.items?.map(item => (item.id === productItem.id ? { ...item, [key]: value } : item)),
            })
        );
    };

    const updateGuestInformation = (e: ChangeEvent<HTMLInputElement>, guestId: string) => {
        const name = e.target.name;
        const value = e.target.value;
        dispatch(updateGuestData(guestId, name, value));

        // If the guest being updated is the primary guest, the user also needs to be updated.
        if (guestsOnTravel[guestId]?.primary) {
            dispatch(updateUserData(name, value));
        }
    };

    const productHasInsurance = (product: TCartItem) => product.price !== product.priceInsurance;

    const renderLettings = () => {
        return lettings.map(letting => {
            return (
                !letting.detlid &&
                letting.items?.map((item, i) => {
                    const selectedGuest = guestsOnTravel?.[item.guestId ?? ""];
                    const guestSelectOptions = Object.entries(guestsOnTravel)
                        .filter(([_id, guest]) => isGuestRightAgeForProduct(guest, letting))
                        .map(([id, guest]) => ({
                            value: id,
                            label: getGuestNameAndAge(guest, t("book.general.years"), letting),
                        }));

                    return (
                        <CheckoutConnectCard key={`${item.id}-${i}`}>
                            <div className="row">
                                <div className="col-12 col-lg-7 u-d-flex u-flex-column u-justify-content-between u-mb-0">
                                    <div>
                                        <div className="u-mb-12">
                                            <CheckoutConnectCardHeading
                                                primaryHeading={letting.groupTitle ?? letting.title}
                                                secondaryHeading={`${letting.minage} - ${letting.maxage} år`}
                                                secondaryHeadingColor={style.alertColor}
                                            />
                                        </div>
                                        <div className="u-mb-6">
                                            <span>
                                                {capitalizeFirstLetter(showArrDepDates(letting, "dddd Do MMMM"))}
                                            </span>
                                        </div>
                                        {productHasInsurance(letting) && (
                                            <div className="u-pt-18 u-pb-18">
                                                <Checkbox
                                                    name="withInsurance"
                                                    label={`${t("book.checkout.add_insurance")} (${
                                                        letting.priceInsurance &&
                                                        letting.priceInsurance - letting.price + " " + letting.curr
                                                    })`}
                                                    className="checkbox__input"
                                                    checked={item.withInsurance}
                                                    onChange={event => {
                                                        handleProductChange(event, letting, item);
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    {allowLettingNotes && isDesktopNewDesign && (
                                        <CheckoutConnectComment
                                            product={letting}
                                            item={item}
                                            onUpdateProduct={updateProductItem}
                                            onRemoveProduct={removeProductFromCart}
                                        />
                                    )}
                                </div>
                                <div className="col-12 col-lg-5 u-mb-0">
                                    <div className="u-mb-12">
                                        <Select
                                            required
                                            label={
                                                <span>
                                                    {`${t("book.checkout.for_who")} `}
                                                    <span style={{ fontWeight: 400 }} className="u-fw-default">{`(${
                                                        letting.minage
                                                    } - ${letting.maxage} ${t("book.general.years")})`}</span>
                                                </span>
                                            }
                                            name="guestId"
                                            defaultValue={(item.guestId || "").toString()}
                                            options={guestSelectOptions}
                                            fullWidth
                                            type="standard"
                                            onSelectCallbackWithEvent={event => {
                                                handleProductChange(event, letting, item);
                                            }}
                                            placeholder={t("book.checkout.pick_guest")}
                                            useFirstAsDefault={false}
                                            disabled={!guestSelectOptions.length}
                                            isValid={isSectionStateInputValid(sectionState, item.guestId ?? "")}
                                            showAsterisk
                                            boldLabel
                                        />
                                        {!guestSelectOptions.length && (
                                            <CheckoutConnectNoGuestsToConnect
                                                minAge={letting.minage}
                                                maxAge={letting.maxage}
                                            />
                                        )}
                                    </div>
                                    <div className="row u-bs-gutters-6">
                                        <div className="col-4">
                                            <Input
                                                label={`${t("book.general.height")} (cm)`}
                                                required
                                                disabled={!item.guestId}
                                                name="height"
                                                value={selectedGuest?.height || ""}
                                                onChange={e => {
                                                    if (isNumberWithoutDecimals(e.target.value)) {
                                                        updateGuestInformation(e, item.guestId ?? "");
                                                    }
                                                }}
                                                placeholder={t("book.general.height")}
                                                isValid={isSectionStateInputValid(
                                                    sectionState,
                                                    selectedGuest?.height ? selectedGuest.height.toString() : ""
                                                )}
                                            />
                                        </div>
                                        <div className="col-4">
                                            <Input
                                                label={`${t("book.general.weight")} (kg)`}
                                                required
                                                disabled={!item.guestId}
                                                name="weight"
                                                value={selectedGuest?.weight || ""}
                                                onChange={e => {
                                                    if (isNumberWithoutDecimals(e.target.value)) {
                                                        updateGuestInformation(e, item.guestId ?? "");
                                                    }
                                                }}
                                                placeholder={t("book.general.weight")}
                                                isValid={isSectionStateInputValid(
                                                    sectionState,
                                                    selectedGuest?.weight ? selectedGuest.weight.toString() : ""
                                                )}
                                            />
                                        </div>
                                        <div className="col-4">
                                            <Input
                                                label={`${t("book.general.shoesize")} (eu)`}
                                                required
                                                disabled={!item.guestId}
                                                name="shoesize"
                                                value={selectedGuest?.shoesize || ""}
                                                onChange={e => {
                                                    if (isNumberWithoutDecimals(e.target.value)) {
                                                        updateGuestInformation(e, item.guestId ?? "");
                                                    }
                                                }}
                                                placeholder={t("book.general.shoesize")}
                                                isValid={isSectionStateInputValid(
                                                    sectionState,
                                                    selectedGuest?.shoesize ? selectedGuest.shoesize.toString() : ""
                                                )}
                                                labelNoWrap
                                            />
                                        </div>
                                    </div>
                                    {allowLettingNotes && !isDesktopNewDesign && (
                                        <div className="u-pt-18">
                                            <CheckoutConnectComment
                                                product={letting}
                                                item={item}
                                                onUpdateProduct={updateProductItem}
                                                onRemoveProduct={removeProductFromCart}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </CheckoutConnectCard>
                    );
                })
            );
        });
    };

    return (
        <div>
            {lettingHelpInformationAvailable && (
                <div className="u-mb-18">
                    <Notification type="info">
                        <>{t("book.checkout.letting_help_information")}</>
                    </Notification>
                </div>
            )}
            <div className="u-mb-24">{renderLettings()}</div>
            <div className="u-d-flex u-justify-content-center u-mb-36">
                <CheckoutConnectDeliveryPoint sectionState={sectionState} products={lettings} />
            </div>
        </div>
    );
};
