// import * as React from "react";
import Theme from "./../Theme";

const defautIconStats = {
    color: Theme.palette.primary,
    size: 22,
    filled: false,
    iconClass: "icon",
};

export default defautIconStats;
