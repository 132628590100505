import { useCallback } from "react";
import queryString from "query-string";
import sum from "lodash/sum";
import { productTypeNames } from "../Constants";
import { getGuestObjectForReservationTypeFromArray } from "../Helper";
import { TReservationType } from "../store/types";
import useAppSelector from "./useAppSelector";
import { RootState } from "..";
import { optionsSelector } from "../Selectors";

const useReservationTypeLink = () => {
    const reservationResult = useAppSelector(state => state.reservationResult);
    const sharedSearchedDates = !!useAppSelector(optionsSelector)?.calendar?.sharedsearchdates;
    const lastSearchedDateSpan = useAppSelector((state: RootState) => state.search.lastSearchedDateSpan);
    const generalAges = useAppSelector(state => state.clientData?.options?.general?.ages);

    return useCallback(
        (reservationType: TReservationType) => {
            const baseLink = `/search/${reservationType.lid}`;

            const search = reservationResult[reservationType.lid]?.search;

            let arrdate, depdate;
            let checkPriceAndAvailability = true;

            if (sharedSearchedDates && lastSearchedDateSpan.arrdate && lastSearchedDateSpan.depdate) {
                arrdate = lastSearchedDateSpan.arrdate;
                depdate = lastSearchedDateSpan.depdate;
            } else if (search) {
                arrdate = search.arrdate;
                depdate = search.depdate;
                checkPriceAndAvailability = search?.checkPriceAndAvailability;
            } else {
                return baseLink;
            }

            if (checkPriceAndAvailability && arrdate && depdate) {
                if (
                    reservationType.type === productTypeNames.ACCOMMODATION ||
                    reservationType.type === productTypeNames.PACKAGE
                ) {
                    const queryStringObject: Record<string, string | number> = {
                        startDate: arrdate,
                        endDate: depdate,
                    };

                    const generalAgesParsed = JSON.parse(generalAges);
                    let guestAges = search?.guests || [];

                    let guests: Record<string, number> = getGuestObjectForReservationTypeFromArray(
                        guestAges,
                        reservationType.lid,
                        generalAgesParsed
                    );

                    // If no guests were found from previous search, check if there are default (minCount) ages set for the reservation type.
                    if (sum(Object.values(guests)) === 0) {
                        const generalAgesForReservationType = generalAgesParsed[reservationType.lid] || [];
                        const defaultGuestAges = generalAgesForReservationType.reduce(
                            (acc: number[], cur: { minCount?: number; value?: number }) => {
                                if (!!cur.minCount && cur.value !== undefined) {
                                    acc = [...acc, ...Array(cur.minCount).fill(cur.value)];
                                }

                                return acc;
                            },
                            []
                        );

                        guestAges = defaultGuestAges;
                        guests = getGuestObjectForReservationTypeFromArray(
                            guestAges,
                            reservationType.lid,
                            generalAgesParsed
                        );
                    }

                    Object.entries(guests)
                        .filter(([, value]) => value)
                        .forEach(([key, value]) => {
                            queryStringObject[key] = value;
                            const ageObject = generalAgesParsed[reservationType.lid].find(
                                (age: any) => age.key === key
                            );
                            if (ageObject?.requiresAge) {
                                const value = guestAges.filter(
                                    (age: any) => ageObject.min <= age && ageObject.max >= age
                                );
                                queryStringObject[`ages-for-${key}`] = value?.join("-");
                            }
                        });

                    return `${baseLink}?${queryString.stringify(queryStringObject)}`;
                } else if (reservationType.useAutoSearch) {
                    // Hide the dates searched for when using auto-search by only returning the base link.
                    return baseLink;
                } else if (reservationType.type === productTypeNames.SKIPASS) {
                    // Both start and end date should use arrdate when searching for skipass.
                    const queryStringObject: Record<string, string | number> = {
                        startDate: arrdate,
                        endDate: arrdate,
                    };
                    return `${baseLink}?${queryString.stringify(queryStringObject)}`;
                } else {
                    const queryStringObject: Record<string, string | number> = {
                        startDate: arrdate,
                        endDate: depdate,
                    };
                    return `${baseLink}?${queryString.stringify(queryStringObject)}`;
                }
            }

            return baseLink;
        },
        [generalAges, reservationResult, sharedSearchedDates, lastSearchedDateSpan]
    );
};

export default useReservationTypeLink;
