import React, { useState } from "react";
import { Icon } from "../";
import { PlusIcon, SignsIcon, TickIcon } from "../R360Icons";
import "./Alert.scss";

// Alert component
export const Alert = ({
    type,
    children,
    constrained = false,
    dismissable = false,
    reload = false,
    hideIcon = false,
}) => {
    const [show, setShow] = useState(true);
    const alertWidth = constrained ? " alert-message--constrained" : "";
    const hideClass = !show ? " alert-message--hidden" : "";
    const alertClass = type
        ? `alert-message${alertWidth} alert-message--${type}${hideClass}`
        : `alert-message${alertWidth}`;

    // Get alert icon
    const icon = () => {
        switch (type) {
            case "success":
                return <TickIcon size={28} color="#ffffff" color2="#32bea6" iconClass="alert-message__icon-success" />;
            case "info":
                return <SignsIcon size={30} color="#ec8427" iconClass="alert-message__icon-info" />;
            case "warning":
                return <PlusIcon size={16} color="#e04f5f" iconClass="alert-message__icon-warning" />;
            default:
                break;
        }
    };

    return (
        <div className={alertClass} role="alert">
            {!hideIcon && (
                <div className="alert-message__icon">
                    <span>{icon()}</span>
                </div>
            )}
            <div className={`alert-message__body${hideIcon ? " alert-message__body--full-width" : ""}`}>{children}</div>
            {dismissable && (
                <div className="alert-message__dismiss">
                    <button type="button" className="alert-message__dismiss-button" onClick={() => setShow(false)}>
                        <PlusIcon size={20} color="#dadada" iconClass="alert-message__dismiss-button-icon" />
                        {/* <span className="sr-only">{texts?.closealert || "Stäng dialogruta"}</span> */}
                    </button>
                </div>
            )}
            {reload && (
                <div className="alert-message__reload">
                    <button
                        type="button"
                        className="alert-message__reload-button"
                        onClick={() => window.location.reload()}
                    >
                        <Icon
                            name="FaUndo"
                            size={26}
                            color="#dadada"
                            // iconClass="alert-message__reload-button-icon"
                        />
                        {/* <span className="alert-message__reload-button-text">{texts?.refresh || "Ladda om"}</span> */}
                    </button>
                </div>
            )}
        </div>
    );
};

const Sorry = ({ children, variant = "light" }) => (
    <Alert variant={variant} className="alert-sorry">
        <div className="alert-sorry__icon">
            <Icon name="FaRegMehRollingEyes" size={40} color="#F1C40F" />
        </div>
        <div className="alert-sorry__text">{children}</div>
    </Alert>
);

Alert.Sorry = Sorry;

export default Alert;
