import React from "react";
import PropTypes from "prop-types";
import InputRangeSlider from "../../InputRangeSlider";
import { roundBy } from "../../../../Helper";
import { RangePropType } from "../../../../PropTypes";

const DistanceRangeFilter = props => {
    const { name, label, value, min, max, onChange, onInput, hideLabel, disabled = false } = props;
    let { unit } = props;

    let step = 1,
        labelFormatter = value => `${value} ${unit}`;

    // Convert between meters to kilometers if distance is longer than 1000m
    if (unit === "m" && max >= 1000) {
        unit = "km";
        step = 100; // 100m
        labelFormatter = value => `${roundBy(value / 1000)} ${unit}`;
    }

    return (
        <InputRangeSlider
            multiple={true}
            label={label}
            name={name}
            min={min}
            max={max}
            step={step}
            value={value}
            labelFormatter={labelFormatter}
            onChange={onChange}
            onInput={onInput}
            hideLabel={hideLabel}
            disabled={disabled}
        />
    );
};

DistanceRangeFilter.propTypes = {
    name: PropTypes.string.isRequired,
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    value: RangePropType.isRequired,
    unit: PropTypes.string,
    onChange: PropTypes.func,
    onInput: PropTypes.func,
    hideLabel: PropTypes.bool,
    disabled: PropTypes.bool,
};

export default DistanceRangeFilter;
