import { ErrorMessage } from "@r360/library";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { RootState } from "../..";
import { sortGroups, sortLettingItems } from "../../BusinessUtils";
import { LettingDesktop } from "../../assets/reservationTypes/Letting";
import GroupIncrementerCard from "../../components/GroupIncrementerCard";
import { GroupIncrementerCardSkeleton } from "../../components/GroupIncrementerCard/GroupIncrementerCardSkeleton";
import { NoSearchResult } from "../../components/NoSearchResult/NoSearchResult";
import { ReservationTypeHeader } from "../../components/ReservationTypeHeader/ReservationTypeHeader";
import { ReservationTypeImage } from "../../components/ReservationTypeImage/ReservationTypeImage";
import { newFilterGroupItems } from "../../hooks";
import useAppSelector from "../../hooks/useAppSelector";
import useChangeFilter from "../../hooks/useChangeFilter";
import useChangeSearch from "../../hooks/useChangeSearch";
import useTranslate from "../../hooks/useTranslate";
import { resvTypeByLid } from "../../selectors/Selectors";
import { TCriteriaItem, TGroup, TReservationResult, TReservationType, TReservationTypeLid } from "../../store/types";

import { useProductsNotAddedPath } from "../../hooks/useProductsNotAddedPath";

type TLettingPage = {
    reservationTypeid: TReservationTypeLid;
};

export const LettingPage = ({ reservationTypeid }: TLettingPage) => {
    const filter = useAppSelector((state: RootState) => state.filter);
    const activeFilters = filter[reservationTypeid];
    const { requestsLoading } = useAppSelector((state: RootState) => state.axiosStatus);
    const isLoading = requestsLoading["fetchReservationTypeResults"];
    const reservationTypeResult = useAppSelector((state: RootState) => state.reservationResult);
    const lettingResult: TReservationResult = reservationTypeResult[reservationTypeid];
    const [filteredData, setFilteredData] = useState<TGroup[]>([]);
    const [addToCartDataQuantity, setAddToCartDataQuantity] = useState<{ [key: number]: number }>({});
    const [numberOfDaysSearched, setNumberOfDaysSearched] = useState(0);
    const reservationType: TReservationType | undefined = useAppSelector((state: RootState) =>
        resvTypeByLid(state, reservationTypeid)
    );
    const reservationTypeCriterias = reservationType?.criterias || {};
    const addToCartTotalQuantity = Object.values(addToCartDataQuantity).reduce((acc, item) => acc + item, 0);
    const showProductsNotAddedWarning = !!addToCartTotalQuantity;
    const t = useTranslate();

    useChangeSearch({
        reservationResultSearch: lettingResult?.search,
        reservationTypeId: reservationTypeid,
        skipGuestsOnSearchWithPrice: true,
        allowSearchWithoutPrice: false,
    });

    useProductsNotAddedPath(showProductsNotAddedWarning);
    useChangeFilter(reservationTypeid);

    useEffect(() => {
        if (lettingResult?.groups) {
            const arrDate = lettingResult.search && moment(lettingResult.search?.arrdate);
            const depDate = lettingResult.search && moment(lettingResult.search?.depdate);
            setNumberOfDaysSearched(depDate.diff(arrDate, "days") + 1);
        } else {
            setNumberOfDaysSearched(0);
        }
    }, [lettingResult]);

    // Clear addToCartData when a new search has been made
    useEffect(() => {
        setAddToCartDataQuantity({});
    }, [lettingResult?.resultSetId]);

    useEffect(() => {
        const criterias = Object.values(reservationTypeCriterias)
            .flat()
            .reduce((acc, curr) => {
                acc[curr.code] = curr;
                return acc;
            }, {} as { [key: number]: TCriteriaItem });

        let filteredData = Object.values(lettingResult?.groups || {})
            .map((group: TGroup) => {
                return newFilterGroupItems(group, activeFilters || {}, criterias);
            })
            .filter(group => !!group.items.length);

        // Sort letting groups and items.
        filteredData = sortGroups(filteredData).map(group => {
            group.items = sortLettingItems(group.items);
            return group;
        });

        setFilteredData(filteredData);
    }, [lettingResult?.groups, activeFilters]);

    const renderLettingList = () => {
        if (isLoading) {
            return [...Array(4)].map((_, i) => <GroupIncrementerCardSkeleton key={i} />);
        } else if (filteredData?.length && !isLoading) {
            return filteredData.map(({ items }) => {
                return (
                    <GroupIncrementerCard
                        key={items[0].id}
                        title={items[0].title}
                        description={items[0].weblong ?? ""}
                        promotion={items[0].promotionCode !== null}
                        thumbnailUrl={items[0].images[0]?.url}
                        items={items}
                        ageCategoryTitle="agecategory"
                        fallbackImage={LettingDesktop}
                        numberOfDaysSearched={numberOfDaysSearched}
                        onAddToCartQuantityChange={data =>
                            setAddToCartDataQuantity(prevState => {
                                if (prevState[items[0].id] === data) {
                                    return prevState;
                                }

                                return { ...prevState, [items[0].id]: data };
                            })
                        }
                    />
                );
            });
        } else if ((lettingResult?.hitsMatchingFilter === "0" || !filteredData?.length) && !isLoading) {
            return (
                <div className="u-pt-30">
                    <NoSearchResult />
                </div>
            );
        } else if (lettingResult?.error && !isLoading) {
            return (
                <div className="u-pt-24">
                    <ErrorMessage
                        headingText={t("book.general.error")}
                        primaryButtonText={t("book.general.reload_page")}
                        primaryButtonOnClick={() => window.location.reload()}
                    />
                </div>
            );
        }
    };

    const showFilter =
        lettingResult?.groups &&
        reservationTypeCriterias &&
        Object.values(lettingResult.groups).length > 0 &&
        Object.values(reservationTypeCriterias).flat().length > 0;

    return lettingResult?.search || isLoading ? (
        <>
            {/* <ProductsNotAddedToCartModal readyToShow={showProductsNotAddedWarning} /> */}
            <ReservationTypeHeader
                reservationTypeId={reservationTypeid}
                hits={filteredData?.length ?? 0}
                filtersAvailable={showFilter}
            />
            {renderLettingList()}
        </>
    ) : (
        <ReservationTypeImage reservationTypeId={reservationTypeid} />
    );
};
