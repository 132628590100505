import React from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";
import { confirmable, createConfirmation } from "react-confirm";
import "./AlertDialog.scss";

/**
 * Open alert dialog with OK button
 *
 * @param {object} props
 * @returns Promise
 */
const Alert = props => {
    const { okLabel = "OK", title, description, show, proceed, enableEscape = true } = props;

    return (
        <div>
            <Modal
                show={show}
                onHide={() => proceed(false)}
                backdrop={enableEscape ? true : "static"}
                keyboard={enableEscape}
            >
                {title && (
                    <Modal.Header>
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>
                )}
                {description && <Modal.Body className="confirmation-dialog__body">{description}</Modal.Body>}
                <Modal.Footer>
                    <Button className="confirmation-dialog__buttons-ok" variant="primary" onClick={() => proceed(true)}>
                        {okLabel}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

Alert.propTypes = {
    okLabel: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    enableEscape: PropTypes.bool,

    // Below props are provided by confirmable, don't change.
    show: PropTypes.bool,
    proceed: PropTypes.func,
};

export default function alertDialog(options = {}) {
    return createConfirmation(confirmable(Alert))(options);
}
