import React, { useState } from "react";
import { Form } from "react-bootstrap";

import "./TextField.scss";

// Text field component
const TextField = props => {
    const {
        id,
        className,
        type = "text",
        label = "Form label",
        placeholder = "Placeholder text",
        errorMessage = "The field is empty",
        autoComplete = "off",
        hideLabel = false,
        disabled = false,
        required = false,
        onChange,
    } = props;
    const [state, setState] = useState(props.value || "");
    const [invalid, setInvalid] = useState(false);
    const classes = className ? `text-field ${className}` : "text-field";

    const handleChange = event => {
        setState(event.target.value);
        if (onChange) {
            onChange(event.target.value);
        }
    };

    return (
        <Form.Group controlId={id} className={classes}>
            <Form.Label className={hideLabel ? "sr-only" : ""}>{label}</Form.Label>
            <Form.Control
                required={required}
                type={type}
                value={state}
                placeholder={placeholder}
                autoComplete={autoComplete}
                disabled={disabled}
                onChange={handleChange}
                isInvalid={invalid && !state ? true : false}
                onBlur={() => (!state && required ? setInvalid(true) : setInvalid(false))}
                aria-describedby={`${id}_error-message`}
                aria-invalid={!state ? true : false}
            />
            <Form.Text className={hideLabel ? "sr-only" : ""} id={`${id}_error-message`}>
                {errorMessage}
            </Form.Text>
        </Form.Group>
    );
};

export default TextField;
