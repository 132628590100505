import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { sortLettings } from "../../BusinessUtils";
import * as Constants from "../../Constants";
import { getDateObjectForRequests } from "../../hooks";
import useQueryString from "../../hooks/QueryString/useQueryString";
import useChangeSearch from "../../hooks/useChangeSearch";
import { optionsSelector } from "../../selectors/Selectors";
import ReservationTypesLoader from "../ContentLoaderOld/ReservationTypesLoader";
import PromotionCodeInput from "../PromotionCodeInputOld/PromotionCodeInput";
import ReservationTypeDateFilter from "../ReservationTypeDateFilter";
import ReservationTypeNoResults from "../ReservationTypeNoResults";
import { Button, DropdownButton, Icon, InformationBlock } from "../UI";
import LettingItem from "./LettingItem/LettingItem";
import "./OldLettingView.scss";
import { textsSelector } from "../../Selectors";

const LettingView = props => {
    const params = useParams();
    const { reservationTypeid } = props.reservationTypeid ? props : params;
    const filters = useSelector(state => state.filter);
    const promotion = useSelector(state => state.clientData?.options?.module?.promotion);
    const isMobile = useSelector(state => state.window.isMobile);
    const isLoading = useSelector(state => state.axiosStatus.loading);
    const texts = useSelector(textsSelector);
    const reservationTypeResult = useSelector(state => state.reservationResult);
    const clientData = useSelector(state => state.clientData);
    const lettings = reservationTypeResult[reservationTypeid];
    const generalAges = JSON.parse(clientData.options?.general?.ages);
    const [age, setAge] = useState({});
    const [startValue, onSetStartDate] = useQueryString("startDate");
    const [endValue, onSetEndDate] = useQueryString("endDate");
    const navigate = useNavigate();
    const newDesignMenu = useSelector(optionsSelector).layout?.new_design_menu;

    useChangeSearch({
        reservationResultSearch: lettings?.search,
        reservationTypeId: reservationTypeid,
        skipGuestsOnSearchWithPrice: true,
        allowSearchWithoutPrice: false,
    });

    const dates = getDateObjectForRequests({ startDate: startValue, endDate: endValue }, lettings?.search, true);

    const [startDate, setStartDate] = useState(moment(dates.startDate));
    const [endDate, setEndDate] = useState(moment(dates.endDate));
    const [singleDate, setSingleDate] = useState(startDate.isValid() && endDate.isValid() && startDate.isSame(endDate));

    useEffect(() => {
        if (reservationTypeid) {
            const startDate = moment(dates.startDate);
            const endDate = moment(dates.endDate);
            setStartDate(startDate);
            setEndDate(endDate);
            setSingleDate(startDate.isValid() && endDate.isValid() && startDate.isSame(endDate));
        }
        // eslint-disable-next-line
    }, [reservationTypeid]);

    const renderDatePicker = () => {
        return (
            <ReservationTypeDateFilter
                texts={texts}
                isMobile={isMobile}
                reservationTypeid={reservationTypeid}
                promoCode={filters.promoCode}
                initialStartDate={startDate}
                initialEndDate={endDate}
                isSingleDate={singleDate}
                onSetSingledate={single => {
                    setSingleDate(single);
                }}
                onSetStartDate={date => {
                    setStartDate(moment(date));
                    onSetStartDate(date);
                }}
                onSetEndDate={date => {
                    setEndDate(moment(date));
                    onSetEndDate(date);
                }}
                onDatesChange={() => {
                    // The history needs to be updated in a Reactive way instead of natively as
                    // setStartDate and setEndDate does. Otherwise the useChangeSearch hook won't trigger.
                    // Remove everything before and including /client from beginning of pathname
                    // to access the root path of the React application when navigating.
                    const pathname = window.location.pathname.replace(/^.*\/client/i, "");
                    const search = window.location.search;
                    navigate(`${pathname}${search}`, { replace: true });
                }}
            />
        );
    };

    const renderAgePicker = () => {
        const showAllButtonClass =
            Object.keys(age).length === 0
                ? "skipass-view__list-button skipass-view__list-button--active"
                : "skipass-view__list-button";
        return (
            <DropdownButton
                closeOnClick
                text={age?.key ? texts?.[age.title] : texts?.generalshowall}
                className="button--dropdown activities-view__filters-dropdown"
            >
                <ul className="activities-view__list-menu">
                    <li className="activities-view__list-button" key={`age-all`}>
                        <Button
                            text={texts?.generalshowall}
                            className={showAllButtonClass}
                            onClick={() => setAge({})}
                        />
                    </li>
                    {generalAges[reservationTypeid]?.map((item, index) => {
                        const buttonClass =
                            item.key === age.key
                                ? "activities-view__list-button activities-view__list-button--active"
                                : "activities-view__list-button";
                        return (
                            <li className="activities-view__list-item" key={`age-${index}`}>
                                <Button
                                    text={texts?.[item.title]}
                                    className={buttonClass}
                                    onClick={() => setAge(item)}
                                />
                            </li>
                        );
                    })}
                </ul>
            </DropdownButton>
        );
    };

    // Render options list
    const renderLettingList = () => {
        let lettingsArray = [];

        // Add groups to array if any
        if (lettings?.groups) {
            // Flatten multidimensional Object to a single array
            lettingsArray = [].concat(...Object.values(lettings.groups).map(group => group.items));
        }

        if (lettingsArray.length) {
            lettingsArray = lettingsArray.filter(item => {
                return age.key
                    ? // All ages...
                      (parseInt(item.maxage) <= age.max && parseInt(item.maxage) >= age.min) ||
                          // ...or if max or min age is within age span
                          (parseInt(item.minage) >= age.min && parseInt(item.minage) <= age.max)
                    : true;
            });

            lettingsArray = sortLettings(lettingsArray);

            return lettingsArray.map(item => {
                return (
                    <LettingItem
                        item={item}
                        cartProducts={props.cartProducts}
                        generalAges={generalAges}
                        promoCode={filters.promoCode}
                        key={`${item.type}_${item.poollid}_${item.pricecode}_${item.price}_${item.sortorder}_${item.days}_${item.minage}_${item.maxage}`}
                        index={`${item.type}_${item.poollid}_${item.pricecode}_${item.price}_${item.sortorder}_${item.days}_${item.minage}_${item.maxage}`}
                    />
                );
            });
        } else if ((lettings?.hitsMatchingFilter === "0" || !lettingsArray.length) && !isLoading) {
            return (
                <div className="col">
                    {lettings && <ReservationTypeNoResults resvtype={Constants.productTypeNames.LETTING} />}
                </div>
            );
        } else if (lettings?.error && !isLoading) {
            return (
                <div className="col">
                    <Alert variant="light" className="letting-view__alert--fetch-error mt-3">
                        <div className="letting-view__alert-icon">
                            <Icon name="FaExclamationTriangle" size={40} color="#e04f5f" />
                        </div>
                        <div className="letting-view__alert-text">
                            <h3 className="h6">{texts?.noresultnetworkheader}</h3>
                            <p>{texts?.noresultnetworkbody}</p>
                            <Button
                                onClick={window.location.reload}
                                className="button--text-only letting-view__alert-button"
                            >
                                <Icon name="FaUndoAlt" size={18} color="#333333" />
                                <span className="sr-only">
                                    {texts?.noresultgetdata} {props.header}
                                </span>
                            </Button>
                        </div>
                    </Alert>
                </div>
            );
        } else {
            return Array.from([1, 2, 3]).map((item, index) => {
                return (
                    <div className="col-sm-12 col-md-6 col-lg-4" key={"loader" + index}>
                        <ReservationTypesLoader />
                    </div>
                );
            });
        }
    };

    return (
        <div className="letting-view">
            <InformationBlock className="w-100">{texts?.lettinginformation}</InformationBlock>
            {!newDesignMenu && (
                <div className="letting-view__filters-container">
                    <div className="letting-view__filters">
                        <div className="letting-view__filters-item">{renderDatePicker()}</div>
                        <div className="letting-view__filters-item">{renderAgePicker()}</div>
                    </div>
                    {!newDesignMenu && promotion && <PromotionCodeInput promoCode={filters.promoCode} texts={texts} />}
                </div>
            )}
            <div className="letting-view__options row" aria-live="polite">
                {renderLettingList()}
            </div>
        </div>
    );
};

export default LettingView;
