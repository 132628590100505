import React from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { resetCart } from "../../store/actions/checkoutCart";
import { resetGuests } from "../../store/actions/guests";
import { resetReservation } from "../../store/actions/reservation";
import { confirmationDialog } from "../UI";
import "./ChangeReservationBar.scss";
import { textsSelector } from "../../Selectors";

const ChangeReservationBar = ({ reservationId }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const texts = useSelector(textsSelector);

    const openCancelConfirmation = async () => {
        const response = await confirmationDialog({
            title: texts["change_reservation_bar.confirmation.title"],
            description: texts["change_reservation_bar.confirmation.description"] + "\n- " + reservationId,
            okLabel: texts["general.confirmation.continue"],
            cancelLabel: texts["general.confirmation.cancel"],
        });

        if (response) {
            const navigateToStart =
                ["/checkout", "/checkout/summary", "/reservation/extras"].includes(location?.pathname) ||
                location?.pathname.startsWith("/pay/");

            if (navigateToStart) {
                navigateToStart && navigate("/", { replace: true });
            }

            dispatch(resetCart());
            dispatch(resetReservation());
            dispatch(resetGuests());
        }
    };

    return (
        <div className="change-reservation-bar">
            <div className="container">
                <Row>
                    <Col className="py-3 text-center">
                        <span className="me-4">
                            {texts["change_reservation_bar.description"]} {reservationId}
                        </span>
                        <button className="btn btn-sm btn-light" onClick={openCancelConfirmation}>
                            {texts["change_reservation_bar.cancel_button"]}
                        </button>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default ChangeReservationBar;
