import React, { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import { Formik, FormikErrors, FormikHelpers, FormikProps, FormikTouched, getIn } from "formik";
import * as yup from "yup";
import { useSelector } from "react-redux";
import "../../YupExtensions";
import { PhoneCountryCodeSelect } from "../PhoneCountryCodeSelect/PhoneCountryCodeSelect";
import { isNumberWithoutDecimals } from "../../Helper";
import moment from "moment-timezone";
import debounce from "lodash/debounce";
import useFormPhoneNumberValidation from "../../hooks/useFormPhoneNumberValidation";
import { RootState } from "../..";
import { Button, Input } from "@r360/library";
import useTranslate from "../../hooks/useTranslate";
import { DaySelect, MonthSelect, YearSelect } from "../BirthDateSelect";
import { CountrySelect } from "../CountrySelect";
import { TClientCountry } from "../../store/types";
import { TPersonalInformationGuest } from "./PersonalInformation";

type TPersonalDetailsInformation = {
    isPrimaryGuest: boolean;
    isPrimaryIfBookingForOthers?: boolean;
    newGuest?: boolean;
    guest?: TPersonalInformationGuest;
    onChange?: (arg1: string, arg2: string | number, arg3?: () => void) => void;
    onCancel?: () => void;
    onSubmit?: (arg0: TPersonalDetailsForm) => void;
    showSubmitButton?: boolean;
    disabled?: boolean;
    showAgePicker?: boolean;
    skipValidation?: boolean;
    onLiveValidation?: (arg0: boolean) => void;
};

export type TPersonalDetailsInformationHandle = {
    submitForm: () => void;
    resetForm: () => void;
    resetTouched: () => void;
    getValues: () => void;
    getIsValid: () => boolean;
    setFieldValue: () => void;
};

export interface TPersonalDetailsForm extends Record<string, string | number> {
    id: number;
    firstname: string;
    lastname: string;
    email: string;
    phoneprefix: number;
    phone: string;
    year: number;
    month: number;
    day: number;
    address: string;
    postalcode: number;
    city: string;
    country: string;
}

type TFormikTouched = boolean | FormikTouched<any> | FormikTouched<any>[] | undefined;
type TFormikErrors = string | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined;

const PersonalDetailsInformation = forwardRef(
    (
        {
            isPrimaryGuest,
            isPrimaryIfBookingForOthers = false,
            newGuest,
            guest,
            onChange,
            onCancel,
            onSubmit,
            showSubmitButton,
            disabled,
            showAgePicker = true,
            skipValidation = false,
            onLiveValidation,
        }: TPersonalDetailsInformation,
        ref
    ) => {
        const t = useTranslate();
        const countries = useSelector((state: RootState) => state.clientData?.country) as TClientCountry[];
        const formikRef = useRef<FormikProps<TPersonalDetailsForm>>(null);

        const { phoneValid, validatePhone, validatePhonePrefix } = useFormPhoneNumberValidation(
            isPrimaryGuest || isPrimaryIfBookingForOthers,
            newGuest || !!guest?.phone || false,
            newGuest || !!guest?.phoneprefix || false,
            () => {
                //  formikRef.current?.setFieldTouched("phoneprefix", true, false);
            },
            () => {
                formikRef.current?.setFieldTouched("phone", true, false);
            }
        ) as {
            phoneValid: boolean;
            phonePrefixValid: boolean;
            validatePhone: (arg0: string | number, arg1: string | number) => boolean;
            validatePhonePrefix: (arg0: string | number, arg1: string | number) => boolean;
        };

        // Force live validation when phoneIsValid changes to ensure the live validation has the latest phoneValid state
        useEffect(() => {
            debouncedLiveValidation(phoneValid);
            //eslint-disable-next-line
        }, [phoneValid]);

        // Force form validation when isPrimaryIfBookingForOthers changes. When changing to isPrimaryIfBookingForOthers, some fields are suddenly required and vice versa.
        useEffect(() => {
            formikRef.current?.validateForm().then(errors => {
                Object.keys(errors).forEach(fieldWidthError => {
                    // Fields will only show invalid if they´re touched
                    const fieldsToTouch = ["phoneprefix, phone", "email"];
                    // Touch the specified fields
                    if (!formikRef.current?.touched[fieldWidthError] && fieldsToTouch.includes(fieldWidthError)) {
                        formikRef.current?.setFieldTouched(fieldWidthError, true, false);
                    }
                });

                if (isPrimaryIfBookingForOthers) {
                    !guest?.phoneprefix && formikRef.current?.setFieldTouched("phoneprefix", true, false);
                    !guest?.phone && formikRef.current?.setFieldTouched("phone", true, false);
                }
            });
            if (guest) validatePhone(guest?.phoneprefix, guest?.phone);
            debouncedLiveValidation();
            //eslint-disable-next-line
        }, [isPrimaryIfBookingForOthers]);

        useImperativeHandle(ref, () => ({
            submitForm: formikRef.current?.submitForm,
            resetForm: formikRef.current?.resetForm,
            resetTouched: () => formikRef.current?.setTouched({}),
            getValues: getParsedValues,
            getIsValid: () => formikRef.current?.isValid,
            setFieldValue: formikRef.current?.setFieldValue,
        }));

        const validationSchema = yup.object().shape({
            ...(guest && {
                id: yup.number().required(),
            }),
            firstname: yup.string().required(),
            lastname: yup.string().required(),
            email:
                isPrimaryGuest || isPrimaryIfBookingForOthers ? yup.string().email().required() : yup.string().email(),
            ...(showAgePicker && {
                year: yup.number().required(),
                month: yup.number().required(),
                day: yup.number().required(),
            }),
            ...(isPrimaryGuest && {
                address: yup.string().required(),
                postalcode: yup.string().required(),
                city: yup.string().required(),
                country: yup.string().required(),
            }),
        });

        const debouncedOnChange = debounce(
            (fieldName, fieldValue, restorePreviousValue) => {
                onChange?.(fieldName, fieldValue, restorePreviousValue);
            },

            250
        );

        const debouncedLiveValidation = debounce((valid?: boolean) => {
            let formikAndPhoneIsValid = false;

            // If valid is true, only return true if the formik validation also passes
            // For example, when the phone changes and validates it calls this function with valid = true
            // So we know the phone is valid but we also check the formik validation
            if (valid && formikRef?.current?.isValid) {
                formikAndPhoneIsValid = true;
                // Always return false if the value is false
            } else if (valid === false) {
                formikAndPhoneIsValid = false;
            } else {
                // Fall back to checking the formik validation and the phone validation (if phone validation is required)
                formikAndPhoneIsValid =
                    isPrimaryGuest || isPrimaryIfBookingForOthers
                        ? !!formikRef.current?.isValid && phoneValid
                        : !!formikRef.current?.isValid;
            }

            onLiveValidation?.(formikAndPhoneIsValid);
        }, 250);

        const triggerOnChangeCallbackIfValid = (fieldName: string, fieldValue: string) => {
            if (!onChange) {
                return;
            }

            const previousValue = formikRef.current?.values[fieldName];
            const restorePreviousValue = () => formikRef.current?.setFieldValue(fieldName, previousValue);

            if (["year", "month", "day"].includes(fieldName)) {
                const dateFormat = "YYYY-MM-DD";
                const values = { ...formikRef.current?.values };

                const birthdayValues: { [key: string]: string } = {
                    year: values.year?.toString() ?? "",
                    month: values.month?.toString() ?? "",
                    day: values.day?.toString() ?? "",
                };

                birthdayValues[fieldName] = fieldValue;
                const isValidDate = !Object.values(birthdayValues).includes("");

                if (isValidDate) {
                    const birthday = moment()
                        .set({
                            year: parseInt(birthdayValues.year),
                            month: parseInt(birthdayValues.month) - 1, // Remove 1 since MonthSelect-component starts from 1 not 0
                            date: parseInt(birthdayValues.day),
                        })
                        .format(dateFormat);

                    onChange("birthday", birthday, restorePreviousValue);
                }

                return;
            }

            validationSchema
                .validateAt(fieldName, { [fieldName]: fieldValue })
                .then(() => {
                    debouncedLiveValidation?.();
                    debouncedOnChange(fieldName, fieldValue, restorePreviousValue);
                })
                .catch(() => {
                    // Validation of field didn't pass. Good when debugging invalid fields.
                    debouncedLiveValidation?.(false);
                });
        };

        const getParsedValues = (values: any) => {
            const valuesClone = { ...values }; // Changed to values instead of formikRef.current.values since ref had previous values

            valuesClone.birthday = moment()
                .set({
                    year: valuesClone.year,
                    month: valuesClone.month - 1, // Remove 1 since MonthSelect-component starts from 1 not 0
                    date: valuesClone.day,
                })
                .format("YYYY-MM-DD");

            delete valuesClone.year;
            delete valuesClone.month;
            delete valuesClone.day;

            return valuesClone as TPersonalDetailsForm;
        };

        const customHandleSubmit = (values: TPersonalDetailsForm, formikBag: FormikHelpers<TPersonalDetailsForm>) => {
            if (newGuest && !values.phoneprefix && !values.phone) {
                // OK, optional
            } else {
                const phoneErrors: { phoneprefix: string; phone: string } = { phoneprefix: "", phone: "" };

                if (!validatePhonePrefix(values.phoneprefix, values.phone)) {
                    phoneErrors.phoneprefix = "Invalid phone prefix";
                }

                if (!validatePhone(values.phoneprefix, values.phone)) {
                    phoneErrors.phone = "Invalid phone number";
                }

                if (phoneErrors && (phoneErrors.phoneprefix || phoneErrors.phone)) {
                    formikBag.setErrors(phoneErrors);
                    return;
                }
            }

            onSubmit?.(getParsedValues(values));
        };

        const birthdayMoment = moment(guest?.birthday || "");

        const isFieldValid = (touched: TFormikTouched, errors: TFormikErrors) => {
            if (touched) {
                if (!errors) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return undefined;
            }
        };

        const isFieldRequired = (name: string) => {
            const schemaDescription = validationSchema.describe();
            const accessor = name.split(".").join(".fields.");
            const field = getIn(schemaDescription.fields, accessor);
            if (!field) {
                return false;
            }
            const isRequired = field.tests.some((test: any) => test.name === "required") || !field.optional;
            return isRequired;
        };

        return (
            <div>
                <Formik
                    innerRef={formikRef}
                    validationSchema={!skipValidation ? validationSchema : null}
                    onSubmit={customHandleSubmit}
                    initialValues={
                        {
                            ...(guest && {
                                id: guest?.addrlid || guest?.id,
                            }),
                            firstname: guest?.firstname || guest?.name1 || "",
                            lastname: guest?.lastname || guest?.name2 || "",
                            email: guest?.email || "",
                            phoneprefix: guest?.phone3prefix || guest?.phoneprefix || "",
                            phone: guest?.phone3 || guest?.phone || "",
                            ...(showAgePicker && {
                                year: birthdayMoment.isValid() ? birthdayMoment.year() : "",
                                month: birthdayMoment.isValid() ? birthdayMoment.month() + 1 : "", // Add 1 since MonthSelect-component starts from 1 not 0
                                day: birthdayMoment.isValid() ? birthdayMoment.date() : "",
                            }),
                            ...(isPrimaryGuest && {
                                address: guest?.addr1 || "",
                                postalcode: guest?.addr3 || parseInt(""),
                                city: guest?.addr4 || "",
                                country: guest?.country || guest?.addr5 || "",
                            }),
                        } as TPersonalDetailsForm
                    }
                >
                    {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        errors,
                        setFieldValue,
                        setFieldTouched,
                        setFieldError,
                        isValid,
                    }) => {
                        return (
                            <form className="row" onSubmit={handleSubmit} noValidate>
                                <div className="col-12 u-mb-0">
                                    <div className="row u-bs-gutters-6 u-mb-12">
                                        <div className="col-12 col-lg-6 col-xl-4 u-mb-12 u-mb-xl-0">
                                            <Input
                                                label={t("book.account.firstname")}
                                                required
                                                name="firstname"
                                                value={values.firstname}
                                                isValid={isFieldValid(touched.firstname, errors.firstname)}
                                                onChange={e => {
                                                    handleChange(e);
                                                    triggerOnChangeCallbackIfValid(e.target.name, e.target.value);
                                                }}
                                                onBlur={handleBlur}
                                                disabled={disabled}
                                                showAsterisk={isFieldRequired("firstname")}
                                            />
                                        </div>
                                        <div className="col-12 col-lg-6 col-xl-4 u-mb-12 u-mb-xl-0">
                                            <Input
                                                label={t("book.account.lastname")}
                                                required
                                                name="lastname"
                                                id="formGroupFirstName"
                                                value={values.lastname}
                                                isValid={isFieldValid(touched.lastname, errors.lastname)}
                                                onChange={e => {
                                                    handleChange(e);
                                                    triggerOnChangeCallbackIfValid(e.target.name, e.target.value);
                                                }}
                                                onBlur={handleBlur}
                                                disabled={disabled}
                                                showAsterisk={isFieldRequired("lastname")}
                                            />
                                        </div>
                                        {showAgePicker && (
                                            <div className="col-12 col-xl-4 u-mb-0">
                                                <div className="row u-bs-gutters-6">
                                                    <div className="col-4 u-mb-0">
                                                        <YearSelect
                                                            name="year"
                                                            value={values.year}
                                                            onChange={(selectedOption, name) => {
                                                                setFieldTouched(name, true);
                                                                setFieldValue(name, selectedOption.value);
                                                                triggerOnChangeCallbackIfValid(
                                                                    name,
                                                                    selectedOption.value.toString()
                                                                );
                                                            }}
                                                            isValid={isFieldValid(touched.year, errors.year)}
                                                            showAsterisk={isFieldRequired("year")}
                                                        />
                                                    </div>
                                                    <div className="col-4 u-mb-0">
                                                        <MonthSelect
                                                            name="month"
                                                            value={values.month}
                                                            year={values.year}
                                                            onChange={(selectedOption, name) => {
                                                                setFieldTouched(name, true);
                                                                setFieldValue(name, selectedOption.value);
                                                                triggerOnChangeCallbackIfValid(
                                                                    name,
                                                                    selectedOption.value.toString()
                                                                );
                                                            }}
                                                            isValid={isFieldValid(touched.month, errors.month)}
                                                            showAsterisk={isFieldRequired("month")}
                                                        />
                                                    </div>
                                                    <div className="col-4 u-mb-0">
                                                        <DaySelect
                                                            name="day"
                                                            value={values.day}
                                                            year={values.year}
                                                            month={values.month}
                                                            onChange={(selectedOption, name) => {
                                                                setFieldTouched(name, true);
                                                                setFieldValue(name, selectedOption.value);
                                                                triggerOnChangeCallbackIfValid(
                                                                    name,
                                                                    selectedOption.value.toString()
                                                                );
                                                            }}
                                                            isValid={isFieldValid(touched.day, errors.day)}
                                                            showAsterisk={isFieldRequired("day")}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    {isPrimaryGuest && (
                                        <div className="row u-bs-gutters-6">
                                            <div className="col-12 col-lg-5 col-xl-4">
                                                <Input
                                                    label={t("book.account.address")}
                                                    required
                                                    name="address"
                                                    value={values.address}
                                                    isValid={isFieldValid(touched.address, errors.address)}
                                                    onChange={e => {
                                                        handleChange(e);
                                                        triggerOnChangeCallbackIfValid(e.target.name, e.target.value);
                                                    }}
                                                    onBlur={handleBlur}
                                                    disabled={disabled}
                                                    showAsterisk={isFieldRequired("address")}
                                                />
                                            </div>
                                            <div className="col-6 col-lg-4 col-xl-2">
                                                <Input
                                                    label={t("book.account.postalcode")}
                                                    required
                                                    name="postalcode"
                                                    value={values.postalcode}
                                                    isValid={touched.postalcode && !errors.postalcode}
                                                    onChange={e => {
                                                        handleChange(e);
                                                        triggerOnChangeCallbackIfValid(e.target.name, e.target.value);
                                                    }}
                                                    onBlur={handleBlur}
                                                    disabled={disabled}
                                                    showAsterisk={isFieldRequired("postalcode")}
                                                />
                                            </div>
                                            <div className="col-6 col-lg-3">
                                                <Input
                                                    label={t("book.account.city")}
                                                    required
                                                    name="city"
                                                    value={values.city}
                                                    isValid={touched.city && !errors.city}
                                                    onChange={e => {
                                                        handleChange(e);
                                                        triggerOnChangeCallbackIfValid(e.target.name, e.target.value);
                                                    }}
                                                    onBlur={handleBlur}
                                                    disabled={disabled}
                                                    showAsterisk={isFieldRequired("city")}
                                                />
                                            </div>
                                            <div className="col-12 col-xl-3">
                                                <CountrySelect
                                                    countries={countries}
                                                    name="country"
                                                    value={values.country}
                                                    onChange={(selectedOption, name) => {
                                                        setFieldTouched("country", true);
                                                        setFieldValue("country", selectedOption.value);
                                                        triggerOnChangeCallbackIfValid(
                                                            name,
                                                            selectedOption.value.toString()
                                                        );
                                                    }}
                                                    disabled={disabled}
                                                    isValid={touched.country && !errors.country}
                                                    showAsterisk={isFieldRequired("country")}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    <div className="row u-bs-gutters-6">
                                        <div className="col-12 col-lg-3 col-xl-4">
                                            <PhoneCountryCodeSelect
                                                name="phoneprefix"
                                                value={values.phoneprefix}
                                                isValid={
                                                    touched.phone && phoneValid && values.phoneprefix
                                                        ? true
                                                        : touched.phone && !phoneValid
                                                        ? false
                                                        : undefined
                                                }
                                                onChange={(selectedOption, name) => {
                                                    setFieldTouched(name, true);
                                                    setFieldValue(name, selectedOption.value);

                                                    if (validatePhonePrefix(selectedOption.value, values.phone)) {
                                                        onChange?.(name, selectedOption.value);

                                                        if (validatePhone(selectedOption.value, values.phone)) {
                                                            formikRef.current && debouncedLiveValidation?.(true);
                                                            onChange?.("phone", values.phone);
                                                        } else {
                                                            // We need to reset the phone number if it's not valid when changing the prefix
                                                            // or else the old (now invalid) phone number may have been saved on the guest.

                                                            debouncedLiveValidation?.(false);
                                                            setFieldError(
                                                                "phone",
                                                                "Phone number not compliant with prefix"
                                                            );
                                                            onChange?.("phone", "");
                                                        }
                                                    } else {
                                                        debouncedLiveValidation?.(false);
                                                    }
                                                }}
                                                disabled={disabled}
                                                showAsterisk={isPrimaryGuest || isPrimaryIfBookingForOthers}
                                            />
                                        </div>
                                        <div className="col-12 col-lg-4">
                                            <Input
                                                label={t("book.account.phone")}
                                                required={isPrimaryGuest || isPrimaryIfBookingForOthers}
                                                name="phone"
                                                type="tel"
                                                value={values.phone}
                                                isValid={
                                                    touched.phone && phoneValid
                                                        ? true
                                                        : touched.phone && !phoneValid
                                                        ? false
                                                        : undefined
                                                }
                                                errorMessage={t("book.account.phone.validation")}
                                                onChange={e => {
                                                    if (isNumberWithoutDecimals(e.target.value)) {
                                                        handleChange(e);
                                                        if (validatePhone(values.phoneprefix, e.target.value)) {
                                                            onChange?.("phone", e.target.value);
                                                            setTimeout(() => {
                                                                debouncedLiveValidation?.(true);
                                                            }, 0);
                                                        } else {
                                                            debouncedLiveValidation?.(false);

                                                            if (!errors.phone) {
                                                                setFieldError(
                                                                    "phone",
                                                                    "Phone number not compliant with prefix"
                                                                );
                                                            }
                                                        }
                                                    }
                                                }}
                                                onBlur={handleBlur}
                                                disabled={disabled}
                                                showAsterisk={isPrimaryGuest || isPrimaryIfBookingForOthers}
                                            />
                                        </div>
                                        <div className="col-12 col-lg-5 col-xl-4">
                                            <Input
                                                label={t("book.general.email")}
                                                required={isPrimaryGuest || isPrimaryIfBookingForOthers}
                                                name="email"
                                                type="email"
                                                value={values.email}
                                                isValid={isFieldValid(touched.email, errors.email)}
                                                errorMessage={t("book.account.email.validation")}
                                                onChange={e => {
                                                    handleChange(e);
                                                    triggerOnChangeCallbackIfValid(e.target.name, e.target.value);
                                                }}
                                                onBlur={handleBlur}
                                                disabled={disabled}
                                                showAsterisk={isFieldRequired("email")}
                                            />
                                        </div>
                                    </div>
                                    {showSubmitButton && (
                                        <div className="u-d-flex u-gap-24 u-justify-content-end u-pt-12">
                                            <>
                                                <Button type="tertiary" onClick={onCancel}>
                                                    {t("book.general.cancel")}
                                                </Button>
                                                <Button
                                                    submit
                                                    disabled={
                                                        !isValid || !validatePhone(values.phoneprefix, values.phone)
                                                    }
                                                >
                                                    {t("book.general.save")}
                                                </Button>
                                            </>
                                        </div>
                                    )}
                                </div>
                            </form>
                        );
                    }}
                </Formik>
            </div>
        );
    }
);

PersonalDetailsInformation.displayName = "PersonalDetailsInformation";

export default PersonalDetailsInformation;
