import classNames from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import { TItem } from "../../store/types";
import { GroupIncrementer } from "./GroupIncrementer";
import "./GroupIncrementerCard.scss";
import { GroupIncrementerSummary } from "./GroupIncrementerSummary";
import { useDispatch } from "react-redux";
import { addProductCount } from "../../store/actions/checkoutCart";
import useTranslate from "../../hooks/useTranslate";
import { ExpandableText, OptimizedImage, Ribbon, Tag, getOptimizedImagePathFromUrl } from "@r360/library";
import { RichText } from "../UI";
import { getInspectionDataForProduct } from "../../BusinessUtils";

export type TAddToCartData = {
    item: TItem;
    quantity: number;
};

type TGroupIncrementerCard = {
    category?: string;
    title: string;
    description: string;
    days?: number[];
    promotion: boolean;
    thumbnailUrl?: string;
    items: TItem[];
    ageCategoryTitle?: "title" | "agecategory";
    fallbackImage?: any;
    numberOfDaysSearched?: number;
    onAddToCartQuantityChange?: (arg0: number) => void;
};
export const GroupIncrementerCard = ({
    category,
    title,
    description,
    days,
    promotion,
    thumbnailUrl,
    items,
    ageCategoryTitle = "title",
    fallbackImage,
    numberOfDaysSearched,
    onAddToCartQuantityChange,
}: TGroupIncrementerCard) => {
    const [addToCartData, setAddToCartData] = useState<{ [key: number]: TAddToCartData }>({});
    const dispatch = useDispatch();
    const [addToCartSuccess, setAddToCartSuccess] = useState(false);
    const t = useTranslate();
    const showDays = days !== undefined;

    const addToCartDataQuantity = Object.values(addToCartData).reduce((acc, item) => {
        return acc + item.quantity;
    }, 0);

    useEffect(() => {
        onAddToCartQuantityChange && onAddToCartQuantityChange(addToCartDataQuantity);
    }, [addToCartDataQuantity]);

    const changeGroupQuantity = useCallback(
        (item: TItem, quantity: number) => {
            setAddToCartData(prevState => {
                if (prevState && quantity > 0) {
                    return { ...prevState, [item.id]: { item, quantity } };
                }
                const { [item.id]: _, ...rest } = prevState;
                return rest;
            });
        },
        [addToCartData]
    );

    const onAddToCart = () => {
        Object.values(addToCartData).forEach(({ item, quantity }) => {
            const itemWithGroupTitle = { ...item, groupTitle: title };
            dispatch(addProductCount(itemWithGroupTitle, quantity, true));
        });
        setAddToCartSuccess(true);
        setTimeout(() => {
            setAddToCartData({});
            setAddToCartSuccess(false);
        }, 1000);
    };

    const getAgeGroupTitle = (item: TItem) => {
        return {
            title: item.title,
            agecategory: item.agecategory ?? "",
        }[ageCategoryTitle];
    };

    const getRibbonText = () => {
        const allSame = days?.every((val, i, arr) => val === arr[0]);
        if (days) {
            if (days[0] === 0) {
                return "Säsongskort";
            } else if (allSame) {
                return `${days[0]} ${
                    days[0] === 1
                        ? t("book.general.day").toLocaleUpperCase()
                        : t("book.general.days").toLocaleUpperCase()
                }`;
            } else {
                const highest = Math.max(...days);
                const lowest = Math.min(...days);
                return `${lowest}–${highest} ${t("book.general.days").toLocaleUpperCase()}`;
            }
        }
        return "";
    };

    return (
        <article className="group-incrementer-card">
            <div className="group-incrementer-card__intro">
                <div className="group-incrementer-card__img-wrapper">
                    <OptimizedImage fallbackUrl={fallbackImage} path={getOptimizedImagePathFromUrl(thumbnailUrl)} />
                    {promotion ? (
                        <Tag
                            text={t("book.results.included_in_campaign")}
                            className="group-incrementer-card__campaign-badge"
                            size="mini"
                            theme="yellow"
                        />
                    ) : null}
                </div>
                <div className="group-incrementer-card__description">
                    {category && <div className="group-incrementer-card__label">{category}</div>}
                    <h2 className="group-incrementer-card__heading">{title}</h2>
                    <ExpandableText
                        text={description}
                        collapsedButtonText={t("book.general.show_more")}
                        expandedButtonText={t("book.general.show_less")}
                        collapsedMaxLength={200}
                        inlineButton
                        renderText={text => <RichText content={text} />}
                    />
                </div>
            </div>
            <div
                className={classNames("group-incrementer-card__groups-row", {
                    "group-incrementer-card__groups-row--center": items.length > 4,
                })}
            >
                {items.map(item => {
                    const quantity =
                        addToCartData && Object.values(addToCartData).find(x => x.item.id === item.id)?.quantity;

                    return (
                        <div key={item.id} className="group-incrementer-wrapper" {...getInspectionDataForProduct(item)}>
                            <GroupIncrementer
                                ageGroupTitle={getAgeGroupTitle(item)}
                                minAge={item.minage}
                                maxAge={item.maxage}
                                unitPrice={item.ordinaryprice}
                                unitCampaignPrice={item.price}
                                currency={item.curr}
                                quantity={quantity ?? 0}
                                onIncrementerChange={value => changeGroupQuantity(item, value)}
                            />
                        </div>
                    );
                })}
            </div>
            <GroupIncrementerSummary
                addToCartData={addToCartData ? Object.values(addToCartData) : []}
                onAddToCart={onAddToCart}
                addToCartSuccess={addToCartSuccess}
            />
            {showDays && <Ribbon text={getRibbonText()} />}
            {numberOfDaysSearched && numberOfDaysSearched > 0 && (
                <Ribbon
                    text={`${numberOfDaysSearched} ${
                        numberOfDaysSearched > 1 ? t("book.general.days") : t("book.general.day")
                    }`}
                />
            )}
        </article>
    );
};
