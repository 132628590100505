import ProductExtrasPage from "./pages/ProductExtrasPage";
import OldProductPage from "./pages/OldProductPage";
import AccommodationAreaPage from "./pages/AccommodationAreaPage/AccommodationAreaPage";
import PackagePage from "./pages/PackagePage";
import OldCheckoutPage from "./pages/CheckoutConnectPage/OldCheckoutPage";
import CheckoutConnectPage from "./pages/CheckoutConnectPage/CheckoutConnectPage";
import OldCheckoutConfirmationPage from "./pages/OldCheckoutConfirmationPage";
import CheckoutConfirmationPage from "./pages/CheckoutConfirmationPage";
import SkipassPage from "./pages/SkipassPage";
import AccountPage from "./pages/Account/AccountPage";
import MyAccount from "./pages/Account/MyAccount";
import MyGuests from "./pages/Account/MyGuests";
import MyBookings from "./pages/Account/MyBookings";
import OldBookingPage from "./pages/Account/BookingPage";
import BookingPage from "./pages/BookingPage";
import OldCheckoutSummaryPage from "./pages/CheckoutSummaryPage/OldCheckoutSummaryPage";
import CheckoutSummaryPage from "./pages/CheckoutSummaryPage/CheckoutSummaryPage";
import CheckoutPayPage from "./pages/CheckoutPayPage/CheckoutPayPage";
import OldCheckoutPayPage from "./pages/CheckoutPayPage/OldCheckoutPayPage";
import LogoutPage from "./pages/Account/LogoutPage";
import RegistrationPage from "./pages/RegistrationPage/RegistrationPage";
import OldRegistrationPage from "./pages/RegistrationPage/OldRegistrationPage";
import DeveloperPage from "./pages/DeveloperPage";
import SearchReservationTypesPage from "./pages/SearchReservationTypesPage";
import OldResetPasswordPage from "./pages/ResetPasswordPage/OldResetPasswordPage";
import ResetPasswordPage from "./pages/ResetPasswordPage/ResetpasswordPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import CartPage from "./pages/CartPage/CartPage";
import FastCheckoutPage from "./pages/FastCheckoutPage/FastCheckoutPage";
import OldFastCheckoutPage from "./pages/FastCheckoutPage/OldFastCheckoutPage";
import AccommodationPage from "./pages/AccommodationPage/AccommodationPage";
import LoginPage from "./pages/LoginPage/LoginPage";
import OldLoginPage from "./pages/LoginPage/OldLoginPage";
import { MyTicketsPage } from "./pages/MyTickets/MyTicketsPage";
import MyPages from "./pages/MyPages";
import AccommodationExtrasPage from "./pages/AccommodationExtrasPage/AccommodationExtrasPage";

// List routes that belong to My Page.
// We use this list to decide which routes to allow when an option general/mypage_only is enabled
export const myPageRouteNames = [
    "ProductExtrasPage",
    "CheckoutPage",
    "CheckoutSummaryPage",
    "CheckoutPayPage",
    "CheckoutConfirmationPage",
    "LoginPage",
    "LogoutPage",
    "ResetPasswordPage",
    "ForgotPasswordPage",
    "AccountPage",
    "MyAccount",
    "MyGuests",
    "MyBookings",
    "BookingPage",
    "CartPage",
    "MyPages",
    "BookingPage",
    "ConfirmationPage",
];

export const getRoutes = (
    newDesignAccommodation = false,
    newDesignLoginRegistration = false,
    newDesignCheckout = false
) => [
    {
        path: "/reservation/extras",
        requireAuth: false,
        name: "ProductExtrasPage",
        component: ProductExtrasPage,
    },
    {
        path: "/product/:reservationTypeid/:grouppoollid",
        requireAuth: false,
        name: "ProductPage",
        component: newDesignAccommodation ? AccommodationAreaPage : OldProductPage,
    },
    {
        path: "/product/:reservationTypeid/:grouppoollid/:poollid",
        requireAuth: false,
        name: "ProductPage",
        component: newDesignAccommodation ? AccommodationPage : OldProductPage,
    },
    {
        path: "/product/:reservationTypeid/:grouppoollid/:poollid/:unitlid",
        requireAuth: false,
        name: "ProductPage",
        component: newDesignAccommodation ? AccommodationPage : OldProductPage,
    },
    {
        path: "/product-extras/:reservationTypeid/:grouppoollid/:poollid/:unitlid/:id",
        requireAuth: false,
        name: "ProductExtrasPage",
        component: AccommodationExtrasPage,
    },
    {
        path: "/product/:reservationTypeid/:grouppoollid/:poollid/:unitlid/price_calendar",
        requireAuth: false,
        name: "ProductPage",
        component: newDesignAccommodation ? AccommodationAreaPage : OldProductPage,
    },
    {
        path: "/package/:reservationTypeid/:poollid",
        requireAuth: false,
        name: "PackagePage",
        component: PackagePage,
    },
    {
        path: "/checkout",
        requireAuth: true,
        name: "CheckoutPage",
        component: newDesignCheckout ? CheckoutConnectPage : OldCheckoutPage,
    },
    {
        path: "/fastcheckout",
        requireAuth: false,
        name: "FastCheckoutPage",
        component: newDesignCheckout ? FastCheckoutPage : OldFastCheckoutPage,
    },
    {
        path: "/user/login",
        requireAuth: false,
        name: "LoginPage",
        component: newDesignLoginRegistration ? LoginPage : OldLoginPage,
    },
    {
        path: "/user/resetpassword/:token",
        requireAuth: false,
        name: "ResetPasswordPage",
        component: newDesignLoginRegistration ? ResetPasswordPage : OldResetPasswordPage,
    },
    {
        path: "/user/forgotpassword",
        requireAuth: false,
        name: "ForgotPasswordPage",
        component: ForgotPasswordPage,
    },
    {
        path: "/user/registration",
        requireAuth: false,
        name: "RegistrationPage",
        component: newDesignLoginRegistration ? RegistrationPage : OldRegistrationPage,
    },
    {
        path: "/skipass",
        requireAuth: false,
        name: "SkipassPage",
        component: SkipassPage,
    },
    {
        path: "/account",
        requireAuth: true,
        name: "AccountPage",
        component: AccountPage,
    },
    {
        path: "/my-pages",
        requireAuth: true,
        name: "MyPages",
        component: MyPages,
    },
    {
        path: "/my-pages/bookings/:resvid",
        requireAuth: true,
        name: "BookingPage",
        component: BookingPage,
    },
    {
        path: "/account/myAccount",
        requireAuth: true,
        name: "MyAccount",
        component: MyAccount,
    },
    {
        path: "/account/guests",
        requireAuth: true,
        name: "MyGuests",
        component: MyGuests,
    },
    {
        path: "/account/bookings",
        requireAuth: true,
        name: "MyBookings",
        component: MyBookings,
    },
    {
        path: "/account/bookings/:resvid",
        requireAuth: true,
        name: "BookingPage",
        component: OldBookingPage,
    },
    {
        path: "/account/logout",
        requireAuth: true,
        name: "LogoutPage",
        component: LogoutPage,
    },
    {
        path: "/checkout/summary",
        requireAuth: true,
        name: "CheckoutSummaryPage",
        component: newDesignCheckout ? CheckoutSummaryPage : OldCheckoutSummaryPage,
    },
    {
        path: "/pay/confirmation/:resvid",
        requireAuth: false,
        name: "CheckoutConfirmationPage",
        component: newDesignCheckout ? CheckoutConfirmationPage : OldCheckoutConfirmationPage,
    },
    {
        path: "/pay/:resvid/:paymentid/:payerlid",
        requireAuth: false,
        name: "CheckoutPayPage",
        component: newDesignCheckout ? CheckoutPayPage : OldCheckoutPayPage,
    },
    {
        path: "/dev",
        requireAuth: false,
        requireSU: true,
        name: "DeveloperPage",
        component: DeveloperPage,
    },
    {
        path: "/search",
        requireAuth: false,
        name: "SearchReservationTypesPage",
        component: SearchReservationTypesPage,
    },
    {
        path: "/search/:id",
        requireAuth: false,
        name: "SearchReservationTypesPage",
        component: SearchReservationTypesPage,
    },
    {
        path: "/result",
        requireAuth: false,
        name: "SearchReservationTypesPage",
        component: SearchReservationTypesPage,
    },
    {
        path: "/result/:id",
        requireAuth: false,
        name: "SearchReservationTypesPage",
        component: SearchReservationTypesPage,
    },
    {
        path: "/cart",
        requireAuth: false,
        name: "CartPage",
        component: CartPage,
    },
];

export const getStandaloneRoutes = () => [
    {
        path: "/mytickets/:resvHash",
        requireAuth: false,
        name: "MyTicketsPage",
        component: MyTicketsPage,
    },
];
