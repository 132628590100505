import React from "react";
import useAppSelector from "../../hooks/useAppSelector";
import { TAge } from "../MenuSearch/MenuSearch";
import "./Guests.scss";
import Guest from "./Guest/Guest";
import { InfoIcon, Button, TriangleExclamationIcon, style } from "@r360/library";
import useTranslate from "../../hooks/useTranslate";
import { agesSelector } from "../../selectors/Selectors";
import { TGeneralAge } from "../../store/types";
import { RootState } from "../..";
import { useSelector } from "react-redux";

type TGuestsPopover = {
    onChange: (count: number, data: TAge) => void;
    onReset?: () => void;
    onDone?: () => void;
    filters: { [key: string]: number };
    resvType: number | string;
    requiredAges: { [key: string]: (number | string)[] };
    setRequiredAges: React.Dispatch<
        React.SetStateAction<{
            [key: string]: (number | string)[];
        }>
    >;
    maxCount?: number;
};

export const GuestsPopover: React.FC<TGuestsPopover> = ({
    onChange,
    onReset,
    onDone,
    filters,
    resvType,
    requiredAges,
    setRequiredAges,
    maxCount,
}) => {
    const t = useTranslate();

    return (
        <div className="guests-popover">
            <Guests
                resvType={resvType}
                filters={filters}
                onChange={onChange}
                requiredAges={requiredAges}
                setRequiredAges={setRequiredAges}
                max={maxCount}
            />
            <hr className="guests-line" />
            <div className="guests-footer">
                {typeof onReset !== "undefined" && (
                    <Button type="tertiary" onClick={() => onReset?.()} buttonSize="small">
                        {t("book.search.guest_picker.clear")}
                    </Button>
                )}
                {typeof onDone !== "undefined" && (
                    <Button buttonClassName="guests-footer-done" onClick={() => onDone?.()} buttonSize="small">
                        {t("book.close.guest_picker")}
                    </Button>
                )}
            </div>
        </div>
    );
};

type TGuests = {
    onChange: (count: number, data: TAge) => void;
    filters: { [key: string]: number };
    resvType: string | number;
    minAge?: number;
    maxAge?: number;
    requiredAges?: { [key: string]: (number | string)[] };
    setRequiredAges: React.Dispatch<
        React.SetStateAction<{
            [key: string]: (number | string)[];
        }>
    >;
    prices?: Map<string, number>;
    max?: number; // max guest count
};

export const Guests: React.FC<TGuests> = ({
    onChange,
    filters,
    resvType,
    requiredAges,
    setRequiredAges,
    prices,
    minAge = 0,
    maxAge = 99,
    max = 99,
}) => {
    const type = typeof resvType === "string" ? parseInt(resvType, 10) : resvType;
    const generalAges: TGeneralAge[] = useAppSelector(state => agesSelector(state, type));
    const currency = useSelector((state: RootState) => state.clientData?.options?.general?.currency);
    const t = useTranslate();

    const isAgeValid = requiredAges && !Object.values(requiredAges).flat().includes("undefined");

    const usedGuestCount = generalAges
        .map(data => filters[data.key] ?? 0)
        .reduce((totalCount, count) => totalCount + count, 0);
    const maxCount = usedGuestCount >= max ? 0 : max;

    const guestList = generalAges.filter(data => {
        return data.min >= minAge && data.max <= maxAge;
    });

    return (
        <div className="guests">
            {guestList.map(data => {
                const guestCount = filters[data.key] ?? 0;
                return (
                    <Guest
                        onChange={onChange}
                        key={data.title}
                        data={data}
                        guestCount={guestCount}
                        price={prices && prices.get(data.key)}
                        requiredAges={requiredAges}
                        setRequiredAges={setRequiredAges}
                        max={Math.max(maxCount, guestCount)}
                        currency={currency}
                    />
                );
            })}
            {!isAgeValid && requiredAges && (
                <div className="guests__error-message">
                    <TriangleExclamationIcon className="guests__error-icon" color={style.alertColor} />
                    <p className="guests__error-text">{t("book.error_message.guest_picker")}</p>
                </div>
            )}
            {max > 0 && usedGuestCount >= max && (
                <div className="guests-info-text">
                    <InfoIcon size={14} color={style.yellowColor} />
                    <span>{t("book.search.guest_picker.max_guests_reached", [max])}</span>
                </div>
            )}
            {guestList.map(data => {
                if (data.minCount > 0) {
                    return (
                        <div className="guests-info-text" key={data.title}>
                            <InfoIcon size={14} color={style.brandBlueColor} />
                            <span>{t("book.search.guest_picker.description", [data.minCount, t(data.title)])}</span>
                        </div>
                    );
                }
            })}
        </div>
    );
};
