import React, { useEffect, useState } from "react";
import { AccommodationExtras } from "../../components/AccommodationExtras/AccommodationExtras";
import { useLocation, useParams } from "react-router-dom";
import { TCartItem, TItem, TReservationResult } from "../../store/types";
import useAppSelector from "../../hooks/useAppSelector";
import { RootState } from "../..";
import PageContainer from "../../components/PageContainer";
import { Breadcrumbs } from "@r360/library";
import { resvTypeByLid } from "../../Selectors";
import useTranslate from "../../hooks/useTranslate";

const AccommodationExtrasPage = () => {
    const routeParams = useParams();
    const location = useLocation();
    const reservationTypeId = parseInt(routeParams.reservationTypeid ?? "", 10);
    const grouppoollid = parseInt(routeParams.grouppoollid ?? "", 10);
    const poollid = parseInt(routeParams.poollid ?? "", 10);
    const unitlid = parseInt(routeParams.unitlid || "", 10);
    const id = parseInt(routeParams.id || "", 10);
    const reservationResult = useAppSelector((state: RootState) => state.reservationResult);
    const accommodationResult = reservationResult[reservationTypeId] as TReservationResult;
    const reservationType = useAppSelector((state: RootState) => resvTypeByLid(state, reservationTypeId));
    const reservationTypeDescription = reservationType?.description;
    const { isMobile } = useAppSelector((state: RootState) => state.window);
    const [item, setItem] = useState<TItem | TCartItem>();
    const cartProducts: TCartItem[] = useAppSelector(state => state.checkoutCart?.products);
    const t = useTranslate();
    const [isCartItem, setIsCartItem] = useState(false);

    useEffect(() => {
        // Try to find the item in the cart, otherwise in the searchresult
        const cartItem = cartProducts.find(
            product =>
                Number(product.id) === Number(id) || // First, try matching with id
                Number(product.original_id) === Number(id) // Then, try matching with original_id, if it´s an existing reservation
        );
        if (cartItem) {
            setItem(cartItem);
            setIsCartItem(true);
        } else {
            const item = Object.values(accommodationResult?.groups ?? {})
                .find(group => group.grouppoollid === grouppoollid)
                ?.items.find(item => item.id === id);
            setItem(item);
            setIsCartItem(false);
        }
    }, [accommodationResult, cartProducts, grouppoollid, id]);

    const renderBreadcrumbs = () => {
        const breadcrumbs = [
            {
                title: reservationTypeDescription,
                path: `/search/${reservationTypeId}${location.search}`,
            },
            {
                title: item?.groupTitle ?? "",
                path: `/product/${reservationTypeId}/${grouppoollid}${location.search}`,
            },
            {
                title: item?.title ?? "",
                path: `/product/${reservationTypeId}/${grouppoollid}/${poollid}/${unitlid}${location.search}`,
            },
            {
                title: t("book.accomodation.extras"),
                path: "",
            },
        ];

        return <Breadcrumbs breadcrumbs={breadcrumbs} firstItemsDropdown={isMobile} />;
    };

    if (!item) return;

    return (
        <PageContainer>
            {!isCartItem && renderBreadcrumbs()}
            <AccommodationExtras item={item} groupTitle={item.title} />
        </PageContainer>
    );
};

export default AccommodationExtrasPage;
