import * as types from "../actions/types";
import PropTypes from "prop-types";
import AxiosClient from "../../API/AxiosClient";
import axios from "axios";
import moment from "moment-timezone";
import cloneDeep from "lodash/cloneDeep";
import { setRequestsLoading } from "../../store/actions/axiosStatus";

/*
  Use to fetch all listed guests on one cart
  On response sucess
    - Sets all guests from response in Redux
*/
export const fetchGuestsOnCart = (cartId, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const url = `cart/${cartId}/guests`;
    return dispatch => {
        AxiosClient.get(url, config)
            .then(response => {
                // Set property "ontravel" to true for all guests fetched from cart.
                const guests = Object.entries(response.data.payload).reduce((acc, [guestId, guest]) => {
                    guest.ontravel = true;
                    acc[guestId] = guest;
                    return acc;
                }, {});

                dispatch(setGuests(guests));
            })
            .catch(error => {
                dispatch(fetchGuestsFailed(error.response.data));
            });
    };
};

/*
  Use to fetch all listed guests on one user
  On response sucess
  - Append all guests from response in Redux
*/
export const fetchGuestsOnUser = (token, skipDuplicates = false) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const url = `users/guests`;
    return dispatch => {
        AxiosClient.get(url, config)
            .then(response => {
                const guestsArray = response.data.payload.result;
                const guestsObject = guestsArray.reduce((acc, cur) => {
                    // Auto set ontravel for the primary guest to have it pre-checked in the checkout.
                    if (cur.primary) {
                        cur.ontravel = true;
                    }

                    acc[cur.id] = cur;
                    return acc;
                }, {});

                dispatch(appendGuests(guestsObject, skipDuplicates));
            })
            .catch(error => {
                console.log("Is error", error);
                dispatch(fetchGuestsFailed(error.response.data));
            });
    };
};

export const setGuests = guests => {
    return {
        type: types.SET_GUESTS,
        guests: guests,
    };
};

export const appendGuests = (guests, skipDuplicates) => {
    return {
        type: types.APPEND_GUESTS,
        guests,
        skipDuplicates,
    };
};

const fetchGuestsFailed = error => {
    return {
        type: types.FETCH_GUESTS_FAILED,
        error: error,
    };
};

/*
  Use to update one guest using guestId
*/
export const updateGuestData = (guestId, key, value) => {
    return {
        type: types.UPDATE_GUEST_DATA,
        guestId: guestId,
        key: key,
        value: value,
    };
};

/*
  Use to create all guests on one cart
  On response sucess
    - Fetch all guests from current cartID and add it to Redux
*/
export const createGuestsOnCart = (cartId, token, guestCount) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    const allRequests = [];
    const url = `cart/${cartId}/guests`;
    for (let i = 0; i < guestCount; i++) {
        allRequests.push(AxiosClient.post(url, { index: i }, config));
    }

    return dispatch => {
        axios
            .all(allRequests)
            .then(
                axios.spread(() => {
                    dispatch(fetchGuestsOnCart(cartId, token));
                })
            )
            .catch(errors => {
                dispatch(createGuestsFailed(errors[0]?.response));
            });
    };
};

const createGuestsFailed = errors => {
    return {
        type: types.CREATE_GUESTS_FAILED,
        error: errors,
    };
};

/*
  Use to create ONE guest on one cart
  On response success
    - Set created guest in Redux
*/
export const createGuestOnCart = (cartId, token, guest = {}) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const url = `cart/${cartId}/guests`;

    return dispatch => {
        AxiosClient.post(url, guest, config)
            .then(response => {
                // Set property "ontravel" to true for the created guest on cart.
                response.data.payload.ontravel = true;

                dispatch(setGuest(response.data.payload));
            })
            .catch(error => {
                dispatch(createGuestFailed(error.response.data));
            });
    };
};

const setGuest = guest => {
    return {
        type: types.SET_GUEST,
        guest: guest,
    };
};

const createGuestFailed = error => {
    return {
        type: types.CREATE_GUEST_FAILED,
        error: error,
    };
};

/*
  Use to update guests on cart
*/
export const updateGuests = (token, cartId, guests) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    const allRequests = [];
    Object.keys(guests).forEach(guestId => {
        const year = guests[guestId].year;
        const month = guests[guestId].month;
        const day = guests[guestId].day;
        const birthdayStr = year + "/" + month + "/" + day;
        const birthday = moment(birthdayStr);
        if (birthday.isValid()) {
            guests[guestId].birthday = birthday.format("YYYY-MM-DD");
            if (guests[guestId].phoneprefix) {
                guests[guestId].phoneprefix = parseInt(guests[guestId].phoneprefix);
            }

            if (guests[guestId].phone3 === "" && guests[guestId].phone) guests[guestId].phone3 = guests[guestId].phone;
            const url = `cart/${cartId}/guests/${guestId}`;
            if (!guests[guestId]?.disabled) {
                allRequests.push(AxiosClient.put(url, guests[guestId], config));
            }
        } else {
            return dispatch => {
                dispatch(
                    updateGuestFailed([
                        {
                            message: "Could not create {Birthday}. Please try again or contact support.",
                        },
                    ])
                );
                dispatch(updatedAllGuests(false));
            };
        }
    });

    return dispatch => {
        axios
            .all(allRequests)
            .then(
                axios.spread((...responses) => {
                    responses.forEach(response => {
                        dispatch(updateGuestWithData(response.data.payload));
                    });
                    dispatch(updatedAllGuests(true));
                })
            )
            .catch(errors => {
                dispatch(updateGuestFailed(errors[0]?.response));
                dispatch(updatedAllGuests(false));
            });
    };
};

/*
  Use to sync the guests on a cart.
*/
export const syncGuestsOnCart = (token, cartId, guests) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    const url = `cart/${cartId}/guests/sync`;

    const parsedGuests = cloneDeep(Object.values(guests)).reduce((acc, guest) => {
        if (guest.phone && guest.phoneprefix) {
            guest.phoneprefix = parseInt(guest.phoneprefix);
        } else {
            delete guest.phone;
            delete guest.phoneprefix;
        }

        acc.push(guest);
        return acc;
    }, []);

    return dispatch => {
        AxiosClient.post(url, parsedGuests, config)
            .then(() => {
                dispatch(updatedAllGuests(true));
            })
            .catch(error => {
                dispatch(updateGuestFailed(error.response.data));
                dispatch(updatedAllGuests(false));
            });
    };
};

export const updatedAllGuests = value => {
    return {
        type: types.ALL_GUESTS_UPDATED_SUCCESSFULLY,
        updatedAllGuests: value,
    };
};

export const updateGuestWithData = data => {
    return {
        type: types.UPDATE_GUEST,
        guest: data,
    };
};

export const updateGuestFailed = error => {
    return {
        type: types.UPDATE_GUEST_FAILED,
        error: error,
    };
};

/*
  Use to remove guest from product
*/
export const deleteGuestFromCart = (token, cartId, guestId) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const url = `/cart/${cartId}/guests/${guestId}`;
    return dispatch => {
        AxiosClient.delete(url, config, null)
            .then(() => {
                dispatch(deleteGuest(guestId));
            })
            .catch(error => {
                dispatch(deleteGuestFromCartFailed(error.response.data));
            });
    };
};

export const deleteGuest = guestId => {
    return {
        type: types.DELETE_GUEST,
        guestId: guestId,
    };
};

export const deleteGuestFailed = guestId => {
    return {
        type: types.DELETE_GUEST_FAILED,
        guestId: guestId,
    };
};

const deleteGuestFromCartFailed = error => {
    return {
        type: types.DELETE_GUEST_FROM_CART_FAILED,
        error: error,
    };
};

/**
 * Use to fetch a guest.
 *
 * @param {number} guestId
 * @param {string} token
 */
export const fetchGuest = (guestId, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const url = `users/guests/${guestId}`;
    return dispatch => {
        AxiosClient.get(url, config)
            .then(response => {
                dispatch(setGuest(response.data.payload));
            })
            .catch(error => {
                dispatch(fetchGuestFailed(error.response.data));
            });
    };
};

/**
 * Use to delete/disconnect a guest.
 *
 * @param {number} guestId
 * @param {string} token
 */
export const disconnectGuestFromUser = (guestId, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const url = `users/guests/${guestId}`;
    return dispatch => {
        AxiosClient.delete(url, config)
            .then(() => {
                dispatch(deleteGuest(guestId));
            })
            .catch(() => {
                dispatch(deleteGuestFailed(guestId));
            });
    };
};

/**
 * Use to update a guest connected to a user.
 *
 * @param {string} token
 * @param {Object} guest
 */
export const updateUserGuest = (token, guest) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    const url = `users/guests/${guest.id}`;

    if (guest.phone) {
        guest.phoneprefix = guest.phoneprefix && parseInt(guest.phoneprefix);
    } else {
        guest.phoneprefix = "";
    }

    return dispatch => {
        dispatch(setRequestsLoading({ request: "updateUserGuest", loading: true }));

        AxiosClient.put(url, guest, config)
            .then(response => {
                dispatch(updateGuestWithData(response.data.payload));
                dispatch(setRequestsLoading({ request: "updateUserGuest", loading: false }));
            })
            .catch(error => {
                dispatch(updateGuestFailed(error.response.data));
                dispatch(setRequestsLoading({ request: "updateUserGuest", loading: false }));
            });
    };
};

/**
 * Use to update a guest connected to a user.
 *
 * @param {string} token
 * @param {Object} guest
 */
export const createUserGuest = (token, guest) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    const url = `users/guests`;

    if (guest.phone && guest.phoneprefix) {
        guest.phoneprefix = parseInt(guest.phoneprefix);
    } else {
        delete guest.phone;
        delete guest.phoneprefix;
    }

    return dispatch => {
        dispatch(setRequestsLoading({ request: "createUserGuest", loading: true }));
        AxiosClient.post(url, guest, config)
            .then(response => {
                dispatch(setGuest(response.data.payload));
                dispatch(setRequestsLoading({ request: "createUserGuest", loading: false }));
            })
            .catch(error => {
                dispatch(createGuestFailed(error.response.data));
                dispatch(setRequestsLoading({ request: "createUserGuest", loading: false }));
            });
    };
};

const fetchGuestFailed = error => {
    return {
        type: types.FETCH_GUEST_FAILED,
        error: error,
    };
};

export const resetGuests = () => {
    return {
        type: types.RESET_GUESTS,
    };
};

/*
  PropTypes
*/
updateGuestData.PropTypes = {
    guestId: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
};
