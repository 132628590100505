import produce from "immer";
import * as types from "../actions/types";

/**
 * {
 *    type: {transactionTypes}
 *    data: {any}
 * }[]
 */
const INITIAL_STATE = [];

const reducer = produce((draft, action) => {
    switch (action.type) {
        case types.SET_NOTIFICATION:
            draft.push(action.payload);
            break;

        case types.CLEAR_NOTIFICATIONS:
            return INITIAL_STATE;

        default:
            break;
    }
}, INITIAL_STATE);
export default reducer;
