import React from "react";
import { useSelector } from "react-redux";

import { formatPrice } from "../../../Helper";
import { TickIcon } from "../../UI/R360Icons";
import classnames from "classnames";
import "../ProductAvailability.scss";
import "./ProductAvailabilityItem.scss";
import { Button } from "react-bootstrap";
import { getInspectionDataForProduct } from "../../../BusinessUtils";

const showPrice = (price, currency) => {
    return `${formatPrice(price, currency)}`;
};

// Product availability item
const ProductAvailabilityItem = props => {
    const { items } = props.data;
    const { texts, renderCritHeader, isMobile, resultTableCritsSortorder } = props;
    const allFilters = useSelector(state => state.availableFilters.default);

    const getCritsIconColor = () => "#333333";

    /**
     * Render the items details item text for ENUM, MIN or MAX.
     *
     * @param {Object} item The product item.
     * @param {number} critId The criteria ID to get the item text for.
     *
     * @returns {string} The item text.
     */
    const renderItemDetailsItemText = (item, critId) => {
        const filterForCritId = allFilters[critId];
        const definitionForCritId = item?.crits?.[critId];

        if (!filterForCritId) {
            return;
        }

        // Allow 0 as value
        if (definitionForCritId !== 0 && !definitionForCritId) {
            return;
        }

        switch (filterForCritId.type) {
            case "ENUM":
                return filterForCritId.enums?.[definitionForCritId]?.value;

            case "MIN":
            case "MAX":
                return `${definitionForCritId}${filterForCritId.typeunit}`;

            default:
                return "";
        }
    };

    const renderMobileItemCrits = item => {
        const critsIconColor = getCritsIconColor(item);
        const mobileProps = {
            color: critsIconColor,
            size: 24,
        };

        return (
            <div className="product-availability-item__details">
                {resultTableCritsSortorder.map(critId => (
                    <div key={critId} data-critcode={critId} className="product-availability-item__details-item">
                        {typeof item.crits?.[critId] === "boolean" ? (
                            <>
                                <div className="product-availability-item__details-item-icon">
                                    {renderCritHeader(critId, mobileProps)}
                                </div>
                                <div className="product-availability-item__details-item-text">
                                    {item?.crits?.[critId] === true ? (
                                        <TickIcon
                                            size={20}
                                            color="transparent"
                                            color2={critsIconColor}
                                            iconClass="product-availability-item__icon"
                                        />
                                    ) : (
                                        "—"
                                    )}
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="product-availability-item__details-item">
                                    <div className="product-availability-item__details-item-icon">
                                        {renderCritHeader(critId, mobileProps)}
                                    </div>
                                    <div className="product-availability-item__details-item-text">
                                        {renderItemDetailsItemText(item, critId)}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                ))}
            </div>
        );
    };

    const renderItem = () => {
        const item = items[0];

        if (!item) {
            return <></>;
        }

        const hasPackages = items.length >= 2;
        const lowestPackagePrice = items.sort((a, b) => a.price - b.price)[0].price;
        const { title, price, ordinaryprice, curr: currency, pricecode } = item;
        const critsIconColor = getCritsIconColor(item);

        const handleItemClick = event => {
            event.stopPropagation();
            props.didSelectProduct(item);
        };

        const handlePriceCalendarClick = event => {
            event.stopPropagation();
            props.onPriceCalendarClick(item);
        };

        const renderPrice = () => {
            const priceClass = classnames("price", { "price-mobile": isMobile });
            const ordinarypriceClass = classnames("ordinaryprice", { "ordinaryprice-mobile": isMobile });

            return (
                <>
                    {hasPackages ? (
                        <div className={priceClass}>fr. {showPrice(lowestPackagePrice, currency)}</div>
                    ) : (
                        <>
                            <div className={priceClass}>{showPrice(price, currency)}</div>
                            {ordinaryprice > 0 && (
                                <div className={ordinarypriceClass}>{showPrice(ordinaryprice, currency)}</div>
                            )}
                        </>
                    )}
                </>
            );
        };

        const renderBookButton = () => (
            <div className="product-availability-item-button-wrapper">
                <Button className="button" size="sm" style={{ whiteSpace: "nowrap" }}>
                    {texts["product.read_more_and_book"]}
                </Button>
                {item.hasPriceCalendar && (
                    <a className="link" onClick={handlePriceCalendarClick}>
                        Priskalender
                    </a>
                )}
            </div>
        );

        return !isMobile ? (
            <tr
                className="product-availability__table-tr"
                onClick={handleItemClick}
                {...getInspectionDataForProduct(item)}
            >
                <td>{title}</td>
                {resultTableCritsSortorder.map(critId => {
                    if (item.crits) {
                        if (typeof item.crits?.[critId] === "boolean") {
                            return (
                                <td key={critId} data-critcode={critId}>
                                    {item.crits?.[critId] === true ? (
                                        <TickIcon
                                            size={26}
                                            color="transparent"
                                            color2={critsIconColor}
                                            iconClass="product-availability-item__icon"
                                        />
                                    ) : (
                                        "—"
                                    )}
                                </td>
                            );
                        }
                    } else {
                        return <td key={critId} data-critcode={critId}></td>;
                    }
                    return (
                        <td key={critId} data-critcode={critId}>
                            {renderItemDetailsItemText(item, critId)}
                        </td>
                    );
                })}
                <td>{pricecode && renderPrice()}</td>
                <td className="text-end">{renderBookButton()}</td>
            </tr>
        ) : (
            <>
                <tr
                    className="product-availability__table-tr"
                    onClick={handleItemClick}
                    {...getInspectionDataForProduct(item)}
                >
                    <td colSpan="2">{title}</td>
                </tr>
                <tr className="product-availability__table-tr" onClick={handleItemClick}>
                    <td className="text-start">{pricecode && renderPrice()}</td>
                    <td className="text-end" style={{ verticalAlign: "top" }}>
                        {renderBookButton()}
                    </td>
                </tr>
                <tr className="product-availability__table-tr" onClick={handleItemClick}>
                    <td colSpan="2" style={{ borderBottom: "1px solid #999" }}>
                        {renderMobileItemCrits(item)}
                    </td>
                </tr>
            </>
        );
    };

    return renderItem();
};
export default ProductAvailabilityItem;
