import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";
import moment from "moment-timezone";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useMatch, useNavigate, useParams } from "react-router-dom";
import AxiosClient from "../API/AxiosClient";
import AccommodationView from "../components/AccommodationView/AccommodationView";
import PriceCalendar from "../components/PriceCalendar/OldPriceCalendar";
import ChangeSearch from "../components/ChangeSearch/ChangeSearch";
import ProductAvailability from "../components/ProductAvailability/ProductAvailability";
import ProductGallery from "../components/ProductGallery/ProductGallery";
import ProductImagesGallery from "../components/ProductImagesGallery/ProductImagesGallery";
import PromotionCodeInput from "../components/PromotionCodeInputOld/PromotionCodeInput";
import { AlertSorry, Filters, Icon, Modal } from "../components/UI";
import MoreFilterButton from "../components/UI/Filters/MoreFilterButton/MoreFilterButton";
import ReadMoreText from "../components/UI/ReadMoreText";
import * as Constants from "../Constants";
import { isFilterMatch } from "../hooks";
import useChangeFilter from "../hooks/useChangeFilter";
import useChangeSearch from "../hooks/useChangeSearch";
import { optionsSelector } from "../selectors/Selectors";
import { clearFilters, setPromoCode } from "../store/actions/filter";
import useAppSelector from "../hooks/useAppSelector";
import { textsSelector } from "../Selectors";

// Product page component
const ProductPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const routeParams = useParams();
    const location = useLocation();
    const showPriceCalendar = !!useMatch("/product/:reservationTypeid/:grouppoollid/:poollid/:unitlid/price_calendar");
    const reservationTypeid = parseInt(routeParams.reservationTypeid, 10);
    const grouppoollid = parseInt(routeParams.grouppoollid, 10);
    const poollid = parseInt(routeParams.poollid || 0, 10);
    const unitlid = parseInt(routeParams.unitlid || 0, 10);
    const isMobile = useSelector(state => state.window.isMobile);
    const clientData = useSelector(state => state.clientData);
    const texts = useSelector(textsSelector);
    const reservationTypes = useSelector(state => state.clientData.reservationtypes);
    const reservationResult = useSelector(state => state.reservationResult);
    const promotion = useSelector(state => state.clientData?.options?.module?.promotion);
    const filters = useSelector(state => state.filter);
    const calendarData = useSelector(state => state.clientData?.calendarData);
    const ages = JSON.parse(clientData?.options.general.ages);
    const activeFilters = filters[reservationTypeid];
    const [product, setProduct] = useState();
    const [selectedPoolUnit, setSelectedPoolUnit] = useState();
    const [images, setImages] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showChangeSearch, setShowChangeSearch] = useState(false);
    const [showNoUnitsFoundMessage, setShowNoUnitsFoundMessage] = useState(false);
    const [showNoPoolUnitFoundMessage, setShowNoPoolUnitFoundMessage] = useState(false);
    const reservationTypeResult = reservationResult[reservationTypeid];
    const reservationResultWithId = reservationResult[reservationTypeid];
    const [showFilter, setShowFilter] = useState(false);
    const [filterSearch, setFilterSearch] = useState("");
    const [showAccommodationViewModal, setShowAccommodationViewModal] = useState(false);
    const [showAccommodationPriceCalendarModal, setShowAccommodationPriceCalendarModal] = useState(showPriceCalendar);
    const [availablePeriodsGrouppool, setAvailablePeriodsGrouppool] = useState(undefined);
    const [availablePeriodsPoolUnit, setAvailablePeriodsPoolUnit] = useState(undefined);
    const showImageDescriptions = useSelector(optionsSelector).layout?.show_image_descriptions || false;
    const newDesignMenu = useSelector(optionsSelector).layout?.new_design_menu;
    const useAccommodationPoolUnitPeriods = !!useSelector(optionsSelector).calendar?.useaccommodationpoolunitperiods;
    const lang = useAppSelector(state => state.clientData?.lang);

    useChangeSearch({
        reservationResultSearch: reservationResultWithId?.search,
        reservationTypeId: reservationTypeid,
        grouppoollid,
        skipGuestsOnSearchWithPrice: false,
        allowSearchWithoutPrice: true,
    });

    useChangeFilter(reservationTypeid);

    const fetchAvailablePeriodsGrouppool = (reservationTypeid, grouppoollid) => {
        const url = `/calendars/available_periods_grouppool?resvtype=${reservationTypeid}&grouppoollid=${grouppoollid}`;

        AxiosClient.get(url).then(response => {
            setAvailablePeriodsGrouppool(response?.data?.payload || []);
        });
    };

    useEffect(() => {
        if (reservationTypeid && grouppoollid && useAccommodationPoolUnitPeriods) {
            fetchAvailablePeriodsGrouppool(reservationTypeid, grouppoollid);
        }
    }, [reservationTypeid, grouppoollid, useAccommodationPoolUnitPeriods]);

    useEffect(() => {
        if (selectedPoolUnit && availablePeriodsGrouppool && useAccommodationPoolUnitPeriods) {
            setAvailablePeriodsPoolUnit(
                (availablePeriodsGrouppool || []).filter(
                    period =>
                        period.poollid === selectedPoolUnit.poollid &&
                        (period.unitlid === 0 || period.unitlid === selectedPoolUnit.unitlid)
                )
            );
        }
    }, [selectedPoolUnit, availablePeriodsGrouppool, useAccommodationPoolUnitPeriods]);

    useEffect(() => {
        // Validate age categories for selected reservation type
        if (reservationTypeid && reservationTypes && ages) {
            const reservationType = reservationTypes.find(type => {
                return Number(type.lid) === reservationTypeid;
            });

            // Some reservation types do not require age categories
            const requireAgeCategories = [
                Constants.productTypeNames.ACCOMMODATION,
                Constants.productTypeNames.ACTIVITY,
                Constants.productTypeNames.LETTING,
                Constants.productTypeNames.SKIPASS,
            ].includes(reservationType?.type);

            if (!requireAgeCategories) {
                return;
            }

            if (!(reservationTypeid in ages)) {
                throw new Error(`Faulty setup: Missing age categories for reservation type ${reservationTypeid}`);
            }
        }
    }, [reservationTypeid, reservationTypes, ages]);

    const getFilteredProductItemsGroupedByPoolUnit = useCallback(
        product => {
            const filterCriterias =
                filters.filterCriterias && reservationTypeid in filters.filterCriterias
                    ? filters.filterCriterias[reservationTypeid]
                    : {};

            let filteredItems = product.items.filter(item => {
                if (Object.keys(item.crits).length) {
                    return isFilterMatch(activeFilters, item.crits, filterCriterias);
                }

                // Always include products that hasn't any criterias
                return true;
            });

            if (filterSearch !== "") {
                filteredItems = filteredItems.filter(
                    item =>
                        item.desc1?.toString?.().toLowerCase?.().includes(filterSearch.toLowerCase()) ||
                        item.title?.toString?.().toLowerCase?.().includes(filterSearch.toLowerCase()) ||
                        item.unitdesc1?.toString?.().toLowerCase?.().includes(filterSearch.toLowerCase())
                );
            }

            const filteredItemsInitialSortOrder = [
                ...new Set([...filteredItems.map(item => `${item.poollid}_${item.unitlid}`)]),
            ];

            // Group products by poollid/unitlid in case of using packages.
            const groupedFilteredItems = Object.entries(
                groupBy(filteredItems, item => `${item.grouppoollid}_${item.poollid}_${item.unitlid}`)
            )
                .reduce((acc, [lidString, items]) => {
                    const [grouppoollidString, poollidString, unitlidString] = lidString.split("_");
                    const itemObject = {
                        grouppoollid: parseInt(grouppoollidString, 10),
                        poollid: parseInt(poollidString, 10),
                        unitlid: parseInt(unitlidString, 10),
                        // Sort items by price, but always position an empty pricetext first.
                        items: sortBy(items, item => (!item.pricetext ? -1 : item.price)),
                    };
                    acc.push(itemObject);
                    return acc;
                }, [])
                .sort(
                    (a, b) =>
                        filteredItemsInitialSortOrder.indexOf(`${a.poollid}_${a.unitlid}`) -
                        filteredItemsInitialSortOrder.indexOf(`${b.poollid}_${b.unitlid}`)
                );

            return groupedFilteredItems;
        },
        [activeFilters, filterSearch, filters.filterCriterias, reservationTypeid]
    );

    useEffect(() => {
        if (!poollid || !product) {
            setShowAccommodationViewModal(false);
            setShowAccommodationPriceCalendarModal(false);
            return;
        }

        const filteredItems = getFilteredProductItemsGroupedByPoolUnit(product);

        const selectedPoolUnit = filteredItems.find(item => item.poollid === poollid && item.unitlid === unitlid);

        if (selectedPoolUnit && showPriceCalendar) {
            setSelectedPoolUnit(selectedPoolUnit);
            setShowAccommodationViewModal(false);
            setShowAccommodationPriceCalendarModal(true);
        } else if (selectedPoolUnit) {
            setSelectedPoolUnit(selectedPoolUnit);
            setShowAccommodationViewModal(true);
            setShowNoPoolUnitFoundMessage(false);
            setShowAccommodationPriceCalendarModal(false);
        } else {
            setShowNoPoolUnitFoundMessage(true);
        }
    }, [poollid, unitlid, product, getFilteredProductItemsGroupedByPoolUnit, showPriceCalendar]);

    // Handle click on more info button
    const handleProductGalleryClick = () => {
        setShowModal(!showModal);
    };
    const changeSearchClick = () => {
        setShowChangeSearch(!showChangeSearch);
    };

    useEffect(() => {
        if (Object.entries(reservationResult).length > 0) {
            if (reservationResult[reservationTypeid]?.groups) {
                const item = Object.values(reservationResult[reservationTypeid].groups).find(group => {
                    return parseInt(group.grouppoollid, 10) === grouppoollid;
                });

                setShowNoUnitsFoundMessage(false);

                if (item) {
                    setProduct(item);
                    if (item.groupitem?.images.length !== 0) {
                        const productPageImages = item?.groupitem?.images.map(image => ({
                            thumbnail: image.url,
                            original: image.url,
                            text: image?.translatedDesc ? image?.translatedDesc[lang] : "",
                        }));
                        setImages(productPageImages);
                    }
                } else if (!product) {
                    // Redirect to accommodation result page if there is nothing to show
                    if (product?.groupitem === undefined) {
                        navigate("/search/" + reservationTypeid, { replace: true, state: { redirect: true } });
                    }
                    setProduct();
                } else {
                    setShowNoUnitsFoundMessage(true);
                }
            }
        }

        // TODO: R360ONL-741 Fix dependencies for useEffect related to searches.
        // eslint-disable-next-line
    }, [reservationResult]);

    const handleSelectPoolUnit = item => {
        const path = `/product/${reservationTypeid}/${grouppoollid}/${item.poollid}/${item.unitlid}`;
        if (poollid) {
            navigate(`${path}${location.search}`, { replace: true });
        } else {
            navigate(`${path}${location.search}`);
        }
    };

    const handleSelectPoolPriceCalendar = item => {
        const path = `/product/${reservationTypeid}/${grouppoollid}/${item.poollid}/${item.unitlid}/price_calendar`;
        if (poollid) {
            navigate(`${path}${location.search}`, { replace: true });
        } else {
            navigate(`${path}${location.search}`);
        }
    };

    const handleCloseAccommodationViewModal = () => {
        const path = `/product/${reservationTypeid}/${grouppoollid}`;
        navigate(`${path}${location.search}`, { replace: true });
    };

    const goBackURL = () => {
        let path = "/search",
            search = "";

        if (reservationTypeid) {
            path = `${path}/${reservationTypeid}`;
        }

        if (location?.search) {
            search = location.search;

            // Exclude pricecode when going back to avoid a global search be used for a specific pricecode.
            // The price code is only used for search using the price calender.
            if (search.search("pricecode") !== -1) {
                const searchParams = new URLSearchParams(search);
                searchParams.delete("pricecode");
                search = `?${searchParams.toString()}`;
            }
        }

        return `${path}${search}`;
    };

    const handleShowAllOffering = () => {
        setFilterSearch("");
        dispatch(setPromoCode(""));
        dispatch(clearFilters(reservationTypeid));
        navigate(`/product/${reservationTypeid}/${grouppoollid}?checkPrice=0`);
    };

    // Render
    if (product?.groupitem) {
        const filteredItems = getFilteredProductItemsGroupedByPoolUnit(product);
        const format = "ddd D MMM";
        const arrdate = moment(reservationTypeResult?.search?.arrdate);
        const depdate = moment(reservationTypeResult?.search?.depdate);

        return (
            <>
                <section>
                    <div className="container">
                        <Link
                            text={texts?.toaccommodation}
                            className="btn button button--text-only back-link"
                            to={goBackURL()}
                        >
                            <Icon name="FaArrowLeft" color="#0d3e60" size={16} />
                            <span className="back-link__text">{texts?.toaccommodation}</span>
                        </Link>

                        <ProductGallery images={images} />

                        <div style={{ textAlign: "right", height: "2rem", paddingTop: "0.5rem" }}>
                            {images.length > 2 && (
                                <a className="link" onClick={handleProductGalleryClick}>
                                    {texts?.showallphotosforthearea}
                                </a>
                            )}
                        </div>
                    </div>
                    <Modal show={showModal} setShow={setShowModal} size="lg">
                        <ProductImagesGallery images={images} showImageDescriptions={showImageDescriptions} />
                    </Modal>
                    <Modal
                        show={showAccommodationViewModal && !!selectedPoolUnit}
                        setShow={setShowAccommodationViewModal}
                        size="lg"
                        onHide={handleCloseAccommodationViewModal}
                    >
                        <AccommodationView
                            groupedItem={selectedPoolUnit}
                            texts={texts}
                            presentationCriteriasDefs={product.presentationcriterias}
                            groupItem={product?.groupitem}
                            resvType={reservationTypeid}
                            reservationResultSearch={reservationTypeResult?.search}
                            showNoPoolUnitFoundMessage={showNoPoolUnitFoundMessage || showNoUnitsFoundMessage}
                            availablePeriods={availablePeriodsPoolUnit}
                        />
                    </Modal>
                    <Modal
                        show={showAccommodationPriceCalendarModal && !!selectedPoolUnit}
                        setShow={setShowAccommodationPriceCalendarModal}
                        size="lg"
                        onHide={handleCloseAccommodationViewModal}
                    >
                        <PriceCalendar
                            resvType={reservationTypeid}
                            groupedItem={selectedPoolUnit}
                            filteredItems={filteredItems}
                            reservationResultSearch={reservationTypeResult?.search}
                        />
                    </Modal>
                </section>
                <section className="container">
                    <div className="row">
                        <div className="col-md-12 col-lg-12">
                            <h1 className="page-header pb-0">{product.groupitem.title}</h1>
                        </div>
                        {product.groupitem.weblong !== "" && (
                            <div className="col-md-12 col-lg-12" style={{ paddingTop: "1rem" }}>
                                <ReadMoreText
                                    lines={3}
                                    moreText={texts?.showmore}
                                    lessText={texts?.showless}
                                    anchorClass="link"
                                    html={true}
                                    whiteSpacePreLine={true}
                                >
                                    {product.groupitem.weblong}
                                </ReadMoreText>
                            </div>
                        )}
                    </div>
                </section>
                {!isMobile && (
                    <section className="container mt-5 mb-5">
                        <div className="row mb-3">
                            <div className="col" style={{ display: "flex", alignItems: "center" }}>
                                <h4 className="mb-0 me-5">
                                    {texts?.availableaccommodations}
                                    {reservationTypeResult?.search?.checkPriceAndAvailability &&
                                        ` ${arrdate.format(format)} - ${depdate.format(format)}`}
                                </h4>
                                <a className="page-changesearch" onClick={changeSearchClick} variant="link">
                                    {texts?.changesearch}
                                </a>
                                {reservationTypeResult?.search?.checkPriceAndAvailability && (
                                    <a className="link ms-5" onClick={handleShowAllOffering}>
                                        {texts["search.show_all_offering"]}
                                    </a>
                                )}
                            </div>
                        </div>
                        {showChangeSearch && calendarData && reservationTypes.length > 0 && (
                            <>
                                <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
                                    <div className="me-2" style={{ flexShrink: 0 }}>
                                        <ChangeSearch
                                            texts={texts}
                                            resvType={reservationTypeid}
                                            showRange
                                            isMobile={isMobile}
                                            reservationResultSearch={reservationTypeResult?.search}
                                            reservationTypeid={reservationTypeid}
                                            availablePeriods={
                                                useAccommodationPoolUnitPeriods && availablePeriodsGrouppool
                                                    ? { [reservationTypeid]: availablePeriodsGrouppool }
                                                    : undefined
                                            }
                                        />
                                    </div>
                                    {filters?.filterCriterias?.[reservationTypeid] && (
                                        <div className="my-2" style={{ flexShrink: 0 }}>
                                            <MoreFilterButton
                                                text={showFilter ? `${texts?.filterclose}` : `${texts?.filtermore}`}
                                                onClick={() => setShowFilter(!showFilter)}
                                            />
                                        </div>
                                    )}
                                </div>
                                <Filters
                                    reservationType={reservationTypeid}
                                    showFilter={showFilter}
                                    showFilterSearch={true}
                                    filterSearch={filterSearch}
                                    onFilterSearchChange={setFilterSearch}
                                />
                            </>
                        )}
                        {!newDesignMenu &&
                            showChangeSearch &&
                            promotion &&
                            reservationTypeResult?.search?.checkPriceAndAvailability && (
                                <PromotionCodeInput texts={texts} promoCode={filters.promoCode} />
                            )}
                    </section>
                )}
                {isMobile && (
                    <section className="container">
                        <div style={{ textAlign: "left", paddingTop: "2rem" }}>
                            <h4>{texts?.availableaccommodations}</h4>
                        </div>
                        <div
                            className="mb-3"
                            style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
                        >
                            {reservationTypeResult?.search?.checkPriceAndAvailability ? (
                                <>
                                    <h6 className="m-0">{`${arrdate.format(format)} - ${depdate.format(format)}`}</h6>
                                    <a className="link ms-2" onClick={changeSearchClick}>
                                        {texts?.changesearch}
                                    </a>
                                    <a className="link ms-5" onClick={handleShowAllOffering}>
                                        {texts["search.show_all_offering"]}
                                    </a>
                                </>
                            ) : (
                                <a className="link" onClick={changeSearchClick}>
                                    {texts?.changesearch}
                                </a>
                            )}
                        </div>
                        {showChangeSearch && calendarData && reservationTypes.length > 0 && (
                            <>
                                <div>
                                    <ChangeSearch
                                        texts={texts}
                                        resvType={reservationTypeid}
                                        showRange
                                        isMobile={isMobile}
                                        reservationResultSearch={reservationTypeResult?.search}
                                        reservationTypeid={reservationTypeid}
                                        availablePeriods={
                                            useAccommodationPoolUnitPeriods && availablePeriodsGrouppool
                                                ? { [reservationTypeid]: availablePeriodsGrouppool }
                                                : undefined
                                        }
                                    />
                                    {!newDesignMenu &&
                                        showChangeSearch &&
                                        promotion &&
                                        reservationTypeResult?.search?.checkPriceAndAvailability && (
                                            <PromotionCodeInput texts={texts} promoCode={filters.promoCode} />
                                        )}
                                    {filters?.filterCriterias?.[reservationTypeid] && (
                                        <div className="mt-2">
                                            <MoreFilterButton
                                                text={showFilter ? `${texts?.filterclose}` : `${texts?.filtermore}`}
                                                onClick={() => setShowFilter(!showFilter)}
                                            />
                                        </div>
                                    )}
                                </div>
                                <Filters
                                    reservationType={reservationTypeid}
                                    showFilter={showFilter}
                                    showFilterSearch={true}
                                    filterSearch={filterSearch}
                                    onFilterSearchChange={setFilterSearch}
                                />
                            </>
                        )}
                    </section>
                )}
                <section className="container">
                    <div className="row">
                        <div className="col-md-12">
                            {showNoUnitsFoundMessage || !filteredItems?.length ? (
                                <AlertSorry
                                    heading={texts?.accommodationnoresultheader}
                                    content={texts?.accommodationnoresultbody}
                                />
                            ) : (
                                <ProductAvailability
                                    productList={filteredItems}
                                    product={product}
                                    onSelect={handleSelectPoolUnit}
                                    onPriceCalendarClick={handleSelectPoolPriceCalendar}
                                />
                            )}
                        </div>
                    </div>
                </section>
            </>
        );
    }

    return null;
};

export default ProductPage;
