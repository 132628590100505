declare global {
    interface Window {
        __BASE_URL__: string;
        __CODEBASE__: string;
        __LANG__: string;
    }
    interface HTMLIFrameElement {
        contentWindowexternalCheckoutIframe: any;
    }
}

export type TReservationTypeLid = number;

export type TLanguage = {
    code: string;
    language: string;
    default: boolean;
    visible: boolean;
};

export type TReservationType = {
    lid: TReservationTypeLid;
    description: string;
    type: string;
    daystoarr: string;
    sortorder: number;
    hidden: boolean;
    lastvalidbookingtime: string;
    active: boolean;
    link: string;
    useCampaign?: boolean;
    useAutoSearch?: boolean;
    autoSearchFromDate?: string;
    autoSearchToDate?: string;
    desktopImage?: string;
    mobileImage?: string;
    useFilterSearch?: boolean;
    usePriceIntervalFilter?: boolean;
    criterias?: TReservationTypeCriterias;
    useSkipassExtra: boolean;
    skipassExtraReservationTypeId: number;
    useGeneralInfo: boolean;
};

export type TGuest = {
    addr1: string | null;
    addr2: string | null;
    addr3: string | null;
    addr4: string | null;
    addr5: string | null;
    age: number;
    assigned_products: Array<string>;
    birthday: string;
    country: string | null;
    email: string;
    firstname: string;
    guestlid: number;
    headsize: number;
    height: number;
    id: number;
    imagehash: string;
    keycardNo: string;
    lastname: string;
    phone: number;
    phonefull: string;
    phoneprefix: number;
    primary: boolean;
    primaryIfBookingForOthers?: boolean;
    requiresimage: false;
    shoesize: number;
    weight: number;
    ontravel: boolean | "on";
    disabled: boolean;
};

export type TGuestsOnTravelReducer = Record<string, TGuest>;

type TReservationResultBase = {
    resultSetId: string;
    search: TSearch;
    hitsMatchingFilter: string;
    error: boolean;
};
export interface TReservationResult extends TReservationResultBase {
    groups: { [key: string]: TGroup };
}

export interface TPackageReservationResult extends TReservationResultBase {
    groups: { [key: string]: TPackageGroup };
}

export type TSearch = {
    type: number;
    guests: number[];
    arrdate: string;
    depdate: string;
    checkPriceAndAvailability: boolean;
    pricecode: string;
    promoCode: string;
    grouppoollid: string;
};

export type TGroupItem = {
    grouppoollid: number;
    images: TImage[];
    price: number;
    title: string;
    thumbnail: TThumbnail;
    weblong: string;
    category: string;
    sortorder: number;
};

export type TGroup = {
    items: TItem[];
    id: string | number;
    grouppoollid: number;
    groupitem: TGroupItem;
    isGrouped?: boolean;
    presentationcriterias?: { [key: number]: TFilterCriteria };
    resulttablecrits?: { [key: number]: TFilterCriteria };
    crits?: any;
    sortorder?: number;
};

export type TPackageGroup = {
    items: TItem[];
    id: string;
    grouppoollid: number;
    groupitem: TGroupItem;
    sortorder?: number;
    images: TImage[];
    title: string;
    weblong: string;
    type: number;
};

export enum ECriteriaType {
    ENUM = "ENUM",
    BOOL = "BOOL",
    MIN = "MIN",
    MAX = "MAX",
}

export type TCriteriaItem = {
    code: number;
    title: string;
    enums: TCriteriaEnum[];
    type: ECriteriaType;
    typeunit: string;
    filterEnumsBasedOnResult?: boolean;
};

export type TCriteriaEnum = {
    code: number;
    title: string;
};

export type TCrits = {
    [key: string]: any;
};

export enum ECriteriaPresentationType {
    ACCOMMODATION_AREA_QUANTITY_DISTANCE_CRITERIAS = "accommodation_area_quantity_distance_criterias",
    INTERVAL_FILTERS = "interval_filters",
    ACCOMMODATION_TYPE_FILTERS = "accommodation_type_filters",
    ENUM_FILTERS = "enum_filters",
    OTHER_FILTERS = "other_filters",
    AMENITIES_FILTERS = "amenities_filters",
    ACCOMMODATION_UNIT_CRITERIAS = "accommodation_unit_criterias",
    ACCOMMODATION_QUANTITY_DISTANCE_CRITERIAS = "accommodation_quantity_distance_criterias",
    ACCOMMODATION_AMENITIES = "accommodation_amenities",
}

export type TReservationTypeCriterias = {
    [key: string]: TCriteriaItem[];
};

export type TImage = {
    desc: string;
    translatedDesc: TTranslatedDecriptions;
    url: string;
    sortIndex: string;
};

type TTranslatedDecriptions = {
    [key: string]: string;
};

type TOccation = {
    date: string;
    fromtime: string;
    totime: string;
};

type TPresentationCriteriasDef = {
    title: string;
    type: string;
    typeunit: string;
};

type TPricematrixItem = {
    [key: number]: number;
};

export type TItem = {
    activitylid: number;
    agecategory?: string;
    aprice: number;
    arrdate: string;
    arttext: string;
    available?: number;
    booked?: string;
    bookstat?: string;
    bookonunit?: boolean | null;
    detlid?: number;
    cart_id: string | null;
    category?: string;
    capac?: number;
    channellid: number;
    children: any[];
    connected: boolean;
    connectedProductId?: number | string;
    count?: number; // e.g number of selected activity occurences to be used on the checkout page
    created: string;
    crits: TCrits;
    curr: string;
    days: number;
    default_web: boolean | null;
    demand_web: boolean | null;
    desc1: string;
    depdate: string;
    dynamicprices: boolean;
    freesale?: string;
    fromtime: string | null;
    group: string;
    groupTitle: string;
    grouppoollid: number;
    guestNotes: { [key: string]: string };
    guests: { [key: string]: any };
    guestages?: { [key: string]: number };
    handled: boolean | null;
    hasPriceCalendar: boolean;
    hidden: boolean;
    id: number;
    images: TImage[];
    info?: TInfo;
    intervallid: number;
    insurance?: boolean;
    keycardNo: null;
    keycardPrice?: number;
    kind: string;
    kinddesc: string;
    limittoonereservation: string;
    location?: string;
    locked?: boolean;
    mainimage: TImage | null;
    mandatory_web: boolean | null;
    maxage: number;
    minage: number;
    note: string | null;
    occations: TOccation[];
    ordinaryprice: number;
    original_id: number | null;
    parent: number | null;
    parentpool: number | null;
    photomandatory: boolean;
    phones?: { [key: string]: string };
    pooldesc1: string | null;
    pooldesc2: string | null;
    pooldesc3: string | null;
    pooldesc4: string | null;
    poollid: number;
    presentationcritdefs: TPresentationCriteriasDef[];
    price: number;
    pricec1?: number;
    pricec2?: number;
    pricec3?: number;
    pricec4?: number;
    pricecode: string;
    pricetext: string;
    promotionCode?: string;
    quantity: number;
    requiresimage: boolean;
    resultset: string;
    rules: {
        show_web: boolean;
        show_owner: boolean;
        mandatory_web: boolean;
        mandatory_owner: boolean;
        demand_web: boolean;
        default_web: boolean;
        default_owner: boolean;
        showpriceweb: boolean;
        initial_qty: number;
        initial_min_qty: number;
        initial_max_qty: number;
        qty: number;
        min_qty: number;
        max_qty: number;
        type_input: string;
    };
    showpriceweb: boolean;
    sortorder: number;
    title: string;
    totime: string | null;
    type: number;
    unitdesc1: string | null;
    unitdesc2: string | null;
    unitdesc3: string | null;
    unitlid: number;
    weblong: string;
    webshort: string;
    thumbnail: TImage | null;
    ownerlid: number | null;
    pricematrix?: TPricematrixItem;
    bookedquantity?: number;
    latitude: number | null;
    longitude: number | null;
};

export type TInfo = {
    guests?: { [key: string]: number };
    extras?: TItem[];
};

export type TStringIndexedObjectWithNumbers = {
    [key: string]: number;
};

export interface TCartItem extends TItem {
    count: number;
    additional: { quantity: number; price: number };
    // pricematrix: TPricematrixItem;
    available: number;
    checked: boolean;
    info: {
        guests: { totalGuests: number; [key: string]: number };
        extras: TCartItem[];
    };
    withPrice?: boolean;
    items?: TCartItemItem[];
    bookedquantity?: number;
    deliveryPoint?: string;
    priceInsurance?: number;
    guestages: { [key: string]: number };
    cartItemId: number | string;
    cartItemAddedTimestamp: string;
    groupedWith?: number;
}

export type TCartItemItem = {
    id?: string;
    guestId?: string | null;
    keycardNo?: string;
    keycard?: string;
    valid?: boolean;
    note?: string;
    customActivityPhone?: boolean;
    phoneprefix?: string;
    phone?: string;
    withInsurance?: boolean;
    deliveryPoint?: string;
    minAge?: number;
    maxAge?: number;
    firstname?: string;
    lastname?: string;
};

type TThumbnail = {
    hash: string;
    desc: string;
    url: string;
};

export type TGeneralAge = {
    key: string;
    title: string;
    description: string;
    value: number;
    min: number;
    max: number;
    default: number;
    minCount: number;
    requiresAge?: boolean;
    guestCount?: number;
    agecat: "c1" | "c2" | "c3" | "c4" | "c5";
};

export type TGeneralAges = {
    [key: number]: TGeneralAge[];
};

type TFilterEnum = {
    lid: number;
    parentlid: number;
    value: string;
};

export type TFilterCriteria = {
    id?: number;
    title?: string;
    desc1?: string;
    code: number;
    sortorder: number;
    asParent: boolean;
    displayAs: "range" | "multicheck" | "bool";
    enumFilter: boolean;
    child: null;
    extra: boolean;
    parent: number;
    enums: { [key: number]: TFilterEnum };
    type: string;
    typeunit: string;
    enumCount: number;
    enumFiltered: number;
    label?: string;
    min: number;
    max: number;
};

export type TCheckoutCart = {
    products: TCartItem[];
    error: any;
    createdFromReservation: string | null;
    note: string;
    addedAllProductsSucceed: boolean;
    correlation_id: number;
    cartId: string;
    primaryGuestId: number;
    bookingForOthers: boolean | null;
    summary: TCheckoutCartSummary;
};

export type TCheckoutCartSummary = {
    id: string;
    correlation_id: number;
    primary_guest_id: number;
    resvid: number | null;
    headlid: number;
    restored_from_reservation: boolean;
    cookies: string;
    summary: {
        total_products: number;
        total_extras: number;
        total_guests: number;
        total_amount: number;

        total_amount_to_pay: number;
        total_amount_paid: number;
    };
    products: { [key: string]: TCartItem };
    guests: { [key: string]: TGuest };
    detailedView?: boolean;
};

export type TAccount = {
    user: TUser;
    newUser: TNewUser;
    token: string;
    isAgent: any;
    error: TError;
    failedToCreateNewUser?: {
        error: boolean;
        data: { code: string; message: string; data: { [key: string]: string } }[];
    } | null;
    bookingDetailedView?: boolean;
    loginSuccessful?: boolean;
    reservations: TReservation[];
    successfullyUpdatedUserData: boolean;
};

export type TError = {
    successful: boolean;
    is_su: boolean;
    is_agent: boolean;
    is_admin: boolean;
    code: number;
    errors: { data: string; message: string }[];
    meta: {
        timestamp: string;
        codebase: string;
        revision: string;
        app_version: number;
        timing: {
            api: string;
        };
        message: string;
    };
    payload: any;
} | null;

export type TReservationRules = {
    id: string;
    allowinvoice: boolean;
    allowmodify: boolean;
    allowcancellation: boolean;
    allowprepayment: boolean;
    allowfullpayment: boolean;
    allowccancel: boolean;
    allowklarna: boolean | null;
    noofinvoicedays: null | number;
    minpayamount: number;
    maxpayamount: number;
    reservationok: boolean;
    totamountinclvat: number;
    totamounttopay: number;
    tottaxamounttopay: number;
    totvatamount: number;
    totalamount: number;
    totaltax: number;
    paidamount: number;
    prelimary: boolean;
    headlid: number;
    deposit: number;
    deliveryrequireaccommodation: boolean;
    rows: any;
    prepayamount: number;
    prefinalamount: number;
    finalamount: number;
    duedate: string;
    duedatenext: null | string;
    partPaymentType: null | string;
    transaction: string;
};

export type TReservation = {
    error: boolean;
    resvid: string;
    headlid: number;
    payerlid: number;
    cancelled: boolean;
    arrdate: string;
    depdate: string;
    reservation: {
        id: string;
        created: string;
        session: string;
        headlid: number;
        resvid: string;
        hash: string;
        agentMainGiestLid: null | number;
        version: null | number;
        products: { [key: string]: TCartItem };
        agentlid: null | number;
        agenttype: null | number;
        payerlid: number;
        paymentMethod: string;
        status: number;
        invoicebuntno: number;
        invoicedoclid: null | number;
        invoiceocrnumber: null | number;
        invoicefilename: null | string;
        invoiceurl: null | string;
        invoicefilepath: null | string;
        sentConfirmation: boolean;
        hasinvoice: boolean;
        partPaymentType: null;
        new_guestlids: [];
        cancelled?: boolean;
    };
    reservationrules: TReservationRules;
    guests: { [key: string]: TGuest };
    allowcancellation: boolean;
    allowpayment: boolean;
    allowrestore: boolean;
    getinvoice: boolean;
    hasinvoice: boolean;
};

export type TUser = {
    addr1: string;
    addr2: string;
    addr3: string;
    addr4: string;
    addr5: string;
    address: string;
    addrlid: number;
    agreements: { [key: number]: boolean } | null;
    birthday: string;
    city: string;
    country: string;
    email: string;
    fcontactdate: null;
    firstname: string;
    futureReservations: null;
    headSize: number;
    height: number;
    id: number;
    imagehash: string;
    isAgent: boolean;
    isOwner: boolean;
    keycardNo: string;
    lastname: string;
    name1: string;
    name2: string;
    orgno: number;
    phone: string;
    phone3: string;
    phone3prefix: number;
    phonefull: number;
    phoneprefix: number;
    postalcode: number;
    shoesize: number;
    weight: number;
};

export type TNewUser = {
    name1: string;
    name2: string;
    addr1: string;
    addr2: string;
    addr3: string;
    addr4: string;
    addr5: string;
    phone3prefix: number;
    phone3: string;
    email: string;
    password: string;
    repeatpassword: string;
    year: number;
    month: number;
    day: number;
};

export type TClientCountry = {
    code: string;
    default: string;
    desc2: string;
    isocode: string;
    lang: string;
    name: string;
    phoneprefix: string;
};

export type TDeliveryPoint = {
    namenative: string;
    nametranslated: string;
    namedisplay: string;
    shopid: number;
};

export type TAgreement = {
    gdprtypeisactive: string;
    gdprtypelid: number;
    defaultvaliddays: number;
    desc1: string;
    desc2: string;
    yestext: string;
    notext: string;
};

export type TSignUpWrapper = {
    showWrapper: boolean;
    loginModal: boolean;
    registrationModal: boolean;
    pathname: string | null;
};
