import moment from "moment";

export const getDateObjectForRequests = (query, search, nullIfNotFound = false) => {
    // Start date
    const setStartDate = () => {
        if (query.startDate) {
            return query.startDate;
        } else if (search?.arrdate) {
            return search.arrdate;
        }

        if (nullIfNotFound) {
            return null;
        } else {
            return moment().format("YYYY-MM-DD");
        }
    };

    // End date
    const setEndDate = () => {
        if (query.endDate) {
            return query.endDate;
        } else if (search?.depdate) {
            return search.depdate;
        }

        if (nullIfNotFound) {
            return null;
        } else {
            return moment().add(1, "weeks").format("YYYY-MM-DD");
        }
    };

    return {
        startDate: setStartDate(),
        endDate: setEndDate(),
    };
};
