import moment from "moment-timezone";
import React from "react";
import { DateRangePicker, DayPickerRangeController, SingleDatePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { connect } from "react-redux";
import { Button } from "..";
import { fastDateIsSameOrBetween } from "../../../Helper";
import * as types from "../../../store/actions/types";
import "./DatePicker.scss";

// eslint-disable-next-line
Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
};

/*
// 2 good methods to use when debugging dates and if you want to narrow your debugging to a few selected dates
const validDay = (day) => {
  return day.isAfter('2022-04-07') && day.isBefore('2022-04-11');
}

const log = (day, ...data) => {
  if (validDay(day)) {
    console.log('DEBUG', day.format(), data);
  }
}
*/

// Dater picker component
class DatePicker extends React.Component {
    state = {
        show: false,
        focusedInput: null,
        startDate: null,
        endDate: null,
        date: null,
        focused: null,
        bookableStartDays: [],
        availableEndDatesToStartDate: null,
        initialStartdate: null,
        blockedPeriodsDays: {},
    };

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.startDate !== prevProps.startDate) {
            this.setState({
                startDate: this.props.startDate,
            });
        }
        if (this.props.endDate !== prevProps.endDate) {
            this.setState({
                endDate: this.props.endDate,
            });
        }
        if (this.props.clearDates !== prevProps.clearDates) {
            if (this.props.clearDates) {
                this.setState({
                    startDate: null,
                    endDate: null,
                    focusedInput: "startDate",
                });
            }
        }
        if (prevProps.resvType && this.props.resvType !== prevProps.resvType) {
            // Re-initiate the calendar if the reservation type changes.
            this.initiate();
        }
    }

    componentDidMount() {
        this.initiate();
    }

    initiate = () => {
        if (this.props.calendarData2?.calendar[this.props.resvType]?.startdate) {
            const initialStartdate = moment(this.props.calendarData2.calendar[this.props.resvType].startdate);
            this.setState({ initialStartdate: initialStartdate });
        }

        if (this.props.startDate) {
            this.setState({ startDate: this.props.startDate });
        }

        if (this.props.endDate) {
            this.setState({ endDate: this.props.endDate });
        }

        if (this.props.date) {
            this.setState({ date: this.props.date });
        }

        const blockedPeriodsDays = this.getBlockedPeriodsDays();

        this.setState({ blockedPeriodsDays }, this.matchBookableDays);
    };

    checkIfAnyDayInRangeIsInBlockedPeriods = (rangeStart, rangeEnd) => {
        const now = rangeStart.clone();
        let anyDayInRangeIsInBlockedPeriods = false;

        // Early return if no blocked periods.
        if (Object.keys(this.state.blockedPeriodsDays).length === 0) {
            return false;
        }

        while (now.isSameOrBefore(rangeEnd)) {
            if (this.state.blockedPeriodsDays[now.format("YYYY-MM-DD")]) {
                anyDayInRangeIsInBlockedPeriods = true;
                break;
            }

            now.add(1, "days");
        }

        return anyDayInRangeIsInBlockedPeriods;
    };

    isDayBlocked = day => {
        // TODO: Why are we using calendarData2??? Comes from mapStateToProps() at the bottom. We already have calendarData as prop??
        const available_periods = this.props.availablePeriods || this.props.calendarData2?.available_periods;

        // Check if current day is before the start date of the calendar.
        if (this.props.calendarData2?.calendar[this.props.resvType].startdate) {
            const startDate = moment(this.props.calendarData2?.calendar[this.props.resvType].startdate);
            const isBefore = day.diff(startDate) < 0;

            if (isBefore) {
                return true;
            }
        }

        // If the day exists in the days extracted from blocked periods, block the day.
        if (this.state.blockedPeriodsDays[day.format("YYYY-MM-DD")]) {
            return true;
        }

        // Check if no available periods exists for the reservation type.
        // If so, limit by the startdate and enddate of the calendar.
        if (this.props.resvType && !available_periods?.[this.props.resvType]) {
            const startDate = moment(this.props.calendarData2?.calendar[this.props.resvType].startdate);
            const endDate = moment(this.props.calendarData2?.calendar[this.props.resvType].enddate);
            const isSameOrBetween = day.isBetween(startDate, endDate, "day", "[]");
            return !isSameOrBetween;
        }

        // Check if the current day is one of the start dates and that it has at least one matching end date.
        if (this.state.availableEndDatesToStartDate?.[day.format("YYYY-MM-DD")]?.length >= 1) {
            // The current day is a valid start date.

            // If no dates has been selected, the day is not blocked.
            if (!this.state?.startDate && !this.state.endDate) {
                return false;
            }

            // If both start and end dates has been selected, the day is not blocked.
            if (this.state?.startDate && this.state.endDate) {
                return false;
            }
        }

        // Checks what days should be blocked when only start date is selected.
        // Only valid departure dates will be visible during selection.
        if (
            this.state?.startDate &&
            !this.state.endDate &&
            this.state.availableEndDatesToStartDate?.[this.state?.startDate.format("YYYY-MM-DD")]?.includes(
                day.format("YYYY-MM-DD")
            )
        ) {
            return false;
        }

        //
        // -- Date is blocked if it arrives here (good place for debugging)
        //
        return true;
    };

    onDatesChange = (startDate, endDate) => {
        if (this.state.startDate && startDate !== this.state.startDate) {
            endDate = null;
        }

        this.setState({ focusedInput: "startDate" }, () => {
            // Always set focus on startDate for controller if 'singleDate' property is supplied...
            if (this.props.singleDate && startDate) {
                this.setState({ focusedInput: "startDate" });
                // ... or unfocus input fields if on mobile device, else set focus as usually if not.
            } else {
                this.setState({
                    focusedInput: startDate && endDate ? (this.props.isMobile ? null : "startDate") : "endDate",
                });
            }
        });

        this.setState({ startDate: startDate, endDate: endDate });

        this.props.changedDate(moment(startDate).format("YYYY-MM-DD"), moment(endDate).format("YYYY-MM-DD"));
    };

    matchingPeriods = (date, availablePeriods) => {
        if (availablePeriods) {
            const periods = availablePeriods.filter(availableDate =>
                fastDateIsSameOrBetween(date, availableDate.startdate, availableDate.enddate)
            );
            return periods;
        }

        return [];
    };

    matchSelectedDay = () => {
        const uniqueAvailablePeriodsForReservationType = this.getUniqueAvailablePeriodsForReservationType(
            this.props.resvType
        );
        let availableEndDatesToStartDate = {};

        (this.state.bookableStartDays || []).forEach(element => {
            const day = moment(element, "YYYY-MM-DD");
            const dayDate = day.format("YYYY-MM-DD");

            const periods = this.matchingPeriods(dayDate, uniqueAvailablePeriodsForReservationType);

            periods.forEach(period => {
                const periodEndDay = moment(period.enddate);

                period.rules.forEach(([startday, length]) => {
                    if (length !== -1 && (startday === day.isoWeekday() || startday === -1)) {
                        const depDay = moment(day.format("YYYY-MM-DD")).add(length, "day");
                        const depDayDate = depDay.format("YYYY-MM-DD");

                        // Check that the arrival date is before the departure date.
                        // Check if the departure date is before or same as the period todate.
                        // Check if any day between arrival and departure date is blocked.
                        if (
                            day.isBefore(depDay) &&
                            depDay.isSameOrBefore(periodEndDay) &&
                            !this.checkIfAnyDayInRangeIsInBlockedPeriods(day, depDay)
                        ) {
                            if (!availableEndDatesToStartDate[dayDate]) {
                                availableEndDatesToStartDate[dayDate] = [];
                            }
                            availableEndDatesToStartDate[dayDate].push(depDayDate);
                        }
                    } else if (length === -1) {
                        //console.log("Matching period", dayDate, period);
                        for (let index = 1; index < 50; index++) {
                            const depDay = moment(day.format("YYYY-MM-DD")).add(index, "day");
                            const depDayDate = depDay.format("YYYY-MM-DD");

                            // Check if the departure date is before or same as the period todate.
                            // Check if any day between arrival and departure date is blocked.
                            if (
                                depDay.isSameOrBefore(periodEndDay) &&
                                !this.checkIfAnyDayInRangeIsInBlockedPeriods(day, depDay)
                            ) {
                                if (!availableEndDatesToStartDate[dayDate]) {
                                    availableEndDatesToStartDate[dayDate] = [];
                                }

                                availableEndDatesToStartDate[dayDate].push(depDayDate);
                            } else {
                                // No need to check more dates if day is blocked or if the period todate has been passed.
                                break;
                            }
                        }
                    }
                });
            });
        });

        this.setState({ show: true });
        this.setState({ availableEndDatesToStartDate });
    };

    matchBookableDays = () => {
        // Find out the last available date used for generating the arrays of bookable below
        const lastAvailableDate = this.props.calendarData2
            ? this.props.calendarData2.calendar[-1].enddate // use last available date for all reservation types (combined)
            : moment().add(3, "year").format("YYYY-MM-DD"); // default to +3 years if no calendar data exists

        let start = moment().toDate();
        let target = moment(lastAvailableDate).toDate();

        // These arrays contain all valid and bookable days that are shown as selectable in the calendar
        let bookableStartDays = [];

        const uniqueAvailablePeriodsForReservationType = this.getUniqueAvailablePeriodsForReservationType(
            this.props.resvType
        );

        while (target > start) {
            const day = moment(start);
            const dayDate = day.format("YYYY-MM-DD");

            if (!this.state.blockedPeriodsDays[dayDate]) {
                const periods = this.matchingPeriods(dayDate, uniqueAvailablePeriodsForReservationType);
                let dayMatchesPeriods = false;

                periods.forEach(period => {
                    period.rules.forEach(([startday]) => {
                        if (day.isoWeekday() === startday || startday === -1) {
                            dayMatchesPeriods = true;
                            return;
                        }
                    });
                });

                if (dayMatchesPeriods) {
                    bookableStartDays.push(dayDate);
                }
            }

            start = start.addDays(1);
        }

        this.setState({ bookableStartDays }, this.matchSelectedDay);
    };

    getBlockedPeriodsDays = () => {
        const blockedPeriods =
            this.props.blockedPeriods?.[this.props.resvType] ||
            this.props.calendarData2?.blocked_periods[this.props.resvType] ||
            [];

        const blockedPeriodsDays = {};

        blockedPeriods
            // Skip auto blocked periods for now
            .filter(period => !period.auto)
            .forEach(period => {
                let now = moment(period.fromdate);
                const toDate = moment(period.todate);

                while (now.isSameOrBefore(toDate)) {
                    const nowDate = now.format("YYYY-MM-DD");
                    blockedPeriodsDays[nowDate] = true;
                    now.add(1, "days");
                }
            });

        return blockedPeriodsDays;
    };

    // Combine available periods that have identical start/end date and rules.
    getUniqueAvailablePeriodsForReservationType = resvType => {
        const availablePeriods =
            (this.props.availablePeriods || this.props.calendarData2?.available_periods)?.[resvType] || [];

        const uniqueAvailablePeriods = {};

        availablePeriods.forEach(period => {
            const key = `${period.startdate}_${period.enddate}_${JSON.stringify(period.rules)}`;

            if (!uniqueAvailablePeriods[key]) {
                uniqueAvailablePeriods[key] = {
                    startdate: period.startdate,
                    enddate: period.enddate,
                    rules: period.rules,
                };
            }
        });

        return Object.values(uniqueAvailablePeriods);
    };

    onSingleDateChange = date => {
        this.setState({ date });
        if (this.props.onDateChange) {
            this.props.onDateChange(moment(date).format("YYYY-MM-DD"));
        }
    };

    // Highlight parent container if any input field has focus
    highlightParentIfFocused = () => {
        if (this.props.focusChanged) {
            if (this.state.focused || this.state.focusedInput) {
                this.props.focusChanged(true);
            } else {
                this.props.focusChanged(false);
            }
        }
    };

    // Render icon for previous month navigation
    renderNavPreviousMonth = () => {
        return (
            <ion-icon
                class="DayPickerNavigation_button__horizontalDefault DayPickerNavigation_leftButton__horizontalDefault"
                name="chevron-back-outline"
            ></ion-icon>
        );
    };

    // Render icon for previous month navigation
    renderNavNextMonth = () => {
        return (
            <ion-icon
                class="DayPickerNavigation_button__horizontalDefault DayPickerNavigation_rightButton__horizontalDefault"
                name="chevron-forward-outline"
            ></ion-icon>
        );
    };

    // Hack to render week i Date picker
    // https://github.com/airbnb/react-dates/issues/889
    renderDayContents = day => {
        return (
            <>
                <span className="CalendarDayWeekNumber">{day.format("W")}</span>
                <div className="CalendarDay__date-outer-container">
                    <div className="CalendarDay__date-inner-container">
                        <div className="CalendarDay__date">{day.format("D")}</div>
                    </div>
                </div>
            </>
        );
    };

    // Render custom arrow
    renderDateSeparatorIcon = () => {
        return <ion-icon name="remove-outline"></ion-icon>;
    };

    // Render button to reset chosen calendar dates
    renderClearDatesButton = () => {
        return (
            <div className="date-picker__clear-dates">
                <Button
                    className="btn-sm button--grey date-picker__clear-dates-button"
                    onClick={() =>
                        this.setState({
                            startDate: null,
                            endDate: null,
                            focusedInput: "startDate",
                        })
                    }
                >
                    {this.props.texts?.cleardates || "Rensa datum"}
                </Button>
            </div>
        );
    };

    // Overrides all default phrases translation
    defaultPhrasesOverrides = texts => {
        const chooseAvailableStartDate = ({ date }) => `Välj ${date} som ditt incheckningsdatum. Det är tillgängligt.`;
        const chooseAvailableEndDate = ({ date }) => `Välj ${date} som ditt utcheckningsdatum. Det är tillgängligt.`;
        const chooseAvailableDate = ({ date }) => date;
        const dateIsUnavailable = ({ date }) => `Ej tillgänglig. ${date}`;
        const dateIsSelected = ({ date }) => `Vald. ${date}`;
        const dateIsSelectedAsStartDate = ({ date }) => `Valt startdatum. ${date}`;
        const dateIsSelectedAsEndDate = ({ date }) => `Valt slutdatum. ${date}`;
        const DateRangePickerOverride = {
            calendarLabel: texts?.["calendar.calendarlabel"],
            roleDescription: texts?.["calendar.roledescription"],
            closeDatePicker: texts?.["calendar.closedatepicker"],
            focusStartDate: texts?.["calendar.focusstartdate"],
            clearDate: texts?.["calendar.cleardate"],
            clearDates: texts?.["calendar.cleardates"],
            jumpToPrevMonth: texts?.["calendar.jumptoprevmonth"],
            jumpToNextMonth: texts?.["calendar.jumptonextmonth"],
            keyboardShortcuts: texts?.["calendar.keyboardshortcuts"],
            showKeyboardShortcutsPanel: texts?.["calendar.showkeyboardshortcutspanel"],
            hideKeyboardShortcutsPanel: texts?.["calendar.hidekeyboardshortcutspanel"],
            openThisPanel: texts?.["calendar.openthispanel"],
            enterKey: texts?.["calendar.enterKey"],
            leftArrowRightArrow: texts?.["calendar.leftarrowrightarrow"],
            upArrowDownArrow: texts?.["calendar.uparrowdownarrow"],
            pageUpPageDown: texts?.["calendar.pageuppagedown"],
            homeEnd: texts?.["calendar.homeend"],
            escape: texts?.["calendar.escape"],
            questionMark: texts?.["calendar.questionmark"],
            selectFocusedDate: texts?.["calendar.selectfocuseddate"],
            moveFocusByOneDay: texts?.["calendar.movefocusbyoneday"],
            moveFocusByOneWeek: texts?.["calendar.movefocusbyoneweek"],
            moveFocusByOneMonth: texts?.["calendar.movefocusbyonemonth"],
            moveFocustoStartAndEndOfWeek: texts?.["calendar.movefocustostartandendofweek"],
            returnFocusToInput: texts?.["calendar.returnfocustoinput"],
            keyboardForwardNavigationInstructions: texts?.["calendar.keyboardforwardnavigationinstructions"],
            keyboardBackwardNavigationInstructions: texts?.["calendar.keyboardbackwardnavigationinstructions"],
            chooseAvailableStartDate: chooseAvailableStartDate,
            chooseAvailableEndDate: chooseAvailableEndDate,
            chooseAvailableDate: chooseAvailableDate,
            dateIsUnavailable: dateIsUnavailable,
            dateIsSelected: dateIsSelected,
            dateIsSelectedAsStartDate: dateIsSelectedAsStartDate,
            dateIsSelectedAsEndDate: dateIsSelectedAsEndDate,
        };
        return DateRangePickerOverride;
    };

    // Render date picker with only one date
    renderSingleDatePicker = () => {
        return (
            <SingleDatePicker
                isDayBlocked={this.isDayBlocked}
                block
                readOnly
                hideKeyboardShortcutsPanel
                enableOutsideDays
                transitionDuration={0}
                id={this.props.id || "inputDateId"}
                date={this.state.date}
                placeholder={this.props.placeholder || this.props.texts?.calendarselectdate}
                ariaLabel={this.props.ariaLabel || this.props.texts?.calendarselectdate}
                onDateChange={date => this.onSingleDateChange(date)}
                focused={this.state.focused}
                onFocusChange={({ focused }) => this.setState({ focused }, () => this.highlightParentIfFocused())}
                numberOfMonths={1}
                renderDayContents={day => this.renderDayContents(day)}
                firstDayOfWeek={1}
                keepOpenOnDateSelect={this.props.keepOpenOnDateSelect === false ? false : true}
                phrases={this.defaultPhrasesOverrides(this.props.texts)}
                noBorder={this.props.noBorder || false}
                initialVisibleMonth={() => this.state.date || this.state.initialStartdate || moment()}
            />
        );
    };

    // Render date picker with start and end date
    renderDateRangePicker = () => {
        return (
            <DateRangePicker
                isDayBlocked={this.isDayBlocked}
                block
                hideKeyboardShortcutsPanel
                readOnly
                transitionDuration={0}
                keepOpenOnDateSelect={this.props.keepOpenOnDateSelect === false ? false : true}
                startDate={this.state.startDate}
                startDateId={this.props.startDateId || "checkinInputId"}
                startDatePlaceholderText={this.props.texts?.calendararrivaldate}
                startDateAriaLabel={this.props.startDateAriaLabel || this.props.texts?.calendardateofcheckin}
                endDate={this.state.endDate}
                endDateId={this.props.endDateId || "checkoutInputId"}
                endDatePlaceholderText={this.props.texts?.calendardepartdate}
                endDateAriaLabel={this.props.endDateAriaLabel || this.props.texts?.calendardateofcheckout}
                onDatesChange={({ startDate, endDate }) => this.onDatesChange(startDate, endDate)}
                focusedInput={this.state.focusedInput}
                onFocusChange={focusedInput => {
                    this.setState({ focusedInput }, () => this.highlightParentIfFocused());
                }}
                customArrowIcon={this.renderDateSeparatorIcon()}
                renderDayContents={day => this.renderDayContents(day)}
                firstDayOfWeek={1}
                navPrev={this.renderNavPreviousMonth()}
                navNext={this.renderNavNextMonth()}
                displayFormat={() => "ddd DD MMM"}
                numberOfMonths={this.props.isMobile ? 1 : this.props.numberOfMonths || 2}
                phrases={this.defaultPhrasesOverrides(this.props.texts)}
                noBorder={this.props.noBorder || false}
                initialVisibleMonth={() => this.state.startDate || this.state.initialStartdate || moment()}
                calendarInfoPosition="bottom"
                renderCalendarInfo={() => this.renderClearDatesButton()}
            />
        );
    };

    // Render day picker range controller
    renderDayPickerRangeController = () => {
        return (
            <DayPickerRangeController
                isDayBlocked={this.isDayBlocked}
                hideKeyboardShortcutsPanel
                transitionDuration={0}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                onDatesChange={({ startDate, endDate }) =>
                    this.props.singleDate
                        ? this.onDatesChange(startDate, startDate)
                        : this.onDatesChange(startDate, endDate)
                }
                focusedInput={this.state.focusedInput || this.props.focusedInput || "startDate"}
                onFocusChange={focusedInput =>
                    this.props.singleDate
                        ? this.setState({ focusedInput: "startDate" })
                        : this.setState({ focusedInput: focusedInput || "startDate" })
                }
                renderDayContents={day => this.renderDayContents(day)}
                numberOfMonths={this.props.isMobile ? 1 : this.props.numberOfMonths || 2}
                firstDayOfWeek={1}
                navPrev={this.renderNavPreviousMonth()}
                navNext={this.renderNavNextMonth()}
                phrases={this.defaultPhrasesOverrides(this.props.texts)}
                noBorder={this.props.noBorder || false}
                initialVisibleMonth={() => this.state.startDate || this.state.initialStartdate || moment()}
            />
        );
    };

    render = () => {
        // console.log("this.state.availableEndDatesToStartDate", this.state.availableEndDatesToStartDate);
        // console.log("this.state.blockedPeriodsDays", this.state.blockedPeriodsDays);
        // console.log("this.state.bookableStartDays", this.state.bookableStartDays);
        // console.log("this.props.availablePeriods", this.props.availablePeriods);
        // console.log(
        //     "this.props.calendarData2?.available_periods?.[resvType]",
        //     this.props.calendarData2?.available_periods?.[this.props.resvType]
        // );

        const { showRange, showController } = this.props;
        return (
            <div className="date-picker-old">
                {(() => {
                    if (showRange) {
                        return this.renderDateRangePicker();
                    } else if (showController) {
                        return (
                            <div
                                className={
                                    this.props.singleDate
                                        ? "date-picker__single-date-controller"
                                        : "date-picker__date-range-controller"
                                }
                            >
                                {!this.state.show && this.renderDayPickerRangeController()}
                                {this.state.show && this.renderDayPickerRangeController()}
                            </div>
                        );
                    }
                    return this.renderSingleDatePicker();
                })()}
            </div>
        );
    };
}

const mapDispatchToProps = dispatch => {
    return {
        increment: () => dispatch({ type: types.FETCH_CALENDAR_DATA }),
    };
};

const mapStateToProps = state => ({
    calendarData2: state.clientData.calendarData,
    reservationTypes: state.clientData.reservationtypes,
    texts: state.clientData.texts
        ? Object.entries(state.clientData?.texts).reduce((acc, [key, value]) => {
              acc[key] = value.value;
              return acc;
          }, {})
        : {},
    isMobile: state.window.isMobile,
    lang: state.clientData.lang,
});

export default connect(mapStateToProps, mapDispatchToProps)(DatePicker);
