import moment from "moment";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { formatPrice } from "../../Helper";
import useAppSelector from "../../hooks/useAppSelector";
import useTranslate from "../../hooks/useTranslate";
import { Button, ChevronUpIcon, ChevronDownIcon } from "@r360/library";
import { TAddToCartData } from "./GroupIncrementerCard";

type Props = {
    addToCartData: TAddToCartData[];
    onAddToCart: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    addToCartSuccess: boolean;
};

export const GroupIncrementerSummary = ({ addToCartData, onAddToCart, addToCartSuccess }: Props) => {
    const [accordionOpen, setAccordionOpen] = useState(false);
    const currency = useAppSelector(state => state.clientData?.options?.general?.currency);
    const totalPrice = addToCartData.reduce((prev, curr) => prev + curr.quantity * curr.item.price, 0);
    const t = useTranslate();

    return addToCartData.length > 0 ? (
        <div style={{ overflow: "hidden" }}>
            <div className="group-incrementer-card-summary">
                <div className="group-incrementer-card-summary__accordion">
                    <div className="group-incrementer-card-summary__accordion-title">
                        <button
                            className="group-incrementer-card-summary__accordion-btn"
                            onClick={() => setAccordionOpen(prevState => !prevState)}
                        >
                            <span className="group-incrementer-card-summary__accordion-label">
                                {`${t("book.results.total_price")}: `}
                                <span>{`${formatPrice(totalPrice, currency)}`}</span>
                            </span>
                            {accordionOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                        </button>
                        <div className="group-incrementer-card-summary__desktop-add-to-cart">
                            {addToCartSuccess ? (
                                <Button
                                    fullWidth
                                    type="success"
                                    buttonClassName="a-scale-11 group-incrementer-card-summary__add-to-cart-success-btn"
                                >
                                    {t("book.results.added_to_cart")}
                                </Button>
                            ) : (
                                <Button fullWidth onClick={onAddToCart}>
                                    {t("book.results.add_to_cart")}
                                </Button>
                            )}
                        </div>
                    </div>
                    {accordionOpen && (
                        <div className="group-incrementer-card-summary__accordion-content">
                            {addToCartData
                                // .sort((a, b) => a.id > b.id)
                                .map(({ item, quantity }) => {
                                    return (
                                        <Row key={item.id} className="group-incrementer-card-summary__row">
                                            <Col xs={6} sm={4} className="group-incrementer-card-summary__column">
                                                <span>{`${quantity} x ${item.title}`}</span>
                                                <br />
                                                <span className="group-incrementer-card-summary__column-mobile-only">
                                                    [
                                                    {`${moment(item.arrdate).format("ddd DD MMM")} - ${moment(
                                                        item.depdate
                                                    ).format("ddd DD MMM")}`}
                                                    ]
                                                </span>
                                            </Col>
                                            <Col xs={6} sm={6} className="group-incrementer-card-summary__column">
                                                [
                                                {`${moment(item.arrdate).format("ddd DD MMM")} - ${moment(
                                                    item.depdate
                                                ).format("ddd DD MMM")}`}
                                                ]
                                            </Col>
                                            <Col xs={6} sm={2} className="group-incrementer-card-summary__column">
                                                {formatPrice(quantity * item.price, currency)}
                                            </Col>
                                        </Row>
                                    );
                                })}
                        </div>
                    )}
                </div>
                {addToCartSuccess ? (
                    <Button
                        fullWidth
                        type="success"
                        buttonSize="medium"
                        buttonClassName="group-incrementer-card-summary__mobile-add-to-cart group-incrementer-card-summary__add-to-cart-success-btn"
                    >
                        {t("book.results.added_to_cart")}
                    </Button>
                ) : (
                    <Button
                        fullWidth
                        buttonSize="medium"
                        onClick={onAddToCart}
                        buttonClassName="group-incrementer-card-summary__mobile-add-to-cart"
                    >
                        {t("book.results.add_to_cart")}
                    </Button>
                )}
            </div>
        </div>
    ) : null;
};
