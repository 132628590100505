import { useRef } from "react";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";

/**
 * A hook to return a new reference only if useeffect parameter actually changed. If not previous reference is returned.
 *
 * @param arg Argument for useEffect dependency list
 * @returns The new value for argument for useEffect dependency list if it actually changed.
 */
const useMemoizeArg = (arg: any) => {
    const ref = useRef();
    const prevArg = ref.current;

    if (isEqual(prevArg, arg)) {
        return prevArg;
    }

    ref.current = cloneDeep(arg);

    return ref.current;
};

export default useMemoizeArg;
