import React from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";
import { confirmable, createConfirmation } from "react-confirm";
import "./ConfirmationDialog.scss";

/**
 * Open confirmation dialog
 *
 * Usage:
 * const openChangeAccommodationConfirmation = async accommodation => {
 *  const response = await confirmationDialog({
 *     title: texts["checkout.accommodation.change_confirmation.title"],
 *     description: texts["checkout.accommodation.change_confirmation.description"],
 *     okLabel: texts["general.confirmation.continue"],
 *     cancelLabel: texts["general.confirmation.cancel"],
 *  })
 * }
 *
 * @param {object} props
 * @returns Promise
 */
const Confirmation = props => {
    const { okLabel = "OK", cancelLabel = "Cancel", title, description, show, proceed, enableEscape = true } = props;

    return (
        <div>
            <Modal
                show={show}
                onHide={() => proceed(false)}
                backdrop={enableEscape ? true : "static"}
                keyboard={enableEscape}
            >
                {title && (
                    <Modal.Header>
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>
                )}
                {description && <Modal.Body className="confirmation-dialog__body">{description}</Modal.Body>}
                <Modal.Footer>
                    <Button
                        onClick={() => proceed(false)}
                        variant="link"
                        size="md"
                        className="fw-light text-primary p-0"
                    >
                        {cancelLabel}
                    </Button>
                    <Button className="confirmation-dialog__buttons-ok" variant="primary" onClick={() => proceed(true)}>
                        {okLabel}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

Confirmation.propTypes = {
    okLabel: PropTypes.string,
    cancelLabel: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    enableEscape: PropTypes.bool,

    // Below props are provided by confirmable, don't change.
    show: PropTypes.bool,
    proceed: PropTypes.func,
};

export default function confirmationDialog(options = {}) {
    return createConfirmation(confirmable(Confirmation))(options);
}
