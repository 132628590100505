import React, { useEffect, useRef } from "react";
import { TAccount } from "../../store/types";
import { publishUpdatedUserData, updatedUserData } from "../../store/actions/account";
import useAppDispatch from "../../hooks/useAppDispatch";
import { Button, Notification } from "@r360/library";
import classNames from "classnames";
import useTranslate from "../../hooks/useTranslate";
import PersonalInformation, { TPersonalInformationGuest } from "../PersonalInformation/PersonalInformation";
import useAppSelector from "../../hooks/useAppSelector";

export const MyInformation = () => {
    const t = useTranslate();
    const dispatch = useAppDispatch();
    const account: TAccount = useAppSelector(state => state.account);
    const userToken = account.token;
    let user = account.user as TPersonalInformationGuest;
    const errors = account.error?.errors;
    const successfullyUpdatedUserData = account.successfullyUpdatedUserData;
    const cartIsEmpty = useAppSelector(state => !state.checkoutCart?.products.length);
    const customerOptions = useAppSelector(state => state.clientData.options.customer);
    const { isDesktop } = useAppSelector(state => state.window);
    const { requestsLoading } = useAppSelector(state => state.axiosStatus);
    const updatingDataInProgress = requestsLoading["publishUpdatedUserData"];

    const hasDuplicateUserError =
        errors && Object.keys(errors).length ? errors.some(error => error.data === "DUPLICATE") : false;
    const isAgent = !!user?.isAgent;
    const personalInformationRef = useRef<any>(null);

    if (hasDuplicateUserError) {
        // Reset the email address.
        user = { ...user, email: "" };
    }

    useEffect(() => {
        dispatch(updatedUserData(false));
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (hasDuplicateUserError) {
            // Reset the email address
            personalInformationRef.current?.detailsFormRef.setFieldValue("email", "", true);
        }
    }, [hasDuplicateUserError, personalInformationRef]);

    const handleSubmit = (userValues: any) => {
        dispatch(publishUpdatedUserData(userValues, userToken));
    };

    const renderErrorNotification = () => {
        if (!errors || Object.keys(errors).length === 0) {
            return null;
        }

        return (
            <div className="u-mb-24">
                <Notification type="error">
                    <ul className="u-mb-0">
                        {errors.map(error => {
                            if (error.data === "DUPLICATE") {
                                return <li key={error.data}>{t("emailaddressisalreadyinuse")}</li>;
                            }
                            return <li key={error.data}>{error.message}</li>;
                        })}
                    </ul>
                </Notification>
            </div>
        );
    };

    return (
        <>
            {renderErrorNotification()}
            {successfullyUpdatedUserData && (
                <div className="u-mb-24">
                    <Notification type="success">{t("book.my_pages.information_saved")}</Notification>
                </div>
            )}
            {isAgent && (
                <div className="u-mb-24">
                    <Notification type="info">{t("book.my_pages.agent_information_info")}</Notification>
                </div>
            )}
            {cartIsEmpty && (
                <div className="u-mb-24">
                    <Notification type="info">{t("book.mypages.change_existing_booking")}</Notification>
                </div>
            )}
            {!cartIsEmpty && (
                <div className="u-mb-24">
                    <Notification type="warning">{t("book.mypages.ongoing_booking")}</Notification>
                </div>
            )}
            <div className={classNames({ "u-disabled": isAgent || !cartIsEmpty || updatingDataInProgress })}>
                <div>
                    <PersonalInformation
                        ref={personalInformationRef}
                        isPrimaryGuest
                        guest={user}
                        onSubmit={handleSubmit}
                        showAgePicker={!isAgent}
                        showKeycardNo={(customerOptions?.show_keycard_number && !isAgent) || false}
                        showKeycardImage={(customerOptions?.show_keycard_image && !isAgent) || false}
                        showWeight={(customerOptions?.show_weight && !isAgent) || false}
                        showHeight={(customerOptions?.show_height && !isAgent) || false}
                        showShoesize={(customerOptions?.show_shoesize && !isAgent) || false}
                        showHeadsize={(customerOptions?.show_headsize && !isAgent) || false}
                    />
                </div>
            </div>
            {!isAgent && cartIsEmpty && (
                <div className="u-d-flex u-justify-content-end">
                    <Button
                        onClick={personalInformationRef.current?.submitForm}
                        loading={updatingDataInProgress}
                        fullWidth={!isDesktop}
                    >
                        {t("book.general.save")}
                    </Button>
                </div>
            )}
        </>
    );
};
