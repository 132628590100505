import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Glide from "@glidejs/glide";
import "@glidejs/glide/src/assets/sass/glide.core.scss";
import "@glidejs/glide/src/assets/sass/glide.theme.scss";

import { ArrowDownIcon } from "../R360Icons";
import "./Carousel.scss";
import { textsSelector } from "../../../Selectors";

// Carousel component
// based on https://github.com/glidejs/glide
const Carousel = props => {
    const {
        type = "slider",
        bound = false,
        startAt = 0,
        perView = 3,
        peek = 0, // INT or Object with 'before' and 'after' properties with INT number of pixels
        items, // Array
        className,
        children,
        itemComponent,
    } = props;
    const texts = useSelector(textsSelector);
    const isMobile = useSelector(state => state.window.isMobile);
    const showControls = items?.length > perView ? true : false;
    const showBullets = props.showBullets !== undefined ? props.showBullets : showControls && !isMobile;
    const carouselClasses = className ? `glide carousel ${className}` : "glide carousel";
    const wrapperClass = showControls ? "carousel__wrapper" : "";

    // Mount carousel on component load
    useEffect(() => {
        new Glide(".glide", {
            type,
            bound,
            startAt,
            perView,
            peek,
        }).mount();
    }, [type, bound, startAt, perView, peek]);

    // Render carousel item
    const renderItem = item => {
        if (itemComponent) {
            return itemComponent(item);
        }
        return "item";
    };

    // return
    return (
        <div className={wrapperClass}>
            <div className={carouselClasses}>
                <div data-glide-el="track" className="glide__track">
                    <ul className="glide__slides">
                        {items?.length > 0 &&
                            items.map((item, index) => {
                                return (
                                    <li className="glide__slide" key={index}>
                                        {renderItem(item, index)}
                                    </li>
                                );
                            })}
                        {children}
                    </ul>
                </div>
                {showControls && (
                    <>
                        <div className="glide__arrows" data-glide-el="controls">
                            <button className="glide__arrow glide__arrow--left" data-glide-dir="<">
                                <ArrowDownIcon size={20} color="#333333" />
                                <span className="sr-only">{texts?.previousslide || "Föregående slide"}</span>
                            </button>
                            <button className="glide__arrow glide__arrow--right" data-glide-dir=">">
                                <ArrowDownIcon size={20} color="#333333" />
                                <span className="sr-only">{texts?.nextslide || "Nästa slide"}</span>
                            </button>
                        </div>
                        {showBullets && (
                            <div className="glide__bullets" data-glide-el="controls[nav]">
                                {items?.length > 0 &&
                                    items.map((item, index) => {
                                        return (
                                            <button
                                                className="glide__bullet"
                                                data-glide-dir={`=${index}`}
                                                key={index}
                                            ></button>
                                        );
                                    })}
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default Carousel;
