import { Button, Checkbox, CloseIcon, Heading, Input, Skeleton, SkeletonGroup, TrashIcon } from "@r360/library";
import classNames from "classnames";
import cloneDeep from "lodash/cloneDeep";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { RootState } from "../..";
import { capitalizeFirstLetter } from "../../Helper";
import useAppSelector from "../../hooks/useAppSelector";
import { resvTypeByLid } from "../../selectors/Selectors";
import { removeFilter, resetFilterState, setFilter } from "../../store/actions/filter";
import {
    ECriteriaType,
    TCriteriaEnum,
    TCriteriaItem,
    TCrits,
    TGroup,
    TItem,
    TReservationTypeCriterias,
} from "../../store/types";
import { DistanceRangeFilter } from "../UI";
import "./accommodations-filter.scss";
import { newFilterGroupItems } from "../../hooks";
import useMemoizeArg from "../../hooks/useMemoizeArg";
import useTranslate from "../../hooks/useTranslate";
import capitalize from "lodash/capitalize";
import "./accommodations-filter.scss";

type TAccommodationFilter = {
    reservationTypeId: number;
    showOnMobile: boolean;
    onMobileClose: () => void;
    showFilterSearch: boolean;
    unfilteredSearchResult: TGroup[];
    filteredSearchResult: any[]; // TODO fix any
    columns?: boolean;
    isLoading?: boolean;
};

type TRangeFiltersInput = {
    [key: number]: {
        min: number;
        max: number;
    };
};

type TRangeFilterInputResponse = {
    key: number;
    value: {
        min: number;
        max: number;
    };
};

type TFilterChangeResponse = {
    details?: TCriteriaItem;
    key: string;
    type: string;
    value: any; // TODO fix any
};

type TPresentationTypes = {
    [key: string]: {
        heading?: string;
    };
};

export const AccommodationFilter = ({
    showOnMobile,
    onMobileClose,
    reservationTypeId,
    showFilterSearch,
    unfilteredSearchResult,
    filteredSearchResult,
    columns = false,
    isLoading,
}: TAccommodationFilter) => {
    const dispatch = useDispatch();
    const filters = useAppSelector((state: RootState) => state.filter);
    const activeFilters = filters && reservationTypeId in filters ? filters[reservationTypeId] || {} : {};
    const { isDesktopNewDesign } = useAppSelector((state: RootState) => state.window);
    const reservationTypeCriterias: TReservationTypeCriterias =
        useAppSelector((state: RootState) => resvTypeByLid(state, reservationTypeId))?.criterias || {};

    const [unfilteredSearchResultCriterias, setUnfilteredSearchResultCriterias] = useState<TCrits[]>([]);
    const [filteredSearchResultCriterias, setFilteredSearchResultCriterias] = useState<TCrits[]>([]);

    const [filteredSearchResultCriteriasByExcludedEnum, setFilteredSearchResultCriteriasByExcludedEnum] = useState<{
        [key: number]: TCrits[];
    }>({});

    const [rangeFiltersInput, setRangeFiltersInput] = useState<TRangeFiltersInput>({});

    const t = useTranslate();

    const presentationTypes: TPresentationTypes = {
        interval_filters: { heading: "" },
        accommodation_type_filters: { heading: "" },
        enum_filters: { heading: "" },
        amenities_filters: { heading: t("book.general.amenities") },
    };

    useEffect(() => {
        const criterias = Object.values(reservationTypeCriterias)
            .flat()
            .reduce((acc, curr) => {
                acc[curr.code] = curr;
                return acc;
            }, {} as { [key: number]: TCriteriaItem });

        // Enum filters
        const activeEnumFilters = (Object.values(reservationTypeCriterias).flat() as TCriteriaItem[]).filter(
            (criteria: TCriteriaItem) => criteria.type === ECriteriaType.ENUM && activeFilters[criteria.code]
        );

        setFilteredSearchResultCriteriasByExcludedEnum(
            activeEnumFilters.reduce((acc, criteria) => {
                // Exclude current enum from filtering.
                const activeFiltersWithExcludedCurrent = cloneDeep(activeFilters);
                delete activeFiltersWithExcludedCurrent[criteria.code];

                acc[criteria.code] = unfilteredSearchResult
                    .map(group => {
                        return newFilterGroupItems(group, activeFiltersWithExcludedCurrent || {}, criterias) as TGroup;
                    })
                    .filter(group => !!group.items.length)
                    .flatMap((group: TGroup) => group.items.map((item: TItem) => item.crits));
                return acc;
            }, {} as { [key: number]: TCrits[] })
        );
    }, [
        useMemoizeArg(activeFilters),
        useMemoizeArg(filteredSearchResult),
        useMemoizeArg(unfilteredSearchResult),
        useMemoizeArg(reservationTypeCriterias),
    ]);

    useEffect(() => {
        const extractedCriterias = unfilteredSearchResult.flatMap((group: TGroup) =>
            group.items.map((item: TItem) => item.crits)
        );

        setUnfilteredSearchResultCriterias(extractedCriterias);
    }, [useMemoizeArg(unfilteredSearchResult)]);

    useEffect(() => {
        const extractedCriterias = filteredSearchResult.flatMap(group => group.items.map((item: TItem) => item.crits));

        setFilteredSearchResultCriterias(extractedCriterias);
    }, [useMemoizeArg(filteredSearchResult)]);

    // Update redux on filter change
    const onFilterChange = (response: TFilterChangeResponse) => {
        dispatch(setFilter(response, reservationTypeId));
    };

    // Remove filter
    const removeActiveFilter = (filter: TFilterChangeResponse) => {
        dispatch(removeFilter(filter, reservationTypeId));
    };

    // Update redux on range filter change
    const onRangeFilterChange = (response: TFilterChangeResponse, rangeMin: number, rangeMax: number) => {
        // Make sure that the values being set are within the range.
        // If values are set at the end limits, the filter can be removed as active.

        if (response.value.min < rangeMin) {
            response.value.min = rangeMin;
        }

        if (response.value.max > rangeMax) {
            response.value.max = rangeMax;
        }

        if (response.value.min === rangeMin && response.value.max === rangeMax) {
            removeActiveFilter(response);
        } else {
            dispatch(setFilter(response, reservationTypeId));
        }
    };

    const onRangeFilterInput = (response: TRangeFilterInputResponse) => {
        const min = response.value.min;
        const max = response.value.max;

        setRangeFiltersInput(prevState => {
            if (prevState[response.key]?.min === min && prevState[response.key]?.max === max) {
                return prevState;
            }

            return { ...prevState, [response.key]: { min, max } };
        });
    };

    const onEnumFilterChange = (response: TFilterChangeResponse) => {
        const critCode = response.key;
        const enumCode = response.value;

        if (activeFilters && critCode in activeFilters && activeFilters[critCode].includes(enumCode)) {
            removeActiveFilter(response);
        } else {
            dispatch(setFilter(response, reservationTypeId));
        }
    };

    const handleSearchFilterChange = (value: string) => {
        onFilterChange({
            type: "text",
            key: "search",
            value,
        });
    };

    const getMinMaxForRangeFilter = (criteria: TCriteriaItem): { min: number; max: number } | undefined => {
        const matchingCriteriaValues = unfilteredSearchResultCriterias
            .filter((item: any) => item[criteria.code] !== undefined)
            .map((item: any) => item[criteria.code]);

        if (!matchingCriteriaValues.length) {
            return undefined;
        }

        return { min: Math.min(...matchingCriteriaValues), max: Math.max(...matchingCriteriaValues) };
    };

    const countEnumMatches = (criteria: TCriteriaItem, enumItem: TCriteriaEnum) => {
        const extractedCriterias =
            filteredSearchResultCriteriasByExcludedEnum[criteria.code] || filteredSearchResultCriterias;

        return extractedCriterias.filter((item: any) => item[criteria.code] === enumItem.code).length;
    };

    const countBoolMatches = (criteria: TCriteriaItem) => {
        return filteredSearchResultCriterias.filter((item: any) => item[criteria.code] === true).length;
    };

    const clearAccommodationFilters = () => {
        dispatch(resetFilterState());
        setRangeFiltersInput({});
    };

    const renderRangeFilter = (criteria: TCriteriaItem) => {
        if (criteria.type === "MAX" || criteria.type === "MIN") {
            const minMaxForRangeFilter = getMinMaxForRangeFilter(criteria);
            const disabled = !!(!minMaxForRangeFilter || minMaxForRangeFilter.min === minMaxForRangeFilter.max);

            const min = minMaxForRangeFilter?.min ?? 0;
            const max = minMaxForRangeFilter?.max ?? 1;

            const value = { min, max };

            // If it exists, set value to the current input value or the active value
            if (rangeFiltersInput && criteria.code in rangeFiltersInput) {
                value.min = rangeFiltersInput[criteria.code].min;
                value.max = rangeFiltersInput[criteria.code].max;
            } else if (activeFilters && criteria.code in activeFilters) {
                value.min = activeFilters[criteria.code].min;
                value.max = activeFilters[criteria.code].max;
            }

            const filterHeadingClassName = classNames(
                "accommodation-filter-slider__heading",
                "accommodation-filter-slider__heading-hide"
            );

            // If disabled, set a range between 0 - 1 to trigger the entire track is selected.

            const key = `${criteria.code}_${min}_${max}`;

            return (
                <section key={key} className="accommodation-filter-slider">
                    <div className="accommodation-filter-slider__header">
                        <Heading type="h2" styleAs="h5" className={filterHeadingClassName}>
                            {capitalize(criteria.title.toLocaleLowerCase())}
                        </Heading>
                        {minMaxForRangeFilter && !(min === 0 && max === 0) && (
                            <span
                                className={disabled ? "accommodation-filter-slider__label--disabled" : ""}
                            >{`${value.min} - ${value.max} ${criteria.typeunit}`}</span>
                        )}
                    </div>
                    <DistanceRangeFilter
                        key={key}
                        name={`${criteria.code}`}
                        label={capitalize(criteria.title.toLocaleLowerCase())}
                        min={disabled ? 0 : min}
                        max={disabled ? 1 : max}
                        value={disabled ? { min: 0, max: 1 } : value}
                        unit={criteria.typeunit}
                        hideLabel
                        onChange={value => {
                            onRangeFilterChange({ ...value, type: criteria.type, details: criteria }, min, max);
                        }}
                        onInput={onRangeFilterInput}
                        disabled={disabled}
                    />
                </section>
            );
        } else {
            console.info(
                "A range filter has an unsupported type, need to be either MIN or MAX",
                criteria.code,
                criteria.type
            );
        }
    };

    const renderEnumFilter = (criteria: TCriteriaItem) => {
        return (
            <section key={criteria.code} className="accommodation-filter-checkbox-group">
                <Heading type="h2" className="accommodation-filter-checkbox-group-heading">
                    {capitalize(criteria.title.toLocaleLowerCase())}
                </Heading>
                <hr className="accommodation-filter-checkbox-group-divider" />
                <div className="accommodation-filter-checkbox-group__checkboxes">
                    {criteria.enums.map(enumItem => {
                        const checked = !!(
                            activeFilters &&
                            criteria.code in activeFilters &&
                            activeFilters[criteria.code].includes(enumItem.code)
                        );

                        const enumMatches = countEnumMatches(criteria, enumItem);

                        return (
                            <Checkbox
                                label={`${enumItem.title} (${enumMatches})`}
                                key={enumItem.code}
                                checked={checked}
                                onChange={() =>
                                    onEnumFilterChange({
                                        key: criteria.code.toString(),
                                        value: enumItem.code,
                                        type: criteria.type,
                                        details: criteria,
                                    })
                                }
                            />
                        );
                    })}
                </div>
            </section>
        );
    };

    const renderBoolFilter = (criteria: TCriteriaItem) => {
        const checked = !!activeFilters?.[criteria.code];
        const boolMatches = countBoolMatches(criteria);

        return (
            <Checkbox
                key={criteria.code}
                label={`${capitalizeFirstLetter(criteria.title?.toLowerCase())} (${boolMatches})`}
                checked={checked}
                onChange={() => {
                    onFilterChange({
                        key: criteria.code.toString(),
                        value: !checked,
                        type: criteria.type,
                        details: criteria,
                    });
                }}
            />
        );
    };

    const renderFilters = (filters: string[], columns: boolean) => {
        return (
            <>
                {Object.entries(presentationTypes)
                    .filter(([presentationType]) => filters.includes(presentationType))
                    .map(([presentationType, settings]) =>
                        renderForPresentationType(presentationType, settings.heading ?? "", columns)
                    )}
            </>
        );
    };

    const renderForPresentationType = (presentationType: string, heading: string, column: boolean) => {
        const presentationTypeCriterias = reservationTypeCriterias[presentationType] || [];

        if (!presentationTypeCriterias.length) {
            return;
        }

        return (
            <section className={classNames("col-12", { "col-md": column })} key={presentationType}>
                <div>
                    {heading && (
                        <>
                            <Heading type="h2" className="accommodation-filter-checkbox-group-heading">
                                {heading}
                            </Heading>
                            <hr className="accommodation-filter-checkbox-group-divider" />
                        </>
                    )}
                    <div className="accommodation-filter-checkbox-group__checkboxes">
                        {presentationTypeCriterias.map(renderFilter)}
                    </div>
                </div>
            </section>
        );
    };

    const renderFilter = (criteria: TCriteriaItem) => {
        switch (criteria.type) {
            case "MIN":
            case "MAX":
                return renderRangeFilter(criteria);
            case "ENUM":
                return renderEnumFilter(criteria);
            case "BOOL":
                return renderBoolFilter(criteria);
        }
    };

    if (isLoading) {
        return (
            <SkeletonGroup>
                <div className="u-bg-white u-p-24">
                    <div className="row">
                        <div className={classNames("col-12 u-mb-30", { "col-md-4": columns })}>
                            <Skeleton height="50px" className="u-mb-18" />
                            {[...Array(5)].map((_item, index) => (
                                <Skeleton key={index} className="u-mb-18" />
                            ))}
                        </div>
                        {[...Array(2)].map((_item, index) => {
                            return (
                                <div key={index} className={classNames("col-12 u-mb-30", { "col-md": columns })}>
                                    <Skeleton className="u-mb-18" width="75%" />
                                    {[...Array(7)].map((_item, index) => (
                                        <div key={index} className="u-d-flex u-gap-6">
                                            <Skeleton width="25px" height="25px" className="u-mb-18" />
                                            <Skeleton width="50%" className="u-mb-18" />
                                        </div>
                                    ))}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </SkeletonGroup>
        );
    }

    return (
        <section className={classNames("accommodation-filter", { "accommodation-filter--show-mobile": showOnMobile })}>
            <div className="row">
                <div className={classNames("col-12", { "col-md-4": columns })}>
                    <>
                        {!isDesktopNewDesign ? (
                            <>
                                <div className="accommodation-filter__mobile-header">
                                    <button onClick={onMobileClose} className="accommodation-filter__close-btn">
                                        <CloseIcon size={36} />
                                    </button>
                                    <Heading type="h2" className="accommodation-filter__heading">
                                        {t("book.general.filter")}
                                    </Heading>
                                    <Button
                                        type="tertiary"
                                        leftIcon={<TrashIcon />}
                                        onClick={() => clearAccommodationFilters()}
                                    >
                                        {t("book.general.clear_filter")}
                                    </Button>
                                </div>
                            </>
                        ) : (
                            <div className="accommodation-filter__desktop-header">
                                <Heading type="h2" styleAs="h5" className="accommodation-filter__heading">
                                    {t("book.general.filter")}
                                </Heading>
                                <Button
                                    type="tertiary"
                                    leftIcon={<TrashIcon />}
                                    onClick={() => clearAccommodationFilters()}
                                >
                                    {t("book.general.clear_filter")}
                                </Button>
                            </div>
                        )}
                        {showFilterSearch && (
                            <div className="accommodation-filter__search">
                                <Input
                                    label={t("book.accomodation.search_name")}
                                    placeholder={t("book.accomodation.search_name_placeholder")}
                                    onChange={e => handleSearchFilterChange(e.target.value)}
                                    value={activeFilters.search || ""}
                                />
                            </div>
                        )}
                        {renderFilters(["interval_filters"], false)}
                    </>
                </div>
                {renderFilters(["accommodation_type_filters", "enum_filters", "amenities_filters"], columns)}
            </div>
        </section>
    );
};
