import { isNaN } from "lodash";
import * as types from "../actions/types";

const initialState = {
    guests: {},
    error: null,
};

const reducer = (state = initialState, action) => {
    const guests = { ...state.guests };
    switch (action.type) {
        case types.SIGN_OUT_USER:
        case types.RESET_GUESTS:
            return initialState;

        case types.SET_GUEST:
            return {
                ...state,
                guests: {
                    ...state.guests,
                    [action.guest.id]: action.guest,
                },
            };

        case types.UPDATE_GUEST_DATA:
            return {
                ...state,
                guests: {
                    ...state.guests,
                    [action.guestId]: {
                        ...state.guests[action.guestId],
                        [action.key]: action.value,
                    },
                },
            };

        case types.SET_GUESTS:
            return {
                ...state,
                guests: action.guests,
            };

        case types.APPEND_GUESTS: {
            const skipDuplicates = action.skipDuplicates;
            const currentGuestIds = state.guests ? Object.values(state.guests).map(guest => guest.id) : [];
            const guestsToAppend = skipDuplicates
                ? Object.entries(action.guests).reduce((acc, [guestId, guest]) => {
                      if (!currentGuestIds.includes(guest.id)) {
                          acc[guestId] = guest;
                      }

                      return acc;
                  }, {})
                : action.guests;

            return {
                ...state,
                guests: { ...state.guests, ...guestsToAppend },
            };
        }

        case types.CREATE_GUESTS_FAILED:
            return {
                ...state,
                error: action.error,
            };

        case types.CREATE_GUEST_FAILED:
            return {
                ...state,
                error: action.error,
            };

        case types.FETCH_GUESTS_FAILED:
            return {
                ...state,
                error: action.error,
            };

        case types.UPDATE_GUEST:
            return {
                ...state,
                guests: {
                    ...state.guests,
                    [action.guest.id]: {
                        ...state.guests[action.guest.id],
                        ...action.guest,
                    },
                },
            };

        case types.UPDATE_GUEST_FAILED:
            return {
                ...state,
                error: action.error,
            };

        case types.ALL_GUESTS_UPDATED_SUCCESSFULLY:
            return {
                ...state,
                allGuestsUpdatedSuccessfully: action.updatedAllGuests,
            };

        case types.DELETE_GUEST: {
            const updatedGuests = Object.keys(guests).reduce((acc, key) => {
                if (parseInt(key) !== action.guestId) {
                    return { ...acc, [key]: state.guests[key] };
                }
                return acc;
            }, {});

            return {
                ...state,
                guests: {
                    ...updatedGuests,
                },
            };
        }

        case types.DELETE_GUEST_FROM_CART_FAILED:
            return {
                ...state,
                error: action.error,
            };

        case types.CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };

        // Update the primary guest when the authenticated user is updated.
        case types.SET_AUTHENTICATED_USER_DATA: {
            const primaryGuest = Object.values(state.guests).find(guest => guest.guestlid === action.user.addrlid);

            if (!primaryGuest) {
                return state;
            }

            return {
                ...state,
                guests: {
                    ...state.guests,
                    [primaryGuest.guestlid]: {
                        ...primaryGuest,
                        ...action.user,
                    },
                },
            };
        }

        default:
            return {
                ...state,
            };
    }
};

export default reducer;
