import React, { CSSProperties, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import useTranslate from "../hooks/useTranslate";
import PageContainer from "../components/PageContainer";
import PackingImage from "../assets/Packing.png";
import { Button, Heading } from "@r360/library";
import useAppDispatch from "../hooks/useAppDispatch";
import * as types from "../store/actions/types";

const CheckoutConfirmationPage = () => {
    const { resvid } = useParams();
    const dispatch = useAppDispatch();

    const t = useTranslate();
    const [searchParams] = useSearchParams();

    const paymentWasInvoice = searchParams.get("invoice") === "true";

    useEffect(() => {
        dispatch({ type: types.DESTORY_STATE });
    }, [dispatch]);

    const imgStyle: CSSProperties = {
        width: "90%",
        maxWidth: 600,
    };

    return (
        <PageContainer>
            <div className="u-d-flex u-flex-column u-align-items-center u-pt-72">
                <img style={imgStyle} className="u-mb-36" src={PackingImage} />
                <Heading type="h2" styleAs="h3" className="u-mb-18 u-text-center">
                    <span className="u-color-success-dark">{t("book.pay.confirmation.heading", [resvid ?? ""])}</span>
                    {` ${t("book.pay.confirmation.sub_heading")}`}
                </Heading>
                <p>
                    {paymentWasInvoice && `${t("book.pay.confirmation.invoice_will_be_sent")} `}
                    {`${t("book.pay.confirmation.view_booking")} `}
                    <Button type="tertiary" link linkTo={`/my-pages/bookings/${resvid}`}>
                        {t("book.my_pages")}
                    </Button>
                    .
                </p>
            </div>
        </PageContainer>
    );
};

export default CheckoutConfirmationPage;
