import React, { useState, useRef, useEffect } from "react";
import { RichText } from "..";

/**
 * ReadMoreText uses -webkit-line-clamp to only display a specific number of rows of content.
 */
const ReadMoreText = ({
    children,
    lines = 3,
    html = false,
    whiteSpacePreLine = false,
    anchorClass = "",
    moreText = "View more",
    lessText = "View less",
    hideAnchor = false,
}) => {
    const [expanded, setExpanded] = useState(false);
    const [showToggle, setShowToggle] = useState(false);
    const contentRef = useRef(null);

    useEffect(() => {
        if (!contentRef.current || expanded) {
            return;
        }

        const offsetHeight = contentRef.current.offsetHeight;
        const scrollHeight = contentRef.current.scrollHeight;

        if (offsetHeight < scrollHeight) {
            setShowToggle(true);
        } else {
            setShowToggle(false);
        }
    }, [contentRef.current?.scrollHeight, lines, expanded]);

    const styles = {
        ...(!expanded && {
            display: "-webkit-box",
            WebkitLineClamp: lines,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
        }),
        ...(whiteSpacePreLine && { whiteSpace: "pre-line" }),
    };

    return (
        <div>
            <div ref={contentRef} style={styles}>
                {html ? <RichText content={children} /> : children}
            </div>
            {!hideAnchor && showToggle && (
                <a className={anchorClass} onClick={() => setExpanded(!expanded)}>
                    {expanded ? lessText : moreText}
                </a>
            )}
        </div>
    );
};
export default ReadMoreText;
