import React, { useContext, useState } from "react";
import { Accordion, useAccordionButton } from "react-bootstrap";
import AccordionContext from "react-bootstrap/AccordionContext";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReservationTypeDateFilter from "../components/ReservationTypeDateFilter";
import { Button, Checkbox, Icon, Incrementer } from "../components/UI";
import "../components/UI/Filters/Filters.scss";
import { getDateObjectForRequests } from "../hooks";
import useQueryString from "../hooks/QueryString/useQueryString";
import useChangeSearch from "../hooks/useChangeSearch";
import { optionsSelector } from "../selectors/Selectors";
import { setFilter } from "../store/actions/filter";
import moment from "moment";
import { textsSelector } from "../Selectors";

/* This solution is a band-aid and should NOT be copied/re-used. All modules/views should render a single <Filter ...props />
    and passing in relevant props to tailor the behaviour of a single calendar/agepicker/filters buttons based on reservationType */
const BikeFilterPage = ({ reservationType }) => {
    const dispatch = useDispatch();
    const filters = useSelector(state => state.filter);
    const texts = useSelector(textsSelector);
    const resvTypeCrits = filters.filterCriterias?.[reservationType] || [];
    const allFilters = useSelector(state => state.availableFilters.default);
    const isMobile = useSelector(state => state.window.isMobile);
    const reservationTypeResult = useSelector(state => state.reservationResult);
    const rentals = reservationTypeResult[reservationType];
    const [startValue, onSetStartDate] = useQueryString("startDate") || "";
    const [endValue, onSetEndDate] = useQueryString("endDate") || "";
    const navigate = useNavigate();

    useChangeSearch({
        reservationResultSearch: rentals?.search,
        reservationTypeId: reservationType,
        skipGuestsOnSearchWithPrice: true,
        allowSearchWithoutPrice: false,
    });

    const dates = getDateObjectForRequests({ startDate: startValue, endDate: endValue }, rentals?.search, true);

    const [startDate, setStartDate] = useState(moment(dates.startDate));
    const [endDate, setEndDate] = useState(moment(dates.endDate));
    const [singleDate, setSingleDate] = useState(startDate.isValid() && endDate.isValid() && startDate.isSame(endDate));

    const [activeFilters, setActiveFilters] = useState(filters[reservationType] || null);
    const newDesignMenu = useSelector(optionsSelector).layout?.new_design_menu;

    if (!filters[reservationType]) {
        filters[reservationType] = {};
    }

    const renderDatePicker = () => {
        return (
            <ReservationTypeDateFilter
                texts={texts}
                isMobile={isMobile}
                reservationTypeid={reservationType}
                promoCode={filters.promoCode}
                initialStartDate={startDate}
                initialEndDate={endDate}
                isSingleDate={singleDate}
                onSetSingledate={single => {
                    setSingleDate(single);
                }}
                onSetStartDate={date => {
                    setStartDate(moment(date));
                    onSetStartDate(date);
                }}
                onSetEndDate={date => {
                    setEndDate(moment(date));
                    onSetEndDate(date);
                }}
                onDatesChange={() => {
                    // The navigation needs to be updated in a Reactive way instead of natively as
                    // setStartDate and setEndDate does. Otherwise the useChangeSearch hook won't trigger.
                    // Remove everything before and including /client from beginning of pathname
                    // to access the root path of the React application when navigating.
                    const pathname = window.location.pathname.replace(/^.*\/client/i, "");
                    const search = window.location.search;
                    navigate(`${pathname}${search}`, { replace: true });
                }}
            />
        );
    };

    // Remove filter
    const removeFilter = filter => {
        const value = typeof filter[1] === "boolean" ? false : 0;
        const response = { key: filter[0], value: value };
        setActiveFilters({
            ...activeFilters,
            [response.key]: response.value,
        });
        dispatch(setFilter(response, reservationType));
    };

    // Render filter
    const renderFilter = (filter, label) => {
        // Set lokal state and update redux on filter change
        const onFilterChange = response => {
            dispatch(setFilter(response, reservationType));
        };
        const onEnumFilterChange = response => {
            if (filters[reservationType][response.key] === response.value) {
                removeFilter([response.key, response.value]);
            } else {
                dispatch(setFilter(response, reservationType));
            }
        };
        switch (filter.type) {
            case "BOOL": {
                return (
                    <Checkbox
                        label={label}
                        name={filter.code}
                        checked={
                            filters[reservationType] && filters[reservationType][filter.code]
                                ? filters[reservationType][filter.code]
                                : false
                        }
                        onChange={onFilterChange}
                    />
                );
            }
            case "MIN": {
                return (
                    <div className="filter__group">
                        <div className="filter__group-label">{label}</div>
                        <div className="filter__group-input">
                            <Incrementer
                                label={label}
                                name={filter.code}
                                value={
                                    filters[reservationType] && filters[reservationType][filter.code]
                                        ? filters[reservationType][filter.code]
                                        : 0
                                }
                                onChange={onFilterChange}
                            />
                        </div>
                    </div>
                );
            }
            case "ENUM": {
                return (
                    <div className="filter__enum">
                        <div className="filter__enum-label">{label}</div>
                        {Object.entries(filter.enums).map((x, i) => {
                            return (
                                <div key={i} className="checkbox">
                                    <label htmlFor={x[1].lid} className="checkbox__label">
                                        <span className="checkbox__label-text">{x[1].value}</span>
                                        <input
                                            type="checkbox"
                                            id={x[1].lid}
                                            name={x[1].lid}
                                            className="checkbox__input"
                                            checked={
                                                filters[reservationType] && filters[reservationType][x[1].lid]
                                                    ? filters[reservationType][x[1].lid]
                                                    : false
                                            }
                                            onChange={() =>
                                                onEnumFilterChange({
                                                    key: x[1].lid,
                                                    value: x[1].value,
                                                })
                                            }
                                        />
                                        <span className="checkbox__checkmark" aria-hidden="true"></span>
                                    </label>
                                </div>
                            );
                        })}
                    </div>
                );
            }
            default:
                break;
        }
    };

    // Toggle button for more filters accordion
    const MoreFiltersToggle = ({ eventKey, callback }) => {
        const currentEventKey = useContext(AccordionContext);
        const isCurrentEventKey = currentEventKey === eventKey;
        const onClick = useAccordionButton(eventKey, () => callback && callback(eventKey));
        return (
            <Button
                text={isCurrentEventKey ? `${texts?.filterclose}` : `${texts?.filtermore}`}
                className="filter__more-filters-toggle"
                onClick={onClick}
            />
        );
    };

    // Render
    return (
        <div className="filter__wrapper">
            <Accordion>
                <div>
                    <header>
                        <div className="filter">
                            <div className="filter__list">
                                {!newDesignMenu && <div className="filter__item">{renderDatePicker()}</div>}
                                {filters?.filterCriterias?.[reservationType] && (
                                    <div className="filter__item filter__more-filters">
                                        <MoreFiltersToggle eventKey="0" />
                                    </div>
                                )}
                            </div>
                        </div>
                    </header>
                </div>
                <Accordion.Collapse eventKey="0" className="filter__more-filters-wrapper">
                    <div className="filter__more-filters-container container">
                        <div className="row">
                            {Object.entries(resvTypeCrits).map((filter, index) => {
                                if (!allFilters?.[filter[0]]) {
                                    return null;
                                }
                                const label = allFilters?.[filter[0]].title;
                                return (
                                    <div
                                        className="col-lg-3 col-md-6 col-xs-12 filter__more-filters-item"
                                        key={"reservation-filter" + index}
                                    >
                                        {renderFilter(filter[1], label)}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </Accordion.Collapse>
                {filters[reservationType] && Object.keys(filters[reservationType]).length !== 0 && (
                    <div className="filter__active-filters-container container">
                        <div className="filter__active-filters-label">Valda filter:</div>
                        {Object.entries(filters[reservationType]).map((item, index) => {
                            const value = typeof item[1] === "boolean" ? "" : ` ${item[1]}`;
                            return (
                                <div className="filter__active-filters-item" key={index}>
                                    {allFilters[item[0]] && allFilters[item[0]].title}
                                    {value}
                                    <Button onClick={() => removeFilter(item)}>
                                        <span className="sr-only">
                                            Ta bort filtrering för {allFilters[item[0]] && allFilters[item[0]].title}
                                        </span>
                                        <Icon name="FaTimes" size={10} color="#FFF" />
                                    </Button>
                                </div>
                            );
                        })}
                    </div>
                )}
            </Accordion>
        </div>
    );
};

export default BikeFilterPage;
