import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from "react";
import "./WtpVideoStream.scss";
import { Notification, Skeleton } from "@r360/library";
import classNames from "classnames";
import useTranslate from "../../hooks/useTranslate";

type TWtpVideoStream = {
    imageHandler: (arg0: string) => Promise<string | null | undefined>;
    onCardNumberMatch: (arg0: string) => void;
    // stopCamera: () => void;
};

type TWtpVideoStreamHandle = {
    stopVideo: () => void;
};

export const WtpVideoStream = forwardRef<TWtpVideoStreamHandle, TWtpVideoStream>(
    ({ imageHandler, onCardNumberMatch }: TWtpVideoStream, ref) => {
        const t = useTranslate();
        const videoRef = useRef<HTMLVideoElement>(null);
        const [isVideoRunning, setIsVideoRunning] = useState(false);

        useImperativeHandle(ref, () => ({
            stopVideo: () => stopVideo(),
        }));

        useEffect(() => {
            const videoElement = videoRef.current;
            startVideo();

            if (videoElement) {
                videoElement.addEventListener("loadedmetadata", handleLoadMetaData);
            }

            return () => {
                stopVideo();
                if (videoElement) {
                    videoElement.removeEventListener("loadedmetadata", handleLoadMetaData);
                    videoElement.srcObject = null;
                }
            };
            //eslint-disable-next-line
        }, []);

        const startVideo = async () => {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({
                    video: {
                        facingMode: "environment",
                    },
                });

                setIsVideoRunning(true);

                if (videoRef.current) {
                    videoRef.current.srcObject = null;
                    videoRef.current.srcObject = stream;

                    await new Promise(resolve => {
                        videoRef.current?.addEventListener("loadedmetadata", resolve, {
                            once: true,
                        });
                    });

                    await videoRef.current.play();
                }
            } catch (error) {
                console.error("Error accessing the camera:", error);
            }
        };

        const stopVideo = () => {
            if (videoRef.current && videoRef.current.srcObject instanceof MediaStream) {
                const tracks = videoRef.current.srcObject.getTracks();
                tracks.forEach(track => track.stop());
                setIsVideoRunning(false);
            }
        };

        const handleLoadMetaData = () => {
            captureImage();
        };

        // After Image is captured, send to Tesseract OCR
        const captureImage = async () => {
            try {
                if (!videoRef.current) {
                    console.log("Video element is not available");
                    return;
                }

                const videoWidth = videoRef.current.videoWidth;
                const videoHeight = videoRef.current.videoHeight;

                // Create an offscreen canvas to hold the captured image
                const canvas = document.createElement("canvas");
                const context = canvas.getContext("2d");

                if (!context) return;
                context.imageSmoothingEnabled = false;

                canvas.width = videoWidth;
                canvas.height = videoHeight;

                // Capture the entire video frame
                context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

                // Calculate the dimensions for the three sections
                const sectionWidth = videoWidth;
                const topSectionHeight = videoHeight / 3;
                const middleSectionHeight = (videoHeight - topSectionHeight) / 2;

                // Create two offscreen canvases for the original
                const originalCanvas = document.createElement("canvas");
                const originalContext = originalCanvas.getContext("2d");
                originalCanvas.width = videoWidth;
                originalCanvas.height = videoHeight;

                // Draw the original image onto the original canvas
                if (!originalContext) return;
                originalContext.drawImage(canvas, 0, 0, videoWidth, videoHeight);

                canvas.width = videoWidth;
                canvas.height = middleSectionHeight;

                context.drawImage(
                    originalCanvas,
                    0,
                    topSectionHeight,
                    sectionWidth,
                    middleSectionHeight,
                    0,
                    0,
                    sectionWidth,
                    middleSectionHeight
                );

                // Convert the final canvas to a data URL
                const image = canvas.toDataURL("image/jpeg");

                if (image.length < 10) {
                    console.log(" IMAGE IS BROKEN ", image);
                    return;
                }

                const idNum = await imageHandler(image);

                if (idNum) {
                    stopVideo();
                    onCardNumberMatch(idNum);
                    return;
                }

                captureImage();
            } catch (error) {
                console.error("Error capturing image:", error);
            }
        };

        return (
            <>
                {!isVideoRunning && (
                    <>
                        <div className="u-mb-18">
                            <Notification type="warning">
                                <p>{t("book.wtp_scanner.searching_for_camera")}</p>
                                <p className="u-mb-0">{t("book.wtp-scanner.allow_camera")}</p>
                            </Notification>
                        </div>
                        <Skeleton height="576px" width="100%" />
                    </>
                )}
                <div
                    className={classNames("wtp-scanner__video-container", {
                        "wtp-scanner__video-container--hidden": !isVideoRunning,
                    })}
                >
                    <video ref={videoRef} autoPlay playsInline muted width="100%" height="100%"></video>
                    <div className="wtp-scanner__overlay"></div>
                </div>
            </>
        );
    }
);

WtpVideoStream.displayName = "WtpVideoStream";
