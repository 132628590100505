import React from "react";
import { useParams } from "react-router-dom";
import ResetPassword from "../../components/ResetPassword/OldResetPassword/OldResetPassword";

const ResetPasswordPage = () => {
    const { token } = useParams();

    return (
        <div className="container">
            <ResetPassword token={token} />
        </div>
    );
};

export default ResetPasswordPage;
