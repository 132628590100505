import classnames from "classnames";
import React, { createRef, useEffect, useMemo, useRef, useState } from "react";
import { Accordion, Button, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomToggle from "../../components/FillOutComponents/CustomToggle/CustomToggle";
import PersonalInformation from "../../components/PersonalInformation/OldPersonalInformation/PersonalInformation";
import { Button as UIButton, Icon, InformationBlock } from "../../components/UI";
import confirmationDialog from "../../components/UI/ConfirmationDialog";
import { getGuestNameAndAge } from "../../Helper";
// import { setGuests, updateGuests } from "../../store/actions/guests";
import {
    createUserGuest,
    disconnectGuestFromUser,
    fetchGuestsOnUser,
    updateUserGuest,
} from "../../store/actions/guests";
import { textsSelector } from "../../Selectors";

const MyGuests = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const texts = useSelector(textsSelector);
    const isMobile = useSelector(state => state.window.isMobile);
    const userToken = useSelector(state => state.account.token);
    const guests = useSelector(state => state.guests.guests);
    const cartIsEmpty = useSelector(state => !state.checkoutCart?.products.length);
    const [isAddNewGuestVisible, setIsAddNewGuestVisible] = useState(false);
    const newGuestRef = useRef(null);
    const customerOptions = useSelector(state => state.clientData.options.customer);

    useEffect(() => {
        if (Object.keys(guests).length === 0 && userToken) {
            dispatch(fetchGuestsOnUser(userToken));
        }

        // eslint-disable-next-line
    }, [dispatch, userToken]);

    const handleSubmit = (guestId, guestValues) => {
        guestRefs[guestId].accordion.current.toggle();
        dispatch(updateUserGuest(userToken, guestValues));
        guestRefs[guestId].form.current.resetTouched();
    };

    const handleNewGuestSubmit = guestValues => {
        setIsAddNewGuestVisible(false);
        dispatch(createUserGuest(userToken, guestValues));
        newGuestRef.current.resetForm();
    };

    // Create a reference for each guest that will be used to connect to their forms.
    const guestRefs = useMemo(() => {
        return Object.entries(guests)
            .filter(([, guest]) => !guest.primary)
            .reduce((acc, [guestId]) => {
                acc[guestId] = {
                    form: createRef(),
                    accordion: createRef(),
                };

                return acc;
            }, {});
    }, [guests]);

    /**
     * Open a confirmation dialog and if the user confirms, remove the guest.
     * In R360, the guest will still exist in the system, but is disconnected from the user.
     *
     * @param {number} guestId
     */
    const openDeleteGuestConfirmation = async guestId => {
        const guest = guests[guestId];

        if (!guest) {
            return;
        }

        const guestNameAndAge = getGuestNameAndAge(guest, texts?.generalyearshort);
        const response = await confirmationDialog({
            title: texts["mypages.guest_removal_confirmation.title"],
            description: texts["mypages.guest_removal_confirmation.description"] + "\n\n" + guestNameAndAge,
            okLabel: texts["general.confirmation.continue"],
            cancelLabel: texts["general.confirmation.cancel"],
        });

        if (response) {
            dispatch(disconnectGuestFromUser(guestId, userToken));
        }
    };

    return (
        <Container>
            <h1 className="page-header">{texts?.myguests}</h1>

            <InformationBlock className="mb-5">
                {cartIsEmpty ? texts["mypages.changeexistingbooking"] : texts["mypages.ongoingbookingguests"]}
            </InformationBlock>

            <div className={classnames({ "soft-disabled": !cartIsEmpty })}>
                {Object.entries(guests)
                    .filter(([, guest]) => !guest.primary)
                    .map(([guestId, guest], index) => (
                        <Row key={guest.id} className="mb-3">
                            <Col md={10}>
                                <Accordion>
                                    <CustomToggle
                                        isMobile={isMobile}
                                        title={
                                            guest.firstname
                                                ? getGuestNameAndAge(guest, texts?.generalyearshort)
                                                : texts?.noname
                                        }
                                        eventKey={index.toString()}
                                        className="custom-toggle--small custom-toggle--toggle-icon-right m-0"
                                        ref={guestRefs[guestId].accordion}
                                    />
                                    <Accordion.Collapse eventKey={index.toString()}>
                                        <Row>
                                            <Col>
                                                <PersonalInformation
                                                    ref={guestRefs[guestId].form}
                                                    guestId={guestId}
                                                    guest={guest}
                                                    onSubmit={values => {
                                                        handleSubmit(guestId, values);
                                                    }}
                                                    texts={texts}
                                                    showKeycardNo={customerOptions?.show_keycard_number || false}
                                                    showKeycardImage={customerOptions?.show_keycard_image || false}
                                                    showWeight={customerOptions?.show_weight || false}
                                                    showHeight={customerOptions?.show_height || false}
                                                    showShoesize={customerOptions?.show_shoesize || false}
                                                    showHeadsize={customerOptions?.show_headsize || false}
                                                />

                                                <Row className="mb-3">
                                                    <Col className="text-end">
                                                        <Button
                                                            onClick={() => openDeleteGuestConfirmation(guestId)}
                                                            variant="link"
                                                            size="md"
                                                            className="fw-light text-primary p-0"
                                                        >
                                                            {texts["mypages.removeguest"]}
                                                        </Button>
                                                        <UIButton
                                                            className="button ms-4"
                                                            onClick={() => guestRefs[guestId].form.current.submitForm()}
                                                        >
                                                            {texts?.save}
                                                        </UIButton>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Accordion.Collapse>
                                </Accordion>
                            </Col>
                        </Row>
                    ))}

                <Button
                    disabled={isAddNewGuestVisible}
                    onClick={() => setIsAddNewGuestVisible(true)}
                    variant="link"
                    className="fw-light text-primary p-0 mb-3"
                >
                    {texts?.checkoutaddguest}
                </Button>
            </div>

            <Accordion.Collapse in={isAddNewGuestVisible}>
                <Row>
                    <Col md={10}>
                        <p>{texts["checkout.guests.new_guest.title"]}</p>
                        <PersonalInformation
                            ref={newGuestRef}
                            onSubmit={handleNewGuestSubmit}
                            texts={texts}
                            showKeycardNo={customerOptions?.show_keycard_number || false}
                            showKeycardImage={customerOptions?.show_keycard_image || false}
                            showWeight={customerOptions?.show_weight || false}
                            showHeight={customerOptions?.show_height || false}
                            showShoesize={customerOptions?.show_shoesize || false}
                            showHeadsize={customerOptions?.show_headsize || false}
                        />
                        <Row>
                            <Col className="text-end">
                                <Button
                                    onClick={() => {
                                        newGuestRef.current.resetForm();
                                        setIsAddNewGuestVisible(false);
                                    }}
                                    variant="link"
                                    className="fw-light text-primary"
                                >
                                    {texts["customer_form.cancel"]}
                                </Button>
                                <UIButton className="button ms-4" onClick={() => newGuestRef.current.submitForm()}>
                                    {texts["customer_form.add"]}
                                </UIButton>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Accordion.Collapse>

            <Row className="mt-5 mb-5">
                <Col>
                    <UIButton
                        onClick={() => {
                            navigate(-1);
                        }}
                        className="button--invert me-2"
                        type="button"
                    >
                        <Icon name="FaArrowLeft" size={16} color="#0d3e60" className="booking-view__back-button-icon" />
                        {texts?.goback}
                    </UIButton>
                </Col>
            </Row>
        </Container>
    );
};

export default MyGuests;
