import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isBooked } from "../../BusinessUtils";
import {
    formatPrice,
    hasAnyBookedAccommodation,
    hasAnyBookedActivitiesWithDynamicPrice,
    hasAnyBookedPackage,
} from "../../Helper";
import { optionsSelector } from "../../selectors/Selectors";
import { cancelReservation as cancelReservationAction } from "../../store/actions/account";
import { createCartFromReservation, emptyCartProducts } from "../../store/actions/checkoutCart";
import * as types from "../../store/actions/types";
import AccommodationList from "../AccommodationList/AccommodationList";
import ActivityWithDynamicPriceList from "../ActivityWithDynamicPriceList/ActivityWithDynamicPriceList";
import AdditionalProductsLinks from "../AdditionalProductsLinks/AdditionalProductsLinks";
import CancelledProductList from "../CancelledProductList/CancelledProductList";
import ContactInformation from "../ContactInformation/ContactInformation";
import GuestListView from "../GuestListView/GuestListView";
import PackageList from "../PackageList/PackageList";
import { Alert, Button, alertDialog } from "../UI";
import confirmationDialog from "../UI/ConfirmationDialog";
import AxiosClient from "../../API/AxiosClient";
import "./BookingView.scss";
import OtherProductList from "../OtherProductList/OtherProductList";
import debounce from "lodash/debounce";
import { textsSelector } from "../../Selectors";

const tempRenderedProducts = [];

const BookingView = ({ booking }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const texts = useSelector(textsSelector);
    const userToken = useSelector(state => state.account.token);
    const loggedInUser = useSelector(state => state.account.user);
    const [selectedReservationType, setSelectedReservationType] = useState({});
    const [didClickAddAdditionalProducts, setDidClickAddAdditionalProducts] = useState(false);
    const clientData = useSelector(state => state.clientData);
    const currency = useSelector(state => state.clientData?.options?.general?.currency);
    const reservationTypes = useSelector(state => state.clientData.reservationtypes);
    const [uniqueProductTypes, setUniqueProductTypes] = useState([]);
    const checkoutSummaryError = useSelector(state => state.checkoutCart.error);
    const reservationError = useSelector(state => state.reservation.error);
    const createdFromReservation = useSelector(state => state.checkoutCart.createdFromReservation);
    const [depDateHasPassed, setDepDateHasPassed] = useState(true);
    const lang = useSelector(state => state.clientData?.lang);
    const getinvoice = useSelector(optionsSelector).mypage.getinvoice;
    const myPageOnly = !!useSelector(optionsSelector).general?.mypage_only;
    const [renderedProducts, setRenderedProducts] = useState(undefined);

    useEffect(() => {
        dispatch({ type: types.CLEAR_ERRORS });
    }, [dispatch]);

    useEffect(() => {
        setDepDateHasPassed(booking?.depdate ? moment().isAfter(booking.depdate, "day") : true);
    }, [booking?.depdate]);

    useEffect(() => {
        if (booking?.reservation?.products) {
            let uniqueProductTypes = [
                ...new Set(
                    Object.values(booking?.reservation?.products).map(product =>
                        reservationTypes.find(resvtype => resvtype.lid === product.type)
                    )
                ),
            ].filter(uniqueProductType => uniqueProductType); // Make sure the value is truthy, otherwise no match was found above.

            // Sort reservation types by their sort order, which is defined in r360
            uniqueProductTypes.sort(function (a, b) {
                return a.sortorder < b.sortorder;
            });

            setUniqueProductTypes(uniqueProductTypes);
        }
        // TODO: R360ONL-741 Fix dependencies for useEffect related to searches.
        // eslint-disable-next-line
    }, [booking?.reservation?.products]);

    useEffect(() => {
        if (didClickAddAdditionalProducts && createdFromReservation && selectedReservationType?.lid) {
            navigate(`/search/${selectedReservationType.lid}`);
        }

        // TODO: R360ONL-741 Fix dependencies for useEffect related to searches.
        // eslint-disable-next-line
    }, [createdFromReservation, didClickAddAdditionalProducts, selectedReservationType]);

    const debouncedSetRenderedProducts = useCallback(
        debounce(tempRenderedProducts => {
            setRenderedProducts([...tempRenderedProducts]);
        }, 100),
        [setRenderedProducts]
    );

    // const setRenderedProduct2 = useCallback(
    //     debounce(id => {
    //         console.log("renderedProducts in debounce", renderedProducts);
    //         console.log("set id in debounce", id);
    //         if (!renderedProducts.includes(id)) {
    //             console.log("set new state in debounce", [...renderedProducts, id]);
    //             setRenderedProducts([...renderedProducts, id]);
    //         }
    //     }, 100),
    //     [setRenderedProducts, renderedProducts]
    // );

    const setRenderedProduct = id => {
        if (!tempRenderedProducts.includes(id)) {
            tempRenderedProducts.push(id);
            debouncedSetRenderedProducts(tempRenderedProducts);
        }
    };

    // Cancel the reservation.
    const renderCancelReservationButton = () => {
        const resvid = booking?.reservation.resvid;
        const allowCancellation = booking?.allowcancellation;

        if (allowCancellation) {
            return (
                <Button
                    className="border-0 booking-view__button__buttonCancel"
                    variant="primary"
                    onClick={() => {
                        openCancelReservationConfirmation(resvid);
                    }}
                >
                    {texts?.generalcancel}
                </Button>
            );
        } else {
            return (
                <OverlayTrigger
                    placement="left"
                    overlay={<Tooltip id="tooltip-disabled">{texts["bookingview.cancellation_not_allowed"]}</Tooltip>}
                >
                    <span className="text-center">
                        <Button
                            disabled
                            style={{ pointerEvents: "none" }}
                            className="border-0 booking-view__button__buttonCancel-disabled"
                        >
                            {texts?.generalcancel}
                        </Button>
                    </span>
                </OverlayTrigger>
            );
        }
    };

    const changeReservation = () => {
        const resvid = booking?.reservation?.resvid;

        if (!resvid || booking.cancelled) {
            return;
        }

        dispatch(emptyCartProducts());
        dispatch(createCartFromReservation(resvid, userToken, reservationTypes, clientData?.cookie));
    };

    const cancelReservation = () => {
        const resvid = booking?.reservation?.resvid;

        if (!resvid) {
            return;
        }

        dispatch(emptyCartProducts());
        dispatch(cancelReservationAction(userToken, resvid));
        dispatch(createCartFromReservation(null, userToken, reservationTypes, clientData?.cookie));
    };

    // Reservation is paid or not paid button
    const renderReservationPaymentInformation = () => {
        const reservationOkButNotPayed =
            booking?.reservationrules?.reservationok && booking?.reservationrules.totamounttopay > 0;

        if (reservationOkButNotPayed) {
            return (
                <Button
                    className="button booking-view__button-pay-button"
                    to={`/pay/${booking.reservation?.resvid}/0/${booking?.reservation?.payerlid}`}
                >
                    {texts?.pay || "Betala"}
                </Button>
            );
        }
        return null;
    };

    const createAndDownloadBlobFile = (arrayBufferBody, filename) => {
        const blob = new Blob([arrayBufferBody]);
        const link = document.createElement("a");

        if (link.download !== undefined) {
            link.setAttribute("href", URL.createObjectURL(blob));
            link.setAttribute("download", filename);
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const fetchInvoice = resvid => {
        const config = {
            headers: {
                Authorization: `Bearer ${userToken}`,
            },
            responseType: "arraybuffer",
        };

        const url = `/reservations/invoice/${resvid}`;

        AxiosClient.get(url, config)
            .then(response => {
                if (response.data instanceof ArrayBuffer) {
                    createAndDownloadBlobFile(response.data, `${resvid}.pdf`);
                }
            })
            .catch(e => {
                console.error(e);
                alertDialog({
                    title: texts["bookingview.failed_download_invoice_alert.title"],
                    description: texts["bookingview.failed_download_invoice_alert.description"],
                    okLabel: texts["general.confirmation.ok"],
                });
            });
    };

    const downloadAsPdfButton = () => {
        const resvid = booking?.reservation.resvid;
        const hasinvoice = booking?.reservation?.hasinvoice;

        if (!lang || !resvid || !getinvoice || !hasinvoice) {
            return null;
        }

        return (
            <Button className="button booking-view__button-downloadAsPdf-button" onClick={() => fetchInvoice(resvid)}>
                {texts?.downloadaspdf}
            </Button>
        );
    };

    //--------------------------------------------- Se över betaltkomponenten -----------------------------------------//

    // Render price
    const renderPrice = () => {
        return (
            <Row>
                <Col md={7}></Col>
                <Col md={5} sm={12} xs={12}>
                    {booking && (
                        <div className="booking-view__price">
                            <div className="booking-view__price-row booking-view__price-total">
                                <span>{texts?.totalprice}</span>
                                <span>{booking && formatPrice(booking?.reservationrules.totalamount, currency)}</span>
                            </div>
                            <div className="booking-view__price-row booking-view__price-total">
                                <span>{texts?.paid}</span>
                                <span>{booking && formatPrice(booking?.reservationrules.paidamount, currency)}</span>
                            </div>
                            <div className="booking-view__price-row booking-view__price-total">
                                <span>{texts?.totalpricetopay}</span>
                                <span>
                                    {booking && formatPrice(booking?.reservationrules.totamounttopay, currency)}
                                </span>
                            </div>
                        </div>
                    )}
                </Col>
            </Row>
        );
    };

    //-----------------------------------------------------------------------------------------------------------------------------//

    // Alert view
    const alertView = () => {
        const errors = [].concat(reservationError?.errors, checkoutSummaryError?.errors);
        return (
            <Alert type="warning">
                {errors.map((error, index) => {
                    return <p key={index}>{error?.message}</p>;
                })}
            </Alert>
        );
    };

    /**
     * Open a confirmation dialog and if the user confirms, remove the selected product from mina sidor and
     * navigate to the mina sidor.
     *
     * @param {Object} product
     */
    const openCancelReservationConfirmation = async resvid => {
        const response = await confirmationDialog({
            title: texts["bookingview.cancel_reservation_confirmation.title"],
            description: texts["bookingview.cancel_reservation_confirmation.description"],
        });

        if (response) {
            cancelReservation(resvid);
        }
    };

    const handleAddAdditionalProducts = reservationType => {
        if (!booking.cancelled) {
            setSelectedReservationType(reservationType);
            setDidClickAddAdditionalProducts(true);
            changeReservation();
        }
    };

    // Early exit if booking not found or not products exists in the booking.
    if (!booking || Object.values(booking?.reservation?.products).length === 0) {
        return null;
    }

    const onlyBookedProducts = Object.fromEntries(
        Object.entries(booking?.reservation.products || {}).filter(([, product]) => isBooked(product))
    );

    return (
        <div className="booking-view">
            {!depDateHasPassed && !myPageOnly && !booking.cancelled && (
                <>
                    <AdditionalProductsLinks
                        onClick={handleAddAdditionalProducts}
                        texts={texts}
                        disabledReservationTypeMessage={texts["bookingview.handled_reservation_type"]}
                        disabledReservationTypes={Object.values(booking.reservation.products)
                            .filter(product => product.handled)
                            .map(product => product.type)}
                    />

                    <div style={{ clear: "both" }} className="mb-5"></div>
                </>
            )}

            <Alert hideIcon>{texts["bookingview.change_information_text"]}</Alert>

            <ContactInformation loggedInUser={loggedInUser} arrivalDate={booking.arrdate} texts={texts} />

            {hasAnyBookedAccommodation(booking.reservation.products) && (
                <AccommodationList
                    uniqueProductTypes={uniqueProductTypes}
                    texts={texts}
                    products={booking?.reservation?.products}
                    currency={currency}
                    booking={booking}
                    setRenderedProduct={setRenderedProduct}
                />
            )}

            {hasAnyBookedPackage(booking.reservation.products) && (
                <PackageList
                    uniqueProductTypes={uniqueProductTypes}
                    texts={texts}
                    products={booking.reservation.products}
                    currency={currency}
                    booking={booking}
                    setRenderedProduct={setRenderedProduct}
                />
            )}

            {hasAnyBookedActivitiesWithDynamicPrice(booking?.reservation?.products) && (
                <ActivityWithDynamicPriceList
                    texts={texts}
                    products={onlyBookedProducts}
                    currency={currency}
                    booking={booking}
                    setRenderedProduct={setRenderedProduct}
                />
            )}

            <GuestListView
                texts={texts}
                products={onlyBookedProducts}
                guests={booking?.guests}
                currency={currency}
                booking={booking}
                setRenderedProduct={setRenderedProduct}
            />

            {renderedProducts && (
                <OtherProductList
                    products={onlyBookedProducts}
                    renderedProducts={renderedProducts}
                    currency={currency}
                    booking={booking}
                />
            )}

            <CancelledProductList
                products={booking?.reservation.products}
                texts={texts}
                setRenderedProduct={setRenderedProduct}
            />

            <footer className="booking-view__footer">
                {renderPrice()}

                {(checkoutSummaryError || reservationError) && alertView()}

                <Row>
                    <Col md={{ span: 7, offset: 5 }} sm={12} xs={12}>
                        <div className="booking-view__button">
                            {downloadAsPdfButton()}
                            {renderCancelReservationButton()}
                            {renderReservationPaymentInformation()}
                        </div>
                    </Col>
                </Row>
            </footer>
        </div>
    );

    //-----------------------------------------------------------------------------------------------------------------------------------------//
};

export default BookingView;
