import { Button, CalendarCheckIcon, Heading, HouseCrackedIcon, style, UserTwoIcon } from "@r360/library";
import React, { useCallback } from "react";
import { TCartItem, TItem } from "../../store/types";
import "./AccommodationOption.scss";
import { capitalizeFirstLetter, formatPrice } from "../../Helper";
import { RootState } from "../..";
import useAppSelector from "../../hooks/useAppSelector";
import moment from "moment";
import useTranslate from "../../hooks/useTranslate";
import { getInspectionDataForProduct } from "../../BusinessUtils";

export enum EItemBookableStatus {
    BOOKABLE,
    ALREADY_IN_CART,
    BLOCKED_BY_OWNER_RULES,
}

type TAccommodationOption = {
    item: TItem;
    hasPackages: boolean;
    guestCount: number;
    arrDate: string;
    depDate: string;
    onBooking: () => void;
    accommodationCartProducts: TCartItem[];
    getOwnerMaxRangeLength?: (startDate: string) => number | undefined;
    isSmall?: boolean;
};

export const AccommodationOption = ({
    item,
    hasPackages,
    guestCount,
    arrDate,
    depDate,
    onBooking,
    accommodationCartProducts,
    getOwnerMaxRangeLength,
    isSmall,
}: TAccommodationOption) => {
    const { isDesktop } = useAppSelector((state: RootState) => state.window);
    const t = useTranslate();
    const isOwnerItem = !!item.ownerlid;
    const mandatoryExtras = item.info?.extras
        ?.filter(extra => extra.mandatory_web)
        .map(extra => extra.title)
        .join(", ");

    const secondaryTitle = item.pricetext
        ? item.pricetext
        : hasPackages
        ? t("product_availability.accommodation_only")
        : "";

    const getItemBookableStatus = useCallback((): EItemBookableStatus => {
        const itemArrDate = moment(item.arrdate);
        const itemDepDate = moment(item.depdate);
        const itemArrDepDateDiff = itemDepDate.diff(itemArrDate, "days");

        // Validate if item is allowed to be booked based on owner rules, if logged in as owner.
        const ownerMaxRangeLength = getOwnerMaxRangeLength?.(item.arrdate);
        if (ownerMaxRangeLength !== undefined && itemArrDepDateDiff > ownerMaxRangeLength) {
            return EItemBookableStatus.BLOCKED_BY_OWNER_RULES;
        }

        // If pool item, always allow booking.
        if (!item.unitlid) {
            return EItemBookableStatus.BOOKABLE;
        }

        const matchedUnitsInCart = accommodationCartProducts.filter(
            (cartProduct: TCartItem) => cartProduct.poollid === item.poollid && cartProduct.unitlid === item.unitlid
        );

        const hasDateConflict = matchedUnitsInCart.some((cartProduct: TCartItem) => {
            const cartProductArrDate = moment(cartProduct.arrdate);
            const cartProductDepDate = moment(cartProduct.depdate);

            return (
                itemArrDate.isBetween(cartProductArrDate, cartProductDepDate, undefined, "[)") ||
                itemDepDate.isBetween(cartProductArrDate, cartProductDepDate, undefined, "(]")
            );
        });

        return hasDateConflict ? EItemBookableStatus.ALREADY_IN_CART : EItemBookableStatus.BOOKABLE;
    }, [accommodationCartProducts, getOwnerMaxRangeLength]);

    const itemBookableStatus = getItemBookableStatus();

    return (
        <article className="accommodation-option" {...getInspectionDataForProduct(item)}>
            <div className="accommodation-option__title">
                <Heading type="h5">{t("book.general.price")}</Heading>
                <div className="accommodation-option__price">{`${formatPrice(item.price, item.curr)}`}</div>
            </div>
            {mandatoryExtras && item.price !== 0 && (
                <p className="accommodation-option__description">
                    {`${t(
                        isOwnerItem ? "book.accomodation.extras.mandatory" : "book.general.includes"
                    )}: ${mandatoryExtras}`}
                    .
                </p>
            )}
            <div className={isSmall ? "accommodation-option-small__content" : "accommodation-option__content"}>
                <div className={isSmall ? "accommodation-option-small__info" : "accommodation-option__info"}>
                    {secondaryTitle && (
                        <div className="accommodation-option__secondary-title accommodation-option__icon-w-text">
                            <HouseCrackedIcon color={style.darkBlueColor} />
                            <p className="accommodation-option__secondary-title__text">{secondaryTitle}</p>
                        </div>
                    )}
                    <div
                        className={
                            isSmall ? "accommodation-option-small__search-info" : "accommodation-option__search-info"
                        }
                    >
                        <div className="accommodation-option__icon-w-text">
                            <CalendarCheckIcon color={style.darkBlueColor} />
                            <span>{`${capitalizeFirstLetter(
                                moment(arrDate).format("dddd Do MMMM")
                            )} -  ${capitalizeFirstLetter(moment(depDate).format("dddd Do MMMM YYYY"))} `}</span>
                        </div>
                        <div className="accommodation-option__icon-w-text">
                            <UserTwoIcon color={style.darkBlueColor} />
                            <span>
                                {guestCount === 1
                                    ? `1 ${t("book.general.person")}`
                                    : t("book.general.number_persons", [guestCount])}{" "}
                                {}
                            </span>
                        </div>
                    </div>
                </div>
                <Button
                    fullWidth={!isDesktop || isSmall}
                    disabled={itemBookableStatus !== EItemBookableStatus.BOOKABLE}
                    onClick={onBooking}
                >
                    {itemBookableStatus === EItemBookableStatus.BOOKABLE && t("reservationbutton")}
                    {itemBookableStatus === EItemBookableStatus.ALREADY_IN_CART && t("product.added_to_cart")}
                    {itemBookableStatus === EItemBookableStatus.BLOCKED_BY_OWNER_RULES && t("book.general.cannot_book")}
                </Button>
            </div>
        </article>
    );
};
