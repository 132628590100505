import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import useTranslate from "../../hooks/useTranslate";
import { TItem, TReservationTypeLid } from "../../store/types";
import { SimpleCard } from "../SimpleCard/SimpleCard";

type TAccommodationOwnerResultList = {
    reservationTypeId: TReservationTypeLid;
    items: TItem[];
};

const AccommodationOwnerResultList = ({ reservationTypeId, items }: TAccommodationOwnerResultList) => {
    const navigate = useNavigate();
    const t = useTranslate();

    const handleClick = useCallback(
        (item: TItem) => {
            navigate(
                `/product/${reservationTypeId}/${item.grouppoollid}/${item.poollid}/${item.unitlid}${location.search}`
            );
        },
        [reservationTypeId, location.search]
    );

    return (
        <div>
            {items.map(item => (
                <div key={item.id} className="mb-3">
                    <SimpleCard
                        title={item.title}
                        description={item.weblong || ""}
                        buttonText={t("book.general.book")}
                        onButtonClick={() => handleClick(item)}
                        images={item.images}
                    />
                </div>
            ))}
        </div>
    );
};

export default AccommodationOwnerResultList;
