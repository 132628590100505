import React, { ReactNode, useCallback } from "react";
import useAppSelector from "../../../../hooks/useAppSelector";
import useTranslate from "../../../../hooks/useTranslate";
import { Status } from "@r360/library";
import { TCartItem } from "../../../../store/types";
import { formatPrice } from "../../../../Helper";

type TCartItemComponent = {
    cartItem: TCartItem;
    extrasItemId: number;
    title?: string;
    renderTitle?: () => ReactNode;
    currency: string;
    price: number;
    showPriceWeb?: boolean;
    quantity?: number;
    isMandatory?: boolean;
};

export const ExtrasListItem = ({
    cartItem,
    extrasItemId,
    title = "",
    renderTitle,
    currency,
    price,
    showPriceWeb = false,
    quantity = 1,
    isMandatory = false,
}: TCartItemComponent) => {
    const t = useTranslate();
    const isMobile = useAppSelector(state => state.window.isMobile);
    const isOwnerItem = !!cartItem.ownerlid;

    let showPrice = true;
    if (isMandatory && !showPriceWeb) {
        showPrice = false;
    }

    const getMandatoryText = useCallback(() => {
        if (isOwnerItem) {
            return t("book.accomodation.extras.mandatory");
        } else if (isMobile) {
            return t("book.cart.extras.included");
        } else {
            return t("book.cart.extras.included_in_acccommodation");
        }
    }, [isOwnerItem, isMobile, t]);

    return (
        <div className="extras-list-item" key={extrasItemId}>
            <div className="extras-list-item-title">{renderTitle?.() || title}</div>
            <div className="extras-list-item-quantity">
                {quantity > 1 && `${t("book.general.quantity")} ${quantity}`}
            </div>
            <div className="extras-list-item-price">
                {isMandatory ? (
                    <>
                        <div className="extras-list-item-mandatory">
                            <Status size={10} type="success" /> {getMandatoryText()}
                        </div>
                        <div>{showPrice && `${formatPrice(price, currency)}`}</div>
                    </>
                ) : (
                    `${formatPrice(price, currency)}`
                )}
            </div>
        </div>
    );
};
