import { Button, ChevronDownIcon, ChevronUpIcon, style } from "@r360/library";
import moment from "moment";
import React from "react";
import "./EditSearchButton.scss";
import useTranslate from "../../hooks/useTranslate";

type TEditSearchButton = {
    arrDate: string;
    depDate: string;
    guestsCount: number;
    searchExists: boolean;
    onClick: () => void;
    isOpen: boolean;
};

export const EditSearchButton = ({
    arrDate,
    depDate,
    guestsCount,
    searchExists,
    onClick,
    isOpen,
}: TEditSearchButton) => {
    const t = useTranslate();
    const fancySearchString = `${guestsCount} personer, ${moment(arrDate).format("Do MMM")} - ${moment(depDate).format(
        "Do MMMM YYYY"
    )}`;

    const text = searchExists ? fancySearchString : t("book.accomodation.search_accommodation");

    return (
        <Button
            type="tertiary"
            onClick={onClick}
            rightIcon={
                isOpen ? <ChevronUpIcon color={style.darkBlueColor} /> : <ChevronDownIcon color={style.darkBlueColor} />
            }
            buttonClassName="edit-search-button"
        >
            {text}
        </Button>
    );
};
