import { Button, Heading, ImagePlaceholder } from "@r360/library";
import React from "react";
import { TImage } from "../../store/types";
import ProductGallery from "../ProductGallery/ProductGallery";
import "./SimpleCard.scss";
import { RichText } from "../UI";

type TSimpleCard = {
    title: string;
    images: TImage[];
    description: string;
    buttonText: string;
    onButtonClick: () => void;
};

export const SimpleCard = ({ title, images, description, buttonText, onButtonClick }: TSimpleCard) => {
    return (
        <div className="simple-card">
            <div className="row">
                <div className="col-12 col-xl-4 col-md-6 simple-card__gallery">
                    {images.length > 0 ? (
                        <ProductGallery
                            images={(images || []).map(image => ({
                                original: image.url,
                                thumbnail: image.url,
                            }))}
                            forceImageGallery={true}
                            inAccommodationCard
                        />
                    ) : (
                        <ImagePlaceholder aspectRatio="16 / 9" />
                    )}
                </div>
                <div className="col-12 col-xl-8 col-md-6">
                    <div className="simple-card__info">
                        <Heading type="h2" styleAs="h3" className="u-mb-6">
                            {title}
                        </Heading>
                        <div className="simple-card__description">
                            {description ? <RichText content={description} /> : ""}
                        </div>
                    </div>
                    <Button buttonClassName="simple-card__button--desktop" onClick={onButtonClick} buttonSize="small">
                        {buttonText}
                    </Button>
                    <div className="simple-card__button--mobile" onClick={onButtonClick}>
                        {buttonText}
                    </div>
                </div>
            </div>
        </div>
    );
};
