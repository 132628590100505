import qs from "query-string";

const setQueryStringWithoutPageReload = qsValue => {
    if (window.history.pushState) {
        const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + qsValue;
        if (window.history.state?.path !== newurl) {
            window.history.replaceState({ path: newurl }, "", newurl);
        }
    }
};

export const getQueryStringValue = (key, queryString = window.location.search) => {
    const values = qs.parse(queryString);
    return values[key];
};

export const getQueryStringObjectValue = (keys, queryString = window.location.search) => {
    const values = qs.parse(queryString);
    return keys.reduce((acc, key) => {
        const valueFromUrl = values[key];
        if (valueFromUrl) acc[key] = valueFromUrl;
        return acc;
    }, {});
};

export const setQueryStringValue = (key, value, queryString = window.location.search) => {
    let values = qs.parse(queryString);
    let newQsValue;

    if (value === "") {
        delete values[key];
        newQsValue = qs.stringify(values);
    } else {
        newQsValue = qs.stringify({
            ...values,
            [key]: value,
        });
    }

    setQueryStringWithoutPageReload(newQsValue !== "" ? `?${newQsValue}` : "");
};

export const setQueryStringObjectValue = (values, queryString = window.location.search) => {
    let urlValues = qs.parse(queryString);
    let newQsValue;

    newQsValue = qs.stringify({
        ...urlValues,
        ...values,
    });

    setQueryStringWithoutPageReload(newQsValue !== "" ? `?${newQsValue}` : "");
};
