import React from "react";
import { Incrementer } from "../../../UI";
import { formatPrice } from "../../../../Helper";
import { TrashIcon } from "@r360/library";
import ConfirmationDialog from "../../../Utilities/ConfirmationDialog";
import useTranslate from "../../../../hooks/useTranslate";
import { on } from "events";

type TCartItemGuestAgeListItemComponent = {
    id: string;
    title: string;
    quantity: number;
    maxQuantity: number;
    currency: string;
    price: number;
    value: number;
    onChange: (id: string, value: number) => void;
    onDelete: (id: string) => void;
    totalGuests: number;
    onNoGuestsLeft: () => void;
    productTitle: string;
};

export const CartItemGuestAgeListItem = ({
    id,
    title,
    quantity,
    maxQuantity,
    currency,
    price,
    value,
    onChange,
    onDelete,
    totalGuests,
    onNoGuestsLeft,
    productTitle,
}: TCartItemGuestAgeListItemComponent) => {
    const t = useTranslate();
    const totalPrice = price * quantity;

    const handleDeleteByDecrement = async () => {
        if (totalGuests === 1) {
            onNoGuestsLeft();
            return;
        }

        handleDelete();
    };

    const handleDelete = async () => {
        const response = await ConfirmationDialog({
            heading: t("book.confirmations.remove_product_from_cart.title", [`${productTitle} - ${title}`]),
            confirmBtnText: t("book.confirmations.remove_product_from_cart.ok"),
            cancelBtnText: t("book.confirmations.remove_product_from_cart.cancel"),
        });

        if (!response) return;

        if (typeof onDelete === "function") {
            onDelete(id);
        }
    };

    return (
        <div className="cart-item-guest-age-list--row--item" id={id}>
            <div className="cart-item-guest-age-list--row--item-title">{title}</div>
            <div className="cart-item-guest-age-list--row--item-quantity">
                <Incrementer
                    name={id}
                    label={quantity}
                    value={value}
                    minValue={0}
                    maxValue={maxQuantity}
                    onChange={(data: { key: string; value: number }) => {
                        if (data.value === 0) {
                            handleDeleteByDecrement();
                        } else if (typeof onChange === "function") {
                            onChange(data.key, data.value);
                        }
                    }}
                />
            </div>
            <div className="cart-item-guest-age-list--row--item-price">
                <div className="cart-item-guest-age-list--row--item-right-content">
                    {formatPrice(totalPrice, currency)}
                    <div onClick={handleDelete} className="cart-item-guest-age-list--row--item--delete">
                        <TrashIcon />
                    </div>
                </div>
            </div>
        </div>
    );
};
