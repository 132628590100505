import moment from "moment-timezone";
import { signal } from ".";
import { SIGNAL_CART_ADD_PRODUCT, SIGNAL_CART_EMPTY, SIGNAL_CART_REMOVE_PRODUCT } from "./types";

/**
 * Create a tracking signal which may be used to track products added to cart
 *
 * @param {object} product The product to track
 * @returns {object} Signal
 */
export const createCartAddProductSignal = product => {
    const theSignal = signal(SIGNAL_CART_ADD_PRODUCT, {
        name: product.title ? product.title : product.pooldesc3,
        pooldesc1: product.pooldesc1,
        pooldesc3: product.pooldesc3,
        poollid: product.poollid,
        unitdesc1: product.unitdesc1,
        unitdesc3: product.unitdesc3,
        unitlid: product.unitlid,
        price: product.price,
        pricecode: product.pricecode,
        quantity: product.quantity,
        arrdate: moment(product.arrdate).format("YYYY-MM-DD"),
        depdate: moment(product.depdate).format("YYYY-MM-DD"),
        isextra: product.parent ? true : false,
        promotionlid: product.promotionlid,
        promotioncode: product.promotionCode,
        promotiondescription: product.promotionDescription,
        reservationtypelid: product.type,
        reservationtype: product.kind,
        reservationtypedesc: product.kinddesc,
    });

    return theSignal;
};

export const createCartRemoveProductSignal = product => {
    const theSignal = signal(SIGNAL_CART_REMOVE_PRODUCT, {
        name: product.title ? product.title : product.pooldesc3,
        pooldesc1: product.pooldesc1,
        pooldesc3: product.pooldesc3,
        poollid: product.poollid,
        unitdesc1: product.unitdesc1,
        unitdesc3: product.unitdesc3,
        unitlid: product.unitlid,
        price: product.price,
        pricecode: product.pricecode,
        quantity: product.quantity,
        arrdate: moment(product.arrdate).format("YYYY-MM-DD"),
        depdate: moment(product.depdate).format("YYYY-MM-DD"),
        isextra: product.parent ? true : false,
        promotionlid: product.promotionlid,
        promotioncode: product.promotionCode,
        promotiondescription: product.promotionDescription,
        reservationtypelid: product.type,
        reservationtype: product.kind,
        reservationtypedesc: product.kinddesc,
    });

    return theSignal;
};

export const createCartEmptySignal = () => signal(SIGNAL_CART_EMPTY);
