import { isSkipassForFastCheckout } from "./BusinessUtils";

/**
 * Get general ages and allow to fetch ages by a given reservation type lid
 *
 * @param {object} state
 * @param {number} resvtypelid (optional)
 * @returns {object|array}
 */
export const agesSelector = (state, resvtypelid) => {
    const ages = JSON.parse(state.clientData.options?.general?.ages);

    if (!ages) {
        return;
    }

    if (resvtypelid) {
        if (resvtypelid in ages) {
            return ages[resvtypelid]; // array
        } else {
            throw new Error(`Reservation type ${resvtypelid} does not have any ages configured in general ages`);
        }
    }

    return ages; // hash map
};

export const resvTypeByLid = (state, typelid) => {
    return state.clientData?.reservationtypes?.find(resvtype => resvtype.lid === typelid);
};

/**
 * Usage: (state, 'ACCOMMODATION') => [500, 1000, ...]
 *
 * Example:
 * const accommodationLids = useSelector(
 *   (state) => resvTypeLidsByTypeSelector(state, Constants.productTypeNames.ACCOMMODATION)
 * );
 *
 * @param {object} state
 * @param {string} type
 * @return {array}
 */
export const resvTypeLidsByTypeSelector = (state, type) => {
    const lids = state.clientData?.reservationtypes
        ?.filter(resvtype => {
            return resvtype.type === type;
        })
        .map(resvtype => resvtype.lid);

    if (!lids) {
        return [];
    }

    return lids;
};

/**
 * Get reservation types by specified type.
 * We may have several reservation types of type ACCOMMODATION like "Boende" and "Stugby" and "Camping" and "Hotel"
 *
 * @param {object} state
 * @param {string} type
 * @returns {array}
 */
export const reservationTypesByTypeSelector = (state, type) => {
    const resvtypes = state.clientData?.reservationtypes?.filter(resvtype => {
        return resvtype.type === type;
    });

    if (!resvtypes) {
        return [];
    }

    return resvtypes;
};

export const guestsOnTravelSelector = state => {
    return Object.entries(state.guests.guests).reduce((acc, [guestId, guest]) => {
        if (guest.ontravel) {
            acc[guestId] = guest;
        }

        return acc;
    }, {});
};

export const optionsSelector = state => state.clientData?.options || {};

export const cartCreatedFromReservationSelector = state => !!state.checkoutCart?.createdFromReservation;

export const notificationsCheckSelector = (state, type, data) => {
    // Check if all the values in the provided data matches with the data in the notification.
    const compareData = (needleData, haystackData) =>
        Object.entries(needleData)
            .map(([key, value]) => haystackData[key] === value)
            .every(check => check === true);

    return !!state.notifications.find(
        notification => notification.type === type && compareData(data, notification.data)
    );
};

/**
 * Get available languages from clientData state.
 *
 * @param {object} state
 * @returns A list of available languages.
 */
export const languagesSelector = state => state.clientData.languages?.filter(lang => lang.visible) || [];

export const textsSelector = state =>
    state.clientData.texts
        ? Object.entries(state.clientData?.texts).reduce((acc, [key, value]) => {
              acc[key] = value.value;
              return acc;
          }, {})
        : {};

/**
 * Get the correct checkout url.
 *
 * Return fast checkout url if all are true:
 * - Option reservation.fastcheckout is enabled
 * - Only skipass products in cart
 * - Not logged in
 * - Payment method: Klarna | Ecster | Easy
 *
 * @param {object} state
 * @returns The URL to the checkout.
 */
export const checkoutUrlSelector = state => {
    const checkoutUrl = "/checkout";
    const fastcheckoutUrl = "/fastcheckout";

    const fastcheckoutEnabled = optionsSelector(state).reservation?.fastcheckout;
    const paymentMethod = optionsSelector(state).payment?.preferredclientpayment;

    const cartProducts = state.checkoutCart.products || [];
    const hasOnlySkipassProductsForFastCheckout =
        cartProducts.length > 0 && cartProducts.every(isSkipassForFastCheckout);
    const loggedInUser = state.account.user;
    const fastCheckoutPaymentMethods = ["klarna", "ecster", "easy"];

    if (
        fastcheckoutEnabled &&
        hasOnlySkipassProductsForFastCheckout &&
        !loggedInUser &&
        fastCheckoutPaymentMethods.includes(paymentMethod)
    ) {
        return fastcheckoutUrl;
    }

    return checkoutUrl;
};

export const customTranslationsSelector = state => state.clientData.customTranslations || [];
