import isEqual from "lodash/isEqual";
import moment from "moment-timezone";
import React, { useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { showArrDepDates } from "../../../BusinessUtils";
import { getGuestNameAndAge } from "../../../Helper";
import { agesSelector, guestsOnTravelSelector } from "../../../selectors/Selectors";
import { updateProduct } from "../../../store/actions/checkoutCart";
import { InformationBlock } from "../../UI";
import confirmationDialog from "../../UI/ConfirmationDialog";
import { CalendarIcon } from "../../UI/R360Icons";
import "./Package.scss";

const Package = props => {
    const dispatch = useDispatch();
    const { products, type, texts, removeProductFromCart } = props;
    const navigate = useNavigate();
    const packages = products.filter(product => parseInt(product.type, 10) === parseInt(type.lid, 10));
    const guestsOnTravel = useSelector(guestsOnTravelSelector);
    const generalAges = useSelector(agesSelector);
    const userToken = useSelector(state => state.account.token);
    const checkoutCartId = useSelector(state => state.checkoutCart.cartId);

    /**
     * Check that all guests connected to packages should be on the travel.
     * If not, remove them from the package.
     */
    useEffect(() => {
        const guestIds = Object.keys(guestsOnTravel).map(guestId => parseInt(guestId));

        for (const packageItem of packages) {
            if (!packageItem.guests) {
                continue;
            }

            const packageGuests = { ...packageItem.guests };

            for (const [index, packageGuestId] of Object.entries(packageGuests)) {
                if (!guestIds.includes(packageGuestId)) {
                    delete packageGuests[index];
                }
            }

            if (!isEqual(packageItem.guests, packageGuests)) {
                dispatch(
                    updateProduct(packageItem, {
                        key: "guests",
                        value: packageGuests,
                    })
                );
            }
        }
    }, [dispatch, packages, guestsOnTravel]);

    /**
     * Get ages for a reservation type.
     *
     * @param {string} reservationTypeId The reservation type ID.
     *
     * @returns {Object[]} A list of ages objects.
     */
    const getAgesForReservationType = reservationTypeId => {
        if (!generalAges[reservationTypeId]) {
            throw new Error(`Reservation type ${reservationTypeId} does not have any ages configured in general ages`);
        }

        return generalAges[reservationTypeId];
    };

    /**
     * Get a list of ages for an package sorted by descending min age.
     *
     * E.g. if guests for package is { youth: 2, adult: 1, child: 1 }, this is returned:
     * [{ key: "adult", ...} , { key: "youth", ...}, { key: "youth", ...}, { key: "child", ...}]
     *
     * @param {Object} packageItem The package product from cart.
     *
     * @returns {Object[]} A list of ages for an package sorted by descending min age.
     */
    const getSortedAgesForPackage = packageItem => {
        const ages = getAgesForReservationType(packageItem.type);
        const guestCounts = packageItem.info.guests;
        const totalGuestsCount = packageItem.info.guests.totalGuests;

        // Sort age categories by descending min age.
        const sortedAges = ages.sort((a, b) => b.min - a.min);

        let selectsByAge = [];

        sortedAges.forEach(age => {
            if (guestCounts[age.key] > 0) {
                for (let i = 0; i < guestCounts[age.key]; i++) {
                    selectsByAge.push(age);
                }
            }
        });

        if (selectsByAge.length !== totalGuestsCount) {
            throw new Error(
                `Guest count doesn't match when comparing total guests and guest count per age category for package with intervallid ${packageItem.intervallid}`
            );
        }

        return selectsByAge;
    };

    /**
     * Check if a guest can be added to an package slot based on age and other assigned
     * packages.
     *
     * @param {Object} guest
     * @param {Object} packageItem
     * @param {number} dropdownIndex
     * @param {Object} age
     *
     * @returns {boolean} True if the guest can be added to the slot, otherwise false.
     */
    const getCanGuestBeAddedToPackageSlot = (guest, packageItem, dropdownIndex, age) => {
        const arrDate = moment(packageItem.arrdate);
        const depDate = moment(packageItem.depdate);

        // Check that the guest is the correct age on arrival date.
        if (!guest.birthday) {
            return false;
        }

        const guestAgeAtArrivalDate = arrDate.diff(moment(guest.birthday), "years");

        if (guestAgeAtArrivalDate < age.min || guestAgeAtArrivalDate > age.max) {
            return false;
        }

        // Check if the guest is already assigned to another spot on the current package.
        const guestIsAlreadyAssignedToOtherSpotAtCurrentPackage = Object.entries(packageItem.guests || {}).some(
            ([index, packageGuestId]) => {
                if (packageGuestId === guest.id && parseInt(index) !== dropdownIndex) {
                    return true;
                }

                return false;
            }
        );

        if (guestIsAlreadyAssignedToOtherSpotAtCurrentPackage) {
            return false;
        }

        // Check if the guest is assigned to any other packages during the same period.
        const otherPackages = packages.filter(filterPackage => filterPackage.id !== packageItem.id);

        const guestIsAlreadyAssignedToOtherPackageForPeriod = otherPackages.some(otherPackage => {
            if (Object.values(otherPackage.guests || {}).includes(guest.id)) {
                const assignedArrDate = moment(otherPackage.arrdate);
                const assignedDepDate = moment(otherPackage.depdate);

                if (
                    arrDate.isBetween(assignedArrDate, assignedDepDate, undefined, "[)") ||
                    depDate.isBetween(assignedArrDate, assignedDepDate, undefined, "(]")
                ) {
                    return true;
                }
            }

            return false;
        });

        if (guestIsAlreadyAssignedToOtherPackageForPeriod) {
            return false;
        }

        return true;
    };

    /**
     * Assign a guest to a package slot.
     *
     * @param {Object} packageItem
     * @param {number} dropdownIndex
     * @param {number} guestId
     */
    const assignGuestToPackage = (packageItem, dropdownIndex, guestId) => {
        const packageGuests = { ...packageItem.guests };

        // If not guest is provided, remove guest is assigned to the provided index.
        if (guestId === "") {
            delete packageGuests[dropdownIndex];
        } else {
            const guestIdNumber = parseInt(guestId);

            if (Object.values(packageGuests).includes(guestIdNumber)) {
                return;
            }

            packageGuests[dropdownIndex] = guestIdNumber;
        }

        dispatch(
            updateProduct(packageItem, {
                key: "guests",
                value: packageGuests,
            })
        );
    };

    /**
     * Open a confirmation dialog and if the user confirms, remove the package from cart and
     * navigate to the package product page.
     *
     * @param {Object} packageItem
     */
    const openChangePackageConfirmation = async packageItem => {
        const response = await confirmationDialog({
            title: texts["checkout.package.change_confirmation.title"],
            description: texts["checkout.package.change_confirmation.description"],
            okLabel: texts["general.confirmation.continue"],
            cancelLabel: texts["general.confirmation.cancel"],
        });

        if (response) {
            const { type } = packageItem;

            removeProductFromCart(packageItem);

            navigate(`/search/${type}`);
        }
    };

    /**
     * Open a confirmation dialog and if the user confirms, remove the package from cart
     *
     * @param {object} packageItem
     */
    const openRemovePackageConfirmation = async packageItem => {
        const response = await confirmationDialog({
            title: texts["checkout.package.remove_confirmation.title"],
            description: texts["checkout.package.remove_confirmation.description"],
            okLabel: texts["general.confirmation.continue"],
            cancelLabel: texts["general.confirmation.cancel"],
        });

        if (response) {
            removeProductFromCart(packageItem);
        }
    };

    /**
     * Render the select fields for a package.
     *
     * @param {Object} packageItem
     */
    const renderGuestSelectsForPackage = packageItem => {
        const sortedAges = getSortedAgesForPackage(packageItem);
        const packageGuests = packageItem.guests || {};

        return (
            <>
                {sortedAges.map((age, dropdownIndex) => (
                    <Form.Group as={Col} md="3" key={Math.random()}>
                        <Form.Control
                            required
                            as="select"
                            name="guestId"
                            autoComplete="off"
                            defaultValue={(packageGuests[dropdownIndex] || "").toString()}
                            onChange={event => assignGuestToPackage(packageItem, dropdownIndex, event.target.value)}
                        >
                            <option value="">{texts?.[age.title]} *</option>
                            {Object.entries(guestsOnTravel)
                                .filter(([, guest]) =>
                                    getCanGuestBeAddedToPackageSlot(guest, packageItem, dropdownIndex, age)
                                )
                                .map(([guestId, guest]) => {
                                    return (
                                        <option key={guestId} value={guestId}>
                                            {getGuestNameAndAge(guest, texts?.generalyearshort, packageItem)}
                                        </option>
                                    );
                                })}
                        </Form.Control>
                    </Form.Group>
                ))}
            </>
        );
    };

    /**
     * Render all packages.
     */
    const renderPackages = () => {
        const sortedPackages = packages.sort((a, b) => moment(a.arrdate).unix() - moment(b.arrdate).unix());

        return (
            <div>
                {sortedPackages.map(packageItem => (
                    <div key={packageItem.cartItemId} className="package-fill-out__item">
                        <Row>
                            <Form.Group as={Col} md="auto">
                                <h5 className="m-0">{packageItem.title}</h5>
                                <span className="package-fill-out__item-date">
                                    <CalendarIcon
                                        size={16}
                                        color="#333333"
                                        iconClass="package-fill-out__item-date-icon"
                                    />
                                    {showArrDepDates(packageItem)}
                                </span>
                            </Form.Group>
                        </Row>

                        <Row>{renderGuestSelectsForPackage(packageItem)}</Row>

                        <Row>
                            <Col>
                                <Button
                                    className="skiRental__button p-0 text-danger"
                                    variant="link"
                                    onClick={() => openRemovePackageConfirmation(packageItem)}
                                >
                                    {texts?.generalremove}
                                </Button>

                                <Button
                                    onClick={() => openChangePackageConfirmation(packageItem)}
                                    variant="link"
                                    size="md"
                                    className="fw-light text-primary p-0 ms-4"
                                >
                                    {texts["checkout.package.buttons.change"]}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                ))}
            </div>
        );
    };

    // Make sure the required data from the state has been fetched.
    if (!guestsOnTravel || !generalAges || !userToken || !checkoutCartId) {
        return <></>;
    }

    return (
        <div className="pt-4">
            <InformationBlock className="mb-4">{texts?.missingguestinformation}</InformationBlock>

            {renderPackages()}
        </div>
    );
};

export default Package;
