import React, { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Field, Formik } from "formik";
import * as yup from "yup";
import { useSelector } from "react-redux";
import { Col, Form, Button, Row } from "react-bootstrap";
import "../../../YupExtensions";
import "./PersonalInformation.scss";
import PhoneCountryCodeSelect from "../../PhoneCountryCodeSelect/OldPhoneCountryCodeSelect";
import { YearPicker, MonthPicker, DayPicker } from "react-dropdown-date";
import classnames from "classnames";
import { isNumberWithoutDecimals } from "../../../Helper";
import moment from "moment-timezone";
import debounce from "lodash/debounce";
import useFormPhoneNumberValidation from "../../../hooks/useFormPhoneNumberValidation";

const PersonalDetailsInformation = forwardRef((props, ref) => {
    const {
        isPrimaryGuest,
        newGuest,
        guest,
        texts,
        onChange,
        onCancel,
        onSubmit,
        showSubmitButton,
        disabled,
        showAgePicker = true,
        skipValidation = false,
        onValidateBeforeSubmit, // Optional: Complementary form validation. Return an object with {fieldName: "error message"} to trigger form errors to show
    } = props;

    const countries = useSelector(state => state.clientData?.country);

    const formikRef = useRef(null);
    const [customClass, setCustomClass] = useState("");

    const { phoneValid, phonePrefixValid, validatePhone, validatePhonePrefix } = useFormPhoneNumberValidation(
        isPrimaryGuest,
        newGuest || !!guest?.phone || false,
        newGuest || !!guest?.phoneprefix || false,
        () => {
            formikRef.current.setFieldTouched("phoneprefix", true, false);
        },
        () => {
            formikRef.current.setFieldTouched("phone", true, false);
        }
    );

    useImperativeHandle(ref, () => ({
        submitForm: formikRef.current.submitForm,
        resetForm: formikRef.current.resetForm,
        resetTouched: () => formikRef.current.setTouched({}),
        getValues: getParsedValues,
        getIsValid: () => formikRef.current.isValid,
        setFieldValue: formikRef.current.setFieldValue,
        highlightForm: () => {
            setCustomClass("shake");
            setTimeout(() => {
                setCustomClass("");
            }, 2000);
        },
    }));

    const validationSchema = yup.object().shape({
        ...(guest && {
            id: yup.number().required(),
        }),
        firstname: yup.string().required(),
        lastname: yup.string().required(),
        email: isPrimaryGuest ? yup.string().email().required() : yup.string().email(),
        ...(showAgePicker && {
            year: yup.string().required(),
            month: yup.string().required(),
            day: yup.string().required(),
        }),
        ...(isPrimaryGuest && {
            address: yup.string().required(),
            postalcode: yup.string().required(),
            city: yup.string().required(),
            country: yup.string().required(),
        }),
    });

    const debouncedOnChange = debounce(
        (fieldName, fieldValue, restorePreviousValue) => onChange(fieldName, fieldValue, restorePreviousValue),
        250
    );

    const triggerOnChangeCallbackIfValid = (fieldName, fieldValue) => {
        if (!onChange) {
            return;
        }

        const previousValue = formikRef.current.values[fieldName];
        const restorePreviousValue = () => formikRef.current.setFieldValue(fieldName, previousValue);

        if (["year", "month", "day"].includes(fieldName)) {
            const dateFormat = "YYYY-MM-DD";
            const values = { ...formikRef.current.values };
            const birthdayValues = { year: values.year, month: values.month, day: values.day };
            birthdayValues[fieldName] = fieldValue;
            const isValidDate = !Object.values(birthdayValues).includes("");

            if (isValidDate) {
                const birthday = moment()
                    .set({
                        year: birthdayValues.year,
                        month: birthdayValues.month,
                        date: birthdayValues.day,
                    })
                    .format(dateFormat);

                onChange("birthday", birthday, restorePreviousValue);
            }

            return;
        }

        validationSchema
            .validateAt(fieldName, { [fieldName]: fieldValue })
            .then(() => {
                debouncedOnChange(fieldName, fieldValue, restorePreviousValue);
            })
            .catch((/*err*/) => {
                // Validation of field didn't pass. Good when debugging invalid fields.
                // console.log("validation of", fieldName, fieldValue, "did not pass", err);
            });
    };

    const getParsedValues = () => {
        const valuesClone = { ...formikRef.current.values };

        valuesClone.birthday = moment()
            .set({
                year: valuesClone.year,
                month: valuesClone.month,
                date: valuesClone.day,
            })
            .format("YYYY-MM-DD");

        delete valuesClone.year;
        delete valuesClone.month;
        delete valuesClone.day;

        return valuesClone;
    };

    const customHandleSubmit = (values, formikBag) => {
        if (newGuest && !values.phoneprefix && !values.phone) {
            // OK, optional
        } else {
            const phoneErrors = {};
            if (!validatePhonePrefix(values.phoneprefix, values.phone)) {
                phoneErrors.phoneprefix = "Invalid phone prefix";
            }

            if (!validatePhone(values.phoneprefix, values.phone)) {
                phoneErrors.phone = "Invalid phone number";
            }

            if (phoneErrors && Object.keys(phoneErrors).length) {
                formikBag.setErrors(phoneErrors);
                return;
            }
        }

        // Allow a handler to have complementary validation before submitting the form
        if (onValidateBeforeSubmit && typeof onValidateBeforeSubmit === "function") {
            const errors = onValidateBeforeSubmit(values);

            if (errors && Object.keys(errors).length) {
                // This will trigger the form validation again for specific fields
                // Example: { [fieldName]: "Error message" }
                formikBag.setErrors(errors);
            }
        }

        onSubmit && onSubmit(getParsedValues());
    };

    const birthdayMoment = moment(guest?.birthday || "");

    if (!texts) {
        return <></>;
    }

    return (
        <div className={"personal-details-information " + customClass}>
            <Formik
                innerRef={formikRef}
                validationSchema={!skipValidation ? validationSchema : null}
                onSubmit={customHandleSubmit}
                initialValues={{
                    ...(guest && {
                        id: guest?.addrlid || guest?.id,
                    }),
                    firstname: guest?.firstname || guest?.name1 || "",
                    lastname: guest?.lastname || guest?.name2 || "",
                    email: guest?.email || "",
                    phoneprefix: guest?.phone3prefix || guest?.phoneprefix || "",
                    phone: guest?.phone3 || guest?.phone || "",
                    ...(showAgePicker && {
                        year: birthdayMoment.isValid() ? birthdayMoment.year() : "",
                        month: birthdayMoment.isValid() ? birthdayMoment.month() : "",
                        day: birthdayMoment.isValid() ? birthdayMoment.date() : "",
                    }),
                    ...(isPrimaryGuest && {
                        address: guest?.addr1 || "",
                        postalcode: guest?.addr3 || "",
                        city: guest?.addr4 || "",
                        country: guest?.country || guest?.addr5 || "",
                    }),
                }}
            >
                {({ handleSubmit, handleChange, handleBlur, values, touched, errors }) => {
                    return (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Row>
                                <Form.Group as={Col} md="6">
                                    <Form.Control
                                        type="text"
                                        name="firstname"
                                        placeholder={texts.generalfirstname}
                                        value={values.firstname}
                                        onChange={event => {
                                            handleChange(event);
                                            triggerOnChangeCallbackIfValid(
                                                event.target.name,
                                                event.target.value,
                                                values.firstname
                                            );
                                        }}
                                        onBlur={handleBlur}
                                        isValid={touched.firstname && !errors.firstname}
                                        isInvalid={touched.firstname && !!errors.firstname}
                                        disabled={disabled}
                                    ></Form.Control>
                                </Form.Group>
                                <Form.Group as={Col} md="6">
                                    <Form.Control
                                        name="lastname"
                                        placeholder={texts.generallastname}
                                        value={values.lastname}
                                        onChange={event => {
                                            handleChange(event);
                                            triggerOnChangeCallbackIfValid(event.target.name, event.target.value);
                                        }}
                                        onBlur={handleBlur}
                                        isValid={touched.lastname && !errors.lastname}
                                        isInvalid={touched.lastname && !!errors.lastname}
                                        disabled={disabled}
                                    ></Form.Control>
                                </Form.Group>
                            </Row>
                            <Row>
                                {showAgePicker && (
                                    <Form.Group as={Col} md="6" className="mb-0">
                                        <Row>
                                            <Form.Group as={Col} xs="5">
                                                <Field>
                                                    {({ form: { setFieldValue, setFieldTouched } }) => (
                                                        <YearPicker
                                                            defaultValue={texts.generalyear}
                                                            reverse
                                                            value={values.year}
                                                            onChange={value => {
                                                                setFieldTouched("year", true);
                                                                setFieldValue("year", value);
                                                                triggerOnChangeCallbackIfValid("year", value);
                                                            }}
                                                            name="year"
                                                            classes={classnames("form-control", {
                                                                "is-valid": touched.year && !errors.year,
                                                                "is-invalid": touched.year && !!errors.year,
                                                            })}
                                                            disabled={disabled}
                                                        />
                                                    )}
                                                </Field>
                                            </Form.Group>
                                            <Form.Group as={Col} xs="4">
                                                <Field>
                                                    {({ form: { setFieldValue, setFieldTouched } }) => (
                                                        <MonthPicker
                                                            defaultValue={texts.generalmonth}
                                                            endYearGiven
                                                            numeric
                                                            value={values.month}
                                                            year={values.year}
                                                            onChange={value => {
                                                                setFieldTouched("month", true);
                                                                setFieldValue("month", value);
                                                                triggerOnChangeCallbackIfValid("month", value);
                                                            }}
                                                            name="month"
                                                            classes={classnames("form-control", {
                                                                "is-valid": touched.month && !errors.month,
                                                                "is-invalid": touched.month && !!errors.month,
                                                            })}
                                                            disabled={disabled}
                                                        />
                                                    )}
                                                </Field>
                                            </Form.Group>
                                            <Form.Group as={Col} xs="3">
                                                <Field>
                                                    {({ form: { setFieldValue, setFieldTouched } }) => (
                                                        <DayPicker
                                                            defaultValue={texts.generalday}
                                                            year={values.year}
                                                            month={values.month}
                                                            value={values.day}
                                                            endYearGiven
                                                            onChange={value => {
                                                                setFieldTouched("day", true);
                                                                setFieldValue("day", value);
                                                                triggerOnChangeCallbackIfValid("day", value);
                                                            }}
                                                            name="day"
                                                            classes={classnames("form-control", {
                                                                "is-valid": touched.day && !errors.day,
                                                                "is-invalid": touched.day && !!errors.day,
                                                            })}
                                                            disabled={disabled}
                                                        />
                                                    )}
                                                </Field>
                                            </Form.Group>
                                        </Row>
                                    </Form.Group>
                                )}
                                <Form.Group as={Col} md="6">
                                    <Form.Control
                                        name="email"
                                        placeholder={texts.generalemail}
                                        type="email"
                                        value={values.email}
                                        onChange={event => {
                                            handleChange(event);
                                            triggerOnChangeCallbackIfValid(event.target.name, event.target.value);
                                        }}
                                        onBlur={handleBlur}
                                        isValid={touched.email && !errors.email}
                                        isInvalid={touched.email && !!errors.email}
                                        disabled={disabled}
                                    ></Form.Control>
                                    {!isPrimaryGuest && (
                                        <Form.Text className="text-muted fw-light personal-details-information__optional">
                                            {texts["customer_form.optional"]}
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} md="6" className="mb-0">
                                    <Row>
                                        <Form.Group as={Col} xs="6">
                                            <Field>
                                                {({ form: { setFieldValue, setFieldTouched } }) => (
                                                    <PhoneCountryCodeSelect
                                                        name="phoneprefix"
                                                        placeholder={texts?.generalphoneprefix}
                                                        othersLabel="Other countries"
                                                        value={values.phoneprefix}
                                                        onChange={event => {
                                                            setFieldTouched("phoneprefix", true);
                                                            setFieldValue("phoneprefix", event.target.value);
                                                            if (validatePhonePrefix(event.target.value, values.phone)) {
                                                                onChange("phoneprefix", event.target.value);

                                                                if (validatePhone(event.target.value, values.phone)) {
                                                                    onChange("phone", values.phone);
                                                                } else {
                                                                    // We need to reset the phone number if it's not valid when changing the prefix
                                                                    // or else the old (now invalid) phone number may have been saved on the guest.
                                                                    onChange("phone", "");
                                                                }
                                                            }
                                                        }}
                                                        className={classnames("form-control", {
                                                            "is-valid": touched.phoneprefix && phonePrefixValid,
                                                            "is-invalid": touched.phoneprefix && !phonePrefixValid,
                                                        })}
                                                        disabled={disabled}
                                                    />
                                                )}
                                            </Field>
                                        </Form.Group>
                                        <Form.Group as={Col} xs="6">
                                            <Form.Control
                                                name="phone"
                                                placeholder={texts.generalphonenumber}
                                                type="tel"
                                                value={values.phone}
                                                onChange={event => {
                                                    if (isNumberWithoutDecimals(event.target.value)) {
                                                        handleChange(event);
                                                        if (validatePhone(values.phoneprefix, event.target.value)) {
                                                            onChange("phone", event.target.value);
                                                        }
                                                    }
                                                }}
                                                onBlur={handleBlur}
                                                isValid={touched.phone && phoneValid}
                                                isInvalid={touched.phone && !phoneValid}
                                                disabled={disabled}
                                            ></Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {texts.validationinvalidphonenumber}
                                            </Form.Control.Feedback>
                                            {!isPrimaryGuest && (
                                                <Form.Text className="text-muted fw-light personal-details-information__optional">
                                                    {texts.validationphonenumberrequiredononeguest}
                                                </Form.Text>
                                            )}
                                        </Form.Group>
                                    </Row>

                                    {isPrimaryGuest && (
                                        <Row className="personal-details-information__address-form-row">
                                            <Form.Group className="personal-details-information__address-form-group">
                                                <Form.Control
                                                    name="address"
                                                    placeholder={texts.generaladdress1}
                                                    type="text"
                                                    value={values.address}
                                                    onChange={event => {
                                                        handleChange(event);
                                                        triggerOnChangeCallbackIfValid(
                                                            event.target.name,
                                                            event.target.value
                                                        );
                                                    }}
                                                    onBlur={handleBlur}
                                                    isValid={touched.address && !errors.address}
                                                    isInvalid={touched.address && !!errors.address}
                                                    disabled={disabled}
                                                />
                                            </Form.Group>
                                            <Form.Group className="personal-details-information__address-form-group">
                                                <Form.Control
                                                    name="postalcode"
                                                    placeholder={texts.generalzipcode}
                                                    type="text"
                                                    value={values.postalcode}
                                                    onChange={event => {
                                                        handleChange(event);
                                                        triggerOnChangeCallbackIfValid(
                                                            event.target.name,
                                                            event.target.value
                                                        );
                                                    }}
                                                    onBlur={handleBlur}
                                                    isValid={touched.postalcode && !errors.postalcode}
                                                    isInvalid={touched.postalcode && !!errors.postalcode}
                                                    disabled={disabled}
                                                />
                                            </Form.Group>
                                            <Form.Group className="personal-details-information__address-form-group">
                                                <Form.Control
                                                    name="city"
                                                    placeholder={texts.generalcity}
                                                    type="text"
                                                    value={values.city}
                                                    onChange={event => {
                                                        handleChange(event);
                                                        triggerOnChangeCallbackIfValid(
                                                            event.target.name,
                                                            event.target.value
                                                        );
                                                    }}
                                                    onBlur={handleBlur}
                                                    isValid={touched.city && !errors.city}
                                                    isInvalid={touched.city && !!errors.city}
                                                    disabled={disabled}
                                                />
                                            </Form.Group>
                                            <Form.Group className="personal-details-information__address-form-group">
                                                <Form.Control
                                                    style={{
                                                        borderTopLeftRadius: "0px",
                                                        borderTopRightRadius: "0px",
                                                    }}
                                                    as="select"
                                                    name="country"
                                                    placeholder={texts.generalcountry}
                                                    type="text"
                                                    value={values.country}
                                                    onChange={event => {
                                                        handleChange(event);
                                                        triggerOnChangeCallbackIfValid(
                                                            event.target.name,
                                                            event.target.value
                                                        );
                                                    }}
                                                    onBlur={handleBlur}
                                                    isValid={touched.country && !errors.country}
                                                    isInvalid={touched.country && !!errors.country}
                                                    disabled={disabled}
                                                >
                                                    <option disabled value="">
                                                        {texts.generalcountry}
                                                    </option>
                                                    {countries &&
                                                        countries.map(country => {
                                                            return (
                                                                <option key={country.code} value={country.code}>
                                                                    {country.name}
                                                                </option>
                                                            );
                                                        })}
                                                </Form.Control>
                                            </Form.Group>
                                        </Row>
                                    )}
                                </Form.Group>
                                {showSubmitButton && (
                                    <Form.Group
                                        as={Col}
                                        md="6"
                                        className="text-end personal-details-information__buttons"
                                    >
                                        <Button
                                            onClick={() => {
                                                formikRef.current.resetForm();
                                                onCancel();
                                            }}
                                            variant="link"
                                            className="fw-light text-primary personal-details-information__buttons-cancel"
                                        >
                                            {texts["customer_form.cancel"]}
                                        </Button>
                                        <Button
                                            type="submit"
                                            className="button personal-details-information__buttons-submit ms-2"
                                        >
                                            {texts["customer_form.add"]}
                                        </Button>
                                    </Form.Group>
                                )}
                            </Row>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
});

PersonalDetailsInformation.displayName = "PersonalDetailsInformation";

export default PersonalDetailsInformation;
