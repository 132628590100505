import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { resetCart } from "../../store/actions/checkoutCart";
import { resetGuests } from "../../store/actions/guests";
import { resetReservation } from "../../store/actions/reservation";
import { TReservationTypeLid } from "../../store/types";
import { Button, ConfirmationModal, Heading } from "@r360/library";
import PageContainer from "../PageContainer";
import useTranslate from "../../hooks/useTranslate";
import useAppSelector from "../../hooks/useAppSelector";

const ChangeReservationBar = ({ reservationId }: { reservationId: TReservationTypeLid }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const t = useTranslate();
    const location = useLocation();
    const { isMobile, isIframe, isDesktop, iframeOffsetTop, isTablet } = useAppSelector(state => state.window);
    const [openCancelChangeReservationModal, setOpenCancelChangeReservationModal] = useState(false);

    const cancelChangeReservation = () => {
        const navigateToStart =
            ["/checkout", "/checkout/summary", "/reservation/extras", "/cart"].includes(location?.pathname) ||
            location?.pathname.startsWith("/pay/") ||
            location?.pathname.startsWith("/product-extras/");

        if (navigateToStart) {
            navigateToStart && navigate("/", { replace: true });
        }

        dispatch(resetCart());
        dispatch(resetReservation());
        dispatch(resetGuests());
    };

    return (
        <>
            <div className="u-bg-yellow">
                <PageContainer minHeight={false} className="u-pt-18 u-pb-18">
                    <div className="u-d-flex u-justify-content-between u-justify-content-md-center u-align-items-center u-gap-24">
                        <Heading type="h2" styleAs="default-highlighted" className="u-mb-0">
                            {t("book.change_reservation.change_for_booking", [reservationId])}
                        </Heading>
                        <Button type="tertiary" onClick={() => setOpenCancelChangeReservationModal(true)}>
                            {t("book.change_reservation.cancel")}
                        </Button>
                    </div>
                </PageContainer>
            </div>
            <ConfirmationModal
                open={openCancelChangeReservationModal}
                heading={t("book.change_reservation.cancel")}
                description={t("book.change_reservation.cancel_and_empty_cart")}
                confirmBtnText={t("book.general.continue")}
                cancelBtnText={t("book.general.cancel")}
                removing={true}
                onClose={() => setOpenCancelChangeReservationModal(false)}
                onConfirmClick={cancelChangeReservation}
                onCancelClick={() => setOpenCancelChangeReservationModal(false)}
                {...(isIframe && isDesktop && { fromTop: 120 + iframeOffsetTop + "px" })}
                {...(isIframe &&
                    (isMobile || isTablet) && {
                        fromTop: 80 + iframeOffsetTop + "px",
                    })}
            />
        </>
    );
};

export default ChangeReservationBar;
