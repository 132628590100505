import { Button, ErrorMessage, Heading } from "@r360/library";
import React, { useEffect, useState } from "react";
import { RootState } from "../..";
import { sortBySortorder, sortGroups, sortSkipassItems } from "../../BusinessUtils";
import { SkipassDesktop } from "../../assets/reservationTypes/Skipass";
import GroupIncrementerCard from "../../components/GroupIncrementerCard";
import { GroupIncrementerCardSkeleton } from "../../components/GroupIncrementerCard/GroupIncrementerCardSkeleton";
import { NoSearchResult } from "../../components/NoSearchResult/NoSearchResult";
import { ReservationTypeHeader } from "../../components/ReservationTypeHeader/ReservationTypeHeader";
import { ReservationTypeImage } from "../../components/ReservationTypeImage/ReservationTypeImage";
import { newFilterGroupItems } from "../../hooks";
import useAppSelector from "../../hooks/useAppSelector";
import useChangeFilter from "../../hooks/useChangeFilter";
import useChangeSearch from "../../hooks/useChangeSearch";
import { useProductsNotAddedPath } from "../../hooks/useProductsNotAddedPath";
import useTranslate from "../../hooks/useTranslate";
import { agesSelector, cartProductsSelector, resvTypeByLid } from "../../selectors/Selectors";
import {
    TCriteriaItem,
    TGroup,
    TItem,
    TReservationResult,
    TReservationType,
    TReservationTypeLid,
} from "../../store/types";
import { Col } from "react-bootstrap";
import "./SkipassPage.scss";
import { useNavigate } from "react-router-dom";
import { RichText } from "../../components/UI";

type TSkipassPageProps = {
    reservationTypeid: TReservationTypeLid;
};

export const SkipassPage = ({ reservationTypeid }: TSkipassPageProps) => {
    const filter = useAppSelector((state: RootState) => state.filter);
    const activeFilters = filter[reservationTypeid];
    const { requestsLoading } = useAppSelector((state: RootState) => state.axiosStatus);
    const isLoading = requestsLoading["fetchReservationTypeResults"];
    const clientData = useAppSelector((state: RootState) => state.clientData);
    const reservationTypes: TReservationType[] = clientData.reservationtypes;
    const skipassResult: TReservationResult = useAppSelector(
        (state: RootState) => state.reservationResult[reservationTypeid]
    );
    const cartProducts = useAppSelector(cartProductsSelector);
    const generalAges = useAppSelector((state: RootState) => agesSelector(state));
    const [filteredData, setFilteredData] = useState<TGroup[]>([]);
    const t = useTranslate();
    const [addToCartDataQuantity, setAddToCartDataQuantity] = useState<{ [key: number]: number }>({});
    const addToCartTotalQuantity = Object.values(addToCartDataQuantity).reduce((acc, item) => acc + item, 0);
    const reservationType: TReservationType | undefined = useAppSelector((state: RootState) =>
        resvTypeByLid(state, reservationTypeid)
    );
    const reservationTypeCriterias = reservationType?.criterias || {};
    const showProductsNotAddedWarning = !!addToCartTotalQuantity;
    const navigate = useNavigate();

    const accommodationsWithConnectedSkipasses = reservationTypes.filter(
        resType => resType.useSkipassExtra && resType.skipassExtraReservationTypeId
    );
    const cartProductsWithSkipassExtra = cartProducts.filter(
        p => p.type === reservationTypes?.find(r => r.useSkipassExtra)?.lid
    );

    const navigateToExtrasPageOrCart = () => {
        if (cartProductsWithSkipassExtra.length === 1) {
            navigate(
                `/product-extras/${cartProductsWithSkipassExtra[0].type}/${cartProductsWithSkipassExtra[0].grouppoollid}/${cartProductsWithSkipassExtra[0].poollid}/${cartProductsWithSkipassExtra[0].unitlid}/${cartProductsWithSkipassExtra[0].id}`
            );
        } else if (cartProductsWithSkipassExtra.length > 1) {
            navigate("/cart");
        }
    };

    useChangeSearch({
        reservationResultSearch: skipassResult?.search,
        reservationTypeId: reservationTypeid,
        skipGuestsOnSearchWithPrice: true,
        filterOnDays: false,
    });

    useChangeFilter(reservationTypeid);
    useProductsNotAddedPath(showProductsNotAddedWarning);

    // Clear addToCartData when a new search has been made
    useEffect(() => {
        setAddToCartDataQuantity({});
    }, [skipassResult?.resultSetId]);

    if (reservationTypeid) {
        if (!(reservationTypeid in generalAges)) {
            throw new Error(`Reservation type ${reservationTypeid} does not have any ages configured in general ages`);
        }
    }

    useEffect(() => {
        const criterias = Object.values(reservationTypeCriterias)
            .flat()
            .reduce((acc, curr) => {
                acc[curr.code] = curr;
                return acc;
            }, {} as { [key: number]: TCriteriaItem });

        let filteredData = Object.values(skipassResult?.groups || {})
            .map(group => ({
                ...group,
                items: group.items.filter((item: TItem) => {
                    return item.category !== "keycard";
                }),
            }))
            .map((group: TGroup) => {
                return newFilterGroupItems(group, activeFilters || {}, criterias);
            })
            .filter(group => !!group.items.length);

        // Sort skipass groups and items.
        filteredData = sortGroups(filteredData).map(group => {
            group.items = sortSkipassItems(group.items);
            return group;
        });

        setFilteredData(filteredData);
    }, [skipassResult?.groups, activeFilters]);

    const renderSkipassList = () => {
        if (isLoading) {
            return [...Array(4)].map((_, i) => <GroupIncrementerCardSkeleton key={i} />);
        } else if (filteredData.length > 0) {
            return filteredData.map(({ groupitem, items }) => {
                return (
                    <GroupIncrementerCard
                        key={items[0].id}
                        category={groupitem?.category}
                        title={groupitem?.title || ""}
                        description={groupitem?.weblong || ""}
                        days={items.map(item => item.days)}
                        promotion={items[0].promotionCode !== null}
                        thumbnailUrl={groupitem?.images[0]?.url}
                        items={sortBySortorder(items).slice(0, 5)}
                        fallbackImage={SkipassDesktop}
                        onAddToCartQuantityChange={data =>
                            setAddToCartDataQuantity(prevState => {
                                if (prevState[items[0].id] === data) {
                                    return prevState;
                                }

                                return { ...prevState, [items[0].id]: data };
                            })
                        }
                    />
                );
            });
        } else if ((skipassResult?.hitsMatchingFilter === "0" || !filteredData.length) && !isLoading) {
            return (
                <div className="u-pt-30">
                    <NoSearchResult />
                </div>
            );
        } else if (skipassResult?.error && !isLoading) {
            return (
                <div className="u-pt-24">
                    <ErrorMessage
                        headingText={t("book.general.error")}
                        primaryButtonText={t("book.general.reload_page")}
                        primaryButtonOnClick={() => window.location.reload()}
                    />
                </div>
            );
        }
    };

    const showFilter =
        skipassResult?.groups &&
        reservationTypeCriterias &&
        Object.values(skipassResult.groups).length > 0 &&
        Object.values(reservationTypeCriterias).flat().length > 0;

    return skipassResult || isLoading ? (
        <>
            {/* <ProductsNotAddedToCartModal readyToShow={showProductsNotAddedWarning} /> */}
            <ReservationTypeHeader
                reservationTypeId={reservationTypeid}
                hits={filteredData.length}
                filtersAvailable={showFilter}
            />
            {accommodationsWithConnectedSkipasses.length > 0 && cartProductsWithSkipassExtra.length < 1 && (
                <div>
                    <Col md={6} className="skipass-extra-promotion">
                        <Heading type="h3">{t("book.skipass_extra.promotion_header")}</Heading>
                        <span>
                            {t(
                                "book.skipass_extra.promotion_body",
                                accommodationsWithConnectedSkipasses.map(item => {
                                    return item.description;
                                })
                            )}
                        </span>
                    </Col>
                </div>
            )}
            {cartProductsWithSkipassExtra.length > 0 && (
                <div>
                    <Col md={6} className="skipass-extra-promotion">
                        <Heading type="h3">{t("book.skipass_extra.promotion_header_in_cart")}</Heading>
                        <p>
                            {cartProductsWithSkipassExtra.length === 1 ? (
                                <RichText content={t("book.skipass_extra.promotion_body_in_cart_one")} />
                            ) : (
                                <RichText content={t("book.skipass_extra.promotion_body_in_cart")} />
                            )}
                        </p>
                        <Button type="primary" fullWidth onClick={navigateToExtrasPageOrCart}>
                            {cartProductsWithSkipassExtra.length > 1
                                ? t("book.general.go_to_cart")
                                : `${t("book.general.go_to")} ${cartProductsWithSkipassExtra[0].title}`}
                        </Button>
                    </Col>
                </div>
            )}
            {renderSkipassList()}
        </>
    ) : (
        <ReservationTypeImage reservationTypeId={reservationTypeid} />
    );
};
