import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProductView from "../../ProductView/ProductView";
import {
    addProductWithCount,
    updateProductWithCount,
    removeProduct,
    setLatestChangedProductType,
} from "../../../store/actions/checkoutCart";
import { formatPrice } from "../../../Helper";
import ItemCapacity from "../../ItemCapacity/ItemCapacity";
import { Incrementer, Button, Tag, Modal, ResponsiveImage } from "../../UI";
import { RentalBike as RentalBikeImage } from "../../../assets/reservationTypes";
import ItemFilterButton from "../../ItemFilterButton/ItemFilterButton";
import "./BikeItem.scss";
import { textsSelector } from "../../../Selectors";

const BikeItem = props => {
    const {
        title,
        weblong,
        available,
        thumbnail,
        price,
        index,
        poollid,
        unitlid,
        arrdate,
        category,
        occations,
        type,
        crits,
    } = props.item;

    const [showModal, setShowModal] = useState(false);
    const [count, setCount] = useState(0);
    const dispatch = useDispatch();
    const currency = useSelector(state => state.clientData?.options?.general?.currency);
    const texts = useSelector(textsSelector);
    const productInCart = props.cartProducts?.find(product => {
        const productId =
            product.poollid +
            product.unitlid +
            product.arrdate +
            product.price +
            (product.category || btoa(JSON.stringify(product.occations)));
        const propsId = poollid + unitlid + arrdate + price + (category || btoa(JSON.stringify(occations)));
        return productId === propsId;
    });
    // Set count value if product is present in cart
    useEffect(() => {
        setCount(productInCart?.count);
    }, [productInCart]);

    // Expand accordion in OrderOverview.
    const expandOrderOverviewAccordion = () => {
        dispatch(setLatestChangedProductType(parseInt(type)));
    };

    // Handle click on more info button
    const handleClick = () => {
        setShowModal(!showModal);
    };

    // Increment count
    const incrementCount = () => {
        setCount(count + 1);
        dispatch(addProductWithCount(props.item));
        expandOrderOverviewAccordion();
    };
    // Set item count
    const setItemCount = response => {
        if (productInCart) {
            if (response.value === 0) {
                dispatch(removeProduct(productInCart));
            } else {
                dispatch(updateProductWithCount(productInCart, response.value));
            }
        }
        setCount(response.value);
        expandOrderOverviewAccordion();
    };

    const RentingInput = () => {
        if (count > 0) {
            return (
                <div className="rental-item__incrementer">
                    <Incrementer label={title} value={count} maxValue={available} onChange={setItemCount} size={8} />
                </div>
            );
        }
        return <Button text={texts?.generaladd} onClick={incrementCount} className="rental-item__booking-button" />;
    };

    return (
        <div className="rental-item__wrapper col-sm-12 col-md-6 col-lg-4">
            <div className="rental-item">
                <div className="rental-item__image-container">
                    <ResponsiveImage
                        height={204}
                        src={thumbnail?.url}
                        placeholder={thumbnail?.url ? undefined : RentalBikeImage}
                        className="rental-item__image"
                    />
                    {props.promoCode && (
                        <div className="rental-item__promo-tag-container">
                            <Tag type="small" className="rental-item__promo-tag">
                                {texts?.promocodeincluded}
                            </Tag>
                        </div>
                    )}
                </div>
                <div className="rental-item__body">
                    <div className="tag__container">
                        <ItemCapacity available={available} texts={texts} />
                    </div>
                    <h2 className="h5 rental-item__header">{title}</h2>
                    <div className="rental-item-container">
                        <ItemFilterButton crits={crits} type={type} />
                        <Button
                            id={`rental-more-information-${index}`}
                            text={texts?.moreinformation}
                            className="rental-item__modal-button"
                            onClick={handleClick}
                            hasPopup={true}
                            expanded={showModal}
                            disabled={!weblong}
                        />
                        <RentingInput />
                    </div>
                    <div className="rental-item__price">{formatPrice(price, currency)}</div>
                </div>
            </div>
            <Modal
                show={showModal}
                setShow={setShowModal}
                labelledBy={`rental-more-information-${props.index}`}
                className="rental-item__modal"
                id={`rental-more-information-modal-${props.index}`}
            >
                <ProductView weblong={weblong} title={title} image={thumbnail || RentalBikeImage} texts={texts} />
            </Modal>
        </div>
    );
};

export default BikeItem;
