// eslint-disable-next-line
import React from "react";
import { useDispatch } from "react-redux";
import * as types from "../../store/actions/types";

const LogoutPage = () => {
    const dispatch = useDispatch();
    dispatch({ type: types.DESTORY_STATE });
    // navigate("/");

    return;
};

export default LogoutPage;
