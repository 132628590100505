import React from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setCart } from "../store/actions/checkoutCart";

const hijackCart = dispatch => {
    let cartUUID = prompt("Specify cart UUID");
    let correlationId = prompt("Also specify cart correlation id");

    if (cartUUID && correlationId) {
        dispatch(
            setCart({
                correlation_id: correlationId,
                id: cartUUID,
            })
        );

        alert("Cart ID's saved to state");
    }
};

const DeveloperPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    if (!window.__DEBUG__) {
        navigate("/", { replace: true });
    }

    return (
        <div className="container">
            <h1>Developer</h1>
            <p>
                <button className="btn btn-primary" onClick={() => hijackCart(dispatch)}>
                    Hijack cart
                </button>
                <Link to={`/checkout/summary`} className="btn btn-primary">
                    Checkout Summary
                </Link>
            </p>
        </div>
    );
};

export default DeveloperPage;
