import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Form, Button, Row } from "react-bootstrap";
import { updateProduct } from "../../../store/actions/checkoutCart";
import { InformationBlock } from "../../UI";
import confirmationDialog from "../../UI/ConfirmationDialog";
import { CalendarIcon, GuestIcon } from "../../UI/R360Icons";
import { getGuestNameAndAge, getPackageProductTitle } from "../../../Helper";
import moment from "moment-timezone";

import "./Accommodation.scss";
import { agesSelector, guestsOnTravelSelector, optionsSelector } from "../../../selectors/Selectors";
import groupBy from "lodash/groupBy";
import isEqual from "lodash/isEqual";
import { useNavigate } from "react-router";
import { isBooked, showArrDepDates } from "../../../BusinessUtils";

const Accommodation = props => {
    const dispatch = useDispatch();
    const { products, type, texts, removeProductFromCart } = props;
    const navigate = useNavigate();
    const accommodations = products.filter(product => parseInt(product.type, 10) === parseInt(type.lid, 10));
    const guestsOnTravel = useSelector(guestsOnTravelSelector);
    const generalAges = useSelector(agesSelector);
    const userToken = useSelector(state => state.account.token);
    const checkoutCartId = useSelector(state => state.checkoutCart.cartId);
    const skipGuestsOnAccommodation = useSelector(optionsSelector).reservation?.skipguestsonaccommodation || false;

    /**
     * Check that all guests connected to accommodations should be on the travel.
     * If not, remove them from the accommodation.
     */
    useEffect(() => {
        const guestIds = Object.keys(guestsOnTravel).map(guestId => parseInt(guestId));

        for (const accommodation of accommodations) {
            if (!accommodation.guests) {
                continue;
            }

            const accommodationGuests = { ...accommodation.guests };

            for (const [index, accommodationGuestId] of Object.entries(accommodationGuests)) {
                if (!guestIds.includes(accommodationGuestId)) {
                    delete accommodationGuests[index];
                }
            }

            if (!isEqual(accommodation.guests, accommodationGuests)) {
                dispatch(
                    updateProduct(accommodation, {
                        key: "guests",
                        value: accommodationGuests,
                    })
                );
            }
        }
    }, [dispatch, accommodations, guestsOnTravel]);

    /**
     * Get ages for a reservation type.
     *
     * @param {string} reservationTypeId The reservation type ID.
     *
     * @returns {Object[]} A list of ages objects.
     */
    const getAgesForReservationType = reservationTypeId => {
        if (!generalAges[reservationTypeId]) {
            throw new Error(`Reservation type ${reservationTypeId} does not have any ages configured in general ages`);
        }

        return generalAges[reservationTypeId];
    };

    /**
     * Get a list of ages for an accommodation sorted by descending min age.
     *
     * E.g. if guests for accommodation is { youth: 2, adult: 1, child: 1 }, this is returned:
     * [{ key: "adult", ...} , { key: "youth", ...}, { key: "youth", ...}, { key: "child", ...}]
     *
     * @param {Object} accommodation The accommodation product from cart.
     *
     * @returns {Object[]} A list of ages for an accommodation sorted by descending min age.
     */
    const getSortedAgesForAccommodation = accommodation => {
        const ages = getAgesForReservationType(accommodation.type);
        const guestCounts = accommodation.info.guests;
        const totalGuestsCount = accommodation.info.guests.totalGuests;

        // Sort age categories by descending min age.
        const sortedAges = ages.sort((a, b) => b.min - a.min);

        let selectsByAge = [];

        sortedAges.forEach(age => {
            if (guestCounts[age.key] > 0) {
                for (let i = 0; i < guestCounts[age.key]; i++) {
                    selectsByAge.push(age);
                }
            }
        });

        if (!isBooked(accommodation) && selectsByAge.length !== totalGuestsCount) {
            throw new Error(
                `Guest count doesn't match when comparing total guests and guest count per age category for accommodation ${accommodation.lid}`
            );
        }

        return selectsByAge;
    };

    /**
     * Check if a guest can be added to an accommodation slot based on age and other assigned
     * accommodations.
     *
     * @param {Object} guest
     * @param {Object} accommodation
     * @param {number} dropdownIndex
     * @param {Object} age
     *
     * @returns {boolean} True if the guest can be added to the slot, otherwise false.
     */
    const getCanGuestBeAddedToAccommodationSlot = (guest, accommodation, dropdownIndex, age) => {
        const arrDate = moment(accommodation.arrdate);
        const depDate = moment(accommodation.depdate);

        // Check that the guest is the correct age on arrival date.
        if (!guest.birthday) {
            return false;
        }

        const guestAgeAtArrivalDate = arrDate.diff(moment(guest.birthday), "years");

        if (guestAgeAtArrivalDate < age.min || guestAgeAtArrivalDate > age.max) {
            return false;
        }

        // Check if the guest is already assigned to another spot on the current accommodation.
        const guestIsAlreadyAssignedToOtherSpotAtCurrentAccommodation = Object.entries(accommodation.guests || {}).some(
            ([index, accommodationGuestId]) => {
                if (accommodationGuestId === guest.id && parseInt(index) !== dropdownIndex) {
                    return true;
                }

                return false;
            }
        );

        if (guestIsAlreadyAssignedToOtherSpotAtCurrentAccommodation) {
            return false;
        }

        // Check if the guest is assigned to any other accommodations during the same period.
        const otherAccommodations = accommodations.filter(
            filterAccommodation => filterAccommodation.cartItemId !== accommodation.cartItemId
        );

        const guestIsAlreadyAssignedToOtherAccommodationForPeriod = otherAccommodations.some(otherAccommodation => {
            if (Object.values(otherAccommodation.guests || {}).includes(guest.id)) {
                const assignedArrDate = moment(otherAccommodation.arrdate);
                const assignedDepDate = moment(otherAccommodation.depdate);

                if (
                    arrDate.isBetween(assignedArrDate, assignedDepDate, undefined, "[)") ||
                    depDate.isBetween(assignedArrDate, assignedDepDate, undefined, "(]")
                ) {
                    return true;
                }
            }

            return false;
        });

        if (guestIsAlreadyAssignedToOtherAccommodationForPeriod) {
            return false;
        }

        return true;
    };

    /**
     * Assign a guest to an accommodation slot.
     *
     * @param {Object} accommodation
     * @param {number} dropdownIndex
     * @param {number} guestId
     */
    const assignGuestToAccommodation = (accommodation, dropdownIndex, guestId) => {
        const accommodationGuests = { ...accommodation.guests };

        // If not guest is provided, remove guest is assigned to the provided index.
        if (guestId === "") {
            delete accommodationGuests[dropdownIndex];
        } else {
            const guestIdNumber = parseInt(guestId);

            if (Object.values(accommodationGuests).includes(guestIdNumber)) {
                return;
            }

            accommodationGuests[dropdownIndex] = guestIdNumber;
        }

        dispatch(
            updateProduct(accommodation, {
                key: "guests",
                value: accommodationGuests,
            })
        );
    };

    /**
     * Open a confirmation dialog and if the user confirms, remove the accommodation from cart and
     * navigate to the accommodation product page.
     *
     * @param {Object} accommodation
     */
    const openChangeAccommodationConfirmation = async accommodation => {
        let description = texts["checkout.accommodation.change_confirmation.description"];

        const hasConnectedProducts = products.some(item => item.connectedProductId === accommodation.cartItemId);

        if (hasConnectedProducts) {
            description +=
                "\n\n" + texts["checkout.accommodation.change_confirmation.description_has_connected_products"] || "";
        }

        const response = await confirmationDialog({
            title: texts["checkout.accommodation.change_confirmation.title"],
            description: description,
            okLabel: texts["general.confirmation.continue"],
            cancelLabel: texts["general.confirmation.cancel"],
        });

        if (response) {
            const { type, grouppoollid } = accommodation;

            removeProductFromCart(accommodation);

            navigate(`/product/${type}/${grouppoollid}`);
        }
    };

    /**
     * Open a confirmation dialog and if the user confirms, remove the accommodation from cart
     *
     * @param {object} accommodation
     */
    const openRemoveAccommodationConfirmation = async accommodation => {
        let description = texts["checkout.accommodation.remove_confirmation.description"];

        const hasConnectedProducts = products.some(item => item.connectedProductId === accommodation.cartItemId);

        if (hasConnectedProducts) {
            description +=
                "\n\n" + texts["checkout.accommodation.remove_confirmation.description_has_connected_products"] || "";
        }

        const response = await confirmationDialog({
            title: texts["checkout.accommodation.remove_confirmation.title"],
            description: description,
            okLabel: texts["general.confirmation.continue"],
            cancelLabel: texts["general.confirmation.cancel"],
        });

        if (response) {
            removeProductFromCart(accommodation);
        }
    };

    const renderGuestAgesForAccommodation = accommodation => {
        const sortedAges = getSortedAgesForAccommodation(accommodation);

        const groupedAges = sortedAges.reduce((acc, currentAge) => {
            const currentAgeIndexOfAcc = acc.findIndex(age => age.key === currentAge.key);

            if (currentAgeIndexOfAcc === -1) {
                currentAge.guestCount = 1;
                acc.push(currentAge);
            } else {
                acc[currentAgeIndexOfAcc].guestCount++;
            }

            return acc;
        }, []);

        return (
            <>
                {groupedAges.map((age, index) => (
                    <React.Fragment key={index}>
                        <div className="accommodation-fill-out__item-guest">
                            <GuestIcon size={16} color="#333333" iconClass="accommodation-fill-out__item-guest-icon" />
                            {`${age.guestCount} ${texts?.[age.title]}`}
                        </div>
                    </React.Fragment>
                ))}
            </>
        );
    };

    const renderGuestCountForAccommodation = accommodation => {
        return (
            <div className="accommodation-fill-out__item-guest">
                <GuestIcon size={16} color="#333333" iconClass="accommodation-fill-out__item-guest-icon" />
                {accommodation.info.guests.totalGuests === 1
                    ? `${accommodation.info.guests.totalGuests} ${texts?.["generalguest"]}`
                    : `${accommodation.info.guests.totalGuests} ${texts?.["generalguests"]}`}
            </div>
        );
    };

    /**
     * Render the select fields for an accommodation.
     *
     * @param {Object} accommodation
     */
    const renderGuestSelectsForAccommodation = accommodation => {
        const sortedAges = getSortedAgesForAccommodation(accommodation);
        const accommodationGuests = accommodation.guests || {};

        return (
            <>
                {sortedAges.map((age, dropdownIndex) => (
                    <Form.Group as={Col} md="3" key={Math.random()}>
                        <Form.Control
                            required
                            as="select"
                            name="guestId"
                            autoComplete="off"
                            defaultValue={(accommodationGuests[dropdownIndex] || "").toString()}
                            onChange={event =>
                                assignGuestToAccommodation(accommodation, dropdownIndex, event.target.value)
                            }
                        >
                            <option value="">{texts?.[age.title]} *</option>
                            {Object.entries(guestsOnTravel)
                                .filter(([, guest]) =>
                                    getCanGuestBeAddedToAccommodationSlot(guest, accommodation, dropdownIndex, age)
                                )
                                .map(([guestId, guest]) => {
                                    return (
                                        <option key={guestId} value={guestId}>
                                            {getGuestNameAndAge(guest, texts?.generalyearshort, accommodation)}
                                        </option>
                                    );
                                })}
                        </Form.Control>
                    </Form.Group>
                ))}
            </>
        );
    };

    /**
     * Render all accommodations.
     */
    const renderAccommodations = () => {
        const sortedAccommodations = accommodations.sort((a, b) => moment(a.arrdate).unix() - moment(b.arrdate).unix());
        const groupedAndSortedAccommodations = groupBy(sortedAccommodations, "groupTitle");

        return (
            <div>
                {Object.entries(groupedAndSortedAccommodations).map(([groupTitle, accommodations]) => (
                    <div key={groupTitle} className="accommodation-fill-out__group">
                        <h4 className="m-0">{groupTitle}</h4>

                        {accommodations.map(accommodation => (
                            <div
                                key={accommodation.cartItemId || accommodation.id}
                                className="accommodation-fill-out__item"
                            >
                                <Row>
                                    <Form.Group as={Col} md="auto">
                                        <h5 className="m-0">{getPackageProductTitle(accommodation)}</h5>
                                        <span className="accommodation-fill-out__item-date">
                                            <CalendarIcon
                                                size={16}
                                                color="#333333"
                                                iconClass="accommodation-fill-out__item-date-icon"
                                            />
                                            {showArrDepDates(accommodation)}
                                        </span>

                                        {skipGuestsOnAccommodation &&
                                            isBooked(accommodation) &&
                                            renderGuestCountForAccommodation(accommodation)}

                                        {skipGuestsOnAccommodation &&
                                            !isBooked(accommodation) &&
                                            renderGuestAgesForAccommodation(accommodation)}
                                    </Form.Group>
                                </Row>

                                {!skipGuestsOnAccommodation && !isBooked(accommodation) && (
                                    <Row>{renderGuestSelectsForAccommodation(accommodation)}</Row>
                                )}

                                {!isBooked(accommodation) && ( // When changing a reservation we won't allow accommodation to be removed or changed
                                    <Row>
                                        <Col>
                                            <Button
                                                className="skiRental__button p-0 text-danger"
                                                variant="link"
                                                onClick={() => openRemoveAccommodationConfirmation(accommodation)}
                                            >
                                                {texts?.generalremove}
                                            </Button>

                                            <Button
                                                onClick={() => openChangeAccommodationConfirmation(accommodation)}
                                                variant="link"
                                                size="md"
                                                className="fw-light text-primary p-0 ms-4"
                                            >
                                                {texts["checkout.accommodation.buttons.change"]}
                                            </Button>
                                        </Col>
                                    </Row>
                                )}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        );
    };

    // Make sure the required data from the state has been fetched.
    if (!guestsOnTravel || !generalAges || !userToken || !checkoutCartId) {
        return <></>;
    }

    return (
        <div className="pt-4" data-component="Accommodation">
            {!skipGuestsOnAccommodation && (
                <InformationBlock className="mb-4">{texts?.missingguestinformation}</InformationBlock>
            )}

            {renderAccommodations()}
        </div>
    );
};

export default Accommodation;
