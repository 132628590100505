import PropTypes from "prop-types";

/**
 * Custom prop type for filters/criterias
 */
export const FilterPropType = PropTypes.shape({
    // Required
    code: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    typeunit: PropTypes.string.isRequired,
    displayAs: PropTypes.oneOf(["range", "bool", "dropdown", "buttons", "multicheck"]).isRequired,
    type: PropTypes.oneOf(["MIN", "MAX", "BOOL", "ENUM"]).isRequired,
    enums: PropTypes.array.isRequired,

    // Optional
    min: PropTypes.number,
    max: PropTypes.number,
    values: PropTypes.array,
});

export const RangePropType = PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
});
