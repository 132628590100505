import * as types from "../actions/types";
import { resetLastSearchedTimestamps } from "./search";
import AxiosClient from "../../API/AxiosClient";
import { hasDatesChanged } from "../../Helper";
import isEqual from "lodash/isEqual";

export const setDate = date => {
    return (dispatch, getState) => {
        // See if dates has changed, and if any date has changed we will reset the last searched timestamps
        const state = getState();
        const prevDate = state.filter.date;
        const resetTimestamps = hasDatesChanged(date.startDate, date.endDate, prevDate.startDate, prevDate.endDate);

        if (resetTimestamps) {
            dispatch(resetLastSearchedTimestamps());
        }

        dispatch({
            type: types.SET_DATE,
            date: date,
        });
    };
};

export const setPerson = person => {
    return {
        type: types.SET_PERSON,
        person: person,
    };
};

export const setFilter = (filter, resvtype) => {
    return {
        type: types.SET_FILTER,
        filter,
        resvtype,
    };
};

export const setFilters = (filters, resvtype) => {
    return dispatch => {
        dispatch({
            type: types.SET_FILTERS,
            filters,
            resvtype,
        });
    };
};

/**
 * Legacy setFilters which supports the old design with option layout/new_design_accommodation = false.
 *
 * Throw this away when everyone is using the new design :)
 */
export const setFiltersLegacy = (filters, resvtype) => {
    return (dispatch, getState) => {
        const state = getState();
        const criterias = state.filter?.filterCriterias?.[resvtype];

        if (!criterias) {
            return;
        }

        // Check that all filters trying to be set exists in filterCriterias and matches the restrictions.
        filters = Object.fromEntries(
            Object.entries(filters).filter(([code, value]) => {
                const critera = criterias[code];

                switch (critera?.type) {
                    case "BOOL":
                        // Value should always be boolean true.
                        return value === true;

                    case "MIN":
                    case "MAX":
                        // Value can be either a number or an object with min and max set.
                        // If a number, convert to object with min and max before validation.
                        value = typeof value === "object" ? value : { min: value, max: value };
                        return value.min <= value.max && value.min >= critera.min && value.max <= critera.max;

                    case "ENUM":
                        // Value is a list of enum codes. All codes need to be valid, otherwise skip the filter.
                        return Array.isArray(value) && value.every(enumCode => critera.enums?.[enumCode]);

                    default:
                        return false;
                }
            })
        );

        dispatch({
            type: types.SET_FILTERS,
            filters,
            resvtype,
        });
    };
};

export const removeFilter = (activeFilter, resvtype) => {
    return {
        type: types.REMOVE_FILTER,
        activeFilter,
        resvtype,
    };
};

export const removeFilters = resvtype => {
    return {
        type: types.REMOVE_FILTERS,
        resvtype,
    };
};

export const clearFilters = resvtype => {
    return {
        type: types.CLEAR_FILTERS,
        resvtype,
    };
};

export const setReservationTypeFilters = (filters, resvtype) => {
    return {
        type: types.SET_RESERVATIONTYPE_FILTERS,
        filters: filters,
        resvtype: resvtype,
    };
};

export const setPromoCode = (code, resvType) => {
    return dispatch => {
        if (!code) {
            // If the code is falsy, there is no need to verify it against the API. Just remove it.
            dispatch(setPromoCodeSuccess(""));
            dispatch(resetLastSearchedTimestamps());
        } else {
            AxiosClient.post("/promotions", { promoCode: code })
                .then(() => {
                    if (resvType) {
                        dispatch(setPromoCodeSuccess(code, resvType));
                    } else {
                        dispatch(setPromoCodeSuccess(code));
                    }
                    dispatch(resetLastSearchedTimestamps());
                })
                .catch(error => {
                    if (resvType) {
                        dispatch(resetPromoCode(resvType));
                    } else {
                        dispatch(resetPromoCode());
                    }
                    dispatch(setPromoCodeError(error, resvType));
                });
        }
    };

    //
};

export const resetPromoCode = (resvType = undefined) => {
    return {
        type: types.RESET_PROMO_CODE,
        resvType: resvType,
    };
};

export const setPromoCodeSuccess = (code, resvType) => {
    return {
        type: types.SET_PROMO_CODE,
        code: code,
        resvType: resvType,
    };
};

export const setPromoCodeError = (error, resvType) => {
    return {
        type: types.SET_PROMO_CODE_ERROR,
        error: error,
        resvType: resvType,
    };
};

/*
  Use to increase guest count on filter search
*/
export const incrementGuestCount = guestKey => {
    return {
        type: types.INCREMENT_GUEST_COUNT,
        guestKey: guestKey,
    };
};

/*
  Use to decremtn guest count on filter search
*/
export const decrementGuestCount = guestKey => {
    return {
        type: types.DECREMENT_GUEST_COUNT,
        guestKey: guestKey,
    };
};

/*
  Use to create guest count filter keys 
*/
export const createGuestKey = key => {
    return {
        type: types.CREATE_GUEST_KEY,
        guestKey: key,
    };
};

/*
  Use to update filter value 
  This is an important trigger that we sometimes may want to trigger a new search
*/
export const updateFilter = (key, value, addTototalCount) => {
    const action = {
        type: types.UPDATE_FILTER,
        key: key,
        value: value,
        addTototalCount: addTototalCount,
    };

    // When we update guestAges we need to reset last searched timestamps if any of the ages have changed
    if (key === "guestAges") {
        return (dispatch, getState) => {
            const state = getState();
            const prevGuestAges = state.filter.guestAges?.slice().sort();
            const guestAges = value.slice().sort();
            const hasAgesChanged = !isEqual(guestAges, prevGuestAges);

            if (hasAgesChanged) {
                dispatch(resetLastSearchedTimestamps());
            }

            dispatch(action);
        };
    }

    return action;
};

export const resetFilterState = () => {
    return {
        type: types.RESET_FILTER_STATE,
    };
};
