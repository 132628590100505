import * as types from "../actions/types";

const initialState = {
    date: {
        startDate: null,
        endDate: null,
    },
    totalGuests: 0,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_DATE: {
            return {
                ...state,
                date: action.date,
            };
        }

        case types.INCREMENT_GUEST_COUNT: {
            return {
                ...state,
                [action.guestKey]: state[action.guestKey] + 1,
                totalGuests: state.totalGuests + 1,
            };
        }

        case types.DECREMENT_GUEST_COUNT: {
            return {
                ...state,
                [action.guestKey]: state[action.guestKey] - 1,
                totalGuests: state.totalGuests - 1,
            };
        }

        case types.CREATE_GUEST_KEY: {
            return {
                ...state,
                [action.guestKey]: 0,
            };
        }

        case types.UPDATE_FILTER: {
            return {
                ...state,
                [action.key]: action.value,
            };
        }

        // Set person
        case types.SET_PERSON: {
            const personKey = action.person.key;
            let totalGuests = action.person.count;

            // totalValueFilters.forEach((filter) => {
            //   if (state.hasOwnProperty(filter) && personKey !== filter) {
            //     totalGuests = totalGuests + state[filter];
            //   }
            // });
            return {
                ...state,
                [personKey]: action.person.count,
                totalGuests: totalGuests,
            };
        }

        // Set reservation type filters
        case types.SET_RESERVATIONTYPE_FILTERS: {
            const { resvtype, filters } = action;

            if (Object.values(filters).length === 0) {
                return state;
            }

            let filterCriterias = { ...state.filterCriterias };
            filterCriterias[resvtype] = filters;

            return {
                ...state,
                filterCriterias: filterCriterias,
            };
        }

        // Set filter
        case types.SET_FILTER: {
            const { filter, resvtype } = action;
            // console.log(filter);
            let resvFilters = { ...state[resvtype] };
            if (filter.value === false || (filter.type === "text" && !filter.value)) {
                // Support removing filter
                delete resvFilters[filter.key];
            } else {
                // ENUMs are saved as an array with active enums, using their parent criteria code as key
                if (filter.type === "ENUM") {
                    const enumCode = parseInt(filter.value);

                    // Check if enum code exists in list
                    if (filter.key in resvFilters && !resvFilters[filter.key].includes(enumCode)) {
                        resvFilters[filter.key].push(enumCode);
                    } else {
                        resvFilters[filter.key] = [filter.value];
                    }
                } else {
                    resvFilters[filter.key] = filter.value;
                }
            }

            return {
                ...state,
                [resvtype]: Object.keys(resvFilters).length ? resvFilters : null,
            };
        }

        // Set filters
        case types.SET_FILTERS: {
            const { filters, resvtype } = action;

            return {
                ...state,
                [resvtype]: { ...filters },
            };
        }

        case types.REMOVE_FILTER: {
            const { resvtype, activeFilter } = action;
            const critCode = activeFilter.key;
            let resvFilters = { ...state[resvtype] };

            if (activeFilter.type === "ENUM") {
                // Remove active enum code from list of active enums
                resvFilters[critCode] = resvFilters[critCode].filter(enumCode => enumCode !== activeFilter.value);

                // Remove empty array from state
                if (resvFilters[critCode].length === 0) {
                    delete resvFilters[critCode];
                }
            } else {
                delete resvFilters[critCode];
            }

            return {
                ...state,
                [resvtype]: resvFilters,
            };
        }

        case types.REMOVE_FILTERS: {
            const { resvtype } = action;

            return {
                ...state,
                [resvtype]: null,
            };
        }

        // Clear filter
        case types.CLEAR_FILTERS: {
            const { resvtype } = action;

            return {
                ...state,
                [resvtype]: {},
            };
        }

        // Set promo code
        case types.SET_PROMO_CODE: {
            return {
                ...state,
                promoCode: action.code,
                promoCodeError: false,
                resvTypePromoCodes: {
                    ...state.resvTypePromoCodes,
                    [action.resvType]: { code: action.code, error: false },
                },
            };
        }

        // Set promo code failed
        case types.SET_PROMO_CODE_ERROR: {
            return {
                ...state,
                promoCodeError: action.error,
                resvTypePromoCodes: {
                    ...state.resvTypePromoCodes,
                    [action.resvType]: { ...state.resvTypePromoCodes[action.resvType], error: true },
                },
            };
        }

        // Reset promo code
        case types.RESET_PROMO_CODE: {
            return {
                ...state,
                promoCode: "",
                promoCodeError: false,
                resvTypePromoCodes: {
                    ...state.resvTypePromoCodes,
                    [action.resvType]: { code: "", error: false },
                },
            };
        }

        // Reset filter state
        case types.RESET_FILTER_STATE: {
            return {
                ...initialState,
            };
        }

        default: {
            return {
                ...state,
            };
        }
    }
};

export default reducer;
