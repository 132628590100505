import * as types from "../actions/types";
import AxiosClient from "../../API/AxiosClient";
import ConfirmationDialog from "../../components/Utilities/ConfirmationDialog";
import { resetCart } from "./checkoutCart";
import { setRequestsLoading } from "./axiosStatus";
import { textsSelector } from "../../Selectors";

/*
  Use to fetch customer reservations
*/
export const fetchReservations = token => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    return dispatch => {
        dispatch(setRequestsLoading({ request: "fetchReservations", loading: true }));

        AxiosClient.get("/reservations", config)
            .then(response => {
                dispatch(setReservations(response.data.payload));
                dispatch(setRequestsLoading({ request: "fetchReservations", loading: false }));
            })
            .catch(error => {
                dispatch(fetchReservationsFailed(error.response.data));
                dispatch(setRequestsLoading({ request: "fetchReservations", loading: false }));
            });
    };
};

export const resetReservations = () => {
    return dispatch => {
        dispatch(setReservations([]));
    };
};

const setReservations = payload => {
    return {
        type: types.SET_ACCOUNT_RESERVATIONS,
        reservations: payload,
        error: false,
    };
};

const fetchReservationsFailed = error => {
    return {
        type: types.FETCH_ACCOUNT_RESERVATIONS_FAILED,
        reservations: null,
        error: error,
    };
};

/*
  Use to fetch customer reservation with reservationId
*/
export const fetchReservation = (token, reservationId, verificationHash) => {
    let config = {},
        url = `/reservations/${reservationId}`;

    if (token) {
        config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
    } else {
        // Unauthenticated users require a hash to fetch the reservation in a secure way
        url += `?hash=${verificationHash}`;
    }
    return dispatch => {
        dispatch(setRequestsLoading({ request: "fetchReservation", loading: true }));

        return AxiosClient.get(url, config)
            .then(response => {
                const reservation = response.data.payload;
                dispatch(setReservation(reservation));
                dispatch(setRequestsLoading({ request: "fetchReservation", loading: false }));
                return reservation;
            })
            .catch(error => {
                console.log("Fetch reservation error:", error);
                dispatch(fetchReservationFailed(error));
                dispatch(setRequestsLoading({ request: "fetchReservation", loading: false }));
            });
    };
};

const setReservation = reservation => {
    return {
        type: types.SET_ACCOUNT_RESERVATION,
        product: reservation,
        error: false,
    };
};

const fetchReservationFailed = error => {
    return {
        type: types.FETCH_ACCOUNT_RESERVATION_FAILED,
        error: error,
    };
};

/*
  Use to login customer with username and password
  On response success
  - If owner: Let owner confirm that the cart has to be emptied before loggin in.
  - Redux: Set account->user->token
  - fetchAuthenticatedUser to get all customer data
*/
export const authenticateCustomer = (username, password) => {
    return (dispatch, getState) => {
        const state = getState();
        const texts = textsSelector(state);
        const hasItemsInCart = !!(state?.checkoutCart?.products || []).length;

        let body = {
            username: username,
            password: password,
        };
        if (window.__DEBUG__) {
            body.automagic = 1;
            body.password = "";
        }

        dispatch(setRequestsLoading({ request: "authenticateCustomer", loading: true }));

        AxiosClient.post("/auth/customer", body)
            .then(async response => {
                const payload = response.data.payload;
                const token = payload.token;
                const isOwner = payload.isOwner;

                if (isOwner && hasItemsInCart) {
                    const response = await ConfirmationDialog({
                        heading: texts["book.confirmations.owner_sign_in_empty_cart.title"],
                        description: texts["book.confirmations.owner_sign_in_empty_cart.description"],
                        confirmBtnText: texts["book.confirmations.owner_sign_in_empty_cart.ok"],
                        cancelBtnText: texts["book.confirmations.owner_sign_in_empty_cart.cancel"],
                    });

                    if (!response) {
                        return;
                    }

                    dispatch(resetCart());
                }

                dispatch(setCustomerToken(token));
                dispatch(fetchAuthenticatedUser(token));

                // We're doing a timeout here because we don't want the skeletons to end before the page has been redirected
                setTimeout(() => {
                    dispatch(setRequestsLoading({ request: "authenticateCustomer", loading: false }));
                }, 1500);
            })
            .catch(error => {
                dispatch(authenticateCustomerFailed(error.response.data));
                dispatch(setRequestsLoading({ request: "authenticateCustomer", loading: false }));
            });
    };
};

/*
  Use to login an agent.

  No credentials are needed as it relies in the session stored in the backend.

  On response success
  - Redux: Set account->user->token
  - fetchAuthenticatedUser to get all customer data
*/
export const authenticateAgent = () => {
    return dispatch => {
        AxiosClient.post("/auth/agent")
            .then(response => {
                // Sign out potentially logged in user.
                dispatch(signOutUser(undefined, true));

                const token = response.data.payload.token;
                dispatch(setCustomerToken(token));
                dispatch(fetchAuthenticatedUser(token));
            })
            .catch(error => {
                dispatch(authenticateCustomerFailed(error.response.data));
            });
    };
};

/*
  Use to login an owner using provided JWT token.

  On response success
  - Redux: Set account->user->token
  - fetchAuthenticatedUser to get all customer data
*/
export const authenticateOwner = token => {
    return (dispatch, getState) => {
        const state = getState();
        const texts = textsSelector(state);
        const loggedInUser = state?.account?.user;
        const hasItemsInCart = !!(state?.checkoutCart?.products || []).length;

        AxiosClient.post("/auth/owner", { token })
            .then(async response => {
                const payload = response.data.payload;

                if (!payload.isOwner) {
                    throw new Error("Failed to authenticate owner using token login");
                }

                if (hasItemsInCart) {
                    const response = await ConfirmationDialog({
                        heading: texts["book.confirmations.owner_sign_in_empty_cart.title"],
                        description: texts["book.confirmations.owner_sign_in_empty_cart.description"],
                        confirmBtnText: texts["book.confirmations.owner_sign_in_empty_cart.ok"],
                        cancelBtnText: texts["book.confirmations.owner_sign_in_empty_cart.cancel"],
                    });

                    if (!response) {
                        return;
                    }

                    dispatch(resetCart());
                }

                // Sign out potentially logged in user.
                if (loggedInUser) {
                    dispatch(signOutUser());
                }

                const token = payload.token;
                dispatch(setCustomerToken(token));
                dispatch(fetchAuthenticatedUser(token));
            })
            .catch(error => {
                dispatch(authenticateCustomerFailed(error.response.data));
            });
    };
};

// Retrieve password
export const retrieveUserPassword = username => {
    return dispatch => {
        dispatch(setRequestsLoading({ request: "retrieveUserPassword", loading: true }));

        AxiosClient.post("/users/forgotpassword", {
            email: username,
        })
            .then(response => {
                dispatch(setRetrievePassword(response.data.payload));
                dispatch(setRequestsLoading({ request: "retrieveUserPassword", loading: false }));
            })
            .catch(error => {
                console.log("retrieveUserPassword", error.response.data); // KEEP: Ease debugging with logrocket
                dispatch(setRetrievePasswordFailed(error.response.data));
                dispatch(setRequestsLoading({ request: "retrieveUserPassword", loading: false }));
            });
    };
};

export const setRetrievePassword = payload => {
    return {
        type: types.SET_RETRIEVE_PASSWORD_SUCCESS,
        payload: payload,
    };
};

const setRetrievePasswordFailed = () => {
    return {
        type: types.SET_RETRIEVE_PASSWORD_FAILED,
        error: true,
    };
};

const setCustomerToken = token => {
    return {
        type: types.SET_CUSTOMER_TOKEN,
        token: token,
        error: false,
    };
};

const authenticateCustomerFailed = error => {
    return {
        type: types.AUTHENTICATE_CUSTOMER_FAILED,
        error: error,
    };
};

/*
  Use to check customer authentication status with token
*/
export const checkAuthenticationStatus = token => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    return dispatch => {
        AxiosClient.get("/auth/check", config)
            .then(response => {
                dispatch(setCustomerAuthenticationStatus(response.data.successful));
            })
            .catch(error => {
                dispatch(checkAuthenticationStatusFailed(error.response.data));
            });
    };
};

const setCustomerAuthenticationStatus = authenticated => {
    return {
        type: types.SET_CUSTOMER_AUTHENTICATION_STATUS,
        loginSuccessful: authenticated,
        error: false,
    };
};

const checkAuthenticationStatusFailed = error => {
    return {
        type: "checkAuthenticationStatusFailed",
        error: error,
    };
};

/*
  Use to get authenticated user-data with token
  On response success
  - Redux: Set account->user
*/
export const fetchAuthenticatedUser = token => {
    return dispatch => {
        AxiosClient.get("/auth/current_user", {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(response => {
                dispatch(setAuthenticatedUserData(response.data.payload));
            })
            .catch(error => {
                dispatch(fetchAuthenticatedUserDataFailed(error.response.data));
            });
    };
};

const setAuthenticatedUserData = userInformation => {
    return {
        type: types.SET_AUTHENTICATED_USER_DATA,
        user: userInformation,
        loginSuccessful: true,
        error: false,
    };
};

const fetchAuthenticatedUserDataFailed = error => {
    return {
        type: types.FETCH_AUTHENTICATED_USER_DATA_FAILED,
        error: error,
        loginSuccessful: false,
    };
};

export const resetApiMessages = () => {
    return {
        type: types.RESET_API_MESSAGES,
    };
};

/*
  Use to set Unregistered User
*/
export const setUnregisteredUser = (key, value) => {
    return {
        type: types.SET_UNREGISTERED_USER,
        value: value,
        key: key,
    };
};

/*
  Use to register new user in R360 back-end
  Adding null value for Mandatory
*/
export const registerNewUser = newUser => {
    if (newUser.phone3prefix) {
        newUser.phone3prefix = parseInt(newUser.phone3prefix);
    }

    return dispatch => {
        dispatch(setRequestsLoading({ request: "registerNewUser", loading: true }));

        AxiosClient.post("/users", newUser)
            .then(response => {
                dispatch(setAuthenticatedUserData(response.data.payload.user));
                dispatch(setCustomerToken(response.data.payload.token));
                dispatch(failedToCreateNewUser(null));

                setTimeout(() => {
                    dispatch(setRequestsLoading({ request: "registerNewUser", loading: false }));
                }, 1500);
            })
            .catch(error => {
                console.log("registerNewUser", error.response.data); // KEEP: Ease debugging with logrocket
                dispatch(failedToCreateNewUser(true, error.response.data.errors));
                dispatch(setRequestsLoading({ request: "registerNewUser", loading: false }));
            });
    };
};

const failedToCreateNewUser = (error, data) => {
    return {
        type: types.FAILED_TO_CREATE_NEW_USER,
        error: error,
        data: data,
    };
};

/*
  Use to update user data in redux. NOT IN BACK-END
*/
export const updateUserData = (key, value) => {
    return {
        type: types.UPDATE_USER_DATA,
        key: key,
        value: value,
    };
};

/*
  Use to set Customer Data i'n R360 back-end
  On response success
  - Sets Authenticated User Data using response object
*/
export const publishUpdatedUserData = (user, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    if (user.phone3prefix) {
        user.phone3prefix = parseInt(user.phone3prefix);
    }

    return dispatch => {
        dispatch(setRequestsLoading({ request: "publishUpdatedUserData", loading: true }));

        AxiosClient.put("/users/current", user, config)
            .then(response => {
                dispatch(setAuthenticatedUserData(response.data.payload.user));
                dispatch(publishUpdatedUserDataFailed(null));
                dispatch(updatedUserData(true));
                dispatch(setRequestsLoading({ request: "publishUpdatedUserData", loading: false }));
            })
            .catch(error => {
                console.log("publishUpdatedUserData", error.response.data); // KEEP: Ease debugging with logrocket
                dispatch(publishUpdatedUserDataFailed(error.response.data));
                dispatch(updatedUserData(false));
                dispatch(setRequestsLoading({ request: "publishUpdatedUserData", loading: false }));
            });
    };
};

export const updatedUserData = value => {
    return {
        type: types.SUCCESSFULLY_UPDATED_USER_DATA,
        value: value,
    };
};

const publishUpdatedUserDataFailed = error => {
    return {
        type: types.PUBLISH_UPDATED_USER_DATA_FAILED,
        error: error,
    };
};

/*
  Use to get user guests
*/
export const fetchAccountGuests = token => {
    const url = "/users/guests";
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    return dispatch => {
        AxiosClient.get(url, config)
            .then(response => {
                const guests = response.data.payload.result;
                dispatch(setAccountGuests(guests));
            })
            .catch(error => {
                dispatch(fetchAccountGuestsFailed(error.response));
            });
    };
};

const setAccountGuests = guests => {
    return {
        type: types.SET_ACCOUNT_GUESTS,
        guests: guests,
    };
};

const fetchAccountGuestsFailed = error => {
    return {
        type: types.FETCH_ACCOUNT_GUESTS_FAILED,
        error: error,
    };
};

/*
  Use to cancel product from the reservation
*/
export const cancelProductFromReservation = (token, reservationId, detlid) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const url = `/reservations/${reservationId}/${detlid}`;
    return dispatch => {
        dispatch(setRequestsLoading({ request: "cancelProductFromReservation", loading: true }));

        AxiosClient.delete(url, config)
            .then(() => {
                dispatch(fetchReservation(token, reservationId));
                dispatch(setRequestsLoading({ request: "cancelProductFromReservation", loading: false }));
            })
            .catch(error => {
                console.log(error);
                dispatch(cancelProductFromReservationFailed(error));
                dispatch(setRequestsLoading({ request: "cancelProductFromReservation", loading: false }));
            });
    };
};

export const cancelReservation = (token, reservationId) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const url = `/reservations/${reservationId}`;
    return dispatch => {
        dispatch(setRequestsLoading({ request: "cancelReservation", loading: true }));
        AxiosClient.delete(url, config)
            .then(response => {
                console.log("delete", response.data.payload);
                dispatch(fetchReservation(token, reservationId));
                dispatch(setRequestsLoading({ request: "cancelReservation", loading: false }));
            })
            .catch(error => {
                console.log(error);
                dispatch(cancelFullProductFromReservationFailed(error));
                dispatch(setRequestsLoading({ request: "cancelReservation", loading: false }));
            });
    };
};

const cancelProductFromReservationFailed = error => {
    return {
        type: types.CANCEL_PRODUCT_FROM_RESERVATION_FAILED,
        error: error,
    };
};

const cancelFullProductFromReservationFailed = error => {
    return {
        type: types.CANCEL_FULLPRODUCT_FROM_RESERVATION_FAILED,
        error: error,
    };
};

/**
 * Sign out the logged in user.
 *
 * If the logged user is an owner, the cart needs to be emptied.
 * Let the visitor confirm that the cart will be emptied.
 *
 * @param {string|undefined} reason
 * @param {boolean} skipConfirmation
 * @returns Promise - successful logout true|false
 */
export const signOutUser = (reason, skipConfirmation = false) => {
    return async (dispatch, getState) => {
        const state = getState();
        const texts = textsSelector(state);
        const isOwner = !!state?.account?.user?.isOwner;
        const hasItemsInCart = !!(state?.checkoutCart?.products || []).length;
        const isCreatedFromReservation = !!state?.checkoutCart?.createdFromReservation;

        if (isOwner && hasItemsInCart) {
            if (!skipConfirmation) {
                const response = await ConfirmationDialog({
                    heading: texts["book.confirmations.owner_sign_out_empty_cart.title"],
                    description: texts["book.confirmations.owner_sign_out_empty_cart.description"],
                    confirmBtnText: texts["book.confirmations.owner_sign_out_empty_cart.ok"],
                    cancelBtnText: texts["book.confirmations.owner_sign_out_empty_cart.cancel"],
                });

                if (!response) {
                    return Promise.resolve(false);
                }
            }
            dispatch({ type: types.SIGN_OUT_USER, reason });
            dispatch(resetCart());
        } else if (isCreatedFromReservation) {
            dispatch({ type: types.SIGN_OUT_USER, reason });
            dispatch(resetCart());
        } else {
            dispatch({ type: types.SIGN_OUT_USER, reason });
        }

        return Promise.resolve(true);
    };
};

export const setBookingDetailedView = detailedView => {
    return {
        type: types.SET_BOOKING_DETAILED_VIEW,
        detailedView,
    };
};
