import React, { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { optionsSelector } from "../Selectors";
import { checkAuthenticationStatus, signOutUser } from "../store/actions/account";
import useAppDispatch from "../hooks/useAppDispatch";
import PageContainer from "../components/PageContainer";
import {
    Breadcrumbs,
    Button,
    CalendarCheckIcon,
    ConfirmationModal,
    Heading,
    UserIcon,
    UserTwoIcon,
    style,
} from "@r360/library";
import { ExpandableSection } from "../components/ExpandableSection";
import { MyInformation } from "../components/MyInformation/MyInformation";
import MyGuests from "../components/MyGuests/MyGuests";
import MyBookings from "../components/MyBookings/MyBookings";
import useQueryString from "../hooks/QueryString/useQueryString";
import useTranslate from "../hooks/useTranslate";
import useAppSelector from "../hooks/useAppSelector";
import classNames from "classnames";

enum MyPagesSections {
    INFORMATION = "information",
    GUESTS = "guests",
    BOOKINGS = "bookings",
}

const MyPages = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const t = useTranslate();

    const [informationQuery, setInformationQuery] = useQueryString(MyPagesSections.INFORMATION);
    const [guestsQuery, setGuestsQuery] = useQueryString(MyPagesSections.GUESTS);
    const [bookingsQuery, setBookingsQuery] = useQueryString(MyPagesSections.BOOKINGS);

    const myPageOnly = !!useAppSelector(optionsSelector).general?.mypage_only;
    const account = useAppSelector(state => state.account);
    const userToken = account.token;
    const showGuests = useAppSelector(optionsSelector).customer?.show_guests || false;

    const [openLogoutChangeReservationModal, setOpenLogoutChangeReservationModal] = useState(false);
    const checkoutCart = useAppSelector(state => state.checkoutCart.products);
    const handleSignOut = () => {
        dispatch(signOutUser("")).then((loggedOut: boolean) => loggedOut && navigate("/"));
    };

    useLayoutEffect(() => {
        // Open the booking accodion by default if no querys parameters are provided
        if (informationQuery !== "true" && guestsQuery !== "true") {
            setBookingsQuery("true");
        }
        // eslint-disable-next-line
    }, []);

    // Check if authenticated
    useEffect(() => {
        dispatch(checkAuthenticationStatus(userToken));
    }, [dispatch, userToken]);

    const breadcrumbs = [
        {
            title: t("book.products"),
            path: "/",
        },
        {
            title: t("book.my_pages"),
        },
    ];

    return (
        <PageContainer>
            <div
                className={classNames("u-d-flex", {
                    "u-justify-content-between": !myPageOnly,
                    "u-justify-content-end": myPageOnly,
                })}
            >
                {!myPageOnly && <Breadcrumbs breadcrumbs={breadcrumbs} />}
                <Button
                    type="tertiary"
                    onClick={() => {
                        if (checkoutCart.length > 0) {
                            setOpenLogoutChangeReservationModal(true);
                        } else {
                            handleSignOut();
                        }
                    }}
                >
                    {t("book.general.log_out")}
                </Button>
            </div>
            <ConfirmationModal
                open={openLogoutChangeReservationModal}
                heading={t("book.confirmations.change_reservation_empty_cart_logout.title")}
                description={t("book.confirmations.change_reservation_empty_cart_logout.description")}
                confirmBtnText={t("book.general.continue")}
                cancelBtnText={t("book.general.cancel")}
                removing={true}
                onClose={() => setOpenLogoutChangeReservationModal(false)}
                onConfirmClick={handleSignOut}
                onCancelClick={() => setOpenLogoutChangeReservationModal(false)}
            />
            <div className="u-d-flex u-flex-column u-gap-30 u-pt-lg-42">
                <Heading type="h1" styleAs="h2">
                    {t("book.my_pages")}
                </Heading>
                <ExpandableSection
                    title={t("book.my_pages.my_information")}
                    icon={<UserIcon color={style.whiteColor} />}
                    expanded={informationQuery === "true"}
                    onToggle={() => setInformationQuery(informationQuery === "true" ? "false" : "true")}
                >
                    <MyInformation />
                </ExpandableSection>
                {showGuests && (
                    <ExpandableSection
                        title={t("book.my_pages.my_guests")}
                        icon={<UserTwoIcon color={style.whiteColor} />}
                        expanded={guestsQuery === "true"}
                        onToggle={() => setGuestsQuery(guestsQuery === "true" ? "false" : "true")}
                    >
                        <MyGuests />
                    </ExpandableSection>
                )}
                <ExpandableSection
                    title={t("book.my_pages.my_bookings")}
                    icon={<CalendarCheckIcon color={style.whiteColor} />}
                    expanded={bookingsQuery === "true"}
                    onToggle={() => setBookingsQuery(bookingsQuery === "true" ? "false" : "true")}
                >
                    <MyBookings />
                </ExpandableSection>
            </div>
        </PageContainer>
    );
};

export default MyPages;
