import React, { useState } from "react";
import moment from "moment-timezone";
import { Button, Col, Row, Form } from "react-bootstrap";
import { isActivity, showArrDepDates } from "../../../BusinessUtils";
import { Icon, Tag } from "../../UI";
import {
    displayGuestText,
    getGuestNameAndAge,
    isGuestRightAgeAtArrival,
    isGuestRightAgeForProduct,
    isNumberWithoutDecimals,
} from "../../../Helper";
import promptDialog from "../../UI/PromptDialog";
import PhoneCountryCodeSelect from "../../PhoneCountryCodeSelect/OldPhoneCountryCodeSelect";
import useFormPhoneNumberValidation from "../../../hooks/useFormPhoneNumberValidation";

const Activity = props => {
    const { id, activity, item, guests, texts, allowNotes, onUpdate, onRemove } = props;

    const [currentValues, setCurrentValues] = useState({
        phone: item.phone,
        phoneprefix: item.phoneprefix,
    });

    const [touched, setTouched] = useState({
        phone: false,
        phoneprefix: false,
    });

    const phoneNumberRequired = true;

    const { phoneValid, phonePrefixValid, validatePhone, validatePhonePrefix } =
        useFormPhoneNumberValidation(phoneNumberRequired);

    const updateField = (fieldName, fieldValue) => {
        onUpdate(fieldName, fieldValue, activity, item);
    };

    const openNotesPromptDialog = async (product, productItem) => {
        const [status, value] = await promptDialog({
            placeholder: texts["checkout.activities.note_prompt.placeholder"],
            okLabel: texts["general.prompt.save"],
            cancelLabel: texts["general.confirmation.cancel"],
            initialValue: productItem.note || "",
            maxLength: 250,
        });

        if (status) {
            onUpdate("note", value, product, productItem);
        }
    };

    return (
        <div id={id} className="activities-fillout__item">
            <Row>
                <Form.Group as={Col} md="auto">
                    <h5>{activity.title}</h5>
                </Form.Group>
                <Form.Group as={Col} md="auto">
                    <Row>
                        <Tag className="col-auto me-1" type="medium">
                            {showArrDepDates(activity)}
                        </Tag>

                        {activity.occations?.length > 0 && (
                            <Tag className="col-auto me-1" type="medium">
                                {activity.occations.length} {texts.activityoccations}
                            </Tag>
                        )}

                        <Tag className="col-auto" type="medium">
                            {displayGuestText(item.minAge, item.maxAge, texts?.generalyearshort)}
                        </Tag>
                    </Row>
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col} md="auto">
                    {isActivity(activity) && activity?.occations?.length > 1 && (
                        <ul>
                            {activity?.occations?.map((occation, index) => (
                                <li key={index}>
                                    {`${moment(occation.date).format("ddd D MMM")} ${occation.fromtime} - ${
                                        occation.totime
                                    }`}
                                </li>
                            ))}
                        </ul>
                    )}
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col} md="4" lg="3" controlId="validationCustom01">
                    <Form.Control
                        required
                        as="select"
                        name="guestId"
                        value={item.guestId || ""}
                        onChange={event => {
                            onUpdate(event.target.name, event.target.value, activity, item);
                        }}
                    >
                        <option value="" disabled>
                            {texts?.generalguest}*
                        </option>
                        {Object.values(guests)
                            .filter(guest =>
                                isGuestRightAgeAtArrival(guest, activity.arrdate, item.minAge, item.maxAge)
                            )
                            .map(guest => {
                                return (
                                    <option key={guest.id} value={guest.id}>
                                        {getGuestNameAndAge(guest, texts?.generalyearshort, activity)}
                                    </option>
                                );
                            })}
                    </Form.Control>
                </Form.Group>
                <Form.Group as={Col} md="8" lg="6" controlId="validationCustom02">
                    <Row>
                        <Col md="6" className="form-group mb-0">
                            <PhoneCountryCodeSelect
                                required
                                placeholder={texts?.generalphoneprefix}
                                othersLabel="Other countries"
                                disabled={!item.guestId}
                                name="phoneprefix"
                                value={currentValues.phoneprefix || ""}
                                onChange={event => {
                                    setTouched({ ...touched, phoneprefix: true });
                                    setCurrentValues({ ...currentValues, phoneprefix: event.target.value });

                                    if (validatePhonePrefix(event.target.value, currentValues.phone)) {
                                        event.target.setCustomValidity("");
                                        updateField("phoneprefix", event.target.value);
                                    } else {
                                        event.target.setCustomValidity("Invalid phoneprefix");
                                    }
                                }}
                                isValid={touched.phoneprefix && phonePrefixValid}
                                isInvalid={touched.phoneprefix && !phonePrefixValid}
                            />
                        </Col>
                        <Col md="6">
                            <Form.Control
                                disabled={!item.guestId}
                                required
                                name={"phone"}
                                placeholder={`${texts["checkout.activities.contact_phone"]}*`}
                                type="tel"
                                value={currentValues.phone || ""}
                                onChange={event => {
                                    if (isNumberWithoutDecimals(event.target.value)) {
                                        setTouched({ ...touched, phone: true });
                                        setCurrentValues({ ...currentValues, phone: event.target.value });

                                        if (validatePhone(currentValues.phoneprefix, event.target.value)) {
                                            event.target.setCustomValidity("");
                                            updateField("phone", event.target.value);
                                        } else {
                                            event.target.setCustomValidity("Invalid phone");
                                        }
                                    }
                                }}
                                isValid={touched.phone && phoneValid}
                                isInvalid={touched.phone && !phoneValid}
                            ></Form.Control>
                        </Col>
                    </Row>
                </Form.Group>
            </Row>

            {allowNotes && (
                <Row>
                    <Form.Group as={Col} md={12} lg={9}>
                        <Button
                            onClick={() => openNotesPromptDialog(activity, item)}
                            variant="link"
                            size="md"
                            className="fw-light text-primary p-0 d-flex align-items-center"
                        >
                            <Icon name="FaRegEdit" size={16} className="me-2 mb-1" />
                            {texts[item.note ? "checkout.change_note" : "checkout.add_note"]}
                        </Button>
                        {item.note && <div className="activities-fillout__noteBox fw-light">{item.note}</div>}
                    </Form.Group>
                </Row>
            )}

            <Row>
                <Form.Group as={Col} md={12} lg={9}>
                    <Button
                        className="skiRental__button p-0 text-danger"
                        variant="link"
                        onClick={() => {
                            onRemove(activity, item);
                        }}
                    >
                        {texts?.generalremove}
                    </Button>
                </Form.Group>
            </Row>
        </div>
    );
};

export default Activity;
