import React, {
    ChangeEvent,
    SyntheticEvent,
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactCrop, { Crop, PercentCrop, PixelCrop, centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/src/ReactCrop.scss";
import "./UploadPhoto.scss";
import AxiosClient from "../../API/AxiosClient";
import { updateUserData } from "../../store/actions/account";
import { updateGuestData } from "../../store/actions/guests";
import { RootState } from "../..";
import { Button, Heading, ImagePlaceholder, Modal, style, Notification } from "@r360/library";
import { setRequestsLoading } from "../../store/actions/axiosStatus";
import classNames from "classnames";
import useTranslate from "../../hooks/useTranslate";

const imageAspectRatio = 138 / 150;
const minWidth = 150;
const minHeight = imageAspectRatio * minWidth;

export type TButtonType = "transparent" | "secondary";

type TUploadPhoto = {
    guestlid?: number;
    imageHash: string;
    noMaxWidth?: boolean;
    buttonType?: TButtonType;
    disabled?: boolean;
};

export const UploadPhoto = React.memo(
    forwardRef(
        ({ guestlid, imageHash, noMaxWidth = false, buttonType = "transparent", disabled }: TUploadPhoto, ref) => {
            const t = useTranslate();
            const dispatch = useDispatch();
            const userToken = useSelector((state: RootState) => state.account.token);
            const guests = useSelector((state: RootState) => state.guests.guests);
            const user = useSelector((state: RootState) => state.account.user);
            const { requestsLoading } = useSelector((state: RootState) => state.axiosStatus);
            const uploadingInProgress = requestsLoading["uploadGuestImage"];

            const [showModal, setShowModal] = useState(false);
            const [verified, setVerified] = useState(false);
            const [crop, setCrop] = useState<PixelCrop | PercentCrop>();
            const [completedCrop, setCompletedCrop] = useState<Crop>();
            const fileInputRef = useRef<HTMLInputElement | null>(null);
            const imageCropRef = useRef<HTMLImageElement | null>(null);
            const [showCropper, setShowCropper] = useState(false);
            const [imageUploadFailed, setImageUploadFailed] = useState(false);
            const [imageResolutionError, setImageResolutionError] = useState(false);

            const getImageUrlFromHash = (imageHash: string) => {
                if (!imageHash) {
                    return "";
                }

                const baseImageUrl = "https://r360generalstorage.blob.core.windows.net/userprofileimages/";
                const imageExtension = ".jpg";

                return `${baseImageUrl}${imageHash}${imageExtension}`;
            };

            const [imagePreviewUrl, setImagePreviewUrl] = useState(getImageUrlFromHash(imageHash));
            const [imageCropUrl, setImageCropUrl] = useState("");
            const [imageTooLarge, setImageTooLarge] = useState(false);

            const openPhotoButtonModal = () => setShowModal(true);

            useImperativeHandle(ref, () => {
                return {
                    openPhotoButtonModal,
                };
            });

            useEffect(() => {
                setVerified(!!imageHash);
                setImagePreviewUrl(getImageUrlFromHash(imageHash));
            }, [imageHash]);

            useEffect(() => {
                if (showModal) {
                    setImageUploadFailed(false);
                }
            }, [showModal]);

            const onImageLoad = (e: SyntheticEvent<HTMLImageElement, Event>) => {
                const { naturalWidth: width, naturalHeight: height } = e.currentTarget;

                if (width < minWidth || height < minHeight) {
                    resetCropper();
                    setImageResolutionError(true);
                    return;
                }

                const aspectCropConfig: Partial<Crop> =
                    width * imageAspectRatio > height
                        ? { unit: "%", width: Math.min(100, Math.max(80, (minWidth / width) * 100)) }
                        : { unit: "%", height: Math.min(100, Math.max(80, (minHeight / height) * 100)) };

                const crop = centerCrop(
                    makeAspectCrop(aspectCropConfig, imageAspectRatio, width, height),
                    width,
                    height
                );

                setCrop(crop);
            };

            const onSelectFile = (e: ChangeEvent<HTMLInputElement>) => {
                if (e.target.files && e.target.files.length > 0) {
                    setCrop(undefined); // Makes crop preview update between images.
                    const reader = new FileReader();
                    reader.addEventListener("load", () => setImageCropUrl(reader.result?.toString() ?? ""));
                    reader.readAsDataURL(e.target.files[0]);
                    setShowCropper(true);
                    setShowModal(true);
                }
            };

            const openFileInput = () => fileInputRef.current?.click?.();

            const getCroppedCanvas = (image: HTMLImageElement | null, crop: Crop) => {
                if (!image) return;

                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");

                if (!ctx) {
                    throw new Error("No 2d context");
                }

                const { naturalWidth, naturalHeight } = image;

                const scaleX = naturalWidth / image.width;
                const scaleY = naturalHeight / image.height;

                canvas.width = Math.floor(crop.width * scaleX);
                canvas.height = Math.floor(crop.height * scaleY);
                ctx.imageSmoothingQuality = "high";

                const cropX = crop.x * scaleX;
                const cropY = crop.y * scaleY;

                const centerX = naturalWidth / 2;
                const centerY = naturalHeight / 2;

                ctx.save();

                ctx.translate(-cropX, -cropY);
                ctx.translate(centerX, centerY);
                ctx.translate(-centerX, -centerY);
                ctx.drawImage(image, 0, 0, naturalWidth, naturalHeight, 0, 0, naturalWidth, naturalHeight);
                ctx.restore();

                return canvas;
            };

            const handleSaveCrop = () => {
                const croppedCanvas = completedCrop && getCroppedCanvas(imageCropRef.current, completedCrop);

                if (!croppedCanvas) return;

                const previewUrl = croppedCanvas.toDataURL("image/jpeg");

                setImagePreviewUrl(previewUrl);

                croppedCanvas.toBlob(blob => {
                    if (blob && guestlid) uploadGuestImage(guestlid, blob);
                }, "image/jpeg");

                // resetCropper();
            };

            const resetCropper = () => {
                if (fileInputRef?.current) {
                    fileInputRef.current.value = "";
                }

                setImageCropUrl("");
                setShowCropper(false);
                setCrop(undefined);
                setCompletedCrop(undefined);
                setImageUploadFailed(false);
                setImageTooLarge(false);
                setImageResolutionError(false);
            };

            /**
             * Upload image for a guest.
             *
             * @param {number} guestlid
             * @param {Blob} imageBlob
             */
            const uploadGuestImage = (guestlid: number, imageBlob: Blob) => {
                const config = {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                        "Content-Type": "multipart/form-data",
                    },
                };

                const url = `users/guestimage`;
                const formData = new FormData();
                formData.append("guestlid", guestlid.toString());
                formData.append("file", imageBlob, "guestphoto.jpg");

                dispatch(setRequestsLoading({ request: "uploadGuestImage", loading: true }));

                AxiosClient.post(url, formData, config)
                    .then(response => {
                        if (!response?.data?.payload?.imageHash) {
                            throw new Error("Failed to upload skipass image");
                        }

                        // setImageUploadSuccessful(true);
                        updateImageHashOnUserAndGuest(response.data.payload.imageHash);
                        setShowModal(false);
                        dispatch(setRequestsLoading({ request: "uploadGuestImage", loading: false }));

                        setTimeout(() => {
                            resetCropper();
                        }, 100);
                    })
                    .catch(error => {
                        setImageUploadFailed(true);
                        if (error.response.request.status === 413) {
                            setImageTooLarge(true);
                        }
                        setImagePreviewUrl(getImageUrlFromHash(imageHash));
                        setShowCropper(false);
                        dispatch(setRequestsLoading({ request: "uploadGuestImage", loading: false }));
                        console.error(error);
                    });
            };

            const updateImageHashOnUserAndGuest = (imageHash: string) => {
                // Update image hash for the user.
                if (guestlid === user?.id) {
                    dispatch(updateUserData("imagehash", imageHash));
                }

                // If guestlid matches guest, update image hash for the guest.
                if (guestlid && guests[guestlid]) {
                    dispatch(updateGuestData(guestlid, "imagehash", imageHash));
                }
            };

            const handleCloseModal = () => {
                if (!uploadingInProgress) {
                    resetCropper();
                    setShowModal(false);
                }
            };

            return (
                <>
                    <div
                        className={classNames("u-d-flex u-flex-column u-gap-12 u-align-items-center", {
                            "u-disabled": disabled,
                        })}
                    >
                        <div
                            style={{
                                position: "relative",
                                width: "100%",
                                maxWidth: noMaxWidth ? "none" : "150px",
                                // height: "150px",
                                aspectRatio: "6 / 7",
                                borderRadius: "8px",
                                overflow: "hidden",
                                border: "1px solid black",
                                borderColor: style.greyColor,
                            }}
                        >
                            {!verified ? (
                                <ImagePlaceholder fillParent />
                            ) : (
                                <div
                                    style={{
                                        height: "100%",
                                        backgroundImage: `url(${imagePreviewUrl})`,
                                        backgroundSize: "cover",
                                    }}
                                ></div>
                            )}
                        </div>

                        <Button
                            type={buttonType}
                            buttonSize={"small"}
                            buttonClassName={"" + (!verified ? "" : "")}
                            onClick={openFileInput}
                            fullWidth
                        >
                            {!verified ? t("book.upload_photo") : t("book.change_photo")}
                        </Button>
                        <input
                            className="d-none"
                            ref={fileInputRef}
                            type="file"
                            accept="image/png, image/jpeg"
                            onChange={onSelectFile}
                        />
                    </div>
                    <Modal
                        size="lg"
                        open={showModal}
                        onClose={handleCloseModal}
                        closeOnOutsideClick={!uploadingInProgress}
                        closeOnEscape={!uploadingInProgress}
                    >
                        <div>
                            <Heading type="h2" styleAs="h3" className="u-mb-18">
                                {t("book.checkout.upload_photo_skipass")}
                            </Heading>
                            {showCropper && (
                                <>
                                    <div className="u-mb-18">
                                        {t("book.checkout.upload_photo_skipass.instructions")}
                                    </div>
                                    <div
                                        className={classNames("u-mb-24 u-d-flex u-justify-content-center", {
                                            "u-disabled": uploadingInProgress,
                                        })}
                                    >
                                        <ReactCrop
                                            aspect={imageAspectRatio}
                                            minWidth={150}
                                            keepSelection={true}
                                            ruleOfThirds={true}
                                            crop={crop}
                                            onChange={(_, percentageCrop) => setCrop(percentageCrop)}
                                            onComplete={setCompletedCrop}
                                        >
                                            <img
                                                ref={imageCropRef}
                                                src={imageCropUrl}
                                                onLoad={onImageLoad}
                                                style={{ maxHeight: "60vh" }}
                                            />
                                        </ReactCrop>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-lg-6">
                                            <Button
                                                buttonClassName="w-100 button--invert"
                                                onClick={handleCloseModal}
                                                fullWidth
                                                disabled={uploadingInProgress}
                                            >
                                                {t("book.general.cancel")}
                                            </Button>
                                        </div>
                                        <div className="col-12 col-lg-6">
                                            <Button
                                                buttonClassName="w-100"
                                                onClick={handleSaveCrop}
                                                fullWidth
                                                loading={uploadingInProgress}
                                            >
                                                {t("book.general.save")}
                                            </Button>
                                        </div>
                                    </div>
                                </>
                            )}
                            {!showCropper && (
                                <>
                                    {imageUploadFailed && (
                                        <Notification type="error">
                                            <Heading type="h5" className="u-mb-0">
                                                {t("book.general.upload_failed")}
                                            </Heading>
                                            {imageTooLarge && <p>{t("book.photo_upload_too_large")}</p>}
                                        </Notification>
                                    )}
                                    {imageResolutionError && (
                                        <Notification type="error">
                                            <p className="u-mb-0">
                                                {t("book.checkout.upload_photo_skipass.resolution_error")}
                                            </p>
                                        </Notification>
                                    )}
                                </>
                            )}
                        </div>
                    </Modal>
                </>
            );
        }
    )
);

UploadPhoto.displayName = "PhotoButton";
