import React from "react";
import { useSelector } from "react-redux";
import Login from "../Login/OldLogin";
import Registration from "../Registration/OldRegistration/Registration";

const SignUpWrapper = () => {
    const signUpWrapper = useSelector(state => state.window.signUpWrapper);

    const modalToShow = () => {
        if (signUpWrapper.loginModal) {
            return <Login></Login>;
        } else {
            return <Registration></Registration>;
        }
    };

    return modalToShow();
};

export default SignUpWrapper;
