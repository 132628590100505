import React from "react";
import { Logotype } from "@r360/library";
import "./Footer.scss";
import classNames from "classnames";

type TFooter = {
    color?: "dark-grey" | "white";
    size?: "small" | "medium";
};

export const Footer = ({ color = "dark-grey", size = "medium" }: TFooter) => {
    return (
        <div className={classNames("footer", { "footer--sm": size === "small" })}>
            <Logotype width={size === "small" ? 70 : 100} color={color} />
        </div>
    );
};
