import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { optionsSelector } from "../../selectors/Selectors";
import { fetchReservations, checkAuthenticationStatus } from "../../store/actions/account";
import useAppDispatch from "../../hooks/useAppDispatch";
import {
    BasicTable,
    Button,
    ChevronRightIcon,
    Input,
    Notification,
    Skeleton,
    SkeletonGroup,
    style,
} from "@r360/library";
import { TAccount, TReservation } from "../../store/types";
import { formatPrice } from "../../Helper";
import useMemoizeArg from "../../hooks/useMemoizeArg";
import moment from "moment";
import "./MyBookings.scss";
import useTranslate from "../../hooks/useTranslate";
import useAppSelector from "../../hooks/useAppSelector";

type TTableData = {
    id: string;
    arrDate: string;
    bookingNumber: string;
    totalPrice: string;
    status: string;
    statusElement: JSX.Element;
    statusOrder: number;
    button?: JSX.Element;
};

const MyBookings = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const t = useTranslate();

    const account: TAccount = useAppSelector(state => state.account);
    const userToken = account.token;
    const reservations = account.reservations;
    const currency = useAppSelector(optionsSelector).general?.currency;
    const showReservationAllowed = !!useAppSelector(optionsSelector).mypage.showreservation;
    const { isDesktopNewDesign } = useAppSelector(state => state.window);
    const loadingReservations = useAppSelector(state => state.axiosStatus.requestsLoading["fetchReservations"]);

    const [searchPhrase, setSearchPhrase] = useState("");
    const [showContactResortWarning, setShowContactResortWarning] = useState(false);

    const filteredReservations = searchPhrase
        ? reservations.filter(reservation => reservation.resvid.toUpperCase().includes(searchPhrase.toUpperCase()))
        : reservations;

    // Check if authenticated
    useEffect(() => {
        dispatch(checkAuthenticationStatus(userToken));
    }, [dispatch, userToken]);

    useEffect(() => {
        dispatch(fetchReservations(userToken));
    }, [dispatch, userToken]);

    // If a reservation have a negative amount to pay then show a warning
    useEffect(() => {
        if (reservations.some(reservation => reservation.reservationrules.totamounttopay < 0)) {
            setShowContactResortWarning(true);
        }
        //eslint-disable-next-line
    }, [useMemoizeArg(reservations)]);

    const getBookingStatus = (reservation: TReservation) => {
        let status = "",
            color = "",
            order = 0;

        const amountToPay = reservation.reservationrules.totamounttopay;
        const contactResort = amountToPay < 0;

        if (reservation.cancelled && !contactResort) {
            status = t("book.my_pages.cancelled");
            color = "dark-grey";
            order = 4;
        } else {
            if (amountToPay == 0) {
                status = t("book.my_pages.payed");
                color = "success";
                order = 3;
            }

            if (amountToPay > 0) {
                status = t("book.my_pages.unpaid");
                color = "alert";
                order = 2;
            }

            if (contactResort) {
                status = reservation.cancelled
                    ? `${t("book.my_pages.contact_resort")} (${t("book.my_pages.cancelled")})`
                    : t("book.my_pages.contact_resort");
                color = "yellow";
                order = 1;
            }
        }

        return {
            status,
            element: <span className={`u-color-${color}`}>{status}</span>,
            order,
        };
    };

    const tableColumns = [
        {
            dataIndex: "arrDate",
            defaultSortOrder: "descend",
            key: "arrDate",
            sorter: (a: TTableData, b: TTableData) => moment(a.arrDate).diff(moment(b.arrDate)),
            title: t("book.my_pages.arrival_date"),
            width: "20%",
        },
        {
            dataIndex: "bookingNumber",
            key: "bookingNumber",
            title: t("book.my_pages.booking_number"),
            width: "20%",
        },
        {
            dataIndex: "totalPrice",
            key: "totalPrice",
            title: t("book.results.total_price"),
            width: "20%",
        },
        {
            dataIndex: "statusElement",
            key: "status",
            sorter: (a: TTableData, b: TTableData) => b.statusOrder - a.statusOrder,
            title: t("book.general.status"),
            width: "20%",
        },
        {
            dataIndex: "button",
            key: "button",
            title: "",
            width: "20%",
        },
    ];

    const tableData: TTableData[] = filteredReservations
        .map(reservation => {
            const status = getBookingStatus(reservation);

            return {
                id: reservation.resvid,
                arrDate: reservation.arrdate,
                bookingNumber: reservation.resvid,
                totalPrice: formatPrice(reservation.reservationrules.totamountinclvat, currency),
                status: status.status,
                statusElement: status.element,
                statusOrder: status.order,
                button:
                    reservation.cancelled === false && showReservationAllowed ? (
                        <div className="u-d-flex u-justify-content-end">
                            <Button type="tertiary" link linkTo={`/my-pages/bookings/${reservation.resvid}`}>
                                {t("book.my_pages.see_booking")}
                            </Button>
                        </div>
                    ) : undefined,
            };
        })
        .sort((a, b) => moment(b.arrDate).diff(moment(a.arrDate)));

    return (
        <>
            {showContactResortWarning && (
                <div className="u-mb-24">
                    <Notification type="warning">{t("book.my_pages.my_bookings.contact_resort")}</Notification>
                </div>
            )}
            {reservations.length > 20 && (
                <div className="row u-mb-12">
                    <div className="col-12 col-lg-4">
                        <Input
                            label={t("book.my_pages.search_bookings")}
                            onChange={e => setSearchPhrase(e.target.value)}
                            value={searchPhrase}
                        />
                    </div>
                </div>
            )}
            <div className="u-mb-24">
                {loadingReservations ? (
                    <div className="u-pt-12">
                        <SkeletonGroup>
                            <Skeleton height="60px" borderRadius="6px 6px 0 0" />
                            {[...Array(10)].map((_el, i) => {
                                return (
                                    <Skeleton
                                        key={i}
                                        height="50px"
                                        borderRadius="0"
                                        shade={i % 2 !== 0 ? "medium" : "light"}
                                    />
                                );
                            })}
                        </SkeletonGroup>
                    </div>
                ) : isDesktopNewDesign ? (
                    <BasicTable
                        columns={tableColumns}
                        data={tableData}
                        noDataText="Inga bokningar hittades"
                        rowKey="id"
                        lightGreyOnEvenRows
                        hasPagination
                        paginationPageSize={20}
                    />
                ) : (
                    tableData.map(reservation => {
                        return (
                            <>
                                <div key={reservation.bookingNumber} className="u-d-flex u-gap-18 u-pt-12 u-pb-12">
                                    <div className="u-d-flex u-justify-content-between" style={{ flexGrow: 1 }}>
                                        <div className="u-d-flex u-flex-column u-gap-6">
                                            <div>
                                                <strong>{reservation.bookingNumber}</strong>
                                            </div>
                                            <div>{reservation.arrDate}</div>
                                        </div>
                                        <div className="u-d-flex u-flex-column u-align-items-end u-gap-6">
                                            <div>{reservation.totalPrice}</div>
                                            <div>{reservation.statusElement}</div>
                                        </div>
                                    </div>
                                    <div className="u-d-flex u-align-items-center">
                                        <ChevronButton
                                            onClick={() => navigate(`/my-pages/bookings/${reservation.bookingNumber}`)}
                                        />
                                    </div>
                                </div>
                                <hr className="u-mt-0 u-mb-0" style={{ backgroundColor: style.darkGreyColor }} />
                            </>
                        );
                    })
                )}
            </div>
        </>
    );
};

const ChevronButton = ({ onClick }: { onClick: () => void }) => {
    return (
        <button className="chevron-button" onClick={onClick}>
            <ChevronRightIcon color={style.brandBlueColor} />
        </button>
    );
};

export default MyBookings;
