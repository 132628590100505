import classnames from "classnames";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { sortCartProducts } from "../BusinessUtils";
import OrderOverview from "../components/OrderOverview/OrderOverview";
import ProductExtras from "../components/ProductExtras/ProductExtras";
import { Alert, Button, Icon } from "../components/UI";
import * as Constants from "../Constants";
import { optionsSelector, reservationTypesByTypeSelector } from "../selectors/Selectors";
import { fetchAdditionalItemInformation } from "../store/actions/reservationResult";
import { textsSelector } from "../Selectors";

// Products extras page
const ProductExtrasPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userName = useSelector(state => state.account?.user?.name1);
    const cartProducts = useSelector(state => state.checkoutCart.products);
    const isMobile = useSelector(state => state.window.isMobile);
    const texts = useSelector(textsSelector);
    const reservationTypes = useSelector(state => state.clientData.reservationtypes);
    const isLoading = useSelector(state => state.axiosStatus.loading);
    const cartCreatedFromReservation = useSelector(state => state.checkoutCart.createdFromReservation);
    const newDesignMenu = useSelector(optionsSelector).layout?.new_design_menu;

    const accommodationTypes = useSelector(state =>
        reservationTypesByTypeSelector(state, Constants.productTypeNames.ACCOMMODATION)
    );
    const accommodationLids = accommodationTypes.map(x => parseInt(x.lid));

    const accommodationsInCart = cartProducts.filter(product => accommodationLids.includes(parseInt(product.type)));
    const accommodationsInCartWithExtras = sortCartProducts(
        accommodationsInCart.filter(product => !!product.info?.extras?.length)
    );

    const myPageOnly = !!useSelector(optionsSelector).general?.mypage_only;

    // Form validation
    const [validated, setValidated] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const formHasErrors = !validated && formSubmitted;

    const handleSubmit = event => {
        const form = event.target;

        // Prevent real form submission, go to next page
        event.preventDefault();
        event.stopPropagation();

        setFormSubmitted(true);

        if (form.checkValidity() === false) {
            setValidated(false);
        } else {
            setValidated(true);
            navigate("/checkout");
        }
    };

    useEffect(() => {
        accommodationsInCart.forEach(accommodation => {
            const resvtype = parseInt(accommodation.type, 10);
            const resultsetid = accommodation.resultset;
            const itemId = accommodation.id;
            const groupId = accommodation.group;

            // We don't need to fetch extras for booked accommodations because they are fetched from the cart instead
            // Don't fetch extras if there already exist extras for the accommodation.
            if (accommodation.detlid || accommodation.info?.extras?.length) {
                return;
            }

            dispatch(
                fetchAdditionalItemInformation({
                    resvtype,
                    resultsetid,
                    itemId,
                    groupId,
                    skipSystemPoolExtras: cartCreatedFromReservation,
                })
            );
        });

        if (cartProducts.length === 0) {
            navigate("/");
        }

        // eslint-disable-next-line
    }, [cartProducts]);

    if (cartProducts.length === 0) {
        return null;
    }

    let pageHeading = userName ? `${userName}, ${texts?.productextrasheader}` : texts?.productextrasheader;

    return (
        <div className="container page-container product-extras-page">
            <h1 className="page-productExtrasPageHeader" style={{ paddingTop: "2rem" }}>
                {(!isLoading || !!accommodationsInCartWithExtras.length) && (
                    <>{accommodationsInCartWithExtras.length ? pageHeading : texts?.accommodationaddedtocart}</>
                )}
            </h1>
            <div className="row">
                <div className="col-md-12 col-lg-9">
                    {/* Note about validation: Using class name instead of `validated` prop or else it doesn't work */}

                    <Form noValidate onSubmit={handleSubmit} className={classnames({ "was-validated": formHasErrors })}>
                        {accommodationsInCartWithExtras.length ? (
                            <ProductExtras
                                products={accommodationsInCartWithExtras}
                                isMobile={isMobile}
                                texts={texts}
                                createdFromReservation={cartCreatedFromReservation}
                            />
                        ) : (
                            <div style={{ paddingTop: "8rem" }} />
                        )}

                        {formHasErrors && (
                            <Alert type="warning">
                                {texts.validationextrasformrequiresaction}
                                {/* Formuläret saknar val för ett eller flera tillbehör */}
                            </Alert>
                        )}

                        {/* <Button type="submit" text={texts?.generalcontinue} icon="FaArrowRight" className="mb-5" /> */}
                        <div className="row mb-4" style={isMobile ? { flexDirection: "column" } : {}}>
                            <div className="col">
                                {!myPageOnly && (
                                    <Button
                                        onClick={() => {
                                            navigate("/");
                                        }}
                                        type="button"
                                        className={isMobile ? "w-100" : ""}
                                    >
                                        <Icon name="FaArrowLeft" color="#FFFFFF" size={16} className="me-2" />
                                        {texts?.addmoreproducts}
                                    </Button>
                                )}
                                <Button
                                    type="submit"
                                    onClick={() => {
                                        navigate("/checkout");
                                    }}
                                    className={isMobile ? "w-100 mt-2" : "ms-3"}
                                >
                                    {texts?.gotothecheckout}
                                </Button>
                            </div>
                        </div>
                    </Form>
                </div>
                {!newDesignMenu && (
                    <div className="col-md-12 col-lg-3">
                        <OrderOverview
                            products={cartProducts}
                            reservationTypes={reservationTypes}
                            activeResvLid={cartProducts[0].type}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProductExtrasPage;
