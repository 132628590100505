import React from "react";
import { Skeleton, SkeletonGroup } from "@r360/library";

export const BookingSummarySkeleton = () => {
    return (
        <SkeletonGroup>
            <div className="u-mb-36 u-pt-lg-36">
                <Skeleton height="300px" />
            </div>
            <div className="u-d-flex u-flex-column u-gap-30 u-mb-36">
                {[...Array(3)].map((_el, i) => {
                    return (
                        <div key={i}>
                            <Skeleton height="200px" />
                        </div>
                    );
                })}
            </div>
        </SkeletonGroup>
    );
};
