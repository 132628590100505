import isEqual from "lodash/isEqual";
import queryString from "query-string";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { removeFilters, setFilters, setFiltersLegacy } from "../store/actions/filter";
import useThrottle from "./useThrottle";
import { optionsSelector } from "../Selectors";

const useChangeFilter = reservationTypeId => {
    const location = useLocation();
    const filterCriterias = useSelector(state => state.filter?.filterCriterias);
    const newDesignAccommodation = useSelector(state => optionsSelector(state).layout?.new_design_accommodation);

    let filterFromState = useSelector(state => state.filter)?.[reservationTypeId] || null;

    if (filterFromState && Object.keys(filterFromState).length === 0) {
        filterFromState = null;
    }

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [firstRun, setFirstRun] = useState(true);

    const getUrlForFilterFromState = useCallback(() => {
        const urlParams = queryString.parse(location.search);

        if (filterFromState) {
            urlParams["filters"] = btoa(JSON.stringify(filterFromState));
        } else if ("filters" in urlParams) {
            delete urlParams["filters"];
        }

        const search = queryString.stringify(urlParams);
        return search;
    }, [location.search, filterFromState]);

    useThrottle(
        () => {
            const urlParams = queryString.parse(location.search);

            // We handle selected filters in URL differently in new vs old design
            if (newDesignAccommodation) {
                // Only get filters from the URL on the first run with defined filterCriteras.
                // The first run will potentially trigger setFilters, which will cause this hook to run again,
                // but it will get into the "else"-statement instead.
                if (firstRun) {
                    if (urlParams["filters"]) {
                        try {
                            const filters = JSON.parse(atob(urlParams.filters));

                            if (!isEqual(filterFromState, filters)) {
                                dispatch(setFilters(filters, reservationTypeId));
                            }
                        } catch (e) {
                            dispatch(removeFilters(reservationTypeId));
                            console.error(e);
                        }
                    }

                    setFirstRun(false);
                } else {
                    // Compare the urls with filters to see if we have a diff
                    const search = getUrlForFilterFromState();
                    const previousUrl = `${location.pathname}${location.search}`;
                    const replaceUrl = search ? `${location.pathname}?${search}` : location.pathname;

                    if (previousUrl !== replaceUrl) {
                        navigate(replaceUrl, { replace: true, state: { scrollToTop: false } });
                    }
                }
            } else {
                // Legacy design

                if (!filterCriterias) {
                    return;
                }

                // Only get filters from the URL on the first run with defined filterCriteras.
                // The first run will potentially trigger setFiltersLegacy, which will cause this hook to run again,
                // but it will get into the "else"-statement instead.
                if (firstRun) {
                    if (urlParams["filters"]) {
                        try {
                            const filters = JSON.parse(atob(urlParams.filters));

                            if (!isEqual(filterFromState, filters)) {
                                dispatch(setFiltersLegacy(filters, reservationTypeId));
                            }
                        } catch (e) {
                            dispatch(removeFilters(reservationTypeId));
                            console.error(e);
                        }
                    }

                    setFirstRun(false);
                } else {
                    const search = getUrlForFilterFromState();
                    const previousUrl = `${location.pathname}${location.search}`;
                    const replaceUrl = search ? `${location.pathname}?${search}` : location.pathname;

                    if (previousUrl !== replaceUrl) {
                        navigate(replaceUrl, { replace: true, state: { scrollToTop: false } });
                    }
                }
            }
        },
        100,
        [
            dispatch,
            reservationTypeId,
            location.pathname,
            location.search,
            firstRun,
            filterFromState,
            getUrlForFilterFromState,
            filterCriterias,
            newDesignAccommodation,
        ]
    );
};

export default useChangeFilter;
