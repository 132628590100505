import {
    Button,
    ImagePlaceholder,
    Status,
    Skeleton,
    Tooltip,
    KeyIcon,
    GridIcon,
    Tag,
    ChevronDownIcon,
    ChevronUpIcon,
    Modal,
    Heading,
    OptimizedImage,
    getOptimizedImagePathFromUrl,
} from "@r360/library";
import classNames from "classnames";
import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { RootState } from "../../..";
import { formatPrice } from "../../../Helper";
import useAppSelector from "../../../hooks/useAppSelector";
import useTranslate from "../../../hooks/useTranslate";
import { TGroupedFilteredItem } from "../../../pages/AccommodationAreaPage/AccommodationAreaPage";
import { ECriteriaType, TCartItem, TCriteriaItem, TItem, TReservationResult, TSearch } from "../../../store/types";
import capitalize from "lodash/capitalize";
import { itemCritColumnClasses, itemPriceColumnClasses, itemTitleColumnClasses } from "../AvailableAccommodations";
import "./AvailableAccommodationItem.scss";
import { getInspectionDataForProduct, isAccommodation } from "../../../BusinessUtils";
import moment from "moment";
import { userSelector } from "../../../selectors/Selectors";
import OptionRow from "./OptionRow";
import useAppDispatch from "../../../hooks/useAppDispatch";
import sortBy from "lodash/sortBy";
import useItemInCart from "../../../hooks/useItemInCart";

type TAvailableAccommodationItem = {
    reservationTypeid: number;
    productListItem: TGroupedFilteredItem;
    critsToDisplay: TCriteriaItem[];
    showPrice: boolean;
    onPriceCalendarClick: (arg0: TItem) => void;
    fetchExtras: boolean;
    promoCode: string;
    onGalleryClick?: () => void;
    options: TItem[];
};

export const AvailableAccommodationItem = ({
    reservationTypeid,
    productListItem,
    critsToDisplay,
    showPrice,
    onPriceCalendarClick,
    fetchExtras,
    promoCode,
    onGalleryClick,
    options,
}: TAvailableAccommodationItem) => {
    const routeParams = useParams();
    const location = useLocation();
    const t = useTranslate();
    const dispatch = useAppDispatch();
    const loading = useAppSelector((state: RootState) => state.axiosStatus.loading);
    const reservationResult = useAppSelector((state: RootState) => state.reservationResult);
    const reservationTypeId = parseInt(routeParams.reservationTypeid ?? "", 10);
    const accomodationResult = reservationResult[reservationTypeId] as TReservationResult;
    const { arrdate, depdate, guests, checkPriceAndAvailability } = (accomodationResult?.search as TSearch) || {};
    const searchWithPriceExists = !!(arrdate && depdate && checkPriceAndAvailability);
    const hasPackages = !!options && options.length >= 2;
    const hasOneOption = !!options && options.length === 1;
    const cartProducts: TCartItem[] = useAppSelector(state => state.checkoutCart?.products);
    const accommodationCartProducts = cartProducts.filter(isAccommodation);
    // Select the cheapest item to display price from.
    const item = sortBy(productListItem.items, item => item.price)[0];
    const isOwnerItem = !!item.ownerlid;
    const isOwner = !!useAppSelector(userSelector)?.isOwner;
    const isAgent = !!useAppSelector(userSelector)?.isAgent;
    const hasOrdinaryPrice = !!(item.price && item.ordinaryprice > 0);
    const navigate = useNavigate();
    const itemHasExtras = item.info?.extras;
    const mandatoryExtras = item.info?.extras
        ?.filter(extra => extra.mandatory_web)
        .map(extra => extra.title)
        .join(", ");
    const [isOptionsOpen, setIsOptionsOpen] = useState(false);
    const [showShowTooFarApartWarning, setShowTooFarApartWarning] = useState(false);
    const [showAlreadyInCartModal, setShowAlreadyInCartModal] = useState(false);
    const getItemInCart = useItemInCart();
    const { isItemInCart } = getItemInCart(item);
    const { isDesktop, isMobile, isTablet, isIframe, iframeOffsetTop } = useAppSelector(
        (state: RootState) => state.window
    );

    const secondaryTitle = item.pricetext
        ? item.pricetext
        : hasPackages
        ? t("product_availability.accommodation_only")
        : "";

    const image = item.images.sort(
        (a, b) => parseInt(a.sortIndex === "" ? "10" : a.sortIndex) - parseInt(b.sortIndex === "" ? "10" : b.sortIndex)
    )?.[0];

    const accommodationsAreTooFarApart = () => {
        let tooFarApart = false;

        if (accommodationCartProducts.length > 0) {
            accommodationCartProducts.forEach(cartItem => {
                if (moment(cartItem.arrdate).isAfter(depdate, "day") || moment(arrdate).isAfter(cartItem.depdate)) {
                    tooFarApart = true;
                }
            });
        }

        return tooFarApart;
    };

    const handleBooking = (item: TItem) => {
        if (isItemInCart) {
            setShowAlreadyInCartModal(true);
        } else if (accommodationsAreTooFarApart()) {
            setShowTooFarApartWarning(true);
        } else {
            navigate(
                `/product-extras/${reservationTypeId}/${item.grouppoollid}/${item.poollid}/${item.unitlid}/${item.id}`
            );
        }
    };

    const renderCriteriaValue = (value: any, criteria: TCriteriaItem) => {
        if (value === undefined) {
            return "";
        }

        switch (criteria.type) {
            case ECriteriaType.BOOL:
                return <Status type={value ? "success" : "not-available"} />;

            case ECriteriaType.ENUM:
                return criteria.enums.find(enumItem => enumItem.code === value)?.title || "";

            case ECriteriaType.MIN:
            case ECriteriaType.MAX:
                return `${value} ${criteria.typeunit}`;

            default:
                return "";
        }
    };

    const renderPrice = () => {
        return (
            <div className="available-accommodation-price">
                {productListItem.items.length > 1 && !productListItem.items.every(item => item.ownerlid) && (
                    <div className="available-accommodation-price__from">{t("book.general.from")}</div>
                )}
                <div
                    className={classNames("available-accommodation-price__current-price", {
                        "available-accommodation-price__current-price--campaign": hasOrdinaryPrice || promoCode,
                    })}
                >
                    <div className="available-accommodation-price__from">{t("book.general.from")}</div>
                    {`${formatPrice(item.price, item.curr)}`}
                </div>
                {hasOrdinaryPrice && (
                    <div className="available-accommodation-price__ordinary-price">{`${formatPrice(
                        item.ordinaryprice,
                        item.curr
                    )}`}</div>
                )}
            </div>
        );
    };

    const handleReadMoreClick = () => {
        navigate(
            `/product/${reservationTypeid}/${productListItem.grouppoollid}/${productListItem.poollid}/${productListItem.unitlid}${location.search}`
        );
    };

    const handlePriceCalendarClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.stopPropagation();
        onPriceCalendarClick(item);
    };

    return (
        <div className="available-accommodation-item" {...getInspectionDataForProduct(item)}>
            <>
                <div className="available-accommodation-item__title-section">
                    <div className="available-accommodation-item__heading">
                        {isOwnerItem && (
                            <>
                                <Tooltip toolTipContent={t("book.accommodation.is_owner")}>
                                    <KeyIcon color="#F5B32B" />
                                </Tooltip>{" "}
                            </>
                        )}
                        {item.title}
                    </div>
                    <div className="u-d-flex">
                        {mandatoryExtras && !loading && item.price !== 0 && (
                            <p className="available-accommodation-item__description">
                                {`${t(
                                    isOwnerItem ? "book.accomodation.extras.mandatory" : "book.general.includes"
                                )}: ${mandatoryExtras}`}
                            </p>
                        )}
                        {loading && <Skeleton width="150" shade="light" />}
                        {promoCode && isDesktop && (
                            <Tag
                                text={t("book.general.campaign")}
                                theme="yellow"
                                size="small"
                                className="available-accommodation-item__campaign-tag"
                            />
                        )}
                    </div>
                </div>
                <div className={classNames("available-accommodation-item__title", itemTitleColumnClasses)}>
                    <div className="available-accommodation-item__img">
                        {!item.images.length && <ImagePlaceholder aspectRatio="16 / 9" iconSize={30} />}
                        {image?.url && <OptimizedImage path={getOptimizedImagePathFromUrl(image.url)} />}
                        {item.images.length > 0 && (
                            <>
                                <Button
                                    buttonClassName="available-accommodation-item__img-btn"
                                    type="secondary"
                                    buttonSize="small"
                                    onClick={onGalleryClick}
                                >
                                    <GridIcon className="available-accommodation-item__img-icon" />
                                </Button>
                            </>
                        )}
                        {promoCode && !isDesktop && (
                            <Tag
                                text={t("book.general.campaign")}
                                theme="yellow"
                                size="small"
                                className="available-accommodation-item__campaign-tag-mobile"
                            />
                        )}
                    </div>
                    <p className="available-accommodation-item__secondary-title">{secondaryTitle}</p>
                </div>
                {item.crits &&
                    critsToDisplay.map(criteria => (
                        <div
                            key={criteria.code}
                            className={classNames("available-accommodation-item__crit", itemCritColumnClasses)}
                        >
                            <div className="available-accommodation-item__crit-content">
                                <>
                                    <span className="available-accommodation-item__crit-title">
                                        {capitalize(criteria.title.toLowerCase())}
                                    </span>
                                    {criteria.code && renderCriteriaValue(item.crits[criteria.code], criteria)}
                                </>
                            </div>
                        </div>
                    ))}
                <div
                    className={classNames("available-accommodation-item__price", itemPriceColumnClasses, {
                        "available-accommodation-item__price--w-calendar": item.hasPriceCalendar,
                    })}
                >
                    {!isOwner && !isAgent && item.hasPriceCalendar && (isMobile || isTablet) && (
                        <a
                            className="available-accommodation-item__calendar-link link u-ml-12"
                            onClick={handlePriceCalendarClick}
                        >
                            {t("book.general.price_calendar")}
                        </a>
                    )}
                    {showPrice && renderPrice()}
                </div>
                <div className="available-accommodation-item__actions col-12 col-lg">
                    {searchWithPriceExists && !isOwner && (
                        <div className="available-accommodation-item__actions-buttons-container">
                            <Button
                                buttonClassName="available-accommodation-item__read-more-btn"
                                buttonSize="small"
                                type="secondary"
                                onClick={handleReadMoreClick}
                            >
                                {t("book.general.read_more")}
                            </Button>
                            {hasPackages && (
                                <Button
                                    buttonClassName="available-accommodation-item__read-more-btn"
                                    buttonSize="small"
                                    rightIcon={
                                        isOptionsOpen ? (
                                            loading ? undefined : (
                                                <ChevronUpIcon />
                                            )
                                        ) : loading ? undefined : (
                                            <ChevronDownIcon />
                                        )
                                    }
                                    loading={loading}
                                    onClick={() => !loading && setIsOptionsOpen(prevState => !prevState)}
                                >
                                    {t("book.general.book")}
                                </Button>
                            )}
                            {hasOneOption && !hasPackages && (
                                <Button
                                    buttonClassName="available-accommodation-item__read-more-btn"
                                    buttonSize="small"
                                    onClick={() => !loading && handleBooking(options[0])}
                                    loading={loading}
                                >
                                    {t("book.general.book")}
                                </Button>
                            )}
                        </div>
                    )}
                    {(!searchWithPriceExists || isOwner) && (
                        <Button
                            buttonClassName="available-accommodation-item__read-more-btn"
                            buttonSize="small"
                            onClick={handleReadMoreClick}
                        >
                            {t("book.general.price_placeholder")}
                        </Button>
                    )}

                    {!isOwner && !isAgent && item.hasPriceCalendar && !isMobile && !isTablet && (
                        <a
                            className="available-accommodation-item__calendar-link link u-ml-12"
                            onClick={handlePriceCalendarClick}
                        >
                            {t("book.general.price_calendar")}
                        </a>
                    )}
                </div>
            </>
            {isOptionsOpen && (
                <div className="available-accommodation-item__option-rows">
                    {options.map(option => (
                        <OptionRow
                            option={option}
                            key={option.id}
                            hasPackages={hasPackages}
                            guestCount={guests.length}
                            arrDate={arrdate}
                            depDate={depdate}
                            onBooking={() => handleBooking(option)}
                            accommodationCartProducts={accommodationCartProducts}
                        />
                    ))}
                </div>
            )}
            {showShowTooFarApartWarning && (
                <Modal
                    open={showShowTooFarApartWarning}
                    onClose={() => setShowTooFarApartWarning(false)}
                    {...(isIframe &&
                        isDesktop && {
                            fromTop: 120 + iframeOffsetTop + "px",
                        })}
                    {...(isIframe &&
                        (isMobile || isTablet) && {
                            fromTop: 70 + iframeOffsetTop + "px",
                        })}
                >
                    <Heading type="h3" className="u-mb-12 u-mt-12">
                        {t("book.accomodations_not_overlapping.title")}
                    </Heading>
                    <p>{t("book.accomodations_not_overlapping.description")}</p>
                </Modal>
            )}
            {showAlreadyInCartModal && (
                <Modal
                    open={showAlreadyInCartModal}
                    onClose={() => setShowAlreadyInCartModal(false)}
                    {...(isIframe &&
                        isDesktop && {
                            fromTop: 120 + iframeOffsetTop + "px",
                        })}
                    {...(isIframe &&
                        (isMobile || isTablet) && {
                            fromTop: 70 + iframeOffsetTop + "px",
                        })}
                >
                    <div>
                        <Heading type="h3" className="u-mb-12 u-mt-12">
                            {t("book.accommodation_product_already_in_cart")}
                        </Heading>
                        <p>{t("book.accomodations_product_already_in_cart.description")}.</p>
                    </div>
                    <div className="u-d-flex justify-content-end u-mt-18">
                        <Button
                            type="secondary"
                            buttonClassName="available-accommodation__already-in-cart-button"
                            onClick={() => setShowAlreadyInCartModal(false)}
                        >
                            {t("book.general.cancel")}
                        </Button>
                        <Button type="primary" onClick={() => navigate("/cart")}>
                            {t("book.general.show_cart")}
                        </Button>
                    </div>
                </Modal>
            )}
        </div>
    );
};
