import React from "react";
import { useSelector } from "react-redux";
import useTranslate from "../../hooks/useTranslate";
import { TGeneralAges, TItem } from "../../store/types";
import { TAddToCartData, TGuests, addToCartSuccesDuration } from "./MultipleVariantsCard";
import { agesSelector } from "../../selectors/Selectors";
import { useEffect, useRef, useState } from "react";
import { Button, ChevronDownIcon, ChevronUpIcon, Dropdown, TDropDownHandle } from "@r360/library";
import { TAge } from "../MenuSearch/MenuSearch";
import { formatPrice } from "../../Helper";
import Guests from "../Guests";
import { getGeneralAgeCategoryPrices } from "../../BusinessUtilsTypescript";
import { getTotalPriceByGuestCategories } from "../../BusinessUtils";

type TMultipleVariantsGuestsDropdownButton = {
    item: TItem;
    addToCartData: { [key: number]: TAddToCartData };
    onChange: (item: TItem, guests: TGuests) => void;
    handleAddToCart: (item: TItem, count: number, guests: TGuests) => void;
    addToCartSuccess: number | null;
    itemInCartQuantity?: number;
};

export const MultipleVariantsGuestsDropdownButton = ({
    item,
    handleAddToCart,
    addToCartSuccess,
    itemInCartQuantity,
}: TMultipleVariantsGuestsDropdownButton) => {
    const t = useTranslate();
    const generalAges: TGeneralAges = useSelector(agesSelector);
    const dropdownRef = useRef<TDropDownHandle>(null);
    const [, setRequiredAges] = useState<{ [key: string]: string[] }>({});
    const [guests, setGuests] = useState<TGuests>({});

    let totalGuestsCount = 0;

    // Don't add more items than available minus the ones already in the cart
    const maxToBeAdded = (item.available ?? 0) - (itemInCartQuantity ?? 0);

    const itemAddToCartSuccess = addToCartSuccess === item.id;

    const prices = getGeneralAgeCategoryPrices(item, generalAges);

    // Calculate the sum of prices assigned to each age category
    const totalPrice = getTotalPriceByGuestCategories(item, guests, generalAges);

    totalGuestsCount = Object.values(guests).reduce((sum, quantity) => sum + quantity, 0);

    const closeDropdown = () => {
        if (dropdownRef.current) {
            dropdownRef.current.setRefIsOpen();
        }
    };

    const handleGuestChange = (count: number, data: TAge) => {
        if (count < 1) {
            setGuests({ ...guests, [data.key]: 0 });
        } else {
            setGuests({ ...guests, [data.key]: count });
        }
    };

    const resetGuests = () => {
        setGuests({});
    };

    const handleAddToCartClick = () => {
        resetGuests();
        handleAddToCart(item, totalGuestsCount, guests);
    };

    useEffect(() => {
        let timeout: NodeJS.Timeout;
        if (itemAddToCartSuccess) {
            timeout = setTimeout(() => {
                closeDropdown();
            }, addToCartSuccesDuration);
        }
        return () => {
            if (!itemAddToCartSuccess) {
                clearTimeout(timeout);
            }
        };
    }, [itemAddToCartSuccess]);

    return (
        <Dropdown
            value={t("book.general.choose_quantity")}
            rightAlign
            renderCustomButton={(isOpen, onToggle) => {
                return (
                    <Button
                        onClick={() => onToggle()}
                        rightIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                        badgeCount={totalGuestsCount > 0 ? totalGuestsCount : null}
                        secondaryBadgeCount={itemInCartQuantity && itemInCartQuantity > 0 ? itemInCartQuantity : null}
                        disabled={!maxToBeAdded && !itemAddToCartSuccess}
                    >
                        {t("book.general.choose_quantity")}
                    </Button>
                );
            }}
            ref={dropdownRef}
        >
            <div className="multiple-variants-card__dropdown">
                <Guests
                    onChange={handleGuestChange}
                    filters={guests}
                    prices={prices}
                    resvType={item.type}
                    setRequiredAges={setRequiredAges}
                    max={maxToBeAdded}
                    minAge={item.minage}
                    maxAge={item.maxage}
                />
                <div className="multiple-variants-card__dropdown-price">
                    {t("book.general.total")}: {formatPrice(totalPrice, item.curr)}
                </div>
                {itemAddToCartSuccess ? (
                    <Button fullWidth type="success" buttonClassName="a-scale-11">
                        {t("book.results.added_to_cart")}
                    </Button>
                ) : (
                    <Button fullWidth onClick={handleAddToCartClick} disabled={!totalGuestsCount}>
                        {t("book.results.add_to_cart")}
                    </Button>
                )}
            </div>
        </Dropdown>
    );
};
