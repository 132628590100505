import { BarsStaggeredIcon, Button, CloseIcon, style } from "@r360/library";
import React, { useEffect, useRef, useState } from "react";
import { RootState } from "../..";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import useMemoizeArg from "../../hooks/useMemoizeArg";
import useTranslate from "../../hooks/useTranslate";
import { resvTypeByLid } from "../../selectors/Selectors";
import { clearFilters, removeFilter, setFilter } from "../../store/actions/filter";
import {
    ECriteriaPresentationType,
    TCriteriaItem,
    TCrits,
    TGroup,
    TItem,
    TReservationTypeCriterias,
    TReservationTypeLid,
} from "../../store/types";
import "./filterRow.scss";
import { FilterRowItem } from "./filterRowItem";

type TFilterRow = {
    reservationTypeId: TReservationTypeLid;
    withButton?: boolean;
    showFilter?: boolean;
};

export type TFilterChange = {
    value: number | boolean | null;
    key: number;
    type: string;
    details: TCriteriaItem;
};

export type TFilterRowList = TCriteriaItem[];

export const FilterRow = ({ reservationTypeId, withButton = false, showFilter = true }: TFilterRow) => {
    const dispatch = useAppDispatch();
    const filters = useAppSelector((state: RootState) => state.filter);
    const activeFilters = filters && reservationTypeId in filters ? filters[reservationTypeId] || {} : {};
    const t = useTranslate();
    const reservationTypeCriterias: TReservationTypeCriterias =
        useAppSelector((state: RootState) => resvTypeByLid(state, reservationTypeId))?.criterias || {};
    const [criterias, setCriterias] = useState<TCriteriaItem[]>([]);
    const searchResult: TGroup[] = Object.values(
        useAppSelector((state: RootState) => state.reservationResult[reservationTypeId]?.groups || {})
    );
    const [searchResultCriterias, setFilteredSearchResultCriteras] = useState<TCrits[]>([]);
    const [showFilterRow, setShowFilterRow] = useState(true);
    const showFilterRowState = withButton ? showFilterRow : showFilter;

    useEffect(() => {
        setCriterias([
            ...(reservationTypeCriterias[ECriteriaPresentationType.ENUM_FILTERS] || []),
            ...(reservationTypeCriterias[ECriteriaPresentationType.OTHER_FILTERS] || []),
        ]);
    }, [useMemoizeArg(reservationTypeCriterias)]);

    useEffect(() => {
        const extractedCriterias = searchResult.flatMap((group: TGroup) =>
            group.items.map((item: TItem) => item.crits)
        );

        setFilteredSearchResultCriteras(extractedCriterias);
    }, [useMemoizeArg(searchResult)]);

    const removeActiveFilter = (filter: TFilterChange) => {
        dispatch(removeFilter(filter, reservationTypeId));
    };

    const onFilterChange = (filter: TFilterChange) => {
        dispatch(setFilter(filter, reservationTypeId));
    };

    const onEnumFilterChange = (filter: TFilterChange) => {
        const critCode = filter.details.code;
        const enumCode = filter.value;

        if (activeFilters && critCode in activeFilters && activeFilters[critCode].includes(enumCode)) {
            removeActiveFilter(filter);
        } else {
            dispatch(setFilter(filter, reservationTypeId));
        }
    };

    const handleClearBtnClick = () => {
        dispatch(clearFilters(reservationTypeId));
    };

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const itemRef = useRef<HTMLLIElement | null>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (itemRef.current && !itemRef.current.contains(event.target as Node)) {
                setDropdownOpen(false);
            }
        };

        const removeEventListener = () => {
            document.removeEventListener("click", handleClickOutside, true);
        };

        if (dropdownOpen) {
            document.addEventListener("click", handleClickOutside, true);
        } else {
            removeEventListener();
        }

        return () => {
            removeEventListener();
        };
    }, [dropdownOpen]);

    return (
        <>
            <div className="filter-row">
                {withButton && (
                    <Button
                        type="tertiary"
                        leftIcon={<BarsStaggeredIcon />}
                        onClick={() => setShowFilterRow(prevState => !prevState)}
                        buttonClassName="filter-row__toggle-btn"
                    >
                        {showFilterRowState ? "Dölj filtrering" : "Visa filtrering"}
                    </Button>
                )}
                {showFilterRowState && (
                    <ul className="filter-row__list">
                        {criterias.map((filter: TCriteriaItem) => {
                            return (
                                <FilterRowItem
                                    reservationTypeId={reservationTypeId}
                                    searchResultCriterias={searchResultCriterias}
                                    key={filter.code}
                                    item={filter}
                                    onFilterChange={onFilterChange}
                                    onEnumFilterChange={onEnumFilterChange}
                                />
                            );
                        })}

                        {activeFilters && Object.values(activeFilters).length > 0 && (
                            <li>
                                <Button
                                    type="tertiary"
                                    leftIcon={<CloseIcon color={style.brandBlueColor} />}
                                    buttonClassName="filter-row__clear-btn"
                                    onClick={handleClearBtnClick}
                                >
                                    {t("book.general.clear_filter")}
                                </Button>
                            </li>
                        )}
                    </ul>
                )}
            </div>
        </>
    );
};
