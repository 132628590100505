import React, { useEffect, useRef } from "react";
import moment from "moment";
import { DateRangePicker, SingleDatePicker, FocusedInputShape } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { Button } from "@r360/library";
import "./DatePicker.scss";
import { ChevronLeftIcon, ChevronRightIcon } from "@r360/library";
import useTranslate from "../../../../hooks/useTranslate";

enum DatePickerSize {
    "small",
    "medium",
}

type TDatePicker = {
    showRange?: boolean;
    phrases?: { [key: string]: any };
    onSingleDateChange?: (startDate: moment.Moment | null) => void;
    onDoubleDatesChange?: (startDate: moment.Moment | null, endDate: moment.Moment | null) => void;
    isDayBlocked?: (day: moment.Moment) => void;
    onClearDates?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    size?: keyof typeof DatePickerSize | null;
    placeholder?: string;
    ariaLabel?: string;
    startDate?: moment.Moment | null;
    endDate?: moment.Moment | null;
    singleDate?: boolean;
    changedDate?: () => void;
    onDateChange?: () => void;
    focusChanged?: () => void;
    noBorder?: boolean;
    keepOpenOnDateSelect?: boolean;
    startDateAriaLabel?: string;
    endDateAriaLabel?: string;
    numberOfMonths?: number;
    showController?: boolean;
    startDatePlaceholderText?: string;
    endDatePlaceholderText?: string;
    focusedInput?: FocusedInputShape | null;
    focused?: boolean;
    onSingleFocusChange?: (arg: { focused: boolean }) => void;
    onDoubleFocusChange?: (arg: FocusedInputShape | null) => void;
    initialStartdate?: moment.Moment | null;
    onCloseCallback?: () => void;
    startDateTitle?: string;
    endDateTitle?: string;
};

export const DatePicker: React.FC<TDatePicker> = ({
    placeholder,
    ariaLabel,
    startDate = null,
    endDate = null,
    noBorder,
    keepOpenOnDateSelect,
    startDateAriaLabel,
    endDateAriaLabel,
    numberOfMonths,
    showRange = true,
    phrases,
    onSingleDateChange,
    onDoubleDatesChange,
    isDayBlocked,
    onClearDates,
    startDatePlaceholderText,
    endDatePlaceholderText,
    focusedInput = null,
    focused = false,
    onSingleFocusChange = () => null,
    onDoubleFocusChange = () => null,
    initialStartdate,
    onCloseCallback,
    startDateTitle = "",
    endDateTitle = "",
}: TDatePicker) => {
    const rangePickerRef = useRef<any>(null);
    const singlePickerRef = useRef<any>(null);
    const t = useTranslate();

    const NavPreviousMonth = () => {
        return (
            <div className="DayPickerNavigation_button__horizontalDefault DayPickerNavigation_leftButton__horizontalDefault">
                <ChevronLeftIcon />
            </div>
        );
    };

    const NavNextMonth = () => {
        return (
            <div className="DayPickerNavigation_button__horizontalDefault DayPickerNavigation_rightButton__horizontalDefault">
                <ChevronRightIcon />
            </div>
        );
    };

    const renderDayContents = (day: moment.Moment) => {
        return (
            <>
                <span className="CalendarDayWeekNumber">v{day.format("W")}</span>
                <div className="CalendarDay__date-outer-container">
                    <div className="CalendarDay__date-inner-container">
                        <div className="CalendarDay__date">{day.format("D")}</div>
                    </div>
                </div>
            </>
        );
    };

    const renderClearDatesButton = () => {
        return (
            <div className="date-picker__clear-dates">
                <Button buttonSize="small" type="secondary" onClick={onClearDates}>
                    {phrases?.cleardates || t("book.search.cleardates")}
                </Button>
            </div>
        );
    };

    useEffect(() => {
        const pickerRef = showRange ? rangePickerRef : singlePickerRef;

        const dateInputs = pickerRef.current.container.querySelectorAll(".DateInput");

        if (dateInputs.length === 1) {
            dateInputs[0].dataset.startdatetitle = startDateTitle;
        } else if (dateInputs.length === 2) {
            dateInputs[0].dataset.startdatetitle = startDateTitle;
            dateInputs[1].dataset.enddatetitle = endDateTitle;
        }
    }, [showRange, startDateTitle, endDateTitle]);

    /**
     * Due to limitations/old moment version in react-dates,
     * it must made sure that dates are using moment and not moment-timezone.
     */
    const convertMoment = (date: moment.Moment | null | undefined) => {
        if (date?.isValid()) {
            return moment(date.toISOString(true).substring(0, 10));
        }

        return date || null;
    };

    const renderDateRangePicker = () => {
        return (
            <DateRangePicker
                ref={rangePickerRef}
                isDayBlocked={isDayBlocked as any}
                block
                hideKeyboardShortcutsPanel
                readOnly
                transitionDuration={0}
                keepOpenOnDateSelect={keepOpenOnDateSelect}
                startDate={convertMoment(startDate)}
                startDateId={"startDateId"}
                startDatePlaceholderText={startDatePlaceholderText}
                startDateAriaLabel={startDateAriaLabel}
                endDate={convertMoment(endDate)}
                endDateId={"endDateId"}
                endDatePlaceholderText={endDatePlaceholderText}
                endDateAriaLabel={endDateAriaLabel}
                onDatesChange={({ startDate, endDate }) => {
                    onDoubleDatesChange && onDoubleDatesChange(startDate, endDate);
                }}
                focusedInput={focusedInput}
                onFocusChange={onDoubleFocusChange}
                renderDayContents={renderDayContents as any}
                firstDayOfWeek={1}
                navPrev={<NavPreviousMonth />}
                navNext={<NavNextMonth />}
                displayFormat={() => "ddd DD MMM"}
                weekDayFormat="ddd"
                numberOfMonths={numberOfMonths}
                phrases={phrases}
                noBorder={noBorder}
                initialVisibleMonth={() => convertMoment(startDate || initialStartdate) || moment()}
                calendarInfoPosition="bottom"
                renderCalendarInfo={() => renderClearDatesButton()}
                onClose={() => onCloseCallback?.()}
            />
        );
    };

    // Render date picker with only one date
    const renderSingleDatePicker = () => {
        return (
            <SingleDatePicker
                ref={singlePickerRef}
                //Using as any because of miss match in moment typing between react-dates and local moment
                isDayBlocked={isDayBlocked as any}
                block
                readOnly
                hideKeyboardShortcutsPanel
                transitionDuration={0}
                id={"startDateId"}
                date={convertMoment(startDate)}
                placeholder={placeholder}
                ariaLabel={ariaLabel}
                onDateChange={date => {
                    onSingleDateChange?.(date);
                }}
                focused={focused}
                onFocusChange={onSingleFocusChange}
                numberOfMonths={1}
                renderDayContents={renderDayContents as any}
                firstDayOfWeek={1}
                keepOpenOnDateSelect={keepOpenOnDateSelect}
                phrases={phrases}
                noBorder={noBorder || false}
                initialVisibleMonth={() => convertMoment(startDate || initialStartdate) || moment()}
                onClose={() => onCloseCallback?.()}
                calendarInfoPosition="bottom"
                navPrev={<NavPreviousMonth />}
                navNext={<NavNextMonth />}
                displayFormat={() => "ddd DD MMM"}
                weekDayFormat="ddd"
            />
        );
    };

    if (showRange) return renderDateRangePicker();

    return renderSingleDatePicker();
};
