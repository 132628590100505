import classNames from "classnames";
import React, { createRef, forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Accordion, Button, Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getGuestNameAndAge, getPackageProductTitle, scrollToContainerIfNeeded } from "../../../Helper";
import { updateUserData } from "../../../store/actions/account";
import { updateProduct } from "../../../store/actions/checkoutCart";
import { createGuestOnCart, updateGuestData } from "../../../store/actions/guests";
import PersonalDetailsInformation from "../../PersonalInformation/OldPersonalInformation/PersonalDetailsInformation";
import { Checkbox, InformationBlock, Modal } from "../../UI";
import confirmationDialog from "../../UI/ConfirmationDialog";
import "./Guests.scss";
import { textsSelector } from "../../../Selectors";

const Guests = forwardRef((props, ref) => {
    const { onlyPrimaryGuest, products, checkoutCartId, onSubmitAll } = props;

    useImperativeHandle(ref, () => ({
        submitAll: submitAll,
    }));

    // Hooks
    const dispatch = useDispatch();
    const [openedGuestForms, setOpenedGuestForms] = useState(new Set());
    const [isAddNewGuestVisible, setIsAddNewGuestVisible] = useState(false);
    const userFormRef = useRef(null);
    const newGuestFormRef = useRef(null);
    const guestRefs = useRef({});
    const [showChangeGuestFromReservationLockedModal, setShowChangeGuestFromReservationLockedModal] = useState(false);

    // Selectors
    const allGuests = useSelector(state => state.guests.guests);
    const userToken = useSelector(state => state.account.token);
    const texts = useSelector(textsSelector);
    const user = useSelector(state => state.account.user);
    const isAgent = !!user?.isAgent;

    // Remember if any traveller has a phone number,
    // not during re-renders, but only during submission of all guest forms.
    let anyTravellerHasPhoneNumber = false;

    // Create a reference for each guest that will be used to connect to their forms.
    guestRefs.current = Object.entries(allGuests)
        .filter(([, guest]) => !guest.primary)
        .reduce((acc, [guestId]) => {
            acc[guestId] = createRef();
            return acc;
        }, {});

    const guestFormExtendedValidation = formValues => {
        if (anyTravellerHasPhoneNumber) {
            return; // OK
        }

        const travellers = Object.entries(allGuests)
            .map(guestEntry => guestEntry[1])
            .filter(guest => guest.ontravel);

        anyTravellerHasPhoneNumber = travellers.some(traveller => traveller.phoneprefix && traveller.phone);

        if (anyTravellerHasPhoneNumber) {
            return; // OK
        }

        // -- Check that at least one guest has a valid phone number
        const hasPhoneNumber = !!(formValues.phone && formValues.phoneprefix);
        const isTraveller = travellers.some(traveller => traveller.id === formValues.id);

        anyTravellerHasPhoneNumber = hasPhoneNumber && isTraveller;

        if (anyTravellerHasPhoneNumber) {
            return; // OK
        }

        // ERROR
        return {
            phone: "Phone required", // Any non empty value triggers an error for this field
        };
    };

    /**
     * Submit all guest forms that are rendered. If all forms are valid,
     * trigger the onSubmitAll callback with all the guests.
     *
     * Open invalid forms if any.
     */
    const submitAll = () => {
        const userOrGuestRefs = Object.values(guestRefs.current).filter(guestRef => guestRef.current);
        userOrGuestRefs.push(userFormRef);

        const promises = userOrGuestRefs.map(userOrGuestRef => submitFormAndGetPromise(userOrGuestRef));

        Promise.all(promises)
            .then(allValues => {
                onSubmitAll && onSubmitAll(allValues);
            })
            .catch(() => {
                openAllInvalidForms();
            });
    };

    /**
     * Submit a specific guest form and get a promise.
     *
     * @param {*} userOrGuestRef The reference to a guest form.
     * @returns {Promise} A promise that is resolved with guest data if form is valid, else rejected.
     */
    const submitFormAndGetPromise = userOrGuestRef => {
        return new Promise((resolve, reject) => {
            userOrGuestRef.current.submitForm().finally(() => {
                if (userOrGuestRef.current.getIsValid()) {
                    resolve(userOrGuestRef.current.getValues());
                } else {
                    reject();
                }
            });
        });
    };

    /**
     * Open all guest forms that aren't valid.
     */
    const openAllInvalidForms = () => {
        const guestIdForInvalidForms = Object.entries(guestRefs.current)
            .filter(([, guestRef]) => guestRef.current?.getIsValid() === false)
            .map(([guestId]) => guestId);

        setOpenedGuestForms(new Set(guestIdForInvalidForms));
    };

    const openProductRemovalConfirmationDialog = productTitles => {
        return confirmationDialog({
            title: texts["checkout.guests.products_removal_confirmation.title"],
            description:
                texts["checkout.guests.products_removal_confirmation.description"] +
                "\n" +
                productTitles.map(title => `- ${title}`).join("\n"),
            okLabel: texts["general.confirmation.continue"],
            cancelLabel: texts["general.confirmation.cancel"],
        });
    };

    /**
     * Add a new guest to the cart.
     *
     * @param {Object} guest The guest to add.
     */
    const addNewGuest = guest => {
        dispatch(createGuestOnCart(checkoutCartId, userToken, guest));
    };

    /**
     * Get assigned product titles for a guest.
     *
     * @param {Object} guest The guest to get assigned product titles for.
     *
     * @returns {string[]} A list of product titles.
     */
    const getAssignedProductTitlesForGuest = guest => {
        return products
            .filter(product => {
                if (
                    (product.items &&
                        product.items.find(item => parseInt(item.guestId, 10) === parseInt(guest.id, 10))) ||
                    (product.guests && Object.values(product.guests).includes(guest.id))
                ) {
                    return true;
                }

                return false;
            })
            .map(product => getPackageProductTitle(product));
    };

    /**
     * Remove a guest for all assigned products.
     *
     * @param {number} guestId The ID of the guest.
     */
    const removeGuestFromAssignedProducts = guestId => {
        const guest = allGuests[guestId];

        if (!guest) {
            return;
        }

        if (products.length > 0) {
            products.forEach(product => {
                const items = product.items;
                const guests = product.guests;

                if (items) {
                    const guestIsConnectedToProduct = items.some(
                        item => parseInt(item.guestId, 10) === parseInt(guest.id, 10)
                    );

                    if (guestIsConnectedToProduct) {
                        dispatch(
                            updateProduct(product, {
                                key: "items",
                                value: items.map(item =>
                                    parseInt(item.guestId, 10) === parseInt(guest.id, 10)
                                        ? { ...item, guestId: "" }
                                        : item
                                ),
                            })
                        );
                    }
                } else if (guests) {
                    // The product is an accommoation.
                    const guestExistsOnProduct = Object.values(guests).includes(guest.id);

                    if (guestExistsOnProduct) {
                        dispatch(
                            updateProduct(product, {
                                key: "guests",
                                value: Object.entries(guests).reduce((acc, [index, guestId]) => {
                                    if (guest.id !== guestId) {
                                        acc[index] = guestId;
                                    }
                                    return acc;
                                }, {}),
                            })
                        );
                    }
                }
            });
        }
    };

    /**
     * Upate a field for the logged in user.
     *
     * @param {string} field The name of the field to update.
     * @param {string} value The value to set for the field.
     * @param {Function} restorePreviousValue A callback function that restores the previous value of the field.
     */
    const updateUserField = async (field, value, restorePreviousValue) => {
        // Update the user and the primary guest as they are the same person stored at two locations.
        const guests = Object.values(allGuests);
        const primaryGuest = guests.find(guest => guest.primary === true);

        if (!primaryGuest) {
            console.log("Primary guest not found when updating user field", allGuests);
        }

        if (primaryGuest && ["firstname", "lastname", "birthday", "email", "phoneprefix", "phone"].includes(field)) {
            const updatedGuestField = await updateGuestField(primaryGuest.id, field, value);

            if (!updatedGuestField) {
                restorePreviousValue && restorePreviousValue();

                return;
            }
        }

        dispatch(updateUserData(field, value));
    };

    /**
     * Update a field for a guest.
     *
     * @param {number} guestId The ID of the guest to update.
     * @param {string} field The name of the field to update.
     * @param {string} value The value to set for the field.
     * @param {Function} restorePreviousValue A callback function that restores the previous value of the field.
     */
    const updateGuestField = async (guestId, field, value, restorePreviousValue) => {
        // eslint-disable-next-line
        return new Promise(async resolve => {
            const guest = allGuests[guestId];

            // If ontravel is disabled, or if birthday is changed for a guest with ontravel set to true,
            // remove guests from assigned products.
            if ((field === "ontravel" && !value) || (field === "birthday" && guest.ontravel)) {
                const productTitles = getAssignedProductTitlesForGuest(guest);

                if (productTitles.length > 0) {
                    const confirmation = await openProductRemovalConfirmationDialog(productTitles);

                    if (!confirmation) {
                        restorePreviousValue && restorePreviousValue();

                        resolve(false);
                        return;
                    }

                    removeGuestFromAssignedProducts(guestId);
                }
            }

            dispatch(updateGuestData(guestId, field, value));

            resolve(true);
        });
    };

    /**
     * Open the form for a guest.
     *
     * @param {number} guestId The ID of the guest to open the form for.
     */
    const openGuestForm = guestId => {
        const newSet = new Set(openedGuestForms);
        newSet.add(guestId);
        setOpenedGuestForms(newSet);
    };

    /**
     * Close the form for a guest.
     *
     * @param {number} guestId The ID of the guest to close the form for.
     */
    const closeGuestForm = guestId => {
        const newSet = new Set(openedGuestForms);
        newSet.delete(guestId);
        setOpenedGuestForms(newSet);
    };

    /**
     * Check if the form is open for a guest.
     *
     * @param {number} guestId The ID of the geust to check if the form is open for.
     *
     * @returns {boolean} True if the form is open, otherwise false.
     */
    const isGuestFormOpen = guestId => openedGuestForms.has(guestId);

    /**
     * Render a modal that is shown when trying to change guest information when the cart is created
     * from a reservation.
     */
    const renderChangeGuestFromReservationLockedModal = () => (
        <Modal show={showChangeGuestFromReservationLockedModal} setShow={setShowChangeGuestFromReservationLockedModal}>
            <div className="d-flex justify-content-center">
                <Card className="border-0" style={{ width: "18rem" }}>
                    <Card.Body>
                        <Card.Title>
                            {texts["checkout.guests.change_guest_from_reservation_locked_modal.title"]}
                        </Card.Title>
                        <Card.Text>
                            {texts["checkout.guests.change_guest_from_reservation_locked_modal.description"]}
                        </Card.Text>
                    </Card.Body>
                </Card>
            </div>
        </Modal>
    );

    return (
        <div className="guests">
            <div id="user-form-container">
                <h5 className="mb-3 mt-5">{texts?.checkoutcontactinformationheader}</h5>

                <InformationBlock className="mb-3">{texts?.checkoutcontactinformationinfo}</InformationBlock>

                <Row>
                    <Col lg="8" className={classNames({ "soft-disabled": isAgent })}>
                        <PersonalDetailsInformation
                            ref={userFormRef}
                            isPrimaryGuest
                            guest={user}
                            onChange={updateUserField}
                            texts={texts}
                            showAgePicker={!isAgent}
                            skipValidation={isAgent}
                            requirePhoneNumber={!isAgent}
                        />
                    </Col>
                </Row>
            </div>

            {!onlyPrimaryGuest && (
                <>
                    <h5 className="mb-3 mt-3">{texts?.generalguests}</h5>
                    <InformationBlock>{texts?.checkoutguestsinformationtext}</InformationBlock>

                    <div className="mt-3"></div>
                    {Object.entries(allGuests)
                        // View primary guest first, followed by the disabled guests, followed by the rest.
                        .sort(
                            ([, a], [, b]) =>
                                (b.primary ? 2 : 0) +
                                (b.disabled ? 1 : 0) -
                                ((a.primary ? 2 : 0) + (a.disabled ? 1 : 0))
                        )
                        .map(([guestId, guest]) => {
                            return (
                                <Row key={guestId}>
                                    <Col md="8">
                                        <Row>
                                            <Col md="12" className="d-flex">
                                                <Checkbox
                                                    className="flex-fill"
                                                    label={getGuestNameAndAge(guest, texts?.generalyearshort)}
                                                    checked={!!guest.ontravel}
                                                    name={guestId}
                                                    onChange={({ value }) =>
                                                        updateGuestField(guestId, "ontravel", value)
                                                    }
                                                    disabled={guest.disabled}
                                                />
                                                {guest.primary && (
                                                    <Button
                                                        onClick={() => {
                                                            scrollToContainerIfNeeded("user-form-container");
                                                            userFormRef.current.highlightForm();
                                                        }}
                                                        variant="link"
                                                        size="md"
                                                        className="flex-fit fw-light text-primary p-0 guests__editGuest"
                                                    >
                                                        {texts["checkout.guests.buttons.change_user"] ||
                                                            texts?.changecustomer}
                                                    </Button>
                                                )}
                                                {!guest.primary && guest.ontravel && (
                                                    <Button
                                                        onClick={() => {
                                                            if (guest.disabled) {
                                                                setShowChangeGuestFromReservationLockedModal(true);
                                                                return;
                                                            }

                                                            isGuestFormOpen(guestId)
                                                                ? closeGuestForm(guestId)
                                                                : openGuestForm(guestId);
                                                        }}
                                                        variant="link"
                                                        size="md"
                                                        className="flex-fit fw-light text-primary p-0 guests__editGuest"
                                                    >
                                                        {texts["checkout.guests.buttons.change_guest"] ||
                                                            texts?.checkout.guests.buttons.change_guest}
                                                    </Button>
                                                )}
                                            </Col>
                                        </Row>
                                        {!guest.primary && guest.ontravel && !guest.disabled && (
                                            <Row>
                                                <Col md="12">
                                                    <Accordion.Collapse in={isGuestFormOpen(guestId)}>
                                                        <div className="guests__item">
                                                            <PersonalDetailsInformation
                                                                ref={guestRefs.current[guestId]}
                                                                isPrimaryGuest={false}
                                                                guest={guest}
                                                                onValidateBeforeSubmit={guestFormExtendedValidation}
                                                                onChange={(
                                                                    fieldName,
                                                                    fieldValue,
                                                                    restorePreviousValue
                                                                ) =>
                                                                    updateGuestField(
                                                                        guestId,
                                                                        fieldName,
                                                                        fieldValue,
                                                                        restorePreviousValue
                                                                    )
                                                                }
                                                                texts={texts}
                                                            />
                                                        </div>
                                                    </Accordion.Collapse>
                                                </Col>
                                            </Row>
                                        )}
                                    </Col>
                                </Row>
                            );
                        })}

                    <Button
                        disabled={isAddNewGuestVisible}
                        onClick={() => setIsAddNewGuestVisible(true)}
                        variant="link"
                        size="lg"
                        className="fw-light text-primary p-0 guests__addMoreGuests"
                    >
                        {texts?.checkoutaddguest}
                    </Button>

                    <Accordion.Collapse in={isAddNewGuestVisible}>
                        <Row className="mt-3">
                            <Col lg="8">
                                <p>{texts["checkout.guests.new_guest.title"]}</p>
                                <PersonalDetailsInformation
                                    ref={newGuestFormRef}
                                    isPrimaryGuest={false}
                                    newGuest
                                    showSubmitButton={true}
                                    texts={texts}
                                    onCancel={() => {
                                        setIsAddNewGuestVisible(false);
                                        newGuestFormRef.current.resetForm();
                                    }}
                                    onSubmit={values => {
                                        addNewGuest(values);
                                        setIsAddNewGuestVisible(false);
                                        newGuestFormRef.current.resetForm();
                                    }}
                                />
                            </Col>
                        </Row>
                    </Accordion.Collapse>

                    {showChangeGuestFromReservationLockedModal && renderChangeGuestFromReservationLockedModal()}
                </>
            )}
        </div>
    );
});

Guests.displayName = "Guests";

export default Guests;
