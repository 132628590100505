import { useCallback } from "react";
import useAppSelector from "./useAppSelector";

/**
 * A hook that returns a function that can be used to fetch translations.
 *
 * Usage:
 * const t = useTranslate(showMissingTextPlaceholder);
 *
 * t("book.test") will return the translation for translation key book.test.
 *
 * t("book.variable_test", ["abc", 123]) will return the translation for translation key book.variable_test
 * with "abc" mapped against %0 and 123 mapped against %1.
 * E.g. if returned translation is "The first three letters are %0 and the first three numbers are %1.", it will become
 * "The first three letters are abc and the first three numbers are 123."
 *
 * @param showMissingTextPlaceholder boolean If missing texts should return a placeholder text.
 * @returns A function that can be used to fetch translations.
 */
const useTranslate = (showMissingTextPlaceholder = true) => {
    const texts: { [key: string]: { value: string; active: boolean } } =
        useAppSelector(state => state?.clientData?.texts) || {};

    const interpolate = useCallback((text: string, interpolations: string[] = []): string => {
        const interpolatedText = interpolations.reduce((acc, interpolation, index) => {
            acc = acc.replace(`%${index}`, interpolation);
            return acc;
        }, text);

        return interpolatedText.trim();
    }, []);

    const t = useCallback(
        (key: string, interpolations: (number | string)[] = []): string => {
            if (texts[key] === undefined) {
                return showMissingTextPlaceholder ? "[Missing text]" : "";
            }
            if (!texts[key].active) {
                return "";
            }

            return interpolate(
                texts[key].value,
                // Since both string and numbers can be received as interpolations, always convert to a string.
                interpolations.map(interpolation => interpolation?.toString() || "")
            );
        },
        [texts, showMissingTextPlaceholder]
    );

    return t;
};

export default useTranslate;
