import {
    Breadcrumbs,
    Button,
    ChevronDownIcon,
    ChevronLeftIcon,
    ChevronUpIcon,
    ExpandableText,
    Heading,
    Modal,
    Skeleton,
    style,
} from "@r360/library";
import { groupBy, sortBy } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useMatch, useNavigate, useParams } from "react-router-dom";
import { RootState } from "../..";
import { optionsSelector } from "../../Selectors";
import { AvailableAccommodations } from "../../components/AvailableAccommodations/AvailableAccommodations";
import ImagePresentation from "../../components/ImagePresentation/ImagePresentation";
import { NoSearchResult } from "../../components/NoSearchResult/NoSearchResult";
import PageContainer from "../../components/PageContainer";
import PriceCalendar from "../../components/PriceCalendar/PriceCalendar";
import ProductImagesGallery from "../../components/ProductImagesGallery/ProductImagesGallery";
import { RichText } from "../../components/UI";
import { newFilterGroupItems } from "../../hooks";
import useAppSelector from "../../hooks/useAppSelector";
import useChangeFilter from "../../hooks/useChangeFilter";
import useChangeSearch from "../../hooks/useChangeSearch";
import useMemoizeArg from "../../hooks/useMemoizeArg";
import useTranslate from "../../hooks/useTranslate";
import { resvTypeByLid } from "../../selectors/Selectors";
import {
    TCriteriaItem,
    TGroup,
    TItem,
    TReservationResult,
    TReservationType,
    TReservationTypeCriterias,
} from "../../store/types";
import "./AccommodationAreaPage.scss";
import AxiosClient from "../../API/AxiosClient";
import { AccommodationMap } from "../../components/AccommodationMap/AccommodationMap";
import { setIsMapOpen } from "../../store/actions/window";
import useAppDispatch from "../../hooks/useAppDispatch";

type TProductPageImages = {
    thumbnail: string;
    original: string;
    text: string;
};

export type TGroupedFilteredItem = {
    grouppoollid: number;
    poollid: number;
    unitlid: number;
    items: TItem[];
    sortorder: number;
};

const AccommodationAreaPage = () => {
    const routeParams = useParams();
    const dispatch = useAppDispatch();
    const reservationTypeId = parseInt(routeParams.reservationTypeid ?? "", 10);
    const grouppoollid = parseInt(routeParams.grouppoollid ?? "", 10);
    const poollid = parseInt(routeParams.poollid || "", 10);
    const unitlid = parseInt(routeParams.unitlid || "", 10);
    const reservationType = useAppSelector((state: RootState) => resvTypeByLid(state, reservationTypeId));
    const reservationTypeDescription = reservationType?.description;
    const reservationResult = useAppSelector((state: RootState) => state.reservationResult);
    const accommodationResult = reservationResult[reservationTypeId] as TReservationResult;
    const skipassExtrasResult: TReservationResult | undefined =
        reservationType?.useSkipassExtra && reservationType.skipassExtraReservationTypeId
            ? reservationResult[reservationType.skipassExtraReservationTypeId]
            : undefined;
    const filters = useAppSelector((state: RootState) => state.filter);

    const activeFilters = filters[reservationTypeId];
    const showPriceCalendar = !!useMatch("/product/:reservationTypeid/:grouppoollid/:poollid/:unitlid/price_calendar");
    const [group, setGroup] = useState<TGroup>();
    const [images, setImages] = useState<TProductPageImages[]>([]);
    const [showGalleryModal, setShowGalleryModal] = useState(false);
    const [showAccommodationPriceCalendarModal, setShowAccommodationPriceCalendarModal] = useState(showPriceCalendar);
    const navigate = useNavigate();
    const [selectedPoolUnit, setSelectedPoolUnit] = useState<TGroupedFilteredItem>(Object);
    const { isDesktop, isMobile, isTablet, isIframe, iframeOffsetTop, screenSize } = useAppSelector(
        (state: RootState) => state.window
    );
    const clientData = useAppSelector((state: RootState) => state.clientData);
    const t = useTranslate();
    const [filterSearch, setFilterSearch] = useState("");
    const useFilterSearch = !!(clientData.reservationtypes as TReservationType[]).find(
        (x: TReservationType) => x.lid === reservationTypeId
    )?.useFilterSearch;
    const reservationTypeCriterias: TReservationTypeCriterias =
        useAppSelector((state: RootState) => resvTypeByLid(state, reservationTypeId))?.criterias || {};
    const lang = useAppSelector(state => state.clientData?.lang);
    const showImageDescriptions = useSelector(optionsSelector).layout?.show_image_descriptions || false;
    const { requestsLoading } = useAppSelector((state: RootState) => state.axiosStatus);
    const isLoading = requestsLoading["fetchReservationTypeResults"];
    const isMapEnabled = !!useAppSelector(optionsSelector)?.module?.maps;
    const isMapOpen = useAppSelector((state: RootState) => state.window.isMapOpen);
    const hasCoordinates = group && group.items.find(item => item.longitude !== null && item.latitude !== null);

    const toggleMap = () => {
        dispatch(setIsMapOpen(!isMapOpen));
    };

    useEffect(() => {
        AxiosClient.get("/client/mapview");
    }, []);

    useChangeSearch({
        reservationResultSearch: accommodationResult?.search,
        reservationTypeId: reservationTypeId,
        skipGuestsOnSearchWithPrice: false,
        allowSearchWithoutPrice: true,
        grouppoollid: grouppoollid,
    });

    // Search for skipass extra.
    useChangeSearch({
        reservationResultSearch: skipassExtrasResult?.search,
        reservationTypeId: reservationType?.skipassExtraReservationTypeId,
        skipGuestsOnSearchWithPrice: true,
        allowSearchWithoutPrice: false,
        disabled: !reservationType?.useSkipassExtra || !reservationType?.skipassExtraReservationTypeId,
    });

    useChangeFilter(reservationTypeId);

    useEffect(() => {
        if (accommodationResult && Object.entries(accommodationResult).length > 0) {
            if (accommodationResult?.groups) {
                const group = (Object.values(accommodationResult.groups) as TGroup[]).find(group => {
                    return group.grouppoollid === grouppoollid;
                });

                if (group) {
                    setGroup(group);
                    if (group.groupitem.images.length !== 0) {
                        const productPageImages: TProductPageImages[] = group?.groupitem?.images.map(image => ({
                            thumbnail: image.url,
                            original: image.url,
                            text: image?.translatedDesc ? image?.translatedDesc[lang] : "",
                        }));

                        setImages(productPageImages);
                    }
                }
            }
        }
    }, [accommodationResult?.resultSetId, accommodationResult?.groups, lang, grouppoollid]);

    const getFilteredItemsGroupedByPoolUnit = useCallback(
        (group: TGroup) => {
            const criterias = Object.values(reservationTypeCriterias)
                .flat()
                .reduce((acc, curr) => {
                    acc[curr.code] = curr;
                    return acc;
                }, {} as { [key: number]: TCriteriaItem });

            const filteredGroup: TGroup = newFilterGroupItems(group, activeFilters || {}, criterias);

            const filteredItems = filteredGroup.items;

            const filteredItemsInitialSortOrder = [
                ...new Set([...filteredItems.map(item => `${item.poollid}_${item.unitlid}`)]),
            ];

            // Group products by poollid/unitlid in case of using packages.
            const groupedFilteredItems = Object.entries(
                groupBy(filteredItems, item => `${item.grouppoollid}_${item.poollid}_${item.unitlid}`)
            )
                .reduce((acc: TGroupedFilteredItem[], [lidString, items]) => {
                    const [grouppoollidString, poollidString, unitlidString] = lidString.split("_");
                    const itemObject = {
                        grouppoollid: parseInt(grouppoollidString, 10),
                        poollid: parseInt(poollidString, 10),
                        unitlid: parseInt(unitlidString, 10),
                        // Sort items by price, but always position an empty pricetext first.
                        items: sortBy(items, item => (!item.pricetext ? -1 : item.price)),
                        sortorder: items[0].sortorder,
                    };
                    acc.push(itemObject);
                    return acc;
                }, [])
                .sort(
                    (a, b) =>
                        filteredItemsInitialSortOrder.indexOf(`${a.poollid}_${a.unitlid}`) -
                        filteredItemsInitialSortOrder.indexOf(`${b.poollid}_${b.unitlid}`)
                );

            return groupedFilteredItems;
        },
        [activeFilters, filters.filterCriterias, reservationTypeCriterias, reservationTypeId, filterSearch]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const items = group ? getFilteredItemsGroupedByPoolUnit(group) : [];

    useEffect(() => {
        if (!poollid || !group) {
            setShowAccommodationPriceCalendarModal(false);
            return;
        }

        const selectedPoolUnit = items.find(item => item.poollid === poollid && item.unitlid === unitlid);

        if (selectedPoolUnit && showPriceCalendar) {
            setSelectedPoolUnit(selectedPoolUnit);
            setShowAccommodationPriceCalendarModal(true);
        } else if (selectedPoolUnit) {
            setSelectedPoolUnit(selectedPoolUnit);
            setShowAccommodationPriceCalendarModal(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [poollid, unitlid, useMemoizeArg(group), showPriceCalendar, useMemoizeArg(items)]);

    const handleSelectPoolPriceCalendar = (item: TItem) => {
        const path = `/product/${reservationTypeId}/${grouppoollid}/${item.poollid}/${item.unitlid}/price_calendar`;
        if (poollid) {
            navigate(`${path}${location.search}`, { replace: true, state: { scrollToTop: false } });
        } else {
            navigate(`${path}${location.search}`, { state: { scrollToTop: false } });
        }
    };

    const handleClosePriceCalendarModal = () => {
        const path = `/product/${reservationTypeId}/${grouppoollid}`;
        navigate(`${path}${location.search}`, { replace: true, state: { scrollToTop: false } });
    };

    const isModalPastBottom = isDesktop
        ? screenSize.height < iframeOffsetTop + 800
        : screenSize.height < iframeOffsetTop + 500;

    if (!group?.groupitem) {
        return (
            <div className="u-pt-36 u-d-flex u-flex-column u-align-items-center">
                <NoSearchResult />
                <Button buttonClassName="u-mt-24 u-mb-54" leftIcon={<ChevronLeftIcon />} onClick={() => navigate("/")}>
                    {t("book.cart.back_to_selection")}
                </Button>
            </div>
        );
    }

    const breadcrumbs = [
        {
            title: reservationTypeDescription,
            path: `/search/${reservationTypeId}${location.search}`,
        },
        {
            title: group.groupitem.title,
            path: "",
        },
    ];
    return (
        <PageContainer>
            <>
                <div className="u-mb-54">
                    <Breadcrumbs breadcrumbs={breadcrumbs} />
                </div>
                <div className="accommodation-area-page__intro row">
                    <div className="accommodation-area-page__intro-description col-12 col-lg-6 col-xl-5">
                        {isDesktop && (
                            <Heading type="h1" className="u-mb-18">
                                {group?.groupitem.title}
                            </Heading>
                        )}
                        <Heading type="h2" className="u-mb-18">
                            {t("book.general.area")}
                        </Heading>
                        <ExpandableText
                            text={group?.groupitem.weblong ?? ""}
                            renderText={text => <RichText content={text} />}
                            collapsedButtonText={t("book.general.read_more")}
                            expandedButtonText={t("book.general.read_less")}
                        />
                    </div>
                    <div className="accommodation-area-page__intro-image col-12 col-lg-6 col-xl-7">
                        {!isDesktop && (
                            <Heading type="h1" className="u-mb-24">
                                {group?.groupitem.title}
                            </Heading>
                        )}
                        <ImagePresentation
                            aspectRatioX="16"
                            aspectRatioY="9"
                            spacingXInPercent="2%"
                            spacingYInPercent="1%"
                            images={images.slice(0, 1).map(image => image.original)}
                            showModalButton={images.length > 1}
                            onShowModalButtonClick={() => setShowGalleryModal(true)}
                        />
                    </div>
                </div>
                {isMapEnabled && hasCoordinates && (
                    <div className="u-d-flex u-justify-content-end u-mb-12">
                        <Button
                            type="tertiary"
                            onClick={toggleMap}
                            rightIcon={
                                isMapOpen ? (
                                    <ChevronUpIcon color={style.darkBlueColor} />
                                ) : (
                                    <ChevronDownIcon color={style.darkBlueColor} />
                                )
                            }
                            buttonClassName="edit-search-button"
                        >
                            {isMapOpen ? t("book.map.hide_map") : t("book.map.show_map")}
                        </Button>
                    </div>
                )}
                {isMapEnabled && isMapOpen && hasCoordinates && (
                    <AccommodationMap
                        accommodationResultGroups={[group]}
                        reservationTypeId={reservationTypeId}
                        isLoading={isLoading}
                        isAccommodationAreaPage={true}
                    />
                )}
                {group && (
                    <AvailableAccommodations
                        reservationTypeId={reservationTypeId}
                        groupPool={group}
                        items={items}
                        onPriceCalendarClick={handleSelectPoolPriceCalendar}
                        useFilterSearch={useFilterSearch ?? true}
                        filterSearch={filterSearch}
                        setFilterSearch={setFilterSearch}
                        accomodationResult={accommodationResult}
                    />
                )}
            </>
            <Modal
                open={showGalleryModal}
                size="lg"
                onClose={() => setShowGalleryModal(false)}
                {...(isIframe &&
                    isDesktop && {
                        fromTop: isModalPastBottom ? iframeOffsetTop - 200 + "px" : 120 + iframeOffsetTop + "px",
                    })}
                {...(isIframe &&
                    (isMobile || isTablet) && {
                        fromTop: isModalPastBottom ? iframeOffsetTop - 380 + "px" : 70 + iframeOffsetTop + "px",
                    })}
                className={"accommodation-area-page__gallery-modal"}
            >
                <ProductImagesGallery images={images} showImageDescriptions={showImageDescriptions} />
            </Modal>
            <Modal
                size="xl"
                open={showAccommodationPriceCalendarModal && !!selectedPoolUnit}
                onClose={handleClosePriceCalendarModal}
                bgColor={isDesktop ? "white" : "light-grey"}
                {...(isIframe &&
                    isDesktop && {
                        fromTop: isModalPastBottom ? iframeOffsetTop - 200 + "px" : 120 + iframeOffsetTop + "px",
                    })}
                {...(isIframe &&
                    (isTablet || isMobile) && {
                        fromTop: isModalPastBottom ? iframeOffsetTop - 380 + "px" : 70 + iframeOffsetTop + "px",
                    })}
                className={"accommodation-area-page__price-calendar-modal"}
            >
                <PriceCalendar
                    resvType={reservationTypeId}
                    groupedItem={selectedPoolUnit}
                    filteredItems={items}
                    reservationResultSearch={accommodationResult?.search}
                />
            </Modal>
        </PageContainer>
    );
};

export default AccommodationAreaPage;
