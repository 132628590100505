import React from "react";
import "./CheckoutConnectCard.scss";
import { Heading, PipeSeparator, Button, TrashIcon, style } from "@r360/library";
import { useSelector } from "react-redux";
import { RootState } from "../../..";
import useTranslate from "../../../hooks/useTranslate";
import classNames from "classnames";

export type TCheckoutConnectCardConnected = "none" | "above" | "below" | "above-and-below";

type TCheckoutConnectCard = {
    children: React.ReactElement;
    connected?: TCheckoutConnectCardConnected;
};

export const CheckoutConnectCard = ({ children, connected = "none" }: TCheckoutConnectCard) => {
    return (
        <article
            className={classNames("checkout-connect-card", {
                "checkout-connect-card--connected-below": connected === "below",
                "checkout-connect-card--connected-above": connected === "above",
                "checkout-connect-card--connected-above-and-below": connected === "above-and-below",
            })}
        >
            {children}
        </article>
    );
};

type TCheckoutConnectCardHeading = {
    primaryHeading: string;
    secondaryHeading?: string;
    secondaryHeadingColor?: string;
};

export const CheckoutConnectCardHeading = ({
    primaryHeading,
    secondaryHeading,
    secondaryHeadingColor,
}: TCheckoutConnectCardHeading) => {
    const { isDesktopNewDesign } = useSelector((state: RootState) => state.window);

    return (
        <div className="u-d-flex u-align-items-center">
            <Heading type="h2" styleAs={isDesktopNewDesign ? "h3" : "h5"}>
                {primaryHeading}
            </Heading>
            {secondaryHeading && (
                <>
                    <PipeSeparator height="25px" margin="6px" />
                    <Heading type="h3" styleAs="h5" style={{ color: secondaryHeadingColor }}>
                        {secondaryHeading}
                    </Heading>
                </>
            )}
        </div>
    );
};

type TCheckoutConnectCardRemoveBtn = {
    onClick: () => void;
    isItem?: boolean;
};

export const CheckoutConnectCardRemoveBtn = ({ onClick, isItem = false }: TCheckoutConnectCardRemoveBtn) => {
    const t = useTranslate();

    return (
        <Button type="tertiary" rightIcon={<TrashIcon color={style.brandBlueColor} />} onClick={onClick}>
            {!isItem ? t("book.general.remove_product") : t("book.checkout.remove_guest")}
        </Button>
    );
};

type TCheckoutConnectNoGuestsToConnect = {
    minAge: number;
    maxAge: number;
};

export const CheckoutConnectNoGuestsToConnect = ({ minAge, maxAge }: TCheckoutConnectNoGuestsToConnect) => {
    const t = useTranslate();

    return (
        <p className="u-color-alert u-pt-3 u-mb-0">
            <small>{t("book.checkout.no_guests_to_connect", [minAge, maxAge])}</small>
        </p>
    );
};
