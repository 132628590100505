import React from "react";
import { RootState } from "../..";
import { useSelector } from "react-redux";
import { TReservationType } from "../../store/types";
import { ActivityDesktop } from "../../assets/reservationTypes/Activity";
import { AccommodationDesktop } from "../../assets/reservationTypes/Accommodation";
import { SkipassDesktop } from "../../assets/reservationTypes/Skipass";
import { LettingDesktop } from "../../assets/reservationTypes/Letting";
import { PackageDesktop } from "../../assets/reservationTypes/Package";
import { RentalDesktop } from "../../assets/reservationTypes/Rental";
import "./GoToReservationType.scss";
import useReservationTypeLink from "../../hooks/useReservationTypeLink";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { Button } from "@r360/library";

type TGoToReservationType = {
    customOnClick?: (arg0: TReservationType) => void;
    center?: boolean;
    inCart?: boolean;
};

export const GoToReservationType = ({ customOnClick, center = true, inCart = false }: TGoToReservationType) => {
    const navigate = useNavigate();
    const reservationTypes = useSelector((state: RootState) =>
        state.clientData.reservationtypes?.filter((resType: TReservationType) => !resType.hidden)
    );
    const showImageButtonsOnBookMore = useSelector(
        (state: RootState) => state.clientData.options.layout.show_images_book_more
    );
    const reservationTypeLink = useReservationTypeLink();

    const getFallbackImage = (type: string): string => {
        switch (type) {
            case "ACTIVITY":
                return ActivityDesktop;
            case "ACCOMMODATION":
                return AccommodationDesktop;
            case "SKIPASS":
                return SkipassDesktop;
            case "RENTAL":
                return RentalDesktop;
            case "LETTING":
                return LettingDesktop;
            case "PACKAGE":
                return PackageDesktop;
            default:
                return SkipassDesktop;
        }
    };

    const handleClick = (resType: TReservationType) => {
        if (customOnClick) {
            customOnClick(resType);
            return;
        }
        navigate(reservationTypeLink(resType));
    };

    return (
        <div className="go-to-reservation-type">
            {showImageButtonsOnBookMore ? (
                <div
                    className={classNames("row u-d-flex", {
                        "u-justify-content-center": center,
                    })}
                >
                    {reservationTypes.map((resType: TReservationType) => (
                        <div
                            className={classNames("go-to-reservation-type__restype-link col-4 col-md-3 col-lg-2", {
                                "col-6 col-md-4 col-lg-6": inCart,
                            })}
                            key={resType.lid}
                            onClick={() => handleClick(resType)}
                        >
                            <div
                                className="go-to-reservation-type__restype-link-img"
                                style={{
                                    backgroundImage: `url(${resType.desktopImage || getFallbackImage(resType.type)})`,
                                }}
                            >
                                <div className="go-to-reservation-type__color-layer" />
                            </div>
                            <p className="go-to-reservation-type__restype-link-text">{resType.description}</p>
                        </div>
                    ))}
                </div>
            ) : (
                <div
                    className="go-to-reservation-type__button-section"
                    style={{ justifyContent: inCart ? "start" : "center" }}
                >
                    {reservationTypes.map((resType: TReservationType) => (
                        <Button type="primary" key={resType.lid} onClick={() => handleClick(resType)}>
                            {resType.description}
                        </Button>
                    ))}
                </div>
            )}
        </div>
    );
};
