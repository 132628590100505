import { Heading, Status } from "@r360/library";
import { capitalize } from "lodash";
import React, { useEffect, useState } from "react";
import { RootState } from "../..";
import useAppSelector from "../../hooks/useAppSelector";
import useTranslate from "../../hooks/useTranslate";
import { TCriteriaItem, TCrits } from "../../store/types";
import "./AccommodationAmenities.scss";

type TAccommodationAmenities = {
    criterias: TCriteriaItem[];
    criteriaValues: TCrits;
};

export const AccommodationAmenities = ({ criterias, criteriaValues }: TAccommodationAmenities) => {
    const t = useTranslate();
    const { isDesktopNewDesign } = useAppSelector((state: RootState) => state.window);
    const [criteriaColumns, setCriteriaColumns] = useState<TCriteriaItem[][]>([]);
    const [numberOfColumns, setNumberOfColumns] = useState(3);

    useEffect(() => {
        const newCriterias = [...criterias];
        const columnIndex = Math.floor(criterias.length / numberOfColumns);

        // The number of criterias missing in order to create three columns with an equal amount of criterias
        const equalColumnsDiff = numberOfColumns - (criterias.length - columnIndex * numberOfColumns);

        const thirdColumn = newCriterias.splice(-columnIndex);
        const secondColumn = newCriterias.splice(equalColumnsDiff === 1 ? -columnIndex - 1 : -columnIndex);
        const firstColumn = newCriterias;

        setCriteriaColumns([firstColumn, secondColumn, thirdColumn]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [numberOfColumns]);

    useEffect(() => {
        if (!isDesktopNewDesign) {
            setNumberOfColumns(2);
        } else {
            setNumberOfColumns(3);
        }
    }, [isDesktopNewDesign]);

    if (!criterias.length) return null;

    return (
        <>
            <Heading type="h2" className="u-mb-12">
                {t("book.general.amenities")}
            </Heading>
            <div className="accommodation-amenities">
                <div className="row">
                    {criteriaColumns.map(criteriaColumn => {
                        if (!criteriaColumn.length) {
                            return null;
                        }

                        return (
                            <div key={criteriaColumn[0].code} className="col-12 col-sm-6 col-xl-4">
                                <div className="accommodation-amenities__column">
                                    {criteriaColumn.map(criteria => {
                                        const value = criteriaValues[criteria.code];
                                        return (
                                            <div key={criteria.code} className="accommodation-amenity">
                                                <div className="accommodation-amenity__title">
                                                    {capitalize(criteria.title.toLowerCase())}
                                                </div>
                                                <Status type={value ? "success" : "not-available"} />
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
};
