/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useBlocker } from "./useBlocker";
import { useDispatch } from "react-redux";
import { setProductsNotAddedPath } from "../store/actions/checkoutCart";

export function useCallbackPrompt(when: boolean) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [showPrompt, setShowPrompt] = useState(false);
    const [lastLocation, setLastLocation] = useState<any>(null);
    const [confirmedNavigation, setConfirmedNavigation] = useState(false);

    const cancelNavigation = useCallback(() => {
        setShowPrompt(false);
    }, []);

    // handle blocking when user click on another route prompt will be shown
    const handleBlockedNavigation = useCallback(
        (nextLocation: any) => {
            // in if condition we are checking next location and current location are equals or not
            if (
                !confirmedNavigation &&
                (nextLocation.location.pathname !== location.pathname ||
                    nextLocation.location.search !== location.search)
            ) {
                setShowPrompt(true);
                setLastLocation(nextLocation);
                return false;
            }

            return true;
        },
        [confirmedNavigation]
    );

    const confirmNavigation = useCallback(() => {
        setShowPrompt(false);
        setConfirmedNavigation(true);
        dispatch(setProductsNotAddedPath(""));
    }, []);

    useEffect(() => {
        if (confirmedNavigation && lastLocation) {
            // Since useNavigate prepends the url with the basepath we need to remove the basepath from lastLocation or it will be added twice
            const location = lastLocation.location.pathname.replace(process.env.PUBLIC_URL, "");
            const search = lastLocation.location.search;

            navigate(`${location}${search ?? ""}`);
            setConfirmedNavigation(false);
        }
    }, [confirmedNavigation, lastLocation]);

    useBlocker(handleBlockedNavigation, when);

    return { showPrompt, confirmNavigation, cancelNavigation };
}
