import { sendMessage } from "../Helper";

/**
 * Signals are used to signal a parent container that some interesting stuff has happened.
 *
 * We use signals to communicate information between parent <-> iframe child to bypass third-party limitations in browsers
 * that blocks cookies, localStorage etc.
 *
 * An example of a signal may be a 'checkout' signal that contains information about a reservation before it's paid.
 *
 * The structure of a signal looks like REDUX actions to simply use existing formats that we know work really good.
 *
 * Signals may be tracked in parent to e.g push application data to Google Tag Manager etc.
 *
 * @param object signal (Create a signal using the function `signal()`)
 */
export const sendSignal = signal => {
    sendMessage({
        event: "signal",
        signal: signal,
    });

    console.log("Signaling (send):", signal);
};

/**
 * Create a signal to track actions of interest in the application and send them to a PostMessage API listener
 *
 * @param string type
 * @param object payload Data you want to allow parents to track
 * @param object meta (optional)
 * @returns
 */
export const signal = (type, payload = {}, meta = {}) => ({
    type,
    meta,
    payload,
});
