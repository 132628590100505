import { Formik, FormikProps } from "formik";
import React, { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Input } from "@r360/library";
import { isFieldValid } from "./PersonalInformation";
import { verifyWTPNumber } from "../../Helper";
import useTranslate from "../../hooks/useTranslate";

type TPersonalKeycardInformation = {
    keycardNo: string;
    imagehash: string;
    guestlid: number;
    onSubmit?: (value: TPersonalKeycardForm) => void;
    showKeycardNo: boolean;
};

export type TPersonalKeycardForm = {
    keycardNo: string;
};

const PersonalKeycardInformation = forwardRef(
    ({ keycardNo, onSubmit, showKeycardNo }: TPersonalKeycardInformation, ref) => {
        const t = useTranslate();
        const formikRef = useRef<FormikProps<TPersonalKeycardForm>>(null);
        const [validKeycardNo, setValidKeycardNo] = useState(false);
        const [validatingKeycardNo, setValidatingKeycardNo] = useState(false);

        useImperativeHandle(ref, () => ({
            submitForm: formikRef.current?.submitForm,
            resetForm: formikRef.current?.resetForm,
            resetTouched: () => formikRef.current?.setTouched({}),
        }));

        const customHandleSubmit = () => {
            formikRef.current && onSubmit?.(formikRef.current.values);
        };

        const validateKeycardNo = (value: string) => {
            if (value === "") {
                return;
            }

            setValidatingKeycardNo(true);

            return verifyWTPNumber(value)
                .then((response: any) => {
                    const valid = response.data.payload.valid;

                    setValidKeycardNo(valid);
                    setValidatingKeycardNo(false);

                    if (!valid) {
                        return "Invalid keycard number";
                    }
                })
                .catch((error: any) => {
                    console.log(error);
                    setValidatingKeycardNo(false);
                    return "Invalid keycard number";
                });
        };

        return (
            <>
                <Formik
                    innerRef={formikRef}
                    onSubmit={customHandleSubmit}
                    initialValues={
                        {
                            ...(showKeycardNo && { keycardNo: keycardNo || "" }),
                        } as TPersonalKeycardForm
                    }
                    validateOnChange={false}
                    validateOnBlur={false}
                >
                    {({ handleSubmit, handleChange, values, touched, errors }) => {
                        return (
                            <form onSubmit={handleSubmit} noValidate>
                                {showKeycardNo && (
                                    <div>
                                        <Input
                                            label={`${t("book.checkout.keycard_number")} (Skipass)`}
                                            required
                                            name="keycardNo"
                                            value={values.keycardNo}
                                            isValid={
                                                !values.keycardNo
                                                    ? undefined
                                                    : isFieldValid(touched.keycardNo, errors.keycardNo) &&
                                                      validKeycardNo
                                            }
                                            onChange={handleChange}
                                            onBlur={e => validateKeycardNo(e.target.value)}
                                            withSubmitButton
                                            submitButtonLabel="OK"
                                            onSubmitButtonPress={() => formikRef.current?.submitForm()}
                                            disabled={validatingKeycardNo}
                                        />
                                        {!validatingKeycardNo &&
                                            !validKeycardNo &&
                                            touched.keycardNo &&
                                            values.keycardNo && (
                                                <p className="u-color-alert u-pt-6">{t("book.keycard_invalid")}</p>
                                            )}
                                    </div>
                                )}
                            </form>
                        );
                    }}
                </Formik>
            </>
        );
    }
);

PersonalKeycardInformation.displayName = "PersonalKeycardInformation";

export default PersonalKeycardInformation;
