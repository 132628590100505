import React, { createRef, forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { ExpandableSection } from "../../ExpandableSection";
import {
    Button,
    Checkbox,
    Heading,
    UserIcon,
    UserPlusIcon,
    style,
    Notification,
    UserTwoIcon,
    Select,
    TSelectOption,
    ConfirmationModal,
    scrollToElement,
} from "@r360/library";
import { useSelector } from "react-redux";
import { updateUserData } from "../../../store/actions/account";
import { TCartItem, TGuest, TGuestsOnTravelReducer } from "../../../store/types";
import { createGuestOnCart, updateGuestData } from "../../../store/actions/guests";
import { getGuestNameAndAge, getPackageProductTitle, scrollToContainerIfNeeded } from "../../../Helper";
import PersonalDetailsInformation, {
    TPersonalDetailsInformationHandle,
} from "../../PersonalInformation/PersonalDetailsInformation";
import classNames from "classnames";
import "./CheckoutConnectGuests.scss";
import { CloseButton } from "react-bootstrap";
import { CheckoutConnectProceedButton } from "../CheckoutConnectProceedButtton";
import useAppDispatch from "../../../hooks/useAppDispatch";
import { RootState } from "../../..";
import { StaticSection, TSectionState, TSectionStates } from "../../../pages/CheckoutConnectPage/CheckoutConnectPage";
import useTranslate from "../../../hooks/useTranslate";
import { updateProduct } from "../../../store/actions/checkoutCart";
import { guestsOnTravelSelector } from "../../../selectors/Selectors";
import moment from "moment";
import { TPersonalInformationGuest } from "../../PersonalInformation/PersonalInformation";

type TCheckoutConnectGuests = {
    sectionStates: TSectionStates;
    changeSectionState: (arg0: string, arg1: TSectionState) => void;
    onlyPrimaryGuest: boolean;
    allGuests: TGuestsOnTravelReducer;
    products: TCartItem[];
    checkoutCartId: string;
    onSubmitAll: (arg0: any) => void;
    bookingForOthers: boolean | null;
    isAgent?: boolean;
    createdFromReservation: string | null;
};

export type TCheckoutConnectGuestsHandle = {
    getPrimaryGuestAssignedTitles: string[] | undefined;
    submitAll: () => void;
};

export const CheckoutConnectGuests = forwardRef(
    (
        {
            sectionStates,
            changeSectionState,
            onlyPrimaryGuest,
            allGuests,
            products,
            checkoutCartId,
            onSubmitAll,
            bookingForOthers,
            isAgent = false,
            createdFromReservation,
        }: TCheckoutConnectGuests,
        ref
    ) => {
        const t = useTranslate();
        const dispatch = useAppDispatch();

        const user = useSelector((state: RootState) => state.account.user);
        const userToken = useSelector((state: RootState) => state.account.token);
        const guestsOnTravel = useSelector(guestsOnTravelSelector);
        const { isMobile, isTablet, isDesktop, isIframe, iframeOffsetTop } = useSelector(
            (state: RootState) => state.window
        );

        const userFormRef = useRef<TPersonalDetailsInformationHandle | null>(null);
        const guestRefs = useRef<{ [key: string]: any }>();
        const newGuestFormRef = useRef<TPersonalDetailsInformationHandle | null>(null);
        const primaryGuest = Object.values(allGuests).find(guest => guest.primary);
        const initGuestsValid = Object.entries(allGuests)
            .filter(([, guest]) => !guest.primary)
            .reduce((acc: { [key: string]: boolean | undefined }, [guestId]) => {
                acc[guestId] = undefined;
                return acc;
            }, {});
        const [guestsValid, setGuestsValid] = useState<{ [key: string]: boolean | undefined }>(initGuestsValid);
        const [atLeastOnePhoneNumber, setAtLeastOnePhoneNumber] = useState(true);
        const [guestToEdit, setGuestToEdit] = useState("");
        const [isAddNewGuestFormOpen, setIsAddNewGuestFormOpen] = useState(false);
        const sectionState = sectionStates[StaticSection.GUESTS];
        const [guestToRemove, setGuestToRemove] = useState<{
            guest: TGuest;
            productTitles: string[];
            onConfirm: () => void;
            onCancel: () => void | undefined;
        } | null>(null);
        const [noAdultGuests, setNoAdultGuests] = useState(false);
        // Check if any guests that is on the travel have errors
        const invalidGuests = Object.entries(guestsValid).some(
            ([key, value]) => guestsOnTravel[key] && value === false
        );

        useImperativeHandle(ref, () => ({
            getPrimaryGuestAssignedTitles: primaryGuest && getAssignedProductTitlesForGuest(primaryGuest),
            submitAll: submitAll,
        }));

        useEffect(() => {
            checkUserValidation();
            // eslint-disable-next-line
        }, []);

        // Close add guest-forms when section is closed
        useEffect(() => {
            if (!sectionState.expanded) {
                setGuestToEdit("");
            }
        }, [sectionState.expanded]);

        useEffect(() => {
            setAtLeastOnePhoneNumber(checkAtLeastOnePhoneNumber());
            // eslint-disable-next-line
        }, [allGuests, guestsOnTravel]);

        // Update the section-state-statuses when errors occur
        useEffect(() => {
            if (invalidGuests) {
                changeSectionState(StaticSection.GUESTS, { status: "error" });
            } else if (!atLeastOnePhoneNumber && Object.values(guestsOnTravel).length > 0) {
                changeSectionState(StaticSection.GUESTS, {
                    status: "error",
                    statusMessage: t("book.checkout.at_least_one_phone"),
                });
            } else if (sectionState.status === "error" && Object.values(guestsOnTravel).length > 0) {
                changeSectionState(StaticSection.GUESTS, { status: "success", statusMessage: "" });
            } else if (!Object.values(guestsOnTravel).length) {
                changeSectionState(StaticSection.GUESTS, {
                    status: "error",
                    statusMessage: t("book.checkout.no_guests_on_travel"),
                });
            }
            // eslint-disable-next-line
        }, [invalidGuests, atLeastOnePhoneNumber, guestsOnTravel, bookingForOthers]);

        // Handle sideeffects when swithing from booking for others or booking for myself and vice versa
        useEffect(() => {
            setGuestToEdit("");
            setIsAddNewGuestFormOpen(false);
        }, [bookingForOthers]);

        useEffect(() => {
            // Check if any guest is primaryIfBookingForOthers
            const anyTravelingGuestHasPrimaryIfBookingForOthers = Object.values(guestsOnTravel).some(
                guest => guest.primaryIfBookingForOthers
            );

            if (bookingForOthers) {
                // Set the first traveling adult guest as primaryIfBookingForOthers when setting bookingForOthers to true
                if (
                    !anyTravelingGuestHasPrimaryIfBookingForOthers &&
                    Object.keys(guestsOnTravel).length > 0 &&
                    !onlyPrimaryGuest
                ) {
                    // Is there any adult guests?
                    const anyAdultGuests = Object.values(allGuests).some(
                        guest => isGuestAdult(guest) && !guest.primary
                    );

                    if (!anyAdultGuests) {
                        // If no adult guest, set state so we can show a warning
                        setNoAdultGuests(true);
                    } else {
                        noAdultGuests && setNoAdultGuests(false);

                        // Find the first traveling adult guest
                        const firstAdultGuest = Object.values(guestsOnTravel).find(
                            guest => isGuestAdult(guest) && !guest.primary && !guest.primaryIfBookingForOthers
                        );

                        // Set the adult guest as primaryIfBookingForOthers
                        if (firstAdultGuest) {
                            updateGuestField(firstAdultGuest.guestlid, "primaryIfBookingForOthers", true);
                        }
                    }
                }
                // Remove the primary guest from the travel and de-connect all his/her products
                if (primaryGuest?.id && primaryGuest.ontravel) {
                    updateGuestField(primaryGuest.id, "ontravel", false);
                }
            } else {
                // Make sure the primary guest is in guestsOnTravel when switching to booking for myself
                if (primaryGuest && primaryGuest?.ontravel === false) {
                    updateGuestField(primaryGuest.id, "ontravel", true);
                }

                // Remove all primaryIfBookinForOthers on all guests
                if (anyTravelingGuestHasPrimaryIfBookingForOthers) {
                    removePrimaryIfBookingForOthersOnGuests();
                }
            }
            // eslint-disable-next-line
        }, [bookingForOthers, guestsOnTravel, allGuests]);

        const checkUserValidation = () => {
            const userIsValid = userFormRef.current?.getIsValid();

            if (userIsValid) {
                changeSectionState(StaticSection.USER, { status: "success" });
            } else {
                changeSectionState(StaticSection.USER, { status: "error" });
                changeSectionState(StaticSection.USER, { expanded: true });
            }
        };

        // Create a reference for each guest that will be used to connect to their forms.
        guestRefs.current = Object.entries(allGuests)
            .filter(([, guest]) => !guest.primary)
            .reduce((acc: { [key: string]: any }, [guestId]) => {
                acc[guestId] = createRef();
                return acc;
            }, {});

        /**
         * Upate a field for the logged in user.
         *
         * @param {string} field The name of the field to update.
         * @param {string} value The value to set for the field.
         * @param {Function} restorePreviousValue A callback function that restores the previous value of the field.
         */
        const updateUserField = async (field: string, value: string | number) => {
            // Update the user and the primary guest as they are the same person stored at two locations.
            const guests = Object.values(allGuests);
            const primaryGuest = guests.find(guest => guest.primary === true);

            if (!primaryGuest) {
                console.log("Primary guest not found when updating user field", allGuests);
            }

            if (
                primaryGuest &&
                ["firstname", "lastname", "birthday", "email", "phoneprefix", "phone"].includes(field)
            ) {
                updateGuestField(primaryGuest.id, field, value);
            }

            dispatch(updateUserData(field, value));
        };

        /**
         * Update a field for a guest.
         *
         * @param {number} guestId The ID of the guest to update.
         * @param {string} field The name of the field to update.
         * @param {string} value The value to set for the field.
         * @param {Function} restorePreviousValue A callback function that restores the previous value of the field.
         */
        const updateGuestField = (guestId: number, field: string, value: any, restorePreviousValue?: () => void) => {
            const guest = allGuests[guestId];

            /* If ontravel is disabled, or if birthday is changed for a guest with ontravel set to true,
                  remove guests from assigned products. */
            if ((field === "ontravel" && !value) || (field === "birthday" && guest.ontravel)) {
                const productTitles = getAssignedProductTitlesForGuest(guest);

                // If the guest has products and is not primary, we show a confirmation modal and do an early return
                if (productTitles.length > 0 && !guest.primary) {
                    setGuestToRemove({
                        guest,
                        productTitles,
                        onConfirm: () => {
                            removeGuestFromAssignedProducts(guestId.toString());
                            dispatch(updateGuestData(guestId, field, value));
                        },
                        onCancel: () => restorePreviousValue?.(),
                    });
                    return;
                } else if (productTitles.length > 0 && guest.primary) {
                    // If the guest is primary we just remove the guest from assigned products since the confirmation's already happened
                    removeGuestFromAssignedProducts(guestId.toString());
                }
            }

            dispatch(updateGuestData(guestId, field, value));
        };

        /**
         * Add a new guest to the cart.
         *
         * @param {TGuest} guest The guest to add.
         */
        const addNewGuest = (guest: TGuest) => {
            dispatch(createGuestOnCart(checkoutCartId, userToken, guest));
        };

        /**
         * Remove a guest for all assigned products.
         *
         * @param {number} guestId The ID of the guest.
         */
        const removeGuestFromAssignedProducts = (guestId: string) => {
            const guest = allGuests[guestId];

            if (!guest) {
                return;
            }

            if (products.length > 0) {
                products.forEach(product => {
                    const items = product.items;
                    const guests = product.guests;

                    if (items) {
                        const anyItemIsConnectedToGuest = items.some(
                            item => parseInt(item.guestId ?? "", 10) === guest.id
                        );

                        if (anyItemIsConnectedToGuest) {
                            dispatch(
                                updateProduct(product, {
                                    key: "items",
                                    value: items.map(item =>
                                        item.guestId == guestId ? { ...item, guestId: "" } : item
                                    ),
                                })
                            );

                            // Since we removed a connected guest from a product, we need to set an error on that section
                            changeSectionState(product.type.toString(), { status: "error", expanded: true });
                        }
                    } else if (guests) {
                        // The product is an accommodation.
                        const guestExistsOnProduct = Object.values(guests).includes(guest.id);

                        if (guestExistsOnProduct) {
                            dispatch(
                                updateProduct(product, {
                                    key: "guests",
                                    value: Object.entries(guests).reduce(
                                        (acc: { [key: string]: number }, [index, guestId]) => {
                                            if (guest.id !== guestId) {
                                                acc[index] = guestId;
                                            }
                                            return acc;
                                        },
                                        {}
                                    ),
                                })
                            );

                            // Since we removed a connected guest from a product, we need to set an error on that section
                            changeSectionState(product.type.toString(), { status: "error", expanded: true });
                        }
                    }
                });

                setGuestToRemove(null);
            }
        };

        const getAssignedProductTitlesForGuest = (guest: TGuest) => {
            return products
                .filter(product => {
                    if (
                        (product.items && product.items.find(item => parseInt(item.guestId ?? "", 10) === guest.id)) ||
                        (product.guests && Object.values(product.guests).includes(guest.id))
                    ) {
                        return true;
                    }

                    return false;
                })
                .map(product => getPackageProductTitle(product, true));
        };

        /**
         * Submit all guest forms that are rendered. If all forms are valid,
         * trigger the onSubmitAll callback with all the guests.
         *
         * Open invalid forms if any.
         */
        const submitAll = () => {
            if (!Object.values(guestsOnTravel).length) {
                changeSectionState(StaticSection.GUESTS, {
                    status: "error",
                    statusMessage: t("book.checkout.no_guests_on_travel"),
                });
                return;
            }

            const userOrGuestRefs =
                guestRefs.current && Object.values(guestRefs.current).filter(guestRef => guestRef.current);
            userOrGuestRefs?.push(userFormRef);

            const promises = userOrGuestRefs?.map(userOrGuestRef => submitFormAndGetPromise(userOrGuestRef));

            if (promises) {
                Promise.all(promises)
                    .then(allValues => {
                        onSubmitAll?.(allValues);
                    })
                    .catch(err => {
                        console.log(err);
                        setInvalidGuests();
                    });
            }
        };

        /**
         * Submit a specific guest form and get a promise.
         *
         * @param {*} userOrGuestRef The reference to a guest form.
         * @returns {Promise} A promise that is resolved with guest data if form is valid, else rejected.
         */
        const submitFormAndGetPromise = (userOrGuestRef: any) => {
            return new Promise((resolve, reject) => {
                userOrGuestRef.current.submitForm().finally(() => {
                    if (userOrGuestRef.current.getIsValid()) {
                        resolve(userOrGuestRef.current.getValues());
                    } else {
                        reject();
                    }
                });
            });
        };

        const setInvalidGuests = () => {
            const guestIdForInvalidForms =
                guestRefs.current &&
                Object.entries(guestRefs.current)
                    .filter(([, guestRef]) => guestRef.current?.getIsValid() === false)
                    .map(([guestId]) => guestId);

            new Set(guestIdForInvalidForms)?.forEach(id => {
                setGuestsValid(prevState => ({ ...prevState, [id]: false }));
            });
        };

        // Remember if any traveller has a phone number,
        // not during re-renders, but only during submission of all guest forms.
        let anyTravellerHasPhoneNumber = false;

        const checkAtLeastOnePhoneNumber = () => {
            if (anyTravellerHasPhoneNumber) {
                return true;
            }

            anyTravellerHasPhoneNumber = Object.values(guestsOnTravel).some(guest => guest.phoneprefix && guest.phone);

            if (anyTravellerHasPhoneNumber) {
                return true;
            }

            return false;
        };

        const isGuestFormOpen = (guestId: string) => guestId === guestToEdit;
        const isGuestAdult = (guest: TGuest) => moment().diff(moment(guest.birthday), "years") > 18;

        // Set a guest as primaryIfBookingForOthers
        const handleSelectPrimaryIfBookingForOthers = (value: string) => {
            removePrimaryIfBookingForOthersOnGuests();
            updateGuestField(parseInt(value), "primaryIfBookingForOthers", true);

            // If onlyPrimaryGuest, also include this guest on the travel
            if (onlyPrimaryGuest) {
                updateGuestField(parseInt(value), "ontravel", true);
            }
        };

        // If another guest has primaryIfBookingForOthers set to true, set it to false since only one guest can be primaryIfBookingForOthers
        const removePrimaryIfBookingForOthersOnGuests = () => {
            Object.entries(allGuests).forEach(([guestId, guest]) => {
                if (guest.primaryIfBookingForOthers) {
                    updateGuestField(parseInt(guestId), "primaryIfBookingForOthers", false);
                }

                // If onlyPrimaryGuest, also remove this guest from travel
                if (onlyPrimaryGuest && guest.ontravel) {
                    updateGuestField(parseInt(guestId), "ontravel", false);
                }
            });
        };

        const sortGuests = (a: [string, TGuest], b: [string, TGuest]) => {
            const [first, second] = [a[1], b[1]];
            const sortAlpabetically = first.firstname?.localeCompare(second.firstname);
            const sortByPrimary = first.primary === second.primary ? 0 : first.primary ? -1 : 1;
            const sortByPrimaryIfBookingForOthers =
                first.primaryIfBookingForOthers === second.primaryIfBookingForOthers
                    ? 0
                    : first.primaryIfBookingForOthers
                    ? -1
                    : 1;

            if (!bookingForOthers && !first.primary && !second.primary) {
                return sortAlpabetically;
            }

            if (first.primary || second.primary) {
                return sortByPrimary;
            }

            if (first.primaryIfBookingForOthers || second.primaryIfBookingForOthers) {
                return sortByPrimaryIfBookingForOthers;
            } else {
                return sortAlpabetically;
            }
        };

        return (
            <>
                <div id="user-form-section" className="u-mb-24">
                    <ExpandableSection
                        expanded={sectionStates[StaticSection.USER].expanded}
                        onToggle={() =>
                            changeSectionState(StaticSection.USER, {
                                expanded: !sectionStates[StaticSection.USER].expanded,
                            })
                        }
                        title={t("book.checkout.information_details")}
                        status={sectionStates[StaticSection.USER].status}
                        icon={<UserIcon color={style.whiteColor} />}
                        disabled={sectionStates[StaticSection.USER].disabled}
                    >
                        <>
                            {isAgent && (
                                <div className="u-mb-24">
                                    <Notification type="info">
                                        <p className="u-mb-0">
                                            {t("book.checkout.contact_resort_to_change_information")}
                                        </p>
                                    </Notification>
                                </div>
                            )}
                            <PersonalDetailsInformation
                                ref={userFormRef}
                                isPrimaryGuest
                                guest={user as TPersonalInformationGuest}
                                onChange={updateUserField}
                                showAgePicker={!isAgent}
                                skipValidation={isAgent}
                                onLiveValidation={valid =>
                                    changeSectionState(StaticSection.USER, { status: valid ? "success" : "error" })
                                }
                                disabled={isAgent}
                            />
                        </>
                    </ExpandableSection>
                </div>
                {!sectionState.hidden && (
                    <div id="guest-form-section" className="u-mb-24">
                        <ExpandableSection
                            expanded={sectionState.expanded}
                            onToggle={() =>
                                changeSectionState(StaticSection.GUESTS, {
                                    expanded: !sectionStates[StaticSection.GUESTS].expanded,
                                })
                            }
                            title={
                                onlyPrimaryGuest
                                    ? t("book.checkout.who_is_responsible_guest")
                                    : t("book.checkout.guests_section_title", [
                                          `${Object.values(guestsOnTravel).length}
                                          ${!isMobile ? t("book.general.chosen") : ""}`,
                                      ])
                            }
                            status={
                                sectionState.status === "success"
                                    ? "success"
                                    : sectionState.status === "error"
                                    ? "error"
                                    : undefined
                            }
                            icon={<UserTwoIcon color={style.whiteColor} />}
                            disabled={sectionStates[StaticSection.GUESTS].disabled}
                        >
                            <>
                                {sectionStates[StaticSection.GUESTS].status === "error" &&
                                    sectionStates[StaticSection.GUESTS].statusMessage && (
                                        <div className="u-mb-18">
                                            <Notification type="error">
                                                <p className="u-mb-0">
                                                    {sectionStates[StaticSection.GUESTS].statusMessage}
                                                </p>
                                            </Notification>
                                        </div>
                                    )}
                                <div className="u-mb-24">
                                    <Notification type="info">
                                        <p className="u-mb-0">{t("book.checkout.guests_can_be_removed")}</p>
                                    </Notification>
                                </div>
                                {bookingForOthers && noAdultGuests && (
                                    <div className="u-mb-24">
                                        <Notification type="info">
                                            <p className="u-mb-0">{t("book.checkout.no_adult_guests")}</p>
                                        </Notification>
                                    </div>
                                )}
                                {!onlyPrimaryGuest && (
                                    <div className="row u-bs-gutters-6 u-mb-24">
                                        {Object.entries(allGuests)
                                            .filter(guest => (bookingForOthers ? !guest[1].primary : true))
                                            .sort(sortGuests)
                                            .map(([guestId, guest]) => {
                                                const isPrimaryGuest = guest.primary;
                                                let disableToggle = false;
                                                const contactPerson =
                                                    isPrimaryGuest ||
                                                    (guest.primaryIfBookingForOthers && bookingForOthers);

                                                const toggleGuest = () => {
                                                    // Can't remove a contact person the travel
                                                    if (!contactPerson && !guest.disabled) {
                                                        updateGuestField(
                                                            parseInt(guestId),
                                                            "ontravel",
                                                            !guest.ontravel
                                                        );
                                                    }
                                                };

                                                return (
                                                    <div
                                                        key={guestId}
                                                        className={contactPerson ? "col-12" : "col-xl-6"}
                                                    >
                                                        <div
                                                            className={classNames("checkout-connect-guests__guest", {
                                                                "checkout-connect-guests__guest--on-travel":
                                                                    !!guest.ontravel,
                                                                "checkout-connect-guests__guest--error":
                                                                    guest.ontravel && guestsValid[guestId] === false,
                                                                // "checkout-connect-guests__guest--disabled":
                                                                //     guest.disabled,
                                                            })}
                                                            onClick={() => (!disableToggle ? toggleGuest() : null)}
                                                        >
                                                            <div style={{ pointerEvents: "none" }}>
                                                                <Checkbox
                                                                    readOnly
                                                                    name={guestId}
                                                                    label={`${getGuestNameAndAge(guest, "år", {})} ${
                                                                        contactPerson
                                                                            ? `(${t(
                                                                                  "book.checkout.responsible_guest"
                                                                              )})`
                                                                            : ""
                                                                    }`}
                                                                    checked={!!guest.ontravel}
                                                                    disabled={guest.disabled}
                                                                />
                                                            </div>
                                                            <Button
                                                                type="tertiary"
                                                                onClick={() => {
                                                                    if (isPrimaryGuest) {
                                                                        scrollToElement(
                                                                            "user-form-section",
                                                                            undefined,
                                                                            isMobile ? "start" : "center"
                                                                        );
                                                                        changeSectionState(StaticSection.USER, {
                                                                            expanded: true,
                                                                        });
                                                                    } else {
                                                                        scrollToElement(
                                                                            "edit-guest-form",
                                                                            undefined,
                                                                            isMobile ? "start" : "center"
                                                                        );
                                                                        disableToggle = true;
                                                                        setGuestToEdit(
                                                                            isGuestFormOpen(guestId) ? "" : guestId
                                                                        );
                                                                        setTimeout(() => {
                                                                            disableToggle = false;
                                                                        }, 0);
                                                                    }
                                                                }}
                                                                disabled={!guest.ontravel || guest.disabled}
                                                            >
                                                                {isPrimaryGuest && !isMobile
                                                                    ? t("book.checkout.change_contact_details")
                                                                    : t("book.general.edit")}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        <div className="col-12 col-lg-6">
                                            <div
                                                className="checkout-connect-guests__guest checkout-connect-guests__guest--add-guest"
                                                onClick={() => {
                                                    scrollToElement(
                                                        "edit-guest-form",
                                                        undefined,
                                                        isMobile ? "start" : "center"
                                                    );
                                                    setIsAddNewGuestFormOpen(true);
                                                }}
                                            >
                                                <span>{t("book.checkout.add_guest")}</span>
                                                <UserPlusIcon size={24} />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div id="edit-guest-form">
                                    {Object.entries(allGuests).map(([guestId, guest]) => {
                                        return (
                                            !guest.primary &&
                                            guest.ontravel &&
                                            !guest.disabled && (
                                                <section
                                                    className="checkout-connect-guests__guest-form"
                                                    key={guestId}
                                                    style={{
                                                        display: isGuestFormOpen(guestId) ? "block" : "none",
                                                    }}
                                                >
                                                    <Heading
                                                        type={"h2"}
                                                        styleAs="default-highlighted"
                                                        className="u-mb-12"
                                                    >
                                                        {t("book.checkout.editing")} {guest.firstname} {guest.lastname}
                                                    </Heading>
                                                    <CloseButton
                                                        onClick={() => setGuestToEdit("")}
                                                        className="checkout-connect-guests__guest-form-close-btn"
                                                    />
                                                    <PersonalDetailsInformation
                                                        ref={guestRefs.current?.[guestId]}
                                                        isPrimaryGuest={false}
                                                        isPrimaryIfBookingForOthers={guest.primaryIfBookingForOthers}
                                                        guest={guest as TPersonalInformationGuest}
                                                        onChange={(fieldName, fieldValue, restorePreviousValue) => {
                                                            updateGuestField(
                                                                parseInt(guestId),
                                                                fieldName,
                                                                fieldValue,
                                                                restorePreviousValue
                                                            );
                                                        }}
                                                        onLiveValidation={valid => {
                                                            setGuestsValid(prevState => ({
                                                                ...prevState,
                                                                [guestId]: valid,
                                                            }));
                                                        }}
                                                    />
                                                </section>
                                            )
                                        );
                                    })}
                                </div>
                                <section
                                    className="checkout-connect-guests__guest-form checkout-connect-guests__guest-form--new-guest"
                                    style={{ display: isAddNewGuestFormOpen ? "block" : "none" }}
                                >
                                    <Heading type={"h2"} styleAs="default-highlighted" className="u-mb-12">
                                        {t("book.checkout.add_guest")}
                                    </Heading>
                                    <CloseButton
                                        onClick={() => setIsAddNewGuestFormOpen(false)}
                                        className="checkout-connect-guests__guest-form-close-btn"
                                    />
                                    <PersonalDetailsInformation
                                        ref={newGuestFormRef}
                                        isPrimaryGuest={false}
                                        newGuest
                                        showSubmitButton={true}
                                        onCancel={() => {
                                            setIsAddNewGuestFormOpen(false);
                                            newGuestFormRef.current?.resetForm();
                                        }}
                                        onSubmit={values => {
                                            addNewGuest(values as any);
                                            setIsAddNewGuestFormOpen(false);
                                            newGuestFormRef.current?.resetForm();
                                        }}
                                    />
                                </section>
                                <div className="u-d-flex u-flex-column u-align-items-center">
                                    <div style={{ maxWidth: 500 }}>
                                        {bookingForOthers && !noAdultGuests && !createdFromReservation && (
                                            <div className="u-mb-24">
                                                <div className="u-mb-6">
                                                    <Select
                                                        required
                                                        label={t("book.checkout.pick_responsible_guest")}
                                                        options={Object.entries(
                                                            onlyPrimaryGuest ? allGuests : guestsOnTravel
                                                        )
                                                            .filter(([, guest]) =>
                                                                // We need to remove the primary guest from the list if onlyPrimaryGuest, since guests now comes from allGuests
                                                                onlyPrimaryGuest ? !guest.primary : guest
                                                            )
                                                            .filter(([, guest]) => isGuestAdult(guest))
                                                            .map(([guestId, guest]) => {
                                                                return {
                                                                    label: `${guest.firstname} ${guest.lastname}`,
                                                                    value: guestId,
                                                                    disabled: !isGuestAdult(guest),
                                                                };
                                                            })}
                                                        defaultValue={Object.values(guestsOnTravel)
                                                            .find(guest => guest.primaryIfBookingForOthers)
                                                            ?.id.toString()}
                                                        onSelectCallback={(selectedOption: TSelectOption) =>
                                                            handleSelectPrimaryIfBookingForOthers(
                                                                selectedOption.value as string
                                                            )
                                                        }
                                                        fullWidth
                                                        disabled={
                                                            !onlyPrimaryGuest
                                                                ? !Object.values(guestsOnTravel).length
                                                                : false
                                                        }
                                                        useFirstAsDefault={false}
                                                        placeholder={t("book.checkout.pick_guest")}
                                                        showAsterisk
                                                        boldLabel
                                                        type="standard"
                                                    />
                                                </div>
                                                <small> {t("book.checkout.only_adult_guests")}</small>
                                                {onlyPrimaryGuest && (
                                                    <div className="u-pt-24">
                                                        <Button
                                                            type="tertiary"
                                                            onClick={() =>
                                                                setGuestToEdit(
                                                                    Object.values(guestsOnTravel)
                                                                        .find(guest => guest.primaryIfBookingForOthers)
                                                                        ?.id.toString() ?? ""
                                                                )
                                                            }
                                                            disabled={
                                                                !Object.values(guestsOnTravel).find(
                                                                    guest => guest.primaryIfBookingForOthers
                                                                )
                                                            }
                                                        >
                                                            {t("book.checkout.edit_responsible_guest")}
                                                        </Button>
                                                        {` eller `}
                                                        <Button
                                                            type="tertiary"
                                                            onClick={() => setIsAddNewGuestFormOpen(true)}
                                                        >
                                                            {t("book.checkout.add_guest")}
                                                        </Button>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                        <CheckoutConnectProceedButton
                                            text={t("book.general.continue")}
                                            onClick={submitAll}
                                            disabled={sectionState.status === "error"}
                                        />
                                    </div>
                                </div>
                            </>
                        </ExpandableSection>
                    </div>
                )}
                <ConfirmationModal
                    open={guestToRemove}
                    heading={t("book.checkout.guest_has_connected_products", [guestToRemove?.guest.firstname ?? ""])}
                    description={
                        <>
                            {t("book.checkout.products_will_disconnect")}
                            <ul className="u-pl-18 u-pt-12 u-mb-24">
                                {guestToRemove?.productTitles.map((title, i) => (
                                    <li key={`${title}-${i}`}>{title}</li>
                                ))}
                            </ul>
                        </>
                    }
                    confirmBtnText={t("book.general.ok")}
                    cancelBtnText={t("book.general.cancel")}
                    removing={true}
                    onClose={() => setGuestToRemove(null)}
                    onConfirmClick={guestToRemove?.onConfirm}
                    onCancelClick={() => {
                        guestToRemove?.onCancel();
                        setGuestToRemove(null);
                    }}
                    {...(isIframe && isDesktop && { fromTop: 120 + iframeOffsetTop + "px" })}
                    {...(isIframe &&
                        (isMobile || isTablet) && {
                            fromTop: 80 + iframeOffsetTop + "px",
                        })}
                />
            </>
        );
    }
);

CheckoutConnectGuests.displayName = "CheckoutConnectGuests";
