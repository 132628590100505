import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Row, Col } from "react-bootstrap";
import RichText from "../../UI/RichText";
import { updateProduct } from "../../../store/actions/checkoutCart";
import { Incrementer } from "../../UI";
import "./ProductExtrasItem.scss";
import { isAccommodation, isParent } from "../../../BusinessUtils";
import { sendSignal } from "../../../signals";
import { createCartAddProductSignal, createCartRemoveProductSignal } from "../../../signals/cart";

// Product extras item component
const ProductExtrasItem = ({ data, accommodation, productId, texts }) => {
    const dispatch = useDispatch();
    const currency = useSelector(state => state.clientData?.options?.general?.currency);
    const isMobile = useSelector(state => state.window.isMobile);
    const extrasItemIdentifier = `${productId}_${data.id}`;

    // Render form element based on type
    const renderFormElement = (describedBy = "") => {
        const onChange = value => {
            const info = accommodation.info;
            const currentExtra = Object.entries(info.extras).find(item => parseInt(item[1].id) === parseInt(data.id));
            const oldQuantity = info.extras[parseInt(currentExtra[0])].quantity;

            if (data.rules.type_input === "editbox" || data.rules.type_input === "dropdown") {
                currentExtra[1].quantity = value;
                currentExtra[1].price = value * currentExtra[1].aprice;
            } else if (data.rules.type_input === "checkbox" || data.rules.type_input === "radiobutton") {
                currentExtra[1].checked = value;
                currentExtra[1].quantity = value ? 1 : 0;
                currentExtra[1].price = currentExtra[1].quantity * currentExtra[1].aprice;
            } else {
                throw new Error("Extras item", data.pooldesc1, "does not have a valid type_input");
            }

            const newQuantity = currentExtra[1].quantity;

            // Track when extras have been added/removed on an accommodation
            if (isAccommodation(accommodation) && isParent(accommodation)) {
                // Track changes to quantity
                if (newQuantity !== oldQuantity) {
                    if (newQuantity > oldQuantity) {
                        // Added extras
                        sendSignal(createCartAddProductSignal(currentExtra[1]));
                    } else {
                        // Removed extras
                        sendSignal(createCartRemoveProductSignal(currentExtra[1]));
                    }
                }
            }

            dispatch(
                updateProduct(accommodation, {
                    key: "info",
                    value: info,
                })
            );
        };

        switch (data.rules.type_input) {
            case "dropdown":
            case "editbox": {
                return (
                    <Incrementer
                        label={data.pooldesc1}
                        name={extrasItemIdentifier}
                        describedBy={describedBy}
                        minValue={data.rules.min_qty}
                        maxValue={data.rules.max_qty}
                        value={data.quantity}
                        onChange={({ value }) => onChange(value)}
                        size={8}
                        disabled={!!data.mandatory_web}
                    />
                );
            }

            // Radio buttons support the "demand_web" option which means that the customer is required to make a choice on a non-selected radio group
            case "radiobutton": {
                if (data.rules.demand_web) {
                    // Customer need to make an active choice
                    let noAnswer = false;
                    let yesAnswer = false;

                    // If a choice has already been made, use that choice
                    if ("checked" in data) {
                        if (data.checked) {
                            yesAnswer = true;
                        } else {
                            noAnswer = true;
                        }
                    }

                    return (
                        <Form.Group>
                            <Form.Check
                                required
                                type="radio"
                                name={extrasItemIdentifier}
                                id={`${extrasItemIdentifier}_no`}
                                label={texts.generalno}
                                onChange={() => onChange(false)}
                                defaultChecked={noAnswer}
                            />
                            <Form.Check
                                required
                                type="radio"
                                name={extrasItemIdentifier}
                                id={`${extrasItemIdentifier}_yes`}
                                label={texts.generalyes}
                                onChange={() => onChange(true)}
                                defaultChecked={yesAnswer}
                            />
                        </Form.Group>
                    );
                } else {
                    // YES is pre-selected if it's default
                    // NO is pre-selected if it isn't default
                    return (
                        <>
                            <Form.Check
                                required
                                type="radio"
                                name={extrasItemIdentifier}
                                id={`${extrasItemIdentifier}_no`}
                                label={texts.generalno}
                                onChange={() => onChange(false)}
                                defaultChecked={!data.rules.default_web}
                            />
                            <Form.Check
                                required
                                type="radio"
                                name={extrasItemIdentifier}
                                id={`${extrasItemIdentifier}_yes`}
                                label={texts.generalyes}
                                onChange={() => onChange(true)}
                                defaultChecked={data.rules.default_web}
                            />
                        </>
                    );
                }
            }

            case "checkbox": {
                let checked;

                // Use the checked property if it has been set
                if ("checked" in data) {
                    checked = data.checked ? true : false;
                }
                // Initialize the checked property from quantity.
                else {
                    checked = data.quantity === 1 ? true : false;
                }
                return (
                    <div className="product-extras-item__form-checkbox">
                        <div className="checkbox">
                            <label
                                htmlFor={`${extrasItemIdentifier}-checkbox`}
                                className="checkbox__label checkbox__label--hidden"
                            >
                                <span className="checkbox__label-text">={data.pooldesc3}</span>
                                <input
                                    type="checkbox"
                                    id={`${extrasItemIdentifier}-checkbox`}
                                    name={extrasItemIdentifier}
                                    className="checkbox__input"
                                    checked={checked}
                                    onChange={() => onChange(!checked)}
                                    disabled={!!data.mandatory_web}
                                />
                                <span className="checkbox__checkmark" aria-hidden="true" />
                            </label>
                        </div>
                    </div>
                );
            }
            default: {
                return null;
            }
        }
    };

    const renderPriceOrIncludedTag = () => {
        if (data.mandatory_web && data.showpriceweb) {
            return (
                <div className="product-extras-item__price">
                    <span>
                        {data.aprice} {currency}
                    </span>
                </div>
            );
        } else if (data.mandatory_web) {
            return (
                <div className="product-extras-item__included">
                    <span>{texts?.included || "Inkluderat"}</span>
                </div>
            );
        } else {
            return (
                <div className="product-extras-item__price">
                    <span>
                        {data.aprice} {currency}
                    </span>
                </div>
            );
        }
    };

    // When products gets disabled:
    // Disable extras item if quantity is not allowed to be change according to min/max rules or if it's a booked extra of type checkbox

    return (
        <div className="product-extras-item">
            {!isMobile && (
                <>
                    <Row>
                        <Col md={2}>
                            <div className="product-extras-item__form-container">
                                <div className="product-extras-item__form-element">{renderFormElement()}</div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="product-extras-item__information-container">
                                <h2 className="product-extras-item__header">{data.title}</h2>
                            </div>
                            {data.mandatory_web && data.showpriceweb ? (
                                <div className="product-extras-item__included">
                                    <span>{texts?.included || texts?.productextraitemincluded}</span>
                                </div>
                            ) : null}
                        </Col>
                        <Col md={4}>
                            <Row>
                                <Col md={4}></Col>
                                <Col md={8}>{renderPriceOrIncludedTag()}</Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={2}></Col>
                        <Col md={10}>
                            {data.weblong !== "" ? (
                                <div className="product-extras-item__description">
                                    <p>
                                        <RichText content={data.weblong} />
                                    </p>
                                </div>
                            ) : null}
                        </Col>
                    </Row>
                </>
            )}
            {isMobile && (
                <>
                    <Row>
                        <Col xs={12}>
                            <div className="product-extras-item__information-container">
                                <h2 className="product-extras-item__header">{data.title}</h2>
                            </div>
                            {data.mandatory_web && data.showpriceweb ? (
                                <div className="product-extras-item__included">
                                    <span>{texts?.included || texts?.productextraitemincluded}</span>
                                </div>
                            ) : null}
                        </Col>
                        <Col xs={12}>
                            <Row>
                                <Col xs={6}>
                                    <div className="product-extras-item__form-container">
                                        <div className="product-extras-item__form-element">{renderFormElement()}</div>
                                    </div>
                                </Col>
                                <Col xs={6}>{renderPriceOrIncludedTag()}</Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            {data.weblong !== "" ? (
                                <div className="product-extras-item__description">
                                    <p>
                                        <RichText content={data.weblong} />
                                    </p>
                                </div>
                            ) : null}
                        </Col>
                    </Row>
                </>
            )}
        </div>
    );
};

export default ProductExtrasItem;
