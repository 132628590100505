import React from "react";
import ProductGallery from "../ProductGallery/ProductGallery";
import "./AccommodationResultCard.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { ImagePlaceholder, Tag } from "@r360/library";
import useTranslate from "../../hooks/useTranslate";
import { TImage } from "../../store/types";
import { formatPrice } from "../../Helper";

export type AccommodationResultCardCriteria = {
    title: string;
    value: string;
};

export type Props = {
    title: string;
    price: number;
    ordinaryprice: number;
    showPrice: boolean;
    itemCount: number;
    description: string;
    images: TImage[];
    poollid: number;
    reservationTypeId: any;
    promoCode: string;
    currency: string;
    criterias: AccommodationResultCardCriteria[];
    accommodationTypes: string[];
};

export const AccommodationResultCard = ({
    title,
    price,
    ordinaryprice,
    showPrice,
    itemCount,
    description,
    images,
    poollid,
    reservationTypeId,
    promoCode,
    currency,
    criterias,
    accommodationTypes,
}: Props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const t = useTranslate();

    const navigateToGroupPool = () => {
        navigate(`/product/${reservationTypeId}/${poollid}${location.search}`);
    };

    const hasOrdinaryPrice = ordinaryprice > 0;

    const formatDescription = (description: string) => {
        const htmlRegEx = /(<([^>]+)>)/gi;
        if (description.length > 120) {
            return `${description.substring(0, 120)}...`.replace(" .", ".").replace(htmlRegEx, "");
        } else {
            return description.replace(htmlRegEx, "");
        }
    };

    return (
        <article className="accommodation-card">
            <div className="accommodation-card__gallery">
                {images.length > 0 ? (
                    <ProductGallery
                        images={(images.slice(0, 14) || []).map(image => ({
                            original: image.url,
                            thumbnail: image.url,
                        }))}
                        forceImageGallery={true}
                        onImageGalleryClick={() => null}
                        inAccommodationCard
                    />
                ) : (
                    <ImagePlaceholder aspectRatio="16 / 9" />
                )}
            </div>
            <div className="accommodation-card__content">
                <h2 className="accommodation-card__heading">{title}</h2>
                <div className="accommodation-card__tags">
                    {accommodationTypes.length > 0 &&
                        accommodationTypes.map(accommodationType => (
                            <Tag key={accommodationType} text={accommodationType} theme="bright-blue" />
                        ))}
                    {itemCount > 0 &&
                        (showPrice ? (
                            <Tag text={t("book.general.number_available", [itemCount])} theme="light-blue" />
                        ) : (
                            <Tag text={t("book.general.number_accommodations", [itemCount])} theme="light-blue" />
                        ))}
                    {promoCode && <Tag text={t("book.general.campaign")} theme="yellow" />}
                </div>
                <p className="accommodation-card__description">{formatDescription(description)}</p>
                <div className="accommodation-card__criterias">
                    {criterias.map(criteria => {
                        return (
                            <div key={criteria.title} className="accommodation-card__criterias-item">
                                <div className="accommodation-card__criterias-value">{criteria.value}</div>
                                <div className="accommodation-card__criterias-label">{criteria.title}</div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <button className="accommodation-card__btn" onClick={navigateToGroupPool}>
                {showPrice ? (
                    <>
                        <span>
                            {t("book.general.from")} {formatPrice(price, currency)}
                        </span>
                        {hasOrdinaryPrice && (
                            <span className="accommodation-card__btn-ordinary-price">
                                Tidigare {formatPrice(ordinaryprice, currency)}
                            </span>
                        )}
                    </>
                ) : (
                    <span>{t("book.general.price_placeholder")}</span>
                )}
            </button>
        </article>
    );
};
