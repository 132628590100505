import { useEffect } from "react";
import { setProductsNotAddedPath } from "../store/actions/checkoutCart";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

export const useProductsNotAddedPath = (when: boolean) => {
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setProductsNotAddedPath(when ? location.pathname : ""));
        //eslint-disable-next-line
    }, [when]);
};
