import * as React from "react";
import Theme from "../../Theme";

const ArrowRight = ({ color = Theme.palette.primary }) => {
    return (
        <svg width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.7886 9.64778L3.37119 17.0651C3.01345 17.4228 2.43348 17.4228 2.07579 17.0651L1.21067 16.2C0.853552 15.8429 0.852865 15.2641 1.20915 14.9061L7.08754 9.00006L1.20915 3.09403C0.852865 2.73607 0.853552 2.15728 1.21067 1.80016L2.07579 0.935047C2.43352 0.577315 3.01349 0.577315 3.37119 0.935047L10.7885 8.35238C11.1463 8.71008 11.1463 9.29005 10.7886 9.64778Z"
                fill={color}
            />
        </svg>
    );
};

export default ArrowRight;
