import React from "react";
import NoSearchResultImage from "../../assets/noSearchResult.png";
import useTranslate from "../../hooks/useTranslate";
import "./NoSearchResult.scss";

export const NoSearchResult = () => {
    const t = useTranslate();

    return (
        <div className="no-search-result">
            <p className="no-search-result__text">{t("book.search.no_result")}</p>
            <img className="no-search-result__img" src={NoSearchResultImage} />
        </div>
    );
};
