import { Select, TSelectOption } from "@r360/library";
import moment from "moment";
import React from "react";
import useTranslate from "../../hooks/useTranslate";
import { capitalizeFirstLetter } from "../../Helper";

type TYearSelect = {
    name: string;
    value: number;
    onChange: (selectedOption: TSelectOption, name: string) => void;
    onDarkBackground?: boolean;
    isValid?: boolean;
    showAsterisk?: boolean;
};

interface TMonthSelect extends TYearSelect {
    year: number | null;
    shortPlaceholder?: boolean;
}

interface TDaySelect extends TMonthSelect {
    month: number | null;
}

export const YearSelect = ({ name, value, onChange, onDarkBackground, isValid, showAsterisk = false }: TYearSelect) => {
    const t = useTranslate();

    const years = (back: number) => {
        const year = new Date().getFullYear();
        return Array.from({ length: back }, (v, i) => year - back + i + 1);
    };

    const options = years(100)
        .reverse()
        .map(year => ({ value: year, label: year.toString() }));

    return (
        <div className="year-select">
            <Select
                label={t("book.account.birthyear")}
                defaultValue={value}
                options={options}
                fullWidth
                onDarkBackground={onDarkBackground}
                onSelectCallback={(selectedOption: TSelectOption) => onChange(selectedOption, name)}
                placeholder={capitalizeFirstLetter(t("book.general.years"))}
                useFirstAsDefault={false}
                type="standard"
                isValid={isValid}
                showAsterisk={showAsterisk}
            />
        </div>
    );
};

export const MonthSelect = ({
    year,
    name,
    value,
    onChange,
    onDarkBackground,
    isValid,
    showAsterisk = false,
    shortPlaceholder = false,
}: TMonthSelect) => {
    const t = useTranslate();
    const options = moment.monthsShort().map((month, i) => ({ value: i + 1, label: (i + 1).toString() }));

    return (
        <div className="month-select">
            <Select
                label={t("book.account.month")}
                defaultValue={value}
                options={options}
                fullWidth
                onDarkBackground={onDarkBackground}
                onSelectCallback={(selectedOption: TSelectOption) => onChange(selectedOption, name)}
                placeholder={shortPlaceholder ? t("book.account.month.short") : t("book.account.month")}
                useFirstAsDefault={false}
                disabled={!year}
                type="standard"
                isValid={isValid}
                showAsterisk={showAsterisk}
            />
        </div>
    );
};

export const DaySelect = ({
    year,
    month,
    name,
    value,
    onChange,
    onDarkBackground,
    isValid,
    showAsterisk = false,
}: TDaySelect) => {
    const t = useTranslate();
    const daysInMonth = moment(`${year}-${month}`, "YYYY-M").daysInMonth();
    const daysArray = Array.from({ length: daysInMonth }, (v, i) => i + 1);
    const options = daysArray.map((month, i) => ({ value: i + 1, label: month.toString() }));

    return (
        <div className="month-select">
            <Select
                label={t("book.account.day")}
                defaultValue={value}
                options={options}
                fullWidth
                onDarkBackground={onDarkBackground}
                onSelectCallback={(selectedOption: TSelectOption) => onChange(selectedOption, name)}
                placeholder={t("book.account.day")}
                useFirstAsDefault={false}
                disabled={!month}
                type="standard"
                isValid={isValid}
                showAsterisk={showAsterisk}
            />
        </div>
    );
};
