import * as React from "react";
import defautIconStats from "../Icon.types";
import Theme from "../../Theme";

const SkiLift = ({ color = Theme.palette.primary, size = defautIconStats.size, iconClass = defautIconStats.width }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            style={{ height: size, width: size }}
            width="512pt"
            height="512pt"
            className={iconClass}
        >
            <g id="Ski_Lift" data-name="Ski Lift">
                <g id="Outline">
                    <path
                        fill={color}
                        d="M336,242.121V224h8a8,8,0,0,0,0-16h-8v-8a8,8,0,0,0-16,0v8H277.185A71.883,71.883,0,0,0,304,152V64a8,8,0,0,0-6.368-7.832l-72-15a8,8,0,0,0-3.264,15.664L288,70.505V152a56.063,56.063,0,0,1-56,56H192v-8a8,8,0,0,0-16,0v8h-8a8,8,0,0,0,0,16h8v18.121C112.415,253.489,64,309.2,64,376c0,74.99,61.009,136,136,136H312c74.991,0,136-61.01,136-136C448,309.2,399.585,253.489,336,242.121ZM304,224h16v16.242c-2.648-.155-5.314-.242-8-.242h-8Zm-80,0h64v16H224Zm-32,0h16v16h-8c-2.686,0-5.352.087-8,.242ZM336,493.587V408a8,8,0,0,0-16,0v87.727c-2.645.175-5.311.273-8,.273H200c-2.689,0-5.355-.1-8-.273V408a8,8,0,0,0-16,0v85.587A120.006,120.006,0,0,1,200,256H312a120.006,120.006,0,0,1,24,237.587Z"
                    />
                    <path
                        fill={color}
                        d="M326.48,77.8l145,28.06a8,8,0,0,0,3.04-15.709l-145-28.06A8,8,0,0,0,326.48,77.8Z"
                    />
                    <path
                        fill={color}
                        d="M38.48,22.048l153,29.611a8,8,0,0,0,3.04-15.709L41.52,6.339a8,8,0,0,0-3.04,15.709Z"
                    />
                    <path
                        fill={color}
                        d="M505.52,144.146l-176-34.06a8,8,0,0,0-3.04,15.709l176,34.059a8,8,0,0,0,3.04-15.708Z"
                    />
                    <path
                        fill={color}
                        d="M265.009,113.742a8,8,0,0,0,1.51-15.856l-257-49.74A8,8,0,1,0,6.48,63.854l257,49.741A8.059,8.059,0,0,0,265.009,113.742Z"
                    />
                    <circle fill={color} cx="336" cy="327.49" r="8" />
                    <path
                        fill={color}
                        d="M369.1,289.109A103.55,103.55,0,0,0,338,275.29a8,8,0,0,0-10,7.75v12.45a8,8,0,0,0,16,0v-1.47A88.679,88.679,0,0,1,399.64,368H344v-8.51a8,8,0,0,0-16,0V376a7.989,7.989,0,0,0,8,8h72a7.989,7.989,0,0,0,8-8,104.167,104.167,0,0,0-46.9-86.891Z"
                    />
                    <path
                        fill={color}
                        d="M136.25,312.28a8,8,0,1,0-5.32-13.97,7.993,7.993,0,0,0-.66,11.29A8,8,0,0,0,136.25,312.28Z"
                    />
                    <path
                        fill={color}
                        d="M180.9,276.71a8.02,8.02,0,0,0-6.9-1.42,104.125,104.125,0,0,0-13.87,4.66,8,8,0,1,0,6.15,14.76c.56-.231,1.13-.46,1.72-.69V368H112.36a86.929,86.929,0,0,1,10.88-34.99,8,8,0,1,0-13.95-7.85A103.4,103.4,0,0,0,96,376a7.989,7.989,0,0,0,8,8h72a7.989,7.989,0,0,0,8-8V283.04A7.953,7.953,0,0,0,180.9,276.71Z"
                    />
                    <path
                        fill={color}
                        d="M304,304a8.011,8.011,0,0,0-8,8v56H216V288h56a8,8,0,0,0,.02-16H208a7.989,7.989,0,0,0-8,8v96a7.989,7.989,0,0,0,8,8h96a7.989,7.989,0,0,0,8-8V312A8.011,8.011,0,0,0,304,304Z"
                    />
                    <circle fill={color} cx="304" cy="280" r="8" />
                    <circle fill={color} cx="216" cy="408" r="8" />
                    <circle fill={color} cx="8" cy="8" r="8" />
                    <circle fill={color} cx="504" cy="104" r="8" />
                </g>
            </g>
        </svg>
    );
};

export default SkiLift;
