import AxiosClient from "../../API/AxiosClient";
import * as types from "../actions/types";
import { arraysAreEqualAfterSort, hasDatesChanged } from "../../Helper";
import { resvTypeByLid } from "../../selectors/Selectors";
import * as Constants from "../../Constants";
import { setReservationTypeFilters } from "./filter";
import { setCartProductExtras } from "./checkoutCart";
import { resetLastSearchedTimestampByResvtype, setLastSearchedDateSpan, updateLastSearchedTimestamp } from "./search";
import moment from "moment-timezone";
import { isSystemPool } from "../../BusinessUtils";
import { setRequestsLoading } from "./axiosStatus";
import { optionsSelector } from "../../Selectors";

// Get reservation type criterias
const getReservationTypeCriterias = (id, resvtype) => {
    return AxiosClient.get("/criterias?resultset=" + id + "&resvtype=" + resvtype);
};

export const fetchReservationTypeResultsFailed = (error, resvtype) => {
    return {
        type: types.FETCH_RESERVATIONTYPERESULT_FAILED,
        error: error,
        resvtype: resvtype,
    };
};

export const setResultSetId = (payload, resvtype) => {
    return {
        type: types.SET_RESULTSETID,
        payload: payload,
        resvtype: resvtype,
    };
};

export const setReservationTypeResults = (
    payload,
    resvtype,
    promoCode,
    checkPriceAndAvailability,
    grouppoollid,
    pricecode,
    isInitialSearch,
    isLoggedIn
) => {
    return {
        type: types.SET_RESERVATIONTYPERESULT,
        payload,
        resvtype,
        promoCode,
        checkPriceAndAvailability,
        grouppoollid,
        pricecode,
        isInitialSearch,
        isLoggedIn,
    };
};

export const clearReservationTypeResults = resvtype => {
    return {
        type: types.CLEAR_RESERVATIONRESULTDATA,
        resvtype: resvtype,
    };
};

export const clearAllReservationTypeResults = () => {
    return {
        type: types.CLEAR_ALL_RESERVATIONRESULTDATA,
    };
};

export const removeReservationTypeResults = resvtype => {
    return {
        type: types.REMOVE_RESERVATIONRESULT,
        resvtype: resvtype,
    };
};

export const setSlowRequestParam = slowRequest => {
    return {
        type: types.SET_SLOW_REQUEST_PARAM,
        slowRequest: slowRequest,
    };
};

export const fetchAllReservationTypesResults = (reservationTypes, requestData) => {
    // Do not run all requests in parallel at the same time to allow the API to handle all requests better
    const delayBetweenEachRequest = 250;

    return dispatch => {
        reservationTypes
            .filter(resvtype => resvtype.hidden === false)
            .forEach((resvtype, index) => {
                // We use the index as a multiplier to make the timeout below execute later and later for every request that we need to send.
                // The first request will be sent directly because index is 0 which gives us 0ms.
                const delayThisRequest = delayBetweenEachRequest * index;

                setTimeout(() => {
                    // We need to create this object within setTimeout to have a local copy
                    const requestDataToSend = {
                        startdate: requestData.startdate,
                        enddate: requestData.enddate,
                        promoCode: requestData.promoCode,
                        resvtype: resvtype.lid,
                    };

                    if (resvtype.type === Constants.productTypeNames.ACCOMMODATION) {
                        requestDataToSend.ages = requestData.ages;
                    }

                    dispatch(fetchReservationTypeResults(requestDataToSend));
                }, delayThisRequest);
            });
    };
};

export const fetchReservationTypeResults = (payload, checkPriceAndAvailability = true, isInitialSearch = false) => {
    console.log(payload);
    if (!payload.promoCode) {
        payload.promoCode = "";
    }

    // We don't want old dates and old ages to be sent to api.
    // Exclude fields that shall not be transformed into form data.

    // eslint-disable-next-line no-unused-vars
    const {
        oldStartDate,
        oldEndDate,
        oldAges,
        oldPromoCode,
        oldGrouppoollid,
        oldPricecode,
        oldIsLoggedIn,
        isLoggedIn,
        useAutoSearch,
        ...searchParams
    } = payload;

    // If searching without taking price into consideration. Will increase performance.
    if (!checkPriceAndAvailability) {
        searchParams.checkprice = false;
        searchParams.checkavail = false;
    }

    if (!searchParams.grouppoollid) {
        delete searchParams.grouppoollid;
    }

    const resvtypelid = parseInt(payload.resvtype);
    const promoCode = payload.promoCode;

    return (dispatch, getState) => {
        const state = getState();
        const reservationType = resvTypeByLid(state, resvtypelid);
        const newDesignAccommodation = optionsSelector(state).layout?.new_design_accommodation;

        const catchSearchErrors = error => {
            if (error.response.status === 400) {
                if (error.response.data.errors.length) {
                    // If we have a blocked date we want to clear search results for this type
                    const blockedDateError = error.response.data.errors.find(problem => {
                        return problem.code === "BLOCKED_DATE";
                    });

                    if (blockedDateError) {
                        dispatch(clearReservationTypeResults(resvtypelid));
                    }
                }
            }

            dispatch(fetchReservationTypeResultsFailed(error, resvtypelid));
            dispatch(setRequestsLoading({ request: "fetchReservationTypeResults", loading: false }));
        };

        if (!reservationType) {
            throw new Error(`Cannot find reservation type by lid ${resvtypelid}`);
        }

        let forceNewSearch = false;

        // Check if dates have been changed and trigger a reset/new search.
        if (typeof payload.oldStartDate !== "undefined" && typeof payload.oldEndDate !== "undefined") {
            const datesChanged = hasDatesChanged(
                payload.startdate,
                payload.enddate,
                payload.oldStartDate,
                payload.oldEndDate
            );

            if (datesChanged) {
                forceNewSearch = true;
                dispatch(resetLastSearchedTimestampByResvtype(resvtypelid));
            }
        }

        // Check if ages have been changed and trigger a reset/new search.
        if (payload.oldAges && payload.ages && !arraysAreEqualAfterSort(payload.oldAges, payload.ages)) {
            forceNewSearch = true;
            dispatch(resetLastSearchedTimestampByResvtype(resvtypelid));
        }

        // Check if promoCode have been changed and trigger a reset/new search.
        if (payload.oldPromoCode !== payload.promoCode) {
            forceNewSearch = true;
            dispatch(resetLastSearchedTimestampByResvtype(resvtypelid));
        }

        // Check if grouppoollid have been changed and trigger a reset/new search.
        // Only force a new search if the old grouppoollid has a truthy value. If the old groupoollid is falsy,
        // the old search is for the entire reservation type and the new grouppoollid will already be in the old result.
        if (payload.oldGrouppoollid && payload.oldGrouppoollid !== payload.grouppoollid) {
            forceNewSearch = true;
            dispatch(resetLastSearchedTimestampByResvtype(resvtypelid));
        }

        // Check if pricecode have been changed and trigger a reset/new search.
        if (payload.oldPricecode !== payload.pricecode) {
            forceNewSearch = true;
            dispatch(resetLastSearchedTimestampByResvtype(resvtypelid));
        }

        // Check if logged in status has changed and trigger as reset/new search.
        if (payload.oldIsLoggedIn !== payload.isLoggedIn) {
            forceNewSearch = true;
            dispatch(resetLastSearchedTimestampByResvtype(resvtypelid));
        }

        if (!forceNewSearch) {
            // This is an important trigger that we sometimes may want to trigger a new search.
            // Check first if we are allowed to make a new search for the given reservation type.
            // This check exists because we want to limit the number of searches to as few as possible per customer.
            // This will improve the booking experience!
            const defaultLifetime = state.search.defaultLifetime;
            const customLifetimes = state.search.customLifetimes;
            const now = moment();
            let lastSearched,
                lifetime = 0,
                allowSearch = false,
                hasSearchExpired = false;

            if (
                resvtypelid in state.search.lastSearched &&
                typeof state.search.lastSearched[resvtypelid] === "string"
            ) {
                lastSearched = moment(state.search.lastSearched[resvtypelid]);
            }

            if (
                typeof customLifetimes[reservationType.type] === "number" &&
                customLifetimes[reservationType.type] >= 0
            ) {
                lifetime = customLifetimes[reservationType.type];
            } else {
                lifetime = defaultLifetime;
            }

            if (lifetime === 0) {
                allowSearch = true;
            } else if (!lastSearched) {
                allowSearch = true;
            } else {
                // Compare last searched timestamps to see if a new search is allowed
                hasSearchExpired = lastSearched.clone().add(lifetime, "minutes").isBefore(now);

                if (hasSearchExpired) {
                    allowSearch = true;
                }
            }

            if (!allowSearch) {
                return;
            }
        }

        // Provide token if logged in as owner to identify the owners own accommodations.
        const config = state.account?.user?.isOwner
            ? {
                  headers: {
                      Authorization: `Bearer ${state.account.token}`,
                  },
              }
            : {};

        dispatch(setRequestsLoading({ request: "fetchReservationTypeResults", loading: true }));

        // Optimzation: Do not run all search/results/criterias requests in parallell to give the API some time to handle them better.
        // 1. Search for products and return results directly in the same response
        // 2. Fetch criterias for results
        AxiosClient.get(`/search?resvtype=${resvtypelid}`, { ...config, params: searchParams })
            .then(searchResponse => {
                const resultSetId = searchResponse.data.payload.resultset;

                dispatch(updateLastSearchedTimestamp(resvtypelid));
                dispatch(setResultSetId(resultSetId, resvtypelid));
                dispatch(
                    setReservationTypeResults(
                        searchResponse.data.payload,
                        resvtypelid,
                        promoCode,
                        checkPriceAndAvailability,
                        payload.grouppoollid,
                        payload.pricecode,
                        isInitialSearch,
                        isLoggedIn
                    )
                );

                if (newDesignAccommodation) {
                    dispatch(setRequestsLoading({ request: "fetchReservationTypeResults", loading: false }));

                    if (
                        !useAutoSearch &&
                        checkPriceAndAvailability &&
                        searchParams.startdate &&
                        searchParams.enddate &&
                        searchParams.startdate !== searchParams.enddate
                    ) {
                        dispatch(setLastSearchedDateSpan(resvtypelid, searchParams.startdate, searchParams.enddate));
                    }
                } else {
                    // This request is only required for old design
                    return getReservationTypeCriterias(resultSetId, resvtypelid).then(critsResponse => {
                        dispatch(setRequestsLoading({ request: "fetchReservationTypeResults", loading: false }));
                        dispatch(setReservationTypeFilters(critsResponse.data.payload, resvtypelid));
                    });
                }
            })
            .catch(catchSearchErrors);
    };
};

//AdditionalItem
export const fetchAdditionalItemInformation = payload => {
    const { resvtype, resultsetid, itemId, groupId, skipSystemPoolExtras = false } = payload;

    // Include resultset in URL only for debugging and track requests in log rocket
    const productsUrl = `/products?resultsetid=${resultsetid}&id=${itemId}`;

    return (dispatch, getState) => {
        const state = getState();

        // Provide token if logged in as owner to identify the owners own accommodations.
        const config = state.account?.user?.isOwner
            ? {
                  headers: {
                      Authorization: `Bearer ${state.account.token}`,
                  },
              }
            : {};

        dispatch(setRequestsLoading({ request: "fetchAdditionalItemInformation", loading: true }));

        AxiosClient.get(productsUrl, config)
            .then(response => {
                dispatch(setAdditionalItemInformation(response.data.payload, groupId, resvtype));

                let extras = response.data.payload.extras || [];

                if (skipSystemPoolExtras) {
                    extras = extras.filter(extra => !isSystemPool(extra));
                }
                dispatch(setRequestsLoading({ request: "fetchAdditionalItemInformation", loading: false }));
                dispatch(setCartProductExtras(itemId, extras));
            })
            .catch(error => {
                dispatch(fetchAdditionalItemInformationFailed(error, resvtype));
            });
    };
};

const fetchAdditionalItemInformationFailed = (error, resvtype) => {
    return {
        type: types.FETCH_ADDITIONALINITEMFORMATION_FAILED,
        error: error,
        resvtype: resvtype,
    };
};

const setAdditionalItemInformation = (payload, groupId, resvtype) => {
    // console.log('setAdditionalItemInformation', payload, groupId, resvtype);
    return {
        type: types.SET_ADDITIONALINITEMFORMATION,
        payload: payload,
        groupId: groupId,
        resvtype: resvtype,
    };
};

export const fetchProductsExtras = (reservationTypeId, productIds) => {
    return (dispatch, getState) => {
        const state = getState();

        // Provide token if logged in as owner to identify the owners own accommodations.
        const config = state.account?.user?.isOwner
            ? {
                  headers: {
                      Authorization: `Bearer ${state.account.token}`,
                  },
              }
            : {};

        dispatch(setRequestsLoading({ request: "fetchProductsExtras", loading: true }));

        const productsExtrasUrl = `/products/extras?ids=${productIds.join(",")}`;

        AxiosClient.get(productsExtrasUrl, config)
            .then(response => {
                dispatch(setRequestsLoading({ request: "fetchProductsExtras", loading: false }));
                dispatch(setProductsExtras(reservationTypeId, response.data.payload));
            })
            .catch(error => {
                dispatch(fetchProductsExtrasFailed(error, reservationTypeId));
            });
    };
};

const setProductsExtras = (reservationTypeId, productsExtras) => {
    return {
        type: types.SET_PRODUCTS_EXTRAS,
        payload: productsExtras,
        resvtype: reservationTypeId,
    };
};

const fetchProductsExtrasFailed = (error, resvtype) => {
    return {
        type: types.FETCH_PRODUCTS_EXTRAS_FAILED,
        error: error,
        resvtype: resvtype,
    };
};
