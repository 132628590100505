import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import PersonalDetailsInformation from "./PersonalDetailsInformation";
import "./PersonalInformation.scss";
import PersonalKeycardInformation from "./PersonalKeycardInformation";
import PersonalRentalInformation from "./PersonalRentalInformation";

const PersonalInformation = forwardRef((props, ref) => {
    const {
        isPrimaryGuest,
        guestId,
        guest,
        texts,
        onSubmit,
        showAgePicker = true,
        showKeycardNo = true,
        showKeycardImage = true,
        showWeight = true,
        showHeight = true,
        showShoesize = true,
        showHeadsize = true,
    } = props;
    const detailsFormRef = useRef(null);
    const keycardFormRef = useRef(null);
    const rentalFormRef = useRef(null);
    const [detailsSubmittedValues, setDetailsSubmittedValues] = useState(null);
    const [keycardSubmittedValues, setKeycardSubmittedValues] = useState(null);
    const [rentalSubmittedValues, setRentalSubmittedValues] = useState(null);

    const useKeycardForm = showKeycardNo || showKeycardImage;
    const useRentalForm = showWeight || showHeight || showShoesize || showHeadsize;

    useImperativeHandle(ref, () => ({
        submitForm: handleSubmit,
        resetForm: resetForms,
        resetTouched: resetTouched,

        // Export inner forms' refs to be able to
        // affect field values and validations from the outside.
        // See example in MyAccount.
        detailsFormRef: detailsFormRef.current,
        keycardFormRef: useKeycardForm.current,
        rentalFormRef: useRentalForm.current,
    }));

    const resetSubmittedValues = () => {
        setDetailsSubmittedValues(null);
        setKeycardSubmittedValues(null);
        setRentalSubmittedValues(null);
    };

    const submitForms = () => {
        detailsFormRef.current.submitForm();
        useKeycardForm && keycardFormRef.current.submitForm();
        useRentalForm && rentalFormRef.current.submitForm();
    };

    const resetForms = () => {
        detailsFormRef.current.resetForm();
        useKeycardForm && keycardFormRef.current.resetForm();
        useRentalForm && rentalFormRef.current.resetForm();
    };

    const resetTouched = () => {
        detailsFormRef.current.resetTouched();
        useKeycardForm && keycardFormRef.current.resetTouched();
        useRentalForm && rentalFormRef.current.resetTouched();
    };

    useEffect(() => {
        if (
            detailsSubmittedValues &&
            (!useKeycardForm || keycardSubmittedValues) &&
            (!useRentalForm || rentalSubmittedValues)
        ) {
            const guestValues = {
                ...guest,
                ...detailsSubmittedValues,
                ...(useKeycardForm && keycardSubmittedValues),
                ...(useRentalForm && rentalSubmittedValues),
            };

            onSubmit && onSubmit(guestValues);

            resetSubmittedValues();
        }
        // eslint-disable-next-line
    }, [detailsSubmittedValues, keycardSubmittedValues, rentalSubmittedValues]);

    const handleSubmit = () => {
        resetSubmittedValues();
        submitForms();
    };

    const handleDetailsSubmit = values => {
        setDetailsSubmittedValues(values);
    };

    const handleKeycardSubmit = values => {
        setKeycardSubmittedValues(values);
    };

    const handleRentalSubmit = values => {
        setRentalSubmittedValues(values);
    };

    if (!texts) {
        return <></>;
    }

    return (
        <div className={"personal-information"}>
            <Row>
                <Col>
                    <PersonalDetailsInformation
                        ref={detailsFormRef}
                        isPrimaryGuest={isPrimaryGuest}
                        guest={guest}
                        onSubmit={handleDetailsSubmit}
                        texts={texts}
                        showAgePicker={showAgePicker}
                    />
                </Col>
            </Row>

            {useKeycardForm && (
                <Row>
                    <Col>
                        <PersonalKeycardInformation
                            ref={keycardFormRef}
                            showKeycardNo={showKeycardNo}
                            showKeycardImage={showKeycardImage}
                            imagehash={guest?.imagehash}
                            keycardNo={guest?.keycardNo}
                            guestlid={guestId}
                            onSubmit={handleKeycardSubmit}
                            texts={texts}
                        />
                    </Col>
                </Row>
            )}

            {useRentalForm && (
                <Row>
                    <Col>
                        <PersonalRentalInformation
                            ref={rentalFormRef}
                            showWeight={showWeight}
                            showHeight={showHeight}
                            showShoesize={showShoesize}
                            showHeadsize={showHeadsize}
                            weight={guest?.weight}
                            height={guest?.height}
                            shoesize={guest?.shoesize}
                            headsize={guest?.headsize}
                            onSubmit={handleRentalSubmit}
                            texts={texts}
                        />
                    </Col>
                </Row>
            )}
        </div>
    );
});

PersonalInformation.displayName = "PersonalInformation";

export default PersonalInformation;
