import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal as ModalComponent } from "react-bootstrap";
import { setSignUpWrapper, setBookingError } from "../../../store/actions/window";
import { _ } from "../../../Helper";
import "./Modal.scss";

// Modal component
const Modal = props => {
    const dispatch = useDispatch();
    /*
     * Component needs two attributs:
     * {bool} "show" — state variable for the modal
     * {function} "setShow" - function to set state variable to 'false' on modal hide/close
     */
    const { show, setShow, component, children, className, labelledBy, id } = props;
    const isBookingError = useSelector(state => state.window.isBookingError);
    const modalClass = className ? className : "";

    // Handle click
    const handleClick = () => {
        dispatch(setSignUpWrapper(false, false, false, null));
        if (isBookingError) {
            dispatch(setBookingError(false));
        }
        if (setShow) {
            setShow(false);
        }
    };

    const scrollIntoView = () => {
        _(() => {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
        });
    };

    // Check if function is a constructor
    function isConstructor(f) {
        try {
            new f();
        } catch (err) {
            return false;
        }
        return true;
    }

    // Render output
    const renderOutput = () => {
        /* If function... */
        if (typeof component === "function") {
            if (isConstructor(component) === true) {
                const output = new component();
                return output.render();
            }
            return component();

            /* Else... */
        } else {
            return component;
        }
    };

    const allowed = ["size", "backdrop"];

    const inheritedProps = Object.keys(props)
        .filter(key => allowed.includes(key))
        .reduce((obj, key) => {
            obj[key] = props[key];
            return obj;
        }, {});

    return (
        <ModalComponent
            {...inheritedProps}
            show={show}
            onShow={scrollIntoView}
            onHide={() => {
                if (props.onHide) {
                    props.onHide();
                }
                handleClick();
            }}
            dialogClassName={modalClass}
            aria-labelledby={labelledBy || ""}
            id={id || ""}
        >
            <ModalComponent.Header closeButton />
            <ModalComponent.Body>
                {renderOutput()}
                {children}
            </ModalComponent.Body>
        </ModalComponent>
    );
};

export default Modal;
