import React from "react";
import { OptimizedImage, getOptimizedImagePathFromUrl } from "@r360/library";
import "./ProductImagesGallery.scss";

const ProductImagesGallery = ({ images, showImageDescriptions }) => {
    return (
        <div className="product-images-gallery">
            {images.map((image, index) => {
                return (
                    <div key={index} className="product-images-gallery__image-container">
                        <div className="product-images-gallery__image">
                            <OptimizedImage
                                aspectX={16}
                                aspectY={9}
                                path={getOptimizedImagePathFromUrl(image.thumbnail)}
                            />
                        </div>
                        {showImageDescriptions && image?.text !== "" && (
                            <div className="product-images-gallery__image-text">{image?.text}</div>
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default ProductImagesGallery;
