import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { guestsOnTravelSelector, optionsSelector } from "../../../selectors/Selectors";
import { updateProduct } from "../../../store/actions/checkoutCart";
import { createGuestOnCart } from "../../../store/actions/guests";
import { Icon, InformationBlock } from "../../UI";
import { v4 as uuidv4 } from "uuid";
import "./Activities.scss";
import Activity from "./Activity";
import { agesSelector } from "../../../Selectors";
import isEqual from "lodash/isEqual";

const Activities = props => {
    const dispatch = useDispatch();
    const { products, type, texts } = props;
    const activities = products.filter(product => parseInt(product.type, 10) === parseInt(type.lid, 10));
    const guestsOnTravel = useSelector(guestsOnTravelSelector);
    const userToken = useSelector(state => state.account.token);
    const allowActivityNotes = useSelector(optionsSelector).reservation?.allow_activity_notes || false;
    const checkoutCartId = useSelector(state => state.checkoutCart.cartId);
    const generalAges = useSelector(agesSelector)[type.lid];

    const syncActivityItems = activity => {
        const activityItems = activity.items || [];

        // Start by building up a complete new set of items and then sync with existing.
        const syncOperations = { keep: [], add: [] };

        // If multiple ages exists for the activity.
        if (activity.info?.guests) {
            syncOperations.add = Object.entries(activity.info?.guests || {})
                .filter(([key]) => key !== "totalGuests")
                .reduce((acc, [ageKey, ageCount]) => {
                    const ageDataForKey = generalAges.find(ageData => ageData.key === ageKey);

                    for (let i = 0; i < ageCount; i++) {
                        acc.push({
                            id: uuidv4(),
                            guestId: null,
                            minAge: ageDataForKey ? Math.max(activity.minage, ageDataForKey.min) : activity.minage,
                            maxAge: ageDataForKey ? Math.min(activity.maxage, ageDataForKey.max) : activity.maxage,
                        });
                    }

                    return acc;
                }, []);
        } else {
            for (let i = 0; i < activity.count; i++) {
                syncOperations.add.push({
                    id: uuidv4(),
                    guestId: null,
                    minAge: activity.minage,
                    maxAge: activity.maxage,
                });
            }
        }

        syncOperations.keep = activityItems.filter(activityItem => {
            const matchItem = syncOperations.add.find(item => {
                return item.minAge === activityItem.minAge && item.maxAge === activityItem.maxAge;
            });

            if (matchItem) {
                // Item is a match, keep existing and prevent if from being added again.
                syncOperations.add = syncOperations.add.filter(item => item !== matchItem);
                return true;
            }

            return false;
        });

        const newItems = [...syncOperations.keep, ...syncOperations.add];

        if (!isEqual(activityItems, newItems)) {
            dispatch(updateProduct(activity, { key: "items", value: newItems }));
        }
    };

    useEffect(() => {
        activities.forEach(syncActivityItems);

        // activities.forEach(activity => {
        //     if (activity.items && activity.items.length === activity.count) {
        //         // Empty statment.
        //     } else {
        //         if (activity.items) {
        //             let rest = activity.count - activity.items.length;
        //             let items = activity.items;
        //             if (rest > 0) {
        //                 //console.log("CREATE " + rest + " skipaaess");

        //                 for (let index = 0; index < rest; index++) {
        //                     const uuid = uuidv4();
        //                     items.push({ id: uuid, guestId: null, minAge: 3, maxAge: 20 });
        //                 }
        //             } else {
        //                 rest = rest * -1;
        //                 //console.log("Remove " + rest + " skipaaess");
        //                 for (let index = 0; index < rest; index++) {
        //                     items.pop();
        //                 }
        //             }
        //             dispatch(updateProduct(activity, { key: "items", value: items }));
        //         } else {
        //             let items = [];
        //             for (let count = 0; count < activity.count; count++) {
        //                 const uuid = uuidv4();
        //                 items.push({ id: uuid, guestId: null });
        //             }
        //             dispatch(updateProduct(activity, { key: "items", value: items }));
        //         }
        //     }
        // });

        // eslint-disable-next-line
    }, []);

    const handleProductChange = (fieldName, fieldValue, product, productItem) => {
        updateProductItem(product, productItem, fieldName, fieldValue);
    };

    /**
     * Update a product item.
     * Do not update guest data because we don't want to save phone numbers on guests.
     *
     * @param {object} product
     * @param {object} productItem
     * @param {string} key
     * @param value
     */
    const updateProductItem = (product, productItem, key, value) => {
        dispatch(
            updateProduct(product, {
                key: "items",
                value: product.items.map(item => (item.id === productItem.id ? { ...item, [key]: value } : item)),
            })
        );
    };

    const renderActivities = () => {
        return activities.map(activity => {
            return (
                !activity.detlid &&
                activity.items?.map(item => {
                    return (
                        <Activity
                            id={item.id}
                            key={item.id}
                            texts={texts}
                            activity={activity}
                            item={item}
                            guests={guestsOnTravel}
                            allowNotes={allowActivityNotes}
                            onUpdate={handleProductChange}
                            onRemove={(activity, values) => {
                                props.removeProductFromCart(activity, values);
                            }}
                        />
                    );
                })
            );
        });
    };

    return (
        <div className="pt-4">
            <InformationBlock className="mb-4">{texts?.missingguestinformation}</InformationBlock>

            <div>{renderActivities()}</div>
            {props.toggleGuests && (
                <Button
                    onClick={() => {
                        dispatch(createGuestOnCart(checkoutCartId, userToken));
                        props.toggleGuests();
                    }}
                    variant="light"
                    size="sm"
                    className="activities-fillout__add-guest-button"
                >
                    {texts?.checkoutaddguest}
                    <Icon name="FaPlus" size={14} color="#333333" />
                </Button>
            )}
        </div>
    );
};

export default Activities;
