import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row } from "react-bootstrap";
import ClipLoader from "react-spinners/ClipLoader";
import { Fade } from "react-bootstrap";
import { Alert, Theme, Button as UIButton } from "../../components/UI";
import * as types from "../../store/actions/types";
import { sendSignal } from "../../signals";
import { fetchReservation } from "../../store/actions/account";
import { partPaymentType } from "../../Constants";
import PartPayment from "../../components/PartPayment/PartPayment";
import { isBooked } from "../../BusinessUtils";
import useQueryString from "../../hooks/QueryString/useQueryString";
import { createPurchaseSignal } from "../../signals/purchaseSignal";
import useTranslate from "../../hooks/useTranslate";
import _ from "lodash";
import { isIframe, sendMessage } from "../../Helper";

const trackPurchase = (booking, onTrackedProducts) => {
    if (!booking || !booking.reservation) {
        return;
    }

    if (booking.reservation.cancelled) {
        return;
    }

    if (booking.reservationrules.totamounttopay <= 0) {
        return;
    }

    const products = Object.values(booking.reservation.products).filter(isBooked);

    if (!products) {
        return;
    }

    // Only send signal if any booked product has not been tracked before

    // const allowSignal = bookedDetlids.some(detlid => {
    //     // If detlid has not been tracked before it's ok to send the signal
    //     return !trackedProducts.includes(detlid);
    // });

    // Prevent sending duplicate signals
    // setTrackedProducts(union(trackedProducts, bookedDetlids));

    // Send the signal after a short while to allow the tracked products to be stored in state
    setTimeout(() => {
        const purchaseSignal = createPurchaseSignal(booking);
        sendSignal(purchaseSignal);

        if (typeof onTrackedProducts === "function") {
            const bookedDetlids = products.map(product => product.detlid);
            onTrackedProducts(bookedDetlids);
        }

        console.log("Signaling: Track with signal", purchaseSignal);
    }, 500);
};

const CheckoutPayPage = () => {
    const t = useTranslate();
    const dispatch = useDispatch();
    const { resvid, payerlid } = useParams();
    const [verificationHash] = useQueryString("verificationHash");
    const userToken = useSelector(state => state.account.token);
    const isAgent = !!useSelector(state => state.account.user?.isAgent);
    const reservations = useSelector(state => state.account.reservations);
    const currency = useSelector(state => state.clientData?.options?.general?.currency);
    let preferredclientpayment = useSelector(state => state.clientData.options.payment.preferredclientpayment);

    // -- Local state
    const [loading, setLoading] = useState(false);
    const [paymentSelection, setPaymentSelection] = useState(partPaymentType.FINALPAYAMOUNT);
    const [trackedPurchase, setTrackedPurchase] = useState(false);
    const [hasRedirectedToPayPage, setHasRedirectedToPayPage] = useState(false);

    // -- Computed values
    if (isAgent) {
        preferredclientpayment = "invoice";
    }

    const booking = reservations?.find(item => item.resvid === resvid);
    const toPay = booking?.reservationrules?.totamounttopay;
    const isValidBooking = !!booking?.resvid;

    const showPartPaymentChoices =
        preferredclientpayment !== "invoice" &&
        booking &&
        booking.reservationrules?.totamounttopay > 0 &&
        booking.reservationrules?.allowprepayment &&
        booking.reservationrules.minpayamount > 0 &&
        booking.reservationrules.minpayamount < booking.reservationrules.maxpayamount;

    // -- Callbacks and effects
    const handleTrackPurchase = useCallback(() => {
        setTrackedPurchase(true);
    }, []);

    const loadReservation = useCallback(
        (userToken, resvid, verificationHash) => {
            if (userToken || verificationHash) {
                setLoading(true);
                dispatch(fetchReservation(userToken, resvid, verificationHash));
            }
        },
        [dispatch]
    );

    const handleContinueToPaymentClick = () => {
        continueToPayment();
    };

    const continueToPayment = useCallback(() => {
        dispatch({ type: types.DESTORY_STATE });

        // Allow for state to be cleared by delaying redirect
        _.delay(() => {
            redirectToFinalPayPage(preferredclientpayment, booking.resvid, payerlid, paymentSelection);
        }, 1000);
    }, [dispatch, preferredclientpayment, booking, payerlid, paymentSelection]);

    const redirectToFinalPayPage = (preferredclientpayment, resvid, payerlid, paymentSelection) => {
        let payUrl = `${window.__BASE_URL__}/pay/${preferredclientpayment}/${resvid}/0/${payerlid}?source=r360online.booking`;

        if (paymentSelection) {
            payUrl += `&paytype=${paymentSelection}`;
        }

        setHasRedirectedToPayPage(true);

        if (isIframe()) {
            // This confirmation url does not need a codebase or base url prepended because it's handled by the iframe integration script
            // because this redirect will escape the iframe to allow for Strong Customer Authentication (SCA) at the payment providers
            // which doesn't work any good inside iframes.
            //
            // The iframe script will prepend the parent website's origin and pathname to "remember" where to redirect the customer after
            // a completed payment.
            const confirmationUrl = `pay/confirmation/${resvid}?lang=${window.__LANG__}`;

            sendMessage({
                event: "redirectToPayPage",
                payUrl: payUrl,
                confirmationUrl: confirmationUrl,
            });
        } else {
            // When we are not in an iframe we redirect the customer directly to the pay page
            let confirmationUrl;

            if (window.location.hostname === "localhost") {
                confirmationUrl = `${window.location.origin}/pay/confirmation/${resvid}?lang=${window.__LANG__}`;
            } else {
                confirmationUrl = `/${window.__CODEBASE__}client/pay/confirmation/${resvid}?lang=${window.__LANG__}`;
            }

            const integrationsUrl = btoa(confirmationUrl);
            const fullUrl = `${payUrl}&integrationsurl=${integrationsUrl})`;

            window.location.href = fullUrl;
        }
    };

    useEffect(() => {
        // Keep the loading state for non-invoice payments having an amount to pay which are being redirected to a pay page
        if (
            (preferredclientpayment === "invoice" || toPay === 0 || showPartPaymentChoices) &&
            isValidBooking &&
            loading
        ) {
            setLoading(false);
        }
    }, [booking, isValidBooking, loading, preferredclientpayment, toPay, showPartPaymentChoices]);

    useEffect(() => {
        loadReservation(userToken, resvid, verificationHash);
    }, [dispatch, loadReservation, userToken, resvid, verificationHash]);

    useEffect(() => {
        if (!isAgent && !trackedPurchase) {
            trackPurchase(booking, handleTrackPurchase);
        }
    }, [handleTrackPurchase, isAgent, booking, trackedPurchase]);

    // Check if we shall redirect directly to pay page
    useEffect(() => {
        if (!isAgent && !hasRedirectedToPayPage && trackedPurchase && !showPartPaymentChoices) {
            continueToPayment();
        }
    }, [continueToPayment, isAgent, trackedPurchase, showPartPaymentChoices, hasRedirectedToPayPage]);

    const renderLoader = () => {
        return (
            <Fade in={loading} unmountOnExit={true}>
                <div
                    style={{
                        position: "fixed",
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        backgroundColor: "rgba(255,255,255,0.6)",
                        zIndex: 99999,
                    }}
                >
                    <div
                        style={{
                            position: "absolute",
                            left: 0,
                            right: 0,
                            top: "45%",
                            marginLeft: "auto",
                            marginRight: "auto",
                            width: "100px",
                            textAlign: "center",
                            alignItems: "center",
                        }}
                    >
                        <Fade in={loading}>
                            <ClipLoader size={60} color={Theme.palette.primary} loading={true} />
                        </Fade>
                    </div>
                </div>
            </Fade>
        );
    };

    const renderInvoiceConfirmationDetails = () => {
        return (
            <Container>
                <h1 style={{ paddingBottom: "50px" }}>{t("tripbookedinvoiceheader")}</h1>
                <h5 className="fw-light">{t("tripbookedinvoicesubheader")}</h5>

                {t("confirmation.message").replace("%0", resvid)}
            </Container>
        );
    };

    const renderPartPaymentChoices = () => {
        return (
            <Container>
                <h1 style={{ paddingBottom: "50px" }}>{t("tripbookedheader")}</h1>
                <div style={{ paddingBottom: "50px" }}>
                    <h5 className="fw-light">{t("tripbookedsubheader")}</h5>

                    {showPartPaymentChoices && (
                        <Row className="mt-5">
                            <Col xl={5} md={7} sm={12} xs={12}>
                                <PartPayment
                                    value={paymentSelection}
                                    onChange={setPaymentSelection}
                                    minAmountToPay={booking.reservationrules.minpayamount}
                                    maxAmountToPay={booking.reservationrules.maxpayamount}
                                    currency={currency}
                                />
                            </Col>
                        </Row>
                    )}

                    <UIButton
                        className="button checkout-summary__button-continue-button"
                        onClick={handleContinueToPaymentClick}
                    >
                        {t("continuetopayment")}
                    </UIButton>
                </div>
            </Container>
        );
    };

    const renderNothingMoreToPay = () => {
        return (
            <Alert type="success">
                <h1>{t("book.pay.nothingmoretopay").replace("#bookingnumber#", booking?.resvid)}</h1>
            </Alert>
        );
    };

    return (
        <Container>
            {renderLoader()}

            {preferredclientpayment === "invoice" && renderInvoiceConfirmationDetails()}

            {preferredclientpayment !== "invoice" &&
                isValidBooking &&
                toPay > 0 &&
                showPartPaymentChoices &&
                renderPartPaymentChoices()}

            {preferredclientpayment !== "invoice" && isValidBooking && toPay === 0 && renderNothingMoreToPay()}
        </Container>
    );
};

export default CheckoutPayPage;
