import React from "react";
import { Col, Row } from "react-bootstrap";
import "./TestEnvironmentBar.scss";

const TestEnvironmentBar = () => {
    return (
        <div className="test-environment-bar">
            <div className="container">
                <Row>
                    <Col className="py-3 d-flex align-items-center justify-content-center">
                        <div className="me-5">
                            Testversion av <b>Bokningsmotorn</b>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default TestEnvironmentBar;
