import sum from "lodash/sum";
import queryString from "query-string";
import React, { useCallback } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { productTypeNames } from "../../Constants";
import { getGuestObjectForReservationTypeFromArray } from "../../Helper";
import AccommodationResultPage from "../../pages/AccommodationResultPage/OldAccommodationResultPage";
import PackageResultPage from "../../pages/OldPackageResultPage";
import BikeRentalPage from "../../pages/OldBikeRentalPage";
import { optionsSelector } from "../../selectors/Selectors";
import ActivitiesView from "../OldActivitiesView/OldActivitiesView";
import AdditionalProduct from "../AdditionalProduct/AdditionalProduct";
import LettingView from "../LettingView/OldLettingView";
import OrderOverview from "../OrderOverview/OrderOverview";
import SkipassView from "../SkipassViewOld/SkipassView";
import { Carousel, RichText } from "../UI";
import { Notification } from "@r360/library";
import classNames from "classnames";
import { textsSelector } from "../../Selectors";

const SearchReservationTypes = ({ activeReservationType }) => {
    const reservationTypes = useSelector(state => state.clientData.reservationtypes).filter(item => !item.hidden);
    const isMobile = useSelector(state => state.window.isMobile);
    const cartProducts = useSelector(state => state.checkoutCart.products);
    const reservationResults = useSelector(state => state.reservationResult);
    const guestData = useSelector(state => state.clientData?.options?.general);
    const navigate = useNavigate();
    const reservationTypeCardsBackgroundColor =
        useSelector(optionsSelector).layout?.reservation_type_cards_background_color || "";
    const newDesignMenu = useSelector(optionsSelector).layout?.new_design_menu;

    const texts = useSelector(textsSelector);
    const reservationHeaderText = texts["book.header.information"];

    const getLinkForItem = useCallback(
        item => {
            const baseLink = `/search/${item.lid}`;

            const search = reservationResults[item.lid]?.search;

            if (!search) {
                return baseLink;
            }

            if (search.checkPriceAndAvailability && search.arrdate && search.depdate) {
                let queryStringObject = {};
                queryStringObject.startDate = search.arrdate;
                queryStringObject.endDate = search.depdate;

                if (item.type === productTypeNames.ACCOMMODATION) {
                    if ((search.guests || []).length === 0) {
                        return baseLink;
                    }

                    const guests = getGuestObjectForReservationTypeFromArray(search.guests, item.lid, guestData);

                    if (sum(Object.values(guests)) === 0) {
                        return baseLink;
                    }

                    Object.entries(guests).forEach(([key, value]) => {
                        queryStringObject[key] = value;
                    });
                }

                return `${baseLink}?${queryString.stringify(queryStringObject)}`;
            }

            return baseLink;
        },
        [reservationResults, guestData]
    );

    // Carousel item
    const carouselItem = useCallback(
        (item, index) => {
            const linkForItem = getLinkForItem(item);

            return (
                <AdditionalProduct
                    key={item.lid}
                    product={item}
                    index={index}
                    onClick={() => navigate(linkForItem)}
                    isActive={activeReservationType === item}
                    backgroundColorOverride={reservationTypeCardsBackgroundColor?.trim()}
                />
            );
        },
        [getLinkForItem, activeReservationType, navigate, reservationTypeCardsBackgroundColor]
    );

    // Check if there are too few items to fill the carousel
    const isFewerItems = () => {
        const numberOfCarouselItems = reservationTypes.length;

        if (isMobile && numberOfCarouselItems >= 3) {
            return false;
        }
        return true;
    };

    const getPeekValue = () => {
        if (isFewerItems()) {
            return 0;
        } else if (isMobile) {
            return { before: 0, after: 50 };
        }
        return 0;
    };

    const renderActiveReservationType = () => {
        const properties = {
            cartProducts,
            reservationTypeid: activeReservationType.lid,
        };

        switch (activeReservationType.type) {
            case productTypeNames.SKIPASS: {
                return <SkipassView {...properties} />;
            }
            case productTypeNames.ACTIVITY: {
                return <ActivitiesView {...properties} />;
            }
            case productTypeNames.LETTING: {
                return <LettingView {...properties} />;
            }
            case productTypeNames.RENTAL: {
                return <BikeRentalPage reservationType={activeReservationType.lid} />;
            }
            case productTypeNames.ACCOMMODATION: {
                return <AccommodationResultPage reservationType={activeReservationType.lid} />;
            }
            case productTypeNames.PACKAGE: {
                return <PackageResultPage reservationType={activeReservationType.lid} />;
            }
            default: {
                return `Innehåll för ${activeReservationType.description}`;
            }
        }
    };

    const activeReservationTypeIsAccommodation = activeReservationType?.type === productTypeNames.ACCOMMODATION;
    const activeReservationTypeIsPackage = activeReservationType?.type === productTypeNames.PACKAGE;

    return (
        <div className="SearchReservationTypes">
            {!newDesignMenu &&
                (!isMobile ? (
                    <Carousel
                        perView={4}
                        peek={getPeekValue()}
                        bound={true}
                        items={reservationTypes}
                        itemComponent={carouselItem}
                        showBullets={false}
                    />
                ) : (
                    <div style={{ overflowX: "auto", display: "flex" }}>{reservationTypes.map(carouselItem)}</div>
                ))}
            {newDesignMenu && reservationHeaderText && (
                <div style={{ padding: "12px 0" }}>
                    <Notification type="info">
                        {
                            <div>
                                <RichText content={reservationHeaderText} />
                            </div>
                        }
                    </Notification>
                </div>
            )}
            <Row style={{ marginTop: "1rem" }}>
                <Col md={12} lg={activeReservationTypeIsAccommodation || activeReservationTypeIsPackage ? 12 : 9}>
                    <div
                        className={classNames("SearchReservationTypes__container", {
                            SearchReservationTypes__container_accommodation: activeReservationTypeIsAccommodation,
                            SearchReservationTypes__container_package: activeReservationTypeIsPackage,
                        })}
                    >
                        {activeReservationType && renderActiveReservationType()}
                    </div>
                </Col>

                {!newDesignMenu &&
                    activeReservationType &&
                    !activeReservationTypeIsAccommodation &&
                    !activeReservationTypeIsPackage && (
                        <Col md={12} lg={3}>
                            <div className="SearchReservationTypes__order-overview" aria-live="polite">
                                <OrderOverview
                                    showCheckoutButton
                                    products={cartProducts}
                                    reservationTypes={reservationTypes}
                                />
                            </div>
                        </Col>
                    )}

                {/* </Route> */}
            </Row>
        </div>
    );
};
export default SearchReservationTypes;
