import cloneDeep from "lodash/cloneDeep";
import * as types from "../actions/types";

const initialState = {
    hitsMatchingFilter: "",
    search: "",
    groups: null,
    error: false,
};

const reducer = (state = {}, action) => {
    switch (action.type) {
        case types.SET_RESERVATIONTYPERESULT: {
            const { payload, resvtype, promoCode, checkPriceAndAvailability, grouppoollid, pricecode, isLoggedIn } =
                action;
            let resvResult = {
                ...state[resvtype],
                ...payload,
                error: false,
            };

            if (promoCode) {
                resvResult.search.promoCode = promoCode;
            }

            if (payload.search?.guests === "") {
                resvResult.search.guests = [];
            }

            resvResult.search.checkPriceAndAvailability = !!checkPriceAndAvailability;
            resvResult.search.isLoggedIn = !!isLoggedIn;

            if (grouppoollid) {
                resvResult.search.grouppoollid = grouppoollid;
            }

            resvResult.search.pricecode = pricecode;

            return {
                ...state,
                [resvtype]: resvResult,
                // hitsMatchingFilter: action.payload.hitsMatchingFilter,
                // groups: action.payload.groups,
                // search: action.payload.search,
                // error: false,
            };
        }

        case types.FETCH_RESERVATIONTYPERESULT_FAILED: {
            const resvResult = {
                ...state[action.resvtype],
                error: true,
            };
            return {
                ...state,
                [action.resvtype]: resvResult,
            };
        }

        case types.SET_RESULTSETID: {
            const resvResult = {
                ...state[action.resvtype],
                resultSetId: action.payload,
            };
            return {
                ...state,
                [action.resvtype]: resvResult,
            };
        }

        case types.CLEAR_ALL_RESERVATIONRESULTDATA: {
            return {
                // empty object to clear everything
            };
        }

        case types.CLEAR_RESERVATIONRESULTDATA: {
            return {
                ...state,
                [action.resvtype]: initialState,
            };
        }

        case types.REMOVE_RESERVATIONRESULT: {
            const { [action.resvtype]: _, ...rest } = state;
            return rest;
        }

        case types.FETCH_ADDITIONALINITEMFORMATION_FAILED: {
            const resvResult = {
                ...state[action.resvtype],
                error: true,
            };
            return {
                ...state,
                [action.resvtype]: resvResult,
            };
        }

        case types.SET_ADDITIONALINITEMFORMATION: {
            let resvResult = {
                ...state[action.resvtype],
            };
            let groups = {
                ...state[action.resvtype].groups,
            };

            groups[action.groupId].items = state[action.resvtype].groups[action.groupId].items.map(item => {
                if (
                    item.poollid === action.payload.product.poollid &&
                    item.unitlid === action.payload.product.unitlid &&
                    item.pricecode === action.payload.product.pricecode
                ) {
                    const modifiedItem = Object.assign(
                        {},
                        item,
                        {
                            pooldesc3: action.payload.product.pooldesc3,
                            unitdesc3: action.payload.product.unitdesc3,
                            title: action.payload.product.title,
                        },
                        {
                            info: {
                                extras: action.payload.extras,
                            },
                        }
                    );

                    return modifiedItem;
                }

                return item;
            });

            resvResult.groups = groups;

            return {
                ...state,
                [action.resvtype]: resvResult,
            };
        }

        case types.FETCH_PRODUCTS_EXTRAS_FAILED: {
            const resvResult = {
                ...state[action.resvtype],
                error: true,
            };
            return {
                ...state,
                [action.resvtype]: resvResult,
            };
        }

        case types.SET_PRODUCTS_EXTRAS: {
            const reservationTypeId = action.resvtype;
            const productsExtras = action.payload;
            const groups = cloneDeep(state[reservationTypeId]?.groups);

            if (!groups) {
                return;
            }

            const groupEntries = Object.entries(groups);

            const findGroupId = productId =>
                groupEntries.find(([groupId, group]) => group.items?.some(item => item.id === productId))?.[0];

            Object.entries(productsExtras).forEach(([productId, extras]) => {
                productId = parseInt(productId, 10);
                const groupId = findGroupId(productId);
                const groupItems = groups[groupId]?.items;

                if (!groupItems) {
                    return;
                }

                groups[groupId].items = groupItems.map(item => {
                    if (item.id === productId) {
                        item.info = { ...(item.info || {}), extras: extras };
                    }

                    return item;
                });
            });

            return {
                ...state,
                [reservationTypeId]: {
                    ...state[reservationTypeId],
                    groups: groups,
                },
            };
        }

        case types.SET_SLOW_REQUEST_PARAM: {
            return {
                ...state,
                slowRequest: action.slowRequest,
            };
        }

        default: {
            return {
                ...state,
            };
        }
    }
};

export default reducer;
