import * as types from "../actions/types";

const initialState = {
    reservation: null,
    error: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_RESERVATION:
            return {
                ...state,
                reservation: action.reservation,
            };

        case types.RESET_RESERVATION:
            return {
                ...initialState,
            };

        case types.MAKE_RESERVATION_FAILED:
            return {
                ...state,
                reservation: null,
                error: action.error,
            };

        case types.CLEAR_ERRORS: {
            return {
                ...state,
                error: null,
            };
        }

        default: {
            return {
                ...state,
            };
        }
    }
};

export default reducer;
