import React from "react";
import { useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import { Alert } from "../components/UI";
import useTranslate from "../hooks/useTranslate";

// Confirmation page
const CheckoutConfirmationPage = () => {
    const t = useTranslate();
    const { resvid } = useParams();

    return (
        <Container>
            <Alert type="success">
                <h1>{t("book.pay.paymentcompleted").replace("#bookingnumber#", resvid)}</h1>
            </Alert>
        </Container>
    );
};

export default CheckoutConfirmationPage;
