import * as types from "../actions/types";

const initialState = {
    loading: null,
    responses: [],
    requests: [],
    error: null,
    requestsLoading: {},
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_LOADING:
            return {
                ...state,
                loading: action.loading,
            };

        case types.SET_REQUESTS_LOADING:
            return {
                ...state,
                requestsLoading: {
                    ...state["requestsLoading"],
                    [action.request]: action.loading,
                },
            };

        default:
            return {
                ...state,
            };
    }
};

export default reducer;
