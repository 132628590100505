import React from "react";
import { RootState } from "../..";
import useAppSelector from "../../hooks/useAppSelector";
import { TReservationType } from "../../store/types";
import "./ReservationTypeImage.scss";
import { SkipassDesktop, SkipassMobile } from "../../assets/reservationTypes/Skipass";
import { RentalDesktop, RentalMobile } from "../../assets/reservationTypes/Rental";
import { LettingDesktop, LettingMobile } from "../../assets/reservationTypes/Letting";
import { ActivityDesktop, ActivityMobile } from "../../assets/reservationTypes/Activity";
import { getOptimizedImageUrlFromImageUrlAndWidth } from "@r360/library";

type TReservationTypeImage = {
    reservationTypeId: number;
};

type TImage = {
    desktop?: string;
    mobile?: string;
};

export const ReservationTypeImage = ({ reservationTypeId }: TReservationTypeImage) => {
    const { isMobile } = useAppSelector((state: RootState) => state.window);
    const clientData = useAppSelector((state: RootState) => state.clientData);
    const { type, desktopImage, mobileImage } = clientData.reservationtypes.find(
        (resvType: TReservationType) => resvType.lid === reservationTypeId
    ) as TReservationType;

    const fallbackImage = {
        SKIPASS: {
            desktop: SkipassDesktop,
            mobile: SkipassMobile,
        },
        LETTING: {
            desktop: LettingDesktop,
            mobile: LettingMobile,
        },
        RENTAL: {
            desktop: RentalDesktop,
            mobile: RentalMobile,
        },
        ACTIVITY: {
            desktop: ActivityDesktop,
            mobile: ActivityMobile,
        },
    }[type] as TImage;

    const optimizedDesktopImage = getOptimizedImageUrlFromImageUrlAndWidth(desktopImage, window.innerWidth);
    const optimizedMobileImage = getOptimizedImageUrlFromImageUrlAndWidth(mobileImage, window.innerWidth);

    const image: TImage = {
        desktop: optimizedDesktopImage ?? fallbackImage?.desktop,
        mobile: optimizedMobileImage ?? fallbackImage?.mobile,
    };

    return (
        <div
            className="reservation-type-image"
            style={{
                backgroundImage: `url(${!isMobile ? image.desktop : image.mobile})`,
            }}
        ></div>
    );
};
