import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import configureStore from "./configureStore";
import App from "./App";
import ScrollToTop from "./components/ScrollToTop";
import { Container } from "react-bootstrap";
import { ErrorBoundary } from "react-error-boundary";
import { _ } from "./Helper";
import { Alert } from "./components/UI";
import axiosClient from "./API/AxiosClient";
import ErrorInterceptor from "./store/errorInterceptor";
import moment from "moment-timezone";
import "moment-timezone/node_modules/moment/locale/nb";
import "moment-timezone/node_modules/moment/locale/sv";
import { system } from "./Constants";

// Set moment default locale
moment.locale(system.LANGUAGE);

export type ErrorFallbackInterface = { error: Error; componentStack?: string };

const ErrorFallback = ({ error, componentStack }: ErrorFallbackInterface) => {
    const err = JSON.stringify(error?.toString());

    if (err.indexOf("Access is denied for this document") > -1) {
        return (
            <Container>
                <div className="alert-message--network-success">
                    <Alert type="info" reload>
                        <h1>Tillåt tredjepartcookies</h1>
                        <p>
                            För att kunna nyttja bokningsapplikationen måste du tillåta tredje part kakor i din
                            webbläsare. Detta för att vi ska kunna förbättra din upplevelse.
                            <br />
                            <b>Detta hittar du under </b>
                            <pre>Inställningar -&gt; Sekretess och säkerhet</pre>
                        </p>
                    </Alert>
                </div>
            </Container>
        );
    }

    // SecurityError: Failed to read the 'localStorage' property from 'Window': Access is denied for this document.

    return (
        <Container>
            <div className="alert-message--network-error">
                <Alert type="info" reload>
                    <h1>Ett fel uppstod</h1>
                    <p>
                        Ett fel uppstod, vi har loggat felet och kommer titta närmre på det. Vänligen försök igen, ladda
                        om sidan eller klicka på knappen till höger.
                    </p>
                    <div className="alert-message--network-error-code text-muted">
                        <pre>{error.message}</pre>
                        <pre>{componentStack}</pre>
                    </div>
                </Alert>
            </div>
        </Container>
    );
};

const store = configureStore();
ErrorInterceptor.interceptor(axiosClient, store);

const container = document.getElementById("main-content-node")!;
const root = createRoot(container);

root.render(
    <ErrorBoundary
        onReset={() => {
            const params = ["error=1"];

            window.location.href =
                "https://" + window.location.host + window.location.pathname + "?" + params.join("&");
        }}
        FallbackComponent={ErrorFallback}
    >
        <Provider store={store}>
            <BrowserRouter basename={process.env.PUBLIC_URL}>
                <ScrollToTop />
                <App />
            </BrowserRouter>
        </Provider>
    </ErrorBoundary>
);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
