import React, { useCallback, useEffect, useState } from "react";
import {
    AccommodationResultCard,
    AccommodationResultCardCriteria,
} from "../AccommodationResultCard/AccommodationResultCard";
import classNames from "classnames";
import { RootState } from "../..";
import {
    ECriteriaPresentationType,
    TCriteriaEnum,
    TCriteriaItem,
    TGroup,
    TItem,
    TReservationTypeCriterias,
    TReservationTypeLid,
} from "../../store/types";
import { AccommodationResultCardSkeleton } from "../AccommodationResultCard/AccommodationResultCardSkeleton";
import useMemoizeArg from "../../hooks/useMemoizeArg";
import useAppSelector from "../../hooks/useAppSelector";
import { getInspectionDataForGroup, getLowestPriceAccommodation } from "../../BusinessUtils";
import { sortBy } from "lodash";

type TAccommodationResultList = {
    reservationTypeId: TReservationTypeLid;
    reservationTypeResult: TGroup[];
    reservationTypeCriterias: TReservationTypeCriterias;
    promoCode: string;
    belowFilter?: boolean;
    isLoading?: boolean;
    filterIsPresent?: boolean;
};

const ACCOMMODATION_TYPE_CRITERIA_CODE = 1006;

const AccommodationResultList = ({
    reservationTypeId,
    reservationTypeResult,
    reservationTypeCriterias,
    promoCode,
    belowFilter = false,
    isLoading,
    filterIsPresent = false,
}: TAccommodationResultList) => {
    const currency = useAppSelector((state: RootState) => state.clientData?.options?.general?.currency);

    const [accommodationAreaQuantityDistanceCriterias, setAccommodationAreaQuantityDistanceCriterias] = useState<
        TCriteriaItem[]
    >([]);
    const [accommodationTypeEnums, setAccommodationTypeEnums] = useState<TCriteriaEnum[]>([]);

    useEffect(() => {
        setAccommodationAreaQuantityDistanceCriterias(
            reservationTypeCriterias[ECriteriaPresentationType.ACCOMMODATION_AREA_QUANTITY_DISTANCE_CRITERIAS] || []
        );

        setAccommodationTypeEnums(
            (reservationTypeCriterias[ECriteriaPresentationType.ACCOMMODATION_TYPE_FILTERS] || []).find(
                criteria => criteria.code === ACCOMMODATION_TYPE_CRITERIA_CODE
            )?.enums || []
        );
    }, [useMemoizeArg(reservationTypeCriterias)]);

    // Only show price if there are at least one item with a price code.
    const showPrice = useCallback((group: TGroup) => {
        return group.items?.some(item => !!item?.pricecode);
    }, []);

    // Only show promotion code if there are at least one item with a promo code.
    const showPromoCode = useCallback((group: TGroup) => {
        return group.items?.some(item => !!item?.promotionCode);
    }, []);

    if (isLoading) {
        return (
            <div className="row">
                {[...Array(!filterIsPresent || belowFilter ? 3 : 4)].map((_item, index) => {
                    return (
                        <div
                            className={classNames("accommodation-card-wrapper col-12 col-md-6", {
                                "col-xl-4": !filterIsPresent || belowFilter,
                            })}
                            key={"loader" + index}
                        >
                            <AccommodationResultCardSkeleton />
                        </div>
                    );
                })}
            </div>
        );
    }

    const getUniqueAccommodationsCount = (items: TItem[]) => {
        const count = new Set([...items.map(item => `${item.poollid}_${item.unitlid}`)]).size;
        return count || items.length;
    };

    const getResultCardCriterias = (items: TItem[]): AccommodationResultCardCriteria[] => {
        return accommodationAreaQuantityDistanceCriterias.reduce((acc, critiera) => {
            // Find min and max from reservationTypeResult for current critiera.
            const values = items.map(item => item.crits[critiera.code]).filter(value => !isNaN(value));

            if (!values.length) {
                return acc;
            }

            const min = Math.min(...values);
            const max = Math.max(...values);
            const formattedValue =
                min === max ? `${min} ${critiera.typeunit}`.trim() : `${min}-${max} ${critiera.typeunit}`.trim();

            acc.push({
                title: critiera.title,
                value: formattedValue,
            });

            return acc;
        }, [] as AccommodationResultCardCriteria[]);
    };

    const getAccommodationTypes = (items: TItem[]): string[] => {
        return [
            ...new Set(
                items
                    .map(
                        item =>
                            accommodationTypeEnums.find(
                                enumItem => enumItem.code === item.crits[ACCOMMODATION_TYPE_CRITERIA_CODE]
                            )?.title || ""
                    )
                    .filter(value => !!value)
            ),
        ];
    };

    const startSlice = belowFilter ? 4 : 0;
    const endSlice = !filterIsPresent || belowFilter ? reservationTypeResult.length : 4;
    const slicedDisplayableGroups = reservationTypeResult.slice(startSlice, endSlice);

    return (
        <div className="row">
            {slicedDisplayableGroups.map(group => {
                // Replace groupitem price with lowest price of items within each group
                const { price, ordinaryprice } = getLowestPriceAccommodation(group.items);
                const itemCount = getUniqueAccommodationsCount(group.items);
                const criterias = getResultCardCriterias(group.items);
                const accommodationTypes = getAccommodationTypes(group.items);

                return (
                    <div
                        key={group.id}
                        className={classNames("accommodation-card-wrapper col-12 col-md-6", {
                            "col-xl-4": !filterIsPresent || belowFilter,
                        })}
                        {...getInspectionDataForGroup(group)}
                    >
                        <AccommodationResultCard
                            title={group.groupitem.title}
                            price={price}
                            ordinaryprice={ordinaryprice}
                            showPrice={showPrice(group)}
                            itemCount={itemCount}
                            reservationTypeId={reservationTypeId}
                            poollid={group.grouppoollid}
                            images={group.groupitem.images}
                            description={group.groupitem.weblong}
                            promoCode={showPromoCode(group) ? promoCode : ""}
                            currency={currency}
                            criterias={criterias}
                            accommodationTypes={accommodationTypes}
                        />
                    </div>
                );
            })}
        </div>
    );
};

export default AccommodationResultList;
