import * as React from "react";
import defautIconStats from "../Icon.types";
import Theme from "../../Theme";

const TrashCan = ({
    color = Theme.palette.primary,
    size = defautIconStats.size,
    iconClass = defautIconStats.iconClass,
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            style={{ height: size, width: size }}
            width="512pt"
            height="512pt"
            className={iconClass}
        >
            <g>
                <g>
                    <path
                        fill={color}
                        d="m436.982 87.454h-14.592v-16.478c0-12.461-10.138-22.599-22.599-22.599h-66.408v-18.442c0-16.506-13.428-29.935-29.934-29.935h-39.365c-4.142 0-7.499 3.357-7.499 7.499 0 4.141 3.357 7.499 7.499 7.499h39.365c8.236 0 14.937 6.701 14.937 14.937v18.443h-124.772v-18.443c0-8.236 6.701-14.937 14.937-14.937h20.58c4.141 0 7.499-3.357 7.499-7.499 0-4.141-3.357-7.499-7.499-7.499h-20.58c-16.506 0-29.935 13.429-29.935 29.935v18.443h-66.408c-12.461 0-22.599 10.138-22.599 22.599v16.478h-14.591c-8.899 0-16.14 7.24-16.14 16.139v43.252c0 8.899 7.24 16.14 16.14 16.14h1.241l32.191 304.59c2.676 25.327 23.9 44.426 49.368 44.426h196.365c25.468 0 46.692-19.099 49.367-44.426l26.236-248.243c.436-4.118-2.55-7.81-6.669-8.245-4.111-.433-7.81 2.55-8.245 6.669l-26.237 248.243c-1.867 17.676-16.679 31.004-34.453 31.004h-196.365c-17.774 0-32.586-13.329-34.453-31.004l-32.024-303.015h329.32l-2.148 20.331c-.435 4.119 2.55 7.81 6.669 8.246 4.111.434 7.81-2.55 8.245-6.669l2.316-21.908h1.241c8.9 0 16.14-7.24 16.14-16.14v-43.252c-.001-8.899-7.241-16.139-16.141-16.139zm-332.375-16.478c0-4.191 3.41-7.601 7.601-7.601h287.583c4.192 0 7.601 3.41 7.601 7.601v16.478h-302.785zm333.518 75.869c0 .63-.513 1.142-1.142 1.142h-361.965c-.63 0-1.142-.513-1.142-1.142v-43.252c0-.63.513-1.142 1.142-1.142h361.964c.63 0 1.142.512 1.142 1.142v43.252z"
                    />
                    <path
                        fill={color}
                        d="m165.275 192.854c-4.129.322-7.215 3.931-6.892 8.06l20.257 259.328c.307 3.93 3.591 6.915 7.467 6.915.196 0 .393-.008.592-.023 4.129-.322 7.215-3.931 6.892-8.06l-20.257-259.328c-.322-4.13-3.933-7.221-8.059-6.892z"
                    />
                    <path
                        fill={color}
                        d="m338.665 199.746-20.257 259.328c-.322 4.129 2.763 7.737 6.892 8.06.199.016.396.023.592.023 3.876 0 7.161-2.985 7.468-6.915l20.257-259.328c.322-4.129-2.763-7.737-6.892-8.06-4.125-.325-7.736 2.763-8.06 6.892z"
                    />
                    <path
                        fill={color}
                        d="m248.501 200.33v259.328c0 4.141 3.357 7.499 7.499 7.499s7.499-3.357 7.499-7.499v-259.328c0-4.141-3.358-7.499-7.499-7.499s-7.499 3.357-7.499 7.499z"
                    />
                </g>
            </g>
        </svg>
    );
};

export default TrashCan;
