import classnames from "classnames";
import debounce from "lodash/debounce";
import LogRocket from "logrocket";
// Need to import locales from moment-timezone/node_modules if version of moment is older.
// Check if timezone has momoment as installed dependency: `ls -l node_modules/moment-timezone/node_modules | grep moment`.
// Debug with `moment.locale(), yourdate.locale(), yourdate.tz()` where you want to use moment dates.
import moment from "moment-timezone";
import baseMoment from "moment";
import "moment/locale/nb";
import "moment/locale/sv";
import "moment/locale/da";
import "moment/locale/de";
import queryString from "query-string";
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { connect, useDispatch, useSelector, useStore } from "react-redux";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import packageInfo from "../package.json";
import "./App.scss";
import OldChangeReservationBar from "./components/ChangeReservationBar/OldChangeReservationBar";
import ChangeReservationBar from "./components/ChangeReservationBar/ChangeReservationBar";
import OrderOverviewCart from "./components/OrderOverview/OrderOverviewCart/OrderOverviewCart";
import SignUpWrapper from "./components/SignUpWrapper/SignUpWrapper";
import { confirmationDialog, MenuOld as Menu, Modal as ModalOld } from "./components/UI";
import LoadingOverlay from "./components/UI/LoadingOverlay/LoadingOverlay";
import RichText from "./components/UI/RichText";
import useWindowSize from "./components/UI/WindowSize";
import { syncStateToBrowserStorage } from "./configureStore";
import { customColors, system } from "./Constants";
import { isIframe, sendMessage, viewPortDimensions, _state } from "./Helper";
import useQueryString from "./hooks/QueryString/useQueryString";
import OrderOverviewPage from "./pages/Widget/OrderOverviewPage";
import { getRoutes, getStandaloneRoutes, myPageRouteNames } from "./routes";
import { languagesSelector, optionsSelector } from "./selectors/Selectors";
import { sendSignal, signal } from "./signals";
import * as signals from "./signals/types.js";
import { emptyCartProducts } from "./store/actions/checkoutCart";
import { bootstrapClient, setCookie } from "./store/actions/clientData";
import { resetFilterState } from "./store/actions/filter";
import { clearAllReservationTypeResults } from "./store/actions/reservationResult";
import { resetLastSearchedTimestamps } from "./store/actions/search";
import { createTimeStamp, screenResize, setIframeOffsetTop, setIsIframe } from "./store/actions/window";
import MainHeader from "./components/MainHeader";
import { authenticateAgent, authenticateOwner, signOutUser } from "./store/actions/account";
import AgentBar from "./components/AgentBar/AgentBar";
import classNames from "classnames";
import { createPageViewSignal } from "./signals/page";
import useDebounce from "./hooks/useDebounce";
import { Footer } from "./components/Footer/Footer";
import OwnerBar from "./components/OwnerBar/OwnerBar";
import LoginRegistrationModal from "./components/LoginSignUpModal";
import { Heading, Button, Modal, Notification, Logotype } from "@r360/library";
import useTranslate from "./hooks/useTranslate";
import { textsSelector } from "./Selectors";
import TestEnvironmentBar from "./components/TestEnvironmentBar/TestEnvironmentBar";
import { ProductsNotAddedToCartModal } from "./components/ProductsNotAddedToCartModal/ProductsNotAddedToCartModal";

const App = () => {
    document.getElementById("r360client-version").innerHTML = packageInfo.version;
    const store = useStore();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [queryLang, setQueryLang] = useQueryString("lang");
    const [ownerToken, setOwnerToken] = useQueryString("owner_token");
    const lastTimestamp = useSelector(state => state.window.timestamp);
    const loginSuccessful = useSelector(state => state.account.loginSuccessful);
    const account = useSelector(state => state.account);
    const error = useSelector(state => state.window.error);
    const texts = useSelector(textsSelector);
    const isTestSite = useSelector(state => state.clientData?.test) || false;
    const agent = useSelector(state => state.clientData?.agent);
    const site = useSelector(state => state.clientData?.site);
    const criterias = useSelector(state => state.availableFilters.default);
    const checkoutCart = useSelector(state => state.checkoutCart);
    const showWrapperModal = useSelector(state => state.window.signUpWrapper.showWrapper);
    const isBookingError = useSelector(state => state.window.isBookingError);
    const lang = useSelector(state => state.clientData?.lang);
    const windowSize = useWindowSize();
    const [cartExpiredCheckInterval, setCartExpiredCheckInterval] = useState();
    const [readySignalSent, setReadySignalSent] = useState(false);
    const [showCartExpiredModal, setShowCartExpiredModal] = useState(false);
    const resizeObserverRef = useRef(
        "ResizeObserver" in window ? new ResizeObserver(() => reportSizeToIframeParent()) : null
    );
    const { isDesktop, isMobile, isTablet, iframeOffsetTop } = useSelector(state => state.window);
    const myPageOnly = !!useSelector(optionsSelector).general?.mypage_only;
    const languages = useSelector(languagesSelector);
    const newDesignMenu = useSelector(optionsSelector).layout?.new_design_menu;
    const newDesignAccommodation = useSelector(optionsSelector).layout?.new_design_accommodation;
    const newDesignLoginSignup = useSelector(optionsSelector).layout?.new_design_login_signup;
    const newDesignCheckout = useSelector(optionsSelector).layout?.new_design_checkout;
    const newDesignMyPages = useSelector(optionsSelector).layout?.new_design_my_pages;

    // Force push 4
    const customPrimaryColor = useSelector(optionsSelector).style?.customprimarycolor;
    const customPrimaryHoverColor = useSelector(optionsSelector).style?.customprimaryhovercolor;
    const customPrimaryContrastColor = useSelector(optionsSelector).style?.customprimarycontrastcolor;
    const customLoginColor = useSelector(optionsSelector).style?.customlogincolor;
    const customLoginContrastColor = useSelector(optionsSelector).style?.customlogincontrastcolor;
    const t = useTranslate();
    const productsNotAddedPath = useSelector(state => state.checkoutCart.productsNotAddedPath);

    // Save object props to variables to be used in useEffect dependencies
    const pathname = location.pathname;
    const pathsearch = location.search;
    const pathstate = location.state;

    // Global configuration of timezone
    moment.tz.setDefault("Europe/Stockholm");

    /**
     * Set the current language as the locale for moment.
     * Some languages in our system may have a difference code than the locale code that moments accepts.
     */
    useEffect(() => {
        const defaultLocale = system.LANGUAGE;
        const localeMappings = {
            se: "sv",
            no: "nb",
            da: "da",
            de: "de",
        };
        const locale = localeMappings[lang] || lang || defaultLocale;

        // Both instances of moment (base and timezone) needs to get locale set.
        baseMoment.locale(locale); // moment
        moment.locale(locale); // moment-timezone
    }, [lang]);

    useEffect(() => {
        dispatch(setIsIframe(isIframe()));
    }, []);

    // Set custom colors
    useEffect(() => {
        const rootStyle = document.documentElement.style;
        const colorsAreAvailable = customPrimaryColor || customPrimaryHoverColor || customPrimaryContrastColor;

        if (colorsAreAvailable) {
            // Change the color variables if custom colors exists. Otherwise the r360 color variables will remain default
            if (customPrimaryColor) {
                rootStyle.setProperty(customColors.CUSTOMPRIMARYCOLOR, customPrimaryColor);
            }

            if (customPrimaryHoverColor) {
                rootStyle.setProperty(customColors.CUSTOMPRIMARYHOVERCOLOR, customPrimaryHoverColor);
            }

            if (customPrimaryContrastColor) {
                rootStyle.setProperty(customColors.CUSTOMPRIMARYCONTRASTCOLOR, customPrimaryContrastColor);
            }

            if (customLoginColor) {
                rootStyle.setProperty(customColors.CUSTOMLOGINCOLOR, customLoginColor);
            }

            if (customLoginContrastColor) {
                rootStyle.setProperty(customColors.CUSTOMLOGINCONTRASTCOLOR, customLoginContrastColor);
            }

            document.body.classList.add("enable-custom-colors");
        }
    }, [
        customPrimaryColor,
        customPrimaryHoverColor,
        customPrimaryContrastColor,
        customLoginColor,
        customLoginContrastColor,
    ]);

    // Clear search results when language is changed or when page reloads
    useEffect(() => {
        // Language was changed via URL
        if (queryLang) {
            console.log("Language was changed to '" + queryLang + "', or page reloaded. Clear search results"); // KEEP
            dispatch(resetLastSearchedTimestamps());
        }
    }, [dispatch, queryLang]);

    useEffect(() => {
        dispatch(screenResize(windowSize));
    }, [dispatch, windowSize]);

    // Auto sign out when session expired after x hours
    useEffect(() => {
        const everyMinute = 1000 * 60;
        const interval = setInterval(() => {
            if (loginSuccessful && lastTimestamp) {
                const now = moment();
                const lastTime = moment(lastTimestamp);
                const sessionMaxLengthHours = 3;
                const sessionLength = now.diff(lastTime, "minutes");
                const sessionExpired = sessionLength >= sessionMaxLengthHours * 60;
                console.log("SESSION TIME", `${sessionLength} minutes`);

                if (sessionExpired) {
                    dispatch(signOutUser("timeout", true));
                }
            }
        }, everyMinute);

        // Reset stored timestamp if timestamp in state is empty, this may happen when logging out
        if (!lastTimestamp) {
            sessionStorage.removeItem("timestamp");
        }

        return () => clearInterval(interval);
    }, [dispatch, lastTimestamp, loginSuccessful]);

    useEffect(() => {
        dispatch(bootstrapClient());
        const timestamp = sessionStorage.getItem("timestamp");
        const date = timestamp ? moment(timestamp).toISOString() : moment().toISOString();
        dispatch(createTimeStamp(date));
        sessionStorage.setItem("timestamp", date);
    }, [dispatch]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSendMessage = useCallback(
        debounce(data => {
            sendMessage(data);
        }, 100),
        []
    );

    useEffect(() => {
        if (!readySignalSent) {
            sendSignal(signal(signals.SIGNAL_READY));
            setReadySignalSent(true);
        }
    }, [readySignalSent]);

    // Only the track the last page view during the last n ms
    const debouncedPageView = useDebounce(path => {
        sendSignal(createPageViewSignal(path));
    }, 1000);

    // Listen for changes to the path/route, skip the querystring.
    // This is used for page view tracking and we don't want to send the full location everytime the querystring changes.
    useEffect(() => {
        debouncedPageView(location.pathname);
    }, [debouncedPageView, location.pathname]);

    // Listen for changes to the path/route and querystring
    useEffect(() => {
        // Debug which route we navigate to
        // console.log("Navigated to another page", pathname, location);

        if (isIframe()) {
            // Send all history changes to parent to change URL
            const path = `${pathname}${pathsearch ? pathsearch : ""}`;
            const data = {
                event: "at",
                path,
                scrollToTop: pathstate?.scrollToTop !== false,
            };
            debouncedSendMessage(data);
        }

        // Update timestamp when user navigates
        const date = moment().toISOString();
        dispatch(createTimeStamp(date));
        sessionStorage.setItem("timestamp", date);
    }, [dispatch, debouncedSendMessage, pathname, pathsearch, pathstate]); // we cannot listen on changes on the location object

    useEffect(() => {
        // Note: If you want to send data to log rocket during development, comment out check for "localhost" and "demo"
        const isSiteExcluded =
            window.location.hostname === "localhost" ||
            window.location.hostname.indexOf(".r360online.test") >= 0 ||
            window.location.hostname.indexOf(".r360dev.se") >= 0 ||
            window.location.hostname.startsWith("demo.");

        if (loginSuccessful && !isSiteExcluded) {
            LogRocket.init("mlokp2/dev", {
                network: {
                    requestSanitizer: request => {
                        // Remove sensitive authentication details
                        if ("Authorization" in request.headers) {
                            request.headers["Authorization"] = "<REMOVED>";
                        }

                        // if the url contains 'auth'
                        if (request.url.toLowerCase().indexOf("auth") !== -1) {
                            // scrub out the body
                            request.body = null;
                        }

                        return request;
                    },
                },
            });

            // Identify authenticated user in dashboard
            LogRocket.identify(account?.user?.email, {
                name: account?.user?.name1,
                email: account?.user?.email,
                addrlid: account?.user?.addrlid,
            });
        }
        // eslint-disable-next-line
    }, [loginSuccessful]);

    useEffect(() => {
        // console.log("Updated checkoutCart", checkoutCart);
        _state(checkoutCart);
    }, [checkoutCart]);

    useEffect(() => {
        window.addEventListener("message", messageReceived);
        return () => window.removeEventListener("message", messageReceived);
    });

    /**
     * The cart is not allowed to contain products generated from search results that are older than 24 hours.
     * If older, clear cart and show a message to the user.
     * Check every 10 seconds.
     */
    useEffect(() => {
        cartExpiredCheckInterval && clearInterval(cartExpiredCheckInterval);

        if (checkoutCart?.products?.length) {
            const cartExpiredCheckAndNavigate = () => {
                const cartIsExpired = moment()
                    .subtract(1, "days")
                    .isAfter(
                        moment.min(
                            checkoutCart.products
                                .filter(product => !product.detlid) // Exclude already booked products.
                                .map(product => moment(product.created))
                        )
                    );
                if (cartIsExpired) {
                    dispatch(emptyCartProducts());
                    setShowCartExpiredModal(true);
                }
            };
            const intervalTimeInMs = 10000;
            setCartExpiredCheckInterval(setInterval(cartExpiredCheckAndNavigate, intervalTimeInMs));
        }
        return () => cartExpiredCheckInterval && clearInterval(cartExpiredCheckInterval);
        // eslint-disable-next-line
    }, [checkoutCart, location, navigate]);

    const onExpiredCartOkClick = () => {
        const createdFromReservation = checkoutCart.createdFromReservation;
        const navigateToStart = ["/checkout", "/checkout/summary", "fastcheckout", "/reservation/extras"].includes(
            location?.pathname
        );
        navigateToStart && navigate("/", { replace: true });
        createdFromReservation ? navigate("/account/bookings", { replace: true }) : window.location.reload();
        setShowCartExpiredModal(false);
    };

    const messageReceived = event => {
        if (!isIframe()) {
            return;
        }

        // We only care about JSON formatted strings that we want to parse.
        // E.g react-dev-tools will send alot of events with data set to an object
        if (event && typeof event.data === "string" && !event.data.includes("webpackHotUpdate")) {
            try {
                const message = JSON.parse(event.data) || {};

                // Expect to receive an event and a value in the message.
                if (!message.event) {
                    return;
                }

                if (message.event === "cookie" && message.value) {
                    dispatch(setCookie(message.value));
                } else if (message.event === "iframeOffsetTop") {
                    dispatch(setIframeOffsetTop(message.value));
                } else if (message.event === "navigate") {
                    // Allow iframe to navigate the app from outside
                    if (message.path.length > 0) {
                        navigate(message.path);
                    }
                }
            } catch (e) {
                console.error("JSON parse error: Could not parse JSON in App.js:messageReceived() from event", event);
            }
        }
    };

    // eslint-disable-next-line
    const reportSizeToIframeParent = useCallback(
        debounce(overrides => {
            const fetchedViewPortDimensions = viewPortDimensions();
            const height = overrides?.height || fetchedViewPortDimensions.height;
            const width = overrides?.width || fetchedViewPortDimensions.width;

            const data = { event: "resize", height, width };
            const parsedQs = queryString.parse(location.search);
            if (isIframe() && parseInt(parsedQs?.widget) !== 1 && parseInt(parsedQs?.modal) !== 1) {
                if (sendMessage) sendMessage(data);
            }
        }, 250),
        [location.search]
    );

    /**
     * Set observers for the ResizeObserver.
     * The same target can be observed multiple times, but the event will only trigger once.
     */
    const setResizeObserverObservers = () => {
        resizeObserverRef.current.observe(document.querySelector("body"));

        const modalDialog = document.querySelector("div.modal-dialog");
        modalDialog && resizeObserverRef.current.observe(modalDialog);
    };

    useLayoutEffect(() => {
        if (isIframe()) {
            // Make sure ResizeObserver exists as it may not be available for older browsers.
            if ("ResizeObserver" in window) {
                setResizeObserverObservers();

                // Disable the vertical scroll of body when using automatic sizing of the iframe.
                //
                // Notes: We cannot hide overflowed content because this will clip popups
                // window.document.body.style.overflowY = "hidden";

                // eslint-disable-next-line
                return () => resizeObserverRef.current.disconnect();
            } else {
                // Default to 1500px height with scrollbars.
                window.document.body.style.overflowY = "auto";
                sendMessage?.({ event: "resize", height: 1500 });
            }
        }
    }, []);

    /**
     * Listen for when modal is open and update ResizeObserver to also detect the height of the dialog.
     */
    useEffect(() => {
        if (isIframe()) {
            const mutationObserver = new MutationObserver(mutations => {
                if (mutations.length === 1) {
                    const mutation = mutations[0];
                    const classList = mutation.target.classList;
                    if ([...classList].includes("modal-open")) {
                        setResizeObserverObservers();
                    }
                }
            });

            mutationObserver.observe(document.getElementsByTagName("body")[0], {
                attributes: true,
                attributeFilter: ["class"],
            });

            return () => mutationObserver.disconnect();
        }
    }, []);

    /**
     * Login agent user if authenticated as agent in backend.
     */
    useEffect(() => {
        if (agent && !account.user?.isAgent) {
            dispatch(authenticateAgent());
        }
    }, [dispatch, agent, account.user]);

    /**
     * Login owner user if owner token is provided in url.
     */
    useEffect(() => {
        if (ownerToken) {
            dispatch(authenticateOwner(ownerToken));
            setOwnerToken("");
        }
    }, [dispatch, ownerToken]);

    // Should actions in OrderOverviewCart bes disabled?
    const disableCartActions = () => {
        const disablePages = ["/checkout", "/checkout/summary", "fastcheckout", "/confirm"];
        if (location && disablePages.includes(pathname)) {
            return true;
        }
        return false;
    };

    // Should login and registration links in menu be hidden?
    const hideActionLinks = () => {
        const hidePages = ["/user/login", "/user/registration"];
        if (location && hidePages.includes(pathname)) {
            return true;
        }
        return false;
    };

    const hasPermissionTo = () => {
        return loginSuccessful;
    };

    const changeLanguage = async languageCode => {
        if (checkoutCart?.products?.length > 0) {
            const response = await confirmationDialog({
                title: texts["language.change_confirmation.title"],
                description: texts["language.change_confirmation.description"],
                cancelLabel: texts["general.confirmation.cancel"],
                okLabel: texts["general.confirmation.continue"],
            });

            if (!response) {
                return;
            }

            dispatch(emptyCartProducts());
        }

        dispatch(clearAllReservationTypeResults());
        dispatch(resetLastSearchedTimestamps());
        dispatch(resetFilterState());

        // Triger sync of Redux state to browser storage explicitly to avoid racing condition with throttle-time.
        syncStateToBrowserStorage(store)();

        setQueryLang(languageCode);
        window.location.reload();
    };

    const getProtectedRoute = (name, path, Component, requireAuth) => {
        if (requireAuth && !hasPermissionTo()) {
            // Remember current path to redirect user to the same page after a successful login
            return <Route key={name} path={path} element={<Navigate replace to={`/user/login?path=${pathname}`} />} />;
        }

        return <Route key={name} path={path} element={<Component />} />;
    };

    const renderRoutes = routes => {
        const routeArray = routes
            // Check which routes we want to allow by default or only allow my page routes depending on option.
            .filter(route => !myPageOnly || myPageRouteNames.includes(route.name))
            .map(route => {
                const { path, component, name, requireAuth } = route;

                return getProtectedRoute(name, path, component, requireAuth);
            });
        return routeArray;
    };

    const AlertView = error => {
        return (
            <div className="page-container">
                <Notification type="error">
                    <h4 className="h4">{t("book.errors.network.title")}</h4>
                    <p>{t("book.errors.network.description")}</p>
                    <div className="text-muted">
                        Code: {error.statusText} {" - "} {error.status}
                    </div>
                </Notification>
            </div>
        );
    };

    const appClassName = classNames("App", newDesignMenu && "App-new-design");

    const widgetView = () => {
        const parsedQs = queryString.parse(location.search);
        const reservationPageHeading = texts?.["reservationheader"] !== "" ? texts?.["reservationheader"] : null;

        if ((isIframe() && parseInt(parsedQs?.widget)) || parseInt(parsedQs?.modal)) {
            return (
                <Routes>
                    <Route path="/OrderOverview">
                        <OrderOverviewPage />
                    </Route>
                </Routes>
            );
        } else {
            return (
                <div className={appClassName}>
                    {isTestSite && <TestEnvironmentBar />}
                    {agent?.sites?.length && account?.user?.isAgent && <AgentBar currentSite={site} agent={agent} />}
                    {account?.user?.isOwner && <OwnerBar owner={account.user} />}
                    {checkoutCart.createdFromReservation && (
                        <>
                            {newDesignMyPages ? (
                                <ChangeReservationBar reservationId={checkoutCart.createdFromReservation} />
                            ) : (
                                <OldChangeReservationBar reservationId={checkoutCart.createdFromReservation} />
                            )}
                        </>
                    )}
                    {newDesignMenu && !pathname.includes("/account") ? (
                        <MainHeader />
                    ) : (
                        <div className="container App__head">
                            <Row>
                                <Col md="6">
                                    <div className="dreamSearchHeaderText">
                                        {pathname.match(/search\/\d*/) && (
                                            <div>
                                                <RichText content={reservationPageHeading} />
                                            </div>
                                        )}
                                    </div>
                                </Col>
                                <Col md="6">
                                    <Menu hideActionLinks={hideActionLinks()} />
                                    <OrderOverviewCart disableCartActions={disableCartActions()} />
                                    {languages.length >= 2 && (
                                        <div className="App__head-languages">
                                            {languages.map(language => (
                                                <a
                                                    onClick={() => changeLanguage(language.code)}
                                                    key={language.code}
                                                    className={classnames({ active: language.code === lang })}
                                                >
                                                    {language.code}
                                                </a>
                                            ))}
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        </div>
                    )}
                    {error && AlertView(error)}
                    <LoadingOverlay />
                    {showWrapperModal && !newDesignLoginSignup ? (
                        <ModalOld show={showWrapperModal}>
                            <SignUpWrapper></SignUpWrapper>
                        </ModalOld>
                    ) : newDesignLoginSignup ? (
                        <LoginRegistrationModal />
                    ) : null}
                    {isBookingError && (
                        <ModalOld show={isBookingError}>
                            <h2>{texts.accommodationsdontoverlapheader || "Boenden överlappar inte"}</h2>
                            <p>
                                {texts?.accommodationsdontoverlap ||
                                    "Dina boenden i varukorgen överlappar inte i tid. Vänligen ändra datum!"}
                            </p>
                        </ModalOld>
                    )}
                    {showCartExpiredModal && (
                        <Modal
                            open={showCartExpiredModal}
                            closeBtn={false}
                            {...(isIframe && isDesktop && { fromTop: 120 + iframeOffsetTop + "px" })}
                            {...(isIframe &&
                                (isMobile || isTablet) && {
                                    fromTop: 80 + iframeOffsetTop + "px",
                                })}
                        >
                            <Heading type={"h2"}>
                                {checkoutCart.createdFromReservation
                                    ? t("book.cart_from_reservation_expired_alert.title")
                                    : t("book.cart_expired_alert.title")}
                            </Heading>
                            <p>
                                {checkoutCart.createdFromReservation
                                    ? t("book.cart_from_reservation_expired_alert.description")
                                    : t("book.cart_expired_alert.description")}
                            </p>
                            <div className="u-d-flex justify-content-end">
                                <Button type="primary" onClick={onExpiredCartOkClick}>
                                    Ok
                                </Button>
                            </div>
                        </Modal>
                    )}
                    {texts && criterias && (
                        <Routes>
                            {newDesignMyPages && (
                                <Route path="/account" element={<Navigate replace to="/my-pages" />} />
                            )}
                            {renderRoutes(
                                getRoutes(
                                    newDesignAccommodation,
                                    newDesignLoginSignup,
                                    newDesignCheckout,
                                    newDesignMyPages
                                )
                            )}
                            {/* Default route */}
                            {myPageOnly ? (
                                <Route path="*" element={<Navigate replace to="/user/login" />} />
                            ) : (
                                <Route path="*" element={<Navigate replace to="/search" />} />
                            )}
                        </Routes>
                    )}

                    {newDesignMenu ? (
                        <Footer />
                    ) : (
                        <div className="container mt-5 pt-5">
                            <p className="text-muted text-center" style={{ fontSize: "0.825rem" }}>
                                r360 Online powered by r360 - &copy;{" "}
                                <a
                                    href="https://www.r360.se/"
                                    className="text-muted"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    r360 Resort Systems AB
                                </a>
                            </p>
                        </div>
                    )}
                    <ProductsNotAddedToCartModal readyToShow={productsNotAddedPath} />
                </div>
            );
        }
    };

    if (pathname.includes("/mytickets")) {
        return <Routes>{renderRoutes(getStandaloneRoutes())}</Routes>;
    } else {
        return widgetView();
    }
};

export default connect()(App);
