import classnames from "classnames";
import React, { useEffect, useRef } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PersonalInformation from "../../components/PersonalInformation/OldPersonalInformation/PersonalInformation";
import { Alert, Icon, InformationBlock } from "../../components/UI";
import { checkAuthenticationStatus, publishUpdatedUserData } from "../../store/actions/account";
import { textsSelector } from "../../Selectors";

const MyAccount = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const texts = useSelector(textsSelector);
    const userToken = useSelector(state => state.account.token);
    const cartIsEmpty = useSelector(state => !state.checkoutCart?.products.length);
    const personalInformationRef = useRef(null);
    const customerOptions = useSelector(state => state.clientData.options.customer);
    const errors = useSelector(state => state.account.error?.errors);
    const hasDuplicateUserError =
        errors && Object.keys(errors).length ? errors.some(error => error.data === "DUPLICATE") : false;

    let user = useSelector(state => state.account.user);
    const isAgent = !!user?.isAgent;

    if (hasDuplicateUserError) {
        // Reset the email address
        user = { ...user, email: "" };
    }

    useEffect(() => {
        if (hasDuplicateUserError) {
            // Reset the email address
            personalInformationRef.current.detailsFormRef.setFieldValue("email", "", true);
        }
    }, [hasDuplicateUserError, personalInformationRef]);

    // Check if authenticated
    useEffect(() => {
        dispatch(checkAuthenticationStatus(userToken));
    }, [dispatch, userToken]);

    const handleSubmit = userValues => {
        dispatch(publishUpdatedUserData(userValues, userToken));
    };

    const AlertView = () => {
        if (!errors || Object.keys(errors).length === 0) {
            return null;
        }

        return (
            <Alert type="warning">
                <ul>
                    {errors.map(error => {
                        if (error.data === "DUPLICATE") {
                            return <li key={error.data}>{texts?.emailaddressisalreadyinuse}</li>;
                        }

                        return <li key={error.data}>{error.message}</li>;
                    })}
                </ul>
            </Alert>
        );
    };

    return (
        <Container>
            <h1 className="page-header">{texts?.personalinformation}</h1>

            {user && (
                <>
                    <InformationBlock className="mb-5">
                        {cartIsEmpty ? texts["mypages.changeexistingbooking"] : texts["mypages.ongoingbookinguser"]}
                    </InformationBlock>

                    <AlertView />

                    <Row className={classnames({ "soft-disabled": !cartIsEmpty || isAgent })}>
                        <Col md={8}>
                            <PersonalInformation
                                ref={personalInformationRef}
                                isPrimaryGuest
                                guestId={user.addrlid}
                                guest={user}
                                onSubmit={handleSubmit}
                                texts={texts}
                                showAgePicker={!isAgent}
                                showKeycardNo={customerOptions?.show_keycard_number || false}
                                showKeycardImage={customerOptions?.show_keycard_image || false}
                                showWeight={customerOptions?.show_weight || false}
                                showHeight={customerOptions?.show_height || false}
                                showShoesize={customerOptions?.show_shoesize || false}
                                showHeadsize={customerOptions?.show_headsize || false}
                            />
                        </Col>
                    </Row>

                    <div className="mb-5 mt-3">
                        <Button
                            onClick={() => {
                                navigate(-1);
                            }}
                            className="button button--invert me-2"
                            type="button"
                        >
                            <Icon
                                name="FaArrowLeft"
                                size={16}
                                color="#0d3e60"
                                className="booking-view__back-button-icon"
                            />
                            {texts?.goback}
                        </Button>
                        <Button
                            className="button"
                            onClick={personalInformationRef.current?.submitForm}
                            disabled={!cartIsEmpty || isAgent}
                        >
                            {texts?.save}
                        </Button>
                    </div>
                </>
            )}
        </Container>
    );
};

export default MyAccount;
