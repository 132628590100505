import React, { useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Accommodation as AccomodationImage } from "../../../assets/reservationTypes";
import ProductGallery from "../../ProductGallery/ProductGallery";
import ProductView from "../../ProductView/ProductView";
import { Button, Modal, Tag } from "../../UI";
import { formatPrice, preventClick } from "../../../Helper";
import "./AccommodationResultItemOld.scss";
import { textsSelector } from "../../../Selectors";

// Accommodation result item component
const AccommodationResultItemOld = props => {
    const location = useLocation();
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const { title, price, thumbnail, itemCount, type, data, index, showPrice } = props;
    const currency = useSelector(state => state.clientData?.options?.general?.currency);
    const allFilters = useSelector(state => state.availableFilters.default);
    const texts = useSelector(textsSelector);
    let commonCrits = data.commonCrits ? data.commonCrits : {};
    const sortedFilters = Object.values(commonCrits)
        .filter(item => data.presentationcriterias?.[item.code])
        .sort((a, b) => b.active - a.active);
    const visibleFilterItems = sortedFilters
        .filter(item => item.value !== false)
        .filter(item => data.crits[item.code])
        .filter((item, index) => index < 5);
    const moreFilterItems = sortedFilters
        .filter(item => item.value !== false)
        .filter(item => data.crits[item.code])
        .filter((item, index) => index >= 5);

    const getFilterLabel = item => {
        let filterLabel = item.label;

        if (allFilters?.[item.code]) {
            filterLabel = filterLabel.replace("#T#", allFilters[item.code].title);
            if (
                allFilters[item.code].desc3 &&
                item.type === "number" &&
                allFilters[item.code].desc3.indexOf("!") === -1
            )
                filterLabel = filterLabel.replace("#S#", allFilters[item.code].desc3);
            else filterLabel = filterLabel.replace("#S#", "");
        } else {
            return null;
        }
        return filterLabel;
    };

    // Visible filters
    const visibleFilters = () => {
        return visibleFilterItems.map((item, index) => {
            const filterLabel = getFilterLabel(item);
            if (!filterLabel) {
                return null;
            }
            return (
                <div
                    className={`col-auto accommodation-list-item__tag ${
                        item.active ? "accommodation-list-item__tag--active" : ""
                    }`}
                    key={index}
                >
                    <Tag>{`${filterLabel}`}</Tag>
                </div>
            );
        });
    };

    // More filters, all except the first 5
    const moreFilters = () => {
        return (
            <OverlayTrigger
                trigger="click"
                key={`popover-${title}`}
                placement="bottom"
                overlay={
                    <Popover id={`popover-${title}`}>
                        <Popover.Body>
                            <ul className="accommodation-list-item__more-filters-list">
                                {moreFilterItems.map((item, index) => {
                                    const filterLabel = getFilterLabel(item);
                                    if (!filterLabel) {
                                        return null;
                                    }
                                    return <li key={index}>{filterLabel}</li>;
                                })}
                            </ul>
                        </Popover.Body>
                    </Popover>
                }
            >
                <Button
                    text="+"
                    className="accommodation-list-item__more-filters-button"
                    ariaLabel={`${texts?.showadditionalpropertiesfor} ${title}`}
                    hasPopup={true}
                    onClick={preventClick}
                />
            </OverlayTrigger>
        );
    };

    // Handle click on more info button
    const handleModalButtonClick = event => {
        preventClick(event);
        setShowModal(!showModal);
    };

    const navigateToGroupPool = () => {
        navigate(`/product/${type}/${props.data.grouppoollid}${location.search}`);
    };

    return (
        <div className="col-sm-12 col-md-6 col-lg-4 mb-5 accommodation-list-item accommodation-list-item__child">
            <div className="card border-0 h-100" style={{ background: "transparent" }}>
                <div className="accommodation-list-item__image-container">
                    <ProductGallery
                        images={(props.data?.groupitem?.images || []).map(image => ({
                            original: image.url,
                            thumbnail: image.url,
                        }))}
                        forceImageGallery={true}
                        onImageGalleryClick={navigateToGroupPool}
                    />
                    {props.promoCode && (
                        <div className="accommodation-list-item__promo-tag-container">
                            <Tag type="small" className="accommodation-list-item__promo-tag">
                                {texts?.promocodeincluded}
                            </Tag>
                        </div>
                    )}
                </div>
                <div className="card-body p-0" onClick={navigateToGroupPool}>
                    <div className="accommodation-list-item__tag">
                        <Tag type="small">{`${itemCount} ${texts?.accommodationfound}`}</Tag>
                    </div>
                    <div className="card-title accommodation-list-item__title">{title}</div>
                    <div className="row p-0 g-2">
                        {visibleFilters()}
                        {moreFilterItems.length > 0 && (
                            <div className="col-auto accommodation-list-item__more-filters">{moreFilters()}</div>
                        )}
                    </div>
                    <div className="accommodation-list-item__modal-button-container">
                        <Button
                            id={`accommodation-more-information-${index}`}
                            text={texts?.moreinformation}
                            className="skipass-item__modal-button"
                            onClick={handleModalButtonClick}
                            hasPopup={true}
                            expanded={showModal}
                            disabled={data.groupitem?.weblong ? false : true}
                        />
                    </div>
                </div>
                {showPrice && <div className="accommodation-list-item__price">fr. {formatPrice(price, currency)}</div>}
            </div>
            <Modal
                show={showModal}
                setShow={setShowModal}
                labelledBy={`accommodation-list-item-${props.index}`}
                className="skipass-item__modal"
                id={`accommodation-more-information-modal-${props.index}`}
            >
                <ProductView
                    weblong={data.groupitem?.weblong}
                    title={title}
                    image={thumbnail || AccomodationImage}
                    texts={texts}
                />
            </Modal>
        </div>
    );
};

export default AccommodationResultItemOld;
