import * as React from "react";
import defautIconStats from "../Icon.types";
import Theme from "../../Theme";

const Signs = ({
    color = Theme.palette.primary,
    size = defautIconStats.size,
    iconClass = defautIconStats.iconClass,
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            style={{ height: size, width: size }}
            width="512pt"
            height="512pt"
            className={iconClass}
        >
            <path
                fill={color}
                d="m256 0c-141.164062 0-256 114.835938-256 256s114.835938 256 256 256 256-114.835938 256-256-114.835938-256-256-256zm0 0"
            />
            <path
                d="m277.332031 384c0 11.78125-9.550781 21.332031-21.332031 21.332031s-21.332031-9.550781-21.332031-21.332031 9.550781-21.332031 21.332031-21.332031 21.332031 9.550781 21.332031 21.332031zm0 0"
                fill="#eceff1"
            />
            <path
                d="m277.332031 298.667969c0 11.773437-9.554687 21.332031-21.332031 21.332031s-21.332031-9.558594-21.332031-21.332031v-170.667969c0-11.777344 9.554687-21.332031 21.332031-21.332031s21.332031 9.554687 21.332031 21.332031zm0 0"
                fill="#fafafa"
            />
        </svg>
    );
};

export default Signs;
