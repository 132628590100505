import moment from "moment-timezone";
import React, { useState } from "react";
import { Col, FormControl, Row, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Icon, Tag } from "../../components/UI";
import { formatPrice, getDateFormat } from "../../Helper";
import "./BookingsListView.scss";
import { textsSelector } from "../../Selectors";

// Bookings list view component
const BookingsListView = props => {
    const texts = useSelector(textsSelector);
    const navigate = useNavigate();
    const currency = useSelector(state => state.clientData?.options?.general?.currency);
    const showReservationAllowed = useSelector(state => state.clientData.options.mypage.showreservation);
    const isLoading = useSelector(state => state.axiosStatus.loading);
    const isMobile = useSelector(state => state.window.isMobile);
    const countryOfBusiness = useSelector(state => state.clientData.countryOfBusiness);
    const [search, setSearch] = useState("");

    // Status message for the booking
    const StatusMessage = ({ status, cancelled }) => {
        if (cancelled) {
            return (
                <Tag type="medium" className="bookings-list-view__row-status bookings-list-view__row-status--cancelled">
                    {texts?.cancelled || "Cancelled"}
                </Tag>
            );
        }

        if (status.totamounttopay <= 0) {
            return (
                <Tag type="medium" className="bookings-list-view__row-status bookings-list-view__row-status--success">
                    {texts?.paid}
                </Tag>
            );
        }
        return (
            <Tag type="medium" className="bookings-list-view__row-status bookings-list-view__row-status--info">
                {texts?.notpaid}
            </Tag>
        );
    };

    // Get reservation date
    const getReservationDate = reservation => {
        return moment(reservation.arrdate).format(getDateFormat(countryOfBusiness));
    };

    /**
     * Search reservartions by booking number. Only filters already fetched reservations.
     */
    const searchReservations = reservations =>
        search === ""
            ? reservations
            : reservations.filter(reservation => reservation.resvid.toUpperCase().includes(search.toUpperCase()));

    // Return
    return (
        <div className="bookings-list-view">
            <div className="bookings-list-view__additional-products-wrapper">
                <h2 className="bookings-list-view__additional-products-title">
                    {texts?.changeanythingtoyourbookingheader}
                </h2>
                <p>{texts?.changeanythingtoyourbooking}</p>
            </div>
            {props.reservations?.length > 0 && (
                <>
                    {props.reservations?.length > 10 && (
                        <Row className="mb-2">
                            <Col lg="3" md="6" xs="12">
                                <FormControl
                                    onChange={event => setSearch(event.target.value)}
                                    placeholder={texts["bookingview.search_reservation"]}
                                    value={search}
                                />
                            </Col>
                        </Row>
                    )}

                    <Table borderless className="bookings-list-view__list">
                        <caption className="sr-only">{texts?.listofbookings}</caption>
                        <thead>
                            <tr>
                                <th
                                    className="bookings-list-view__list-header bookings-list-view__list-header--hidden-mobile"
                                    scope="col"
                                >
                                    {texts?.date}
                                </th>
                                <th className="bookings-list-view__list-header" scope="col">
                                    {texts?.bookingnumber}
                                </th>
                                <th
                                    className="bookings-list-view__list-header bookings-list-view__list-header--hidden-mobile"
                                    scope="col"
                                >
                                    {texts?.totalprice}
                                </th>
                                <th
                                    className="bookings-list-view__list-header bookings-list-view__list-header--hidden-mobile"
                                    scope="col"
                                >
                                    {texts?.status}
                                </th>
                                <th className="sr-only" scope="col">
                                    {texts?.choosebooking}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {searchReservations(props.reservations)
                                .sort((a, b) => new Date(b.arrdate) - new Date(a.arrdate))
                                .map(reservation => {
                                    const reservationDate = getReservationDate(reservation);

                                    return (
                                        <tr className="bookings-list-view__list-row" key={reservation.resvid}>
                                            <td className="bookings-list-view__list-item bookings-list-view__list-item--hidden-mobile">
                                                {reservationDate}
                                            </td>
                                            <td className="bookings-list-view__list-item bookings-list-view__list-item-header">
                                                {reservation.resvid}
                                            </td>
                                            <td className="bookings-list-view__list-item bookings-list-view__list-item--hidden-mobile">
                                                {formatPrice(reservation.reservationrules.totamountinclvat, currency)}
                                            </td>
                                            <td className="bookings-list-view__list-item bookings-list-view__list-item--hidden-mobile">
                                                <StatusMessage
                                                    status={reservation.reservationrules}
                                                    cancelled={reservation.cancelled}
                                                />
                                            </td>
                                            <td className="bookings-list-view__list-item-buttons">
                                                <div>
                                                    {!isMobile && (
                                                        <>
                                                            {showReservationAllowed &&
                                                            reservation.cancelled === false ? (
                                                                <Button
                                                                    to={`/account/bookings/${reservation.resvid}`}
                                                                    text={texts?.choose}
                                                                    ariaLabel={`${texts?.gotobooking} ${reservation.resvid}`}
                                                                    className="bookings-list-view__row-cta-button"
                                                                />
                                                            ) : null}
                                                        </>
                                                    )}
                                                    {isMobile && (
                                                        <>
                                                            <div>
                                                                <StatusMessage
                                                                    status={reservation.reservationrules}
                                                                    cancelled={reservation.cancelled}
                                                                />
                                                            </div>
                                                            {showReservationAllowed &&
                                                            reservation.cancelled === false ? (
                                                                <div>
                                                                    <Button
                                                                        to={`/account/bookings/${reservation.resvid}`}
                                                                        text={texts?.choose}
                                                                        ariaLabel={`${texts?.gotobooking} ${reservation.resvid}`}
                                                                        className="bookings-list-view__row-cta-button"
                                                                    />
                                                                </div>
                                                            ) : null}
                                                        </>
                                                    )}
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </Table>
                </>
            )}
            {!props.reservations?.length && !isLoading && (
                <div className="bookings-list-view__empty-message">
                    [ {texts?.nobookings || texts?.nobookingstimetobooksomething} ]
                </div>
            )}
            <div className="bookings-list-view__button-container">
                <Button
                    onClick={() => {
                        navigate("/account");
                    }}
                    className="button--invert me-2"
                    type="button"
                >
                    <Icon name="FaArrowLeft" size={16} color="#0d3e60" className="me-2" />
                    {texts?.goback}
                </Button>
            </div>
        </div>
    );
};

export default BookingsListView;
