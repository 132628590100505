import * as React from "react";
import defautIconStats from "../Icon.types";

const Group = ({ size = defautIconStats.size, iconClass = defautIconStats.iconClass }) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            style={{ height: size, width: size }}
            width="512pt"
            height="512pt"
            className={iconClass}
        >
            <path
                d="M437.76,195.421c14.561-14.261,23.04-33.742,23.04-54.621c0-42.346-34.45-76.8-76.8-76.8
			c-13.992,0-27.758,3.846-39.825,11.125c-4.033,2.438-5.333,7.679-2.9,11.717c2.433,4.038,7.683,5.325,11.717,2.9
			c9.408-5.675,20.125-8.675,31.008-8.675c32.933,0,59.733,26.796,59.733,59.733c0,20.283-10.158,38.979-27.208,50.037
			c-14.317,9.392-32.467,12.1-49.042,7.379c-4.483-1.292-9.25,1.325-10.55,5.858c-1.292,4.533,1.333,9.254,5.867,10.55
			c6.9,1.975,14.033,2.975,21.2,2.975c13.418,0,26.388-3.66,37.903-10.27c43.806,15.873,73.03,57.232,73.03,104.137v42.667h-77.812
			c-6.044-56.996-41.902-106.872-94.205-130.555c16.644-16.946,26.95-40.138,26.95-65.711C349.867,106.108,307.758,64,256,64
			c-51.758,0-93.867,42.108-93.867,93.867c0,25.581,10.312,48.78,26.965,65.728c-17.602,7.997-33.927,19.185-47.773,32.952
			c-26.543,26.619-42.695,60.784-46.529,97.588H17.067v-42.667c0-29.55,11.542-57.412,32.467-78.425
			c11.519-11.446,25.543-20.178,40.643-25.666C101.694,213.96,114.625,217.6,128,217.6c7.167,0,14.3-1,21.2-2.975
			c4.533-1.296,7.158-6.017,5.867-10.55c-1.3-4.533-6.067-7.146-10.55-5.858c-16.567,4.729-34.708,2.025-49.083-7.4
			c-17.008-11.038-27.167-29.733-27.167-50.017c0-32.937,26.8-59.733,59.733-59.733c10.883,0,21.6,3,31.008,8.675
			c4.05,2.425,9.275,1.125,11.717-2.9c2.433-4.037,1.133-9.279-2.9-11.717C155.758,67.846,141.992,64,128,64
			c-42.35,0-76.8,34.454-76.8,76.8c0,20.863,8.465,40.329,22.984,54.577c-13.533,6.291-26.032,14.976-36.717,25.59
			C13.308,245.233,0,277.371,0,311.467v51.2c0,4.713,3.817,8.533,8.533,8.533h85.333v68.267c0,4.713,3.817,8.533,8.533,8.533h307.2
			c4.717,0,8.533-3.821,8.533-8.533V371.2h85.333c4.717,0,8.533-3.821,8.533-8.533v-51.2
			C512,261.012,482.678,216.128,437.76,195.421z M179.2,157.867c0-42.346,34.45-76.8,76.8-76.8c42.35,0,76.8,34.454,76.8,76.8
			s-34.45,76.8-76.8,76.8C213.65,234.667,179.2,200.212,179.2,157.867z M401.067,430.933H110.933V371.2
			c0-38.692,15.083-75.129,42.45-102.579c14.417-14.34,31.895-25.463,50.634-32.659c14.895,9.947,32.767,15.771,51.983,15.771
			c19.248,0,37.151-5.841,52.06-15.818c55.778,21.431,93.007,75.205,93.007,135.285V430.933z"
            />
        </svg>
    );
};

export default Group;
