import * as types from "../actions/types";

const initialState = {
    screenSize: "",
    device: "",
    isMobile: false,
    isTablet: false,
    isDesktop: false,
    timestamp: null,
    signUpWrapper: {
        showWrapper: false,
        loginModal: false,
        registrationModal: false,
        pathname: null,
    },
    isIframe: false,
    iframeOffsetTop: 0,
    isMapOpen: true,
    error: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SIGN_OUT_USER:
            sessionStorage.removeItem("timestamp");

            return {
                ...state,
                signUpWrapper: initialState.signUpWrapper,
            };

        case types.SCREEN_RESIZE:
            return {
                ...state,
                screenSize: action.screenSize,
            };

        case types.SET_DEVICE:
            return {
                ...state,
                device: action.device,
                isMobile: action.isMobile,
                isTablet: action.isTablet,
                isDesktop: action.isDesktop,
                isTabletNewDesign: action.isTabletNewDesign,
                isDesktopNewDesign: action.isDesktopNewDesign,
            };

        case types.SET_SIGN_UP_MODAL:
            return {
                ...state,
                signUpWrapper: {
                    ...state.signUpWrapper,
                    showWrapper: action.showWrapper,
                    loginModal: action.showLoginModal,
                    registrationModal: action.showRegistrationModal,
                    pathname: action.pathname,
                },
            };

        case types.SET_BOOKING_ERROR:
            return {
                ...state,
                isBookingError: action.error,
            };

        case types.CREATE_TIMESTAMP: {
            return {
                ...state,
                timestamp: action.date,
            };
        }

        case types.SET_IS_IFRAME:
            return {
                ...state,
                isIframe: action.isIframe,
            };

        case types.SET_IFRAME_OFFSET_TOP:
            return {
                ...state,
                iframeOffsetTop: parseInt(action.iframeOffsetTop, 10),
            };

        case types.SET_MAP_OPEN:
            return {
                ...state,
                isMapOpen: action.isMapOpen,
            };

        case "SET_ERROR":
            return {
                ...state,
                error: action.error,
            };

        default:
            return {
                ...state,
            };
    }
};

export default reducer;
