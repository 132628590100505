import * as React from "react";
import defautIconStats from "../Icon.types";
import Theme from "../../Theme";

const Bed = ({ color = Theme.palette.primary, size = defautIconStats.size, iconClass = defautIconStats.width }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 510.67 510.67"
            style={{ height: size, width: size }}
            width="512pt"
            height="512pt"
            className={iconClass}
        >
            <g>
                <path
                    fill={color}
                    d="M473.139,310.04v-84.255c0-18.156-14.772-32.928-32.928-32.928H147.275c-4.143,0-7.5,3.357-7.5,7.5   c0,4.143,3.357,7.5,7.5,7.5h292.936c9.886,0,17.928,8.042,17.928,17.928v83.992H424.47v-17.181   c0-18.036-14.673-32.709-32.709-32.709h-78.926c-18.035,0-32.708,14.673-32.708,32.709v17.181h-49.584v-17.181   c0-18.036-14.673-32.709-32.708-32.709h-78.926c-18.036,0-32.709,14.673-32.709,32.709v17.181H52.531v-83.992   c0-9.886,8.042-17.928,17.928-17.928h46.815c4.143,0,7.5-3.357,7.5-7.5c0-4.143-3.357-7.5-7.5-7.5H70.459   c-18.156,0-32.928,14.771-32.928,32.928v84.255C16.453,312.367,0,330.278,0,351.966V464.73c0,4.143,3.358,7.5,7.5,7.5h109.774   c4.143,0,7.5-3.357,7.5-7.5s-3.357-7.5-7.5-7.5H15v-78.191h383.211c4.143,0,7.5-3.357,7.5-7.5s-3.357-7.5-7.5-7.5H15v-12.072   c0-14.992,12.196-27.188,27.188-27.188h426.293c14.992,0,27.189,12.196,27.189,27.188v12.072h-67.459c-4.143,0-7.5,3.357-7.5,7.5   s3.357,7.5,7.5,7.5h67.459v78.191H147.275c-4.143,0-7.5,3.357-7.5,7.5s3.357,7.5,7.5,7.5H503.17c4.142,0,7.5-3.357,7.5-7.5V351.966   C510.67,330.278,494.217,312.367,473.139,310.04z M295.127,292.597c0-9.765,7.943-17.709,17.708-17.709h78.926   c9.765,0,17.709,7.944,17.709,17.709v17.181H295.127V292.597z M101.2,292.597c0-9.765,7.944-17.709,17.709-17.709h78.926   c9.765,0,17.708,7.944,17.708,17.709v17.181H101.2V292.597z"
                />
                <path
                    fill={color}
                    d="M195.415,168.693h40.166c4.143,0,7.5-3.357,7.5-7.5s-3.357-7.5-7.5-7.5h-23.118l28.647-31.255   c4.322-4.717,0.855-12.567-5.529-12.567h-36.392c-4.143,0-7.5,3.357-7.5,7.5c0,4.143,3.357,7.5,7.5,7.5h19.344l-28.647,31.255   C185.564,160.843,189.03,168.693,195.415,168.693z"
                />
                <path
                    fill={color}
                    d="M282.542,124.06h64.725c4.143,0,7.5-3.357,7.5-7.5c0-4.143-3.357-7.5-7.5-7.5H299.59l53.206-58.052   c4.324-4.717,0.855-12.567-5.529-12.567h-60.936c-4.087,0-7.5,3.413-7.5,7.5s3.413,7.5,7.5,7.5h43.888l-53.206,58.052   C272.689,116.209,276.158,124.06,282.542,124.06z"
                />
            </g>
        </svg>
    );
};

export default Bed;
