import React from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { isCancelled } from "../../BusinessUtils";
import ProductListItem from "../ProductListItem/ProductListItem";
import "./CancelledProductList.scss";
import { textsSelector } from "../../Selectors";

const CancelledProductList = ({ products, setRenderedProduct }) => {
    const texts = useSelector(textsSelector);
    const productLists = Object.keys(products).map(productId => {
        return products[productId];
    });
    const cancelledProducts = productLists.filter(isCancelled);
    if (cancelledProducts.length === 0) {
        return null;
    }
    const parentProductIsCancelled = product => {
        if (!product.parent) {
            return false;
        }

        const parentProduct = Object.values(products).find(findProduct => findProduct.id === product.id);

        if (!parentProduct) {
            return false;
        }

        return isCancelled(parentProduct);
    };

    // Don't list children to cancelled parent products here. They will be rendered within the parent.
    const cancelledProductsToList = cancelledProducts.filter(product => !parentProductIsCancelled(product));

    if (cancelledProductsToList.length === 0) {
        return null;
    }

    return (
        <div className="box cancelledproductlist">
            <Row className="mb-4">
                <Col>
                    <h4>{texts["bookingview.cancelled_products"]}</h4>
                </Col>
            </Row>
            {cancelledProductsToList.map(product => {
                return (
                    <ProductListItem
                        key={product.id}
                        products={products}
                        product={product}
                        texts={texts}
                        setRenderedProduct={setRenderedProduct}
                    />
                );
            })}
        </div>
    );
};

export default CancelledProductList;
