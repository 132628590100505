import React from "react";
import { Form } from "react-bootstrap";
import phoneCountryCodes from "./PhoneCountryCodes.json";

const PhoneCountryCodeSelect = props => {
    const {
        required,
        value,
        onChange,
        placeholder,
        othersLabel,
        className,
        disabled,
        id,
        name,
        defaultValue,
        ...rest
    } = props;

    const renderOptions = items =>
        items.map((item, index) => (
            <option key={index} data-countrycode={item.countryCode} value={item.phoneCode}>
                {`${item.countryName} (+${item.phoneCode})`}
            </option>
        ));

    return (
        <Form.Control
            required={required}
            as="select"
            value={value || ""}
            defaultValue={defaultValue}
            onChange={onChange}
            className={className}
            disabled={disabled}
            id={id}
            name={name}
            {...rest}
        >
            <option value="" data-countrycode="" disabled>
                {placeholder}
                {required && "*"}
            </option>

            {renderOptions(phoneCountryCodes.common)}

            <optgroup label={othersLabel}>{renderOptions(phoneCountryCodes.others)}</optgroup>
        </Form.Control>
    );
};

export default PhoneCountryCodeSelect;
