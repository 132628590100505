import React from "react";
import { MultipleVariantsGuestsDropdownButton } from "./MultipleVariantsGuestsDropdownButton";
import { MultipleVariantsQuantityDropdownButton } from "./MultipleVariantsQuantityDropdownButton";
import { TItem } from "../../store/types";
import { TAddToCartData, TGuests } from "./MultipleVariantsCard";
import { sum } from "lodash";
import { hasAnyGuestCategoryPrice, hasDynamicPrices, hasSamePriceInAllGuestCategories } from "../../BusinessUtils";

type TMultipleVariantsDropdownButton = {
    item: TItem;
    addToCartData: { [key: number]: TAddToCartData };
    onChange: (item: TItem, quantity: number, guests?: TGuests) => void;
    handleAddToCart: (item: TItem, quantity: number, guests?: TGuests) => void;
    addToCartSuccess: number | null;
    itemInCartQuantity?: number;
};

export const MultipleVariantsDropdownButton = ({
    item,
    addToCartData,
    onChange,
    handleAddToCart,
    addToCartSuccess,
    itemInCartQuantity = 0,
}: TMultipleVariantsDropdownButton) => {
    const showGuestsDropdown =
        !hasDynamicPrices(item) && hasAnyGuestCategoryPrice(item) && !hasSamePriceInAllGuestCategories(item);

    const handleGuestsChange = (item: TItem, guests: TGuests) => {
        const quantity = sum(Object.values(guests));
        onChange(item, quantity, guests);
    };

    const handleQuantityChange = (item: TItem, quantity: number) => {
        onChange(item, quantity);
    };

    return showGuestsDropdown ? (
        <MultipleVariantsGuestsDropdownButton
            item={item}
            addToCartData={addToCartData}
            onChange={handleGuestsChange}
            handleAddToCart={handleAddToCart}
            addToCartSuccess={addToCartSuccess}
            itemInCartQuantity={itemInCartQuantity}
        />
    ) : (
        <MultipleVariantsQuantityDropdownButton
            item={item}
            addToCartData={addToCartData}
            onChange={handleQuantityChange}
            handleAddToCart={handleAddToCart}
            addToCartSuccess={addToCartSuccess}
            itemInCartQuantity={itemInCartQuantity}
        />
    );
};
