// CLIENTDATA
export const SET_CLIENTDATA = "SET_CLIENTDATA";
export const FETCH_CLIENTDATA_FAILED = "FETCH_CLIENTDATA_FAILED";
export const SET_LANG = "SET_LANG";
export const SET_LANGUAGES = "SET_LANGUAGES";
export const SET_COOKIE = "SET_COOKIE";
export const SIGN_OUT_AGENT = "SIGN_OUT_AGENT";

// calendarData
export const FETCH_CALENDAR_DATA = "FETCH_CALENDAR_DATA";
export const SET_CALENDAR_DATA = "SET_CALENDAR_DATA";
export const FETCH_CALENDAR_DATA_FAILED = "FETCH_CALENDAR_DATA_FAILED";

// RESERVATIONTYPERESULTSET
export const SET_RESERVATIONTYPERESULT = "SET_RESERVATIONTYPERESULT";
export const FETCH_RESERVATIONTYPERESULT_FAILED = "FETCH_RESERVATIONTYPERESULT_FAILED";
export const CLEAR_RESERVATIONRESULTDATA = "CLEAR_RESERVATIONRESULTDATA";
export const CLEAR_ALL_RESERVATIONRESULTDATA = "CLEAR_ALL_RESERVATIONRESULTDATA";
export const REMOVE_RESERVATIONRESULT = "REMOVE_RESERVATIONRESULT";
export const SET_RESULTSETID = "SET_RESULTSETID";
export const SET_SLOW_REQUEST_PARAM = "SET_SLOW_REQUEST_PARAM";
export const SET_NO_HITS_REDIRECT = "SET_NO_HITS_REDIRECT";

// PRODUCTS
export const ADD_PRODUCT = "ADD_PRODUCT";
export const ADD_PRODUCT_GUESTS = "ADD_PRODUCT_GUESTS";
export const REMOVE_PRODUCT = "REMOVE_PRODUCT";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const REMOVE_PRODUCT_COUNT = "REMOVE_PRODUCT_COUNT";
export const ADD_PRODUCT_COUNT = "ADD_PRODUCT_COUNT";

export const ADD_PRODUCT_WITH_COUNT = "ADD_PRODUCT_WITH_COUNT";
export const UPDATE_PRODUCT_WITH_COUNT = "UPDATE_PRODUCT_WITH_COUNT";
export const SUCCESSFULLY_ADDED_ALL_PRODUCTS = "SUCCESSFULLY_ADDED_ALL_PRODUCTS";
export const UPDATE_PRODUCT_ITEM = "UPDATE_PRODUCT_ITEM";

export const SET_PRODUCT_GUEST_CATEGORY_COUNT = "SET_PRODUCT_GUEST_CATEGORY_COUNT";
export const REMOVE_PRODUCT_GUEST_CATEGORY = "REMOVE_PRODUCT_GUEST_CATEGORY";

// CHECKOUT_CART
export const SET_CHECKOUT_CART_ID = "SET_CHECKOUT_CART_ID";
export const SET_CHECKOUT_CART_SUMMARY = "SET_CHECKOUT_CART_SUMMARY";
export const ADD_EXTRAS_TO_CART_FAILED = "ADD_EXTRAS_TO_CART_FAILED";
export const FETCH_CHECKOUT_CART_SUMMARY_FAILED = "FETCH_CHECKOUT_CART_SUMMARY_FAILED";
export const SET_CART_FAILED = "SET_CART_FAILED";
export const ADDED_ALL_PRODUCTS_FAILED = "ADDED_ALL_PRODUCTS_FAILED";
export const SYNC_PRODUCTS_IN_CART_FAILED = "SYNC_PRODUCTS_IN_CART_FAILED";
export const DELETE_PRODUCT_FROM_CART_FAILED = "DELETE_PRODUCT_FROM_CART_FAILED";
export const SET_LATEST_CHANGED_PRODUCT_TYPE = "SET_LATEST_CHANGED_PRODUCT_TYPE";
export const EMPTY_CART_PRODUCTS = "EMPTY_CART_PRODUCTS";
export const CREATE_CART_FROM_RESERVATION_FAILED = "CREATE_CART_FROM_RESERVATION_FAILED";
export const SET_CART_FROM_RESERVATION = "SET_CART_FROM_RESERVATION";
export const SET_CART_PRODUCT_EXTRAS = "SET_CART_PRODUCT_EXTRAS";
export const SET_CHECKOUT_CART_NOTE = "SET_CHECKOUT_CART_NOTE";
export const RESET_CART = "RESET_CART";
export const SET_BOOKING_FOR_OTHERS = "SET_BOOKING_FOR_OTHERS";
export const SET_CHECKOUT_SUMMARY_DETAILED_VIEW = "SET_CHECKOUT_SUMMARY_DETAILED_VIEW";
export const SET_PRODUCTS_NOT_ADDED_PATH = "SET_PRODUCTS_NOT_ADDED_PATH";

/*
  GUESTS
*/
// Guests
export const SET_GUEST = "SET_GUEST";
export const DELETE_GUEST = "DELETE_GUEST";
export const SET_GUESTS = "SET_GUESTS";
export const APPEND_GUESTS = "APPEND_GUESTS";
export const UPDATE_GUEST = "UPDATE_GUEST";
export const RESET_GUESTS = "RESET_GUESTS";
export const INCREMENT_GUEST_COUNT = "INCREMENT_GUEST_COUNT";
export const DECREMENT_GUEST_COUNT = "DECREMENT_GUEST_COUNT";
export const UPDATE_FILTER = "UPDATE_FILTER";
export const CREATE_GUEST_KEY = "CREATE_GUEST_KEY";
export const UPDATE_GUEST_FAILED = "UPDATE_GUEST_FAILED";
export const CREATE_GUESTS_FAILED = "CREATE_GUESTS_FAILED";
export const CREATE_GUEST_FAILED = "CREATE_GUEST_FAILED";
export const FETCH_GUEST_FAILED = "FETCH_GUEST_FAILED";
export const FETCH_GUESTS_FAILED = "FETCH_GUESTS_FAILED";
export const ALL_GUESTS_UPDATED_SUCCESSFULLY = "ALL_GUESTS_UPDATED_SUCCESSFULLY";
export const DELETE_GUEST_FROM_CART_FAILED = "DELETE_GUEST_FROM_CART_FAILED";
// Guest
export const UPDATE_GUEST_DATA = "UPDATE_GUEST_DATA";
export const DELETE_GUEST_FAILED = "DELETE_GUEST_FAILED";

// WINDOW
export const SCREEN_RESIZE = "SCREEN_RESIZE";
export const SET_DEVICE = "SET_DEVICE";
export const SET_SIGN_UP_MODAL = "SET_SIGN_UP_MODAL";
export const CREATE_TIMESTAMP = "CREATE_TIMESTAMP";
export const SET_BOOKING_ERROR = "SET_BOOKING_ERROR";
export const SET_IS_IFRAME = "SET_IS_IFRAME";
export const SET_MAP_OPEN = "SET_MAP_OPEN";
export const SET_IFRAME_OFFSET_TOP = "SET_IFRAME_OFFSET_TOP";

// FILTER
export const SET_DATE = "SET_DATE";
export const SET_PERSON = "SET_PERSON";
export const SET_FILTER = "SET_FILTER";
export const SET_FILTERS = "SET_FILTERS";
export const REMOVE_FILTER = "REMOVE_FILTER";
export const REMOVE_FILTERS = "REMOVE_FILTERS";
export const SET_RESERVATIONTYPE_FILTERS = "SET_RESERVATIONTYPE_FILTERS";
export const CLEAR_FILTERS = "CLEAR_FILTERS";
export const SET_PROMO_CODE = "SET_PROMO_CODE";
export const SET_PROMO_CODE_ERROR = "SET_PROMO_CODE_ERROR";
export const RESET_PROMO_CODE = "RESET_PROMO_CODE";
export const SET_DEFAULT_CALENDAR_DATA = "SET_DEFAULT_CALENDAR_DATA";
export const RESET_FILTER_STATE = "RESET_FILTER_STATE";

// ADDITIONALINFORMATION
export const FETCH_ADDITIONALINITEMFORMATION_FAILED = "FETCH_ADDITIONALITEMINFORMATION_FAILED";
export const SET_ADDITIONALINITEMFORMATION = "SET_ADDITIONALINITEMFORMATION";

// PRODUCTS EXTRAS
export const FETCH_PRODUCTS_EXTRAS_FAILED = "FETCH_PRODUCTS_EXTRAS_FAILED";
export const SET_PRODUCTS_EXTRAS = "SET_PRODUCTS_EXTRAS";

/* ACCOUNT */
//RESERVATIONS
export const FETCH_ACCOUNT_RESERVATIONS = "FETCH_ACCOUNT_RESERVATIONS";
export const SET_ACCOUNT_RESERVATIONS = "SET_ACCOUNT_RESERVATIONS";
export const FETCH_ACCOUNT_RESERVATIONS_FAILED = "FETCH_ACCOUNT_RESERVATIONS_FAILED";
//RESERVATION
export const FETCH_ACCOUNT_RESERVATION = "FETCH_ACCOUNT_RESERVATION";
export const SET_ACCOUNT_RESERVATION = "SET_ACCOUNT_RESERVATION";
export const FETCH_ACCOUNT_RESERVATION_FAILED = "FETCH_ACCOUNT_RESERVATION_FAILED";
export const SET_BOOKING_DETAILED_VIEW = "SET_BOOKING_DETAILED_VIEW";

export const SET_CUSTOMER_TOKEN = "SET_CUSTOMER_TOKEN";
export const SET_CUSTOMER_AUTHENTICATION_STATUS = "SET_CUSTOMER_AUTHENTICATION_STATUS";
export const AUTHENTICATE_CUSTOMER_FAILED = "AUTHENTICATE_CUSTOMER_FAILED";
export const SET_AUTHENTICATED_USER_DATA = "SET_AUTHENTICATED_USER_DATA";
export const FETCH_AUTHENTICATED_USER_DATA_FAILED = "FETCH_AUTHENTICATED_USER_DATA_FAILED";
export const SET_UNREGISTERED_USER = "SET_UNREGISTERED_USER ";
export const UPDATE_USER_DATA = "UPDATE_USER_DATA";
export const FAILED_TO_CREATE_NEW_USER = "FAILED_TO_CREATE_NEW_USER";
export const SUCCESSFULLY_UPDATED_USER_DATA = "SUCCESSFULLY_UPDATED_USER_DATA";
export const PUBLISH_UPDATED_USER_DATA_FAILED = "PUBLISH_UPDATED_USER_DATA_FAILED";
export const SET_RETRIEVE_PASSWORD_SUCCESS = "SET_RETRIEVE_PASSWORD_SUCCESS";
export const SET_RETRIEVE_PASSWORD_FAILED = "SET_RETRIEVE_PASSWORD_FAILED";
export const RESET_API_MESSAGES = "RESET_API_MESSAGES";
export const FETCH_ACCOUNT_GUESTS_FAILED = "FETCH_ACCOUNT_GUESTS_FAILED";
export const SET_ACCOUNT_GUESTS = "SET_ACCOUNT_GUESTS";

// AXIOS STATUS
export const IS_LOADING = "IS_LOADING";
export const LOADING_DONE = "LOADING_DONE";
export const SET_LOADING = "SET_LOADING";
export const SET_REQUESTS_LOADING = "SET_REQUESTS_LOADING";

// RESERVATION
export const SET_RESERVATION = "SET_RESERVATION";
export const RESET_RESERVATION = "RESET_RESERVATION";
export const MAKE_RESERVATION_FAILED = "MAKE_RESERVATION_FAILED";
export const CANCEL_PRODUCT_FROM_RESERVATION_FAILED = "CANCEL_PRODUCT_FROM_RESERVATION_FAILED";
export const CANCEL_FULLPRODUCT_FROM_RESERVATION_FAILED = "CANCEL_FULLPRODUCT_FROM_RESERVATION_FAILED";

//TEXTS
export const SET_TEXT_DATA = "SET_TEXT_DATA";
export const FETCH_TEXT_DATA_FAILED = "FETCH_TEXT_DATA_FAILED";

// DESTORY
export const DESTORY_STATE = "DESTORY_STATE";
export const SIGN_OUT_USER = "SIGN_OUT_USER";

// ERRORS
export const CLEAR_ERRORS = "CLEAR_ERRORS";

// AVAILABLE FILTERS
export const SET_AVAILABLE_CRITERIAS = "SET_AVAILABLE_FILTERS";
export const FETCH_AVAILABLE_CRITERIAS_FAILED = "FETCH_AVAILABLE_FILTERS_FAILED";

// SEARCH
export const SET_LAST_SEARCHED_TIMESTAMP = "SET_LAST_SEARCHED_TIMESTAMP";
export const RESET_LAST_SEARCHED_TIMESTAMPS = "RESET_LAST_SEARCHED_TIMESTAMPS";
export const RESET_LAST_SEARCHED_TIMESTAMP_BY_RESVTYPE = "RESET_LAST_SEARCHED_TIMESTAMP_BY_RESVTYPE";
export const SET_LAST_SEARCHED_DATE_SPAN = "SET_LAST_SEARCHED_DATE_SPAN";
export const RESET_LAST_SEARCHED_DATE_SPAN = "RESET_LAST_SEARCHED_DATE_SPAN";
export const SET_LAST_SEARCHED_GUESTS = "SET_LAST_SEARCHED_GUESTS";
export const RESET_LAST_SEARCHED_GUESTS = "RESET_LAST_SEARCHED_GUESTS";
export const SET_SELECTED_SORT_ACCOMMODATION = "SET_SELECTED_SORT_ACCOMMODATION";
export const RESET_SELECTED_SORT_ACCOMMODATION = "RESET_SELECTED_SORT_ACCOMMODATION";

// NOTIFICATIONS
export const SET_NOTIFICATION = "SET_NOTIFICATION";
export const CLEAR_NOTIFICATIONS = "CLEAR_NOTIFICATIONS";
