import React from "react";
import classnames from "classnames";

import "./Checkbox.scss";

// Checkbox component
const Checkbox = props => {
    const { label, hideLabel = false, name, className, disabled } = props;
    const labelClasses = classnames("checkbox__label", {
        "checkbox__label--hidden": hideLabel,
        "checkbox__label--disabled": disabled,
    });
    const checkmarkClasses = classnames("checkbox__checkmark", {
        "checkbox__checkmark--disabled": disabled,
    });

    return (
        <div className={classnames("checkbox", { [className]: className })}>
            <label htmlFor={name} className={labelClasses}>
                <span className="checkbox__label-text">{label}</span>
                <input
                    type="checkbox"
                    id={name}
                    name={name}
                    className="checkbox__input"
                    checked={props.checked}
                    onChange={() => {
                        props.onChange({ key: name, value: !props.checked });
                    }}
                    disabled={disabled}
                />
                <span className={checkmarkClasses} aria-hidden="true"></span>
            </label>
        </div>
    );
};

export const CheckboxGroup = props => {
    const { label, hideLabel = false, name, disabled } = props;
    const labelClasses = classnames("checkbox__label", {
        "checkbox__label--hidden": hideLabel,
        "checkbox__label--disabled": disabled,
    });
    const checkmarkClasses = classnames("checkbox__checkmark", {
        "checkbox__checkmark--disabled": disabled,
    });

    return (
        <div className="checkbox">
            <label htmlFor={name} className={labelClasses}>
                <span className="checkbox__label-text">{label}</span>
                <input
                    type="checkbox"
                    id={name}
                    name={name}
                    className="checkbox__input"
                    checked={props.checked}
                    onChange={() => {
                        props.onChange();
                    }}
                    disabled={disabled}
                />
                <span className={checkmarkClasses} aria-hidden="true"></span>
            </label>
        </div>
    );
};

export default Checkbox;
