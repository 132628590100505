import * as types from "./types";

export const setAvailableCriterias = payload => {
    return {
        type: types.SET_AVAILABLE_CRITERIAS,
        payload: payload,
    };
};

export const fetchAvailableCriteriasFailed = error => {
    return {
        type: types.FETCH_AVAILABLE_CRITERIAS_FAILED,
        error: error,
    };
};
