import classnames from "classnames";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
    hasBookstat,
    isAccommodation,
    isActivityWithDynamicPrice,
    isExtra,
    isPackage,
    isSkipassWithPhotoMandatory,
} from "../../../BusinessUtils";
import { getGuestNameAndAge, parsePhoneNumber } from "../../../Helper";
import PhotoButton from "../../PhotoButton/PhotoButton";
import ProductListItem from "../../ProductListItem/ProductListItem";
import "./GuestListItem.scss";

/**
 * Render one guest in guest list view  *
 */
const GuestListItem = ({
    texts,
    guest,
    currency,
    booking,
    createdFromReservation,
    setRenderedProduct,
    generalAges,
}) => {
    const [showDetails, setShowDetails] = useState(false);

    const handleMoreInformationClick = () => {
        setShowDetails(!showDetails);
    };

    // Render one guest and prouduct related information.
    const renderGuest = guest => {
        const products = Object.values(guest.products);
        const showPhotoButton = products.filter(isSkipassWithPhotoMandatory).length > 0;

        return (
            <>
                <Row>
                    <Col md={8} sm={12}>
                        <h4 className="box-heading guestListItem__fullname">
                            {getGuestNameAndAge(guest, texts?.generalyearshort)}
                        </h4>
                    </Col>
                    {Object.keys(guest.products).length !== 0 && (
                        <Col md={4} sm={12} className="text-end">
                            <a className="link" onClick={handleMoreInformationClick}>
                                {texts[showDetails ? "guests.lessinformation" : "guests.moreinformationbutton"]}
                            </a>
                        </Col>
                    )}
                </Row>
                {showDetails && (
                    <Row className={classnames({ guestListItem__expanded: showDetails })}>
                        <Col md={9} xs={8}>
                            {guest.email && <div>{guest.email}</div>}
                            {guest.phone && <div>{parsePhoneNumber(guest.phoneprefix, guest.phone)}</div>}
                        </Col>
                        <Col md={3} xs={4}>
                            {showPhotoButton && <PhotoButton guestlid={guest.guestlid} imagehash={guest.imagehash} />}
                        </Col>
                    </Row>
                )}
            </>
        );
    };

    // Render all products that is connected to the guest.
    const renderProducts = guest => {
        // Get products as array and sort by booked products asc.
        const products = Object.values(guest.products).sort(
            (a, b) => (hasBookstat(b) ? 1 : 0) - (hasBookstat(a) ? 1 : 0)
        );

        if (products.length === 0) {
            return `${texts["checkoutsummary.guest.missingproducts"]}`;
        }

        return (
            // Creates a new product and populated with the data.
            products
                .filter(product => {
                    // Do not render extras, accommodations or packages.
                    if (
                        isExtra(product) ||
                        isAccommodation(product) ||
                        isActivityWithDynamicPrice(product) ||
                        isPackage(product)
                    ) {
                        return false;
                    }

                    return true;
                })
                .map(product => {
                    // Render one product
                    return (
                        <ProductListItem
                            key={product.id}
                            products={products}
                            product={product}
                            guest={guest}
                            texts={texts}
                            currency={currency}
                            booking={booking}
                            showDetails={showDetails}
                            createdFromReservation={createdFromReservation}
                            setRenderedProduct={setRenderedProduct}
                            generalAges={generalAges}
                        />
                    );
                })
        );
    };

    return (
        <div className="box guestListItem">
            {renderGuest(guest)}
            {renderProducts(guest)}
        </div>
    );
};

export default GuestListItem;
