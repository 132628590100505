import React from "react";
import { Col, Row } from "react-bootstrap";
import "./OwnerBar.scss";
import useTranslate from "../../hooks/useTranslate";

type Props = {
    owner: any;
};

const OwnerBar = ({ owner }: Props) => {
    const t = useTranslate();

    return (
        <div className="owner-bar">
            <div className="container">
                <Row>
                    <Col className="py-3 d-flex align-items-center justify-content-center">
                        <div className="me-5">
                            {`${t("book.owner.logged_in_as_owner")}: ${owner.firstname || ""} ${
                                owner.lastname || ""
                            } (${owner.email})`}
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default OwnerBar;
