import { CalendarIcon, ErrorMessage, FileOutlineIcon, TagIcon, style } from "@r360/library";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { RootState } from "../..";
import { sortGroups, sortRentalItems } from "../../BusinessUtils";
import { formatPrice } from "../../Helper";
import { RentalDesktop } from "../../assets/reservationTypes/Rental";
import { MultipleVariantsCard } from "../../components/MultipleVariantsCard/MultipleVariantsCard";
import { MultipleVariantsCardSkeleton } from "../../components/MultipleVariantsCard/MultipleVariantsCardSkeleton";
import { NoSearchResult } from "../../components/NoSearchResult/NoSearchResult";
import { ReservationTypeHeader } from "../../components/ReservationTypeHeader/ReservationTypeHeader";
import { ReservationTypeImage } from "../../components/ReservationTypeImage/ReservationTypeImage";
import { newFilterGroupItems } from "../../hooks";
import useAppSelector from "../../hooks/useAppSelector";
import useChangeFilter from "../../hooks/useChangeFilter";
import useChangeSearch from "../../hooks/useChangeSearch";
import useTranslate from "../../hooks/useTranslate";
import { resvTypeByLid } from "../../selectors/Selectors";
import {
    TCriteriaItem,
    TGroup,
    TItem,
    TReservationResult,
    TReservationType,
    TReservationTypeLid,
} from "../../store/types";

import { useProductsNotAddedPath } from "../../hooks/useProductsNotAddedPath";

type TBikeRentalPageProps = {
    reservationTypeid: TReservationTypeLid;
};

export const BikeRentalPage = ({ reservationTypeid }: TBikeRentalPageProps) => {
    const { requestsLoading } = useAppSelector((state: RootState) => state.axiosStatus);
    const isLoading = requestsLoading["fetchReservationTypeResults"];
    const { isDesktop } = useAppSelector((state: RootState) => state.window);
    const clientData = useAppSelector((state: RootState) => state.clientData);
    const currency = clientData.options?.general?.currency;
    const filter = useAppSelector((state: RootState) => state.filter);
    const activeFilters = filter[reservationTypeid];
    const reservationResult = useAppSelector((state: RootState) => state.reservationResult);
    const rentalResult: TReservationResult = reservationResult[reservationTypeid];
    const [filteredData, setFilteredData] = useState<TGroup[] | null>(null);
    const t = useTranslate();
    const [addToCartDataQuantity, setAddToCartDataQuantity] = useState<{ [key: number]: number }>({});
    const addToCartTotalQuantity = Object.values(addToCartDataQuantity).reduce((acc, item) => acc + item, 0);
    const showProductsNotAddedWarning = !!addToCartTotalQuantity;
    const reservationType: TReservationType | undefined = useAppSelector((state: RootState) =>
        resvTypeByLid(state, reservationTypeid)
    );
    const reservationTypeCriterias = reservationType?.criterias || {};

    useChangeSearch({
        reservationResultSearch: rentalResult?.search,
        reservationTypeId: reservationTypeid,
        skipGuestsOnSearchWithPrice: true,
        allowSearchWithoutPrice: false,
    });

    useChangeFilter(reservationTypeid);
    useProductsNotAddedPath(showProductsNotAddedWarning);

    useEffect(() => {
        const criterias = Object.values(reservationTypeCriterias)
            .flat()
            .reduce((acc, curr) => {
                acc[curr.code] = curr;
                return acc;
            }, {} as { [key: number]: TCriteriaItem });

        let filteredData = Object.values(rentalResult?.groups || {})
            .map((group: TGroup) => {
                return newFilterGroupItems(group, activeFilters || {}, criterias);
            })
            .map((group: TGroup) => ({
                ...group,
                items: group.items.filter(item => item.available),
            }))
            .filter(group => group.items.length > 0);

        // Sort bike rental groups and items.
        filteredData = sortGroups(filteredData).map(group => {
            group.items = sortRentalItems(group.items);
            return group;
        });

        setFilteredData(filteredData);
    }, [rentalResult?.resultSetId, activeFilters]);

    // Clear addToCartData when a new search has been made
    useEffect(() => {
        setAddToCartDataQuantity({});
    }, [rentalResult?.resultSetId]);

    const getBikeCardItemColumns = (item: TItem) => {
        return [
            {
                title: t("book.general.number_days", [
                    `${moment(rentalResult.search.depdate)
                        .add(1, "days")
                        .diff(moment(rentalResult.search.arrdate), "days")
                        .toString()}`,
                ]),
                icon: <CalendarIcon color={style.brandBlueColor} />,
            },
            {
                title: item.title,
                icon: <FileOutlineIcon color={style.brandBlueColor} />,
            },
            {
                title: t("book.activity.number_left", [item.available ?? ""]),
            },
            {
                title: `${formatPrice(item.price, currency)}`,
                icon: <TagIcon color={style.brandBlueColor} />,
            },
        ];
    };

    const renderBikeRentalList = () => {
        if (isLoading) {
            return [...Array(8)].map((_, i) => <MultipleVariantsCardSkeleton key={i} isDesktop={isDesktop} />);
        } else if (filteredData?.length && !isLoading) {
            return filteredData?.map((group, i) => {
                const title = group.groupitem?.title ?? group.items[0]?.title;
                const images = [
                    ...(group.items[0]?.mainimage ? [group.items[0]?.mainimage] : []),
                    ...(group.items[0]?.images || []),
                ];
                return (
                    <MultipleVariantsCard
                        key={group.id}
                        group={group}
                        items={group.items}
                        title={group.groupitem?.title ?? group.items[0]?.title}
                        description={group.groupitem?.weblong ?? group.items[0]?.weblong}
                        availability={group.items[0]?.available}
                        images={images}
                        showVariants={group.items.length > 1}
                        currency={currency}
                        getItemColumns={getBikeCardItemColumns}
                        priceLabelText={t("book.general.price_from")}
                        showItemsButtonText={t("book.general.show_items")}
                        renderExtraInformation={multipleItems => {
                            const category = group.items[0]?.category;

                            return (
                                <div className="u-d-flex u-gap-6 u-align-items-center">
                                    {(multipleItems || category) && (
                                        <>
                                            <FileOutlineIcon color={style.brandBlueColor} />
                                            {multipleItems ? (
                                                <p className="u-mb-0">{t("book.rental.multiple_variants_info")}</p>
                                            ) : (
                                                <p>{group.items[0]?.category}</p>
                                            )}
                                        </>
                                    )}
                                </div>
                            );
                        }}
                        fallbackImage={RentalDesktop}
                        onAddToCartQuantityChange={data =>
                            setAddToCartDataQuantity(prevState => {
                                if (prevState[group.items[0]?.id] === data) {
                                    return prevState;
                                }

                                return { ...prevState, [group.items[0]?.id]: data };
                            })
                        }
                        index={i}
                        arrayLength={filteredData.length}
                        animate={!Object.values(activeFilters ?? {}).length}
                        decorateItemBeforeCart={item => ({ ...item, groupTitle: title })}
                        reservationType={reservationType?.type}
                    />
                );
            });
        } else if ((rentalResult?.hitsMatchingFilter === "0" || !filteredData?.length) && !isLoading) {
            return <NoSearchResult />;
        } else if (rentalResult?.error && !isLoading) {
            return (
                <div className="u-pt-24">
                    <ErrorMessage
                        headingText={t("book.general.error")}
                        primaryButtonText={t("book.general.reload_page")}
                        primaryButtonOnClick={() => window.location.reload()}
                    />
                </div>
            );
        }
    };

    const showFilter =
        rentalResult?.groups &&
        reservationTypeCriterias &&
        Object.values(rentalResult.groups).length > 0 &&
        Object.values(reservationTypeCriterias).flat().length > 0;

    return rentalResult?.search || isLoading ? (
        <>
            {/* <ProductsNotAddedToCartModal readyToShow={showProductsNotAddedWarning} /> */}
            <ReservationTypeHeader
                reservationTypeId={reservationTypeid}
                hits={filteredData?.length ?? 0}
                filtersAvailable={showFilter}
            />
            {renderBikeRentalList()}
        </>
    ) : (
        <ReservationTypeImage reservationTypeId={reservationTypeid} />
    );
};
