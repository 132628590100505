import React, { forwardRef, useState, useImperativeHandle } from "react";
import { Dropdown } from "react-bootstrap";

import "./DropdownButton.scss";

// Dropdown component
const DropdownButton = forwardRef((props, ref) => {
    const { type = "button", text, className, closeOnClick = false, disabled = false, alignRight = false } = props;
    const buttonText = text || "Dropdown Button";
    const dropdownId = buttonText.replace(/([^a-z0-9]+)/gi, "-");
    const [show, setShowMenu] = useState(false);
    const dropdownClasses = className ? `dropdown-button ${className}` : "dropdown-button";
    const popperConfig = {
        modifiers: [
            {
                name: "offset",
                options: {
                    offset: () => [30, 30],
                },
            },
        ],
    };

    useImperativeHandle(ref, () => ({
        setShowMenu: setShowMenu,
    }));

    // Render menu content
    const renderMenuContent = () => {
        if (props.children) {
            return props.children;
        }
        return "Default content...";
    };

    // Handle toggle
    const handleToggle = event => {
        setShowMenu(event);
    };

    // Handle menu click
    const handleMenuClick = () => {
        if (closeOnClick) {
            setShowMenu(false);
        }
    };

    // Render
    return (
        <Dropdown
            className={dropdownClasses}
            show={show}
            onToggle={handleToggle}
            // alignLeft   <-- creates console error
        >
            {type === "button" && (
                <Dropdown.Toggle
                    className="dropdown-button__button"
                    variant="outline-secondary"
                    id={dropdownId}
                    disabled={disabled}
                >
                    {buttonText}
                </Dropdown.Toggle>
            )}
            {type === "link" && (
                <Dropdown.Toggle
                    className="dropdown-button__link btn-link"
                    // variant="outline-secondary"
                    id={dropdownId}
                    disabled={disabled}
                >
                    {buttonText}
                </Dropdown.Toggle>
            )}

            <Dropdown.Menu
                align={alignRight ? "end" : ""}
                className="dropdown-button__menu"
                popperConfig={popperConfig}
                onClick={handleMenuClick}
            >
                {renderMenuContent()}
            </Dropdown.Menu>
        </Dropdown>
    );
});

DropdownButton.displayName = "DropdownButton";

export default DropdownButton;
