import React from "react";
import { ParagraphSkeleton, Skeleton, SkeletonGroup } from "@r360/library";

export const GroupIncrementerCardSkeleton = () => {
    return (
        <SkeletonGroup>
            <div className="u-br-12 u-overflow-hidden u-bg-white u-mb-24">
                <div className="row">
                    <div className="col-12 col-lg-3">
                        <Skeleton height="150px" borderRadius="0" />
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="u-p-36">
                            <Skeleton width="50%" className="u-mb-12" />
                            <ParagraphSkeleton />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-3"></div>
                    <div className="col-12 col-lg">
                        <div className="u-p-36">
                            <div className="row">
                                {[...Array(4)].map((_, i) => {
                                    return (
                                        <div
                                            key={i}
                                            className="u-d-flex u-flex-column u-gap-6 col-6 col-lg-3 u-align-items-center u-mb-12"
                                        >
                                            <Skeleton width="85%" />
                                            <Skeleton width="50%" />
                                            <Skeleton height="35px" />
                                            <Skeleton />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </SkeletonGroup>
    );
};
