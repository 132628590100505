import React, { useState } from "react";
import { useSelector } from "react-redux";
import { optionsSelector } from "../../../selectors/Selectors";
import ProductAmenities from "../../ProductAmenities/ProductAmenities";
import ProductGallery from "../../ProductGallery/ProductGallery";
import ProductImagesGallery from "../../ProductImagesGallery/ProductImagesGallery";
import { Modal } from "../../UI";
import ReadMoreText from "../../UI/ReadMoreText";
import "./AccommodationDetails.scss";
import useAppSelector from "../../../hooks/useAppSelector";

const AccommodationDetails = ({ weblong, texts, title, images = [], product }) => {
    const [showImagesModal, setShowImagesModal] = useState(false);
    const showImageDescriptions = useSelector(optionsSelector).layout?.show_image_descriptions || false;
    const lang = useAppSelector(state => state.clientData?.lang);

    // Handle click on show all pcitures
    const handleShowImagesClick = () => {
        setShowImagesModal(!showImagesModal);
    };

    // Prepare images for product gallery
    const galleryImages = images.map(image => ({
        thumbnail: image.url,
        original: image.url,
        text: image?.translatedDesc ? image?.translatedDesc[lang] : "",
    }));

    return (
        <div className="AccommodationDetails">
            <h2 className="h2">{title}</h2>
            <ProductGallery images={galleryImages} numberOfThumbnails={2} />
            <div style={{ textAlign: "right", height: "2rem", paddingTop: "0.5rem" }}>
                {images.length > 1 && (
                    <a className="link" onClick={handleShowImagesClick}>
                        {texts?.showallpictures}
                    </a>
                )}
            </div>

            {weblong?.trim() !== "" && (
                <div className="row mb-4">
                    <div className="col-md-12 col-lg-12">
                        <h5 className="h5">{texts?.accommodationinformationheading || texts?.description}</h5>
                    </div>
                    <div className="col-md-12 col-lg-12">
                        <ReadMoreText
                            lines={3}
                            moreText={texts?.showmore}
                            lessText={texts?.showless}
                            anchorClass="link"
                            html={true}
                            whiteSpacePreLine={true}
                        >
                            {weblong}
                        </ReadMoreText>
                    </div>
                </div>
            )}

            {Object.entries(product.crits).filter(([critId]) => product.presentationcritdefs[critId]).length > 0 && (
                <div className="row">
                    <div className="col">
                        <h5 className="h5" style={{ paddingBottom: 0 }}>
                            {texts?.amenities}
                        </h5>
                        <ProductAmenities
                            presentationCriteriasDefs={product.presentationcritdefs}
                            crits={product.crits}
                            texts={texts}
                        />
                    </div>
                </div>
            )}

            <Modal show={showImagesModal} setShow={setShowImagesModal} size="lg">
                <ProductImagesGallery images={galleryImages} showImageDescriptions={showImageDescriptions} />
            </Modal>
        </div>
    );
};
export default AccommodationDetails;
