import { TCartItem, TItem } from "../store/types";
import { RootState } from "..";
import useAppSelector from "./useAppSelector";
import { isAccommodation } from "../BusinessUtils";

/**
 * Usage:
 * const getItemInCart = useItemInCart();
 * const { isItemInCart, itemInCartQuantity } = getItemInCart(item);
 *
 * @returns A function that can be used to see if an item is in the cart and the quantity that has been added.
 */

const isCartItem = (item: any): item is TCartItem => !!item.cartItemId;

export const useItemInCart = () => {
    const cartProducts: TCartItem[] = useAppSelector((state: RootState) => state.checkoutCart.products);

    const getItemInCart = (item: TItem) => {
        const itemId = getItemId(item);

        const itemInCart = cartProducts?.find(cartItem => {
            if (cartItem.kind === item.kind) {
                return itemId === getItemId(cartItem);
            }
        });

        return { isItemInCart: !!itemInCart, itemInCartQuantity: itemInCart?.count ?? 0 };
    };

    const getItemId = (item: TItem | TCartItem) => {
        const id = Object.entries(item).reduce((id: string, [key, value]) => {
            switch (key) {
                case "poollid":
                case "arrdate":
                case "price":
                case "activitylid":
                case "category":
                    return id + value?.toString() ?? "";

                case "occations":
                    return id + JSON.stringify(value);

                case "unitlid":
                    // Needed to allow pool-bookings of accommodation to be added multiple times to the cart.
                    if (isAccommodation(item) && value === 0 && isCartItem(item)) {
                        return id + item.cartItemId?.toString() ?? "";
                    } else {
                        return id + value?.toString() ?? "";
                    }
                default:
                    return id;
            }
        }, "");

        return id;
    };

    return getItemInCart;
};

export default useItemInCart;
