import {
    ErrorMessage,
    Heading,
    Notification,
    Select,
    Button,
    style,
    ChevronUpIcon,
    ChevronDownIcon,
} from "@r360/library";
import { TSelectOption } from "@r360/library/dist/components/Select";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { RootState } from "../..";
import { AccommodationFilter } from "../../components/AccommodationFilter/AccommodationFilter";
import AccommodationOwnerResultList from "../../components/AccommodationOwnerResultList/AccommodationOwnerResultList";
import AccommodationResultList from "../../components/AccommodationResultList/AccommodationResultList";
import { NoSearchResult } from "../../components/NoSearchResult/NoSearchResult";
import { ToggleFilterButton } from "../../components/ToggleFilterButton/ToggleFilterButton";
import { newFilterGroupItems } from "../../hooks";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import useChangeFilter from "../../hooks/useChangeFilter";
import useChangeSearch from "../../hooks/useChangeSearch";
import useTranslate from "../../hooks/useTranslate";
import { optionsSelector, resvTypeByLid } from "../../Selectors";
import { userSelector } from "../../selectors/Selectors";
import {
    resetLastSearchedTimestamps,
    resetSelectedSortAccommodation,
    setSelectedSortAccommodation,
} from "../../store/actions/search";
import {
    TCriteriaItem,
    TGroup,
    TItem,
    TReservationResult,
    TReservationType,
    TReservationTypeCriterias,
    TReservationTypeLid,
} from "../../store/types";
import pick from "lodash/pick";
import AxiosClient from "../../API/AxiosClient";
import { AccommodationMap } from "../../components/AccommodationMap/AccommodationMap";
import { setIsMapOpen } from "../../store/actions/window";
import moment from "moment";
import { getLowestPrice } from "../../BusinessUtils";
import "../../components/AccommodationFilter/accommodations-filter.scss";

interface TLocationState {
    redirect: string;
}

type TAccomodationResultPage = {
    reservationTypeid: TReservationTypeLid;
};

const presentationTypeFiltersToShow = [
    "interval_filters",
    "accommodation_type_filters",
    "enum_filters",
    "amenities_filters",
];

export const AccommodationResultPage = ({ reservationTypeid }: TAccomodationResultPage) => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const locationState = location.state as TLocationState;
    const user = useAppSelector(userSelector);
    const filters = useAppSelector((state: RootState) => state.filter);
    const activeFilters = filters[reservationTypeid];
    const { isMobile, isDesktopNewDesign } = useAppSelector((state: RootState) => state.window);
    const clientData = useAppSelector((state: RootState) => state.clientData);
    const accommodationResult: TReservationResult = useAppSelector(
        (state: RootState) => state.reservationResult[reservationTypeid]
    );
    const [redirect, setRedirect] = useState(false);
    const [filteredData, setFilteredData] = useState<TGroup[]>([]);
    const [filteredAndSortedData, setFilteredAndSortedData] = useState<TGroup[]>(filteredData);
    const [ownerItems, setOwnerItems] = useState<TItem[]>([]);
    const [showMobileFilter, setShowMobileFilter] = useState(false);
    const sort = useAppSelector((state: RootState) => state.search.selectedSortAccommodation.sort);
    const t = useTranslate();
    const { useFilterSearch } = (
        reservationTypeid ? clientData.reservationtypes.find((x: TReservationType) => x.lid === reservationTypeid) : {}
    ) as TReservationType;
    const reservationTypeCriterias: TReservationTypeCriterias =
        useAppSelector((state: RootState) => resvTypeByLid(state, reservationTypeid))?.criterias || {};
    const { requestsLoading } = useAppSelector((state: RootState) => state.axiosStatus);
    const isLoading = requestsLoading["fetchReservationTypeResults"];
    const isMapOpen = useAppSelector((state: RootState) => state.window.isMapOpen);
    const isMapEnabled = !!useAppSelector(optionsSelector)?.module?.maps;
    const hasCoordinates =
        accommodationResult?.groups &&
        Object.values(accommodationResult?.groups).find(group =>
            group.items.find(item => item.longitude !== null && item.latitude !== null)
        );

    const toggleMap = () => {
        dispatch(setIsMapOpen(!isMapOpen));
    };

    useEffect(() => {
        AxiosClient.get("/client/mapview");
    }, []);

    useEffect(() => {
        if (
            accommodationResult?.search.arrdate === moment().format("YYYY-MM-DD") &&
            accommodationResult?.search.depdate === moment().add(1, "y").format("YYYY-MM-DD")
        ) {
            dispatch(setSelectedSortAccommodation("standard"));
        }
    }, [accommodationResult?.search.arrdate, accommodationResult?.search.depdate, dispatch]);

    useChangeSearch({
        reservationResultSearch: accommodationResult?.search,
        reservationTypeId: reservationTypeid,
        skipGuestsOnSearchWithPrice: false,
        allowSearchWithoutPrice: true,
    });

    useChangeFilter(reservationTypeid);

    useEffect(() => {
        if (locationState?.redirect) {
            setRedirect(true);
        }
    }, [locationState?.redirect]);

    // ? Move?
    const resetLastSearchedTimestampsAndReloadPage = () => {
        dispatch(resetLastSearchedTimestamps());
        dispatch(resetSelectedSortAccommodation());
        window.location.reload();
    };

    const sortOptions: TSelectOption[] = accommodationResult?.search?.checkPriceAndAvailability
        ? [
              { value: "alphabetical", label: t("book.general.sort_alphabetical") },
              {
                  value: "price-ascending",
                  label: t("book.general.price_ascending"),
              },
              {
                  value: "price-descending",
                  label: t("book.general.price_descending"),
              },
              {
                  value: "standard",
                  label: "Standard",
              },
          ]
        : [
              { value: "alphabetical", label: t("book.general.sort_alphabetical") },
              {
                  value: "standard",
                  label: "Standard",
              },
          ];

    useEffect(() => {
        const criterias = Object.values(reservationTypeCriterias)
            .flat()
            .reduce((acc, curr) => {
                acc[curr.code] = curr;
                return acc;
            }, {} as { [key: number]: TCriteriaItem });

        const filteredData = Object.values(accommodationResult?.groups || {})
            .map(group => {
                return newFilterGroupItems(group, activeFilters || {}, criterias) as TGroup;
            })
            .filter(group => !!group.items.length);

        setFilteredData(filteredData);
    }, [accommodationResult?.groups, activeFilters, reservationTypeCriterias]);

    useEffect(() => {
        const sortedData = [...filteredData];

        if (sort) {
            if (sort === "price-descending") {
                sortedData.sort((a, b) => getLowestPrice(b.items) - getLowestPrice(a.items));
            } else if (sort === "price-ascending") {
                sortedData.sort((a, b) => getLowestPrice(a.items) - getLowestPrice(b.items));
            } else if (sort === "alphabetical") {
                sortedData.sort((a, b) =>
                    a.groupitem?.title.localeCompare(b.groupitem?.title, clientData.lang, {
                        ignorePunctuation: true,
                    })
                );
            } else if (sort === "standard") {
                sortedData.sort((a, b) => a.groupitem?.sortorder - b.groupitem?.sortorder);
            }
        }
        setFilteredAndSortedData(sortedData);
    }, [clientData.lang, filteredData, sort]);

    useEffect(() => {
        if (user?.isOwner) {
            const ownerItems = Object.values(
                Object.values(accommodationResult?.groups || {})
                    .flatMap(group => group.items)
                    .filter(item => item.ownerlid)
                    .reduce((acc, cur) => {
                        // Only get one copy of if the same accommodation exists multiple times. E.g. when using bundles.
                        const key = `${cur.poollid}_${cur.unitlid}`;

                        if (!acc[key]) {
                            acc[key] = cur;
                        }

                        return acc;
                    }, {} as { [key: string]: TItem })
            );

            setOwnerItems(ownerItems);
        }
    }, [accommodationResult?.resultSetId, user?.isOwner]);

    const hasMatchingCriterias =
        Object.values(pick(reservationTypeCriterias, presentationTypeFiltersToShow)).flat().length > 0 ||
        useFilterSearch;
    const showFilter = Object.values(accommodationResult?.groups || {}).length > 0 && hasMatchingCriterias;

    return (
        <div className="u-pt-24">
            {redirect && (
                <Notification type={"error"}>
                    <p>{t("nohitsredirectmessage") || t("nohitsredirectedaccomodationmessage")}</p>
                </Notification>
            )}
            {user?.isOwner && (
                <>
                    <div className="row">
                        <div className="col-12 col-lg-6 u-d-flex u-flex-column u-mb-18">
                            <Heading type="h2" className="u-mb-18">
                                {t("book.accommodation.my_accommodations")}
                                {ownerItems.length || !isLoading ? ` (${ownerItems.length})` : ""}
                            </Heading>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-12">
                            {ownerItems.length === 0 && !isLoading ? (
                                <div>{t("book.search.owner_no_result")}</div>
                            ) : (
                                <AccommodationOwnerResultList
                                    reservationTypeId={reservationTypeid}
                                    items={ownerItems}
                                />
                            )}
                        </div>
                    </div>
                </>
            )}
            <div className="row">
                <div className="col-12 col-lg-6 u-d-flex u-flex-column u-mb-18">
                    <Heading type="h2" className="u-mb-18">
                        {t("book.accomodation.our_accomodations")}
                        {filteredAndSortedData.length || !isLoading ? ` (${filteredAndSortedData.length})` : ""}
                    </Heading>
                    {!isDesktopNewDesign && showFilter && (
                        <ToggleFilterButton
                            reservationTypeId={reservationTypeid}
                            showFilter={showMobileFilter}
                            onClick={setShowMobileFilter}
                        />
                    )}
                </div>
                <div
                    className={classNames("col-12 col-lg-6 u-d-flex u-gap-12 u-align-items-center u-mb-12", {
                        "u-justify-content-end": !isMobile,
                        "align-items-start": isMobile,
                        "flex-column-reverse": isMobile,
                    })}
                >
                    {isMapEnabled && hasCoordinates && (
                        <Button
                            type="tertiary"
                            onClick={toggleMap}
                            rightIcon={
                                isMapOpen ? (
                                    <ChevronUpIcon color={style.darkBlueColor} />
                                ) : (
                                    <ChevronDownIcon color={style.darkBlueColor} />
                                )
                            }
                            buttonClassName="edit-search-button"
                        >
                            {isMapOpen ? t("book.map.hide_map") : t("book.map.show_map")}
                        </Button>
                    )}
                    <div style={{ width: 200 }}>
                        <Select
                            options={sortOptions}
                            onSelectCallback={(option: TSelectOption) =>
                                dispatch(setSelectedSortAccommodation(option.value.toString()))
                            }
                            defaultValue={sort}
                            fullWidth
                            placeholder={t("book.general.sort")}
                            useFirstAsDefault={false}
                            type="standard"
                        />
                    </div>
                </div>
            </div>
            {isMapEnabled && isMapOpen && hasCoordinates && (
                <AccommodationMap
                    accommodationResultGroups={Object.values(accommodationResult?.groups)}
                    reservationTypeId={reservationTypeid}
                    isLoading={isLoading}
                />
            )}
            <div className="row">
                {(showFilter || (isLoading && hasMatchingCriterias)) && (
                    <div className="col-12 col-xl-4 accommodation-filter-wrapper">
                        <AccommodationFilter
                            key={accommodationResult?.resultSetId || ""}
                            reservationTypeId={reservationTypeid}
                            showOnMobile={showMobileFilter}
                            onMobileClose={() => setShowMobileFilter(false)}
                            showFilterSearch={useFilterSearch ?? true}
                            unfilteredSearchResult={Object.values(accommodationResult?.groups || {})}
                            filteredSearchResult={filteredAndSortedData}
                            isLoading={isLoading}
                        />
                        <div className="accommodation-filter__blurry-box"></div>
                    </div>
                )}
                {!!filteredAndSortedData.length || isLoading ? (
                    <>
                        <div
                            className={classNames("col-12", {
                                "col-xl-8": showFilter || (isLoading && hasMatchingCriterias),
                            })}
                        >
                            <AccommodationResultList
                                reservationTypeId={reservationTypeid}
                                reservationTypeResult={filteredAndSortedData}
                                reservationTypeCriterias={reservationTypeCriterias}
                                promoCode={accommodationResult?.search?.promoCode}
                                isLoading={isLoading}
                                filterIsPresent={showFilter || (isLoading && hasMatchingCriterias)}
                            />
                        </div>
                        {showFilter && (
                            <div className="col-12">
                                <AccommodationResultList
                                    reservationTypeId={reservationTypeid}
                                    reservationTypeResult={filteredAndSortedData}
                                    reservationTypeCriterias={reservationTypeCriterias}
                                    promoCode={accommodationResult?.search?.promoCode}
                                    isLoading={isLoading}
                                    belowFilter
                                    filterIsPresent={showFilter}
                                />
                            </div>
                        )}
                    </>
                ) : accommodationResult?.hitsMatchingFilter === "0" ||
                  !filteredAndSortedData.length ||
                  !accommodationResult?.groups ? (
                    <div className={classNames("col-12 u-pt-30", { "col-xl-8": showFilter })}>
                        <NoSearchResult />
                    </div>
                ) : (
                    accommodationResult?.error && (
                        <div className="u-pt-24">
                            <ErrorMessage
                                headingText={t("book.general.error")}
                                primaryButtonText={t("book.general.reload_page")}
                                primaryButtonOnClick={resetLastSearchedTimestampsAndReloadPage}
                            />
                        </div>
                    )
                )}
            </div>
        </div>
    );
};
