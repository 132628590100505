import { Button, Heading } from "@r360/library";
import classNames from "classnames";
import React from "react";
import { isAccommodation as isAccommodationFunc } from "../../../../BusinessUtils";
import { RootState } from "../../../..";
import * as Constants from "../../../../Constants";
import { getPackageProductTitle } from "../../../../Helper";
import useAppSelector from "../../../../hooks/useAppSelector";
import useTranslate from "../../../../hooks/useTranslate";
import { TCartItem } from "../../../../store/types";
import "./CartItemChangeAccommodation.scss";
import { useNavigate } from "react-router-dom";

type TCartItemChangeAccommodationComponent = {
    item: TCartItem;
};

export const CartItemChangeAccommodation = ({ item }: TCartItemChangeAccommodationComponent) => {
    const t = useTranslate(true);
    const navigate = useNavigate();
    const packageProductTitle = getPackageProductTitle(item);
    const isAccommodation = isAccommodationFunc(item);
    const { isDesktop } = useAppSelector((state: RootState) => state.window);

    const rightContentClassName = classNames(
        "cart-item-header-desktop-right-content",
        !isAccommodation && "cart-item-header-desktop-right-content--incrementer"
    );

    const getExtendedTitle = () => {
        switch (item.kind) {
            case Constants.productTypeNames.SKIPASS:
            case Constants.productTypeNames.RENTAL:
                return `${item.groupTitle} - ${packageProductTitle}`;
            default:
                return item.title;
        }
    };

    const handleChangeExtrasItem = (item: TCartItem) => {
        navigate(`/product-extras/${item.type}/${item.parentpool}/${item.poollid}/${item.unitlid}/${item.id}`);
    };

    return (
        <>
            <div className="cart-item-wrapper">
                <div className="cart-item">
                    {isDesktop ? (
                        <>
                            <div className="cart-item-header-desktop">
                                <div className="cart-item-header-desktop-left-content">
                                    <div className="cart-item-header-desktop-title">{getExtendedTitle()}</div>
                                </div>
                                <div className={rightContentClassName}>
                                    <Button
                                        buttonClassName="cart-item-header-desktop-link"
                                        type="tertiary"
                                        onClick={() => handleChangeExtrasItem(item)}
                                    >
                                        {t("book.cart.accommodation.change_extras")}
                                    </Button>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="cart-item-header-wrapper">
                                <div className="cart-item-left">
                                    <div className="cart-item-header">
                                        <Heading type="h5" className="cart-item-header-title">
                                            {getExtendedTitle()}
                                        </Heading>
                                    </div>
                                    <div className="cart-item-bottom">
                                        <div>
                                            <Button
                                                buttonClassName="cart-item-header-link__right"
                                                type="tertiary"
                                                onClick={() => handleChangeExtrasItem(item)}
                                            >
                                                {t("book.cart.accommodation.change_extras")}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};
