import { EMapType, Map } from "@r360/library";
import maxBy from "lodash/maxBy";
import minBy from "lodash/minBy";
import React from "react";
import { RootState } from "../..";
import { getLowestPriceAccommodation } from "../../BusinessUtils";
import { formatPrice } from "../../Helper";
import useAppSelector from "../../hooks/useAppSelector";
import useTranslate from "../../hooks/useTranslate";
import { TGroup, TGroupItem, TItem, TReservationTypeLid } from "../../store/types";
import { AccommodationResultCardTiny } from "../AccommodationResultCardTiny/AccommodationResultCardTiny";
import "./AccommodationMap.scss";
import { AccommodationMapSkeleton } from "./AccommodationMapSkeleton";

type TAccommodationMap = {
    accommodationResultGroups: TGroup[];
    reservationTypeId: TReservationTypeLid;
    isLoading?: boolean;
    isAccommodationAreaPage?: boolean;
};

const PopUpComponent = ({
    accommodations,
    groupItem,
    reservationTypeId,
    currency,
    searchWithPrice = false,
    isAccommodationAreaPage = false,
    multipleAccommodationsCard = false,
    capacity,
}: {
    accommodations: TItem[];
    groupItem: TGroupItem;
    reservationTypeId: TReservationTypeLid;
    currency: string;
    searchWithPrice?: boolean;
    isAccommodationAreaPage?: boolean;
    multipleAccommodationsCard?: boolean;
    capacity: string | number;
}) => {
    const accommodation = accommodations[0];

    let description = accommodation.weblong;

    if (multipleAccommodationsCard) {
        if (isAccommodationAreaPage) {
            description = `OBS! Flera boenden (${accommodations.length} st) har samma position. Vänligen se listningen nedan.`;
        } else {
            description = groupItem.weblong;
        }
    }

    return (
        <div className="accommodation-map__popup">
            <div key={accommodation.unitlid}>
                <AccommodationResultCardTiny
                    groupPoolName={multipleAccommodationsCard ? "" : groupItem.title}
                    title={multipleAccommodationsCard ? groupItem.title : accommodation.title}
                    capacity={capacity}
                    price={accommodation.price}
                    ordinaryprice={accommodation.ordinaryprice}
                    showPrice={!!accommodation?.pricecode}
                    description={description}
                    images={
                        multipleAccommodationsCard ? groupItem.images.slice(0, 6) : accommodation.images.slice(0, 6)
                    }
                    grouppoollid={accommodation.grouppoollid}
                    poollid={accommodation.poollid}
                    unitlid={accommodation.unitlid}
                    reservationTypeId={reservationTypeId}
                    currency={currency}
                    searchWithPrice={searchWithPrice}
                    multipleAccommodationsCard={multipleAccommodationsCard}
                    isAccommodationAreaPage={isAccommodationAreaPage}
                />
            </div>
        </div>
    );
};

export const AccommodationMap = ({
    accommodationResultGroups,
    reservationTypeId,
    isLoading = false,
    isAccommodationAreaPage = false,
}: TAccommodationMap) => {
    const currency = useAppSelector((state: RootState) => state.clientData?.options?.general?.currency);
    const t = useTranslate();

    const markers = accommodationResultGroups
        .map(group =>
            Object.values(
                (group.items || [])
                    .filter(item => typeof item.latitude === "number" && typeof item.longitude === "number")
                    //Filter out units on pools that doesn't have bookonunit
                    .filter(item => {
                        if (
                            item.unitlid &&
                            group?.items.find(poolitem => poolitem.poollid === item.parentpool)?.bookonunit
                        ) {
                            return false;
                        }
                        return true;
                    })
                    .reduce((result: { [key: string]: TItem[] }, item) => {
                        const coordinateKey = item.longitude + "-" + item.latitude;

                        //Only render unit if no other unit with the same pool and coordinate exist
                        if (result[coordinateKey]) {
                            const resultCoordinateHasPool = result[coordinateKey].some(
                                resultItem => item.poollid === resultItem.poollid
                            );
                            if (!resultCoordinateHasPool) {
                                result[coordinateKey] = result[coordinateKey].concat(item);
                            }
                        } else {
                            result[coordinateKey] = [item];
                        }

                        return result;
                    }, {})
            ).map(accommodations => {
                const multipleAccommodationsCard = accommodations.length > 1;
                const capacity = multipleAccommodationsCard
                    ? `${minBy(accommodations, "capac")?.capac || 0}-${maxBy(accommodations, "capac")?.capac || 0}`
                    : accommodations[0].capac || 0;
                const { price } = getLowestPriceAccommodation(accommodations);
                const searchWithPrice = !!accommodations[0]?.pricecode;

                return {
                    lat: Number(accommodations[0].latitude),
                    lng: Number(accommodations[0].longitude),
                    popupContent: (
                        <PopUpComponent
                            accommodations={accommodations}
                            groupItem={group.groupitem}
                            reservationTypeId={reservationTypeId}
                            currency={currency}
                            multipleAccommodationsCard={multipleAccommodationsCard}
                            isAccommodationAreaPage={isAccommodationAreaPage}
                            capacity={capacity}
                            searchWithPrice={searchWithPrice}
                        />
                    ),
                    markerText: searchWithPrice
                        ? multipleAccommodationsCard
                            ? `fr. ${formatPrice(price, currency)}`
                            : `${formatPrice(price, currency)}`
                        : t("book.map.people", [capacity]),
                };
            })
        )
        .flat();

    return markers.length > 0 ? (
        <>
            {isLoading ? (
                <AccommodationMapSkeleton />
            ) : (
                <div className="accommodation-map">
                    <Map markers={markers} mapType={EMapType.DEFAULT} />
                </div>
            )}
        </>
    ) : null;
};
