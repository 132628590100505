import React from "react";
import { TCartItem, TGeneralAges } from "../../../../store/types";
import "./CartItemGuestAgeList.scss";
import useAppSelector from "../../../../hooks/useAppSelector";
import { agesSelector } from "../../../../selectors/Selectors";
import { useDispatch, useSelector } from "react-redux";
import { getGeneralAgeCategoryPrices } from "../../../../BusinessUtilsTypescript";
import useTranslate from "../../../../hooks/useTranslate";
import { CartItemGuestAgeListItem } from "./CartItemGuestAgeListItem";
import { removeProductGuestCategory, setProductGuestCategoryCount } from "../../../../store/actions/checkoutCart";

type TCartItemGuestAgeListComponent = {
    item: TCartItem;
    onNoGuestsLeft: () => void;
};
export const CartItemGuestAgeList = ({ item, onNoGuestsLeft }: TCartItemGuestAgeListComponent) => {
    const t = useTranslate();
    const dispatch = useDispatch();
    const currency = useAppSelector(state => state.clientData?.options?.general?.currency);
    const generalAges: TGeneralAges = useSelector(agesSelector);

    const reachedMaxSeats = item.info.guests.totalGuests >= item.available;

    /* Create a list e.g 
    [
        {
            id: string
            title: string
            price: number
            quantity: number
        }
    ]
    */
    const guestAgeItems = Object.values(generalAges[item.type])
        .filter(data => {
            return data.key in item.info.guests;
        })
        .map(data => {
            const prices = getGeneralAgeCategoryPrices(item, generalAges);
            const quantity = item.info.guests[data.key];
            let price = prices.get(data.key);

            if (typeof price === "undefined") {
                price = 0;
            }

            return {
                id: data.key,
                title: `${data.min}-${data.max} ${t("book.general.years")}`,
                price: price,
                quantity: quantity,
            };
        });

    return (
        <div className="cart-item-guest-age-list">
            {guestAgeItems.map(guestAgeItem => {
                return (
                    <div className="cart-item-guest-age-list--row" key={guestAgeItem.id}>
                        <CartItemGuestAgeListItem
                            id={guestAgeItem.id}
                            title={guestAgeItem.title}
                            quantity={guestAgeItem.quantity}
                            maxQuantity={reachedMaxSeats ? guestAgeItem.quantity : item.available}
                            price={guestAgeItem.price}
                            value={guestAgeItem.quantity}
                            currency={currency}
                            onChange={(id, value) => {
                                dispatch(setProductGuestCategoryCount(item, id, value));
                            }}
                            onDelete={id => {
                                dispatch(removeProductGuestCategory(item, id));
                            }}
                            totalGuests={item.info.guests.totalGuests}
                            onNoGuestsLeft={onNoGuestsLeft}
                            productTitle={item.title}
                        />
                    </div>
                );
            })}
        </div>
    );
};
