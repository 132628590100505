import moment from "moment-timezone";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { isActivityWithDynamicPrice, isBooked } from "../../BusinessUtils";
import { formatPrice, getGuestNameAndAge } from "../../Helper";
import CancelProductButton from "../CancelProductButton/CancelProductButton";
import { CalendarIcon, GuestIcon } from "../UI/R360Icons";

const ActivityWithDynamicPriceList = ({
    texts,
    products,
    currency,
    booking,
    createdFromReservation,
    setRenderedProduct,
}) => {
    const renderPrice = product => {
        if (isBooked(product)) {
            return formatPrice(product.price, currency);
        } else if (product.pricematrix?.[Object.values(product.guests).length] >= 0) {
            return formatPrice(product.pricematrix[Object.values(product.guests).length], currency);
        }

        return "";
    };

    return (
        <div className="activityWithDynamicPriceList">
            {Object.values(products)
                .filter(isActivityWithDynamicPrice)
                .map(product => {
                    setRenderedProduct?.(product.id);

                    return (
                        <div className="box" key={product.activitylid}>
                            {createdFromReservation && !isBooked(product) && (
                                <Row>
                                    <Col>
                                        <h6 className="h6">{texts["checkoutsummary.new_in_change_reservation"]}</h6>
                                    </Col>
                                </Row>
                            )}

                            <Row className="mb-4">
                                <Col>
                                    <h2 className="box-heading">
                                        {product?.kinddesc} - {product?.title}
                                    </h2>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <CalendarIcon size={19} color="#333333" iconClass="float-start me-3" />{" "}
                                    <h6>{texts["checkoutsummary.occations"]}</h6>
                                </Col>
                            </Row>

                            {product.occations.map((occation, index) => (
                                <Row key={index}>
                                    <Col>
                                        {`${moment(occation.date).format("ddd D MMM")} ${occation.fromtime} - ${
                                            occation.totime
                                        }`}
                                    </Col>
                                </Row>
                            ))}

                            <Row className="mt-4">
                                <Col>
                                    <GuestIcon size={19} color="#333333" iconClass="float-start me-3" />{" "}
                                    <h6>{texts["checkoutsummary.participants"]}</h6>
                                </Col>
                            </Row>
                            <Row className="align-items-end">
                                <Col md="8">
                                    {Object.values(product.guests).map(guest => (
                                        <div key={guest.guestlid}>
                                            {getGuestNameAndAge(guest, texts?.generalyearshort)}
                                        </div>
                                    ))}
                                </Col>
                                <Col md="4">
                                    <Row>
                                        <Col
                                            md={booking ? 11 : 12}
                                            style={{
                                                fontSize: "1.25rem",
                                                fontWeight: 500,
                                                textAlign: "right",
                                            }}
                                        >
                                            {" "}
                                            {renderPrice(product)}
                                        </Col>
                                        {booking && (
                                            <Col md={1} className="text-end">
                                                <CancelProductButton
                                                    resvid={booking.resvid}
                                                    detlid={product.detlid}
                                                    productTitle={product.title}
                                                    confirmationTitle={
                                                        texts["bookingview.guests.cancel_product_confirmation.title"]
                                                    }
                                                    confirmationDescription={
                                                        texts[
                                                            "bookingview.guests.cancel_product_confirmation.description"
                                                        ]
                                                    }
                                                    allowCancellation={booking.allowcancellation}
                                                />
                                            </Col>
                                        )}
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    );
                })}
        </div>
    );
};

export default ActivityWithDynamicPriceList;
