import { useState } from "react";
import { validateMobilePhoneNumber } from "../ValidationUtils";

/**
 * A custom hook used to validate phone number fields phoneprefix and phone in a form.
 *
 * @param {boolean} isRequired
 * @param {boolean} initialPrefixValid
 * @param {boolean} initialPhoneNumberValid
 * @returns {object}
 */
const useFormPhoneNumberValidation = (
    isRequired = false,
    initialPrefixValid = false,
    initialPhoneNumberValid = false,
    onTouchedPrefix,
    onTouchedPhone
) => {
    const [phonePrefixValid, setPhonePrefixValid] = useState(initialPrefixValid);
    const [phoneValid, setPhoneValid] = useState(initialPhoneNumberValid);

    /**
     * Validate mobile phone number field using custom validation instead of YUP which behaves strange when combining two fields
     *
     * @param {string} phonePrefix
     * @param {string} phoneNumber
     * @returns {boolean}
     */
    const validatePhone = (phonePrefix, phoneNumber) => {
        if (isRequired && !phoneNumber) {
            // Mandatory for primary guest
            setPhoneValid(false);
            return false;
        } else if (!phoneNumber) {
            // Optional for regular guests
            setPhoneValid(true);
            return true;
        }

        if (!phonePrefix) {
            if (onTouchedPrefix && typeof onTouchedPrefix === "function") {
                onTouchedPrefix();
            }

            setPhonePrefixValid(false);
            setPhoneValid(false);
            return false;
        }

        const valid = validateMobilePhoneNumber(phonePrefix, phoneNumber);
        setPhoneValid(valid);
        return valid;
    };

    /**
     * Validate mobile phone prefix field using custom validation instead of YUP which behaves strange when combining two fields
     *
     * @param {string} phonePrefix
     * @returns {boolean}
     */
    const validatePhonePrefix = (phonePrefix, phoneNumber) => {
        if (isRequired && !phonePrefix) {
            setPhonePrefixValid(false);
            return false;
        } else if (!phonePrefix && phoneNumber) {
            setPhonePrefixValid(false);
            return false;
        }

        setPhonePrefixValid(true);

        if (onTouchedPhone && typeof onTouchedPhone === "function") {
            onTouchedPhone();
        }

        // Phone number depends on phoneprefix
        if (phoneNumber) {
            const valid = validateMobilePhoneNumber(phonePrefix, phoneNumber);
            setPhoneValid(valid);
            //! This has been added on checkout remake
            return valid;
        }

        return true;
    };

    return {
        phonePrefixValid,
        phoneValid,
        validatePhonePrefix,
        validatePhone,
    };
};

export default useFormPhoneNumberValidation;
