/*
    Usage:
    import "YupExtensions";
*/
import * as yup from "yup";
import { validateMobilePhoneNumber } from "./ValidationUtils";

/**
 * Validate phone number by prefix
 *
 * Empty value returns true because all YUP fields are optional by default().
 * If this method returns false for empty values, then it will automatically require a value to be set and validated.
 * Source: https://github.com/jquense/yup/issues/1212
 *
 * Usage:
 * yup.string().phoneNumberByPrefix("45").required()
 * yup.string().phoneNumberByPrefix("46").required()
 * yup.string().phoneNumberByPrefix("47").required()
 *
 * @param {string} prefix e.g 45, 46, 47
 * @returns {boolean}
 */
function phoneNumberByPrefix(prefix) {
    return this.test("phone-number-by-prefix", function (value) {
        // Optional value by default
        if (!value) {
            return true;
        }

        return validateMobilePhoneNumber(prefix, value);
    });
}

yup.addMethod(yup.string, "phoneNumberByPrefix", phoneNumberByPrefix);
