import React, { useCallback } from "react";
import AccommodationResultItemOld from "./AccommodationResultItemOld/AccommodationResultItemOld";

const AccommodationResultList = props => {
    const getUniqueAccommodationsCount = items => {
        const count = new Set([...items.map(item => `${item.poollid}_${item.unitlid}`)]).size;

        return count || items.length;
    };

    const getLowestPrice = items => {
        const prices = items.map(item => item.price);
        const lowestPrice = Math.min(...prices); // Math.min(1,2,3,4) --> 1
        return lowestPrice;
    };

    const displayableGroups = Object.values(props.reservationTypeResult).filter(group => !!group.groupitem);

    /**
     * Only show price if there are atleast one item with a price code.
     */
    const showPrice = useCallback(group => {
        return group.items?.some(item => !!item?.pricecode);
    }, []);

    return (
        <div className="row">
            {displayableGroups.map((group, index) => {
                // Replace groupitem price with lowest price of items within each group
                const price = getLowestPrice(group.items);
                const itemCount = getUniqueAccommodationsCount(group.items);
                return (
                    <AccommodationResultItemOld
                        key={group.id}
                        title={group.groupitem.title}
                        thumbnail={group.groupitem.thumbnail?.url}
                        price={price}
                        showPrice={showPrice(group)}
                        itemCount={itemCount}
                        data={group}
                        type={props.reservationType}
                        promoCode={props.promoCode}
                        index={index}
                    />
                );
            })}
        </div>
    );
};

export default AccommodationResultList;
