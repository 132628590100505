import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { sendSignal } from "../../signals";
import { partPaymentType } from "../../Constants";
import { isBooked } from "../../BusinessUtils";
import useQueryString from "../../hooks/QueryString/useQueryString";
import { createPurchaseSignal } from "../../signals/purchaseSignal";
import useTranslate from "../../hooks/useTranslate";
import _ from "lodash";
import { formatPrice, isIframe, sendMessage } from "../../Helper";
import { TAccount, TReservation } from "../../store/types";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import { optionsSelector } from "../../selectors/Selectors";
import { Button, ChevronRightIcon, Heading, Radio, Notification } from "@r360/library";
import PageContainer from "../../components/PageContainer";
import { BookingSummarySkeleton } from "../../components/BookingSummary/BookingSummarySkeleton";
import AxiosClient from "../../API/AxiosClient";
import * as types from "../../store/actions/types";

const trackPurchase = (
    booking: TReservation | undefined,
    onTrackedProducts: (arg0: (number | undefined)[]) => void
) => {
    if (!booking || !booking.reservation) {
        return;
    }

    if (booking.reservation.cancelled) {
        return;
    }

    if (booking.reservationrules.totamounttopay <= 0) {
        return;
    }

    const products = Object.values(booking.reservation.products).filter(isBooked);

    if (!products) {
        return;
    }

    // Only send signal if any booked product has not been tracked before

    // const allowSignal = bookedDetlids.some(detlid => {
    //     // If detlid has not been tracked before it's ok to send the signal
    //     return !trackedProducts.includes(detlid);
    // });

    // Prevent sending duplicate signals
    // setTrackedProducts(union(trackedProducts, bookedDetlids));

    // Send the signal after a short while to allow the tracked products to be stored in state
    setTimeout(() => {
        const purchaseSignal = createPurchaseSignal(booking);
        sendSignal(purchaseSignal);

        if (typeof onTrackedProducts === "function") {
            const bookedDetlids = products.map(product => product.detlid);
            onTrackedProducts(bookedDetlids);
        }

        console.log("Signaling: Track with signal", purchaseSignal);
    }, 500);
};

const CheckoutPayPage = () => {
    const t = useTranslate();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { resvid, payerlid } = useParams();
    const [verificationHash] = useQueryString("verificationHash");
    const account: TAccount = useAppSelector(state => state.account);
    const userToken = account.token;
    const isAgent = !!account.user?.isAgent;
    const currency = useAppSelector(optionsSelector).general?.currency;
    let preferredclientpayment = useAppSelector(optionsSelector).payment.preferredclientpayment;
    const { isMobile } = useAppSelector(state => state.window);

    const [loading, setLoading] = useState(false);
    const [paymentSelection, setPaymentSelection] = useState(partPaymentType.FINALPAYAMOUNT);
    const [trackedPurchase, setTrackedPurchase] = useState(false);
    const [hasRedirectedToPayPage, setHasRedirectedToPayPage] = useState(false);
    const [hasClickedContinueToPayment, setHasClickedContinueToPayment] = useState(false);
    const [booking, setBooking] = useState<TReservation>();

    if (isAgent) {
        preferredclientpayment = "invoice";
    }

    const toPay = booking?.reservationrules?.totamounttopay;
    const isValidBooking = booking?.resvid;
    const payingWithInvoice = preferredclientpayment === "invoice";

    const showPartPaymentChoices =
        !payingWithInvoice &&
        booking &&
        booking.reservationrules?.totamounttopay > 0 &&
        booking.reservationrules?.allowprepayment &&
        booking.reservationrules.minpayamount > 0 &&
        booking.reservationrules.minpayamount < booking.reservationrules.maxpayamount;

    // -- Callbacks and effects
    const handleTrackPurchase = useCallback(() => {
        setTrackedPurchase(true);
    }, []);

    const loadReservation = useCallback((userToken: string, resvid: string, verificationHash: string) => {
        if (userToken || verificationHash) {
            setLoading(true);

            let config = {},
                url = `/reservations/${resvid}`;

            if (userToken) {
                config = {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                };
            } else {
                // Unauthenticated users require a hash to fetch the reservation in a secure way
                url += `?hash=${verificationHash}`;
            }

            AxiosClient.get(url, config).then(response => {
                setBooking(response.data.payload);
            });
        }
    }, []);

    const handleContinueToPaymentClick = () => {
        setHasClickedContinueToPayment(true);
        continueToPayment();
    };

    const continueToPayment = useCallback(() => {
        dispatch({ type: types.DESTORY_STATE });

        // Allow for state to be cleared by delaying redirect
        _.delay(() => {
            if (booking && payerlid) {
                redirectToFinalPayPage(preferredclientpayment, booking.resvid, payerlid, paymentSelection);
            }
        }, 1000);
    }, [dispatch, preferredclientpayment, booking, payerlid, paymentSelection]);

    const redirectToFinalPayPage = (
        preferredclientpayment: string,
        resvid: string,
        payerlid: string,
        paymentSelection: string
    ) => {
        let payUrl = `${window.__BASE_URL__}/pay/${preferredclientpayment}/${resvid}/0/${payerlid}?source=r360online.booking`;

        if (paymentSelection) {
            payUrl += `&paytype=${paymentSelection}`;
        }

        setHasRedirectedToPayPage(true);

        if (isIframe()) {
            // This confirmation url does not need a codebase or base url prepended because it's handled by the iframe integration script
            // because this redirect will escape the iframe to allow for Strong Customer Authentication (SCA) at the payment providers
            // which doesn't work any good inside iframes.
            //
            // The iframe script will prepend the parent website's origin and pathname to "remember" where to redirect the customer after
            // a completed payment.
            const confirmationUrl = `pay/confirmation/${resvid}?lang=${window.__LANG__}`;

            sendMessage({
                event: "redirectToPayPage",
                payUrl: payUrl,
                confirmationUrl: confirmationUrl,
            });
        } else {
            // When we are not in an iframe we redirect the customer directly to the pay page
            let confirmationUrl;

            if (window.location.hostname === "localhost") {
                confirmationUrl = `${window.location.origin}/pay/confirmation/${resvid}?lang=${window.__LANG__}`;
            } else {
                confirmationUrl = `/${window.__CODEBASE__}client/pay/confirmation/${resvid}?lang=${window.__LANG__}`;
            }

            const integrationsUrl = btoa(confirmationUrl);
            const fullUrl = `${payUrl}&integrationsurl=${integrationsUrl})`;

            window.location.href = fullUrl;
        }
    };

    const continueToConfirmationPage = () => {
        let confirmationUrl = `/pay/confirmation/${resvid}`;

        confirmationUrl = payingWithInvoice ? `${confirmationUrl}?invoice=true` : confirmationUrl;

        navigate(confirmationUrl);
    };

    useEffect(() => {
        loadReservation(userToken, resvid ?? "", verificationHash);
    }, [dispatch, loadReservation, userToken, resvid, verificationHash]);

    // Continue to confirmation if paying with invoice or if there´s nothing more to pay
    useEffect(() => {
        if ((payingWithInvoice || toPay === 0) && isValidBooking && loading) {
            continueToConfirmationPage();
        }
        //eslint-disable-next-line
    }, [booking, isValidBooking, loading, preferredclientpayment, toPay, payingWithInvoice]);

    useEffect(() => {
        if (!isAgent && !trackedPurchase) {
            trackPurchase(booking, handleTrackPurchase);
        }
    }, [handleTrackPurchase, isAgent, booking, trackedPurchase]);

    // Check if we shall redirect directly to pay page
    useEffect(() => {
        if (!payingWithInvoice && !isAgent && !hasRedirectedToPayPage && trackedPurchase && !showPartPaymentChoices) {
            dispatch({ type: types.DESTORY_STATE });

            // Allow for state to be cleared by delaying redirect
            _.delay(() => {
                continueToPayment();
            }, 1000);
        }
    }, [
        continueToPayment,
        isAgent,
        trackedPurchase,
        showPartPaymentChoices,
        hasRedirectedToPayPage,
        payingWithInvoice,
        dispatch,
    ]);

    const renderPartPaymentChoices = () => {
        return (
            <div className="u-pt-42">
                <Heading type="h2" styleAs="h3" className="u-text-center u-mb-18">
                    {t("payment.part_payment.title")}
                </Heading>
                <div className="u-d-flex u-justify-content-center u-mb-42">
                    <div className="u-d-flex u-flex-column u-gap-12 u-mb-24">
                        <Radio
                            buttonStyle
                            name="partpayment"
                            id="partPaymentFinalPayAmount"
                            value={partPaymentType.FINALPAYAMOUNT}
                            label={t("payment.part_payment.pay_all")}
                            checked={paymentSelection === partPaymentType.FINALPAYAMOUNT}
                            onChange={({ target: { value } }) => {
                                setPaymentSelection(value);
                            }}
                        />
                        <div>
                            <div className="u-mb-6">
                                <Radio
                                    buttonStyle
                                    name="partpayment"
                                    id="partPaymentPrepayAmount"
                                    value={partPaymentType.PREPAYAMOUNT}
                                    label={t("payment.part_payment.pay_registration_fee") + "*"}
                                    checked={paymentSelection === partPaymentType.PREPAYAMOUNT}
                                    onChange={({ target: { value } }) => {
                                        setPaymentSelection(value);
                                    }}
                                />
                            </div>
                            <small>
                                {"* "}
                                {t("payment.part_payment.remaining_amount").replace(
                                    "%amount%",
                                    formatPrice(
                                        (booking?.reservationrules.maxpayamount ?? 0) -
                                            (booking?.reservationrules.minpayamount ?? 0),
                                        currency
                                    )
                                )}
                            </small>
                        </div>
                    </div>
                </div>
                <div className="u-d-flex u-justify-content-center">
                    <Button
                        rightIcon={<ChevronRightIcon />}
                        onClick={handleContinueToPaymentClick}
                        fullWidth={isMobile}
                        loading={hasClickedContinueToPayment}
                    >
                        {t("book.checkout.proceed_to_payment")}
                    </Button>
                </div>
            </div>
        );
    };

    const renderNothingMoreToPay = () => {
        return (
            <Notification type="success">
                <p className="u-mb-0">
                    {t("book.pay.nothingmoretopay").replace("#bookingnumber#", booking?.resvid ?? "")}
                </p>
            </Notification>
        );
    };

    return (
        <PageContainer>
            {loading && <BookingSummarySkeleton />}
            {!payingWithInvoice &&
                isValidBooking &&
                toPay &&
                toPay > 0 &&
                showPartPaymentChoices &&
                renderPartPaymentChoices()}

            {!payingWithInvoice && isValidBooking && toPay === 0 && renderNothingMoreToPay()}
        </PageContainer>
    );
};

export default CheckoutPayPage;
