import React from "react";
import PackageResultItem from "./PackageResultItem/PackageResultItem";

const PackageResultList = props => {
    const getLowestPrice = items => {
        const prices = items.map(interval => interval.price).filter(price => price !== null);
        if (prices.length === 0) return null;
        const lowestPrice = Math.min(...prices);
        return lowestPrice;
    };

    const packages = props.reservationTypeResult ? Object.values(props.reservationTypeResult) : [];

    return (
        <div className="row">
            {packages.map(product => {
                // Replace groupitem price with lowest price of items within each group
                const price = getLowestPrice(product.items);
                return (
                    <PackageResultItem key={product.id} price={price} product={product} type={props.reservationType} />
                );
            })}
        </div>
    );
};

export default PackageResultList;
