import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch, useStore } from "react-redux";
import { syncStateToBrowserStorage } from "../../configureStore";
import { signOutAgent } from "../../store/actions/clientData";
import * as types from "../../store/actions/types";
import { Button } from "../UI";
import "./AgentBar.scss";
import { signOutUser } from "../../store/actions/account";

const AgentBar = ({ currentSite, agent }) => {
    const store = useStore();
    const dispatch = useDispatch();

    const handleChangeSite = siteName => {
        // Find seleted site among agent sites.
        const site = agent.sites.find(site => site.site_name === siteName);

        if (site) {
            window.location.href = site.href;
        }
    };

    const logoutAgent = () => {
        if (agent.logoutUrl) {
            // Sign out agent from clientData.
            dispatch(signOutAgent());

            // Sign out logged in user.
            dispatch(signOutUser());

            // Triger sync of Redux state to browser storage explicitly to avoid racing condition with throttle-time.
            syncStateToBrowserStorage(store)();

            // Redirect to agent logout url that will clear the agent session.
            window.location.href = agent.logoutUrl;
        }
    };

    return (
        <div className="agent-bar">
            <div className="container">
                <Row>
                    <Col className="py-3 d-flex align-items-center justify-content-center">
                        <div className="me-5">Agent: {agent.name}</div>

                        <Form.Group className="d-flex align-items-center">
                            Site:
                            <Form.Control
                                className="ms-2"
                                required
                                as="select"
                                name="site"
                                autoComplete="off"
                                defaultValue={currentSite}
                                onChange={event => handleChangeSite(event.target.value)}
                            >
                                {agent.sites.map(site => {
                                    return (
                                        <option key={site.site_id} value={site.site_name}>
                                            {site.site_title}
                                        </option>
                                    );
                                })}
                            </Form.Control>
                        </Form.Group>

                        <Button className="ms-4" onClick={logoutAgent}>
                            Log out
                        </Button>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default AgentBar;
