import isEqual from "lodash/isEqual";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import AxiosClient from "./API/AxiosClient";
import {
    isActivityWithDynamicPrice,
    isLetting,
    isSkipass,
    getTotalPriceByGuestCategories,
    hasAnyGuestCategoryPrice,
    hasSamePriceInAllGuestCategories,
    isAccommodation,
    isBooked,
    isPackage,
} from "./BusinessUtils";
import configureStore from "./configureStore";

/**
 * Build an absolute URL prepended with a base path
 * @param {string} path
 * @returns {string}
 */
export const absurl = path => {
    return "/client" + path;
};

export const sendMessage = msg => {
    // Make sure you are sending a string, and to stringify JSON
    window.parent.postMessage(JSON.stringify(msg), "*");
};

export const roundBy = (number, decimals = 2) => {
    return parseFloat(number.toFixed(decimals));
};

export const arrayGetFirst = array =>
    // First first non undefined item because index 0 could have been deleted
    array.find(x => typeof x !== "undefined");

export const hasDatesChanged = (startDate, endDate, compareStartDate, compareEndDate) => {
    if (startDate instanceof moment) {
        startDate = startDate.format("YYYY-MM-DD");
    }

    if (endDate instanceof moment) {
        endDate = endDate.format("YYYY-MM-DD");
    }

    if (compareStartDate instanceof moment) {
        compareStartDate = compareStartDate.format("YYYY-MM-DD");
    }

    if (compareEndDate instanceof moment) {
        compareEndDate = compareEndDate.format("YYYY-MM-DD");
    }

    // console.log("Comparing", startDate, compareStartDate, "and", endDate, compareEndDate);

    // string comparison
    return startDate !== compareStartDate || endDate !== compareEndDate;
};

export const isIframe = () => {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
};

export const _window = win => {
    const data = { event: "window", win: JSON.stringify(win) };
    window.parent.postMessage(JSON.stringify(data), "*");
};

export const _ = win => {
    // console.log(JSON.stringify(win));
    const data = { event: "eval", eval: encodeURI(win.toString()) }; // FIXME: Remove support for eval()!! Dangerous!
    // console.log(data);
    window.parent.postMessage(JSON.stringify(data), "*");
};

export const _state = state => {
    const data = { event: "state", state: state };
    // console.log("did send to helper");
    window.parent.postMessage(JSON.stringify(data), "*");
};

export const viewPortDimensions = () => {
    const html = window.document.documentElement;
    const body = window.document.body;
    const dialog = window.document.querySelector("div.modal-dialog");
    const minHeight = 1000;

    const height =
        Math.max(
            body.scrollHeight,
            body.offsetHeight,
            html.offsetHeight,
            minHeight,
            dialog?.scrollHeight || 0,
            dialog?.offsetHeight || 0
        ) + 20; // Add 20 extra px to cover margins for dialog.
    const width = window.innerWidth;

    return { height, width };
};

// takes a {} object and returns a FormData object
export const objectToFormData = (obj, form, namespace) => {
    var fd = form || new FormData();
    var formKey;

    for (var property in obj) {
        // TODO: Why are we looping an object and then checking if the looped item exists in the object?
        // eslint-disable-next-line
        if (obj.hasOwnProperty(property)) {
            if (namespace) {
                formKey = namespace + "[" + property + "]";
            } else {
                formKey = property;
            }
            if (typeof obj[property] === "object" && !(obj[property] instanceof File)) {
                objectToFormData(obj[property], fd, formKey);
            } else {
                fd.append(formKey, obj[property]);
            }
        }
    }

    return fd;
};

/**
 * Get booking date periods
 * (based on weeks)
 * @param {Object} range — object with booking range parameters
 * @returns array with booking period objects
 *
 */
export const getBookingDatePeriods = range => {
    const { startdate, enddate, rules } = range;

    /** Get time diff in weeks */
    function getWeeksDiff(start, end) {
        const exactNumberOfWeeks = moment(start).diff(moment(end), "weeks", true);
        return Math.ceil(Math.abs(exactNumberOfWeeks));
    }

    /** Get booking start and end date */
    function getBookingPeriod(currentWeekDiff, startDay, numberOfDays) {
        let weekPeriod = {},
            startdayInCurrentWeek = null,
            endOfCurrentBookingRange = null;

        // Get start and end of current booking period
        if (currentWeekDiff === 0) {
            startdayInCurrentWeek = moment(startdate).isoWeekday(startDay);
            endOfCurrentBookingRange = moment(startdayInCurrentWeek).add(numberOfDays, "days");
        } else {
            startdayInCurrentWeek = moment(startdate).add(currentWeekDiff, "weeks").isoWeekday(startDay);
            endOfCurrentBookingRange = moment(startdayInCurrentWeek).add(numberOfDays, "days");
        }

        // Return null if period start date falls outside of range scope
        if (moment(enddate).isBefore(startdayInCurrentWeek)) {
            return;
        }

        // Check that period start date does not fall out of range scope.
        // If so, return range start date.
        if (moment(startdate).isSameOrBefore(startdayInCurrentWeek)) {
            weekPeriod.start = startdayInCurrentWeek.format("YYYY-MM-DD");
        } else {
            weekPeriod.start = moment(startdate).format("YYYY-MM-DD");
        }

        // Check that period end date does not fall out of range scope.
        // if so, return range end date.
        if (moment(enddate).isSameOrAfter(endOfCurrentBookingRange)) {
            weekPeriod.end = endOfCurrentBookingRange.format("YYYY-MM-DD");
        } else {
            weekPeriod.end = moment(enddate).format("YYYY-MM-DD");
        }

        return weekPeriod;
    }

    /** Execute function... */
    const weeksDiff = getWeeksDiff(startdate, enddate);
    let result = [];

    // Loop through rules and add week info to result array
    rules.forEach(rule => {
        for (let x = 0; x <= weeksDiff; x++) {
            const weekInfo = getBookingPeriod(x, rule[0], rule[1]);

            if (weekInfo) {
                result.push(weekInfo);
            }
        }
    });

    //console.log(result);
    return result;
};

/**
 * Get booking range periods
 * @param {Object} range — object with booking range parameters
 * @returns array with booking period objects
 */
export const getBookingRangePeriods = range => {
    // Variables
    const { startdate, enddate, rules } = range;
    const rangeStart = moment(startdate),
        rangeEnd = moment(enddate),
        dateFormat = "YYYY-MM-DD";
    let currentDate = rangeStart,
        bookingRangePeriods = [];

    /*
     * Get period from current date
     */
    function getPeriodFromCurrentDate(date, periodStartDay, daysInPeriod) {
        let now = date,
            period = {};

        // Set next period start date
        if (now.isoWeekday() !== periodStartDay) {
            while (now.isoWeekday() !== periodStartDay) {
                now = now.add(1, "days");
            }
        }
        period.start = now.format(dateFormat);

        // Set end date of period...
        if (now.add(daysInPeriod, "days").isSameOrBefore(rangeEnd, "day")) {
            period.end = now.add(daysInPeriod, "days").format(dateFormat);

            // ...or set last possible date if period exceed range scope
        } else {
            let lastPossibleDate = moment(period.start);
            while (lastPossibleDate.isBefore(rangeEnd, "day")) {
                lastPossibleDate = lastPossibleDate.add(1, "days");
            }
            period.end = lastPossibleDate.format(dateFormat);
        }

        // Set current date to period end date and return period object
        currentDate = moment(period.end);
        return period;
    }

    /*
     * Map through rules array
     */
    rules.forEach(rule => {
        // While current date is before range end...
        while (currentDate.isBefore(rangeEnd, "day")) {
            // ...get next period from current date.
            const period = getPeriodFromCurrentDate(currentDate, rule[0], rule[1]);
            bookingRangePeriods.push(period);
        }
    });

    //console.log(bookingRangePeriods);
};

// Simple way to flatten nested objects to one object
// Example: { date: { startdate: "", endDate: "" } } --> { startDate: " ", endDate: " " }
export const flattenObject = obj => {
    const flattened = {};

    Object.keys(obj).forEach(key => {
        if (typeof obj[key] === "object" && obj[key] !== null) {
            Object.assign(flattened, flattenObject(obj[key]));
        } else {
            flattened[key] = obj[key];
        }
    });

    return flattened;
};

export const formatNumber = x => {
    if (!x) {
        return 0;
    }
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

/*
  Use to check if age is between two dates
*/
export const isGuestRightAge = (guest, product) => {
    const dateString = `${guest[1]?.year}-${guest[1]?.month}-${guest[1]?.day}`;
    const age = moment(product.arrdate, "YYYY-MM-DD").diff(moment(dateString, "YYYY-MM-DD"), "years");
    return age >= product.minage && age <= product.maxage;
};

/*
  Check if the guest is the right age for a product.
*/
export const isGuestRightAgeForProduct = (guest, product) => {
    const dateFormat = "YYYY-MM-DD";
    const guestBirthday = moment(guest.birthday, dateFormat);

    if (!guestBirthday.isValid()) {
        return false;
    }

    const age = moment(product.arrdate, dateFormat).diff(guestBirthday, "years");

    return age >= product.minage && age <= product.maxage;
};

/*
  Check if the guest is the right age at specific arrival date.
*/
export const isGuestRightAgeAtArrival = (guest, arrivalDate, minAge, maxAge) => {
    const dateFormat = "YYYY-MM-DD";
    const guestBirthday = moment(guest.birthday, dateFormat);

    if (!guestBirthday.isValid()) {
        return false;
    }

    const age = moment(arrivalDate, dateFormat).diff(guestBirthday, "years");

    return age >= minAge && age <= maxAge;
};

/*
  Use to return age string
*/
export const getAge = (guest, product) => {
    const dateString = `${guest[1]?.year}-${guest[1]?.month}-${guest[1]?.day}`;
    const age = moment(product.arrdate, "YYYY-MM-DD").diff(moment(dateString, "YYYY-MM-DD"), "years");

    return age.toString();
};

export const displayGuestText = (min, max, text) => {
    return `${min} - ${max} ${text}`;
};

/*
  Use to create an array of guest ages
*/
export const getAgeFromGuestData = (data, dataObject) => {
    let updatedAges = [];
    if (dataObject) {
        for (let index = 0; index < dataObject[data.key]; index++) {
            updatedAges.push(data.value);
        }
        return updatedAges;
    }
};

export const getAgeFromDate = date => {
    const dateMoment = moment(date);

    if (dateMoment.isValid()) {
        return moment().diff(dateMoment, "years").toString();
    }

    return "";
};

/*
  Use to verify guest has image
*/
export const verifyGuestImage = guestlid => {
    const url = "/users/guestimage?guestlid" + guestlid;
    return AxiosClient.get(url);
};

export const verifyWTPNumber = WTPnumber => {
    const url = "/validate/skipass_keycard";
    const body = { keycardNo: WTPnumber };
    return AxiosClient.post(url, body);
};

/*
  Get age category
*/
export const getAgeCategories = (minage, maxage, categories) => {
    if (minage >= 0 && maxage && categories) {
        const ageItems = categories?.filter(
            category =>
                (parseInt(minage) >= category.min && parseInt(minage) <= category.max) ||
                (parseInt(minage) <= category.min && parseInt(maxage) >= category.max) ||
                (parseInt(maxage) >= category.min && parseInt(maxage) <= category.max)
        );
        return ageItems;
    }
    return [];
};

/*
  Use to create date to moment object with format
*/
export const createMomentFromDate = (date, format) => {
    if (format) {
        return moment(date).format(format);
    } else {
        return moment(date);
    }
};

/*
  Use to check if Object{} is empty
*/
export const isEmpty = obj => {
    for (var prop in obj) {
        // eslint-disable-next-line
        if (obj.hasOwnProperty(prop)) return false;
    }
    return true;
};

/*
  Use to convert Object{} to Array[]
*/
export const convertToArray = obj => {
    const array = Object.keys(obj).map(key => obj[key]);
    return array;
};

/*
  PropTypes
*/
createMomentFromDate.propTypes = {
    date: PropTypes.string.isRequired,
    format: PropTypes.string,
};

isEmpty.propTypes = {
    obj: PropTypes.object.isRequired,
};

convertToArray.propTypes = {
    obj: PropTypes.object.isRequired,
};

/**
 * Check if a provided value only contains numbers without decimals.
 *
 * @param {string | number} value The provided value to check if it contains only numbers.
 *
 * @returns A boolean indicating if the value only contains numbers without decimals. Will return
 *          true for an empty string.
 */
export const isNumberWithoutDecimals = value => value.toString().match(/^[0-9]*$/) !== null;

/**
 * Get the name and age of a guest, combined to a string.
 *
 * @param {Object} guest The guest to get name and age for.
 * @param {string | null} shortYearText The text representing the year suffix.
 * @param {Object} product The product to get the arrival date from.
 *
 * @returns The name and age of a guest.
 */
export const getGuestNameAndAge = (guest, shortYearText, product) => {
    const ageString = getGuestAge(guest, shortYearText, product?.arrdate);

    return `${guest.firstname || ""} ${guest.lastname || ""}${ageString !== "" ? ", " + ageString : ""}`;
};

/**
 * Get age of a guest with a year suffix.
 * If a arrival date is provided, the age is calculated against the arrival date.
 *
 * @param {Object} guest The guest to get age for.
 * @param {string | null} shortYearText The text representing the year suffix.
 * @param {string} arrivalDate The the arrival date.
 *
 * @returns The age of a guest.
 */
export const getGuestAge = (guest, shortYearText, arrivalDate) => {
    const birthdayMoment = moment(guest.birthday);
    const productArrivalOrNowMoment = arrivalDate ? moment(arrivalDate) : moment();

    // R360 uses 1899-12-30 as default birthdate. Ignore it from the presentation.
    if (birthdayMoment.isValid() && productArrivalOrNowMoment.isValid() && guest.birthday !== "1899-12-30") {
        const age = productArrivalOrNowMoment.diff(birthdayMoment, "years").toString();
        return `${age} ${shortYearText}`;
    }

    return "";
};

/**
 * Scroll to a provided ID in DOM if not already in view.
 *
 * @param {string} containerId The ID to find in the DOM and scroll to.
 */
export const scrollToContainerIfNeeded = (containerId, alwaysScroll = false) => {
    const element = document.querySelector(`#${containerId}`);

    if (!element) {
        return;
    }

    if (element.getBoundingClientRect().top < 0 || alwaysScroll) {
        element.scrollIntoView({
            behavior: "smooth",
            block: "start",
        });
    }
};

/**
 * Get the package product title for a product.
 *
 * TODO: Make a component for the purpose of displaying the product name with formatting. E.g pricetext need a separate design/format.
 *
 * @param {Object} product
 *
 * @returns The package product title.
 */
export const getPackageProductTitle = (product, groupTitle = false) => {
    // No title found for product, return empty string.
    if (!product.title) {
        return "";
    }

    if (groupTitle && product.groupTitle && product.groupTitle !== product.title) {
        return `${product.groupTitle} - ${product.title}`;
    }

    // Product has a price text, show both title and price text
    if (product.title && product.pricetext && product.title !== product.pricetext) {
        return `${product.title}, ${product.pricetext}`;
    }

    // Either desc3 isn't set or it has the same value as the title. Only return title.
    return product.title;
};

export const getProductTitle = product => {
    if (!product?.title) {
        return "";
    }

    if (product.groupTitle && product.groupTitle !== product.title) {
        return `${product.groupTitle} - ${product.title}`;
    }

    return product.title;
};

/**
 * Check if two arrays containing numbers are equal after that they have been sorted.
 *
 * @param {Array} arr1
 * @param {Array} arr2
 *
 * @returns A boolean where true indicates the arrays are equal.
 */
export const arraysAreEqualAfterSort = (arr1, arr2) => {
    return isEqual([...arr1].sort(), [...arr2].sort());
};

export const getGuestObjectForReservationTypeFromArray = (guestArray, reservationTypeId, generalAges) => {
    if (!generalAges) {
        return {};
    }

    const ages = generalAges[reservationTypeId];

    if (!(ages || []).length) {
        return {};
    }

    const guests = {};

    ages.forEach(age => {
        const guestCount = (guestArray || []).filter(guestAge => guestAge >= age.min && guestAge <= age.max).length;

        guests[age.key] = guestCount || 0;
    });

    return guests;
};

/**
 * Get size in bytes for the provided string.
 *
 * @param {string} value
 *
 * @returns The size in bytes.
 */
export const getSizeInBytesForString = (value = "") => {
    // Using TextEncoder provided the fastest away to get size in bytes.
    // Compared against:
    // - new Blob([jsonString]).size; (1-2x time)
    // - Buffer.byteLength(jsonString, "utf8"); (3x time)

    return new TextEncoder().encode(value).length;
};

/**
 * Check if a date is the same or between two provided dates.
 *
 * For performance, a native way is used to determine if a date is same or between two dates.
 * This way is 10 times faster than using moment.
 *
 * @param {string} checkDate
 * @param {string} startDate
 * @param {string} endDate
 *
 * @returns A boolean indiciating if the check date is same or between the two dates provided.
 */
export const fastDateIsSameOrBetween = (checkDate, startDate, endDate) => {
    const dateTime = new Date(checkDate).getTime();
    const startDateTime = new Date(startDate).getTime();
    const endDateTime = new Date(endDate).getTime();
    const isSameOrBetween = dateTime >= startDateTime && dateTime <= endDateTime;

    return isSameOrBetween;
};

/**
 * Check if a date is the same or before a provided date.
 *
 * For performance, a native way is used to determine if a date is same or before.
 *
 * @param {string} checkDate
 * @param {string} limitDate
 *
 * @returns A boolean indiciating if the check date is same or before the provided date.
 */
export const fastDateIsSameOrBefore = (checkDate, limitDate) => {
    const dateTime = new Date(checkDate).getTime();
    const limitDateTime = new Date(limitDate).getTime();
    const isSameOrBefore = dateTime <= limitDateTime;

    return isSameOrBefore;
};

/**
 * Check if any of the products provided are of type accommodation.
 *
 * @param {Object} products
 *
 * @returns True if accommodation found, otherwise false.
 */
export const hasAnyAccommodation = products => Object.values(products).some(isAccommodation);

/**
 * Check if any of the products provided are of type package.
 *
 * @param {Object} products
 *
 * @returns True if package found, otherwise false.
 */
export const hasAnyPackage = products => Object.values(products).some(isPackage);

/**
 * Check if any of the products provided are of type accommodation and is booked.
 *
 * @param {Object} products
 *
 * @returns True if booked accommodation found, otherwise false.
 */
export const hasAnyBookedAccommodation = products =>
    Object.values(products).some(product => isAccommodation(product) && isBooked(product) && !product.parent);

/**
 * Check if any of the products provided are of type package and is booked.
 *
 * @param {Object} products
 *
 * @returns True if booked package found, otherwise false.
 */
export const hasAnyBookedPackage = products =>
    Object.values(products).some(product => isPackage(product) && isBooked(product));

/**
 * Check if any of the products provided are of type activity with dynamic price and is booked.
 *
 * @param {Object} products
 *
 * @returns True if booked activity with dynmaic price found, otherwise false.
 */
export const hasAnyBookedActivitiesWithDynamicPrice = products =>
    Object.values(products).some(product => isActivityWithDynamicPrice(product) && isBooked(product));

/**
 * Prevent default click event and stops propagation.
 *
 * @param {Object} event
 */
export const preventClick = event => {
    event.stopPropagation();
    event.preventDefault();
};

/**
 * Parse phone number by adding prefix and removing leading zeroes in the phone number.
 *
 * @param {number} phonePrefix
 * @param {number} phoneNumber
 *
 * @returns The parsed phone number.
 */
export const parsePhoneNumber = (phonePrefix, phoneNumber) => {
    if (phonePrefix) {
        return `+${phonePrefix} ${phoneNumber?.toString().replace(/^0+/, "")}`;
    }

    return phoneNumber;
};

/**
 * Formats a price with currency.
 *
 * @param {number|float} price
 * @param {string} currency
 *
 * @returns Formatted price with curreny.
 */
export const formatPrice = (price = 0, currency = "") => `${formatNumber(Math.round(price))} ${currency}`.trim();

/**
 * Return correct date format based on country of business
 *
 * @param {string} countryOfBusiness
 *
 * @returns The correct date format
 */
export const getDateFormat = countryOfBusiness => {
    if (countryOfBusiness === "NO") {
        return "DD-MM-YY";
    }
    return "YYYY-MM-DD";
};

/**
 *
 * @param {TItem} item
 * @param {TGeneralAges?} generalAges
 * @returns {number}
 */
export const getCartItemPrice = (item, generalAges) => {
    if (!isBooked(item)) {
        if (item.pricematrix) {
            return item.pricematrix[item.count];
        } else if (hasAnyGuestCategoryPrice(item) && !hasSamePriceInAllGuestCategories(item)) {
            return getTotalPriceByGuestCategories(item, item.info.guests, generalAges);
        } else {
            return Math.round(item.price) * (item.count || 1);
        }
    } else if (item.additional) {
        return Math.round(item.additional.price);
    }

    return 0;
};

/**
 *
 * @param {TItem} item
 * @param {string} currency
 * @param {TGeneralAges?} generalAges
 * @returns {string}
 */
export const getFancyCartItemPrice = (item, currency, generalAges) => {
    const itemPrice = getCartItemPrice(item, generalAges);
    if (itemPrice !== null) return `${formatPrice(itemPrice, currency)}`;
    return "";
};

export const getFancyAccomodationPrice = (item, extrasPrice, currency) => {
    const itemPrice = getCartItemPrice(item);
    if (itemPrice !== null) return `${formatPrice(itemPrice + extrasPrice, currency)}`;
    return "";
};

export const capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * Check if any of the products provided are of type skipass.
 *
 * @param {Object} products
 *
 * @returns True if skipass found, otherwise false.
 */
export const hasAnySkipass = products => Object.values(products).some(isSkipass);

/**
 * Check if any of the products provided are of type letting.
 *
 * @param {Object} products
 *
 * @returns True if letting found, otherwise false.
 */
export const hasAnyLetting = products => Object.values(products).some(isLetting);

/**
 * Generates a URL link to the product extras page for a given product.
 *
 * @param {Object} product
 * @returns {string} The URL link to the product extras page.
 */
export const getLinktoProductExtras = product =>
    `/product-extras/${product.type}/${product.parentpool}/${product.poollid}/${product.unitlid}/${product.id}`;
