export const loadState = () => {
    try {
        if (!sessionStorage.getItem("state")) {
            return undefined;
        }

        return JSON.parse(sessionStorage.getItem("state"));
    } catch (error) {
        console.log(error);
        return undefined;
    }
};

export const saveState = state => {
    try {
        sessionStorage.setItem("state", JSON.stringify(state));
    } catch (error) {
        console.log(error);
    }
};
