import React, { useContext } from "react";
import { Accordion, Card, useAccordionButton } from "react-bootstrap";
import AccordionContext from "react-bootstrap/AccordionContext";
import { ArrowDownIcon } from "../../UI/R360Icons";
import "./Accordion.scss";

// Accordion component
const AccordionComponent = props => {
    const { header, className, children, icon, expanded, id } = props;

    const ContextAwareToggle = ({ children, eventKey, callback }) => {
        const currentEventKey = useContext(AccordionContext);
        const toggleAccordion = useAccordionButton(eventKey, () => callback && callback(eventKey));
        const isCurrentEventKey = currentEventKey === eventKey;
        const arrowClass = isCurrentEventKey
            ? "accordion__toggle-icon accordion__toggle-icon--toggled"
            : "accordion__toggle-icon";

        return (
            <Card.Header onClick={toggleAccordion} aria-expanded={isCurrentEventKey ? true : false} id={id}>
                {children}
                <ArrowDownIcon size={12} color="#333333" iconClass={arrowClass} />
            </Card.Header>
        );
    };

    return (
        <Accordion className={className || ""} defaultActiveKey={expanded && props.eventKey}>
            <Card>
                <ContextAwareToggle eventKey={props.eventKey}>
                    {icon && icon}
                    {header || "Accordion"}
                </ContextAwareToggle>
                <Accordion.Collapse eventKey={props.eventKey}>
                    <Card.Body>{children}</Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    );
};

export default AccordionComponent;
