import React from "react";
import { Link } from "react-router-dom";

import "./Button.scss";
import { NextIcon } from "../R360Icons";

// Button component
class Button extends React.Component {
    // Render button HTML based on props
    renderButton() {
        const {
            children,
            text,
            icon,
            type = "button",
            onClick,
            href,
            leftIcon,
            to,
            className,
            hasPopup = false,
            disabled = false,
            expanded = undefined,
            ariaLabel = "",
            ariaPressed = false,
            hidden = false,
        } = this.props;
        const buttonText = text || null;
        const buttonIcon = icon ? <NextIcon size={16} color="#FFFFFF" iconClass="button__icon" /> : "";
        const buttonClasses = className ? `btn button ${className}` : "btn button";

        // Button
        if (onClick) {
            return (
                <button
                    hidden={hidden}
                    type={type}
                    onClick={onClick}
                    className={buttonClasses}
                    aria-haspopup={hasPopup}
                    aria-expanded={expanded}
                    aria-label={ariaLabel}
                    aria-pressed={ariaPressed}
                    disabled={disabled}
                >
                    {children || buttonText} {buttonIcon}
                </button>
            );

            // Link
        } else if (href) {
            return (
                <a href={href} className={buttonClasses} aria-label={ariaLabel}>
                    {children || buttonText} {buttonIcon}
                </a>
            );

            // React link
        } else if (to) {
            return (
                <Link to={to} className={buttonClasses} aria-label={ariaLabel}>
                    {children || buttonText} {buttonIcon}
                </Link>
            );
        } else if (leftIcon) {
            return (
                <button type={type} className={buttonClasses} disabled={disabled} aria-label={ariaLabel}>
                    {icon}
                    {children || buttonText}
                </button>
            );
            // Placeholder button
        } else {
            return (
                <button type={type} className={buttonClasses} disabled={disabled} aria-label={ariaLabel}>
                    {children || buttonText} {buttonIcon}
                </button>
            );
        }
    }

    render() {
        return this.renderButton();
    }
}

export default Button;
