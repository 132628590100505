import * as React from "react";
import defautIconStats from "../Icon.types";
import Theme from "../../Theme";

const Menu = ({ color = Theme.palette.primary, size = defautIconStats.size }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            //   viewBox="0 0 511.563 511.563"
            style={{ height: size, width: size }}
            //   width="512pt"
            //   height="512pt"
            //   className={iconClass}

            width="512"
            height="512"
            x="0"
            y="0"
            viewBox="0 0 64 64"
            //   style="enable-background:new 0 0 512 512"
            //   class=""
        >
            {/* <g transform="matrix(-1,0,0,1,64,0)">
        <g xmlns="http://www.w3.org/2000/svg">
          <g>
            <g>
              <path
                d="m59.5 18.5h-55c-1.1 0-2-.9-2-2s.9-2 2-2h55c1.1 0 2 .9 2 2s-.9 2-2 2z"
                fill={color}
                data-original={color}
              />
            </g>
          </g>
        </g>
        <g xmlns="http://www.w3.org/2000/svg">
          <g>
            <g>
              <path
                d="m31.5 34.5h-27c-1.1 0-2-.9-2-2s.9-2 2-2h27c1.1 0 2 .9 2 2s-.9 2-2 2z"
                fill={color}
                data-original={color}
              />
            </g>
          </g>
        </g>
        <g xmlns="http://www.w3.org/2000/svg">
          <g>
            <g>
              <path
                d="m31.5 50.5h-27c-1.1 0-2-.9-2-2s.9-2 2-2h27c1.1 0 2 .9 2 2s-.9 2-2 2z"
                fill={color}
                data-original={color}
              />
            </g>
          </g>
        </g>
      </g> */}

            <g transform="matrix(-1.0199979899999998,0,0,1.0199979899999998,64.11948543999995,-0.11948543999994854)">
                <g xmlns="http://www.w3.org/2000/svg">
                    <g>
                        <g>
                            <path
                                d="m59.5 18.5h-55c-1.1 0-2-.9-2-2s.9-2 2-2h55c1.1 0 2 .9 2 2s-.9 2-2 2z"
                                fill={color}
                                data-original={color}
                                // style=""
                                // class=""
                            />
                        </g>
                    </g>
                </g>
                <g xmlns="http://www.w3.org/2000/svg">
                    <g>
                        <g>
                            <path
                                d="m31.5 34.5h-27c-1.1 0-2-.9-2-2s.9-2 2-2h27c1.1 0 2 .9 2 2s-.9 2-2 2z"
                                fill={color}
                                data-original={color}
                                // style=""
                                // class=""
                            />
                        </g>
                    </g>
                </g>
                <g xmlns="http://www.w3.org/2000/svg">
                    <g>
                        <g>
                            <path
                                d="m31.5 50.5h-27c-1.1 0-2-.9-2-2s.9-2 2-2h27c1.1 0 2 .9 2 2s-.9 2-2 2z"
                                fill={color}
                                data-original={color}
                                // style=""
                                // class=""
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default Menu;
