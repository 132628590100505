import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { getNewOrModifiedCartProducts } from "../../../BusinessUtils";
import { AccommodationsAreToFarApart } from "../../../hooks";
import { checkoutUrlSelector, textsSelector } from "../../../Selectors";
import { setBookingError, setSignUpWrapper } from "../../../store/actions/window";
import { Button } from "../../UI";
import { CartIcon, CloseIcon } from "../../UI/R360Icons";
import OrderOverview from "../OrderOverview";
import "./OrderOverviewCart.scss";

// Order overview cart component
const OrderOverviewCart = props => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const account = useSelector(state => state.account);
    const isMobile = useSelector(state => state.window.isMobile);
    const loginSuccessful = useSelector(state => state.account.loginSuccessful);
    const showingLoginModal = useSelector(state => state.window.signUpWrapper.loginModal);
    const [showCart, setShowCart] = useState(showingLoginModal);
    const texts = useSelector(textsSelector);
    const wrapperRef = useRef(null);
    const wrapperClass = showCart
        ? "order-overview-cart__wrapper order-overview-cart__wrapper--visible"
        : "order-overview-cart__wrapper";
    const cartProducts = getNewOrModifiedCartProducts(useSelector(state => state.checkoutCart?.products));
    const accommodationLid = useSelector(
        state => state.clientData.reservationtypes.find(item => item.type === "ACCOMMODATION")?.lid
    );
    const accommodationsInCart = cartProducts?.find(item => parseInt(item.type) === parseInt(accommodationLid));
    const reservationTypes = useSelector(state => state.clientData.reservationtypes);
    const checkoutUrl = useSelector(checkoutUrlSelector);
    const isAccommodationsBookingError = AccommodationsAreToFarApart();

    // Close order-overview-cart with the 'Esc' key
    useEffect(() => {
        const callback = event => {
            if (event.key === "Escape") {
                setShowCart(false);
            }
        };

        document.addEventListener("keydown", callback, false);
        return () => document.removeEventListener("keydown", callback, false);
    }, []);

    // Close order-overview-cart on click outside of container
    useEffect(() => {
        const callback = event => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setShowCart(false);
            }
        };

        document.addEventListener("mousedown", callback);
        return () => document.removeEventListener("mousedown", callback);
    }, [wrapperRef]);

    // Handle button click
    const handleClick = () => {
        setShowCart(!showCart);
    };

    // Login or continue
    const loginOrContinue = () => {
        if (isAccommodationsBookingError) {
            handleClick();
            dispatch(setBookingError(true));
        } else {
            let address;

            if (accommodationsInCart && accommodationsInCart.length > 0) {
                // Avoid loop to same page
                if (location.pathname === "/reservation/extras") {
                    address = checkoutUrl;
                } else {
                    address = "/reservation/extras";
                }
            } else {
                address = checkoutUrl;
            }

            if (address !== "/fastcheckout" && !loginSuccessful) {
                handleClick();
                dispatch(setSignUpWrapper(true, true, false, address));
            } else {
                handleClick();
                navigate(address);
            }
        }
    };

    // Get number of items in cart
    const cartItemsCount = () => {
        let productCount = 0;
        if (cartProducts && cartProducts.length > 0) {
            cartProducts.forEach(item => {
                if (item.count) {
                    productCount = productCount + item.count;
                } else {
                    productCount++;
                }
            });
        }
        return productCount;
    };

    // Return
    return (
        <>
            <div className="order-overview-cart">
                <div className="order-overview-cart__button-container">
                    <button
                        type="button"
                        aria-label={texts?.cart || "Varukorg"}
                        aria-haspopup={true}
                        aria-expanded={false}
                        className="order-overview-cart__button"
                        onClick={handleClick}
                    >
                        <CartIcon size={25} color="#333333" className="order-overview-cart__button-icon" />
                        {/* <span>{texts?.cart || "Varukorg"}</span> */}
                    </button>

                    {cartProducts.length > 0 && (
                        <div className="order-overview-cart__product-count-container">
                            <span
                                className="order-overview-cart__product-count"
                                aria-label={`${cartItemsCount()} ${
                                    texts?.productsincart || texts?.productsinshoppingcart
                                }`}
                            >
                                {cartItemsCount()}
                            </span>
                        </div>
                    )}
                </div>
                <div className={wrapperClass} ref={wrapperRef} aria-hidden={!showCart}>
                    <button
                        type="button"
                        aria-label={texts?.closecart || texts?.hidethecart}
                        className="order-overview-cart__close-button"
                        onClick={handleClick}
                    >
                        <CloseIcon size={15} color="#000000" />
                    </button>

                    <div className="order-overview-cart__container">
                        {reservationTypes.length > 0 && (
                            <OrderOverview
                                products={cartProducts}
                                reservationTypes={reservationTypes}
                                isMobile={isMobile}
                                disableCartActions={props.disableCartActions}
                            />
                        )}
                        {cartProducts.length > 0 && !props.disableCartActions && (
                            <div className="order-overview-cart__booking-link-wrapper">
                                <Button
                                    className="button--full-width"
                                    onClick={loginOrContinue}
                                    // aria-label={`Fortsätt bokning, ${cartProducts.length} produkter i varukorgen`}
                                >
                                    {texts?.tocheckout}
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {account?.user && (
                <span className="m-0 fw-light order-overview-cart__helloText">
                    {texts?.generalhello || "Hej"} <Link to="/account">{account?.user?.name1}</Link>
                </span>
            )}
        </>
    );
};

export default OrderOverviewCart;
