import React from "react";
import "./Ticket.scss";
import { UserIcon } from "@r360/library";
import useTranslate from "../../hooks/useTranslate";

export enum TicketProductStatus {
    ACTIVATED = "A",
    BOOKED = "B",
    CANCELLED = "C",
    ERROR = "E",
}

export type TTicket = {
    guestName: string;
    orderNumber: string;
    productName: string;
    productStatus: string;
    startDate: string;
};

export const Ticket = ({ guestName, productName, productStatus, orderNumber }: TTicket) => {
    const t = useTranslate();
    const isActivated = productStatus === TicketProductStatus.ACTIVATED;
    const isBooked = productStatus === TicketProductStatus.BOOKED;
    const isError = productStatus === TicketProductStatus.ERROR;
    const isCancelled = productStatus === TicketProductStatus.CANCELLED;

    const renderQRCode = (orderNumber: string) => {
        return (
            <img
                className="qr-image"
                src={`https://api.qrserver.com/v1/create-qr-code/?size=300x300&data=${orderNumber}`}
                // src={`https://chart.googleapis.com/chart?cht=qr&chl=${orderNumber}&chs=300x300&chld=L|0`}
                alt={`QR code for order number ${orderNumber}`}
            />
        );
    };

    return (
        <div className="ticket">
            {guestName && (
                <h3 className="guestName">
                    <UserIcon size={18} className="icon" color="#0D3E60" /> {guestName}
                </h3>
            )}
            {orderNumber && <h6 className="orderNumber">{orderNumber}</h6>}
            {productName && <h6 className="productName">{productName}</h6>}

            {isBooked && <p>{t("book.mytickets.booked")}</p>}
            {isCancelled && <p>{t("book.mytickets.cancelled")}</p>}
            {isError && <p>{t("book.mytickets.error")}</p>}
            {isActivated && renderQRCode(orderNumber)}
        </div>
    );
};
