// Set available filters in Redux

import * as types from "../actions/types";

const reducer = (state = {}, action) => {
    switch (action.type) {
        case types.SET_AVAILABLE_CRITERIAS: {
            return {
                ...state,
                default: action.payload,
                error: false,
            };
        }
        case types.FETCH_AVAILABLE_CRITERIAS_FAILED: {
            return {
                ...state,
                error: action.error,
            };
        }

        default:
            return {
                ...state,
            };
    }
};

export default reducer;
