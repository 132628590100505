import {
    Button,
    CalendarCheckIcon,
    CommaList,
    Heading,
    LocationIcon,
    PhoneIcon,
    Tag,
    UserIcon,
    UserTwoIcon,
    style,
} from "@r360/library";
import React from "react";
import "./BookingSummaryTable.scss";
import classNames from "classnames";
import { capitalizeFirstLetter, formatPrice } from "../../../Helper";
import { RichText } from "../../UI";
import useTranslate from "../../../hooks/useTranslate";
import { useSelector } from "react-redux";
import { RootState } from "../../..";

type TBookingSummaryTable = {
    title: string;
    children: JSX.Element;
    productCount: number;
    hidden?: boolean;
    numberOfNewProducts?: number;
};

export const BookingSummaryTable = ({
    title,
    children,
    productCount,
    hidden = false,
    numberOfNewProducts = 0,
}: TBookingSummaryTable) => {
    const t = useTranslate();

    return (
        <section
            className={classNames("booking-summary-table", {
                "booking-summary-table--hidden": hidden,
            })}
        >
            <div className="u-d-flex u-gap-12 u-align-items-center u-mb-12 u-flex-wrap">
                <Heading type="h2" className="booking-summary-table__heading">
                    {`${title} (${productCount})`}
                </Heading>
                {numberOfNewProducts > 0 && (
                    <Tag
                        text={`${numberOfNewProducts} ${
                            numberOfNewProducts === 1
                                ? t("book.checkout.one_new_addition")
                                : t("book.checkout.multiple_new_additions")
                        }`}
                        theme="success-light"
                    />
                )}
            </div>
            <div className="booking-summary-table__rows">{children}</div>
        </section>
    );
};

export type TBookingSummaryRowAddition = {
    id?: number;
    title: string;
    quantity: number;
    price: number;
    showPrice: boolean;
    currency: string;
    canceled: boolean;
    actionText?: string;
    actionOnClick?: () => void;
    newOrChanged?: boolean;
    isProduct?: boolean;
};

export type TBookingSummaryRowGuests = {
    id?: number;
    name: string;
    additionalInfo?: JSX.Element | string;
};

export type TBookingSummaryRowDates = {
    arrDate: string;
    depDate: string;
};

export type TBookingSummaryRowPrice = {
    price: number;
    ordinaryPrice?: number;
    currency: string;
    isTotalPrice?: boolean;
    campaign?: boolean;
};

export type TBookingSummaryRowDetails = {
    description: JSX.Element | string;
    additionalInfo?: {
        [key: string]: string;
    };
};

type TBookingSummaryRow = {
    title?: string;
    additions?: TBookingSummaryRowAddition[];
    phone?: string;
    guests?: TBookingSummaryRowGuests[];
    location?: string;
    dates?: string[];
    price: TBookingSummaryRowPrice;
    isCancelled?: boolean;
    cancellationBtn?: JSX.Element;
    addExtrasBtn?: JSX.Element;
    details?: TBookingSummaryRowDetails;
    showDetails?: boolean;
    hidden?: boolean;
    isNewOrChangedProduct?: boolean;
    hasConnectedProducts?: boolean;
    isConnectedProduct?: boolean;
    onRenderAddition?: (addition: TBookingSummaryRowAddition) => void;
    quantity?: number;
};

export const BookingSummaryRow = ({
    title,
    additions,
    phone,
    guests,
    location,
    dates,
    price,
    isCancelled,
    cancellationBtn,
    addExtrasBtn,
    details,
    showDetails,
    hidden = false,
    isNewOrChangedProduct = false,
    hasConnectedProducts = false,
    isConnectedProduct = false,
    onRenderAddition,
    quantity,
}: TBookingSummaryRow) => {
    const t = useTranslate();
    const { isMobile, isTabletNewDesign } = useSelector((state: RootState) => state.window);
    const hasAdditionalInfo = details?.additionalInfo && Object.values(details?.additionalInfo).length > 0;
    const detailsExpanded = showDetails && (!!details?.description || hasAdditionalInfo) && !price.isTotalPrice;

    const renderGuestsIcon = () => {
        if (guests && guests.length > 1) {
            return (
                <div>
                    <UserTwoIcon color={style.brandBlueColor} />
                </div>
            );
        } else {
            return (
                <div>
                    <UserIcon color={style.brandBlueColor} />
                </div>
            );
        }
    };

    return (
        <article
            className={classNames("booking-summary-table__row", {
                "booking-summary-table__row--w-details": detailsExpanded && !hasConnectedProducts,
                "booking-summary-table__row--hidden": hidden,
                "booking-summary-table__row--new": isNewOrChangedProduct,
                "booking-summary-table__row--connected-product": isConnectedProduct,
                "booking-summary-table__row--total-price": price.isTotalPrice,
                "booking-summary-table__row--disabled": isCancelled,
            })}
        >
            <div className="row u-bs-gutters-6">
                <div className="col-12 col-lg-4 booking-summary-table__column">
                    {title && (
                        <div className="booking-summary-table__column-item booking-summary-table__column-item--title">
                            <Heading type="h2" styleAs="h6">
                                {title}
                            </Heading>
                            {quantity && <span>{`${t("book.general.quantity")}: ${quantity}`}</span>}
                            {!isCancelled &&
                                additions?.map((addition, i) => {
                                    onRenderAddition?.(addition);

                                    return (
                                        <div
                                            key={`${addition.title}-${i}`}
                                            className={classNames("booking-summary-table__extra", {
                                                "booking-summary-table__extra--canceled": addition.canceled,
                                                "booking-summary-table__extra--product": addition.isProduct,
                                                "booking-summary-table__extra--new-or-changed": addition.newOrChanged,
                                            })}
                                        >
                                            <span className="u-mr-6">
                                                <span className="u-fw-medium">{`${addition.isProduct ? "" : "+"} ${
                                                    addition.quantity
                                                }x `}</span>
                                                {`${addition.title} ${
                                                    addition.showPrice
                                                        ? `(${formatPrice(addition.price, addition.currency)})`
                                                        : ""
                                                }`}
                                            </span>
                                            {addition.actionText && addition.actionOnClick && (
                                                <Button
                                                    type="tertiary"
                                                    buttonSize={"small"}
                                                    onClick={addition.actionOnClick}
                                                >
                                                    {addition.actionText}
                                                </Button>
                                            )}
                                        </div>
                                    );
                                })}
                            {addExtrasBtn}
                        </div>
                    )}
                </div>

                <div className="col-12 col-lg-3 booking-summary-table__column ">
                    {phone && (
                        <div className="booking-summary-table__column-item booking-summary-table__column-item--phone">
                            <>
                                <PhoneIcon color={style.brandBlueColor} />
                                <div>
                                    <div>{phone}</div>
                                </div>
                            </>
                        </div>
                    )}
                    {guests && (
                        <div className="booking-summary-table__column-item booking-summary-table__column-item--guests">
                            {!!guests.length && renderGuestsIcon()}
                            <div>
                                {isMobile || isTabletNewDesign ? (
                                    <CommaList array={guests.map(guest => guest.name)} fullStop={false} />
                                ) : (
                                    guests?.map((guest, i) => {
                                        return (
                                            <React.Fragment key={`${guest}-${i}`}>
                                                <div>{guest.name}</div>
                                                {/* <div>{guest.additionalInfo}</div> */}
                                            </React.Fragment>
                                        );
                                    })
                                )}
                            </div>
                        </div>
                    )}
                </div>
                <div className="col-12 col-lg-3 booking-summary-table__column">
                    {location && (
                        <div className="booking-summary-table__column-item">
                            <>
                                <LocationIcon color={style.brandBlueColor} />
                                <div>
                                    <div>{capitalizeFirstLetter(location.toLowerCase())}</div>
                                </div>
                            </>
                        </div>
                    )}
                    {dates && (
                        <div className="booking-summary-table__column-item">
                            <>
                                <CalendarCheckIcon color={style.brandBlueColor} />
                                <div>
                                    {dates?.map(date => {
                                        return <div key={date}>{capitalizeFirstLetter(date)}</div>;
                                    })}
                                </div>
                            </>
                        </div>
                    )}
                </div>
                <div
                    className={classNames(
                        "col-12 col-lg-2 booking-summary-table__column booking-summary-table__column--price",
                        {
                            // "col-5": !price.isTotalPrice,
                        }
                    )}
                >
                    <div
                        className={classNames(
                            "booking-summary-table__column-item booking-summary-table__column-item--price",
                            { "booking-summary-table__column-item--price--total": price.isTotalPrice }
                        )}
                    >
                        {isCancelled ? (
                            <span className="u-color-alert">Avbokad</span>
                        ) : price.isTotalPrice ? (
                            <>
                                <span>{t("book.general.total")}: </span>
                                <span className="u-fw-regular">{formatPrice(price.price, price.currency)}</span>
                            </>
                        ) : (
                            <div className="u-d-flex u-flex-column u-align-items-end">
                                <div className={price.ordinaryPrice ? "u-color-bright-blue" : ""}>
                                    {formatPrice(price.price, price.currency)}
                                </div>
                                {price.ordinaryPrice && (
                                    <div className="booking-summary-table__ordinary-price">
                                        {formatPrice(price.ordinaryPrice, price.currency)}
                                    </div>
                                )}
                                {price.campaign && (
                                    <Tag text={t("book.general.campaign")} theme="yellow" className="u-mt-6" />
                                )}
                                {cancellationBtn}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {detailsExpanded && (
                <div className="row u-bs-gutters-6 booking-summary-table__details">
                    <div
                        className={classNames("col-12 col-lg-8 booking-summary-table__description", {
                            "booking-summary-table__description--w-additional-info": details?.additionalInfo,
                        })}
                    >
                        {details?.description}
                    </div>
                    {hasAdditionalInfo && (
                        <div className="col-12 col-lg-4 booking-summary-table__additional-details">
                            <div>
                                {details?.additionalInfo &&
                                    Object.entries(details?.additionalInfo).map(([key, value]) => {
                                        return (
                                            <div key={key}>
                                                <span className="u-fw-medium" key={key}>{`${key}: `}</span>
                                                <RichText content={value} />
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                    )}
                </div>
            )}
        </article>
    );
};
