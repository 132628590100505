import { sum } from "lodash";
import moment from "moment-timezone";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { optionsSelector } from "../../selectors/Selectors";
import Guests from "../GuestsOld/Guests";
import { Button, DropdownButton, InformationBlock } from "../UI";
import DatePicker from "../UI/DatePickerOld";
import "./ChangeSearch.scss";

const ChangeSearch = props => {
    const {
        texts,
        showController,
        showRange,
        reservationResultSearch,
        reservationTypeid,
        accommodationAvailability,
        numberOfMonths,
        availablePeriods,
        blockedPeriods,
    } = props;
    const navigate = useNavigate();
    const location = useLocation();

    const guestData = useSelector(state => state.clientData?.options?.general);
    const [date, setSelectedDate] = useState({ startDate: null, endDate: null });
    const [guests, setGuests] = useState({});

    useEffect(() => {
        // Only update the dates if the previous search has been made with prices and dates.
        if (
            reservationResultSearch?.checkPriceAndAvailability &&
            reservationResultSearch?.arrdate &&
            reservationResultSearch?.depdate
        ) {
            setSelectedDate({
                startDate: reservationResultSearch.arrdate,
                endDate: reservationResultSearch.depdate,
            });
        }
    }, [reservationResultSearch]);

    useEffect(() => {
        if (guestData?.ages) {
            const urlSearchParams = new URLSearchParams(location.search);
            const params = Object.fromEntries(urlSearchParams.entries());
            const obj = JSON.parse(guestData?.ages);
            const ages = obj[reservationTypeid];
            const guests = {};
            (ages || []).forEach(age => {
                const guestCountFromParams = params[age.key] && parseInt(params[age.key], 10);
                const guestCountFromStore = (reservationResultSearch?.guests || []).filter(
                    guestAge => guestAge >= age.min && guestAge <= age.max
                ).length;

                const guestCount = guestCountFromParams || guestCountFromStore || 0;
                guests[age.key] = age.minCount !== undefined && age.minCount > guestCount ? age.minCount : guestCount;
            });
            setGuests(guests);
        }
    }, [guestData, reservationTypeid, reservationResultSearch?.guests, location.search]);

    const handleDateChange = (startDate, endDate) => {
        if (moment(startDate).isValid() && moment(endDate).isValid()) {
            setSelectedDate({
                startDate: startDate,
                endDate: endDate,
            });
        }
    };

    /**
     * Check that guests and dates are provied before updating the URL.
     */
    const allowSearch = sum(Object.values(guests)) > 0 && date.startDate && date.endDate;

    const handleGuestChange = (count, data) => {
        setGuests({ ...guests, [data.key]: count });
    };

    const createSearchUrl = () => {
        let queryStringObject = {};
        queryStringObject.startDate = date.startDate;
        queryStringObject.endDate = date.endDate;
        Object.entries(guests).forEach(([key, value]) => {
            queryStringObject[key] = value;
        });

        return queryString.stringify(queryStringObject);
    };

    const renderGuestsDropdown = () => {
        const guestCount = sum(Object.values(guests));

        return (
            <div className="col changesearch__guests-container">
                <DropdownButton
                    alignRight
                    text={
                        guestCount === 0
                            ? texts?.generalguest
                            : guestCount <= 1
                            ? `${guestCount} ${texts?.generalguest}`
                            : `${guestCount} ${texts?.generalguests}`
                    }
                    className="changesearch__guests-dropdown"
                >
                    <Guests
                        texts={texts}
                        resvType={reservationTypeid}
                        filters={guests}
                        onChange={handleGuestChange}
                        onChangeDelay={0}
                    />
                    <InformationBlock className="w-100 pt-2">
                        {texts["search.guest_picker_description"]}
                    </InformationBlock>
                </DropdownButton>
            </div>
        );
    };

    const handleSearch = () => {
        const url = createSearchUrl();
        navigate(`${location.pathname}?${url}`, { replace: true });
    };

    const renderDatePicker = () => {
        return (
            <div className="col-auto changesearch__date-picker">
                <DatePicker
                    resvType={reservationTypeid}
                    startDate={
                        date.startDate && reservationResultSearch?.checkPriceAndAvailability
                            ? moment(date.startDate)
                            : null
                    }
                    endDate={
                        date.endDate && reservationResultSearch?.checkPriceAndAvailability ? moment(date.endDate) : null
                    }
                    changedDate={(start, end) => {
                        handleDateChange(start, end);
                    }}
                    showRange={showRange}
                    showController={showController}
                    numberOfMonths={numberOfMonths || 2}
                    availableDays={accommodationAvailability}
                    availablePeriods={availablePeriods}
                    blockedPeriods={blockedPeriods}
                />
            </div>
        );
    };

    const renderControllerView = () => {
        return (
            <div className="changesearch__controller">
                <form className="g-0 changesearch">
                    {renderDatePicker()}
                    <div className="changesearch__guests-and-search-container">
                        {renderGuestsDropdown()}
                        <div className="changesearch__search-button-container">
                            <Button
                                onClick={handleSearch}
                                text={texts?.generalsearch}
                                className="changesearch__search-button"
                                disabled={!allowSearch}
                            />
                        </div>
                    </div>
                </form>
            </div>
        );
    };

    const renderInputGroup = () => {
        return (
            <div className="changesearch__inputgroup">
                <div className="row g-0 changesearch">
                    {renderDatePicker()}
                    {renderGuestsDropdown()}
                    <div className="col-auto changesearch__search-button-container">
                        <Button
                            onClick={handleSearch}
                            text={texts?.generalsearch}
                            className="changesearch__search-button"
                            disabled={!allowSearch}
                        />
                    </div>
                </div>
            </div>
        );
    };

    return showController ? renderControllerView() : renderInputGroup();
};

export default ChangeSearch;
