import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TSectionStates, isSectionStateInputValid } from "../../pages/CheckoutConnectPage/CheckoutConnectPage";
import { TCartItem, TCartItemItem, TGeneralAge, TGuest, TReservationType } from "../../store/types";
import { v4 as uuidv4 } from "uuid";
import { updateProduct } from "../../store/actions/checkoutCart";
import { CheckoutConnectCard } from "./CheckoutConnectCard";
import {
    CheckoutConnectCardHeading,
    CheckoutConnectCardRemoveBtn,
    CheckoutConnectNoGuestsToConnect,
} from "./CheckoutConnectCard/CheckoutConnectCard";
import {
    capitalizeFirstLetter,
    getGuestNameAndAge,
    isGuestRightAgeAtArrival,
    isNumberWithoutDecimals,
} from "../../Helper";
import { showArrDepDates } from "../../BusinessUtils";
import { Button, Heading, Input, PhoneIcon, Select, TSelectOption, style } from "@r360/library";
import useTranslate from "../../hooks/useTranslate";
import { agesSelector, guestsOnTravelSelector, optionsSelector } from "../../selectors/Selectors";
import { CheckoutConnectComment } from ".";
import { PhoneCountryCodeSelect } from "../PhoneCountryCodeSelect/PhoneCountryCodeSelect";
import useFormPhoneNumberValidation from "../../hooks/useFormPhoneNumberValidation";
import classNames from "classnames";
import { isEqual } from "lodash";

type TCheckoutConnectActivities = {
    sectionStates: TSectionStates;
    removeProductFromCart: (arg0: TCartItem, arg1: TCartItemItem) => void;
    removeProductFromCartAndSummary: (arg0: TCartItem) => void;
    products: TCartItem[];
    type: TReservationType;
};

type TPhoneData = { phonePrefix?: number; phone?: string; edit?: boolean; touched?: boolean };

export const CheckoutConnectActivities = ({
    sectionStates,
    removeProductFromCart,
    removeProductFromCartAndSummary,
    products,
    type,
}: TCheckoutConnectActivities) => {
    const t = useTranslate();
    const dispatch = useDispatch();
    const activities = products.filter(product => product.type === type.lid);
    const allActivitiesHavePhoneNumber = activities.every(activity =>
        activity.items?.every(item => item.phoneprefix && item.phone)
    );

    const guestsOnTravel = useSelector(guestsOnTravelSelector);
    const allowActivityNotes = useSelector(optionsSelector).reservation?.allow_activity_notes || false;
    const sectionState = sectionStates[type.lid];
    const generalAges: TGeneralAge[] = useSelector(agesSelector)[type.lid];

    const [phoneData, setPhoneData] = useState<{
        [key: string]: TPhoneData;
    }>();

    type TSyncOperations = {
        keep: TCartItemItem[];
        add: TCartItemItem[];
    };

    const syncActivityItems = (activity: TCartItem) => {
        const activityItems = activity.items || [];

        // Start by building up a complete new set of items and then sync with existing.
        const syncOperations: TSyncOperations = { keep: [], add: [] };

        // If multiple ages exists for the activity.
        if (activity.info?.guests) {
            syncOperations.add = Object.entries(activity.info?.guests || {})
                .filter(([key]) => key !== "totalGuests")
                .reduce((acc: TCartItemItem[], [ageKey, ageCount]) => {
                    const ageDataForKey = generalAges.find(ageData => ageData.key === ageKey);

                    for (let i = 0; i < ageCount; i++) {
                        acc.push({
                            id: uuidv4(),
                            guestId: null,
                            minAge: ageDataForKey ? Math.max(activity.minage, ageDataForKey.min) : activity.minage,
                            maxAge: ageDataForKey ? Math.min(activity.maxage, ageDataForKey.max) : activity.maxage,
                        });
                    }

                    return acc;
                }, []);
        } else {
            for (let i = 0; i < activity.count; i++) {
                syncOperations.add.push({
                    id: uuidv4(),
                    guestId: null,
                    minAge: activity.minage,
                    maxAge: activity.maxage,
                });
            }
        }

        syncOperations.keep = activityItems.filter(activityItem => {
            const matchItem = syncOperations.add.find(item => {
                return item.minAge === activityItem.minAge && item.maxAge === activityItem.maxAge;
            });

            if (matchItem) {
                // Item is a match, keep existing and prevent if from being added again.
                syncOperations.add = syncOperations.add.filter(item => item !== matchItem);
                return true;
            }

            return false;
        });

        const newItems = [...syncOperations.keep, ...syncOperations.add];

        if (!isEqual(activityItems, newItems)) {
            dispatch(updateProduct(activity, { key: "items", value: newItems }));
        }
    };

    useEffect(() => {
        activities.forEach(syncActivityItems);
        //eslint-disable-next-line
    }, []);

    const handleProductChange = (
        fieldName: string,
        fieldValue: string,
        product: TCartItem,
        productItem: TCartItemItem
    ) => {
        updateProductItem(product, productItem, fieldName, fieldValue);
    };

    /**
     * Update a product item.
     * Do not update guest data because we don't want to save phone numbers on guests.
     *
     * @param {object} product
     * @param {object} productItem
     * @param {string} key
     * @param value
     */
    const updateProductItem = (product: TCartItem, productItem: TCartItemItem, key: string, value: string) => {
        dispatch(
            updateProduct(product, {
                key: "items",
                value: product.items?.map(item => (item.id === productItem.id ? { ...item, [key]: value } : item)),
            })
        );
    };

    const getActivityAgeSpan = (activity: TCartItem) => {
        if (!activity.items) {
            return;
        }

        if (activity.minage === 0 && activity.maxage === 99) {
            return t("book.general.all_ages");
        } else {
            return `${activity.minage} - ${activity.maxage} ${t("book.general.years")}`;
        }
    };

    const setPhoneNumberForAllActivities = (phonePrefix: number, phone: string) => {
        if (!phonePrefix || !phone) {
            return;
        }

        activities
            .filter(activity => !activity.detlid && activity.items?.length)
            .forEach(activity => {
                setPhoneNumber(activity, phonePrefix, phone);
            });
    };

    const setPhoneNumber = (activity: TCartItem, phonePrefix: number, phone: string) => {
        let items = activity.items;

        if (items?.length) {
            items = items.map(item => {
                item.phoneprefix = phonePrefix.toString();
                item.phone = phone;
                return item;
            });

            dispatch(
                updateProduct(activity, {
                    key: "items",
                    value: items,
                })
            );

            setPhoneData(prevState => ({
                ...prevState,
                [activity.id]: { ...prevState?.[activity.id], phonePrefix, phone, edit: false },
            }));
        }
    };

    const changePhoneData = (id: string, fieldName: string, value: any) => {
        const isGeneralPhonePrefix = id === "general" && fieldName === "phonePrefix";

        setPhoneData(prevData => {
            return {
                ...prevData,
                [id]: {
                    ...phoneData?.[id],
                    touched: isGeneralPhonePrefix
                        ? prevData?.[id].touched
                        : fieldName === "edit"
                        ? phoneData?.[id]?.edit
                        : true,
                    [fieldName]: value,
                },
            };
        });
    };

    const resetPhone = (activity: TCartItem) => {
        const generalData = phoneData?.["general"];

        if (!generalData?.phone || !generalData?.phonePrefix) {
            return;
        }

        setPhoneData(prevState => ({
            ...prevState,
            [activity.id]: {
                ...prevState?.[activity.id],
                phonePrefix: generalData.phonePrefix,
                phone: generalData.phone,
            },
        }));

        setPhoneNumber(activity, generalData?.phonePrefix, generalData?.phone);
    };

    const canGuestBeAddedToActivity = (guest: TGuest, activity: TCartItem, item: TCartItemItem) => {
        // Check if a guest is already added to the activity and has the right age
        const guestAlreadyAddedToActivity = activity.items
            ?.filter(activityItem => activityItem.id !== item.id)
            .some(activityItem => parseInt(activityItem.guestId ?? "") === guest.id);

        return (
            !guestAlreadyAddedToActivity && isGuestRightAgeAtArrival(guest, activity.arrdate, item.minAge, item.maxAge)
        );
    };

    const renderActivities = () => {
        return activities.map((activity, i) => {
            const firstActivityItemPhone = activity.items?.[0]?.phone;

            return (
                <div
                    key={`${activity.id}-${i}`}
                    className={classNames({ "u-disabled": !allActivitiesHavePhoneNumber })}
                >
                    <CheckoutConnectCard>
                        <>
                            <div className="row u-mb-18">
                                <div className="col-12 col-lg-6">
                                    <div className="u-mb-12">
                                        <CheckoutConnectCardHeading
                                            primaryHeading={activity.groupTitle}
                                            secondaryHeading={getActivityAgeSpan(activity)}
                                        />
                                    </div>
                                    <div className="u-mb-6">
                                        <span>{capitalizeFirstLetter(showArrDepDates(activity, "dddd Do MMMM"))}</span>
                                    </div>
                                </div>
                                {allActivitiesHavePhoneNumber && (
                                    <div className="col-12 col-lg-6 u-d-flex u-flex-column u-align-items-lg-end">
                                        <Heading type="h4" styleAs="default-highlighted">
                                            {t("book.checkout.activity_guest_phone")}
                                        </Heading>
                                        {phoneData?.[activity.id]?.edit ? (
                                            <CheckoutConnectChangeActivityPhone
                                                activityId={activity.id.toString()}
                                                phoneData={phoneData}
                                                changePhoneData={changePhoneData}
                                                onUpdate={(phonePrefix, phone) =>
                                                    setPhoneNumber(activity, phonePrefix, phone)
                                                }
                                                onResetPhone={() => resetPhone(activity)}
                                                showReset={!!phoneData["general"]}
                                                savedActivityPhonePrefix={activity.items?.[0].phoneprefix}
                                                savedActivityPhone={activity.items?.[0].phone}
                                            />
                                        ) : (
                                            <div className="u-d-flex u-gap-12 u-align-items-center">
                                                <p className="u-mb-0">{firstActivityItemPhone}</p>
                                                <Button
                                                    type="tertiary"
                                                    onClick={() =>
                                                        changePhoneData(activity.id.toString(), "edit", "true")
                                                    }
                                                >
                                                    {t("book.general.edit")}
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                            <div className="row u-mb-12">
                                {!activity.detlid &&
                                    activity.items?.map((item, dropdownIndex) => {
                                        const guestSelectOptions = Object.entries(guestsOnTravel)
                                            .filter(([, guest]) => canGuestBeAddedToActivity(guest, activity, item))
                                            .map(([id, guest]) => ({
                                                value: id,
                                                label: getGuestNameAndAge(guest, "år", activity),
                                            }));

                                        const forAllAges = item.minAge === 0 && item.maxAge === 99;

                                        return (
                                            <div
                                                key={`${item.guestId}-${dropdownIndex}`}
                                                className="col-12 col-lg-6 u-mb-24"
                                            >
                                                <div className="u-mb-12">
                                                    <Select
                                                        required
                                                        label={
                                                            <span>
                                                                {`${t("book.general.guest")} ${dropdownIndex + 1} `}
                                                                {!forAllAges && item.minAge && item.maxAge && (
                                                                    <span
                                                                        style={{ fontWeight: 400 }}
                                                                        className="u-fw-default"
                                                                    >{`(${item.minAge} - ${item.maxAge} ${t(
                                                                        "book.general.years"
                                                                    )})`}</span>
                                                                )}
                                                            </span>
                                                        }
                                                        defaultValue={item.guestId || ""}
                                                        name="guestId"
                                                        options={guestSelectOptions}
                                                        type="standard"
                                                        onSelectCallbackWithEvent={event => {
                                                            handleProductChange(
                                                                event.target.name,
                                                                event.target.value,
                                                                activity,
                                                                item
                                                            );
                                                        }}
                                                        placeholder={t("book.checkout.pick_guest")}
                                                        useFirstAsDefault={false}
                                                        disabled={!guestSelectOptions.length}
                                                        isValid={isSectionStateInputValid(
                                                            sectionState,
                                                            item.guestId ?? ""
                                                        )}
                                                        showAsterisk
                                                        boldLabel
                                                    />
                                                    {!guestSelectOptions.length && (
                                                        <CheckoutConnectNoGuestsToConnect
                                                            minAge={activity.minage}
                                                            maxAge={activity.maxage}
                                                        />
                                                    )}
                                                </div>
                                                {allowActivityNotes && (
                                                    <CheckoutConnectComment
                                                        product={activity}
                                                        item={item}
                                                        onUpdateProduct={updateProductItem}
                                                        onRemoveProduct={removeProductFromCart}
                                                        isItem
                                                    />
                                                )}
                                            </div>
                                        );
                                    })}
                            </div>
                            <CheckoutConnectCardRemoveBtn onClick={() => removeProductFromCartAndSummary(activity)} />
                        </>
                    </CheckoutConnectCard>
                </div>
            );
        });
    };

    return (
        <>
            {!allActivitiesHavePhoneNumber && (
                <div className="u-d-flex u-justify-content-center u-mb-36">
                    <div
                        className="u-d-flex u-flex-column u-align-items-center u-justify-content-center u-gap-18"
                        style={{ width: "100%", maxWidth: 500 }}
                    >
                        <div className="u-pt-18 u-pb-18">
                            <div className="u-d-flex u-gap-12">
                                <PhoneIcon size={30} color={style.brandBlueColor} />
                                <Heading type="h4" className="u-mb-12">
                                    {t("book.checkout.phone_for_activities.heading")}
                                </Heading>
                            </div>

                            <p>{t("book.checkout.phone_for_activities.description")}</p>
                            <CheckoutConnectChangeActivityPhone
                                activityId={"general"}
                                phoneData={phoneData}
                                changePhoneData={changePhoneData}
                                onUpdateAll={setPhoneNumberForAllActivities}
                            />
                        </div>
                    </div>
                </div>
            )}
            {renderActivities()}
        </>
    );
};

type TCheckoutConnectChangeActivityPhone = {
    activityId: string;
    phoneData?: { [key: string]: TPhoneData };
    changePhoneData: (arg0: string, arg1: string, arg2: any) => void;
    onUpdate?: (arg0: number, arg1: string) => void;
    onUpdateAll?: (arg0: number, arg1: string) => void;
    onResetPhone?: () => void;
    showReset?: boolean;
    savedActivityPhonePrefix?: string;
    savedActivityPhone?: string;
};

const CheckoutConnectChangeActivityPhone = ({
    activityId,
    phoneData,
    changePhoneData,
    onUpdate,
    onUpdateAll,
    onResetPhone,
    showReset = false,
    savedActivityPhonePrefix,
    savedActivityPhone,
}: TCheckoutConnectChangeActivityPhone) => {
    const t = useTranslate();
    const { phoneValid, validatePhone, validatePhonePrefix } = useFormPhoneNumberValidation(
        true,
        false,
        false,
        () => null,
        () => null
    ) as {
        phoneValid: boolean;
        phonePrefixValid: boolean;
        validatePhone: (arg0: string | number, arg1: string | number) => boolean;
        validatePhonePrefix: (arg0: string | number, arg1: string | number) => boolean;
    };

    const currentPhoneData = phoneData?.[activityId];
    const phonePrefix = currentPhoneData?.phonePrefix ?? parseInt(savedActivityPhonePrefix ?? "");
    const phone = currentPhoneData?.phone ?? savedActivityPhone;

    return (
        <div className="row u-bs-gutters-6">
            <div className="col-12 col-lg-5">
                <PhoneCountryCodeSelect
                    name="phoneprefix"
                    value={phonePrefix ?? parseInt("")}
                    onChange={(selectedOption: TSelectOption) => {
                        changePhoneData(activityId, "phonePrefix", selectedOption.value as number);
                        validatePhonePrefix(selectedOption.value, currentPhoneData?.phone ?? "");
                    }}
                    isValid={!currentPhoneData?.touched ? undefined : phoneValid}
                />
            </div>
            <div className="col-12 col-lg-7">
                <Input
                    label={t("book.checkout.mobile_number")}
                    fullWidth
                    name="phone"
                    type="tel"
                    value={phone}
                    onChange={e => {
                        if (isNumberWithoutDecimals(e.target.value)) {
                            changePhoneData(activityId, "phone", e.target.value);
                            if (validatePhone(phonePrefix, e.target.value)) {
                                e.target.setCustomValidity("");
                            } else {
                                e.target.setCustomValidity("Invalid phone");
                            }
                        }
                    }}
                    isValid={!currentPhoneData?.touched ? undefined : phoneValid}
                />
            </div>
            <div className="col-12 u-d-flex u-gap-12 u-justify-content-end">
                {onUpdateAll && (
                    <Button
                        type="transparent"
                        onClick={() => {
                            if (phoneValid) {
                                onUpdateAll?.(phonePrefix ?? parseInt(""), phone ?? "");
                            }
                        }}
                        fullWidth
                        disabled={!phoneValid}
                    >
                        {t("book.checkout.set_phone")}
                    </Button>
                )}
                {onUpdate && (
                    <>
                        <Button
                            type="tertiary"
                            onClick={() => {
                                if (phoneValid) {
                                    onUpdate?.(phonePrefix ?? parseInt(""), phone ?? "");
                                }
                            }}
                            disabled={!phoneValid}
                        >
                            {t("book.general.save")}
                        </Button>
                        <Button type="tertiary" onClick={() => changePhoneData(activityId, "edit", false)}>
                            {t("book.general.cancel")}
                        </Button>
                        {showReset && onResetPhone && (
                            <Button type="tertiary" onClick={onResetPhone}>
                                {t("book.general.reset")}
                            </Button>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};
