import React from "react";
import { Row, Col } from "react-bootstrap";
import { Incrementer } from "../../UI";
import "./Guest.scss";

const Guest = props => {
    const { guestCount, texts, data, onChange } = props;
    const { title, description } = data;

    const handleIncrementerChange = ({ value }) => {
        onChange?.(value, data);
    };

    return (
        <div className="guest-old">
            <Row className="mb-2">
                <Col>
                    <div className="guest-old__title" id={`guest-stepper-title-label-${texts[title]}`}>
                        {texts[title]}
                    </div>
                    {texts[description] && (
                        <div className="guest-old__description" id={`guest-stepper-description-label-${texts[title]}`}>
                            {texts[description]}
                        </div>
                    )}
                </Col>
                <Col xs="auto">
                    <Incrementer
                        label={texts[title]}
                        value={guestCount}
                        minValue={data.minCount}
                        onChange={handleIncrementerChange}
                        size={8}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default Guest;
