import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import PersonalDetailsInformation, { TPersonalDetailsForm } from "./PersonalDetailsInformation";
import PersonalRentalInformation, { TPersonalRentalForm } from "./PersonalRentalInformation";
import { FormikErrors, FormikTouched } from "formik";
import PersonalKeycardInformation, { TPersonalKeycardForm } from "./PersonalKeycardInformation";
import { TGuest, TNewUser, TUser } from "../../store/types";
import { UploadPhoto } from "../UploadPhoto";
import { TButtonType } from "../UploadPhoto/UploadPhoto";
import useTranslate from "../../hooks/useTranslate";

type TPersonalInfomation = {
    isPrimaryGuest: boolean;
    guest?: TPersonalInformationGuest;
    onSubmit: (arg0: any) => void;
    showAgePicker: boolean;
    showKeycardImage: boolean;
    showKeycardNo: boolean;
    showWeight: boolean;
    showHeight: boolean;
    showShoesize: boolean;
    showHeadsize: boolean;
    uploadPhotoButtonType?: TButtonType;
    newGuest?: boolean;
};

export type TPersonalInfomationRef = {
    submitForm: () => void;
    resetForm: () => void;
    resetTouched: () => void;
    detailsFormRef: any;
    keycardFormRef: any;
    rentalFormRef: any;
};

export type TPersonalInformationGuest = TUser & TGuest & TNewUser;
export type TFormikTouched = boolean | FormikTouched<any> | FormikTouched<any>[] | undefined;
export type TFormikErrors = string | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined;

export const isFieldValid = (touched: TFormikTouched, errors: TFormikErrors) => {
    if (touched) {
        if (!errors) {
            return true;
        } else {
            return false;
        }
    } else {
        return undefined;
    }
};

const PersonalInformation = forwardRef(
    (
        {
            isPrimaryGuest,
            guest,
            onSubmit,
            showAgePicker,
            showKeycardNo,
            showKeycardImage,
            showWeight,
            showHeight,
            showShoesize,
            showHeadsize,
            uploadPhotoButtonType = "secondary",
            newGuest,
        }: TPersonalInfomation,
        ref
    ) => {
        const t = useTranslate();
        const detailsFormRef = useRef<any | null>(null);
        const keycardFormRef = useRef<any | null>(null);
        const rentalFormRef = useRef<any | null>(null);
        const [detailsSubmittedValues, setDetailsSubmittedValues] = useState<TPersonalDetailsForm | null>(null);
        const [keycardSubmittedValues, setKeycardSubmittedValues] = useState<TPersonalKeycardForm | null>(null);
        const [rentalSubmittedValues, setRentalSubmittedValues] = useState<TPersonalRentalForm | null>(null);

        const useKeycardForm = showKeycardNo;
        const useRentalForm = showWeight || showHeight || showShoesize || showHeadsize;

        useImperativeHandle(ref, () => ({
            submitForm: handleSubmit,
            resetForm: resetForms,
            resetTouched: resetTouched,

            // Export inner forms' refs to be able to
            // affect field values and validations from the outside.
            // See example in MyPages.
            detailsFormRef: detailsFormRef.current,
            keycardFormRef: keycardFormRef.current,
            rentalFormRef: rentalFormRef.current,
        }));

        const resetSubmittedValues = () => {
            setDetailsSubmittedValues(null);
            setKeycardSubmittedValues(null);
            setRentalSubmittedValues(null);
        };

        const submitForms = () => {
            detailsFormRef.current.submitForm();
            useKeycardForm && keycardFormRef.current.submitForm();
            useRentalForm && rentalFormRef.current.submitForm();
        };

        const resetForms = () => {
            detailsFormRef.current.resetForm();
            useKeycardForm && keycardFormRef.current.resetForm();
            useRentalForm && rentalFormRef.current.resetForm();
        };

        const resetTouched = () => {
            detailsFormRef.current.resetTouched();
            useKeycardForm && keycardFormRef.current.resetTouched();
            useRentalForm && rentalFormRef.current.resetTouched();
        };

        useEffect(() => {
            if (
                detailsSubmittedValues &&
                (!useKeycardForm || keycardSubmittedValues) &&
                (!useRentalForm || rentalSubmittedValues)
            ) {
                const guestValues = {
                    ...guest,
                    ...detailsSubmittedValues,
                    ...(useKeycardForm && keycardSubmittedValues),
                    ...(useRentalForm && rentalSubmittedValues),
                };

                onSubmit?.(guestValues);
                resetSubmittedValues();
            }
            // eslint-disable-next-line
        }, [detailsSubmittedValues, keycardSubmittedValues, rentalSubmittedValues]);

        const handleSubmit = () => {
            resetSubmittedValues();
            submitForms();
        };

        const handleDetailsSubmit = (values: TPersonalDetailsForm) => {
            setDetailsSubmittedValues(values);
        };

        const handleKeycardSubmit = (values: TPersonalKeycardForm) => {
            setKeycardSubmittedValues(values);
        };

        const handleRentalSubmit = (values: TPersonalRentalForm) => {
            setRentalSubmittedValues(values);
        };

        return (
            <div className="row">
                <div className="col-12">
                    <div className="u-mb-12">
                        <PersonalDetailsInformation
                            ref={detailsFormRef}
                            isPrimaryGuest={isPrimaryGuest}
                            guest={guest}
                            onSubmit={handleDetailsSubmit}
                            showAgePicker={showAgePicker}
                        />
                    </div>
                    <div className="row">
                        {showKeycardImage && (
                            <div className="col-12 col-sm-4 col-xl-2 u-mb-18 u-mb-lg-0">
                                <UploadPhoto
                                    guestlid={guest?.id}
                                    imageHash={guest?.imagehash ?? ""}
                                    noMaxWidth
                                    buttonType={uploadPhotoButtonType}
                                    disabled={newGuest}
                                />
                                {newGuest && <p className="u-fs-12 u-pt-6">{t("book.my_pages.new_guest_photo")}</p>}
                            </div>
                        )}
                        <div className="col-12 col-sm-8 col-xl-7 u-mb-24 u-mb-xl-0">
                            {useRentalForm && (
                                <div className="u-mb-24">
                                    <PersonalRentalInformation
                                        ref={rentalFormRef}
                                        showWeight={showWeight}
                                        showHeight={showHeight}
                                        showShoesize={showShoesize}
                                        showHeadsize={showHeadsize}
                                        weight={guest?.weight ?? parseInt("")}
                                        height={guest?.height ?? parseInt("")}
                                        shoesize={guest?.shoesize ?? parseInt("")}
                                        headsize={guest?.headsize ?? parseInt("")}
                                        onSubmit={handleRentalSubmit}
                                    />
                                </div>
                            )}
                            {useKeycardForm && (
                                <div>
                                    <PersonalKeycardInformation
                                        ref={keycardFormRef}
                                        showKeycardNo={showKeycardNo}
                                        keycardNo={guest?.keycardNo ?? ""}
                                        guestlid={guest?.id ?? parseInt("")}
                                        imagehash={guest?.imagehash ?? ""}
                                        onSubmit={handleKeycardSubmit}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
);

PersonalInformation.displayName = "PersonalInformation";

export default PersonalInformation;
