import * as types from "../actions/types";

export const notificationTypes = {
    SEARCH_CONDITIONS_CHECK_FAILED: "SEARCH_CONDITIONS_CHECK_FAILED",
};

export const setNotfication = (type, data) => {
    return {
        type: types.SET_NOTIFICATION,
        payload: { type, data },
    };
};

export const clearNotifications = () => {
    return {
        type: types.CLEAR_NOTIFICATIONS,
    };
};
