import classNames from "classnames";
import React from "react";
import "./PageContainer.scss";

type TPageContainer = {
    children: React.ReactNode;
    minHeight?: boolean;
    className?: string;
};

export const PageContainer = ({ children, minHeight = true, className = "" }: TPageContainer) => {
    return (
        <div className={classNames("page-container", className, { "page-container--w-min-height": minHeight })}>
            {children}
        </div>
    );
};
