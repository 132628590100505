import React from "react";
import { Col, Row } from "react-bootstrap";
import { getGuestAge, parsePhoneNumber } from "../../Helper";
import "./ContactInformation.scss";

const ContactInformation = ({ loggedInUser, arrivalDate, texts }) => {
    return (
        <div className="box contact-information">
            {loggedInUser && (
                <Row className="contact-information__guests">
                    <Row>
                        <Col className="box-head contact-information__section-head">
                            <h2 className="box-heading contact-information__section-header">
                                {texts?.checkoutcontactinformationheader}
                            </h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={2}>
                            <h6>{texts["checkoutsummary.customer.name"]}</h6>
                            <p>
                                {loggedInUser.firstname} {loggedInUser.lastname}
                            </p>
                        </Col>
                        <Col md={2}>
                            <h6>{texts["checkoutsummary.customer.age"]}</h6>
                            <p>{getGuestAge(loggedInUser, texts["generalyearshort"], arrivalDate)}</p>
                        </Col>
                        <Col md={3}>
                            <h6>{texts["checkoutsummary.customer.email"]}</h6>
                            <p style={{ wordWrap: "break-word" }}>{loggedInUser.email}</p>
                        </Col>
                        <Col md={2}>
                            <h6>{texts["checkoutsummary.customer.phonenumber"]}</h6>
                            <p>{parsePhoneNumber(loggedInUser.phoneprefix, loggedInUser.phone)}</p>
                        </Col>
                        <Col md={3}>
                            <h6>{texts["checkoutsummary.customer.address"]}</h6>
                            <p>
                                {loggedInUser.addr1}
                                <br />
                                {loggedInUser.addr3} {loggedInUser.addr4}
                            </p>
                        </Col>
                    </Row>
                </Row>
            )}
        </div>
    );
};
export default ContactInformation;
