import { Checkbox, ChevronDownIcon, ChevronUpIcon, style } from "@r360/library";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { capitalizeFirstLetter } from "../../Helper";
import useAppSelector from "../../hooks/useAppSelector";
import { ECriteriaType, TCriteriaItem, TCrits, TReservationTypeLid } from "../../store/types";
import { TFilterChange } from "./filterRow";

type Props = {
    reservationTypeId: TReservationTypeLid;
    item: TCriteriaItem;
    searchResultCriterias: TCrits[];
    onFilterChange: (arg0: TFilterChange) => void;
    onEnumFilterChange: (arg0: TFilterChange) => void;
};

export const FilterRowItem = ({
    reservationTypeId,
    item,
    searchResultCriterias,
    onFilterChange,
    onEnumFilterChange,
}: Props) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const itemRef = useRef<HTMLLIElement | null>(null);
    const filtersFromStore = useAppSelector(state => state.filter);
    const activeFilters =
        filtersFromStore && reservationTypeId in filtersFromStore ? filtersFromStore[reservationTypeId] : {};

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (itemRef.current && !itemRef.current.contains(event.target as Node)) {
                setDropdownOpen(false);
            }
        };

        const removeEventListener = () => {
            document.removeEventListener("click", handleClickOutside, true);
        };

        if (dropdownOpen) {
            document.addEventListener("click", handleClickOutside, true);
        } else {
            removeEventListener();
        }

        return () => {
            removeEventListener();
        };
    }, [dropdownOpen]);

    const renderFilter = (filter: TCriteriaItem) => {
        switch (filter.type) {
            case ECriteriaType.BOOL: {
                const checked = activeFilters && filter.code in activeFilters ? activeFilters[filter.code] : false;
                const boolBtnClassName = classNames("filter-row__btn", { "filter-row__btn--active": checked });

                return (
                    <button
                        onClick={() =>
                            onFilterChange({ value: !checked, key: item.code, type: item.type, details: item })
                        }
                        className={boolBtnClassName}
                    >
                        {capitalizeFirstLetter(item.title?.toLowerCase())}
                    </button>
                );
            }

            case ECriteriaType.ENUM: {
                const active = filter.enums.some(
                    enumItem =>
                        !!(
                            activeFilters &&
                            item.code in activeFilters &&
                            activeFilters[filter.code].includes(enumItem.code)
                        )
                );

                const arrowColor = active ? style.whiteColor : style.blackColor;
                const btnClassName = classNames("filter-row__btn", { "filter-row__btn--active": active });

                return (
                    <>
                        <button onClick={() => setDropdownOpen(prev => !prev)} className={btnClassName}>
                            {item.title}
                            {!dropdownOpen ? (
                                <ChevronDownIcon color={arrowColor} />
                            ) : (
                                <ChevronUpIcon color={arrowColor} />
                            )}
                        </button>
                        {dropdownOpen && (
                            <div className="filter-row__dropdown">
                                <ul>
                                    {item.enums.map(enumItem => {
                                        const checked = !!(
                                            activeFilters &&
                                            item.code in activeFilters &&
                                            activeFilters[item.code].includes(enumItem.code)
                                        );

                                        // If enums should be filtered based on search result or not.
                                        if (
                                            item.filterEnumsBasedOnResult &&
                                            !searchResultCriterias.some(crits => crits[item.code] === enumItem.code) &&
                                            !checked
                                        ) {
                                            return null;
                                        }

                                        return (
                                            <li key={enumItem.code}>
                                                <Checkbox
                                                    label={enumItem.title}
                                                    checked={checked}
                                                    checkboxClassName="filter-row__checkbox"
                                                    onChange={() =>
                                                        onEnumFilterChange({
                                                            value: enumItem.code,
                                                            key: item.code,
                                                            type: item.type,
                                                            details: item,
                                                        })
                                                    }
                                                />
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        )}
                    </>
                );
            }
        }
    };

    return (
        <li ref={itemRef} className="filter-row__item">
            {renderFilter(item)}
        </li>
    );
};
