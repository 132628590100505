import React, { createRef, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isProductForFastCheckout } from "../../BusinessUtils";
import * as Constants from "../../Constants";
import { optionsSelector } from "../../Selectors";
import {
    addedAllProducts,
    createNewCart,
    emptyCartProducts,
    removeProduct,
    syncProductsInCart,
    updateProduct,
    updateProductWithCount,
} from "../../store/actions/checkoutCart";
import { makeReservation } from "../../store/actions/reservation";
import * as types from "../../store/actions/types";
import { formatNumber, getProductTitle } from "../../Helper";
import useTranslate from "../../hooks/useTranslate";
import { RootState } from "../..";
import { TCartItem, TCartItemItem, TCheckoutCart, TReservation, TReservationType } from "../../store/types";
import useAppDispatch from "../../hooks/useAppDispatch";
import PageContainer from "../../components/PageContainer";
import {
    ArrowRightIcon,
    Breadcrumbs,
    Button,
    ConfirmationModal,
    Heading,
    Notification,
    TrashIcon,
    style,
} from "@r360/library";
import { CheckoutConnectSkipass } from "../../components/CheckoutConnect";
import classNames from "classnames";
import { TSectionState, TSectionStates } from "../CheckoutConnectPage/CheckoutConnectPage";

type TProductData = {
    product: TCartItem;
    cartId?: string;
    mandatory: {
        product_id: number;
        keycardNo?: string;
        withExistingKeycard?: string;
        firstname?: string;
        lastname?: string;
    };
};

const FastCheckoutPage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const t = useTranslate();

    const clientData = useSelector((state: RootState) => state.clientData);
    const reservationTypes: TReservationType[] = clientData.reservationtypes;
    const loggedInUser = useSelector((state: RootState) => state.account.user);
    const checkoutCart: TCheckoutCart = useSelector((state: RootState) => state.checkoutCart);
    const cartProducts = checkoutCart.products;
    const checkoutCartId = checkoutCart.cartId;
    const addedAllProductsSucceed = checkoutCart.addedAllProductsSucceed;
    const reservation = useSelector((state: RootState) => (state.reservation as TReservation).reservation);
    const currency = useSelector((state: RootState) => state.clientData?.options?.general?.currency);
    const fastcheckoutEnabled = useSelector(optionsSelector).reservation?.fastcheckout;
    const requireGuestName = useSelector(optionsSelector).reservation?.fastcheckout_require_guest_name;
    const { isMobile, isTablet, isDesktop, isIframe, iframeOffsetTop } = useSelector(
        (state: RootState) => state.window
    );
    const isLoading = useSelector((state: RootState) => state.axiosStatus.loading);

    const productsForFastCheckout = cartProducts.filter(isProductForFastCheckout);
    const hasOnlyProductsForFastCheckout =
        cartProducts.length > 0 && cartProducts.length === productsForFastCheckout.length;
    const uniqueReservationTypes = [
        ...new Set(
            productsForFastCheckout
                .map(item => reservationTypes.find(resvType => resvType.lid === item.type))
                .filter(item => !!item)
        ),
    ];

    const initSectionStates = () => {
        const sectionsArray = [...uniqueReservationTypes.map(resvType => resvType?.lid.toString())];
        const sections = sectionsArray.reduce((acc: TSectionStates, section) => {
            if (!section) {
                return acc;
            }

            return {
                ...acc,
                [section]: {
                    status: undefined,
                    disabled: false,
                    statusMessage: "",
                    hidden: false,
                },
            };
        }, {});

        return sections;
    };

    const [sectionStates, setSectionStates] = useState<TSectionStates>(initSectionStates());
    const [productToRemove, setProductToRemove] = useState<{
        product: TCartItem;
        item: TCartItemItem;
    } | null>(null);
    const [emptyCartModal, setEmptyCartModal] = useState(false);
    const [fastCheckoutFinished, setFastCheckoutFinished] = useState(false);
    const formRefs = useRef<{ [key: string]: any } | null>(null);
    const [clearCartModal, setClearCartModal] = useState(false);

    useEffect(() => {
        // Create a ref for each reservation type.
        formRefs.current = uniqueReservationTypes.reduce((acc: { [key: string]: any }, resvType) => {
            if (!resvType) {
                return acc;
            }

            acc[resvType.lid] = createRef();
            return acc;
        }, {});
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        dispatch({ type: types.CLEAR_ERRORS });
        dispatch(addedAllProducts(false));

        if (!checkoutCartId && hasOnlyProductsForFastCheckout) {
            // Create cart without a customer token to make it an unauthenticated cart.
            dispatch(createNewCart(null, clientData?.cookie));
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (cartProducts && cartProducts.length === 0) {
            setEmptyCartModal(true);
        }
    }, [cartProducts, navigate]);

    useEffect(() => {
        // Navigate to checkout if user is logged in, if fast checkout isn't enabled or if cart doesn't only contain skipass products allowed in the fast checkout.
        if (loggedInUser || !fastcheckoutEnabled || (cartProducts.length > 0 && !hasOnlyProductsForFastCheckout)) {
            navigate("/checkout", { replace: true });
            return;
        }
    }, [navigate, cartProducts, hasOnlyProductsForFastCheckout, loggedInUser, fastcheckoutEnabled]);

    useEffect(() => {
        if (addedAllProductsSucceed === true && checkoutCartId) {
            dispatch(makeReservation(null, checkoutCartId));
        }
    }, [dispatch, addedAllProductsSucceed, checkoutCartId]);

    useEffect(() => {
        if (reservation) {
            navigate(`/pay/${reservation.resvid}/0/${reservation.payerlid}?verificationHash=${reservation.hash}`);
        }
    }, [reservation, navigate]);

    const changeSectionState = (section: string, state: TSectionState) => {
        setSectionStates(prevState => ({
            ...prevState,
            [section]: {
                ...prevState[section],
                expanded: state.expanded ?? prevState[section].expanded,
                status: state.status ?? prevState[section].status,
                disabled: state.disabled ?? prevState[section].disabled,
                statusMessage: state.statusMessage ?? prevState[section].statusMessage,
                hidden: state.hidden ?? prevState[section].hidden,
            },
        }));
    };

    const handleClearCart = async () => {
        dispatch(emptyCartProducts());
        navigate("/search");
    };

    const onEmptyCart = () => {
        console.log("FastCheckout: Cart is empty, redirect back to search page"); //! KEEP: Useful for debugging in log rocket, because some times the cart is empty
        navigate("/search", { replace: true });
    };

    const handleSubmit = () => {
        let formsAreValid = false;

        // Check validity for each form.
        uniqueReservationTypes.forEach(resvType => {
            if (resvType?.lid) {
                const formIsValid = formRefs.current?.[resvType.lid].current.checkValidity();
                changeSectionState(resvType.lid.toString(), { status: formIsValid ? "success" : "error" });
                formsAreValid = formRefs.current?.[resvType.lid].current.checkValidity();
            }
        });

        if (formsAreValid) {
            const productsRequestData: TProductData[] = [];

            productsForFastCheckout.forEach(product => {
                const currentProductType = reservationTypes.find(
                    reservationType => reservationType.lid === product.type
                )?.type;

                if (currentProductType === Constants.productTypeNames.SKIPASS && product.items && product.id) {
                    product.items.forEach(item => {
                        const productData: TProductData = {
                            product: product,
                            cartId: item.id,
                            mandatory: {
                                product_id: product.id,
                                withExistingKeycard: item.keycard === undefined ? undefined : item.keycard,
                                firstname: item.firstname || "Guest",
                                lastname: item.lastname || "Guest",
                            },
                        };

                        if (!!item.keycard && item?.valid === true) {
                            productData.mandatory.keycardNo = `${item.keycardNo}`;
                        }

                        productsRequestData.push(productData);
                    });
                }
            });

            dispatch({ type: types.CLEAR_ERRORS });
            dispatch(addedAllProducts(false));
            dispatch(syncProductsInCart(checkoutCartId, productsRequestData));
            setFastCheckoutFinished(true);
        }
    };

    const removeProductFromCart = (productToRemove: TCartItem, itemToRemove: TCartItemItem) => {
        const currentCount = productToRemove.count - 1;
        if (currentCount === 0) {
            dispatch(removeProduct(productToRemove));
        } else {
            dispatch(
                updateProduct(productToRemove, {
                    key: "items",
                    value: productToRemove?.items?.filter(item => item !== itemToRemove),
                })
            );
            dispatch(updateProductWithCount(productToRemove, currentCount));
        }
    };

    const getTotalPrice = () => {
        const totalPrice = productsForFastCheckout.reduce((accPrice: number, product: TCartItem) => {
            accPrice += product.price * (product.count || 1);

            // Include eventual new keycards if connected to a keycard provider.
            // Value "0" means that an existing card won't be used and therefore needs to by a new.
            if (product.connected && product.keycardPrice && product.keycardPrice > 0) {
                const newKeycardsCount = (product.items || []).filter(item => item.keycard === "0").length;
                accPrice += newKeycardsCount * product.keycardPrice;
            }

            return accPrice;
        }, 0);

        return formatNumber(Math.round(totalPrice));
    };

    const breadcrumbs = [
        {
            title: t("book.menu.cart"),
            path: "/cart",
        },
        {
            title: t("book.fast_checkout"),
        },
    ];

    const getBorderColor = (resvTypeLid: number) => {
        switch (sectionStates[resvTypeLid].status) {
            case "success":
                return style.successColor;
            case "error":
                return style.alertColor;
            default:
                return "transparent";
        }
    };

    return (
        <>
            <PageContainer>
                <div className="u-d-flex u-justify-content-between u-mb-18">
                    <Breadcrumbs breadcrumbs={breadcrumbs} />
                    <Button rightIcon={<TrashIcon />} type="tertiary" onClick={() => setClearCartModal(true)}>
                        {t("book.checkout.cancel_order")}
                    </Button>
                </div>
                <div className="u-mb-24">
                    <Notification type="info">
                        <p>{t("book.fast_checkout.info")}</p>
                        <p className="u-mb-0">
                            {t("book.fast_checkout.info.login")}{" "}
                            <Button link linkTo="/user/login?path=/checkout" type="tertiary">
                                {t("book.menu.login")}.
                            </Button>{" "}
                        </p>
                    </Notification>
                </div>
                {uniqueReservationTypes.map(resvType => (
                    <>
                        {resvType && (
                            <form
                                key={resvType.lid}
                                ref={formRefs.current?.[resvType.lid]}
                                onSubmit={handleSubmit}
                                onChange={e => {
                                    const target = e.target as HTMLInputElement;

                                    // Don't do live validation for skipass WTP-number
                                    if (target.name === "keycardNo") {
                                        return;
                                    }

                                    const isRadioForSkipassKeycard1 =
                                        target.name.includes("skipass-radio") && target.value === "1";

                                    if (
                                        sectionStates[resvType.lid].status !== undefined &&
                                        !isRadioForSkipassKeycard1
                                    ) {
                                        changeSectionState(resvType.lid.toString(), {
                                            status: e.currentTarget.checkValidity() ? "success" : "error",
                                        });
                                    }
                                }}
                                className={classNames({
                                    "u-disabled": fastCheckoutFinished && isLoading,
                                })}
                            >
                                {resvType && (
                                    <div key={resvType.lid} className="u-mb-24">
                                        <Heading type="h1" styleAs="h2" className="u-mb-18">
                                            {resvType?.description}
                                        </Heading>
                                        <div
                                            className="u-bg-white u-br-12 u-p-12 u-pt-xl-36 u-pb-xl-36 u-pr-xl-96 u-pl-xl-96"
                                            style={{
                                                border: `1px solid ${getBorderColor(resvType.lid)}`,
                                            }}
                                        >
                                            <CheckoutConnectSkipass
                                                type={resvType}
                                                products={productsForFastCheckout}
                                                sectionStates={sectionStates}
                                                changeSectionState={changeSectionState}
                                                removeProductFromCart={(product, item) =>
                                                    setProductToRemove({
                                                        product: product,
                                                        item: item,
                                                    })
                                                }
                                                skipGuestsOnSkipass
                                                requireGuestNames={requireGuestName}
                                                isFastCheckout
                                            />
                                        </div>
                                    </div>
                                )}
                            </form>
                        )}
                    </>
                ))}
                <div className="u-d-flex u-flex-column u-align-items-end u-mb-42" style={{ fontSize: 30 }}>
                    <div className="u-mb-24">
                        <span className="u-fw-medium">{t("book.general.total")}:</span>
                        <span>{` ${getTotalPrice()} ${currency}`}</span>
                    </div>
                    <Button
                        onClick={handleSubmit}
                        rightIcon={<ArrowRightIcon />}
                        loading={fastCheckoutFinished && isLoading}
                    >
                        {t("book.checkout.proceed_to_payment")}
                    </Button>
                </div>
            </PageContainer>
            <ConfirmationModal
                open={productToRemove}
                heading={t("book.confirmations.remove_product_from_cart.title", [
                    getProductTitle(productToRemove?.product),
                ])}
                confirmBtnText={t("book.confirmations.remove_product_from_cart.ok")}
                cancelBtnText={t("book.confirmations.remove_product_from_cart.cancel")}
                removing={true}
                onClose={() => setProductToRemove(null)}
                onConfirmClick={() => {
                    if (productToRemove) {
                        removeProductFromCart(productToRemove.product, productToRemove.item ?? {});
                        setProductToRemove(null);
                    }
                }}
                onCancelClick={() => setProductToRemove(null)}
                {...(isIframe && isDesktop && { fromTop: 120 + iframeOffsetTop + "px" })}
                {...(isIframe &&
                    (isMobile || isTablet) && {
                        fromTop: 80 + iframeOffsetTop + "px",
                    })}
            />
            <ConfirmationModal
                open={emptyCartModal}
                onClose={() => setEmptyCartModal(false)}
                heading={t("book.cart_expired_alert.title")}
                confirmBtnText={t("book.general.continue")}
                onConfirmClick={onEmptyCart}
                {...(isIframe && isDesktop && { fromTop: 120 + iframeOffsetTop + "px" })}
                {...(isIframe &&
                    (isMobile || isTablet) && {
                        fromTop: 80 + iframeOffsetTop + "px",
                    })}
            />
            <ConfirmationModal
                open={clearCartModal}
                heading={t("book.checkout.cancel_order.description")}
                confirmBtnText={t("book.checkout.cancel_order")}
                cancelBtnText={t("book.confirmations.remove_product_from_cart.cancel")}
                removing={true}
                onClose={() => setClearCartModal(false)}
                onConfirmClick={handleClearCart}
                onCancelClick={() => setClearCartModal(false)}
                {...(isIframe && isDesktop && { fromTop: 120 + iframeOffsetTop + "px" })}
                {...(isIframe &&
                    (isMobile || isTablet) && {
                        fromTop: 80 + iframeOffsetTop + "px",
                    })}
            />
        </>
    );
};

export default FastCheckoutPage;
