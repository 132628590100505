import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Guest from "./Guest/Guest";

const Guests = props => {
    const { resvType, filters } = props;
    const guestData = useSelector(state => state.clientData?.options?.general);
    const [guestJsonData, setGuestJsonData] = useState(null);

    useEffect(() => {
        if (guestData?.ages) {
            const obj = JSON.parse(guestData?.ages);
            setGuestJsonData(obj[resvType]);
        }
    }, [guestData, resvType]);

    return (
        <div>
            {guestJsonData?.map(data => {
                return <Guest {...props} key={data.title} data={data} guestCount={filters[data.key]}></Guest>;
            })}
        </div>
    );
};

export default Guests;
