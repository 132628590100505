import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SearchReservationTypes from "../components/SearchReservationTypes";
import SearchReservationTypesOld from "../components/SearchReservationTypesOld/SearchReservationTypes";
import {
    activeReservationTypeSelector,
    NewDesignReservationTypeSelector,
    optionsSelector,
} from "../selectors/Selectors";
import useAppSelector from "../hooks/useAppSelector";
import { TReservationType } from "../store/types";
import useTranslate from "../hooks/useTranslate";
import { useSelector } from "react-redux";

const SearchReservationTypesPage = () => {
    const navigate = useNavigate();
    const routeParams = useParams();
    const t = useTranslate();
    const reservationTypes = useAppSelector(state => state.clientData.reservationtypes).filter(
        (type: TReservationType) => !type.hidden
    );
    const activeReservationType = useSelector(state => activeReservationTypeSelector(state, routeParams.id));
    // routeParams.id &&
    // reservationTypes.find((type: TReservationType) => type.lid === parseInt(routeParams.id || "", 10));
    const newDesignMenu = useSelector(optionsSelector).layout?.new_design_menu;
    const newDesignReservationType = useSelector(state =>
        NewDesignReservationTypeSelector(state, activeReservationType?.type)
    );

    useEffect(() => {
        if (!activeReservationType && reservationTypes[0]) {
            navigate(`/search/${reservationTypes[0].lid}`, { replace: true });
        }
    }, [activeReservationType, reservationTypes, navigate]);

    return newDesignReservationType && newDesignMenu ? (
        <SearchReservationTypes activeReservationType={activeReservationType} />
    ) : newDesignMenu ? (
        <div className="container">
            <SearchReservationTypesOld activeReservationType={activeReservationType} />
        </div>
    ) : (
        <div className="container">
            <h1 className="page-header__search pb-2" style={{ textAlign: "center" }}>
                {t("searchheader")}
            </h1>
            <SearchReservationTypesOld activeReservationType={activeReservationType} />
        </div>
    );
};

export default SearchReservationTypesPage;
