import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AxiosClient from "../../API/AxiosClient";
import { Ticket } from "../../components/Ticket/Ticket";
import { ourWindow } from "../../ourWindow";
import "./MyTicketsPage.scss";
import { LanguageIcon, Logotype, MailIcon, PhoneIcon } from "@r360/library";
import useAppSelector from "../../hooks/useAppSelector";
import { keepNumbers } from "../../BusinessUtils";
import useTranslate from "../../hooks/useTranslate";
import { Footer } from "../../components/Footer/Footer";

declare const window: ourWindow;

export type TTicketPayload = {
    guestName: string;
    orderNumber: string;
    productName: string;
    productStatus: string;
    startDate: string;
    isActivated: boolean;
    isBooked: boolean;
    isCancelled: boolean;
    isError: boolean;
};

export const MyTicketsPage = () => {
    const params = useParams();
    const t = useTranslate();
    const contact = useAppSelector(state => state.clientData.contact);
    const [loadingState, setLoadingState] = useState<"PENDING" | "LOADED" | "ERROR">("PENDING");
    const [tickets, setTickets] = useState<TTicketPayload[]>([]);
    const resvHash = params.resvHash;

    useEffect(() => {
        if (resvHash) {
            AxiosClient.get(`tickets?resvHash=${resvHash}`)
                .then(response => {
                    setLoadingState("LOADED");
                    setTickets(response.data.payload.tickets);
                })
                .catch(error => {
                    setLoadingState("ERROR");
                    console.error("MyTicketsPage", error);
                });
        }
    }, [resvHash]);

    const renderTickets = (tickets: TTicketPayload[]) => {
        return tickets.map(ticket => (
            <React.Fragment key={ticket.orderNumber}>
                <Ticket
                    guestName={ticket.guestName}
                    orderNumber={ticket.orderNumber}
                    productName={ticket.productName}
                    startDate={ticket.startDate}
                    productStatus={ticket.productStatus}
                />
            </React.Fragment>
        ));
    };

    const phoneFormatted = contact?.phone;
    const phone = keepNumbers(contact?.phone);
    const email = contact?.email;
    const website = contact?.website;

    const showGeneralInformation =
        loadingState === "LOADED" && t("book.mytickets.general_information").trim().length > 0;

    return (
        <div id="MyTicketsPage" className="my-tickets-page">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="logo">
                            <img
                                src={`//${window.__HOST__}/gfx/pay/${window.__SITE__}.png`}
                                alt={`${window.__SITE__} logo`}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {showGeneralInformation && (
                <div className="information-message">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="body">{t("book.mytickets.general_information")}</div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="tickets">
                            {loadingState === "LOADED" && renderTickets(tickets)}
                            {loadingState === "PENDING" && <p>{t("book.mytickets.loading_tickets")}</p>}
                            {loadingState === "ERROR" && (
                                <p>
                                    <em>{t("book.mytickets.error_loading_tickets")}</em>
                                </p>
                            )}
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <footer className="footer">
                            <div className="contact-details">
                                <h3 className="contact-details-title">{t("book.mytickets.contact.heading")}</h3>

                                <div className="contact-details-body">
                                    <p>{t("book.mytickets.contact.text")}</p>

                                    {phone && (
                                        <p className="contact-method">
                                            <PhoneIcon className="icon" size={24} />{" "}
                                            <a href={"tel:" + phone}>{phoneFormatted}</a>
                                        </p>
                                    )}

                                    {email && (
                                        <p className="contact-method">
                                            <MailIcon className="icon" size={24} />{" "}
                                            <a href={"mailto:" + email}>{email}</a>
                                        </p>
                                    )}

                                    {website && (
                                        <p className="contact-method">
                                            <LanguageIcon className="icon" size={24} />{" "}
                                            <a href={`http://${website}`} target="_blank" rel="noreferrer">
                                                {website}
                                            </a>
                                        </p>
                                    )}
                                </div>
                            </div>

                            <div className="poweredby">
                                <div>
                                    <Logotype width={100} color="white" />
                                </div>
                            </div>
                        </footer>
                    </div>
                </div>
            </div>
        </div>
    );
};
