import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Tag } from "../UI";
import { CloseIcon, TickIcon } from "../UI/R360Icons";
import { setPromoCode } from "../../store/actions/filter";

import "./PromotionCodeInput.scss";
import { textsSelector } from "../../Selectors";

// Promotion code input component
const PromotionCodeInput = props => {
    const [showInput, setShowInput] = useState(false);
    const [input, setInput] = useState();
    const dispatch = useDispatch();
    const texts = useSelector(textsSelector);
    const promoCodeError = useSelector(state => state.filter.promoCodeError);

    // Handle toggle for promotion code input field
    const handleInputToggle = () => {
        setShowInput(!showInput);
    };

    // On input value change
    const onInputChange = e => {
        setInput(e.target.value);
    };

    // Save promotion code (and reset input field)
    const savePromo = () => {
        dispatch(setPromoCode(input));

        /*if (props.onSave) {
            props.onSave(input);
        } else {
            dispatch(setPromoCode(input));
        }*/

        setInput("");
        setShowInput(false);
    };

    // Remove prmotion code
    const removePromo = () => {
        dispatch(setPromoCode(""));

        /*if (props.onRemove) {
            props.onRemove(input);
        } else {
            dispatch(setPromoCode(""));
        }*/
    };

    // Input field or button depending on 'showInput'
    const inputElement = () => {
        if (showInput) {
            return (
                <div className="promotion-code-input__input">
                    <label htmlFor="promotionCode" className="sr-only">
                        {texts?.promocode}
                    </label>
                    <input
                        type="text"
                        value={input}
                        name="promotionCode"
                        id="promotionCode"
                        placeholder={texts?.promocode}
                        onChange={onInputChange}
                    />
                    <Button
                        className="promotion-code-input__input-button"
                        ariaLabel={texts?.promocodesave}
                        onClick={savePromo}
                    >
                        <TickIcon size={30} color="transparent" color2="#ffffff" />
                    </Button>
                </div>
            );
        }
        return (
            <Button className="button--text-only" onClick={handleInputToggle}>
                <span aria-hidden="true">+</span>
                {texts?.searchpromocode}
            </Button>
        );
    };

    // Return
    return (
        <div className="promotion-code-input">
            {props.promoCode && (
                <Tag className="promotion-code-input__tag">
                    {props.promoCode}
                    <Button className="promotion-code-input__tag-button" onClick={() => removePromo()}>
                        <span className="sr-only">{texts?.promocoderemove}</span>
                        <CloseIcon size={7} color="#ffffff" />
                    </Button>
                </Tag>
            )}
            {!props.promoCode && inputElement()}
            {promoCodeError && (
                <div style={{ color: "red" }}>
                    {texts?.promocodeerror?.replace("#promocode#", JSON.parse(promoCodeError.config.data).promoCode) ||
                        "Kampanjkoden '#promocode#' finns inte.".replace(
                            "#promocode#",
                            JSON.parse(promoCodeError.config.data).promoCode
                        )}
                </div>
            )}
        </div>
    );
};

export default PromotionCodeInput;
