import React, { ChangeEvent, createRef, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { isBooked, isSeasonPass, showArrDepDates } from "../../BusinessUtils";
import { capitalizeFirstLetter, getGuestNameAndAge, isGuestRightAgeForProduct, verifyWTPNumber } from "../../Helper";
import { updateProduct } from "../../store/actions/checkoutCart";
import { updateGuestData } from "../../store/actions/guests";
import { TCartItem, TCartItemItem, TReservationType } from "../../store/types";
import { RootState } from "../..";
import { CheckoutConnectCard } from "./CheckoutConnectCard";
import {
    CheckoutConnectCardHeading,
    CheckoutConnectCardRemoveBtn,
    CheckoutConnectNoGuestsToConnect,
    TCheckoutConnectCardConnected,
} from "./CheckoutConnectCard/CheckoutConnectCard";
import {
    Button,
    CameraIcon,
    Heading,
    InfoIcon,
    Input,
    Modal,
    Notification,
    Radio,
    Select,
    Tooltip,
    style,
} from "@r360/library";
import {
    TSectionState,
    TSectionStates,
    isSectionStateInputValid,
} from "../../pages/CheckoutConnectPage/CheckoutConnectPage";
import useTranslate from "../../hooks/useTranslate";
import { UploadPhoto } from "../UploadPhoto";
import { setRequestsLoading } from "../../store/actions/axiosStatus";
import { guestsOnTravelSelector } from "../../selectors/Selectors";
import { Col, Row } from "react-bootstrap";
import { WtpScanner } from "../WtpScanner";

type TCheckoutConnectSkipass = {
    sectionStates: TSectionStates;
    changeSectionState: (arg0: string, arg1: TSectionState) => void;
    products: TCartItem[];
    type: TReservationType;
    removeProductFromCart: (arg0: TCartItem, arg1: TCartItemItem) => void;
    skipGuestsOnSkipass?: boolean;
    connectedToAccommodation?: boolean;
    requireGuestNames?: boolean;
    isFastCheckout?: boolean;
};

export const CheckoutConnectSkipass = ({
    sectionStates,
    changeSectionState,
    products,
    type,
    removeProductFromCart,
    skipGuestsOnSkipass = false,
    connectedToAccommodation = false,
    requireGuestNames = false,
    isFastCheckout = false,
}: TCheckoutConnectSkipass) => {
    const t = useTranslate();
    const dispatch = useDispatch();

    // Keep available products of the right type or those connected to an accomodation
    const skipasses = products.filter(
        product =>
            !isBooked(product) &&
            (product.type === type.lid || (connectedToAccommodation && product.connectedProductId))
    );

    const guestsOnTravel = useSelector(guestsOnTravelSelector);
    const keycardHelpImage: string = useSelector(
        (state: RootState) => state.clientData.options.general?.keycardhelpimage
    );
    const keycardHelpTextAvailable = !t("book.checkout.skipass_help_information").includes("book.");
    const keycardHelpAvailable = keycardHelpImage || keycardHelpTextAvailable;
    const { requestsLoading } = useSelector((state: RootState) => state.axiosStatus);
    const { isMobile, isTablet, isDesktop, isIframe, iframeOffsetTop, isDesktopNewDesign } = useSelector(
        (state: RootState) => state.window
    );
    const [itemKeycardsTouched, setItemKeycardsTouched] = useState<{ [key: string]: boolean }>();
    const [itemKeyCardValidating, setItemKeyCardValidating] = useState<string>();
    const [showWtpScannerModal, setShowWtpScannerModal] = useState<{ [key: string]: boolean }>();
    const [showKeycardHelpModal, setShowKeycardHelpModal] = useState(false);

    const sectionState = sectionStates[type.lid];
    const verifyingWTPInProgress = requestsLoading["verifyWTPNumber"];

    const keycardInputRefs = useRef<any | null>(null);
    const itemsLength = skipasses.map(x => x.items).flat().length;

    useEffect(() => {
        skipasses.forEach(skipass => {
            if (skipass.items && skipass.items.length === skipass.count) {
                // Empty statement.
            } else {
                if (skipass.items) {
                    let rest = skipass.count - skipass.items.length;
                    const items = skipass.items;
                    if (rest > 0) {
                        //console.log("CREATE " + rest + " skipaaess");

                        for (let index = 0; index < rest; index++) {
                            const uuid = uuidv4();
                            items.push({
                                id: uuid,
                                guestId: null,
                                keycardNo: "",
                                ...(isFastCheckout ? { firstname: "", lastname: "" } : {}),
                            });
                        }
                    } else {
                        rest = rest * -1;
                        //console.log("Remove " + rest + " skipaaess");
                        for (let index = 0; index < rest; index++) {
                            items.pop();
                        }
                    }
                    dispatch(updateProduct(skipass, { key: "items", value: items }));
                } else {
                    const items = [];
                    for (let count = 0; count < skipass.count; count++) {
                        const uuid = uuidv4();
                        items.push({
                            id: uuid,
                            guestId: null,
                            keycardNo: "",
                            ...(isFastCheckout ? { firstname: "", lastname: "" } : {}),
                        });
                    }
                    dispatch(updateProduct(skipass, { key: "items", value: items }));
                }
            }
        });
    }, [dispatch, skipasses]);

    /*  Blur keycard inputs when the length of items changes.
        This triggers the validation of the keycardNo.
        We do this because we need to revalidate the keycards when a skipass is removed, in case we had an error with multiple keycards with the same keycardNo. 
    */
    useEffect(() => {
        Object.values(keycardInputRefs.current).forEach((x: any) => {
            if (!x?.input.current) {
                return;
            }
            x.input.current?.focus();
            x.input.current?.blur();
        });
    }, [itemsLength]);

    keycardInputRefs.current = skipasses
        .map(skipass => skipass.items)
        .flat()
        .reduce((acc: { [key: string]: { input: any } }, item) => {
            if (!item?.id) {
                return acc;
            }

            acc[item.id] = {
                input: createRef(),
            };

            return acc;
        }, {});

    const handleProductGuestIdChange = (event: ChangeEvent<any>, product: TCartItem, productItem: TCartItemItem) => {
        const { value } = event.target;

        // If the guest selected has a keycardNo, assign it to the skipass item.
        const guest = guestsOnTravel[value];

        dispatch(
            updateProduct(product, {
                key: "items",
                value: product.items?.map(item =>
                    item.id === productItem.id ? { ...item, guestId: value, keycardNo: guest?.keycardNo || "" } : item
                ),
            })
        );
    };

    const handleProductChange = (
        event: ChangeEvent<any>,
        product: TCartItem,
        productItem: TCartItemItem,
        key: string
    ) => {
        const { value } = event.target;

        dispatch(
            updateProduct(product, {
                key: "items",
                value: product.items?.map(item => (item.id === productItem.id ? { ...item, [key]: value } : item)),
            })
        );
    };

    const verifyKeycardNumber = (keycardNo: string) => {
        dispatch(setRequestsLoading({ request: "verifyWTPNumber", loading: true }));

        return verifyWTPNumber(keycardNo)
            .then(response => {
                const valid = response.data.payload.valid;
                const finalKeycardNo = response.data.payload.finalKeycardNo;

                dispatch(setRequestsLoading({ request: "verifyWTPNumber", loading: false }));
                return { valid, finalKeycardNo };
            })
            .catch(error => {
                dispatch(setRequestsLoading({ request: "verifyWTPNumber", loading: false }));
                console.log(error);
            });
    };

    const updateProductAndGuestKeycardNumber = (
        keycardNo: string,
        product: TCartItem,
        productItem: TCartItemItem,
        valid: boolean
    ) => {
        if (!product || !productItem) {
            return;
        }

        dispatch(
            updateProduct(product, {
                key: "items",
                value: product.items?.map(item => (item.id === productItem.id ? { ...item, keycardNo, valid } : item)),
            })
        );

        if (!skipGuestsOnSkipass) {
            dispatch(updateGuestData(productItem.guestId, "keycardNo", keycardNo));
        }
    };

    /**
     * Check if any other skipass have a invalid information.
     *
     * @param {string} id
     * @returns A boolean saying if there are other invalid skipasses.
     */
    const anyOtherInvalidKeycards = (id: string) =>
        skipasses
            .map(skipass => skipass.items)
            .flat()
            .filter(item => item?.id !== id)
            // Return true if:
            .some(
                item =>
                    item?.keycard === null ||
                    item?.keycard === undefined || // Keycard isn't selected
                    (!item?.guestId && !skipGuestsOnSkipass) || // Guest is not connected
                    (!item?.valid && item?.keycard === "1") // Item is not valid and keycard requires keycardNo
            );

    const renderSkipasses = () => {
        return skipasses.map((skipass, skipassIndex) => {
            return skipass.items?.map((item, itemIndex) => {
                const selectedGuest = guestsOnTravel?.[item.guestId ?? ""];
                const guestSelectOptions = Object.entries(guestsOnTravel)
                    .filter(([, guest]) => isGuestRightAgeForProduct(guest, skipass))
                    .map(([id, guest]) => ({
                        value: id,
                        label: getGuestNameAndAge(guest, "år", skipass),
                    }));

                const value1Radio = document.getElementById(`skipass-radio-1-${item.id}`) as HTMLInputElement;
                const keycardInputRef = keycardInputRefs.current[item.id ?? ""];
                const isKeycardTouched = item.id && itemKeycardsTouched?.[item.id];
                const keycardBeingValidated = verifyingWTPInProgress && itemKeyCardValidating === item.id;
                const isLastConnectedSkipass =
                    !!skipass.items && skipasses.length - 1 === skipassIndex && skipass.items.length - 1 === itemIndex;

                let connected: TCheckoutConnectCardConnected = "none";
                if (connectedToAccommodation && isLastConnectedSkipass) {
                    connected = "above";
                } else if (connectedToAccommodation && !isLastConnectedSkipass) {
                    connected = "above-and-below";
                }

                // Set a custom error on the radio controlling the keycard input (since the keycard input might not be available in the DOM)
                // if existing keycard is chosen and keycard number is empty, or if the keycard number exists but is invalid
                // We do this to ensure that a customer never can continue with an empty or invaldid keycard number
                if (item.keycard === "1" && (!item.keycardNo || (!item.valid && item.keycardNo))) {
                    value1Radio?.setCustomValidity("Force error");
                }

                return (
                    <div key={`${skipass.activitylid} - ${itemIndex}`}>
                        <CheckoutConnectCard connected={connected}>
                            <div className="row">
                                <div className="col-12 col-lg-5 u-d-flex u-flex-column u-justify-content-between u-mb-0">
                                    <div>
                                        <div className="u-mb-12">
                                            <CheckoutConnectCardHeading
                                                primaryHeading={skipass.groupTitle}
                                                secondaryHeading={
                                                    skipass.title ??
                                                    `${skipass.minage} - ${skipass.maxage} ${t("book.general.years")}`
                                                }
                                                secondaryHeadingColor={style.alertColor}
                                            />
                                        </div>
                                        <div className="u-mb-6">
                                            {!isSeasonPass(skipass) && (
                                                <span>
                                                    {capitalizeFirstLetter(showArrDepDates(skipass, "dddd Do MMMM"))}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    {isDesktopNewDesign && (
                                        <div className="u-pt-18">
                                            <CheckoutConnectCardRemoveBtn
                                                onClick={() => removeProductFromCart(skipass, item)}
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className="col-12 col-lg-2 u-mb-0 u-d-flex u-justify-content-center u-mb-18 u-mb-lg-0">
                                    {!skipGuestsOnSkipass &&
                                        item.guestId &&
                                        !!skipass?.photomandatory &&
                                        skipass.connected && (
                                            <div
                                                style={{
                                                    outline:
                                                        sectionState.status === "error" && !selectedGuest.imagehash
                                                            ? "1px solid red"
                                                            : "none",
                                                    outlineOffset: "6px",
                                                }}
                                            >
                                                <UploadPhoto
                                                    guestlid={parseInt(item.guestId) ?? parseInt("")}
                                                    imageHash={selectedGuest?.imagehash}
                                                />
                                                <Input
                                                    required
                                                    type="text"
                                                    className="u-d-none"
                                                    value={selectedGuest?.imagehash ? item.guestId : ""}
                                                />
                                            </div>
                                        )}
                                </div>
                                <div className="col-12 col-lg-5 u-mb-0">
                                    {isFastCheckout && skipGuestsOnSkipass && requireGuestNames && (
                                        <Row>
                                            <Col sm={6}>
                                                <Input
                                                    required
                                                    type="text"
                                                    placeholder={t("book.account.firstname") + "*"}
                                                    value={item.firstname}
                                                    onChange={e => handleProductChange(e, skipass, item, "firstname")}
                                                    isValid={
                                                        sectionState.status === "error" ? !!item.firstname : undefined
                                                    }
                                                />
                                            </Col>
                                            <Col sm={6}>
                                                <Input
                                                    required
                                                    type="text"
                                                    placeholder={t("book.account.lastname") + "*"}
                                                    value={item.lastname}
                                                    onChange={e => handleProductChange(e, skipass, item, "lastname")}
                                                    isValid={
                                                        sectionState.status === "error" ? !!item.lastname : undefined
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    )}
                                    {!skipGuestsOnSkipass && (
                                        <div className="u-mb-12">
                                            <Select
                                                required
                                                label={
                                                    <span>
                                                        {`${t("book.checkout.for_who")} `}
                                                        <span style={{ fontWeight: 400 }} className="u-fw-default">{`(${
                                                            skipass.minage
                                                        } - ${skipass.maxage} ${t("book.general.years")})`}</span>
                                                    </span>
                                                }
                                                defaultValue={(item.guestId ?? "").toString()}
                                                options={guestSelectOptions}
                                                fullWidth
                                                type="standard"
                                                onSelectCallbackWithEvent={e =>
                                                    handleProductGuestIdChange(e, skipass, item)
                                                }
                                                placeholder={t("book.checkout.pick_guest")}
                                                useFirstAsDefault={false}
                                                disabled={!guestSelectOptions.length}
                                                isValid={isSectionStateInputValid?.(sectionState, item.guestId ?? "")}
                                                showAsterisk
                                                boldLabel
                                            />
                                            {!guestSelectOptions.length && (
                                                <CheckoutConnectNoGuestsToConnect
                                                    minAge={skipass.minage}
                                                    maxAge={skipass.maxage}
                                                />
                                            )}
                                        </div>
                                    )}
                                    {!skipass.connected ? (
                                        <div className="u-d-flex u-align-items-center u-gap-6">
                                            <InfoIcon color={style.brandBlueColor} />
                                            <span>{t("book.checkout.no_skipass_needed")}</span>
                                        </div>
                                    ) : (
                                        <div
                                            className="u-d-flex u-gap-12"
                                            style={{
                                                outline:
                                                    sectionState.status === "error" &&
                                                    !item.keycard &&
                                                    item.keycard !== "0" &&
                                                    (item.guestId || skipGuestsOnSkipass)
                                                        ? "1px solid red"
                                                        : "none",
                                                outlineOffset: "6px",
                                            }}
                                        >
                                            <Radio
                                                required
                                                label={`${t("book.checkout.new_keycard")} (${skipass.keycardPrice} ${
                                                    skipass.curr
                                                })`}
                                                value={0}
                                                name={`skipass-radio-${item.id}`}
                                                defaultChecked={item.keycard === "0" && skipass.connected}
                                                onChange={e => {
                                                    if (e.target.checked) {
                                                        value1Radio.setCustomValidity("");
                                                    }

                                                    handleProductChange(e, skipass, item, "keycard");
                                                }}
                                                disabled={!item.guestId && !skipGuestsOnSkipass}
                                            />
                                            <Radio
                                                id={`skipass-radio-1-${item.id}`}
                                                required
                                                label={t("book.checkout.existing_keycard")}
                                                value={1}
                                                name={`skipass-radio-${item.id}`}
                                                defaultChecked={item.keycard === "1" && skipass.connected}
                                                onChange={e => {
                                                    if (!item.valid && e.target.checked) {
                                                        console.log(item);
                                                        e.target.setCustomValidity("Force error");
                                                    }

                                                    // Validate the keycardNo if one exists
                                                    setTimeout(() => {
                                                        if (item.keycardNo) {
                                                            keycardInputRef.input.current?.focus();
                                                            keycardInputRef.input.current?.blur();
                                                        }
                                                    }, 500);

                                                    handleProductChange(e, skipass, item, "keycard");
                                                }}
                                                disabled={!item.guestId && !skipGuestsOnSkipass}
                                            />
                                        </div>
                                    )}
                                    {item.keycard === "1" &&
                                        (item.guestId || skipGuestsOnSkipass) &&
                                        skipass.connected && (
                                            <div className="u-pt-18">
                                                <span className="u-fw-medium">{t("book.checkout.keycard_number")}</span>
                                                <div className="u-d-flex u-align-items-center">
                                                    <Input
                                                        ref={keycardInputRef.input}
                                                        id={`keycard-${item.id}`}
                                                        autoFocus
                                                        withSubmitButton
                                                        submitButtonLabel="OK"
                                                        isValid={
                                                            sectionState?.status === "error" && !item.valid
                                                                ? false // Not valid if section has error and keycard is not valid
                                                                : sectionState?.status === "success" && item.valid
                                                                ? true // Valid if section is valid and item is valid
                                                                : !item.keycardNo || !isKeycardTouched
                                                                ? undefined // undefined if keycard is empty or never touched
                                                                : !!item.valid // fallback if no other conditions are met
                                                        }
                                                        errorMessage={t("book.checkout.skipass_validation_errror")}
                                                        name="keycardNo"
                                                        placeholder={t("book.checkout.keycard_number")}
                                                        value={
                                                            keycardBeingValidated
                                                                ? t("book.checkout.validating_keycard")
                                                                : item.keycardNo
                                                        }
                                                        onChange={e => {
                                                            handleProductChange(e, skipass, item, "keycardNo");
                                                        }}
                                                        onKeyDown={e => {
                                                            e.key === "Enter" && e.preventDefault();
                                                            if (e.key === "Enter") {
                                                                e.currentTarget.blur();
                                                            }
                                                        }}
                                                        disabled={
                                                            (!item.guestId && !skipGuestsOnSkipass) ||
                                                            keycardBeingValidated
                                                        }
                                                        onBlur={e => {
                                                            // setCustomValidity is used as a workaround to make server side validation work. Formik could be used instead.
                                                            // Providing a message to setCustomValidity forces that native validation to get an invalid state.
                                                            // The synthetic e need to trigger .persist() to be used after the server side validation.
                                                            e.persist();
                                                            const keycardNo = e.target.value;

                                                            if (!item.valid && !keycardNo) {
                                                                return;
                                                            }
                                                            // Update state in order to know which items keycard is being validated
                                                            setItemKeyCardValidating(item.id);
                                                            let keycardIsAlreadyUsedByOtherGuest = false;
                                                            let guestHasDifferentKeycards = false;
                                                            // Check whether the keycardNo is already used by another or if the connected guest already have another keycardNo set
                                                            skipasses.forEach(skipass => {
                                                                skipass.items?.forEach(skipassItem => {
                                                                    if (
                                                                        skipassItem.keycardNo === keycardNo && // If keycardNo is already used
                                                                        skipassItem.id !== item.id && // and is not the current item
                                                                        (skipGuestsOnSkipass ||
                                                                            skipassItem.guestId !== item.guestId) // ... and is used on another guest
                                                                    ) {
                                                                        keycardIsAlreadyUsedByOtherGuest = true;
                                                                    }
                                                                    if (
                                                                        item.guestId &&
                                                                        !skipGuestsOnSkipass &&
                                                                        skipassItem.guestId === item.guestId && // If item exists that is assigned to the guest
                                                                        skipassItem.keycardNo &&
                                                                        skipassItem.keycardNo !== keycardNo // ... and the keycardNo is different and not empty
                                                                    ) {
                                                                        guestHasDifferentKeycards = true;
                                                                    }
                                                                });
                                                            });

                                                            if (
                                                                !keycardNo ||
                                                                guestHasDifferentKeycards ||
                                                                keycardIsAlreadyUsedByOtherGuest
                                                            ) {
                                                                updateProductAndGuestKeycardNumber(
                                                                    keycardNo,
                                                                    skipass,
                                                                    item,
                                                                    false
                                                                );
                                                                value1Radio.setCustomValidity("Force error");
                                                                changeSectionState?.(type.lid.toString(), {
                                                                    status: "error",
                                                                });
                                                                setItemKeycardsTouched(prevState => ({
                                                                    ...prevState,
                                                                    [item.id ?? ""]: true,
                                                                }));
                                                                setShowWtpScannerModal(prevState => ({
                                                                    ...prevState,
                                                                    [item.id ?? ""]: false,
                                                                }));
                                                                return;
                                                            }
                                                            verifyKeycardNumber(keycardNo).then(response => {
                                                                if (response) {
                                                                    if (response.valid) {
                                                                        // Reset custom validity if valid keycard number
                                                                        value1Radio?.setCustomValidity("");
                                                                        e.target.value = response.finalKeycardNo;
                                                                        // Change validation from error to success if all keycards are valid
                                                                        if (
                                                                            item.id &&
                                                                            !anyOtherInvalidKeycards(item.id) &&
                                                                            sectionState.status === "error"
                                                                        ) {
                                                                            changeSectionState?.(type.lid.toString(), {
                                                                                status: "success",
                                                                            });
                                                                        }
                                                                        // Set status to error if keycard number is invalid
                                                                    } else {
                                                                        value1Radio?.setCustomValidity("Force error");

                                                                        changeSectionState?.(type.lid.toString(), {
                                                                            status: "error",
                                                                        });
                                                                    }

                                                                    updateProductAndGuestKeycardNumber(
                                                                        response.valid
                                                                            ? response.finalKeycardNo
                                                                            : keycardNo,
                                                                        skipass,
                                                                        item,
                                                                        response.valid
                                                                    );

                                                                    setItemKeycardsTouched(prevState => ({
                                                                        ...prevState,
                                                                        [item.id ?? ""]: true,
                                                                    }));
                                                                }
                                                            });
                                                        }}
                                                        fullWidth
                                                    />
                                                    <div
                                                        role="button"
                                                        className="u-p-12"
                                                        onClick={() =>
                                                            setShowWtpScannerModal(prevState => ({
                                                                ...prevState,
                                                                [item.id ?? ""]: true,
                                                            }))
                                                        }
                                                    >
                                                        {isDesktopNewDesign ? (
                                                            <Tooltip toolTipContent={t("book.wtp_scanner_tooltip")}>
                                                                <CameraIcon size={20} color={style.brandBlueColor} />
                                                            </Tooltip>
                                                        ) : (
                                                            <CameraIcon size={20} color={style.brandBlueColor} />
                                                        )}
                                                    </div>
                                                </div>
                                                <WtpScanner
                                                    showModal={showWtpScannerModal?.[item.id ?? ""] ?? false}
                                                    onCloseModal={() =>
                                                        setShowWtpScannerModal(prevState => ({
                                                            ...prevState,
                                                            [item.id ?? ""]: false,
                                                        }))
                                                    }
                                                    onMatch={cardNumber => {
                                                        if (keycardInputRef) {
                                                            if (showWtpScannerModal?.[item.id ?? ""]) {
                                                                setShowWtpScannerModal(prevState => ({
                                                                    ...prevState,
                                                                    [item.id ?? ""]: false,
                                                                }));
                                                            }

                                                            const input = keycardInputRef.input.current;
                                                            input.value = cardNumber;
                                                            input?.focus();
                                                            input?.blur(); // Trigger validation
                                                        }
                                                    }}
                                                />
                                                {keycardHelpAvailable && (
                                                    <div className="u-pt-12">
                                                        <Button
                                                            type="tertiary"
                                                            leftIcon={<InfoIcon />}
                                                            onClick={() => setShowKeycardHelpModal(true)}
                                                        >
                                                            {t("book.general.need_help")}
                                                        </Button>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    {!isDesktopNewDesign && (
                                        <div className="u-pt-18">
                                            <CheckoutConnectCardRemoveBtn
                                                onClick={() => removeProductFromCart(skipass, item)}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </CheckoutConnectCard>
                    </div>
                );
            });
        });
    };

    return (
        <>
            {!connectedToAccommodation && (
                <div className="u-mb-18">
                    <Notification type="info">{t("book.checkout.skipass_info")}</Notification>
                </div>
            )}
            {renderSkipasses()}
            {keycardHelpAvailable && (
                <Modal
                    open={showKeycardHelpModal}
                    onClose={() => setShowKeycardHelpModal(false)}
                    size="lg"
                    {...(isIframe && isDesktop && { fromTop: 120 + iframeOffsetTop + "px" })}
                    {...(isIframe &&
                        (isMobile || isTablet) && {
                            fromTop: 80 + iframeOffsetTop + "px",
                        })}
                >
                    <div className="u-pt-24">
                        {keycardHelpImage && (
                            <img src={keycardHelpImage} style={{ maxWidth: "100%" }} className="u-mb-24" />
                        )}
                        {keycardHelpTextAvailable && (
                            <>
                                <Heading type="h2" styleAs="h5">
                                    {t("book.checkout.keycard_number")}
                                </Heading>
                                <p>{t("book.checkout.skipass_help_information")}</p>
                            </>
                        )}
                    </div>
                </Modal>
            )}
        </>
    );
};

export default CheckoutConnectSkipass;
