import * as React from "react";
import defautIconStats from "../Icon.types";
import Theme from "../../Theme";

const CrossCountrySkiing = ({
    color = Theme.palette.primary,
    size = defautIconStats.size,
    iconClass = defautIconStats.width,
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            enableBackground="new 0 0 236 236"
            viewBox="0 0 236 236"
            style={{ height: size, width: size }}
            className={iconClass}
        >
            <g transform="matrix(1.2,0,0,1.2,-25.599919128417866,-25.600102233886645)">
                <switch xmlns="http://www.w3.org/2000/svg">
                    <g>
                        <path
                            d="m173.089 43.6c.505 1.565.777 3.234.777 4.967 0 8.931-7.24 16.17-16.17 16.17-2.666 0-5.181-.645-7.398-1.788 2.096 6.5 8.195 11.203 15.393 11.203 8.93 0 16.17-7.24 16.17-16.17-.001-6.264-3.563-11.696-8.772-14.382z"
                            fill="#fbfbfb"
                        />
                        <path
                            d="m103.119 101.321c-.229 0-.463-.032-.695-.099l-61.692-17.828c-1.327-.383-2.091-1.769-1.708-3.096.383-1.326 1.769-2.093 3.096-1.708l61.692 17.829c1.327.383 2.091 1.77 1.708 3.096-.317 1.094-1.316 1.806-2.401 1.806z"
                            fill={color}
                        />
                        <path
                            d="m193.005 198.201c-.135 0-.271-.011-.408-.034-1.362-.224-2.286-1.51-2.062-2.872l12.798-77.888c.224-1.362 1.51-2.286 2.872-2.062s2.286 1.51 2.061 2.872l-12.798 77.888c-.2 1.226-1.26 2.096-2.463 2.096z"
                            fill={color}
                        />
                        <g fill="#846f75">
                            <path
                                d="m165.69 76.652c10.294 0 18.67-8.375 18.67-18.67 0-10.294-8.376-18.67-18.67-18.67s-18.67 8.375-18.67 18.67 8.376 18.67 18.67 18.67zm0-32.339c7.538 0 13.67 6.132 13.67 13.67s-6.132 13.67-13.67 13.67-13.67-6.132-13.67-13.67 6.133-13.67 13.67-13.67z"
                                fill={color}
                            />
                            <path
                                d="m216.342 204.596c-.976-.977-2.56-.977-3.535 0-4.575 4.575-10.658 7.094-17.128 7.094h-31.144c.385-.949.587-1.973.584-3.028l-.094-37.413c-.005-1.823-.641-3.607-1.789-5.024l-19.848-24.456 18.685-42.267 14.304 13.451c1.323 1.246 3.051 2.009 4.863 2.15l26.952 2.099c4.406.342 8.26-2.963 8.601-7.36.342-4.4-2.96-8.26-7.36-8.603 0 0 0 0-.001 0l-24.14-1.879-20.487-19.266c-1.89-1.778-4.534-2.53-7.075-2.015-2.541.516-4.683 2.238-5.732 4.609l-25.235 57.086c-1.221 2.762-.797 5.936 1.106 8.281l21.151 26.063.087 34.583c.003 1.057.216 2.064.593 2.988h-23.132c-1.381 0-2.5 1.119-2.5 2.5s1.119 2.5 2.5 2.5h69.11c7.806 0 15.145-3.039 20.664-8.559.976-.975.976-2.558 0-3.534zm-84.591-59.69c-.715-.881-.874-2.072-.416-3.109l25.235-57.085c.393-.89 1.198-1.537 2.153-1.73.198-.041.397-.06.595-.06.757 0 1.498.288 2.06.816l21.128 19.869c.415.39.951.627 1.519.671l25.017 1.948c1.652.128 2.892 1.578 2.763 3.23-.128 1.65-1.573 2.893-3.23 2.762l-26.95-2.099c-.68-.053-1.328-.339-1.825-.807l-16.877-15.871c-.591-.556-1.415-.792-2.209-.629-.795.161-1.462.698-1.79 1.439l-20.73 46.894c-.379.858-.246 1.857.345 2.586l20.811 25.643c.432.533.67 1.203.672 1.887l.094 37.413c.002.803-.309 1.558-.875 2.127s-1.32.884-2.131.886c-1.653 0-3.001-1.345-3.005-2.998l-.09-35.467c-.001-.571-.199-1.125-.559-1.569z"
                                fill={color}
                            />
                            <path
                                d="m108.624 107.491c2.048 0 3.997-.773 5.487-2.178l16.776-15.798 7.622 1.211c2.113.335 4.229-.17 5.96-1.426s2.869-3.111 3.205-5.224c.692-4.359-2.291-8.47-6.65-9.163l-11.51-1.828c-2.465-.384-4.924.369-6.741 2.079l-19.638 18.494c-3.213 3.027-3.365 8.103-.34 11.316 1.506 1.6 3.631 2.517 5.829 2.517zm-2.061-10.194 19.637-18.492c.564-.532 1.295-.818 2.056-.818.158 0 .317.012.477.038l11.506 1.828c1.636.26 2.756 1.803 2.496 3.44-.126.793-.553 1.49-1.203 1.961-.648.471-1.439.663-2.237.535l-8.836-1.404c-.766-.12-1.542.119-2.106.649l-17.67 16.64c-1.169 1.103-3.147 1.041-4.248-.128-1.136-1.206-1.078-3.112.128-4.249z"
                                fill={color}
                            />
                            <path
                                d="m128.391 155.777c.109-2.136-.62-4.186-2.052-5.772-1.433-1.588-3.398-2.523-5.534-2.632-2.128-.103-4.186.62-5.773 2.053l-8.625 7.786-31.864-14.605c-3.063-1.402-6.56-.699-8.863 1.497l-8.332-28.06c-.393-1.324-1.782-2.079-3.108-1.685-1.324.393-2.078 1.785-1.685 3.108l17.331 58.367c2.803 9.442 10.51 16.673 20.112 18.869.188.043.375.063.56.063 1.14 0 2.17-.785 2.435-1.943.308-1.346-.534-2.687-1.879-2.995-7.846-1.794-14.143-7.702-16.434-15.417l-4.856-16.354 34.701 15.905c1.054.483 2.176.729 3.335.729 1.986 0 3.892-.733 5.365-2.063l12.536-11.315c1.586-1.435 2.52-3.4 2.63-5.536zm-5.982 1.822-12.536 11.316c-.875.791-2.189.995-3.266.501l-36.653-16.799c-1.506-.69-2.17-2.479-1.48-3.984.505-1.102 1.598-1.754 2.738-1.754.418 0 .843.088 1.247.273l33.359 15.29c.908.416 1.976.252 2.717-.417l9.847-8.888c0-.001 0-.001 0-.001.596-.537 1.366-.811 2.167-.77.802.041 1.54.392 2.078.988s.811 1.365.77 2.167-.392 1.54-.988 2.078z"
                                fill={color}
                            />
                        </g>
                    </g>
                </switch>
            </g>
        </svg>
    );
};

export default CrossCountrySkiing;
