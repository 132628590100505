import React from "react";
import { formatPrice } from "../../Helper";

import "./ProductPrice.scss";

const ProductPrice = ({ currency, price, fromprice = false, ordinaryprice = -1, discounted = false }) => {
    // Support for ordinary prices
    if (ordinaryprice >= 1) {
        return (
            <span className="ProductPrice">
                <span className={discounted ? "discountedprice" : ""}>
                    {fromprice && `fr.`} {formatPrice(price, currency)}
                </span>
                <s className="ordinaryprice">{formatPrice(ordinaryprice, currency)}</s>
            </span>
        );
    } else {
        // Show regular price
        return (
            <span className="ProductPrice">
                {fromprice && `fr.`} {formatPrice(price, currency)}
            </span>
        );
    }
};

export default ProductPrice;
