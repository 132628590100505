import moment from "moment-timezone";
import { useEffect } from "react";
import { useLocation } from "react-router";
import { RootState } from "..";
import { TAge } from "../components/MenuSearch/MenuSearch";
import { optionsSelector } from "../Selectors";
import { fetchReservationTypeResults } from "../store/actions/reservationResult";
import { TReservationType } from "../store/types";
import useAppDispatch from "./useAppDispatch";
import useAppSelector from "./useAppSelector";
import { resvTypeByLid, userSelector } from "../selectors/Selectors";
import useMemoizeArg from "./useMemoizeArg";

// interface TUseChangeSearch {
//     reservationResultSearch?: TSearch;
//     reservationTypeId: string;
//     grouppoollid?: string;
//     skipGuestsOnSearchWithPrice: boolean;
//     allowSearchWithoutPrice: boolean;
//     disabled: boolean;
// }

const useChangeSearch = ({
    reservationResultSearch,
    reservationTypeId,
    grouppoollid,
    skipGuestsOnSearchWithPrice,
    allowSearchWithoutPrice,
    filterOnDays = true,
    disabled = false,
}: any) => {
    const location = useLocation();
    const clientData = useAppSelector((state: RootState) => state.clientData);
    const guestData = clientData?.options?.general;
    const filter = useAppSelector(state => state.filter);
    const dispatch = useAppDispatch();
    const newDesignMenu = useAppSelector(optionsSelector).layout?.new_design_menu;
    const reservationType = useAppSelector((state: RootState) => resvTypeByLid(state, reservationTypeId));
    const useAutoSearch = !!reservationType?.useAutoSearch;
    const autoSearchFromDate = reservationType?.autoSearchFromDate || "";
    const autoSearchToDate = reservationType?.autoSearchFromDate || "";
    const isLoggedIn = !!useAppSelector(userSelector);

    useEffect(() => {
        if (disabled || !reservationType) return;
        const urlSearchParams = new URLSearchParams(location.search);
        const params = Object.fromEntries(urlSearchParams.entries());

        let checkPriceAndAvailability = true;
        let startDate;
        let endDate;
        let guestArray = [];
        const requiredAges: { [key: string]: string[] } = {};

        if (!skipGuestsOnSearchWithPrice) {
            const obj = JSON.parse(guestData?.ages);
            const ages = obj[reservationTypeId];

            Object.keys(params)
                .filter(key => key.startsWith("ages-for-"))
                .forEach(key => {
                    requiredAges[key.replace("ages-for-", "")] = params[key].split("-");
                });

            guestArray = (ages || [])
                .filter((age: TAge) => parseInt(params[age.key], 10) > 0)
                .map((age: TAge) =>
                    [...Array(parseInt(params[age.key], 10))].map((_e, i) => {
                        return age.requiresAge && requiredAges[age.key]
                            ? parseInt(requiredAges[age.key][i])
                            : age.value;
                    })
                )
                .flat();

            // If no guests are provided in the params, try to find guests from the previous search result.
            if (!guestArray.length) {
                guestArray = reservationResultSearch?.guests || [];
            }
        }

        if (
            !guestArray.length &&
            allowSearchWithoutPrice &&
            (params.checkPrice === "0" ||
                !reservationResultSearch ||
                reservationResultSearch?.checkPriceAndAvailability === false)
        ) {
            // If no search exists and no search params are provided, search for everything in the reservation type
            // without checking price and availablity.
            checkPriceAndAvailability = false;
            startDate = moment();
            endDate = moment().add(1, "year");
        } else if (useAutoSearch) {
            // If autoSearchFromDate and autoSearchToDate is provided, we always expect the autoSearchToDate to be later than autoSearchFromDate.
            startDate = moment().isAfter(moment(autoSearchFromDate)) ? moment() : moment(autoSearchFromDate);
            endDate = startDate.isBefore(moment(autoSearchToDate)) ? moment(autoSearchToDate) : moment().add(1, "year");
        } else if (
            (params.startDate || reservationResultSearch?.arrdate) &&
            (params.endDate || reservationResultSearch?.depdate)
        ) {
            startDate = moment(params.startDate || reservationResultSearch?.arrdate);
            endDate = moment(params.endDate || reservationResultSearch?.depdate);

            if (skipGuestsOnSearchWithPrice === false && guestArray.length === 0) {
                console.log(
                    "ChangeSearch - useEffect[location.search] - Early return for no guests on search with price"
                );
                return;
            }
        } else {
            return;
        }

        if (!startDate?.isValid?.() || !endDate?.isValid?.()) {
            console.log("ChangeSearch - useEffect[location.search] - Early return for invalid date");
            return;
        }

        const requestData = {
            resvtype: reservationTypeId,
            oldStartDate: reservationResultSearch?.arrdate,
            oldEndDate: reservationResultSearch?.depdate,
            startdate: startDate.format("YYYY-MM-DD"),
            enddate: endDate.format("YYYY-MM-DD"),
            oldAges: reservationResultSearch?.guests || [],
            ages: guestArray,
            oldPromoCode: "",
            promoCode: "",
            oldGrouppoollid: "",
            grouppoollid: "",
            oldPricecode: "",
            pricecode: "",
            filterOnDays,
            oldIsLoggedIn: reservationResultSearch?.isLoggedIn,
            isLoggedIn,
            useAutoSearch,
        };

        // The new menu forces the user to write a promocode for each reservationtype
        const getPromoCodeFromState = () => {
            if (newDesignMenu) {
                if (filter.resvTypePromoCodes) {
                    return filter.resvTypePromoCodes[reservationTypeId]?.code || "";
                } else {
                    return "";
                }
            } else {
                return filter.promoCode || "";
            }
        };

        // Only search using promo code, groupoollid and pricecode when searching with price.
        requestData.oldPromoCode = reservationResultSearch?.promoCode || "";
        requestData.promoCode = getPromoCodeFromState();
        if (checkPriceAndAvailability) {
            requestData.oldGrouppoollid = reservationResultSearch?.grouppoollid || "";
            requestData.grouppoollid = grouppoollid || "";
            requestData.oldPricecode = reservationResultSearch?.pricecode || "";
            requestData.pricecode = params.pricecode || "";
        }

        dispatch(fetchReservationTypeResults(requestData, checkPriceAndAvailability));
    }, [
        dispatch,
        location.search,
        filter.promoCode,
        filter.resvTypePromoCodes,
        reservationTypeId,
        useMemoizeArg(reservationResultSearch),
        grouppoollid,
        guestData?.ages,
        skipGuestsOnSearchWithPrice,
        allowSearchWithoutPrice,
        isLoggedIn,
        useAutoSearch,
    ]);

    /*useEffect(() => {
        const urlSearchParams = new URLSearchParams(location.search);
        const params = Object.fromEntries(urlSearchParams.entries());

        if ((params["promoCode"] || "") !== "" && params["promoCode"] !== (filter.promoCode || "")) {
            dispatch(setPromoCode(params["promoCode"]));
        }
    }, [location.search, filter.promoCode]);*/
};

export default useChangeSearch;
