import React from "react";

import { Button, TextField } from "..";

// Search input group component
class SearchInputGroup extends React.Component {
    render() {
        return (
            <div className="mb-3 input-group">
                <TextField />
                <div className="input-group-append">
                    <Button text="Sök" />
                </div>
            </div>
        );
    }
}

export default SearchInputGroup;
