import React, { useState } from "react";
import { useSelector } from "react-redux";
import { cancelProductFromReservation } from "../../store/actions/account";
import { emptyCartProducts } from "../../store/actions/checkoutCart";
import { TCartItem } from "../../store/types";
import { RootState } from "../..";
import useAppDispatch from "../../hooks/useAppDispatch";
import { Button, ConfirmationModal } from "@r360/library";
import useTranslate from "../../hooks/useTranslate";

type TBookingSummaryCancelProductButton = {
    allowCancellation: boolean;
    resvid: string;
    detlid: number;
    productTitle: string;
    connectedProducts?: TCartItem[];
    loadingReservation?: boolean;
};

const BookingSummaryCancellationButton = ({
    allowCancellation,
    resvid,
    detlid,
    productTitle = "",
    connectedProducts = [],
    loadingReservation = false,
}: TBookingSummaryCancelProductButton) => {
    const dispatch = useAppDispatch();
    const t = useTranslate();
    const userToken = useSelector((state: RootState) => state.account.token);
    const { requestsLoading } = useSelector((state: RootState) => state.axiosStatus);
    const cancellingProductFromReservation = requestsLoading["cancelProductFromReservation"];
    const [showCancelProductModal, setShowCancelProductModal] = useState(false);

    if (!allowCancellation) {
        return null;
    }

    const renderConnectedProductsDescription = () => {
        return (
            <>
                <p>{t("book.confirmations.cancel_product", [productTitle])}</p>
                <p className="u-mb-6">{t("book.confirmations.cancelling_connected_products")}</p>
                <ul className="u-pl-18 u-mb-24">
                    {connectedProducts.map(product => (
                        <li key={product.id}>{`${product.kinddesc} (${product.title})`}</li>
                    ))}
                </ul>
            </>
        );
    };

    const cancelProduct = () => {
        if (!resvid || !detlid) {
            return;
        }

        dispatch(emptyCartProducts());
        dispatch(cancelProductFromReservation(userToken, resvid, detlid));
    };

    return (
        <>
            <Button
                type="tertiary"
                onClick={() => {
                    setShowCancelProductModal(true);
                }}
            >
                {t("book.my_pages.cancel")}
            </Button>
            <ConfirmationModal
                open={showCancelProductModal}
                onClose={() => setShowCancelProductModal(false)}
                heading={t("book.booking.cancel_product")}
                description={
                    connectedProducts.length > 0
                        ? renderConnectedProductsDescription()
                        : t("book.confirmations.cancel_product", [productTitle])
                }
                confirmBtnText={t("book.general.continue")}
                onConfirmClick={() => cancelProduct()}
                confirmBtnLoading={cancellingProductFromReservation || loadingReservation}
                cancelBtnText={t("book.general.cancel")}
                onCancelClick={() => setShowCancelProductModal(false)}
                removing
            ></ConfirmationModal>
        </>
    );
};

export default BookingSummaryCancellationButton;
