/*
    Migrate functions from BusinessUtils.js to TypeScript
*/

import { TGeneralAges, TItem } from "./store/types";

export const getGeneralAgeCategoryPrices = (item: TItem, generalAges: TGeneralAges) => {
    const prices = generalAges[item.type].reduce((hashmap, generalAge) => {
        const priceFieldName: "price" | "pricec1" | "pricec2" | "pricec3" | "pricec4" =
            generalAge.agecat === "c5" ? "price" : `price${generalAge.agecat}`;
        const price = item[priceFieldName] ?? 0;

        hashmap.set(generalAge.key, price);

        return hashmap;
    }, new Map<string, number>());

    return prices;
};
