import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import throttle from "lodash/throttle";
import { loadState, saveState } from "./localStorage";
import thunk from "redux-thunk";
import clientDataReducer from "./store/reducers/clientData";
import checkoutCartReducer from "./store/reducers/checkoutCart";
import guestsReducer from "./store/reducers/guests";
import windowReducer from "./store/reducers/window";
import filterReducer from "./store/reducers/filter";
import reservationResultReducer from "./store/reducers/reservationResult";
import availableFiltersReducer from "./store/reducers/availableFilters";
import accountReducer from "./store/reducers/account";
import axiosStatusReducer from "./store/reducers/axiosStatus";
import reservationReducer from "./store/reducers/reservation";
import searchReducer from "./store/reducers/search";
import notificationsReducer from "./store/reducers/notifications";
import * as types from "./store/actions/types";

import LogRocket from "logrocket";
import { getSizeInBytesForString } from "./Helper";

const doNotLogTheseReduxActions = [
    types.SET_DEVICE,
    types.SCREEN_RESIZE,
    types.SET_PROMO_CODE_ERROR,
    types.SET_LOADING,
    "SET_ERROR",

    // Hide secret token
    types.SET_CUSTOMER_TOKEN,
];

export const syncStateToBrowserStorage = store => {
    return () => {
        const state = store.getState();

        const saveToStateObject = {
            account: state.account,
            clientData: state.clientData,
            checkoutCart: state.checkoutCart,
            filter: state.filter,
            guests: state.guests,
            reservation: state.reservation,
            reservationResult: state.reservationResult,
            search: state.search,
        };

        const jsonString = JSON.stringify(saveToStateObject);

        const stateSizeInBytes = getSizeInBytesForString(jsonString);
        const stateSizeInMB = stateSizeInBytes / 1024 / 1024;

        // If the state size is greater than 2MB, don't store the search results in sessionStorage.
        if (stateSizeInMB > 2) {
            // Only keep the search params from reservationResult to avoid storing all search results.
            const reservationResult = Object.fromEntries(
                Object.entries(state.reservationResult || {}).map(([resvTypeLid, data]) => [
                    resvTypeLid,
                    {
                        search: data.search,
                    },
                ])
            );

            // Reset lastSearched timestamps to trigger a new search on page load.
            const search = { ...state.search, lastSearched: {} };

            saveToStateObject.reservationResult = reservationResult;
            saveToStateObject.search = search;
        }

        saveState(saveToStateObject);
    };
};

const configureStore = () => {
    const reduxDevToolsConfig = {
        trace: true, // Allow actions to be traced in dev tools to find source that triggered action,
        maxAge: 200,
    };

    const composeEnhancers =
        typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
            ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(reduxDevToolsConfig)
            : compose;

    const reduxMiddlewareConfig = {
        actionSanitizer: function (action) {
            // Skip unimportant actions
            if (doNotLogTheseReduxActions.indexOf(action.type) >= 0) {
                return null;
            }
            return action;
        },
    };

    const enhancer = composeEnhancers(
        applyMiddleware(thunk),
        applyMiddleware(LogRocket.reduxMiddleware(reduxMiddlewareConfig))
    );
    //const enhancer = composeEnhancers(applyMiddleware(thunk, LogRocket.reduxMiddleware()));

    const persistedState = loadState();

    const appReducer = combineReducers({
        account: accountReducer,
        availableFilters: availableFiltersReducer,
        axiosStatus: axiosStatusReducer,
        checkoutCart: checkoutCartReducer,
        clientData: clientDataReducer,
        filter: filterReducer,
        guests: guestsReducer,
        notifications: notificationsReducer,
        reservation: reservationReducer,
        reservationResult: reservationResultReducer,
        search: searchReducer,
        window: windowReducer,
    });

    /*
    Use DESTORY_STATE to clear state after reservation is made
  */
    const rootReducer = (state, action) => {
        if (action.type === types.DESTORY_STATE) {
            // State to keep
            const { clientData, window, filter, availableFilters, account, axiosStatus } = state;
            state = {
                clientData,
                window,
                filter,
                availableFilters,
                account,
                axiosStatus,
            };
        }

        return appReducer(state, action);
    };

    const store = createStore(rootReducer, persistedState, enhancer);

    // Performance optimization: Save state n time after last change
    const saveTime = 2000;
    store.subscribe(throttle(syncStateToBrowserStorage(store), saveTime));

    return store;
};

export default configureStore;
