import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateProduct } from "../../../store/actions/checkoutCart";
import { capitalizeFirstLetter, getGuestNameAndAge, getPackageProductTitle } from "../../../Helper";
import moment from "moment-timezone";
import { agesSelector, guestsOnTravelSelector, optionsSelector } from "../../../selectors/Selectors";
import isEqual from "lodash/isEqual";
import { useNavigate } from "react-router";
import { isBooked, showArrDepDates } from "../../../BusinessUtils";
import { TCartItem, TCartItemItem, TGuest, TReservationType } from "../../../store/types";
import { RootState } from "../../..";
import { TGeneralAge } from "../../../store/types";
import { CheckoutConnectCard } from "../CheckoutConnectCard";
import {
    CheckoutConnectCardHeading,
    CheckoutConnectCardRemoveBtn,
    CheckoutConnectNoGuestsToConnect,
} from "../CheckoutConnectCard/CheckoutConnectCard";
import useTranslate from "../../../hooks/useTranslate";
import { Button, ConfirmationModal, Notification, Select, TSelectOption, Tooltip } from "@r360/library";
import { TSectionState, TSectionStates } from "../../../pages/CheckoutConnectPage/CheckoutConnectPage";
import classNames from "classnames";
import "./CheckoutConnectAccomodation.scss";
import CheckoutConnectSkipass from "../CheckoutConnectSkipass";

type TCheckoutConnectAccomodation = {
    sectionStates: TSectionStates;
    changeSectionState: (arg0: string, arg1: TSectionState) => void;
    products: TCartItem[];
    type: TReservationType;
    removeProductFromCart: (arg0: TCartItem, arg1?: boolean) => void;
    removeConnectedProductFromCart: (arg0: TCartItem, arg1: TCartItemItem) => void;
};
export const CheckoutConnectAccomodation = ({
    sectionStates,
    changeSectionState,
    products,
    type,
    removeProductFromCart,
    removeConnectedProductFromCart,
}: TCheckoutConnectAccomodation) => {
    const t = useTranslate();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const accommodations = products.filter(product => product.type === type.lid);
    const guestsOnTravel = useSelector(guestsOnTravelSelector);
    const generalAges = useSelector(agesSelector);
    const userToken = useSelector((state: RootState) => state.account.token);
    const checkoutCartId = useSelector((state: RootState) => state.checkoutCart.cartId);
    const skipGuestsOnAccommodation = useSelector(optionsSelector).reservation?.skipguestsonaccommodation || false;
    const { isMobile, isIframe, isDesktop, iframeOffsetTop, isTablet } = useSelector(
        (state: RootState) => state.window
    );
    const [accomodationToChange, setAccomodationToChange] = useState<TCartItem | null>(null);
    const sectionState = sectionStates[type.lid];

    /**
     * Check that all guests connected to accommodations should be on the travel.
     * If not, remove them from the accommodation.
     */
    useEffect(() => {
        const guestIds = Object.keys(guestsOnTravel).map(guestId => parseInt(guestId));

        for (const accommodation of accommodations) {
            if (!accommodation.guests) {
                continue;
            }

            const accommodationGuests = { ...accommodation.guests };

            for (const [index, accommodationGuestId] of Object.entries(accommodationGuests)) {
                if (!guestIds.includes(accommodationGuestId as number)) {
                    delete accommodationGuests[parseInt(index)];
                }
            }

            if (!isEqual(accommodation.guests, accommodationGuests)) {
                dispatch(
                    updateProduct(accommodation, {
                        key: "guests",
                        value: accommodationGuests,
                    })
                );
            }
        }
    }, [dispatch, accommodations, guestsOnTravel]);

    // TODO Validate that the guests that have been assigned to an accommodation have at least one phone number
    // const anyAccomodationTravellerHasPhoneNumber = () => {
    //     const accommodationsToValidate = accommodations
    //         .filter(accommodation => accommodation.type === type.lid)
    //         .filter(accommodation => Object.values(accommodation.guests).length > 0);

    //     const validAccommodations = accommodationsToValidate.filter(accommodation =>
    //         Object.values(accommodation.guests).some(guestlid => {
    //             const guest = guestsOnTravel[guestlid];
    //             return guest?.phoneprefix && guest?.phone;
    //         })
    //     );

    //     return validAccommodations.length === accommodationsToValidate.length;
    // };

    // Check whether accomodations have period overlaps
    let accomodationsForSamePeriod = false;

    if (accommodations.length > 1) {
        accommodations.forEach(accomodation => {
            const arrDate = moment(accomodation.arrdate);
            const depDate = moment(accomodation.depdate);

            const otherAccommodations = accommodations.filter(
                filterAccommodation => filterAccommodation.id !== accomodation.id
            );

            const otherAccomodationsInSamePeriod = otherAccommodations.some(otherAccommodation => {
                const assignedArrDate = moment(otherAccommodation.arrdate);
                const assignedDepDate = moment(otherAccommodation.depdate);

                if (
                    arrDate.isBetween(assignedArrDate, assignedDepDate, undefined, "[)") ||
                    depDate.isBetween(assignedArrDate, assignedDepDate, undefined, "(]")
                ) {
                    return true;
                }

                return false;
            });

            if (otherAccomodationsInSamePeriod) {
                accomodationsForSamePeriod = true;
            } else {
                accomodationsForSamePeriod = false;
            }
        });
    }

    /**
     * Get ages for a reservation type.
     *
     * @param {string} reservationTypeId The reservation type ID.
     *
     * @returns {Object[]} A list of ages objects.
     */
    const getAgesForReservationType = (reservationTypeId: number) => {
        if (!generalAges[reservationTypeId]) {
            throw new Error(`Reservation type ${reservationTypeId} does not have any ages configured in general ages`);
        }

        return generalAges[reservationTypeId];
    };

    /**
     * Get a list of ages for an accommodation sorted by descending min age.
     *
     * E.g. if guests for accommodation is { youth: 2, adult: 1, child: 1 }, this is returned:
     * [{ key: "adult", ...} , { key: "youth", ...}, { key: "youth", ...}, { key: "child", ...}]
     *
     * @param {Object} accommodation The accommodation product from cart.
     *
     * @returns {Object[]} A list of ages for an accommodation sorted by descending min age.
     */
    const getSortedAgesForAccommodation = (accommodation: TCartItem) => {
        const ages = getAgesForReservationType(accommodation.type) as TGeneralAge[];
        const guestCounts: { [key: string]: number } = accommodation.info.guests;
        const totalGuestsCount = accommodation.info.guests.totalGuests;

        // Sort age categories by descending min age.
        const sortedAges = ages.sort((a, b) => b.min - a.min);

        const selectsByAge: TGeneralAge[] = [];

        sortedAges.forEach(age => {
            if (guestCounts[age.key] > 0) {
                for (let i = 0; i < guestCounts[age.key]; i++) {
                    selectsByAge.push(age);
                }
            }
        });

        if (!isBooked(accommodation) && selectsByAge.length !== totalGuestsCount) {
            throw new Error(
                `Guest count doesn't match when comparing total guests and guest count per age category for accommodation ${accommodation.type}`
            );
        }

        return selectsByAge;
    };

    /**
     * Check if a guest can be added to an accommodation slot based on age and other assigned
     * accommodations.
     *
     * @param {Object} guest
     * @param {Object} accommodation
     * @param {number} dropdownIndex
     * @param {Object} age
     *
     * @returns {boolean} True if the guest can be added to the slot, otherwise false.
     */
    const getCanGuestBeAddedToAccommodationSlot = (
        guest: TGuest,
        accommodation: TCartItem,
        dropdownIndex: number,
        age: TGeneralAge
    ) => {
        const arrDate = moment(accommodation.arrdate);
        const depDate = moment(accommodation.depdate);

        // Check that the guest is the correct age on arrival date.
        if (!guest.birthday) {
            return false;
        }

        const guestAgeAtArrivalDate = arrDate.diff(moment(guest.birthday), "years");

        if (guestAgeAtArrivalDate < age.min || guestAgeAtArrivalDate > age.max) {
            return false;
        }

        // Check if the guest is already assigned to another spot on the current accommodation.
        const guestIsAlreadyAssignedToOtherSpotAtCurrentAccommodation = Object.entries(accommodation.guests || {}).some(
            ([index, accommodationGuestId]) => {
                if (accommodationGuestId === guest.id && parseInt(index) !== dropdownIndex) {
                    return true;
                }

                return false;
            }
        );

        if (guestIsAlreadyAssignedToOtherSpotAtCurrentAccommodation) {
            return false;
        }

        // Check if the guest is assigned to any other accommodations during the same period.
        const otherAccommodations = accommodations.filter(
            filterAccommodation => filterAccommodation.id !== accommodation.id
        );

        const guestIsAlreadyAssignedToOtherAccommodationForPeriod = otherAccommodations.some(otherAccommodation => {
            if (Object.values(otherAccommodation.guests || {}).includes(guest.id)) {
                const assignedArrDate = moment(otherAccommodation.arrdate);
                const assignedDepDate = moment(otherAccommodation.depdate);

                if (
                    arrDate.isBetween(assignedArrDate, assignedDepDate, undefined, "[)") ||
                    depDate.isBetween(assignedArrDate, assignedDepDate, undefined, "(]")
                ) {
                    return true;
                }
            }

            return false;
        });

        if (guestIsAlreadyAssignedToOtherAccommodationForPeriod) {
            return false;
        }

        return true;
    };

    /**
     * Assign a guest to an accommodation slot.
     *
     * @param {Object} accommodation
     * @param {number} dropdownIndex
     * @param {number} guestId
     */
    const assignGuestToAccommodation = (accommodation: TCartItem, dropdownIndex: number, guestId: string) => {
        const accommodationGuests = { ...accommodation.guests };

        // If no guest is provided, remove guest is assigned to the provided index.
        if (guestId === "") {
            delete accommodationGuests[dropdownIndex];
        } else {
            const guestIdNumber = parseInt(guestId);

            if (Object.values(accommodationGuests).includes(guestIdNumber)) {
                return;
            }

            accommodationGuests[dropdownIndex] = guestIdNumber;
        }

        dispatch(
            updateProduct(accommodation, {
                key: "guests",
                value: accommodationGuests,
            })
        );
    };

    const renderGuestAgesForAccommodation = (accommodation: TCartItem) => {
        const sortedAges = getSortedAgesForAccommodation(accommodation);

        const groupedAges = sortedAges.reduce((acc: TGeneralAge[], currentAge) => {
            const currentAgeIndexOfAcc = acc.findIndex(age => age.key === currentAge.key);

            if (currentAgeIndexOfAcc === -1) {
                currentAge.guestCount = 1;
                acc.push(currentAge);
            } else {
                //eslint-disable-next-line
                acc[currentAgeIndexOfAcc].guestCount!++;
            }

            return acc;
        }, []);

        return (
            <div className="u-d-flex u-gap-3 u-flex-column">
                {groupedAges.map((age: TGeneralAge, index: number) => (
                    <span key={index}>{`${age.guestCount} st ${t(age.title)}`}</span>
                ))}
            </div>
        );
    };

    const renderGuestCountForAccommodation = (accommodation: TCartItem) => {
        return (
            <Tooltip toolTipContent={renderGuestAgesForAccommodation(accommodation)} triggerOnClick>
                <Button type="tertiary">
                    {accommodation.info.guests.totalGuests === 1
                        ? `${accommodation.info.guests.totalGuests} ${t("generalguest")}`
                        : `${accommodation.info.guests.totalGuests} ${t("generalguests")}`}
                </Button>
            </Tooltip>
        );
    };

    /**
     * Render the select fields for an accommodation.
     *
     * @param {Object} accommodation
     */
    const renderGuestSelectsForAccommodation = (accommodation: TCartItem) => {
        const sortedAges = getSortedAgesForAccommodation(accommodation);
        const accommodationGuests = accommodation.guests || {};

        return (
            <div className="row u-bs-gutters-6">
                {sortedAges.map((age, dropdownIndex) => {
                    const guestSelectOptions = Object.entries(guestsOnTravel)
                        .filter(([, guest]) =>
                            getCanGuestBeAddedToAccommodationSlot(guest, accommodation, dropdownIndex, age)
                        )
                        .map(([id, guest]) => ({
                            value: id,
                            label: getGuestNameAndAge(guest, "år", accommodation),
                        }));

                    return (
                        <div
                            className={classNames("col-12 u-mb-18", {
                                "col-lg-6": sortedAges.length <= 2,
                                "col-lg-4": sortedAges.length > 2,
                            })}
                            key={`${age.key}-${dropdownIndex}`}
                        >
                            <Select
                                required
                                label={
                                    <span>
                                        {`${t("book.general.guest")} ${dropdownIndex + 1} `}
                                        <span style={{ fontWeight: 400 }} className="u-fw-default">{`(${age.min} - ${
                                            age.max
                                        } ${t("book.general.years")})`}</span>
                                    </span>
                                }
                                defaultValue={(accommodationGuests[dropdownIndex] || "").toString()}
                                options={guestSelectOptions}
                                fullWidth
                                type="standard"
                                onSelectCallback={(selectedOption: TSelectOption) =>
                                    assignGuestToAccommodation(
                                        accommodation,
                                        dropdownIndex,
                                        selectedOption.value as string
                                    )
                                }
                                placeholder={t("book.checkout.pick_guest")}
                                useFirstAsDefault={false}
                                disabled={!guestSelectOptions.length}
                                isValid={
                                    sectionState.status === undefined
                                        ? undefined
                                        : !(sectionState.status === "error" && !accommodationGuests[dropdownIndex])
                                }
                                showAsterisk
                                boldLabel
                            />
                            {!guestSelectOptions.length && (
                                <CheckoutConnectNoGuestsToConnect minAge={age.min} maxAge={age.max} />
                            )}
                        </div>
                    );
                })}
            </div>
        );
    };

    const renderAccommodations = () => {
        const sortedAccommodations = accommodations.sort((a, b) => moment(a.arrdate).unix() - moment(b.arrdate).unix());

        return (
            <>
                {sortedAccommodations.map(accommodation => {
                    const showSelects = !skipGuestsOnAccommodation && !isBooked(accommodation);
                    const accomodationHasExtras = !!(
                        accommodation.info?.extras && accommodation.info?.extras.some(extra => extra.quantity)
                    );
                    const accomodationIsBooked = isBooked(accommodation);

                    const connectedSkipasses = products.filter(
                        product => product.connectedProductId === accommodation.cartItemId
                    );

                    return (
                        <div key={accommodation.groupTitle}>
                            <CheckoutConnectCard connected={connectedSkipasses.length ? "below" : "none"}>
                                <div className="checkout-connect-accomodation">
                                    <div className="checkout-connect-accomodation__content">
                                        <div className="u-mb-24">
                                            <div className="u-mb-12">
                                                <CheckoutConnectCardHeading
                                                    primaryHeading={accommodation.groupTitle}
                                                    secondaryHeading={getPackageProductTitle(accommodation)}
                                                />
                                            </div>
                                            <div className="u-mb-6">
                                                {renderGuestCountForAccommodation(accommodation)}
                                                {", "}
                                                <span>
                                                    {capitalizeFirstLetter(
                                                        showArrDepDates(accommodation, "dddd Do MMMM")
                                                    )}
                                                </span>
                                            </div>
                                            {accomodationHasExtras && (
                                                <div className="u-mb-24">
                                                    <span className="u-fw-medium">Tillval: </span>
                                                    {accommodation.info?.extras
                                                        .filter(extra => extra.quantity)
                                                        .map((extra, i, array) => {
                                                            return (
                                                                <span key={extra.id}>
                                                                    {`${extra.quantity}x`} {extra.title}
                                                                    {i !== array.length - 1 ? ", " : ""}
                                                                </span>
                                                            );
                                                        })}
                                                </div>
                                            )}
                                        </div>
                                        {showSelects && renderGuestSelectsForAccommodation(accommodation)}
                                        {!accomodationIsBooked && (
                                            <div className="u-d-flex u-gap-24">
                                                <CheckoutConnectCardRemoveBtn
                                                    onClick={() => removeProductFromCart(accommodation)}
                                                />
                                                <Button
                                                    type="tertiary"
                                                    onClick={() => setAccomodationToChange(accommodation)}
                                                >
                                                    {t("book.cart.accommodation.change")}
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                    {!showSelects && (
                                        <div
                                            className="checkout-connect-accomodation__img"
                                            style={{ backgroundImage: `url("${accommodation.images[0]?.url}")` }}
                                        ></div>
                                    )}
                                </div>
                            </CheckoutConnectCard>
                            {!!connectedSkipasses.length && (
                                <CheckoutConnectSkipass
                                    sectionStates={sectionStates}
                                    changeSectionState={changeSectionState}
                                    removeProductFromCart={removeConnectedProductFromCart}
                                    type={type}
                                    products={connectedSkipasses}
                                    connectedToAccommodation
                                />
                            )}
                        </div>
                    );
                })}
            </>
        );
    };

    // Make sure the required data from the state has been fetched.
    if (!guestsOnTravel || !generalAges || !userToken || !checkoutCartId) {
        return <></>;
    }

    return (
        <>
            {accomodationsForSamePeriod && (
                <div className="u-mb-24">
                    <Notification type="warning">
                        <div className="u-fw-medium">{t("book.checkout.same_period_accomodations.heading")}</div>
                        {t("book.checkout.same_period_accomodations.description")}
                    </Notification>
                </div>
            )}
            {renderAccommodations()}
            <ConfirmationModal
                open={accomodationToChange}
                heading={t("book.confirmations.change_accommodation_in_cart.title")}
                description={t("book.confirmations.change_accommodation_in_cart.description")}
                confirmBtnText={t("book.general.ok")}
                cancelBtnText={t("book.general.cancel")}
                removing={true}
                onClose={() => setAccomodationToChange(null)}
                onConfirmClick={() => {
                    accomodationToChange && removeProductFromCart(accomodationToChange, false);
                    navigate(`/product/${accomodationToChange?.type}/${accomodationToChange?.grouppoollid}`);
                }}
                onCancelClick={() => setAccomodationToChange(null)}
                {...(isIframe && isDesktop && { fromTop: 120 + iframeOffsetTop + "px" })}
                {...(isIframe &&
                    (isMobile || isTablet) && {
                        fromTop: 80 + iframeOffsetTop + "px",
                    })}
            />
        </>
    );
};
