import { Button, ChevronDownIcon, ChevronUpIcon, Dropdown, TDropDownHandle } from "@r360/library";
import React, { useEffect, useRef } from "react";
import { formatPrice } from "../../Helper";
import useTranslate from "../../hooks/useTranslate";
import { TItem } from "../../store/types";
import Incrementer from "../UI/Incrementer";
import { TAddToCartData, addToCartSuccesDuration } from "./MultipleVariantsCard";
import useAppSelector from "../../hooks/useAppSelector";

type TMultipleVariantsQuantityDropdownButton = {
    item: TItem;
    addToCartData: { [key: number]: TAddToCartData };
    onChange: (arg0: TItem, arg1: number) => void;
    handleAddToCart: (arg0: TItem, arg1: number) => void;
    addToCartSuccess: number | null;
    itemInCartQuantity?: number;
};

export const MultipleVariantsQuantityDropdownButton = ({
    item,
    addToCartData,
    onChange,
    handleAddToCart,
    addToCartSuccess,
    itemInCartQuantity,
}: TMultipleVariantsQuantityDropdownButton) => {
    const t = useTranslate();
    const totalGuestsCount = addToCartData[item.id]?.quantity ?? 0;
    const dropdownRef = useRef<TDropDownHandle>(null);
    const withPriceMatrix = item.dynamicprices && item.pricematrix;
    const { isMobile } = useAppSelector(state => state.window);

    // Don't add more items than available minus the ones already in the cart
    let maxToBeAdded = (item.available ?? 0) - (itemInCartQuantity ?? 0);

    // With pricematrix, don't add more items than the pricematrix supports, minus the ones already in the cart
    if (withPriceMatrix) {
        const priceMatrixCount = item?.pricematrix ? Object.values(item?.pricematrix).length : 0;
        maxToBeAdded = Math.min(maxToBeAdded, priceMatrixCount - (itemInCartQuantity ?? 0));
    }

    const itemAddToCartSuccess = addToCartSuccess === item.id;

    const setItemCount = (value: number) => {
        onChange(item, value);
    };

    const price = (() => {
        if (withPriceMatrix && totalGuestsCount) {
            return item.pricematrix && item.pricematrix[totalGuestsCount];
        } else {
            return item.price * totalGuestsCount;
        }
    })();

    const closeDropdown = () => {
        if (dropdownRef.current) {
            dropdownRef.current.setRefIsOpen();
        }
    };

    useEffect(() => {
        let timeout: NodeJS.Timeout;
        if (itemAddToCartSuccess) {
            timeout = setTimeout(() => {
                closeDropdown();
            }, addToCartSuccesDuration);
        }
        return () => {
            if (!itemAddToCartSuccess) {
                clearTimeout(timeout);
            }
        };
    }, [itemAddToCartSuccess]);

    return (
        <Dropdown
            value={t("book.general.choose_quantity")}
            rightAlign={!isMobile}
            renderCustomButton={(isOpen, onToggle) => {
                return (
                    <Button
                        onClick={() => onToggle()}
                        rightIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                        badgeCount={totalGuestsCount > 0 ? totalGuestsCount : null}
                        secondaryBadgeCount={itemInCartQuantity && itemInCartQuantity > 0 ? itemInCartQuantity : null}
                        disabled={!maxToBeAdded && !itemAddToCartSuccess}
                    >
                        {t("book.general.choose_quantity")}
                    </Button>
                );
            }}
            ref={dropdownRef}
        >
            <div className="multiple-variants-card__dropdown">
                <Incrementer
                    name="count"
                    value={totalGuestsCount}
                    maxValue={maxToBeAdded}
                    onChange={({ value }: { value: number }) => setItemCount(value)}
                    disabled={itemAddToCartSuccess}
                />
                <div className="multiple-variants-card__dropdown-price">
                    {t("book.general.total")}: {formatPrice(price, item.curr)}
                </div>
                {itemAddToCartSuccess ? (
                    <Button fullWidth type="success" buttonClassName="a-scale-11">
                        {t("book.results.added_to_cart")}
                    </Button>
                ) : (
                    <Button
                        fullWidth
                        onClick={() => handleAddToCart(item, totalGuestsCount)}
                        disabled={!totalGuestsCount}
                    >
                        {t("book.results.add_to_cart")}
                    </Button>
                )}
            </div>
        </Dropdown>
    );
};
