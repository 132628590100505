import React from "react";
import * as Constants from "../../Constants";
import useTranslate from "../../hooks/useTranslate";
import { AlertSorry } from "../UI";

export const ReservationTypeNoResults = ({ resvtype }) => {
    const t = useTranslate();
    switch (resvtype) {
        case Constants.productTypeNames.ACCOMMODATION:
            return (
                <AlertSorry
                    className="reservation-type-results__alert--no-hits"
                    heading={t("accommodationnoresultheader")}
                    content={t("accommodationnoresultbody")}
                />
            );

        case Constants.productTypeNames.SKIPASS:
            return (
                <AlertSorry
                    className="reservation-type-results__alert--no-hits"
                    heading={t("skipassnoresultheader")}
                    content={t("skipassnoresultbody")}
                />
            );

        case Constants.productTypeNames.ACTIVITY:
            return (
                <AlertSorry
                    className="reservation-type-results__alert--no-hits"
                    heading={t("activitynoresultheader")}
                    content={t("activitynoresultbody")}
                />
            );

        case Constants.productTypeNames.LETTING:
            return (
                <AlertSorry
                    className="reservation-type-results__alert--no-hits"
                    heading={t("lettingnoresultheader")}
                    content={t("lettingnoresultbody")}
                />
            );

        case Constants.productTypeNames.RENTAL:
            return (
                <AlertSorry
                    className="reservation-type-results__alert--no-hits"
                    heading={t("rentalnoresultheader")}
                    content={t("rentalnoresultbody")}
                />
            );

        default:
            return (
                <AlertSorry
                    className="reservation-type-results__alert--no-hits"
                    heading={t("generalnoresultheader")}
                    content={t("generalnoresultbody")}
                />
            );
    }
};

export default ReservationTypeNoResults;
