import * as types from "../actions/types";
const initialState = {
    reservationtypes: [],
    calendarData: null,
    letting: null,
    options: null,
    agreements: [],
    country: [],
    texts: null,
    lang: null,
    languages: [],
    error: false,
    cookie: null,
    site: null,
    contact: null,
    agent: null,
    customTranslations: [],
};

const reducer = (state = initialState, action) => {
    // Use to overwrite/turn on new design
    // const payloadWithNewLayout = JSON.parse(JSON.stringify(action.payload ?? {}), (key, value) => {
    //     if (
    //         key == "new_design_menu" ||
    //         key == "new_design_accommodation" ||
    //         key == "new_design_skipass" ||
    //         key == "new_design_letting" ||
    //         key == "new_design_activity"
    //     ) {
    //         return true;
    //     } else {
    //         return value;
    //     }
    // });

    switch (action.type) {
        case types.SET_CLIENTDATA:
            return {
                ...state,
                ...action.payload,
            };
        case types.FETCH_CLIENTDATA_FAILED:
            return {
                ...state,
                error: action.error,
            };

        case types.SET_CALENDAR_DATA:
            return {
                ...state,
                calendarData: action.payload,
                error: false,
            };

        case types.FETCH_CALENDAR_DATA_FAILED:
            return {
                ...state,
                calendarData: null,
                error: action.error,
            };

        case types.SET_TEXT_DATA:
            return {
                ...state,
                texts: action.texts,
            };

        case types.SET_LANG:
            return {
                ...state,
                lang: action.lang,
            };

        case types.SET_COOKIE:
            return {
                ...state,
                cookie: action.cookie,
            };

        case types.SET_LANGUAGES:
            return {
                ...state,
                languages: action.languages,
            };

        case types.SIGN_OUT_AGENT:
            return {
                ...state,
                agent: null,
            };

        default:
            return {
                ...state,
            };
    }
};

export default reducer;
