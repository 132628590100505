import classnames from "classnames";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
    getNewCartProducts,
    getNewOrModifiedCartProducts,
    hasBookstat,
    isAccommodation,
    isActivity,
    isBooked,
    isNew,
    isSeasonPass,
    setAdditionalOnBookedAccommodationProductExtras,
    showArrDepDates,
    sortCartProducts,
} from "../../BusinessUtils";
import * as Constants from "../../Constants";
import { formatPrice, getAgeCategories, getPackageProductTitle } from "../../Helper";
import { AccommodationsAreToFarApart } from "../../hooks";
import { checkoutUrlSelector, resvTypeLidsByTypeSelector, textsSelector } from "../../Selectors";
import {
    addProduct,
    deleteProductFromCart,
    emptyCartProducts,
    removeProduct,
    updateProductWithCount,
} from "../../store/actions/checkoutCart";
import { setBookingError } from "../../store/actions/window";
import { Accordion, Alert, Button, Icon, Incrementer } from "../UI";
import {
    BikeIcon,
    CalendarIcon,
    CloseInvertedIcon,
    ContactIcon,
    GuestIcon,
    HouseIcon,
    SkiIcon,
    SkiingIcon,
    TrashCanIcon,
} from "../UI/R360Icons";
import "./OrderOverview.scss";

// Order overview component
const OrderOverview = props => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const clientData = useSelector(state => state.clientData);
    const texts = useSelector(textsSelector);
    const userToken = useSelector(state => state.account.token);
    const currency = useSelector(state => state.clientData?.options?.general?.currency);
    const accommodationLids = useSelector(state =>
        resvTypeLidsByTypeSelector(state, Constants.productTypeNames.ACCOMMODATION)
    );
    const cartProducts = (useSelector(state => state.checkoutCart?.products) || []).map(product =>
        setAdditionalOnBookedAccommodationProductExtras(product)
    );
    const newOrModifiedCartProducts = getNewOrModifiedCartProducts(cartProducts);
    const newCartProducts = getNewCartProducts(cartProducts);
    const newOrModifiedAccommodationCartProducts = newOrModifiedCartProducts.filter(isAccommodation);

    const cartSummary = useSelector(state => state.checkoutCart.summary);
    const generalAges = JSON.parse(clientData.options?.general?.ages);
    const activeLid = useSelector(state => state.checkoutCart.activeLid);
    const checkoutUrl = useSelector(checkoutUrlSelector);
    const isAccommodationsBookingError = AccommodationsAreToFarApart();

    let cartIsValid;

    // Expand active lid accordion and close all others
    useEffect(() => {
        const accordionToClick = document.getElementById(activeLid);
        const accordions = document.getElementsByClassName("card-header");

        // Close open accordions without active lid
        for (let i = 0; i < accordions.length; i++) {
            const element = accordions[i];
            const isExpanded = element.getAttribute("aria-expanded");
            const id = element.getAttribute("id");
            if (isExpanded === "true" && parseInt(id) !== activeLid && !props.activeResvLid) {
                element.click();
            }
        }

        // Click active lid accordion if not already expanded
        if (accordionToClick && !props.activeResvLid) {
            if (accordionToClick.getAttribute("aria-expanded") === "false") {
                accordionToClick.click();
            }
        }
    }, [activeLid, props.activeResvLid]);

    // Enable reserve button
    if (newOrModifiedCartProducts.length !== 0) {
        cartIsValid = true;
    } else {
        cartIsValid = false;
    }

    // Lock selected accommodation in cart when adding additional or moving on
    const lockSelectedAccommodationInCart = () => {
        const accommodationToReplace = cartProducts?.find(
            item => accommodationLids.includes(parseInt(item.type)) && item.locked === undefined
        );
        if (accommodationToReplace) {
            let newItemToAdd = {
                ...accommodationToReplace,
                locked: true,
            };

            dispatch(removeProduct(accommodationToReplace));
            dispatch(addProduct(newItemToAdd));
        }
    };

    // Continue to next step
    const continueToNextStep = () => {
        if (isAccommodationsBookingError) {
            dispatch(setBookingError(true));
        } else {
            lockSelectedAccommodationInCart();

            if (newOrModifiedAccommodationCartProducts.length > 0) {
                navigate("/reservation/extras");
            } else {
                navigate(checkoutUrl);
            }
        }
    };

    // Add additional accomodation
    const addAdditionalAccommodation = () => {
        lockSelectedAccommodationInCart();
        navigate("/");
    };

    // Render fees and total price
    const renderFeesAndTotalPrice = () => {
        let totalPrice = null;

        if (newOrModifiedCartProducts.length === 0) {
            return null;
        }

        /* Add values to variables */
        newCartProducts.forEach(item => {
            /* Add count price or price from item matrix */
            if (item.pricematrix) {
                totalPrice = totalPrice + parseInt(item.pricematrix[item.count]);
            } else {
                totalPrice = totalPrice + parseInt(item.price * (item.count || 1));
            }
        });

        // Get all extras for accommodations in cart.
        const accommodationExtrasToSum = newOrModifiedAccommodationCartProducts
            .flatMap(accommodation => accommodation.info?.extras || [])
            .filter(extra => !hasBookstat(extra) || extra.additional);
        /* Add costs for accommodation extras */
        accommodationExtrasToSum
            .filter(extra => {
                if (extra.rules.mandatory_web) {
                    return false;
                } else {
                    return (extra.quantity !== 0 && extra.rules.max_qty !== 0) || extra.checked === true;
                }
            })
            .forEach(item => {
                totalPrice += item.additional?.price || item.price;
            });

        return (
            <div className="order-overview__payment-details">
                {cartIsValid ? (
                    <>
                        <div className="order-overview__payment-row order-overview__total-price">
                            {`${texts?.totalprice} `}
                            <span className="order-overview__payment-value">{formatPrice(totalPrice, currency)}</span>
                        </div>
                    </>
                ) : (
                    <div className="order-overview__empty-cart">{texts?.cartempty}</div>
                )}
            </div>
        );
    };

    const ExtrasListItem = props => {
        let { extrasItemId, title, currency, price, showPriceWeb, quantity, isMandatory } = props;

        title = quantity === 0 || quantity === true ? title : `${quantity} x ${title}`;

        if (isMandatory) {
            title += ` (${texts.generalincluded})`;
        }

        let showPrice = true;

        if (isMandatory && !showPriceWeb) {
            showPrice = false;
        }

        return (
            <div className="order-overview__item-extras-unit" key={extrasItemId}>
                <div className="order-overview__item-extras-title">{title}</div>
                {showPrice && (
                    <div className="order-overview__item-extras-price">
                        {price} {currency}
                    </div>
                )}
            </div>
        );
    };

    // Product extras for accommodations
    const ExtrasList = ({ extras }) => {
        const extrasToRender = extras.filter(extra => {
            let shallRender = false;

            if (isNew(extra)) {
                shallRender = extra.quantity > 0 || extra.checked === true || extra.additional;
            } else if (isBooked(extra)) {
                shallRender = extra.quantity > extra.bookedquantity || extra.checked === true || extra.additional;
            } else {
                shallRender = false;
            }

            return shallRender;
        });

        if (!extrasToRender.length) {
            return null;
        }

        return (
            <div className="order-overview__item-extras-list">
                <strong>{texts?.addition}:</strong>
                <br />

                {extrasToRender.map(extra => {
                    return (
                        <ExtrasListItem
                            key={extra.id}
                            extrasItemId={extra.id}
                            title={extra.title}
                            currency={currency}
                            price={extra.additional?.price || extra.price}
                            showPriceWeb={extra.showpriceweb}
                            quantity={extra.additional?.quantity || extra.quantity}
                            isMandatory={extra.rules.mandatory_web}
                        />
                    );
                })}
            </div>
        );
    };

    // Remove button
    const RemoveButton = props => {
        return (
            <button
                type="button"
                className="order-overview__item-remove-button"
                aria-label={`${texts?.generalremove} ${props.item.title}`}
                onClick={() => {
                    removeProductFromCart(props.item);
                }}
            >
                <CloseInvertedIcon size={16} />
            </button>
        );
    };

    // Remove product from cart
    const removeProductFromCart = productToRemove => {
        dispatch(removeProduct(productToRemove));
        if (cartSummary) {
            Object.keys(cartSummary.products).forEach(key => {
                const product = cartSummary.products[key];
                if (product.original_id === productToRemove.id) {
                    dispatch(deleteProductFromCart(userToken, cartSummary.id, key));
                }
            });
        }
    };

    // Get age category tags
    const getAgeCategoryTags = item => {
        if (item.minage >= 0 && item.maxage && generalAges) {
            const categories = getAgeCategories(item.minage, item.maxage, generalAges[parseInt(item.type)]);

            return categories.map((category, index) => {
                return <div key={index}>{texts[category.title]}</div>;
            });
        }
    };

    // Get max increment value
    const getMaxIncrementValue = item => {
        if (item?.available) {
            if (item?.pricematrix) {
                if (Object.keys(item.pricematrix).length < item.available) {
                    return Object.keys(item.pricematrix).length;
                } else if (item.available === 0 && Object.keys(item.pricematrix).length > 0) {
                    return Object.keys(item.pricematrix).length;
                } else {
                    return item.available;
                }
            } else {
                return item.available;
            }
        } else {
            return undefined;
        }
    };

    // Get price for an item, where booked items only should return the additional cost.
    const getItemPrice = item => {
        if (!isBooked(item)) {
            if (item.pricematrix) {
                return `${formatPrice(item.pricematrix[item.count], currency)}`;
            } else {
                return `${formatPrice(item.price * (item.count || 1), currency)}`;
            }
        } else if (item.additional) {
            return `${formatPrice(item.price * (item.count || 1), currency)}`;
        }

        return "";
    };

    // Render reservation type items
    const renderReservationTypeItems = (resvType, key) => {
        // Filter and sort the products.
        // First added product is always shown first.
        const items = sortCartProducts(
            newOrModifiedCartProducts.filter(item => parseInt(item.type, 10) === parseInt(resvType.lid, 10))
        );

        const isExpanded = parseInt(props.activeResvLid || activeLid, 10) === parseInt(resvType.lid, 10);
        const iconSettings = { size: 20, color: "#333333" };
        let icon;

        if (items.length === 0) {
            return null;
        }

        switch (resvType.type) {
            case Constants.productTypeNames.ACCOMMODATION:
                icon = <HouseIcon iconClass="accordion__icon" {...iconSettings} />;
                break;
            case Constants.productTypeNames.SKIPASS:
                icon = <ContactIcon iconClass="accordion__icon" {...iconSettings} />;
                break;
            case Constants.productTypeNames.LETTING:
                icon = <SkiingIcon iconClass="accordion__icon" {...iconSettings} />;
                break;
            case Constants.productTypeNames.ACTIVITY:
                icon = <SkiIcon iconClass="accordion__icon" {...iconSettings} />;
                break;
            case Constants.productTypeNames.RENTAL:
                icon = <BikeIcon iconClass="accordion__icon" {...iconSettings} />;
                break;
            case Constants.productTypeNames.PACKAGE:
                icon = <Icon name="FaGift" className="accordion__icon" {...iconSettings} />;
                break;
            default:
                break;
        }
        return (
            <Accordion
                className={`order-overview__accordion`}
                header={resvType.description}
                icon={icon}
                expanded={isExpanded ? true : false}
                key={key}
                id={resvType.lid}
                eventKey={resvType.lid}
            >
                {items.map((item, index) => {
                    const { title, price, groupTitle } = item;
                    const disabledClass = item.locked ? " order-overview__item--disabled" : "";
                    const packageProductTitle = getPackageProductTitle(item);

                    // On incrementer value change
                    const onValueChange = response => {
                        if (response.value === 0) {
                            dispatch(removeProduct(item));
                        } else {
                            dispatch(updateProductWithCount(item, response.value));
                        }
                    };

                    return (
                        <div
                            className={`order-overview__item${disabledClass}`}
                            key={`${item.type}_${item.grouppoollid}_${item.id}_${index}`}
                        >
                            {!props.disableCartActions && !item.detlid && <RemoveButton item={item} />}
                            <h4 className="order-overview__item-header">{packageProductTitle}</h4>

                            {/* Price count or price from matrix */}
                            {(!hasBookstat(item) || item.additional) && (
                                <div className="order-overview__item-price">{getItemPrice(item)}</div>
                            )}

                            {groupTitle && groupTitle !== packageProductTitle && (
                                <div className="order-overview__item-subheader">{groupTitle}</div>
                            )}
                            {![Constants.productTypeNames.ACCOMMODATION, Constants.productTypeNames.PACKAGE].includes(
                                resvType.type
                            ) &&
                                !item.pricematrix && (
                                    <div className="order-overview__item-subheader">
                                        {item.count} x {formatPrice(price, currency)}
                                    </div>
                                )}

                            {/* Group price from matrix */}
                            {item.pricematrix && (
                                <div className="order-overview__item-subheader">Grupp-pris, {item.count} deltagare</div>
                            )}

                            {item.minage >= 0 && item.maxage && (
                                <div className="order-overview__age-categories">
                                    <strong>{texts?.validfor || "Gäller för"}:</strong>
                                    <br />
                                    {getAgeCategoryTags(item)}
                                </div>
                            )}
                            <div className="order-overview__item-dates">
                                <CalendarIcon size={19} color="#333333" iconClass="order-overview__item-dates-icon" />
                                <span className="order-overview__item-dates-description text-capitalize">
                                    {isSeasonPass(item) && <>{texts?.seasonpass}</>}
                                    {showArrDepDates(item)}
                                </span>
                            </div>
                            {isActivity(item) && item.occations?.length > 0 && (
                                <div className="order-overview__item-time">
                                    <Icon
                                        name="FaRegClock"
                                        size={14}
                                        color="#333333"
                                        className="order-overview__item-time-icon"
                                    />
                                    <span className="order-overview__item-time-description">
                                        {item.occations.length} {texts.activityoccations}
                                    </span>
                                </div>
                            )}
                            {item.info?.guests && (
                                <div className="order-overview__item-guests">
                                    <GuestIcon size={14} color="#333333" iconClass="order-overview__item-guests-icon" />
                                    <span className="order-overview__item-guests-description">
                                        {item.info.guests.totalGuests || 0}
                                        {item.info.guests.totalGuests === 1
                                            ? ` ${texts?.generalguest}`
                                            : ` ${texts?.generalguests}`}
                                    </span>
                                </div>
                            )}
                            {![Constants.productTypeNames.ACCOMMODATION, Constants.productTypeNames.PACKAGE].includes(
                                resvType.type
                            ) &&
                                !item.detlid && (
                                    <div className="order-overview__item-incrementer">
                                        <Incrementer
                                            value={item.count || 0}
                                            label={title}
                                            maxValue={getMaxIncrementValue(item)}
                                            onChange={onValueChange}
                                            disabled={props.disableCartActions ? true : false}
                                        />
                                    </div>
                                )}
                            {item.info?.extras && <ExtrasList extras={item.info?.extras} />}
                        </div>
                    );
                })}
            </Accordion>
        );
    };

    // Reserv button
    const ReservButton = () => {
        if (!cartIsValid) {
            return <Button text={texts?.reserve} className="button--full-width order-overview__button" disabled />;
        }
        return (
            <Button
                onClick={continueToNextStep}
                text={texts?.reserve}
                className="button--full-width order-overview__button"
            />
        );
    };

    // Return
    return (
        <form className="order-overview">
            <div className="order-overview__list">
                <h2 className="order-overview__header">{texts?.orderoverviewheader}</h2>
                {props.reservationTypes?.length > 0 &&
                    props.reservationTypes.map(type => {
                        return renderReservationTypeItems(type, type.lid);
                    })}
            </div>
            {renderFeesAndTotalPrice()}
            {newOrModifiedCartProducts?.length > 0 && !props.disableCartActions && (
                <div>
                    <Button
                        className="button--invert order-overview__empty-cart-button"
                        onClick={() => {
                            dispatch(emptyCartProducts());
                            if (location.pathname.includes("reservation/extras")) {
                                navigate("/");
                            }
                        }}
                    >
                        <TrashCanIcon size={16} color="#333333" />
                        <span>{texts?.generalemptybasket || "Töm varukorgen"}</span>
                    </Button>
                </div>
            )}
            {isAccommodationsBookingError && (
                <Alert type="info" hideIcon>
                    <h3>{texts.accommodationsdontoverlapheader || texts?.accomodationoverlapnot}</h3>
                    <p>{texts?.accommodationsdontoverlap || texts?.accomodationdoesnotoverlapinshoppingcart}</p>
                </Alert>
            )}
            {props.showButtons && (
                <>
                    <ReservButton />
                    {cartIsValid && props.showAddMoreButton && (
                        <Button
                            text={texts?.addadditionalaccomodations}
                            onClick={addAdditionalAccommodation}
                            className="button--invert button--full-width order-overview__button"
                        />
                    )}
                </>
            )}
            {props.showCheckoutButton && (
                <Button
                    text={texts?.tocheckout}
                    to={checkoutUrl}
                    className={classnames("button--full-width order-overview__button", {
                        "soft-disabled": !props.products.length,
                    })}
                />
            )}
        </form>
    );
};

export default OrderOverview;
