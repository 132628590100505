import React from "react";
import { Form } from "react-bootstrap";
import RichText from "../UI/RichText";
import "./InformationConsent.scss";

// Information consent component
const InformationConsent = ({ agreement, agreementValue, onChange }) => {
    // On checkbox change
    const onRadioChange = data => {
        onChange(agreement, data.value);
    };

    // Radio button
    const Radio = ({ label, name, value, checked, onChange }) => {
        const id = name + value;

        return (
            <Form.Group>
                <Form.Check
                    type="radio"
                    label={label}
                    required={true}
                    id={id}
                    name={name}
                    value={value}
                    checked={checked}
                    onChange={() => {
                        onChange({ key: name, value: value });
                    }}
                />
            </Form.Group>
        );
    };

    return (
        <div className="information-consent">
            <h3 className="information-consent__header">{agreement.desc1}</h3>
            <div className="information-consent__body">
                <p className="information-consent__text">
                    <RichText content={agreement.desc2} />
                </p>
                <div className="information-consent__input">
                    <Radio
                        label={agreement.yestext}
                        name={`${agreement.gdprtypelid}`}
                        value={true}
                        onChange={onRadioChange}
                        checked={agreementValue === true}
                    />
                    <Radio
                        label={agreement.notext}
                        name={`${agreement.gdprtypelid}`}
                        value={false}
                        onChange={onRadioChange}
                        checked={agreementValue === false}
                    />
                </div>
            </div>
        </div>
    );
};

export default InformationConsent;
