import * as React from "react";
import defautIconStats from "../Icon.types";
import Theme from "../../Theme";

const Tick = ({
    color = Theme.palette.primary,
    color2 = "#FFFFFF",
    size = defautIconStats.size,
    iconClass = defautIconStats.iconClass,
}) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            style={{ height: size, width: size }}
            className={iconClass}
        >
            <ellipse fill={color} cx="256" cy="256" rx="256" ry="255.832" />
            <polygon
                fill={color2}
                points="235.472,392.08 114.432,297.784 148.848,253.616 223.176,311.52 345.848,134.504 
        391.88,166.392 "
            />
        </svg>
    );
};

export default Tick;
