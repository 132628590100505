import queryString from "query-string";
import AxiosClient from "../../API/AxiosClient";
import * as types from "./types";
import * as Constants from "../../Constants";
import { setAvailableCriterias, fetchAvailableCriteriasFailed } from "./availableFilters";

const SYSTEM_LANGUAGE = Constants.system.LANGUAGE;

const setSessionLanguage = langToUse => {
    // Store language in global space
    window.sessionStorage.setItem("__LANG__", langToUse);
    window.__LANG__ = langToUse;
    window.__BASE_URL__ = window.__BASE_URL_NO_LANG__ + window.__LANG__;

    // We need to also reconfigure Axios with a new default URL that includes the specified language
    AxiosClient.defaults.baseURL = window.__BASE_URL__ + window.__BASE_API_PATH;
};

export const bootstrapClient = () => {
    return dispatch => {
        const qs = queryString.parse(window.location.search);
        let langToUse;

        if ("lang" in qs) {
            langToUse = qs["lang"];
        } else if (typeof Storage !== "undefined") {
            langToUse = window.sessionStorage.getItem("__LANG__");
        } else if (typeof window.__LANG__ !== "undefined" && window.__LANG__) {
            langToUse = window.__LANG__;
        }

        if (langToUse) {
            setSessionLanguage(langToUse);
            dispatch(setLang(langToUse));
            dispatch(fetchClientData(langToUse));

            fetchLanguages().then(({ languages }) => {
                dispatch(setLanguages(languages));
            });
        } else {
            fetchLanguages().then(({ lang, languages }) => {
                setSessionLanguage(lang);
                dispatch(setLang(lang));
                dispatch(setLanguages(languages));
                dispatch(fetchClientData(lang));
            });
        }
    };
};

const fetchLanguages = () =>
    AxiosClient.get(window.__BASE_URL_NO_LANG__ + `${SYSTEM_LANGUAGE}/v3/api/languages`).then(response => {
        if (response.data.successful) {
            const languages = response.data.payload;
            const defaultLanguage = languages.find(lang => lang.default);
            const lang = defaultLanguage ? defaultLanguage.code : SYSTEM_LANGUAGE;

            return { lang, languages };
        }
    });

/*
  Use to fetch ClientData / CalendarData / TextsData / CriteriasData.
*/
export const fetchClientData = langToUse => {
    const isFulfilled = res => res.status === "fulfilled";
    const isRejected = res => res.status === "rejected";

    const clientDataUrls = [
        window.__BASE_URL_NO_LANG__ + `${langToUse}/v3/api/client`,
        window.__BASE_URL_NO_LANG__ + `${langToUse}/v3/api/calendars`,
        window.__BASE_URL_NO_LANG__ + `${langToUse}/v3/api/text/client`,
        window.__BASE_URL_NO_LANG__ + `${langToUse}/v3/api/criterias`,
    ];

    return dispatch => {
        Promise.allSettled(clientDataUrls.map(AxiosClient.get)).then(
            ([clientRes, calendarsRes, textRes, criteriasRes]) => {
                // Client response.
                if (isFulfilled(clientRes)) {
                    dispatch(setClientData(clientRes.value.data.payload));
                } else if (isRejected(clientRes)) {
                    dispatch(fetchClientDataFailed(clientRes.reason));
                }

                // Calendars response.
                if (isFulfilled(calendarsRes)) {
                    dispatch(setCalendarData(calendarsRes.value.data.payload));
                } else if (isRejected(calendarsRes)) {
                    dispatch(fetchCalendarDataFailed(calendarsRes.reason));
                }

                // Texts response.
                if (isFulfilled(textRes)) {
                    dispatch(setTextData(textRes.value.data.payload));
                } else if (isRejected(textRes)) {
                    dispatch(fetchTextDataFailed(textRes.reason));
                }

                // Criterias response.
                if (isFulfilled(criteriasRes)) {
                    dispatch(setAvailableCriterias(criteriasRes.value.data.payload));
                } else if (isRejected(criteriasRes)) {
                    fetchAvailableCriteriasFailed(criteriasRes.reason);
                }
            }
        );
    };
};

const setClientData = payload => {
    return {
        type: types.SET_CLIENTDATA,
        payload: payload,
    };
};

const fetchClientDataFailed = error => {
    return {
        type: types.FETCH_CLIENTDATA_FAILED,
        error: error,
    };
};

const setCalendarData = payload => {
    return {
        type: types.SET_CALENDAR_DATA,
        payload: payload,
    };
};

const fetchCalendarDataFailed = error => {
    return {
        type: types.FETCH_CALENDAR_DATA_FAILED,
        error: error,
    };
};

const setTextData = texts => {
    return {
        type: types.SET_TEXT_DATA,
        texts: texts,
    };
};

const fetchTextDataFailed = error => {
    return {
        type: types.FETCH_TEXT_DATA_FAILED,
        error: error,
    };
};

const setLang = langToUse => {
    return {
        type: types.SET_LANG,
        lang: langToUse,
    };
};

const setLanguages = languages => {
    return {
        type: types.SET_LANGUAGES,
        languages,
    };
};

export const setCookie = cookieData => {
    return {
        type: types.SET_COOKIE,
        cookie: cookieData,
    };
};

export const signOutAgent = () => {
    return {
        type: types.SIGN_OUT_AGENT,
    };
};
