import React, { useEffect, useState } from "react";
import AxiosClient from "../../API/AxiosClient";
import { Button, ButtonSkeleton, Heading, Input, Status, UserIcon, style } from "@r360/library";
import useTranslate from "../../hooks/useTranslate";
import "./ResetPassword.scss";
import { useNavigate } from "react-router-dom";
import useAppDispatch from "../../hooks/useAppDispatch";
import { setRequestsLoading } from "../../store/actions/axiosStatus";
import useAppSelector from "../../hooks/useAppSelector";
import { RootState } from "../..";
import { validatePassword } from "../../ValidationUtils";

type TResetPassword = {
    token?: string;
};

export const ResetPassword = ({ token }: TResetPassword) => {
    const dispatch = useAppDispatch();
    const t = useTranslate();
    const navigate = useNavigate();
    const [newPassword, setNewPassword] = useState("");
    const [repeatNewPassword, setRepeatNewPassword] = useState("");
    const [passwordHasChanged, setPasswordHasChanged] = useState(false);
    const [tokenVerified, setTokenVerified] = useState<boolean | null>(null);
    const { requestsLoading } = useAppSelector((state: RootState) => state.axiosStatus);
    const resetPasswordInProgress = requestsLoading["resetPassword"];

    const passwordsAreEqual = newPassword === repeatNewPassword;

    const passwordsAreValid = validatePassword(newPassword) && validatePassword(repeatNewPassword) && passwordsAreEqual;

    useEffect(() => {
        AxiosClient.get(`users/verify_password_reset_token?token=${token}`)
            .then(() => {
                setTokenVerified(true);
            })
            .catch(() => {
                setTokenVerified(false);
            });
    }, [token]);

    const attemptResetPassword = () => {
        if (passwordsAreValid && tokenVerified) {
            dispatch(setRequestsLoading({ request: "resetPassword", loading: true }));

            const sendPasswordWithToken = async () => {
                try {
                    const response = await AxiosClient.put("users/reset_password", {
                        password: newPassword,
                        repeatpassword: repeatNewPassword,
                        token: token,
                    });

                    if (response) {
                        setPasswordHasChanged(true);
                        dispatch(setRequestsLoading({ request: "resetPassword", loading: false }));
                    }
                } catch (error) {
                    console.error(error);
                    setTokenVerified(false);
                    dispatch(setRequestsLoading({ request: "resetPassword", loading: false }));
                }
            };

            sendPasswordWithToken();
        }
    };

    const handleLoginClick = () => {
        navigate("/user/login");
    };

    return (
        <div className="reset-password">
            <Heading type="h2" className="u-mb-24 u-text-center" onDarkBackground>
                {t("book.account.change_password")}
            </Heading>
            <form
                onSubmit={e => {
                    e.preventDefault();
                    attemptResetPassword();
                }}
                noValidate
            >
                {passwordHasChanged || tokenVerified === false ? (
                    <div className="u-d-flex u-flex-column u-align-items-center u-gap-12 u-pt-30 u-mb-54">
                        <Status type={passwordHasChanged ? "success" : "error"} size={24} />
                        <p className="u-mb-0 u-color-white">
                            {passwordHasChanged
                                ? t("book.account.password_changed")
                                : t("book.account.reset_password_token_expired")}
                        </p>
                    </div>
                ) : (
                    <div className="u-d-flex u-flex-column u-gap-12 u-mb-24">
                        <Input
                            type="password"
                            label={t("book.account.new_password")}
                            value={newPassword}
                            isValid={newPassword ? validatePassword(newPassword) : undefined}
                            errorMessage={t("book.account.password.validation.length")}
                            id="username"
                            name="username"
                            onChange={e => {
                                setNewPassword(e.target.value);
                            }}
                            onDarkBackground
                            showValidationMessageOnBlurOnly
                            autoComplete="off"
                        />
                        <Input
                            label={t("book.account.confirm_password")}
                            type="password"
                            id="password"
                            name="password"
                            value={repeatNewPassword}
                            isValid={repeatNewPassword ? passwordsAreEqual : undefined}
                            errorMessage={t("book.account.password.validation")}
                            onChange={e => {
                                setRepeatNewPassword(e.target.value);
                            }}
                            onDarkBackground
                            showValidationMessageOnBlurOnly
                            autoComplete="off"
                        />
                    </div>
                )}
                {passwordHasChanged || tokenVerified === false ? (
                    <Button
                        type="login"
                        onClick={handleLoginClick}
                        transparentColor="white"
                        rightIcon={<UserIcon color={style.darkBlueColor} />}
                        fullWidth
                    >
                        {t("book.menu.login")}
                    </Button>
                ) : (
                    <>
                        {resetPasswordInProgress ? (
                            <ButtonSkeleton width="100%" />
                        ) : (
                            <Button
                                submit
                                fullWidth
                                disabled={!passwordsAreValid}
                                type="transparent"
                                transparentColor="white"
                            >
                                {t("book.general.save")}
                            </Button>
                        )}
                    </>
                )}
            </form>
        </div>
    );
};
