import { CalendarIcon, ErrorMessage, style, TagIcon, UserIcon } from "@r360/library";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { RootState } from "../..";
import { ActivityDesktop } from "../../assets/reservationTypes/Activity";
import { sortGroups, sortPackageItems } from "../../BusinessUtils";
import { MultipleVariantsCard } from "../../components/MultipleVariantsCard/MultipleVariantsCard";
import { MultipleVariantsCardSkeleton } from "../../components/MultipleVariantsCard/MultipleVariantsCardSkeleton";
import { NoSearchResult } from "../../components/NoSearchResult/NoSearchResult";
import { ReservationTypeHeader } from "../../components/ReservationTypeHeader/ReservationTypeHeader";
import { formatPrice } from "../../Helper";
import { newFilterGroupItems } from "../../hooks";
import useAppSelector from "../../hooks/useAppSelector";
import useChangeFilter from "../../hooks/useChangeFilter";
import useChangeSearch from "../../hooks/useChangeSearch";
import useTranslate from "../../hooks/useTranslate";
import { resvTypeByLid } from "../../selectors/Selectors";
import {
    TCriteriaItem,
    TGeneralAges,
    TItem,
    TPackageGroup,
    TPackageReservationResult,
    TReservationType,
    TReservationTypeLid,
} from "../../store/types";

type TPackageResultPage = {
    reservationTypeid: TReservationTypeLid;
};

export const PackageResultPage = ({ reservationTypeid }: TPackageResultPage) => {
    const filter = useAppSelector((state: RootState) => state.filter);
    const packageFilters = filter[reservationTypeid];
    const { isDesktopNewDesign } = useAppSelector((state: RootState) => state.window);
    const isLoading = useAppSelector((state: RootState) => state.axiosStatus.loading);
    const clientData = useAppSelector((state: RootState) => state.clientData);
    const currency = useAppSelector((state: RootState) => state.clientData?.options?.general?.currency);
    const reservationTypeResult = useAppSelector((state: RootState) => state.reservationResult);
    const packagesResult: TPackageReservationResult = reservationTypeResult[reservationTypeid];
    const [filteredData, setFilteredData] = useState<TPackageGroup[]>([]);
    const t = useTranslate();
    const reservationType: TReservationType | undefined = useAppSelector((state: RootState) =>
        resvTypeByLid(state, reservationTypeid)
    );
    const reservationTypeCriterias = reservationType?.criterias || {};
    const checkPriceAndAvailability = packagesResult?.search?.checkPriceAndAvailability;

    useChangeSearch({
        reservationResultSearch: packagesResult?.search,
        reservationTypeId: reservationTypeid,
        skipGuestsOnSearchWithPrice: false,
        allowSearchWithoutPrice: true,
    });

    useChangeFilter(reservationTypeid);

    useEffect(() => {
        const criterias = Object.values(reservationTypeCriterias)
            .flat()
            .reduce((acc, curr) => {
                acc[curr.code] = curr;
                return acc;
            }, {} as { [key: number]: TCriteriaItem });

        if (packagesResult?.groups) {
            let filteredData: TPackageGroup[] = Object.values(packagesResult?.groups || {})
                .map((group: TPackageGroup) => {
                    return newFilterGroupItems(group, packageFilters || {}, criterias);
                })
                .filter((group: TPackageGroup) => group.items.length > 0);

            // Sort package groups and items.
            filteredData = sortGroups(filteredData).map(group => {
                group.items = sortPackageItems(group.items);
                return group;
            });

            setFilteredData(filteredData);
        }
    }, [packagesResult?.resultSetId, packageFilters]);

    const getPackageCardItemColumns = (item: TItem) => {
        const guestCount = (packagesResult?.search.guests || []).length;
        return [
            {
                title: `${moment(item.arrdate).format("D MMMM")} - ${moment(item.depdate).format("D MMMM")}`,
                icon: <CalendarIcon />,
            },
            ...(checkPriceAndAvailability
                ? [
                      {
                          title:
                              guestCount === 1
                                  ? t("book.general.number_person", [guestCount])
                                  : t("book.general.number_persons", [guestCount]),
                          icon: <UserIcon color={style.brandBlueColor} />,
                      },
                      {
                          title: (
                              <>
                                  {`${t("book.package.total_price")} `}
                                  {formatPrice(item.price, currency)}
                              </>
                          ),
                          icon: <TagIcon color={style.brandBlueColor} />,
                      },
                  ]
                : []),
        ];
    };

    const renderPackageList = () => {
        if (isLoading) {
            return [...Array(8)].map((_, i) => <MultipleVariantsCardSkeleton key={i} isDesktop={isDesktopNewDesign} />);
        } else if (filteredData.length > 0) {
            return filteredData.map((group, i) => (
                <MultipleVariantsCard
                    key={group.id}
                    group={group}
                    description={group.weblong}
                    items={group.items}
                    title={group.title}
                    images={group.images}
                    showVariants
                    currency={currency}
                    getItemColumns={getPackageCardItemColumns}
                    priceLabelText={t("book.package.groupprice_from")}
                    showItemsButtonText={t("book.package.show_occurrences", [group.items.length])}
                    fallbackImage={ActivityDesktop}
                    index={i}
                    arrayLength={filteredData.length}
                    unlimitedBookings
                    searchWithoutPrice={!checkPriceAndAvailability}
                    decorateItemBeforeCart={item => {
                        const generalAges: TGeneralAges = JSON.parse(clientData.options?.general?.ages);

                        const decoratedItem: TItem = {
                            ...group,
                            ...item,
                            info: {
                                guests: {
                                    totalGuests: packagesResult?.search.guests.length || 0,
                                },
                            },
                        };

                        generalAges[group.type].forEach(age => {
                            if (decoratedItem.info?.guests) {
                                decoratedItem.info.guests[age.key] =
                                    packagesResult?.search?.guests.filter(
                                        guestAge => guestAge >= age.min && guestAge <= age.max
                                    ).length || 0;
                            }
                        });

                        return decoratedItem;
                    }}
                    withMoreImageButton
                    reservationType={reservationType?.type}
                    newCartItemOnEveryAdd
                />
            ));
        } else if ((packagesResult?.hitsMatchingFilter === "0" || !filteredData?.length) && !isLoading) {
            return <NoSearchResult />;
        } else if (packagesResult?.error && !isLoading) {
            return (
                <div className="u-pt-24">
                    <ErrorMessage
                        headingText={t("book.general.error")}
                        primaryButtonText={t("book.general.reload_page")}
                        primaryButtonOnClick={() => window.location.reload()}
                    />
                </div>
            );
        }
    };

    const showFilter =
        packagesResult?.groups &&
        reservationTypeCriterias &&
        Object.values(packagesResult.groups).length > 0 &&
        Object.values(reservationTypeCriterias).flat().length > 0;

    return packagesResult?.search || isLoading ? (
        <>
            <ReservationTypeHeader
                reservationTypeId={reservationTypeid}
                hits={filteredData?.length ?? 0}
                filtersAvailable={showFilter}
            />
            {renderPackageList()}
        </>
    ) : null;
};
