import React from "react";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import Registration from "../../components/Registration/OldRegistration/Registration";

/**
 * Registration page
 *
 * Supports the "?path=<path>" parameter to redirect user back to flow after registration
 */
const RegistrationPage = () => {
    const location = useLocation();
    const qs = queryString.parse(location.search);

    return (
        <div className="container">
            <Registration registrationPage={true} redirectPath={qs.path} />
        </div>
    );
};

export default RegistrationPage;
