import moment from "moment-timezone";
import { parsePhoneNumber } from "awesome-phonenumber";
import * as Constants from "./Constants";
import { min } from "lodash";
import { getGeneralAgeCategoryPrices } from "./BusinessUtilsTypescript";

/**
 * This is business utility functions for business logic
 */

/**
 * Show arrival/departure dates with correct formatting by reservation type
 *
 * @param {object} product
 * @param {string} format
 * @returns {string}
 */
export const showArrDepDates = (
    product,
    format = "ddd D MMM", // ex. fre 4 jun
    differentDatesWithTimes = false
) => {
    const arrdate = moment(product.arrdate);
    const depdate = moment(product.depdate);

    if (isAccommodation(product)) {
        return `${arrdate.format(format)} - ${depdate.format(format)}`;
    } else if (isActivity(product)) {
        if (arrdate.isSame(depdate)) {
            // Same date
            if (!product.occations || product.occations?.length === 1) {
                // Show date and time
                return `${arrdate.format(format)} ${product.fromtime} - ${product.totime}`;
            } else {
                // Show only date
                return arrdate.format(format);
            }
        } else if (differentDatesWithTimes) {
            // Different dates
            return `${arrdate.format(format)} - ${depdate.format(format)} ${product.fromtime} - ${product.totime}`;
        } else {
            // Different dates
            return `${arrdate.format(format)} - ${depdate.format(format)}`;
        }
    } else {
        // Skipass, Letting, Rental
        const arrdateFormatted = arrdate.format(format);
        const depdateFormatted = depdate.format(format);
        return arrdateFormatted !== depdateFormatted ? `${arrdateFormatted} - ${depdateFormatted}` : arrdateFormatted;
    }
};

export const isSeasonPass = product => {
    // 0 days or same day is a seasonpass
    return product.kind === Constants.productTypeNames.SKIPASS && product.days === 0;
};

export const isPackage = product => Constants.productTypeNames.PACKAGE === product.kind;
export const isSkipass = product => Constants.productTypeNames.SKIPASS === product.kind;
export const isRegularSkipass = product => isSkipass(product) && !isSeasonPass(product);
export const isSkipassWithPhotoMandatory = product => isSkipass(product) && product.photomandatory;
export const isSkipassForFastCheckout = product =>
    isRegularSkipass(product) && !product.photomandatory && product.connected;
export const isProductForFastCheckout = () => isSkipassForFastCheckout;
export const isLetting = product => Constants.productTypeNames.LETTING === product.kind;
export const isAccommodation = product =>
    Constants.productTypeNames.ACCOMMODATION === product.kind && product.usecheckin;
export const isAccommodationExtra = product =>
    Constants.productTypeNames.ACCOMMODATION === product.kind && !product.usecheckin;
export const isExtra = product => (product.parent ? true : false);
export const isParent = product => (product.parent ? false : true);
export const isActivity = product => Constants.productTypeNames.ACTIVITY === product.kind;
export const isActivityWithDynamicPrice = product => isActivity(product) && product.dynamicprices === true;
export const isRental = product => Constants.productTypeNames.RENTAL === product.kind;

export const isBooked = product => product.bookstat === "B";
export const isCancelled = product => product.bookstat === "C";
export const hasBookstat = product => !!product.bookstat;

export const isDetail = product => product && product.detlid > 0;
export const isNew = product => product && !product.detlid;

export const hasChildren = product => product.children && product.children.length;

export const sortBySortorder = items => {
    return items.slice().sort((a, b) => {
        return a.sortorder - b.sortorder; // lowest sortorder first, e.g. 0 --> 10
    });
};

/**
 * Sort products in cart by their added timestamp, first added product is sorted first
 *
 * @param {array} products
 */
export const sortCartProducts = products =>
    products.slice().sort((a, b) => new Date(a.cartItemAddedTimestamp) - new Date(b.cartItemAddedTimestamp));

/**
 * Sort skipasses by sortorder and then days if sortorder is the same.
 *
 * This function is pure and returns a new list of skipasses.
 *
 * @param {array} skipasses
 * @returns {array} Ordered list of skipasses
 */
export const sortSkipasses = products => {
    // Sort skipasses by sortorder and then days if sortorder is the same
    // Sort skipasses by days, highest days are at the top
    // Sort skipasses by age, oldest at the top
    // Sort skipasses by names, A at the top
    return products
        .slice() // copy array to be pure
        .sort((a, b) => {
            // sort copy
            const bySortorder = a.sortorder - b.sortorder; // lowest sortorder first, e.g 0 --> 10
            const byDays = b.days - a.days; // highest days first, e.g 5 --> 1
            const byAge = b.maxage - a.maxage; // highest age first, e.g senior --> child
            const byName = a.title?.toLowerCase().localeCompare(b.title.toLowerCase()); // names in ascending order, e.g A --> Ö

            // If sortorder is equal...
            if (bySortorder === 0) {
                // ... then sort by days
                if (byDays === 0) {
                    // ... then sort by age
                    if (byAge === 0) {
                        // ... then sort by name
                        return byName;
                    } else {
                        return byAge;
                    }
                } else {
                    return byDays;
                }
            } else {
                return bySortorder;
            }
        });
};

/**
 * Sort a list of rental products (arttype B) like bikes etc
 *
 * @param {array} products
 * @returns {array}
 */
export const sortRentals = products => {
    const sorted = products
        .slice() // copy array to be pure
        .sort((a, b) => {
            // sort copy
            const bySortorder = a.sortorder - b.sortorder; // lowest sortorder first, e.g 0 --> 10
            const byName = a.title.toLowerCase().localeCompare(b.title.toLowerCase()); // names in ascending order, e.g A --> Ö

            // If sortorder is equal...
            if (bySortorder === 0) {
                // ... then sort by name
                return byName;
            } else {
                return bySortorder;
            }
        });

    return sorted;
};

/**
 * Sort a list of letting products (skiing)
 *
 * @param {array} products
 * @returns {array}
 */
export const sortLettings = (products, lowestAgeFirst = false) => {
    const sorted = products
        .slice() // copy array to be pure
        .sort((a, b) => {
            // sort copy
            const bySortorder = a.sortorder - b.sortorder; // lowest sortorder first, e.g 0 --> 10
            const byAge = b.maxage - a.maxage; // highest age first, e.g senior --> child
            const byAgeLowestFirst = a.maxage - b.maxage; // lowest age first, e.g child --> senior
            const byName = a.title.toLowerCase().localeCompare(b.title.toLowerCase()); // names in ascending order, e.g A --> Ö

            // If sortorder is equal...
            if (bySortorder === 0) {
                // ... then sort by age
                if (byAge === 0) {
                    // ... then sort by name
                    return byName;
                } else {
                    return lowestAgeFirst ? byAgeLowestFirst : byAge;
                }
            } else {
                return bySortorder;
            }
        });

    return sorted;
};

/**
 * Sorts an array of package items based on their arrival and departure dates.
 *
 * @param {Array} products - The array of products to be sorted.
 * @returns {Array} - The sorted array of products.
 */
export const sortPackageItems = products => {
    return products.slice().sort((a, b) => {
        // Sort by arrdate in ascending order. E.g. 2022-01-01 11:00:00 --> 2022-01-01 12:00:00
        const aArrdate = moment(a.arrdate);
        const bArrdate = moment(b.arrdate);

        // If the arrdate is the same, sort by depdate in ascending order.
        if (aArrdate.isSame(bArrdate)) {
            const aDepdate = moment(a.depdate);
            const bDepdate = moment(b.depdate);

            return moment.duration(aDepdate.diff(bDepdate)).asMinutes();
        }

        return moment.duration(aArrdate.diff(bArrdate)).asMinutes();
    });
};

/**
 * Sorts an array of rental items based on their sort order.
 *
 * @param {Array} products - The array of rental items to be sorted.
 * @returns {Array} - The sorted array of rental items.
 */
export const sortRentalItems = products => {
    return products.slice().sort((a, b) => {
        const sortorderA = a.sortorder;
        const sortorderB = b.sortorder;

        if (sortorderA === sortorderB) {
            return Math.random() - 0.5; // Randomize the order if sortorder is the same.
        }

        return sortorderA - sortorderB;
    });
};

/**
 * Sorts an array of skipass items based on their sort order.
 *
 * @param {Array} products - The array of skipass items to be sorted.
 * @returns {Array} - The sorted array of skipass items.
 */
export const sortSkipassItems = products => {
    return products.slice().sort((a, b) => {
        const sortorderA = a.sortorder;
        const sortorderB = b.sortorder;

        if (sortorderA === sortorderB) {
            return Math.random() - 0.5; // Randomize the order if sortorder is the same.
        }

        return sortorderA - sortorderB;
    });
};

/**
 * Sorts an array of letting items based on the minimum age.
 *
 * @param {Array} products - The array of letting items to be sorted.
 * @returns {Array} - The sorted array of letting items.
 */
export const sortLettingItems = products => {
    return products.slice().sort((a, b) => {
        // Sort by lowest age first.
        return a.minage - b.minage;
    });
};

/**
 * Sorts the activity items based on their occations in ascending order.
 *
 * @param {Array} products - The array of activity items to be sorted.
 * @returns {Array} - The sorted array of activity items.
 */
export const sortActivityItems = products => {
    return products.slice().sort((a, b) => {
        // Sort by the occations in ascending order. E.g. 2022-01-01 11:00:00 --> 2022-01-01 12:00:00
        const aFirstOccation = a.occations?.[0];
        const bFirstOccation = b.occations?.[0];

        if (aFirstOccation && bFirstOccation) {
            const aFirstOccationMoment = moment(`${aFirstOccation.date} ${aFirstOccation.fromtime}`);
            const bFirstOccationMoment = moment(`${bFirstOccation.date} ${bFirstOccation.fromtime}`);

            return moment.duration(aFirstOccationMoment.diff(bFirstOccationMoment)).asMinutes();
        }

        return 0;
    });
};

/**
 * Sort activities
 *
 * @param {array} products
 * @returns {array}
 */
export const sortActivities = products => {
    const sorted = products
        .slice() // copy array to be pure
        .sort((a, b) => {
            // sort copy
            const bySortorder = a.sortorder - b.sortorder; // lowest sortorder first, e.g 0 --> 10
            const byAge = b.maxage - a.maxage; // lowest age first, e.g child --> senior
            const byName = a.title?.toLowerCase().localeCompare(b.title?.toLowerCase()) || 0; // names in ascending order, e.g A --> Ö

            const sortOrder = bySortorder || byAge || byName || 0;

            if (sortOrder !== 0) {
                return sortOrder;
            }

            // If the activities couldn't be distinguished using using sortorder, age or name,
            // sort by the occations in ascending order. E.g. 2022-01-01 11:00:00 --> 2022-01-01 12:00:00
            const aFirstOccation = a.occations?.[0];
            const bFirstOccation = b.occations?.[0];

            if (aFirstOccation && bFirstOccation) {
                const aFirstOccationMoment = moment(`${aFirstOccation.date} ${aFirstOccation.fromtime}`);
                const bFirstOccationMoment = moment(`${bFirstOccation.date} ${bFirstOccation.fromtime}`);

                return moment.duration(aFirstOccationMoment.diff(bFirstOccationMoment)).asMinutes();
            }

            return 0;
        });

    return sorted;
};

/**
 * Sorts an array of groups based on their sort order.
 * If the sort order is the same, the order is randomized.
 * The sort order is determined by the sortorder property of the group, groupitem or the first item in the group.
 *
 * @param {Array} groups - The array of groups to be sorted.
 * @returns {Array} - The sorted array of groups.
 */
export const sortGroups = groups => {
    return groups.slice().sort((a, b) => {
        const sortorderA = a.sortorder ?? a.groupitem?.sortorder ?? a.items?.[0]?.sortorder ?? 0;

        const sortorderB = b.sortorder ?? b.groupitem?.sortorder ?? b.items?.[0]?.sortorder ?? 0;

        if (sortorderA === sortorderB) {
            return Math.random() - 0.5; // Randomize the order if sortorder is the same.
        }

        return sortorderA - sortorderB;
    });
};

/**
 * Group activities on poollid
 *
 * @param {array} activitiesResult
 * @returns {array}
 */
export const groupActivities = groups => {
    return groups
        ? Object.values(
              groups.reduce((arr, group) => {
                  group.items.forEach(item => {
                      if (item.poollid) {
                          const { items, ...rest } = group;
                          arr[item.poollid] = {
                              ...rest,
                              items: arr[item.poollid]?.items ? arr[item.poollid].items.concat(item) : [item],
                          };
                      }
                  });

                  return arr;
              }, {})
          )
        : [];
};

export const setAdditionalOnBookedAccommodationProduct = product => {
    if (
        !isBooked(product) ||
        (!isAccommodation(product) && !isAccommodationExtra(product)) ||
        !product.quantity ||
        !product.bookedquantity ||
        product.quantity <= product.bookedquantity
    ) {
        return { ...product, additional: null };
    }

    const quantity = product.quantity - product.bookedquantity;
    const price = (product.aprice || 0) * quantity;

    return { ...product, additional: { quantity, price } };
};

export const setAdditionalOnBookedAccommodationProductExtras = product => {
    if (isAccommodation(product)) {
        return {
            ...product,
            info: {
                ...product.info,
                extras: (product.info?.extras || []).map(setAdditionalOnBookedAccommodationProduct),
            },
        };
    }
    return product;
};

export const accommodationHasNewOrModifiedExtras = product =>
    isAccommodation(product) &&
    (product?.info?.extras || []).some(
        extra =>
            (!isBooked(extra) && extra.quantity > 0 && (!isBooked(product) || !extra.rules.mandatory_web)) ||
            extra.additional
    );

export const accommodationHasNewConnectedProducts = (products, accommodation) =>
    isAccommodation(accommodation) &&
    products
        .filter(product => isNew(product))
        .some(product => product.connectedProductId && product.connectedProductId === accommodation.cartItemId);

export const getNewOrModifiedCartProducts = products =>
    products.filter(
        product =>
            isNew(product) ||
            accommodationHasNewOrModifiedExtras(product) ||
            accommodationHasNewConnectedProducts(products, product)
    );

export const getNewCartProducts = products => products.filter(product => !hasBookstat(product));

export const isSystemPool = product => product.poollid <= 999;

/**
 * Keep numbers from string.
 * Good for cleaning phone numbers.
 *
 * Usage:
 * keepNumbers("+070123 45-6AB"); // 070123456
 *
 * @param {string} str
 * @returns string
 */
export const keepNumbers = str => str && str.replace(/[^\d]/g, "");

/**
 * Convert a valid mobile phone prefix and phone number to a national format, supported for the selected prefix.
 *
 * Example:
 * Swedish mobile phone numbers starting with 7xxx will be converted automatically to 07xxx
 *
 * @param {string} prefix
 * @param {string} phoneNumber
 * @returns {string}
 */
export const mobilePhoneNumberToNationalFormat = (prefix, phoneNumber) => {
    if (!prefix) {
        throw new Error("Invalid mobile phone number! Cannot convert to national format");
    }

    // Remove any unnessesary plus signs from prefix
    prefix = prefix.replace("+", "");

    const fullPhoneNumber = "+" + prefix + keepNumbers(phoneNumber);
    const pn = parsePhoneNumber(fullPhoneNumber);

    if (pn.valid && pn.typeIsMobile) {
        return keepNumbers(pn.number.national);
    } else {
        throw new Error("Invalid mobile phone number! Cannot convert to national format");
    }
};

export const showExtraInOrderOverviewWhenChangeReservation = (product, extra) => {
    return (
        (!isBooked(extra) && extra.quantity > 0 && (!isBooked(product) || !extra.rules.mandatory_web)) ||
        extra.additional
    );
};

/**
 *
 * @param {TItem[]} items
 * @param {TGeneralAges} generalAges
 * @returns {number}
 */
export const getLowestPrice = (items, generalAges = undefined) => {
    const prices = items.map(item =>
        hasAnyGuestCategoryPrice(item) ? getLowestPriceByGuestCategories(item, generalAges) : item.price
    );
    const lowestPrice = Math.min(...prices); // Math.min(1,2,3,4) --> 1
    return lowestPrice;
};

export const getLowestPriceAccommodation = items => {
    const lowestPrice = items.reduce(
        (item, acc) => {
            if (item.price < acc.price) {
                return { price: item.price, ordinaryprice: item.ordinaryprice > 0 ? item.ordinaryprice : 0 };
            }
            return acc;
        },
        { price: items[0].price, ordinaryprice: items[0].ordinaryprice }
    );

    return lowestPrice;
};

/**
 * Get the lowest price for a product which has multiple prices by guest categories
 *
 * E.g
 * price = price for c5
 * pricec1 = price for c1
 * pricec2 = price for c2
 * pricec3 = price for c3
 * pricec4 = price for c4
 *
 * @param {TItem} item
 * @param {TGeneralAges} generalAges
 * @returns {number}
 */
export const getLowestPriceByGuestCategories = (item, generalAges) => {
    if (!generalAges) {
        throw new Error("Missing general age categories when calculating lowest price for product");
    }

    const prices = getPricesByGuestCategories(item, generalAges);
    return min([item.price, ...prices]);
};

/**
 *
 * @param {TItem} item
 * @param {TGeneralAges} generalAges
 * @returns {array}
 */
export const getPricesByGuestCategories = (item, generalAges) => {
    if (!generalAges) {
        throw new Error("Missing general age categories when calculating prices for product");
    }

    const prices = generalAges[item.type]
        .filter(ages => item.minage <= ages.min && item.maxage >= ages.max) // use valid guest categories
        .map(ages => (ages.agecat === "c5" ? "price" : `price${ages.agecat}`)) // generate price fields
        .map(priceField => parseInt(item[priceField], 10)); // generate amounts

    return prices;
};

/**
 *
 * @param {TStringIndexedObjectWithNumbers} guests
 * @param {TGeneralAges} generalAges
 */
export const getTotalPriceByGuestCategories = (item, guests, generalAges) => {
    const prices = getGeneralAgeCategoryPrices(item, generalAges);

    // Calculate the sum of prices assigned to each age category
    const totalPrice = Array.from(prices.entries()).reduce((sum, [ageCat, price]) => {
        const guestCount = ageCat in guests ? guests[ageCat] : 0;
        return sum + price * guestCount;
    }, 0);

    return totalPrice;
};

/**
 * @param {TItem} item
 * @returns {boolean}
 */
export const hasSamePriceInAllGuestCategories = item => {
    return (
        item.price === item.pricec1 &&
        item.price === item.pricec2 &&
        item.price === item.pricec3 &&
        item.price === item.pricec4
    );
};

export const hasAnyGuestCategoryPrice = item =>
    "pricec1" in item || "pricec2" in item || "pricec3" in item || "pricec4" in item;

export const hasDynamicPrices = item => item?.dynamicprices === true && item?.pricematrix;

/**
 * Get inspection data for a product to help customer support and developers to debug in production
 * by inspecting the source code.
 *
 * In the future we will show this contextual info in a nicer way.
 *
 * Usage:
 * <article className="classA, classB" {...getInspectionDataForProduct(item)}>...</article>
 *
 * @param {TItem} item
 * @returns {object}
 */
export const getInspectionDataForProduct = item => {
    return {
        "data-inspectiontype": "product",
        "data-poollid": item.poollid,
        "data-unitlid": item.unitlid,
        "data-parentpool": item.parentpool,
        "data-activitylid": item.activitylid,
        "data-pooldesc1": item.pooldesc1,
        "data-unitdesc1": item.unitdesc1,
        "data-pricecode": item.pricecode,
        "data-price": item.price,
        "data-pricec1": item.pricec1,
        "data-pricec2": item.pricec2,
        "data-pricec3": item.pricec3,
        "data-pricec4": item.pricec4,
        "data-ordinaryprice": item.ordinaryprice,
        "data-pricetext": item.pricetext,
        "data-sortorder": item.sortorder,
        "data-promotioncode": item.promotionCode,
        "data-freesale": item.freesale,
        "data-channellid": item.channellid,
        "data-connected": item.connected,
        "data-title": item.title,
        "data-pooldesc4": item.pooldesc4,
        "data-unitdesc4": item.unitdesc4,
        "data-minage": item.minage,
        "data-maxage": item.maxage,
        "data-photomandatory": item.photomandatory,
        "data-ownerlid": item.ownerlid,
        "data-days": item.days,
    };
};

export const getInspectionDataForGroup = group => {
    return {
        "data-inspectiontype": "group",
        "data-groupid": group.id,
        "data-grouppoollid": group.grouppoollid,
        "data-itemcount": group.items && group.items.length,
        "data-title": group?.groupitem.title || "",
        "data-sortorder": group?.groupitem.sortorder,
    };
};

export const getInspectionDataForExtrasItem = extrasItem => {
    return {
        "data-inspectiontype": "extras-item",
        "data-extraslid": extrasItem.extraslid,
        "data-poollid": extrasItem.poollid,
        "data-price": extrasItem.price,
        "data-aprice": extrasItem.aprice,
        "data-pricecode": extrasItem.pricecode,
        "data-pooldesc1": extrasItem.pooldesc1,
        "data-pooldesc3": extrasItem.pooldesc3,
        "data-title": extrasItem.title,
        "data-rules-show-web": extrasItem.rules.show_web,
        "data-rules-showpriceweb": extrasItem.rules.showpriceweb,
        "data-rules-default-web": extrasItem.rules.default_web,
        "data-rules-demand-web": extrasItem.rules.demand_web,
        "data-rules-mandatory-web": extrasItem.rules.mandatory_web,
        "data-rules-show-owner": extrasItem.rules.show_owner,
        "data-rules-mandatory-owner": extrasItem.rules.mandatory_owner,
        "data-rules-demand-owner": extrasItem.rules.demand_owner,
        "data-rules-min-qty": extrasItem.rules.min_qty,
        "data-rules-max-qty": extrasItem.rules.max_qty,
        "data-rules-qty": extrasItem.rules.qty,
    };
};
