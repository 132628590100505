import classnames from "classnames";
import React, { createRef, useEffect, useMemo, useRef, useState } from "react";
import PersonalInformation, { TPersonalInformationGuest } from "../PersonalInformation/PersonalInformation";
import {
    createUserGuest,
    disconnectGuestFromUser,
    fetchGuestsOnUser,
    updateUserGuest,
} from "../../store/actions/guests";
import useAppDispatch from "../../hooks/useAppDispatch";
import { TGuest } from "../../store/types";
import "./MyGuests.scss";
import useTranslate from "../../hooks/useTranslate";
import {
    Button,
    ConfirmationModal,
    Heading,
    ImagePlaceholder,
    Notification,
    UserIcon,
    UserPlusIcon,
    scrollToElement,
    style,
} from "@r360/library";
import classNames from "classnames";
import { getGuestAge } from "../../Helper";
import useAppSelector from "../../hooks/useAppSelector";

const MyGuests = () => {
    const dispatch = useAppDispatch();
    const t = useTranslate();

    const userToken = useAppSelector(state => state.account.token);
    const guests: { [key: string]: TGuest } = useAppSelector(state => state.guests.guests);
    const cartIsEmpty = useAppSelector(state => !state.checkoutCart?.products.length);
    const customerOptions = useAppSelector(state => state.clientData.options.customer);
    const { requestsLoading } = useAppSelector(state => state.axiosStatus);
    const updatingGuestInformationInProgress: boolean = requestsLoading["updateUserGuest"];
    const creatingNewGuestGuestInProgress: boolean = requestsLoading["createUserGuest"];
    const { isMobile, isTablet, isDesktop, isIframe, iframeOffsetTop } = useAppSelector(state => state.window);

    const [guestToEdit, setGuestToEdit] = useState<string | null>(null);
    const [guestToRemove, setGuestToRemove] = useState<TGuest | null>(null);
    const [newGuestIsOpen, setNewGuestIsOpen] = useState(false);
    const newGuestRef = useRef<any | null>(null);
    const nonPrimaryGuests = Object.entries(guests).filter(([, guest]) => !guest.primary);

    useEffect(() => {
        if (Object.keys(guests).length === 0 && userToken) {
            dispatch(fetchGuestsOnUser(userToken));
        }
        // eslint-disable-next-line
    }, [dispatch, userToken]);

    // Close the new guest form when finished adding a new guest
    useEffect(() => {
        if (!creatingNewGuestGuestInProgress && newGuestIsOpen) {
            setNewGuestIsOpen(false);
        }
        // eslint-disable-next-line
    }, [creatingNewGuestGuestInProgress]);

    const handleSubmit = (guestId: string, guestValues: any) => {
        dispatch(updateUserGuest(userToken, guestValues));
        guestRefs[guestId].form.current.resetTouched();
    };

    const handleNewGuestSubmit = (guestValues: any) => {
        dispatch(createUserGuest(userToken, guestValues));
        newGuestRef.current.resetForm();
    };

    // Create a reference for each guest that will be used to connect to their forms.
    const guestRefs = useMemo(() => {
        return Object.entries(guests)
            .filter(([, guest]) => !guest.primary)
            .reduce((acc: { [key: string]: { form: any } }, [guestId]) => {
                acc[guestId] = {
                    form: createRef(),
                };

                return acc;
            }, {});
        // eslint-disable-next-line
    }, [Object.keys(guests).length]);

    const handleRemoveGuest = () => {
        if (guestToRemove) {
            dispatch(disconnectGuestFromUser(guestToRemove.id, userToken));
            setGuestToRemove(null);
            setGuestToEdit(null);
        }
    };

    const getImageUrlFromHash = (imageHash: string) => {
        if (!imageHash) {
            return "";
        }

        const baseImageUrl = "https://r360generalstorage.blob.core.windows.net/userprofileimages/";
        const imageExtension = ".jpg";

        return `${baseImageUrl}${imageHash}${imageExtension}`;
    };

    const toggleGuestToEdit = (guestId: string) => {
        if (!cartIsEmpty) {
            return;
        }

        if (guestId === guestToEdit) {
            setGuestToEdit(null);
        } else {
            setGuestToEdit(guestId);
            scrollToElement("edit-guest-form", undefined, isMobile ? "start" : "center");
        }

        if (newGuestIsOpen) {
            setNewGuestIsOpen(false);
        }
    };

    const toggleNewGuestIsOpen = () => {
        if (!cartIsEmpty) {
            return;
        }

        if (!newGuestIsOpen) {
            scrollToElement("edit-guest-form", undefined, isMobile ? "start" : "center");
        }
        setNewGuestIsOpen(prevState => !prevState);

        if (guestToEdit) {
            setGuestToEdit(null);
        }
    };

    return (
        <>
            <div className="u-mb-24">
                <Notification type="info">{t("book.my_pages.my_guests.info")}</Notification>
            </div>
            {!cartIsEmpty && (
                <div className="u-mb-24">
                    <Notification type="warning">{t("book.my_pages.my_guests.ongoing_booking")}</Notification>
                </div>
            )}
            <ul
                className={classNames("my-guests__guests-list", {
                    "u-disabled": !cartIsEmpty,
                })}
            >
                {nonPrimaryGuests.map(([guestId, guest]) => {
                    const guestIsSelected = guestToEdit === guestId;
                    const guestHasImage = guest.imagehash;

                    return (
                        <li
                            key={guestId}
                            className="my-guests__guests-list-item"
                            onClick={() => toggleGuestToEdit(guestId)}
                        >
                            <div
                                className={classNames("my-guests__guests-list-img", {
                                    "my-guests__guests-list-img--selected": guestIsSelected,
                                })}
                                style={{
                                    backgroundImage: guestHasImage
                                        ? `url(${getImageUrlFromHash(guest.imagehash)})`
                                        : "none",
                                }}
                            >
                                {!guestHasImage && (
                                    <ImagePlaceholder
                                        fillParent
                                        customIcon={<UserIcon color={style.whiteColor} size={42} />}
                                    />
                                )}
                            </div>
                            <div className="my-guests__guests-list-text">
                                {guest.firstname} {`(${getGuestAge(guest, t("book.general.years"), "")})`}
                            </div>
                        </li>
                    );
                })}
                <li className="my-guests__guests-list-item" onClick={() => toggleNewGuestIsOpen()}>
                    <div
                        className={classNames("my-guests__guests-list-img my-guests__guests-list-img--new-guest", {
                            "my-guests__guests-list-img--selected": newGuestIsOpen,
                        })}
                    >
                        <div className="my-guests__new-guest-icon">
                            <UserPlusIcon size={56} color={style.whiteColor} />
                        </div>
                    </div>
                    <div className="my-guests__guests-list-text">{t("book.checkout.add_guest")}</div>
                </li>
            </ul>
            <div id="edit-guest-form">
                {nonPrimaryGuests
                    .filter(([guestId]) => guestId === guestToEdit)
                    .map(([guestId, guest]) => {
                        return (
                            <section
                                key={guestId}
                                className={classnames("u-bg-light-grey u-p-24 u-br-8", {
                                    "u-disabled": updatingGuestInformationInProgress,
                                })}
                            >
                                <Heading
                                    type="h1"
                                    styleAs="default-highlighted"
                                    className="u-mb-18"
                                >{`Redigerar ${guest.firstname} ${guest.lastname}`}</Heading>
                                <PersonalInformation
                                    ref={guestRefs[guestId].form}
                                    isPrimaryGuest={false}
                                    guest={guest as TPersonalInformationGuest}
                                    onSubmit={values => {
                                        handleSubmit(guestId, values);
                                    }}
                                    showAgePicker
                                    showKeycardNo={customerOptions?.show_keycard_number || false}
                                    showKeycardImage={customerOptions?.show_keycard_image || false}
                                    showWeight={customerOptions?.show_weight || false}
                                    showHeight={customerOptions?.show_height || false}
                                    showShoesize={customerOptions?.show_shoesize || false}
                                    showHeadsize={customerOptions?.show_headsize || false}
                                    uploadPhotoButtonType="transparent"
                                />
                                <div className="u-d-flex u-flex-wrap u-gap-18 u-justify-content-end">
                                    <Button
                                        type={isMobile ? "transparent" : "tertiary"}
                                        onClick={() => setGuestToEdit(null)}
                                        fullWidth={isMobile}
                                    >
                                        {t("book.general.cancel")}
                                    </Button>
                                    <Button type="danger" onClick={() => setGuestToRemove(guest)} fullWidth={isMobile}>
                                        {t("book.general.remove")}
                                    </Button>
                                    <Button
                                        type="primary"
                                        onClick={() => guestRefs[guestId].form.current.submitForm()}
                                        loading={updatingGuestInformationInProgress}
                                        fullWidth={isMobile}
                                    >
                                        {t("book.general.update")}
                                    </Button>
                                </div>
                            </section>
                        );
                    })}
                {newGuestIsOpen && (
                    <section
                        className={classnames("u-bg-light-grey u-p-24 u-br-8", {
                            "u-disabled": creatingNewGuestGuestInProgress,
                        })}
                    >
                        <Heading type="h1" styleAs="default-highlighted" className="u-mb-18">
                            {t("book.checkout.add_guest")}
                        </Heading>
                        <PersonalInformation
                            isPrimaryGuest={false}
                            ref={newGuestRef}
                            onSubmit={handleNewGuestSubmit}
                            showAgePicker
                            showKeycardNo={customerOptions?.show_keycard_number || false}
                            showKeycardImage={customerOptions?.show_keycard_image || false}
                            showWeight={customerOptions?.show_weight || false}
                            showHeight={customerOptions?.show_height || false}
                            showShoesize={customerOptions?.show_shoesize || false}
                            showHeadsize={customerOptions?.show_headsize || false}
                            uploadPhotoButtonType="transparent"
                            newGuest
                        />
                        <div className="u-d-flex u-gap-18 u-justify-content-end">
                            <Button type="tertiary" onClick={() => setNewGuestIsOpen(false)}>
                                {t("book.general.cancel")}
                            </Button>
                            <Button
                                type="primary"
                                onClick={() => newGuestRef.current?.submitForm()}
                                loading={creatingNewGuestGuestInProgress}
                            >
                                {t("book.my_pages.create_guest")}
                            </Button>
                        </div>
                    </section>
                )}
            </div>
            <ConfirmationModal
                open={guestToRemove}
                heading={t("book.checkout.remove_guest")}
                description={t("book.confirmations.remove", [`${guestToRemove?.firstname} ${guestToRemove?.lastname}`])}
                confirmBtnText={t("book.general.ok")}
                cancelBtnText={t("book.general.cancel")}
                removing={true}
                onClose={() => setGuestToRemove(null)}
                onConfirmClick={handleRemoveGuest}
                onCancelClick={() => {
                    setGuestToRemove(null);
                }}
                {...(isIframe && isDesktop && { fromTop: 120 + iframeOffsetTop + "px" })}
                {...(isIframe &&
                    (isMobile || isTablet) && {
                        fromTop: 80 + iframeOffsetTop + "px",
                    })}
            />
        </>
    );
};

export default MyGuests;
