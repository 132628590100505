import React from "react";

import HelpFilled from "./HelpFilled";
import Skiing from "./Skiing";
import Guest from "./Guest";
import Contact from "./Contact";
import Ski from "./Ski";
import CrossCountrySkiing from "./CrossCountrySkiing";
import Food from "./Food";
import Writing from "./Writing";
import Bed from "./Bed";
import Dog from "./Dog";
import SkiLift from "./SkiLift";
import Select from "./Select";
import Close from "./Close";
import CloseInverted from "./CloseInverted";
import Search from "./Search";
import Calendar from "./Calendar";
import House from "./House";
import Next from "./Next";
import Sweden from "./Sweden";
import Cleaning from "./Cleaning";
import Signs from "./Signs";
import Plus from "./Plus";
import Tick from "./Tick";
import Hotel from "./Hotel";
import WallClock from "./WallClock";
import ArrowCurved from "./ArrowCurved";
import ArrowDown from "./ArrowDown";
import Like from "./Like";
import Information from "./Information";
import MoneyBack from "./MoneyBack";
import Menu from "./Menu";
import Cart from "./Cart";
import TrashCan from "./TrashCan";
import Checkmark from "./Checkmark";
import Bike from "./Bike";
import Booking from "./Booking";
import User from "./User";
import Group from "./Group";

export { HelpFilled as HelpFilledIcon };
export { Skiing as SkiingIcon };
export { Guest as GuestIcon };
export { Contact as ContactIcon };
export { Ski as SkiIcon };
export { Food as FoodIcon };
export { Writing as WritingIcon };
export { Bed as BedIcon };
export { Dog as DogIcon };
export { SkiLift as SkiLiftIcon };
export { Select as SelectIcon };
export { Close as CloseIcon };
export { CloseInverted as CloseInvertedIcon };
export { Search as SearchIcon };
export { Calendar as CalendarIcon };
export { House as HouseIcon };
export { Next as NextIcon };
export { Sweden as SwedenIcon };
export { Cleaning as CleaningIcon };
export { Signs as SignsIcon };
export { Plus as PlusIcon };
export { Tick as TickIcon };
export { Hotel as HotelIcon };
export { WallClock as WallClockIcon };
export { ArrowCurved as ArrowCurvedIcon };
export { ArrowDown as ArrowDownIcon };
export { Like as LikeIcon };
export { Information as InformationIcon };
export { MoneyBack as MoneyBackIcon };
export { Menu as MenuIcon };
export { CrossCountrySkiing as CrossCountrySkiingIcon };
export { Cart as CartIcon };
export { TrashCan as TrashCanIcon };
export { Checkmark as CheckmarkIcon };
export { Bike as BikeIcon };
export { Booking as BookingIcon };
export { User as UserIcon };
export { Group as GroupIcon };

// export byId (id)=> {
//   switch (id) {
//     case 1001:
//       return <Information ..props>
//       break;

//     default:
//       break;
//   }
// }

export const iconByCritId = (id, props) => {
    switch (id) {
        case 1007:
            return <Select {...props} />;
        case 1009:
            return <Bed {...props} />;
        case 1016:
            return <Dog {...props} />;
        case 1046:
            return <SkiLift {...props} />;
        case 1047:
            return <CrossCountrySkiing {...props} />;
        default:
            break;
    }
};
