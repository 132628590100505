import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { optionsSelector } from "../../selectors/Selectors";
import "./Menu.scss";
import classNames from "classnames";
import { Select, TSelectOption, Dropdown } from "@r360/library";
import classnames from "classnames";
import useAppSelector from "../../hooks/useAppSelector";
import { TReservationType } from "../../store/types";
import useTranslate from "../../hooks/useTranslate";

type TMenu = {
    reservationTypes: TReservationType[];
};

type TMenuItem = {
    description: string;
    link: string;
};

type TMenuItemComponent = {
    description: string;
    link: string;
    setMenuItemWidths: (state: any) => void;
    moreMenu: boolean;
};

const MenuItem: React.FC<TMenuItemComponent> = ({
    description,
    link,
    setMenuItemWidths,
    moreMenu,
}: TMenuItemComponent) => {
    const classNameMenuItem = classNames("menu-item", moreMenu && "menu-item__more-menu");
    const menuItemRef = useRef(null);
    useLayoutEffect(() => {
        const width = menuItemRef.current ? menuItemRef.current["offsetWidth"] : 0;
        setMenuItemWidths((state: any) => {
            return {
                ...state,
                [description]: width,
            };
        });
    }, []);

    return (
        <NavLink
            ref={menuItemRef}
            key={description}
            to={link}
            className={({ isActive }) => classnames(classNameMenuItem, isActive ? "menu-item__active" : undefined)}
        >
            {description}
        </NavLink>
    );
};

export const Menu: React.FC<TMenu> = ({ reservationTypes }: TMenu) => {
    const navigate = useNavigate();
    const myPageOnly = !!useAppSelector(optionsSelector).general?.mypage_only;
    const activeReservationType = reservationTypes.find(({ active }) => active);
    const t = useTranslate();

    const getMenuItems = () => {
        return !myPageOnly ? reservationTypes : [];
    };
    const [menuItems, setMenuItems] = useState<(TMenuItem | TReservationType)[]>(getMenuItems());
    // const [moreMenuItems, setMoreMenuItems] = useState<string[]>([]);

    useEffect(() => {
        setMenuItems(getMenuItems());
    }, [reservationTypes]);

    const menuRef = useRef(null);
    const moreMenuRef = useRef(null);
    const [menuWidth, setMenuWidth] = useState(menuRef.current ? menuRef.current["offsetWidth"] : 0);
    const [menuItemWidths, setMenuItemWidths] = useState<{ [key: string]: number }>({});
    const [moreMenuWidth, setMoreMenuWidth] = useState(0);
    const { pathname } = useLocation();

    // useEffect(() => {
    // setMoreMenuItems(() => {
    //     let totalSum = 0;
    //     return menuItems.reduce((acc: string[], menuItem) => {
    //         const width = menuItemWidths[menuItem.description];
    //         totalSum += width || 0;
    //         const fullWidthHit = totalSum >= menuWidth - moreMenuWidth;
    //         if (fullWidthHit) return [...acc, menuItem.description];
    //         return acc;
    //     }, []);
    // });
    // }, [menuItemWidths, menuWidth, moreMenuWidth, menuItems]);

    const setMoreMenuItems = () => {
        let totalSum = 0;
        return menuItems.reduce((acc: string[], menuItem) => {
            const width = menuItemWidths[menuItem.description];
            totalSum += width || 0;
            const fullWidthHit = totalSum >= menuWidth - moreMenuWidth;
            if (fullWidthHit) return [...acc, menuItem.description];
            return acc;
        }, []);
    };

    const moreMenuItems = setMoreMenuItems();

    // useEffect(() => {
    //     const width = menuRef.current ? menuRef.current["offsetWidth"] : 0;
    //     setMenuWidth(width);
    // }, []);

    // useLayoutEffect(() => {
    //     const width = moreMenuRef.current ? moreMenuRef.current["offsetWidth"] : 0;
    //     setMoreMenuWidth(width);
    // }, []);

    const moreMenuObserver = React.useRef(
        new ResizeObserver(entries => {
            const width = entries[0].borderBoxSize[0].inlineSize;
            setMoreMenuWidth(width);
        })
    );

    useEffect(() => {
        if (moreMenuRef.current) {
            moreMenuObserver.current.observe(moreMenuRef.current);
        }

        return () => {
            if (moreMenuRef.current) {
                moreMenuObserver.current.unobserve(moreMenuRef.current);
            }
        };
    }, [moreMenuRef, moreMenuObserver]);

    const menuObserver = React.useRef(
        new ResizeObserver(entries => {
            const { width } = entries[0].contentRect;
            // const width = entries[0].borderBoxSize[0].inlineSize;
            setMenuWidth(width);
        })
    );

    useEffect(() => {
        if (menuRef.current) {
            menuObserver.current.observe(menuRef.current);
        }

        return () => {
            if (menuRef.current) {
                menuObserver.current.unobserve(menuRef.current);
            }
        };
    }, [menuRef, menuObserver]);

    // const setMenuItemWidth = useCallback((key: string, width: number) => {
    //     setMenuItemWidths(state => {
    //         return {
    //             ...state,
    //             [key]: width,
    //         };
    //     });
    // }, []);

    const onMoreMenuItemClick = (menuItem: TSelectOption) => {
        navigate(menuItem.value.toString());
    };

    return (
        <>
            {activeReservationType && (
                <div className="menu-mobile" ref={menuRef}>
                    <Select
                        label={t("book.menu.mobile.choose")}
                        type="secondary"
                        options={menuItems.map(menuItem => ({ value: menuItem.link, label: menuItem.description }))}
                        size="medium"
                        fullWidth
                        onSelectCallback={onMoreMenuItemClick}
                        defaultValue={pathname}
                        onDarkBackground
                    />
                </div>
            )}
            <div className="menu" ref={menuRef}>
                {menuItems
                    .filter(({ description }) => !moreMenuItems.includes(description))
                    .map(({ description, link }: TMenuItem | TReservationType) => (
                        <MenuItem
                            key={description}
                            description={description}
                            link={link}
                            setMenuItemWidths={setMenuItemWidths}
                            moreMenu={false}
                        />
                    ))}
                <div ref={moreMenuRef}>
                    {moreMenuItems.length > 0 ? (
                        <Dropdown
                            renderCustomButton={(_: boolean, onToggle: () => void) => (
                                <div className="menu-item-more" onClick={() => onToggle()}>
                                    {t("book.menu.more")}
                                </div>
                            )}
                            rightAlign
                            onSelectCallback={onMoreMenuItemClick}
                            options={menuItems
                                .filter(({ description }) => moreMenuItems.includes(description))
                                .map(({ link, description }) => ({
                                    value: link,
                                    label: description,
                                    className: "menu-item-more-option",
                                }))}
                        />
                    ) : null}
                </div>
            </div>
        </>
    );
};
