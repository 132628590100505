import React from "react";
import { SimpleImg } from "react-simple-img";

/**
 * Responsive image wrapper for react-simple-img to support default values.
 *
 * Smart react lazy load image with IntersectionObserver API, Priority Hints and animations
 *
 * Docs: https://www.npmjs.com/package/react-simple-img
 */
const ResponsiveImage = props => {
    const {
        src,
        placeholder = false,
        animationDuration = 0.0, // turn off animation by default
    } = props;

    return <SimpleImg animationDuration={animationDuration} src={src} placeholder={placeholder} {...props} />;
};

export default ResponsiveImage;
