import { Heading, Modal, Notification } from "@r360/library";
import React, { useRef } from "react";
import { WtpVideoStream } from "./WtpVideoStream";
import Tesseract from "tesseract.js";
import useAppSelector from "../../hooks/useAppSelector";
import useTranslate from "../../hooks/useTranslate";
import { RichText } from "../UI";

type TWtpScanner = {
    showModal: boolean;
    onCloseModal: () => void;
    onMatch: (arg0: string) => void;
};

export const WtpScanner = ({ showModal, onCloseModal, onMatch }: TWtpScanner) => {
    const t = useTranslate();
    const { isMobile, isTablet, isDesktop, isIframe, iframeOffsetTop } = useAppSelector(state => state.window);
    const videoStreamRef = useRef<any | null>(null);

    const getSkipassNumber = (inputString: string) => {
        const teamAxessId = extractTeamAxessId(inputString);
        const skidataId = extractSkidataId(inputString);

        console.log(teamAxessId, skidataId);

        if (skidataId) {
            // Remove "-" and whitespace
            return skidataId.replaceAll("-", "").replaceAll(" ", "");
        }

        if (teamAxessId) {
            return teamAxessId.replaceAll("-", "").replaceAll(" ", "");
        }

        return undefined;
    };

    const extractSkidataId = (input: string) => {
        const skidataPattern = /(\d{2}-\d{4} \d{4} \d{4} \d{4} \d{4}-\d)/;
        const skidataMatch = input.match(skidataPattern);
        return skidataMatch ? skidataMatch[0] : null;
    };

    const extractTeamAxessId = (input: string) => {
        const teamAxessPattern = /[a-zA-Z0-9]{8}-[a-zA-Z0-9]{3}-[a-zA-Z0-9]{3}/i;
        const teamAxessMatch = input.match(teamAxessPattern);
        return teamAxessMatch ? teamAxessMatch[0] : null;
    };

    const performOCR = async (image: string) => {
        try {
            const {
                data: { text },
            } = await Tesseract.recognize(image, "eng");

            const extractedId = getSkipassNumber(text);

            if (extractedId) {
                return extractedId;
            } else {
                return null;
            }
        } catch (error) {
            console.error("Error during OCR:", error);
        }
    };

    const handleCloseModal = () => {
        videoStreamRef.current?.stopVideo();
        onCloseModal();
    };

    return (
        <Modal
            open={showModal}
            onClose={handleCloseModal}
            closeOnOutsideClick={false}
            size="lg"
            {...(isIframe && isDesktop && { fromTop: 120 + iframeOffsetTop + "px" })}
            {...(isIframe &&
                (isMobile || isTablet) && {
                    fromTop: 80 + iframeOffsetTop + "px",
                })}
        >
            <div className="u-pt-24">
                <div className="u-mb-18">
                    <Heading type="h2" styleAs="h6" className="u-mb-12">
                        {t("book.wtp_scanner_heading")}
                    </Heading>
                    <Notification type="info">
                        <ul className="u-pl-18 u-mb-0">
                            <li>{<RichText content={t("book.wtp_scanner_position_tip")} />}</li>
                            <li>{t("book.wtp_scanner_brightness_tip")}</li>
                        </ul>
                    </Notification>
                </div>
                <WtpVideoStream
                    ref={videoStreamRef}
                    imageHandler={performOCR}
                    onCardNumberMatch={onMatch}
                ></WtpVideoStream>
            </div>
        </Modal>
    );
};
