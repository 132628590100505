import { Accordion, Button, ConfirmationModal, Heading, PenIcon, Tag, TrashIcon } from "@r360/library";
import classNames from "classnames";
import moment from "moment-timezone";
import queryString from "query-string";
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    isNew,
    hasAnyGuestCategoryPrice,
    hasDynamicPrices,
    hasSamePriceInAllGuestCategories,
    isActivityWithDynamicPrice,
    showExtraInOrderOverviewWhenChangeReservation,
    isAccommodation as isAccommodationFunc,
    isPackage as isPackageFunc,
    isSeasonPass,
    isBooked,
} from "../../../../BusinessUtils";
import { RootState } from "../../../..";
import * as Constants from "../../../../Constants";
import {
    formatPrice,
    getFancyAccomodationPrice,
    getFancyCartItemPrice,
    getGuestObjectForReservationTypeFromArray,
    getPackageProductTitle,
} from "../../../../Helper";
import useAppDispatch from "../../../../hooks/useAppDispatch";
import useAppSelector from "../../../../hooks/useAppSelector";
import useTranslate from "../../../../hooks/useTranslate";
import { removeProduct, updateProductWithCount } from "../../../../store/actions/checkoutCart";
import { TCartItem, TGeneralAges } from "../../../../store/types";
import { RichText } from "../../../UI";
import Incrementer from "../../../UI/Incrementer";
import { AccommodationGuests } from "./AccommodationGuests";
import { ExtrasListItem } from "./ExtrasListItem";
import { useSelector } from "react-redux";
import { agesSelector } from "../../../../selectors/Selectors";
import { CartItemGuestAgeList } from "./CartItemGuestAgeList";
import sum from "lodash/sum";

import "./CartItem.scss";

type TCartItemComponent = {
    item: TCartItem;
    connectedProducts: TCartItem[];
};

export const CartItem = ({ item, connectedProducts }: TCartItemComponent) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const t = useTranslate(true);
    const [deleteItemWarning, setDeleteItemWarning] = useState(false);
    const [changeAccomodationWarning, setChangeAccomodationWarning] = useState(false);
    const packageProductTitle = getPackageProductTitle(item);
    const currency = useAppSelector(state => state.clientData?.options?.general?.currency);
    const { isIframe, isDesktop, isMobile, isTablet, iframeOffsetTop, screenSize } = useAppSelector(
        (state: RootState) => state.window
    );
    const generalAges: TGeneralAges = useSelector(agesSelector);
    const isAccommodation = isAccommodationFunc(item);
    const isPackage = isPackageFunc(item);
    const isProductWithDifferentGuestages =
        !hasDynamicPrices(item) && hasAnyGuestCategoryPrice(item) && !hasSamePriceInAllGuestCategories(item);
    const [showAccommodationGuests, setShowAccommodationGuests] = useState(false);

    // On incrementer value change
    const onValueChange = (value: number, item: TCartItem) => {
        if (value === 0) {
            setDeleteItemWarning(true);
        } else {
            dispatch(updateProductWithCount(item, value));
        }
    };

    const onDeleteItem = (item: TCartItem) => {
        dispatch(removeProduct(item));
        setDeleteItemWarning(false);
    };

    const rightContentClassName = classNames(
        "cart-item-header-desktop-right-content",
        !isAccommodation && "cart-item-header-desktop-right-content--incrementer"
    );

    const getAgeCategoryTags = (item: TCartItem) => {
        if (item.minage >= 0 && item.maxage && generalAges) {
            if (item.minage === 0 && item.maxage === 99) {
                return t("book.general.all_ages");
            } else {
                return `${item.minage} - ${item.maxage} ${t("book.general.years")}`;
            }
        }
    };

    const priceClassName = classNames(
        "cart-item-header-price",
        item.ordinaryprice > 0 && "cart-item-header-price__special"
    );
    const originalPriceClassName = classNames("cart-item-header-price", "cart-item-header-price__original");
    const priceClassNameDesktop = classNames(
        "cart-item-header-desktop-price",
        item.ordinaryprice > 0 && "cart-item-header-price__special"
    );

    const originalDesktopPriceClassName = classNames(
        "cart-item-header-price",
        "cart-item-header-desktop-price__original"
    );

    const arrDate = moment(item.arrdate);
    const depDate = moment(item.depdate);
    const arrDateString = arrDate.format("ddd DD MMM");
    const depDateString = depDate.format("ddd DD MMM");
    const days = depDate.diff(arrDate, "days") + 1;

    const totalGuests = item.info?.guests?.totalGuests;
    const showAgeCategoryTags = item.minage >= 0 && !!item.maxage;

    const extras =
        item.info?.extras
            ?.filter(
                item =>
                    (item.quantity !== 0 && item.rules.max_qty !== 0 && item.checked !== false) || item.checked === true
            )
            .filter(extra => showExtraInOrderOverviewWhenChangeReservation(item, extra)) || [];

    const extrasPrice = extras?.reduce((prev, curr) => {
        if (curr.rules.mandatory_web) {
            return prev;
        }

        return prev + (curr.additional?.price ?? curr.price);
    }, 0);

    const connectedProductsPrice = sum(connectedProducts.map(item => item.price * item.count));
    const isAccommodationWithExtrasOrConnectedProducts = extras.length > 0 || connectedProducts.length > 0;

    const getLinkForAccommodationOrPackage = useCallback(() => {
        const baseLink = `/product/${item.type}/${item.grouppoollid}/${item.poollid}/${item.unitlid || 0}`;

        const queryStringObject: { [key: string]: string } = {};
        queryStringObject.startDate = item.arrdate;
        queryStringObject.endDate = item.depdate;

        const guests = getGuestObjectForReservationTypeFromArray(Object.values(item.guestages), item.type);

        Object.entries(guests).forEach(([key, value]: any) => {
            queryStringObject[key] = value;
        });

        return `${baseLink}?${queryString.stringify(queryStringObject)}`;
    }, [item]);

    const onChangeAccomodation = () => {
        onDeleteItem(item);
        navigate(getLinkForAccommodationOrPackage());
    };

    const handleChangeExtrasItem = (item: TCartItem) => {
        navigate(`/product-extras/${item.type}/${item.parentpool}/${item.poollid}/${item.unitlid}/${item.id}`);
    };

    const getExtendedTitle = () => {
        switch (item.kind) {
            case Constants.productTypeNames.SKIPASS:
            case Constants.productTypeNames.RENTAL:
                return `${item.groupTitle} - ${packageProductTitle}`;
            default:
                return item.title;
        }
    };

    // Let this be undefined if we don't find a valid maximum
    let maxToBeAdded;

    if (typeof item.available !== "undefined") {
        maxToBeAdded = item.available;

        if (item?.pricematrix) {
            maxToBeAdded = Math.min(
                item.available,
                item?.pricematrix ? Object.values(item?.pricematrix).length : item.available
            );
        }
    }

    return (
        <>
            <div className="cart-item-wrapper">
                <div className="cart-item">
                    {isDesktop && screenSize.width > 1300 ? (
                        <>
                            <div className="cart-item-header-desktop">
                                <div className="cart-item-header-desktop-left-content">
                                    <div className="cart-item-header-desktop-title">{getExtendedTitle()}</div>
                                    <div className="cart-item-header-desktop-specification">
                                        {isSeasonPass(item) ? (
                                            <>{t("book.cart.item_types.seasoncard")}</>
                                        ) : days > 1 ? (
                                            `${arrDateString} - ${depDateString}`
                                        ) : (
                                            arrDateString
                                        )}
                                        {item.fromtime && item.totime && `, ${item.fromtime} - ${item.totime}`}
                                        {totalGuests && <span className="cart-item-info-line">|</span>}
                                        {totalGuests &&
                                            (!isAccommodation || !isNew(item)) &&
                                            `${totalGuests} ${
                                                totalGuests === 1 ? t("book.general.guest") : t("book.general.guests")
                                            }`}
                                        {totalGuests && isAccommodation && isNew(item) && (
                                            <Button
                                                type="tertiary"
                                                onClick={() => setShowAccommodationGuests(!showAccommodationGuests)}
                                                disabled={showAccommodationGuests}
                                            >
                                                {`${totalGuests} ${
                                                    totalGuests === 1
                                                        ? t("book.general.guest")
                                                        : t("book.general.guests")
                                                }`}
                                                <PenIcon className="u-ml-6" />
                                            </Button>
                                        )}
                                        {showAgeCategoryTags && (
                                            <>
                                                <span className="cart-item-info-line">|</span>
                                                <span className="cart-item-header__category-tags">
                                                    {getAgeCategoryTags(item)}
                                                </span>
                                            </>
                                        )}
                                    </div>
                                    {item.promotionCode && <Tag text={t("book.general.campaign")} theme="yellow" />}
                                </div>
                                <div className={rightContentClassName}>
                                    {isAccommodation && (
                                        <>
                                            {isNew(item) && (
                                                <Button
                                                    buttonClassName="cart-item-header-desktop-link"
                                                    type="tertiary"
                                                    onClick={() => setChangeAccomodationWarning(true)}
                                                >
                                                    {t("book.cart.accommodation.change")}
                                                </Button>
                                            )}
                                            <Button
                                                buttonClassName="cart-item-header-desktop-link"
                                                type="tertiary"
                                                onClick={() => handleChangeExtrasItem(item)}
                                            >
                                                {t("book.cart.accommodation.change_extras")}
                                            </Button>
                                        </>
                                    )}
                                    {!isAccommodation && !isPackage && (
                                        <div>
                                            <Incrementer
                                                name={item.title}
                                                label={item.title}
                                                value={item.count ?? item.quantity}
                                                maxValue={maxToBeAdded}
                                                onChange={({ value }: { value: number }) => {
                                                    onValueChange(value, item);
                                                }}
                                                size="small"
                                                hidden={
                                                    hasAnyGuestCategoryPrice(item) &&
                                                    !hasSamePriceInAllGuestCategories(item)
                                                }
                                            />
                                            {isActivityWithDynamicPrice(item) && (
                                                <div className="cart-item-header-desktop-activity-spots">
                                                    {t("book.cart.activity.spots_booked", [item.count, item.available])}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    <div className="cart-item-header-price-wrapper">
                                        <div className={priceClassNameDesktop}>
                                            {isAccommodation
                                                ? getFancyAccomodationPrice(
                                                      item,
                                                      extrasPrice + connectedProductsPrice,
                                                      currency
                                                  )
                                                : getFancyCartItemPrice(item, currency, generalAges)}
                                        </div>
                                        {item.ordinaryprice > 0 && (
                                            <div className={originalDesktopPriceClassName}>{`${formatPrice(
                                                item.ordinaryprice * (item.count ?? 1),
                                                currency
                                            )}`}</div>
                                        )}
                                        {isAccommodation && (extras.length > 0 || connectedProducts.length > 0) && (
                                            <div className="cart-item-header-price__inc-extras">
                                                ({t("book.cart.extras.inc_extras")})
                                            </div>
                                        )}
                                    </div>
                                    {isNew(item) && (
                                        <div
                                            className="cart-item-header-desktop-trash"
                                            onClick={() => setDeleteItemWarning(true)}
                                        >
                                            <TrashIcon />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="cart-item-header-wrapper">
                                <div className="cart-item-left">
                                    <div className="cart-item-header">
                                        <div className="cart-item-header-title">{getExtendedTitle()}</div>
                                    </div>
                                    <div className="cart-item-info">
                                        <div>
                                            {isSeasonPass(item) ? (
                                                <>{t("book.cart.item_types.seasoncard")}</>
                                            ) : days > 1 ? (
                                                `${arrDateString} - ${depDateString}`
                                            ) : (
                                                arrDateString
                                            )}
                                        </div>
                                        {totalGuests && (!isAccommodation || !isNew(item)) && (
                                            <div>
                                                {`${totalGuests} ${
                                                    totalGuests === 1
                                                        ? t("book.general.guest")
                                                        : t("book.general.guests")
                                                }`}
                                            </div>
                                        )}
                                        <div className="cart-item-header__category-tags">
                                            {item.minage >= 0 && item.maxage && getAgeCategoryTags(item)}
                                        </div>
                                        {totalGuests && isAccommodation && isNew(item) && (
                                            <div>
                                                <Button
                                                    type="tertiary"
                                                    onClick={() => setShowAccommodationGuests(!showAccommodationGuests)}
                                                    disabled={showAccommodationGuests}
                                                >
                                                    {`${totalGuests} ${
                                                        totalGuests === 1
                                                            ? t("book.general.guest")
                                                            : t("book.general.guests")
                                                    }`}
                                                    <PenIcon className="u-ml-6" />
                                                </Button>
                                            </div>
                                        )}
                                        {isActivityWithDynamicPrice(item) && (
                                            <div className="cart-item-header-activity-spots">
                                                {t("book.cart.activity.spots_booked", [item.count, item.available])}
                                            </div>
                                        )}
                                        {item.promotionCode && (
                                            <div>
                                                <Tag text={t("book.general.campaign")} theme="yellow" />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="cart-item-header-price-wrapper">
                                    <Heading type="h5" className={priceClassName}>
                                        {isAccommodation
                                            ? getFancyAccomodationPrice(
                                                  item,
                                                  extrasPrice + connectedProductsPrice,
                                                  currency
                                              )
                                            : getFancyCartItemPrice(item, currency, generalAges)}
                                    </Heading>
                                    {item.ordinaryprice > 0 && (
                                        <div className={originalPriceClassName}>{`${formatPrice(
                                            item.ordinaryprice * (item.count ?? 1),
                                            currency
                                        )}`}</div>
                                    )}
                                </div>
                            </div>
                            {!isDesktop && isAccommodation && showAccommodationGuests && (
                                <div className="cart-item-accommodation-guests">
                                    <AccommodationGuests
                                        cartItem={item}
                                        onCancel={() => setShowAccommodationGuests(false)}
                                        handleChangeExtrasItem={() => handleChangeExtrasItem(item)}
                                    />
                                </div>
                            )}
                            <div className="cart-item-bottom">
                                {isNew(item) ? (
                                    <div className="cart-item-bottom-trash" onClick={() => setDeleteItemWarning(true)}>
                                        <TrashIcon />
                                    </div>
                                ) : (
                                    <div></div>
                                )}
                                {isAccommodation ? (
                                    <div>
                                        {isNew(item) && (
                                            <Button
                                                buttonClassName="cart-item-header-link"
                                                type="tertiary"
                                                onClick={() => setChangeAccomodationWarning(true)}
                                            >
                                                {t("book.cart.accommodation.change")}
                                            </Button>
                                        )}
                                        <Button
                                            buttonClassName="cart-item-header-link cart-item-header-link__right"
                                            type="tertiary"
                                            onClick={() => handleChangeExtrasItem(item)}
                                        >
                                            {t("book.cart.accommodation.change_extras")}
                                        </Button>
                                    </div>
                                ) : (
                                    <Incrementer
                                        name={item.title}
                                        label={item.title}
                                        value={item.count}
                                        maxValue={maxToBeAdded}
                                        onChange={({ value }: { value: number }) => {
                                            onValueChange(value, item);
                                        }}
                                        size="small"
                                        hidden={
                                            hasAnyGuestCategoryPrice(item) && !hasSamePriceInAllGuestCategories(item)
                                        }
                                    />
                                )}
                            </div>
                        </>
                    )}
                    {isDesktop && isAccommodation && isNew(item) && showAccommodationGuests && (
                        <div className="cart-item-accommodation-guests">
                            <AccommodationGuests
                                cartItem={item}
                                onCancel={() => setShowAccommodationGuests(false)}
                                handleChangeExtrasItem={() => handleChangeExtrasItem(item)}
                            />
                        </div>
                    )}
                </div>
                {isAccommodationWithExtrasOrConnectedProducts && (
                    <Accordion
                        title={`${t("book.cart.extras.show")}  (${
                            extras.length + connectedProducts.length + (!isBooked(item) ? 1 : 0)
                        })`}
                        titleOnExpanded={`${t("book.cart.extras.hide")} (${
                            extras.length + connectedProducts.length + (!isBooked(item) ? 1 : 0)
                        })`}
                    >
                        <div className="cart-item-accordion">
                            {!isBooked(item) && (
                                <div className="extras-row">
                                    <ExtrasListItem
                                        extrasItemId={item.id}
                                        title={packageProductTitle}
                                        currency={currency}
                                        price={item.price}
                                        cartItem={item}
                                    />
                                </div>
                            )}
                            {extras.map(extra => {
                                return (
                                    <div key={extra.id} className="extras-row">
                                        <ExtrasListItem
                                            extrasItemId={extra.id}
                                            title={extra.title}
                                            currency={currency}
                                            price={extra.additional?.price || extra.price}
                                            showPriceWeb={extra.showpriceweb}
                                            quantity={extra.additional?.quantity || extra.quantity}
                                            isMandatory={extra.rules.mandatory_web}
                                            cartItem={extra}
                                        />
                                    </div>
                                );
                            })}

                            {connectedProducts.map(connectedProduct => {
                                return (
                                    <div key={connectedProduct.id} className="extras-row">
                                        <ExtrasListItem
                                            extrasItemId={connectedProduct.id}
                                            renderTitle={() => (
                                                <>
                                                    <span>{`${connectedProduct.groupTitle} - ${connectedProduct.title}`}</span>
                                                    {connectedProduct.minage > 0 &&
                                                        connectedProduct.maxage > 0 &&
                                                        connectedProduct.minage < connectedProduct.maxage && (
                                                            <>
                                                                <span className="cart-item-info-line">|</span>
                                                                <span className="cart-item-header__category-tags">
                                                                    {getAgeCategoryTags(connectedProduct)}
                                                                </span>
                                                            </>
                                                        )}
                                                </>
                                            )}
                                            currency={currency}
                                            price={
                                                connectedProduct.price *
                                                Math.max(connectedProduct.count, connectedProduct.quantity)
                                            }
                                            quantity={Math.max(connectedProduct.count, connectedProduct.quantity)}
                                            cartItem={connectedProduct}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </Accordion>
                )}
                {isProductWithDifferentGuestages && (
                    <CartItemGuestAgeList item={item} onNoGuestsLeft={() => setDeleteItemWarning(true)} />
                )}
            </div>
            <ConfirmationModal
                open={deleteItemWarning}
                heading={
                    <RichText content={t("book.confirmations.remove_product_from_cart.title", [getExtendedTitle()])} />
                }
                description={
                    isAccommodationWithExtrasOrConnectedProducts
                        ? t("book.confirmations.remove_product_from_cart.description_extras")
                        : undefined
                }
                confirmBtnText={t("book.confirmations.remove_product_from_cart.ok")}
                cancelBtnText={t("book.confirmations.remove_product_from_cart.cancel")}
                removing={true}
                onClose={() => setDeleteItemWarning(false)}
                onConfirmClick={() => onDeleteItem(item)}
                onCancelClick={() => setDeleteItemWarning(false)}
                {...(isIframe && isDesktop && { fromTop: 120 + iframeOffsetTop + "px" })}
                {...(isIframe &&
                    (isMobile || isTablet) && {
                        fromTop: 80 + iframeOffsetTop + "px",
                    })}
            />
            <ConfirmationModal
                open={changeAccomodationWarning}
                heading={t("book.confirmations.change_accommodation_in_cart.title")}
                description={t("book.confirmations.change_accommodation_in_cart.description")}
                confirmBtnText={t("book.confirmations.change_accommodation_in_cart.ok")}
                cancelBtnText={t("book.general.cancel")}
                removing={true}
                onClose={() => setChangeAccomodationWarning(false)}
                onConfirmClick={onChangeAccomodation}
                onCancelClick={() => setChangeAccomodationWarning(false)}
                {...(isIframe && isDesktop && { fromTop: 120 + iframeOffsetTop + "px" })}
                {...(isIframe &&
                    (isMobile || isTablet) && {
                        fromTop: 80 + iframeOffsetTop + "px",
                    })}
            />
        </>
    );
};
