import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import BookingView from "../../components/BookingView/BookingView";
import { checkAuthenticationStatus, fetchReservation } from "../../store/actions/account";
import { bootstrapClient } from "../../store/actions/clientData";
import { clearAllReservationTypeResults } from "../../store/actions/reservationResult";
import { resetLastSearchedTimestamps } from "../../store/actions/search";
import { textsSelector } from "../../Selectors";

const BookingPage = () => {
    // The reservation id (resvid) helps us to access the state variable anywhere in our code.
    const { resvid } = useParams();
    const dispatch = useDispatch();
    const userToken = useSelector(state => state.account.token);
    const texts = useSelector(textsSelector);
    const filters = useSelector(state => state.filter);
    const reservationTypes = useSelector(state => state.clientData.reservationtypes);
    const reservations = useSelector(state => state.account.reservations);
    const authenticated = useSelector(state => state.account.loginSuccessful);
    const showReservationAllowed = useSelector(state => state.clientData.options.mypage.showreservation);

    let booking;

    // Find booking in state but we will always fetch a fresh booking from backend if we are authenticated and the page reloads.
    if (showReservationAllowed) {
        booking = reservations?.find(item => item.reservation?.resvid === resvid);
    } else {
        console.log("Reservation is not allowed to be viewed because mypage/showreservation is turned off");
    }

    useEffect(() => {
        if (!reservationTypes.length) {
            dispatch(bootstrapClient());
        }
    }, [dispatch, reservationTypes.length]);

    // Check if authenticated and prompt to re-login if not logged in
    useEffect(() => {
        dispatch(checkAuthenticationStatus(userToken));
    }, [dispatch, userToken]);

    // Always fetch current reservation from backend to get freshly updated information, jummy ;)
    useEffect(() => {
        if (showReservationAllowed && authenticated) {
            const reservationPromise = dispatch(fetchReservation(userToken, resvid));

            reservationPromise.then(() => {
                // Clear search results
                dispatch(clearAllReservationTypeResults());
                dispatch(resetLastSearchedTimestamps());
            });
        }
    }, [dispatch, authenticated, userToken, resvid, showReservationAllowed]);

    return (
        <div className="container">
            <h1 className="page-header">
                {texts?.booking} - {resvid}
                {booking && booking.cancelled && (
                    <span style={{ color: "#E04F5F", textTransform: "uppercase" }}>{` ${
                        texts?.cancelled || "Cancelled"
                    }`}</span>
                )}
            </h1>
            {showReservationAllowed && booking && (
                <BookingView reservationTypes={reservationTypes} filters={filters} booking={booking} texts={texts} />
            )}
        </div>
    );
};

export default BookingPage;
