import React, { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { optionsSelector } from "../../../selectors/Selectors";
import { authenticateCustomer, resetApiMessages } from "../../../store/actions/account";
import { setSignUpWrapper } from "../../../store/actions/window";
import ForgotPassword from "../../ForgotPassword/ForgotPassword";
import { Alert } from "../../UI";
import "./Login.scss";
import useTranslate from "../../../hooks/useTranslate";

// Login component
const Login = props => {
    const t = useTranslate();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const wrapperRef = useRef(null);
    const loginSuccessful = useSelector(state => state.account.loginSuccessful);
    const loginError = useSelector(state => state.account.error);
    const retrievedPassword = useSelector(state => state.account.retrievedPassword);
    const currentPath = useSelector(state => state.window.signUpWrapper.pathname);

    let redirectTo;

    if (props.redirectPath) {
        redirectTo = props.redirectPath;
    } else if (props.loginPage) {
        redirectTo = "/account";
    } else {
        redirectTo = currentPath;
    }

    const [username, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [showLogin, setShowLogin] = useState(true);
    const [, setRetrieveMessage] = useState(false);
    const [isLoginError, setLoginError] = useState(false);
    const [emptyLoginFields, setEmptyLoginFields] = useState(false);
    const myPageOnly = !!useSelector(optionsSelector).general?.mypage_only;

    useEffect(() => {
        dispatch(resetApiMessages());
    }, [dispatch]);

    // Close menu on click outside of container
    // useEffect(() => {
    //   document.addEventListener("mousedown", (event) => {
    //     if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
    //       removeErrorMessages();
    //     }
    //   });
    // }, [wrapperRef]);

    useEffect(() => {
        setLoginError(loginError);
    }, [loginError]);

    useEffect(() => {
        setRetrieveMessage(retrievedPassword);
    }, [retrievedPassword]);

    useEffect(() => {
        if (loginSuccessful) {
            if (redirectTo) {
                navigate(redirectTo);
            }
            dispatch(setSignUpWrapper(false, false, false));
        }
    }, [loginSuccessful, redirectTo, dispatch, navigate]);

    // Remove error messages
    const removeErrorMessages = () => {
        setEmptyLoginFields(false);
        dispatch(resetApiMessages()); // Remove retrieve password message
    };

    const checkInputFieldsAndContinue = () => {
        setLoginError(false);
        if (username && password) {
            setEmptyLoginFields(false);
            dispatch(authenticateCustomer(username, password));
        } else {
            setEmptyLoginFields({
                username,
                password,
            });
        }
    };

    // Login form
    const loginForm = () => {
        return (
            <div className="login" ref={wrapperRef}>
                <div className="login__wrapper">
                    <div className="text-center">
                        <h1 className="h1 page-header login__header">{t("login_title")}</h1>
                    </div>
                    {isLoginError && !emptyLoginFields && (
                        <Alert type="warning">
                            <p>{t("wronguserorpassword")}</p>
                        </Alert>
                    )}
                    {emptyLoginFields && (
                        <Alert type="warning">
                            <ul>
                                {!emptyLoginFields.username && (
                                    <li>
                                        {t("generalemail")} {t("ismissing")}
                                    </li>
                                )}
                                {!emptyLoginFields.password && (
                                    <li>
                                        {t("generalpassword")} {t("ismissing")}
                                    </li>
                                )}
                            </ul>
                        </Alert>
                    )}
                    {t("loginmessage") && t("loginmessage") !== " " && t("loginmessage") !== "" && (
                        <div className="login__create pb-5">{t("loginmessage")}</div>
                    )}

                    <form className="login__form" onSubmit={e => e.preventDefault()}>
                        <div>
                            <label htmlFor="username" className="visuallyhidden">
                                {t("generalemail")}
                            </label>
                            <input
                                type="text"
                                placeholder={t("generalemail")}
                                value={username}
                                id="username"
                                name="username"
                                className={`form-control login__username${
                                    emptyLoginFields?.username === "" && !username ? " login__input--invalid" : ""
                                }`}
                                onChange={e => {
                                    setUserName(e.target.value);
                                }}
                            />
                        </div>
                        <div>
                            <label htmlFor="password" className="visuallyhidden">
                                {t("generalpassword")}
                            </label>
                            <input
                                type="password"
                                placeholder={t("generalpassword")}
                                id="password"
                                name="password"
                                value={password}
                                className={`form-control login__password${
                                    emptyLoginFields?.password === "" && !password ? " login__input--invalid" : ""
                                }`}
                                onChange={e => {
                                    setPassword(e.target.value);
                                }}
                            />
                        </div>
                        <div className="login__submit-container d-grid">
                            <input
                                type="submit"
                                className="btn btn-primary login__submit"
                                value={t("login")}
                                onClick={checkInputFieldsAndContinue}
                            />
                        </div>
                    </form>
                    {/*
          <div className="row login__separator">
            <div className="col-md-5">
              <hr />
            </div>
            <div className="col-md-2 login__separator-text">eller</div>
            <div className="col-md-5">
              <hr />
            </div>
          </div>

          <div className="login__alternate">
            <button className="btn login__alternate-button">
              <Icon
                name="FaGoogle"
                size={18}
                color="#0d3e60"
                className="login__alternate-button-icon"
              />
              Fortsätt med Google
            </button>
            <button className="btn login__alternate-button">
              <Icon
                name="FaFacebookF"
                size={18}
                color="#0d3e60"
                className="login__alternate-button-icon"
              />
              Fortsätt med Facebook
            </button>
          </div> */}

                    {!myPageOnly && (
                        <div className="login__create">
                            {t("noaccount")}{" "}
                            <Button
                                className="login__create-link"
                                variant="link"
                                onClick={() => {
                                    removeErrorMessages();
                                    if (props.loginPage) {
                                        navigate(`/user/registration?path=${redirectTo}`);
                                    } else {
                                        dispatch(setSignUpWrapper(true, false, true, redirectTo));
                                    }
                                }}
                            >
                                {t("signup")}
                            </Button>
                        </div>
                    )}
                    <div className="text-center mt-2">
                        <Button
                            className="login__create-link"
                            variant="link"
                            onClick={() => {
                                // setRetrieveMessage("");
                                removeErrorMessages();
                                setShowLogin(false);
                            }}
                        >
                            {t("forgottenpassword")}
                        </Button>
                    </div>
                </div>
            </div>
        );
    };

    if (!showLogin) {
        return <ForgotPassword loginLinkCallback={() => setShowLogin(true)} />;
    }
    return loginForm();
};

export default Login;
