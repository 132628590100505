import classnames from "classnames";
import moment from "moment-timezone";
import React from "react";
import { Col, Row } from "react-bootstrap";
import {
    getTotalPriceByGuestCategories,
    hasAnyGuestCategoryPrice,
    hasDynamicPrices,
    hasSamePriceInAllGuestCategories,
    isBooked,
    isCancelled,
    isLetting,
    isSeasonPass,
    isSkipass,
    showArrDepDates,
} from "../../BusinessUtils";
import CancelProductButton from "../CancelProductButton/CancelProductButton";
import { RichText } from "../UI";
import "./ProductListItem.scss";

const ProductListItem = ({
    products,
    product,
    guest,
    texts,
    currency,
    booking,
    showDetails,
    createdFromReservation,
    setRenderedProduct,
    generalAges,
    allowCancellation = true,
}) => {
    const renderProductPrice = product => {
        let price = parseInt(product.price);

        // Sum price for every child
        if (product.children.length) {
            price = product.children.reduce((sum, childId) => {
                // We need to find the child by id and fetch its price to sum it up
                return (sum += parseInt(products[childId]?.price || 0));
            }, price);
        }
        // Sum prices for guest categories
        else if (
            Object.values(product.guests).length > 0 &&
            hasAnyGuestCategoryPrice(product) &&
            !hasSamePriceInAllGuestCategories(product) &&
            !hasDynamicPrices(product)
        ) {
            // Find the age category of the guests to use the right price
            const guest = Object.values(product.guests)[0];
            const matchingGeneralAge = generalAges[product.type]?.find(
                generalAge => guest.age >= generalAge.min && guest.age <= generalAge.max
            );

            if (!matchingGeneralAge) {
                // We do not want to throw an error here to block the customer from continuing to pay.
                // The price shows wrong if setup incorrectly.
                console.warn(
                    "CheckoutSummary: Could not find matching guest age category in general.ages for product",
                    product.title,
                    "with guest",
                    guest
                );
                console.warn("CheckoutSummary: Render no price for product", product.title, "with guest", guest);

                return null; // render no price
            }

            const ageCategory = matchingGeneralAge.agecat;
            const priceField = ageCategory === "c5" ? "price" : `price${ageCategory}`; // price | pricec1 | pricec2 | pricec3 | pricec4

            price = product[priceField];
        }

        return (
            <>
                {price} {currency}
            </>
        );
    };

    // Render one childProduct with products
    const renderChildren = ({ allProducts, parentProduct, currency }) => {
        if (parentProduct.children.length === 0) {
            return null;
        }

        return (
            <ul className="productlistitem__additions">
                {parentProduct.children.map(childProductId => {
                    const childProduct = allProducts[childProductId];

                    if (!childProduct) {
                        return null;
                    }

                    setRenderedProduct?.(childProduct.id);

                    return (
                        <li key={childProductId}>
                            {/* pooldesc2 used as a backup before we are going to fix with title in backend or registervård */}
                            {childProduct.title || childProduct.pooldesc2} ({childProduct.price} {currency})
                        </li>
                    );
                })}
            </ul>
        );
    };

    const renderCancelledProduct = () => {
        setRenderedProduct?.(product.id);
        const cancelledChildren = product.children
            .map(childId => products[childId])
            .filter(child => !!child && isCancelled(child));

        return (
            <Row className="productlistitem__product" key={product.id}>
                <Col md={9} xs={8}>
                    <Row>
                        <Col md={3}>
                            {product.parent ? (
                                <div className="productlistitem__product-kinddesc">{`${texts?.accessories} :`}</div>
                            ) : (
                                <div className="productlistitem__product-kinddesc">{product?.kinddesc}</div>
                            )}
                        </Col>
                        <Col md={5}>
                            <span className="productlistitem__product-title">{product?.title}</span>
                            {cancelledChildren.length > 0 && (
                                <ul className="productlistitem__additions">
                                    {cancelledChildren.map(child => (
                                        <li key={child.id}>
                                            <span>
                                                <b>{child.quantity} x </b>
                                                {child.title || child.pooldesc2}
                                            </span>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </Col>
                        <Col md={4}>
                            <div className="productlistitem__product-date">
                                {isSeasonPass(product) && `${texts?.seasonpass}`}
                            </div>
                            {!product.parent && (
                                <div className="productlistitem__product-date">{showArrDepDates(product)}</div>
                            )}
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    };

    const renderBookedProduct = () => {
        setRenderedProduct?.(product.id);

        return (
            <>
                {createdFromReservation && !isBooked(product) && (
                    <Row>
                        <Col>
                            <h6 className="h6">{texts["checkoutsummary.new_in_change_reservation"]}</h6>
                        </Col>
                    </Row>
                )}
                <Row
                    className={classnames("productlistitem__product", {
                        "productlistitem__product-expanded": showDetails,
                    })}
                    key={product.id}
                >
                    <Col md={9} xs={8}>
                        <Row>
                            <Col md={3}>
                                <div className="productlistitem__product-kinddesc">{product?.kinddesc}</div>
                            </Col>

                            <Col md={5}>
                                <span className="productlistitem__product-title">
                                    {product.quantity > 1 ? `${product.quantity} x ${product?.title}` : product?.title}
                                </span>
                                {isSkipass(product) && product.keycardNo && (
                                    <ul className="productlistitem__keycard-number">
                                        <li>
                                            {texts["checkoutsummary.skipass.keycardnumber"]} {product.keycardNo}
                                        </li>
                                    </ul>
                                )}

                                {guest &&
                                    renderChildren({
                                        allProducts: guest.products,
                                        parentProduct: product,
                                        currency,
                                    })}

                                {product.insurance && (
                                    <ul className="productlistitem__additions">
                                        <li>{texts["checkoutsummary.rental.insurance"] || "Försäkring"}</li>
                                    </ul>
                                )}

                                {showDetails && isLetting(product) && (guest.weight || guest.height || guest.shoesize) && (
                                    <ul>
                                        {guest.weight && (
                                            <li>
                                                {texts?.weight}: {guest.weight} kg
                                            </li>
                                        )}
                                        {guest.height && (
                                            <li>
                                                {texts?.height}: {guest.height} cm
                                            </li>
                                        )}
                                        {guest.shoesize && (
                                            <li>
                                                {texts?.shoesize}: {guest.shoesize}{" "}
                                                {texts["checkout.skirental.shoesize_unit"]}
                                            </li>
                                        )}
                                    </ul>
                                )}
                            </Col>
                            <Col md={4}>
                                <div className="productlistitem__product-date">
                                    {isSeasonPass(product) && texts?.seasonpass}
                                    {product.occations?.length > 0 &&
                                        product.occations.map((occation, index) => (
                                            <div key={index}>
                                                {`${moment(occation.date).format("ddd D MMM")}, ${texts?.clockshort} ${
                                                    occation.fromtime
                                                } - ${occation.totime}`}
                                            </div>
                                        ))}

                                    {!isSeasonPass(product) && !product.occations?.length && showArrDepDates(product)}
                                </div>
                            </Col>
                        </Row>
                        {showDetails && (
                            <>
                                {product.deliveryPoint && (
                                    <Row className="mt-4">
                                        <Col>
                                            <div>
                                                <h6>{texts?.delivery}</h6>
                                                {product.deliveryPoint}
                                            </div>
                                        </Col>
                                    </Row>
                                )}
                                {product.weblong && (
                                    <Row className="mt-4">
                                        <Col>
                                            <div>
                                                <h6>{product.title}</h6>
                                                <p>
                                                    <RichText content={product.weblong} />
                                                </p>
                                            </div>
                                        </Col>
                                    </Row>
                                )}
                            </>
                        )}
                    </Col>

                    <Col md={3} xs={4}>
                        <Row>
                            <Col md={booking && allowCancellation ? 10 : 12}>
                                <div className="productlistitem__product-price">{renderProductPrice(product)}</div>
                            </Col>
                            {booking && allowCancellation && (
                                <Col md={2} className="text-end">
                                    <CancelProductButton
                                        resvid={booking.resvid}
                                        detlid={product.detlid}
                                        productTitle={product.title}
                                        confirmationTitle={
                                            texts["bookingview.guests.cancel_product_confirmation.title"]
                                        }
                                        confirmationDescription={
                                            texts["bookingview.guests.cancel_product_confirmation.description"]
                                        }
                                        allowCancellation={booking.allowcancellation}
                                    />
                                </Col>
                            )}
                        </Row>
                    </Col>
                </Row>
            </>
        );
    };

    return (
        <>
            {!isCancelled(product) && renderBookedProduct()}
            {isCancelled(product) && renderCancelledProduct()}
        </>
    );
};
export default ProductListItem;
