import React from "react";
import {
    isAccommodation,
    isAccommodationExtra,
    isActivityWithDynamicPrice,
    isCancelled,
    isPackage,
    isParent,
} from "../../BusinessUtils";
import GuestListItem from "./GuestListItem/GuestListItem";
import "./GuestListView.scss";

const guestHasProductsToShow = guest => {
    const products = Object.values(guest.products);

    if (products.length === 0) {
        return false;
    }

    const hasOtherProducts = products.some(
        product =>
            !isAccommodation(product) &&
            !isAccommodationExtra(product) &&
            !isActivityWithDynamicPrice(product) &&
            !isPackage(product)
    );

    return hasOtherProducts;
};

const GuestListView = ({
    texts,
    products,
    guests,
    currency,
    booking,
    checkoutSummary,
    createdFromReservation,
    setRenderedProduct,
    generalAges,
}) => {
    if (!products) {
        return null;
    }

    const guestList = Array.isArray(guests) ? guests : Object.values(guests);
    const productEntries = Object.entries(products);

    // Create a list of guests where each guest shall have a list of booked products.
    // Like a hashmap or array with guestlid as key
    const allGuestsWithProducts = {};

    // Looping throught all the guests
    for (let guest of guestList) {
        // if guest doesn't exist so send a guest to an empty object (allGuestsWithProducts)
        allGuestsWithProducts[guest.guestlid] = guest;

        // First filter a product from all the products then find and compare with guestlid in guestWithProduct
        const guestParentProductEntries = productEntries
            .filter(([, product]) => isParent(product) && !isCancelled(product))
            .filter(([, product]) => product.guests && Object.keys(product.guests).length)
            .filter(([, product]) =>
                Object.values(product.guests).some(guestWithProduct => guestWithProduct.guestlid === guest.guestlid)
            );

        const guestChildrenProductIds = guestParentProductEntries
            .map(([, product]) => (product.children || []).map(child => child.toString()))
            .flat();

        const guestChildProductsEntries = productEntries.filter(([key]) => guestChildrenProductIds.includes(key));

        const guestProducts = Object.fromEntries([...guestParentProductEntries, ...guestChildProductsEntries]);

        allGuestsWithProducts[guest.guestlid].products = guestProducts;
    }

    // Render a list of guests like(allGuestsWithProducts) where each guest have one or many products
    // Hide guests that only have accommodations or activites with dynamic price.
    return Object.values(allGuestsWithProducts)
        .filter(
            guest => (checkoutSummary && Object.values(guest.products).length === 0) || guestHasProductsToShow(guest)
        )
        .map(guest => {
            return (
                <GuestListItem
                    key={guest.guestlid}
                    texts={texts}
                    guest={guest}
                    currency={currency}
                    booking={booking}
                    createdFromReservation={createdFromReservation}
                    setRenderedProduct={setRenderedProduct}
                    generalAges={generalAges}
                />
            );
        });
};
export default GuestListView;
