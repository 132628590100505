import axios from "axios";
import moment from "moment-timezone";
import { v4 as uuidv4 } from "uuid";
import AxiosClient from "../../API/AxiosClient";
import { isAccommodation, isBooked, isPackage, isSystemPool } from "../../BusinessUtils";
import * as Constants from "../../Constants";
import { convertToArray, isEmpty } from "../../Helper";
import { sendSignal } from "../../signals";
import { createCartAddProductSignal, createCartEmptySignal, createCartRemoveProductSignal } from "../../signals/cart";
import * as types from "../actions/types";
import { resetGuests } from "./guests";
import { resetReservation } from "./reservation";
import { setRequestsLoading } from "../../store/actions/axiosStatus";

/*
    Note: The frontend cart need to handle accommodation pools and packages as unique products to
    allow the customer to add a pool or package as multiple different cart items.

    Accommodation pools and packages each get a unique cart item id.
    All other types keep their id as a cart item id.

    customCartItemId can be used when we want to connect other products to this item before it has been added to cart.
*/
const handleAddProduct = (product, customCartItemId) => {
    let addedProduct = { ...product };

    if (customCartItemId) {
        addedProduct.cartItemId = customCartItemId;
    } else if (!product.cartItemId) {
        if (isAccommodation(product) || isPackage(product)) {
            addedProduct.cartItemId = uuidv4();
        } else {
            addedProduct.cartItemId = product.id;
        }
    }

    if (!product.cartItemAddedTimestamp) {
        addedProduct.cartItemAddedTimestamp = moment().format();
    }

    // Track which product was added to cart
    sendSignal(createCartAddProductSignal(addedProduct));

    return addedProduct;
};

export const addProduct = (product, customCartItemId) => {
    const addedProduct = handleAddProduct(product, customCartItemId);

    return {
        type: types.ADD_PRODUCT,
        product: addedProduct,
        error: false,
    };
};

export const addProductGuests = (product, customCartItemId) => {
    const addedProduct = handleAddProduct(product, customCartItemId);

    return {
        type: types.ADD_PRODUCT_GUESTS,
        product: addedProduct,
        error: false,
    };
};

export const removeProductGuestCategory = (product, guestAgeCategory) => {
    return {
        type: types.REMOVE_PRODUCT_GUEST_CATEGORY,
        product: product,
        payload: {
            guestAgeCategory,
        },
        error: false,
    };
};

export const setProductGuestCategoryCount = (product, guestAgeCategory, guestCount) => {
    return {
        type: types.SET_PRODUCT_GUEST_CATEGORY_COUNT,
        product: product,
        payload: {
            guestAgeCategory,
            guestCount,
        },
        error: false,
    };
};

export const addProductWithCount = product => {
    const addedProduct = handleAddProduct(product);

    return {
        type: types.ADD_PRODUCT_WITH_COUNT,
        product: addedProduct,
        error: false,
    };
};

/*
  Use to remove product from Checkout Cart
*/
export const removeProduct = product => {
    sendSignal(createCartRemoveProductSignal(product));

    return {
        type: types.REMOVE_PRODUCT,
        product: product,
        error: false,
    };
};

export const updateProduct = (product, payload) => {
    return {
        type: types.UPDATE_PRODUCT,
        product: product,
        payload: payload,
        error: false,
    };
};

export const addProductCount = (product, count, updateCount = false) => {
    const addedProduct = handleAddProduct(product);

    return {
        type: types.ADD_PRODUCT_COUNT,
        product: addedProduct,
        count: count,
        error: false,
        updateCount,
    };
};

export const removeProductCount = (product, count) => {
    return {
        type: types.REMOVE_PRODUCT_COUNT,
        product: product,
        count: count,
        error: false,
    };
};

export const setBookingForOthers = bookingForOthers => {
    return {
        type: types.SET_BOOKING_FOR_OTHERS,
        bookingForOthers: bookingForOthers,
        error: false,
    };
};

export const updateProductWithCount = (product, count) => {
    return {
        type: types.UPDATE_PRODUCT_WITH_COUNT,
        product: product,
        count: count,
        error: false,
    };
};

export const createNewCart = (token, cookieData = null) => {
    const config = token
        ? {
              headers: {
                  Authorization: `Bearer ${token}`,
              },
          }
        : {};
    return dispatch => {
        let body = {};
        if (cookieData) body.cookies = cookieData;

        AxiosClient.post("/cart", body, config)
            .then(response => {
                dispatch(setCart(response.data.payload));
            })
            .catch(error => {
                dispatch(setCartFailed(error.response.data));
            });
    };
};

export const setCart = payload => {
    return {
        type: types.SET_CHECKOUT_CART_ID,
        payload: payload,
    };
};

const setCartFailed = error => {
    return {
        type: types.SET_CART_FAILED,
        error: error,
    };
};

/*
  Use to add products to cart
 */
export const addProductsToCart = (cartId, productsData, token) => {
    const allRequests = [];

    // Provide token if logged in.
    const config = token
        ? {
              headers: {
                  Authorization: `Bearer ${token}`,
              },
          }
        : {};

    // Check which products we need to update or add

    productsData.forEach(productData => {
        if (isBooked(productData.product)) {
            // Handle booked products

            // Update any extras if quantity has been changed
            if (productData.product?.info?.extras) {
                const extras = productData.product.info.extras;
                const product = productData.product;

                const bookedExtras = extras.filter(
                    extra => isBooked(extra) && extra.quantity > 0 && extra.quantity !== extra.bookedquantity
                );
                const newExtras = extras.filter(extra => !isBooked(extra) && extra.quantity > 0);

                // -- Update any booked extra that has an updated quantity
                bookedExtras.forEach(extra => {
                    // Update quantity for any booked extras
                    allRequests.push(
                        AxiosClient.put(
                            `cart/${cartId}/products/${extra.id}`,
                            {
                                quantity: extra.quantity,
                            },
                            config
                        )
                    );
                });

                // -- Add any new extras
                if (newExtras.length) {
                    // Add new extras, all in the same request
                    const preparedExtras = newExtras.map(extra => ({
                        id: extra.id,
                        cart_id: extra.cart_id,
                        poollid: extra.poollid,
                        detlid: extra.detlid,
                        quantity: extra.quantity,
                    }));

                    allRequests.push(
                        AxiosClient.post(
                            `/cart/${cartId}/products/${product.id}/extras`,
                            {
                                add: preparedExtras,
                            },
                            config
                        )
                    );
                }
            }
        } else {
            // Add new products that have not been booked
            allRequests.push(
                AxiosClient.post(
                    `cart/${cartId}/products?resvtype=${productData.product.type}`,
                    productData.mandatory,
                    config
                )
            );
        }
    });

    const productGotExtras = productsData.some(product => product?.product?.info?.extras);

    return dispatch => {
        axios
            // wait for all requests's promises to fulfill or reject
            .all(allRequests)
            .then(
                axios.spread((...responses) => {
                    const dispatchedExtras = [];

                    // Handle each response in turn to know if we need to add extras or not, if product shall be updated or not.
                    // WARNING! Requests and responses may different indexes because not all products generate a request.
                    responses.forEach(response => {
                        // Skip response for added new extras to cart
                        if (response.request.responseURL.match(/\/extras$/)) {
                            return;
                        }

                        const payload = response.data.payload;

                        // Find the correct product data:
                        // - It may be a new product
                        // - It may be a booked accommodation with some new extras/additions
                        // - It may be a booked accommodation with some extras/additions with increased quantity
                        const currentProductData = productsData.find(productData => {
                            // Find new products
                            if (productData.mandatory.product_id === payload.original_product_id) {
                                return true;
                            }
                            // Find updated cart products
                            else if (productData.mandatory.product_id === payload.id) {
                                return true;
                            }
                            // Find primary product which has an updated child, where child is returned in payload and belongs to a parent
                            else if (productData.mandatory.product_id === payload.parent) {
                                return true;
                            }

                            return false;
                        });

                        // Abort if we could not match request with response.
                        // This may happen when new extras has been added to a booked accommodation.
                        if (!currentProductData) {
                            console.warn("Missing currentProductData in addProductsToCart()");
                            return;
                        }

                        if (currentProductData.cartId) {
                            const extras = currentProductData.product?.info?.extras;

                            if (extras && !isBooked(currentProductData.product)) {
                                // Track the returned promise
                                dispatchedExtras.push(
                                    dispatch(addExtrasToCart(cartId, currentProductData.product.id, extras, token))
                                );
                            }

                            dispatch(
                                updateProductItem(
                                    payload.original_product_id,
                                    currentProductData.cartId,
                                    payload.row_id
                                )
                            );
                        } else {
                            const productId = { uid: payload.original_product_id };
                            const payloadRow = { key: "row_id", value: payload.row_id };
                            const extras = currentProductData.product?.info?.extras;

                            if (extras && !currentProductData.product?.detlid) {
                                // Track the returned promise
                                dispatchedExtras.push(dispatch(addExtrasToCart(cartId, payload.row_id, extras, token)));
                            }

                            dispatch(updateProduct(productId, payloadRow));
                        }
                    });

                    // All responses has been handled and if we are allowed to continue we mark
                    // that all products was successfully added to cart
                    if (productGotExtras && dispatchedExtras.length) {
                        // Wait for all extras to be created before we actually say that all products have been added
                        Promise.all(dispatchedExtras)
                            .then(() => {
                                // All dispatched actions for extras are finished
                                console.info("All extras have been added to cart successfully");
                                dispatch(addedAllProducts(true));
                            })
                            .catch(() => {
                                console.error("Some extras could not be added to cart");
                                dispatch(addedAllProducts(false));
                            });
                    } else {
                        dispatch(addedAllProducts(true));
                    }
                })
            )
            .catch((...errors) => {
                console.error("checkoutCart.js Add products to cart failed", errors);
                dispatch(addedAllProductsFailed(errors[0]?.response.data));
                dispatch(addedAllProducts(false));
            });
    };
};

const updateProductItem = (productId, cartId, row_id) => {
    return {
        type: types.UPDATE_PRODUCT_ITEM,
        productId: productId,
        cartId: cartId,
        row_id: row_id,
    };
};

const addedAllProductsFailed = error => {
    return {
        type: types.ADDED_ALL_PRODUCTS_FAILED,
        error: error,
    };
};

export const addedAllProducts = value => {
    return {
        type: types.SUCCESSFULLY_ADDED_ALL_PRODUCTS,
        addedAllProductsSucceed: value,
    };
};

export const setCartProductExtras = (productId, payload) => {
    return {
        type: types.SET_CART_PRODUCT_EXTRAS,
        productId: productId,
        payload: payload,
    };
};

/*
  Use to fetch cart summary
*/
export const fetchCheckoutCartSummary = (token, cartId) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const url = `/cart/${cartId}`;
    const requestName = "fetchCheckoutCartSummary";

    return dispatch => {
        dispatch(setRequestsLoading({ request: requestName, loading: true }));

        AxiosClient.get(url, config)
            .then(response => {
                dispatch(setCheckoutCartSummary(response.data.payload));
                dispatch(setRequestsLoading({ request: requestName, loading: false }));
            })
            .catch(error => {
                dispatch(fetchCheckoutCartSummaryFailed(error.response.data));
                dispatch(setRequestsLoading({ request: requestName, loading: false }));
            });
    };
};

const setCheckoutCartSummary = summary => {
    return {
        type: types.SET_CHECKOUT_CART_SUMMARY,
        summary: summary,
    };
};

const fetchCheckoutCartSummaryFailed = error => {
    return {
        type: types.FETCH_CHECKOUT_CART_SUMMARY_FAILED,
        error: error,
    };
};

/*
  Use to remove product from Cart ( Summary )
*/
export const deleteProductFromCart = (token, cartId, productId) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const url = `/cart/${cartId}/products/${productId}`;

    return dispatch => {
        AxiosClient.delete(url, config)
            .then(() => {
                dispatch(fetchCheckoutCartSummary(token, cartId));
            })
            .catch(error => {
                dispatch(deleteProductFromCartFailed(error.response.data));
            });
    };
};

const deleteProductFromCartFailed = error => {
    return {
        type: types.DELETE_PRODUCT_FROM_CART_FAILED,
        error: error,
    };
};

/*
  Use to add extras to cart
*/
const addExtrasToCart = (cartId, rowId, extras, token) => {
    const url = `/cart/${cartId}/products/${rowId}/extras`;

    // Provide token if logged in.
    const config = token
        ? {
              headers: {
                  Authorization: `Bearer ${token}`,
              },
          }
        : {};

    const addExtras = extras
        .filter(extra => extra.quantity >= 1)
        .map(extra => {
            return {
                id: extra.id,
                cart_id: extra.cart_id,
                poollid: extra.poollid,
                detlid: extra.detlid,
                quantity: extra.quantity,
            };
        });

    return dispatch => {
        if (addExtras.length) {
            // Return the promise so the caller may track the status (fulfilled/rejected)
            return AxiosClient.post(
                url,
                {
                    add: addExtras,
                },
                config
            ).catch(error => {
                console.log("addExtrasToCart failed");
                dispatch(addExtrasToCartFailed(error.response.data));
            });
        }
    };
};

const addExtrasToCartFailed = error => {
    return {
        type: types.ADD_EXTRAS_TO_CART_FAILED,
        error: error,
    };
};

/*
  Use to sync all products in cart
*/
export const syncProductsInCart = (cartId, productsRequestData) => {
    /*
        These two scenarios are handled by the API.

        # New reservation
        1. Clear all existing products
        2. Re-add all products

        # Existing reservation
        1. Clear all new products
        2. Reset any booked quantities
        3. Re-add all products
    */
    return (dispatch, getState) => {
        const state = getState();

        const token = state.account?.token;

        AxiosClient.delete(`/cart/${cartId}/products`)
            .then(() => {
                dispatch(addProductsToCart(cartId, productsRequestData, token));
            })
            .catch(error => {
                dispatch(syncProductsInCartFailed(error.response.data));
            });
    };
};

const syncProductsInCartFailed = error => {
    return {
        type: types.SYNC_PRODUCTS_IN_CART_FAILED,
        error: error,
    };
};

export const setLatestChangedProductType = typeLid => {
    return {
        type: types.SET_LATEST_CHANGED_PRODUCT_TYPE,
        activeLid: typeLid,
    };
};

export const emptyCartProducts = () => {
    sendSignal(createCartEmptySignal());

    return {
        type: types.EMPTY_CART_PRODUCTS,
    };
};

export const resetCart = () => {
    return {
        type: types.RESET_CART,
    };
};

/*
  Use to create checkoutCart from a reservation using reservation id.
*/
export const createCartFromReservation = (reservationId, token, resvTypes, cookieData = null) => {
    // A token is always required when restoring a reservation
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    let body = {
        resvid: reservationId,
    };

    if (cookieData) {
        body.cookie = cookieData;
    }

    return dispatch => {
        dispatch(setRequestsLoading({ request: "createCartFromReservation", loading: true }));

        // Return promise to allow caller to know when promise has resolved
        return AxiosClient.post("/cart", body, config)
            .then(response => {
                const payload = response.data.payload;
                const cartId = payload.id;

                if (!isEmpty(payload)) {
                    dispatch(resetCart());
                    dispatch(resetReservation());
                    dispatch(resetGuests());
                    dispatch(setCart(payload));

                    const guests = payload.guests;
                    for (const id in guests) {
                        const birthday = moment(guests[id].birthday);
                        guests[id].year = birthday.year().toString();
                        guests[id].month = (birthday.month() + 1).toString();
                        guests[id].day = birthday.date().toString();
                        guests[id].disabled = true;
                        guests[id].ontravel = true;
                    }

                    dispatch({ type: types.SET_GUESTS, guests: guests });

                    const products = convertToArray(payload.products).filter(product => !isSystemPool(product));

                    const accommodations = products.filter(
                        product =>
                            resvTypes.find(type => type.lid === parseInt(product.type)).type ===
                                Constants.productTypeNames.ACCOMMODATION && product.parent === null
                    );

                    const otherProducts = products.filter(product => {
                        const resvtype = resvTypes.find(type => type.lid === parseInt(product.type)).type;
                        return resvtype !== Constants.productTypeNames.ACCOMMODATION && product.parent === null;
                    });

                    if (accommodations.length > 0) {
                        const requests = [];

                        accommodations.forEach(accommodation => {
                            // Send cart id and product id (row id in cart) to API to fetch additional product data from cart
                            // and to fetch extras differently depending on if it's a restored product or regular product.
                            const url = `/cart/${cartId}/products/${accommodation.id}/extras`;
                            requests.push(AxiosClient.get(url, config));

                            const accommodationExtras = products.filter(
                                product =>
                                    resvTypes.find(type => type.lid === parseInt(product.type)).type ===
                                        Constants.productTypeNames.ACCOMMODATION && product.parent === accommodation.id
                            );

                            // Merge extras into info
                            accommodation.info = { ...accommodation.info, extras: accommodationExtras };
                        });

                        axios
                            .all(requests)
                            .then(
                                axios.spread((...responses) => {
                                    responses.forEach(response => {
                                        const payload = response.data.payload;

                                        // Find out which product we want to update extras for
                                        if (!isEmpty(payload)) {
                                            const accommodationToUpdate = accommodations.find(
                                                product =>
                                                    product.poollid === payload.product.poollid &&
                                                    product.unitlid === payload.product.unitlid
                                            );

                                            // console.log(
                                            //     "Product to update from cart request",
                                            //     cloneDeep(accommodationToUpdate.arttext)
                                            // );

                                            console.log("payload extras", payload.extras);
                                            payload.extras.forEach(extra => {
                                                const extras = accommodationToUpdate.info?.extras;
                                                const index = extras.findIndex(e => e.poollid === extra.poollid);
                                                console.log("accommodation extras", extras);
                                                if (index === -1) {
                                                    // New extra found, add it to the list
                                                    extras.push(extra);
                                                } else {
                                                    // Modify existing extra
                                                    extras[index] = { ...extra, ...extras[index] };

                                                    if (
                                                        extras[index].rules.type_input === "checkbox" &&
                                                        extras[index].quantity
                                                    ) {
                                                        extras[index].checked = true;
                                                    }
                                                }
                                            });
                                        }
                                    });

                                    // eslint-disable-next-line
                                    dispatch(setCartFromReservation(reservationId, products));
                                })
                            )
                            .catch(errors => {
                                console.log("error", errors);
                            });
                    } else if (otherProducts.length > 0) {
                        dispatch(setCartFromReservation(reservationId, otherProducts));
                    }
                }
                dispatch(setRequestsLoading({ request: "createCartFromReservation", loading: false }));
            })
            .catch(error => {
                dispatch(createCartFromReservationFailed(error));
                dispatch(setRequestsLoading({ request: "createCartFromReservation", loading: false }));
            });
    };
};

const createCartFromReservationFailed = error => {
    console.log(error);
    return {
        type: types.CREATE_CART_FROM_RESERVATION_FAILED,
        error: error,
        createdFromReservation: null,
    };
};

const setCartFromReservation = (reservationId, products) => {
    products = products.filter(product => {
        return product.parent === null;
    });
    return {
        type: types.SET_CART_FROM_RESERVATION,
        products: products,
        createdFromReservation: reservationId,
    };
};

export const setCheckoutCartNote = note => {
    return {
        type: types.SET_CHECKOUT_CART_NOTE,
        note,
    };
};

export const setCheckoutSummaryDetailedView = detailedView => {
    return {
        type: types.SET_CHECKOUT_SUMMARY_DETAILED_VIEW,
        detailedView,
    };
};

export const setProductsNotAddedPath = path => {
    return {
        type: types.SET_PRODUCTS_NOT_ADDED_PATH,
        path,
    };
};
