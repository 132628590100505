import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button } from "..";
import * as types from "../../../store/actions/types";
import { setSignUpWrapper } from "../../../store/actions/window";
import { CloseIcon, MenuIcon } from "../R360Icons";
import { optionsSelector } from "../../../selectors/Selectors";
import "./MenuOld.scss";
import { signOutUser } from "../../../store/actions/account";
import { textsSelector } from "../../../Selectors";

const MenuOld = props => {
    const dispatch = useDispatch();
    const location = useLocation();
    const loginSuccessful = useSelector(state => state.account.loginSuccessful);
    const showingLoginModal = useSelector(state => state.window.signUpWrapper.loginModal);
    const [showMenu, setShowMenu] = useState(showingLoginModal);
    const texts = useSelector(textsSelector);
    const isAgent = useSelector(state => state.account?.user?.isAgent);
    const showGuests = useSelector(optionsSelector).customer?.show_guests || false;
    const wrapperRef = useRef(null);
    const wrapperClass = showMenu ? "menu_old__wrapper menu_old__wrapper--visible" : "menu_old__wrapper";
    const myPageOnly = !!useSelector(optionsSelector).general?.mypage_only;
    const navigate = useNavigate();

    // Close menu with the 'Esc' key
    useEffect(() => {
        document.addEventListener(
            "keydown",
            event => {
                if (event.key === "Escape") {
                    setShowMenu(false);
                }
            },
            false
        );
    }, []);

    // Close menu on click outside of container
    useEffect(() => {
        document.addEventListener("mousedown", event => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setShowMenu(false);
            }
        });
    }, [wrapperRef]);

    // Handle button click
    const handleClick = () => {
        setShowMenu(!showMenu);
    };

    // Link list depending on if user is logged in or not
    const LinkList = () => {
        if (loginSuccessful) {
            return (
                <ul className="menu_old__list">
                    {!myPageOnly && (
                        <li className="menu_old__list-item">
                            <Link to="/" className="menu_old__link" onClick={handleClick}>
                                {texts?.generalstart}
                            </Link>
                        </li>
                    )}
                    <li className="menu_old__list-item">
                        <Link to="/account" className="menu_old__link" onClick={handleClick}>
                            {texts?.generalmyaccount}
                        </Link>
                    </li>
                    <li className="menu_old__list-item">
                        <Link to="/account/bookings" className="menu_old__link" onClick={handleClick}>
                            {texts?.generalreservations}
                        </Link>
                    </li>
                    {showGuests && (
                        <li className="menu_old__list-item">
                            <Link to="/account/guests" className="menu_old__link" onClick={handleClick}>
                                {texts?.myguests}
                            </Link>
                        </li>
                    )}

                    {!isAgent && (
                        <li className="menu_old__list-item">
                            <Button
                                className="menu_old__button-link"
                                onClick={() => {
                                    handleClick();
                                    dispatch(signOutUser()).then(loggedOut => loggedOut && navigate("/"));
                                }}
                            >
                                {texts?.logout}
                            </Button>
                        </li>
                    )}
                </ul>
            );
        } else {
            return (
                <ul className="menu_old__list">
                    {/* {cartProducts.length > 0 && (
            <li className="menu_old__list-item menu__list-item--border-bottom">
              <div className="d-grid menu__booking-link-wrapper">
                <Link
                  className="btn button menu__booking-link"
                  onClick={loginOrContinue}
                  // aria-label={`Fortsätt bokning, ${cartProducts.length} produkter i varukorgen`}
                >
                  {texts?.continuebooking || "Fortsätt bokning"}
                </Link>
                <div className="menu_old__product-count-container">
                  <span className="menu_old__product-count">
                    {cartProducts.length}
                  </span>
                </div>
              </div>
            </li>
          )} */}
                    {!myPageOnly && (
                        <li className="menu_old__list-item">
                            <Link to="/" className="menu_old__link" onClick={handleClick}>
                                {texts?.generalstart}
                            </Link>
                        </li>
                    )}
                    {!props.hideActionLinks && (
                        <>
                            <li className="menu_old__list-item">
                                <Button
                                    className="menu_old__button-link"
                                    onClick={() => {
                                        handleClick();
                                        dispatch(setSignUpWrapper(true, true, false, "/account"));
                                    }}
                                >
                                    {texts?.login}
                                </Button>
                            </li>
                            {!myPageOnly && (
                                <li className="menu_old__list-item">
                                    <Button
                                        className="menu_old__button-link"
                                        onClick={() => {
                                            handleClick();
                                            dispatch(setSignUpWrapper(true, false, true, location.pathname));
                                        }}
                                    >
                                        {texts?.signup}
                                    </Button>
                                </li>
                            )}
                        </>
                    )}
                </ul>
            );
        }
    };

    // Return
    return (
        <>
            <div className="menu_old">
                <div className="menu_old__button-container">
                    <button
                        type="button"
                        aria-label={texts?.mainmenu || "Huvudmeny"}
                        aria-haspopup={true}
                        aria-expanded={false}
                        className="menu_old__button"
                        onClick={handleClick}
                    >
                        <MenuIcon size={25} color="#333333" className="menu_old__button-icon" />
                        <span>{texts?.generalmenu || "Meny"}</span>
                    </button>
                </div>
                <div className={wrapperClass} ref={wrapperRef} aria-hidden={!showMenu}>
                    <button
                        type="button"
                        aria-label={texts?.closemenu || "Stäng huvudmenyn"}
                        className="menu_old__close-button"
                        onClick={handleClick}
                    >
                        <CloseIcon size={15} color="#ffffff" />
                    </button>
                    <nav className="menu_old__container" aria-label={texts?.mainmenu || "Huvudmeny"}>
                        <LinkList />
                    </nav>
                </div>
            </div>
            {/* {account?.user && (
        <p className="m-0 fw-light float-end menu_old__helloText">
          {texts?.generalhello || "Hej"} <Link to="/account">{account?.user?.name1}</Link>
        </p>
      )} */}
        </>
    );
};

export default MenuOld;
