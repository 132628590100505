import React, { useRef, useState } from "react";
import { Button, Checkbox, DatePicker, DropdownButton } from "../UI";

const ReservationTypeDateFilter = ({
    texts,
    reservationTypeid,
    initialStartDate,
    initialEndDate,
    isMobile = false,
    isSingleDate = false,
    onBeforeDateChange,
    onAfterDateChange,
    onClearDates,
    onDatesChange,
    onSetStartDate,
    onSetEndDate,
    onSetSingledate,
}) => {
    const [singleDate, setSingleDate] = useState(isSingleDate);
    const [clearDates, setClearDates] = useState(false);
    const dropdownButtonRef = useRef(null);

    // Always render with start- and end dates from props
    const startDate = initialStartDate?.isValid?.() ? initialStartDate : null;
    const endDate = initialEndDate?.isValid?.() ? initialEndDate : null;

    const handleClearDates = clear => {
        setClearDates(clear);

        if (onClearDates) {
            onClearDates(clear);
        }
    };

    const handleSetSingleDate = single => {
        setSingleDate(single);

        if (onSetSingledate) {
            onSetSingledate(single);
        }

        // When toggling single date, clear selected dates.
        handleClearDates(true);
    };

    const handleDateChange = (start, end) => {
        if (onBeforeDateChange) {
            onBeforeDateChange(start, end);
        }

        if (end !== "Invalid date") {
            if (onSetStartDate) {
                onSetStartDate(start);
            }

            if (onSetEndDate) {
                onSetEndDate(end);
            }

            if (onDatesChange) {
                onDatesChange(start, end);
            }

            dropdownButtonRef.current.setShowMenu(false);
        }

        if (onAfterDateChange) {
            onAfterDateChange(start, end);
        }
    };

    const toggleSingleDatePicker = ({ value }) => {
        handleSetSingleDate(value);
    };

    const defaultText = singleDate
        ? texts?.date || "Datum"
        : `${texts?.startdate || "Startdatum"} - ${texts?.enddate || "Slutdatum"}`;

    const buttonText =
        !startDate || !endDate
            ? defaultText
            : startDate.format("ddd DD MMM") === endDate.format("ddd DD MMM")
            ? startDate.format("ddd DD MMM")
            : `${startDate.format("ddd DD MMM")} - ${endDate.format("ddd DD MMM")}`;

    return (
        <DropdownButton
            ref={dropdownButtonRef}
            text={buttonText}
            className="filter__dropdown-button filter__dropdown-button--active filter__dropdown-button--no-padding"
        >
            <DatePicker
                resvType={reservationTypeid}
                showController
                singleDate={singleDate}
                startDate={startDate}
                endDate={endDate}
                clearDates={clearDates}
                isMobile={isMobile}
                changedDate={(start, end) => {
                    if (clearDates) {
                        handleClearDates(false);
                    }
                    singleDate ? handleDateChange(start, start) : handleDateChange(start, end);
                }}
                numberOfMonths={1}
            />
            <div className="letting-view__controller-toggle">
                <Checkbox
                    label={texts?.generalsingleday}
                    name="singleDateSwitch"
                    checked={singleDate}
                    onChange={toggleSingleDatePicker}
                />
            </div>
            <Button className="btn-sm button--grey filter__clear-dates-button" onClick={() => handleClearDates(true)}>
                {texts?.cleardates || "Rensa datum"}
            </Button>
        </DropdownButton>
    );
};

export default ReservationTypeDateFilter;
