import React, { useCallback } from "react";
import { Interweave } from "interweave";
import { UrlMatcher, EmailMatcher } from "interweave-autolink";
import { useSelector } from "react-redux";
import { optionsSelector } from "../../../Selectors";

const filter = {
    node(name, node) {
        if (name === "a") {
            // Useful if we want to prevent a link from having target _blank set
            if (node.target === "forceSelf") {
                node.setAttribute("target", "_self");
            } else {
                node.setAttribute("target", "_blank");
            }
        }
        return node;
    },
};

/**
 * RichText is used to present HTML safely and also converting links to clickable <a> tags
 *
 * The UrlMatcher will automatically detect URLs and create links.
 * The EmailMatcher will automatically detect email addresses and create mailto-links.
 * The `newWindow` option always opens links in new windows
 *
 * We use this component to hide implementation details and reuse functionality.
 *
 * https://interweave.dev/
 * https://interweave.dev/docs/exts/autolink
 */
const RichText = ({ content }) => {
    const formatEmail = !!useSelector(optionsSelector)?.text?.format_email;

    const getMatchers = useCallback(() => {
        const matchers = [new UrlMatcher("url")];

        if (formatEmail) {
            matchers.push(new EmailMatcher("email"));
        }

        return matchers;
    }, [formatEmail]);

    return <Interweave content={content} matchers={getMatchers()} newWindow={true} filters={[filter]} />;
};

export default RichText;
