import { parsePhoneNumber } from "awesome-phonenumber";
import { keepNumbers } from "./BusinessUtils";

export const validateOrigin = origin => {
    // Security: Check for valid origin
    if (!origin.match(/\.r360(online|dev|stage)\.(se|test)$/)) {
        return false;
    }

    return true;
};

export const validateJSON = jsonString => {
    // Data format check
    if (!jsonString || typeof jsonString !== "string") {
        return;
    }

    try {
        return JSON.parse(jsonString);
    } catch (e) {
        return;
    }
};

/**
 * Validate a mobile phone number
 *
 * @param {string} prefix e.g 45, 46, 47
 * @param {string} phoneNumber e.g 0701234567
 * @returns {boolean}
 */
export const validateMobilePhoneNumber = (prefix, phoneNumber) => {
    if (!prefix) {
        return false;
    }

    if (!phoneNumber) {
        return false;
    }

    // Convert to strings
    prefix = "" + prefix;
    phoneNumber = "" + phoneNumber;

    // Remove any unnessesary plus signs from prefix
    prefix = prefix.replace("+", "");

    const fullPhoneNumber = "+" + prefix + keepNumbers(phoneNumber);
    const pn = parsePhoneNumber(fullPhoneNumber);

    // Note: Some countries (e.g Denmark) classify some phone number groups as both fixed and mobile
    return pn.valid && pn.typeIsMobile;
};

export const validateEmail = email => {
    // Allow any domain and any tld.
    // There are thousands of new tlds longer than 2-4 characters.
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i.test(email);
};

export const validatePassword = password => {
    if (!password) {
        return false;
    }

    return password.length >= 8;
};
