import React from "react";

import "./AdditionalProduct.scss";
import placeholderImage from "../../assets/images/image1.jpg";
import { TickIcon } from "../UI/R360Icons";
import {
    Skipass as SkipassImage,
    Letting as LettingImage,
    Activities as ActivitiesImage,
    Rental as RentalImage,
    Package as PackageImage,
} from "../../assets/reservationTypes";
import * as Constants from "../../Constants";
import { alertDialog, ResponsiveImage } from "../UI";

// Additional product component
const AdditionalProduct = ({
    texts,
    product,
    isActive,
    onClick,
    index,
    height = 200,
    backgroundColorOverride = "",
    disabled = false,
    disabledMessage = "",
}) => {
    const imageUrl = () => {
        switch (product.type) {
            case Constants.productTypeNames.SKIPASS:
                return SkipassImage;
            case Constants.productTypeNames.LETTING:
                return LettingImage;
            case Constants.productTypeNames.RENTAL:
                return RentalImage;
            case Constants.productTypeNames.ACTIVITY:
                return ActivitiesImage;
            case Constants.productTypeNames.PACKAGE:
                return PackageImage;
            default:
                return "";
        }
    };

    // Handle item click
    const handleClick = () => {
        if (disabled) {
            alertDialog({
                description: disabledMessage,
                okLabel: texts["general.confirmation.ok"],
            });
        } else {
            onClick && onClick(product.lid);
        }
    };

    return (
        <div className="additional-product" style={{ height: `${height}px` }}>
            <button
                type="button"
                className="additional-product__button"
                onClick={handleClick}
                aria-expanded={isActive}
                aria-controls={`container${index}`}
                style={{ backgroundColor: backgroundColorOverride || "silver", opacity: disabled ? 0.2 : 1 }}
            >
                {!backgroundColorOverride ? (
                    <ResponsiveImage
                        height={height}
                        src={imageUrl()}
                        placeholder={placeholderImage}
                        className="additional-product__image"
                    />
                ) : (
                    <>
                        {/* Ugly hack to make the button take up enough width */}
                        <div style={{ width: "999px" }}></div>
                    </>
                )}
                <div className="additional-product__title">
                    {product.description}
                    {isActive && <TickIcon size={35} color="#FFF" color2="#333" />}
                </div>
            </button>
        </div>
    );
};

export default AdditionalProduct;
