import { ECriteriaType, TCriteriaItem, TCrits, TGroup, TPackageGroup } from "../store/types";

/**
 * Filter group items
 * @returns {Object} — group object with filtered items
 */
export const filterGroupItems = (
    group: TGroup | TPackageGroup,
    activeFilters: { [key: number | string]: any },
    criterias: { [key: number]: TCriteriaItem }
) => {
    let filteredGroup: TGroup | TPackageGroup = { ...group };
    activeFilters = { ...activeFilters }; // Shallow clone.

    if (activeFilters.search) {
        filteredGroup = filterGroupBySearch(filteredGroup, activeFilters.search);

        // Don't include search in isFilterMatch below since it expects criteria codes.
        delete activeFilters.search;
    }

    // Filter out group items that match active filter values.
    filteredGroup.items = Object.values(filteredGroup.items).filter(item => {
        return isFilterMatch(activeFilters, item.crits, criterias);
    });

    return filteredGroup;
};

const filterGroupBySearch = (group: TGroup | TPackageGroup, searchNeedle: string) => {
    const filterSearchNeedleMatcher = new RegExp(searchNeedle, "i"); // case insensitive search

    const filteredItems = group.items.filter(
        item =>
            group.groupitem.title?.toString?.().search(filterSearchNeedleMatcher) !== -1 ||
            item.pooldesc1?.toString?.().search(filterSearchNeedleMatcher) !== -1 ||
            item.unitdesc1?.toString?.().search(filterSearchNeedleMatcher) !== -1 ||
            item.title?.toString?.().search(filterSearchNeedleMatcher) !== -1
    );

    // Keep when debugging search filter
    // const queriedStrings = filteredItems.map(
    //     item => `${group.groupitem.title}:${item.pooldesc1}:${item.unitdesc1}:${item.title}`
    // );
    // console.log("DEBUG Search query results:", searchNeedle, queriedStrings);

    group.items = filteredItems;

    return group;
};

/**
 * Compare active filters and item criterias
 * @returns {Boolean} — true if all filters match, else false
 */
export const isFilterMatch = (
    activeFilters: { [key: number]: any },
    itemCrits: TCrits,
    criterias: { [key: number]: TCriteriaItem }
) => {
    if (Object.keys(activeFilters).length === 0) {
        return true;
    }

    for (const critcode in activeFilters) {
        if (itemCrits && critcode in activeFilters && critcode in itemCrits) {
            if (
                criteriaMatch(itemCrits[critcode], activeFilters[critcode], parseInt(critcode, 10), criterias) === false
            )
                return false;
        } else {
            return false;
        }
    }

    return true;
};

/**
 * Match criteria value to active filter value
 * @returns {Boolean} — true if values match, else false
 */
export const criteriaMatch = (
    value: any,
    filterValue: any,
    critcode: number,
    criterias: { [key: number]: TCriteriaItem }
) => {
    if (!(critcode in criterias)) {
        return false;
    }
    const criteria = criterias[critcode];

    switch (criteria.type) {
        case ECriteriaType.ENUM:
            // A item can have on more enums for the same critera selected.
            // E.g. when age criteria spans multiple ages.
            if (Array.isArray(value)) {
                return value.some(val => filterValue.includes(val));
            } else {
                return filterValue.includes(value);
            }
        case ECriteriaType.MIN:
        case ECriteriaType.MAX:
            return value >= filterValue.min && value <= filterValue.max;
        case ECriteriaType.BOOL:
            return value === filterValue;
        default:
            return false;
    }
};
