import axios from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { setLoading } from "../store/actions/axiosStatus";

const instance = axios.create({
    // baseURL: "https://jarvso.r360online.se/en/v3/api",
    // baseURL: "https://himmelfjall.r360online.se/en/v3/api",
    // baseURL: "https://klappen.r360online.se/en/v3/api",
    // baseURL: "https://jarvsobacken.r360online.se/en/v3/api",
    baseURL: window.__BASE_URL__ + window.__BASE_API_PATH,
    responseType: "json",
    withCredentials: true,
});

export const useAxiosLoader = () => {
    const [counter, setCounter] = useState(0);
    const dispatch = useDispatch();
    const inc = useCallback(() => setCounter(counter => counter + 1), [setCounter]); // add to counter
    const dec = useCallback(() => setCounter(counter => counter - 1), [setCounter]); // remove from counter

    const interceptors = useMemo(
        () => ({
            // eslint-disable-next-line
            request: config => (inc(), config),
            // eslint-disable-next-line
            response: response => (dec(), response),
            // eslint-disable-next-line
            error: error => (dec(), Promise.reject(error)),
        }),
        [inc, dec]
    ); // create the interceptors

    useEffect(() => {
        // add request interceptors
        const reqInterceptor = instance.interceptors.request.use(interceptors.request, interceptors.error);

        // add response interceptors
        const resInterceptor = instance.interceptors.response.use(interceptors.response, interceptors.error);

        return () => {
            // remove all intercepts when done
            instance.interceptors.request.eject(reqInterceptor);
            instance.interceptors.response.eject(resInterceptor);
        };
    }, [interceptors]);

    useEffect(() => {
        if (counter > 0) {
            dispatch(setLoading({ loading: true }));
        } else {
            dispatch(setLoading({ loading: false }));
        }
    }, [dispatch, counter]);

    return [counter > 0];
};

export default instance;
