import React, { useEffect, useState, useTransition } from "react";
import { TCartItem, TCartItemItem } from "../../store/types";
import { Button, Textarea } from "@r360/library";
import { CheckoutConnectCardRemoveBtn } from "./CheckoutConnectCard/CheckoutConnectCard";
import useTranslate from "../../hooks/useTranslate";
import classNames from "classnames";

type TCheckoutConnectComment = {
    product: TCartItem;
    item: TCartItemItem;
    onUpdateProduct: (arg0: TCartItem, arg1: TCartItemItem, arg2: string, arg3: string) => void;
    onRemoveProduct: (arg0: TCartItem, arg1: TCartItemItem) => void;
    isItem?: boolean;
};

export const CheckoutConnectComment = ({
    product,
    item,
    onUpdateProduct,
    onRemoveProduct,
    isItem = false,
}: TCheckoutConnectComment) => {
    const t = useTranslate();
    const [editComment, setEditComment] = useState(false);

    return (
        <>
            {!editComment && item.note && <p className="u-mb-12">{`${t("book.general.comment")}: "${item.note}"`}</p>}
            {editComment && (
                <div className="u-mb-18">
                    <Textarea
                        value={item.note}
                        onChange={e => {
                            onUpdateProduct(product, item, "note", e.target.value);
                        }}
                        autoFocus
                        maxLength={250}
                        onKeyDown={e => {
                            e.key === "Enter" && e.preventDefault();
                            if (e.key === "Enter") {
                                setEditComment(false);
                            }
                        }}
                        // Since onBlur has higher priority then onClick; set editComment to false after a timeout so any click events can run before the blur
                        onBlur={() => setTimeout(() => setEditComment(false), 200)}
                    />
                </div>
            )}
            <div className="u-d-flex u-flex-wrap u-gap-18">
                {!editComment && (
                    <CheckoutConnectCardRemoveBtn onClick={() => onRemoveProduct(product, item)} isItem={isItem} />
                )}
                {!editComment && (
                    <Button type="tertiary" onClick={() => setEditComment(true)}>
                        {item.note ? t("book.general.comment.edit") : t("book.general.comment.add")}
                    </Button>
                )}
                {editComment && (
                    <>
                        <Button
                            type="tertiary"
                            onClick={() => {
                                console.log("runs");
                                onUpdateProduct(product, item, "note", "");
                                setEditComment(false);
                            }}
                        >
                            {t("book.general.comment.delete")}
                        </Button>
                        <Button type="tertiary" onClick={() => setEditComment(false)} disabled={!item.note}>
                            {t("book.general.comment.save")}
                        </Button>
                    </>
                )}
            </div>
        </>
    );
};
