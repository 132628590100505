import React, { useEffect, useState } from "react";
import { Accordion, FormControl } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Button, Checkbox, Icon, Incrementer } from "..";
import { setFilter, removeFilter } from "../../../store/actions/filter";
import DistanceRangeFilter from "./DistanceRangeFilter";
import QuantityRangeFilter from "./QuantityRangeFilter";
import { roundBy } from "../../../Helper";
import "./Filters.scss";
import { sortBySortorder } from "../../../BusinessUtils";
import { textsSelector } from "../../../Selectors";

// Filter component
const Filter = props => {
    const dispatch = useDispatch();
    const filters = useSelector(state => state.filter);
    const texts = useSelector(textsSelector);
    const resvTypeCrits = filters.filterCriterias?.[props.reservationType] || {};
    const allFilters = useSelector(state => state.availableFilters.default);
    const [search, setSearch] = useState(props.filterSearch || "");
    const activeFilters = filters && props.reservationType in filters ? filters[props.reservationType] : {};

    useEffect(() => {
        setSearch(props.filterSearch);
    }, [props.filterSearch]);

    const handleSearchChange = event => {
        setSearch(event.target.value);
        props.onFilterSearchChange && props.onFilterSearchChange(event.target.value);
    };

    // Remove filter
    const removeActiveFilter = filter => {
        dispatch(removeFilter(filter, props.reservationType));
    };

    // Set lokal state and update redux on filter change
    const onFilterChange = response => {
        dispatch(setFilter(response, props.reservationType));
    };

    const onEnumFilterChange = response => {
        const critCode = response.key;
        const enumCode = response.value;

        if (activeFilters && critCode in activeFilters && activeFilters[critCode].includes(enumCode)) {
            removeActiveFilter(response);
        } else {
            dispatch(setFilter(response, props.reservationType));
        }
    };

    // Render filter
    const renderFilter = filter => {
        // Filter type is set from the Filter XML inside web admin on reservation types, used to
        // override which type of UI component they want to show for filters
        switch (filter.displayAs) {
            case "range": {
                if (filter.type === "MAX" || filter.type === "MIN") {
                    const min = resvTypeCrits[filter.code].min;
                    const max = resvTypeCrits[filter.code].max;
                    const value = { min: filter.min, max: filter.max };

                    if (activeFilters && filter.code in activeFilters) {
                        value.min = activeFilters[filter.code].min;
                        value.max = activeFilters[filter.code].max;
                    }

                    if (filter.type === "MAX") {
                        return (
                            <DistanceRangeFilter
                                name={`${filter.code}`}
                                label={filter.label}
                                min={min}
                                max={max}
                                value={value}
                                unit={filter.typeunit}
                                onChange={value => onFilterChange({ ...value, type: filter.type, details: filter })}
                            />
                        );
                    } else if (filter.type === "MIN") {
                        return (
                            <QuantityRangeFilter
                                multiple
                                name={`${filter.code}`}
                                label={filter.label}
                                min={min}
                                max={max}
                                value={value}
                                unit={filter.typeunit}
                                onChange={value => onFilterChange({ ...value, type: filter.type, details: filter })}
                            />
                        );
                    }
                } else {
                    console.info(
                        "A range filter has an unsupported type, need to be either MIN or MAX",
                        filter.code,
                        filter.type
                    );
                }

                break;
            }

            case "bool": {
                const checked = activeFilters && filter.code in activeFilters ? activeFilters[filter.code] : false;

                return (
                    <Checkbox
                        label={filter.label}
                        name={filter.code}
                        checked={checked}
                        onChange={value => onFilterChange({ ...value, type: filter.type, details: filter })}
                    />
                );
            }

            case "buttons": {
                // Best for MIN/quantity
                return (
                    <div className="filter__group">
                        <div className="filter__group-label">{filter.label}</div>
                        <div className="filter__group-input">
                            <Incrementer
                                label={filter.label}
                                name={filter.code}
                                value={activeFilters && activeFilters[filter.code] ? activeFilters[filter.code] : 0}
                                onChange={value => onFilterChange({ ...value, type: filter.type, details: filter })}
                            />
                        </div>
                    </div>
                );
            }

            case "multicheck": {
                // Best for ENUMs
                return (
                    <div className="filter__enum">
                        <div className="filter__enum-label">{filter.label}</div>
                        {Object.entries(filter.enums || {}).map((entry, i) => {
                            const critCode = filter.code;
                            const enumCode = parseInt(entry[0]);
                            const enumValue = entry[1].value;
                            const checked = !!(
                                activeFilters &&
                                critCode in activeFilters &&
                                activeFilters[critCode].includes(enumCode)
                            );

                            return (
                                <div key={i} className="checkbox">
                                    <label htmlFor={enumCode} className="checkbox__label">
                                        <span className="checkbox__label-text">{enumValue}</span>
                                        <input
                                            type="checkbox"
                                            id={enumCode}
                                            name={enumCode}
                                            className="checkbox__input"
                                            checked={checked}
                                            onChange={() =>
                                                onEnumFilterChange({
                                                    key: filter.code,
                                                    value: enumCode,
                                                    type: filter.type,
                                                    details: filter,
                                                })
                                            }
                                        />
                                        <span className="checkbox__checkmark" aria-hidden="true"></span>
                                    </label>
                                </div>
                            );
                        })}
                    </div>
                );
            }
            default:
                break;
        }
    };

    let selectedFilters = [];
    if (activeFilters) {
        selectedFilters = Object.entries(activeFilters).reduce((list, item) => {
            const critcode = parseInt(item[0]);
            const value = item[1]; // either a clean value or a list of active enums
            const criteria = resvTypeCrits[critcode];

            if (!criteria) {
                return list;
            }

            const filterDefinition = allFilters[critcode];
            const title = filterDefinition.title;

            let label = "";

            if (criteria.type === "ENUM") {
                // Value is a list of active enums
                const activeEnums = value.reduce((enums, enumCode) => {
                    const enumCriteria = criteria.enums[enumCode];

                    if (!enumCriteria) {
                        return enums;
                    }

                    return enums.concat({
                        key: critcode,
                        type: criteria.type,
                        displayAs: criteria.displayAs,
                        value: enumCode,
                        title: title,
                        label: enumCriteria.value,
                    });
                }, []);

                list = list.concat(activeEnums);
                return list;
            }

            // -- Handle simple values

            switch (criteria.displayAs) {
                case "range": {
                    // E.g Antal sängar: 1-15
                    if (criteria.typeunit === "m" && value.max >= 1000) {
                        label = `${title}: ${roundBy(value.min / 1000)}-${roundBy(value.max / 1000)}km`;
                    } else {
                        label = `${title}: ${value.min}-${value.max}${criteria.typeunit}`;
                    }
                    break;
                }

                case "bool": {
                    // E.g Husdjur tillåtet
                    label = `${title}`;
                    break;
                }

                case "buttons": {
                    // E.g Antal sovrum: 2
                    label = `${title}: ${value}`;
                    break;
                }
            }

            return list.concat({
                key: critcode,
                type: criteria.type,
                displayAs: criteria.displayAs,
                value: value,
                title: title,
                label: label,
            });
        }, []);
    }

    // Prepare filters that should be displayed and sort them
    const filtersToDisplay = sortBySortorder(
        Object.values(resvTypeCrits)
            .filter(filter => filter.code in allFilters)
            .map(filter => ({ ...filter, label: allFilters[filter.code].title }))
    );

    // Render
    return (
        <div className="filter__wrapper">
            <Accordion>
                <Accordion.Collapse eventKey="0" className="filter__more-filters-wrapper" in={props.showFilter}>
                    <div className="filter__more-filters-container container">
                        {props.showFilterSearch && (
                            <div className="row">
                                <div className="col-lg-3 col-md-6 col-xs-12" style={{ marginBottom: "1rem" }}>
                                    <FormControl
                                        onChange={handleSearchChange}
                                        placeholder={
                                            texts?.accomondationsearch || texts?.accomodationresultpagesearchresult
                                        }
                                        value={search}
                                        aria-label="Default"
                                        aria-describedby="inputGroup-sizing-sm"
                                    />
                                </div>
                            </div>
                        )}
                        <div className="row">
                            {filtersToDisplay.map(filter => (
                                <div
                                    className="col-lg-3 col-md-6 col-xs-12 filter__more-filters-item"
                                    key={"reservation-filter" + filter.code}
                                >
                                    {renderFilter(filter)}
                                </div>
                            ))}
                        </div>
                    </div>
                </Accordion.Collapse>

                {selectedFilters.length > 0 && (
                    <div className="filter__active-filters-container container">
                        <div className="filter__active-filters-label">Valda filter:</div>
                        {selectedFilters.map(selectedFilter => {
                            return (
                                <div
                                    className="filter__active-filters-item"
                                    key={`${selectedFilter.key}_${selectedFilter.value}`}
                                >
                                    {selectedFilter.label}
                                    <Button onClick={() => removeActiveFilter(selectedFilter)}>
                                        <span className="sr-only">Ta bort filtrering för {selectedFilter.label}</span>
                                        <Icon name="FaTimes" size={10} color="#FFF" />
                                    </Button>
                                </div>
                            );
                        })}
                    </div>
                )}
            </Accordion>
        </div>
    );
};

export default Filter;
