import React, { useState } from "react";
import { TGuest, TReservation, TUser } from "../../store/types";
import { Button, CommaList, Heading, PenIcon, Textarea, style } from "@r360/library";
import { setCheckoutCartNote } from "../../store/actions/checkoutCart";
import useAppDispatch from "../../hooks/useAppDispatch";
import { optionsSelector } from "../../Selectors";
import useTranslate from "../../hooks/useTranslate";
import useAppSelector from "../../hooks/useAppSelector";

type TBookingSummaryContactInfo = {
    primaryGuest: TUser;
    responsibleGuest: TGuest | TUser;
    guests: TGuest[];
    booking?: TReservation;
};

export const BookingSummaryContactInfo = ({
    primaryGuest,
    responsibleGuest,
    guests,
    booking,
}: TBookingSummaryContactInfo) => {
    const dispatch = useAppDispatch();
    const t = useTranslate();
    const { isDesktopNewDesign } = useAppSelector(state => state.window);
    const allowReservationNote = useAppSelector(optionsSelector).reservation?.allow_reservation_note || false;
    const savedNote = useAppSelector(state => state.checkoutCart.note);
    const [editComment, setEditComment] = useState(false);
    const [note, setNote] = useState(savedNote);

    const guestsArray = Object.values(guests);
    const hasGuests = guestsArray.length > 0;
    const primaryAndResponsibleGuestAreTheSame = primaryGuest.id === responsibleGuest.id;

    const getBookingStatus = (reservation: TReservation) => {
        let status = "",
            color = "";

        if (reservation.cancelled) {
            status = t("book.my_pages.cancelled");
            color = "dark-grey";
        } else {
            const amountToPay = reservation.reservationrules.totamounttopay;

            if (amountToPay == 0) {
                status = t("book.my_pages.payed");
                color = "success";
            }

            if (amountToPay > 0) {
                status = t("book.my_pages.unpaid");
                color = "alert";
            }

            if (amountToPay < 0) {
                status = t("book.my_pages.contact_resort");
                color = "yellow";
            }
        }

        return {
            status,
            element: <span className={`u-color-${color}`}>{status}</span>,
        };
    };

    const handleSaveNote = () => {
        dispatch(setCheckoutCartNote(note));
        setEditComment(false);
    };

    const handleDeleteNote = () => {
        dispatch(setCheckoutCartNote(""));
        setEditComment(false);
        setNote("");
    };

    return (
        <section className="u-bg-white u-p-24 u-br-8">
            <div className="row">
                <div className="col-12 col-lg-6 u-d-flex u-flex-column u-gap-6 u-mb-6">
                    {booking && (
                        <div>
                            <span className="u-fw-medium">Status: </span>
                            {getBookingStatus(booking).element}
                        </div>
                    )}
                    <div>
                        <span className="u-fw-medium">{t("book.checkout.booking_guest")}:</span>
                        {` ${primaryGuest?.firstname} ${primaryGuest?.lastname}`}
                    </div>
                    {!primaryAndResponsibleGuestAreTheSame && (
                        <div>
                            <span className="u-fw-medium">{t("book.checkout.responsible_guest")}:</span>
                            {` ${responsibleGuest?.firstname} ${responsibleGuest?.lastname}`}
                        </div>
                    )}
                    {hasGuests && (
                        <div>
                            <span className="u-fw-medium">{t("book.general.guests")}:</span>
                            <CommaList
                                array={guestsArray
                                    .sort((x, y) =>
                                        x.id === responsibleGuest.id ? -1 : y.id === responsibleGuest.id ? 1 : 0
                                    )
                                    .map(guest => ` ${guest.firstname} ${guest.lastname}`)}
                                preLastPhrase="och"
                                fullStop={false}
                            />
                        </div>
                    )}
                </div>
                <div
                    className="col-12 col-lg-6 u-d-flex u-flex-column u-gap-6 u-pl-lg-24"
                    style={{ borderLeft: isDesktopNewDesign ? `1px solid ${style.greyColor}` : 0 }}
                >
                    {!primaryAndResponsibleGuestAreTheSame && (
                        <Heading type="h2" styleAs="h6" className="u-mb-6">
                            {t("book.checkout.booking_guest_contact")}
                        </Heading>
                    )}
                    <div>
                        <span className="u-fw-medium">{t("book.account.phone")}:</span>
                        {` ${primaryGuest?.phone}`}
                    </div>
                    {primaryGuest.email && (
                        <div>
                            <span className="u-fw-medium">{t("book.general.email")}:</span>
                            {` ${primaryGuest?.email}`}
                        </div>
                    )}
                    {primaryGuest.addr1 && (
                        <div>
                            <span className="u-fw-medium">{t("book.account.address")}:</span>
                            {` ${primaryGuest?.addr1}, ${primaryGuest?.addr3} ${primaryGuest?.addr4}`}
                        </div>
                    )}
                    {!primaryAndResponsibleGuestAreTheSame && (
                        <div className="u-pt-18">
                            <Heading type="h2" styleAs="h6" className="u-mb-6">
                                {t("book.checkout.responsible_guest_contact")}
                            </Heading>
                            <div>
                                <span className="u-fw-medium">{t("book.account.phone")}:</span>
                                {` ${responsibleGuest?.phone}`}
                            </div>
                            {responsibleGuest.email && (
                                <div>
                                    <span className="u-fw-medium">{t("book.general.email")}:</span>
                                    {` ${responsibleGuest?.email}`}
                                </div>
                            )}
                            {responsibleGuest.addr1 && (
                                <div>
                                    <span className="u-fw-medium">{t("book.account.address")}:</span>
                                    {` ${responsibleGuest?.addr1}, ${responsibleGuest?.addr3} ${responsibleGuest?.addr4}`}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
            {allowReservationNote && (
                <div className="row u-pt-24">
                    <div className="col-12 col-lg-6">
                        {editComment ? (
                            <>
                                <div className="u-mb-12">
                                    <Textarea value={note} onChange={e => setNote(e.target.value)} />
                                </div>
                                <div className="u-d-flex u-gap-12">
                                    <Button type="tertiary" onClick={handleDeleteNote} disabled={!note}>
                                        {t("book.general.comment.delete")}
                                    </Button>
                                    <Button type="tertiary" onClick={() => setEditComment(false)}>
                                        {t("book.general.cancel")}
                                    </Button>
                                    <Button type="tertiary" onClick={handleSaveNote} disabled={!note}>
                                        {t("book.general.comment.save")}
                                    </Button>
                                </div>
                            </>
                        ) : savedNote ? (
                            <>
                                <p className="u-mb-6">
                                    <span className="u-fw-medium">{t("book.general.comment")}: </span>
                                    {savedNote}
                                </p>
                                <Button type="tertiary" onClick={() => setEditComment(true)} leftIcon={<PenIcon />}>
                                    {t("book.general.comment.edit")}
                                </Button>
                            </>
                        ) : (
                            <Button type="tertiary" onClick={() => setEditComment(true)} leftIcon={<PenIcon />}>
                                {t("book.checkout.add_comment")}
                            </Button>
                        )}
                    </div>
                </div>
            )}
        </section>
    );
};
