import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { registerNewUser, resetApiMessages, setUnregisteredUser } from "../../../store/actions/account";
import { setSignUpWrapper } from "../../../store/actions/window";
import { validateMobilePhoneNumber } from "../../../ValidationUtils";
import PhoneCountryCodeSelect from "../../PhoneCountryCodeSelect/OldPhoneCountryCodeSelect";
import { Alert, Checkbox } from "../../UI";
import "./Registration.scss";
import { textsSelector } from "../../../Selectors";

// User registration component
const Registration = props => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const wrapperRef = useRef(null);
    const phoneNumberInputRef = useRef(null);
    const client = useSelector(state => state.clientData);
    const country = useSelector(state => state.clientData.country);
    const texts = useSelector(textsSelector);
    const newUser = useSelector(state => state.account.newUser);
    const account = useSelector(state => state.account);
    const failedToCreateNewUser = useSelector(state => state.account.failedToCreateNewUser);
    const currentPath = useSelector(state => state.window.signUpWrapper.pathname);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [validated, setValidated] = useState(false);
    const [isSameAsPassword, setSameAsPassword] = useState(true);
    const invalidClass = !isSameAsPassword ? " registration__input--invalid" : "";

    let redirectTo;

    if (props.redirectPath) {
        redirectTo = props.redirectPath;
    } else if (currentPath) {
        redirectTo = currentPath;
    } else {
        redirectTo = "/";
    }

    // Remove error messages
    const removeErrorMessages = useCallback(() => {
        dispatch(resetApiMessages()); // Remove retrieve password message
    }, [dispatch]);

    useEffect(() => {
        removeErrorMessages();
    }, [removeErrorMessages]);

    useEffect(() => {
        if (account?.loginSuccessful) {
            if (redirectTo) {
                if (!props.registrationPage) {
                    dispatch(setSignUpWrapper(false, false, false));
                }
                navigate(redirectTo);
            } else {
                window.location.reload();
            }
        }
    }, [dispatch, navigate, account, redirectTo, props.registrationPage]);

    // Close menu on click outside of container
    // useEffect(() => {
    //   document.addEventListener("mousedown", (event) => {
    //     if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
    //       removeErrorMessages();
    //     }
    //   });
    // }, [wrapperRef]);

    // Update unregistered user info on input change
    const didChange = (event, field = "") => {
        if (field === "phone3") {
            didChangePhoneNumber(newUser.phone3prefix, event.target.value);
        } else if (field === "phone3prefix") {
            didChangePhoneNumber(event.target.value, newUser.phone3);
        }

        dispatch(setUnregisteredUser(field !== "" ? field : event.target.name, event.target.value));
    };

    /**
     * The phone number was changed in some way, either the prefix or the number part
     *
     * @param {string} phonePrefix
     * @param {string} phoneNumber
     */
    const didChangePhoneNumber = (phonePrefix, phoneNumber) => {
        const valid = validateMobilePhoneNumber(phonePrefix, phoneNumber);

        // Always update the phone number field, even if only the prefix was changed.
        // The number-part always depends on the selected prefix.
        phoneNumberInputRef.current.setCustomValidity(valid ? "" : "Invalid phone number");
    };

    // Set state on terms checkbox change
    const onTermsInputChanged = ({ value }) => {
        setTermsAccepted(value);
    };

    const alertView = () => {
        const errors = failedToCreateNewUser?.data?.map(item => {
            if (item.data && Object.keys(item.data).length > 0) {
                return item.data;
            }
            return false;
        });

        return (
            <Alert type="warning">
                <ul>
                    {errors?.length > 0 &&
                        errors.map(error => {
                            return Object.values(error).map((item, index) => {
                                return <li key={index}>{texts?.[item] || texts?.emailaddressisalreadyinuse}</li>;
                            });
                        })}
                    {!isSameAsPassword && <li>{texts?.notmatchingpasswords || texts?.repetitionpasswordmustmatch}</li>}
                </ul>
            </Alert>
        );
    };

    // Handle submit
    const handleSubmit = event => {
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);

        const form = event.currentTarget;
        const birthdayTest = Object.entries(newUser).filter(([key, value]) => {
            if (!["year", "month", "day"].includes(key) && !value) {
                return true;
            }

            return false;
        });

        checkPassword();

        // if (newUser.phone3prefix || newUser.phone3) {
        //     const mobilePhoneNumberIsValid = validateMobilePhoneNumber(newUser.phone3prefix, newUser.phone3);
        //     console.log("mobilePhoneNumberIsValid", mobilePhoneNumberIsValid, newUser.phone3prefix, newUser.phone3);
        //     if (!mobilePhoneNumberIsValid) {
        //         return false;
        //     }
        // }

        if (birthdayTest.length > 0) {
            return false;
        }

        if (!isSameAsPassword) {
            return false;
        }

        if (form.checkValidity() === true) {
            dispatch(registerNewUser(newUser));
        }
    };

    // Check that password and repeated password are the same
    const checkPassword = () => {
        if (newUser.repeatpassword !== newUser.password) {
            setSameAsPassword(false);
        } else {
            setSameAsPassword(true);
        }
    };

    return (
        <div className="registration__wrapper" ref={wrapperRef}>
            <div className="text-center">
                <h1 className="h1 page-header login__header">{texts?.signup}</h1>
            </div>
            {(failedToCreateNewUser?.error || !isSameAsPassword) && alertView()}
            <Form
                className="registration__form"
                noValidate
                validated={validated}
                onSubmit={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleSubmit(e);
                }}
            >
                <div>
                    <label htmlFor="formGroupFirstName" className="sr-only">
                        {texts?.generalfirstname}
                    </label>
                    <input
                        required
                        name="firstname"
                        id="formGroupFirstName"
                        value={newUser?.name1 || ""}
                        className="form-control registration__input--curved-top"
                        type="text"
                        placeholder={texts?.generalfirstname}
                        onChange={e => {
                            didChange(e, "name1");
                        }}
                    />
                </div>
                <div>
                    <label htmlFor="formGroupLastName" className="sr-only">
                        {texts?.generallastname}
                    </label>
                    <input
                        required
                        name="lastname"
                        id="formGroupLastName"
                        value={newUser?.name2 || ""}
                        className="form-control registration__input--square"
                        type="text"
                        placeholder={texts?.generallastname}
                        onChange={e => {
                            didChange(e, "name2");
                        }}
                    />
                </div>
                <div>
                    <label htmlFor="formGroupEmail" className="sr-only">
                        {texts?.generalemail}
                    </label>
                    <input
                        required
                        name="email"
                        id="formGroupEmail"
                        value={newUser?.email || ""}
                        className="form-control registration__input--square"
                        type="email"
                        placeholder={texts?.generalemail}
                        onChange={e => {
                            didChange(e);
                        }}
                    />
                </div>
                <div>
                    <label htmlFor="formGroupAddress" className="sr-only">
                        {texts?.generaladdress1}
                    </label>
                    <input
                        required
                        name="address"
                        id="formGroupAddress"
                        value={newUser?.addr1 || ""}
                        className="form-control registration__input--square"
                        type="text"
                        placeholder={texts?.generaladdress1}
                        onChange={e => {
                            didChange(e, "addr1");
                        }}
                    />
                </div>
                <div>
                    <label htmlFor="formGroupZipCode" className="sr-only">
                        {texts?.generalzipcode}
                    </label>
                    <input
                        required
                        name="postalcode"
                        id="formGroupZipCode"
                        value={newUser?.addr3 || ""}
                        className="form-control registration__input--square"
                        type="text"
                        placeholder={texts?.generalzipcode}
                        onChange={e => {
                            didChange(e, "addr3");
                        }}
                    />
                </div>
                <div>
                    <label htmlFor="formGroupCity" className="sr-only">
                        {texts?.generalcity}
                    </label>
                    <input
                        required
                        name="city"
                        id="formGroupCity"
                        value={newUser?.addr4 || ""}
                        className="form-control registration__input--square"
                        type="text"
                        placeholder={texts?.generalcity}
                        onChange={e => {
                            didChange(e, "addr4");
                        }}
                    />
                </div>
                <div>
                    <label htmlFor="formGroupCountry" className="sr-only">
                        {texts?.generalcountry}
                    </label>
                    <select
                        required
                        className="form-control registration__input-select registration__input--square old-select"
                        name="country"
                        placeholder={texts?.generalcountry}
                        type="text"
                        value={newUser?.addr5 || ""}
                        onChange={e => {
                            didChange(e, "addr5");
                        }}
                        id="formGroupCountry"
                    >
                        <option disabled value="">
                            {texts?.generalcountry}
                        </option>
                        {country &&
                            country.map((country, index) => {
                                return (
                                    <option value={country.code} key={index}>
                                        {country.name}
                                    </option>
                                );
                            })}
                    </select>
                </div>

                <div className="registration__input-prefix-wrapper">
                    <label htmlFor="formGroupPhonePrefix" className="sr-only">
                        {texts?.generalphoneprefix || "Phone prefix"}
                    </label>

                    <PhoneCountryCodeSelect
                        required
                        name="tel-country-code"
                        id="formGroupPhonePrefix"
                        placeholder={texts?.generalphoneprefix}
                        othersLabel="Other countries"
                        value={newUser?.phone3prefix || ""}
                        onChange={event => {
                            didChange(event, "phone3prefix");
                        }}
                        className="form-control registration__input-select registration__input--square old-select"
                    />
                </div>

                <div className="registration__input-phone-wrapper">
                    <label htmlFor="formGroupPhone" className="sr-only">
                        {texts?.generalphonenumber}
                    </label>
                    <input
                        required
                        ref={phoneNumberInputRef}
                        name="tel-national"
                        id="formGroupPhone"
                        value={newUser?.phone3 || ""}
                        className="form-control registration__input--square"
                        type="tel"
                        placeholder={texts?.generalphonenumber}
                        onChange={e => {
                            didChange(e, "phone3");
                        }}
                    />
                </div>
                <div>
                    <label htmlFor="formGroupPassword" className="sr-only">
                        {texts?.generalpassword}
                    </label>
                    <input
                        required
                        name="password"
                        id="formGroupPassword"
                        value={newUser?.password || ""}
                        className="form-control registration__input--square"
                        type="password"
                        placeholder={texts?.generalpassword}
                        onChange={e => {
                            didChange(e);
                        }}
                    />
                </div>
                <div>
                    <label htmlFor="formGroupPasswordAgain" className="sr-only">
                        {texts?.confirmpassword}
                    </label>
                    <input
                        required
                        autoComplete="off"
                        name="repeatpassword"
                        id="formGroupPasswordAgain"
                        value={newUser?.repeatpassword || ""}
                        className={`form-control registration__input--square${invalidClass}`}
                        type="password"
                        placeholder={texts?.confirmpassword}
                        onChange={e => {
                            didChange(e);
                        }}
                        onBlur={checkPassword}
                    />
                </div>
                <Button
                    className="button--full-width login__submit"
                    variant="primary"
                    type="submit"
                    disabled={client?.options?.reservation?.terms_url ? !termsAccepted : false}
                >
                    {texts?.createaccount}
                </Button>
            </Form>
            <div className="registration__terms">
                {client?.options?.reservation?.terms_url && (
                    <>
                        <Checkbox
                            hideLabel
                            id="termsAccepted"
                            name="termsAccepted"
                            label={texts?.termsandconditions}
                            checked={termsAccepted}
                            onChange={onTermsInputChanged}
                        />
                        <p>
                            {texts?.approvetermstext}{" "}
                            <a
                                target="_blank"
                                href={client?.options?.reservation?.terms_url || ""}
                                rel="noopener noreferrer"
                            >
                                {texts?.readtermsandconditions}
                            </a>
                        </p>
                    </>
                )}
            </div>

            <div className="login__create">
                {texts?.alreadyhaveaccount}{" "}
                <Button
                    className="login__create-link"
                    variant="link"
                    onClick={() => {
                        removeErrorMessages();
                        if (props.registrationPage) {
                            navigate(`/user/login?path=${redirectTo}`);
                        } else {
                            dispatch(setSignUpWrapper(true, true, false, redirectTo));
                        }
                    }}
                >
                    {texts?.login}
                </Button>
            </div>
        </div>
    );
};

export default Registration;
