import classnames from "classnames";
import cloneDeep from "lodash/cloneDeep";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { Col, OverlayTrigger, Popover, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Accommodation as AccommodationImage } from "../../assets/reservationTypes";
import {
    hasBookstat,
    isAccommodation,
    isBooked,
    isCancelled,
    isParent,
    setAdditionalOnBookedAccommodationProduct,
    showArrDepDates,
} from "../../BusinessUtils";
import * as Constants from "../../Constants";
import { formatPrice, getPackageProductTitle } from "../../Helper";
import { agesSelector, optionsSelector } from "../../selectors/Selectors";
import { createCartFromReservation, emptyCartProducts } from "../../store/actions/checkoutCart";
import * as types from "../../store/actions/types";
import CancelProductButton from "../CancelProductButton/CancelProductButton";
import { Button, CardImage, Icon, Modal } from "../UI";
import { CalendarIcon, GuestIcon } from "../UI/R360Icons";
import AccommodationDetails from "../Utilities/AccommodationDetails/AccommodationDetails";
import "./AccommodationList.scss";

const AccommodationList = ({
    uniqueProductTypes = [],
    texts,
    products,
    currency,
    booking,
    checkoutSummary,
    setRenderedProduct,
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [selectedProduct, setSelectedProduct] = useState();
    const [didClickAddExtras, setDidClickAddExtras] = useState(false);
    const userToken = useSelector(state => state.account.token);
    const cartCreatedFromReservation = useSelector(state => state.checkoutCart.createdFromReservation);
    const reservationTypes = useSelector(state => state.clientData.reservationtypes);
    const clientData = useSelector(state => state.clientData);
    const generalAges = useSelector(agesSelector);
    const [showMoreInformationModal, setShowMoreInformationModal] = useState(false);
    const skipGuestsOnAccommodation = useSelector(optionsSelector).reservation?.skipguestsonaccommodation || false;
    const accommodationParentProducts = Object.values(cloneDeep(products) || {})
        .filter(product => isAccommodation(product) && isParent(product))
        .map(product => {
            product.children = product.children
                .map(childId => products[childId])
                .filter(child => !!child)
                .map(setAdditionalOnBookedAccommodationProduct);
            return product;
        })
        .sort((a, b) => (hasBookstat(b) ? 1 : 0) - (hasBookstat(a) ? 1 : 0));

    useEffect(() => {
        dispatch({ type: types.CLEAR_ERRORS });
    }, [dispatch]);

    useEffect(() => {
        if (didClickAddExtras && cartCreatedFromReservation) {
            navigate("/cart");
        }
    }, [cartCreatedFromReservation, didClickAddExtras, navigate]);

    // Change a reservation.
    const changeReservation = () => {
        const resvid = booking?.reservation?.resvid;

        if (!resvid) {
            return;
        }

        dispatch(emptyCartProducts());
        dispatch(createCartFromReservation(resvid, userToken, reservationTypes, clientData?.cookie));
    };

    const bookedAccommodationHasNewOrModifiedExtras = product => {
        return (
            isBooked(product) &&
            product.children.some(child => !isCancelled(child) && (!isBooked(child) || child.additional))
        );
    };

    /**
     * Get ages for a reservation type.
     *
     * @param {string} reservationTypeId The reservation type ID.
     *
     * @returns {Object[]} A list of ages objects.
     */
    const getAgesForReservationType = reservationTypeId => {
        if (!generalAges[reservationTypeId]) {
            throw new Error(`Reservation type ${reservationTypeId} does not have any ages configured in general ages`);
        }

        return generalAges[reservationTypeId];
    };

    const getDepDateHasPassed = product => (product?.depdate ? moment().isAfter(product.depdate, "day") : true);

    const verboseGuestsByAges = accommodation =>
        (getAgesForReservationType(accommodation.type) || [])
            .map(age => {
                const guestCount = (Object.values(accommodation.guestages || {}) || []).filter(
                    guestAge => guestAge >= age.min && guestAge <= age.max
                ).length;

                return [age, guestCount];
            })
            .filter(([, guestCount]) => guestCount > 0)
            .map(([age, guestCount]) => `${guestCount} ${texts?.[age.title]}`);

    // Get output based on product type
    const renderProduct = product => {
        // Handle click — prevent block link navigation and show more information modal
        const handleShowMoreInformationClick = (event, product) => {
            event.stopPropagation();
            event.preventDefault();
            setShowMoreInformationModal(!showMoreInformationModal);
            setSelectedProduct(product);
            document.body.click();
        };

        let additions = [],
            additionPrices = [],
            additionsTotalPrice = 0;

        // Calculate total price for all child products that belong to this product
        if (product.children) {
            additions = product.children;
            if (additions && additions.length) {
                additionPrices = additions.map(item => {
                    if (item.mandatory_web) {
                        return 0;
                    } else {
                        return item.price;
                    }
                });

                additionsTotalPrice =
                    additionPrices.length > 0 ? additionPrices.reduce((accumulator, value) => accumulator + value) : 0;
            }
        } else {
            additions = [];
        }

        console.log(product.children);

        // Create a new array with total numbers of guests and get its length.
        let totalGuests = 0;
        if (skipGuestsOnAccommodation || Object.values(product.guests || {}).length === 0) {
            totalGuests = Object.values(product.guestages || {}).length;
        } else {
            totalGuests = Object.values(product.guests || {}).length;
        }

        const connectedProducts = booking
            ? Object.values(products).filter(item => isBooked(item) && item.connectedProductId === product.id)
            : [];

        // Total guests dropdown menu code
        const renderTotalGuestsDropDown = () => {
            return (
                <OverlayTrigger
                    trigger="click"
                    placement="bottom"
                    rootClose={true}
                    overlay={
                        <Popover>
                            <Popover.Body>
                                <ul className="accommodationList-item__totalGuestsDropDownButton-list">
                                    {skipGuestsOnAccommodation || Object.values(product.guests || {}).length === 0
                                        ? verboseGuestsByAges(product).map((row, index) => <li key={index}>{row}</li>)
                                        : Object.values(product.guests).map(guest => {
                                              return (
                                                  <li key={guest.id}>
                                                      {guest.firstname} {guest.lastname}
                                                  </li>
                                              );
                                          })}
                                </ul>
                            </Popover.Body>
                        </Popover>
                    }
                >
                    <a className="link" onClick={renderTotalGuestsDropDownHandleClick}>
                        {totalGuests}
                        {totalGuests === 1 ? ` ${texts?.generalguest}` : ` ${texts?.generalguests}`}
                        <Icon
                            name="FaCaretDown"
                            size={12}
                            color="black"
                            className="accommodationList-item__dropDownIcon"
                        />
                    </a>
                </OverlayTrigger>
            );
        };

        const renderTotalGuestsDropDownHandleClick = event => {
            event.stopPropagation();
            event.preventDefault();
        };

        const handleAddExtras = () => {
            changeReservation();
            setDidClickAddExtras(true);
        };

        const renderAddition = (addition, preferAdditional = false) => {
            let title = addition.title;
            let showPrice = true;

            if (addition.mandatory_web && !addition.showpriceweb) {
                showPrice = false;
            }

            const quantity =
                preferAdditional && addition.additional?.quantity ? addition.additional?.quantity : addition.quantity;

            const price =
                preferAdditional && addition.additional?.price !== undefined
                    ? addition.additional?.price
                    : addition.price;

            setRenderedProduct?.(addition.id);

            return (
                <li key={addition.id}>
                    <span
                        className={classnames({
                            "soft-disabled": isCancelled(addition),
                        })}
                    >
                        <b>{quantity} x </b>
                        {title}
                    </span>

                    {addition.mandatory_web && ` (${texts.generalincluded})`}

                    {showPrice && (!addition.bookstat || addition.bookstat === "B") && (
                        <span className="itemcost">
                            ({price} {currency})
                        </span>
                    )}
                    {addition.bookstat === "C" && (
                        <span
                            className="itemcancelled"
                            style={{
                                opacity: "0.4",
                                pointerEvents: "none",
                            }}
                        >{`(${texts.cancelled})`}</span>
                    )}
                </li>
            );
        };

        const renderProductDetails = () => {
            return (
                <>
                    {(checkoutSummary || isBooked(product)) && (
                        <>
                            {setRenderedProduct?.(product.id)}
                            {cartCreatedFromReservation && !isBooked(product) && (
                                <Row>
                                    <Col>
                                        <h6 className="h6">{texts["checkoutsummary.new_in_change_reservation"]}</h6>
                                    </Col>
                                </Row>
                            )}
                            <Row className="accommodations" key={product.id}>
                                <Col md={4} sm={4}>
                                    <CardImage
                                        image={product?.mainimage?.url}
                                        placeholder={AccommodationImage}
                                        liked={false}
                                        showLike={false}
                                        height="204px"
                                    />
                                </Col>
                                <Col md={8} sm={8}>
                                    <Row style={{ paddingTop: "1rem" }}>
                                        <Col md={8} xs={6}>
                                            <Row>
                                                <h6 className="accommodationList-item__accommodation-item-header">
                                                    {getPackageProductTitle(product) || product.pooldesc3}
                                                </h6>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Button
                                                        text={texts["checkoutsummary.accommodation.moreinformation"]}
                                                        className="accommodationList-item__moreInformationButton"
                                                        onClick={event =>
                                                            handleShowMoreInformationClick(event, product)
                                                        }
                                                        hasPopup={true}
                                                        expanded={showMoreInformationModal}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={4} xs={6}>
                                            <Row>
                                                <Col md={booking ? 10 : 12}>
                                                    <h6 className="accommodationList-item__price">
                                                        {formatPrice(product.price + additionsTotalPrice, currency)}
                                                    </h6>
                                                </Col>
                                                {booking && (
                                                    <Col md={2} className="text-end">
                                                        <CancelProductButton
                                                            resvid={booking.resvid}
                                                            detlid={product.detlid}
                                                            productTitle={product.title}
                                                            connectedProducts={connectedProducts}
                                                            confirmationTitle={
                                                                texts[
                                                                    "bookingview.cancel_accommodation_confirmation.title"
                                                                ]
                                                            }
                                                            confirmationDescription={
                                                                texts[
                                                                    "bookingview.cancel_accommodation_confirmation.description"
                                                                ]
                                                            }
                                                            allowCancellation={booking.allowcancellation}
                                                        />
                                                    </Col>
                                                )}
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={4} className="accommodationList-item__icons-dates">
                                            <CalendarIcon
                                                size={19}
                                                color="#333333"
                                                iconClass="accommodationList-item__icons-dates-icon"
                                            />
                                            <span>
                                                {/* Show arrival/departure dates with correct formatting by reservation type */}
                                                {showArrDepDates(product)}
                                            </span>
                                        </Col>
                                        <Col md={4} className="accommodationList-item__icons-guests">
                                            <GuestIcon
                                                size={19}
                                                color="#333333"
                                                iconClass="accommodationList-item__icons-guests-guestIcon"
                                            />
                                            {<span>{renderTotalGuestsDropDown()}</span>}
                                        </Col>
                                        <Col md={4}>
                                            {booking && !getDepDateHasPassed(product) && (
                                                <div className="accommodationList-item-buttonalignment">
                                                    <Button
                                                        className="accommodationList-item__additionalExtras"
                                                        onClick={handleAddExtras}
                                                    >
                                                        {texts?.additionalextras}
                                                    </Button>
                                                </div>
                                            )}
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={9} sm={12}>
                                            {additions.length > 0 && (
                                                <>
                                                    <ul className="additions">
                                                        {[
                                                            ...additions.filter(
                                                                addition =>
                                                                    !addition.bookstat || addition.bookstat === "B"
                                                            ),
                                                            ...additions.filter(addition => addition.bookstat === "C"),
                                                        ].map(addition => renderAddition(addition))}
                                                    </ul>
                                                    {cartCreatedFromReservation &&
                                                        bookedAccommodationHasNewOrModifiedExtras(product) && (
                                                            <>
                                                                <h6 className="h6">
                                                                    {
                                                                        texts[
                                                                            "checkoutsummary.of_which_new_in_change_reservation"
                                                                        ]
                                                                    }
                                                                </h6>
                                                                <ul className="additions">
                                                                    {additions
                                                                        .filter(
                                                                            addition =>
                                                                                !isCancelled(addition) &&
                                                                                (!isBooked(addition) ||
                                                                                    addition.additional)
                                                                        )
                                                                        .map(addition =>
                                                                            renderAddition(addition, true)
                                                                        )}
                                                                </ul>
                                                            </>
                                                        )}
                                                </>
                                            )}
                                            {connectedProducts.length > 0 && (
                                                <>
                                                    <h6 className="h6 u-mt-6">
                                                        {texts["checkoutsummary.connected_products"]}
                                                    </h6>
                                                    <ul
                                                        style={{
                                                            listStyleType: "none",
                                                            paddingLeft: "0px",
                                                            fontSize: "14px",
                                                        }}
                                                    >
                                                        {connectedProducts.map(connectedProduct => (
                                                            <li
                                                                key={connectedProduct.detlid}
                                                            >{`${connectedProduct.kinddesc} (${connectedProduct.title})`}</li>
                                                        ))}
                                                    </ul>
                                                </>
                                            )}
                                        </Col>
                                    </Row>
                                </Col>
                                <Modal
                                    show={showMoreInformationModal && selectedProduct.id === product.id}
                                    setShow={setShowMoreInformationModal}
                                    size="lg"
                                >
                                    <AccommodationDetails
                                        weblong={product?.weblong ? product?.weblong : ""}
                                        title={getPackageProductTitle(product)}
                                        images={product?.images}
                                        texts={texts}
                                        product={product}
                                    />
                                </Modal>
                            </Row>
                        </>
                    )}
                </>
            );
        };

        return renderProductDetails();
    };

    return (
        <div className="box accommodationList">
            {uniqueProductTypes
                .filter(productType => productType.type === Constants.productTypeNames.ACCOMMODATION)
                .filter(productType => {
                    return accommodationParentProducts
                        .filter(product => product.type === productType.lid)
                        .filter(product => checkoutSummary || isBooked(product)).length;
                })
                .map(productType => {
                    return (
                        <div className="accommodationList__section-wrapper" key={productType.lid}>
                            <Row>
                                <span className="box-head accommodationList__section-head">
                                    <h2 className="box-heading accommodationList__section-header">
                                        {productType.description}
                                    </h2>
                                </span>
                            </Row>
                            {accommodationParentProducts
                                .filter(product => product.type === productType.lid)
                                .filter(product => checkoutSummary || isBooked(product))
                                .map((product, index) => {
                                    return <React.Fragment key={index}>{renderProduct(product)}</React.Fragment>;
                                })}
                        </div>
                    );
                })}
        </div>
    );
};

export default AccommodationList;
