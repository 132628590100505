import { Skeleton, SkeletonGroup } from "@r360/library";
import React from "react";

// width: 100%;
// height: 400px;
// position: relative;
// margin: 36px 0;

export const AccommodationMapSkeleton = () => {
    return (
        <SkeletonGroup>
            <div className="u-bg-white u-br-6 u-overflow-hidden u-mb-36">
                <Skeleton height="400px" borderRadius="0" />
            </div>
        </SkeletonGroup>
    );
};
