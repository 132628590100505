import { TCartItem, TCheckoutCart, TGeneralAges, TGuest, TReservationType } from "../store/types";
import * as Constants from "../Constants";
import {
    TBookingSummaryRowAddition,
    TBookingSummaryRowGuests,
} from "../components/BookingSummary/BookingSummaryTable/BookingSummaryTable";
import {
    hasAnyGuestCategoryPrice,
    hasDynamicPrices,
    hasSamePriceInAllGuestCategories,
    isSeasonPass,
    showArrDepDates,
} from "../BusinessUtils";
import moment from "moment";
import { TSelectOption } from "@r360/library";
import { useState } from "react";
import useTranslate from "./useTranslate";
import { agesSelector } from "../selectors/Selectors";
import useAppSelector from "./useAppSelector";

export const useBookingSummary = (products: { [key: string]: TCartItem }) => {
    const t = useTranslate();
    const generalAges: TGeneralAges = useAppSelector(agesSelector);
    const reservationTypes: TReservationType[] = useAppSelector(state => state.clientData.reservationtypes);
    const checkoutCart = useAppSelector(state => state.checkoutCart) as TCheckoutCart;
    const currency = useAppSelector(state => state.clientData?.options?.general?.currency);
    const [filterByGuest, setFilterByGuest] = useState<TSelectOption | null>(null);

    const handleFilterByGuest = (option: TSelectOption) => {
        if (option.value === "showAll") {
            setFilterByGuest(null);
        } else {
            setFilterByGuest(option);
        }
    };

    const resetFilterByGuest = () => {
        setFilterByGuest(null);
    };

    const getProductTitle = (
        product: TCartItem,
        minAge: number | undefined = undefined,
        maxAge: number | undefined = undefined
    ) => {
        switch (product.kind) {
            case Constants.productTypeNames.SKIPASS:
                if (product.groupTitle) {
                    return `${product.groupTitle} - ${product.title}`;
                }

                if (product.minage && product.maxage) {
                    return `${product.title} (${product.minage} - ${product.maxage} ${t("book.general.years")})`;
                }

                return product.title;

            case Constants.productTypeNames.RENTAL:
                return `${product.pooldesc2 ?? product.title}`;
            case Constants.productTypeNames.ACTIVITY:
                // Include min and max age if dealing with an activity with different prices for different age-categories
                return minAge && maxAge
                    ? `${product.pooldesc2 ?? product.title} (${minAge} - ${maxAge} ${t("book.general.years")})`
                    : `${product.pooldesc2 ?? product.title}`;

            default:
                return product.title;
        }
    };

    const getProductChildren = (children: { [key: string]: number }) => {
        return Object.values(children).map(child => {
            const childProduct = products[child];

            if (!childProduct) {
                return null;
            }

            return childProduct;
        });
    };

    const getProductAdditions = (product: TCartItem) => {
        const additions: TBookingSummaryRowAddition[] = [];

        switch (product.kind) {
            case Constants.productTypeNames.LETTING:
                if (product.price === product.priceInsurance) {
                    // If we arrive here, the insurance is included in the product price. We need to get the original price from the cart in order to show the price of the insurance
                    const productInCart = checkoutCart.products.find(
                        cartProduct => (cartProduct.original_id ?? cartProduct.id) === product.original_id
                    );

                    additions.push(
                        {
                            title: reservationTypes?.find(resvType => resvType.lid === product.type)?.description ?? "",
                            quantity: 1,
                            price: productInCart?.price ?? 0,
                            showPrice: true,
                            currency,
                            canceled: false,
                            isProduct: true,
                        },
                        {
                            title: t("book.general.insurance"),
                            quantity: 1,
                            price: product.price - (productInCart?.price ?? 0),
                            showPrice: true,
                            currency,
                            canceled: false,
                        }
                    );
                } else if (product.insurance) {
                    additions.push({
                        title: t("book.general.insurance"),
                        quantity: 1,
                        price: 0,
                        showPrice: false,
                        currency,
                        canceled: false,
                    });
                }
                break;
            case Constants.productTypeNames.SKIPASS:
                if (product.children.length > 0) {
                    additions.push({
                        title: "Skipass",
                        quantity: 1,
                        price: product.price,
                        showPrice: true,
                        currency,
                        canceled: false,
                        isProduct: true,
                    });
                    getProductChildren(product.children as any).forEach(child => {
                        additions.push({
                            id: child?.id,
                            title: child?.pooldesc2 ?? child?.title ?? "",
                            quantity: 1,
                            price: child?.price ?? 0,
                            showPrice: true,
                            currency,
                            canceled: false,
                        });
                    });
                }
                break;
        }
        return additions;
    };

    const getProductGuests = (product: TCartItem) => {
        switch (product.kind) {
            case Constants.productTypeNames.LETTING:
                return Object.values(product.guests).map(guest => ({
                    id: guest.id,
                    name: `${guest?.firstname} ${guest.lastname}`,
                })) as TBookingSummaryRowGuests[];
            default:
                return Object.values(product.guests).map(guest => ({
                    id: guest.id,
                    name: `${guest?.firstname} ${guest.lastname}`,
                })) as TBookingSummaryRowGuests[];
        }
    };

    const getProductAdditionalInfo = (product: TCartItem) => {
        const additionalInfo: { [key: string]: string } = {};

        switch (product.kind) {
            case Constants.productTypeNames.LETTING: {
                additionalInfo[t("book.general.height")] = `${Object.values(product.guests)[0]?.height} cm`;
                additionalInfo[t("book.general.weight")] = `${Object.values(product.guests)[0]?.weight} kg`;
                additionalInfo[t("book.general.shoesize")] = `${Object.values(product.guests)[0]?.shoesize} (eu)`;

                if (product.note) {
                    additionalInfo[t("book.general.comment")] = `<br/>"${product.note}"`;
                }

                return additionalInfo;
            }

            case Constants.productTypeNames.SKIPASS: {
                if (product.keycardNo) {
                    additionalInfo[t("book.checkout.keycard_number")] = `${product.keycardNo}`;
                }

                return additionalInfo;
            }

            case Constants.productTypeNames.ACTIVITY: {
                if (product.note) {
                    additionalInfo[
                        `${t("book.checkout.comment_for")} ${product.guests[0]?.firstname}`
                    ] = `<br/>"${product.note}"`;
                }

                Object.entries(product.guestNotes)?.forEach(([key, value]) => {
                    additionalInfo[
                        `${t("book.checkout.comment_for")} ${product.guests[key]?.firstname}`
                    ] = `<br/>"${value}"`;
                });

                return additionalInfo;
            }

            default:
                return undefined;
        }
    };

    const getProductTypeLocation = (products: TCartItem[]) => {
        const firstProduct = products[0];

        switch (firstProduct?.kind) {
            case Constants.productTypeNames.LETTING:
            case Constants.productTypeNames.RENTAL:
                return firstProduct.deliveryPoint
                    ? `${t("book.checkout.collect_at")} ${firstProduct.deliveryPoint}`
                    : undefined;
        }
    };

    const getProductDates = (product: TCartItem) => {
        const arrAndDepDate = [showArrDepDates(product)];

        switch (product.kind) {
            case Constants.productTypeNames.ACTIVITY:
                if (product.occations.length > 0) {
                    return product.occations.map(occation => {
                        return `${moment(occation.date).format("ddd D MMM")}, ${occation.fromtime} - ${
                            occation.totime
                        }`;
                    });
                } else {
                    return arrAndDepDate;
                }

            case Constants.productTypeNames.SKIPASS:
                if (isSeasonPass(product)) {
                    return [t("book.cart.item_types.seasoncard")];
                } else {
                    return arrAndDepDate;
                }
            default:
                return arrAndDepDate;
        }
    };

    const getProductPrice = (product: TCartItem, getOrdinaryPrice = false) => {
        let quantity = 0;

        // If package, set quantity to 1 since the price is for all guests
        if (product.kind === Constants.productTypeNames.PACKAGE) {
            quantity = 1;
        } else {
            quantity = product.guests && Object.values(product.guests).length;
        }

        let price = 0;

        const priceToUse = product.aprice ?? product.price;

        if (!product.dynamicprices) {
            price = (getOrdinaryPrice ? product.ordinaryprice : priceToUse) * (quantity || product.quantity || 0);
            // Sum price for every child
            if (product.children.length) {
                price = product.children.reduce((sum, childId) => {
                    // We need to find the child by id and fetch its price to sum it up
                    return (sum += products?.[childId]?.price);
                }, price);
            }
        } else {
            if (product.pricematrix) {
                price = product.pricematrix[Object.values(product.guests).length];
            } else {
                price = product.price;
            }
        }

        return price;
    };

    const isFilteredGuestConnected = (product: TCartItem) => {
        return Object.values(product.guests).some((guest: any) => guest.id === filterByGuest?.value);
    };

    const isActivityWithDifferentGuestages = (product: TCartItem) =>
        !hasDynamicPrices(product) && hasAnyGuestCategoryPrice(product) && !hasSamePriceInAllGuestCategories(product);

    const getProductCategoryPrice = (product: TCartItem) => {
        const priceCategory: "price" | "pricec1" | "pricec2" | "pricec3" | "pricec4" | "pricec5" = `price${
            getGuestAgeCategory(product)?.agecat ?? ""
        }`;

        return product[priceCategory === "pricec5" ? "price" : priceCategory] ?? 0;
    };

    const getGuestAgeCategory = (product: TCartItem) => {
        const agesForReservationType = generalAges[product.type];
        const guest: TGuest = Object.values(product.guests)[0];

        return agesForReservationType.find(cat => cat.min <= guest.age && cat.max >= guest.age);
    };

    return {
        filterByGuest,
        handleFilterByGuest,
        resetFilterByGuest,
        getProductTitle,
        getProductChildren,
        getProductAdditions,
        getProductGuests,
        getProductAdditionalInfo,
        getProductTypeLocation,
        getProductDates,
        getProductPrice,
        isActivityWithDifferentGuestages,
        getProductCategoryPrice,
        getGuestAgeCategory,
        isFilteredGuestConnected,
    };
};
