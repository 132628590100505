import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Activities as ActivitiesImage } from "../../../assets/reservationTypes";
import { getAgeCategories, getDateFormat } from "../../../Helper";
import {
    addProductWithCount,
    removeProduct,
    setLatestChangedProductType,
    updateProductWithCount,
} from "../../../store/actions/checkoutCart";
import ProductPrice from "../../ProductPrice";
import ProductView from "../../ProductView/ProductView";
import { Button, Icon, Incrementer, Modal, ResponsiveImage, Tag } from "../../UI";
import "./ActivitiesItem.scss";
import { textsSelector } from "../../../Selectors";

// Activities item component
const ActivitiesItem = props => {
    const {
        title,
        weblong,
        desc,
        thumbnail,
        price,
        available,
        freesale,
        limittoonereservation,
        index,
        poollid,
        activitylid,
        arrdate,
        category,
        occations,
        type,
        minage,
        maxage,
    } = props.item;

    const [count, setCount] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const dispatch = useDispatch();
    const currency = useSelector(state => state.clientData?.options?.general?.currency);
    const texts = useSelector(textsSelector);
    const countryOfBusiness = useSelector(state => state.clientData.countryOfBusiness);
    const productInCart = props.cartProducts?.find(product => {
        const productId =
            product.poollid.toString() +
            product.activitylid?.toString() +
            product.arrdate +
            product.price +
            (product.category || btoa(JSON.stringify(product.occations)));
        const propsId =
            poollid.toString() +
            activitylid?.toString() +
            arrdate +
            price +
            (category || btoa(JSON.stringify(occations)));
        return productId === propsId;
    });

    // Set count value if product is present in cart
    useEffect(() => {
        setCount(productInCart?.count);
    }, [productInCart]);

    // Expand accordion in OrderOverview
    const expandOrderOverviewAccordion = () => {
        dispatch(setLatestChangedProductType(parseInt(type)));
    };

    // Handle click on more info button
    const handleClick = () => {
        setShowModal(!showModal);
    };

    // Increment count
    const incrementCount = () => {
        setCount(count + 1);
        dispatch(addProductWithCount(props.item));
        expandOrderOverviewAccordion();
    };

    // Set item count
    const setItemCount = response => {
        if (productInCart) {
            if (response.value === 0) {
                dispatch(removeProduct(productInCart));
            } else {
                dispatch(updateProductWithCount(productInCart, response.value));
            }
        }
        setCount(response.value);
        expandOrderOverviewAccordion();
    };

    // Get age category tags
    const getAgeCategoryTags = () => {
        if (minage !== null && maxage && props.generalAges) {
            const categories = getAgeCategories(minage, maxage, props.generalAges[parseInt(type)]);

            return categories.map((item, index) => {
                return (
                    <Tag key={index} type="small" className="letting-item__period-tag" index={index}>
                        {texts[item.title]}
                    </Tag>
                );
            });
        }
    };

    // Render activity input
    const ActivitiesInput = () => {
        if (count > 0) {
            return (
                <div className="activities-item__incrementer">
                    <Incrementer label={title} value={count} maxValue={available} onChange={setItemCount} size={8} />
                </div>
            );
        }
        return <Button text={texts?.generaladd} onClick={incrementCount} className="activities-item__booking-button" />;
    };

    return (
        <div className="activities-item__wrapper col-sm-12 col-md-6 col-lg-4">
            <div className="activities-item">
                <div className="activities-item__image-container">
                    <ResponsiveImage
                        height={204}
                        src={thumbnail?.url}
                        placeholder={ActivitiesImage}
                        className="activities-item__image"
                    />
                    {props.promoCode && (
                        <div className="activities-item__promo-tag-container">
                            <Tag type="small" className="activities-item__promo-tag">
                                {texts?.promocodeincluded}
                            </Tag>
                        </div>
                    )}
                </div>
                <div className="activities-item__body">
                    {freesale === "T" && (
                        <Tag type="small" className="activities-item__available-tag">
                            {texts?.unlimitednumberofplaces}
                        </Tag>
                    )}
                    {available && freesale !== "T" && (
                        <>
                            {limittoonereservation && limittoonereservation === "T" && (
                                <Tag type="small" className="activities-item__available-tag">
                                    {texts?.limittoonereservation} {available} {texts?.places.toLowerCase()}
                                </Tag>
                            )}
                            {!limittoonereservation ||
                                (limittoonereservation === "F" && (
                                    <Tag type="small" className="activities-item__available-tag">
                                        {available} {texts?.placesleft}
                                    </Tag>
                                ))}
                        </>
                    )}

                    {getAgeCategoryTags()}
                    <h3 className="h5 activities-item__header">{title}</h3>
                    {occations.length && (
                        <div className="activities-item__occations">
                            {occations.length > 1 && (
                                <div className="activities-item__occations-header">
                                    {occations.length} {texts.activityoccations}
                                </div>
                            )}
                            <ul className="activities-item__occations-list">
                                {occations.map((occation, index) => {
                                    return (
                                        <li className="activities-item__occations-list-item" key={index}>
                                            <span>
                                                <Icon
                                                    name="FaRegClock"
                                                    size={14}
                                                    color="#333333"
                                                    className="activities-item__occations-list-icon"
                                                />
                                                {moment(occation.date).format(getDateFormat(countryOfBusiness))}
                                            </span>
                                            <span>kl. {`${occation.fromtime}-${occation.totime}`}</span>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    )}
                    <div className="activities-item__modal-button-container">
                        <Button
                            id={`activities-more-information-${index}`}
                            text={texts?.moreinformation}
                            className="activities-item__modal-button"
                            onClick={handleClick}
                            hasPopup={true}
                            expanded={showModal}
                            disabled={weblong ? false : true}
                        />
                        <ActivitiesInput />
                    </div>
                    <div className="activities-item__price">
                        <ProductPrice
                            currency={currency}
                            price={price}
                            ordinaryprice={props.item.ordinaryprice}
                            fromprice={limittoonereservation && limittoonereservation === "T"}
                        />
                    </div>
                </div>
            </div>
            <Modal
                show={showModal}
                setShow={setShowModal}
                labelledBy={`activities-more-information-${props.index}`}
                className="activities-item__modal"
                id={`activities-more-information-modal-${props.index}`}
            >
                <ProductView
                    weblong={weblong ? weblong : desc}
                    title={title}
                    image={thumbnail?.url || ActivitiesImage}
                    texts={texts}
                />
            </Modal>
        </div>
    );
};

export default ActivitiesItem;
