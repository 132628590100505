import { ChevronLeftIcon, ChevronRightIcon, OptimizedImage, getOptimizedImagePathFromUrl } from "@r360/library";
import React, { useRef, useState } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/scss/image-gallery.scss";
import { useSelector } from "react-redux";
import ImagePresentation from "../ImagePresentation/ImagePresentation";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import "./ProductGallery.scss";

// Product gallery component
const ProductGallery = props => {
    const {
        images,
        numberOfThumbnails = 3,
        forceImageGallery = false,
        onImageGalleryClick,
        inAccommodationCard = false,
    } = props;
    const isMobile = useSelector(state => state.window.isMobile);
    const [imageLoaded, setImageLoaded] = useState(false);
    const imageGalleryContainerRef = useRef();
    const imageGalleryWidth = imageGalleryContainerRef?.current?.getBoundingClientRect()?.width || 0;

    // ImageGallery always uses 16/9-aspect ratio so the height can be pre-calculated to occupy correct height before render of images.
    const imageGalleryHeight = (imageGalleryWidth / 16) * 9;

    const renderLargeImage = item => {
        return (
            <OptimizedImage
                aspectX={16}
                aspectY={9}
                path={getOptimizedImagePathFromUrl(item.original)}
                lazyPlaceholder={!imageLoaded}
                onImageLoaded={() => setImageLoaded(true)}
            />
        );
    };

    if (images.length === 0) {
        return <></>;
    }

    return (
        <div className="product-gallery">
            {!isMobile && !forceImageGallery ? (
                <ImagePresentation
                    aspectRatioX="16"
                    aspectRatioY="9"
                    spacingXInPercent="2%"
                    spacingYInPercent="1%"
                    images={images.slice(0, numberOfThumbnails + 1).map(image => image.original)}
                />
            ) : (
                <div ref={imageGalleryContainerRef} style={{ height: imageGalleryHeight || "auto" }}>
                    <LazyLoadComponent>
                        {inAccommodationCard ? (
                            <ImageGallery
                                items={images}
                                showNav
                                showBullets={images.length > 1}
                                showFullscreenButton={false}
                                showThumbnails={false}
                                showPlayButton={false}
                                renderItem={renderLargeImage}
                                useTranslate3D={false}
                                slideDuration={250}
                                stopPropagation
                                disableKeyDown
                                renderLeftNav={onClick => <LeftNav onClick={onClick} />}
                                renderRightNav={onClick => <RightNav onClick={onClick} />}
                            />
                        ) : (
                            <ImageGallery
                                items={images}
                                showNav={true}
                                showBullets={false}
                                showFullscreenButton={false}
                                showThumbnails={false}
                                showPlayButton={false}
                                renderItem={renderLargeImage}
                                useTranslate3D={false}
                                stopPropagation={true}
                                disableKeyDown={true}
                                onClick={() => onImageGalleryClick?.()}
                            />
                        )}
                    </LazyLoadComponent>
                </div>
            )}
        </div>
    );
};

const LeftNav = props => {
    return (
        <button className="image-gallery__nav image-gallery__nav--left" onClick={props.onClick}>
            <ChevronLeftIcon />
        </button>
    );
};

const RightNav = props => {
    return (
        <button className="image-gallery__nav image-gallery__nav--right" onClick={props.onClick}>
            <ChevronRightIcon />
        </button>
    );
};

export default ProductGallery;
