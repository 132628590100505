import { signal } from ".";
import { isBooked } from "../BusinessUtils";
import { SIGNAL_PURCHASE } from "./types";
import moment from "moment-timezone";

/**
 * A purchase is a signal that shows that the reservation has been made and the customer has reached the checkout.
 * 
 * When the reservation has been paid, the purchase becomes paid.
 * 
 * The purchase name is the same that Google Tag Manager uses for tracking before a payment has been confirmed.

 * @param {object} booking 
 * @returns 
 */
export const createPurchaseSignal = booking => {
    // Parameter validation
    if (typeof booking.reservation === "undefined") {
        throw new Error("Invalid booking, missing property: reservation");
    }

    if (typeof booking.reservationrules === "undefined") {
        throw new Error("Invalid booking, missing property: reservationrules");
    }

    if (typeof booking.reservation.products === "undefined") {
        throw new Error("Invalid booking.reservation, missing property: products");
    }

    if (booking.reservation.products.length === 0) {
        throw new Error("Empty booking.reservation.products: At least one product is required");
    }

    // Prepare the signal
    const products = Object.values(booking.reservation.products).filter(isBooked);

    // WARNING! Public API used on customers' sites
    const theSignal = signal(SIGNAL_PURCHASE, {
        resvid: booking.resvid,
        headlid: booking.headlid,
        payerlid: booking.reservation.payerlid,
        totalamount: booking.reservationrules.totamounttopay,
        totaltax: booking.reservationrules.tottaxamounttopay,
        totalproducts: products.length,

        // Transform products to a public API
        products: products.map(product => {
            return {
                id: product.detlid,
                bookstat: product.bookstat,
                name: product.title ? product.title : product.pooldesc3,
                pooldesc1: product.pooldesc1,
                pooldesc3: product.pooldesc3,
                poollid: product.poollid,
                unitdesc1: product.unitdesc1,
                unitdesc3: product.unitdesc3,
                unitlid: product.unitlid,
                price: product.price,
                quantity: product.quantity,
                arrdate: moment(product.arrdate).format("YYYY-MM-DD"),
                depdate: moment(product.depdate).format("YYYY-MM-DD"),
                isextra: product.parent ? true : false,
                promotionlid: product.promotionlid,
                promotioncode: product.promotionCode,
                promotiondescription: product.promotionDescription,
                reservationtypelid: product.type,
                reservationtype: product.kind,
                reservationtypedesc: product.kinddesc,
            };
        }),
    });

    return theSignal;
};
