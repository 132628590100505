import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { Alert, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { isRegularSkipass, isSeasonPass, sortSkipasses } from "../../BusinessUtils";
import * as Constants from "../../Constants";
import { getDateObjectForRequests } from "../../hooks";
import useQueryString from "../../hooks/QueryString/useQueryString";
import useAppSelector from "../../hooks/useAppSelector";
import useChangeSearch from "../../hooks/useChangeSearch";
import { agesSelector, optionsSelector } from "../../selectors/Selectors";
import ReservationTypesLoader from "../ContentLoaderOld/ReservationTypesLoader";
import PromotionCodeInput from "../PromotionCodeInputOld/PromotionCodeInput";
import ReservationTypeDateFilter from "../ReservationTypeDateFilter";
import ReservationTypeNoResults from "../ReservationTypeNoResults";
import { Button, DropdownButton, Icon, InformationBlock } from "../UI";
import SkipassItem from "./SkipassItem/SkipassItem";
import "./SkipassView.scss";
import { textsSelector } from "../../Selectors";

const SkipassView = props => {
    const params = useParams();
    const reservationTypeid = props?.reservationTypeid || params?.id;
    const filters = useSelector(state => state.filter);
    const promotion = useSelector(state => state.clientData?.options?.module?.promotion);
    const isMobile = useSelector(state => state.window.isMobile);
    const isLoading = useSelector(state => state.axiosStatus.loading);
    const texts = useSelector(textsSelector);
    const reservationResult = useSelector(state => state.reservationResult);
    const skipass = reservationResult[reservationTypeid];
    const generalAges = useSelector(state => agesSelector(state));
    const [showOnlySeasonPasses, setShowOnlySeasonPasses] = useState(false);
    const [age, setAge] = useState({});
    const [multiplePassAvailable, setMultiplePassAvailable] = useState(false);
    const [startValue, onSetStartDate] = useQueryString("startDate");
    const [endValue, onSetEndDate] = useQueryString("endDate");
    const navigate = useNavigate();
    const newDesignMenu = useSelector(optionsSelector).layout?.new_design_menu;
    const isSeasonSkipass =
        parseInt(useAppSelector(optionsSelector).skipass.season_skipass_resvtypelid || 0, 10) ===
        parseInt(reservationTypeid, 10);

    useChangeSearch({
        reservationResultSearch: skipass?.search,
        reservationTypeId: reservationTypeid,
        skipGuestsOnSearchWithPrice: true,
        allowSearchWithoutPrice: false,
    });

    const dates = getDateObjectForRequests({ startDate: startValue, endDate: endValue }, skipass?.search, true);

    useEffect(() => {
        if (reservationTypeid) {
            const startDate = moment(dates.startDate);
            const endDate = moment(dates.endDate);
            setStartDate(startDate);
            setEndDate(endDate);
            setSingleDate(startDate.isValid() && endDate.isValid() && startDate.isSame(endDate));
            setShowOnlySeasonPasses(false);
        }
        // eslint-disable-next-line
    }, [reservationTypeid]);

    let agesByResvtype = [];

    const [startDate, setStartDate] = useState(moment(dates.startDate));
    const [endDate, setEndDate] = useState(moment(dates.endDate));
    const [singleDate, setSingleDate] = useState(startDate.isValid() && endDate.isValid() && startDate.isSame(endDate));

    if (reservationTypeid) {
        if (reservationTypeid in generalAges) {
            agesByResvtype = generalAges[reservationTypeid];
        } else {
            throw new Error(`Reservation type ${reservationTypeid} does not have any ages configured in general ages`);
        }
    }

    useEffect(() => {
        if (skipass?.groups) {
            // Flatten multidimensional Object to a single array
            let isOtherPassAvailable = []
                .concat(...Object.values(skipass.groups).map(group => group.items))
                .filter(item => {
                    return item.category !== "keycard" && isRegularSkipass(item);
                });

            isOtherPassAvailable = isOtherPassAvailable.length > 0 ? true : false;

            let isSeasonPassAvailable = []
                .concat(...Object.values(skipass.groups).map(group => group.items))
                .filter(item => {
                    return item.category !== "keycard" && isSeasonPass(item);
                });
            isSeasonPassAvailable = isSeasonPassAvailable.length > 0 ? true : false;

            const onlySkipassTypeAvailabe = isOtherPassAvailable && isSeasonPassAvailable;

            setMultiplePassAvailable(onlySkipassTypeAvailabe);
        }
    }, [skipass]);

    // Render age picker
    const renderAgePicker = () => {
        const showAllButtonClass =
            Object.keys(age).length === 0
                ? "skipass-view__list-button skipass-view__list-button--active"
                : "skipass-view__list-button";
        return (
            <DropdownButton
                closeOnClick
                text={age?.key ? texts?.[age.title] : texts?.generalshowall}
                className="button--dropdown skipass-view__filters-dropdown"
            >
                <ul className="skipass-view__list-menu">
                    <li className="skipass-view__list-item" key={`age-all`}>
                        <Button
                            text={texts?.generalshowall}
                            className={showAllButtonClass}
                            onClick={() => setAge({})}
                        />
                    </li>
                    {agesByResvtype.map((item, index) => {
                        const buttonClass =
                            item.key === age.key
                                ? "skipass-view__list-button skipass-view__list-button--active"
                                : "skipass-view__list-button";
                        return (
                            <li className="skipass-view__list-item" key={`age-${index}`}>
                                <Button
                                    key={index}
                                    text={texts?.[item.title]}
                                    className={buttonClass}
                                    onClick={() => setAge(item)}
                                />
                            </li>
                        );
                    })}
                </ul>
            </DropdownButton>
        );
    };

    const renderDatePicker = () => {
        return (
            <ReservationTypeDateFilter
                texts={texts}
                isMobile={isMobile}
                reservationTypeid={reservationTypeid}
                initialStartDate={startDate}
                initialEndDate={endDate}
                isSingleDate={singleDate}
                onSetSingledate={single => {
                    setSingleDate(single);
                }}
                onSetStartDate={date => {
                    setStartDate(moment(date));
                    onSetStartDate(date);
                }}
                onSetEndDate={date => {
                    setEndDate(moment(date));
                    onSetEndDate(date);
                }}
                onDatesChange={() => {
                    // The history needs to be updated in a Reactive way instead of natively as
                    // setStartDate and setEndDate does. Otherwise the useChangeSearch hook won't trigger.
                    // Remove everything before and including /client from beginning of pathname
                    // to access the root path of the React application when navigating.
                    const pathname = window.location.pathname.replace(/^.*\/client/i, "");
                    const search = window.location.search;
                    navigate(`${pathname}${search}`, { replace: true });
                }}
            />
        );
    };

    // Render options list
    const renderSkipassList = () => {
        let skipassArray = [];
        // Add groups to array if any
        if (skipass?.groups) {
            // Flatten multidimensional Object to a single array
            skipassArray = [].concat(...Object.values(skipass.groups).map(group => group.items)).filter(item => {
                return item.category !== "keycard";
            });
        }

        if (skipassArray.length > 0) {
            skipassArray = sortSkipasses(skipassArray);

            /*
            Set filtering of products depending on if season pass is active or not.

            A customer may have one reservation type that contains both regular skipasses and season passes or
            they may have multiple reservation types, one for regular skipasses and one for season passes.

            Season pass have days = 0
            Other passes have days > 0
            */
            const seasonPasses = skipassArray.filter(item => isSeasonPass(item));
            const regularSkipasses = skipassArray.filter(item => isRegularSkipass(item));

            // Activate season pass section if there are only season passes in result
            if (regularSkipasses.length === 0 && !showOnlySeasonPasses) {
                setShowOnlySeasonPasses(true);
            }

            if (showOnlySeasonPasses) {
                skipassArray = seasonPasses;
            } else {
                skipassArray = regularSkipasses;
            }

            if (Object.keys(age).length !== 0) {
                skipassArray = skipassArray.filter(item => {
                    return age.key || item.minage !== null || item.maxage !== null
                        ? age.min >= item.minage && age.max <= item.maxage
                        : true;
                });
            }

            return skipassArray.map(item => {
                return (
                    <SkipassItem
                        item={item}
                        generalAges={generalAges}
                        cartProducts={props.cartProducts}
                        promoCode={filters.promoCode}
                        key={item.id}
                        index={item.id}
                    />
                );
            });
        } else if ((skipass?.hitsMatchingFilter === "0" || !skipassArray.length) && !isLoading) {
            return (
                <div className="col">
                    {skipass && <ReservationTypeNoResults resvtype={Constants.productTypeNames.SKIPASS} />}
                </div>
            );
        } else if (skipass?.error && !isLoading) {
            return (
                <div className="col">
                    <Alert variant="light" className="skipass-view__alert--fetch-error">
                        <div className="skipass-view__alert-icon">
                            <Icon name="FaExclamationTriangle" size={40} color="#e04f5f" />
                        </div>
                        <div className="skipass-view__alert-text">
                            <h3 className="h6">{texts?.noresultnetworkheader}</h3>
                            <p>{texts?.noresultnetworkbody}</p>
                            <Button
                                onClick={window.location.reload}
                                className="button--text-only skipass-view__alert-button"
                            >
                                <Icon name="FaUndoAlt" size={18} color="#333333" />
                                <span className="sr-only">
                                    {texts?.noresultgetdata} {props.header}
                                </span>
                            </Button>
                        </div>
                    </Alert>
                </div>
            );
        } else {
            return [1, 2, 3].map((item, index) => {
                return (
                    <div className="col-sm-12 col-md-6 col-lg-4" key={"loader" + index}>
                        <ReservationTypesLoader></ReservationTypesLoader>
                    </div>
                );
            });
        }
    };

    // Handle toggle for season pass.
    const handleToggle = showSeasonPasses => {
        if (showSeasonPasses) {
            setShowOnlySeasonPasses(true);
        } else {
            setShowOnlySeasonPasses(false);
        }
    };

    return (
        <div className="skipass-view">
            <InformationBlock className="w-100">{texts?.skipassinformation}</InformationBlock>
            {!newDesignMenu && (
                <div className="skipass-view__filters-container">
                    <div className="skipass-view__filters">
                        <div className="skipass-view__filters-item">{renderDatePicker()}</div>
                        <div className="skipass-view__filters-item skipass-view__filters-item--mobile-block">
                            {renderAgePicker()}
                        </div>
                    </div>
                    {multiplePassAvailable && (
                        <div className="skipass-view__filters--right col-md-4">
                            <Form.Control
                                value={showOnlySeasonPasses}
                                as="select"
                                name="seasonPass"
                                onChange={e => handleToggle(e.target.value === "true" ? true : false)}
                            >
                                <option value={false}>{texts?.showotherpasses || "Visa övriga"}</option>
                                <option value={true}>{texts?.showseasonpasses || "Visa säsongskort"}</option>
                            </Form.Control>
                        </div>
                    )}
                    {!newDesignMenu && promotion && <PromotionCodeInput promoCode={filters.promoCode} texts={texts} />}
                </div>
            )}
            <div className="skipass-view__options row" aria-live="polite">
                {renderSkipassList()}
            </div>
        </div>
    );
};

export default SkipassView;
