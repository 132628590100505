import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Figure } from "react-bootstrap";
import { GroupIcon, UserIcon, PlusIcon, BookingIcon } from "../../components/UI/R360Icons";
import { checkAuthenticationStatus } from "../../store/actions/account";
import { optionsSelector } from "../../selectors/Selectors";
import { textsSelector } from "../../Selectors";

const AccountPage = () => {
    const dispatch = useDispatch();
    const isMobile = useSelector(state => state.window.isMobile);
    const texts = useSelector(textsSelector);
    const navigate = useNavigate();
    const account = useSelector(state => state.account);
    const userToken = account.token;
    const showGuests = useSelector(optionsSelector).customer?.show_guests || false;
    const myPageOnly = !!useSelector(optionsSelector).general?.mypage_only;

    // Check if authenticated
    useEffect(() => {
        dispatch(checkAuthenticationStatus(userToken));
    }, [dispatch, userToken]);

    const accountCards = [
        {
            text: texts?.personalinformation,
            icon: <UserIcon color="black" size={isMobile ? 30 : 55} />,
            route: "/account/myAccount",
            disabled: false,
            hidden: false,
        },
        {
            text: texts?.myguests,
            icon: <GroupIcon color="black" size={isMobile ? 30 : 55} />,
            route: "/account/guests",
            disabled: false,
            hidden: !showGuests,
        },
        {
            text: texts?.bookings,
            icon: <BookingIcon color="black" size={isMobile ? 30 : 55} />,
            route: "/account/bookings",
            disabled: false,
            hidden: false,
        },
    ];

    const clickedCard = route => {
        navigate(route);
    };

    return (
        <Container>
            <h1 className="page-header">{texts?.account}</h1>

            {!myPageOnly && (
                <button
                    type="button"
                    className="btn button button--invert mb-4"
                    onClick={() => {
                        navigate("/");
                    }}
                >
                    <PlusIcon size="14" /> {texts?.generalnewreservation}
                </button>
            )}

            <Row>
                {accountCards
                    .filter(card => !card.hidden)
                    .map(card => {
                        return (
                            <Col key={card.route} xs="12" md="4" className="pb-2">
                                <AccountCard
                                    disabled={card.disabled}
                                    onClick={clickedCard}
                                    route={card.route}
                                    text={card.text}
                                    icon={card.icon}
                                    isMobile={isMobile}
                                ></AccountCard>
                            </Col>
                        );
                    })}
            </Row>
        </Container>
    );
};

const AccountCard = props => {
    const { route, text, icon, onClick, isMobile, disabled } = props;

    if (isMobile) {
        return (
            <button
                className="btn d-flex align-items-center h-100 w-100 p-3"
                disabled={disabled}
                style={{
                    cursor: disabled === true ? "inherit" : "pointer",
                    borderStyle: "solid",
                    borderRadius: "10px ",
                    borderWidth: "2px",
                    borderColor: "#333333",
                    opacity: disabled === true ? "0.2" : "1",
                }}
                onClick={() => {
                    onClick(route);
                }}
            >
                {icon}
                <h5 className="mb-0 ms-2 text-center" style={{ color: "#333333" }}>
                    {text}
                </h5>
            </button>
        );
    }

    return (
        <Figure
            as={"button"}
            disabled={disabled}
            className="w-100 btn"
            style={{
                height: "200px",
                cursor: disabled === true ? "inherit" : "pointer",
                borderStyle: "solid",
                borderRadius: "10px ",
                borderWidth: "2px",
                borderColor: "#333333",
                paddingTop: "35px",
                paddingBottom: "30px",
                opacity: disabled === true ? "0.2" : "1",
            }}
            onClick={() => {
                onClick(route);
            }}
        >
            <div className="text-center">{icon}</div>
            <Figure.Caption style={{ color: "#333333", paddingTop: "25px" }} className="text-center">
                <h3>{text}</h3>
            </Figure.Caption>
        </Figure>
    );
};

export default AccountPage;
