import {
    Checkbox,
    Heading,
    OptimizedImage,
    Radio,
    Skeleton,
    Status,
    getOptimizedImagePathFromUrl,
} from "@r360/library";
import classNames from "classnames";
import React, { useState } from "react";
import { formatPrice } from "../../Helper";
import useTranslate from "../../hooks/useTranslate";
import { TItem } from "../../store/types";
import { RichText } from "../UI";
import Incrementer from "../UI/Incrementer";
import {
    extrasItemPriceColumnClass,
    extrasItemQuantityColumnClass,
    extrasItemTotalColumnClass,
} from "./AccommodationExtras";
import "./AccommodationExtrasItem.scss";
import { getInspectionDataForExtrasItem, hasBookstat, isBooked, isSystemPool } from "../../BusinessUtils";
import useAppSelector from "../../hooks/useAppSelector";
import { cartCreatedFromReservationSelector } from "../../selectors/Selectors";

type TAccommodationExtrasItem = {
    item: TItem;
    accommodationItem: TItem;
    quantity: number;
    setQuantity: (value: number) => void;
    currency: string;
    showUnselectedWarning: boolean;
    itemInCart: boolean;
    loading?: boolean;
};

export const AccommodationExtrasItem = ({
    item,
    accommodationItem,
    quantity,
    setQuantity,
    currency,
    showUnselectedWarning = false,
    itemInCart,
    loading,
}: TAccommodationExtrasItem) => {
    const t = useTranslate();
    const mandatory = item.mandatory_web;
    const showPrice = item.showpriceweb;
    const defaultWeb = item.default_web;
    const demand = item.demand_web;
    const isOwnerAccommodationItem = !!accommodationItem.ownerlid;
    const cartCreatedFromReservation = useAppSelector(cartCreatedFromReservationSelector);

    const isDisabled = (item: TItem) => {
        if (cartCreatedFromReservation && isSystemPool(item)) {
            return true;
        }

        if (
            isBooked(accommodationItem) &&
            ((item?.rules && Object.keys(item?.rules).length === 0) ||
                (hasBookstat(item) &&
                    item.bookedquantity === 1 &&
                    (item.rules.type_input === "checkbox" || item.rules.type_input === "radiobutton")) ||
                (item.rules.min_qty === 0 && item.rules.max_qty === 0 && item.detlid) ||
                item.rules.mandatory_web)
        ) {
            return true;
        }

        return false;
    };
    const disabled = isDisabled(item);

    const getDefaultCheckedValue = () => {
        if ((itemInCart && !!quantity) || (!!defaultWeb && !demand && !itemInCart)) {
            return true;
        } else if (
            (itemInCart && !quantity) ||
            (!defaultWeb && !demand) ||
            (cartCreatedFromReservation && isSystemPool(item))
        ) {
            return false;
        }

        return undefined;
    };

    const [checked, setChecked] = useState<boolean | undefined>(getDefaultCheckedValue());

    const renderTitle = () => {
        return (
            <div className="col-12 col-lg-7">
                <Heading type="h1" styleAs="h5" className="accommodation-extras-item__title">
                    {item.title}
                </Heading>
                {item.weblong && (
                    <p className="accommodation-extras-item__info">
                        <RichText content={item.weblong} />
                    </p>
                )}
            </div>
        );
    };

    const renderPrice = (quantity = 1) => {
        if (item.aprice) {
            return `${formatPrice(item.aprice * quantity, currency)}`;
        }
    };

    const renderExtrasItem = () => {
        switch (item.rules.type_input) {
            case "editbox":
            case "dropdown":
                return (
                    <>
                        {item.mainimage?.url && (
                            <div className="u-mb-6">
                                {loading ? (
                                    <Skeleton width="120px" height="68px" />
                                ) : (
                                    !!item.mainimage?.url && (
                                        <div className="accommodation-extras-item__extra-img">
                                            <OptimizedImage path={getOptimizedImagePathFromUrl(item.mainimage?.url)} />
                                        </div>
                                    )
                                )}
                            </div>
                        )}

                        {renderTitle()}
                        <div
                            className={classNames("accommodation-extras-item__quantity", extrasItemQuantityColumnClass)}
                        >
                            {!mandatory && (
                                <Incrementer
                                    name={"name"}
                                    label={"label"}
                                    value={quantity}
                                    onChange={(value: { key: string; value: number }) => setQuantity(value.value)}
                                    size="small"
                                    minValue={item.rules.min_qty}
                                    maxValue={item.rules.max_qty}
                                    disabled={disabled}
                                />
                            )}
                            {mandatory && (
                                <div className="u-d-flex u-gap-6 u-align-items-center u-pt-6">
                                    <Status type="success" size={12} />
                                    <p className="accommodation-extras-item__mandatory-text">
                                        {t(
                                            isOwnerAccommodationItem
                                                ? "book.accomodation.extras.mandatory"
                                                : "book.cart.extras.included_in_acccommodation"
                                        )}
                                    </p>
                                </div>
                            )}
                        </div>
                        <div className={classNames("accommodation-extras-item__price", extrasItemPriceColumnClass)}>
                            {showPrice && renderPrice()}
                        </div>
                        <div className={classNames("accommodation-extras-item__total", extrasItemTotalColumnClass)}>
                            {showPrice && renderPrice(quantity - (item.bookedquantity || 0))}
                        </div>
                    </>
                );
            case "radiobutton":
                // Defautl to "yes" if the item is in cart and has quantity (1 or more)
                // or if defaultWeb is true (yes by default), the radio doesn't require a choice (demand) and the item is not already in the cart

                // Default to "no" if the item is in cart and has no quantity
                // or if defaultWeb is false, and the radio doesn't require a choice

                return (
                    <>
                        {item.mainimage?.url && (
                            <div className="u-mb-6">
                                {loading ? (
                                    <Skeleton width="120px" height="68px" />
                                ) : (
                                    !!item.mainimage?.url && (
                                        <div className="accommodation-extras-item__extra-img">
                                            <OptimizedImage path={getOptimizedImagePathFromUrl(item.mainimage?.url)} />
                                        </div>
                                    )
                                )}
                            </div>
                        )}
                        {renderTitle()}
                        <div className="col-12 col-lg u-d-flex u-gap-30 u-justify-content-lg-end">
                            <Radio
                                value="yes"
                                label={t("book.general.add_for_amount", [`${item.aprice} ${currency}`])}
                                onChange={() => {
                                    setQuantity(1);
                                    setChecked(true);
                                }}
                                buttonStyle
                                checked={checked}
                                disabled={disabled}
                            />
                            <Radio
                                value="no"
                                label={t("book.general.no_thanks")}
                                onChange={() => {
                                    setQuantity(0);
                                    setChecked(false);
                                }}
                                buttonStyle
                                checked={checked === false}
                                disabled={disabled}
                            />
                        </div>
                    </>
                );

            case "checkbox":
                return (
                    <>
                        {item.mainimage?.url && (
                            <div className="u-mb-6">
                                {loading ? (
                                    <Skeleton width="120px" height="68px" />
                                ) : (
                                    !!item.mainimage?.url && (
                                        <div className="accommodation-extras-item__extra-img">
                                            <OptimizedImage path={getOptimizedImagePathFromUrl(item.mainimage?.url)} />
                                        </div>
                                    )
                                )}
                            </div>
                        )}
                        {renderTitle()}
                        <div className="col-12 col-lg u-d-flex u-gap-30 u-align-items-start u-justify-content-end">
                            <Checkbox
                                label={t("book.general.add_for_amount", [`${item.aprice} ${currency}`])}
                                onChange={() => setQuantity(quantity ? 0 : 1)}
                                buttonStyle
                                checked={!!quantity}
                                disabled={disabled}
                            />
                        </div>
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <article
            className={classNames("accommodation-extras-item row", {
                "accommodation-extras-item--warning": showUnselectedWarning,
            })}
            {...getInspectionDataForExtrasItem(item)}
        >
            {renderExtrasItem()}
        </article>
    );
};
