import { sortSkipasses } from "../BusinessUtils";
import * as Constants from "../Constants";
import { RootState } from "../index";
import { TItem, TReservationResult } from "../store/types";

// -----> SKapa selectors för alla olika reservationstypers produkter som ska listas. Behövs för att räkna fram antal dagar.

export const SkipassProductSelector = (state: RootState, reservationTypeid: number) => {
    const reservationResult: TReservationResult = state.reservationResult[reservationTypeid];
    let skipassArray: any[] = [];
    if (reservationResult?.groups) {
        skipassArray = Object.values(reservationResult.groups)
            .map(group => group.items)

            .flat()
            .filter(item => {
                return item.category !== "keycard";
            });
        // .filter(item => isRegularSkipass(item));
    }
    return sortSkipasses(skipassArray);
};

export const SeasonPassProductSelector = (state: RootState, reservationTypeid: number) => {
    const reservationResult: TReservationResult = state.reservationResult[reservationTypeid];
    let skipassArray: TItem[] = [];
    if (reservationResult?.groups) {
        // Flatten multidimensional Object to a single array
        skipassArray = Object.values(reservationResult.groups)
            .map(group => group.items)
            .flat()
            .filter(item => {
                return item.category !== "keycard";
            })
            .filter(item => isSeasonPass(item));
    }
    return sortSkipasses(skipassArray);
};

const isSeasonPass = (product: TItem) => {
    // 0 days or same day is a seasonpass
    return product.kind === Constants.productTypeNames.SKIPASS && product.days === 0;
};
