const Theme = {
    palette: {
        primary: "#0d3e60",
        info: "#00A699",
        secondary: "#f7555c",
        success: "#5cb85c",
        danger: "#d93900",
        warning: "#f0ad4e",
        sidebar: "#484848",
        lightGray: "#BFBFBF",
        borderColor: "#F5F5F5",
    },
};

export default Theme;
