import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { isBooked, isRegularSkipass, isSeasonPass, showArrDepDates } from "../../../BusinessUtils";
import { displayGuestText, getGuestNameAndAge, isGuestRightAgeForProduct, verifyWTPNumber } from "../../../Helper";
import { guestsOnTravelSelector } from "../../../selectors/Selectors";
import { updateProduct } from "../../../store/actions/checkoutCart";
import { updateGuestData } from "../../../store/actions/guests";
import PhotoButton from "../../PhotoButton/PhotoButton";
import { InformationBlock, Modal, Tag } from "../../UI";
import "./SkiPass.scss";

const SkiPass = ({ allProducts, products, type, texts, removeProductFromCart, skipGuestsOnSkipass = false }) => {
    const dispatch = useDispatch();
    const skipasses = products.filter(
        product => !isBooked(product) && parseInt(product.type, 10) === parseInt(type.lid, 10)
    );

    const guestsOnTravel = useSelector(guestsOnTravelSelector);
    const keycardhelpimage = useSelector(state => state.clientData.options.general?.keycardhelpimage);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        skipasses.forEach(skipass => {
            if (skipass.items && skipass.items.length === skipass.count) {
                // Empty statement.
            } else {
                if (skipass.items) {
                    let rest = skipass.count - skipass.items.length;
                    let items = skipass.items;
                    if (rest > 0) {
                        //console.log("CREATE " + rest + " skipaaess");

                        for (let index = 0; index < rest; index++) {
                            const uuid = uuidv4();
                            items.push({ id: uuid, guestId: null, keycardNo: "" });
                        }
                    } else {
                        rest = rest * -1;
                        //console.log("Remove " + rest + " skipaaess");
                        for (let index = 0; index < rest; index++) {
                            items.pop();
                        }
                    }
                    dispatch(updateProduct(skipass, { key: "items", value: items }));
                } else {
                    let items = [];
                    for (let count = 0; count < skipass.count; count++) {
                        const uuid = uuidv4();
                        items.push({ id: uuid, guestId: null, keycardNo: "" });
                    }
                    dispatch(updateProduct(skipass, { key: "items", value: items }));
                }
            }
        });
    }, [dispatch, skipasses]);

    const handleProductGuestIdChange = (event, product, productItem) => {
        const { value } = event.target;

        // If the guest selected has a keycardNo, assign it to the skipass item.
        const guest = guestsOnTravel[value];

        dispatch(
            updateProduct(product, {
                key: "items",
                value: product.items.map(item =>
                    item.id === productItem.id ? { ...item, guestId: value, keycardNo: guest?.keycardNo || "" } : item
                ),
            })
        );
    };

    const handleProductChange = (event, product, productItem) => {
        const { name, value } = event.target;

        dispatch(
            updateProduct(product, {
                key: "items",
                value: product.items.map(item => (item.id === productItem.id ? { ...item, [name]: value } : item)),
            })
        );
    };

    const verifyKeycardNumber = keycardNo => {
        return verifyWTPNumber(keycardNo)
            .then(response => {
                const valid = response.data.payload.valid;
                const finalKeycardNo = response.data.payload.finalKeycardNo;

                return { valid, finalKeycardNo };
            })
            .catch(error => {
                console.log(error);
            });
    };

    const updateProductAndGuestKeycardNumber = (keycardNo, product, productItem) => {
        if (!product || !productItem) {
            return;
        }

        // valid if keycardNo is truthy.
        const valid = !!keycardNo;

        dispatch(
            updateProduct(product, {
                key: "items",
                value: product.items.map(item => (item.id === productItem.id ? { ...item, keycardNo, valid } : item)),
            })
        );

        if (!skipGuestsOnSkipass) {
            dispatch(updateGuestData(productItem.guestId, "keycardNo", keycardNo));
        }
    };

    const handleClick = () => {
        setShowModal(!showModal);
    };

    const renderSkipasses = () => {
        return skipasses.map(skipass => {
            return (
                <React.Fragment key={skipass.id}>
                    {skipass.items?.map((item, index) => {
                        const selectedGuest = guestsOnTravel?.[item.guestId];

                        return (
                            <div key={item.id} className="skipass-fill-out__item">
                                {index === 0 && (
                                    <Row>
                                        <Form.Group as={Col} md="auto">
                                            <h5 className="m-0 me-3">{skipass.title}</h5>
                                        </Form.Group>
                                        <div className="col-md-auto skipass-fill-out__tag-container">
                                            {isSeasonPass(skipass) && (
                                                <Tag type="medium" className="text-capitalize">
                                                    {texts?.seasonpass}
                                                </Tag>
                                            )}
                                            {isRegularSkipass(skipass) && (
                                                <Tag type="medium" className="text-capitalize">
                                                    {showArrDepDates(skipass)}
                                                </Tag>
                                            )}
                                            <Tag type="medium">
                                                {displayGuestText(
                                                    skipass.minage,
                                                    skipass.maxage,
                                                    texts?.generalyearshort
                                                )}
                                            </Tag>
                                            {skipass.connectedProductId && (
                                                <Tag type="medium">
                                                    {
                                                        allProducts.find(
                                                            product => product.cartItemId === skipass.connectedProductId
                                                        )?.title
                                                    }
                                                </Tag>
                                            )}
                                            {!skipass.connected && (
                                                <Tag type="medium">
                                                    {texts?.nokeycardneeded || "Inget fysiskt skipass behövs"}
                                                </Tag>
                                            )}
                                            {texts?.keycardnumberhelpinformation && skipass.connected && (
                                                <div className="float-end">
                                                    <Button
                                                        variant="primary"
                                                        className="skipass-fill-out__infoButton"
                                                        onClick={handleClick}
                                                    >
                                                        ?
                                                    </Button>
                                                    <Button
                                                        variant="button"
                                                        className="p-0 ps-1 fw-light border-0 skipass-fill-out__infoButtonHelp"
                                                        onClick={handleClick}
                                                    >
                                                        {texts.keycardnumberhelpinformationshort || "Help"}
                                                    </Button>
                                                </div>
                                            )}
                                        </div>
                                    </Row>
                                )}

                                <Row>
                                    {!skipGuestsOnSkipass && (
                                        <Form.Group as={Col} md="4" controlId="validationCustom01">
                                            <Form.Control
                                                required
                                                as="select"
                                                name="guestId"
                                                value={item.guestId || ""}
                                                onChange={event => {
                                                    handleProductGuestIdChange(event, skipass, item);
                                                }}
                                            >
                                                <option value="" disabled>
                                                    {texts?.generalguest}*
                                                </option>

                                                {Object.values(guestsOnTravel).map(guest => {
                                                    return (
                                                        <option
                                                            key={guest.id}
                                                            value={guest.id}
                                                            disabled={!isGuestRightAgeForProduct(guest, skipass)}
                                                        >
                                                            {getGuestNameAndAge(
                                                                guest,
                                                                texts?.generalyearshort,
                                                                skipass
                                                            )}
                                                        </option>
                                                    );
                                                })}
                                            </Form.Control>
                                        </Form.Group>
                                    )}
                                    <Form.Group as={Col} md="4" controlId="validationCustom02">
                                        <Form.Control
                                            required
                                            as="select"
                                            name="keycard"
                                            disabled={(!skipGuestsOnSkipass && !item.guestId) || !skipass.connected}
                                            value={item.keycard || ""}
                                            onChange={event => {
                                                handleProductChange(event, skipass, item);
                                            }}
                                            hidden={!skipass.connected}
                                        >
                                            <option value="" disabled>
                                                {texts?.choosekeycard}
                                            </option>
                                            <option value="0">{texts?.newkeycard}</option>
                                            <option value="1">{texts?.existingkeycard}</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Row>

                                <Row>
                                    {item.keycard === "1" && (
                                        <Form.Group as={Col} md="4" controlId="validationCustom03">
                                            <Form.Control
                                                required={true}
                                                isValid={item?.valid === true ? true : false}
                                                isInvalid={item?.valid === false ? true : false}
                                                name="keycardNo"
                                                placeholder={texts?.wtpnumber}
                                                value={item.keycardNo}
                                                onChange={event => {
                                                    handleProductChange(event, skipass, item);
                                                }}
                                                onKeyPress={e => {
                                                    e.key === "Enter" && e.preventDefault();
                                                    if (e.key === "Enter") {
                                                        e.target.blur();
                                                    }
                                                }}
                                                onBlur={event => {
                                                    // setCustomValidity is used as a workaround to make server side validation work. Formik could be used instead.
                                                    // Providing a message to setCustomValidity forces that native validation to get an invalid state.
                                                    // The synthetic event need to trigger .persist() to be used after the server side validation.
                                                    event.persist();

                                                    const keycardNo = event.target.value;

                                                    if (!keycardNo) {
                                                        updateProductAndGuestKeycardNumber("", skipass, item);
                                                        return;
                                                    }

                                                    verifyKeycardNumber(keycardNo).then(({ valid, finalKeycardNo }) => {
                                                        if (valid) {
                                                            // Reset custom validity if valid keycard number.
                                                            event.target.setCustomValidity("");
                                                            event.target.value = finalKeycardNo;
                                                        } else {
                                                            event.target.setCustomValidity("Force error");
                                                        }

                                                        updateProductAndGuestKeycardNumber(
                                                            valid ? finalKeycardNo : "",
                                                            skipass,
                                                            item
                                                        );
                                                    });
                                                }}
                                                hidden={!skipass.connected}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {texts?.couldnotvalidate}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    )}
                                    {!skipGuestsOnSkipass &&
                                        item.guestId &&
                                        !!skipass?.photomandatory &&
                                        skipass.connected && (
                                            <Form.Group as={Col} md="4">
                                                <PhotoButton
                                                    guestlid={item.guestId}
                                                    imagehash={selectedGuest?.imagehash}
                                                />
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    className="d-none"
                                                    value={selectedGuest?.imagehash ? item.guestId : ""}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {texts?.checkoutskipassphotorequired}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        )}
                                </Row>

                                <Row>
                                    <Form.Group as={Col} md={4}>
                                        <Button
                                            className="skiRental__button p-0 ms-2 text-danger"
                                            variant="link"
                                            onClick={() => {
                                                removeProductFromCart(skipass, item);
                                            }}
                                        >
                                            {texts?.generalremove}
                                        </Button>
                                    </Form.Group>
                                </Row>
                            </div>
                        );
                    })}
                    {!!skipass?.photomandatory && (
                        <Row>
                            <InformationBlock className="pt-0 mb-4">
                                {texts?.checkoutskipassphotorequirementinfo}
                            </InformationBlock>
                        </Row>
                    )}
                </React.Fragment>
            );
        });
    };

    return (
        <div className="pt-4">
            <InformationBlock className="mb-4">{texts?.checkoutskipassinfotext}</InformationBlock>
            {!skipGuestsOnSkipass && (
                <InformationBlock className="mb-4">{texts?.missingguestinformation}</InformationBlock>
            )}

            <div>{renderSkipasses()}</div>
            <Modal show={showModal} setShow={setShowModal}>
                <div className="d-flex justify-content-center">
                    <Card className="border-0" style={{ width: "18rem" }}>
                        <Card.Img variant="top" src={keycardhelpimage} />
                        <Card.Body>
                            <Card.Title>{texts?.keycardnumber}</Card.Title>
                            <Card.Text>{texts?.keycardnumberhelpinformation || "Help"}</Card.Text>
                        </Card.Body>
                    </Card>
                </div>
            </Modal>
        </div>
    );
};

export default SkiPass;
