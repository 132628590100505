import { ConfirmationModal } from "@r360/library";
import React from "react";
import { RootState } from "../..";
import useAppSelector from "../../hooks/useAppSelector";
import { useCallbackPrompt } from "../../hooks/useCallBackPrompts";
import useTranslate from "../../hooks/useTranslate";

type TProductsNotAddedToCartModal = {
    readyToShow: boolean;
};

export const ProductsNotAddedToCartModal = ({ readyToShow }: TProductsNotAddedToCartModal) => {
    const { showPrompt, confirmNavigation, cancelNavigation } = useCallbackPrompt(readyToShow);
    const { isIframe, isDesktop, isMobile, isTablet, iframeOffsetTop } = useAppSelector(
        (state: RootState) => state.window
    );
    const t = useTranslate();

    return (
        <ConfirmationModal
            open={showPrompt}
            onClose={cancelNavigation}
            heading={t("book.leave_warning_header")}
            description={t("book.leave_warning_text")}
            confirmBtnText={t("book.leave_warning_confirm")}
            cancelBtnText={t("book.leave_warning_cancel")}
            removing={false}
            onConfirmClick={confirmNavigation}
            onCancelClick={cancelNavigation}
            {...(isIframe && isDesktop && { fromTop: 120 + iframeOffsetTop + "px" })}
            {...(isIframe &&
                (isMobile || isTablet) && {
                    fromTop: 80 + iframeOffsetTop + "px",
                })}
        />
    );
};
