import {
    Button,
    ChevronDownIcon,
    ChevronUpIcon,
    ExpandableText,
    Heading,
    GridIcon,
    Modal,
    OptimizedImage,
    getOptimizedImagePathFromUrl,
} from "@r360/library";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../..";
import { getInspectionDataForGroup, getInspectionDataForProduct, getLowestPrice } from "../../BusinessUtils";
import { formatPrice } from "../../Helper";
import useAppSelector from "../../hooks/useAppSelector";
import useItemInCart from "../../hooks/useItemInCart";
import useTranslate from "../../hooks/useTranslate";
import { addProduct, addProductCount, addProductGuests } from "../../store/actions/checkoutCart";
import { TItem, TImage, TGroup, TStringIndexedObjectWithNumbers, TGeneralAges } from "../../store/types";
import ProductImagesGallery from "../ProductImagesGallery/ProductImagesGallery";
import { RichText } from "../UI";
import "./MultipleVariantsCard.scss";
import { optionsSelector } from "../../Selectors";
import { MultipleVariantsDropdownButton } from "./MultipleVariantsDropdownButton";
import produce from "immer";
import { agesSelector } from "../../selectors/Selectors";
import MultipleItemRow from "./MultipleItemRow";
import * as Constants from "../../Constants";

export type TGuests = TStringIndexedObjectWithNumbers;

export type TAddToCartData = {
    item: TItem;
    quantity: number;
};

export type TItemColumn = {
    title?: string | JSX.Element;
    icon?: JSX.Element;
};

type TMultipleVariantsCard = {
    showVariants?: boolean;
    currency: string;
    getItemColumns: (arg0: TItem, arg1?: TItem[]) => TItemColumn[];
    priceLabelText: string;
    showItemsButtonText: string;
    renderExtraInformation?: (arg0: boolean) => JSX.Element;
    fallbackImage?: string;
    onAddToCartQuantityChange?: (arg0: number) => void;
    index?: number;
    arrayLength?: number;
    animate?: boolean;
    description: string;
    items: TItem[];
    title: string;
    availability?: number;
    images: TImage[];
    unlimitedBookings?: boolean;
    searchWithoutPrice?: boolean;
    decorateItemBeforeCart?: (item: TItem) => void;
    withMoreImageButton?: boolean;
    group: TGroup | TItem;
    reservationType?: string;
    newCartItemOnEveryAdd?: boolean;
};

export const addToCartSuccesDuration = 1500;

export const MultipleVariantsCard = ({
    showVariants = false,
    currency,
    getItemColumns,
    priceLabelText,
    showItemsButtonText,
    renderExtraInformation,
    fallbackImage,
    onAddToCartQuantityChange,
    index,
    arrayLength = 10,
    animate = true,
    description,
    items,
    title,
    availability = 0,
    images,
    unlimitedBookings = false,
    searchWithoutPrice = false,
    withMoreImageButton = false,
    decorateItemBeforeCart,
    group,
    reservationType,
    newCartItemOnEveryAdd = false,
}: TMultipleVariantsCard) => {
    const t = useTranslate();
    const dispatch = useDispatch();
    const getItemInCart = useItemInCart();
    const generalAges: TGeneralAges = useSelector(agesSelector);
    const firstItem = items[0];
    let firstItemCartQuantity = 0;
    const price = getLowestPrice(items, generalAges);
    const [showItems, setShowItems] = useState(false);
    const [addToCartData, setAddToCartData] = useState<{ [key: number]: TAddToCartData }>({});
    const [addToCartSuccess, setAddToCartSuccess] = useState<number | null>(null);
    const addToCartDataQuantity = Object.values(addToCartData).reduce((acc, item) => {
        return acc + item.quantity;
    }, 0);
    const [showGalleryModal, setShowGalleryModal] = useState(false);
    const { isIframe, isDesktop, isMobile, isTablet, iframeOffsetTop } = useAppSelector(
        (state: RootState) => state.window
    );
    const cardRef = useRef<HTMLDivElement | null>(null);
    const imageRef = useRef<HTMLDivElement | null>(null);
    const [cardHeightIsLargerThanImage, setCardHeightIsLargerThanImage] = useState(false);
    const lang = useAppSelector(state => state.clientData?.lang);
    const showImageDescriptions = useSelector(optionsSelector).layout?.show_image_descriptions || false;

    const groupOrItemInspectionData =
        "isGrouped" in group && group.isGrouped && group.grouppoollid > 0
            ? getInspectionDataForGroup(group)
            : getInspectionDataForProduct(firstItem);

    let mainImage;
    if ("isGrouped" in group && group.isGrouped && group.groupitem?.thumbnail) {
        mainImage = group.groupitem.thumbnail;
    } else if ("images" in group && group.images && group.thumbnail) {
        mainImage = group.thumbnail;
    } else if (firstItem?.images && firstItem?.images.length) {
        mainImage = firstItem?.images[0];
    }

    useEffect(() => {
        if (cardRef.current) {
            const observer = new ResizeObserver(() => {
                if (cardRef.current && imageRef.current) {
                    console.log(cardRef.current?.clientHeight, imageRef.current?.clientHeight);
                    setCardHeightIsLargerThanImage(cardRef.current?.clientHeight > imageRef.current?.clientHeight);
                }
            });

            observer.observe(cardRef.current);
            return () => {
                observer.disconnect();
            };
        }
    }, []);

    if (!showVariants) {
        const { itemInCartQuantity } = getItemInCart(firstItem);
        firstItemCartQuantity = itemInCartQuantity;
    }

    useEffect(() => {
        onAddToCartQuantityChange && onAddToCartQuantityChange(addToCartDataQuantity);
    }, [addToCartDataQuantity, onAddToCartQuantityChange]);

    const changeGroupQuantity = (item: TItem, quantity: number, guests?: TGuests) => {
        setAddToCartData(prevState => {
            if (prevState && quantity > 0) {
                return { ...prevState, [item.id]: { item, quantity } };
            }
            const { [item.id]: _, ...rest } = prevState;
            return rest;
        });
    };

    const handleAddToCart = (item: TItem, count: number, guests?: TGuests) => {
        let decoratedItem = decorateItemBeforeCart ? decorateItemBeforeCart(item) : item;

        if (guests) {
            // Add guests to item before it's added to cart
            decoratedItem = produce(decoratedItem, (draft: TItem) => {
                draft.info
                    ? (draft.info.guests = { ...guests, totalGuests: count })
                    : (draft.info = { guests: { ...guests, totalGuests: count } });
                draft.count = count;
            });
            dispatch(addProductGuests(decoratedItem));
        } else if (newCartItemOnEveryAdd) {
            dispatch(addProduct(decoratedItem));
        } else {
            dispatch(addProductCount(decoratedItem, count, true));
        }

        setAddToCartSuccess(item.id);

        setTimeout(() => {
            setAddToCartSuccess(null);
            changeGroupQuantity(item, 0);
        }, addToCartSuccesDuration);
    };

    //console.log(cardHeightIsLargerThanImage);
    const groupedItems: Record<string, TItem[]> = items.reduce((group: any, item) => {
        const { arrdate } = item;
        group[arrdate] = group[arrdate] ?? [];
        group[arrdate].push(item);
        return group;
    }, {});

    return (
        <article
            ref={cardRef}
            className={classNames("multiple-variants-card", {
                "multiple-variants-card--items-open": showItems,
                // "a-fade-in-ascent": animate && index !== undefined,
            })}
            style={{
                animationDelay: `${(index ?? 0) / 10}s`,
            }}
            {...groupOrItemInspectionData}
        >
            <div className="multiple-variants-card__primary-section">
                <div
                    className={classNames("multiple-variants-card__img-wrapper", {
                        "multiple-variants-card__img-wrapper--no-bottom-radius": cardHeightIsLargerThanImage,
                    })}
                >
                    <div ref={imageRef} className="multiple-variants-card__img">
                        <OptimizedImage
                            fallbackUrl={fallbackImage}
                            path={getOptimizedImagePathFromUrl(mainImage?.url)}
                        />
                    </div>
                    {withMoreImageButton && images.length > 0 && (
                        <>
                            <Button
                                buttonClassName="multiple-variants-card__img-btn"
                                type="secondary"
                                buttonSize="small"
                                onClick={() => {
                                    setShowGalleryModal(true);
                                }}
                            >
                                <GridIcon />
                            </Button>
                            <Modal
                                open={showGalleryModal}
                                size="lg"
                                onClose={() => setShowGalleryModal(false)}
                                {...(isIframe && isDesktop && { fromTop: 120 + iframeOffsetTop + "px" })}
                                {...(isIframe &&
                                    (isMobile || isTablet) && {
                                        fromTop: 80 + iframeOffsetTop + "px",
                                    })}
                                className="multiple-variants-card__gallery-modal"
                            >
                                <ProductImagesGallery
                                    images={images.map(image => ({
                                        thumbnail: image.url,
                                        original: image.url,
                                        text: image?.translatedDesc ? image?.translatedDesc[lang] : "",
                                    }))}
                                    showImageDescriptions={showImageDescriptions}
                                />
                            </Modal>
                        </>
                    )}
                    {firstItem?.promotionCode && firstItem?.promotionCode !== null && (
                        <div className="multiple-variants-card__campaign-badge">
                            {t("book.results.included_in_campaign")}
                        </div>
                    )}
                </div>
                <div className="multiple-variants-card__info">
                    <Heading type="h2" styleAs="h3" className="u-mb-6">
                        {title}
                    </Heading>
                    {renderExtraInformation && (
                        <div className="u-d-flex u-gap-6 u-align-items-center u-mb-6">
                            {renderExtraInformation(showVariants)}
                        </div>
                    )}
                    <ExpandableText
                        text={description ?? ""}
                        collapsedButtonText={t("book.general.read_more")}
                        expandedButtonText={t("book.general.read_less")}
                        collapsedLineClamp={2}
                        inlineButton
                        renderText={(text: string) => <RichText content={text} />}
                    />
                </div>
                <div className="multiple-variants-card__price-wrapper">
                    <div className="multiple-variants-card__availability">
                        {!showVariants && availability && <span>{t("book.activity.number_left", [availability])}</span>}
                    </div>
                    {!searchWithoutPrice && (
                        <>
                            {showVariants && <div className="multiple-variants-card__price-from">{priceLabelText}</div>}
                            <div className="multiple-variants-card__price">{formatPrice(price, currency)}</div>
                        </>
                    )}
                    {showVariants ? (
                        <Button
                            type={"secondary"}
                            buttonSize="small"
                            rightIcon={showItems ? <ChevronUpIcon /> : <ChevronDownIcon />}
                            onClick={() => setShowItems(prevState => !prevState)}
                            badgeCount={showItems ? null : addToCartDataQuantity > 0 ? addToCartDataQuantity : null}
                        >
                            {showItemsButtonText}
                        </Button>
                    ) : (
                        <MultipleVariantsDropdownButton
                            item={firstItem}
                            addToCartData={addToCartData}
                            onChange={changeGroupQuantity}
                            handleAddToCart={handleAddToCart}
                            addToCartSuccess={addToCartSuccess}
                            itemInCartQuantity={firstItemCartQuantity}
                        />
                    )}
                </div>
            </div>
            {showVariants && showItems && reservationType === Constants.productTypeNames.ACTIVITY && (
                <div>
                    {Object.keys(groupedItems).map((item, index) => (
                        <MultipleItemRow
                            key={index}
                            groupedItem={groupedItems[item]}
                            item={groupedItems[item][0]}
                            getItemColumns={getItemColumns}
                            handleAddToCart={handleAddToCart}
                            addToCartSuccess={addToCartSuccess}
                            addToCartData={addToCartData}
                            changeGroupQuantity={changeGroupQuantity}
                            unlimitedBookings={unlimitedBookings}
                            searchWithoutPrice={searchWithoutPrice}
                        />
                    ))}
                </div>
            )}
            {showVariants && showItems && reservationType !== Constants.productTypeNames.ACTIVITY && (
                <div>
                    {items.map((item, index) => (
                        <MultipleItemRow
                            key={index}
                            item={item}
                            getItemColumns={getItemColumns}
                            handleAddToCart={handleAddToCart}
                            addToCartSuccess={addToCartSuccess}
                            addToCartData={addToCartData}
                            changeGroupQuantity={changeGroupQuantity}
                            unlimitedBookings={unlimitedBookings}
                            searchWithoutPrice={searchWithoutPrice}
                        />
                    ))}
                </div>
            )}
        </article>
    );
};
