import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isActivityWithDynamicPrice } from "../../BusinessUtils";
import { formatPrice, hasAnyAccommodation, hasAnyPackage } from "../../Helper";
import { agesSelector, optionsSelector } from "../../selectors/Selectors";
import { fetchCheckoutCartSummary, setCheckoutCartNote } from "../../store/actions/checkoutCart";
import { makeReservation } from "../../store/actions/reservation";
import * as types from "../../store/actions/types";
import AccommodationList from "../AccommodationList/AccommodationList";
import ActivityWithDynamicPriceList from "../ActivityWithDynamicPriceList/ActivityWithDynamicPriceList";
import ContactInformation from "../ContactInformation/ContactInformation";
import GuestListView from "../GuestListView/GuestListView";
import PackageList from "../PackageList/PackageList";
import { Alert, Button as UIButton, Icon } from "../UI";
import promptDialog from "../UI/PromptDialog";
import ecsterLogo from "../../assets/paymentProviders/ecster.jpeg";
import "./CheckoutSummary.scss";
import { textsSelector } from "../../Selectors";

const hasAnyActivitiesWithDynamicPrice = products =>
    Object.values(products).some(product => isActivityWithDynamicPrice(product));

const CheckoutSummary = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const texts = useSelector(textsSelector);
    const userToken = useSelector(state => state.account.token);
    const checkoutSummary = useSelector(state => state.checkoutCart.summary);
    const loggedInUser = useSelector(state => state.account.user);
    const cartId = useSelector(state => state.checkoutCart.cartId);
    const currency = useSelector(state => state.clientData?.options?.general?.currency);
    const reservationTypes = useSelector(state => state.clientData.reservationtypes);
    const [uniqueProductTypes, setUniqueProductTypes] = useState([]);
    const checkoutSummaryError = useSelector(state => state.checkoutCart.error);
    const reservation = useSelector(state => state.reservation.reservation);
    const reservationError = useSelector(state => state.reservation.error);
    const createdFromReservation = useSelector(state => state.checkoutCart.createdFromReservation);
    const allowReservationNote = useSelector(optionsSelector).reservation?.allow_reservation_note || false;
    const preferredclientpayment = useSelector(state => state.clientData.options.payment.preferredclientpayment);
    const note = useSelector(state => state.checkoutCart.note);
    const myPageOnly = !!useSelector(optionsSelector).general?.mypage_only;
    const generalAges = useSelector(agesSelector);

    useEffect(() => {
        dispatch({ type: types.CLEAR_ERRORS });
    }, [dispatch]);

    useEffect(() => {
        if (!cartId) {
            navigate("/", { replace: true });
        } else {
            dispatch(fetchCheckoutCartSummary(userToken, cartId));
        }
    }, [dispatch, navigate, userToken, cartId]);

    useEffect(() => {
        if (reservation) {
            navigate(`/pay/${reservation.resvid}/0/${reservation.payerlid}`);
        }
    }, [reservation, navigate]);

    useEffect(() => {
        if (checkoutSummary?.products) {
            let uniqueProductTypes = [
                ...new Set(
                    Object.keys(checkoutSummary.products).map(productIndex => {
                        const type = checkoutSummary.products[productIndex].type;
                        const currentProductType = reservationTypes.find(resvtype => resvtype.lid === type);
                        return currentProductType;
                    })
                ),
            ].filter(uniqueProductType => uniqueProductType); // Make sure the value is truthy, otherwise no match was found above.

            // Sort reservation types by their sort order, which is defined in r360
            uniqueProductTypes.sort(function (a, b) {
                return a.sortorder < b.sortorder;
            });

            setUniqueProductTypes(uniqueProductTypes);
        }
        // TODO: R360ONL-741 Fix dependencies for useEffect related to searches.
        // eslint-disable-next-line
    }, [checkoutSummary?.products]);

    const openNotesPromptDialog = async () => {
        const [status, value] = await promptDialog({
            placeholder: texts["checkoutsummary.note_prompt.placeholder"],
            okLabel: texts["general.prompt.save"],
            cancelLabel: texts["general.confirmation.cancel"],
            initialValue: note || "",
        });

        if (status) {
            dispatch(setCheckoutCartNote(value));
        }
    };

    //--------------------------------------------- Se över betaltkomponenten -----------------------------------------//

    const renderPaymentLogo = () => {
        if (preferredclientpayment === "ecster") {
            return (
                <div className="text-end mb-5">
                    <img src={ecsterLogo} width="300" />
                </div>
            );
        }
    };

    // Render price
    const renderPrice = () => {
        const formattedTotalAmount = formatPrice(checkoutSummary?.summary.total_amount, currency);
        const formattedTotalAmountPaid = formatPrice(checkoutSummary?.summary.total_amount_paid, currency);
        const formattedTotalAmountToPay = formatPrice(checkoutSummary?.summary.total_amount_to_pay, currency);

        return (
            <Row>
                <Col lg={8} md={7}></Col>
                <Col lg={4} md={5} sm={12} xs={12}>
                    {createdFromReservation ? (
                        <div className="checkout-summary__price">
                            <div className="checkout-summary__price-row checkout-summary__price-total">
                                <span>{texts?.totalprice}</span>
                                <span>{formattedTotalAmount}</span>
                            </div>
                            <div className="checkout-summary__price-row checkout-summary__price-total">
                                <span>{texts?.paid}</span>
                                <span>{formattedTotalAmountPaid}</span>
                            </div>
                            <div className="checkout-summary__price-row checkout-summary__price-total">
                                <span>{texts?.totalpricetopay}</span>
                                <span>{formattedTotalAmountToPay}</span>
                            </div>
                        </div>
                    ) : (
                        <div className="checkout-summary__price">
                            <div className="checkout-summary__price-row checkout-summary__price-total">
                                <span>{texts?.totalprice}</span>
                                <span>{formattedTotalAmount}</span>
                            </div>
                        </div>
                    )}
                </Col>
            </Row>
        );
    };

    //-----------------------------------------------------------------------------------------------------------------------------//

    // Alert view
    const alertView = () => {
        const errors = [].concat(reservationError?.errors, checkoutSummaryError?.errors);
        return (
            <Alert type="warning">
                {errors.map((error, index) => {
                    return <p key={index}>{error?.message}</p>;
                })}
            </Alert>
        );
    };

    // Early exit if no products have yet been created in cart.
    if (!checkoutSummary || Object.values(checkoutSummary?.products).length === 0) {
        return null;
    }

    return (
        <div className="checkout-summary">
            {allowReservationNote && (
                <>
                    <Row className="mb-3">
                        <Col>
                            <Button
                                onClick={() => openNotesPromptDialog()}
                                variant="link"
                                size="md"
                                className="fw-light text-primary p-0 d-flex align-items-center"
                            >
                                <Icon name="FaRegEdit" size={16} className="me-2 mb-1" />
                                {texts[note ? "checkout.change_note" : "checkout.add_note"]}
                            </Button>
                            {note && <div className="checkout-summary__noteBox fw-light">{note}</div>}
                        </Col>
                    </Row>
                </>
            )}

            <ContactInformation loggedInUser={loggedInUser} arrivalDate={checkoutSummary.arrdate} texts={texts} />

            {hasAnyAccommodation(checkoutSummary.products) && (
                <AccommodationList
                    uniqueProductTypes={uniqueProductTypes}
                    texts={texts}
                    products={checkoutSummary.products}
                    currency={currency}
                    checkoutSummary={checkoutSummary}
                />
            )}

            {hasAnyPackage(checkoutSummary.products) && (
                <PackageList
                    uniqueProductTypes={uniqueProductTypes}
                    texts={texts}
                    products={checkoutSummary.products}
                    currency={currency}
                    checkoutSummary={checkoutSummary}
                />
            )}

            {hasAnyActivitiesWithDynamicPrice(checkoutSummary.products) && (
                <ActivityWithDynamicPriceList
                    texts={texts}
                    products={checkoutSummary.products}
                    currency={currency}
                    createdFromReservation={createdFromReservation}
                />
            )}

            <GuestListView
                texts={texts}
                products={checkoutSummary?.products}
                guests={checkoutSummary?.guests}
                currency={currency}
                checkoutSummary={checkoutSummary}
                createdFromReservation={createdFromReservation}
                generalAges={generalAges}
            />

            <footer className="checkout-summary__footer">
                {renderPaymentLogo()}
                {renderPrice()}

                {(checkoutSummaryError || reservationError) && alertView()}

                <Row>
                    <Col md={{ span: 8, offset: 4 }} sm={12} xs={12}>
                        <div className="checkout-summary__button">
                            {!myPageOnly && (
                                <UIButton className="button--invert me-2" to="/">
                                    <Icon name="FaArrowLeft" color="#0d3e60" size={16} className="me-2" />
                                    {texts?.addmoreproducts}
                                </UIButton>
                            )}

                            <UIButton className="checkout-summary__button-back-button button--invert" to="/checkout">
                                <Icon
                                    name="FaArrowLeft"
                                    size={16}
                                    color="#0d3e60"
                                    className="checkout-summary__button-back-button-icon"
                                />
                                {texts?.goback}
                            </UIButton>

                            <UIButton
                                className="button checkout-summary__button-continue-button"
                                onClick={() => {
                                    dispatch(makeReservation(userToken, cartId, note));
                                }}
                            >
                                {texts["continuetopayment"]}
                            </UIButton>
                        </div>
                    </Col>
                </Row>
            </footer>
        </div>
    );

    //-----------------------------------------------------------------------------------------------------------------------------------------//
};

export default CheckoutSummary;
