import {} from "react";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import { reservationTypesByTypeSelector } from "../selectors/Selectors";
import * as Constants from "../Constants";

export const AccommodationsAreToFarApart = () => {
    const accommodationTypes = useSelector(state =>
        reservationTypesByTypeSelector(state, Constants.productTypeNames.ACCOMMODATION)
    );
    const accommodationLids = accommodationTypes.map(x => parseInt(x.lid));
    const accommodationsInCart = useSelector(state =>
        state.checkoutCart.products.filter(item => accommodationLids.includes(parseInt(item.type)))
    );
    let tooFarApart = false;

    if (accommodationsInCart.length > 0) {
        const firstItem = accommodationsInCart[0];
        accommodationsInCart.forEach(item => {
            if (
                moment(item.arrdate).isAfter(firstItem.depdate, "day") ||
                moment(firstItem.arrdate).isAfter(item.depdate)
            ) {
                tooFarApart = true;
            }
        });
    }
    return tooFarApart;
};
