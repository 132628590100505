import classNames from "classnames";
import React from "react";
import { formatPrice } from "../../Helper";
import useTranslate from "../../hooks/useTranslate";
import Incrementer from "../UI/Incrementer";

type Props = {
    ageGroupTitle: string;
    minAge: number;
    maxAge: number | null;
    unitPrice: number;
    unitCampaignPrice: number | null;
    currency: string;
    quantity: number;
    onIncrementerChange: (arg0: number) => void;
};

export const GroupIncrementer = ({
    ageGroupTitle,
    minAge,
    maxAge,
    unitPrice,
    unitCampaignPrice,
    currency,
    quantity,
    onIncrementerChange,
}: Props) => {
    const t = useTranslate();
    const ageSpan = maxAge
        ? `${minAge} - ${maxAge} ${t("book.general.years")}`
        : `${minAge}+ ${t("book.general.years")}`;
    const hasCampainPrice = unitPrice > 0;

    //
    return (
        <section className="group-incrementer">
            <h2 className="group-incrementer__heading">{ageGroupTitle}</h2>
            <div className="group-incrementer__ages">{ageSpan}</div>
            <div className="group-incrementer__unit-price">
                <div
                    className={classNames("group-incrementer__current-price", {
                        "group-incrementer__current-price--campaign": hasCampainPrice,
                    })}
                >{`${formatPrice(unitCampaignPrice, currency)} `}</div>
                {hasCampainPrice && (
                    <div className="group-incrementer__previous-price">{`${formatPrice(unitPrice, currency)}`}</div>
                )}
            </div>
            <div className="group-incrementer__incrementer">
                <Incrementer
                    name={"name"}
                    label={"label"}
                    value={quantity}
                    onChange={({ value }: { value: number }) => onIncrementerChange(value)}
                    size="medium"
                />
            </div>
        </section>
    );
};
