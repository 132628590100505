import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { fetchCheckoutCartSummary, setCheckoutSummaryDetailedView } from "../../store/actions/checkoutCart";
import useAppDispatch from "../../hooks/useAppDispatch";
import * as types from "../../store/actions/types";
import { TCartItem, TCheckoutCart, TGeneralAges, TGuest, TReservationType } from "../../store/types";
import { formatPrice, hasAnyAccommodation } from "../../Helper";
import { BookingSummaryAccomodation, BookingSummaryContactInfo } from "../../components/BookingSummary";
import PageContainer from "../../components/PageContainer";
import { BookingSummaryRow, BookingSummaryTable } from "../../components/BookingSummary/BookingSummaryTable";
import {
    Checkbox,
    Select,
    TSelectOption,
    Notification,
    Button,
    ChevronLeftIcon,
    ChevronRightIcon,
    CommaList,
} from "@r360/library";
import {
    hasAnyGuestCategoryPrice,
    hasDynamicPrices,
    hasSamePriceInAllGuestCategories,
    isBooked,
    isCancelled,
    isParent,
} from "../../BusinessUtils";
import * as Constants from "../../Constants";

import { RichText } from "../../components/UI";
import { makeReservation } from "../../store/actions/reservation";
import { Ecster } from "../../assets/paymentProviders";
import { useBookingSummary } from "../../hooks/useBookingSummary";
import useTranslate from "../../hooks/useTranslate";
import { agesSelector, guestsOnTravelSelector } from "../../selectors/Selectors";
import { BookingSummarySkeleton } from "../../components/BookingSummary/BookingSummarySkeleton";
import useAppSelector from "../../hooks/useAppSelector";

const CheckoutSummaryPage = () => {
    const t = useTranslate();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const userToken = useAppSelector(state => state.account.token);
    const loggedInUser = useAppSelector(state => state.account.user);
    const checkoutCart = useAppSelector(state => state.checkoutCart) as TCheckoutCart;
    const cartId = checkoutCart.cartId;
    const checkoutSummary = checkoutCart.summary;
    const bookingForOthers = checkoutCart.bookingForOthers;
    const createdFromReservation = checkoutCart.createdFromReservation;
    const checkoutSummaryError = checkoutCart.error;
    const note = checkoutCart.note;
    const guests = checkoutSummary?.guests;
    const guestsOnTravel = useAppSelector(guestsOnTravelSelector) as { [key: string]: TGuest };
    const currency = useAppSelector(state => state.clientData?.options?.general?.currency);
    const reservationTypes = useAppSelector(state => state.clientData.reservationtypes) as TReservationType[];
    const reservation = useAppSelector(state => state.reservation.reservation);
    const reservationError = useAppSelector(state => state.reservation.error);
    const detailedView = useAppSelector(state => state.checkoutCart.summaryDetailedView);
    const preferredclientpayment = useAppSelector(state => state.clientData.options.payment.preferredclientpayment);
    const { requestsLoading } = useAppSelector(state => state.axiosStatus);
    const loadingCartSummary = requestsLoading["fetchCheckoutCartSummary"];
    const makeReservationInProgress: boolean = requestsLoading["makeReservation"];
    const { isMobile } = useAppSelector(state => state.window);
    const generalAges: TGeneralAges = useAppSelector(agesSelector);

    const [responsibleGuest, setResponsibleGuest] = useState<TGuest>(loggedInUser);
    const [uniqueProductTypes, setUniqueProductTypes] = useState<(TReservationType | undefined)[]>([]);
    const [renderedProducts, setRenderedProducts] = useState<number[]>([]);
    const unRenderedProducts = Object.values(checkoutSummary?.products ?? {}).filter(
        product => !renderedProducts.includes(product.id)
    );

    const {
        filterByGuest,
        handleFilterByGuest,
        resetFilterByGuest,
        getProductTitle,
        getProductAdditions,
        getProductGuests,
        getProductAdditionalInfo,
        getProductTypeLocation,
        getProductDates,
        getProductPrice,
        isFilteredGuestConnected,
    } = useBookingSummary(checkoutSummary?.products);

    let filteredGuestsArray: TGuest[] = [];

    // Remove the primary user from the list of guests if booking for others
    if (guests) {
        filteredGuestsArray = checkoutCart.bookingForOthers
            ? Object.values(guests).filter(guest => !guest.primary)
            : Object.values(guests);
    }

    const guestWithoutProducts = filteredGuestsArray.filter(
        guest => !Object.values(checkoutSummary.products ?? {}).some(product => product.guests?.[guest.id])
    );

    const productTypesToHide: string[] = [];

    Object.values(checkoutSummary?.products ?? {}).forEach(product => {
        product.connectedProductId && productTypesToHide.push(product.type.toString());
    });

    // Set contact person if booking for others
    useEffect(() => {
        bookingForOthers &&
            guests &&
            filteredGuestsArray.forEach(guest => {
                // We have to use guestsOnTravel instead of guests to look up primaryIfBookingForOthers
                if (guestsOnTravel[guest.id]?.primaryIfBookingForOthers) {
                    setResponsibleGuest(guest);
                }
            });
    }, [bookingForOthers, guests, filteredGuestsArray, guestsOnTravel]);

    useEffect(() => {
        dispatch({ type: types.CLEAR_ERRORS });
    }, [dispatch]);

    useEffect(() => {
        if (!cartId) {
            navigate("/", { replace: true });
        } else {
            dispatch(fetchCheckoutCartSummary(userToken, cartId));
        }
    }, [dispatch, navigate, userToken, cartId]);

    useEffect(() => {
        if (reservation) {
            navigate(`/pay/${reservation.resvid}/0/${reservation.payerlid}`);
        }
    }, [reservation, navigate]);

    useEffect(() => {
        if (checkoutSummary?.products) {
            const uniqueProductTypes = [
                ...new Set(
                    Object.keys(checkoutSummary.products).map(productIndex => {
                        const type = checkoutSummary.products[productIndex].type;
                        const currentProductType = reservationTypes.find(resvtype => resvtype.lid === type);
                        return currentProductType;
                    })
                ),
            ].filter(uniqueProductType => uniqueProductType); // Make sure the value is truthy, otherwise no match was found above.

            // Sort reservation types by their sort order, which is defined in r360
            uniqueProductTypes.sort((a, b) => {
                if (!a || !b) return 0;

                return a.sortorder - b.sortorder;
            });

            setUniqueProductTypes(uniqueProductTypes);
        }
        // eslint-disable-next-line
    }, [checkoutSummary?.products]);

    const isActivityWithDifferentGuestages = (product: TCartItem) =>
        !hasDynamicPrices(product) && hasAnyGuestCategoryPrice(product) && !hasSamePriceInAllGuestCategories(product);

    // Create an array with all unique producttypes and their products
    const productTypesWithParentProducts = uniqueProductTypes
        .filter(productType => productType?.type !== Constants.productTypeNames.ACCOMMODATION)
        .filter(productType => productType?.lid && !productTypesToHide.includes(productType.lid.toString()))
        .map(productType => {
            return {
                title: productType?.description ?? "",
                products: Object.values(checkoutSummary.products)
                    .filter(product => product.type === productType?.lid)
                    .filter(product => isParent(product) && !isCancelled(product))
                    .filter(product => product.guests && Object.keys(product.guests).length)
                    .sort((a, b) => (a.title === b.title ? 1 : -1))
                    // Group activities except for products with different prices (depending on guestage) or products with dynamic prices (they are already grouped)
                    .reduce((acc: TCartItem[], product: TCartItem) => {
                        if (product.kind !== Constants.productTypeNames.ACTIVITY || product.dynamicprices) {
                            return [...acc, product];
                        }

                        if (isActivityWithDifferentGuestages(product)) {
                            return [...acc, product];
                        }

                        const match = acc.find(
                            x =>
                                `${x.depdate}${x.arrdate}${x.activitylid}` ===
                                `${product.depdate}${product.arrdate}${product.activitylid}`
                        );

                        if (!match) {
                            return [...acc, product];
                        }

                        Object.values(product.guests).forEach(guest => {
                            match.guests[guest.id] = guest;
                        });

                        Object.entries(product.guestNotes).forEach(([id, note]) => {
                            match.guestNotes[id] = note;
                        });

                        match.groupedWith = product.id;

                        return [...acc.filter(x => x.id !== match?.id), match];
                    }, []),
            };
        });

    // Early exit if no products have yet been created in cart.
    if (!checkoutSummary || Object.values(checkoutSummary?.products).length === 0) {
        return null;
    }

    const filterByGuestOptions: TSelectOption[] = [
        {
            label: t("book.general.show_all"),
            value: "showAll",
        },
        filteredGuestsArray
            .filter(guest => !guestWithoutProducts.some(guestWithoutProduct => guest.id === guestWithoutProduct.id))
            .map(guest => {
                return {
                    label: `${guest?.firstname} ${guest?.lastname}`,
                    value: guest.id,
                };
            }),
    ].flat();

    const getProductCategoryPrice = (product: TCartItem) => {
        const priceCategory: "price" | "pricec1" | "pricec2" | "pricec3" | "pricec4" | "pricec5" = `price${
            getGuestAgeCategory(product)?.agecat ?? ""
        }`;

        return product[priceCategory === "pricec5" ? "price" : priceCategory] ?? 0;
    };

    const getGuestAgeCategory = (product: TCartItem) => {
        const agesForReservationType = generalAges[product.type];
        const guest: TGuest = Object.values(product.guests)[0];

        return agesForReservationType.find(cat => cat.min <= guest.age && cat.max >= guest.age);
    };

    const renderErrors = () => {
        const errors = [].concat(reservationError?.errors, checkoutSummaryError?.errors);
        return (
            <div className="u-mb-24">
                <Notification type={"error"}>
                    <>
                        {errors.map((error: any, index) => {
                            return (
                                <p key={index} className="u-mb-0">
                                    {error?.message}
                                </p>
                            );
                        })}
                    </>
                </Notification>
            </div>
        );
    };

    const renderPaymentLogo = () => {
        if (preferredclientpayment === "ecster") {
            return (
                <div className="u-d-flex u-justify-content-end u-pt-42">
                    <img src={Ecster} width="300" />
                </div>
            );
        }
    };

    // Render price
    const renderTotalPrice = () => {
        const totalAmount: number = checkoutSummary?.summary.total_amount;
        const totalAmoundPaid: number = checkoutSummary?.summary.total_amount_paid;
        const totalAmountToPay: number = checkoutSummary?.summary.total_amount_to_pay;

        const renderPriceRow = (title: string, amount: number) => {
            return (
                <div>
                    <span className="u-fw-medium">{title}:</span>
                    <span> {formatPrice(amount, currency)}</span>
                </div>
            );
        };

        return (
            <div className="u-d-flex u-flex-column u-align-items-md-end u-mb-42" style={{ fontSize: 30 }}>
                {createdFromReservation ? (
                    <>
                        {renderPriceRow(t("book.general.total"), totalAmount)}
                        {renderPriceRow(t("book.checkout.amount_paid"), totalAmoundPaid)}
                        {renderPriceRow(t("book.checkout.amount_to_pay"), totalAmountToPay)}
                    </>
                ) : (
                    <>{renderPriceRow(t("book.general.total"), totalAmount)}</>
                )}
            </div>
        );
    };

    const renderFilterByGuestNotification = () => {
        return (
            <div className="u-mb-24">
                <Notification type="warning">
                    {t("book.checkout.filtering_on_guest")} {filterByGuest?.label}{" "}
                    <Button type="tertiary" onClick={resetFilterByGuest}>
                        {t("book.general.remove_filter")}
                    </Button>
                </Notification>
            </div>
        );
    };

    const handleSetRenderedProducts = (id: number) => {
        if (!renderedProducts.includes(id)) {
            setRenderedProducts([...renderedProducts, id]);
        }
    };

    return (
        <PageContainer>
            {loadingCartSummary ? (
                <BookingSummarySkeleton />
            ) : (
                <>
                    <div className="u-mb-36 u-pt-lg-36">
                        <BookingSummaryContactInfo
                            primaryGuest={loggedInUser}
                            responsibleGuest={responsibleGuest}
                            guests={filteredGuestsArray}
                        />
                    </div>
                    {(checkoutSummaryError || reservationError) && renderErrors()}
                    {guestWithoutProducts.length > 0 && (
                        <div className="u-mb-24">
                            <Notification type={"error"}>
                                <p>
                                    {t("book.checkout.guests_not_connected.no_products")}
                                    <CommaList
                                        array={guestWithoutProducts.map(
                                            guest => `${guest.firstname} ${guest.lastname}`
                                        )}
                                        preLastPhrase="och"
                                        listItemClassName="u-fw-medium"
                                    />
                                </p>
                                <p className="u-mb-0">
                                    {`${t("book.checkout.guests_not_connected.go_back")} `}
                                    <Link to="/checkout" className="u-link">
                                        {t("book.checkout.connect_guests")}
                                    </Link>
                                    {` ${t("book.general.or")} `}
                                    <Link to="/" className="u-link">
                                        {t("book.checkout.add_more_products")}
                                    </Link>
                                    . {t("book.checkout.guests_not_connected.ok_if_not_connected")}
                                </p>
                            </Notification>
                        </div>
                    )}
                    {filterByGuest && renderFilterByGuestNotification()}
                    <div className="u-mb-24 u-d-flex u-flex-column u-flex-lg-row u-align-items-lg-end u-gap-24">
                        {filteredGuestsArray.length > 1 && (
                            <div style={{ minWidth: 250 }}>
                                <Select
                                    key={filterByGuest?.value}
                                    label={t("book.checkout.filter_on_guest")}
                                    type="standard"
                                    options={filterByGuestOptions}
                                    defaultValue={filterByGuest?.value}
                                    onSelectCallback={handleFilterByGuest}
                                    fullWidth
                                />
                            </div>
                        )}
                        <Checkbox
                            label={t("book.checkout.show_detailed_view")}
                            checked={detailedView}
                            onChange={() => dispatch(setCheckoutSummaryDetailedView(!detailedView))}
                        />
                    </div>
                    <div className="u-d-flex u-flex-column u-gap-30 u-mb-36">
                        {hasAnyAccommodation(checkoutSummary.products) && (
                            <div className="u-d-flex u-flex-column u-gap-24">
                                <BookingSummaryAccomodation
                                    uniqueProductTypes={uniqueProductTypes}
                                    checkoutSummary={checkoutSummary}
                                    filterByGuestId={filterByGuest?.value.toString() ?? ""}
                                    createdFromReservation={createdFromReservation ?? undefined}
                                    detailedView={detailedView}
                                    handleSetRenderedProducts={handleSetRenderedProducts}
                                />
                            </div>
                        )}
                        {productTypesWithParentProducts
                            .filter(product => checkoutSummary || isBooked(product))
                            .filter(product => product.products.length > 0)
                            .map(productType => {
                                // Init the table totalprice as 0

                                let totalPrice = 0;
                                let showTable = true;

                                if (filterByGuest) {
                                    showTable = productType.products.some(isFilteredGuestConnected);
                                }

                                let newOrChangedProducts: TCartItem[] = [];

                                if (createdFromReservation) {
                                    newOrChangedProducts = productType.products.filter(product => !isBooked(product));
                                }

                                return (
                                    <BookingSummaryTable
                                        key={productType.title}
                                        title={productType.title ?? ""}
                                        productCount={productType.products.length}
                                        hidden={!showTable}
                                        numberOfNewProducts={newOrChangedProducts.length}
                                    >
                                        <>
                                            {productType.products.map(product => {
                                                const guests = product.guests && getProductGuests(product);
                                                let price = 0,
                                                    title = "";

                                                const ordinaryPrice =
                                                    product.ordinaryprice > 0 ? getProductPrice(product, true) : 0;

                                                // If the product has different price categories, get the right price
                                                if (isActivityWithDifferentGuestages(product)) {
                                                    price = getProductCategoryPrice(product);
                                                    title = getProductTitle(
                                                        product,
                                                        getGuestAgeCategory(product)?.min,
                                                        getGuestAgeCategory(product)?.max
                                                    );
                                                } else {
                                                    price = getProductPrice(product);
                                                    title = getProductTitle(product);
                                                }

                                                totalPrice += price;

                                                handleSetRenderedProducts(product.id);

                                                if (product.groupedWith) {
                                                    handleSetRenderedProducts(product.groupedWith);
                                                }

                                                return (
                                                    <BookingSummaryRow
                                                        key={product.id}
                                                        title={title}
                                                        additions={getProductAdditions(product)}
                                                        onRenderAddition={addition =>
                                                            addition.id && handleSetRenderedProducts(addition.id)
                                                        }
                                                        phone={
                                                            product.phones
                                                                ? Object.values(product.phones)[0]
                                                                : undefined
                                                        }
                                                        guests={guests}
                                                        price={{
                                                            price,
                                                            ordinaryPrice:
                                                                ordinaryPrice > 0 ? ordinaryPrice : undefined,
                                                            campaign: !!product.promotionCode,
                                                            currency,
                                                        }}
                                                        location={product.location}
                                                        dates={getProductDates(product)}
                                                        details={{
                                                            description: product.weblong ? (
                                                                <RichText content={product.weblong} />
                                                            ) : (
                                                                ""
                                                            ),
                                                            additionalInfo: getProductAdditionalInfo(product),
                                                        }}
                                                        showDetails={detailedView}
                                                        isNewOrChangedProduct={
                                                            !!(createdFromReservation && !isBooked(product))
                                                        }
                                                        hidden={
                                                            filterByGuest ? !isFilteredGuestConnected(product) : false
                                                        }
                                                    />
                                                );
                                            })}
                                            {!filterByGuest && (
                                                <BookingSummaryRow
                                                    location={getProductTypeLocation(productType.products)}
                                                    price={{
                                                        price: totalPrice,
                                                        currency,
                                                        isTotalPrice: true,
                                                    }}
                                                />
                                            )}
                                        </>
                                    </BookingSummaryTable>
                                );
                            })}
                        {unRenderedProducts.length > 0 && (
                            <BookingSummaryTable
                                title={t("book.checkout.other_products")}
                                productCount={unRenderedProducts.length}
                            >
                                <>
                                    {unRenderedProducts.map(product => (
                                        <BookingSummaryRow
                                            key={product.id}
                                            title={product.title}
                                            additions={getProductAdditions(product)}
                                            phone={product.phones ? Object.values(product.phones)[0] : undefined}
                                            guests={product.guests && getProductGuests(product)}
                                            price={{
                                                price: getProductPrice(product),
                                                currency,
                                            }}
                                            isCancelled={isCancelled(product)}
                                            location={product.location}
                                            dates={getProductDates(product)}
                                            details={{
                                                description: product.weblong ? (
                                                    <RichText content={product.weblong} />
                                                ) : (
                                                    ""
                                                ),
                                                additionalInfo: getProductAdditionalInfo(product),
                                            }}
                                            showDetails={detailedView}
                                        />
                                    ))}
                                </>
                            </BookingSummaryTable>
                        )}
                    </div>
                    {filterByGuest && renderFilterByGuestNotification()}
                    {!filterByGuest && renderTotalPrice()}
                    {(checkoutSummaryError || reservationError) && renderErrors()}
                    <div className="u-d-flex u-flex-column u-flex-md-row u-gap-18 u-flex-wrap u-justify-content-between">
                        <div>
                            <Button
                                type="secondary"
                                leftIcon={<ChevronLeftIcon />}
                                link
                                linkTo="/"
                                fullWidth={isMobile}
                            >
                                {t("book.checkout.add_more_products")}
                            </Button>
                        </div>
                        <div className="u-d-flex u-flex-wrap u-gap-18">
                            <Button
                                type="secondary"
                                leftIcon={<ChevronLeftIcon />}
                                link
                                linkTo="/checkout"
                                fullWidth={isMobile}
                            >
                                {t("book.general.go_back")}
                            </Button>
                            <Button
                                rightIcon={<ChevronRightIcon />}
                                onClick={() => {
                                    dispatch(makeReservation(userToken, cartId, note));
                                }}
                                fullWidth={isMobile}
                                loading={makeReservationInProgress}
                            >
                                {t("book.checkout.proceed_to_payment")}
                            </Button>
                        </div>
                    </div>
                    {renderPaymentLogo()}
                </>
            )}
        </PageContainer>
    );
};

export default CheckoutSummaryPage;
