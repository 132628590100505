import {
    Button,
    ExpandableText,
    Heading,
    IncrementerButton,
    InfoIcon,
    OptimizedImage,
    Tag,
    getOptimizedImagePathFromUrl,
    style,
} from "@r360/library";
import React, { useEffect, useState } from "react";
import "./AccommodationExtrasItem.scss";
import useTranslate from "../../hooks/useTranslate";
import { Col, Row } from "react-bootstrap";
import useAppSelector from "../../hooks/useAppSelector";
import useMemoizeArg from "../../hooks/useMemoizeArg";
import { hasBookstat, isBooked, isSkipass, sortBySortorder } from "../../BusinessUtils";
import sumBy from "lodash/sumBy";
import sum from "lodash/sum";
import cloneDeep from "lodash/cloneDeep";
import { formatPrice } from "../../Helper";
import groupBy from "lodash/groupBy";
import { TCartItem, TGroup, TItem } from "../../store/types";
import "./AccommodationExtrasItem.scss";

export type TAddToCartData = {
    item: TItem;
    quantity: number;
};

type AccommodationSkipassProps = {
    accommodation: TItem;
    guests: number[];
    skipassExtraGroup: TGroup;
    onChange: (addToCartData: TAddToCartData[]) => void;
    accommodationConnectedProductsInCart: TCartItem[];
};

const AccommodationSkipassExtraCard = ({
    accommodation,
    guests,
    skipassExtraGroup,
    onChange,
    accommodationConnectedProductsInCart = [],
}: AccommodationSkipassProps) => {
    const t = useTranslate();
    const currency = useAppSelector(state => state.clientData?.options?.general?.currency);
    const [summaryPrice, setSummaryPrice] = useState(0);
    const [summaryOrdinaryPrice, setSummaryOrdinaryPrice] = useState(0);
    const [isMax, setIsMax] = useState(false);
    const [addToCartData, setAddToCartData] = useState<TAddToCartData[]>([]);
    const [bookedQuantites, setBookedQuantities] = useState<Record<number, number>>({});

    useEffect(() => {
        if (!skipassExtraGroup) return;

        // Check if there are exists skipass extras connected to accommodation in cart.
        if (addToCartData.length === 0) {
            setAddToCartData(
                cloneDeep(accommodationConnectedProductsInCart)
                    .filter(item => !hasBookstat(item))
                    .filter(item => item.count >= 1)
                    .map(item => ({ item, quantity: item.count }))
            );
        }
    }, [useMemoizeArg(skipassExtraGroup)]);

    useEffect(() => {
        setBookedQuantities(
            Object.entries(groupBy(accommodationConnectedProductsInCart.filter(isBooked), item => item.poollid)).reduce(
                (acc, [poollid, items]) => {
                    acc[parseInt(poollid, 10)] = sumBy(items, "bookedquantity");
                    return acc;
                },
                {} as Record<number, number>
            )
        );
    }, [useMemoizeArg(accommodationConnectedProductsInCart)]);

    useEffect(() => {
        setSummaryPrice(
            sumBy(addToCartData, addToCartDataEntry => addToCartDataEntry.quantity * addToCartDataEntry.item.aprice)
        );
        setSummaryOrdinaryPrice(
            sumBy(
                addToCartData,
                addToCartDataEntry =>
                    addToCartDataEntry.quantity *
                    (addToCartDataEntry.item.ordinaryprice > 0
                        ? addToCartDataEntry.item.ordinaryprice
                        : addToCartDataEntry.item.price)
            )
        );
        setIsMax(
            sumBy(addToCartData, addToCartDataEntry => addToCartDataEntry.quantity) +
                sum(Object.values(bookedQuantites)) ===
                accommodation.capac
        );
    }, [addToCartData, guests, bookedQuantites, accommodation.capac]);

    useEffect(() => {
        onChange(addToCartData);
    }, [useMemoizeArg(addToCartData)]);

    const onIncrementClick = (poollid: number) => {
        const item = skipassExtraGroup?.items.find(item => item.poollid === poollid);

        if (!item || isMax) return;

        const addToCartDataIndex = addToCartData.findIndex(entry => entry.item.poollid === poollid);

        if (addToCartDataIndex >= 0) {
            const addToCartDataClone = cloneDeep(addToCartData);
            addToCartDataClone[addToCartDataIndex].quantity += 1;
            setAddToCartData(addToCartDataClone);
        } else {
            setAddToCartData([
                ...addToCartData,
                { item: { ...item, groupTitle: skipassExtraGroup?.groupitem.title || "" }, quantity: 1 },
            ]);
        }
    };

    const onDecrementClick = (poollid: number) => {
        setAddToCartData(
            cloneDeep(addToCartData).reduce((acc: TAddToCartData[], curr) => {
                if (curr.item.poollid === poollid && curr.quantity >= 1) {
                    curr.quantity -= 1;
                }

                acc.push(curr);

                return acc;
            }, [])
        );
    };

    if (!skipassExtraGroup?.groupitem || skipassExtraGroup.items.length === 0) return null;

    return (
        <div style={{ margin: "-18px 12px 0 12px" }}>
            <div>
                <Heading type="h5" className="u-mb-12">
                    {t("book.accommodation_extras_skipass_headline")}
                </Heading>
            </div>
            <Row className="accommodation-skipass-extra">
                <Col md={{ span: 8, order: 1 }} xs={{ span: 12, order: 2 }}>
                    <Heading type="h6" className="accommodation-skipass-extra__first-heading">
                        {skipassExtraGroup.groupitem.category}
                    </Heading>
                    <Heading type="h3">{skipassExtraGroup.groupitem.title}</Heading>
                    {skipassExtraGroup.groupitem.weblong && (
                        <ExpandableText
                            text={skipassExtraGroup.groupitem.weblong}
                            collapsedButtonText={t("book.general.show_more")}
                            expandedButtonText={t("book.general.show_less")}
                            collapsedMaxLength={140}
                            inlineButton
                        />
                    )}
                    <div className="u-mt-30">
                        {sortBySortorder(skipassExtraGroup.items.filter(isSkipass)).map((item: TItem) => (
                            <SkipassVariant
                                key={item.id}
                                type={item.title || item.agecategory || ""}
                                age={`${item.minage} - ${item.maxage} ${t("book.general.years")}`}
                                quantity={
                                    addToCartData.find(
                                        addToCartDataEntry => addToCartDataEntry.item.poollid === item.poollid
                                    )?.quantity || 0
                                }
                                bookedQuantity={bookedQuantites[item.poollid] || 0}
                                currency={currency}
                                price={item.price}
                                ordinaryPrice={item.ordinaryprice}
                                increment={() => onIncrementClick(item.poollid)}
                                decrement={() => onDecrementClick(item.poollid)}
                                isMax={isMax}
                            />
                        ))}
                    </div>
                    <div className="accommodation-skipass-extra__total-price">
                        <p style={{ marginBottom: 0 }}>
                            {t("book.cart.summary.total_price")}:
                            <span className="accommodation-skipass-extra__discount-price">
                                {formatPrice(summaryPrice, currency)}
                            </span>
                            {summaryOrdinaryPrice > 0 && summaryOrdinaryPrice !== summaryPrice && (
                                <>
                                    <span className="accommodation-skipass-extra__ord-price">
                                        {formatPrice(summaryOrdinaryPrice, currency)}
                                    </span>
                                </>
                            )}
                        </p>
                    </div>
                </Col>
                <Col
                    md={{ span: 4, order: 2 }}
                    xs={{ span: 12, order: 1 }}
                    className="accommodation-skipass-extra__img-col"
                >
                    {skipassExtraGroup.groupitem.thumbnail?.url && (
                        <div className="accommodation-skipass-extra__img">
                            <OptimizedImage
                                path={getOptimizedImagePathFromUrl(skipassExtraGroup.groupitem.thumbnail.url)}
                            />
                        </div>
                    )}
                    {t("book.accommodation_extras_skipass_discount_tag") && (
                        <Tag
                            text={t("book.accommodation_extras_skipass_discount_tag")}
                            className="accommodation-skipass-extra__discount-tag"
                            theme="success-light"
                        />
                    )}
                </Col>
                <Col md={{ span: 12, order: 3 }} xs={{ span: 12, order: 3 }}>
                    {isMax && (
                        <div className="u-mb-12">
                            <InfoIcon color={style.yellowColor} className="u-mr-6" />
                            <span className="accommodation-skipass-extra__max-info-text">
                                {t("book.accommodation_extras_skipass_max_capac_message")}
                            </span>
                        </div>
                    )}
                </Col>
            </Row>
        </div>
    );
};

export default AccommodationSkipassExtraCard;

type VariantProps = {
    type: string;
    age: string;
    quantity: number;
    bookedQuantity?: number;
    price: number;
    ordinaryPrice: number;
    currency: string;
    isMax: boolean;
    increment: () => void;
    decrement: () => void;
};

const SkipassVariant = ({
    type,
    age,
    quantity,
    bookedQuantity = 0,
    price,
    ordinaryPrice,
    currency,
    isMax,
    increment,
    decrement,
}: VariantProps) => {
    const { isMobile } = useAppSelector(state => state.window);

    return (
        <div className="u-mt-12 u-d-flex u-align-items-center u-justify-content-between row">
            <div className="col-4">
                <span className="u-fw-medium">{type}, </span>
                {isMobile && <br />}
                <span>{age}</span>
            </div>
            <div className="col-3">
                <div className="u-d-flex align-items-center">
                    <Button type="tertiary" onClick={decrement}>
                        <IncrementerButton type="decrement" incrementerSize="small" disabled={quantity <= 0} />
                    </Button>
                    <span className="accommodation-skipass-extra__quantity">{quantity + bookedQuantity}</span>
                    <Button type="tertiary" onClick={increment}>
                        <IncrementerButton type="increment" incrementerSize="small" disabled={isMax} />
                    </Button>
                </div>
            </div>
            <div className="col-5">
                <span className="u-fw-medium">{formatPrice(price, currency)}</span>
                {isMobile && <br />}
                {ordinaryPrice > 0 && (
                    <span style={{ textDecoration: "line-through", marginLeft: isMobile ? "0px" : "12px" }}>
                        {formatPrice(ordinaryPrice)}
                    </span>
                )}
            </div>
        </div>
    );
};
