import { BarsStaggeredIcon, Button } from "@r360/library";
import React from "react";
import useAppSelector from "../../hooks/useAppSelector";
import useTranslate from "../../hooks/useTranslate";

type TToggleFilterButton = {
    reservationTypeId: number;
    showFilter: boolean;
    onClick: (arg0: boolean) => void;
};

export const ToggleFilterButton = ({ reservationTypeId, showFilter, onClick }: TToggleFilterButton) => {
    const t = useTranslate();
    const filters = useAppSelector(state => state.filter);
    const activeFilters = filters && reservationTypeId in filters ? filters[reservationTypeId] : {};
    const activeFiltersCount: number = activeFilters ? Object.keys(activeFilters).length : 0;

    return (
        <Button
            type="secondary"
            buttonSize="small"
            leftIcon={<BarsStaggeredIcon />}
            onClick={() => onClick(!showFilter)}
        >
            {showFilter
                ? `${t("book.general.hide_filter")} (${activeFiltersCount})`
                : `${t("book.general.show_filter")} (${activeFiltersCount})`}
        </Button>
    );
};
