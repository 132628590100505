import * as types from "../actions/types";

export const setLoading = payload => {
    return {
        type: types.SET_LOADING,
        loading: payload.loading,
    };
};

export const setRequestsLoading = payload => {
    return {
        type: types.SET_REQUESTS_LOADING,
        request: payload.request,
        loading: payload.loading,
    };
};
