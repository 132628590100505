import React, { useState } from "react";
import { useSelector } from "react-redux";

import { Icon } from "..";
import "./Incrementer.scss";
import { textsSelector } from "../../../Selectors";

// Number incrementer component
const Incrementer = props => {
    const {
        label = "",
        name,
        describedBy = "",
        value,
        minValue = 0,
        maxValue,
        onChange,
        size = 10,
        disabled = false,
    } = props;
    const [isPolite, setPolite] = useState(false);
    const politeValue = isPolite ? "polite" : "off";
    const texts = useSelector(textsSelector);

    // Handle decrement value
    const handleDecrement = () => {
        if (onChange) {
            if (value > minValue) {
                onChange({ key: name, value: value - 1 });
            }
        }
        setPolite(true);
    };

    // Handle increment value
    const handleIncrement = () => {
        if (onChange) {
            if (maxValue) {
                if (maxValue > value) {
                    onChange({ key: name, value: value + 1 });
                }
            } else {
                onChange({ key: name, value: value + 1 });
            }
        }
        setPolite(true);
    };

    // Set button classes based on count, min and max values
    const decrementClasses =
        value === minValue
            ? "incrementer-old__toggle-button incrementer-old__toggle-button--disabled"
            : "incrementer-old__toggle-button";
    const incrementClasses =
        maxValue && value === maxValue
            ? "incrementer-old__toggle-button incrementer-old__toggle-button--disabled"
            : "incrementer-old__toggle-button";

    return (
        <div className="incrementer-old" role="group" id={name}>
            <button
                type="button"
                onClick={handleDecrement}
                className={decrementClasses}
                aria-label={`${texts?.decreasevaluefor} ${label}`}
                aria-describedby={describedBy}
                disabled={disabled}
            >
                <span className="incrementer-old__toggle-icon" aria-hidden="true">
                    <Icon name="FaMinus" size={size} />
                </span>
            </button>
            <div className="incrementer-old__amount">
                <span aria-hidden="true">{value}</span>
                <span className="sr-only" aria-live={politeValue}>{`${value} ${texts?.valueormore} ${label}`}</span>
            </div>
            <button
                type="button"
                onClick={handleIncrement}
                className={incrementClasses}
                aria-label={`${texts?.increasevaluefor} ${label}`}
                aria-describedby={describedBy}
                disabled={disabled}
            >
                <span className="incrementer-old__toggle-icon" aria-hidden="true">
                    <Icon name="FaPlus" size={size} />
                </span>
            </button>
        </div>
    );
};

export default Incrementer;
